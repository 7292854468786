import type { Url } from "@octopusdeploy/portal-routes";
import { useParams } from "react-router";
import { client } from "~/clientInstance";
export function useUrlResolver(): UrlResolver {
    const spaceId = useSpaceId();
    return {
        resolve(url: Url): string {
            return url.resolveWithSpaceId(spaceId);
        },
    };
}
function useSpaceId() {
    const { spaceId } = useParams<{
        spaceId?: string;
    }>();
    // The client's spaceId and the route's spaceId should be in sync,
    // but because we haven't modelled this relationship in a reliable way,
    // this is not guaranteed to be true, particularly in tests
    return (spaceId || client.spaceId) ?? null;
}
export interface UrlResolver {
    resolve(url: Url): string;
}
