/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import { useRunbookContext } from "../../Runbooks/RunbookContext";
import ActionTemplateSelector from "../ActionTemplateSelector";
import type { ActionTemplateSelectorProps } from "../ActionTemplateSelector/ActionTemplateSelector";
import { ProcessPaperLayout } from "../CustomPaperLayouts/ProcessPaperLayout";
import type { ProcessFilter } from "../types";
export type WithProjectDeploymentProcessInjectedProps = {
    processId: string;
};
//This HOC pulls the project ID off the current deployment process context and passes it into the wrapped component
export const withProjectDeploymentProcessId = <T extends WithProjectDeploymentProcessInjectedProps>(Component: React.ComponentType<T & WithProjectDeploymentProcessInjectedProps>) => {
    type ExternalProps = Omit<T, keyof WithProjectDeploymentProcessInjectedProps>;
    const WithProjectDeploymentProcess: React.FC<ExternalProps> = (props) => {
        const context = useProjectContext();
        if (!context.state.model) {
            return <ProcessPaperLayout processType={ProcessType.Deployment} busy={true} {...(props as T)}/>;
        }
        return <Component {...(props as T)} processId={context.state.model.DeploymentProcessId}/>;
    };
    WithProjectDeploymentProcess.displayName = "WithProjectDeploymentProcess"
    return WithProjectDeploymentProcess;
};
// Preparation for supporting multiple processes: We currently don't have routes setup for this and injecting the scope and process id
// from the context avoids having to change the routes.
export const withProjectDeploymentProcess = <T extends WithProjectDeploymentProcessInjectedProps>(Component: React.ComponentType<T & WithProjectDeploymentProcessInjectedProps & ProcessFilter>) => {
    return withProjectDeploymentProcessId(Component);
};
export type WithProjectRunbookProcessInjectedProps = {
    processId: string;
};
export const withRunbookProcess = <T extends WithProjectRunbookProcessInjectedProps>(Component: React.ComponentType<T>) => {
    type ExternalProps = Omit<T, keyof WithProjectRunbookProcessInjectedProps>;
    const WithRunbookProcess: React.FC<ExternalProps> = (props) => {
        const context = useRunbookContext();
        if (!context.state.runbook) {
            return <ProcessPaperLayout processType={ProcessType.Runbook} busy={true} {...(props as T)}/>;
        }
        return <Component runbookId={context.state.runbook.Id} {...(props as T)} processId={context.state.runbook.RunbookProcessId}/>;
    };
    WithRunbookProcess.displayName = "WithRunbookProcess"
    return WithRunbookProcess;
};
const WrappedActionTemplateSelectionPage = withProjectDeploymentProcess(ActionTemplateSelector);
const WrappedActionTemplateSelectionRunbookPage = withRunbookProcess(ActionTemplateSelector);
export const EnhancedActionTemplateSelectionPage: React.FC<Omit<ActionTemplateSelectorProps, "processId" | "processContext" | "projectContext" | "processQueryStringContext"> & {
    processType: ProcessType;
}> = ({ children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return <WrappedActionTemplateSelectionRunbookPage {...rest}>{children}</WrappedActionTemplateSelectionRunbookPage>;
    }
    return <WrappedActionTemplateSelectionPage {...rest}>{children}</WrappedActionTemplateSelectionPage>;
};
EnhancedActionTemplateSelectionPage.displayName = "EnhancedActionTemplateSelectionPage"
