import type { IconMetadataResource, IconSvgResource } from "@octopusdeploy/octopus-server-client";
import React, { useEffect, useMemo, useState } from "react";
import { Select, Text } from "~/components/form";
import ColorPaletteSelector from "~/components/form/IconEditor/IconColorPaletteSelector";
import IconSelector from "./IconSelector";
export interface IconEditorSettings {
    iconId: string;
    iconColor: string;
}
export const IconEditorColorPalette = ["#3CA4F3", "#145586", "#25D284", "#027744", "#AED0EB", "#173B56", "#C39AF8", "#5E2EA2", "#FFB867", "#EF6F1F", "#FFADA5", "#CA1E1E"];
export const IconEditorDefaultColor = IconEditorColorPalette[0];
const defaultIconIds = [
    "chart-bar",
    "map-signs",
    "code",
    "rocket",
    "eye",
    "coffee",
    "anchor",
    "tachometer-alt",
    "skiing-nordic",
    "tools",
    "exclamation-triangle",
    "globe-africa",
    "money-check-alt",
    "shopping-basket",
    "football-ball",
    "wheelchair",
    "gift",
    "comment-dots",
    "gamepad",
    "crosshairs",
    "shield-alt",
    "binoculars",
    "calendar-check",
    "map-marker-alt",
];
export interface IconEditorProps {
    icons: IconSvgResource[];
    iconMetadata: IconMetadataResource;
    selectedIconId: string;
    selectedIconColor: string;
    onIconIdChange: (iconId: string) => void;
    onIconColorChange: (iconColor: string) => void;
}
function filterToDefaultIcons(icons: IconSvgResource[], selectedIconId: string) {
    return icons.filter((item) => defaultIconIds.some((s) => item.id === s)).sort((a, b) => defaultIconIds.indexOf(a.id) - defaultIconIds.indexOf(b.id));
}
function filterBySearchText(icons: IconSvgResource[], searchText: string) {
    return searchText.length === 0 ? icons : icons.filter((item) => item.searchTerms.some((s) => s.includes(searchText.toLowerCase())));
}
function filterByCategory(icons: IconSvgResource[], iconMetadata: IconMetadataResource, category: string) {
    return category.length > 0 ? icons.filter((item) => iconMetadata.categories[category]?.includes(item.id)) : icons;
}
const IconEditor = ({ icons, iconMetadata, selectedIconId, selectedIconColor, onIconIdChange, onIconColorChange }: IconEditorProps) => {
    const [searchText, setSearchText] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [initialIconId] = useState<string>(selectedIconId);
    const categories = useMemo(() => (iconMetadata.categories ? Object.keys(iconMetadata.categories) : []), [iconMetadata]);
    useEffect(() => {
        for (const category of categories) {
            if (iconMetadata.categories[category].includes(initialIconId)) {
                setSelectedCategory(category);
                return;
            }
        }
    }, [categories, iconMetadata, initialIconId]);
    const filteredIcons = useMemo(() => {
        if (searchText.length === 0 && selectedCategory.length === 0) {
            return filterToDefaultIcons(icons, selectedIconId);
        }
        else {
            const result = filterBySearchText(icons, searchText);
            return filterByCategory(result, iconMetadata, selectedCategory);
        }
    }, [icons, searchText, selectedCategory, iconMetadata, selectedIconId]);
    return (<>
            <Text label="Icon search" value={searchText} onChange={(text) => setSearchText(text)} placeholder="Search icons..."/>
            <Select label="Icon category" allowClear value={selectedCategory} onChange={(category) => setSelectedCategory(category || "")} placeholder="All categories..." items={categories.map((c) => ({ value: c, text: c }))}/>
            <IconSelector icons={filteredIcons} selectedIconId={selectedIconId} selectedIconColor={selectedIconColor} onSelect={(id) => onIconIdChange(id)}/>
            <ColorPaletteSelector colors={IconEditorColorPalette} onColorChange={(color) => onIconColorChange(color)} selectedColor={selectedIconColor}/>
        </>);
};
export default IconEditor;
