import type { Reducer } from "redux";
import { combineReducers } from "redux";
import type { ErrorsState } from "./ErrorsState";
import type { UnhandledErrorState } from "./current";
import current from "./current";
export * from "./actions";
export * from "./selectors";
export type { ErrorsState, UnhandledErrorState };
const unhandledErrorsReducer: Reducer<ErrorsState> = combineReducers({ current });
export default unhandledErrorsReducer;
