import { Snackbar } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { CommonTemplatesInlineTabContent } from "~/areas/projects/components/Variables/TenantVariables/CommonTemplatesInlineTabContent";
import type { CommonTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesState";
import type { CommonTemplatesValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesValueChangeTracker";
import type { TenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import type { TenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
interface CommonTemplatesInlineContentProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    contentState: CommonTemplatesState;
    filterData: TenantVariableFiltersData;
    variableResourceOptions: TenantVariableResourceOptions;
    valueChangeTracker: CommonTemplatesValueChangeTracker;
}
export default function CommonTemplatesInlineContent({ project, doBusyTask, contentState, filterData, variableResourceOptions, valueChangeTracker }: CommonTemplatesInlineContentProps) {
    const { data, pageSize, filterState, actions } = contentState;
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const onIncludeVariableSetFromOnboarding = (text: string) => {
        setIsSnackbarOpen(true);
        setSnackbarText(text);
    };
    return (<>
            {data && (<CommonTemplatesInlineTabContent project={project} doBusyTask={doBusyTask} filterState={filterState} filterData={filterData} setFilterState={actions.setFilter} pageSize={pageSize} setPageState={actions.setPageState} commonTemplatesWithValues={data} onAddTemplateFromOnboarding={onIncludeVariableSetFromOnboarding} resourceOptions={variableResourceOptions} valueChangeTracker={valueChangeTracker}/>)}
            <Snackbar open={isSnackbarOpen} content={snackbarText} autoHideDuration={3500} onClose={() => setIsSnackbarOpen(false)} textAlign={"center"}/>
        </>);
}
