import * as React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ExternalLink from "~/components/Navigation/ExternalLink";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";
const video = {
    youtubeId: "gfaRUIlQybA",
    shortlink: "OnboardingProjectsVideo",
};
export const ProjectSettingsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().settings.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (<OverviewContainer>
                <OverviewHighlightText>
                    <YouTubePlayer videoId={video.youtubeId}/>
                    <p>
                        <ExternalLink href="OnboardingProjectsLearnMore">Project settings</ExternalLink> allow you to edit details about the project, such as the name, description, and the project group, add a logo and control different aspects of
                        the project's release behaviour.
                    </p>
                </OverviewHighlightText>
            </OverviewContainer>);
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
