import { css } from "@emotion/css";
import { LinearProgress } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import type { PropsWithChildren, ReactNode } from "react";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import type { Errors } from "~/components/DataBaseComponent/index";
import ErrorPanel from "~/components/ErrorPanel/index";
export interface ProcessSubPageLayoutProps {
    title: ReactNode;
    titleLogo?: ReactNode;
    sectionControl?: ReactNode;
    busy?: Promise<unknown> | boolean;
    errors?: Errors;
}
export function ProcessSubPageLayout({ title, titleLogo, sectionControl, busy, errors, children }: PropsWithChildren<ProcessSubPageLayoutProps>) {
    const header = (<>
            <BusyFromPromise promise={busy}>{(isBusy: boolean) => <LinearProgress variant={"indeterminate"} show={isBusy}/>}</BusyFromPromise>
            <SubPageTitle title={title} titleLogo={titleLogo} sectionControl={sectionControl}/>
            {errors && <ErrorPanel errors={errors.errors} message={errors.message} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>}
        </>);
    return (<>
            {header}
            {children}
        </>);
}
interface SubPageTitleProps {
    title: React.ReactNode;
    titleLogo?: React.ReactNode;
    sectionControl?: React.ReactNode;
}
function SubPageTitle({ title, titleLogo, sectionControl }: SubPageTitleProps) {
    return (<div className={subPageTitleStyles.root}>
            <div className={subPageTitleStyles.title}>
                <div>{titleLogo}</div>
                <h2 className={subPageTitleStyles.heading}>{title}</h2>
            </div>
            {sectionControl}
        </div>);
}
const subPageTitleStyles = {
    root: css({
        display: "flex",
        padding: space[16],
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        minHeight: "4.25rem",
        gap: space[16],
    }),
    title: css({
        flex: 1,
        display: "flex",
        alignItems: "center",
        fontSize: "1.25rem",
        lineHeight: "1.75rem",
        margin: `${space[4]} 0`,
        gap: space[8],
    }),
    heading: css({
        margin: 0,
        padding: 0,
        color: themeTokens.color.text.primary,
    }),
};
