/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DashboardItemResource, TagSetResource, ChannelProgressionResource, DashboardProjectResource, DashboardTenantResource, DashboardProjectGroupResource, LifecycleResource, OverviewReleaseBff, DashboardEnvironmentResource, } from "@octopusdeploy/octopus-server-client";
enum DimensionTypes {
    None,
    Release,
    Channel,
    Environment,
    Tenant,
    TagSet,
    Project,
    ProjectGroup,
    ProjectName
}
interface DeploymentOverviewFilters {
    [key: number]: {
        [key: string]: boolean;
    };
    groupBy?: DimensionTypes;
    groupByExtra?: string;
    rowDimension?: DimensionTypes;
    columnDimension?: DimensionTypes;
}
interface DataCube {
    releaseIndex: {
        [releaseId: string]: OverviewReleaseBff;
    };
    channelIndex: {
        [channelId: string]: ChannelProgressionResource;
    };
    lifecycleIndex: {
        [lifecycleId: string]: LifecycleResource;
    };
    tenantIndex: {
        [tenantId: string]: DashboardTenantResource;
    };
    tenantTagIndex: {
        [tenantId: string]: string[];
    };
    projectIndex: {
        [projectId: string]: DashboardProjectResource;
    };
    projectGroupIndex: {
        [projectGroupId: string]: DashboardProjectGroupResource;
    };
    channelEnvironments: {
        [index: string]: string[];
    };
    environmentIndex: {
        [environmentId: string]: DashboardEnvironmentResource;
    };
    tagSetIndex: {
        [canonicalTagName: string]: TagSetResource;
    };
    nextAvailableDeployments: {
        [releaseId: string]: {
            [environmentId: string]: string[];
        };
    };
    blockedReleases: string[];
    deployments: DashboardItemResource[];
    missingVariableTenantsPromise: Promise<string[]>;
}
const DimensionGetters: {
    [key: number]: (item: DashboardItemResource) => string;
} = {
    [DimensionTypes.Channel]: (item) => item.ChannelId,
    [DimensionTypes.Release]: (item) => item.ReleaseId,
    [DimensionTypes.Environment]: (item) => item.EnvironmentId,
    [DimensionTypes.Tenant]: (item) => item.TenantId!,
    [DimensionTypes.Project]: (item) => item.ProjectId,
    [DimensionTypes.None]: (item) => null!,
};
export { DimensionTypes, DeploymentOverviewFilters, DataCube, DimensionGetters };
