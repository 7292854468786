import type { LibraryVariableSetResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { Errors } from "~/components/DataBaseComponent";
import Markdown from "~/components/Markdown/index";
import { ExpandableFormSection, Summary, required } from "~/components/form";
import MarkdownEditor from "~/components/form/MarkdownEditor/MarkdownEditor";
import Text from "~/primitiveComponents/form/Text/Text";
interface VariableSetSettingsProps {
    errors: Errors;
    libraryVariableSet: LibraryVariableSetResource;
    onLibraryVariableSetChanged(libraryVariableSet: LibraryVariableSetResource): void;
}
export default class VariableSetSettings extends React.Component<VariableSetSettingsProps> {
    render() {
        return (<div>
                <ExpandableFormSection errorKey="name" title="Name" focusOnExpandAll summary={this.props.libraryVariableSet.Name ? Summary.summary(this.props.libraryVariableSet.Name) : Summary.placeholder("Please enter a name for the variable set")} help="Enter a name for the variable set">
                    <Text label="Name" value={this.props.libraryVariableSet.Name} validate={required("Please enter a name")} onChange={(Name) => this.props.onLibraryVariableSetChanged({ ...this.props.libraryVariableSet, Name })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="description" title="Description" summary={this.props.libraryVariableSet.Description ? Summary.summary(<Markdown markup={this.props.libraryVariableSet.Description}/>) : Summary.placeholder("No description provided")} help="Enter a description for the variable set">
                    <MarkdownEditor value={this.props.libraryVariableSet.Description} label="Variable Set Description" onChange={(Description) => this.props.onLibraryVariableSetChanged({ ...this.props.libraryVariableSet, Description })}/>
                </ExpandableFormSection>
            </div>);
    }
    static displayName = "VariableSetSettings";
}
