import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonEnvironmentOverview, CommonEnvironmentTopics } from "../../CommonComponents/CommonEnvironmentViews";
export const InfrastructureEnvironmentsNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.environments.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonEnvironmentOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonEnvironmentTopics />;
    },
};
