import type { PropertyValueResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import SimpleDataTable from "~/components/SimpleDataTable";
interface ActionPropertyTableProps {
    actionProperties: {};
    currentPropertyValues: Record<string, PropertyValueResource>;
}
interface DataPoint {
    name: string;
    value: PropertyValueResource;
}
const ActionPropertyTable = (props: ActionPropertyTableProps) => {
    const data = Object.keys(props.actionProperties).map((ap) => ({ name: ap, value: props.currentPropertyValues[ap] }));
    return <SimpleDataTable data={data} headerColumns={["Property Name", "Property Value"]} onRow={buildRow}/>;
};
const buildRow = (dataPoint: DataPoint) => {
    return [dataPoint.name, dataPoint.value];
};
export default ActionPropertyTable;
