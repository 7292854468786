import { SmsIcon, Tooltip } from "@octopusdeploy/design-system-components";
import * as React from "react";
interface TemplateHelpTextProps {
    text: string | null;
}
export function TemplateHelpText({ text }: TemplateHelpTextProps) {
    if (!text) {
        return null;
    }
    return (<Tooltip content={text}>
            <SmsIcon />
        </Tooltip>);
}
