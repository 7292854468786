import { ActionButtonType } from "@octopusdeploy/design-system-components";
import React, { useState } from "react";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import type { DoBusyTask } from "../DataBaseComponent";
import NewProjectWizard from "./NewProjectWizard";
interface ContinueButtonProps {
    spaceId: string;
    projectSlug: string;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
}
function ContinueButton(props: ContinueButtonProps) {
    const [openDiolog, setOpenDialog] = useState(false);
    return (<OpenDialogButton type={ActionButtonType.Primary} label={"Continue"} renderDialog={() => <NewProjectWizard open={openDiolog} fullScreen={true} spaceId={props.spaceId} projectSlug={props.projectSlug} busy={props.busy} doBusyTask={props.doBusyTask} close={() => setOpenDialog(false)}/>} onClick={() => {
            setOpenDialog(true);
        }}/>);
}
export default ContinueButton;
