import type { InputPathToArray, InputPathToObject } from "@octopusdeploy/step-inputs";
import type { ArrayTypeDefinition, NonDiscriminatorTypeDefinition, PathSegment, PathToInput, PlainObjectTypeDefinition, InputProperty } from "@octopusdeploy/step-runtime-inputs";
import { getPathToArrayInput, getPathToInputObject } from "@octopusdeploy/step-runtime-inputs";
export function getSchemaForInputObject<StepInputs>(pathToInput: InputPathToObject<unknown>, inputSchema: PlainObjectTypeDefinition): PlainObjectTypeDefinition {
    const schemaAtPath = getSchemaForInputAtPath(getPathToInputObject(pathToInput), inputSchema);
    switch (schemaAtPath.type) {
        case "package":
        case "sensitive":
        case "account":
        case "git-source":
        case "string":
        case "primitive":
        case "container-image":
            throw new Error("An input path to an object can't point to a primitive type");
        case "array":
            throw new Error("An input path to an object can't point to an array");
    }
    return schemaAtPath;
}
export function getSchemaForInputArray<StepInputs>(pathToInput: InputPathToArray<unknown>, inputSchema: PlainObjectTypeDefinition): ArrayTypeDefinition {
    const schemaAtPath = getSchemaForInputAtPath(getPathToArrayInput(pathToInput), inputSchema);
    switch (schemaAtPath.type) {
        case "package":
        case "sensitive":
        case "account":
        case "git-source":
        case "string":
        case "primitive":
        case "container-image":
            throw new Error("An input path to an array can't point to a primitive type");
        case "object":
            throw new Error("An input path to an array can't point to an object");
    }
    return schemaAtPath;
}
// import { getInputPathOfPathSegment, narrowTypeInObjectUnion } from "~/components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/getSelectedOption";
// Note: At any point in the path to the object, the type of the property at a path segment could be a union type.
// We need the specific type (narrowed) of this union type in order to continue traversal.
//
// For example, consider this example:
// type inputs = First | Second;
// type First = { type: Discriminator<"first">; bar: string }
// type Second = { type: Discriminator<"second">;  bar: number }
export function getSchemaForInputAtPath<StepInputs>(pathToInput: PathToInput, inputSchema: PlainObjectTypeDefinition): NonDiscriminatorTypeDefinition {
    return pathToInput.reduce<NonDiscriminatorTypeDefinition>(getSchemaForNextPathSegment, inputSchema);
    function getSchemaForNextPathSegment(schema: NonDiscriminatorTypeDefinition, pathSegment: PathSegment): NonDiscriminatorTypeDefinition {
        if (!schemaIsObjectOrArray(schema)) {
            throw new Error("Attempted to traverse an input path to an object, but found a primitive type before the end of the path.");
        }
        return getSchemaOfNextProperty(schema, pathSegment);
    }
}
export function getInputPropertyForInputAtPath([pathSegment, ...pathToInput]: PathToInput, inputSchema: NonDiscriminatorTypeDefinition): InputProperty {
    assertIsPlainObjectTypeDefinition(inputSchema);
    return pathToInput.reduce<InputProperty>((node, pathSegment) => {
        const schema = node.type;
        assertIsPlainObjectTypeDefinition(schema);
        return getInputPropertyAtPathSegment(schema, pathSegment);
    }, getInputPropertyAtPathSegment(inputSchema, pathSegment));
}
function getInputPropertyAtPathSegment(schema: PlainObjectTypeDefinition, pathSegment: PathSegment): InputProperty {
    const inputProperty = schema.nonDiscriminatorProperties.find((p) => p.name === pathSegment);
    if (!inputProperty) {
        throw new Error(`Object at path ${String(pathSegment)} not found`);
    }
    return inputProperty;
}
function assertIsPlainObjectTypeDefinition(typeDefinition: NonDiscriminatorTypeDefinition): asserts typeDefinition is PlainObjectTypeDefinition {
    if (typeDefinition.type !== "object") {
        throw new Error("Attempted to traverse an input path to an object, but found a primitive type before the end of the path.");
    }
}
function schemaIsObjectOrArray(schemaAtPath: NonDiscriminatorTypeDefinition): schemaAtPath is PlainObjectTypeDefinition | ArrayTypeDefinition {
    switch (schemaAtPath.type) {
        case "object":
        case "array":
            return true;
    }
    return false;
}
function getSchemaOfNextProperty(schema: PlainObjectTypeDefinition | ArrayTypeDefinition, pathSegment: PathSegment): NonDiscriminatorTypeDefinition {
    assertPathSegmentIsNotSymbol(pathSegment);
    if (schema.type === "object") {
        assertPathSegmentIsString(pathSegment);
        const nextObjectSchema = schema.nonDiscriminatorProperties.find((p) => p.name === pathSegment);
        if (!nextObjectSchema) {
            throw new Error(`Object at path ${pathSegment} not found`);
        }
        return nextObjectSchema.type;
    }
    assertPathSegmentIsNumber(pathSegment);
    const itemType = schema.itemTypes[pathSegment];
    if (!itemType) {
        throw new Error(`Array item at index ${pathSegment} not found`);
    }
    return itemType;
}
function assertPathSegmentIsNotSymbol(pathSegment: string | number | symbol): asserts pathSegment is string | number {
    if (typeof pathSegment === "symbol") {
        throw new Error("Symbol keys are not supported");
    }
}
function assertPathSegmentIsString(pathSegment: string | number): asserts pathSegment is string {
    if (typeof pathSegment === "number") {
        throw new Error("Number keys are not supported for objects");
    }
}
function assertPathSegmentIsNumber(pathSegment: string | number): asserts pathSegment is number {
    if (typeof pathSegment === "string") {
        throw new Error("String keys are not supported for arrays");
    }
}
