import * as React from "react";
import type { GlobalConnectedProps } from "~/components/form/VariableLookup/VariableLookup";
import { withVariableLookup } from "~/components/form/VariableLookup/VariableLookup";
import { Text } from "~/components/form/index";
import type { TextProps } from "~/primitiveComponents/form/Text/Text";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
const DebounceTextWithInputRef = (props: TextProps & GlobalConnectedProps) => <DebounceText {...props}/>;
export const VariableLookupText = withVariableLookup()(DebounceTextWithInputRef);
const TextWithInputRef = (props: TextProps & GlobalConnectedProps) => <Text {...props}/>;
export const VariableLookupTextNoDebounce = withVariableLookup()(TextWithInputRef);
