/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { BooleanRadioButtonGroup, BooleanRadioButton, ActionButton, Callout } from "@octopusdeploy/design-system-components";
import type { IdentityMetadataResource, ClaimsBasedIdentity } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import { some, find } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { SimplePagingList } from "~/components/PagingList";
import { required } from "~/components/form/Validators";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import IdHelper from "~/utils/IdHelper";
import { Text } from "../form";
import styles from "./style.module.less";
interface AddIdentityState extends DataBaseComponentState {
    identity: ClaimsBasedIdentity;
    query?: string;
    searchResults: ClaimsBasedIdentity[] | null;
    supportsSearch: boolean;
    selectedSearchResultKey?: string;
}
interface AddIdentityProps {
    selectedProvider: IdentityMetadataResource;
    onAddIdentity: (identity: any) => boolean;
}
class IdentityResults extends SimplePagingList<ClaimsBasedIdentity> {
}
class AddIdentityDialog extends DataBaseComponent<AddIdentityProps, AddIdentityState> {
    constructor(props: AddIdentityProps) {
        super(props);
        this.state = {
            identity: this.createIdentity()!,
            supportsSearch: this.supportsSearch(),
            searchResults: null, //when it's an empty list we can show message showing no results
        };
    }
    render() {
        return (<OkDialogLayout onOkClick={this.addIdentity} okButtonDisabled={this.disableAddIdentity()} busy={this.state.busy} errors={this.errors} okButtonLabel={this.state.supportsSearch ? "Select" : "Add"} title={"Add Login"}>
                <h4>External Login Details</h4>

                {this.state.identity ? this.state.supportsSearch ? this.renderSearch() : this.renderControlsForClaim() : <div>There seems to be a problem with the properties on this identity provider</div>}
            </OkDialogLayout>);
    }
    addIdentity = () => {
        if (this.state.selectedSearchResultKey) {
            const identityToAdd = this.state.searchResults?.find((i) => i.Id === this.state.selectedSearchResultKey);
            if (identityToAdd) {
                return this.props.onAddIdentity(identityToAdd);
            }
        }
        return this.props.onAddIdentity(this.state.identity);
    };
    disableAddIdentity() {
        if (this.state.supportsSearch) {
            return !this.state.selectedSearchResultKey;
        }
        const claims = this.state.identity.Claims;
        const claimsValues = Object.keys(claims).map((key, i) => ({
            identifying: claims[key].IsIdentifyingClaim,
            value: claims[key].Value,
        }));
        return !claimsValues.some((c) => c.identifying && !!c.value);
    }
    renderControlsForClaim() {
        const claims = this.state.identity.Claims;
        return Object.keys(claims).map((key, i) => (<div key={i}>
                <Text value={claims[key].Value!} onChange={(v) => this.setClaim(key, v)} label={this.claimLabel(key)} validate={claims[key].IsIdentifyingClaim ? required(`Please enter ${this.claimDescription(key)}`) : undefined}/>
            </div>));
    }
    setClaim = (key: string, value: string) => {
        this.setState((state) => {
            const Claims = {
                ...state!.identity.Claims,
                [key]: {
                    ...state!.identity.Claims[key],
                    Value: value,
                },
            };
            return { identity: { ...state!.identity, Claims } };
        });
    };
    renderSearch() {
        return (<div>
                <h4>Search</h4>
                <div className={styles.inlineSearch}>
                    <Text value={this.state.query!} onChange={(query) => this.setState({ query })} label={"Search"}/>

                    <ActionButton onClick={() => this.search()} label="Search" busyLabel="Searching..." disabled={this.state.busy}/>
                </div>

                {this.state.searchResults !== null &&
                (this.state.searchResults.length === 0 ? (<Callout title="No results were found" type={"information"}>
                            Enter the start of a group name. For example, to find "Domain Users", type "Domain".
                        </Callout>) : (<IdentityResults items={this.state.searchResults} onRow={this.buildSearchResultRow} onRowRedirectUrl={() => null}/>))}
            </div>);
    }
    buildSearchResultRow = (identity: ClaimsBasedIdentity) => {
        const claims = identity.Claims;
        const entries = Object.keys(claims).map((key, i) => {
            const keyAndValue = `${this.claimLabel(key)}: ${claims[key].Value}`;
            return (
            // only show the ones with values
            claims[key].Value && <div key={i}>{claims[key].IsIdentifyingClaim ? <ListTitle>{keyAndValue}</ListTitle> : <span>{keyAndValue}</span>}</div>);
        });
        return (<div className={styles.container} onClick={() => this.setState({ selectedSearchResultKey: identity.Id })}>
                <div className={styles.select}>
                    <BooleanRadioButtonGroup value={identity.Id === this.state.selectedSearchResultKey} onChange={noOp}>
                        <BooleanRadioButton value={true}/>
                    </BooleanRadioButtonGroup>
                </div>
                <div className={styles.claimContent}>{entries}</div>
            </div>);
    };
    createIdentity() {
        if (!this.props.selectedProvider) {
            return null;
        }
        const identity = {
            // Id just to help in UI
            Id: IdHelper.newId(),
            // this structure to support saving
            IdentityProviderName: this.props.selectedProvider.IdentityProviderName,
            Claims: {} as any,
        };
        this.props.selectedProvider.ClaimDescriptors.forEach((d: any) => (identity.Claims[d.Type] = {
            Value: "",
            IsIdentifyingClaim: d.IsIdentifyingClaim,
        }));
        return identity;
    }
    supportsSearch() {
        return this.props.selectedProvider && this.props.selectedProvider.Links && some(this.props.selectedProvider.Links, (v, key) => key === "UserSearch");
    }
    async search() {
        await this.doBusyTask(async () => {
            if (!this.props.selectedProvider.Links) {
                this.setState({ searchResults: [] });
                return;
            }
            const uri = find(this.props.selectedProvider.Links, (v, key) => key === "UserSearch");
            const partialName = this.state.query;
            const result = await repository.ExternalUsers.searchProvider(uri, partialName!);
            this.setState({
                // add some Ids to help with selecting it for return from this popup
                searchResults: result.Identities.map((x: any) => ({ ...x, Id: IdHelper.newId() })) || [],
            });
        });
    }
    // if the claim object can just be built with these properties via createIdentity then they won't be needed
    claimLabel(key: string) {
        return this.findProviderByKey(key).Label;
    }
    claimDescription(key: string) {
        return this.findProviderByKey(key).Description;
    }
    findProviderByKey(key: string) {
        return this.props.selectedProvider && this.props.selectedProvider.ClaimDescriptors && this.props.selectedProvider.ClaimDescriptors.find((d: any) => d.Type === key);
    }
    static displayName = "AddIdentityDialog";
}
export { AddIdentityDialog };
