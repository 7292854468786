import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
interface WelcomeDialogDismissedAnalyticsEvent extends AnalyticsEvent<"Welcome Dialog Dismissed"> {
    "Action Type": "Cancel";
}
export function createWelcomeDialogDismissedAnalyticsEvent(): WelcomeDialogDismissedAnalyticsEvent {
    return {
        eventName: "Welcome Dialog Dismissed",
        "Action Type": "Cancel",
    };
}
interface WelcomeDialogSelectionAnalyticsEvent extends AnalyticsEvent<"Welcome Dialog Selection"> {
    "Action Type": "Select";
    Selection: WelcomeDialogSelection;
}
export function createWelcomeDialogSelectionAnalyticsEvent(selection: WelcomeDialogSelection): WelcomeDialogSelectionAnalyticsEvent {
    return {
        eventName: "Welcome Dialog Selection",
        "Action Type": "Select",
        Selection: selection,
    };
}
type WelcomeDialogSelection = "Create new project" | "Create sample project" | "View projects" | "View samples instance";
interface ChangeWelcomeDialogHelpPanelAnalyticsEvent extends AnalyticsEvent<"Change Welcome Dialog Help Panel"> {
    "Action Type": "View";
    Selection: string;
}
export function createChangeWelcomeDialogHelpPanelAnalyticsEvent(slideKey: string): ChangeWelcomeDialogHelpPanelAnalyticsEvent {
    return {
        eventName: "Change Welcome Dialog Help Panel",
        "Action Type": "View",
        Selection: slideKey,
    };
}
interface ViewWelcomeDialogAnalyticsEvent extends AnalyticsEvent<"View Welcome Dialog"> {
    "Displayed Cards": string;
    "Resource Type": "Welcome Dialog";
}
export function createViewWelcomeDialogViewAnalyticsEvent(cards: string[]): ViewWelcomeDialogAnalyticsEvent {
    return {
        eventName: "View Welcome Dialog",
        "Displayed Cards": cards.join(", "),
        "Resource Type": "Welcome Dialog",
    };
}
