import { Tooltip } from "@octopusdeploy/design-system-components";
import * as React from "react";
import WarningIcon from "~/components/WarningIcon";
import StringHelper from "~/utils/StringHelper";
import styles from "./style.module.less";
interface ToolTipWarningProps {
    warningMessages?: ReadonlyArray<string>;
    onClick?: () => void;
}
const ToolTipMessages: React.StatelessComponent<ToolTipWarningProps> = (props) => {
    const buildCompositeMessageFromItems = (items: ReadonlyArray<string>): string => {
        return items
            .map((text, index) => {
            if (index === 0) {
                return StringHelper.capitalizeFirstLetter(text);
            }
            return text;
        })
            .join(" and ");
    };
    const warningsDiv = props.warningMessages && props.warningMessages.length > 0 && (<div onClick={() => props.onClick && props.onClick()}>
            <Tooltip content={buildCompositeMessageFromItems(props.warningMessages)}>
                <WarningIcon />
            </Tooltip>
        </div>);
    return <div className={styles.container}>{warningsDiv}</div>;
};
ToolTipMessages.displayName = "ToolTipMessages"
export default ToolTipMessages;
