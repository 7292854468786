import { CodeIcon } from "@octopusdeploy/design-system-components";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission, ProcessType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { isVersionControlledProcess, processScopedEditPermission } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { GitCallouts } from "~/areas/projects/components/VersionControl/GitCallouts";
import { useProjectContext } from "~/areas/projects/context";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import StringHelper from "~/utils/StringHelper";
import { LastPublishedChip } from "../../Runbooks/LastPublishedChip";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
import PublishButton, { usePublishedRunbookSnapshotSetup } from "../../Runbooks/PublishButton";
import ContextualRunNowButton from "../../Runbooks/RunNowButton";
import { useOptionalRunbookContext, useRunbookContext } from "../../Runbooks/RunbookContext";
import ContextAddStepNavigationButton from "../../Steps/ContextAddStepNavigationButton";
import { GitRepositoryErrorCallout } from "../../Variables/GitRepositoryErrorCallout";
import { useDispatchSwitchToCodeEditor } from "../CodeEditor/ProcessCodeEditorAnalytics";
import { isProcessCodeEditorEnabled, useProcessCodeEditorState } from "../CodeEditor/isProcessCodeEditorEnabled";
import { useProcessContext } from "../Contexts/ProcessContext";
import { ProcessPaperLayout } from "./ProcessPaperLayout";
interface ProcessContextPaperLayoutPropsInternal {
    processType: ProcessType;
    doBusyTask: DoBusyTask;
}
type ProcessContextPaperLayoutProps = ProcessContextPaperLayoutPropsInternal & Level2PageLayoutProps & RouteComponentProps<{
    projectSlug: string;
}>;
const ProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps> = ({ children, doBusyTask, ...rest }) => {
    const processContext = useProcessContext();
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const hasSteps = processContext.selectors.hasSteps();
    const runbookContext = useOptionalRunbookContext();
    const runbookName = runbookContext?.state.runbook?.Name ?? StringHelper.ellipsis;
    React.useEffect(() => {
        projectContext.actions.refreshGitVariableErrors();
        // We only want to check that the variables when this component is created. No need for any dependencies to trigger a re-check.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (rest.processType === ProcessType.Runbook) {
        return (<RunbooksProcessContextPaperLayout title={runbookName} hasSteps={hasSteps} project={project} doBusyTask={doBusyTask} {...rest}>
                <GitCallouts isRunbooksPage/>
                {children}
            </RunbooksProcessContextPaperLayout>);
    }
    else {
        return (<DeploymentProcessContextPaperLayout title={"Process"} project={project} doBusyTask={doBusyTask} {...rest}>
                <GitCallouts />
                {children}
            </DeploymentProcessContextPaperLayout>);
    }
};
ProcessContextPaperLayout.displayName = "ProcessContextPaperLayout"
export const EnhancedProcessContextPaperLayout = withRouter(ProcessContextPaperLayout);
const DeploymentProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps & {
    project: ProjectResource;
}> = (props) => {
    const processContext = useProcessContext();
    const projectContext = useProjectContext();
    const hasSteps: boolean = processContext.selectors.hasSteps();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const dispatchSwitchToCodeEditor = useDispatchSwitchToCodeEditor();
    const pageActions: PageAction[] = [];
    const isCodeEditorEnabled = isProcessCodeEditorEnabled(props.project, props.processType);
    const { isProcessCodeEditorEnabledByDefault, enableProcessCodeEditorByDefault } = useProcessCodeEditorState();
    if (isCodeEditorEnabled && isProcessCodeEditorEnabledByDefault) {
        // The process code editor is enabled by default, kick out to that route
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <InternalRedirect to={links.branchDeploymentProcessCodePage.generateUrl({ spaceId: props.project.SpaceId, projectSlug: props.project.Slug, branchName: projectContext.state.gitRef!.CanonicalName })}/>;
    }
    if (isCodeEditorEnabled) {
        const switchToCodeViewButton: PageAction = {
            type: "navigate",
            buttonType: "secondary",
            label: "Code",
            extraContext: "Switch to the code editor to edit the OCL for your deployment process directly",
            icon: <CodeIcon />,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            path: links.branchDeploymentProcessCodePage.generateUrl({ spaceId: props.project.SpaceId, projectSlug: props.project.Slug, branchName: projectContext.state.gitRef!.CanonicalName }),
            onClick: () => {
                enableProcessCodeEditorByDefault();
                dispatchSwitchToCodeEditor();
            },
            hasPermissions: isAllowed({ permission: Permission.ProcessView, project: props.project.Id, tenant: "*" }),
        };
        pageActions.push(switchToCodeViewButton);
    }
    if (hasSteps || props.project?.IsVersionControlled) {
        const createReleaseButton: PageAction = {
            type: "navigate",
            buttonType: "secondary",
            label: "Create Release",
            path: links.createReleasePage.generateUrl({ spaceId: props.project.SpaceId, projectSlug: props.project.Slug }),
            onClick: () => dispatchAction("Create a release", { resource: "Create Release", action: Action.Add }),
            hasPermissions: isAllowed({ permission: Permission.ReleaseCreate, project: props.project.Id, tenant: "*" }),
        };
        pageActions.push(createReleaseButton);
    }
    if (processContext.selectors.hasSteps()) {
        pageActions.push(getContextAddStepNavigationPageAction(props.processType, props.project.Id));
    }
    return (<ProcessPaperLayout pageActions={pageActions} {...props}>
            {props.children}
        </ProcessPaperLayout>);
};
DeploymentProcessContextPaperLayout.displayName = "DeploymentProcessContextPaperLayout"
const RunbooksProcessContextPaperLayout: React.FC<ProcessContextPaperLayoutProps & {
    project: ProjectResource;
    hasSteps: boolean;
    doBusyTask: DoBusyTask;
}> = ({ project, hasSteps, doBusyTask, ...props }) => {
    const runbooksContext = useRunbookContext();
    const runbook = runbooksContext.state.runbook;
    if (!runbook) {
        throw Error("runbook value should exist");
    }
    const publishedRunbookAndTemplate = usePublishedRunbookSnapshotSetup(doBusyTask, runbook);
    const publishedRunbookSnapshot = publishedRunbookAndTemplate?.publishedRunbookSnapshot;
    const runbookRunTemplate = publishedRunbookAndTemplate?.runbookRunTemplate;
    const isRunbookRunTemplateModified = runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
    const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>;
    const processContext = useProcessContext();
    const runNowPageAction: PrimaryPageAction = {
        type: "custom",
        key: "Run Now",
        content: <ContextualRunNowButton spaceId={runbook.SpaceId} isDisabled={!hasSteps}/>,
        hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, wildcard: true }),
    };
    const publishAction: PageAction = {
        type: "custom",
        key: "Publish",
        content: <PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified}/>,
        hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
    };
    const pageActions: PageAction[] = [...(isVersionControlledProcess(project, processContext.state.processType) ? [] : [publishAction]), getContextAddStepNavigationPageAction(props.processType, project.Id)];
    return (<RunbooksPaperLayout title={runbooksContext.state.runbook?.Name} titleChip={titleChip} breadcrumbsItems={[{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }]} primaryAction={runNowPageAction} pageActions={pageActions} {...props}>
            <GitRepositoryErrorCallout calloutType={"danger"} additionalIntroText="The runbook can not be published until the error is resolved."></GitRepositoryErrorCallout>
            {props.children}
        </RunbooksPaperLayout>);
};
RunbooksProcessContextPaperLayout.displayName = "RunbooksProcessContextPaperLayout"
function getContextAddStepNavigationPageAction(processType: ProcessType, projectId: string): PageAction {
    return {
        type: "custom",
        key: "Context Add Step Navigation",
        content: <ContextAddStepNavigationButton />,
        hasPermissions: isAllowed({
            permission: processScopedEditPermission(processType),
            project: projectId,
            wildcard: true,
        }),
    };
}
