/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import Task, { BuiltInTask } from "./Task";
interface TaskPageProps {
    taskId: string;
}
interface TaskPageState extends DataBaseComponentState {
    task?: TaskResource<any>;
    redirectTo?: LinkHref;
}
export class TaskPage extends DataBaseComponent<TaskPageProps, TaskPageState> {
    constructor(props: TaskPageProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.props.taskId, { spaces: ["all"] });
            if (task.Name === BuiltInTask.Deploy && task.Arguments.DeploymentId) {
                this.setState({ redirectTo: links.deploymentToProjectTaskRedirect.generateUrl({ spaceId: task.SpaceId!, deploymentId: task.Arguments.DeploymentId }) });
                return;
            }
            if (task.Name === BuiltInTask.RunbookRun && task.Arguments.RunbookRunId) {
                this.setState({ redirectTo: links.runbookRunRedirect.generateUrl({ spaceId: task.SpaceId!, runbookRunId: task.Arguments.RunbookRunId }), task });
                return;
            }
            if (task.Name === BuiltInTask.ExportProjects) {
                this.setState({ redirectTo: links.exportProjectTaskPage.generateUrl({ spaceId: task.SpaceId!, taskId: task.Id }), task });
                return;
            }
            if (task.Name === BuiltInTask.ImportProjects) {
                this.setState({ redirectTo: links.importProjectTaskPage.generateUrl({ spaceId: task.SpaceId!, taskId: task.Id }), task });
                return;
            }
            if (task.Name === BuiltInTask.BulkDeployment) {
                const project = await repository.Projects.get(task.ProjectId!);
                this.setState({ redirectTo: links.projectTaskDetailsPage.generateUrl({ spaceId: task.SpaceId!, projectSlug: project.Slug, taskId: task.Id }), task });
                return;
            }
            this.setState({ task });
        });
    }
    render() {
        const { redirectTo, task } = this.state;
        if (redirectTo) {
            return <InternalRedirect to={redirectTo} push={false}/>;
        }
        if (!task) {
            return (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>);
        }
        return (<main>
                <div className={containerStyles}>
                    <Task task={task} taskId={this.props.taskId} delayRender={() => task == null} defaultBreadcrumbItems={[{ label: "Tasks", pageUrl: links.tasksPage.generateUrl() }]} showBreadcrumbBackIcon={true}/>
                </div>
            </main>);
    }
    static displayName = "TaskPage";
}
const containerStyles = css({
    margin: space[32],
});
