/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { EnvironmentResource, JiraServiceManagementChangeRequestSettings, ProjectResource, ServiceNowChangeRequestSettings } from "@octopusdeploy/octopus-server-client";
import { ChangeRequestSettingsType, SettingsIdValues } from "@octopusdeploy/octopus-server-client";
const itmsProviderIds: string[] = [SettingsIdValues.JiraServiceManagementIntegration, SettingsIdValues.ServiceNowIntegration];
interface ServiceNowEnvironmentSettings {
    ExtensionId: "servicenow-integration";
    Values: {
        ServiceNowChangeControlled: boolean;
    };
}
export interface ServiceNowProjectSettings {
    ExtensionId: "servicenow-integration";
    Values: {
        ServiceNowChangeControlled: boolean;
        StandardChangeTemplateName?: string;
    };
}
interface JiraServiceManagementEnvironmentSettings {
    ExtensionId: "jiraservicemanagement-integration";
    Values: {
        JiraServiceManagementChangeControlled: boolean;
    };
}
export interface JiraServiceManagementProjectSettings {
    ExtensionId: "jiraservicemanagement-integration";
    Values: {
        JiraServiceManagementChangeControlled: boolean;
    };
}
type ChangeRequestSettings = ServiceNowChangeRequestSettings | JiraServiceManagementChangeRequestSettings | undefined;
export function getChangeRequestSettings(changeRequestSettings: ChangeRequestSettings) {
    if (!changeRequestSettings)
        return {};
    switch (changeRequestSettings.Type) {
        case ChangeRequestSettingsType.ServiceNow:
            return { changeRequestNumber: changeRequestSettings.ChangeRequestNumber, isEmergencyChange: changeRequestSettings.IsEmergencyChange };
        case ChangeRequestSettingsType.JiraServiceManagement:
            return { changeRequestNumber: changeRequestSettings.IssueNumber };
        default:
            return {};
    }
}
export function getEnabledProjectChangeRequestSettings(selectedEnvironment: EnvironmentResource[], project: ProjectResource): (ServiceNowProjectSettings | JiraServiceManagementProjectSettings | undefined)[] {
    const changeControlledEnvironments = getChangeControlledEnvironments(selectedEnvironment);
    if (changeControlledEnvironments.length < 1)
        return [];
    const projectSettings = isProjectChangeControlled(project);
    return projectSettings;
}
export function getChangeControlledEnvironments(environments: EnvironmentResource[]): EnvironmentResource[] {
    return environments.filter((e) => e.ExtensionSettings.filter((s) => itmsProviderIds.includes(s.ExtensionId)).some((s) => {
        switch (s.ExtensionId) {
            case SettingsIdValues.ServiceNowIntegration:
                return s.Values.ServiceNowChangeControlled;
            case SettingsIdValues.JiraServiceManagementIntegration:
                return s.Values.JiraServiceManagementChangeControlled;
            default:
                return false;
        }
    }));
}
export function isProjectChangeControlled(project: ProjectResource) {
    return project.ExtensionSettings.filter((s) => itmsProviderIds.includes(s.ExtensionId))
        .map((s) => {
        switch (s.ExtensionId) {
            case SettingsIdValues.ServiceNowIntegration:
                if (s.Values.ServiceNowChangeControlled) {
                    return s as ServiceNowProjectSettings;
                }
                break;
            case SettingsIdValues.JiraServiceManagementIntegration:
                if (s.Values.JiraServiceManagementChangeControlled) {
                    return s as JiraServiceManagementProjectSettings;
                }
                break;
        }
    })
        .filter((s) => s !== undefined);
}
