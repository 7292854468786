import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { required, Text, Note } from "~/components/form";
interface AddEnvironmentsDialogContentProps {
    saveDone: (environment: EnvironmentResource) => Promise<void>;
}
interface AddEnvironmentsDialogContentPropsInternal extends AddEnvironmentsDialogContentProps {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
}
interface AddEnvironmentsDialogContentState extends DataBaseComponentState {
    name: string;
    description: string;
    useGuidedFailure: boolean;
    newEnvironmentId: string;
}
class AddEnvironmentsDialogContent extends DataBaseComponent<AddEnvironmentsDialogContentPropsInternal, AddEnvironmentsDialogContentState> {
    constructor(props: AddEnvironmentsDialogContentPropsInternal) {
        super(props);
        this.state = {
            name: "",
            description: "",
            useGuidedFailure: false,
            newEnvironmentId: "",
        };
    }
    async save() {
        return this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Environment",
            };
            await this.props.trackAction("Save Environment", ev, async () => {
                const result = await repository.Environments.create({
                    Name: this.state.name,
                    Description: this.state.description,
                    UseGuidedFailure: this.state.useGuidedFailure,
                    AllowDynamicInfrastructure: true,
                    SortOrder: -1, // API automatically assigns this value if <= 0.
                    ExtensionSettings: [],
                }, {});
                this.setState({
                    newEnvironmentId: result.Id,
                });
                await this.props.saveDone(result);
            });
        });
    }
    render() {
        return (<SaveDialogLayout title={"Add Environment"} busy={this.state.busy} errors={this.errors} savePermission={{ permission: Permission.EnvironmentCreate, environment: "*" }} onSaveClick={() => this.save()} onCancelClick={() => {
                this.props.dispatchAction("Cancel Adding Environment", { resource: "Environment", action: Action.Cancel });
                return true; // Return true so the dialog will auto-close still.
            }}>
                <Text label="New environment name" value={this.state.name} onChange={this.handleNameChange} autoFocus={true} validate={required("Please enter an environment name")}/>
                <Note>
                    Examples include:{" "}
                    <a href="#" onClick={(e) => this.setEnvironmentName(e, "Development")}>
                        Development
                    </a>
                    ,
                    <a href="#" onClick={(e) => this.setEnvironmentName(e, "Test")}>
                        {" "}
                        Test
                    </a>{" "}
                    and
                    <a href="#" onClick={(e) => this.setEnvironmentName(e, "Production")}>
                        {" "}
                        Production
                    </a>
                </Note>
            </SaveDialogLayout>);
    }
    private setEnvironmentName(e: React.MouseEvent<Element, MouseEvent>, name: string) {
        e.preventDefault();
        this.setState({ name });
    }
    private handleNameChange = (value: string) => {
        this.setState({ name: value });
    };
    static displayName = "AddEnvironmentsDialogContent";
}
export function AddEnvironmentsDialog(props: AddEnvironmentsDialogContentProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();
    return <AddEnvironmentsDialogContent {...props} dispatchAction={dispatchAction} trackAction={trackAction}/>;
}
