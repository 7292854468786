import type { Moment } from "moment";
import moment from "moment";
export default function rollForward(date: Moment, minutes: number): Moment {
    const forward = moment(date).add(minutes, "minutes");
    if (forward.minute() > 45) {
        forward.minute(0);
        forward.hour(forward.hour() + 1);
    }
    else if (forward.minute() > 30) {
        forward.minute(45);
    }
    else if (forward.minute() > 15) {
        forward.minute(30);
    }
    else if (forward.minute() > 0) {
        forward.minute(15);
    }
    return forward;
}
