/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { ServicePort } from "~/components/Actions/kubernetes/kubernetesServiceComponent";
import { KubernetesNameRegex, NumberRegex } from "~/components/Actions/kubernetes/kubernetesValidation";
import isBound from "~/components/form/BoundField/isBound";
import Note from "../../../primitiveComponents/form/Note/Note";
import { BoundSelect } from "../../../primitiveComponents/form/Select/Select";
import { DataBaseComponent } from "../../DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState } from "../../DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import { VariableLookupAutoComplete } from "../../form/VariableLookupAutoComplete";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { ContainerDetails } from "./kubernetesDeployContainersAction";
interface PortState extends DataBaseComponentState {
    servicePort: ServicePort;
    project?: ProjectResource;
}
interface PortProps {
    servicePort: ServicePort;
    serviceType: string;
    localNames: string[];
    projectId: string;
    containers: ContainerDetails[];
    onAdd(Binding: ServicePort): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}
class PortDialog extends DataBaseComponent<PortProps, PortState> {
    constructor(props: PortProps) {
        super(props);
        this.state = {
            servicePort: null!,
            project: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : null!;
            this.setState({
                servicePort: this.props.servicePort,
                project,
            });
        });
    }
    save = () => {
        let valid = true;
        const binding = this.state.servicePort;
        if (this.state.servicePort.name && this.state.servicePort.name.trim() && !isBound(this.state.servicePort.name) && !KubernetesNameRegex.exec(this.state.servicePort.name.trim())) {
            this.setValidationErrors("The service names must be blank, or have names that consist of lower case alphanumeric characters or '-', and must start and end with an alphanumeric character.", {
                ServicePortName: "The service names must be blank, or have names that consist of lower case alphanumeric characters or '-', and must start and end with an alphanumeric character.",
            });
            valid = false;
        }
        if (!this.state.servicePort.port || !this.state.servicePort.port.toString().trim()) {
            this.setValidationErrors("A port is required", {
                ServicePortPort: "A port is required",
            });
            valid = false;
        }
        if (this.state.servicePort.targetPort &&
            !isBound(this.state.servicePort.targetPort.toString().trim()) &&
            !isNaN(parseInt(this.state.servicePort.targetPort.toString().trim(), 10)) &&
            !(parseInt(this.state.servicePort.targetPort.toString().trim(), 10) >= 1 && parseInt(this.state.servicePort.targetPort.toString().trim(), 10) <= 65535)) {
            this.setValidationErrors("The target port must be a number between 1 and 65535, or a port name", {
                ServicePortTargetPort: "The target port must be a number between 1 and 65535, or a port name",
            });
            valid = false;
        }
        if (this.state.servicePort.port &&
            this.state.servicePort.port.toString().trim() &&
            !isBound(this.state.servicePort.port.toString()) &&
            !(NumberRegex.exec(this.state.servicePort.port.toString().trim()) && parseInt(this.state.servicePort.port.toString().trim(), 10) >= 1 && parseInt(this.state.servicePort.port.toString().trim(), 10) <= 65535)) {
            this.setValidationErrors("Port numbers must be between 1 and 65535", {
                ServicePortPort: "Port numbers must be between 1 and 65535",
            });
            valid = false;
        }
        if (this.props.serviceType !== "ClusterIP" &&
            this.state.servicePort.nodePort &&
            this.state.servicePort.nodePort.toString().trim() &&
            !isBound(this.state.servicePort.nodePort.toString()) &&
            !(NumberRegex.exec(this.state.servicePort.nodePort.toString().trim()) && parseInt(this.state.servicePort.nodePort.toString().trim(), 10) >= 1 && parseInt(this.state.servicePort.nodePort.toString().trim(), 10) <= 65535)) {
            this.setValidationErrors("Node port numbers must be between 1 and 65535", {
                ServicePortNodePort: "Node port numbers must be between 1 and 65535",
            });
            valid = false;
        }
        if (valid) {
            return this.props.onAdd(binding);
        }
        return valid;
    };
    render() {
        return (<OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title={"Add Service Port"}>
                {this.state.servicePort && (<div>
                        <VariableLookupText localNames={this.props.localNames} value={this.state.servicePort.name} error={this.getFieldError("ServicePortName")} onChange={(x) => this.setPortState({ name: x })} label="Name"/>
                        <Note>The optional name of the port. This name can be referenced in the ingress path.</Note>
                        {this.props.containers.length !== 0 ? (<VariableLookupAutoComplete name="KubernetesServicePortPort" localNames={this.props.localNames} placeholder="Port" value={this.state.servicePort.port} error={this.getFieldError("ServicePortPort")} getOptions={this.getPortOptions} onChange={(x) => this.setPortState({ port: x })} allowAnyTextValue={true} label="Port"/>) : (<VariableLookupText localNames={this.props.localNames} value={this.state.servicePort.port} error={this.getFieldError("ServicePortPort")} onChange={(x) => this.setPortState({ port: x })} label="Port"/>)}
                        <Note>The port internal Kubernetes workloads use to access the service.</Note>
                        {this.props.containers.length !== 0 ? (<VariableLookupAutoComplete name="KubernetesServicePortTargetPort" localNames={this.props.localNames} value={this.state.servicePort.targetPort} placeholder="Target Port" getOptions={this.getTargetPortOptions} onChange={(x) => this.setPortState({ targetPort: x })} error={this.getFieldError("ServicePortTargetPort")} allowAnyTextValue={true} label="Target Port"/>) : (<VariableLookupText localNames={this.props.localNames} value={this.state.servicePort.targetPort} onChange={(x) => this.setPortState({ targetPort: x })} error={this.getFieldError("ServicePortTargetPort")} label="Target Port"/>)}
                        <Note>
                            An optional value set to a port exposed by the container. This can be the name of the port, or the port number. If left blank, it will default to the value of the <code>Port</code> above.
                        </Note>
                        {(this.props.serviceType === "NodePort" || this.props.serviceType === "LoadBalancer") && (<div>
                                <VariableLookupText localNames={this.props.localNames} value={this.state.servicePort.nodePort} error={this.getFieldError("ServicePortNodePort")} onChange={(x) => this.setPortState({ nodePort: x })} label="Node Port"/>
                                <Note>An optional value that defines the publicly accessible port exposed on all nodes used to access the service. If left blank, Kubernetes will assign a port.</Note>
                            </div>)}
                        <BoundSelect variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"TCP"} value={this.state.servicePort.protocol} onChange={(x) => this.setPortState({ protocol: x! })} items={[
                    { value: "TCP", text: "TCP" },
                    { value: "UDP", text: "UDP" },
                ]} placeholder="Protocol" label="Protocol"/>
                        <Note>The protocol used by the service.</Note>
                    </div>)}
            </OkDialogLayout>);
    }
    private setPortState<K extends keyof ServicePort>(state: Pick<ServicePort, K>, callback?: () => void) {
        this.setChildState1("servicePort", state);
    }
    /**
     * The target port is the port that is open on the container. This can be the port number, or
     * the port name.
     * @param searchText
     */
    private getTargetPortOptions = async (searchText: string) => {
        const results = _.chain(this.props.containers)
            .flatMap((c) => c.Ports)
            .flatMap((p) => [p.key, p.value])
            .filter((v) => !!v)
            .filter((v) => !searchText || v.toLowerCase().includes(searchText.toLowerCase()))
            .value();
        const itemsToTake = 7;
        return {
            items: results.slice(0, itemsToTake).map((f) => ({ Id: f, Name: f })),
            containsAllResults: results.length <= itemsToTake,
        };
    };
    /**
     * The port is the port that is open on the service. Target port also defaults to the value of the port
     * if it is not specified. Port can only be a number.
     * @param searchText
     */
    private getPortOptions = async (searchText: string) => {
        const results = _.chain(this.props.containers)
            .flatMap((c) => c.Ports)
            .flatMap((p) => p.value)
            .filter((v) => !!v)
            .filter((v) => !searchText || v.toLowerCase().includes(searchText.toLowerCase()))
            .value();
        const itemsToTake = 7;
        return {
            items: results.slice(0, itemsToTake).map((f) => ({ Id: f, Name: f })),
            containsAllResults: results.length <= itemsToTake,
        };
    };
    static displayName = "PortDialog";
}
export default PortDialog;
