/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Tooltip } from "@octopusdeploy/design-system-components";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links, TenantVariableActiveTab } from "@octopusdeploy/portal-routes";
import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { LibraryVariable } from "../../../components/Images/ReactPortal/LibraryVariable";
import { LibraryVariableTemplate } from "../../../components/Images/ReactPortal/LibraryVariableTemplate";
import { ProjectVariable } from "../../../components/Images/ReactPortal/ProjectVariable";
import { ProjectVariableTemplate } from "../../../components/Images/ReactPortal/ProjectVariableTemplate";
import styles from "./style.module.less";
export interface TenantProjectVariableSourceProps {
    spaceId: string;
    tenantId: string;
    tenantName: string;
    type: "project";
}
export interface TenantLibraryVariableSetSourceProps {
    spaceId: string;
    tenantId: string;
    tenantName: string;
    type: "library";
}
export interface LibraryVariableSetSourceProps {
    spaceId: string;
    variableSetName: string;
    variableSetId: string;
}
export interface ProjectVariableSourceProps {
    spaceId: string;
    projectName: string;
    projectId: string;
}
export type ValueSource = TenantProjectVariableSourceProps | TenantLibraryVariableSetSourceProps | LibraryVariableSetSourceProps | ProjectVariableSourceProps;
interface SourceLinkProps {
    source: ValueSource;
}
const SourceLink: React.SFC<SourceLinkProps> = (props: SourceLinkProps) => {
    return (<InternalLink to={getLinkLocation(props.source)}>
            <div className={styles.tooltipWrapper}>
                <Tooltip content={sourceLinkDescription(props.source)}>
                    <div className={styles.link}>
                        {getIcon(props.source)}
                        <span className={styles.text}>{getSourceLinkName(props.source)}</span>
                    </div>
                </Tooltip>
            </div>
        </InternalLink>);
};
SourceLink.displayName = "SourceLink"
function sourceLinkDescription(source: ValueSource): string {
    if (isProjectVariableSource(source)) {
        return `Project Variables: ${source.projectName}`;
    }
    else if (isLibraryVariableSetSource(source)) {
        return `Variable Set: ${source.variableSetName}`;
    }
    else if (isTenantProjectVariableSource(source)) {
        return `Tenant Project Variables: ${source.tenantName}`;
    }
    else {
        // Tenant variable set case
        return `Tenant Common Variables: ${source.tenantName}`;
    }
}
export function getSourceLinkName(source: ValueSource): string {
    if (isProjectVariableSource(source)) {
        return source.projectName;
    }
    else if (isLibraryVariableSetSource(source)) {
        return source.variableSetName;
    }
    else if (isTenantProjectVariableSource(source)) {
        return source.tenantName;
    }
    else {
        // Tenant variable set case
        return source.tenantName;
    }
}
function getLinkLocation(source: ValueSource): LinkHref {
    if (isProjectVariableSource(source)) {
        return links.variablesPage.generateUrl({ spaceId: source.spaceId, projectSlug: source.projectId });
    }
    else if (isLibraryVariableSetSource(source)) {
        return links.editVariableSetPage.generateUrl({ spaceId: source.spaceId, variableSetId: source.variableSetId });
    }
    else if (isTenantProjectVariableSource(source)) {
        return links.tenantVariablesPage.generateUrl({ spaceId: source.spaceId, tenantId: source.tenantId }, { activeTab: TenantVariableActiveTab.ProjectVariables });
    }
    else {
        // Tenant variable set case
        return links.tenantVariablesPage.generateUrl({ spaceId: source.spaceId, tenantId: source.tenantId }, { activeTab: TenantVariableActiveTab.CommonVariables });
    }
}
function getIcon(source: ValueSource): JSX.Element {
    if (isProjectVariableSource(source)) {
        return <ProjectVariable className={styles.icon}/>;
    }
    else if (isLibraryVariableSetSource(source)) {
        return <LibraryVariable className={styles.icon}/>;
    }
    else if (isTenantProjectVariableSource(source)) {
        return <ProjectVariableTemplate className={styles.icon}/>;
    }
    else {
        // Tenant variable set case
        return <LibraryVariableTemplate className={styles.icon}/>;
    }
}
export function isProjectVariableSource(source: ValueSource): source is ProjectVariableSourceProps {
    return (source as ProjectVariableSourceProps).projectId !== undefined;
}
export function isLibraryVariableSetSource(source: ValueSource): source is LibraryVariableSetSourceProps {
    return (source as LibraryVariableSetSourceProps).variableSetId !== undefined;
}
export function isTenantProjectVariableSource(source: ValueSource): source is TenantProjectVariableSourceProps {
    return (source as TenantProjectVariableSourceProps).type === "project";
}
export default SourceLink;
