import type { CommonInputProps, SectionComponent } from "@octopusdeploy/step-ui";
import React from "react";
import type { RenderedComponentAndSummary } from "~/components/StepPackageEditor/EditStepPackageInputs";
import { getInputContentReactKey, Input } from "~/components/StepPackageEditor/Inputs/Components/Input";
import { FormSectionHeading } from "~/components/form/Sections";
interface SectionProps<StepInputs, TInputComponents> {
    section: SectionComponent<TInputComponents>;
    isExpandedByDefault: boolean;
    getRenderedComponentAndSummary(component: TInputComponents): RenderedComponentAndSummary;
}
export function Section<StepInputs, TInputComponents extends CommonInputProps>(props: SectionProps<StepInputs, TInputComponents>) {
    return (<>
            <FormSectionHeading title={props.section.title}/>
            {props.section.content.map((content) => (<Input<StepInputs, TInputComponents> key={getInputContentReactKey(content)} content={content} isExpandedByDefault={props.isExpandedByDefault} getRenderedComponentAndSummary={props.getRenderedComponentAndSummary}/>))}
        </>);
}
