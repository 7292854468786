import * as React from "react";
import { CommonInsightsReportHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsDeploymentDurationOverview, CommonInsightsDeploymentDurationTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsDeploymentDurationViews";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration } from "../../../Registry/pageHelpRegistry";
export const InsightsReportDeploymentDurationRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.insights.report.deploymentDuration,
    renderOverview: (props) => (<>
            <CommonInsightsDeploymentDurationOverview /> <CommonInsightsReportHelp />
        </>),
    renderTopics: (props) => <CommonInsightsDeploymentDurationTopics />,
};
