import type { ConnectTenantsToProjectRequest, EnvironmentResource, ProjectResource, RunbookResource, TenantsFilterQueryParameters, TenantsOverviewTenant } from "@octopusdeploy/octopus-server-client";
import { Repository, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import pluralize from "pluralize";
import * as React from "react";
import ConnectionWizardDialogLayout from "~/areas/projects/components/ProjectTenants/ConnectionWizardDialogLayout";
import type { NamedItemWithLogo } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import PanelSelector, { SelectItemType } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import SelectEnvironments from "~/areas/projects/components/ProjectTenants/SelectEnvironments";
import type { NamedItemWithLogoAndEnvironments } from "~/areas/projects/components/ProjectTenants/SelectEnvironments";
import { createTagSetFilters, FilterOption, getMultiSelectValue } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import type { Filter, FilterValue } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { client, repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DataLoader from "~/components/DataLoader/index";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import type { TenantTagsLookup } from "~/components/tenantTagsets";
import * as tenantTagSets from "~/components/tenantTagsets";
export interface ConnectMultipleTenantsDialogProps {
    project: ProjectResource;
    onConnected: (numberOfTenantsConnected: number) => void;
}
export default function ConnectMultipleTenantsDialog(props: ConnectMultipleTenantsDialogProps) {
    return (<InitialDataLoader load={loadInitialData.bind(null, props.project)} operationName="ConnectTenant" renderWhenLoaded={(data) => <ConnectMultipleTenants {...props} {...data}/>} renderAlternate={({ busy, errors }) => <SaveDialogLayout title={`Connect Tenants to ${props.project.Name}`} busy={busy} errors={errors} onSaveClick={() => Promise.resolve(true)}/>}/>);
}
interface InitialDataProps {
    tenants: AvailableTenants;
    runBooks: RunbookResource[];
    tenantTags: TenantTagsLookup;
}
const InitialDataLoader = DataLoader<InitialDataProps>();
const loadInitialData = async (project: ProjectResource): Promise<InitialDataProps> => {
    const [availableTenants, runBooks, tenantTags] = await Promise.all([loadAllAvailableTenants(project.Id), repository.Projects.getRunbooks(project, { take: Repository.takeAll }), tenantTagSets.loadTenantTagsLookup()]);
    return { tenants: availableTenants, runBooks: runBooks.Items, tenantTags };
};
interface ConnectMultipleTenantsProps extends ConnectMultipleTenantsDialogProps, InitialDataProps {
}
interface ConnectMultipleTenantsState extends DataBaseComponentState {
    availableTenants: AvailableTenants;
    selectedTenantEnvironments: NamedItemWithLogoAndEnvironments[];
    filter: SelectTenantsFilterParameters;
    availableEnvironments: Map<string, EnvironmentResource>;
}
class ConnectMultipleTenants extends DataBaseComponent<ConnectMultipleTenantsProps, ConnectMultipleTenantsState> {
    constructor(props: ConnectMultipleTenantsProps) {
        super(props);
        this.state = {
            availableTenants: props.tenants,
            selectedTenantEnvironments: [],
            filter: { name: "", includeTags: [], excludeTags: [] },
            availableEnvironments: new Map(),
        };
    }
    canConnect = () => this.state.selectedTenantEnvironments.length > 0;
    connect = async () => this.doBusyTask(async () => {
        const request: ConnectTenantsToProjectRequest = {
            TenantEnvironments: this.state.selectedTenantEnvironments.map((t) => ({ TenantId: t.Id, Environments: t.Environments.map((e) => e.Id) })),
        };
        await repository.Projects.connectTenants(request, this.props.project.Id);
        if (this.props.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            const project = await repository.Projects.get(this.props.project.Id);
            client.dispatchEvent({ type: "ProjectModified", project });
        }
        setTimeout(() => this.props.onConnected(this.state.selectedTenantEnvironments.length), 0);
    });
    getFilters = (): Filter[] => {
        return createTagSetFilters(Array.from(this.props.tenantTags.tagSets.values()), this.state.filter.includeTags, this.state.filter.excludeTags);
    };
    filtersChanged = async (filterValues: FilterValue[]): Promise<boolean> => {
        const includeTags = compact(Array.from(this.props.tenantTags.tagSets.values()).flatMap((ts) => getMultiSelectValue(filterValues, ts.Name, FilterOption.In)));
        const excludeTags = compact(Array.from(this.props.tenantTags.tagSets.values()).flatMap((ts) => getMultiSelectValue(filterValues, ts.Name, FilterOption.NotIn)));
        return await this.reloadTenants({ ...this.state.filter, includeTags, excludeTags });
    };
    nameFilterChanged = async (name: string): Promise<boolean> => {
        return await this.reloadTenants({ ...this.state.filter, name });
    };
    reloadTenants = async (filter: SelectTenantsFilterParameters) => this.doBusyTask(async () => {
        const filteredTenants = await loadAvailableTenants(this.props.project.Id, filter.name, filter.includeTags, filter.excludeTags);
        const availableTenants: AvailableTenants = { totalCount: this.state.availableTenants.totalCount, ...filteredTenants };
        this.setState({ availableTenants: availableTenants, filter });
    });
    getAvailableEnvironments = async (): Promise<EnvironmentResource[]> => {
        const environments = (await repository.Projects.getAvailableEnvironmentsForProject(this.props.project.Id)).Environments;
        this.setState({ availableEnvironments: environments.reduce((map, env) => map.set(env.Id, env), new Map<string, EnvironmentResource>()) });
        return environments;
    };
    hasFilteredApplied = () => {
        return this.state.filter.name !== "" || this.state.filter.includeTags.length > 0 || this.state.filter.excludeTags.length > 0;
    };
    render() {
        const disabled = !this.canConnect();
        const onTenantsSelected = (tenants: NamedItemWithLogo[]) => this.setState({ selectedTenantEnvironments: tenants.map((t) => ({ ...t, Environments: [] })) });
        const onEnvironmentsSelected = (environmentIds: string[]) => {
            const environments = environmentIds.map((id) => {
                const name = this.state.availableEnvironments.get(id)?.Name;
                if (!name)
                    throw Error(`The environment with ID ${id} could not be found in the available environments lookup.`);
                return { Id: id, Name: name };
            });
            this.setState((prev) => ({ selectedEnvironmentIds: environmentIds, selectedTenantEnvironments: prev.selectedTenantEnvironments.map((t): NamedItemWithLogoAndEnvironments => ({ ...t, Environments: environments })) }));
        };
        return (<ConnectionWizardDialogLayout title={`Connect Tenants to ${this.props.project.Name}`} onSaveClick={this.connect} saveButtonDisabled={disabled} busy={this.state.busy} errors={this.errors} saveButtonLabel={`Connect ${pluralize("Tenant", this.state.selectedTenantEnvironments.length, true)}`} wizardStepNames={["Select tenants", "Select environments"]} selectItemType={SelectItemType.Tenant}>
                <PanelSelector items={this.state.availableTenants} selectedItems={this.state.selectedTenantEnvironments} updateSelectedItems={onTenantsSelected} filters={this.getFilters()} onFilterChanged={this.filtersChanged.bind(this)} onNameFilterChanged={this.nameFilterChanged.bind(this)} selectItemType={SelectItemType.Tenant} isFiltered={this.hasFilteredApplied()} filteredName={this.state.filter.name}/>
                <SelectEnvironments selectedItems={this.state.selectedTenantEnvironments} getAvailableEnvironments={this.getAvailableEnvironments} updateSelectedEnvironments={onEnvironmentsSelected} showUntenantedCallout={this.props.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted} selectItemType={SelectItemType.Tenant} doBusyTask={this.doBusyTask}/>
            </ConnectionWizardDialogLayout>);
    }
    static displayName = "ConnectMultipleTenants";
}
const loadAllAvailableTenants = async (projectId: string): Promise<AvailableTenants> => {
    const tenants = await loadAvailableTenants(projectId, "", [], []);
    return { ...tenants, totalCount: tenants.filteredTotal };
};
const loadAvailableTenants = async (projectId: string, name: string, includedTags: string[], excludedTags: string[]): Promise<FilteredTenants> => {
    const query: TenantsFilterQueryParameters = {
        filterByExcludedProject: projectId,
        filterByName: name,
        filterByTags: includedTags,
        filterByExcludedTags: excludedTags,
        filterByExcludedName: "",
        filterByProject: undefined,
        filterByEnvironment: undefined,
        filterByExcludedEnvironment: undefined,
        includeMissingVariablesStatus: false,
    };
    const response = await repository.Tenants.tenantsOverview(1, Repository.takeAll, query);
    return { filteredTotal: response.FilteredTenantCount ?? response.TotalTenantCount, records: response.Tenants };
};
export interface AvailableTenants {
    totalCount: number;
    filteredTotal: number;
    records: TenantsOverviewTenant[];
}
interface FilteredTenants {
    filteredTotal: number;
    records: TenantsOverviewTenant[];
}
export interface SelectTenantsFilterParameters {
    name: string;
    includeTags: string[];
    excludeTags: string[];
}
