import type { DeploymentActionPackageResource, DeploymentProcessResource } from "@octopusdeploy/octopus-server-client";
import { displayName } from "@octopusdeploy/octopus-server-client";
import { chain } from "lodash";
import React from "react";
import { Select } from "~/components/form";
import type { DeploymentSettingsModel } from "./DeploymentProcessSettings";
interface ReleaseVersionFromPackageSelectorProps {
    versionPackageActions: DeploymentActionPackageResource[];
    versioningStrategyPackage: DeploymentActionPackageResource | undefined;
    setModelProp: <K extends keyof DeploymentSettingsModel>(model: Pick<DeploymentSettingsModel, K>) => void;
    deploymentProcess: DeploymentProcessResource | undefined;
}
export const ReleaseVersionFromPackageSelector: React.FC<ReleaseVersionFromPackageSelectorProps> = (props: ReleaseVersionFromPackageSelectorProps) => {
    const stepIsDisabled = (deploymentAction: string) => {
        const action = chain(props.deploymentProcess?.Steps)
            .flatMap((step) => step.Actions)
            .find((x) => x.Name === deploymentAction)
            .value();
        return action?.IsDisabled ?? false;
    };
    return (<Select value={props.versioningStrategyPackage === null || props.versioningStrategyPackage === undefined ? undefined : displayName(props.versioningStrategyPackage)} onChange={(packageActionIndex) => props.setModelProp({ versioningStrategyPackage: props.versionPackageActions.find((z) => displayName(z) === packageActionIndex) })} items={props.versionPackageActions.map((packageAction) => ({
            value: displayName(packageAction),
            text: displayName(packageAction),
            disabled: stepIsDisabled(packageAction.DeploymentAction),
        }))} label="Versioning package step"/>);
};
ReleaseVersionFromPackageSelector.displayName = "ReleaseVersionFromPackageSelector"
