import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export function CommonLibraryBuildInformationRepositoryOverview() {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    The <ExternalLink href="BuildInfo">build information repository</ExternalLink> lets you upload information from your build server, manually or with the use of a plugin, to Octopus.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
}
