import type { PackageReference } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs, PathToInput, RuntimePackageSelection } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, getPathToInput, isNotBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { PackageSelectorComponent } from "@octopusdeploy/step-ui";
import React from "react";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import { convertFromRuntimePackageSelection, convertToRuntimePackageSelection } from "~/components/StepPackageEditor/Inputs/Components/PackageSelector/PackageSelectionConverters";
import type { StepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import { isProjectStepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
export function getPackageSelectorSummary<StepInputs>(content: PackageSelectorComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessor(content.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        const runtimePackageSelection = convertToRuntimePackageSelection(inputValue);
        if (runtimePackageSelection.feedId === undefined || runtimePackageSelection.packageId === undefined) {
            return "empty";
        }
        return { isDefaultValue: false, value: runtimePackageSelection.packageId };
    }
    // todo-step-ui: Can we improve the typing so that we don't have to handle this case?
    throw new Error("Package selection cannot be bound");
}
interface PackageSelectorProps<StepInputs> {
    configuredStepUIProps: PackageSelectorComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    dependencies: StepInputDependencies;
    getFieldError: (path: PathToInput) => string;
}
function getInputPathForPackageReferenceProperty(pathToPackageReference: PathToInput, propertyName: keyof RuntimePackageSelection) {
    return [...pathToPackageReference, propertyName];
}
export function getErrorPathForPackageReference(component: PackageSelectorComponent): PathToInput[] {
    const inputPath = getPathToInput(component.input);
    return [getInputPathForPackageReferenceProperty(inputPath, "packageId"), getInputPathForPackageReferenceProperty(inputPath, "feedId")];
}
export function StepPackageSelector<StepInputs>(props: PackageSelectorProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, PackageReference>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);
    // todo-step-ui add support for parameterised package names
    if (isNotBoundValue(inputValue)) {
        const packageSelection = convertToRuntimePackageSelection(inputValue);
        return (<PackageSelector packageId={packageSelection.packageId} feedId={packageSelection.feedId} onPackageIdChange={(packageId) => {
                const updatedPackageSelection: RuntimePackageSelection = { ...packageSelection, packageId };
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimePackageSelection(updatedPackageSelection));
                props.setInputs(updatedInputs);
            }} onFeedIdChange={(feedId) => {
                const updatedPackageSelection: RuntimePackageSelection = { ...packageSelection, feedId };
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, convertFromRuntimePackageSelection(updatedPackageSelection));
                props.setInputs(updatedInputs);
            }} projectId={isProjectStepInputDependencies(props.dependencies) ? props.dependencies.projectId : undefined} feeds={props.dependencies.feeds} localNames={isProjectStepInputDependencies(props.dependencies) ? undefined : props.dependencies.localNames} refreshFeeds={props.dependencies.refreshFeeds} feedIdError={props.getFieldError(getInputPathForPackageReferenceProperty(inputPath, "feedId"))} packageIdError={props.getFieldError(getInputPathForPackageReferenceProperty(inputPath, "packageId"))}/>);
        // todo-step-ui: Add PackageDownloadOptions here too, so that you can select the acquisition location
    }
    else {
        // todo-step-ui: Can we improve the typing so that we don't have to handle this case?
        throw new Error("Package selection can't be bound");
    }
}
