import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import BaseComponent from "~/components/BaseComponent";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import Text from "~/primitiveComponents/form/Text/Text";
interface SaveAsDialogState {
    newName: string;
}
interface SaveAsDialogProps {
    originalName: string;
    onSave(newName: string): void;
}
export default class SaveAsDialog extends BaseComponent<SaveAsDialogProps, SaveAsDialogState> {
    constructor(props: SaveAsDialogProps) {
        super(props);
        this.state = {
            newName: this.createNewName(props.originalName),
        };
    }
    componentDidUpdate(prevProps: SaveAsDialogProps) {
        if (this.props.originalName !== prevProps.originalName) {
            this.setState({
                newName: this.createNewName(this.props.originalName),
            });
        }
    }
    render() {
        const errors: Errors | null = null;
        return (<SaveDialogLayout onSaveClick={this.onSave} title={`Copy of ${this.props.originalName}`} errors={null} busy={null}>
                <Callout type={"information"} title={"Save a copy to keep your changes"}>
                    <ExternalLink href="CommunityContributedStepTemplates">Community step templates</ExternalLink> are read-only because they are linked to the <ExternalLink href="CommunityContributedStepTemplates">Community Library</ExternalLink>.
                </Callout>
                <Text label="Save as" value={this.state.newName} onChange={(newName) => this.setState({ newName })}/>
            </SaveDialogLayout>);
    }
    private createNewName = (name: string) => {
        return `${name} Copy`;
    };
    private onSave = () => {
        this.props.onSave(this.state.newName);
        return Promise.resolve(true);
    };
    static displayName = "SaveAsDialog";
}
