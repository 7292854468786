/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DialogLayoutDispatchProps } from "~/components/DialogLayout/DialogLayout";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
interface RetryRunbookRunDialogProps {
    onRetryRunbookRunDialogClicked(): any;
}
interface RetryRunbookRunDialogState extends DataBaseComponentState {
    isBusy: boolean;
}
export class RetryRunbookRunDialogLayout extends DataBaseComponent<RetryRunbookRunDialogProps & DialogLayoutDispatchProps, RetryRunbookRunDialogState> {
    constructor(props: RetryRunbookRunDialogProps & DialogLayoutDispatchProps) {
        super(props);
        this.state = {
            isBusy: false,
        };
    }
    render() {
        const actions = [];
        actions.push(<ActionButton label="Cancel" key="CancelBlock" onClick={() => this.props.close()}/>);
        actions.push(<ActionButton label="Retry" key="RetryRunbookRunDialog" type={ActionButtonType.Primary} disabled={this.state.isBusy} onClick={() => this.doBusyTask(async () => {
                this.setState({ isBusy: true });
                await this.props.onRetryRunbookRunDialogClicked();
                this.setState({ isBusy: false });
                this.props.close();
            })}/>);
        return (<DialogLayout title="Retry run" busy={this.state.busy} errors={this.errors} key="RetryRunbookRunDialog" closeDialog={this.props.close} actions={actions}>
                <p>Retry a run will re-run the Runbook with the same process, variables, packages and any Git references as this run. Are you sure you want to Retry?</p>
            </DialogLayout>);
    }
    static displayName = "RetryRunbookRunDialogLayout";
}
export default RetryRunbookRunDialogLayout;
