import type { KubernetesServiceProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import { KubernetesServiceComponent } from "~/components/Actions/kubernetes/kubernetesServiceComponent";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
class KubernetesServiceEditFeature extends BaseComponent<ActionEditProps<KubernetesServiceProperties>, never> {
    render() {
        return (<KubernetesServiceComponent properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} errors={this.props.errors} projectId={this.props.projectId} gitRef={this.props.gitRef} expandedByDefault={this.props.expandedByDefault} importLabels={false}/>);
    }
    static displayName = "KubernetesServiceEditFeature";
}
pluginRegistry.registerFeature({
    featureName: "Octopus.Features.KubernetesService",
    title: "Service",
    description: "Defines a Kubernetes Service resource, which is used to expose Pod resources",
    edit: KubernetesServiceEditFeature,
    priority: 8,
});
