/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ActionTemplateParameterResource, ControlType } from "@octopusdeploy/octopus-server-client";
import { clone, compact } from "lodash";
import * as React from "react";
import ActionTemplateParameterEditorDialog from "~/components/ActionTemplateParametersList/ActionTemplateParameterEditorDialog";
import ActionTemplateParameterResourceSummary from "~/components/ActionTemplateParametersList/ActionTemplateParameterResourceSummary";
import DialogOpener from "~/components/Dialog/DialogOpener";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { ActionTemplateParameterSorter } from "./ActionTemplateParameterSorter";
class ActionTemplateParameterRemoveItemsList extends RemoveItemsList<ActionTemplateParameterResource> {
}
interface ActionTemplateParametersListProps {
    parameters: ActionTemplateParameterResource[];
    editPermission: PermissionCheckProps;
    excludedControlTypes?: ControlType[];
    name: "template" | "parameter";
    onParametersChanged(parameters: ActionTemplateParameterResource[]): void;
    listActions?: React.ReactElement[];
}
interface ActionTemplateParametersListState {
    parameterToEdit?: ActionTemplateParameterResource | null;
    parameterToEditIndex?: number | null;
}
export default class ActionTemplateParameterList extends React.Component<ActionTemplateParametersListProps, ActionTemplateParametersListState> {
    public static defaultProps: Partial<ActionTemplateParametersListProps> = {
        excludedControlTypes: [],
    };
    constructor(props: ActionTemplateParametersListProps) {
        super(props);
        this.state = {};
    }
    render() {
        const actions = this.props.listActions || compact([this.reorderButton(), this.addParameterButton()]);
        return (<div>
                <DialogOpener open={!!this.state.parameterToEdit} onClose={this.resetSelection}>
                    <ActionTemplateParameterEditorDialog parameter={this.state.parameterToEdit!} excludedControlTypes={this.props.excludedControlTypes} onOk={this.updateParameter} name={this.props.name} editPermission={this.props.editPermission}/>
                </DialogOpener>
                <ActionTemplateParameterRemoveItemsList empty={""} listActions={actions} data={this.props.parameters} onRow={this.renderItem} onRemoveRow={(parameter) => this.props.onParametersChanged(this.props.parameters.filter((p) => p !== parameter))} onRowTouch={this.editParameter}/>
            </div>);
    }
    private resetSelection = () => {
        return this.setState({ parameterToEdit: null, parameterToEditIndex: null });
    };
    private updateParameter = (parameter: ActionTemplateParameterResource) => {
        const before = this.props.parameters.slice(0, this.state.parameterToEditIndex!);
        const after = this.props.parameters.slice(this.state.parameterToEditIndex! + 1);
        this.setState({ parameterToEditIndex: null, parameterToEdit: null });
        return this.props.onParametersChanged([...before, parameter, ...after]);
    };
    private editParameter = (parameter: ActionTemplateParameterResource) => {
        return this.setState({
            parameterToEdit: clone(parameter),
            parameterToEditIndex: this.props.parameters.indexOf(parameter),
        });
    };
    private renderItem = (parameter: ActionTemplateParameterResource) => {
        return <ActionTemplateParameterResourceSummary key={parameter.Name} parameter={parameter}/>;
    };
    private addParameterButton() {
        const onAdd = (parameter: ActionTemplateParameterResource) => this.props.onParametersChanged([...this.props.parameters, parameter]);
        return <AddActionTemplateParameterButton onAdd={onAdd} name={this.props.name} permission={this.props.editPermission} excludedControls={this.props.excludedControlTypes} type={ActionButtonType.Primary}/>;
    }
    private reorderButton() {
        return <SortActionTemplateParametersButton parameters={this.props.parameters} onChange={this.props.onParametersChanged} permission={this.props.editPermission} name={this.props.name}/>;
    }
    static displayName = "ActionTemplateParameterList";
}
interface AddActionTemplateParameterButtonProps {
    onAdd: (parameter: ActionTemplateParameterResource) => void;
    permission: PermissionCheckProps;
    name: "template" | "parameter";
    type: ActionButtonType;
    excludedControls: ControlType[] | undefined;
}
export function AddActionTemplateParameterButton({ onAdd, permission, name, type, excludedControls }: AddActionTemplateParameterButtonProps) {
    return (<PermissionCheck key="AddParameterPermission" {...permission}>
            <OpenDialogButton key="AddParameter" label={`Add ${name}`} type={type}>
                <ActionTemplateParameterEditorDialog onOk={onAdd} excludedControlTypes={excludedControls} name={name} editPermission={permission}/>
            </OpenDialogButton>
        </PermissionCheck>);
}
interface SortActionTemplateParametersButtonProps {
    parameters: ActionTemplateParameterResource[];
    onChange: (parameters: ActionTemplateParameterResource[]) => void;
    permission: PermissionCheckProps;
    name: "template" | "parameter";
}
export function SortActionTemplateParametersButton({ parameters, onChange, permission, name }: SortActionTemplateParametersButtonProps) {
    if (parameters.length <= 1) {
        return null;
    }
    return (<PermissionCheck key="ReorderParametersPermission" {...permission}>
            <OpenDialogButton key="ReorderParameter" label={`Reorder ${name}s`} type={ActionButtonType.Secondary}>
                <ActionTemplateParameterSorter title={`Reorder ${name}s`} parameters={parameters} onOk={onChange}/>
            </OpenDialogButton>
        </PermissionCheck>);
}
