import { InsightsGranularity, InsightsTimeRange } from "@octopusdeploy/octopus-server-client";
import type { InsightsCadence } from "@octopusdeploy/portal-routes";
import { InsightsCadenceValues } from "@octopusdeploy/portal-routes";
export const insightsCadenceOptions = [
    {
        value: InsightsCadenceValues.LastYearMonthly,
        text: "Last year, monthly",
    },
    {
        value: InsightsCadenceValues.LastYearWeekly,
        text: "Last year, weekly",
    },
    {
        value: InsightsCadenceValues.LastQuarterWeekly,
        text: "Last quarter, weekly",
    },
    {
        value: InsightsCadenceValues.LastQuarterDaily,
        text: "Last quarter, daily",
    },
    {
        value: InsightsCadenceValues.LastMonthDaily,
        text: "Last month, daily",
    },
] as const;
type InsightsCadenceDefinition = {
    timeRange: InsightsTimeRange;
    granularity: InsightsGranularity;
};
export const insightsCadenceLookup: Record<InsightsCadence, InsightsCadenceDefinition> = {
    lastYearMonthly: {
        timeRange: InsightsTimeRange.LastYear,
        granularity: InsightsGranularity.Monthly,
    },
    lastYearWeekly: {
        timeRange: InsightsTimeRange.LastYear,
        granularity: InsightsGranularity.Weekly,
    },
    lastQuarterWeekly: {
        timeRange: InsightsTimeRange.LastQuarter,
        granularity: InsightsGranularity.Weekly,
    },
    lastQuarterDaily: {
        timeRange: InsightsTimeRange.LastQuarter,
        granularity: InsightsGranularity.Daily,
    },
    lastMonthDaily: {
        timeRange: InsightsTimeRange.LastMonth,
        granularity: InsightsGranularity.Daily,
    },
} as const;
export const insightsCadenceXAxisTickInterval: Record<InsightsCadence, number> = {
    lastYearMonthly: 0,
    lastYearWeekly: 6,
    lastQuarterWeekly: 1,
    lastQuarterDaily: 12,
    lastMonthDaily: 3,
};
