import * as _ from "lodash";
export class JsonUtils {
    /**
     * Try to parse the text as JSON
     * @param {string} template The text to parse
     * @returns {boolean} true if the text could be parsed as JSON, and false otherwise
     */
    static tryParseJson(template: string): boolean {
        try {
            JSON.parse(template);
            return true;
        }
        catch (ex) {
            return false;
        }
    }
    /*
     * Try to parse the text as JSON
     * @param template The text to parse
     * @param json
     * @param defaultValue The value to return if parsing fails
     * @returns the parsed result, or the default value if parsing failed
     */
    static tryParse<T>(json: string, defaultValue: T): T {
        try {
            return JSON.parse(json);
        }
        catch (e) {
            return defaultValue;
        }
    }
    /*
     * Try to parse the text as JSON array
     * @param template The text to parse
     * @param json
     * @param defaultValue The value to return if parsing fails
     * @returns the parsed result, or the default value if parsing failed
     */
    static tryParseArray<T>(json: string, defaultValue: T[]): T[] {
        try {
            const array = JSON.parse(json);
            if (_.isArray(array)) {
                return array;
            }
            return defaultValue;
        }
        catch (e) {
            return defaultValue;
        }
    }
}
