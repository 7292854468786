import * as React from "react";
import { buildServerPackagingInstruction } from "../../Instructions";
import { CommonBuildServerPackagingTextForOctoExe, CommonBuildServerPackagingTextForIntegration } from "../CommonRegistrationText";
const BuildServerPackaging: React.SFC<{}> = () => (<div>
        <p>
            When packaging other applications from build servers, we recommend using <i>Octo.exe</i>.
        </p>
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
    </div>);
BuildServerPackaging.displayName = "BuildServerPackaging"
export default buildServerPackagingInstruction(() => <BuildServerPackaging />);
