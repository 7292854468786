import { css } from "@emotion/css";
import { KubernetesIcon, RocketIcon } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import CreateSampleProjectCallout from "app/components/ContextualHelpLayout/PageHelpRegistry/Registrations/Areas/Projects/components/CreateSampleProjectCallout";
import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import image from "../../Images/Projects.svg";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "./CommonViews";
export const CommonProjectsView = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink> let you manage multiple software applications. For each project, you define a deployment process, configuration variables, and the environments the software
                    will be deployed to.
                </p>
                <div>
                    <CreateSampleProjectCallout isInHelpSidebar/>
                </div>
                <ImageWithPlaceholder src={image} alt={"Projects"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
const topicsStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[8],
    color: themeTokens.color.text.secondary,
});
const linkStyles = css({
    display: "flex",
    gap: space[4],
    alignItems: "center",
});
export const CommonProjectsTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources title="Getting Started Guides">
                <div className={topicsStyles}>
                    <div className={linkStyles}>
                        <KubernetesIcon size={14}/>
                        <ExternalLink href="FirstKubernetesGuide">First Kubernetes Deployment</ExternalLink>
                    </div>
                    <div className={linkStyles}>
                        <RocketIcon size={14}/>
                        <ExternalLink href="FirstDeploymentGuide">First Deployment</ExternalLink>
                    </div>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
