import * as React from "react";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
export const OnboardingTextForWorkers = "Workers let you delegate work to a machine other than the Octopus server for increased speed, scale and security.";
const WorkersOnboarding = () => {
    return (<OnboardingPage title="Configure workers to execute deployment tasks on behalf of the Octopus Server" intro={<span>{OnboardingTextForWorkers}</span>} learnMore={<>
                    <ExternalLink href="OnboardingWorkersLearnMore">Learn more</ExternalLink>
                </>}/>);
};
export default WorkersOnboarding;
