import { css } from "@emotion/css";
import { RadioButton } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
interface RadioButtonWithContextualHelpProps<T extends string> {
    /**
     * The value of the radio button.
     */
    value: T;
    /**
     * The label of the radio button.
     */
    label: React.ReactNode;
    /**
     * Controls whether the radio button is the default option.
     * The label of a default option will have "(default)" appended to it.
     */
    isDefault?: boolean;
    /**
     * Controls whether the radio button is disabled.
     */
    disabled?: boolean;
    /**
     * Controls whether the radio button should be automatically focused.
     */
    autoFocus?: boolean;
    /**
     * The accessible name of the radio button.
     */
    accessibleName?: string;
    /**
     * The contextual help to show next to the radio button
     */
    contextualHelp?: React.ReactNode;
}
export function RadioButtonWithContextualHelp<T extends string>(props: RadioButtonWithContextualHelpProps<T>) {
    const styles = {
        radioButtonWithContextualHelpContainer: css({
            display: "flex",
            gap: space[4],
            alignItems: "center",
            marginLeft: "0 !important",
        }),
        contextualHelp: css({
            marginBottom: space[4],
        }),
    };
    return (<div className={styles.radioButtonWithContextualHelpContainer}>
            <RadioButton {...props}/>
            {props.contextualHelp && <div className={styles.contextualHelp}>{props.contextualHelp}</div>}
        </div>);
}
