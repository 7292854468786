import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { FormFieldProps } from "~/components/form";
import CertificateVariableSelect from "~/components/form/CertificateSelect/CertificateVariableSelect";
interface CertificateVariableInputProps extends FormFieldProps<string> {
    projectId: string;
    gitRef: GitRefResource | undefined;
    label: string;
    defaultValueIndicator: JSX.Element | undefined;
    allowClear: boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}
const CertificateVariableInput: React.FC<CertificateVariableInputProps> = (props) => {
    const { value, projectId, gitRef, label, defaultValueIndicator, allowClear, doBusyTask, ...rest } = props;
    const formProps = { ...rest, label };
    return (<React.Fragment>
            <CertificateVariableSelect value={value} projectId={projectId} gitRef={gitRef} doBusyTask={doBusyTask} allowClear={allowClear} {...formProps}/>
            {defaultValueIndicator}
        </React.Fragment>);
};
CertificateVariableInput.displayName = "CertificateVariableInput"
export default CertificateVariableInput;
