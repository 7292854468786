import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
const instanceIconStyles = css({
    backgroundColor: themeTokens.color.icon.success,
    width: "50px",
    height: "50px",
    padding: "15px",
    borderRadius: "25px",
});
export function WelcomeDialogInstanceIcon() {
    return (<div className={instanceIconStyles}>
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 0.25C1.12109 0.25 0 1.37109 0 2.75V5.25C0 6.62891 1.12109 7.75 2.5 7.75H17.5C18.8789 7.75 20 6.62891 20 5.25V2.75C20 1.37109 18.8789 0.25 17.5 0.25H2.5ZM13.4375 3.0625C13.6861 3.0625 13.9246 3.16127 14.1004 3.33709C14.2762 3.5129 14.375 3.75136 14.375 4C14.375 4.24864 14.2762 4.4871 14.1004 4.66291C13.9246 4.83873 13.6861 4.9375 13.4375 4.9375C13.1889 4.9375 12.9504 4.83873 12.7746 4.66291C12.5988 4.4871 12.5 4.24864 12.5 4C12.5 3.75136 12.5988 3.5129 12.7746 3.33709C12.9504 3.16127 13.1889 3.0625 13.4375 3.0625ZM15.3125 4C15.3125 3.75136 15.4113 3.5129 15.5871 3.33709C15.7629 3.16127 16.0014 3.0625 16.25 3.0625C16.4986 3.0625 16.7371 3.16127 16.9129 3.33709C17.0887 3.5129 17.1875 3.75136 17.1875 4C17.1875 4.24864 17.0887 4.4871 16.9129 4.66291C16.7371 4.83873 16.4986 4.9375 16.25 4.9375C16.0014 4.9375 15.7629 4.83873 15.5871 4.66291C15.4113 4.4871 15.3125 4.24864 15.3125 4ZM2.5 10.25C1.12109 10.25 0 11.3711 0 12.75V15.25C0 16.6289 1.12109 17.75 2.5 17.75H17.5C18.8789 17.75 20 16.6289 20 15.25V12.75C20 11.3711 18.8789 10.25 17.5 10.25H2.5ZM13.4375 13.0625C13.6861 13.0625 13.9246 13.1613 14.1004 13.3371C14.2762 13.5129 14.375 13.7514 14.375 14C14.375 14.2486 14.2762 14.4871 14.1004 14.6629C13.9246 14.8387 13.6861 14.9375 13.4375 14.9375C13.1889 14.9375 12.9504 14.8387 12.7746 14.6629C12.5988 14.4871 12.5 14.2486 12.5 14C12.5 13.7514 12.5988 13.5129 12.7746 13.3371C12.9504 13.1613 13.1889 13.0625 13.4375 13.0625ZM15.625 14C15.625 13.7514 15.7238 13.5129 15.8996 13.3371C16.0754 13.1613 16.3139 13.0625 16.5625 13.0625C16.8111 13.0625 17.0496 13.1613 17.2254 13.3371C17.4012 13.5129 17.5 13.7514 17.5 14C17.5 14.2486 17.4012 14.4871 17.2254 14.6629C17.0496 14.8387 16.8111 14.9375 16.5625 14.9375C16.3139 14.9375 16.0754 14.8387 15.8996 14.6629C15.7238 14.4871 15.625 14.2486 15.625 14Z" fill="white"/>
            </svg>
        </div>);
}
