/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { RadioButton, RadioButtonGroup, Tooltip, Callout } from "@octopusdeploy/design-system-components";
import type { AnonymousVcsCredentials, UsernamePasswordVcsCredentials, ReferenceVcsCredentials, GitCredentialResource } from "@octopusdeploy/octopus-server-client";
import { Permission, AuthenticationType, IsUsingReferenceAuth, IsUsingUsernamePasswordAuth, IsUsingAnonymousAuth } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { PermissionCheck } from "~/components/PermissionCheck";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Note, Sensitive, Summary, Text, required } from "~/components/form";
import GitCredentialSelect from "../GitCredentialSelect";
export interface GenericVersionControlSettingsDetailsProps {
    busy?: Promise<unknown> | boolean;
    getFieldError: (key: string) => string | undefined;
    gitCredentials: GitCredentialResource[] | undefined;
    refreshGitCredentials: () => Promise<boolean>;
    url: string;
    credential: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials;
    setRepositoryDetails: (url: string | undefined, credential: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials | undefined) => void;
}
export default function GenericVersionControlSettingsDetails({ busy, getFieldError, gitCredentials, refreshGitCredentials, url, credential, setRepositoryDetails }: GenericVersionControlSettingsDetailsProps) {
    const isExpanded = !url;
    const canViewLibraryGitCredentials = hasPermission(Permission.GitCredentialView);
    const urlSummary = url ? Summary.summary(url) : Summary.placeholder("Enter a Git repository URL");
    const onUrlChanged = async (newUrl: string) => {
        setRepositoryDetails(newUrl, credential);
    };
    const onCredentialTypeChanged = async (newAuthenticationType: AuthenticationType) => {
        if (newAuthenticationType === AuthenticationType.Reference) {
            setRepositoryDetails(url, { Type: AuthenticationType.Reference, Id: undefined! });
        }
        else if (newAuthenticationType === AuthenticationType.UsernamePassword) {
            setRepositoryDetails(url, { Type: AuthenticationType.UsernamePassword, Username: undefined!, Password: undefined! });
        }
        else if (newAuthenticationType === AuthenticationType.Anonymous) {
            setRepositoryDetails(url, { Type: AuthenticationType.Anonymous });
        }
    };
    return (<>
            <ExpandableFormSection errorKey="Url" title="Git Repository" summary={urlSummary} help="Add the Git repository where you want to store this project's deployment process" isExpandedByDefault={isExpanded}>
                <Callout type={"information"} title="Repository must be initialized">
                    <div>If you are creating a new repository, it must be initialized with at least one branch</div>
                </Callout>
                <Text key="Url" value={url} onChange={onUrlChanged} label="URL" error={getFieldError("Url")} validate={required("Enter a Git repository URL.")} autoFocus={true} disabled={!!busy} accessibleName={"Git repository URL"}/>
                <Note>
                    The HTTPS URL to your git repo. E.g. <code>https://github.com/acme/hello-world.git</code>
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Authentication" title="Authentication" summary={authSummary(gitCredentials, credential)} help="Choose a method to authenticate with the above Git repository" isExpandedByDefault={isExpanded}>
                <RadioButtonGroup value={credential.Type} onChange={onCredentialTypeChanged} accessibleName={"Authentication mode"}>
                    <LibraryRadioButtonWithOptionalToolTip busy={busy}/>
                    {IsUsingReferenceAuth(credential) && (<PermissionCheck permission={Permission.GitCredentialView} alternate={<Note>Currently configured with a library credential. You do not have permission to change the credential or repository URL</Note>}>
                            <GitCredentialSelect items={gitCredentials ?? []} onChange={(Id) => setRepositoryDetails(url, { ...credential, Id })} value={credential.Id} onRequestRefresh={refreshGitCredentials}/>
                            <Note> Use credential from the Git credential library </Note>
                        </PermissionCheck>)}

                    <RadioButton value={AuthenticationType.UsernamePassword} key={AuthenticationType.UsernamePassword} label={"Username/Password"} isDefault={!canViewLibraryGitCredentials} accessibleName={"Authenticate using username and password"} disabled={!!busy}/>
                    {IsUsingUsernamePasswordAuth(credential) && (<>
                            <Text key="Username" value={credential.Username} onChange={(Username) => setRepositoryDetails(url, { ...credential, Username })} label="Username" error={getFieldError("Username")} validate={required("Enter authentication details.")} disabled={!!busy}/>
                            <Sensitive key="Password" value={credential.Password} onChange={(Password) => setRepositoryDetails(url, { ...credential, Password })} label="Password or Personal Access Token" error={getFieldError("Password")} disabled={!!busy}/>
                            <Note>If you are using a personal access token, ensure it is scoped with read and write access for the repo.</Note>
                        </>)}
                    <RadioButton value={AuthenticationType.Anonymous} key={AuthenticationType.Anonymous} label={"Anonymous"} accessibleName={"No authentication"} disabled={!!busy}/>
                    {IsUsingAnonymousAuth(credential) && <Note>When no credentials are required (e.g. locally hosted repositories).</Note>}
                </RadioButtonGroup>
            </ExpandableFormSection>
        </>);
}
function authSummary(gitCredentials: GitCredentialResource[] | undefined, credentials: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials): SummaryNode {
    if (credentials) {
        if (IsUsingReferenceAuth(credentials)) {
            const { Id: id } = credentials;
            const credential = gitCredentials?.find((c) => c.Id == id);
            return Summary.summary(React.Children.toArray([
                <span>Using library credential</span>,
                credential && (<span>
                            <strong>{credential ? " " + credential.Name : ""}</strong>
                        </span>),
            ]));
        }
        else if (IsUsingUsernamePasswordAuth(credentials)) {
            const { Username: username, Password: password } = credentials;
            if (username && password && password.HasValue) {
                return Summary.summary(React.Children.toArray([
                    <span>Authenticated with username&nbsp;</span>,
                    <span>
                            <strong>{username}</strong>
                        </span>,
                ]));
            }
        }
        else {
            return Summary.summary(React.Children.toArray([
                <span>
                        Authenticated as an <strong>Anonymous</strong> user
                    </span>,
            ]));
        }
    }
    return Summary.placeholder("Enter authentication details");
}
interface LibraryRadioButtonWithOptionalToolTipProps {
    busy?: Promise<unknown> | boolean;
}
function LibraryRadioButtonWithOptionalToolTip({ busy }: LibraryRadioButtonWithOptionalToolTipProps) {
    const canViewLibraryGitCredentials = hasPermission(Permission.GitCredentialView);
    const baseLibraryRadioButton = (<RadioButton value={AuthenticationType.Reference} key={AuthenticationType.Reference} label={"Library"} disabled={!canViewLibraryGitCredentials || !!busy} isDefault={canViewLibraryGitCredentials} accessibleName={"Use library credentials"}/>);
    return canViewLibraryGitCredentials ? (baseLibraryRadioButton) : (<Tooltip content={"Requires Git Credential View permission"} position="top" style={{ marginLeft: 0 }}>
            {baseLibraryRadioButton}
        </Tooltip>);
}
