import { css, cx } from "@emotion/css";
import { text, themeTokens, space } from "@octopusdeploy/design-system-tokens";
import type { ReactElement } from "react";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
interface EmptyPageProps {
    image: ReactElement;
    title: string;
    description: string;
    action?: ReactElement;
}
export function EmptyPage({ image, title, description, action }: EmptyPageProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    return (<div className={cx(emptyPageStyles, { [emptyPageMobileStyles]: !isLargerThanIpad })}>
            {image}
            <div className={contentStyles}>
                <h2 className={headerStyles}>{title}</h2>
                <div className={descriptionStyles}>{description}</div>
                {action}
            </div>
        </div>);
}
const emptyPageStyles = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "10rem",
    gap: space["16"],
});
const emptyPageMobileStyles = css({
    padding: space["24"],
});
const contentStyles = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: space["8"],
    font: text.interface.body.default.base,
    color: themeTokens.color.text.secondary,
});
const headerStyles = css({
    all: "unset",
    font: text.interface.heading.medium,
    color: themeTokens.color.text.primary,
});
const descriptionStyles = css({
    maxWidth: "42.25rem",
    textAlign: "center",
});
