import React from "react";
import styles from "./style.module.less";
export type DynamicEnvironmentProvisioningIconProps = {
    title: string;
};
export function DynamicEnvironmentProvisioningIcon({ title }: DynamicEnvironmentProvisioningIconProps) {
    return (<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.dynamicEnvironmentStateIcon}>
            <title>{title}</title>
            <path d="M8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16ZM8.35312 3.64687L11.8531 7.14687C11.9969 7.29063 12.0375 7.50625 11.9625 7.69063C11.8875 7.875 11.7031 8 11.5 8H9.5V11C9.5 11.5531 9.05313 12 8.5 12H7.5C6.94688 12 6.5 11.5531 6.5 11V8H4.5C4.29688 8 4.11562 7.87813 4.0375 7.69063C3.95937 7.50313 4.00312 7.2875 4.14687 7.14687L7.64687 3.64687C7.84062 3.45312 8.15937 3.45312 8.35312 3.64687Z" fill="#1A77CA"/>
        </svg>);
}
