import { Callout } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
interface MissingProcessStepsMessageProps {
    project: ProjectResource;
    branchName?: string;
}
const MissingProcessStepsMessage: React.StatelessComponent<MissingProcessStepsMessageProps> = (props) => {
    return (<Callout title="Deployment process does not have any steps" type={"warning"}>
            <div>
                The project's deployment process{props.branchName ? ` on branch "${props.branchName}"` : ""} doesn't have any steps.
                <PermissionCheck permission={Permission.ProcessEdit} project={props.project.Id} tenant="*">
                    <span>
                        {" "}
                        Add steps under the{" "}
                        <strong>
                            <InternalLink to={links.deploymentProcessPage.generateUrl({ spaceId: props.project.SpaceId, projectSlug: props.project.Slug })}>Process</InternalLink>
                        </strong>{" "}
                        menu for this {props.branchName ? "branch or choose another branch" : "project"}.
                    </span>
                </PermissionCheck>
            </div>
        </Callout>);
};
MissingProcessStepsMessage.displayName = "MissingProcessStepsMessage"
export default MissingProcessStepsMessage;
