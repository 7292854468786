import * as React from "react";
import LateStateChangesChild from "~/components/StyleGuide/LateStateChangesChild";
import BaseComponent from "../BaseComponent";
interface State {
    removeChild: boolean;
}
export default class LateStateChanges extends BaseComponent<{}, State> {
    constructor(props: {}) {
        super(props);
        this.state = { removeChild: false };
    }
    async componentDidMount() {
        setTimeout(() => this.setState({ removeChild: true }), 1000);
    }
    render() {
        return !this.state.removeChild && <LateStateChangesChild />;
    }
    static displayName = "LateStateChanges";
}
