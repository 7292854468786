import { pageId } from "@octopusdeploy/portal-routes";
export default () => ({
    upsell: pageId({ area: "Insights", id: "Insights.Upsell", name: "Insights Reports Upsell" }),
    reports: pageId({ area: "Insights", id: "Insights.Reports", name: "Insights Reports" }),
    report: {
        overview: pageId({ area: "Insights", id: "Insights.Report.Overview", name: "Insights Report Overview" }),
        frequency: pageId({ area: "Insights", id: "Insights.Report.DeploymentFrequency", name: "Insights Report Deployment Frequency" }),
        leadTime: pageId({ area: "Insights", id: "Insights.Report.DeploymentLeadTime", name: "Insights Report Deployment Lead Time" }),
        failureRate: pageId({ area: "Insights", id: "Insights.Report.DeploymentFailureRate", name: "Insights Report Failure Rate" }),
        timeToRecovery: pageId({ area: "Insights", id: "Insights.Report.MeanTimeToRecovery", name: "Insights Report Mean Time to Recovery" }),
        deploymentDuration: pageId({ area: "Insights", id: "Insights.Report.DeploymentDuration", name: "Insights Report Deployment Duration" }),
        settings: pageId({ area: "Insights", id: "Insights.Report.Settings", name: "Insights Report Settings" }),
    },
});
