import * as React from "react";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
import { HowToCreateYourPackageDialogLink } from "./HowToCreateYourPackageDialogLink";
import { HowToPushYourPackageDialogLink } from "./HowToPushYourPackageDialogLink";
import styles from "./style.module.less";
export const SupportedPackageTypes = "NuGet, zip, tar, tar gzip, tar bzip2, jar, war, ear and rar";
export const OnboardingTextForPackaging = "The built-in repository accepts " + SupportedPackageTypes + " packages.";
const Onboarding = () => {
    return (<React.Fragment>
            <OnboardingPage title="Upload or push packaged applications to the built-in package repository" intro={<span>
                        {OnboardingTextForPackaging}
                        <br />
                    </span>} learnMore={<div className={styles.learnMoreLinks}>
                        <HowToCreateYourPackageDialogLink label="How to create packages" asSecondaryButton={true}/>
                        <HowToPushYourPackageDialogLink label="How to push packages" asSecondaryButton={true}/>
                    </div>}/>
        </React.Fragment>);
};
export default Onboarding;
