import React from "react";
import type { CloudConnectionStatus } from "~/areas/projects/components/CloudConnection/CloudConnectionStatusIcon";
import { CloudConnectionStatusIcon } from "~/areas/projects/components/CloudConnection/CloudConnectionStatusIcon";
import Note from "~/primitiveComponents/form/Note/Note";
import styles from "./styles.module.less";
export interface CloudConnectionTileProp {
    icon?: React.ReactNode;
    provider: string;
    configurationStatus: string;
    connectionStatus: CloudConnectionStatus | null;
}
const CloudConnectionTile: React.FC<CloudConnectionTileProp> = (props) => {
    const iconStyle = `${styles.tileIcon} ${styles.logoBackground}`;
    return (<div className={styles.tileContainer} role={"tile"} aria-label={`${props.provider} cloud connection tile`}>
            {props.icon ? <div className={iconStyle}>{props.icon}</div> : <></>}
            <h3 className={styles.tileProvider}>{props.provider}</h3>
            <div className={styles.tileStatus}>
                {props.connectionStatus === null ? (<></>) : (<span className={styles.tileStatusIcon}>
                        <CloudConnectionStatusIcon connectionStatus={props.connectionStatus} iconSize={"small"}></CloudConnectionStatusIcon>
                    </span>)}
                {props.configurationStatus === "Configure" ? <span className={styles.tileHighlightText}>{props.configurationStatus}</span> : <span className={styles.tileStatusText}>{props.configurationStatus}</span>}
            </div>
            <div className={styles.tileStatusSubText}>{props.configurationStatus === "Configure" ? <></> : <Note style={{ margin: "0" }}>Use Project Variables to scope your connection to environments and more.</Note>}</div>
        </div>);
};
CloudConnectionTile.displayName = "CloudConnectionTile"
export default CloudConnectionTile;
