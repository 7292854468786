import type { RetentionPeriod } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Summary } from "~/components/form";
import policySummary from "../Lifecycle/policySummary";
function RetentionPolicySummary(releaseRetentionPolicy: RetentionPeriod, tentacleRetentionPolicy: RetentionPeriod) {
    const summary = <RetentionPolicyText releaseRetentionPolicy={releaseRetentionPolicy} tentacleRetentionPolicy={tentacleRetentionPolicy}/>;
    return releaseRetentionPolicy.ShouldKeepForever && tentacleRetentionPolicy.ShouldKeepForever ? Summary.placeholder(summary) : Summary.summary(summary);
}
const RetentionPolicyText: React.SFC<{
    releaseRetentionPolicy: RetentionPeriod;
    tentacleRetentionPolicy: RetentionPeriod;
}> = ({ releaseRetentionPolicy, tentacleRetentionPolicy }) => {
    const releases = releaseRetentionPolicy ? `Releases: ${policySummary(releaseRetentionPolicy)}. ` : null;
    const tentacle = tentacleRetentionPolicy ? `Files on Tentacle: ${policySummary(tentacleRetentionPolicy)}. ` : null;
    return (<React.Fragment>
            {releases}
            {tentacle}
        </React.Fragment>);
};
RetentionPolicyText.displayName = "RetentionPolicyText"
export { RetentionPolicySummary, RetentionPolicyText };
export default RetentionPolicySummary;
