import * as React from "react";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { ChipIcon, DeploymentTargetTypeChip, MissingChip } from "~/components/Chips";
import Lookup from "~/components/Lookup";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
interface DeploymentTargetTypeMultiSelectProps extends FormFieldProps<string[]> {
    items: EndpointRegistration[];
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
const DeploymentTargetTypeTypedMultiSelect = MultiSelect<SelectItem>();
const DeploymentTargetTypeMultiSelect: React.FC<DeploymentTargetTypeMultiSelectProps> = (props) => {
    const { onChange, items, ...otherProps } = props;
    const selectItems = items.map((registration) => ({
        Id: registration.key,
        Name: registration.name,
    }));
    const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={selectItems} lookupId={r.Id} getIdFromElement={(element: SelectItem) => element.Id} render={(item: SelectItem) => {
                return <DeploymentTargetTypeChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} endpointRegistrationName={item.Name}/>;
            }} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.CommunicationStyle}/>}/>);
    };
    return <DeploymentTargetTypeTypedMultiSelect items={selectItems} fieldName="deployment target types" renderChip={chipRenderer} onChange={(value) => onChange(value)} {...otherProps}/>;
};
DeploymentTargetTypeMultiSelect.displayName = "DeploymentTargetTypeMultiSelect"
export default DeploymentTargetTypeMultiSelect;
