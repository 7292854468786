/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { UserResource } from "@octopusdeploy/octopus-server-client";
export default class SystemUserFactory {
    public static systemUser(): UserResource {
        const systemUser: UserResource = {
            Id: "users-system",
            DisplayName: "System",
            Username: "system",
            EmailAddress: "",
            IsActive: true,
            IsService: false,
            Links: null!,
            Identities: [],
        };
        return systemUser;
    }
}
