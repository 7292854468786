/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from "@octopusdeploy/design-system-components";
import type { TaskResource, EventResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import styles from "app/areas/tasks/components/Task/history.module.less";
import SimpleDataTable from "app/components/SimpleDataTable/SimpleDataTable";
import * as React from "react";
import { repository, session } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent/index";
import DataLoader from "~/components/DataLoader/index";
import { EventFormatter } from "~/components/EventFormatter/EventFormatter";
import { NotPermittedToViewEventsCallout } from "~/components/EventListing/EventListing";
import { Loading } from "~/components/Loading/Loading";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
interface TaskHistoryProps {
    task: TaskResource<any>;
    projectId: string | undefined;
    environmentId: string | undefined;
    tenantId: string | undefined;
}
class HistoryTable extends SimpleDataTable<EventResource> {
}
const HistoryDataLoader = DataLoader<{
    events: EventResource[];
}>();
export const TaskHistory: React.FC<TaskHistoryProps> = (props) => {
    const permitEvents: boolean = isAllowed({
        permission: Permission.EventView,
        project: props.projectId,
        environment: props.environmentId,
        tenant: props.tenantId,
    }, props.task.SpaceId ? { spaceId: props.task.SpaceId } : "system");
    const loadEvents = async () => {
        const useOptimization = session.featureToggles?.includes("PermissionCheckOptimizationFeatureToggle");
        const taskScopedRepository = props.task.SpaceId ? await repository.forSpace(props.task.SpaceId) : repository.forSystem();
        const events = useOptimization ? await taskScopedRepository.Events.getUnpaginatedEvents({ regardingAny: [props.task.Id] }) : await taskScopedRepository.Events.list({ regardingAny: [props.task.Id] });
        return { events: events.Items };
    };
    const displayHistoryTable = (data: EventResource[]) => {
        return data.length > 0 ? (<HistoryTable rowColumnClassName={styles.row} data={data} headerColumns={["When", "Who", "What"]} headerColumnClassNames={[styles.row, styles.row, styles.row]} onRow={(event) => [
                <Tooltip content={DateFormatter.dateToShortFormat(event.Occurred) || undefined}>
                        <div style={{ minWidth: "5rem" }}>{DateFormatter.momentAgo(event.Occurred)}</div>
                    </Tooltip>,
                <div className={styles.whoColumn}>{event.Username}</div>,
                <EventFormatter event={event}/>,
            ]}/>) : (<div className={styles.row}>There is no data to show.</div>);
    };
    const showLoadingAndBusyState = (busy: boolean, errors: Errors | undefined) => {
        return <Loading busy={busy} errors={errors}/>;
    };
    return (<div>
            {permitEvents ? (<HistoryDataLoader operationName="LoadHistoryForTask" load={loadEvents} renderWhenLoaded={(data) => displayHistoryTable(data.events)} renderAlternate={({ busy, errors }) => showLoadingAndBusyState(busy, errors)}/>) : (<NotPermittedToViewEventsCallout />)}
        </div>);
};
TaskHistory.displayName = "TaskHistory"
