import React, { forwardRef, useImperativeHandle, useState } from "react";
import type { TemplateValuesSectionComponent, TemplateValuesSectionProps } from "~/components/Actions/helmChartUpgrade/HelmTemplateValuesDrawer";
import type { KeyValuesTemplateValuesSource } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import { KeyValueEditList } from "~/components/EditList/index";
import { ExpandableFormSection, Summary } from "~/components/form/index";
import StringHelper from "~/utils/StringHelper/index";
export const KeyValuesTemplateValuesSection = forwardRef<TemplateValuesSectionComponent, TemplateValuesSectionProps<KeyValuesTemplateValuesSource>>((props: TemplateValuesSectionProps<KeyValuesTemplateValuesSource>, ref) => {
    const [keyValues, setKeyValues] = useState<string>(JSON.stringify(props.editingTemplateValuesSource?.Value || {}));
    const getSummary = () => {
        const variables = JSON.parse(keyValues);
        if (Object.keys(variables).length === 0) {
            return Summary.placeholder("No explicit value overrides supplied");
        }
        else {
            const text = Object.keys(variables)
                .map((m) => `${m} = ${variables[m]}`)
                .join(", ");
            return Summary.summary(text);
        }
    };
    useImperativeHandle(ref, () => ({
        validateAndSave: () => {
            const parsedKeyValues = JSON.parse(keyValues);
            const keys = Object.keys(parsedKeyValues);
            if (keys.length === 0) {
                return "At least one key/value pair must be defined.";
            }
            if (keys.some(StringHelper.isNullOrWhiteSpace)) {
                return "Cannot have an empty key";
            }
            return {
                Type: "KeyValues",
                Value: parsedKeyValues,
            };
        },
    }));
    return (<ExpandableFormSection errorKey="Octopus.Action.Helm.KeyValues" title="Key values" summary={getSummary()} help={<span>
                    Adds values into custom <code>values.yaml</code> and includes via <code>--values</code> Helm argument
                </span>} isExpandedByDefault={true}>
            <KeyValueEditList items={keyValues} name="Key/Value" separator="=" onChange={(val) => setKeyValues(val)} valueLabel="Value" projectId={props.projectId} gitRef={props.gitRef} keyLabel="Key"/>
        </ExpandableFormSection>);
});
