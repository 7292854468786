import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import Task from "~/areas/tasks/components/Task/Task";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
function ProjectTaskDetails({ taskId }: {
    taskId: string;
}) {
    const projectContext = useProjectContext();
    const projectId = projectContext.state.model.Id;
    return <ProjectTaskDetailsInternal projectId={projectId} taskId={taskId}/>;
}
interface ProjectTaskDetailsInternalProps {
    taskId: string;
    projectId: string;
}
class ProjectTaskDetailsInternal extends DataBaseComponent<ProjectTaskDetailsInternalProps> {
    constructor(props: ProjectTaskDetailsInternalProps) {
        super(props);
    }
    render() {
        const { taskId, projectId } = this.props;
        return <Task taskId={taskId} delayRender={() => false} projectId={projectId}/>;
    }
    static displayName = "ProjectTaskDetailsInternal";
}
export default ProjectTaskDetails;
