import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import BaseComponent from "~/components/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
interface TenantedDeploymentParticipationSelectorProps {
    tenantMode: TenantedDeploymentMode;
    resourceTypeLabel: string;
    onChange(newValue: string): void;
}
class TenantedDeploymentParticipationSelector extends BaseComponent<TenantedDeploymentParticipationSelectorProps> {
    render() {
        return (<RadioButtonGroup accessibleName={"The kind of deployments where this account should be included"} value={this.props.tenantMode} onChange={(e) => this.handleTenantModeChange(e)}>
                <RadioButton value={TenantedDeploymentMode.Untenanted} isDefault={true} label="Exclude from tenanted deployments"/>
                <Note>The {this.props.resourceTypeLabel} will never be included in tenanted deployments.</Note>

                <RadioButton value={TenantedDeploymentMode.Tenanted} label="Include only in tenanted deployments"/>
                <Note>The {this.props.resourceTypeLabel} will only be included in deployments to the associated tenants. It will be excluded from untenanted deployments.</Note>

                <RadioButton value={TenantedDeploymentMode.TenantedOrUntenanted} label="Include in both tenanted and untenanted deployments"/>
                <Note>The {this.props.resourceTypeLabel} will be included in untenanted deployments, and deployments to the associated tenants.</Note>

                <Note>
                    For more information on designing a multi-tenant hosting model refer to our <ExternalLink href="MultiTenantHostingModel">documentation</ExternalLink>.
                </Note>
            </RadioButtonGroup>);
    }
    private handleTenantModeChange(value: TenantedDeploymentMode) {
        this.props.onChange(value);
    }
    static displayName = "TenantedDeploymentParticipationSelector";
}
export default TenantedDeploymentParticipationSelector;
