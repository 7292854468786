import { Checkbox } from "@octopusdeploy/design-system-components";
import * as React from "react";
import styles from "./style.module.less";
interface AdvancedFilterCheckboxProps {
    label: string;
    value: boolean;
    icon?: React.ReactNode;
    onChange(value: boolean): void;
}
export const AdvancedFilterCheckbox: React.SFC<AdvancedFilterCheckboxProps> = (props: AdvancedFilterCheckboxProps) => {
    return (<div className={styles.checkboxContainer}>
            <Checkbox className={styles.checkbox} value={props.value} onChange={props.onChange} label={props.label} noMargin={true}/>
            {props.icon && <div className={styles.checkboxIcon}>{props.icon}</div>}
        </div>);
};
AdvancedFilterCheckbox.displayName = "AdvancedFilterCheckbox"
