import React, { useEffect } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { getChartReadyInsightsData } from "~/areas//insights/dataTransformation/getChartReadyInsightsData";
import { InsightsTooltip } from "~/areas/insights/components/ChartTooltips/InsightsTooltip/InsightsTooltip";
import LineChart from "~/areas/insights/components/Charts/LineChart";
import { InsightsChartCard } from "~/areas/insights/components/InsightsChartCard";
import { frequencyTrendColors, SparklineTrendPair } from "~/areas/insights/components/Reports/TrendTables/SparklineTrendPair/SparklineTrendPair";
import { getChartAxisDateFormatter } from "~/areas/insights/dataTransformation/dateHelpers";
import { getEvenlySpacedTickArray, getYAxisDataMax } from "~/areas/insights/dataTransformation/getYAxisDataMax";
import { insightsCadenceLookup, insightsCadenceXAxisTickInterval } from "~/areas/insights/insightsCadence";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { DownloadProjectInsightsCsvButton } from "../DownloadProjectInsightsCsvButton";
import type { ProjectInsightsPageProps } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import { ProjectInsightsDataLoader } from "../ProjectInsightsDataLoader/ProjectInsightsDataLoader";
import { ProjectDeploymentFrequencyTrendTable } from ".//ProjectDeploymentFrequencyTrendTable";
import styles from "./ProjectInsightsFrequency.module.less";
type ProjectInsightsFrequencyInnerProps = ProjectInsightsPageProps;
function ProjectInsightsFrequencyInner({ project, bffResponse, busy, filter }: ProjectInsightsFrequencyInnerProps) {
    const { cadence } = filter;
    const chartData = getChartReadyInsightsData(bffResponse.Series);
    const maxYAxisValue = getYAxisDataMax(chartData, "NumberOfSuccessfulDeployments");
    const dispatchAction = useAnalyticSimpleActionDispatch();
    useEffect(() => {
        dispatchAction("View Project Insights Frequency");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const currentGranularity = insightsCadenceLookup[cadence].granularity;
    const dateFormatter = getChartAxisDateFormatter(currentGranularity);
    return (<main>
            <section className={styles.content}>
                <InsightsChartCard height={500}>
                    <LineChart loading={Boolean(busy)} data={chartData} dataKey="NumberOfSuccessfulDeployments" yAxisLabel="No. of deployments" xAxisDataKey="StartOfInterval" xAxisType="category" yAxisType="number" yAxisDomain={[0, maxYAxisValue]} yAxisTickArray={getEvenlySpacedTickArray(maxYAxisValue)} xTickFormatter={dateFormatter} tooltip={<InsightsTooltip dataKey="NumberOfSuccessfulDeployments" valueFormatter={(v) => `${v.toLocaleString()} deployment${v === 1 ? "" : "s"}`} cadence={cadence}/>} xAxisTickInterval={insightsCadenceXAxisTickInterval[cadence]}/>
                </InsightsChartCard>
                <div className={styles.csvDownloadWrap}>
                    <DownloadProjectInsightsCsvButton project={project} filter={filter}/>
                </div>
            </section>
            <section>
                {bffResponse.DeploymentFrequency.TenantTrends !== null && (<SparklineTrendPair sectionTitle="Tenants" leftData={bffResponse.DeploymentFrequency.TenantTrends.Up} rightData={bffResponse.DeploymentFrequency.TenantTrends.Down} directionColorLookup={frequencyTrendColors} note={<>
                                The tenants that have trended up or down in the last 28 days compared with the previous 28 days. The first 10 in descending order by the percentage difference between the current and previous value are shown. The value
                                shown is the weekly average <ExternalLink href="InsightsDeploymentFrequency">deployment frequency</ExternalLink> for the last 28 days.
                            </>} leftTitle="Trending up" rightTitle="Trending down"/>)}
                <ProjectDeploymentFrequencyTrendTable title="Longest time between successful deployments" note="The deployments with the longest time since the previous successful deployment to the same environment and tenant for the current filter. The first 10 in descending order by time between deployment are shown." data={bffResponse.DeploymentFrequency.LongestTimeSincePreviouslySuccessfulDeployment} project={project}/>
                <ProjectDeploymentFrequencyTrendTable title="Most recent successful deployments" note="The most recent successful deployments for the current filter. The first 10 in descending order by deployment completed time are shown." data={bffResponse.DeploymentFrequency.MostRecentSuccessful} hideTimeSince project={project}/>
            </section>
        </main>);
}
export function ProjectInsightsFrequencyPage() {
    return (<ProjectInsightsDataLoader title="Deployment frequency" trendKey="DeploymentFrequency">
            {(props) => <ProjectInsightsFrequencyInner {...props}/>}
        </ProjectInsightsDataLoader>);
}
