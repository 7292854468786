import { css } from "@emotion/css";
import { Button, Tooltip } from "@octopusdeploy/design-system-components";
import { fontWeight, space } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppAuthorizationStatus, GitHubAppAuthorizationStatusUserDetails } from "@octopusdeploy/octopus-server-client";
import { GitHubAccountType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { GitHubAvatar } from "~/areas/library/components/GitConnections/GitHubInstallationLogo";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { Summary } from "~/components/form/Sections/index";
import type { SummaryNode } from "~/components/form/index";
import { ExpandableFormSection } from "~/components/form/index";
export interface GitHubUserSectionWithDataLoadingProps {
    busy?: Promise<void> | boolean;
    doBusyTask: DoBusyTask;
}
export function GitHubUserSectionWithDataLoading({ busy, doBusyTask }: GitHubUserSectionWithDataLoadingProps) {
    const [authorizationStatus, setAuthorizationStatus] = useState<GitHubAppAuthorizationStatus | undefined>(undefined);
    const getAuthorizationStatus = useDoBusyTaskEffect(doBusyTask, async () => {
        const status = await repository.GitHubApp.getAuthorizationStatusWithUserDetails();
        setAuthorizationStatus(status);
    }, []);
    const refreshUser = async () => {
        await doBusyTask(async () => {
            await repository.GitHubApp.refreshUser();
            getAuthorizationStatus();
        });
    };
    const reauthorizeUser = async () => {
        await doBusyTask(async () => {
            const url = repository.GitHubApp.getAuthorizeAppUrl(window.location.href);
            window.location.replace(url);
        });
    };
    const clearUser = async () => {
        await doBusyTask(async () => {
            setAuthorizationStatus({ IsAuthorized: false, CanAuthorize: authorizationStatus?.CanAuthorize ?? true });
            await repository.GitHubApp.clearUser();
            getAuthorizationStatus();
        });
    };
    return (<ExpandableFormSection errorKey={"GitHubUser"} title={"GitHub User"} summary={GitHubUserSectionSummary(authorizationStatus)} help="Connected GitHub user account">
            <GitHubUserSection busy={busy} refreshUser={refreshUser} authorizeUser={reauthorizeUser} clearUser={clearUser} authorizationStatus={authorizationStatus}/>
        </ExpandableFormSection>);
}
export function GitHubUserSectionSummary(authorizationStatus: GitHubAppAuthorizationStatus | undefined): SummaryNode {
    if (!authorizationStatus) {
        return Summary.placeholder("Loading...");
    }
    if (!authorizationStatus.IsAuthorized) {
        return Summary.summary("No GitHub account connected");
    }
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            gap: space[8],
            alignItems: "center",
        }),
    };
    const user = authorizationStatus.UserDetails;
    return Summary.summary(<span className={styles.container}>
            <GitHubUserAvatar small user={user}/> {user?.Login}
        </span>);
}
export interface GitHubUserSectionProps {
    busy?: Promise<void> | boolean;
    refreshUser: () => Promise<void>;
    authorizeUser: () => Promise<void>;
    clearUser: () => Promise<void>;
    authorizationStatus: GitHubAppAuthorizationStatus | undefined;
}
export function GitHubUserSection({ busy, refreshUser, authorizeUser, clearUser, authorizationStatus }: GitHubUserSectionProps) {
    const styles = {
        container: css({
            display: "flex",
            gap: space[16],
            alignItems: "center",
        }),
        primaryText: css({
            fontWeight: fontWeight[700],
        }),
        secondaryText: css({
            fontStyle: "italic",
        }),
        buttons: css({
            marginLeft: "auto",
            display: "flex",
            gap: space[8],
        }),
    };
    if (authorizationStatus === undefined) {
        return <div>Loading...</div>;
    }
    if (!authorizationStatus.IsAuthorized) {
        const content = authorizationStatus.CanAuthorize ? "You do not have a connected GitHub account" : "Guest users cannot connect a GitHub account";
        return (<div className={styles.container}>
                <div className={styles.secondaryText}>{content}</div>
                {authorizationStatus.CanAuthorize && (<div className={styles.buttons}>
                        <Button label={"Authorize"} onClick={authorizeUser} importance={"loud"} disabled={!!busy}/>
                    </div>)}
            </div>);
    }
    if (!authorizationStatus.UserDetails) {
        // Should never happen
        return <div>Oh no!</div>;
    }
    const user = authorizationStatus.UserDetails;
    return (<div className={styles.container}>
            <GitHubUserAvatar user={user}/>
            <div>
                <div className={styles.primaryText}>{user.PrimaryEmail ? <Tooltip content={user.PrimaryEmail}>{user.Name}</Tooltip> : user.Name}</div>
                <div className={styles.secondaryText}>{user.Login}</div>
            </div>
            <div className={styles.buttons}>
                <Button label={"Refresh"} onClick={refreshUser} importance={"primary"} disabled={!!busy}/>
                <Button label={"Reauthorize"} onClick={authorizeUser} importance={"loud"} disabled={!!busy}/>
                <Button label={"Disconnect"} onClick={clearUser} importance={"destructive"} disabled={!!busy}/>
            </div>
        </div>);
}
interface GitHubUserAvatarProps {
    user?: GitHubAppAuthorizationStatusUserDetails;
    small?: boolean;
}
function GitHubUserAvatar({ user, small }: GitHubUserAvatarProps) {
    if (!user || !user.AvatarUrl) {
        return null;
    }
    const alt = user.Login ? `${user.Login} GitHub avatar` : "GitHub avatar";
    return <GitHubAvatar small={small} type={GitHubAccountType.User} avatarUrl={user.AvatarUrl} alt={alt}/>;
}
