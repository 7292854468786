import React from "react";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { FundamentalsGuideDialogLayout } from "~/components/GettingStarted/FundamentalsGuide/FundamentalsGuideDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { LearnMoreResources, OverviewContainer, SupportResources, TopicsContainer } from "./CommonViews";
import styles from "./Registrations.module.less";
export const DefaultOverview = () => {
    const [showFundamentals, setShowFundamentals] = React.useState(false);
    return (<OverviewContainer>
            <TopicsContainer>
                <CustomDialog open={showFundamentals} close={() => setShowFundamentals(false)} render={(renderProps) => <FundamentalsGuideDialogLayout {...renderProps}/>}/>
                <p>
                    If you need help, search our{" "}
                    <ExternalLink href="DocumentationHome" trackAnalytics={true}>
                        documentation
                    </ExternalLink>{" "}
                    or watch our{" "}
                    <ExternalLink href="GettingStartedVideos" trackAnalytics={true}>
                        video tutorials
                    </ExternalLink>
                    .
                </p>

                {/*
         * Usually these resources would be registered in DefaultTopics below. However,
         * this component is a special case in that it's the fallback resources for all
         * pages that have none.
         * Currently our intention is that many help articles won't have supporting Related
         * Docs, and in that case the "Related Docs" section shouldn't be included at all.
         * So, by putting these links in the OverviewContainer, we ensure that only the
         * fallback content gets the fallback resource links
         */}
                <LearnMoreResources>
                    <div>
                        <ExternalLink href="GettingStarted" trackAnalytics={true}>
                            Getting Started
                        </ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="FirstDeploymentGuide" trackAnalytics={true}>
                            First Deployment Guide
                        </ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="FirstKubernetesGuide" trackAnalytics={true}>
                            First Kubernetes Deployment Guide
                        </ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="BestPractices" trackAnalytics={true}>
                            Best Practices
                        </ExternalLink>
                    </div>
                </LearnMoreResources>
            </TopicsContainer>
        </OverviewContainer>);
};
export const DefaultTopics = () => {
    return <TopicsContainer />;
};
export function DefaultGettingStarted() {
    return (<div className={styles.gettingStarted}>
            <SupportResources>
                <div>
                    <ExternalLink href="Slack" trackAnalytics={true}>
                        Join the Slack Community
                    </ExternalLink>
                </div>
                <div>
                    <ExternalLink href="https://www.youtube.com/playlist?list=PLAGskdGvlaw3-cd9rPiwhwfUo7kDGnOBh" trackAnalytics={true}>
                        Ask Octopus Training Videos
                    </ExternalLink>
                </div>
                <div>
                    <ExternalLink href="HelpMakeSuggestion" trackAnalytics={true}>
                        Make a Suggestion
                    </ExternalLink>
                </div>
                <div>
                    <ExternalLink href="DocumentationHome" trackAnalytics={true}>
                        Search Documentation
                    </ExternalLink>
                </div>
                <div>
                    <ExternalLink href={window.location.origin + window.location.pathname + "/../swaggerui/"} trackAnalytics={true}>
                        Search API Documentation
                    </ExternalLink>
                </div>
                <div>
                    <ExternalLink href="https://octopus.com/downloads" trackAnalytics={true}>
                        See Additional Downloads
                    </ExternalLink>
                </div>
            </SupportResources>
        </div>);
}
