import type { GitCredentialResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import useRequiredContext from "~/hooks/useRequiredContext";
type ProcessGitCredentialsContextValue = GitCredentialResource[];
type ProcessGitCredentialsRefreshContextValue = () => Promise<unknown>;
const ProcessGitCredentialsContext = React.createContext<ProcessGitCredentialsContextValue>([]);
const RefreshProcessGitCredentialsContext = React.createContext<ProcessGitCredentialsRefreshContextValue | undefined>(undefined);
export function useGitCredentialsFromContext(): ProcessGitCredentialsContextValue {
    return useRequiredContext(ProcessGitCredentialsContext, "ProcessGitCredentialsContext");
}
export function useRefreshGitCredentialsFromContext(): ProcessGitCredentialsRefreshContextValue {
    return useRequiredContext(RefreshProcessGitCredentialsContext, "RefreshProcessGitCredentialsContext");
}
type ProcessGitCredentialContextProviderProps = {
    gitCredentials: GitCredentialResource[];
    refreshGitCredentials: () => Promise<unknown>;
};
export function ProcessGitCredentialsContextProvider(props: React.PropsWithChildren<ProcessGitCredentialContextProviderProps>) {
    return (<ProcessGitCredentialsContext.Provider value={props.gitCredentials}>
            <RefreshProcessGitCredentialsContext.Provider value={props.refreshGitCredentials}>{props.children}</RefreshProcessGitCredentialsContext.Provider>
        </ProcessGitCredentialsContext.Provider>);
}
