import CreateSampleProjectCallout from "app/components/ContextualHelpLayout/PageHelpRegistry/Registrations/Areas/Projects/components/CreateSampleProjectCallout";
import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import image from "../../Images/Projects.svg";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const video = {
    youtubeId: "gfaRUIlQybA",
    shortlink: "OnboardingProjectsVideo",
};
export const CommonProjectView = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink> let you manage multiple software applications. For each project, you define a deployment process, configuration variables, and the environments the software
                    will be deployed to.
                </p>
                <div>
                    <CreateSampleProjectCallout isInHelpSidebar/>
                </div>
                <YouTubePlayer videoId={video.youtubeId}/>
                <ImageWithPlaceholder src={image} alt={"Projects"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonProjectTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingProjectsLearnMore">Projects</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
