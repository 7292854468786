import type { DrawerProps, DrawerWithCustomActionsProps, DrawerWithPrimaryButtonProps } from "@octopusdeploy/design-system-components";
import { Drawer, LinearProgress } from "@octopusdeploy/design-system-components";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import ExpansionButtons from "app/components/form/Sections/ExpansionButtons";
import * as React from "react";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import type { Errors } from "~/components/DataBaseComponent/index";
import FormComponent from "~/components/FormComponent/FormComponent";
import { ErrorPanel } from "~/components/form/index";
import { createCloseDrawerEvent } from "~/primitiveComponents/dataDisplay/DrawerWithForm/amplitudeAnalytics";
export type DrawerWithFormProps = Omit<DrawerProps, "onPrimaryButtonClick"> & {
    children: React.ReactNode;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    onSubmit: () => void;
    showExpandAll?: boolean;
    expandAllOnMount?: boolean;
    expandableContainerKey?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isPrimaryButtonDisabled?: Promise<any> | boolean | null;
    customPrimaryAction?: React.ReactNode;
    customActions?: React.ReactNode[];
    primaryButtonLabel?: string;
    correlationId?: string;
};
export function DrawerWithForm({ onSubmit, isOpen, busy, errors, children, showExpandAll, expandAllOnMount, expandableContainerKey, title, onClose, customActions, isPrimaryButtonDisabled, customPrimaryAction, primaryButtonLabel, correlationId, }: DrawerWithFormProps) {
    const trackEvent = useTrackEvent();
    const handleClose = () => {
        trackEvent(createCloseDrawerEvent(title, correlationId));
        if (onClose) {
            onClose();
        }
    };
    let drawerProps: DrawerWithPrimaryButtonProps | DrawerWithCustomActionsProps = {
        isOpen: isOpen,
        title: title,
        onClose: handleClose,
        variant: "default",
        onPrimaryButtonClick: onSubmit,
        layout: "none",
        children, // this is just to avoid the missing prop error
    };
    if (customActions) {
        drawerProps = {
            ...drawerProps,
            variant: "customActions",
            customActions: customActions,
        };
    }
    else {
        drawerProps = {
            ...drawerProps,
            variant: "default",
            onPrimaryButtonClick: onSubmit,
            isPrimaryButtonDisabled: isPrimaryButtonDisabled,
            customPrimaryAction: customPrimaryAction,
            primaryButtonLabel: primaryButtonLabel,
        };
    }
    return (<Drawer {...drawerProps}>
            <FormComponent onFormSubmit={onSubmit}>
                <BusyFromPromise promise={busy}>{(busy: boolean) => <LinearProgress variant={"indeterminate"} show={busy}/>}</BusyFromPromise>
                {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>}
                {showExpandAll && <ExpansionButtons errors={errors?.fieldErrors} expandAllOnMount={expandAllOnMount} containerKey={expandableContainerKey}/>}
                {children}
            </FormComponent>
        </Drawer>);
}
