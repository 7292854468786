import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface DataTableFooterProps {
    className?: string;
}
export class DataTableFooter extends React.Component<DataTableFooterProps> {
    render() {
        return <tfoot className={cn(this.props.className, styles.dataTableFooter)}>{this.props.children}</tfoot>;
    }
    static displayName = "DataTableFooter";
}
