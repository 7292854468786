/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
import type { IconButtonElement } from "@octopusdeploy/design-system-components";
import { CaretUpIcon, GitCommitIcon, GitTagIcon, TriangleExclamationIcon, GitBranchIcon, CaretDownIcon, LockIcon } from "@octopusdeploy/design-system-components";
import { borderRadius, borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { GitRef } from "@octopusdeploy/octopus-server-client";
import { isGitCommit, isGitTag } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { GitRefDropDownLabel } from "./GitRefDropDown";
interface GitRefButtonProps {
    value: GitRef | undefined;
    errorMessage?: string;
    disabled?: boolean;
    refIsLocked: boolean;
    refIsDefault: boolean;
    dropdownArrowButtonRef: React.Ref<IconButtonElement>;
    onClick: React.MouseEventHandler<HTMLElement>;
    onKeyDown: React.KeyboardEventHandler<HTMLElement>;
    style?: "grey" | "white";
    isOpen: boolean;
}
export function GitRefButton({ value, errorMessage, refIsLocked, refIsDefault, onClick, onKeyDown, isOpen }: GitRefButtonProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const hasError = Boolean(errorMessage);
    const styles = cx(gitRefButtonStyles, { [gitRefButtonErrorStyles]: hasError, [gitRefButtonActiveStyles]: isOpen, [gitRefButtonMobileStyles]: !isLargerThanIpad });
    return (<button className={styles} onClick={onClick} onKeyDown={onKeyDown} aria-label="Switch branch">
            <GitIcon gitRef={value}/>
            <span className={gitRefButtonTextStyles}>{errorMessage ?? <GitRefDropDownLabel gitRef={value} isDefault={refIsDefault}/>}</span>
            <div className={iconContainerStyles}>
                {refIsLocked && <LockIcon />}
                {hasError && <TriangleExclamationIcon size={20} color="danger"/>}
                {isOpen ? <CaretUpIcon /> : <CaretDownIcon />}
            </div>
        </button>);
}
const gitRefButtonStyles = css({
    width: "300px",
    height: "36px",
    borderRadius: borderRadius.small,
    border: `${borderWidth[1]} solid ${themeTokens.color.border.tertiary}`,
    padding: `6px ${space[12]}`,
    display: "flex",
    gap: space[8],
    backgroundColor: themeTokens.color.background.primary.default,
    alignItems: "center",
    cursor: "pointer",
    ":focus-visible": {
        outline: "none",
        boxShadow: themeTokens.shadow.focused,
    },
});
const gitRefButtonMobileStyles = css({
    width: "100%",
});
const gitRefButtonErrorStyles = css({
    borderColor: themeTokens.color.border.danger,
    backgroundColor: themeTokens.color.background.danger,
});
const gitRefButtonActiveStyles = css({
    borderColor: themeTokens.color.border.selected,
});
const gitRefButtonTextStyles = css({
    flex: 1,
    font: text.interface.body.default.medium,
    color: themeTokens.color.text.primary,
    textAlign: "left",
    textOverflow: "ellipsis",
    overflow: "clip",
    whiteSpace: "nowrap",
    width: `calc(100% - 24px)`,
});
const iconContainerStyles = css({
    display: "flex",
    gap: space[2],
    alignItems: "center",
});
const GitIcon = ({ gitRef }: {
    gitRef: GitRef | undefined;
}) => {
    if (isGitCommit(gitRef)) {
        return <GitCommitIcon />;
    }
    if (isGitTag(gitRef)) {
        return <GitTagIcon />;
    }
    return <GitBranchIcon emphasis="muted"/>;
};
