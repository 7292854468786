/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import type { FeedResource, OciRegistryFeedResource as OciRegistryFeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import BasicFeedCredentialsFormSection from "../BasicFeedCredentialsFormSection";
import type { FeedEditProps } from "./feedTypeRegistry";
import feedTypeRegistry from "./feedTypeRegistry";
class OciRegistryFeed extends React.Component<FeedEditProps<OciRegistryFeedResource>> {
    render() {
        return (<React.Fragment>
                <BasicFeedCredentialsFormSection model={{ username: this.props.feed.Username!, password: this.props.feed.Password! }} onChanged={(model) => this.props.onChange({ ...this.props.feed, Password: model.password, Username: model.username })} containerKey={this.props.expandableContainerKey}/>
            </React.Fragment>);
    }
    static displayName = "OciRegistryFeed";
}
feedTypeRegistry.registerFeedType({
    type: FeedType.OciRegistry,
    edit: OciRegistryFeed as React.ComponentType<FeedEditProps<FeedResource>>,
    text: "OCI Registry Feed",
    uriNotes: (<React.Fragment>
            <div>
                For the public Docker Hub OCI registry, set the url to <code>oci://registry-1.docker.io</code>
            </div>
        </React.Fragment>),
    uriDefault: "oci://registry-1.docker.io",
});
