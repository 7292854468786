import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { IncludeLibraryVariableSetsButton } from "~/areas/projects/components/Variables/TenantVariables/IncludeLibraryVariableSetsButton";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
interface NoVariableSetsOnboardingProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    onIncludeVariableSet: (text: string) => void;
}
export default function NoVariableSetsOnboarding({ project, doBusyTask, onIncludeVariableSet }: NoVariableSetsOnboardingProps) {
    return (<OnboardingPage title="Configure tenant-specific variables" intro={<div>Common templates are configured in variable sets and allow you to specify variables that can have different values per tenant. To get started, you'll need to include a variable set that contains a variable template.</div>} actionButtons={<IncludeLibraryVariableSetsButton project={project} doBusyTask={doBusyTask} openSnackbar={onIncludeVariableSet}/>} learnMore={<ExternalLink href="LibraryVariableSets">Learn more</ExternalLink>}/>);
}
