import { Tooltip } from "@octopusdeploy/design-system-components";
import type { ProjectResource, ProjectMeanTimeToRecoveryDeployment } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import React from "react";
import { TrendTable } from "~/areas/insights/components/Reports/TrendTables/TrendTable/TrendTable";
import { formatDurationInWords } from "~/areas/insights/dataTransformation/stringHelpers";
import InternalLink from "~/components/Navigation/InternalLink";
import { DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import DateFormatter from "~/utils/DateFormatter";
interface Props {
    title: string;
    note: ReactNode;
    data: ProjectMeanTimeToRecoveryDeployment[];
    project: ProjectResource;
}
export function ProjectTimeToRecoveryTrendTable({ title, note, data, project }: Props) {
    const isTenanted = project.TenantedDeploymentMode != TenantedDeploymentMode.Untenanted;
    return (<TrendTable title={title} isEmpty={data.length === 0} note={note} headerContent={<DataTableRow>
                    {isTenanted && <DataTableHeaderColumn>Tenant</DataTableHeaderColumn>}
                    <DataTableHeaderColumn>Release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Completed</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Deployments until recovery</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Recovered from failed release</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Time to recovery</DataTableHeaderColumn>
                </DataTableRow>} bodyContent={<>
                    {data.map((r) => (<DataTableRow key={r.Id}>
                            {isTenanted && <DataTableRowColumn>{r.TenantName}</DataTableRowColumn>}
                            <DataTableRowColumn>
                                <InternalLink to={links.releasePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, releaseVersion: r.ReleaseVersion })}>{r.ReleaseVersion}</InternalLink>
                            </DataTableRowColumn>
                            <DataTableRowColumn>
                                <Tooltip content={DateFormatter.dateToShortFormat(r.CompletedTime) || undefined}>
                                    <InternalLink to={links.deploymentDetailsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, releaseVersion: r.ReleaseVersion, deploymentId: r.Id })}>
                                        {DateFormatter.momentAgo(r.CompletedTime)}
                                    </InternalLink>
                                </Tooltip>
                            </DataTableRowColumn>
                            <DataTableRowColumn>{r.DeploymentsUntilRecovery}</DataTableRowColumn>
                            <DataTableRowColumn>{r.RecoveredFromFailedReleaseVersion}</DataTableRowColumn>
                            <DataTableRowColumn>{formatDurationInWords(r.TimeToRecovery)}</DataTableRowColumn>
                        </DataTableRow>))}
                </>}/>);
}
