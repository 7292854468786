import { List } from "@octopusdeploy/design-system-components";
import type { GitHubUnknownRepository } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { GitHubRepositoryWithDisabled } from "./GitHubAppRepositoryListItem";
import { GitHubAppRepositoryListItem } from "./GitHubAppRepositoryListItem";
import { GitHubFooterMessage } from "./GitHubFooterMessage";
interface BaseGitHubAppRepositoryListProps<T> {
    repositories: T[];
    selectedRepositories: string[];
    setRepositorySelected: (selected: boolean, repositoryId: string) => void;
    disableInteraction?: boolean;
}
export function GitHubAppUnknownRepositoryList({ repositories, selectedRepositories, setRepositorySelected, disableInteraction }: BaseGitHubAppRepositoryListProps<GitHubUnknownRepository>) {
    return (<>
            {repositories.length > 0 && (<>
                    <List items={repositories} rowKey={(i) => i.RepositoryId} renderRow={(props) => <GitHubAppRepositoryListItem disableInteraction={disableInteraction} repository={props.item} selected={selectedRepositories.includes(props.item.RepositoryId)} onChange={setRepositorySelected}/>}/>
                </>)}
        </>);
}
export default function GitHubAppRepositoryList({ repositories, selectedRepositories, setRepositorySelected, disableInteraction }: BaseGitHubAppRepositoryListProps<GitHubRepositoryWithDisabled>) {
    return (<>
            {repositories.length > 0 && (<>
                    <List items={repositories} rowKey={(i) => i.RepositoryId} renderRow={(props) => <GitHubAppRepositoryListItem disableInteraction={disableInteraction} repository={props.item} selected={selectedRepositories.includes(props.item.RepositoryId)} onChange={setRepositorySelected}/>}/>
                </>)}

            {repositories.length === 0 && <GitHubFooterMessage title="No repositories found"/>}
        </>);
}
