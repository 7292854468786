import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { firstAuthorized } from "~/components/PermissionCheck/PermissionCheck";
interface DefaultLibraryPageRedirectProps {
    spaceId: string;
}
export function DefaultLibraryPageRedirect({ spaceId }: DefaultLibraryPageRedirectProps) {
    return <InternalRedirect to={getDefaultPage(spaceId)}/>;
}
function getDefaultPage(spaceId: string) {
    switch (firstAuthorized([Permission.FeedView, Permission.LifecycleEdit, Permission.VariableView, Permission.ActionTemplateView])) {
        case Permission.FeedView:
            return links.builtInRepositoryPage.generateUrl({ spaceId });
        case Permission.LifecycleEdit:
            return links.lifecyclesPage.generateUrl({ spaceId });
        case Permission.VariableView:
            return links.scriptModulesPage.generateUrl({ spaceId });
        default:
            return links.stepTemplatesPage.generateUrl({ spaceId });
    }
}
