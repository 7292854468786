import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
interface AddGitCredentialsAnalyticsEvent extends AnalyticsEvent<"Add Git Credentials"> {
    Location: "Drawer";
}
export function createAddGitCredentialsAnalyticsEvent(): AddGitCredentialsAnalyticsEvent {
    return {
        eventName: "Add Git Credentials",
        Location: "Drawer",
    };
}
interface SaveGitCredentialsAnalyticsEvent extends AnalyticsEvent<"Save Git Credentials"> {
    Location: "Drawer";
    "Entity Type": "New" | "Existing";
    Status: "Success";
}
export function createSaveGitCredentialsAnalyticsEvent(newOrExisting: "New" | "Existing"): SaveGitCredentialsAnalyticsEvent {
    return {
        eventName: "Save Git Credentials",
        Location: "Drawer",
        Status: "Success",
        "Entity Type": newOrExisting,
    };
}
