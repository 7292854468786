import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { StepConfigurationExportInputs } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs } from "@octopusdeploy/step-runtime-inputs";
import { asStepConfigurationExportInputs, convertFromJsonSchemaToInputSchema } from "@octopusdeploy/step-runtime-inputs";
import type { InputJsonSchema } from "@octopusdeploy/step-runtime-inputs/src/Schema/InputJsonSchema";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { CodeEditor, TextFormat } from "~/components/CodeEditor/CodeEditor";
import LargeInfoDialogLayout from "~/components/DialogLayout/LargeInfoDialogLayout";
import { useStepDataEnricher } from "~/components/StepPackageEditor/Inputs/OctopusServerEnricher";
const descriptionStyles = css({
    flexGrow: "0 !important",
    marginBottom: space[12],
});
interface ExportConfigurationDialogProps {
    name: string;
    description: string;
    inputs: unknown;
    inputSchema?: InputJsonSchema;
    generateExports: (configuredInputs: StepConfigurationExportInputs<unknown>) => string;
}
export const ExportConfigurationDialog = (props: ExportConfigurationDialogProps) => {
    const enricher = useStepDataEnricher();
    if (props.inputSchema === undefined) {
        return <></>;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const inputs = props.inputs as ObjectRuntimeInputs<unknown>;
    const inputSchema = convertFromJsonSchemaToInputSchema(props.inputSchema, inputs);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const configurationInputs = asStepConfigurationExportInputs(inputSchema.properties, inputs, enricher) as StepConfigurationExportInputs<unknown>;
    const editorText = props.generateExports(configurationInputs);
    return (<LargeInfoDialogLayout title={props.name}>
            <div className={descriptionStyles}>{props.description}</div>
            <CodeEditor value={editorText} language={TextFormat.YAML} allowFullScreen={false} readOnly={true} onChange={noOp} showToolbar={true} showCopyButton={true}/>
        </LargeInfoDialogLayout>);
};
