import { css } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import React from "react";
import type { ServerNodeCommsAddress } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialog";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Text } from "~/components/form/index";
import { DataTable, DataTableBody, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
type HaNodeConfigurationPageProps = {
    serverCommsAddresses: ServerNodeCommsAddress[];
    onServerCommsAddressesChange: (serverCommsAddresses: ServerNodeCommsAddress[]) => void;
    getFieldError: (fieldName: string) => string;
};
export const HaNodeConfigurationPage = (props: HaNodeConfigurationPageProps) => {
    const { getFieldError } = props;
    const firstColumnStyle = css({ width: "200px", maxWidth: "200px" });
    const defaultTextStyle = css({ font: text.interface.body.default.base });
    const nodeNameTextStyle = cn(defaultTextStyle, firstColumnStyle, css({ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }));
    const columnHeaderTextStyle = css({ font: text.interface.body.default.small, color: themeTokens.color.text.secondary, verticalAlign: "bottom" });
    const headerRowStyle = css({ height: space["48"], maxHeight: space["48"], verticalAlign: "bottom !important" });
    const normalRowStyle = css({ height: space["64"], maxHeight: space["64"], position: "relative", verticalAlign: "middle !important" });
    return (<>
            <div className={defaultTextStyle}>
                We detected multiple Octopus Server nodes used for High Availability. Please enter the communication URL for each node. <ExternalLink href={"KubernetesAgentHAClusterSupport"}>Learn more</ExternalLink>
            </div>
            <DataTable>
                <DataTableBody>
                    <DataTableRow displayNoBorder={true}>
                        <DataTableRowColumn fullWidth={true} fullHeight={true} className={cn(firstColumnStyle, headerRowStyle)}>
                            <div className={columnHeaderTextStyle}>Node</div>
                        </DataTableRowColumn>
                        <DataTableRowColumn fullHeight={true} className={headerRowStyle}>
                            <div className={columnHeaderTextStyle}>Communication Url</div>
                        </DataTableRowColumn>
                    </DataTableRow>
                    {props.serverCommsAddresses.map(({ nodeName, commsAddress }, index) => (<DataTableRow key={nodeName} displayNoBorder={true}>
                            <DataTableRowColumn fullWidth={true} fullHeight={true} className={cn(firstColumnStyle, normalRowStyle)}>
                                <Tooltip content={nodeName} position="top" display={"block"}>
                                    <div className={nodeNameTextStyle}>{nodeName}</div>
                                </Tooltip>
                            </DataTableRowColumn>
                            <DataTableRowColumn fullHeight={true} className={normalRowStyle}>
                                <Text customMargins={"0"} value={commsAddress} error={getFieldError(nodeName)} onChange={(val) => {
                props.serverCommsAddresses[index].commsAddress = val;
                props.onServerCommsAddressesChange(props.serverCommsAddresses);
            }} placeholder={"https://<server-node-url>:<port>/"}/>
                            </DataTableRowColumn>
                        </DataTableRow>))}
                </DataTableBody>
            </DataTable>
        </>);
};
