/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { ActionButton, ActionButtonType, Callout, CustomMenu, Divider, IconButton, MenuItemButton, MenuItemInformation, MenuItemInternalLink, MenuItemToggle, NavigationButton, NavigationButtonType, Switch, Tooltip, } from "@octopusdeploy/design-system-components";
import type { ActivityElement, GitRefResource, TaskDetailsResource } from "@octopusdeploy/octopus-server-client";
import { ActivityLogEntryCategory, ActivityStatus } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { concat, flatten, without } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import ActionList from "~/components/ActionList";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { Section } from "~/components/Section/Section";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import TaskLogBlock from "~/components/TaskLogLines/TaskLogBlock";
import TaskProgress from "../TaskProgress";
import styles from "./style.module.less";
interface TaskLogComponentProps {
    details: TaskDetailsResource;
    activityElements: UniqueActivityElement[];
    verbose: boolean;
    tail: boolean;
    initialExpandedId?: string;
    manuallyExpandedIds?: string[];
    onFetchRange(element: ActivityElement, start: number, end: number): void;
    isFetchDisabled: boolean;
    setVerbose(value: boolean): void;
    processId?: string;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    gitRef?: GitRefResource;
    onExpandedIdsChanged?: (expandedIds: string[]) => void;
}
type TaskLogProps = TaskLogComponentProps & RouteComponentProps<any>;
interface TaskDetailState {
    expandedIds: string[];
    expandMode: ExpandMode;
    showTimestamps: boolean;
    showAdvancedMenu: boolean;
    showExpandMenu: boolean;
    reloadCount?: number;
    advancedButtonElement?: Element;
    expandButtonElement?: Element;
}
enum ExpandMode {
    All = "All",
    Interesting = "Interesting",
    Errors = "Errors",
    None = "None",
    Custom = "Custom"
}
const timingsLocalStorageKey = "Octopus.TaskLog.ShowTimings";
//eslint-disable-next-line react/no-unsafe
class TaskLog extends React.Component<TaskLogProps, TaskDetailState> {
    reloadCount = 0;
    constructor(props: TaskLogProps) {
        super(props);
        // noinspection TsLint
        this.state = {
            showAdvancedMenu: false,
            showExpandMenu: false,
            showTimestamps: localStorage.getItem(timingsLocalStorageKey) == "true" ?? false,
            expandedIds: this.getInterestingIds(),
            expandMode: ExpandMode.Interesting,
        };
    }
    componentDidMount() {
        this.reloadCount++;
        if (this.props.initialExpandedId) {
            this.updateStateWithInitialExpandedId(this.props);
        }
        if (this.props.manuallyExpandedIds) {
            this.setState({ expandedIds: this.props.manuallyExpandedIds, expandMode: ExpandMode.Custom, reloadCount: this.reloadCount });
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: TaskLogProps) {
        this.reloadCount++;
    }
    updateStateWithInitialExpandedId(props: TaskLogProps) {
        if (props.initialExpandedId) {
            this.setState({
                expandedIds: this.getParents(props.initialExpandedId),
                expandMode: ExpandMode.Custom,
                reloadCount: this.reloadCount,
            });
        }
    }
    getParents(id: string) {
        const seperator = "/";
        const firstSection = id.indexOf(seperator);
        const ids = [id];
        if (firstSection === -1) {
            // No first section... weird..
            return ids;
        }
        let parentSection = id.lastIndexOf(seperator);
        while (parentSection !== -1) {
            const parentId = id.substring(0, parentSection);
            parentSection = parentId.lastIndexOf(seperator);
            ids.push(parentId);
        }
        return ids;
    }
    setExpanded = (id: string, expanded: boolean) => {
        this.setState((prevState) => {
            const expandedIds = expanded ? concat(prevState.expandedIds, id) : without(prevState.expandedIds, id);
            this.props.onExpandedIdsChanged?.(expandedIds);
            return { expandedIds, expandMode: ExpandMode.Custom, focusId: null, showTimestamps: prevState.showTimestamps, showAdvancedMenu: prevState.showAdvancedMenu, showExpandMenu: prevState.showExpandMenu };
        });
    };
    getElementIdsMatching(predicate: (element: UniqueActivityElement) => boolean) {
        return flatten(this.props.activityElements.map((e) => this.getElementsMatchingRecursive(e, predicate))).map((e) => e.uniqueId);
    }
    getElementsMatchingRecursive(element: UniqueActivityElement, predicate: (element: UniqueActivityElement) => boolean): UniqueActivityElement[] {
        const ids = flatten(element.Children.map((l) => this.getElementsMatchingRecursive(l as UniqueActivityElement, predicate)));
        if (ids.length > 0 || predicate(element)) {
            ids.push(element);
        }
        return ids;
    }
    expand(expandMode: ExpandMode) {
        this.setState({ expandedIds: this.getIdsToExpand(expandMode), expandMode });
    }
    getIdsToExpand(mode: ExpandMode) {
        switch (mode) {
            case ExpandMode.All:
                return this.getElementIdsMatching((e) => true);
            case ExpandMode.Interesting:
                return this.getInterestingIds();
            case ExpandMode.Errors:
                return this.getElementIdsMatching((e) => e.LogElements.filter((l) => l.Category === ActivityLogEntryCategory.Error || l.Category === ActivityLogEntryCategory.Fatal).length > 0);
            case ExpandMode.None:
                return [];
            default:
                return this.state.expandedIds;
        }
    }
    getInterestingIds() {
        return this.getElementIdsMatching((e) => e.Status === ActivityStatus.Pending ||
            e.Status === ActivityStatus.Running ||
            e.Status === ActivityStatus.Canceled ||
            e.LogElements.filter((l) => l.Category === ActivityLogEntryCategory.Error || l.Category === ActivityLogEntryCategory.Fatal).length > 0);
    }
    renderActivityLogs = (element: UniqueActivityElement) => {
        const focusId = this.state.reloadCount === this.reloadCount ? this.props.initialExpandedId : null;
        return (<TaskLogBlock key={element.uniqueId} element={element} taskState={this.props.details.Task.State} collapsible={true} expandedIds={this.state.expandedIds} focusId={focusId!} showRunTime={true} showTimestamps={this.state.showTimestamps} setExpanded={(id, expanded) => this.setExpanded(id, expanded)} onFetchRange={this.props.onFetchRange} isFetchDisabled={this.props.isFetchDisabled} processId={this.props.processId} stepsCorrelationIds={this.props.stepsCorrelationIds} gitRef={this.props.gitRef} parentDuration={0}/>);
    };
    render() {
        const details = this.props.details;
        const hasWritten = details.ActivityLogs.filter((l) => l.LogElements.length > 0 || l.Children.length > 0).length > 0;
        const toggleTimings = () => {
            const value = !this.state.showTimestamps;
            localStorage.setItem(timingsLocalStorageKey, value ? "true" : "");
            this.setState({
                showTimestamps: value,
            });
        };
        const toggleAdvancedMenu = (e: React.MouseEvent) => {
            e.preventDefault();
            this.setState({
                showAdvancedMenu: !this.state.showAdvancedMenu,
                advancedButtonElement: e.currentTarget,
            });
        };
        const toggleExpandMenu = (e: React.MouseEvent) => {
            e.preventDefault();
            this.setState({
                showExpandMenu: !this.state.showExpandMenu,
                expandButtonElement: e.currentTarget,
            });
        };
        return (<div>
                <Section bodyClassName={styles.taskLogHeading}>
                    <div>
                        <TaskProgress details={details}/>
                    </div>
                    <div className={cn(styles.taskActions)}>
                        <ActionList actions={[
                <Switch value={this.props.verbose} onChange={(value) => this.props.setVerbose(value)} label="Verbose"/>,
                <ActionButton type={ActionButtonType.Ternary} onClick={toggleExpandMenu} label="Expand/Collapse" icon={<DropDownIcon />} iconPosition="right"/>,
                <NavigationButton type={NavigationButtonType.Ternary} icon={<em className={cn("fa-solid", `fa-download`)} aria-hidden="true"/>} href={details.Links["Raw"]} label="Download" external={true}/>,
                <IconButton icon="Settings" onClick={toggleAdvancedMenu} accessibleName={`Settings`}/>,
            ]}/>

                        <CustomMenu isOpen={this.state.showExpandMenu} anchorElement={this.state.expandButtonElement || null} menuId="a" accessibleName="A" onClose={() => this.setState({
                showExpandMenu: !this.state.showExpandMenu,
                expandButtonElement: undefined,
            })}>
                            <MenuItemButton onClick={() => this.expand(ExpandMode.All)}>Expand all</MenuItemButton>
                            <MenuItemButton onClick={() => this.expand(ExpandMode.Interesting)}>Expand interesting</MenuItemButton>
                            <MenuItemButton onClick={() => this.expand(ExpandMode.Errors)}>Expand errors</MenuItemButton>
                            <MenuItemButton onClick={() => this.expand(ExpandMode.None)}>Collapse all</MenuItemButton>
                        </CustomMenu>

                        <CustomMenu isOpen={this.state.showAdvancedMenu} anchorElement={this.state.advancedButtonElement || null} menuId="a" accessibleName="A" onClose={() => this.setState({
                showAdvancedMenu: !this.state.showAdvancedMenu,
                advancedButtonElement: undefined,
            })}>
                            <MenuItemToggle isEnabled={this.state.showTimestamps} label="Show log timestamps and categories" setIsEnabled={(value) => toggleTimings()}/>
                            <Divider />
                            {this.props.details.PhysicalLogSize < 10 * 1024 * 1024 ? (<MenuItemInternalLink path={links.taskRawLogPage.generateUrl({ taskId: details.Task.Id })} label="View raw log"/>) : (<MenuItemInformation>
                                    <Tooltip content="This is a large log file and may cause your browser to freeze or crash. Please download the file instead.">View raw log</Tooltip>
                                </MenuItemInformation>)}
                        </CustomMenu>
                    </div>
                </Section>
                <Section>
                    {hasWritten ? (<div>{this.props.activityElements.map(this.renderActivityLogs)}</div>) : (<Callout type={"information"} title="No Logs">
                            The task has not written any information to the log yet.
                        </Callout>)}
                </Section>
            </div>);
    }
    static displayName = "TaskLog";
}
export default withRouter(TaskLog);
