import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "./CommonViews";
export const CommonConfigurationAuditOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>For team members to collaborate in the deployment of software, there needs to be trust and accountability. Octopus Deploy captures audit information whenever significant events happen in the system.</p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonConfigurationAuditTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="Auditing">Auditing</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
