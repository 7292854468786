import React, { forwardRef, useImperativeHandle } from "react";
import type { TemplateValuesSectionComponent, TemplateValuesSectionProps } from "~/components/Actions/helmChartUpgrade/HelmTemplateValuesDrawer";
import type { ChartTemplateValuesSource } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { ExpandableFormSection, Note, Summary } from "~/components/form/index";
export const ChartTemplateValuesSection = forwardRef<TemplateValuesSectionComponent, TemplateValuesSectionProps<ChartTemplateValuesSource>>((props: TemplateValuesSectionProps<ChartTemplateValuesSource>, ref) => {
    const [valuesFilePaths, setValuesFilePaths] = React.useState<string>(props.editingTemplateValuesSource?.ValuesFilePaths || "");
    useImperativeHandle(ref, () => ({
        validateAndSave: () => {
            if (!valuesFilePaths) {
                return "A values file must be defined";
            }
            return {
                Type: "Chart",
                ValuesFilePaths: valuesFilePaths,
            };
        },
    }));
    return (<ExpandableFormSection errorKey="Octopus.Action.Package.ValuesFilePath" title={"Files in the chart"} summary={Summary.summary("Files sourced from the chart")} help={<span>Files sourced from the chart</span>} isExpandedByDefault={true}>
            <VariableLookupText localNames={props.localNames} value={valuesFilePaths} onChange={(path) => setValuesFilePaths(path)} label="File(s)" multiline={true}/>
            <Note>
                A newline-separated list of file names, relative to the chart root to be included as additional <code>--values</code> files. Variable replacement will be run on these files before used. Extended template and wildcard syntax is
                supported. E.g., <em>values.{`#{Octopus.Environment.Name}`}.yaml</em>, <em>**\specific-folder\*.yaml</em>.
            </Note>
        </ExpandableFormSection>);
});
