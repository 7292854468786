import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
export default class MissingImportExportPermissionCallout extends React.Component {
    render() {
        return (<Callout title="Permission Required" type={"information"}>
                The Space Manager permission is required to import/export projects from this space.
            </Callout>);
    }
    static displayName = "MissingImportExportPermissionCallout";
}
