import { ProcessType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProcessPaperLayout } from "~/areas/projects/components/Process/CustomPaperLayouts/ProcessPaperLayout";
import ProcessListLayoutDataLoader from "~/areas/projects/components/Process/ProcessListLayoutLoader";
import { useRunbookContext } from "~/areas/projects/components/Runbooks/RunbookContext";
export function RunbookProcessListPage() {
    const context = useRunbookContext();
    if (!context.state.runbook) {
        return <ProcessPaperLayout processType={ProcessType.Runbook} busy={true}/>;
    }
    return <ProcessListLayoutDataLoader processId={context.state.runbook.RunbookProcessId} processType={ProcessType.Runbook}/>;
}
