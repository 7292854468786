import * as React from "react";
import { IconButtonWithOptionalTooltip } from "~/components/IconButtonWithTooltip";
interface CloseButtonProps {
    onClose?: () => void;
    tooltip?: string;
    size?: string;
}
const CloseButton: React.SFC<CloseButtonProps> = (props) => {
    const style: React.CSSProperties = { verticalAlign: "middle", padding: "0" };
    if (props.size) {
        style.width = props.size;
        style.height = props.size;
    }
    return <IconButtonWithOptionalTooltip accessibleName="Close Button" style={style} onClick={props.onClose} toolTipContent={props.tooltip} icon="Cancel"/>;
};
CloseButton.displayName = "CloseButton"
CloseButton.displayName = "CloseButton";
export default CloseButton;
