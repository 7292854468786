import { css } from "@emotion/css";
import React from "react";
import Logo from "~/components/Logo/index";
interface LogoGroupProps {
    logoLinks: string[];
    size?: string;
}
export default function LogoGroup({ logoLinks, size }: LogoGroupProps) {
    return (<div className={styles.container}>
            {logoLinks.map((logoLink) => (<div className={styles.logoContainer} key={logoLink}>
                    <Logo url={logoLink} size={size}/>
                </div>))}
        </div>);
}
const styles = {
    container: css({
        display: "flex",
    }),
    logoContainer: css({
        marginRight: "-20px",
        backgroundColor: "white",
        borderRadius: "50px",
        boxShadow: "0 0 3px grey",
    }),
};
