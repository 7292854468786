import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
import { ProjectContextPaperLayout } from "./ProjectContextPaperLayout";
type ProcessPaperLayoutProps = Level2PageLayoutProps & {
    processType: ProcessType;
};
export const ProcessPaperLayout: React.FC<ProcessPaperLayoutProps> = ({ disableAnimations = true, children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return (<RunbooksPaperLayout disableAnimations={disableAnimations} fullWidth={true} {...rest}>
                {children}
            </RunbooksPaperLayout>);
    }
    else {
        return (<ProjectContextPaperLayout disableAnimations={disableAnimations} fullWidth={true} {...rest}>
                {children}
            </ProjectContextPaperLayout>);
    }
};
ProcessPaperLayout.displayName = "ProcessPaperLayout"
