import { ActionButton } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { PackagingInstructionSelector } from "~/areas/library/components/BuiltInRepository/PackagingOnboarding/PackagingInstructionSelector";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { CustomInfoDialogLayout, MediumDialogFrame } from "~/components/DialogLayout/Custom";
interface Props {
    label: string;
    asSecondaryButton?: boolean;
}
interface State {
    showDialog: boolean;
}
export class HowToCreateYourPackageDialogLink extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDialog: false,
        };
    }
    render() {
        return (<>
                <CustomDialog open={this.state.showDialog} close={this.closeDialog} render={(renderProps) => (<CustomInfoDialogLayout title={"How to Create Packages"} open={this.state.showDialog} close={this.closeDialog} frame={MediumDialogFrame}>
                            <PackagingInstructionSelector />
                        </CustomInfoDialogLayout>)}/>
                {this.props.asSecondaryButton ? (<ActionButton onClick={this.showDialog} label={this.props.label}/>) : (<a href="#" onClick={this.showDialog}>
                        {this.props.label}
                    </a>)}
            </>);
    }
    private showDialog = (e: React.MouseEvent) => {
        e.preventDefault();
        this.setState({ showDialog: true });
    };
    private closeDialog = () => {
        this.setState({ showDialog: false });
    };
    static displayName = "HowToCreateYourPackageDialogLink";
}
