import { css } from "@emotion/css";
import { Divider } from "@octopusdeploy/design-system-components";
import * as React from "react";
import NumberedPagingBar from "~/areas/tenants/components/Paging/NumberedPagingBar";
export const defaultPanelSelectorDialogPageSize = 30;
interface PagingSectionProps {
    itemCount: number;
    pageNumber: number;
    pageSize: number;
    onPagingSelectionChange: (newPageNumber: number, newPageSize: number) => void;
}
export function PagingSection({ itemCount, pageNumber, pageSize, onPagingSelectionChange }: PagingSectionProps) {
    if (itemCount < defaultPanelSelectorDialogPageSize)
        return null;
    return (<>
            <Divider />
            <div className={pagingBar}>
                <NumberedPagingBar totalItems={itemCount} pageNumber={pageNumber} pageSize={pageSize} onPagingSelectionChange={onPagingSelectionChange} pageSizeOptions={[defaultPanelSelectorDialogPageSize]}/>
            </div>
        </>);
}
const pagingBar = css({
    padding: "1rem",
});
