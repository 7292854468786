import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { TentacleCalloutHeading } from "./TentacleCalloutHeading";
export const LinuxPollingTentacleCallout: React.SFC<{}> = () => (<div>
        <h3>Installation Instructions</h3>
        <TentacleCalloutHeading />
        <p>
            For getting started instructions please visit the <ExternalLink href="LinuxListeningTentacle">Linux Tentacle documentation</ExternalLink>.
        </p>
    </div>);
LinuxPollingTentacleCallout.displayName = "LinuxPollingTentacleCallout"
export default LinuxPollingTentacleCallout;
