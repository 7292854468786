export interface AccessRule {
    Identity?: string;
    Access: AccessType;
}
export enum AccessType {
    ReadOnly = "ReadOnly",
    FullControl = "FullControl"
}
export enum StoreLocations {
    LocalMachine = "LocalMachine",
    CurrentUser = "CurrentUser",
    CustomUser = "Custom User"
}
export enum StoreNames {
    My = "My",
    Root = "Root",
    CA = "CA",
    TrustedPeople = "TrustedPeople",
    TrustedPublisher = "TrustedPublisher",
    AuthRoot = "AuthRoot",
    AddressBook = "AddressBook",
    Other = "Other"
}
