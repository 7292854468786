import * as React from "react";
import { RoleChip } from "~/components/Chips/index";
import ParseHelper from "../../utils/ParseHelper/ParseHelper";
import TargetList from "./TargetList";
interface RolesProps {
    rolesAsCSV: string;
}
class RolesList extends TargetList<string> {
}
const Roles: React.StatelessComponent<RolesProps> = (props) => {
    const roles = ParseHelper.parseCSV(props.rolesAsCSV);
    return (<span>
            {roles.length === 1 ? "the tag" : "the tags"} <RolesList targets={roles} renderTarget={(role) => <RoleChip key={role} role={role}/>}/>
        </span>);
};
Roles.displayName = "Roles"
Roles.displayName = "Roles";
export default Roles;
