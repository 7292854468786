import type { ActionProperties, DeploymentActionResource } from "@octopusdeploy/octopus-server-client";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
export function getBlueprintIdFromAction(action: DeploymentActionResource, uriEncode: boolean = false) {
    return getBlueprintIdFromActionProperties(action.Properties, uriEncode);
}
export function getBlueprintIdFromActionProperties(actionProperties: ActionProperties, uriEncode: boolean = false) {
    const selectedProject = convertPropertyValueResourceToString(actionProperties["Octopus.Action.Blueprint.Reference.ProjectId"]);
    const selectedGitRef = convertPropertyValueResourceToString(actionProperties["Octopus.Action.Blueprint.Reference.Commit"]) || convertPropertyValueResourceToString(actionProperties["Octopus.Action.Blueprint.Reference.Branch"]);
    const selectedSlug = convertPropertyValueResourceToString(actionProperties["Octopus.Action.Blueprint.Reference.Slug"]);
    if (selectedProject && selectedGitRef && selectedSlug) {
        return uriEncode ? encodeURIComponent(`${selectedProject}|${selectedGitRef}|${selectedSlug}`) : `${selectedProject}|${selectedGitRef}|${selectedSlug}`;
    }
    else {
        return undefined;
    }
}
