/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { useIsUrlActive } from "@octopusdeploy/design-system-components";
import type { Url } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { withRouter } from "react-router-dom";
import type { RouteComponentProps } from "react-router-dom";
import { isUrl } from "~/components/Navigation/InternalLink/isUrl";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { useUrlResolver } from "~/components/Navigation/useUrlResolver";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs/index";
interface TemplateTab {
    text: string;
    link: Url;
    warning: string | undefined;
}
function warning(yes: boolean): string | undefined {
    return yes ? "Some of the required variable values have not been set" : undefined;
}
export interface TemplatesWarnings {
    projectTemplates: boolean;
    commonTemplates: boolean;
}
interface ProjectTenantVariablesTabsProps extends RouteComponentProps<{
    spaceId: string;
    projectSlug: string;
}> {
    warnings: TemplatesWarnings;
}
function ProjectTenantVariablesTabsInternal(props: ProjectTenantVariablesTabsProps) {
    const isUrlActive = useIsUrlActive();
    const urlResolver = useUrlResolver();
    const navigation = useSpaceAwareNavigation();
    const spaceId = props.match.params.spaceId;
    const projectSlug = props.match.params.projectSlug;
    const tabs: TemplateTab[] = [
        { text: "Project Templates", link: links.projectTenantProjectTemplatesPage.generateUrl({ spaceId, projectSlug }), warning: warning(props.warnings.projectTemplates) },
        { text: "Common Templates", link: links.projectTenantCommonTemplatesPage.generateUrl({ spaceId, projectSlug }), warning: warning(props.warnings.commonTemplates) },
    ];
    const activeTabOrDefault = tabs.find((t) => isUrlActive(t.link, "if path partially matches")) || tabs[0];
    const activeLink = isUrl(activeTabOrDefault.link) ? urlResolver.resolve(activeTabOrDefault.link) : activeTabOrDefault.link;
    return (<ControlledTabsContainer value={activeLink} onChange={(link) => navigation.navigate(link)}>
            {tabs.map((t) => {
            const resolvedLink = isUrl(t.link) ? urlResolver.resolve(t.link) : t.link;
            return <TabItem key={resolvedLink} label={t.text} value={resolvedLink} warning={t.warning}></TabItem>;
        })}
        </ControlledTabsContainer>);
}
const ProjectTenantVariablesTabs = withRouter(ProjectTenantVariablesTabsInternal);
export default ProjectTenantVariablesTabs;
