import React from "react";
// Root path here is the app folder
const allPngs = require.context("../../../", true, /\.png$/);
export function AllPngImageFiles() {
    return (<div style={{ paddingLeft: "1rem" }}>
            <p>This is the complete set of all *.png image files in the portal package. Note that this excludes any images from the design system.</p>
            <AllImages />
        </div>);
}
function AllImages() {
    return (<>
            {allPngs.keys().map((pngName) => (<PngImage pngName={pngName} pngPath={allPngs(pngName)}/>))}
        </>);
}
function PngImage({ pngName, pngPath }: {
    pngName: string;
    pngPath: string;
}) {
    return (<>
            <h2>{pngName}</h2>
            <div style={{ paddingLeft: "1rem" }}>
                <img style={{ maxHeight: "500px", maxWidth: "500px" }} src={pngPath} alt={pngName}/>
            </div>
        </>);
}
