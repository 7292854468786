/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { MouseSensor, TouchSensor } from "@dnd-kit/core";
import type { MouseEvent, TouchEvent } from "react";
// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
    let cur = event.target as HTMLElement;
    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false;
        }
        cur = cur.parentElement as HTMLElement;
    }
    return true;
};
export class CustomMouseSensor extends MouseSensor {
    static activators = [{ eventName: "onMouseDown", handler }] as (typeof MouseSensor)["activators"];
}
export class CustomTouchSensor extends TouchSensor {
    static activators = [{ eventName: "onTouchStart", handler }] as (typeof TouchSensor)["activators"];
}
