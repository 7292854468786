/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { CertificateResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { createAddCertificateAnalyticsEvent } from "~/areas/library/components/Certificates/Certificate/amplitudeAnalytics";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import CertificateSearch from "~/components/form/CertificateSearch/CertificateSearch";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import CertificateOnboarding from "./CertificateOnboarding/CertificateOnboarding";
import CertificateSidebar from "./CertificateSidebar";
interface CertificatesPageProps {
    spaceId: string;
    trackEvent: ReturnType<typeof useTrackEvent>;
}
interface CertificatesState extends DataBaseComponentState {
    certificates?: ResourceCollection<CertificateResource>;
}
class CertificatesPage extends DataBaseComponent<CertificatesPageProps, CertificatesState> {
    constructor(props: CertificatesPageProps) {
        super(props);
        this.state = {
            certificates: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const certificates = await repository.Certificates.list();
            this.setState({
                certificates,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const addCertificatePageAction: PrimaryPageAction = {
            type: "navigate",
            hasPermissions: isAllowed({ permission: Permission.CertificateCreate, wildcard: true }),
            label: "Add Certificate",
            path: links.createCertificatePage.generateUrl({ spaceId: this.props.spaceId }),
            onClick: () => {
                this.props.trackEvent(createAddCertificateAnalyticsEvent("Page"));
            },
        };
        const archivePageAction: PageAction = {
            type: "navigate",
            buttonType: "secondary",
            label: "View archive",
            path: links.archiveCertificatePage.generateUrl({ spaceId: this.props.spaceId }),
        };
        const sidebar = this.state.certificates && this.state.certificates.TotalResults > 0 ? <CertificateSidebar /> : null;
        const content = this.state.certificates && (this.state.certificates.TotalResults > 0 ? <CertificateSearch certificates={this.state.certificates} doBusyTask={this.doBusyTask}/> : <CertificateOnboarding />);
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayoutVNext title="Certificates" primaryAction={addCertificatePageAction} pageActions={[archivePageAction]} busy={this.state.busy} errors={this.errors}>
                    <SidebarLayout sideBar={sidebar}>{content}</SidebarLayout>
                </PaperLayoutVNext>
            </LibraryLayout>);
    }
    static displayName = "CertificatesPage";
}
const CertificatesPageFC = (props: Omit<CertificatesPageProps, "trackEvent">) => {
    const trackEvent = useTrackEvent();
    return <CertificatesPage trackEvent={trackEvent} {...props}/>;
};
export default CertificatesPageFC;
