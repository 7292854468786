import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationSpaceOverview } from "../../CommonComponents/CommonConfigurationSpaceViews";
export const ConfigurationSpaceRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.space,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationSpaceOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
