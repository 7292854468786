import * as React from "react";
import Markdown from "../Markdown";
import styles from "./style.module.less";
interface MarkdownDescriptionProps {
    markup: string;
}
// This is purely to give us a styling wrapper for all resource descriptions (eg. when shown in lists etc).
const MarkdownDescription: React.StatelessComponent<MarkdownDescriptionProps> = (props) => {
    return props.markup ? (<div className={styles.description}>
            <Markdown markup={props.markup}/>
        </div>) : null;
};
MarkdownDescription.displayName = "MarkdownDescription"
export default MarkdownDescription;
