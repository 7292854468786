import type { PageAction } from "@octopusdeploy/design-system-components";
import type { ConvertProjectToVersionControlledCommand, GitCredentialResource, GitHubAppConnections, GitPersistenceSettings, SpaceResource, GitHubAppConnectionSummary } from "@octopusdeploy/octopus-server-client";
import { OctopusError, HasGitPersistenceSettings, AuthenticationType, Permission, PersistenceSettingsType, Repository } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useCallback, useState } from "react";
import VersionControlSettingsConnectionDetails from "~/areas/projects/components/ProjectSettings/VersionControl/Connection/VersionControlSettingsConnectionDetails";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent/index";
import { Form } from "~/components/FormPaperLayout/Form";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, FormSection, FormSectionHeading, MarkdownEditor, Note, required, Select, Summary, Text } from "~/components/form";
interface BlueprintRepositoryModel extends GitPersistenceSettings {
    SpaceId: string;
    Name: string;
    Slug: string;
    Description: string;
}
interface BlueprintsRepositoryInternalPageProps {
    model: BlueprintRepositoryModel;
    cleanModel: BlueprintRepositoryModel;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    setModelState: <K extends keyof BlueprintRepositoryModel>(state: Pick<BlueprintRepositoryModel, K>, callback?: () => void) => void;
    getFieldError: (name: string) => string;
    setModel: (model: BlueprintRepositoryModel) => void;
    doBusyTask: DoBusyTask;
    projectId?: string;
}
const defaultModel: BlueprintRepositoryModel = {
    SpaceId: "",
    Name: "",
    Slug: "",
    Description: "",
    BasePath: "",
    DefaultBranch: "main",
    ProtectedDefaultBranch: false,
    Url: "",
    Credentials: {
        Type: AuthenticationType.Reference,
        Id: "",
    },
    ProtectedBranchNamePatterns: [],
    Type: PersistenceSettingsType.VersionControlled,
    ConversionState: {
        VariablesAreInGit: false,
        RunbooksAreInGit: false,
    },
};
function ConnectBlueprintsRepositoryInternalPage({ model, cleanModel, busy, errors, setModelState, getFieldError, setModel, doBusyTask, projectId }: BlueprintsRepositoryInternalPageProps) {
    const gitHubConnectionsAreEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    const navigation = useSpaceAwareNavigation();
    const cancelAction: PageAction = {
        type: "navigate",
        buttonType: "secondary",
        label: "Cancel",
        path: links.blueprintsRepositoriesPage.generateUrl({ spaceId: repository.spaceId || "" }),
    };
    const handleSave = async () => {
        await doBusyTask(async () => {
            const scopedRepository = await repository.forSpace(model.SpaceId);
            const projectGroupName = "Preview Blueprints Feature";
            const projectGroups = await scopedRepository.ProjectGroups.all();
            const lifecycles = await scopedRepository.Lifecycles.all();
            const defaultLifecycle = lifecycles.find((l) => l.Name === "Default Lifecycle") || lifecycles[0];
            let projectGroup = projectGroups.find((pg) => pg.Name === projectGroupName);
            if (!projectGroup) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                projectGroup = await scopedRepository.ProjectGroups.create({ Name: projectGroupName, EnvironmentIds: [], RetentionPolicyId: null, Links: null!, Id: null! });
            }
            const projects = await scopedRepository.Projects.list({ partialName: `Blueprints - ${model.Name}` });
            let project = projects.Items[0];
            if (project && HasGitPersistenceSettings(project.PersistenceSettings)) {
                throw new OctopusError(0, "A blueprint repository with the same name already exists in this space.");
            }
            if (!project) {
                project = await scopedRepository.Projects.create({
                    Name: `Blueprints - ${model.Name}`,
                    Description: model.Description,
                    ProjectGroupId: projectGroup.Id,
                    ProjectConnectivityPolicy: { AllowDeploymentsToNoTargets: true },
                    LifecycleId: defaultLifecycle.Id,
                });
            }
            const vcsSettingsAndCommitMessage: ConvertProjectToVersionControlledCommand = {
                CommitMessage: "Creating blueprint repository",
                VersionControlSettings: {
                    ...model,
                    BasePath: `.octopus/${model.BasePath}`,
                },
                InitialCommitBranchName: model.DefaultBranch,
            };
            await repository.Projects.convertToVcs(project, vcsSettingsAndCommitMessage);
            setModel(model);
            navigation.navigate(links.blueprintsRepositoriesPage.generateUrl({ spaceId: model.SpaceId }));
        });
    };
    const readOnly = !!projectId;
    const disabled = !!busy || readOnly;
    const [spaces, setSpaces] = useState<SpaceResource[]>([]);
    const [connections, setConnections] = useState<GitHubAppConnections | undefined>(undefined);
    const [gitCredentials, setGitCredentials] = useState<GitCredentialResource[] | undefined>(undefined);
    const refreshGitConnections = useDoBusyTaskEffect(doBusyTask, async () => {
        if (model.SpaceId === "") {
            return;
        }
        if (gitHubConnectionsAreEnabled && hasPermission(Permission.GitCredentialView)) {
            const scopedRepository = await repository.forSpace(model.SpaceId);
            const connections = await scopedRepository.GitHubApp.getConnections(0, Repository.takeAll);
            setConnections(connections);
        }
        else {
            setConnections(undefined);
        }
    }, [gitHubConnectionsAreEnabled, model.SpaceId]);
    const refreshGitCredentials = useDoBusyTaskEffect(doBusyTask, async () => {
        if (model.SpaceId === "") {
            return;
        }
        if (hasPermission(Permission.GitCredentialView)) {
            const scopedRepository = await repository.forSpace(model.SpaceId);
            // SelectWithAddRefresh does not have a filter re-search capability. Not going to break that
            // ground here, instead just get all of the credentials and show them in the list.
            const gitCredentials = await scopedRepository.GitCredentials.list({ take: Repository.takeAll });
            setGitCredentials(gitCredentials.Items);
        }
        else {
            setGitCredentials([]);
        }
    }, [model.SpaceId]);
    const getRepositoriesForConnection = useCallback(async (connection: GitHubAppConnectionSummary) => {
        if (model.SpaceId === "") {
            return;
        }
        const scopedRepository = await repository.forSpace(model.SpaceId);
        return await scopedRepository.GitHubApp.getRepositoriesForConnection(connection.Id ?? "");
    }, [model.SpaceId]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const spaces = await repository.Spaces.all();
        setSpaces(spaces);
    }, []);
    return (<Form model={model} cleanModel={cleanModel} onSaveClick={handleSave} saveText="Repository connected">
            {({ FormContent, createSaveAction }) => (<>
                    {model && (<PaperLayoutVNext title={projectId ? "Repository Details" : "Connect Repository"} primaryAction={readOnly ? undefined : createSaveAction({})} pageActions={readOnly ? [] : [cancelAction]} busy={busy} errors={errors}>
                            <FormContent>
                                <FormSection title="Name and description">
                                    <Text value={model.Name} onChange={(Name) => setModelState({ Name })} label="Repository name" error={getFieldError("name")} validate={required("Please enter a repository name")} disabled={disabled}/>
                                    <MarkdownEditor value={model.Description} label="Description" onChange={(Description) => setModelState({ Description })} disabled={disabled}/>
                                </FormSection>
                                <FormSection title="Space">
                                    <Select items={spaces.map((s) => ({ value: s.Id, text: s.Name }))} label={"Spaces"} value={model.SpaceId} onChange={(spaceId) => setModelState({ SpaceId: spaceId ?? "", Credentials: defaultModel.Credentials })} disabled={disabled}/>
                                </FormSection>
                                <FormSectionHeading title="Version Control"></FormSectionHeading>
                                <TransitionAnimation>
                                    <VersionControlSettingsConnectionDetails busy={busy} getFieldError={getFieldError} spaceId={model.SpaceId} isNew={model.Url === ""} gitCredentials={gitCredentials} refreshGitCredentials={refreshGitCredentials} connections={connections} refreshGitConnections={async () => {
                    await refreshGitConnections();
                }} getRepositoriesForConnection={getRepositoriesForConnection} url={model.Url} setUrl={(Url) => setModelState({ Url })} credential={model.Credentials} setCredential={(Credentials) => setModelState({ Credentials })}/>
                                </TransitionAnimation>
                                <ExpandableFormSection errorKey="BasePath" title="Git File Storage Directory" summary={Summary.summary(model.BasePath)} help="The directory where Octopus should store the files in the repository" isExpandedByDefault={true}>
                                    <Text inputProps={{
                    startAdornment: (<>
                                                    <code>.octopus</code>
                                                    &nbsp;/
                                                </>),
                }} key="BasePath" value={model.BasePath} onChange={(BasePath) => setModelState({ BasePath })} label="Git File Storage Directory" error={getFieldError("BasePath")} disabled={disabled} accessibleName={"Directory in the repository where blueprint files should be stored"}/>
                                    <Note>This controls where in your repository the blueprints will be written. Blueprints will be stored</Note>
                                    <Note>
                                        If this is the only blueprint repository which will be persisted in the repository, <code>.octopus</code> is a good option. If multiple repositories will be stored in the same repository, adding the blueprint
                                        repository name to the path is the recommended convention, e.g. <code>.octopus/acme</code>. Blueprints will be stored within a <code>blueprints</code> subdirectory.
                                    </Note>
                                </ExpandableFormSection>
                                <ExpandableFormSection errorKey="DefaultBranch" title="Branch Settings" summary={Summary.summary(<span>
                                            Default branch is <strong>{model.DefaultBranch}</strong>
                                        </span>)} help="Enter the details for your branches" isExpandedByDefault={true}>
                                    <Text key="DefaultBranch" value={model.DefaultBranch} onChange={(DefaultBranch) => setModelState({ DefaultBranch })} label="Default Branch" error={getFieldError("DefaultBranch")} validate={required("Enter a default branch.")} disabled={disabled} accessibleName={"Name of the default branch on the Git repository"}/>
                                </ExpandableFormSection>
                            </FormContent>
                        </PaperLayoutVNext>)}
                </>)}
        </Form>);
}
interface ConnectBlueprintsRepositoryPageProps {
    projectId?: string;
    spaceId?: string;
}
export class ConnectBlueprintsRepositoryPage extends FormBaseComponent<ConnectBlueprintsRepositoryPageProps, FormBaseComponentState<BlueprintRepositoryModel>, BlueprintRepositoryModel> {
    constructor(props: {}) {
        super(props);
        this.state = {
            model: defaultModel,
            cleanModel: defaultModel,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (this.props.spaceId && this.props.projectId) {
                const scopedRepository = await repository.forSpace(this.props.spaceId);
                const project = await scopedRepository.Projects.get(this.props.projectId);
                if (HasGitPersistenceSettings(project.PersistenceSettings)) {
                    const editModel: BlueprintRepositoryModel = {
                        SpaceId: project.SpaceId,
                        Name: project.Name.replace("Blueprints - ", ""),
                        Slug: project.Slug,
                        Description: project.Description,
                        BasePath: project.PersistenceSettings.BasePath.replace(".octopus/", ""),
                        DefaultBranch: project.PersistenceSettings.DefaultBranch,
                        ProtectedDefaultBranch: project.PersistenceSettings.ProtectedDefaultBranch,
                        Url: project.PersistenceSettings.Url,
                        Credentials: project.PersistenceSettings.Credentials,
                        ProtectedBranchNamePatterns: project.PersistenceSettings.ProtectedBranchNamePatterns,
                        Type: PersistenceSettingsType.VersionControlled,
                        ConversionState: project.PersistenceSettings.ConversionState,
                    };
                    this.setState({ model: editModel, cleanModel: editModel });
                }
            }
        });
    }
    render() {
        return (<ConnectBlueprintsRepositoryInternalPage busy={this.state.busy} errors={this.errors} model={this.state.model} cleanModel={this.state.cleanModel} setModelState={this.setModelState.bind(this)} getFieldError={this.getFieldError.bind(this)} setModel={this.setModel.bind(this)} doBusyTask={this.doBusyTask} projectId={this.props.projectId}></ConnectBlueprintsRepositoryInternalPage>);
    }
    static displayName = "ConnectBlueprintsRepositoryPage";
}
