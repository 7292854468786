import { ExecutionLocation } from "@octopusdeploy/octopus-server-client";
import type { ConvertibleInputPathToValue, SensitiveValue } from "@octopusdeploy/step-inputs";
import type { StepUI } from "@octopusdeploy/step-ui";
import { sensitiveText } from "@octopusdeploy/step-ui";
import type { StepPackage } from "~/components/StepPackageEditor/StepPackage/StepPackage";
export interface JiraServiceDeskChangeRequestStepInputs {
    "Octopus.Action.JiraIntegration.ServiceDesk.ServiceId": SensitiveValue | undefined;
}
export const jiraServiceDeskChangeRequestStepUI: StepUI<JiraServiceDeskChangeRequestStepInputs> = {
    createInitialInputs: () => ({
        "Octopus.Action.JiraIntegration.ServiceDesk.ServiceId": undefined,
    }),
    editInputsForm: (inputs) => [
        sensitiveText({
            label: "Service Id",
            helpText: "Provide the Jira Service Desk Service Id",
            // TODO: Steps team to revisit InputPaths typings to ensure object accessor inputs are convertible
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            input: (inputs["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"] as unknown as ConvertibleInputPathToValue<SensitiveValue>).convertTo<SensitiveValue>({
                toNewType: (serviceId) => serviceId ?? { type: "empty" },
            }),
        }),
    ],
};
export const jiraStepPackage: StepPackage<JiraServiceDeskChangeRequestStepInputs> = {
    name: "Jira Service Desk",
    description: "",
    version: "0.0.0",
    executionLocation: ExecutionLocation.ServerOrWorkerOrTarget,
    canRunInExecutionContainer: false,
    requiresTargetRole: false,
    inputSchema: {
        definitions: {
            JiraServiceDeskChangeRequestInputs: {
                type: "object",
                isRoot: true,
                properties: {
                    ["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"]: { type: "object", isSensitiveValue: true },
                },
                required: ["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"],
            },
        },
    },
    stepUI: jiraServiceDeskChangeRequestStepUI,
    isLegacyStep: true,
    targetDiscoveryCloudConnectionTypes: [],
};
