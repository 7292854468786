import type * as History from "history";
import type { ComponentType, ReactNode } from "react";
import * as React from "react";
import { Prompt, useLocation } from "react-router";
import { HashRouter } from "react-router-dom";
import ConfirmNavigate from "~/components/ConfirmNavigate/ConfirmNavigate";
import type { NavigationBlocker } from "~/components/OctopusRouter/NavigationBlocker";
import { RegisterNavigationBlockerProvider, useNavigationBlockerState } from "~/components/OctopusRouter/NavigationBlocker";
import type { UserConfirmationState } from "~/components/OctopusRouter/UserConfirmationState";
import { useUserConfirmationState } from "~/components/OctopusRouter/UserConfirmationState";
export function OctopusRouter({ children, RouterComponent = HashRouter }: {
    children: ReactNode;
    RouterComponent?: ComponentType<{
        getUserConfirmation?: (message: string, callback: (ok: boolean) => void) => void;
    }>;
}) {
    const { navigationBlocker, registerNavigationBlocker } = useNavigationBlockerState();
    const { userConfirmationState, createUserConfirmationRequest } = useUserConfirmationState(navigationBlocker);
    return (<RegisterNavigationBlockerProvider registerNavigationBlocker={registerNavigationBlocker}>
            <ConfirmNavigateAwayFromForm userConfirmationState={userConfirmationState}/>
            <RouterComponent getUserConfirmation={createUserConfirmationRequest}>
                {navigationBlocker !== null && <NavigateAwayPrompt navigationBlocker={navigationBlocker}/>}
                {children}
            </RouterComponent>
        </RegisterNavigationBlockerProvider>);
}
function NavigateAwayPrompt({ navigationBlocker }: {
    navigationBlocker: NavigationBlocker;
}) {
    const currentLocation = useLocation();
    // If the pathname hasn't changed, return true which will allow the transition.
    // This is so we can ignore filter changes which only modify the query string.
    const getPromptMessage = React.useCallback((nextLocation: History.Location) => {
        if (nextLocation.pathname === currentLocation.pathname) {
            return true;
        }
        return navigationBlocker.confirmationText;
    }, [currentLocation.pathname, navigationBlocker.confirmationText]);
    return <Prompt message={getPromptMessage}/>;
}
function ConfirmNavigateAwayFromForm({ userConfirmationState }: {
    userConfirmationState: UserConfirmationState | null;
}) {
    if (userConfirmationState === null || userConfirmationState.type === "request")
        return null;
    const userConfirmationRequest = userConfirmationState?.type === "request-with-blocker" ? userConfirmationState : null;
    // Note: We want to keep rendering this component after the selection has been made, so that the fade away animation shows
    return (<ConfirmNavigate open={userConfirmationRequest !== null} message={userConfirmationState.message} onCancelNavigation={() => userConfirmationRequest?.setResult(false)} onContinueNavigating={() => userConfirmationRequest?.setResult(true)} onSaveClick={async () => {
            userConfirmationRequest?.setResult(false);
            await userConfirmationRequest?.navigationBlocker.onSaveClick();
        }} saveLabel={userConfirmationState.navigationBlocker.saveLabel}/>);
}
