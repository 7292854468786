import type { PackagingRegistration } from "../../Registry/packagingRegistry";
import BuildServerPackagingInstruction from "./BuildServerPackagingInstruction";
import CommandLinePackagingInstruction from "./CommandLinePackagingInstruction";
import ManualPackagingInstruction from "./ManualPackagingInstruction";
export const AspNetPackagingRegistration: PackagingRegistration = {
    displayOrder: 100,
    instructions: [BuildServerPackagingInstruction, ManualPackagingInstruction, CommandLinePackagingInstruction],
    name: "ASP.NET",
    id: "AspNet",
};
export default AspNetPackagingRegistration;
