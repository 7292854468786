import { css } from "@emotion/css";
import { Button, Callout, ExternalLink, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import { fontSize, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentTargetResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { MachineModelHealthStatus, Permission } from "@octopusdeploy/octopus-server-client";
import classNames from "classnames";
import type { ReactNode } from "react";
import React, { useCallback, useMemo } from "react";
import { useAnalyticGuidedSetupDispatch } from "~/analytics/Analytics";
import { KubernetesAgentTargetConfigurationDialog } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentTargetConfigurationDialog";
import { repository } from "~/clientInstance";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { useSpaceAwareNavigation } from "../Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { GuidedSetupFrame } from "../OnboardingDialog/GuidedSetupFrame";
import { PermissionCheck } from "../PermissionCheck";
import { AddKubernetesAgentCard, ConnectedKubernetesAgentCard } from "./KubernetesAgentCard";
import KubernetesDeploymentTargetsPopover from "./KubernetesDeploymentTargetsPopover";
import { radioButtonStyles } from "./RectangularRadioButtonStyles";
import addKubernetesImageDark from "./assets/img-k8s-dark.svg";
import addKubernetesImageLight from "./assets/img-k8s-light.svg";
type PartialTargetModel = {
    Name: string;
    EnvironmentIds: string[];
    MachineRoles: string[];
};
interface CreateNewKubernetesClusterProps {
    projectId?: string;
    correlationId?: string;
    fullScreen?: boolean;
    title: ReactNode;
    next: () => void;
    skip: () => void;
    close: () => void;
    busy: Promise<void> | undefined;
    onTargetsChange: (targets: DeploymentTargetResource[]) => void;
    environments?: EnvironmentResource[];
}
function CreateNewKubernetesCluster(props: CreateNewKubernetesClusterProps) {
    const [alreadyHasCluster, setAlreadyHaveCluster] = React.useState<"" | "Yes" | "No">("");
    const [isConfiguringCluster, setIsConfiguringCluster] = React.useState<boolean>(false);
    const [nameOfNewTarget, setNameOfNewTarget] = React.useState("");
    const [targets, setTargets] = React.useState<DeploymentTargetResource[]>([]);
    const [temporaryTarget, setTemporaryTarget] = React.useState<PartialTargetModel | undefined>(undefined);
    const [bearerTokenExpired, setBearerTokenExpired] = React.useState(false);
    const guidedSetupDispatchAction = useAnalyticGuidedSetupDispatch();
    const navigate = useSpaceAwareNavigation();
    async function refreshTargets() {
        if (nameOfNewTarget) {
            const allTargets = await repository.Machines.list({ name: nameOfNewTarget });
            const newTarget = allTargets.Items.find((target) => target.Name === nameOfNewTarget);
            if (newTarget) {
                let updatedTargets = [...targets];
                const targetToUpdateIndex = updatedTargets.findIndex((x) => x.Id === newTarget.Id);
                if (targetToUpdateIndex >= 0) {
                    updatedTargets[targetToUpdateIndex] = newTarget;
                }
                else {
                    updatedTargets = [...targets, newTarget];
                }
                setTargets(updatedTargets);
                props.onTargetsChange(updatedTargets);
                // remove temporary target
                setTemporaryTarget(undefined);
            }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedRefreshFunction = useCallback(refreshTargets, [nameOfNewTarget, targets]);
    useRefreshLoop(memoizedRefreshFunction, 2000);
    const onNextClicked = () => {
        props.next();
    };
    const hasTargetWithStatusType = (healthStatusType: MachineModelHealthStatus, targets: DeploymentTargetResource[]): boolean => {
        return targets.some((target) => target.HealthStatus === healthStatusType);
    };
    const memoizedKubernetesAgentTargetConfigurationDialog = useMemo(() => {
        return (<KubernetesAgentTargetConfigurationDialog projectId={props.projectId} correlationId={props.correlationId} dispatchAction={guidedSetupDispatchAction} navigate={navigate} sourcePage="Project Setup" open={isConfiguringCluster} closeDialog={() => setIsConfiguringCluster(false)} openDialog={() => setIsConfiguringCluster(true)} onTargetCompleted={(model) => setNameOfNewTarget(model.Name)} onEstablishingConnection={(model) => {
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const partialModel = model as unknown as PartialTargetModel;
                setTemporaryTarget(partialModel);
            }} onBearerTokenExpired={() => {
                setBearerTokenExpired(true);
            }}/>);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.projectId, props.correlationId, isConfiguringCluster]);
    return (<GuidedSetupFrame helpPanelContent={<div>
                    <p>
                        Kubernetes clusters are represented as <strong>deployment targets</strong> in Octopus.
                    </p>

                    <p>
                        The <strong>Kubernetes Agent</strong> is a small, lightweight application that is installed into the target Kubernetes cluster. The agent will automatically update itself during regular health checks to keep things running
                        smoothly and securely.
                    </p>
                </div>} helpPanelImage={{ src: addKubernetesImageLight, altText: "Placeholder", darkThemeSrc: addKubernetesImageDark }} onClose={props.close}>
            <>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.heading}>{hasTargetWithStatusType(MachineModelHealthStatus.Healthy, targets) ? "Octopus Kubernetes Agent installed on cluster" : "Connect Octopus to your Kubernetes cluster"}</div>
                <PermissionCheck permission={Permission.MachineCreate} alternate={<Callout type="danger" title="Can't add deployment targets">
                            You don’t have permission to add deployment targets. Please contact your Octopus administrator to request {Permission.MachineCreate} permission. You can proceed to the next step and add deployment targets, when you have
                            permission.
                        </Callout>}>
                    {!targets.length && !temporaryTarget && (<>
                            <div className={styles.question}>Do you have a Kubernetes cluster you can deploy to today?</div>
                            <div className={radioButtonStyles}>
                                <RadioButtonGroup value={alreadyHasCluster} onChange={(value: "" | "Yes" | "No") => {
                setAlreadyHaveCluster(value);
            }} horizontal>
                                    <RadioButton className={classNames({ ["active"]: alreadyHasCluster === "Yes" })} label="Yes" value="Yes"/>
                                    <RadioButton className={classNames({ ["active"]: alreadyHasCluster === "No" })} label="No" value="No"/>
                                </RadioButtonGroup>
                            </div>
                        </>)}

                    <div>
                        {alreadyHasCluster === "Yes" && !targets.length && (<div>
                                Connect Octopus to your cluster using the <strong>Kubernetes Agent</strong>.
                            </div>)}

                        {alreadyHasCluster === "No" && !targets.length && (<div>
                                <div>
                                    We recommend creating a Kubernetes cluster in a cloud provider like AWS or Azure or installing one locally using <ExternalLink label="Minikube" href="minikube" size="base"/>.
                                </div>
                                <div className={styles.noCluster}>
                                    Once you have a Kubernetes cluster, connect it to Octopus to using the <strong>Kubernetes Agent</strong>.
                                </div>
                            </div>)}

                        {!!alreadyHasCluster && (<div>
                                {!targets.length && <OptionsForConnectingLater />}
                                {!isConfiguringCluster && !targets.length && !temporaryTarget && <AddKubernetesAgentCard onClick={() => setIsConfiguringCluster(true)}/>}
                                {memoizedKubernetesAgentTargetConfigurationDialog}
                            </div>)}
                    </div>

                    <div className={styles.kubernetesAgentCardsWrapper}>
                        {targets.map((target) => {
            const environmentNames = props.environments?.map((env) => env.Name);
            return (<div key={target.Name}>
                                    <ConnectedKubernetesAgentCard key={target.Id} name={target.Name} id={target.Id} environments={environmentNames} targetTags={target.Roles} healthStatus={target.HealthStatus} close={props.close}/>
                                </div>);
        })}
                        {temporaryTarget && (<div>
                                <ConnectedKubernetesAgentCard key={temporaryTarget.Name} name={temporaryTarget.Name} id={temporaryTarget.Name} environments={props.environments?.map((env) => env.Name)} targetTags={temporaryTarget.MachineRoles} healthStatus={MachineModelHealthStatus.Unknown} close={props.close} forceNotFound bearerTokenExpired={bearerTokenExpired}/>
                            </div>)}
                    </div>

                    {(targets.length > 0 || bearerTokenExpired) && (<div>
                            <Button importance="secondary" label="Add Another Kubernetes Agent" onClick={() => {
                setNameOfNewTarget("");
                setIsConfiguringCluster(true);
                setBearerTokenExpired(false);
                setTemporaryTarget(undefined);
            }}/>
                        </div>)}

                    {hasTargetWithStatusType(MachineModelHealthStatus.Unknown, targets) && (<div className={styles.healthCheckCallout}>
                            <Callout canClose={false} hideTitle type="information">
                                <span>Registration and health check can take up to 3 minutes. You can stay here or proceed – we’ll notify you if an error occurs.</span>
                            </Callout>
                        </div>)}

                    {targets
            .filter((target) => target.HealthStatus === MachineModelHealthStatus.Unhealthy)
            .map((target) => (<div key={target.Id} className={styles.healthCheckCallout}>
                                <Callout canClose={false} hideTitle type="danger">
                                    <span>
                                        Health check failed for <b>{target.Name}</b>. Need help? <ExternalLink href={"kubernetes-agent"} label="Read the docs"/>
                                    </span>
                                </Callout>
                            </div>))}
                </PermissionCheck>

                <div className={styles.buttons}>
                    <Button label="I'll do this later" importance="quiet" onClick={props.skip}/>
                    <Button label="Back" disabled={true} importance="secondary" onClick={() => { }}/>
                    <Button label="Next" disabled={!targets?.length} onClick={onNextClicked} importance="primary"/>
                </div>
            </>
        </GuidedSetupFrame>);
}
export default CreateNewKubernetesCluster;
function OptionsForConnectingLater() {
    return (<div className={styles.alternateOption}>
            Or connect later, using the <strong>Kubernetes API</strong> or <strong>Kubernetes Agent</strong>.
            <KubernetesDeploymentTargetsPopover />
        </div>);
}
const styles = {
    title: css({
        marginTop: space[40],
        marginBottom: space[16],
    }),
    heading: css({
        marginTop: space[32],
        marginBottom: space[32],
        font: text.interface.heading.medium,
    }),
    question: css({}),
    buttons: css({
        display: "flex",
        justifyContent: "flex-end",
        gap: space[8],
        marginTop: space[48],
    }),
    alternateOption: css({
        margin: `${space[4]} 0 ${space[16]} 0`,
        fontSize: fontSize.medium,
        color: themeTokens.color.text.secondary,
    }),
    noCluster: css({
        marginTop: space[32],
    }),
    kubernetesAgentCardsWrapper: css({
        display: "flex",
        flexWrap: "wrap",
        gap: space[16],
        marginBottom: space[16],
    }),
    healthCheckCallout: css({
        marginTop: space[32],
    }),
};
