import { css, keyframes } from "@emotion/css";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
type RoundedSkeletonProps = {
    shape: "Rounded";
    borderRadius: Exclude<keyof typeof borderRadius, "none" | "circle">;
};
type SkeletonProps = RoundedSkeletonProps;
const pulseKeyframes = keyframes({
    "0%": { opacity: 1 },
    "50%": { opacity: 0.5 },
    "100%": { opacity: 1 },
});
const skeletonStyleProps = css({
    backgroundColor: themeTokens.color.background.tertiary,
    animation: `${pulseKeyframes} 1.5s linear 0s infinite`,
    borderRadius: borderRadius.medium,
    width: "100%",
    height: "100%",
});
export function Skeleton(props: SkeletonProps) {
    return <div role="progressbar" className={skeletonStyleProps}/>;
}
