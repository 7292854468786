import * as React from "react";
import styles from "./style.module.less";
interface VariableCellProps {
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
}
const VariableCell: React.SFC<VariableCellProps> = (props: VariableCellProps) => {
    return (<div className={`${styles.cell} ${props.className}`} style={props.style}>
            {props.children}
        </div>);
};
VariableCell.displayName = "VariableCell"
export default VariableCell;
