import { css } from "@emotion/css";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import React, { useEffect } from "react";
import { useAnalyticSampleProjectDispatch } from "~/analytics/Analytics";
import { generateGuid } from "~/areas/projects/components/Process/generation";
import { InitializeSampleProjectTour } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourUtils";
import { repository, session } from "~/clientInstance";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { createWelcomeDialogSelectionAnalyticsEvent, createViewWelcomeDialogViewAnalyticsEvent } from "./Analytics/WelcomeDialogAnalytics";
import { NewProjectCard } from "./Cards/NewProjectCard";
import { SampleProjectCard } from "./Cards/SampleProjectCard";
import { ViewProjectsCard } from "./Cards/ViewProjectsCard";
import { ViewSampleInstanceCard } from "./Cards/ViewSampleInstanceCard";
import type { WelcomeDialogStates } from "./WelcomeDialog";
type WelcomeDialogActionCardsProps = {
    onCreateSampleProject: (sampleProjectReference: string) => Promise<ProjectResource | undefined>;
    currentSpace: SpaceResource;
    busy: Promise<void> | undefined;
    onChange: (value: WelcomeDialogStates) => void;
};
export function WelcomeDialogActionCards({ onCreateSampleProject, busy, currentSpace, onChange }: WelcomeDialogActionCardsProps) {
    const sampleProjectDispatchAction = useAnalyticSampleProjectDispatch();
    const trackEvent = useTrackEvent();
    const navigate = useSpaceAwareNavigation();
    const isKubernetesGuidedSetupFeatureEnabled = useOctopusFeatureToggle("kubernetes-guided-setup", false);
    const cards = [];
    const clickNewProject = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        trackEvent(createWelcomeDialogSelectionAnalyticsEvent("Create new project"));
        onChange("new-project-dialog");
    };
    const clickViewProjects = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        trackEvent(createWelcomeDialogSelectionAnalyticsEvent("View projects"));
        onChange("none");
        navigate.navigate(links.projectsPage.generateUrl({ spaceId: currentSpace.Id ?? repository.spaceId }));
    };
    const clickSampleProject = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        trackEvent(createWelcomeDialogSelectionAnalyticsEvent("Create sample project"));
        const sampleProjectReference = generateGuid();
        sampleProjectDispatchAction("Request Sample Project", {
            sampleProjectReference,
            source: "Welcome Dialog",
        });
        const project = await onCreateSampleProject(sampleProjectReference);
        if (project) {
            onChange("none");
            InitializeSampleProjectTour(project.Slug);
            navigate.navigate(links.projectRootRedirect.generateUrl({ spaceId: currentSpace?.Id ?? repository.spaceId, projectSlug: project.Slug }));
        }
    };
    const clickViewSampleInstance = (e: React.MouseEvent<HTMLElement>) => {
        trackEvent(createWelcomeDialogSelectionAnalyticsEvent("View samples instance"));
        onChange("none");
    };
    if (hasPermission(Permission.ProjectCreate)) {
        cards.push(<NewProjectCard busy={!!busy} onClick={clickNewProject} key={"NewProjectCard"}/>);
    }
    else {
        cards.push(<ViewProjectsCard busy={!!busy} onClick={clickViewProjects} key={"ViewProjectsCard"}/>);
    }
    if (currentSpace && session.currentPermissions?.isSpaceManager(currentSpace)) {
        cards.push(<SampleProjectCard busy={!!busy} onClick={clickSampleProject} key={"SampleProjectCard"} newCopy={isKubernetesGuidedSetupFeatureEnabled}/>);
    }
    else {
        cards.push(<ViewSampleInstanceCard busy={!!busy} onClick={clickViewSampleInstance} key={"ViewSampleInstanceCard"}/>);
    }
    return (<>
            <TrackViewWelcomeDialogAnalyticsEvent displayedCards={cards.map((card) => card.key?.toString() || "Unknown card")}/>
            <div className={styles.actionCards}>{cards}</div>
        </>);
}
function TrackViewWelcomeDialogAnalyticsEvent(props: {
    displayedCards: string[];
}) {
    const trackEvent = useTrackEvent();
    useEffect(() => {
        trackEvent(createViewWelcomeDialogViewAnalyticsEvent(props.displayedCards));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <></>;
}
const styles = {
    actionCards: css({
        display: "flex",
        flexDirection: "column",
        "& > div": css({
            margin: "0.8rem 0",
            maxWidth: "18rem",
        }),
        "@media (min-width: 600px)": {
            flexDirection: "row",
            gap: "24px",
        },
    }),
};
