import cn from "classnames";
import * as React from "react";
import styles from "./CustomDialogContent.module.less";
interface CustomDialogContentProps {
    className?: string;
}
const CustomExtraContent: React.SFC<CustomDialogContentProps> = ({ className, children }) => {
    return <div className={cn(styles.padded, className)}>{children}</div>;
};
CustomExtraContent.displayName = "CustomExtraContent"
const CustomDialogContent: React.SFC<CustomDialogContentProps> = ({ className, children }) => {
    return <div className={cn(styles.content, styles.block, styles.padded, className)}>{children}</div>;
};
CustomDialogContent.displayName = "CustomDialogContent"
const CustomFlexDialogContent: React.SFC<CustomDialogContentProps> = ({ className, children }) => {
    return <div className={cn(styles.content, styles.flex, className)}>{children}</div>;
};
CustomFlexDialogContent.displayName = "CustomFlexDialogContent"
export default CustomDialogContent;
export { CustomDialogContent, CustomFlexDialogContent, CustomExtraContent };
