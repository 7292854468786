import { css } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { borderWidth, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import classNames from "classnames";
import React from "react";
import Card from "~/components/Card";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { GitIcon } from "~/primitiveComponents/dataDisplay/Icon";
import GitHubIcon from "~/primitiveComponents/dataDisplay/Icon/GitHubIcon";
export enum VersionControlSettingsType {
    GitHub = "GitHub",
    Generic = "Generic"
}
export interface VersionControlSettingsTypeSelectorProps {
    selectedType: VersionControlSettingsType | undefined;
    setSelectedType: (type: VersionControlSettingsType) => void;
}
export function VersionControlSettingsTypeSelector(props: VersionControlSettingsTypeSelectorProps) {
    const hasGitCredentialView = hasPermission(Permission.GitCredentialView);
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            gap: space[16],
            padding: space[16],
        }),
        versionControlTypeCard: css({
            cursor: "pointer",
        }),
        versionControlTypeCardInner: css({
            cursor: "pointer",
        }),
    };
    return (<div className={styles.container}>
            <VersionControlSettingsTypeCard type={VersionControlSettingsType.GitHub} title="GitHub" disabled={!hasGitCredentialView} disabledToolTip={"Requires Git Credential View permission"} {...props}/>
            <VersionControlSettingsTypeCard type={VersionControlSettingsType.Generic} title="Other Git Provider" {...props}/>
        </div>);
}
interface VersionControlSettingsTypeCardProps extends VersionControlSettingsTypeSelectorProps {
    type: VersionControlSettingsType;
    title: string;
    disabled?: boolean;
    disabledToolTip?: string | undefined;
}
function VersionControlSettingsTypeCard({ type, title, disabled, disabledToolTip, selectedType, setSelectedType }: VersionControlSettingsTypeCardProps) {
    const themePaletteType = useThemePaletteType();
    const styles = {
        container: css({
            background: "none",
            border: "none",
            padding: 0,
            margin: 0,
            cursor: "pointer",
            outline: "inherits",
            font: "inherit",
        }),
        cardSelected: css({
            background: `${themeTokens.color.background.selected} !important`,
            color: themePaletteType === "light" ? `${themeTokens.color.text.inverse} !important` : undefined,
        }),
        cardDisabled: css({
            background: `${themeTokens.color.background.disabled} !important`,
        }),
        cardDisabledSelected: css({
            background: `${themeTokens.color.background.disabled} !important`,
            outline: `${borderWidth[4]} solid ${themeTokens.color.background.selected}`,
        }),
        headerSelected: css({
            color: `${themePaletteType === "dark" ? "inherit" : themeTokens.color.text.inverse}`,
        }),
    };
    const isSelected = selectedType === type;
    return (<button disabled={disabled} className={styles.container} onClick={() => setSelectedType(type)} name={title}>
            <OptionalTooltip content={disabledToolTip} show={disabled}>
                <Card disableMargin hideDisabledChip isDisabled={disabled} className={classNames(isSelected ? (disabled ? styles.cardDisabledSelected : styles.cardSelected) : disabled ? styles.cardDisabled : undefined)} logo={<VersionControlSettingsTypeLogo type={type}/>} header={<div className={classNames(isSelected ? styles.headerSelected : undefined)}>{title}</div>} content={undefined}/>
            </OptionalTooltip>
        </button>);
}
interface OptionalTooltipProps {
    show?: boolean;
    content: string | undefined;
    children: React.ReactNode;
}
function OptionalTooltip({ show, content, children }: OptionalTooltipProps) {
    if (show) {
        return (<Tooltip content={content} position="top">
                {children}
            </Tooltip>);
    }
    else {
        return <div>{children}</div>;
    }
}
interface VersionControlSettingsTypeLogoProps {
    type: VersionControlSettingsType;
}
function VersionControlSettingsTypeLogo({ type }: VersionControlSettingsTypeLogoProps) {
    const styles = {
        logo: css({
            width: `${space[32]} !important`,
            height: `${space[32]} !important`,
        }),
    };
    return type === VersionControlSettingsType.GitHub ? <GitHubIcon className={styles.logo}/> : <GitIcon className={styles.logo}/>;
}
