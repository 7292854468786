import * as React from "react";
import styles from "./style.module.less";
interface ResizeColumnHandleProps {
    isActive: boolean;
}
const ResizeColumnHandle: React.SFC<ResizeColumnHandleProps> = (props) => {
    return (<div className={styles.dragHandlePositioningContainer}>
            <div className={styles.dragHandleDraggableArea}>
                <div className={`${styles.dragHandleIndicator} ${props.isActive ? styles.dragHandleIndicatorDark : styles.dragHandleIndicatorLight}`}/>
            </div>
        </div>);
};
ResizeColumnHandle.displayName = "ResizeColumnHandle"
export default ResizeColumnHandle;
