import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonLibraryCertificateOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="CertificatesDocumentation">X.509 certificates</ExternalLink> are a key component of many deployment processes. Octopus Deploy provides the ability to securely store and manage your certificates, and easily use
                    them in your projects.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
