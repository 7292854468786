import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import EditScheduledTrigger from "../../Triggers/Scheduled/EditScheduledTriggers";
interface CreateRunbookScheduledTriggersPageProps {
    spaceId: string;
    projectSlug: string;
    runbookId?: string;
}
export function CreateRunbookScheduledTriggersPage({ spaceId, runbookId, projectSlug }: CreateRunbookScheduledTriggersPageProps) {
    return <EditScheduledTrigger create={true} triggerActionCategory={TriggerActionCategory.Runbook} spaceId={spaceId} projectSlug={projectSlug} runbookId={runbookId}/>;
}
