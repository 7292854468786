import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import { getLastRouteSegment } from "~/components/ContextualHelpLayout/getLastRouteSegment";
import { useQueryParams } from "~/components/ContextualHelpLayout/useQueryParams";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import HelpImageWorker from "../../Images/Worker.svg";
import { LearnMoreResources, TopicsContainer, OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
export const CommonWorkerOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId="v6621BId7fE"/>
                <p>
                    <ExternalLink href="Workers">Workers</ExternalLink> let you delegate work to a machine other than the Octopus server for increased speed, scale and security.
                </p>
                <ImageWithPlaceholder src={HelpImageWorker} alt={"Workers"}/>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"How workers work?"}>
                    If a deployment step is configured to run on a worker from a worker pool, Octopus will select any worker in that pool to execute the step. It executes the step using Calamari and returns the logs and any collected artifacts to the
                    Octopus server.
                </Note>
            </TermsText>
        </OverviewContainer>);
};
export const CommonWorkerTopics = () => {
    const getQueryParam = useQueryParams();
    const lastSegment = getLastRouteSegment(window.location.href);
    const category = getQueryParam("category");
    if (lastSegment === "TentaclePassive" && category === "Windows") {
        return (<Topics>
                <ExternalLink href="RegisterWindowsTentacleAsWorker">Register a Windows Listening Tentacle as a Worker</ExternalLink>
            </Topics>);
    }
    if (lastSegment === "TentaclePassive" && category === "Linux") {
        return (<Topics>
                <ExternalLink href="RegisterLinuxTentacleAsWorker">Register a Linux Listening Tentacle as a Worker</ExternalLink>
            </Topics>);
    }
    if (lastSegment === "Ssh" && category === "Linux") {
        return (<Topics>
                <ExternalLink href="RegisterWorkerSSHConnection">Register a Worker with an SSH Connection</ExternalLink>
            </Topics>);
    }
    if (lastSegment === "Ssh" && category === "Mac") {
        return (<Topics>
                <ExternalLink href="RegisterWorkerSSHConnection">Register a Worker with an SSH Connection</ExternalLink>
            </Topics>);
    }
    return null;
};
const Topics = ({ children }: {
    children: React.ReactNode;
}) => (<TopicsContainer>
        <LearnMoreResources>
            <div>{children}</div>
        </LearnMoreResources>
    </TopicsContainer>);
