/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { KubernetesServiceProperties } from "@octopusdeploy/legacy-action-properties";
import * as _ from "lodash";
import { clone } from "lodash";
import * as React from "react";
import { EditResourceYaml } from "~/components/Actions/kubernetes/editResourceYaml";
import PortDialog from "~/components/Actions/kubernetes/servicePortDialog";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import DialogOpener from "~/components/Dialog/DialogOpener";
import type { KeyValueOption } from "~/components/EditList/ExtendedKeyValueEditList";
import StringExtendedKeyValueEditList from "~/components/EditList/ExtendedKeyValueEditList";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { default as ExpandableFormSection } from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "~/primitiveComponents/form/Note/Note";
import { JsonUtils } from "~/utils/jsonUtils";
import { exportService, importService } from "./importYaml";
interface KubernetesServiceState {
    servicePortBindings: ServicePort[];
    editServicePortBinding: ServicePort;
    editServicePortBindingIndex: number;
    resourceYaml: string;
}
export interface ServicePort {
    name: string;
    port: string;
    nodePort: string;
    targetPort: string;
    protocol: string;
}
class ServicePortList extends RemoveItemsList<ServicePort> {
}
export interface ActionEditorProps extends ActionEditProps<KubernetesServiceProperties> {
    importLabels: boolean;
}
export class KubernetesServiceComponent extends BaseComponent<ActionEditorProps, KubernetesServiceState> {
    constructor(props: ActionEditorProps) {
        super(props);
        this.state = {
            servicePortBindings: [],
            editServicePortBinding: null!,
            editServicePortBindingIndex: null!,
            resourceYaml: exportService(this.props, this.props.importLabels),
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            this.setState({
                servicePortBindings: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.ServicePorts"], []),
            });
            if (!this.props.properties["Octopus.Action.KubernetesContainers.ServiceType"]) {
                this.props.setProperties({ "Octopus.Action.KubernetesContainers.ServiceType": "ClusterIP" });
            }
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps: ActionEditorProps) {
        if (this.props.properties["Octopus.Action.KubernetesContainers.ServicePorts"] !== nextProps.properties["Octopus.Action.KubernetesContainers.ServicePorts"]) {
            this.setState({ servicePortBindings: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.ServicePorts"], []) });
        }
        const yaml = exportService(nextProps, nextProps.importLabels);
        if (this.state.resourceYaml !== yaml) {
            this.setState({ resourceYaml: yaml });
        }
    }
    render() {
        const editBindingDialog = (<DialogOpener open={!!this.state.editServicePortBinding} onClose={this.resetSelectedBinding}>
                <PortDialog servicePort={this.state.editServicePortBinding} projectId={this.props.projectId!} doBusyTask={this.props.doBusyTask} localNames={this.props.localNames!} onAdd={(item) => this.saveServicePortBinding(item)} serviceType={this.props.properties["Octopus.Action.KubernetesContainers.ServiceType"]} containers={JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.Containers"], [])}/>
            </DialogOpener>);
        return (<div>
                {editBindingDialog}
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.ServiceYaml" isExpandedByDefault={false} title="Edit YAML" summary={Summary.placeholder("Edit the resource YAML")} help={"Edit the resource YAML."}>
                    <EditResourceYaml yaml={this.state.resourceYaml} onSave={(value) => this.setState({ resourceYaml: value }, () => importService(this.props, value, this.props.importLabels))}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.ServiceName" isExpandedByDefault={this.props.expandedByDefault} title="Service Name" summary={this.nameSummary()} help={"Enter the service exposing the deployment."}>
                    <Note>The unique name of the Kubernetes service resource.</Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#service-name">service name</ExternalLink>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.ServiceName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.ServiceName")} label="Service name"/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.ServiceType" isExpandedByDefault={this.props.expandedByDefault} title="Service Type" summary={this.typeSummary()} help={"Select the service type."}>
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.KubernetesContainers.ServiceType"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceType"]: x })}>
                        <RadioButton value="ClusterIP" label="Cluster IP"/>
                        <Note>The cluster IP service resource is accessible to other resources in the Kubernetes cluster.</Note>
                        <Note>
                            Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#cluster-ip">cluster IP services</ExternalLink>.
                        </Note>
                        <RadioButton value="NodePort" label="Node port"/>
                        <Note>The node port service resource is accessible to other resources in the Kubernetes cluster, and also via ports exposed on the Kubernetes nodes.</Note>
                        <Note>
                            Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#node-port">node port services</ExternalLink>.
                        </Note>
                        <RadioButton value="LoadBalancer" label="Load balancer"/>
                        <Note>The load balancer service resource is accessible to other resources in the Kubernetes cluster, also via ports exposed on the Kubernetes nodes, and also through an external load balancer device.</Note>
                        <Note>
                            Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#load-balancer">load balancer services</ExternalLink>.
                        </Note>
                    </RadioButtonGroup>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.ServiceClusterIp"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceClusterIp"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.ServiceClusterIp")} label="Cluster IP address"/>
                    <Note>An optional value that defines the internal IP address of the service. If left blank, Kubernetes will assign a private IP address to the service.</Note>
                    {this.props.properties["Octopus.Action.KubernetesContainers.ServiceType"] === "LoadBalancer" && (<VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.ServiceLoadBalancerIp"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceLoadBalancerIp"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.ServiceLoadBalancerIp")} label="Load balancer IP address"/>)}
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.LoadBalancerAnnotations" isExpandedByDefault={this.props.expandedByDefault} title="Service Annotations" summary={this.serviceAnnotationsSummary()} help={"Add annotations to configure the service resource."}>
                    <StringExtendedKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.LoadBalancerAnnotations"]} name="Annotation" onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.LoadBalancerAnnotations"]: x })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef} addToTop={true}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.ServicePorts" isExpandedByDefault={this.props.expandedByDefault} title="Service Ports" summary={this.servicePortsSummary()} help={"Add service ports that are exposed by the service."}>
                    <Note>Ports must be configured with the ports that the service exposes, and the port that the service directs traffic to.</Note>
                    <Note>
                        Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-container#ports-1">ports</ExternalLink>.
                    </Note>
                    <ServicePortList listActions={[<ActionButton key="add" label="Add Port" onClick={() => this.addServicePortBinding()}/>]} data={this.state.servicePortBindings} onRow={(binding) => (<div>
                                {binding.name && (<p>
                                        Name: <strong>{binding.name}</strong>
                                    </p>)}
                                {binding.port && (<p>
                                        Port: <strong>{binding.port}</strong>
                                    </p>)}
                                {binding.targetPort ? (<p>
                                        Target Port: <strong>{binding.targetPort}</strong>
                                    </p>) : (<p>Target Port: Same as Port</p>)}
                                {binding.nodePort ? (<p>
                                        Node Port: <strong>{binding.nodePort}</strong>
                                    </p>) : (<p>Node Port: Automatically assigned</p>)}
                                {binding.protocol && (<p>
                                        Protocol: <strong>{binding.protocol}</strong>
                                    </p>)}
                            </div>)} onRowTouch={(binding) => this.editServicePortBinding(binding)} onRemoveRow={(binding) => this.removeServicePortBinding(binding)}/>
                </ExpandableFormSection>
            </div>);
    }
    addServicePortBinding = () => {
        const binding: ServicePort = {
            name: "",
            port: "",
            targetPort: "",
            nodePort: "",
            protocol: "TCP",
        };
        this.setState({
            editServicePortBinding: binding!,
            editServicePortBindingIndex: null!,
        });
    };
    editServicePortBinding = (binding: ServicePort) => {
        this.setState({
            editServicePortBinding: clone(binding),
            editServicePortBindingIndex: this.state.servicePortBindings.indexOf(binding),
        });
    };
    removeServicePortBinding = (binding: ServicePort) => {
        const bindings = [...this.state.servicePortBindings];
        bindings.splice(this.state.servicePortBindings.indexOf(binding), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ServicePorts"]: JSON.stringify(bindings) });
    };
    resetSelectedBinding = () => {
        this.setState({
            editServicePortBinding: null!,
            editServicePortBindingIndex: null!,
        });
    };
    saveServicePortBinding = (binding: ServicePort) => {
        const bindings = [...this.state.servicePortBindings];
        if (this.state.editServicePortBindingIndex === null) {
            bindings.push(binding);
        }
        else {
            bindings[this.state.editServicePortBindingIndex] = binding;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ServicePorts"]: JSON.stringify(bindings) });
        this.resetSelectedBinding();
        return true;
    };
    private nameSummary() {
        if (!this.props.properties["Octopus.Action.KubernetesContainers.ServiceName"]) {
            return Summary.placeholder("No name has been provided");
        }
        return Summary.summary(<span>
                Create a service resource called <strong>{this.props.properties["Octopus.Action.KubernetesContainers.ServiceName"]}</strong>
            </span>);
    }
    private typeSummary() {
        return Summary.summary(<span>
                Create a <strong>{this.props.properties["Octopus.Action.KubernetesContainers.ServiceType"]}</strong> service
                {this.props.properties["Octopus.Action.KubernetesContainers.ServiceClusterIp"] ? (<span>
                        {" "}
                        with the cluster IP address <strong>{this.props.properties["Octopus.Action.KubernetesContainers.ServiceClusterIp"]}</strong>
                    </span>) : (<span> with an automatically assigned cluster IP address</span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.ServiceLoadBalancerIp"] && (<span>
                        {" "}
                        with the load balancer IP address <strong>{this.props.properties["Octopus.Action.KubernetesContainers.ServiceLoadBalancerIp"]}</strong>
                    </span>)}
            </span>);
    }
    private servicePortsSummary() {
        const ports: ServicePort[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.ServicePorts"], []);
        if (ports.length === 0) {
            return Summary.placeholder("No ports have been included");
        }
        const portsSummaryText = ports.map((p) => {
            const portText = p.port === p.targetPort ? (<span>
                        service\target <strong>{p.port}</strong>
                    </span>) : (<span>
                        service <strong>{p.port}</strong> {"=>"} target <strong>{p.targetPort}</strong>
                    </span>);
            const nodePortText = p.nodePort ? (<span>
                    {" "}
                    (node <strong>{p.nodePort}</strong>)
                </span>) : ("");
            const protocolText = p.protocol === "TCP" ? ("") : (<span>
                        {" "}
                        (<strong>{p.protocol}</strong>)
                    </span>);
            return (<div key={p.name}>
                    <strong>{p.name}</strong>: {portText}
                    {nodePortText}
                    {protocolText}
                </div>);
        });
        return Summary.summary(<span>
                Exposing port{ports.length > 1 && "s"}: <br />
                {portsSummaryText}
            </span>);
    }
    private serviceAnnotationsSummary() {
        const annotations: KeyValueOption[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.LoadBalancerAnnotations"], []);
        if (annotations.length === 0) {
            return Summary.placeholder("No annotations have been included");
        }
        return Summary.summary(<span>
                Add the annotation{annotations.length > 1 && <span>s</span>}{" "}
                {_.chain(annotations)
                .flatMap((annotation) => [
                <strong>
                            {annotation.key}: {annotation.value}
                        </strong>,
                <span>, </span>,
            ])
                .slice(0, -1)
                .value()}
            </span>);
    }
    static displayName = "KubernetesServiceComponent";
}
