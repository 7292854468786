import { css } from "@emotion/css";
import { fontSize, fontWeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ResourcesById, EnvironmentResource, LifecycleResource, ProjectGroupResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import SelectLifecycle from "~/areas/projects/components/Lifecycle/SelectLifecycle";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { MarkdownEditor, Select } from "~/components/form/index";
type AdvancedProjectSectionProps = {
    spaceId: string;
    description: string;
    onDescriptionChanged: (newValue: string) => void;
    projectGroups: ProjectGroupResource[];
    projectGroupId: string | undefined;
    onProjectGroupChanged: (newValue: string | undefined) => void;
    lifecycles: LifecycleResource[];
    selectedLifecycle: LifecycleResource | undefined;
    onLifecycleChanged: (newValue: string | undefined) => void;
    onRefreshLifecycles: () => void;
    environmentsById: ResourcesById<EnvironmentResource>;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
};
const AdvancedProjectSection = (props: AdvancedProjectSectionProps) => {
    return (<React.Fragment>
            <div className={advancedOptionsLableStyles}>Advanced settings</div>
            <MarkdownEditor label="Project description" value={props.description} onChange={props.onDescriptionChanged} hideMarkdownToggle/>
            <Select value={props.projectGroupId} onChange={props.onProjectGroupChanged} items={props.projectGroups.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Project group"/>
            <SelectLifecycle spaceId={props.spaceId} busy={props.busy} doBusyTask={props.doBusyTask} lifecycles={props.lifecycles} selectedLifecycleId={props.selectedLifecycle ? props.selectedLifecycle.Id : ""} environmentsById={props.environmentsById} handleLifecycleChange={props.onLifecycleChanged} showNote={true} refreshLifecycles={props.onRefreshLifecycles}/>
        </React.Fragment>);
};
const advancedOptionsLableStyles = css({
    color: themeTokens.color.text.tertiary,
    textTransform: "uppercase",
    margin: `${space[16]} 0`,
    fontSize: fontSize["xSmall"],
    fontWeight: fontWeight[700],
});
export default AdvancedProjectSection;
