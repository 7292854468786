import { CommonEventFieldKeys } from "app/analytics/CommonEventFields";
import type { Action } from "~/analytics/Analytics";
import { toBoolString } from "~/utils/fieldConverters";
// For creating commonly-used fields
export function createResourceTypeField(value: string) {
    return { [CommonEventFieldKeys.ResourceType]: value } as const;
}
export function createActionTypeField(value: Action) {
    return { [CommonEventFieldKeys.ActionType]: value } as const;
}
// For adding ad-hoc fields of varying types
export function createStringField(name: string, value?: string) {
    return value === undefined ? {} : ({ [name]: value } as const);
}
export function createOptionalStringField(predicate: boolean, name: string, value?: string) {
    if (!predicate)
        return undefined;
    return createStringField(name, value);
}
export function createBoolField(name: string, value: boolean) {
    return { [name]: toBoolString(value) } as const;
}
export function createOptionalBoolField(predicate: boolean, name: string, value: boolean) {
    if (!predicate)
        return undefined;
    return createBoolField(name, value);
}
