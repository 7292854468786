// noinspection FunctionWithMoreThanThreeNegationsJS
import type { MachinePolicyResource } from "@octopusdeploy/octopus-server-client";
import type { MachineRpcCallRetryPolicy } from "@octopusdeploy/octopus-server-client/dist/src/resources/machineRpcCallRetryPolicy";
import type { AnalyticFields } from "@octopusdeploy/portal-analytics";
import { usePageEventDispatcher } from "@octopusdeploy/portal-routes";
import React from "react";
import { createBoolField, createOptionalBoolField, createOptionalStringField } from "~/analytics/fields";
export interface MachinePolicyUpdatedArguments {
    previous: MachinePolicyResource;
    current: MachinePolicyResource;
}
export type MachinePolicyUpdatedDispatcher = (args: MachinePolicyUpdatedArguments) => void;
export function useNotifyMachinePolicyUpdate(): MachinePolicyUpdatedDispatcher {
    const dispatcher = usePageEventDispatcher();
    const transientNetworkErrorRecoveryChanges: (original: MachineRpcCallRetryPolicy, updated: MachineRpcCallRetryPolicy) => AnalyticFields = (previous, current) => {
        const behaviorUpdated = previous.Enabled !== current.Enabled;
        const retryDurationUpdated = previous.RetryDuration !== current.RetryDuration;
        const healthCheckRetryDurationUpdated = previous.HealthCheckRetryDuration !== current.HealthCheckRetryDuration;
        return {
            ...createBoolField("Recover from Communication Errors with Tentacle updated", behaviorUpdated || retryDurationUpdated || healthCheckRetryDurationUpdated),
            ...createOptionalBoolField(behaviorUpdated, "Recover from Communication Errors with Tentacle: Behavior (previous)", previous.Enabled),
            ...createOptionalBoolField(behaviorUpdated, "Recover from Communication Errors with Tentacle: Behavior (current)", current.Enabled),
            ...createOptionalStringField(retryDurationUpdated, "Recover from Communication Errors with Tentacle: Re-attempt duration for a Deployment/Runbook run (previous)", previous.RetryDuration),
            ...createOptionalStringField(retryDurationUpdated, "Recover from Communication Errors with Tentacle: Re-attempt duration for a Deployment/Runbook run (current)", current.RetryDuration),
            ...createOptionalStringField(healthCheckRetryDurationUpdated, "Recover from Communication Errors with Tentacle: Re-attempt duration for a Health Check (previous)", previous.HealthCheckRetryDuration),
            ...createOptionalStringField(healthCheckRetryDurationUpdated, "Recover from Communication Errors with Tentacle: Re-attempt duration for a Health Check (current)", current.HealthCheckRetryDuration),
        };
    };
    return React.useCallback((args: MachinePolicyUpdatedArguments) => dispatcher("Machine Policy Updated", {
        ...transientNetworkErrorRecoveryChanges(args.previous.MachineRpcCallRetryPolicy, args.current.MachineRpcCallRetryPolicy),
    }), [dispatcher]);
}
