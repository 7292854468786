/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { repository } from "../../clientInstance";
import InternalRedirect from "../../components/Navigation/InternalRedirect/InternalRedirect";
interface DeploymentToProjectTaskRedirectProps {
    spaceId: string;
    deploymentId: string;
}
interface DeploymentToProjectTaskRedirectState extends DataBaseComponentState {
    redirectTo: LinkHref;
}
export default class DeploymentToProjectTaskRedirect extends DataBaseComponent<DeploymentToProjectTaskRedirectProps, DeploymentToProjectTaskRedirectState> {
    constructor(props: DeploymentToProjectTaskRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const deploymentId = this.props.deploymentId;
            const deployment = await repository.Deployments.get(deploymentId);
            const release = await repository.Releases.get(deployment.ReleaseId);
            const path = links.deploymentDetailsPage.generateUrl({ spaceId: this.props.spaceId, projectSlug: release.ProjectId, releaseVersion: release.Version, deploymentId: deployment.Id });
            this.setState({
                redirectTo: path,
            });
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={redirectTo}/>;
    }
    static displayName = "DeploymentToProjectTaskRedirect";
}
