import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import * as React from "react";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import Summary from "../../../../../components/form/Sections/Summary";
interface PackageDownloadOptionsProps {
    forcePackageDownload: boolean;
    onOptionChanged(value: boolean): void;
}
const PackageDownloadOptions: React.StatelessComponent<PackageDownloadOptionsProps> = (props) => {
    return (<ExpandableFormSection title="Package download" errorKey="options" help="Choose whether to force re-downloading packages" summary={buildSummary()}>
            <BooleanRadioButtonGroup value={props.forcePackageDownload} onChange={props.onOptionChanged}>
                <BooleanRadioButton value={false} label="Use cached packages (if available)" isDefault={true}/>
                <BooleanRadioButton value={true} label="Re-download packages from feed"/>
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>);
    function buildSummary() {
        return props.forcePackageDownload ? Summary.summary("Re-download packages from feed") : Summary.default("Use cached packages (if available)");
    }
};
PackageDownloadOptions.displayName = "PackageDownloadOptions"
export default PackageDownloadOptions;
