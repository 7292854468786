import type { BaseTentacleEndpointConfiguration } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ServerThumbprint from "~/components/ServerThumbprint/ServerThumbprint";
import { Text, ExpandableFormSection, Summary, Note } from "~/components/form";
interface TentacleThumbprintProps {
    endpoint: BaseTentacleEndpointConfiguration;
    serverThumbprint: string | null;
    onChange(newValue: BaseTentacleEndpointConfiguration): void;
}
export class TentacleThumbprint extends React.Component<TentacleThumbprintProps> {
    render() {
        return (<ExpandableFormSection errorKey="Thumbprint" title="Thumbprint" focusOnExpandAll summary={this.props.endpoint.Thumbprint ? Summary.summary(this.props.endpoint.Thumbprint) : Summary.placeholder("No thumbprint")} help="The X509 certificate thumbprint that securely identifies the Tentacle.">
                <Text label="Thumbprint" value={this.props.endpoint.Thumbprint} onChange={(x) => {
                const endpoint = this.props.endpoint;
                endpoint.Thumbprint = x;
                this.props.onChange(endpoint);
            }}/>
                <Note>
                    You can confirm the validity of the thumbprint by opening the Tentacle administration tool on the discovered Tentacle.
                    {this.props.endpoint.CertificateSignatureAlgorithm && (<span>
                            This certificate uses the <strong>{this.props.endpoint.CertificateSignatureAlgorithm}</strong> algorithm.
                        </span>)}
                </Note>
                {this.props.serverThumbprint && (<Note>
                        Please ensure the Tentacle is configured to trust an Octopus Server with the thumbprint:
                        <br />
                        <ServerThumbprint thumbprint={this.props.serverThumbprint}/>
                    </Note>)}
            </ExpandableFormSection>);
    }
    static displayName = "TentacleThumbprint";
}
export default TentacleThumbprint;
