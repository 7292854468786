import React from "react";
import styles from "./style.module.less";
export type DynamicEnvironmentDeprovisioningIconProps = {
    title: string;
};
export function DynamicEnvironmentDeprovisioningIcon({ title }: DynamicEnvironmentDeprovisioningIconProps) {
    return (<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.dynamicEnvironmentStateIcon}>
            <title>{title}</title>
            <path d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM7.64687 12.3531L4.14687 8.85312C4.00312 8.70937 3.9625 8.49375 4.0375 8.30937C4.1125 8.125 4.29688 8 4.5 8H6.5V5C6.5 4.44688 6.94688 4 7.5 4H8.5C9.05313 4 9.5 4.44688 9.5 5V8H11.5C11.7031 8 11.8844 8.12187 11.9625 8.30937C12.0406 8.49687 11.9969 8.7125 11.8531 8.85312L8.35312 12.3531C8.15937 12.5469 7.84062 12.5469 7.64687 12.3531Z" fill="#68778D"/>
        </svg>);
}
