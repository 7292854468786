import { HasGitPersistenceSettings, HasRunbooksInGit, Repository, toGitBranch, type ProjectResource } from "@octopusdeploy/octopus-server-client";
import { repository } from "~/clientInstance";
export async function loadAllTriggerRunbooks(project: ProjectResource) {
    const params = { skip: 0, take: Repository.takeAll };
    if (HasGitPersistenceSettings(project.PersistenceSettings) && HasRunbooksInGit(project.PersistenceSettings)) {
        const defaultBranch = toGitBranch(project.PersistenceSettings.DefaultBranch);
        const runbooks = await repository.Runbooks.getRunbooks(project, defaultBranch, params);
        return runbooks.Items;
    }
    else {
        const runbooks = await repository.Runbooks.getRunbooks(project, undefined, params);
        return runbooks.Items;
    }
}
