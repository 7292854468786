import * as _ from "lodash";
import { repository, session } from "../../../../../clientInstance";
export interface DeploymentEnvironmentAndTenantInfo {
    EnvironmentId: string;
    TenantId?: string;
}
export async function loadPendingInterruptions(projectId: string, deployments: DeploymentEnvironmentAndTenantInfo[]) {
    const useOptimization = session.featureToggles?.includes("PermissionCheckOptimizationFeatureToggle");
    const allTasks = await Promise.all(deployments.map(async (deployment) => {
        const options = {
            environment: deployment.EnvironmentId,
            project: projectId,
            active: true,
            hasPendingInterruptions: true,
            tenant: deployment.TenantId,
        };
        return useOptimization ? repository.Tasks.getUnpaginatedTasks(options) : repository.Tasks.list(options);
    }));
    return _.flatten(allTasks.map((s) => s.Items));
}
