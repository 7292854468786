/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";
import SvgClockIcon from "@material-ui/icons/AccessTime";
import SvgTenantIcon from "@material-ui/icons/AccountCircle";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import * as React from "react";
import { withTheme } from "~/components/Theme";
const EnvironmentIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <path d="M14 30v35h172V30zm26 26H23V39h17zM14 83v35h95V83zm26 26H23V92h17zM14 134v35h172v-35zm26 26H23v-17h17z"/>
    </SvgIcon>);
const ExcludedEnvironmentIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <path d="M6.713 20.367l7.784-8.206 176.512 167.446-7.783 8.205z"/>
        <path d="M88.13 83H14v35h95v-15.2zM40 109H23V92h17zM141.89 134H14v35h164.78zM23 160v-17h17v17zM186 160.27V134h-27.69L186 160.27zM85.58 65H186V30H48.69l36.89 35zM32.27 30H14v35h55.16zM23 56V39h17v17z"/>
    </SvgIcon>);
const ProjectIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <path d="M174.33 26H25.67A14.67 14.67 0 0 0 11 40.7v14.36h178V40.7A14.67 14.67 0 0 0 174.33 26zM11 63.09v96.21A14.72 14.72 0 0 0 25.67 174h148.66A14.72 14.72 0 0 0 189 159.3V63.09zm59.12 58.42h-37v-37h37zm93.63 17H87.11a4.06 4.06 0 1 1 0-8.11h76.64a4.06 4.06 0 1 1 0 8.11zM83 111.52a4.06 4.06 0 0 1 4.06-4.06h59.31a4.06 4.06 0 1 1 0 8.11H87.11a4.06 4.06 0 0 1-4.11-4.05zm81.61-18.83h-77.5a4.06 4.06 0 0 1 0-8.11h77.56a4.06 4.06 0 1 1 0 8.11z"/>
    </SvgIcon>);
const MachineIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <path d="M119.19 70.52h6.54a27 27 0 0 0-18-18V59a3.87 3.87 0 0 1-3.84 3.84h-7.73A3.87 3.87 0 0 1 92.32 59v-6.53a27 27 0 0 0-18 18h6.54a3.86 3.86 0 0 1 3.83 3.84V82a3.86 3.86 0 0 1-3.83 3.84h-6.59a27 27 0 0 0 18 18.05v-6.5a3.87 3.87 0 0 1 3.84-3.84h7.68a3.87 3.87 0 0 1 3.84 3.84v6.54a27 27 0 0 0 18-18.05h-6.54a3.86 3.86 0 0 1-3.73-3.88v-7.64a3.86 3.86 0 0 1 3.83-3.84z"/>
        <path d="M170.17 16.15H29.83A3.82 3.82 0 0 0 26 20v116.71a3.82 3.82 0 0 0 3.83 3.82h65.34v15.74a14.92 14.92 0 0 0-9.3 9.3H42a4.83 4.83 0 1 0 0 9.66h43.87a14.92 14.92 0 0 0 28.26 0H158a4.83 4.83 0 1 0 0-9.66h-43.87a14.92 14.92 0 0 0-9.3-9.3v-15.74h65.34a3.82 3.82 0 0 0 3.83-3.82V20a3.82 3.82 0 0 0-3.83-3.85zM146.07 82a3.87 3.87 0 0 1-3.84 3.84h-8.58a34.59 34.59 0 0 1-26 26v8.58a3.87 3.87 0 0 1-3.84 3.84h-7.65a3.87 3.87 0 0 1-3.84-3.84v-8.58a34.59 34.59 0 0 1-26-26h-8.55A3.87 3.87 0 0 1 53.93 82v-7.64a3.87 3.87 0 0 1 3.84-3.84h8.58a34.59 34.59 0 0 1 26-26V36a3.87 3.87 0 0 1 3.84-3.84h7.68a3.87 3.87 0 0 1 3.81 3.84v8.58a34.59 34.59 0 0 1 26 26h8.58a3.87 3.87 0 0 1 3.84 3.84z"/>
    </SvgIcon>);
const WorkerPoolIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <path d="M30.5 13.5v172h35v-172zm26 163h-17v-17h17zM83.5 90.5v95h35v-95zm26 86h-17v-17h17zM134.5 13.5v172h35v-172zm26 163h-17v-17h17z"/>
    </SvgIcon>);
const DynamicWorkerPoolIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <path d="M 31 14 v 172 h 35 v -172 z m 26 163 h -17 v -17 h 17 z M 84 91 v 95 h 35 v -95 z m 26 86 h -17 v -17 h 17 z M 135 14 v 172 h 35 v -172 z m 26 163 h -17 v -17 h 17 z"/>
    </SvgIcon>);
const WorkerIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <path d="M170.17 16.15H29.83A3.82 3.82 0 0 0 26 20v116.71a3.82 3.82 0 0 0 3.83 3.82h65.34v15.74a14.92 14.92 0 0 0-9.3 9.3H42a4.83 4.83 0 1 0 0 9.66h43.87a14.92 14.92 0 0 0 28.26 0H158a4.83 4.83 0 1 0 0-9.66h-43.87a14.92 14.92 0 0 0-9.3-9.3v-15.74h65.34a3.82 3.82 0 0 0 3.83-3.82V20a3.82 3.82 0 0 0-3.83-3.85zM94.06 28.08h11.88a8.35 8.35 0 0 1 6.7 3.37l-5 21.51h-15L87 31.89a8.4 8.4 0 0 1 7.06-3.81zm6 98.92a40.74 40.74 0 0 1-40-33.1h80a40.74 40.74 0 0 1-39.95 33.1zm43.4-41.44h-87a6.14 6.14 0 0 1 0-12.28h1.68a41.77 41.77 0 0 1 21.54-36.54l5.74 21.48a4.19 4.19 0 0 0 4 3.09H111a4.18 4.18 0 0 0 4-3.23l5-21.5a41.81 41.81 0 0 1 21.81 36.7h1.67a6.14 6.14 0 1 1 0 12.28z"/>
    </SvgIcon>);
const TenantIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <SvgTenantIcon />
    </SvgIcon>);
const UnhealthyMachineIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon {...props} viewBox="0 0 16 16" style={{ fill: theme.whiteConstant, margin: 5 }}>
            <path d="M11.53 10.037728L9.007273 7.515 11.53 4.992273 10.537728 4 8.015 6.522727 5.492273 4 4.5 4.992273 7.022727 7.515 4.5 10.037728l.992273.992272L8.015 8.507273 10.537728 11.03z"/>
        </SvgIcon>));
const UnavailableMachineIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon {...props} viewBox="0 0 16 16" style={{ fill: theme.whiteConstant, margin: 5 }}>
            <path d="M12.240622 3.58747c0-.126157-.09669-.228427-.21596-.228427H4.802677l7.437945 6.58802V3.58747zm-3.739554 7.18861h2.69302l.896546.793832L12.5 11.05282 3.409366 3 3 3.517093l.895418.793235v6.237922c0 .126157.09669.228428.21596.228428h3.684295v.938163c-.246475.089186-.440077.293965-.524395.55467H4.797602c-.15057 0-.27263.129105-.27263.288366 0 .15926.12206.288368.27263.288368H7.27297c.116063.36099.43654.603756.797023.603756.360484 0 .68096-.242766.797024-.603756h2.47142c.15057 0 .27263-.129107.27263-.288368 0-.15926-.12206-.288367-.27263-.288367H8.86307c-.084762-.260412-.278197-.465014-.524395-.55467v-.93876h.162393z"/>
        </SvgIcon>));
const ShellNameIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <path d="M31.62,145.85l-10.26-16.2L86.89,90.94,21.36,51.34,31.62,35.13,115.7,87.34v9Z"/>
        <path d="M98.8,143.74h79.93V163H98.8Z"/>
    </SvgIcon>);
const ExcludedMachineIcon = (props: SvgIconProps) => {
    return withTheme((theme) => (<SvgIcon {...props} viewBox="0 0 200 200" style={{ fill: theme.whiteConstant, margin: 5, height: 19.2, width: 19.2 }}>
            <path d="M6.713 20.367l7.784-8.206 176.512 167.446-7.783 8.205z"/>
            <path d="M158 165.57h-43.87a14.92 14.92 0 0 0-9.3-9.3v-15.74h45l-33.6-31.88a34.39 34.39 0 0 1-8.52 3.2v8.58a3.87 3.87 0 0 1-3.84 3.84h-7.71a3.87 3.87 0 0 1-3.84-3.84v-8.58a34.59 34.59 0 0 1-26-26h-8.55A3.87 3.87 0 0 1 53.93 82v-7.64a3.87 3.87 0 0 1 3.84-3.84h8.58a34.57 34.57 0 0 1 2.37-6.91L26 23.08v113.63a3.82 3.82 0 0 0 3.83 3.82h65.34v15.74a14.92 14.92 0 0 0-9.3 9.3H42a4.83 4.83 0 1 0 0 9.66h43.87a14.92 14.92 0 0 0 28.26 0H158a4.83 4.83 0 1 0 0-9.66zM75.23 54.2a34.5 34.5 0 0 1 17.09-9.65V36a3.87 3.87 0 0 1 3.84-3.84h7.68a3.87 3.87 0 0 1 3.84 3.84v8.58a34.59 34.59 0 0 1 26 26h8.58a3.87 3.87 0 0 1 3.84 3.84V82a3.87 3.87 0 0 1-3.84 3.84h-8.58a34.42 34.42 0 0 1-8.39 15.79l41 38.86h3.94a3.82 3.82 0 0 0 3.83-3.82V20a3.82 3.82 0 0 0-3.83-3.82h-135z"/>
            <path d="M74.69 69.27c-.15.41-.3.83-.42 1.25H76zM119.71 96.4a26.81 26.81 0 0 0 6-10.52h-6.54a3.86 3.86 0 0 1-3.81-3.88v-7.64a3.86 3.86 0 0 1 3.83-3.84h6.54a27 27 0 0 0-18-18V59a3.87 3.87 0 0 1-3.84 3.84h-7.73A3.87 3.87 0 0 1 92.32 59v-6.53a26.83 26.83 0 0 0-11.53 7zM107.68 100.57v3.36c.87-.26 1.73-.58 2.57-.92zM84.64 78.72V82a3.86 3.86 0 0 1-3.83 3.84h-6.54a27 27 0 0 0 18 18.05v-6.5a3.87 3.87 0 0 1 3.84-3.84h4.12z"/>
        </SvgIcon>));
};
const DeploymentProcessIcon = (props: SvgIconProps) => {
    return (<SvgIcon {...props} viewBox="0 0 200 200">
            <path d="M176.84 15.09l-21.16 3.44C134.57 21.44 116 31 102.56 45.89l-23.72 26L46.52 70a4.9 4.9 0 00-4.35 2.16L25.91 96.43a4.89 4.89 0 002 7.17L50.89 114 86 149.11l10.44 23a4.89 4.89 0 007.16 2.05l24.64-16.35a4.87 4.87 0 002.18-4.4l-2.16-32.31 25.88-23.62C169 84 178.56 65.43 181.47 44.32l3.44-21.16a7 7 0 00-8.07-8.07zm-39 67.8a14.67 14.67 0 110-20.75 14.67 14.67 0 01.02 20.75zM73.59 153.49l-27.08-27.08a1.94 1.94 0 00-2.71 0c-1.47 1.42-3.49 3.42-4.21 4.14C15.67 154.44 15 185 15 185s30.56-.67 54.48-24.59c.72-.72 2.72-2.74 4.14-4.21a1.94 1.94 0 00-.03-2.71z"></path>
        </SvgIcon>);
};
const RunbookProcessIcon = (props: SvgIconProps) => {
    return (<SvgIcon {...props} viewBox="0 0 200 200">
            <path d="M185.74 7.38a13.85 13.85 0 00-10.44-4.75H43.84A13.87 13.87 0 0030 16.5q0 .62.06 1.23L9.91 159.52a33.58 33.58 0 007.71 26.7 33.29 33.29 0 0025.15 11.15h109.86a13.87 13.87 0 0013.74-12l22.63-167a13.84 13.84 0 00-3.26-10.99zM135 106H60.57a8.59 8.59 0 010-17.17H135a8.59 8.59 0 110 17.17zm8.59-42.93H69.16a8.59 8.59 0 110-17.17h74.41a8.59 8.59 0 110 17.17z"></path>
        </SvgIcon>);
};
const BindIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 200 200" fill={theme.iconNeutral} {...props}>
            <path d="M191.4,99.8C191.4,99.8,191.4,99.8,191.4,99.8c0-19.2-15.7-34.9-34.9-34.9l-47.3,0c-19.2,0-34.9,15.7-34.9,34.9l0,1.3&#xA;                c0,2.4,0,4,0.8,6.7c0.1,0.3,0.3,0.6,0.5,0.8c0.1,0.1,0.2,0.2,0.3,0.3c2,1.3,4.4,2,7.1,2.1c3.9,0,7.6-1.8,10.2-4.9&#xA;                c0.4-0.5,0.6-1.3,0.3-1.9c-0.3-0.9-0.7-3.1-0.6-4.3c0.3-9.2,7.5-16.3,16.3-16.3l47.3,0c4.3,0,8.4,1.7,11.5,4.8&#xA;                c3.1,3.1,4.8,7.2,4.8,11.5c0,9-7.3,16.3-16.3,16.3l-26.1,0c-0.9,0-1.6,0.6-1.9,1.4c-1.5,4.5-6.7,11.1-10.1,13.6&#xA;                c-0.7,0.5-1,1.4-0.7,2.2c0.3,0.8,1,1.4,1.9,1.4l36.9,0C175.6,134.6,191.3,119,191.4,99.8z"/>
            <path d="M124.7,91.9c-0.1-0.4-0.4-0.8-0.8-1.1c-2-1.3-4.4-2-7.1-2.1c-3.9,0-7.6,1.8-10.2,4.9c-0.5,0.6-0.6,1.4-0.3,2.1&#xA;                c0.3,0.7,0.8,2.8,0.8,4.4c0,9-7.3,16.3-16.3,16.3H43.5c-9,0-16.3-7.3-16.3-16.3c0-4.3,1.7-8.4,4.8-11.5c3.1-3.1,7.2-4.8,11.5-4.8&#xA;                L72,83.7c0.5,0,1.1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.5-1.5c-0.1-2,3.9-9.1,8.4-11.9c0.8-0.5,1.1-1.4,0.9-2.2c-0.2-0.9-1-1.5-1.9-1.5&#xA;                l-37.8-0.6c-9.3,0-18.1,3.6-24.7,10.2c-6.6,6.6-10.2,15.4-10.2,24.7c0,9.3,3.6,18.1,10.2,24.7c6.6,6.6,15.4,10.2,24.7,10.2h47.3&#xA;                c19.2,0,34.9-15.7,34.9-34.9C125.8,96.8,125.6,95,124.7,91.9z"/>
        </SvgIcon>));
const UnbindIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 200 200" fill={theme.iconNeutral} {...props}>
            <path d="M124.8,108c0.6-2.5,0.9-5,0.9-7.7c0.1-3.5-0.1-5.3-1-8.4c-0.1-0.4-0.4-0.8-0.8-1.1c-2-1.3-4.4-2-7.1-2.1&#xA;&#x9;        c-3.2,0-6.3,1.2-8.7,3.4L124.8,108z"/>
            <g>
                <path d="M167.2,163.7L32.7,36.2l-7.8,8.2l22.2,21.1l-3.6-0.1c-9.3,0-18.1,3.6-24.7,10.2C12.2,82.2,8.6,91,8.6,100.3s3.6,18.1,10.2,24.7c6.6,6.6,15.4,10.2,24.7,10.2h47.3c8.2,0,15.8-2.9,21.8-7.7l46.8,44.4L167.2,163.7z M90.8,116.4H43.5c-9,0-16.3-7.3-16.3-16.3c0-4.3,1.7-8.4,4.8-11.5c3.1-3.1,7.2-4.8,11.5-4.8l22.9-0.1l8.8,8.3c-0.6,2.5-0.9,5.1-0.9,7.7v1.3c0,2.4,0,4,0.8,6.7c0.1,0.3,0.3,0.6,0.5,0.8c0.1,0.1,0.2,0.2,0.3,0.3c2,1.3,4.4,2,7.1,2.1c3.2,0,6.2-1.2,8.6-3.3l7,6.7C96.4,115.6,93.7,116.4,90.8,116.4z"/>
                <path d="M156.5,64.9h-47.3c-8.2,0-15.8,2.9-21.7,7.6l13.9,13.1c2.3-1.3,5-2.1,7.9-2.1h47.3c4.3,0,8.4,1.7,11.5,4.8c3.1,3.1,4.8,7.2,4.8,11.5c0,9-7.3,16.3-16.3,16.3h-23l19.6,18.6h3.3c19.1-0.2,34.8-15.8,34.9-35C191.4,80.6,175.7,64.9,156.5,64.9z"/>
            </g>
        </SvgIcon>));
const InsertVariableIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon width="40" height="40" viewBox="0 0 40 40" fill={theme.iconNeutral} {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M27.4 6.5C27.4 5.67157 26.7289 5 25.901 5C22.9929 5 20.6355 7.35912 20.6355 10.2692V13.7699C20.6355 14.2465 20.4993 14.7131 20.2431 15.1148L17.8138 18.9241C17.3954 19.5802 17.3954 20.4198 17.8138 21.0759L20.2431 24.8852C20.4993 25.2869 20.6355 25.7535 20.6355 26.2301V29.7308C20.6355 32.6409 22.9929 35 25.901 35C26.7289 35 27.4 34.3284 27.4 33.5C27.4 32.6716 26.7289 32 25.901 32C24.6486 32 23.6334 30.984 23.6334 29.7308V26.2301C23.6334 25.1817 23.3339 24.1551 22.7703 23.2713L20.6841 20L22.7703 16.7287C23.3339 15.8449 23.6334 14.8183 23.6334 13.7699V10.2692C23.6334 9.01597 24.6486 8 25.901 8C26.7289 8 27.4 7.32843 27.4 6.5Z"/>
            <path d="M29.6 6.5C29.6 5.67157 30.2711 5 31.0989 5C34.007 5 36.3645 7.35912 36.3645 10.2692V13.7699C36.3645 14.2465 36.5006 14.7131 36.7568 15.1148L39.1861 18.9241C39.6046 19.5802 39.6046 20.4198 39.1861 21.0759L36.7568 24.8852C36.5006 25.2869 36.3645 25.7535 36.3645 26.2301V29.7308C36.3645 32.6409 34.007 35 31.0989 35C30.2711 35 29.6 34.3284 29.6 33.5C29.6 32.6716 30.2711 32 31.0989 32C32.3513 32 33.3666 30.984 33.3666 29.7308V26.2301C33.3666 25.1817 33.666 24.1551 34.2297 23.2713L36.3159 20L34.2297 16.7287C33.666 15.8449 33.3666 14.8183 33.3666 13.7699V10.2692C33.3666 9.01597 32.3513 8 31.0989 8C30.2711 8 29.6 7.32843 29.6 6.5Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.26899 28.2827C9.14901 28.9626 9.60288 29.611 10.2827 29.7309C10.9626 29.8509 11.611 29.397 11.7309 28.7172L12.3428 25.2499H14.75C15.4404 25.2499 16 24.6903 16 23.9999C16 23.3096 15.4404 22.7499 14.75 22.7499H12.784L13.7546 17.2499H15.75C16.4404 17.2499 17 16.6903 17 15.9999C17 15.3096 16.4404 14.7499 15.75 14.7499H14.1958L14.7309 11.7172C14.8509 11.0373 14.397 10.389 13.7172 10.269C13.0373 10.149 12.389 10.6029 12.269 11.2827L11.6571 14.7499H7.49047L7.9839 11.6996C8.09415 11.0181 7.63105 10.3763 6.94955 10.266C6.26806 10.1558 5.62623 10.6189 5.51598 11.3004L4.95797 14.7499H2.75C2.05964 14.7499 1.5 15.3096 1.5 15.9999C1.5 16.6903 2.05964 17.2499 2.75 17.2499H4.55356L3.66385 22.7499H1.75C1.05964 22.7499 0.5 23.3096 0.5 23.9999C0.5 24.6903 1.05964 25.2499 1.75 25.2499H3.25944L2.76598 28.3004C2.65574 28.9819 3.11884 29.6237 3.80033 29.7339C4.48183 29.8442 5.12366 29.3811 5.2339 28.6996L5.79194 25.2499H9.80419L9.26899 28.2827ZM7.08606 17.2499H11.216L10.2454 22.7499H6.19635L7.08606 17.2499Z"/>
        </SvgIcon>));
const SelectLogoIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 200 200" fill={theme.iconNeutral} {...props}>
            <path d="M195.5,25.9c0-5.7-2.3-11.1-6.4-15c-3.9-4.1-9.3-6.4-15-6.4H25.9c-5.7,0-11.1,2.3-15,6.4c-4.1,3.9-6.4,9.3-6.4,15v148.2&#xA;&#x9;c0,5.7,2.3,11.1,6.4,15c3.9,4.1,9.3,6.4,15,6.4h148.2c11.7-0.2,21.2-9.7,21.4-21.4L195.5,25.9z M69,49.3h26.6V22.7h8.7v26.6H131V58&#xA;&#x9;h-26.6v26.6h-8.7V58H69V49.3z M25.9,163.6l36.9-47.9l26.7,31.8l36.9-47.6l47.6,63.6H25.9z"/>
        </SvgIcon>));
const GitIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 200 200" fill={theme.iconNeutral} {...props}>
            <path d="M191.52,91.53l-83.05-83a12.25,12.25,0,0,0-17.33,0L73.9,25.73,95.78,47.6A14.55,14.55,0,0,1,114.2,66.15l21.08,21.08a14.57,14.57,0,1,1-8.72,8.22L106.89,75.79v51.75a14.39,14.39,0,0,1,3.85,2.75,14.56,14.56,0,1,1-15.84-3.18V74.88a14.4,14.4,0,0,1-4.77-3.18A14.57,14.57,0,0,1,87,55.77L65.43,34.2,8.48,91.14a12.25,12.25,0,0,0,0,17.33l83.05,83.05a12.25,12.25,0,0,0,17.33,0l82.66-82.66A12.25,12.25,0,0,0,191.52,91.53Z"/>
        </SvgIcon>));
const GitTagIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 15 15" fill={theme.iconNeutral} {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.5 6.775V1.75C1.5 1.6837 1.52634 1.62011 1.57322 1.57322C1.62011 1.52634 1.6837 1.5 1.75 1.5H6.775C6.80785 1.49994 6.84039 1.50636 6.87077 1.51888C6.90114 1.53141 6.92874 1.5498 6.952 1.573L13.202 7.823C13.2253 7.84622 13.2438 7.87381 13.2564 7.90418C13.269 7.93456 13.2754 7.96712 13.2754 8C13.2754 8.03288 13.269 8.06544 13.2564 8.09582C13.2438 8.12619 13.2253 8.15378 13.202 8.177L8.177 13.202C8.15378 13.2253 8.12619 13.2438 8.09582 13.2564C8.06544 13.269 8.03288 13.2754 8 13.2754C7.96712 13.2754 7.93456 13.269 7.90418 13.2564C7.87381 13.2438 7.84622 13.2253 7.823 13.202L1.573 6.952C1.5498 6.92874 1.53141 6.90114 1.51888 6.87077C1.50636 6.84039 1.49994 6.80785 1.5 6.775ZM4.56639e-09 6.775V1.75C4.56639e-09 0.784 0.784 0 1.75 0H6.775C7.239 0 7.685 0.184 8.013 0.513L14.263 6.763C14.5909 7.09115 14.7751 7.53609 14.7751 8C14.7751 8.46391 14.5909 8.90885 14.263 9.237L9.237 14.263C8.90885 14.5909 8.46391 14.7751 8 14.7751C7.53609 14.7751 7.09115 14.5909 6.763 14.263L0.513 8.013C0.350352 7.85047 0.221328 7.65747 0.133305 7.44505C0.0452814 7.23263 -1.66056e-05 7.00494 4.56639e-09 6.775ZM5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5C4 5.26522 4.10536 5.51957 4.29289 5.70711C4.48043 5.89464 4.73478 6 5 6C5.26522 6 5.51957 5.89464 5.70711 5.70711C5.89464 5.51957 6 5.26522 6 5C6 4.73478 5.89464 4.48043 5.70711 4.29289C5.51957 4.10536 5.26522 4 5 4Z"/>
        </SvgIcon>));
const GitBranchIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 8 12" fill={theme.iconNeutral} {...props}>
            <path d="M.25,2.34369V2.14992c.0088-.06168.01509-.12385.02677-.185a1.49975,1.49975,0,0,1,2.9585.48778,1.46981,1.46981,0,0,1-.70886,1.07.04741.04741,0,0,0-.027.046Q2.5004,5.31245,2.5,7.05627V7.0892l.02653-.01344a2.62822,2.62822,0,0,1,.57562-.21983,3.658,3.658,0,0,1,.90629-.10588,1.24589,1.24589,0,0,0,.48788-.10537,1.69792,1.69792,0,0,0,.67654-.524,1.52124,1.52124,0,0,0,.3053-.64569,2.12325,2.12325,0,0,0,.02094-.42348c0-.00812-.01088-.01885-.01936-.024a1.47438,1.47438,0,0,1-.37835-.32305,1.429,1.429,0,0,1-.3206-1.24671A1.459,1.459,0,0,1,5.7862,2.32234a1.7676,1.7676,0,0,1,.50593-.08126,1.12448,1.12448,0,0,1,.44347.07754,1.474,1.474,0,0,1,.9829,1.11615c.01572.06848.02125.13929.0315.209v.18752a.27749.27749,0,0,0-.00556.03,1.39626,1.39626,0,0,1-.08021.371,1.49393,1.49393,0,0,1-.63845.779.04549.04549,0,0,0-.02637.0464c.002.0447.00006.08956.00067.13434a2.51448,2.51448,0,0,1-.26555,1.16084A3.38859,3.38859,0,0,1,4.84117,8.08634a2.431,2.431,0,0,1-.82147.15281,2.75136,2.75136,0,0,0-.339.02511,1.285,1.285,0,0,0-.66829.29288c-.05724.049-.10864.10481-.163.1578l.02481.03016a1.50144,1.50144,0,0,1-.96758,2.48613,1.47471,1.47471,0,0,1-.92017-.20083A1.469,1.469,0,0,1,.275,10.01064c-.01111-.057-.01679-.115-.025-.1726V9.64428c.00448-.03591.008-.072.0136-.10769A1.4853,1.4853,0,0,1,.96449,8.4717a.06486.06486,0,0,0,.03638-.063Q.99939,6.01888,1.00158,3.629A.14128.14128,0,0,0,.928,3.49167a1.46406,1.46406,0,0,1-.647-.94529C.26673,2.47966.26015,2.41129.25,2.34369ZM2.5,2.25036A.75.75,0,1,0,1.7494,3,.75486.75486,0,0,0,2.5,2.25036Zm3.75028.74985A.75.75,0,1,0,7,3.75021.75476.75476,0,0,0,6.25024,3.00021ZM2.5,9.75007a.75.75,0,1,0-.74968.75052A.75489.75489,0,0,0,2.5,9.75007Z"/>
        </SvgIcon>));
const GitCommitIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 10.49 6" fill={theme.iconNeutral} {...props}>
            <path d="M10.5,6.75H8.2s-.06,0-.07,0A3,3,0,0,1,3.19,8.17,3,3,0,0,1,2.38,6.8s0,0-.07,0H0V5.25h2.3s.06,0,.07,0A3,3,0,0,1,4,3.27,2.76,2.76,0,0,1,5.47,3a2.94,2.94,0,0,1,2.06,1,3,3,0,0,1,.6,1.14s0,0,.07,0h2.3ZM6.75,6a1.5,1.5,0,1,0-1.5,1.5A1.5,1.5,0,0,0,6.75,6Z" transform="translate(-0.01 -3)"/>
        </SvgIcon>));
const DatabaseIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 448 512" fill={theme.iconNeutral} {...props}>
            <path d="M448 80V128C448 172.2 347.7 208 224 208C100.3 208 0 172.2 0 128V80C0 35.82 100.3 0 224 0C347.7 0 448 35.82 448 80zM393.2 214.7C413.1 207.3 433.1 197.8 448 186.1V288C448 332.2 347.7 368 224 368C100.3 368 0 332.2 0 288V186.1C14.93 197.8 34.02 207.3 54.85 214.7C99.66 230.7 159.5 240 224 240C288.5 240 348.3 230.7 393.2 214.7V214.7zM54.85 374.7C99.66 390.7 159.5 400 224 400C288.5 400 348.3 390.7 393.2 374.7C413.1 367.3 433.1 357.8 448 346.1V432C448 476.2 347.7 512 224 512C100.3 512 0 476.2 0 432V346.1C14.93 357.8 34.02 367.3 54.85 374.7z"/>
        </SvgIcon>));
const FeedbackIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 16 17" style={{ width: 16, verticalAlign: "middle", marginRight: ".2em" }} fill={theme.iconNeutral} {...props}>
            <path d="M14.4 0.5H1.6C0.72 0.5 0 1.22 0 2.1V16.5L3.2 13.3H14.4C15.28 13.3 16 12.58 16 11.7V2.1C16 1.22 15.28 0.5 14.4 0.5Z" fill="#FB8C00"/>
            <path d="M3 10.5H4.1C4.4025 10.5 4.65 10.3 4.65 10.0556V6.05556C4.65 5.81111 4.4025 5.61111 4.1 5.61111H3V10.5ZM13.9065 7.33556C13.967 7.22444 14 7.10444 14 6.98V6.5C14 6.01111 13.505 5.61111 12.9 5.61111H9.875L10.381 3.54444C10.4085 3.44667 10.392 3.34 10.337 3.25111C10.2105 3.05111 10.051 2.86889 9.853 2.70889L9.6 2.5L6.0745 5.34889C5.8655 5.51778 5.75 5.74444 5.75 5.98V9.46444C5.75 10.0333 6.3275 10.5 7.037 10.5H11.4975C11.8825 10.5 12.2455 10.3356 12.4435 10.0689L13.9065 7.33556Z" fill="white"/>
        </SvgIcon>));
const ChevronIcon = (props: SvgIconProps) => (<SvgIcon {...props}>
        <path d="M12.4805 16.75L17.1016 12.1289C17.457 11.8008 17.457 11.2266 17.1016 10.8984C16.7734 10.543 16.1992 10.543 15.8711 10.8984L10.6211 16.1484C10.2656 16.4766 10.2656 17.0508 10.6211 17.3789L15.8711 22.6289C16.0352 22.793 16.2539 22.875 16.5 22.875C16.7188 22.875 16.9375 22.793 17.1016 22.6289C17.457 22.3008 17.457 21.7266 17.1016 21.3984L12.4805 16.75ZM17.7305 16.75L22.3516 12.1289C22.707 11.8008 22.707 11.2266 22.3516 10.8984C22.0234 10.543 21.4492 10.543 21.1211 10.8984L15.8711 16.1484C15.5156 16.4766 15.5156 17.0508 15.8711 17.3789L21.1211 22.6289C21.2852 22.793 21.5039 22.875 21.75 22.875C21.9688 22.875 22.1875 22.793 22.3516 22.6289C22.707 22.3008 22.707 21.7266 22.3516 21.3984L17.7305 16.75Z" fill="white"/>
    </SvgIcon>);
const ClockIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <SvgClockIcon />
    </SvgIcon>);
const WarningIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <ReportProblemOutlinedIcon />
    </SvgIcon>);
const CancelIcon = (props: SvgIconProps) => (<SvgIcon {...props} viewBox="0 0 200 200">
        <CancelOutlinedIcon />
    </SvgIcon>);
export { EnvironmentIcon, ExcludedEnvironmentIcon, ProjectIcon, MachineIcon, WorkerPoolIcon, TenantIcon, DynamicWorkerPoolIcon, WorkerIcon, ExcludedMachineIcon, UnhealthyMachineIcon, UnavailableMachineIcon, ShellNameIcon, DeploymentProcessIcon, RunbookProcessIcon, BindIcon, UnbindIcon, InsertVariableIcon, SelectLogoIcon, GitIcon, GitTagIcon, GitBranchIcon, GitCommitIcon, DatabaseIcon, FeedbackIcon, ChevronIcon, ClockIcon, WarningIcon, CancelIcon, };
