import * as React from "react";
import { TentacleDownloadButton } from "~/areas/infrastructure/components/TentacleDownloadButton/TentacleDownloadButton";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import { TentacleCalloutHeading } from "./TentacleCalloutHeading";
export const WindowsPollingTentacleCallout: React.SFC<{}> = () => (<div>
        <h3>Installation Instructions</h3>
        <TentacleCalloutHeading />
        <p>
            During setup, configure the Tentacle in <strong>polling</strong> mode. The wizard will then ask for your credentials to connect to the Octopus server and establish the connection.
        </p>
        <Note>
            Learn more about <ExternalLink href="PollingTentacle">configuring Polling Tentacles</ExternalLink>.
        </Note>
        <TentacleDownloadButton />
    </div>);
WindowsPollingTentacleCallout.displayName = "WindowsPollingTentacleCallout"
export default WindowsPollingTentacleCallout;
