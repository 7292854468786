import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import EditScheduledTriggers from "./EditScheduledTriggers";
interface CreateDeploymentScheduledTriggerPageProps {
    spaceId: string;
    projectSlug: string;
}
export function CreateDeploymentScheduledTriggerPage({ spaceId, projectSlug }: CreateDeploymentScheduledTriggerPageProps) {
    return <EditScheduledTriggers create triggerActionCategory={TriggerActionCategory.Deployment} spaceId={spaceId} projectSlug={projectSlug}/>;
}
