import { Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
export function deleteEnvironmentMenuItem(environmentId: string, environmentName: string, onDeleteEnvironment: (environmentId: string) => Promise<void>): MenuItem {
    return OverflowMenuItems.deleteItem("Delete environment", `Are you sure you want to delete dynamic environment '${environmentName}'?`, async () => {
        await onDeleteEnvironment(environmentId);
        return true;
    }, <div>
            <p>Deleting this environment is permanent. There is no going back.</p>
            <p>Do you wish to continue?</p>
        </div>, { permission: Permission.EnvironmentDelete, environment: "*" });
}
