import type { MachineTenantTagSummaryResource, TagResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import styles from "../style.module.less";
import { TagSetContentRow } from "./TagSetContentRow";
interface TagSetContentProps {
    spaceId: string;
    tagSet: TagSetResource;
    tags: TagResource[];
    tenantTagSummaries: MachineTenantTagSummaryResource;
}
export const TagSetContent = ({ spaceId, tagSet, tags, tenantTagSummaries }: TagSetContentProps) => {
    if (tags.length === 0)
        return null;
    return (<>
            <div className={styles.tenantTagRowsOuterContainer} key={tagSet.Id}>
                <h5>{tagSet.Name}</h5>
                {tags.map((tag) => {
            return <TagSetContentRow spaceId={spaceId} tag={tag} tenantTagSummaries={tenantTagSummaries}/>;
        })}
            </div>
        </>);
};
