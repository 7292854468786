import { RadioButton } from "@octopusdeploy/design-system-components";
import type { CloudFormationChangesetProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
import isBound from "~/components/form/BoundField/isBound";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/BoundRadioButtonGroup";
import type { ScriptPackageProperties } from "../script/ScriptPackageReferenceDialog";
const getNamingSummary = (getProps: (props: keyof CloudFormationChangesetProperties) => string): SummaryNode => {
    const generatedNamed = getProps("Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName") === "True";
    return generatedNamed
        ? Summary.summary("Change sets will have automatically generated names")
        : Summary.summary(<span>
                  Change set named <strong>{getProps("Octopus.Action.Aws.CloudFormation.ChangeSet.Name")}</strong>
              </span>);
};
const getExecutionSummary = (getProps: (props: keyof CloudFormationChangesetProperties) => string): SummaryNode => {
    const defer = getProps("Octopus.Action.Aws.CloudFormation.ChangeSet.Defer") === "True";
    return defer ? Summary.summary("Execution will be deferred") : Summary.summary("Change set will be executed immediately");
};
interface CloudFormationChangesetFeatureState {
    generateNameBound: boolean;
}
export class CloudFormationChangesetFeature extends BaseComponent<ActionEditProps<CloudFormationChangesetProperties, ScriptPackageProperties>, CloudFormationChangesetFeatureState> {
    componentDidMount() {
        if (!this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"]) {
            this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName": "True" });
        }
        if (!this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.Defer"]) {
            this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.Defer": "False" });
        }
    }
    render() {
        return (<>
                <ExpandableFormSection summary={getNamingSummary((prop: keyof CloudFormationChangesetProperties) => this.props.properties[prop])} title="Naming" errorKey="Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName" isExpandedByDefault={this.props.expandedByDefault} help="Specify a name or automatically generate one">
                    <BoundRadioButtonGroup onIsBoundChanged={(bound) => this.setState({ generateNameBound: bound })} resetValue={"True"} variableLookup={{
                localNames: this.props.localNames,
            }} value={this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"]} onChange={(val: string) => this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName": val })}>
                        <RadioButton value="True" label="Automatically Generate Change Set Name" isDefault/>
                        <RadioButton value="False" label="Manually Specify Change Set Name"/>
                    </BoundRadioButtonGroup>

                    {(this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"] !== "True" || isBound(this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"])) && (<React.Fragment>
                            <VariableLookupText localNames={this.props.localNames} label={"Change Set Name"} error={this.props.getFieldError("Octopus.Action.Aws.CloudFormation.ChangeSet.Name")} value={this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.Name"]} onChange={(val: string) => this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.Name": val })}/>
                            <Note>Please note that change sets must be unique for a given stack.</Note>
                        </React.Fragment>)}
                </ExpandableFormSection>

                <ExpandableFormSection summary={getExecutionSummary((prop: keyof CloudFormationChangesetProperties) => this.props.properties[prop])} title="Execution" errorKey="Octopus.Action.Aws.CloudFormation.ChangeSet.Defer" isExpandedByDefault={this.props.expandedByDefault} help="Choose when the change set will be executed">
                    <BoundRadioButtonGroup resetValue={"False"} variableLookup={{
                localNames: this.props.localNames,
            }} value={this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.Defer"]} onChange={(val: string) => this.props.setProperties({ "Octopus.Action.Aws.CloudFormation.ChangeSet.Defer": val })}>
                        <RadioButton value="False" label="Execute Immediately" isDefault/>
                        <RadioButton value="True" label="Defer Change Set Execution"/>
                    </BoundRadioButtonGroup>
                </ExpandableFormSection>
            </>);
    }
    static displayName = "CloudFormationChangesetFeature";
}
pluginRegistry.registerFeature({
    featureName: "Octopus.Features.CloudFormation.ChangeSet.Feature",
    title: "CloudFormation Change Sets",
    description: "Use Cloud Formation Change sets when updating or creating stacks.",
    edit: CloudFormationChangesetFeature,
    disable: (properties) => {
        Object.keys(properties)
            .filter((name) => {
            return name.indexOf("Octopus.Action.Aws.CloudFormation.ChangeSet.") === 0;
        })
            .forEach((name) => {
            delete properties[name];
        });
    },
    priority: 1,
});
