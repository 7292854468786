import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectRunbookSnapshotOverview } from "../../CommonComponents/CommonProjectRunbookSnapshotsViews";
export const ProjectRunbookSnapshotRunbookRunsRetryRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.runbookSnapshot().runbookRuns.retry,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookSnapshotOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
