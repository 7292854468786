import type { AuthenticationProviderElement, LoginState, OctopusError } from "@octopusdeploy/octopus-server-client";
export class AuthExtensionManager {
    private readonly authExtensions: {
        [authExtensionName: string]: AuthExtension;
    } = {};
    getAuthExtension(authProviderName: string): AuthExtension | null {
        return this.authExtensions[authProviderName] ?? null;
    }
    registerAuthExtension = (name: string, extension: AuthExtension) => {
        this.authExtensions[name] = extension;
    };
}
export const authExtensionManager = new AuthExtensionManager();
export type AuthExtension = (octopusClient: AuthExtensionClient, provider: AuthenticationProviderElement, loginState: LoginState, onError: AuthExtensionOnError, isDarkMode: boolean) => AuthProvider;
export interface AuthExtensionClient {
    resolve: (url: string) => string;
    post: <Request, Response>(authUrl: string, postData: Request) => Promise<Response>;
}
export type AuthExtensionOnError = (error: OctopusError | Error) => void;
export interface AuthProvider {
    Name: string;
    LinkHtml: string;
    SignIn: () => void;
}
