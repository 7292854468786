import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import type { ProjectInsightsFilter } from "./ProjectInsightsDataLoader/ProjectInsightsDataLoader";
interface Props {
    project: ProjectResource;
    filter: ProjectInsightsFilter;
}
export function DownloadProjectInsightsCsvButton({ project, filter }: Props) {
    const dispatchAction = useAnalyticSimpleActionDispatch();
    const onClick = () => dispatchAction("Click Project Insights Download CSV");
    const tenantArgs = `${filter.tenantId ? `&tenantId=${filter.tenantId}` : ""}${filter.tenantFilter ? `&tenantFilter=${filter.tenantFilter}` : ""}`;
    const url = `api/${project.SpaceId}/projects/${project.Id}/insights/deployments/csv?channelId=${filter.channelId}&environmentId=${filter.environmentId}${tenantArgs}`;
    return <NavigationButton type={NavigationButtonType.Ternary} href={url} label="Download CSV" external onClick={onClick}/>;
}
