import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { MachineModelHealthStatus, type DeploymentTargetResource, type EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ConfigurationProps, ConfigurationState } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialog";
import { ConfigurationDialog } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialog";
import { NfsDriverInstallationHelpContent, TargetConfigurationHelpContent, targetConfigurationHelpImage, targetNfsDriverInstallationHelpImage, TentacleInstallationHelpContent, tentacleInstallationHelpImage, } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialogHelp";
import { ConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationMessages";
import type { BaseConfiguration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationPage";
import { InstallationPage } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/InstallationPage";
import { TargetConfigurationPage } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/TargetConfigurationPage";
import { createTargetHelmCommand } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/createHelmCommand";
import { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { useKubernetesAgentAsWorker } from "~/areas/infrastructure/components/WorkerMachineSettings/useKubernetesAgentAsWorker";
import { repository } from "~/clientInstance";
import type { PagedOnboardingDialogPageData } from "~/components/OnboardingDialog/PagedOnboardingDialog";
import StringHelper from "~/utils/StringHelper/index";
type TargetFieldErrors = {
    [K in keyof TargetConfigurationResource]?: string;
};
interface TargetConfigurationState extends ConfigurationState<TargetConfigurationResource> {
    environments: EnvironmentResource[];
    machineRoles: string[];
    foundDeploymentTarget?: DeploymentTargetResource;
}
export interface TargetConfigurationResource extends BaseConfiguration {
    EnvironmentIds: string[];
    MachineRoles: string[];
    DefaultNamespace: string;
}
export function KubernetesAgentTargetConfigurationDialog(props: ConfigurationProps) {
    const helmChartMajorVersion = useKubernetesAgentAsWorker() ? 2 : 1;
    return <KubernetesAgentTargetConfigurationDialogInternal {...props} helmChartMajorVersion={helmChartMajorVersion}/>;
}
export type HelmChartMajorVersion = 1 | 2;
export type AgentTargetConfigDialogProps = ConfigurationProps & {
    helmChartMajorVersion: HelmChartMajorVersion;
};
export class KubernetesAgentTargetConfigurationDialogInternal extends ConfigurationDialog<TargetConfigurationState, TargetConfigurationResource, AgentTargetConfigDialogProps> {
    constructor(props: AgentTargetConfigDialogProps) {
        super(props, EndpointSelectionScope.DeploymentTarget, ConfigurationMessages.Name.DeploymentTarget);
    }
    onDeploymentTargetFound = (target: DeploymentTargetResource) => {
        this.setState({ foundDeploymentTarget: target });
    };
    onDeploymentTargetHealthStatusUpdated = (target: DeploymentTargetResource) => {
        this.setState({ foundDeploymentTarget: target });
    };
    override initialModel() {
        return {
            ...this.getInitialBaseModel(),
            EnvironmentIds: [],
            MachineRoles: [],
            DefaultNamespace: "",
        };
    }
    override initialiseState() {
        this.state = {
            ...this.initialBaseState,
            environments: [],
            machineRoles: [],
        };
    }
    override async loadMachineTypeSpecificData() {
        const environmentsPromise = repository.Environments.all();
        const machineRolesPromise = repository.MachineRoles.all();
        this.setState({
            environments: await environmentsPromise,
            machineRoles: await machineRolesPromise,
        });
    }
    override async validateMachineTypeSpecificData(model: TargetConfigurationResource, fieldErrors: TargetFieldErrors): Promise<void> {
        if (StringHelper.isNullOrWhiteSpace(model.Name)) {
            fieldErrors.Name = this.nameErrorMessages.Validation.NotProvided;
        }
        else {
            const existingMachine = await repository.Machines.list({ name: this.state.model.Name });
            if (existingMachine.TotalResults > 0) {
                fieldErrors.Name = this.nameErrorMessages.Validation.AlreadyExists;
            }
        }
        if (model.EnvironmentIds.length === 0) {
            fieldErrors.EnvironmentIds = ConfigurationMessages.EnvironmentIds.Validation.NotProvided;
        }
        if (model.MachineRoles.length === 0) {
            fieldErrors.MachineRoles = ConfigurationMessages.MachineRoles.Validation.NotProvided;
        }
    }
    override machineIsFound() {
        return !!this.state.foundDeploymentTarget;
    }
    override machineHealthStatus(): MachineModelHealthStatus | undefined {
        return this.state.foundDeploymentTarget?.HealthStatus;
    }
    override getLinkToMachine() {
        if (this.state.foundDeploymentTarget) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return links.deploymentTargetSettingsPage.generateUrl({ spaceId: repository.spaceId!, machineId: this.state.foundDeploymentTarget.Id });
        }
    }
    override getPages() {
        const showRegenerateTokenButton = this.state.hasTokenExpired && !this.state.foundDeploymentTarget;
        const pages: PagedOnboardingDialogPageData[] = [
            {
                name: "Add Kubernetes Agent",
                title: "Add New Kubernetes Agent",
                helpPanelContent: <TargetConfigurationHelpContent />,
                helpPanelImage: targetConfigurationHelpImage(),
                isPageModelValid: () => this.isModelValid(),
                onMovingToNextPage: async () => {
                    if (!this.state.requiresHaConfiguration) {
                        await this.refreshToken();
                    }
                    if (this.state.usePersistentVolumeStorage) {
                        this.props.onEstablishingConnection?.(this.state.model);
                    }
                    const selectedEnvName = this.state.environments
                        .filter((env) => this.state.model.EnvironmentIds.includes(env.Id))
                        .map((env) => env.Name)
                        .join(";");
                    this.props.dispatchAction?.("Submit Kubernetes Agent Form", {
                        projectId: this.props.projectId,
                        correlationId: this.props.correlationId,
                        sourcePage: this.props.sourcePage,
                        agentName: this.state.model.Name,
                        environments: selectedEnvName,
                        storageClass: this.state.model.StorageClassName,
                    });
                    this.props.onTargetCompleted?.(this.state.model);
                },
                isBusy: this.state.isBusy,
                render: () => (<TargetConfigurationPage model={this.state.model} onModelChange={(model) => this.setModelState(model)} environments={this.state.environments} machineRoles={this.state.machineRoles} canModifyServerUrls={this.state.canModifyServerUrls} showAdvanced={this.state.showAdvanced} toggleShowAdvanced={() => this.setState({ showAdvanced: !this.state.showAdvanced })} getFieldError={(name: string) => this.getFieldError(name)} usePersistentVolumeStorage={this.state.usePersistentVolumeStorage} onUsePersistentVolumeStorageChanged={(usePv) => this.setState({ usePersistentVolumeStorage: usePv })} requiresHaConfiguration={this.state.requiresHaConfiguration}/>),
            },
        ];
        if (this.state.requiresHaConfiguration) {
            pages.push(this.getHaConfigurationPage(<TargetConfigurationHelpContent />, targetConfigurationHelpImage()));
        }
        if (!this.state.usePersistentVolumeStorage) {
            pages.push(this.getNfsCsiDriverInstallationPage(<NfsDriverInstallationHelpContent machineType={EndpointSelectionScope.DeploymentTarget}/>, targetNfsDriverInstallationHelpImage()));
        }
        pages.push({
            name: "Install Kubernetes Agent",
            title: "Install Kubernetes Agent",
            helpPanelContent: <TentacleInstallationHelpContent machineType={EndpointSelectionScope.DeploymentTarget}/>,
            helpPanelImage: tentacleInstallationHelpImage(),
            render: () => (<InstallationPage<DeploymentTargetResource> model={this.state.model} onAgentFound={this.onDeploymentTargetFound} onAgentHealthStatusUpdated={this.onDeploymentTargetHealthStatusUpdated} hasTokenExpired={this.state.hasTokenExpired} accessTokenExpiry={this.state.accessTokenExpiry} generateHelmCommand={(selectedLanguage) => createTargetHelmCommand(selectedLanguage, this.state.model, this.state.environments, this.props.helmChartMajorVersion)} fetchAgent={async (name) => {
                    const targets = await repository.Machines.list({ name });
                    return targets.Items.find((t) => t.Name === name);
                }} machineType={EndpointSelectionScope.DeploymentTarget}/>),
            alwaysShowCloseButton: this.props.projectId ? true : undefined,
            onlyShowCloseButton: this.props.projectId ? this.machineHealthStatus() === MachineModelHealthStatus.Healthy : undefined,
            customPrimaryButton: showRegenerateTokenButton && <ActionButton label="Regenerate token" onClick={() => this.refreshToken()} type={ActionButtonType.Primary}/>,
        });
        return pages;
    }
}
