import cn from "classnames";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { clearLogLine } from "~/areas/tasks/components/Task/TaskLog/TaskLogUtil";
import styles from "~/components/TaskLogLines/style.module.less";
interface TaskLogGapRangeProps {
    start: number;
    end: number;
    message: string;
    isFetchDisabled: boolean;
    onFetchRange(start: number, end: number): void;
}
export const TaskLogGapRange = ({ start, end, message, isFetchDisabled, onFetchRange }: TaskLogGapRangeProps) => {
    const [isLoading, setIsLoading] = React.useState<boolean>();
    const location = useLocation();
    const history = useHistory();
    const showAdditionalClick = (e: React.MouseEvent) => {
        e.preventDefault();
        clearLogLine({ location, history });
        onFetchRange(start, end);
        setIsLoading(true);
    };
    if (isFetchDisabled) {
        return (<div className={cn(styles.showMore, styles.disabled)}>
                {message}{" "}
                {isLoading && (<span>
                        <em className="fa-solid fa-circle-notch fa-spin"></em>
                    </span>)}
            </div>);
    }
    return (<a href="" className={styles.showMore} onClick={showAdditionalClick}>
            {message}{" "}
            {isLoading && (<span>
                    <em className="fa-solid fa-circle-notch fa-spin"></em>
                </span>)}
        </a>);
};
