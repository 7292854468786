/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout, RadioButton } from "@octopusdeploy/design-system-components";
import type { AzureServiceFabricAppProperties } from "@octopusdeploy/legacy-action-properties";
import { AADCredentialType, ActionExecutionLocation, AzureServiceFabricSecurityMode, GetPrimaryPackageReference, InitialisePrimaryPackageReference, SetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import AzureServiceFabricAccountSelector from "~/components/Actions/azure/AzureServiceFabricAccountSelector";
import AzureServiceFabricSdkRequiredInfo from "~/components/Actions/azure/AzureServiceFabricSdkRequiredInfo";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import { UnstructuredFormSection } from "~/components/form";
import type { SummaryNode } from "~/components/form/Sections/ExpandableFormSection";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import Summary from "~/components/form/Sections/Summary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "~/primitiveComponents/form/Note/Note";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/BoundRadioButtonGroup";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/CommonSummaryHelper";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import { AzureBundledToolsForCustomScriptsEdit } from "./azureBundledTools";
interface AzureServiceFabricAppActionSummaryState {
    feedName: string | null;
}
class AzureServiceFabricAppActionSummary extends BaseComponent<ActionSummaryProps, AzureServiceFabricAppActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg ? (<div>
                {"Deploy Service Fabric Application"} <strong>{pkg.PackageId}</strong> {"from"} <DisplayFeedName pkg={pkg}/>
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        on behalf of targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>{" "}
                    </span>)}
            </div>) : (<Callout type={"warning"} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>);
    }
    static displayName = "AzureServiceFabricAppActionSummary";
}
enum UpgradeBehavior {
    None = "None",
    ForceUpgrade = "ForceUpgrade",
    VetoUpgrade = "VetoUpgrade"
}
enum OverwriteBehavior {
    Never = "Never",
    Always = "Always",
    SameAppTypeAndVersion = "SameAppTypeAndVersion"
}
interface AzureServiceFabricAppState {
    resetValues: {
        ClientCertVariable: string;
    };
}
type AzureServiceFabricAppActionEditProps = ActionEditProps<AzureServiceFabricAppProperties>;
type Props = AzureServiceFabricAppActionEditProps & ActionWithFeeds;
class AzureServiceFabricAppActionEditInternal extends BaseComponent<Props, AzureServiceFabricAppState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            resetValues: {
                ClientCertVariable: props.properties["Octopus.Action.ServiceFabric.ClientCertVariable"],
            },
        };
    }
    componentDidUpdate(prevProps: Props) {
        if (this.props.properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] !== prevProps.properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] ||
            this.props.properties["Octopus.Action.ServiceFabric.IsLegacyMode"] !== prevProps.properties["Octopus.Action.ServiceFabric.IsLegacyMode"]) {
            this.props.refreshRunOn && this.props.refreshRunOn();
        }
    }
    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds), true);
        await this.props.doBusyTask(async () => {
            const newProperties: Partial<AzureServiceFabricAppProperties> = {};
            const isLegacyActionType = !!this.props.properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"];
            if (isLegacyActionType) {
                if (!this.props.properties["Octopus.Action.ServiceFabric.SecurityMode"]) {
                    newProperties["Octopus.Action.ServiceFabric.SecurityMode"] = AzureServiceFabricSecurityMode.Unsecure;
                }
                if (!this.props.properties["Octopus.Action.ServiceFabric.AadCredentialType"]) {
                    newProperties["Octopus.Action.ServiceFabric.AadCredentialType"] = AADCredentialType.UserCredential;
                }
                newProperties["Octopus.Action.ServiceFabric.IsLegacyMode"] = "True";
            }
            if (!this.props.properties["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"]) {
                newProperties["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"] = UpgradeBehavior.None;
            }
            if (!this.props.properties["Octopus.Action.ServiceFabric.OverwriteBehavior"]) {
                newProperties["Octopus.Action.ServiceFabric.OverwriteBehavior"] = OverwriteBehavior.SameAppTypeAndVersion;
            }
            this.props.setProperties(newProperties, true);
        });
    }
    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }
        const properties = this.props.properties;
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const isLegacyActionType = this.isLegacyMode();
        return (<div>
                <AzureBundledToolsForCustomScriptsEdit {...this.props}/>

                <UnstructuredFormSection stretchContent={true}>
                    <AzureServiceFabricSdkRequiredInfo />
                </UnstructuredFormSection>

                <FormSectionHeading title="Package"/>

                <ExpandableFormSection errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId" isExpandedByDefault={this.props.expandedByDefault} title="Package" summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds)} help={<span>
                            This step is used to deploy the contents of a Service Fabric package. The package that you wish to deploy should contain your <em>ApplicationParameters</em> and <em>PublishProfiles</em> folders, as well as your application
                            package folder(s). Please see the <ExternalLink href="ServiceFabricPackaging">Service Fabric Packaging guide</ExternalLink> to learn about what your package should contain, and how to create them.
                        </span>}>
                    <DeferredPackageSelector packageId={pkg.PackageId} feedId={pkg.FeedId} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} refreshFeeds={this.loadFeeds} parameters={this.props.parameters} packageSelectionMode={pkg.Properties["SelectionMode"]} packageSelectionModeError={this.props.getFieldError("SelectionMode")} onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))} packageParameterName={pkg.Properties["PackageParameterName"]} packageParameterError={this.props.getFieldError("PackageParameterName")} onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}/>
                </ExpandableFormSection>

                {isLegacyActionType && (<div>
                        <FormSectionHeading title="Service Fabric Cluster"/>
                        <Callout type={"warning"} title={"Legacy mode"}>
                            <strong>
                                This step is referencing a Service Fabric cluster directly, instead of referencing a <ExternalLink href="AzureTargets">Service Fabric Cluster Target</ExternalLink> through Roles. Please read{" "}
                                <ExternalLink href="AzureTargets">our documentation</ExternalLink> to learn how to get started with Azure Targets.
                            </strong>
                        </Callout>
                        <AzureServiceFabricAccountSelector projectId={this.props.projectId!} gitRef={this.props.gitRef} localNames={this.props.localNames!} properties={this.props.properties} clientCertVariableResetValue={this.state.resetValues.ClientCertVariable} setProperties={this.props.setProperties} getFieldError={this.props.getFieldError} doBusyTask={this.props.doBusyTask} expandedByDefault={this.props.expandedByDefault}/>
                    </div>)}

                <FormSectionHeading title="Deployment"/>
                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.PublishProfileFile" isExpandedByDefault={this.props.expandedByDefault} title="Publish Profile File" summary={properties["Octopus.Action.ServiceFabric.PublishProfileFile"] ? Summary.summary(properties["Octopus.Action.ServiceFabric.PublishProfileFile"]) : Summary.placeholder("Publish profile file not provided")} help={"Select the path to your publish profile file."}>
                    <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.ServiceFabric.PublishProfileFile"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.PublishProfileFile"]: x })} error={this.props.getFieldError("Octopus.Action.ServiceFabric.PublishProfileFile")} placeholder="eg. PublishProfiles\\Cloud.xml" label="Publish profile file"/>
                    <Note>
                        Octopus will use the ApplicationParameters file referenced in this publish profile. This value is typically <code>PublishProfiles\Cloud.xml</code>
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.DeployOnly" isExpandedByDefault={this.props.expandedByDefault} title="Deploy Only" summary={properties["Octopus.Action.ServiceFabric.DeployOnly"] && properties["Octopus.Action.ServiceFabric.DeployOnly"] !== "False"
                ? Summary.summary(<span>
                                      The application will <strong>not</strong> but created or updated
                                  </span>)
                : Summary.default("The application will be created or updated")} help="If selected, the Service Fabric application will not be created or upgraded after registering the application type.">
                    <BoundStringCheckbox variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"False"} value={properties["Octopus.Action.ServiceFabric.DeployOnly"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.DeployOnly"]: x })} label="Deploy only"/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade" isExpandedByDefault={this.props.expandedByDefault} title="Unregister Unused Versions" summary={properties["Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade"] && properties["Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade"] !== "False"
                ? Summary.summary(<span>
                                      Unused versions <strong>will</strong> be unregistered
                                  </span>)
                : Summary.default("Unused versions will not be unregistered")} help="Select to unregister any unused application versions that exist after an upgrade is finished.">
                    <BoundStringCheckbox variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"False"} value={properties["Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.UnregisterUnusedApplicationVersionsAfterUpgrade"]: x })} label="Unregister"/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.OverrideUpgradeBehavior" isExpandedByDefault={this.props.expandedByDefault} title="Override Upgrade Behavior" summary={this.upgradeBehaviorSummary()} help="Indicates the behavior used to override the upgrade settings specified by the publish profile.">
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={UpgradeBehavior.None} value={properties["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"]: x })} title="Upgrade behavior">
                        <RadioButton value={UpgradeBehavior.None} label="None" isDefault/>
                        <RadioButton value={UpgradeBehavior.ForceUpgrade} label="Force upgrade"/>
                        <RadioButton value={UpgradeBehavior.VetoUpgrade} label="Veto upgrade"/>
                    </BoundRadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.OverwriteBehavior" isExpandedByDefault={this.props.expandedByDefault} title="Overwrite Behavior" summary={this.overwriteBehaviorSummary()} help="Overwrite behavior if an application exists in the cluster with the same name. This setting is not applicable when upgrading an application.">
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={OverwriteBehavior.SameAppTypeAndVersion} value={properties["Octopus.Action.ServiceFabric.OverwriteBehavior"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.OverwriteBehavior"]: x })} title="Overwrite behavior">
                        <RadioButton value={OverwriteBehavior.Never} label="Never"/>
                        <RadioButton value={OverwriteBehavior.Always} label="Always"/>
                        <RadioButton value={OverwriteBehavior.SameAppTypeAndVersion} label="Same app type and version" isDefault/>
                    </BoundRadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.SkipPackageValidation" isExpandedByDefault={this.props.expandedByDefault} title="Skip Package Validation" summary={properties["Octopus.Action.ServiceFabric.SkipPackageValidation"] && properties["Octopus.Action.ServiceFabric.SkipPackageValidation"] !== "False"
                ? Summary.summary(<span>
                                      Tell Service Fabric <strong>not</strong> to perform package validation
                                  </span>)
                : Summary.default("Service Fabric will perform package validation")} help="Select to tell Service Fabric not to validate the package before deployment.">
                    <BoundStringCheckbox variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"False"} value={properties["Octopus.Action.ServiceFabric.SkipPackageValidation"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.SkipPackageValidation"]: x })} label="Skip validation"/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.CopyPackageTimeoutSec" isExpandedByDefault={this.props.expandedByDefault} title="Copy Package Timeout" summary={properties["Octopus.Action.ServiceFabric.CopyPackageTimeoutSec"] ? Summary.summary(<span>{properties["Octopus.Action.ServiceFabric.CopyPackageTimeoutSec"]} seconds</span>) : Summary.default("Unchanged")} help={"Enter a value in seconds to override the timeout for copying an application package to the image store."}>
                    <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.ServiceFabric.CopyPackageTimeoutSec"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.CopyPackageTimeoutSec"]: x })} error={this.props.getFieldError("Octopus.Action.ServiceFabric.CopyPackageTimeoutSec")} label="Copy package timeout (seconds)"/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec" isExpandedByDefault={this.props.expandedByDefault} title="Register Application Type Timeout" summary={properties["Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec"] ? Summary.summary(<span>{properties["Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec"]} seconds</span>) : Summary.default("Unchanged")} help={"Enter a value in seconds to override the timeout for registering application type."}>
                    <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec"]: x })} error={this.props.getFieldError("Octopus.Action.ServiceFabric.RegisterApplicationTypeTimeoutSec")} label="Register Application Type Timeout (seconds)"/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.ServiceFabric.IsLegacyMode" isExpandedByDefault={this.props.expandedByDefault} title="Enable Legacy Mode" summary={properties["Octopus.Action.ServiceFabric.IsLegacyMode"] === "True"
                ? Summary.summary(<span>
                                      <strong>Enabled</strong>: Connection-related properties are configured on this step
                                  </span>)
                : Summary.default(<span>Not enabled: Connection-related properties are configured on the Deployment Target</span>)} help={"Select legacy mode if you wish to configure connection-related properties on the step and not through Azure Targets."}>
                    <BoundStringCheckbox variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={""} value={properties["Octopus.Action.ServiceFabric.IsLegacyMode"]} onChange={(x) => {
                if (x === "True") {
                    this.props.setProperties({
                        ["Octopus.Action.ServiceFabric.IsLegacyMode"]: "True",
                        ["Octopus.Action.ServiceFabric.ConnectionEndpoint"]: "", // Default
                        ["Octopus.Action.ServiceFabric.SecurityMode"]: AzureServiceFabricSecurityMode.Unsecure, // Default
                        ["Octopus.Action.ServiceFabric.AadCredentialType"]: AADCredentialType.UserCredential, // Default
                    });
                }
                else {
                    this.clearLegacyModeProps();
                }
            }} label="Enable Legacy Mode"/>
                    <Callout type={"warning"} title={"Not recommended"}>
                        Toggling this <strong>on</strong> will allow connection-related properties on the step <strong>(not recommended)</strong>.<br />
                        Toggling this <strong>off</strong> will clear the connection-related properties on the step and allow these to be configured from your Deployment Targets.
                    </Callout>
                </ExpandableFormSection>
            </div>);
    }
    private upgradeBehaviorSummary(): SummaryNode {
        const behavior = this.props.properties["Octopus.Action.ServiceFabric.OverrideUpgradeBehavior"];
        if (behavior === UpgradeBehavior.None) {
            return Summary.default("None");
        }
        if (behavior === UpgradeBehavior.ForceUpgrade) {
            return Summary.summary("Force upgrade");
        }
        if (behavior === UpgradeBehavior.VetoUpgrade) {
            return Summary.summary("Veto upgrade");
        }
        return Summary.summary("Bound to a project variable");
    }
    private overwriteBehaviorSummary(): SummaryNode {
        const behavior = this.props.properties["Octopus.Action.ServiceFabric.OverwriteBehavior"];
        if (behavior === OverwriteBehavior.Never) {
            return Summary.summary("Never");
        }
        if (behavior === OverwriteBehavior.Always) {
            return Summary.summary("Always");
        }
        if (behavior === OverwriteBehavior.SameAppTypeAndVersion) {
            return Summary.default("Same app type and version");
        }
        return Summary.summary("Bound to a project variable");
    }
    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    private isLegacyMode(): boolean {
        const properties = this.props.properties;
        const isLegacyActionType = !!properties && !!(properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] || properties["Octopus.Action.ServiceFabric.IsLegacyMode"] === "True");
        return isLegacyActionType;
    }
    private clearLegacyModeProps = () => {
        this.props.setProperties({
            ["Octopus.Action.ServiceFabric.IsLegacyMode"]: null!,
            ["Octopus.Action.ServiceFabric.ConnectionEndpoint"]: null!,
            ["Octopus.Action.ServiceFabric.SecurityMode"]: null!,
            ["Octopus.Action.ServiceFabric.ServerCertThumbprint"]: null!,
            ["Octopus.Action.ServiceFabric.ClientCertVariable"]: null!,
            ["Octopus.Action.ServiceFabric.AadCredentialType"]: null!,
            ["Octopus.Action.ServiceFabric.AadClientCredentialSecret"]: null!,
            ["Octopus.Action.ServiceFabric.AadUserCredentialUsername"]: null!,
            ["Octopus.Action.ServiceFabric.AadUserCredentialPassword"]: null!,
        }, false);
    };
    static displayName = "AzureServiceFabricAppActionEditInternal";
}
function AzureServiceFabricAppActionEdit(props: React.PropsWithChildren<AzureServiceFabricAppActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    return <AzureServiceFabricAppActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds}/>;
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.AzureServiceFabricApp",
    summary: (properties, targetRolesAsCSV, packages) => <AzureServiceFabricAppActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages}/>,
    editSections: { default: AzureServiceFabricAppActionEdit },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => {
        // Azure steps (pre 2018.5) allowed you to select connection endpoint directly on the step, making target selection optional.
        const isLegacyActionType = !!(action ? action.Properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"] || action.Properties["Octopus.Action.ServiceFabric.IsLegacyMode"] === "True" : null);
        return isLegacyActionType ? TargetRoles.Optional : TargetRoles.Required;
    },
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables", "Octopus.Features.CustomScripts", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles"],
    },
    getInitialProperties: () => {
        return {
            OctopusUseBundledTooling: "False",
        };
    },
});
