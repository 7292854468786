import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client";
import { parseGitHubActionsSubject } from "./parseGitHubActionsSubject";
export type IssuerType = "GitHubActions" | "Other";
export const GitHubActionsIssuer = "https://token.actions.githubusercontent.com";
export function getIssuerType(oidcIdentity: ServiceAccountOidcIdentityResource): IssuerType | undefined {
    if (oidcIdentity.Id === "")
        return undefined;
    if (oidcIdentity.Issuer === GitHubActionsIssuer || parseGitHubActionsSubject(oidcIdentity.Subject)) {
        return "GitHubActions";
    }
    return "Other";
}
