/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { LatestReleaseResource, DashboardResource } from "@octopusdeploy/octopus-server-client";
class CurrentVersionMap {
    private environmentVersionMap: Map<string, string>;
    private tenantEnvironmentVersionMap: Map<string, Map<string, string>>;
    constructor(environmentVersionMap: Map<string, string>, tenantEnvironmentVersionMap: Map<string, Map<string, string>>) {
        this.environmentVersionMap = environmentVersionMap;
        this.tenantEnvironmentVersionMap = tenantEnvironmentVersionMap;
    }
    static CreateFromDashboard(dashboard: DashboardResource): CurrentVersionMap {
        const environmentVersionMap = new Map<string, string>();
        const tenantEnvironmentVersionMap = new Map<string, Map<string, string>>();
        for (const item of dashboard.Items) {
            if (item.TenantId) {
                if (!tenantEnvironmentVersionMap.has(item.TenantId)) {
                    tenantEnvironmentVersionMap.set(item.TenantId, new Map<string, string>());
                }
                tenantEnvironmentVersionMap.get(item.TenantId)!.set(item.EnvironmentId, item.ReleaseVersion);
            }
            else {
                environmentVersionMap.set(item.EnvironmentId, item.ReleaseVersion);
            }
        }
        return new CurrentVersionMap(environmentVersionMap, tenantEnvironmentVersionMap);
    }
    static CreateFromLatestReleases(latestReleases: LatestReleaseResource[]): CurrentVersionMap {
        const environmentVersionMap = new Map<string, string>();
        const tenantEnvironmentVersionMap = new Map<string, Map<string, string>>();
        for (const item of latestReleases) {
            if (item.TenantId) {
                if (!tenantEnvironmentVersionMap.has(item.TenantId)) {
                    tenantEnvironmentVersionMap.set(item.TenantId, new Map<string, string>());
                }
                tenantEnvironmentVersionMap.get(item.TenantId)!.set(item.EnvironmentId, item.ReleaseVersion);
            }
            else {
                environmentVersionMap.set(item.EnvironmentId, item.ReleaseVersion);
            }
        }
        return new CurrentVersionMap(environmentVersionMap, tenantEnvironmentVersionMap);
    }
    getCurrentRelease(environmentId: string, tenantId?: string) {
        if (tenantId) {
            return this.tenantEnvironmentVersionMap.has(tenantId) ? this.tenantEnvironmentVersionMap.get(tenantId)!.get(environmentId) : null;
        }
        return this.environmentVersionMap.get(environmentId);
    }
}
export default CurrentVersionMap;
