import React from "react";
import DisplayDiff from "~/components/DisplayDiff";
interface DirtyStateDetailProps<Model> {
    model: Model;
    cleanModel: Model;
}
export function DirtyStateDetail<Model>({ cleanModel, model }: DirtyStateDetailProps<Model>) {
    const oldValue = JSON.stringify(cleanModel, null, 4);
    const newValue = JSON.stringify(model, null, 4);
    return <DisplayDiff oldValue={oldValue} newValue={newValue} splitView={true}/>;
}
