/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import { type AccountResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { DrawerWithForm } from "~/primitiveComponents/dataDisplay/DrawerWithForm/DrawerWithForm";
import type { AccountEditDrawerProps, AccountEditModel, AccountEditState } from "./AccountEditBase";
import AccountEditBase from "./AccountEditBase";
interface AccountEditDrawerState<TAccountResource extends AccountResource, TModel extends AccountEditModel> extends AccountEditState<TAccountResource, TModel> {
    calloutDismissed?: boolean;
}
abstract class AccountEditBaseDrawer<TAccountResource extends AccountResource, TModel extends AccountEditModel> extends AccountEditBase<TAccountResource, AccountEditDrawerState<TAccountResource, TModel>, TModel> {
    private localStorageKeyForDismissedCallout = "Octopus.Callout.Drawer.Account.Dismissed";
    constructor(props: AccountEditDrawerProps<TAccountResource>) {
        super(props);
        this.state = {
            ...this.initialState(),
            calloutDismissed: localStorage.getItem(this.localStorageKeyForDismissedCallout) === "true",
        };
    }
    onSuccess(account: AccountResource) {
        (this.props as AccountEditDrawerProps<TAccountResource>).onSuccess(account);
        this.setState(this.initialState());
    }
    onTestDone(success: boolean) {
        this.setState({
            showTestDialog: false,
        });
        if (success && this.onSuccess && this.state.accountData?.account) {
            this.onSuccess(this.state.accountData.account);
        }
    }
    onClose() {
        (this.props as AccountEditDrawerProps<TAccountResource>).setIsOpen(false);
    }
    onCloseCallout() {
        localStorage.setItem(this.localStorageKeyForDismissedCallout, true.toString());
        this.setState({ calloutDismissed: true });
    }
    render() {
        const accountData = this.state.accountData;
        const title = accountData?.account.Name ?? "Create Account";
        const customActions = [
            <ActionButton label="Cancel" onClick={() => this.onClose()} type={ActionButtonType.Secondary}/>,
            ...this.getPageActions().map((action) => {
                if (action.type !== "button")
                    return null;
                return <ActionButton type={action.buttonType === "secondary" ? ActionButtonType.Secondary : ActionButtonType.Primary} label={action.label} onClick={action.onClick}></ActionButton>;
            }),
        ];
        customActions.push(<ActionButton onClick={() => this.saveAccount(false)} label="Save" type={ActionButtonType.Primary}/>);
        // TODO: display these
        const overflowActions = accountData === undefined ? [] : this.getOverFlowActions(accountData.account);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const spaceId = repository.spaceId!;
        return (<DrawerWithForm busy={this.state.busy} errors={this.errors} title={title} onSubmit={() => this.saveAccount(false)} onClose={() => this.onClose()} isOpen={(this.props as AccountEditDrawerProps<TAccountResource>).isOpen} variant="customActions" expandableContainerKey={"AccountEdit"} expandAllOnMount={true} showExpandAll={true} customActions={[...customActions]}>
                {!this.state.calloutDismissed && !accountData?.account.Name ? (<Callout type={"information"} title="Finalise account" hideTitle={false} canClose={true} onClose={() => this.onCloseCallout()}>
                        <div>
                            After you create this account, you can find it in{" "}
                            <InternalLink to={links.infrastructureAccountsPage.generateUrl({ spaceId })} openInSelf={false}>
                                Accounts
                            </InternalLink>
                        </div>
                    </Callout>) : (<></>)}
                {this.innerRender()}
            </DrawerWithForm>);
    }
}
export default AccountEditBaseDrawer;
