import moment from "moment";
import * as React from "react";
import type { SFC } from "react";
interface DurationLabelProps {
    duration: string;
}
const DurationLabel: SFC<DurationLabelProps> = (props) => {
    const duration = moment.duration(props.duration);
    return <span title={props.duration}>{duration.humanize()}</span>;
};
export default DurationLabel;
