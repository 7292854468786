import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface DataTableHeaderProps {
    className?: string;
}
export class DataTableHeader extends React.Component<DataTableHeaderProps> {
    render() {
        return <thead className={cn(this.props.className, styles.dataTableHeader)}>{this.props.children}</thead>;
    }
    static displayName = "DataTableHeader";
}
