import { isContainerImageRegistry, isHelmChartFeed } from "@octopusdeploy/octopus-server-client";
import type { FeedType } from "@octopusdeploy/octopus-server-client";
import React, { useMemo } from "react";
import type { RelatedTriggersDependencies } from "./CalloutReferencedPackageTriggerConnections";
import { AvailableTriggerCallout, MultipleRelatedTriggersCallout, SingleRelatedTriggerCallout } from "./RelatedTriggerCallouts";
import { getRelatedTriggersForPrimaryPackage } from "./triggerPackageRelations";
interface CalloutPrimaryPackageTriggerConnectionsProps {
    dependencies: RelatedTriggersDependencies;
    feedType?: FeedType;
    modelDirty: boolean;
}
export function CalloutPrimaryPackageTriggerConnections({ dependencies, feedType, modelDirty }: CalloutPrimaryPackageTriggerConnectionsProps) {
    const { spaceId, projectSlug } = dependencies;
    const relatedTriggers = useMemo(() => getRelatedTriggersForPrimaryPackage(dependencies), [dependencies]);
    if (relatedTriggers.length === 0) {
        const isContainerRegistryOrHelmFeed = feedType && (isContainerImageRegistry(feedType) || isHelmChartFeed(feedType));
        if (isContainerRegistryOrHelmFeed && !modelDirty) {
            return <AvailableTriggerCallout spaceId={spaceId} projectSlug={projectSlug}/>;
        }
        else {
            return <></>;
        }
    }
    const uniqueTriggerCount = [...new Set(relatedTriggers.map((trigger) => trigger.Id))].length;
    if (uniqueTriggerCount === 1) {
        const triggerId = relatedTriggers[0].Id;
        return <SingleRelatedTriggerCallout triggerId={triggerId} text={"This package is connected to an external feed trigger."} spaceId={spaceId} projectSlug={projectSlug}/>;
    }
    else {
        return <MultipleRelatedTriggersCallout text={"This package is connected to external feed triggers."} spaceId={spaceId} projectSlug={projectSlug}/>;
    }
}
