import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface DataTableToolsRowProps {
    className?: string;
}
export class DataTableToolsRow extends React.Component<DataTableToolsRowProps> {
    render() {
        return <tr className={cn(this.props.className, styles.dataTableToolsRow)}>{this.props.children}</tr>;
    }
    static displayName = "DataTableToolsRow";
}
