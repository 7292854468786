export default class IdHelper {
    public static newId(): string {
        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
        function s4() {
            return Math.floor((1 + Math.random()) * 65536)
                .toString(16)
                .substring(1);
        }
    }
}
