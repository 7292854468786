import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import Card from "~/components/Card";
import Logo from "~/components/Logo";
import InternalLink from "~/components/Navigation/InternalLink";
interface InsightsReportCardProps {
    report: InsightsReportResource;
}
export function InsightsReportCard({ report }: InsightsReportCardProps) {
    return <Card logo={<Logo url={report.Links.Logo}/>} header={report.Name} link={<InternalLink to={links.insightsReportOverviewPage.generateUrl({ spaceId: report.SpaceId, reportId: report.Id })}/>} content={report.Description}/>;
}
