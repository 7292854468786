import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import image from "../../Images/Channels.svg";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonProjectChannelOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="Channels">Channels</ExternalLink> allow you to dynamically change the deployment logic and lifecycle of a project based on the release being deployed.
                </p>
                <ImageWithPlaceholder src={image} alt={"Channels"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
