import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren } from "react";
import React from "react";
import { FrameWrapper } from "~/components/DialogLayout/Custom/index";
function CustomTransparentDialogFrame({ children }: PropsWithChildren<{}>) {
    return (<FrameWrapper>
            <div className={styles.transparentFrame}>{children}</div>
        </FrameWrapper>);
}
export default CustomTransparentDialogFrame;
const styles = {
    transparentFrame: css({
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: "1000px",
        maxHeight: "100%",
        background: "transparent",
        borderRadius: space[4],
    }),
};
