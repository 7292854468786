/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/init-declarations */
import { css } from "@emotion/css";
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { DayOfWeek, DaysPerMonthTriggerScheduleResource, ServerTimezoneResource } from "@octopusdeploy/octopus-server-client";
import { MonthlyScheduleType } from "@octopusdeploy/octopus-server-client";
import type { Moment } from "moment";
import moment from "moment";
import * as React from "react";
import ComponentRow from "~/components/ComponentRow/ComponentRow";
import { ExpandableFormSection, Summary, Select } from "~/components/form";
import type { Item } from "~/primitiveComponents/form/Select/Select";
import ScheduleStart from "./ScheduleStart";
import ScheduleTimezone from "./ScheduleTimezone";
interface DaysPerMonthScheduledTriggerEditorProps {
    schedule: DaysPerMonthTriggerScheduleResource;
    timezones: ServerTimezoneResource[];
    onScheduleChange(schedule: DaysPerMonthTriggerScheduleResource): void;
}
interface DaysPerMonthScheduleTriggerEditorState {
    scheduleStartTime: Moment;
    scheduleTimezone: string;
    monthlyScheduleType: MonthlyScheduleType;
    scheduleDateOfMonth?: string;
    scheduleDayOfWeekOrdinal?: string;
    scheduleMonthSpecifiedDayOfWeek: DayOfWeek;
}
export class DaysPerMonthScheduledTriggerEditor extends React.Component<DaysPerMonthScheduledTriggerEditorProps, {}> {
    render() {
        const schedule: DaysPerMonthScheduleTriggerEditorState = {
            scheduleStartTime: moment(this.props.schedule.StartTime).utc(),
            scheduleTimezone: this.props.schedule.Timezone,
            monthlyScheduleType: this.props.schedule.MonthlyScheduleType || MonthlyScheduleType.DateOfMonth,
            scheduleDateOfMonth: this.props.schedule.DateOfMonth ? this.props.schedule.DateOfMonth.toString() : undefined,
            scheduleDayOfWeekOrdinal: this.props.schedule.DayNumberOfMonth ? this.props.schedule.DayNumberOfMonth.toString() : undefined,
            scheduleMonthSpecifiedDayOfWeek: this.props.schedule.DayOfWeek,
        };
        return (<div>
                <ExpandableFormSection errorKey="ScheduleDaysPerMonth" title="On" summary={this.getDaysPerMonthSummary(schedule)} help="Select when in the month the trigger should execute">
                    <RadioButtonGroup value={schedule.monthlyScheduleType} onChange={this.onMonthlyScheduleTypeChange}>
                        {[
                <RadioButton value={MonthlyScheduleType.DateOfMonth} label="Specific date of every month" key={MonthlyScheduleType.DateOfMonth}/>,
                <RadioButton value={MonthlyScheduleType.DayOfMonth} label="Specific day of week of every month" key={MonthlyScheduleType.DayOfMonth}/>,
            ]}
                    </RadioButtonGroup>
                    {schedule.monthlyScheduleType === MonthlyScheduleType.DateOfMonth && (<div>
                            <ComponentRow>
                                <span className={styles.componentRowSpan}>The</span>
                                <Select items={this.getDateOfMonthOptions()} value={schedule.scheduleDateOfMonth} onChange={this.onDateOfMonthChange} label="Day of month" sortItems={false}/>
                                <span className={styles.componentRowSpan}>day of every month</span>
                            </ComponentRow>
                        </div>)}
                    {schedule.monthlyScheduleType === MonthlyScheduleType.DayOfMonth && (<div>
                            <ComponentRow>
                                <span className={styles.componentRowSpan}>The</span>
                                <Select items={this.getDayOfWeekOrdinalOptions()} value={schedule.scheduleDayOfWeekOrdinal} onChange={this.onDayOfWeekOrdinalChange} label="Day of week ordinal" sortItems={false}/>
                                <Select items={this.getMonthSpecifiedDaysOfWeek()} value={schedule.scheduleMonthSpecifiedDayOfWeek} onChange={this.onMonthSpecifiedDayOfWeekChange} label="Day of week" sortItems={false}/>
                                <span className={styles.componentRowSpan}>of every month</span>
                            </ComponentRow>
                        </div>)}
                </ExpandableFormSection>
                <ScheduleTimezone timezones={this.props.timezones} timezone={schedule.scheduleTimezone} onTimezoneChanged={this.onScheduleTimezoneChange}/>
                <ScheduleStart startTime={schedule.scheduleStartTime} onStartTimeChanged={this.onScheduleStartChange}/>
            </div>);
    }
    private getDaysPerMonthSummary(schedule: DaysPerMonthScheduleTriggerEditorState) {
        if (schedule.monthlyScheduleType === MonthlyScheduleType.DateOfMonth && schedule.scheduleDateOfMonth) {
            const dateOfMonth = this.getDateOfMonthOptions().find((opt) => {
                return opt.value === schedule.scheduleDateOfMonth;
            });
            return Summary.summary(<span>
                    The <strong>{dateOfMonth!.text}</strong> day of every month
                </span>);
        }
        if (schedule.monthlyScheduleType === MonthlyScheduleType.DayOfMonth && schedule.scheduleDayOfWeekOrdinal && schedule.scheduleMonthSpecifiedDayOfWeek) {
            const dayOfWeekOrdinal = this.getDayOfWeekOrdinalOptions().find((dowo) => {
                return dowo.value === schedule.scheduleDayOfWeekOrdinal;
            });
            const dayOfWeek = this.getMonthSpecifiedDaysOfWeek().find((dow) => {
                return dow.value === schedule.scheduleMonthSpecifiedDayOfWeek;
            });
            return Summary.summary(<span>
                    The{" "}
                    <strong>
                        {dayOfWeekOrdinal!.text} {dayOfWeek!.text}
                    </strong>{" "}
                    of every month
                </span>);
        }
        return Summary.placeholder("Select what day of the month the schedule should execute.");
    }
    private getDateOfMonthOptions() {
        const items: Item[] = [];
        let text: string;
        for (let value = 1; value <= 31; value++) {
            switch (value) {
                case 1:
                case 21:
                case 31:
                    text = `${value}st`;
                    break;
                case 2:
                case 22:
                    text = `${value}nd`;
                    break;
                case 3:
                case 23:
                    text = `${value}rd`;
                    break;
                default:
                    text = `${value}th`;
                    break;
            }
            items.push({ value: value.toString(), text });
        }
        items.push({ value: "L", text: "Last" });
        return items;
    }
    private getDayOfWeekOrdinalOptions() {
        return [
            { value: "1", text: "First" },
            { value: "2", text: "Second" },
            { value: "3", text: "Third" },
            { value: "4", text: "Fourth" },
            { value: "L", text: "Last" },
        ];
    }
    private getMonthSpecifiedDaysOfWeek() {
        return [
            { value: "Monday", text: "Monday" },
            { value: "Tuesday", text: "Tuesday" },
            { value: "Wednesday", text: "Wednesday" },
            { value: "Thursday", text: "Thursday" },
            { value: "Friday", text: "Friday" },
            { value: "Saturday", text: "Saturday" },
            { value: "Sunday", text: "Sunday" },
        ];
    }
    private onScheduleStartChange = (startTime: Moment) => {
        this.props.onScheduleChange({
            ...this.props.schedule,
            StartTime: startTime.toDate(),
        });
    };
    private onScheduleTimezoneChange = (timezone: string) => {
        this.props.onScheduleChange({
            ...this.props.schedule,
            Timezone: timezone,
        });
    };
    private onMonthlyScheduleTypeChange = (typeString: string) => {
        const type = typeString as MonthlyScheduleType;
        this.props.onScheduleChange({
            ...this.props.schedule,
            MonthlyScheduleType: type,
        });
    };
    private onDateOfMonthChange = (dateOfMonth: string | undefined) => {
        this.props.onScheduleChange({
            ...this.props.schedule,
            DateOfMonth: dateOfMonth!,
        });
    };
    private onDayOfWeekOrdinalChange = (dayOfWeekOrdinal: string | undefined) => {
        this.props.onScheduleChange({
            ...this.props.schedule,
            DayNumberOfMonth: this.props.schedule.MonthlyScheduleType === MonthlyScheduleType.DayOfMonth ? dayOfWeekOrdinal : null!,
        });
    };
    private onMonthSpecifiedDayOfWeekChange = (monthSpecifiedDayOfWeekString: string | undefined) => {
        const monthSpecifiedDayOfWeek = monthSpecifiedDayOfWeekString as DayOfWeek;
        this.props.onScheduleChange({
            ...this.props.schedule,
            DayOfWeek: this.props.schedule.MonthlyScheduleType === MonthlyScheduleType.DayOfMonth ? monthSpecifiedDayOfWeek : null!,
        });
    };
    static displayName = "DaysPerMonthScheduledTriggerEditor";
}
const styles = {
    componentRowSpan: css({
        whiteSpace: "nowrap",
        marginBottom: "1.5rem",
    }),
};
