import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { useOptionalOctopusSessionUser } from "@octopusdeploy/session";
import { useCallback } from "react";
import { createUIFeatureFlagToggledEvent } from "~/hooks/analytics/useUIFeatureFlagDispatch";
import useLocalStorage from "~/hooks/useLocalStorage";
export type FeatureFlagScope = "machine" | "user";
export interface UiFeatureFlagOptions {
    isInitiallyEnabled?: boolean;
    scope?: FeatureFlagScope;
}
/**
 * Stores the value of a feature flag in local storage.
 * Each user will have a different value for the feature flag. In other words, the user id forms part of the local storage key.
 * @param featureFlagKey - The key for the feature flag, used as part of the local storage key
 * @param options - Additional options for configuring the hook.
 * @param options.isInitiallyEnabled - Whether the feature flag should be initially enabled, before the user has configured the value. When there is nothing in local storage, this value will be used.
 * @param options.scope - Whether the feature flag is user based or machine based
 */
export function useUIOnlyFeatureFlag(featureFlagKey: string, options: UiFeatureFlagOptions = {}): [
    isEnabled: boolean,
    setIsEnabled: (isEnabled: boolean) => void
] {
    const { isInitiallyEnabled = false, scope = "user" } = options;
    const localStorageKey = useFeatureFlagStorageKey(featureFlagKey, scope);
    const [localStorageValue, setLocalStorageValue] = useLocalStorage(localStorageKey, { value: isInitiallyEnabled });
    const trackEvent = useTrackEvent();
    const setValue = useCallback((isEnabled: boolean) => {
        setLocalStorageValue({ value: isEnabled });
        trackEvent(createUIFeatureFlagToggledEvent(isEnabled, featureFlagKey));
    }, [featureFlagKey, setLocalStorageValue, trackEvent]);
    return [localStorageValue.value, setValue];
}
function useFeatureFlagStorageKey(featureFlagKey: string, scope: FeatureFlagScope) {
    const currentUser = useOptionalOctopusSessionUser();
    return scope === "machine" || !currentUser ? `Octopus/${featureFlagKey}` : `${currentUser.Id}/${featureFlagKey}`;
}
