/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { NavLink } from "~/components/NavigationSidebarLayout";
import { Navigation } from "~/components/NavigationSidebarLayout";
import { PortalPageHeaderPrimary } from "~/components/PageHeaderPrimary";
import type { PaperLayoutProps } from "~/components/PaperLayout";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
interface InfrastructureLayoutProps {
    enablePageLayoutVNext?: boolean;
}
export function InfrastructureLayout({ enablePageLayoutVNext, children }: React.PropsWithChildren<InfrastructureLayoutProps>) {
    const dynamicEnvironmentsEnabled = isFeatureToggleEnabled("DynamicEnvironmentsFeatureToggle");
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    if (isVerticalNavigationEnabled) {
        return <PortalPageLayout content={children} enablePageLayoutVNext={enablePageLayoutVNext}/>;
    }
    return (<main>
            <PortalPageLayout navItems={getVNextNavItems(dynamicEnvironmentsEnabled)} content={children} header={<PortalPageHeaderPrimary title={"Infrastructure"}/>}/>
        </main>);
}
function getVNextNavItems(dynamicEnvironmentsEnabled: boolean): NavLink[] {
    const navLinks: Array<NavLink | null> = [];
    navLinks.push(...overviewAndDeploymentTargetLinks());
    if (dynamicEnvironmentsEnabled) {
        navLinks.push(createStaticEnvironmentsNavItem("Environments"));
        navLinks.push(createDynamicEnvironmentsNavItem("Dynamic Environments"));
    }
    else {
        navLinks.push(createStaticEnvironmentsNavItem("Environments"));
    }
    navLinks.push(...extraLinks());
    return compact(navLinks);
}
function overviewAndDeploymentTargetLinks(): (NavLink | null)[] {
    return [
        Navigation.navItem("Overview", links.infrastructureOverviewPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true }),
        Navigation.navItem("Deployment Targets", links.deploymentTargetsPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.MachineView], wildcard: true }),
    ];
}
function extraLinks(): (NavLink | null)[] {
    return [
        Navigation.navItem("Workers", links.workerMachinesPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.WorkerView], wildcard: true }),
        Navigation.navItem("Worker Pools", links.workerPoolsPage.generateUrl({ spaceId: repository.spaceId! }), undefined),
        Navigation.navItem("Machine Policies", links.machinePoliciesPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: Permission.MachinePolicyView, wildcard: true }),
        Navigation.navItem("Machine Proxies", links.proxiesPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: Permission.ProxyView, wildcard: true }),
        Navigation.navItem("Accounts", links.infrastructureAccountsPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: Permission.AccountView, wildcard: true }),
    ];
}
function createStaticEnvironmentsNavItem(label: string | JSX.Element) {
    return Navigation.navItem(label, links.infrastructureEnvironmentsPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true });
}
function createDynamicEnvironmentsNavItem(label: string | JSX.Element) {
    return Navigation.navItem(label, links.dynamicEnvironmentsPage.generateUrl({ spaceId: repository.spaceId! }), undefined, { permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true });
}
export const InfrastructureLayoutBusy: React.FC<PaperLayoutProps> = (props: PaperLayoutProps) => {
    return (<InfrastructureLayout>
            <PaperLayoutVNext {...props}/>
        </InfrastructureLayout>);
};
InfrastructureLayoutBusy.displayName = "InfrastructureLayoutBusy"
