import type { ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
export type Result = ActionTemplateSearchModel & {
    IsMatch: boolean;
    Weighting: number;
};
function matchesCategory(result: Result, token: string): boolean {
    return result.Categories && result.Categories.length > 0 && result.Categories.filter((x) => x.toLowerCase().includes(token)).length > 0;
}
function matchesFilter(template: ActionTemplateSearchModel, filter: string): Result {
    const filterStr = filter?.trim().replace(/  +/g, " "); // trim whitespaces and replace multiple whitespaces with a single one.
    if (!filterStr) {
        return { ...template, IsMatch: true, Weighting: 0 };
    }
    const result = { ...template, IsMatch: false, Weighting: 0 };
    let weighting = 0;
    return filterStr
        .split(" ")
        .filter((v, i, self) => i == self.indexOf(v)) //remove duplicates
        .reduce((result, token) => {
        return result.SearchableContent.includes(token) || matchesCategory(result, token) ? { ...result, IsMatch: true, Weighting: ++weighting } : result;
    }, result);
}
const toComparisonString = (input: string | null): string => (input ? input.toLowerCase().replace(/ /g, "") : "");
type ActionTemplateSearchModel = ActionTemplateSearchResource & {
    SearchableContent: string;
};
function convertActionTemplateSearchResourceToModel(resource: ActionTemplateSearchResource): ActionTemplateSearchModel {
    return {
        ...resource,
        SearchableContent: toComparisonString(resource.Name) + toComparisonString(resource.Description) + toComparisonString(resource.Keywords),
    };
}
export default function filterActionTemplates(templates: ActionTemplateSearchResource[], filter: string): Result[] {
    const searchModels = templates.map(convertActionTemplateSearchResourceToModel);
    return searchModels
        .map((at) => matchesFilter(at, filter))
        .filter((x) => x.IsMatch)
        .sort((x, y) => {
        return x.Weighting > y.Weighting ? -1 : 1;
    });
}
