import React from "react";
import ResizeDetector from "react-resize-detector";
interface RenderResponsiveChartContainerProps {
    width: number;
    height: number;
}
interface ResponsiveChartContainerProps {
    children: (renderProps: RenderResponsiveChartContainerProps) => React.ReactElement;
}
// The recharts 'ResponsiveContainer' component causes a console error on a resize event (it keeps the height and width in state).
// This component passes the height and width directly from the resize detector to the render function.
function ResponsiveChartContainer({ children }: ResponsiveChartContainerProps) {
    return (<React.Fragment>
            <ResizeDetector handleHeight handleWidth>
                {({ width = 0, height = 0 }) => <div style={{ width: "100%", height: "100%" }}>{children({ width, height })}</div>}
            </ResizeDetector>
        </React.Fragment>);
}
export default ResponsiveChartContainer;
