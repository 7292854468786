import { CircularProgress } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import MissingVariablesIcon from "../../../tenants/MissingVariablesIcon/MissingVariablesIcon";
import styles from "./style.module.less";
interface TenantsMissingVariablesNotifierProps {
    missingVariableTenantsPromise: Promise<string[]>;
    rowId: string;
}
interface TenantsMissingVariablesNotifierState extends DataBaseComponentState {
    tenantsMissingVariables: string[];
}
class TenantsMissingVariablesNotifier extends DataBaseComponent<TenantsMissingVariablesNotifierProps, TenantsMissingVariablesNotifierState> {
    constructor(props: TenantsMissingVariablesNotifierProps) {
        super(props);
        this.state = {
            tenantsMissingVariables: [],
        };
    }
    componentDidMount = async () => {
        await this.doBusyTask(async () => {
            const tenantsMissingVariables = await this.props.missingVariableTenantsPromise;
            this.setState({ tenantsMissingVariables });
        });
    };
    render = () => {
        const { rowId } = this.props;
        const { tenantsMissingVariables, busy } = this.state;
        const hasMissingVariable = tenantsMissingVariables.indexOf(rowId) !== -1;
        return busy ? (<div className={styles.busyContainer}>
                <CircularProgress size={"small"}/>
            </div>) : (hasMissingVariable && (<div className={styles.variableAlert}>
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    <InternalLink to={links.tenantVariablesPage.generateUrl({ spaceId: repository.spaceId!, tenantId: rowId })}>
                        <MissingVariablesIcon show={true}/>
                    </InternalLink>
                </div>));
    };
    reload = async () => {
        await this.doBusyTask(async () => {
            const tenantsMissingVariables = await this.props.missingVariableTenantsPromise;
            this.setState({ tenantsMissingVariables });
        });
    };
    static displayName = "TenantsMissingVariablesNotifier";
}
export default TenantsMissingVariablesNotifier;
