/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository, client } from "~/clientInstance";
import { SpaceChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { required, Text, MarkdownEditor, Note } from "~/components/form";
interface AddTeamProps {
    currentSpace: SpaceResource;
    onTeamCreated(id: string): void;
}
interface AddTeamState extends DataBaseComponentState {
    name: string;
    spaceAccessibility: TeamSpaceAccessibility;
    description: string;
}
enum TeamSpaceAccessibility {
    CurrentSpace = "CurrentSpace",
    AllSpaces = "AllSpaces"
}
export default class AddTeam extends DataBaseComponent<AddTeamProps, AddTeamState> {
    constructor(props: AddTeamProps) {
        super(props);
        this.state = {
            name: "",
            spaceAccessibility: TeamSpaceAccessibility.CurrentSpace,
            description: "",
        };
    }
    save() {
        return this.doBusyTask(async () => {
            const result = await repository.Teams.create({
                Id: null!,
                Name: this.state.name,
                SpaceId: this.state.spaceAccessibility === TeamSpaceAccessibility.CurrentSpace ? this.props.currentSpace.Id : null,
                ExternalSecurityGroups: [],
                MemberUserIds: [],
                CanBeDeleted: true,
                CanBeRenamed: true,
                CanChangeMembers: true,
                CanChangeRoles: true,
                Links: null!,
                Description: this.state.description,
            }, {});
            this.props.onTeamCreated(result.Id);
        });
    }
    render() {
        const canSeeSpace = !!client.spaceId;
        return (<SaveDialogLayout title={canSeeSpace ? "Add New Team" : "Add New System Team"} busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()}>
                <Text label="New team name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a team name")} autoFocus={true}/>
                <MarkdownEditor label="Team description" value={this.state.description} onChange={this.handleDescriptionChanged}/>
                {canSeeSpace ? (<div>
                        <h4>Make this team accessible in this space or all spaces.</h4>
                        <RadioButtonGroup value={this.state.spaceAccessibility} onChange={(x) => this.setState({ spaceAccessibility: x })}>
                            <RadioButton value={TeamSpaceAccessibility.CurrentSpace} label={<span>Accessible in the {<SpaceChip space={this.props.currentSpace}/>} space only</span>}/>
                            <RadioButton value={TeamSpaceAccessibility.AllSpaces} label="Accessible in all spaces (system team)"/>
                        </RadioButtonGroup>
                    </div>) : (<Note>This team will be accessible in all Spaces</Note>)}
            </SaveDialogLayout>);
    }
    private handleDescriptionChanged = async (description: string) => {
        // in dobusy so the dialog resizes if the markdowneditor grows
        await this.doBusyTask(async () => {
            this.setState({ description });
        });
    };
    static displayName = "AddTeam";
}
