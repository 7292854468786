import React from "react";
import styles from "./style.module.less";
export type DynamicEnvironmentErrorIconProps = {
    title: string;
};
export function DynamicEnvironmentErrorIcon({ title }: DynamicEnvironmentErrorIconProps) {
    return (<svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.dynamicEnvironmentStateIcon}>
            <title>{title}</title>
            <path d="M7.99933 0C8.44308 0 8.85246 0.234375 9.07746 0.61875L15.8275 12.1187C16.0556 12.5062 16.0556 12.9844 15.8337 13.3719C15.6118 13.7594 15.1962 14 14.7493 14H1.24933C0.80246 14 0.386835 13.7594 0.16496 13.3719C-0.0569151 12.9844 -0.0537902 12.5031 0.17121 12.1187L6.92121 0.61875C7.14621 0.234375 7.55558 0 7.99933 0ZM7.99933 4C7.58371 4 7.24933 4.33437 7.24933 4.75V8.25C7.24933 8.66562 7.58371 9 7.99933 9C8.41496 9 8.74934 8.66562 8.74934 8.25V4.75C8.74934 4.33437 8.41496 4 7.99933 4ZM8.99934 11C8.99934 10.4469 8.55246 10 7.99933 10C7.44621 10 6.99933 10.4469 6.99933 11C6.99933 11.5531 7.44621 12 7.99933 12C8.55246 12 8.99934 11.5531 8.99934 11Z" fill="#D63D3D"/>
        </svg>);
}
