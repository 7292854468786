import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import type { PackageReferenceProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { doesRunOnSupportBundledTools } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import BaseComponent from "~/components/BaseComponent";
import type { ActionEditProps } from "../Actions/pluginRegistry";
import type { ScriptPackageProperties } from "../Actions/script/ScriptPackageReferenceDialog";
import { ExpandableFormSection, Summary } from "../form";
const StringProperties = {
    OctopusUseBundledTooling: "",
};
export type BundledToolsProperties = {
    [P in keyof typeof StringProperties]: string;
};
type BundledToolsEditBaseProperties = {
    actionProps: ActionEditProps<BundledToolsProperties, PackageReferenceProperties | ScriptPackageProperties>;
    title: string;
    notes: React.ReactNode;
    help: string;
    useBundledToolsSummary: string;
    usePreInstalledToolsSummary: string;
    useBundledToolsCallout: React.ReactNode;
};
export class BundledToolsEditBase extends BaseComponent<BundledToolsEditBaseProperties, never> {
    summary(useBundled: boolean, useBundledToolsSummary: string, usePreInstalledToolsSummary: string) {
        if (!useBundled) {
            return Summary.default(usePreInstalledToolsSummary);
        }
        return Summary.summary(useBundledToolsSummary);
    }
    render() {
        // Existing steps that use this component may not have a property OctopusUseBundledTooling
        // and in the server the behavior when this property doesn't exist is to treat it as
        // true and include the tooling. For this reason we'll also treat it the same way in the UI
        // To set the default for new steps added by the user, actions can use the getInitialProperties
        // property to provide the appropriate default which would normally be false.
        const useBundled = this.props.actionProps.properties.OctopusUseBundledTooling === "True" || this.props.actionProps.properties.OctopusUseBundledTooling === undefined;
        const bundledToolsSection = (<ExpandableFormSection errorKey="OctopusUseBundledTooling" isExpandedByDefault={this.props.actionProps.expandedByDefault} title={this.props.title} summary={this.summary(useBundled, this.props.useBundledToolsSummary, this.props.usePreInstalledToolsSummary)} help={this.props.help}>
                {this.props.notes}
                <BooleanRadioButtonGroup value={useBundled} onChange={(x: boolean) => {
                this.props.actionProps.setProperties({ OctopusUseBundledTooling: x ? "True" : "False" });
            }}>
                    <BooleanRadioButton value={false} label={this.props.usePreInstalledToolsSummary} isDefault={true}/>
                    <BooleanRadioButton value={true} label={this.props.useBundledToolsSummary}/>
                </BooleanRadioButtonGroup>
                {useBundled ? this.props.useBundledToolsCallout : null}
            </ExpandableFormSection>);
        if (doesRunOnSupportBundledTools(this.props.actionProps.runOn)) {
            return bundledToolsSection;
        }
        return null;
    }
    static displayName = "BundledToolsEditBase";
}
