/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { DeploymentFreezeStatus } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ChipIcon, DeploymentFreezeStatusChip, MissingChip } from "~/components/Chips/index";
import Lookup from "~/components/Lookup/index";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form/index";
// Emulating a resource so we can refer to this enum in dropdownlists easily etc.
export interface DeploymentFreezeStatusResource {
    Id: DeploymentFreezeStatus;
    Name: string;
}
interface DeploymentFreezeStatusMultiSelectProps extends FormFieldProps<DeploymentFreezeStatus[]> {
    items: DeploymentFreezeStatusResource[];
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
const DeploymentFreezeStatusTypedMultiSelect = MultiSelect<DeploymentFreezeStatusResource>();
export const DeploymentFreezeStatusMultiSelect: React.FC<DeploymentFreezeStatusMultiSelectProps> = (props) => {
    const { onChange, ...otherProps } = props;
    const chipRenderer = (r: DeploymentFreezeStatusResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <DeploymentFreezeStatusChip deleteButtonAccessibleName={`Delete ${item}`} onRequestDelete={onRequestDelete} status={item}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.MachineModelHealthStatus} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <DeploymentFreezeStatusTypedMultiSelect renderChip={chipRenderer} fieldName="freeze status" onChange={(value) => onChange(value as DeploymentFreezeStatus[])} {...otherProps}/>;
};
DeploymentFreezeStatusMultiSelect.displayName = "DeploymentFreezeStatusMultiSelect"
