import * as React from "react";
import styles from "./style.module.less";
interface NothingToSeeHereProps {
    content: string;
}
export class NothingToSeeHere extends React.Component<NothingToSeeHereProps> {
    render() {
        return <p className={styles.content}>{this.props.content}</p>;
    }
    static displayName = "NothingToSeeHere";
}
