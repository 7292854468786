import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectRunbookSnapshotOverview } from "../../CommonComponents/CommonProjectRunbookSnapshotsViews";
export const ProjectRunbookSnapshotsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.runbookSnapshots,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookSnapshotOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
export const ProjectRunbookSnapshotsCreateRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.runbookSnapshotCreate,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookSnapshotOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
export const ProjectRunbookSnapshotCreateRootRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.runbookSnapshot().root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookSnapshotOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
export const ProjectRunbookSnapshotEditRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.runbookSnapshot().edit,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookSnapshotOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
