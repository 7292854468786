import type { LinkHref } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { RoleChip } from "~/components/Chips/index";
import type { RolesContentFilter } from "./RolesContent";
interface RolesContentChipProps {
    role: string;
    link: (filter: RolesContentFilter) => LinkHref;
}
export const RolesContentChip = ({ role, link }: RolesContentChipProps) => {
    return <RoleChip role={role} key={"role-" + role} to={link({ roles: [role] })}/>;
};
