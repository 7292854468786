import { Callout } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import useLocalStorage from "~/hooks/useLocalStorage";
export type GitProtectionRulesCalloutProps = {
    project: ProjectResource;
};
export function GitProtectionRulesCallout({ project }: GitProtectionRulesCalloutProps) {
    const gitProtectionRulesFeatureIsEnabled = isFeatureToggleEnabled("ChannelGitProtectionRulesFeatureToggle");
    const [calloutDismissed, setCalloutDismissed] = useLocalStorage("Octopus.GitProtectionRules.CalloutDismissed", false);
    if (!gitProtectionRulesFeatureIsEnabled || calloutDismissed) {
        return null;
    }
    return (<Callout type="new-feature" title="Git Protection Rules" canClose={true} onClose={() => setCalloutDismissed(true)}>
            Restrictions on which branches and tags can be used when creating releases can be configured in{" "}
            <InternalLink to={links.channelsPage.generateUrl({
            spaceId: project.SpaceId,
            projectSlug: project.Slug,
        })} showOpensInNewTabIcon={true} openInSelf={false}>
                Channels
            </InternalLink>
            . Learn more about <ExternalLink href="ChannelGitProtectionRules">Git protection rules in Channels</ExternalLink>.
        </Callout>);
}
