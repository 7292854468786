import * as React from "react";
import { Summary } from "~/components/form";
import { getCronExpressionDescription } from "~/utils/CronExpressions/getCronExpressionDescription";
export default function getCronExpressionSummary(expression: string | undefined) {
    try {
        if (expression && expression.trim().split(" ").length > 5) {
            return Summary.summary(<span>
                    <strong>{getCronExpressionDescription(expression)}</strong>
                </span>);
        }
        return Summary.placeholder("Enter the cron expression to configure when the trigger should execute");
    }
    catch {
        return Summary.summary(<span>
                <strong>Invalid cron expression</strong>
            </span>);
    }
}
