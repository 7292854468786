import { css } from "@emotion/css";
import * as React from "react";
import { Leftovers } from "~/areas/tenants/components/DataTable/Cells/Leftovers";
import { EnvironmentChip } from "~/components/Chips/index";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
interface DeploymentFreezeDataTableEnvironmentsCellProps {
    environments: string[];
}
export function DeploymentFreezeDataTableEnvironmentsCell({ environments }: DeploymentFreezeDataTableEnvironmentsCellProps) {
    if (environments.length === 0) {
        return (<DataTableRowColumn className={cellStyles}>
                <div style={{ margin: "4px" }}>No environments</div>
            </DataTableRowColumn>);
    }
    const count = environments.length > 1 ? environments.length - 2 : null;
    return (<DataTableRowColumn className={cellStyles}>
            <div style={{ display: "flex" }}>
                {environments.slice(0, 2).map((e) => (<EnvironmentChip environmentName={e} key={e}/>))}
                <Leftovers count={count} name={"environment"} style={{ alignSelf: "center" }}/>
            </div>
        </DataTableRowColumn>);
}
const cellStyles = css({ width: "35%" });
