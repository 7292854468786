import React, { useEffect, useState } from "react";
import type { TemplateValuesSourceListItemModel } from "~/components/Actions/helmChartUpgrade/TemplateValueSourceListItem";
import { TemplateValueSourceListItem } from "~/components/Actions/helmChartUpgrade/TemplateValueSourceListItem";
import type { TemplateValuesSources } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import Dialog from "~/components/Dialog/Dialog";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import type { SortableItemModel } from "~/primitiveComponents/dataDisplay/SortableList/SortableList";
import SortableList from "~/primitiveComponents/dataDisplay/SortableList/index";
type HelmTemplateValuesSorterDialogProps = {
    isOpen: boolean;
    onSave: (orderedTemplateValueSources: TemplateValuesSources) => void;
    onCancel: () => void;
    templateValueSources: TemplateValuesSourceListItemModel[];
};
type SortableTemplateValuesSource = SortableItemModel & {
    ItemModel: TemplateValuesSourceListItemModel;
};
const mapToSortableItem = (templateValueSources: TemplateValuesSourceListItemModel[]) => templateValueSources.map((model) => ({
    Id: model.id,
    Name: model.name,
    ItemModel: model,
}));
export const HelmTemplateValuesSorterDialog = (props: HelmTemplateValuesSorterDialogProps) => {
    const [templateValueSources, setTemplateValueSources] = useState<SortableTemplateValuesSource[]>(mapToSortableItem(props.templateValueSources));
    useEffect(() => {
        setTemplateValueSources(mapToSortableItem(props.templateValueSources));
    }, [props.templateValueSources]);
    const onSave = () => {
        props.onSave(templateValueSources.map((x) => x.ItemModel.templateValuesSource));
        return Promise.resolve(true);
    };
    const renderItem = (item: SortableTemplateValuesSource) => <TemplateValueSourceListItem item={item.ItemModel}/>;
    return (<Dialog open={props.isOpen}>
            <SaveDialogLayout title={"Reorder Template Value Sources"} onSaveClick={() => onSave()} onCancelClick={() => props.onCancel()}>
                <SortableList items={templateValueSources} onOrderChanged={(x: SortableTemplateValuesSource[]) => setTemplateValueSources(x)} renderItem={renderItem}/>
            </SaveDialogLayout>
        </Dialog>);
};
