import * as React from "react";
import { manualPackagingInstruction } from "../../Instructions";
import { CommonManualPackagingTextForNuGet, CommonManualPackagingTextForZip } from "../CommonRegistrationText";
export const ManualPackaging: React.SFC<{}> = () => (<div>
        <p>
            When packaging .NET applications manually, creating <i>NuGet</i> or <i>Zip</i> packages yourself is an easy way to get started.
        </p>
        {CommonManualPackagingTextForNuGet}
        {CommonManualPackagingTextForZip}
    </div>);
ManualPackaging.displayName = "ManualPackaging"
export default manualPackagingInstruction(() => <ManualPackaging />);
