/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Repository, TaskRestrictedTo, Permission } from "@octopusdeploy/octopus-server-client";
import type { WorkerPoolsSummaryResource, WorkerPoolResource, WorkerMachineResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import type { ReactNode } from "react";
import { orderedHealthStatuses } from "~/areas/infrastructure/InfrastructureDetails";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import FormPage from "~/components/FormPage/FormPage";
import { Loading } from "~/components/Loading/Loading";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { withTheme } from "~/components/Theme";
import { CardTitle } from "~/components/form/Sections";
import { OctopusIcon, OctopusIconType } from "~/primitiveComponents/dataDisplay/Icon";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import MachineRow from "../MachineRow/MachineRow";
import type { WorkerPoolsSummaryFilter } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import { defaultWorkerPoolsSummaryFilter, createWorkerPoolListWorkerArgs } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
import BaseAllMachinesSummary from "./BaseAllMachinesSummary";
import type { HealthStatusRecord, BaseAllMachinesSummaryProps, BaseAllMachinesSummaryState } from "./BaseAllMachinesSummary";
import { createMachineHealthMap } from "./MachineFilter";
import styles from "./style.module.less";
interface WorkerMachinesSummarySectionProps extends BaseAllMachinesSummaryProps<WorkerPoolsSummaryFilter> {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    workerPools: WorkerPoolResource[];
    filter: WorkerPoolsSummaryFilter;
}
interface WorkerMachinesSummarySectionInnerProps extends WorkerMachinesSummarySectionProps {
    initialData: InitialData;
}
interface InitialData {
    machinesResponse: ResourceCollection<WorkerMachineResource>;
    machineHealthStatusFastLookup: HealthStatusRecord<WorkerMachineResource>;
    endpointRegistrations: EndpointRegistration[];
}
const WorkerMachinesSummaryFormPage = FormPage<InitialData>();
const WorkerMachinesSummarySection: React.FC<WorkerMachinesSummarySectionProps> = (props: WorkerMachinesSummarySectionProps) => {
    return (<WorkerMachinesSummaryFormPage title={"Workers"} load={async () => {
            const machineRequestArgs = createWorkerPoolListWorkerArgs(defaultWorkerPoolsSummaryFilter, null, true);
            const machinesResponse = await repository.Workers.list(machineRequestArgs);
            const machineHealthStatusFastLookup = createMachineHealthMap(machinesResponse, Repository.takeDefaultPageSize);
            const endpointRegistrations = endpointRegistry.getAllRegistrations();
            return { machinesResponse, machineHealthStatusFastLookup, endpointRegistrations: await endpointRegistrations };
        }} renderWhenLoaded={(data) => <WorkerMachinesSummarySectionInner initialData={data} {...props}/>}/>);
};
WorkerMachinesSummarySection.displayName = "WorkerMachinesSummarySection"
class WorkerMachinesSummarySectionInner extends BaseAllMachinesSummary<WorkerMachineResource, WorkerMachinesSummarySectionInnerProps, WorkerPoolsSummaryFilter, BaseAllMachinesSummaryState<WorkerMachineResource>> {
    private requestRaceConditioner = new RequestRaceConditioner();
    constructor(props: WorkerMachinesSummarySectionInnerProps) {
        super(props);
        this.state = {
            ...this.commonInitialState,
            machinesResponse: this.props.initialData.machinesResponse,
            machineHealthStatusFastLookup: this.props.initialData.machineHealthStatusFastLookup,
            endpointRegistrations: this.props.initialData.endpointRegistrations,
            redirectToTasks: false,
        };
    }
    componentDidMount() {
        this.reloadDataAndCurrentPageIndex();
    }
    componentDidUpdate(prevProps: BaseAllMachinesSummaryProps<WorkerPoolsSummaryFilter>) {
        if (!isEqual(prevProps.filter, this.props.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }
    filterMachines(machines: WorkerMachineResource[], filter: WorkerPoolsSummaryFilter) {
        let filteredMachines = machines;
        if (filter.isDisabled) {
            filteredMachines = filteredMachines.filter((machine) => machine.IsDisabled);
        }
        if (filter.workerPoolIds.length > 0) {
            const workerPoolIdsFilterSet = new Set(filter.workerPoolIds);
            filteredMachines = filteredMachines.filter((machine) => machine.WorkerPoolIds.some((workerPoolId) => workerPoolIdsFilterSet.has(workerPoolId)));
        }
        if (filter.machinePartialName) {
            const partialNameFilter = filter.machinePartialName;
            filteredMachines = filteredMachines.filter((machine) => machine.Name.toLocaleLowerCase().includes(partialNameFilter.toLocaleLowerCase()));
        }
        if (filter.shellNames.length > 0) {
            filteredMachines = filteredMachines.filter((machine) => filter.shellNames.includes(machine.ShellName));
        }
        if (filter.healthStatuses.length > 0) {
            filteredMachines = filteredMachines.filter((machine) => filter.healthStatuses.includes(machine.HealthStatus));
        }
        if (filter.commStyles.length > 0) {
            filteredMachines = filteredMachines.filter((machine) => filter.commStyles.includes(machine.Endpoint.CommunicationStyle));
        }
        return filteredMachines;
    }
    render() {
        if (this.state.redirectToTasks) {
            return <InternalRedirect to={links.tasksPage.generateUrl()} push={true}/>;
        }
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        const workerPoolsSummary = this.props.workerPoolsSummary;
        const filteredMachines = this.filterMachines(this.state.machinesResponse.Items, this.props.filter);
        const workerIds = new Set(filteredMachines.map((x) => x.Id));
        const totalFilteredMachines = workerIds.size;
        const workerIdsForTentacleUpgrade = workerPoolsSummary.MachineIdsForTentacleUpgrade ? workerPoolsSummary.MachineIdsForTentacleUpgrade.filter((id) => workerIds.has(id)) : [];
        const workerIdsForCalamariUpgrade = workerPoolsSummary.MachineIdsForCalamariUpgrade.filter((id) => workerIds.has(id));
        const machineStatusesLinks = orderedHealthStatuses.map((status) => this.renderMachineSummaryLinks(filteredMachines, status));
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(filteredMachines);
        const summaryComponents = [...machineStatusesLinks, machinesDisabledLinks];
        const componentKey = "allMachines";
        const overflowMenuItems = [];
        // Only show machine-related actions if they actually have some machines in this environment.
        if (totalFilteredMachines > 0) {
            overflowMenuItems.push(OverflowMenuItems.item(`Check Health for ${workerIds && workerIds.size} Worker${workerIds && workerIds.size === 1 ? "" : "s"}`, () => this.performHealthCheck(TaskRestrictedTo.Workers, workerIds), {
                permission: Permission.WorkerEdit,
                wildcard: true,
            }));
            if (workerIdsForTentacleUpgrade && workerIdsForTentacleUpgrade.length > 0) {
                overflowMenuItems.push(OverflowMenuItems.confirmUpgrade(`Upgrade ${workerIdsForTentacleUpgrade.length} Tentacle${workerIdsForTentacleUpgrade.length === 1 ? "" : "s"}`, () => this.performWorkerUpgrade(), {
                    permission: Permission.WorkerEdit,
                    wildcard: true,
                }));
            }
            if (workerIdsForCalamariUpgrade && workerIdsForCalamariUpgrade.length > 0) {
                overflowMenuItems.push(OverflowMenuItems.confirmUpgrade(`Upgrade Calamari on ${workerIdsForCalamariUpgrade.length} Worker${workerIdsForCalamariUpgrade.length === 1 ? "" : "s"}`, () => this.performCalamariUpgradeOnWorkers(workerIdsForCalamariUpgrade), {
                    permission: Permission.WorkerEdit,
                    wildcard: true,
                }));
            }
        }
        const titleContainer = (<div className={styles.cardTitleContainer}>
                <div className={styles.environmentIcon}>
                    {withTheme((theme) => (<OctopusIcon iconType={OctopusIconType.Worker} color={theme.iconDark}/>))}
                </div>
                <div className={styles.environmentName}>Workers</div>
                <div className={styles.environmentMachinesCount}>({totalFilteredMachines.toLocaleString()})</div>
                <div className={styles.environmentSummaryCounts}>{summaryComponents}</div>
                <div className={styles.environmentOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems}/>
                </div>
            </div>);
        return (<Loading key={componentKey} busy={this.state.busy} errors={this.errors}>
                <CardTitle title={titleContainer}/>
                <div className={styles.cardMedia}>{this.renderMachinesList(filteredMachines, workerIdsForTentacleUpgrade, workerIdsForCalamariUpgrade)}</div>
            </Loading>);
    }
    protected async loadData() {
        const machineArgs = createWorkerPoolListWorkerArgs(this.props.filter, this.state.healthStatusFilter, this.state.isDisabledFilter);
        const promises = Promise.all([repository.Workers.list(machineArgs), endpointRegistry.getAllRegistrations()]);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(promises, ([machinesResponse, endpointRegistrations]) => {
            this.setMachineResponseState(machinesResponse);
            this.setState({ endpointRegistrations });
        });
    }
    protected renderMachine(machine: WorkerMachineResource, needsUpgrading: boolean = false): ReactNode {
        return <MachineRow registrations={this.state.endpointRegistrations} machine={machine} workerPools={this.props.workerPools} needsUpgrading={needsUpgrading}/>;
    }
}
export default WorkerMachinesSummarySection;
