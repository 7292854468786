/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton } from "@octopusdeploy/design-system-components";
import type { HelmChartUpgradeProperties } from "@octopusdeploy/legacy-action-properties";
import { type FeedResource, GetNamedPackageReferences, IsPrimaryPackageReference, PackageAcquisitionLocation, SetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import { RemoveItemsList } from "app/components/RemoveItemsList/RemoveItemsList";
import ExpandableFormSection from "app/components/form/Sections/ExpandableFormSection";
import { clone } from "lodash";
import * as React from "react";
import { useState } from "react";
import { ProcessFeedLookup } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import type { RunOn } from "~/areas/projects/components/Process/types";
import type { PackagedHelmValuesReference } from "~/components/Actions/helmChartUpgrade/PackagedHelmValuesDialog";
import { PackagedHelmValuesDialog } from "~/components/Actions/helmChartUpgrade/PackagedHelmValuesDialog";
import { IsDockerImageReference, IsHelmExePackageReference } from "~/components/Actions/helmChartUpgrade/helmPackageHelpers";
import type { BoundFieldProps } from "~/components/Actions/pluginRegistry";
import { CodeEditor, TextFormat } from "~/components/CodeEditor/CodeEditor";
import DialogOpener from "~/components/Dialog/DialogOpener";
import { KeyValueEditList } from "~/components/EditList/index";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { Note, Summary } from "~/components/form/index";
import type { CodeEditorVariable } from "~/utils/ScriptIntellisense/scriptIntellisense";
type HelmTemplateValuesSectionProps = BoundFieldProps & {
    chartSource: string;
    properties: HelmChartUpgradeProperties;
    setProperties: (properties: Partial<HelmChartUpgradeProperties>, initialise?: boolean, callback?: () => void) => void;
    primaryPackageReference: PackagedHelmValuesReference;
    packages: Array<PackagedHelmValuesReference>;
    setPackages: (packages: Array<PackagedHelmValuesReference>, initialise?: boolean) => void;
    yamlAutocompleteResults: CodeEditorVariable[];
    runOn: RunOn | undefined;
    feeds: FeedResource[];
    loadFeeds: () => Promise<void>;
};
class PackageReferenceList extends RemoveItemsList<PackagedHelmValuesReference> {
}
export const HelmTemplateValuesSection = (props: HelmTemplateValuesSectionProps) => {
    const [editingPackageReference, setEditingPackageReference] = useState<PackagedHelmValuesReference | null>(null);
    const [isAddingPackageReference, setIsAddingPackageReference] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const packageReferences = GetNamedPackageReferences(props.packages).filter((pkg) => !IsHelmExePackageReference(pkg) && !IsDockerImageReference(pkg)) as PackagedHelmValuesReference[];
    const summaryVariables = () => {
        const variables = JSON.parse(props.properties["Octopus.Action.Helm.KeyValues"] || "{}");
        if (Object.keys(variables).length === 0) {
            return Summary.placeholder("No explicit value overrides supplied");
        }
        else {
            const text = Object.keys(variables)
                .map((m) => `${m} = ${variables[m]}`)
                .join(", ");
            return Summary.summary(text);
        }
    };
    const summaryRawYaml = () => {
        const rawYaml = props.properties["Octopus.Action.Helm.YamlValues"];
        if (rawYaml) {
            return Summary.summary("An inline YAML values file has been provided");
        }
        return Summary.placeholder("No inline YAML file has been provided");
    };
    const summaryChartPackageVariables = (pkg: PackagedHelmValuesReference) => {
        const valuesFilePath = pkg.Properties["ValuesFilePath"];
        if (valuesFilePath) {
            return Summary.summary(<span>
                    values file(s) <strong>{valuesFilePath}</strong> from the {props.chartSource === "Package" ? "package" : "Git repository"} will be included in the upgrade
                </span>);
        }
        return Summary.placeholder("No additional values files provided");
    };
    const packageReferenceSummary = () => {
        const packageReferences = GetNamedPackageReferences(props.packages).filter((pkg) => !IsHelmExePackageReference(pkg) && !IsPrimaryPackageReference(pkg) && !IsDockerImageReference(pkg));
        if (!packageReferences || packageReferences.length === 0) {
            return Summary.placeholder("No additional value files");
        }
        return Summary.summary(`${packageReferences.length} package references`);
    };
    const addPackageReference = () => {
        const additionalPackage: PackagedHelmValuesReference = {
            Id: null!,
            Name: null!,
            FeedId: null!,
            PackageId: null!,
            AcquisitionLocation: PackageAcquisitionLocation.ExecutionTarget,
            Properties: { ValuesFilePath: "", PerformVariableReplace: "False", Extract: "true" },
        };
        setEditingPackageReference(additionalPackage);
        setIsAddingPackageReference(true);
    };
    const editPackageReference = (pkg: PackagedHelmValuesReference) => {
        setEditingPackageReference(clone(pkg));
        setIsAddingPackageReference(false);
    };
    const removePackageReference = (pkg: PackagedHelmValuesReference) => {
        const packages = [...props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        props.setPackages(packages);
    };
    const savePackageReference = (packageReference: PackagedHelmValuesReference) => {
        const packageReferences = [...props.packages];
        if (isAddingPackageReference) {
            //we find all the indexes of the values pack packages from the name
            const valuePackIndexes = packageReferences
                .map((pkgRef) => pkgRef.Name)
                .map((name) => name?.match(/ValuesPack-(?<idx>[0-9]+)/))
                .map((matchArray) => (matchArray?.groups ? parseInt(matchArray.groups.idx) : 0))
                .filter((idx) => idx !== 0);
            //then we get the max and add one so we never duplicate an index value
            const maxIndex = Math.max(...valuePackIndexes);
            const newIndex = maxIndex + 1;
            packageReference = { ...packageReference, Name: `ValuesPack-${newIndex}` };
            packageReferences.push(packageReference);
        }
        else {
            //when editing, we might have changed the packageId, so we just match on name & id
            const index = packageReferences.findIndex((pkg) => pkg.Id === packageReference.Id && pkg.Name === packageReference.Name);
            packageReferences[index] = packageReference;
        }
        props.setPackages(packageReferences);
        resetSelectedPackageReference();
        return true;
    };
    const resetSelectedPackageReference = () => {
        setEditingPackageReference(null);
        setIsAddingPackageReference(false);
    };
    return (<>
            <DialogOpener open={!!editingPackageReference} onClose={resetSelectedPackageReference}>
                <PackagedHelmValuesDialog packageReference={editingPackageReference!} runOn={props.runOn} feeds={props.feeds} localNames={props.localNames || []} projectId={props.projectId!} onChange={(packageReference) => savePackageReference(packageReference)} refreshFeeds={props.loadFeeds}/>
            </DialogOpener>

            <ExpanderSectionHeading title="Template Values"/>
            <ExpandableFormSection errorKey="Octopus.Action.Helm.KeyValues" title="Explicit Key Values" summary={summaryVariables()} help={<span>
                        Adds values into custom <code>values.yaml</code> and includes via <code>--values</code> Helm argument
                    </span>}>
                <KeyValueEditList items={props.properties["Octopus.Action.Helm.KeyValues"]} name="Key/Value" separator="=" onChange={(val) => props.setProperties({ ["Octopus.Action.Helm.KeyValues"]: val })} valueLabel="Value" projectId={props.projectId} gitRef={props.gitRef} keyLabel="Key"/>
            </ExpandableFormSection>
            <ExpandableFormSection summary={summaryRawYaml()} title={"Raw Values YAML"} errorKey={"Octopus.Action.Helm.YamlValues"}>
                <CodeEditor allowFullScreen value={props.properties["Octopus.Action.Helm.YamlValues"]} language={TextFormat.YAML} onChange={(x) => props.setProperties({ ["Octopus.Action.Helm.YamlValues"]: x })} autoComplete={props.yamlAutocompleteResults} showToolbar={true} showCopyButton={true} showInsertVariableButton={true} localNames={props.localNames}/>
                <Note>
                    Enter the raw YAML that will be provided as a values file. This field supports the <ExternalLink href="VariableSubstitutionSyntaxExtended">extended template syntax</ExternalLink>.
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Octopus.Action.Package.ValuesFilePath" title={props.chartSource === "Package" ? "Files in Chart Package" : "Files in Git Repository"} summary={summaryChartPackageVariables(props.primaryPackageReference)} help={<span>Files sourced from the {props.chartSource === "Package" ? "chart package" : "Git repository"}</span>}>
                <VariableLookupText localNames={props.localNames} value={props.primaryPackageReference.Properties.ValuesFilePath} onChange={(x) => props.setPackages(SetPrimaryPackageReference({ Properties: { ...props.primaryPackageReference.Properties, ValuesFilePath: x } }, props.packages))} label="File(s)" multiline={true}/>
                <Note>
                    A newline-separated list of file names, relative to the chart root to be included as additional <code>--values</code> files. Variable replacement will be run on these files before used. Extended template and wildcard syntax is
                    supported. E.g., <em>values.{`#{Octopus.Environment.Name}`}.yaml</em>, <em>**\specific-folder\*.yaml</em>.
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Octopus.Action.Script.Packages" title={"Files in Additional Packages"} summary={packageReferenceSummary()} help={<span>Includes yaml files from packages</span>}>
                <Note>Acquired files have Octopus variable replacement on them and must result in the standard yaml format</Note>

                <PackageReferenceList listActions={[<ActionButton key="add" label="Add" onClick={() => addPackageReference()}/>]} data={packageReferences} onRow={(pkg) => (<ProcessFeedLookup feedId={pkg.FeedId}>
                            {(feed) => (<div>
                                    <div>
                                        <strong>{pkg.Properties["ValuesFilePath"]}</strong> in <strong>{pkg.PackageId}</strong> from feed <strong>{feed ? feed.Name : pkg.FeedId}</strong>
                                    </div>
                                </div>)}
                        </ProcessFeedLookup>)} onRowTouch={(pkg) => editPackageReference(pkg)} onRemoveRow={(pkg) => removePackageReference(pkg)}/>
            </ExpandableFormSection>
        </>);
};
