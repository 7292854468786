import type { DataTableColumn } from "@octopusdeploy/design-system-components";
import { SimpleDataTable } from "@octopusdeploy/design-system-components";
import { sortBy } from "lodash";
import React from "react";
import type { PageIdentity } from "~/routing/pageRegistrations/PageIdentity";
import { allPagesFlattened } from "~/routing/pageRegistrations/allPageRegistrations";
export function SiteMapTab() {
    const pages = allPagesFlattened.map((p) => createSiteMapRow(p.route.getDebugString(), p.pageIdentity));
    const orderedPages = sortBy(pages, [(r) => r.pageArea, (r) => r.pageName]);
    const columns: DataTableColumn<SiteMapRow>[] = [
        { title: "Area", render: (p) => p.pageArea, columnSize: "small" },
        { title: "Page Name", render: (p) => p.pageName, columnSize: "small" },
        { title: "Page Id", render: (p) => p.pageId, columnSize: "small" },
        { title: "Route", render: (p) => p.templatedRoute },
    ];
    return <SimpleDataTable columns={columns} data={orderedPages} getRowKey={(r) => r.templatedRoute}/>;
}
function createSiteMapRow(routeDebugString: string, pageIdentity: PageIdentity): SiteMapRow {
    return {
        pageArea: pageIdentity.pageArea,
        pageId: pageIdentity.pageId,
        pageName: pageIdentity.pageName,
        templatedRoute: routeDebugString,
    };
}
interface SiteMapRow {
    pageArea: string;
    pageName: string;
    pageId: string;
    templatedRoute: string;
}
