/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { ActivityLogElement } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { getActivityElementClass } from "~/areas/tasks/components/Task/TaskLog/TaskLogUtil";
import DateFormatter from "~/utils/DateFormatter";
import TextWithLinks from "../TextWithLinks/TextWithLinks";
import styles from "./style.module.less";
interface TaskLogLineProps {
    line: ActivityLogElement;
    selected?: boolean;
    link?: string;
    showTimestamps: boolean;
    onToggleLine?(line: ActivityLogElement): void;
}
export class TaskLogLine extends React.Component<TaskLogLineProps> {
    constructor(props: TaskLogLineProps) {
        super(props);
    }
    componentDidMount() {
        this.scrollToLineIfRequired();
    }
    componentDidUpdate(prevProps: Readonly<TaskLogLineProps>) {
        // Handle situations like browser back/forward
        if (this.props.selected != prevProps.selected) {
            this.scrollToLineIfRequired();
        }
    }
    scrollToLineIfRequired() {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDOM.findDOMNode(this) as Element;
        if (node && this.props.selected) {
            node.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }
    renderLineNumber() {
        const line = this.props.line;
        if (!this.props.line.Number) {
            return <></>;
        }
        if (this.props.link && this.props.onToggleLine) {
            const toggleLine = (e: React.MouseEvent) => {
                e.preventDefault();
                if (this.props.line && this.props.onToggleLine) {
                    this.props.onToggleLine(this.props.line);
                }
            };
            return (<a onClick={toggleLine} href={"#" + this.props.link} className={styles.lineNumber}>
                    {line.Number}
                </a>);
        }
        else {
            return <div className={styles.lineNumber}>{line.Number}</div>;
        }
    }
    render() {
        const line = this.props.line;
        const occurred = () => DateFormatter.dateToCustomFormat(line.OccurredAt, "ddd, MMM Do YYYY HH:mm:ss Z");
        return (<div className={cn(styles.logLine, getActivityElementClass(line.Category), this.props.selected ? styles.selected : undefined)}>
                {this.renderLineNumber()}
                {this.props.showTimestamps && (<>
                        <div className={styles.occurred}>{occurred()}</div>
                        <div className={styles.category}>{line.Category}</div>
                    </>)}
                <div className={styles.message}>
                    <TextWithLinks message={line.MessageText} allowHtml={false}/> {line.Detail}
                </div>
                <div className={styles.copyHelperToken}>##OCTOCOPY##</div>
            </div>);
    }
    static displayName = "TaskLogLine";
}
