import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import * as React from "react";
export interface ScriptSourceSelectorComponentProps {
    scriptSourceValue: string | null;
    onScriptSourceChanged(scriptSource: string): void;
    getFieldError?(): string;
    accessibleName?: string;
}
export function ScriptSourceSelectorComponent(props: ScriptSourceSelectorComponentProps) {
    return (<RadioButtonGroup accessibleName={props.accessibleName || "Script Source"} value={props.scriptSourceValue ?? "Inline"} onChange={(val) => props.onScriptSourceChanged(val)} error={props.getFieldError?.()}>
            <RadioButton value={"Inline"} label="Inline script" isDefault={true}/>
            <RadioButton accessibleName="Git repository" value="GitRepository" label="Git repository"/>
            <RadioButton value={"Package"} label="Package"/>
        </RadioButtonGroup>);
}
export default ScriptSourceSelectorComponent;
