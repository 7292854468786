/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { DashboardRenderMode, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual, sortBy } from "lodash";
import MobileDetect from "mobile-detect";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { DeploymentOverviewFilters, DataCube } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import SpaceDashboardDataSource from "~/areas/projects/components/DashboardDataSource/SpaceDashboardDataSource";
import ProjectDashboard from "~/areas/projects/components/ProjectDashboard/index";
import { AddProjectGroup } from "~/areas/projects/components/Projects/AddProjectGroup";
import { ImportExportButton } from "~/areas/projects/components/Projects/ProjectsPageVNext";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import Dialog from "~/components/Dialog/Dialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { FullWidthPageLayout } from "~/components/FullWidthPageLayout/FullWidthPageLayout";
import { InternalRedirect } from "~/components/Navigation/InternalRedirect/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
import LegacyDashboardOnboardingLayout from "~/components/ProjectBasedActivation/DashboardOnboardingLayout";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
import Section from "~/components/Section";
import { Select } from "~/components/form";
import ComponentRow from "../../../components/ComponentRow/index";
import FilterSearchBox from "../../../components/FilterSearchBox/index";
import DashboardLimitSummary from "./DashboardLimitSummary";
import styles from "./style.module.less";
interface DashboardOverviewFilter {
    projectGroupId: string;
    projectName: string;
}
interface LegacyDashboardState {
    filter: DashboardOverviewFilter;
    matchCount: number;
    onboardingProject?: ProjectResource;
    redirectTo?: string;
    isDashboardLoaded: boolean;
}
const defaultFilter: DashboardOverviewFilter = {
    projectName: "",
    projectGroupId: "",
};
interface DashboardOverviewQuery extends IQuery {
    projectGroupId?: string;
    projectName?: string;
}
interface DashboardOverviewProps {
    spaceId: string;
}
interface LegacyDashboardProps extends DashboardOverviewProps {
    dispatchAction: AnalyticActionDispatcher;
    isVerticalNavigationEnabled: boolean;
    showProjectGroupEditLink?: boolean;
}
interface LegacyDashboardInternalProps extends LegacyDashboardProps {
    addProjectDialogControls: DialogControls;
    addProjectGroupDialogControls: DialogControls;
}
export type RenderDashboardProps = DataBaseComponentState & {
    projectLimit?: number;
    cube?: DataCube;
    totalItems?: number;
    isFiltered?: boolean;
};
export const dashboardRefreshIntervalInMs = 6000;
const DashboardQueryStringFilters = QueryStringFilters.For<DashboardOverviewFilter, DashboardOverviewQuery>();
const DashboardDispatcher = ({ dispatchAction }: {
    dispatchAction: AnalyticActionDispatcher;
}) => {
    const [dispatched, setDispatched] = React.useState(false);
    if (!dispatched) {
        dispatchAction("Dashboard Viewed", { action: Action.View, resource: "Dashboard" });
        setDispatched(true);
    }
    return <></>;
};
export function LegacyDashboard({ dispatchAction, isVerticalNavigationEnabled, showProjectGroupEditLink, spaceId }: LegacyDashboardProps) {
    const addProjectDialogControls = useDialogTrigger();
    const addProjectGroupDialogControls = useDialogTrigger();
    return (<LegacyDashboardInternal spaceId={spaceId} isVerticalNavigationEnabled={isVerticalNavigationEnabled} showProjectGroupEditLink={showProjectGroupEditLink} dispatchAction={dispatchAction} addProjectDialogControls={addProjectDialogControls} addProjectGroupDialogControls={addProjectGroupDialogControls}/>);
}
export class LegacyDashboardInternal extends DataBaseComponent<LegacyDashboardInternalProps, LegacyDashboardState> {
    constructor(props: LegacyDashboardInternalProps) {
        super(props);
        this.state = {
            filter: defaultFilter,
            matchCount: 0,
            onboardingProject: undefined,
            redirectTo: undefined,
            isDashboardLoaded: false,
        };
    }
    render() {
        if (this.state.redirectTo !== undefined) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        return (<>
                <DashboardQueryStringFilters filter={this.state.filter} getQuery={getQueryFromFilters} getFilter={getFilter} onFilterChange={(filter) => this.setState({ filter })}/>
                <SpaceDashboardDataSource render={this.renderDataSource} doBusyTask={this.doBusyTask} onDashboardLoad={() => this.onDashboardLoad()}/>
            </>);
    }
    private onDashboardLoad() {
        this.setState({ isDashboardLoaded: true });
    }
    private showDashboard(dashboardData: RenderDashboardProps) {
        const cube = dashboardData.cube;
        const groups = sortBy(cube!.projectGroupIndex, (g) => g.Name.toLowerCase()).map((g) => ({ value: g.Id, text: g.Name }));
        const hasFilter = !isEqual(defaultFilter, this.state.filter);
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;
        return (<Section className={styles.dashboardContainer}>
                <DashboardDispatcher dispatchAction={this.props.dispatchAction}/>
                <div className={styles.filterHeaderContainer} key="A" role="search">
                    <div className={styles.filterFieldContainer}>
                        <ComponentRow className={styles.filter}>
                            {groups.length > 1 && (<div className={styles.filterField}>
                                    <Select placeholder="Filter by project group" items={groups} onChange={this.handleGroupChange} value={this.state.filter.projectGroupId} allowClear={true}/>
                                </div>)}
                            <div className={styles.filterField}>
                                <FilterSearchBox placeholder="Filter by project name" inputClassName={styles.filterInput} value={this.state.filter.projectName} onChange={this.handleNameChange} autoFocus={autoFocus} fullWidth={true}/>
                            </div>
                            <DashboardLimitSummary matchCount={this.state.matchCount} projectLimit={dashboardData.projectLimit ?? null} hasFilter={hasFilter} totalProjects={Object.keys(dashboardData.cube!.projectIndex).length}/>
                        </ComponentRow>
                    </div>
                </div>
                <ProjectDashboard spaceId={this.props.spaceId} key="B" cube={dashboardData.cube!} filters={this.createDashboardFilters()} maximumRows={dashboardData.projectLimit} showDeploymentCounts={true} onProjectCountChanged={this.handleProjectCountChange} dashboardRenderMode={DashboardRenderMode.VirtualizeColumns} showProjectGroupEditLink={this.props.showProjectGroupEditLink}/>
            </Section>);
    }
    private renderDataSource = (dataSource: RenderDashboardProps) => {
        if (this.state.isDashboardLoaded && isAllowed({ permission: Permission.ProjectCreate })) {
            const totalProjects = Object.keys(dataSource.cube!.projectIndex).length;
            const hasDeployments = dataSource.cube!.deployments.length > 0;
            const canContinueOnboarding = totalProjects === 1 && !hasDeployments;
            if (hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSource) || canContinueOnboarding) {
                const project = dataSource.cube!.projectIndex[Object.keys(dataSource.cube!.projectIndex)[0]];
                return <LegacyDashboardOnboardingLayout spaceId={this.props.spaceId} canContinueOnboarding={canContinueOnboarding} onboardingProject={project} busy={this.state.busy} doBusyTask={this.doBusyTask}/>;
            }
        }
        const configureAction: PageAction = { type: "navigate", label: "Configure", path: links.dashboardConfigurationPage.generateUrl({ spaceId: this.props.spaceId }), buttonType: "secondary" };
        const addProjectPageAction: PrimaryPageAction = this.addProjectPageAction();
        const importExportProjectsPageAction: PageAction = this.importExportProjectsPageAction();
        const addProjectGroupPageAction: PageAction = this.addProjectGroupPageAction();
        const legacyActions = { pageActions: [configureAction] };
        const verticalNavigationActions = {
            pageActions: [configureAction, importExportProjectsPageAction, addProjectGroupPageAction],
            primaryAction: addProjectPageAction,
        };
        const pageActionProps = this.props.isVerticalNavigationEnabled ? verticalNavigationActions : legacyActions;
        return this.state.isDashboardLoaded ? (<FullWidthPageLayout areaTitle={this.props.isVerticalNavigationEnabled ? "Projects" : "Dashboard"} busy={this.state.busy} errors={this.errors} {...pageActionProps}>
                {this.renderDialogs()}
                {this.renderMainContent(dataSource)}
            </FullWidthPageLayout>) : (<FullWidthPageLayout errors={this.errors} isInitialLoad={true}/>);
    };
    private renderDialogs() {
        return (<>
                <Dialog open={this.props.addProjectGroupDialogControls.isOpen}>
                    <AddProjectGroup projectGroupCreated={(_id) => this.props.addProjectGroupDialogControls.closeDialog()}/>
                </Dialog>
                <AddNewProjectDialog open={this.props.addProjectDialogControls.isOpen} close={(project) => {
                // there is following commented out conditional check in original Projects page,
                // need to confirm if this is necessary, we could save a http call as well without it.
                // if (!this.state.hasProjects) {
                if (project) {
                    this.props.dispatchAction("Save Project", { action: Action.Save, resource: "Project" });
                }
                else {
                    this.props.dispatchAction("Cancel Project Creation", { action: Action.Cancel, resource: "Project" });
                }
                // }
                this.props.addProjectDialogControls.closeDialog();
            }}/>
            </>);
    }
    private importExportProjectsPageAction = (): PageAction => ({
        type: "custom",
        key: "importExport",
        content: <ImportExportButton spaceId={this.props.spaceId}/>,
    });
    private addProjectGroupPageAction = (): PageAction => ({
        type: "button",
        label: "Add Project Group",
        hasPermissions: isAllowed({ permission: Permission.ProjectGroupCreate, projectGroup: "*" }),
        buttonType: "secondary",
        onClick: () => {
            this.props.dispatchAction("Add Project Group", { resource: "Project Group", action: Action.Add });
            this.props.addProjectGroupDialogControls.openDialog();
        },
    });
    private addProjectPageAction = (): PrimaryPageAction => ({
        type: "button",
        label: "Add Project",
        hasPermissions: isAllowed({ permission: Permission.ProjectCreate, projectGroup: "*" }),
        onClick: () => {
            this.props.dispatchAction("Add Project", { resource: "Project", action: Action.Add });
            this.props.addProjectDialogControls.openDialog();
        },
    });
    private renderMainContent = (dataSource: RenderDashboardProps) => {
        if (!this.state.isDashboardLoaded)
            return null;
        return this.showDashboard(dataSource);
    };
    private handleGroupChange = (projectGroupId: string | undefined) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectGroupId } }));
    };
    private handleNameChange = (projectName: string) => {
        this.setState((prev) => ({ filter: { ...prev.filter, projectName } }));
    };
    private createDashboardFilters: () => DeploymentOverviewFilters = () => {
        const filter = this.state.filter;
        const groupFilters = filter.projectGroupId !== "" ? { [filter.projectGroupId]: true } : null!;
        const projectNameFilters = filter.projectName !== "" ? { [filter.projectName]: true } : null!;
        return {
            rowDimension: DimensionTypes.Project,
            columnDimension: DimensionTypes.Environment,
            groupBy: DimensionTypes.ProjectGroup,
            [DimensionTypes.ProjectGroup]: groupFilters,
            [DimensionTypes.ProjectName]: projectNameFilters,
        };
    };
    private handleProjectCountChange = (matchCount: number) => {
        // don't re-render if count is the same. Easier to check
        // here than shouldComponentUpdate
        if (matchCount !== this.state.matchCount) {
            this.setState({ matchCount });
        }
    };
    static displayName = "LegacyDashboardInternal";
}
function getQueryFromFilters(filter: DashboardOverviewFilter): DashboardOverviewQuery {
    return {
        projectGroupId: filter.projectGroupId,
        projectName: filter.projectName,
    };
}
function getFilter(query: DashboardOverviewQuery): DashboardOverviewFilter {
    return {
        projectGroupId: query.projectGroupId || "",
        projectName: query.projectName || "",
    };
}
export const DashboardOverviewPage = ({ spaceId }: DashboardOverviewProps) => {
    const dispatchAction = useAnalyticActionDispatch();
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    if (isVerticalNavigationEnabled) {
        return <InternalRedirect to={links.projectsPage.generateUrl({ spaceId })} push={false}/>;
    }
    return <LegacyDashboard spaceId={spaceId} dispatchAction={dispatchAction} isVerticalNavigationEnabled={isVerticalNavigationEnabled}/>;
};
function hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSourceState: RenderDashboardProps): boolean {
    return dataSourceState.cube ? Object.keys(dataSourceState.cube.projectIndex).length === 0 : false;
}
