import type { PageAction } from "@octopusdeploy/design-system-components";
import { ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ActionTemplateParameterResource } from "@octopusdeploy/octopus-server-client";
import { ControlType, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ActionTemplateParameterList, { AddActionTemplateParameterButton, SortActionTemplateParametersButton } from "~/components/ActionTemplateParametersList/index";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
interface BlueprintParametersProps {
    parameters: ActionTemplateParameterResource[];
    onChange: (parameters: ActionTemplateParameterResource[]) => void;
}
interface BlueprintParametersTabProps extends BlueprintParametersProps {
}
export function BlueprintParametersTab({ parameters, onChange }: BlueprintParametersTabProps) {
    const onAdd = (parameter: ActionTemplateParameterResource) => onChange([...parameters, parameter]);
    if (parameters.length === 0) {
        return <ParametersOnboarding onAdd={onAdd}/>;
    }
    return <ActionTemplateParameterList parameters={parameters} name="parameter" excludedControlTypes={excludedParameterControls} editPermission={parameterEditPermission} onParametersChanged={onChange} listActions={[]}/>;
}
interface AddBlueprintParameterButtonProps {
    onAdd: (parameter: ActionTemplateParameterResource) => void;
    type: ActionButtonType;
}
function AddBlueprintParameterButton({ onAdd, type }: AddBlueprintParameterButtonProps) {
    return <AddActionTemplateParameterButton onAdd={onAdd} permission={parameterEditPermission} name={"parameter"} type={type} excludedControls={excludedParameterControls}/>;
}
interface OpenReorderParametersButtonProps extends BlueprintParametersProps {
}
function OpenReorderParametersButton({ parameters, onChange }: OpenReorderParametersButtonProps) {
    return <SortActionTemplateParametersButton parameters={parameters} onChange={onChange} permission={parameterEditPermission} name={"parameter"}/>;
}
interface ParametersOnboardingProps {
    onAdd: (parameter: ActionTemplateParameterResource) => void;
}
function ParametersOnboarding({ onAdd }: ParametersOnboardingProps) {
    return (<OnboardingPage title="Add blueprint parameters" intro={<div>Parameters define variables that are set when the blueprint is included in a project deployment process.</div>} actionButtons={<AddBlueprintParameterButton onAdd={onAdd} type={ActionButtonType.Primary}/>} learnMore={null}/>);
}
const parameterEditPermission = {
    permission: Permission.ActionTemplateEdit,
    project: "*",
    environment: "*",
};
const excludedParameterControls = [ControlType.Sensitive];
export function buildBlueprintParametersActions(props: BlueprintParametersProps): PageAction[] {
    const { parameters, onChange } = props;
    const onAdd = (parameter: ActionTemplateParameterResource) => onChange([...parameters, parameter]);
    const reorderParametersAction: PageAction = {
        type: "custom",
        content: <OpenReorderParametersButton parameters={parameters} onChange={onChange}/>,
        key: "Reorder Parameters",
    };
    const addParameterAction: PageAction = {
        type: "custom",
        content: <AddBlueprintParameterButton onAdd={onAdd} type={ActionButtonType.Secondary}/>,
        key: "Add Parameter",
    };
    return [reorderParametersAction, addParameterAction];
}
