import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationUserOverview } from "../../CommonComponents/CommonConfigurationUserViews";
export const ConfigurationUserRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.user,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationUserOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
