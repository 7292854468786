import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectProcessOverview } from "../../CommonComponents/CommonProjectProcessViews";
export const ProjectRunbookProcessStepNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.process.stepNew,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectProcessOverview processType={ProcessType.Runbook}/>;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
