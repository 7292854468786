import { useEffect, useMemo } from "react";
import type { IsLoopStillRunning, Dependencies } from "../utils/RefreshLoop/refresh-loop";
import { RefreshLoop } from "../utils/RefreshLoop/refresh-loop";
export function useRefreshLoop(callback: (isLoopStillRunning: IsLoopStillRunning) => Promise<unknown>, refreshInterval: number | ((hidden: boolean) => number), config?: Dependencies) {
    const loop = useMemo(() => {
        const refreshIntervalFunc = typeof refreshInterval === "function" ? refreshInterval : (hidden: boolean) => (hidden ? refreshInterval * 12 : refreshInterval);
        return new RefreshLoop(async (isLoopStillRunning: IsLoopStillRunning) => {
            await callback(isLoopStillRunning);
        }, refreshIntervalFunc, config);
    }, [callback, refreshInterval, config]);
    useEffect(() => {
        loop.start();
        return () => {
            loop.stop();
        };
    }, [loop]);
    return loop;
}
