import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { sortBy } from "lodash";
import React from "react";
import type { SelectProps } from "~/primitiveComponents/form/Select/Select";
import Select from "~/primitiveComponents/form/Select/Select";
type SelectPropsWithoutItems = Omit<SelectProps, "items">;
interface EnvironmentSelectProps extends SelectPropsWithoutItems {
    fieldName?: string;
    environments: EnvironmentResource[];
}
const EnvironmentSelect = (props: EnvironmentSelectProps) => {
    const { environments, ...rest } = props;
    const sortedEnvironments = sortBy(environments, (x) => x.SortOrder).map((e) => ({ value: e.Id, text: e.Name }));
    return <Select sortItems={false} items={sortedEnvironments} {...rest}/>;
};
export default EnvironmentSelect;
