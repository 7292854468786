import { Snackbar } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import ProjectTemplatesInlineTabContent from "~/areas/projects/components/Variables/TenantVariables/ProjectTemplatesInlineTabContent";
import type { ProjectTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/useProjectTemplatesState";
import type { ProjectTenantVariableValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import type { TenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import type { TenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
interface ProjectTemplatesInlineContentProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    contentState: ProjectTemplatesState;
    filterData: TenantVariableFiltersData;
    variableResourceOptions: TenantVariableResourceOptions;
    variableValueTracker: ProjectTenantVariableValueChangeTracker;
}
export default function ProjectTemplatesInlineContent({ project, doBusyTask, contentState, filterData, variableResourceOptions, variableValueTracker }: ProjectTemplatesInlineContentProps) {
    const { data, pageSize, filterState, actions } = contentState;
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const onAddTemplateFromOnboarding = (text: string) => {
        setIsSnackbarOpen(true);
        setSnackbarText(text);
    };
    return (<>
            {data && (<ProjectTemplatesInlineTabContent project={project} doBusyTask={doBusyTask} filterState={filterState} filterData={filterData} setFilterState={actions.setFilter} pageSize={pageSize} setPageState={actions.setPageState} projectTemplatesWithValues={data} onAddTemplateFromOnboarding={onAddTemplateFromOnboarding} resourceOptions={variableResourceOptions} variableValueTracker={variableValueTracker}/>)}
            <Snackbar open={isSnackbarOpen} content={snackbarText} autoHideDuration={3500} onClose={() => setIsSnackbarOpen(false)} textAlign={"center"}/>
        </>);
}
