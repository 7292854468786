const getInfrastructureState = (state: GlobalState) => state.infrastructureArea;
const getAccountsState = (state: GlobalState) => getInfrastructureState(state).accounts;
const getMachinesState = (state: GlobalState) => getInfrastructureState(state).machines;
const getAccounts = (state: GlobalState) => getAccountsState(state).byId;
export const getAccountById = (state: GlobalState, props: {
    accountId: string;
}) => {
    return getAccounts(state)[props.accountId];
};
export const getCurrentMachine = (state: GlobalState) => getMachinesState(state).current;
