import * as React from "react";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration } from "../../../Registry/pageHelpRegistry";
export const InsightsReportOverviewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.insights.report.overview,
    renderOverview: (props) => (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    The Insights Report <b> Overview</b> page is where you can view charts for all four Insights metrics at once. To see more detail, select one of the four metrics links on the left.
                </p>
                <p>
                    The <b>Settings</b> link allows you to manage the project groups and environments groups used within this report, as well as the report logo, name, and description.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>),
    renderTopics: (props) => {
        return (<TopicsContainer>
                <LearnMoreResources>
                    <div>
                        <ExternalLink href="InsightsReports">Insights Reports</ExternalLink>
                    </div>
                </LearnMoreResources>
            </TopicsContainer>);
    },
};
