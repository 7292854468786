import { css, cx } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
type BorderedListItemProps = {
    onClick?: () => void;
};
function BorderedListItem({ children, onClick }: React.PropsWithChildren<BorderedListItemProps>) {
    return (<li onClick={onClick} className={cx(borderedListItemStyles, onClick ? borderedListItemHoverStyles : null)}>
            {children}
        </li>);
}
const borderedListItemStyles = css({
    listStyle: "none",
    border: "1px solid",
    borderColor: themeTokens.color.border.primary,
    borderRadius: "3px",
});
const borderedListItemHoverStyles = css({
    "&:hover": {
        backgroundColor: themeTokens.color.background.primary.hovered,
        cursor: "pointer",
    },
});
interface ListItemContentWithButtonsProps {
    buttons?: React.ReactNode;
    buttonAlignment?: "top" | "center";
    content: React.ReactNode;
}
export function ListItemContentWithButtons({ content, buttons, buttonAlignment = "center" }: ListItemContentWithButtonsProps) {
    const classes = listItemContentWithButtonsStyles;
    const buttonClasses = [classes.buttons, buttonAlignment === "center" ? classes.buttonsCenterAligned : classes.buttonsTopAligned];
    return (<div className={classes.root}>
            <div className={classes.content}>{content}</div>
            {buttons && <div className={cx(buttonClasses)}>{buttons}</div>}
        </div>);
}
const listItemContentWithButtonsStyles = {
    root: css({
        display: "flex",
        padding: space[16],
        gap: space[16],
    }),
    content: css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
    }),
    buttons: css({
        display: "flex",
    }),
    buttonsTopAligned: css({
        alignItems: "flex-start",
    }),
    buttonsCenterAligned: css({
        alignItems: "center",
    }),
};
export default BorderedListItem;
