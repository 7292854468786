import { min } from "lodash";
import * as React from "react";
import PagingButton from "~/areas/tenants/components/Paging/PagingButton";
import PagingSelection from "~/areas/tenants/components/Paging/PagingSelection";
import styles from "./style.module.less";
interface NumberedPagingBarProps {
    totalItems: number | null;
    pageNumber: number;
    pageSize: number;
    pageSizeOptions: number[];
    onPagingSelectionChange: (newPageNumber: number, newPageSize: number) => void;
}
type EllipsisPosition = "Left" | "Right";
export default function NumberedPagingBar({ totalItems, pageNumber, pageSize, pageSizeOptions, onPagingSelectionChange }: NumberedPagingBarProps) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (totalItems === null || totalItems < min(pageSizeOptions)!)
        return null;
    function createPagingButton(index: number) {
        return <PagingButton key={index} isActive={pageNumber === index} label={index.toLocaleString()} onPageSelected={() => onPageNumberChange(index)}/>;
    }
    function createEllipsis(position: EllipsisPosition) {
        return (<span key={position} className={styles.pagingEllipsis}>
                ...
            </span>);
    }
    function onPageSizeChange(newPageSize: number) {
        onPagingSelectionChange(1, newPageSize);
    }
    function onPageNumberChange(newPageNumber: number) {
        onPagingSelectionChange(newPageNumber, pageSize);
    }
    const totalPages = totalItems === 0 ? 1 : Math.ceil(totalItems / pageSize);
    const showLeftEllipsis = pageNumber > 4 && totalPages > 7;
    const showRightEllipsis = pageNumber <= totalPages - 4 && totalPages > 7;
    const pageButtonsToRender: JSX.Element[] = [];
    pageButtonsToRender.push(createPagingButton(1)); // Always show first
    if (showLeftEllipsis && showRightEllipsis) {
        pageButtonsToRender.push(createEllipsis("Left"));
        pageButtonsToRender.push(createPagingButton(pageNumber - 1));
        pageButtonsToRender.push(createPagingButton(pageNumber));
        pageButtonsToRender.push(createPagingButton(pageNumber + 1));
        pageButtonsToRender.push(createEllipsis("Right"));
    }
    else if (showLeftEllipsis) {
        pageButtonsToRender.push(createEllipsis("Left"));
        pageButtonsToRender.push(createPagingButton(totalPages - 4));
        pageButtonsToRender.push(createPagingButton(totalPages - 3));
        pageButtonsToRender.push(createPagingButton(totalPages - 2));
        pageButtonsToRender.push(createPagingButton(totalPages - 1));
    }
    else if (showRightEllipsis) {
        pageButtonsToRender.push(createPagingButton(2));
        pageButtonsToRender.push(createPagingButton(3));
        pageButtonsToRender.push(createPagingButton(4));
        pageButtonsToRender.push(createPagingButton(5));
        pageButtonsToRender.push(createEllipsis("Right"));
    }
    else {
        for (let i = 2; i < totalPages; i++) {
            pageButtonsToRender.push(createPagingButton(i));
        }
    }
    if (totalPages > 1) {
        pageButtonsToRender.push(createPagingButton(totalPages)); // Always show last if more than one page
    }
    return (<div className={styles.pagingBar}>
            {pageSizeOptions.length > 1 ? <PagingSelection pageSize={pageSize} onPageSizeChanged={onPageSizeChange} options={pageSizeOptions}/> : null}
            <PagingButton key={-1} isActive={false} icon={<em className="fa-solid fa-angle-left"/>} disabled={pageNumber === 1} onPageSelected={() => onPageNumberChange(pageNumber - 1)}/>
            {pageButtonsToRender}
            <PagingButton key={-2} isActive={false} icon={<em className="fa-solid fa-angle-right"/>} disabled={pageNumber === totalPages} onPageSelected={() => onPageNumberChange(pageNumber + 1)}/>
        </div>);
}
