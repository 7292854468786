import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ChannelGitResourceRuleResource, DeploymentActionResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { DeploymentActionGitDependencyDetails } from "./GitResourceRule";
import { getDeploymentActionGitDependenciesWithDetails } from "./getDeploymentActionGitDependenciesWithDetails";
import { getGitReferencePatternDescription } from "./getGitReferenceRulePatternsDescription";
type GitResourceRulesDescriptionProps = {
    rule: ChannelGitResourceRuleResource;
    actions?: DeploymentActionGitDependencyDetails[];
};
function GitResourceRuleDescription({ rule, actions }: GitResourceRulesDescriptionProps) {
    if (rule.GitDependencyActions.length === 0) {
        return null;
    }
    const gitReferenceRulesDescription = getGitReferencePatternDescription(rule.Rules);
    if (gitReferenceRulesDescription === undefined) {
        return null;
    }
    const gitResourceRuleAppliesTo = (<>
            Applies to{" "}
            <strong>
                {rule.GitDependencyActions.map((gda) => {
            const gitDependencyDetails = actions?.find((a) => a.DeploymentActionSlug === gda.DeploymentActionSlug && a.GitDependencyName === gda.GitDependencyName);
            if (!gitDependencyDetails)
                return gda.DeploymentActionSlug;
            return gitDependencyDetails.DeploymentActionName;
        }).join(", ")}
            </strong>{" "}
            {rule.GitDependencyActions.length > 1 ? "steps" : "step"}
        </>);
    return (<>
            {gitResourceRuleAppliesTo} with {gitReferenceRulesDescription}
        </>);
}
type GitResourceRulesDescriptionListProps = {
    rules: ChannelGitResourceRuleResource[];
    actions?: DeploymentActionResource[];
};
export function GitResourceRulesDescriptionList({ rules, actions }: GitResourceRulesDescriptionListProps) {
    const deploymentActionsWithGitDependencies = actions && getDeploymentActionGitDependenciesWithDetails(actions);
    const styles = {
        gitResourceRulesList: css({
            listStyleType: "decimal",
            paddingLeft: space[16],
        }),
    };
    return (<ol className={styles.gitResourceRulesList}>
            {rules.map((rule) => (<li key={rule.Id}>
                    <GitResourceRuleDescription key={rule.Id} rule={rule} actions={deploymentActionsWithGitDependencies}/>
                </li>))}
        </ol>);
}
