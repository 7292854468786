import { ActionButton, ActionButtonType, SimpleMenu, useMenuState } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
interface AddTriggerButtonProps {
    spaceId: string;
    projectSlug: string;
}
export function AddDeploymentTriggerButton({ spaceId, projectSlug }: AddTriggerButtonProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    return (<>
            <ActionButton type={ActionButtonType.Primary} icon={<DropDownIcon />} iconPosition="right" label="Add Trigger" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <SimpleMenu compact={true} menuState={menuState} items={[
            { type: "internal-link", label: "Deployment target trigger", path: links.createDeploymentTriggerPage.generateUrl({ spaceId, projectSlug }) },
            { type: "internal-link", label: "Scheduled trigger", path: links.createDeploymentScheduledTriggerPage.generateUrl({ spaceId, projectSlug }) },
        ]} accessibleName={"Add Trigger"}/>
        </>);
}
