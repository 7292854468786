import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import * as React from "react";
import OnboardingBlankPage from "~/components/ProjectBasedActivation/OnboardingBlankPage";
interface OnboardingPageProps {
    title: string;
    intro?: React.ReactNode | string;
    learnMore: React.ReactNode;
    image?: React.ReactElement;
    actionButtons?: React.ReactNode;
}
class OnboardingPage extends React.Component<OnboardingPageProps, never> {
    render() {
        return (<AnalyticLinkLocationProvider location="Onboarding Page">
                <OnboardingBlankPage title={this.props.title} description={this.props.intro} image={this.props.image} learnMore={this.props.learnMore} actionButtons={this.props.actionButtons}/>
            </AnalyticLinkLocationProvider>);
    }
    static displayName = "OnboardingPage";
}
export default OnboardingPage;
