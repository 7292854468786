import * as React from "react";
import { CommonProjectRunbookScheduledTriggersOverview } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonProjectRunbookScheduledTriggersView";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
export const ProjectRunbooksScheduledTriggersNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.scheduledTriggerNew,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookScheduledTriggersOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
};
