import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
interface AddFeedAnalyticsEvent extends AnalyticsEvent<"Add Feed">, AddFeedAnalyticsEventProperties {
}
interface AddFeedAnalyticsEventProperties {
    Location: "Drawer";
    "Feed Type": string;
    "Correlation Id"?: string;
}
export function createAddFeedAnalyticsEvent(feedType: string, correlationId: string | undefined): AddFeedAnalyticsEvent {
    return {
        eventName: "Add Feed",
        "Feed Type": feedType,
        "Correlation Id": correlationId,
        Location: "Drawer",
    };
}
interface SaveFeedAnalyticsEvent extends AnalyticsEvent<"Save Feed">, SaveFeedAnalyticsEventConfiguredProperties {
    "Entity Type": "New";
}
interface SaveFeedAnalyticsEventConfiguredProperties {
    Location: "Page" | "Drawer";
    "Feed Type"?: string;
    "Correlation Id"?: string;
}
export function createSaveFeedAnalyticsEvent(eventProperties: SaveFeedAnalyticsEventConfiguredProperties): SaveFeedAnalyticsEvent {
    return {
        eventName: "Save Feed",
        "Entity Type": "New",
        ...eventProperties,
    };
}
interface SaveAndTestFeedAnalyticsEvent extends AnalyticsEvent<"Save and Test Feed">, SaveAndTestFeedAnalyticsEventProperties {
}
interface SaveAndTestFeedAnalyticsEventProperties {
    "Entity Type": "New";
}
interface SaveAndTestFeedAnalyticsEventConfiguredProperties {
    Location: "Page" | "Drawer";
    "Feed Type"?: string;
    "Correlation Id"?: string;
}
export function createSaveAndTestFeedAnalyticsEvent(eventProperties: SaveAndTestFeedAnalyticsEventConfiguredProperties): SaveAndTestFeedAnalyticsEvent {
    return {
        eventName: "Save and Test Feed",
        "Entity Type": "New",
        ...eventProperties,
    };
}
