import type { GitBranchResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { AnalyticErrorCallback } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import { CommonEventFieldKeys } from "~/analytics/CommonEventFields";
import { useTrackedPageEventDispatcher } from "~/analytics/TrackedPageEventDispatcher";
import { createActionTypeField, createResourceTypeField, createStringField } from "~/analytics/fields";
export interface CreateBranchOptions {
    createBranchAction: (cb: AnalyticErrorCallback) => Promise<GitBranchResource>;
    source: string;
}
export type CreateBranchDispatcher = (options: CreateBranchOptions) => Promise<GitBranchResource>;
export function useNotifyCreateBranch(): CreateBranchDispatcher {
    const dispatcher = useTrackedPageEventDispatcher();
    return React.useCallback((options: CreateBranchOptions) => dispatcher("Create branch", {
        ...createActionTypeField(Action.Commit),
        ...createResourceTypeField("Branch"),
        ...createStringField(CommonEventFieldKeys.Source, options.source),
    }, options.createBranchAction), [dispatcher]);
}
