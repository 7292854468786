import { Tooltip } from "@octopusdeploy/design-system-components";
import type { EventResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import EventDetails from "~/components/EventDetails";
import EventFormatter from "~/components/EventFormatter";
import SimpleExpander from "~/components/SimpleExpander/SimpleExpander";
import Avatar from "~/primitiveComponents/dataDisplay/Avatar";
import DateFormatter from "~/utils/DateFormatter/index";
import styles from "./AuditListRow.module.less";
interface AuditListRowProps {
    event: EventResource;
    categoryName: string;
}
export default class AuditListRow extends React.PureComponent<AuditListRowProps> {
    render() {
        const { event, categoryName } = this.props;
        const usernameTooltipContent = (<div className={styles.tooltipContainer}>
                <div>Established with: {this.identityEstablishedWithTextForEvent(event)}</div>
                <div>User agent: {this.userAgentTextForEvent(event)}</div>
            </div>);
        const categoryTooltipContent = (<div className={styles.tooltipContainer}>
                <div>Category: {categoryName}</div>
            </div>);
        const title = (<div className={styles.rowContainer}>
                <div className={styles.whatContainer}>
                    <Tooltip content={categoryTooltipContent}>
                        <EventFormatter event={event}/>
                    </Tooltip>
                </div>
                <div className={styles.whenContainer}>
                    <span>{DateFormatter.dateToLongFormatWithSeconds(event.Occurred)}</span>
                </div>
                <div className={styles.whoContainer}>
                    <Avatar isService={event.IsService} avatarLink={event.Links && event.Links.Avatar}/>
                    <Tooltip content={usernameTooltipContent}>
                        <div className={styles.usernameText}>{event.Username}</div>
                    </Tooltip>
                </div>
            </div>);
        return (<SimpleExpander errorKey={event.Id} key={event.Id} title={title}>
                <div className={styles.detailsContainer}>
                    <div className={styles.keyValueGrid}>
                        <div className={styles.row}>
                            <div className={styles.key}>Established with:</div>
                            <div>{this.identityEstablishedWithTextForEvent(event)}</div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.key}>User agent:</div>
                            <div>{this.userAgentTextForEvent(event)}</div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.key}>Category:</div>
                            <div>{categoryName}</div>
                        </div>
                    </div>
                    <EventDetails eventId={event.Id} spaceId={event.SpaceId}/>
                </div>
            </SimpleExpander>);
    }
    private identityEstablishedWithTextForEvent(event: EventResource) {
        if (!event.IdentityEstablishedWith) {
            return "Unknown";
        }
        return event.IdentityEstablishedWith;
    }
    private userAgentTextForEvent(event: EventResource) {
        if (!event.UserAgent) {
            return "Unknown";
        }
        if (event.UserAgent.startsWith("OctopusClient-js")) {
            return "Portal";
        }
        return event.UserAgent;
    }
    static displayName = "AuditListRow";
}
