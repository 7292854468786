import { css } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
type StatusBarProps = {
    children: React.ReactNode;
};
export function StatusBar(props: StatusBarProps) {
    return <div className={styles}>{props.children}</div>;
}
const styles = css({
    backgroundColor: themeTokens.color.background.secondary.default,
    padding: `${space[8]} ${space[12]}`,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: space[24],
    userSelect: "none",
    overflowX: "auto",
});
