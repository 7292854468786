import React from "react";
import { useDispatch } from "react-redux";
import type { Dispatch } from "redux";
import { useContextualExpandableContainer } from "~/components/Expandable/Expandable";
import type { ExpanderActions } from "~/components/form/Sections/reducers/expanders";
import { expanderActions } from "~/components/form/Sections/reducers/expanders";
export function useExpandAllExpanders(containerKey?: string) {
    const containerKeyFromContext = useContextualExpandableContainer();
    const dispatch = useDispatch<Dispatch<ExpanderActions>>();
    const containerKeyToUse = containerKey ?? containerKeyFromContext;
    return React.useCallback((expand: boolean) => {
        dispatch(expanderActions.onToggleAll({ containerKey: containerKeyToUse, expanded: expand }));
    }, [containerKeyToUse, dispatch]);
}
