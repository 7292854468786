import { pageId } from "@octopusdeploy/portal-routes";
export default () => {
    return {
        root: pageId({
            area: "Library",
            id: "Library",
            name: "Library",
        }),
        feeds: pageId({ area: "Library", id: "Library.Feeds", name: "External Feeds" }),
        feedCreate: pageId({ area: "Library", id: "Library.FeedsNew", name: "Add New External Feed" }),
        feed: {
            root: pageId({ area: "Library", id: "Library.Feed", name: "External Feed" }),
            test: pageId({ area: "Library", id: "Library.FeedTest", name: "Test External Feed" }),
            redirect: pageId({ area: "Library", id: "Library.FeedRedirect", name: "Redirect External Feed" }),
        },
        stepTemplates: {
            root: pageId({ area: "Library", id: "Library.StepTemplates", name: "Step Templates" }),
            builtIn: pageId({ area: "Library", id: "Library.StepTemplatesBuiltIn", name: "Built-in Step Templates" }),
            community: pageId({ area: "Library", id: "Library.StepTemplatesCommunity", name: "Community Step Templates" }),
            installedStep: pageId({ area: "Library", id: "Library.StepTemplatesInstalledStep", name: "Step Templates Installation" }),
            new: pageId({ area: "Library", id: "Library.StepTemplateNew", name: "Add New Step Template" }),
            communityTemplate: {
                root: pageId({ area: "Library", id: "Library.StepTemplatesCommunityTemplate", name: "Community Step Template" }),
                details: pageId({ area: "Library", id: "Library.StepTemplatesCommunityTemplateDetails", name: "Community Step Template Details" }),
            },
        },
        stepTemplate: {
            root: pageId({ area: "Library", id: "Library.StepTemplate", name: "Step Template" }),
            usage: pageId({ area: "Library", id: "Library.StepTemplateUsage", name: "Step Template Usage" }),
            run: pageId({ area: "Library", id: "Library.StepTemplateRun", name: "Run Step Template" }),
        },
        lifecycles: pageId({ area: "Library", id: "Library.Lifecycles", name: "Lifecycles" }),
        lifecyclesCreate: pageId({ area: "Library", id: "Library.LifecyclesNew", name: "Add New Lifecycle" }),
        lifecycle: pageId({ area: "Library", id: "Library.Lifecycle", name: "Lifecycle" }),
        certificates: {
            root: pageId({ area: "Library", id: "Library.Certificates", name: "Certificates" }),
            archive: pageId({ area: "Library", id: "Library.CertificatesArchive", name: "Certificates Archive" }),
            create: pageId({ area: "Library", id: "Library.CertificatesNew", name: "Add New Certificate" }),
        },
        certificate: pageId({ area: "Library", id: "Library.Certificate", name: "Certificate" }),
        variableSets: {
            root: pageId({ area: "Library", id: "Library.VariableSets", name: "Variable Sets" }),
            create: pageId({ area: "Library", id: "Library.VariableSetsNew", name: "Add New Variable Set" }),
        },
        variableSet: pageId({ area: "Library", id: "Library.VariableSet", name: "Variable Set" }),
        tagSets: {
            root: pageId({ area: "Library", id: "Library.TenantTagSets", name: "Tenant Tag Sets" }),
            create: pageId({ area: "Library", id: "Library.TenantTagSetsNew", name: "Add New Tenant Tag Set" }),
        },
        tagSet: pageId({ area: "Library", id: "Library.TenantTagSet", name: "Tenant Tag Set" }),
        builtInRepository: {
            root: pageId({ area: "Library", id: "Library.BuiltInRepository", name: "Packages" }),
            package: pageId({ area: "Library", id: "Library.BuiltInRepositoryPackage", name: "Package Details" }),
            versions: pageId({ area: "Library", id: "Library.BuiltInRepositoryPackageVersions", name: "Package Versions" }),
        },
        buildInformationRepository: {
            root: pageId({ area: "Library", id: "Library.BuildInformationRepository", name: "Build Information" }),
            package: pageId({ area: "Library", id: "Library.BuildInformationRepositoryPackage", name: "Build Information Details" }),
            versions: pageId({ area: "Library", id: "Library.BuildInformationRepositoryPackageVersions", name: "Build Information Versions" }),
        },
        scriptModules: {
            root: pageId({ area: "Library", id: "Library.ScriptModules", name: "Script Modules" }),
            create: pageId({ area: "Library", id: "Library.ScriptModulesNew", name: "Add New Script Module" }),
        },
        scriptModule: pageId({ area: "Library", id: "Library.ScriptModule", name: "Script Module" }),
        gitCredentials: {
            root: pageId({ area: "Library", id: "Library.GitCredentials", name: "Git Credentials" }),
            create: pageId({ area: "Library", id: "Library.GitCredentialsNew", name: "Add New Git Credential" }),
        },
        gitCredential: {
            root: pageId({ area: "Library", id: "Library.GitCredential", name: "Git Credential" }),
            usage: pageId({ area: "Library", id: "Library.GitCredentialUsage", name: "Git Credential Usage" }),
        },
        gitConnections: {
            root: pageId({ area: "Library", id: "Library.GitConnections", name: "GitHub Connections" }),
            getGitHub: pageId({ area: "Library", id: "Library.GitConnectionsGetGitHub", name: "GitHub Connection" }),
            addGitHub: pageId({ area: "Library", id: "Library.GitConnectionsAddGitHub", name: "Add GitHub Connection" }),
        },
    };
};
