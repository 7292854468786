/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { Repository } from "@octopusdeploy/octopus-server-client";
import type { ReactElement, ReactNode } from "react";
import React, { Component } from "react";
import type { BusyState } from "~/components/BusyFromPromise/BusyFromPromise";
import styles from "./LoadMoreWrapper.module.less";
interface LoadMoreWrapperProps<T> {
    items: T[];
    initialTakeSize?: number;
    renderLoadMore: (children: ReactNode) => ReactElement<ReactNode>;
    renderItem: (item: T, index: number) => ReactElement<ReactNode>;
    useAnchorForLoadMore?: boolean;
    showLoadAll?: boolean;
    onLoadMore?: (takeSize: number) => Promise<number>;
    loadMoreDisabled?: BusyState | null;
    alwaysShowLoadMore?: boolean;
}
interface LoadMoreWrapperState {
    itemsTakeSize: number;
    loadedAll: boolean;
}
const LoadLink = (props: {
    onClick: () => void;
    label: string;
}) => (<div className={styles.loadMoreSubText}>
        <a href="#" onClick={(e) => {
        e.preventDefault();
        props.onClick();
    }}>
            {props.label}
        </a>
    </div>);
class LoadMoreWrapper<T> extends Component<LoadMoreWrapperProps<T>, LoadMoreWrapperState> {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static defaultProps: Partial<LoadMoreWrapperProps<any>> = {
        initialTakeSize: Repository.takeDefaultPageSize,
        showLoadAll: true,
        useAnchorForLoadMore: false,
        loadMoreDisabled: false,
        alwaysShowLoadMore: false,
    };
    constructor(props: LoadMoreWrapperProps<T>) {
        super(props);
        this.state = { itemsTakeSize: props.initialTakeSize!, loadedAll: false };
    }
    render() {
        const packagesToShow = this.props.items ? this.props.items.slice(0, this.state.itemsTakeSize) : [];
        return (<>
                {packagesToShow.map(this.props.renderItem)}
                {this.renderLoadMoreAction()}
            </>);
    }
    renderLoadMoreAction() {
        const numberOfItems = this.props.items && this.props.items.length;
        const showLoadMoreAction = numberOfItems > this.state.itemsTakeSize || (this.props.alwaysShowLoadMore && !this.state.loadedAll);
        const loadMoreLabel = "Load more";
        const loadAllLabel = "Load all";
        let loadMoreComponent: ReactElement | null = null;
        if (showLoadMoreAction) {
            loadMoreComponent = this.props.renderLoadMore(<div className={styles.loadMoreContainer}>
                    {this.props.useAnchorForLoadMore ? (<LoadLink onClick={this.onLoadMore} label={loadMoreLabel}/>) : (<ActionButton disabled={this.props.loadMoreDisabled} type={ActionButtonType.Secondary} label={loadMoreLabel} onClick={() => this.onLoadMore()}/>)}
                    {this.props.showLoadAll && <LoadLink onClick={this.onLoadAll} label={loadAllLabel}/>}
                </div>);
        }
        return loadMoreComponent;
    }
    private onLoadMore = async () => {
        const takeSize = this.state.itemsTakeSize + this.props.initialTakeSize!;
        if (this.props.onLoadMore) {
            const numLoaded = await this.props.onLoadMore(takeSize);
            if (numLoaded < this.state.itemsTakeSize)
                return;
        }
        this.setState({ itemsTakeSize: takeSize });
    };
    private onLoadAll = async () => {
        const takeSize = Repository.takeAll;
        await this.props.onLoadMore?.(takeSize);
        this.setState({ itemsTakeSize: takeSize, loadedAll: true });
    };
}
export default LoadMoreWrapper;
