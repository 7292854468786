/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { VariablePromptOptions, ScopeValues, ScopeSpecificationTypes } from "@octopusdeploy/octopus-server-client";
import { VariableType } from "@octopusdeploy/octopus-server-client";
import { flatten, zip, compact, isEqual } from "lodash";
import type { ReadonlyArrays } from "~/areas/variables/ReadonlyVariableResource";
import { convertToFilterableValue } from "~/areas/variables/ReadonlyVariableResource";
import type { FilterableValue, VariableFilter } from "~/areas/variables/VariableFilter/VariableFilter";
import { filterVariableGroups, matchesFilter } from "~/areas/variables/VariableFilter/VariableFilter";
import type { AllVariableMessages, VariableMessages } from "~/areas/variables/VariableMessages/VariableMessages";
import { compareScopes, compareValues } from "~/areas/variables/VariableSorting/sortVariables";
import IdHelper from "~/utils/IdHelper";
import NameDuplicationHelper from "~/utils/NameDuplicationHelper/NameDuplicationHelper";
export class VariableValueModel {
    readonly Id: string;
    readonly Value: string | null;
    readonly Description: string | undefined;
    readonly Scope: Readonly<ReadonlyArrays<ScopeSpecificationTypes>>;
    readonly IsEditable: boolean;
    readonly Prompt: VariablePromptOptions | null;
    readonly Type: VariableType;
    readonly IsSensitive: boolean;
    constructor(newVariableType?: VariableType) {
        this.Id = IdHelper.newId();
        this.Value = null;
        this.Description = null!;
        this.Scope = {};
        this.IsEditable = true;
        this.Prompt = null;
        this.Type = newVariableType ? newVariableType : VariableType.String;
        this.IsSensitive = newVariableType === VariableType.Sensitive;
    }
}
export class VariablesModel {
    constructor(readonly variables: ReadonlyArray<VariableModel>) { }
    sort(availableScopes: ScopeValues): VariablesModel {
        return this.updateSelf(this.variables.map((v) => new VariableModel({ name: v.name, values: [...v.values].sort(compareVariables) })).sort(compareVariablesByName));
        function compareVariablesByName(l: VariableModel, r: VariableModel): number {
            return l.name.toLowerCase().localeCompare(r.name.toLowerCase());
        }
        function compareVariables(l: VariableValueModel, r: VariableValueModel) {
            return compareScopes(l.Scope, r.Scope, availableScopes) || compareValues(convertToFilterableValue(l), convertToFilterableValue(r));
        }
    }
    filterVariables(filter: VariableFilter, messages: AllVariableMessages, availableScopes: ScopeValues, createFilterableVariable: (value: VariableValueModel) => FilterableValue): ReadonlyArray<FilteredVariableModel> {
        const variablesWithMessages = zip<VariableModel | VariableMessages>(this.variables, messages.variableMessages).map((z) => {
            return {
                originalVariable: z[0] as VariableModel,
                messages: z[1] as VariableMessages,
            };
        });
        const filteredGroups = filterVariableGroups(variablesWithMessages, messages, filter, (g) => g.originalVariable.name.toLowerCase());
        return compact(filteredGroups.map((g, groupIndex) => {
            if (!g.matchesFilter) {
                return null;
            }
            const filteredValues = g.group.originalVariable.values.filter((v, variableIndex) => matchesFilter(createFilterableVariable(v), g.group.messages, g.group.messages.valuesMessages[variableIndex], filter));
            if (filteredValues.length) {
                return new FilteredVariableModel(groupIndex, g.group.originalVariable.name, filteredValues);
            }
            return null;
        }));
    }
    updateValue(value: VariableValueModel): VariablesModel {
        return this.updateSelf(this.variables.map((variable) => ({ ...variable, values: variable.values.map((v) => (v.Id === value.Id ? value : v)) })));
    }
    updateValueAndName(value: VariableValueModel, name: string): VariablesModel {
        const oldVariable = this.variables.find((variable) => variable.values.some((v) => v.Id === value.Id));
        const newVariable = new VariableModel({ name, values: oldVariable!.values.map((v) => (v.Id === value.Id ? value : v)) });
        return this.updateSelf(this.variables.map((variable) => (isEqual(variable, oldVariable) ? newVariable : variable)));
    }
    addVariable(variable: VariableModel): VariablesModel {
        const newVariable = new VariableModel(variable);
        return this.updateSelf([newVariable, ...this.variables]);
    }
    duplicateVariable(variableForDuplication: VariableModel, canDuplicateValue: (value: VariableValueModel) => boolean): VariablesModel {
        return this.updateSelf(flatten(this.variables.map((variable) => {
            if (variable === variableForDuplication) {
                const allNames = this.variables.map((v) => v.name.toLowerCase());
                const newName = NameDuplicationHelper.nameTheCopyWithoutCollision(variableForDuplication.name, allNames);
                const valuesForNewVariable = variable.values.filter(canDuplicateValue).map((v) => duplicateValue(v));
                return [variable, new VariableModel({ name: newName, values: valuesForNewVariable })];
            }
            return [variable];
        })));
    }
    duplicate(value: VariableValueModel): VariablesModel {
        const valueDuplicate = duplicateValue(value);
        return this.updateSelf(this.variables.map((variable) => {
            if (variable.values.some((v) => v.Id === value.Id)) {
                const newValues = flatten(variable.values.map((v) => {
                    return v.Id === value.Id ? [v, valueDuplicate] : [v];
                }));
                return new VariableModel({ name: variable.name, values: newValues });
            }
            return variable;
        }));
    }
    addValueToVariable(variableToAddTo: VariableModel, newVariableType: VariableType): VariablesModel {
        return this.updateSelf(this.variables.map((variable) => {
            if (variable === variableToAddTo) {
                return new VariableModel({ name: variable.name, values: [...variable.values, createDefaultVariable(newVariableType)] });
            }
            return variable;
        }));
    }
    resetChanges(originalValue: VariableValueModel): VariablesModel {
        return this.updateSelf(this.variables.map((variable) => {
            if (variable.values.some((v) => v.Id === originalValue.Id)) {
                if (variable.values.length === 1) {
                    // If you have a value by itself, reset everything
                    return new VariableModel({ name: variable.name, values: [originalValue] });
                }
                // Otherwise, don't reset the name
                return new VariableModel({
                    name: variable.name,
                    values: variable.values.map((v) => {
                        return v.Id === originalValue.Id ? originalValue : v;
                    }),
                });
            }
            return variable;
        }));
    }
    merge(variableToMerge: VariableModel): VariablesModel {
        const allValuesWithSameName = flatten(this.variables.filter((v) => v.name.toLowerCase() === variableToMerge.name.toLowerCase()).map((v) => [...v.values]));
        return this.updateSelf(flatten(this.variables.map((variable) => {
            if (variable === variableToMerge) {
                return [new VariableModel({ name: variableToMerge.name, values: allValuesWithSameName })];
            }
            if (variable.name.toLowerCase() === variableToMerge.name.toLowerCase()) {
                return [];
            }
            return [variable];
        })));
    }
    automaticRenameToAvoidCollision(variable: VariableModel): VariablesModel {
        const newName = NameDuplicationHelper.nameTheCopyWithoutCollision(variable.name, this.variables.map((g) => g.name.toLowerCase()));
        return this.rename(variable, newName);
    }
    rename(variable: VariableModel, newName: string): VariablesModel {
        return this.updateSelf(this.variables.map((v) => (v === variable ? new VariableModel({ name: newName, values: v.values }) : v)));
    }
    delete(value: VariableValueModel): VariablesModel {
        return this.updateSelf(flatten(this.variables.map((variable) => {
            if (variable.values.some((v) => v.Id === value.Id)) {
                const reducedValues = variable.values.filter((v) => v.Id !== value.Id);
                return reducedValues.length === 0 ? [] : [new VariableModel({ name: variable.name, values: reducedValues })];
            }
            return [variable];
        })));
    }
    private updateSelf(newVariables: ReadonlyArray<VariableModel>): VariablesModel {
        return new VariablesModel(newVariables);
    }
}
export class VariableModel {
    readonly values: ReadonlyArray<VariableValueModel>;
    readonly name: string;
    constructor(variable: VariableModel) {
        if (!variable.values || variable.values.length < 1) {
            throw new Error("A variable must have at least 1 value");
        }
        this.values = variable.values;
        this.name = variable.name;
    }
}
export class FilteredVariableModel extends VariableModel {
    constructor(readonly originalIndex: number, name: string, filteredValues: VariableValueModel[]) {
        super({ name, values: filteredValues });
    }
}
function createDefaultVariable(variableType?: VariableType): VariableValueModel {
    return {
        Id: IdHelper.newId(),
        IsSensitive: variableType === VariableType.Sensitive,
        Value: "",
        Prompt: null,
        Scope: {},
        Description: "",
        Type: variableType ? variableType : VariableType.String,
        IsEditable: true,
    };
}
function duplicateValue(value: VariableValueModel): VariableValueModel {
    return {
        ...value,
        Id: IdHelper.newId(),
        Value: value.IsSensitive ? null : value.Value,
    };
}
