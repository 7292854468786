import { Callout } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { environmentChipList } from "~/components/Chips";
import { DataTable, DataTableHeader, DataTableRow, DataTableHeaderColumn, DataTableBody, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import styles from "./style.module.less";
interface DeploymentFreezesProps {
    activeFreezes: {
        Name: string;
        Environments: string[];
    }[];
    allEnvironments: EnvironmentResource[];
}
export function DeploymentFreezes({ activeFreezes, allEnvironments }: DeploymentFreezesProps) {
    return (<>
            {activeFreezes.length > 0 && (<Callout title="We found frozen environments in your selection" type={"information"}>
                    The following environments will be affected by a deployment freeze during your deployment:
                    <div className={styles.freezeTableContainer}>
                        <DataTable className={styles.freezeTable}>
                            <DataTableHeader>
                                <DataTableRow className={styles.freezeTableHeaderRow}>
                                    <DataTableHeaderColumn className={styles.freezeTableCell}>Deployment Freeze</DataTableHeaderColumn>
                                    <DataTableHeaderColumn className={styles.freezeTableCell}>Environments</DataTableHeaderColumn>
                                </DataTableRow>
                            </DataTableHeader>
                            <DataTableBody>
                                {activeFreezes.map((f, ix) => (<DataTableRow key={f.Name + ix} displayNoBorder>
                                        <DataTableRowColumn key={f.Name + ix} className={styles.freezeTableCell}>
                                            <div className={styles.freezeTableText}>{f.Name}</div>
                                        </DataTableRowColumn>
                                        <DataTableRowColumn key={f.Name + ix + "Environments"} className={styles.freezeTableCell}>
                                            {environmentChipList(allEnvironments, f.Environments)}
                                        </DataTableRowColumn>
                                    </DataTableRow>))}
                            </DataTableBody>
                        </DataTable>
                    </div>
                    Please remove these environments from the deployment or modify the scheduled start time.
                </Callout>)}
        </>);
}
