import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
const signIconStyles = css({
    backgroundColor: themeTokens.color.icon.feature,
    width: "50px",
    height: "50px",
    padding: "15px",
    borderRadius: "25px",
});
export function WelcomeDialogSignIcon() {
    return (<div className={signIconStyles}>
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.71666 1.8125H2.29998C1.59013 1.8125 1.01664 2.39902 1.01664 3.125V5.75C1.01664 6.47598 1.59013 7.0625 2.29998 7.0625H17.4353C17.6038 7.0625 17.7682 6.99277 17.8885 6.86973L19.8135 4.90098C20.0622 4.64668 20.0622 4.22832 19.8135 3.97402L17.8885 2.00527C17.7682 1.88223 17.6038 1.8125 17.4353 1.8125H11.2833C11.2833 1.08652 10.7098 0.5 10 0.5C9.29015 0.5 8.71666 1.08652 8.71666 1.8125ZM18.9834 11C18.9834 10.274 18.4099 9.6875 17.7 9.6875H11.2833V8.375H8.71666V9.6875H2.56467C2.39623 9.6875 2.2318 9.75723 2.11149 9.88027L0.186485 11.849C-0.0621616 12.1033 -0.0621616 12.5217 0.186485 12.776L2.11149 14.7447C2.2318 14.8678 2.39623 14.9375 2.56467 14.9375H17.7C18.4099 14.9375 18.9834 14.351 18.9834 13.625V11ZM11.2833 20.1875V16.25H8.71666V20.1875C8.71666 20.9135 9.29015 21.5 10 21.5C10.7098 21.5 11.2833 20.9135 11.2833 20.1875Z" fill="white"/>
            </svg>
        </div>);
}
