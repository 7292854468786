import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
interface AddCertificateAnalyticsEvent extends AnalyticsEvent<"Add Certificate"> {
    Location: AddCertificateLocation;
}
export function createAddCertificateAnalyticsEvent(location: "Drawer" | "Page"): AddCertificateAnalyticsEvent {
    return {
        eventName: "Add Certificate",
        Location: location,
    };
}
type AddCertificateLocation = "Drawer" | "Page";
interface SaveCertificateAnalyticsEvent extends AnalyticsEvent<"Save Certificate"> {
    Location: "Drawer";
    "Entity Type": "New";
    Status: "Success";
}
export function createSaveCertificateAnalyticsEvent(): SaveCertificateAnalyticsEvent {
    return {
        eventName: "Save Certificate",
        Status: "Success",
        "Entity Type": "New",
        Location: "Drawer",
    };
}
