import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import styles from "../../../primitiveComponents/dataDisplay/SvgImage/styles.module.less";
const ShipmentError = (props: Partial<SvgImageProps>) => (<SvgImage viewBox="0 0 178 148" title="Package error" {...props}>
        <g id="package" transform="translate(1 2)">
            <path d="M152.9 13.79L129.23 2.25A26.51 26.51 0 0 0 119.48 0H82.924L96.04 14.24h56.75a.239.239 0 0 0 .234-.246.242.242 0 0 0-.124-.204M36 0a26.51 26.51 0 0 0-9.75 2.25L2.47 13.83a.21.21 0 0 0 .09.41h56.98L72.516 0H36zm61 21.11v38.11H58.24V21.11H0v112.94a3.44 3.44 0 0 0 3.44 3.44h97.004a36.059 36.059 0 0 1-5.456-7.248c-10.339-17.68-4.356-40.512 13.353-50.868a37.247 37.247 0 0 1 18.762-5.088c6.635-.002 13.168 1.725 18.881 5.108a35.237 35.237 0 0 1 9.206 7.558v-65.84H97z" className={styles.imgDark}/>
            <path d="M154.284 95.508a29.747 29.747 0 0 0-11.163-11.175 31.192 31.192 0 0 0-15.992-4.338l-.017.001a31.504 31.504 0 0 0-15.888 4.307c-14.991 8.766-20.056 28.094-11.29 43.086a30.35 30.35 0 0 0 11.186 11.27c9.866 5.752 22.115 5.753 31.973.004a29.74 29.74 0 0 0 11.175-11.163c5.763-9.886 5.763-22.134.016-31.992z" className={styles.imgDanger}/>
            <polygon points="122.51 116.08 131.71 116.08 131.71 91.09 122.51 91.09" className={styles.imgWhite} mask="url(#mask-2)"/>
            <polygon points="122.51 131.87 131.71 131.87 131.71 122.48 122.51 122.48" className={styles.imgWhite} mask="url(#mask-2)"/>
        </g>
    </SvgImage>);
export { ShipmentError };
