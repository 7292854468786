import * as React from "react";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import styles from "./style.module.less";
interface ListItemProps {
    secondaryAction?: React.ReactElement;
    overflowMenuItems?: Array<MenuItem | MenuItem[]>;
}
const ListItem: React.StatelessComponent<React.PropsWithChildren<ListItemProps>> = (props) => {
    return (<span className={styles.menuItem}>
            <span className={styles.content}>{props.children}</span>
            {props.secondaryAction && <span>{props.secondaryAction}</span>}
            {props.overflowMenuItems && (<span>
                    <OverflowMenu menuItems={props.overflowMenuItems}/>
                </span>)}
        </span>);
};
ListItem.displayName = "ListItem"
ListItem.displayName = "ListItem";
export default ListItem;
