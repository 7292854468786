/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { nameForLink, resolveStringPathWithSpaceId, useDispatchLinkClicked } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { NavLink, useParams } from "react-router-dom";
import type { LinkEvent } from "~/analytics/Analytics";
import { useUrlResolver } from "~/components/Navigation/useUrlResolver";
import { useIsPortalUrlActive } from "../useIsPortalUrlActive";
interface InternalNavLinkProps extends React.HTMLProps<HTMLElement> {
    className?: string;
    activeClassName?: string;
    to: LinkHref;
    exact?: boolean;
}
const InternalNavLink = (props: InternalNavLinkProps) => {
    const params = useParams<{
        spaceId?: string;
    }>();
    const urlResolver = useUrlResolver();
    const resolvedTo = typeof props.to === "string" ? resolveStringPathWithSpaceId(props.to, params.spaceId) : urlResolver.resolve(props.to);
    const dispatchLink = useDispatchLinkClicked();
    const isUrlActive = useIsPortalUrlActive();
    /* eslint-disable react/forbid-elements */
    return (<NavLink className={props.className} key={resolvedTo} to={resolvedTo} exact={props.exact} isActive={() => isUrlActive(props.to, props.exact)} activeClassName={props.activeClassName} onClick={(e) => {
            const ev: LinkEvent = {
                linkLabel: e.currentTarget.innerText ?? "",
                documentUrl: undefined,
            };
            dispatchLink(nameForLink(), ev);
        }}>
            {props.children}
        </NavLink>);
};
export default InternalNavLink;
