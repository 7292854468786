import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonInfrastructureProxyOverview } from "../../CommonComponents/CommonInfrastructureProxyViews";
export const InfrastructureProxiesNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.proxies.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureProxyOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
