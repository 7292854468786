import type { DynamicEnvironmentOverviewProjectState } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DynamicEnvironmentProjectStateIcon } from "./DynamicEnvironmentProjectStateIcon";
import { getDynamicEnvironmentProjectStateDescription } from "./getDynamicEnvironmentProjectStateDescription";
type DynamicEnvironmentProjectStateIconWithDescriptionProps = {
    state: DynamicEnvironmentOverviewProjectState;
};
export function DynamicEnvironmentProjectStateIconWithDescription({ state }: DynamicEnvironmentProjectStateIconWithDescriptionProps) {
    return (<>
            <DynamicEnvironmentProjectStateIcon state={state}/>
            <span>{getDynamicEnvironmentProjectStateDescription(state)}</span>
        </>);
}
