import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import type { ActionProperties } from "@octopusdeploy/octopus-server-client";
import React from "react";
import MaxParallelism from "./MaxParallelism";
interface MaxParallelismOptionsProps {
    projectId: string;
    maxParallelism: string;
    setStepProperties(properties: Partial<ActionProperties>): void;
}
export const MaxParallelismOptions: React.FC<MaxParallelismOptionsProps> = ({ projectId, maxParallelism, setStepProperties }) => {
    const showWindowSize = React.useMemo(() => !!maxParallelism && maxParallelism.length > 0, [maxParallelism]);
    return (<>
            <BooleanRadioButtonGroup onChange={(v) => {
            setStepProperties({ ["Octopus.Action.MaxParallelism"]: v ? "1" : "" });
        }} value={showWindowSize}>
                <BooleanRadioButton value={false} label="Deploy to all deployment targets in parallel."/>
                <BooleanRadioButton value={true} label="Configure a rolling deployment"/>
            </BooleanRadioButtonGroup>
            {showWindowSize && <MaxParallelism projectId={projectId} value={maxParallelism} onChange={(x) => setStepProperties({ ["Octopus.Action.MaxParallelism"]: x })}/>}
        </>);
};
MaxParallelismOptions.displayName = "MaxParallelismOptions"
