import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { borderRadius, colorScales, space, text, textScales, themeTokens } from "@octopusdeploy/design-system-tokens";
import { MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { useParams } from "react-router-dom";
import { DiscoveryProgress } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/DiscoveryProgress";
import { EnvironmentChip, RoleChip } from "../Chips";
import { KubernetesAgent } from "../Images/MachineSettings/KubernetesAgent";
import { useSpaceAwareNavigation } from "../Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
type AddKubernetesAgentCardProps = {
    onClick: () => void;
};
type ConnectedKubernetesAgentCardProps = {
    name: string;
    environments?: string[];
    targetTags?: string[];
    healthStatus: MachineModelHealthStatus;
    id: string;
    close: () => void;
    forceNotFound?: boolean;
    bearerTokenExpired?: boolean;
};
export const AddKubernetesAgentCard = ({ onClick }: AddKubernetesAgentCardProps) => {
    return (<div className={k8AgentCardStyles.cardBase}>
            <div className={k8AgentCardStyles.k8AgentIconContainer}>
                <KubernetesAgent />
            </div>
            <div className={k8AgentCardStyles.title}>Kubernetes Agent</div>
            <div className={k8AgentCardStyles.addAgentDescription}>Install an agent to execute deployments on your cluster. No extra workers needed.</div>
            <div className={k8AgentCardStyles.addAgentButtonContainer}>
                <Button label={"Add Agent"} onClick={onClick} importance={"secondary"}/>
            </div>
        </div>);
};
export const ConnectedKubernetesAgentCard = ({ environments, targetTags, name, healthStatus, id, close, forceNotFound = false, bearerTokenExpired = false }: ConnectedKubernetesAgentCardProps) => {
    const { Unhealthy, Unavailable, Unknown } = MachineModelHealthStatus;
    const isKubernetesAgentError = healthStatus === Unhealthy || healthStatus === Unavailable;
    const { spaceId } = useParams<{
        spaceId: string | undefined;
    }>();
    const navigate = useSpaceAwareNavigation();
    const url = links.deploymentTargetSettingsPage.generateUrl({ spaceId: spaceId, machineId: id });
    const onClick = () => {
        close();
        navigate.navigate(url);
    };
    return (<div className={k8AgentCardStyles.cardBase}>
            <div className={k8AgentCardStyles.k8AgentIconContainer}>
                <KubernetesAgent />
            </div>

            <div>
                <div className={k8AgentCardStyles.title}>{name}</div>
                <div className={k8AgentCardStyles.subtitle}>Kubernetes Agent</div>
            </div>

            <div className={k8AgentCardStyles.chipContainer}>
                {environments?.map((environment, index) => (<EnvironmentChip key={`${index}-${environment}`} environmentName={environment}/>))}
                {targetTags?.map((tag, index) => (<RoleChip key={`${index}-${tag}`} role={tag} description={`Target Tag: ${tag}`}/>))}
            </div>

            <div className={k8AgentCardStyles.discoveryProgressContainer}>
                <DiscoveryProgress name={name} showHelpLink={false} isFound={!forceNotFound && healthStatus === Unknown} healthStatus={healthStatus} bearerTokenExpired={bearerTokenExpired}/>

                {isKubernetesAgentError && <Button label={"View Deployment Target"} onClick={onClick} importance={"secondary"}/>}
            </div>
        </div>);
};
export const k8AgentCardStyles = {
    cardBase: css({
        width: "348px",
        padding: space[16],
        borderRadius: borderRadius.small,
        boxShadow: themeTokens.shadow.small,
        backgroundColor: themeTokens.color.card.background.default,
        display: "flex",
        flexDirection: "column",
        gap: space[8],
    }),
    k8AgentIconContainer: css({
        width: "40px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        paddingLeft: space[4],
        backgroundColor: colorScales.white,
        borderRadius: borderRadius["small"],
        "&& > div": {
            height: "32px",
        },
    }),
    title: css({ font: textScales.body.base.normal[700] }),
    addAgentDescription: css({
        font: text.interface.body.default.small,
        letterSpacing: "0.1px",
        color: themeTokens.color.text.secondary,
    }),
    subtitle: css({
        font: textScales.body.xSmall.normal[700],
        color: themeTokens.color.text.tertiary,
    }),
    addAgentButtonContainer: css({
        paddingTop: "12px",
        "&& > button": {
            padding: space[6],
            font: textScales.body.medium.normal[700],
        },
    }),
    chipContainer: css({
        gap: space[8],
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        "&& > div": {
            margin: "0",
        },
    }),
    discoveryProgressContainer: css({
        marginTop: space[8],
        "& button": {
            marginTop: space[16],
            padding: `${space[8]} ${space[6]}`,
        },
    }),
};
