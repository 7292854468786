import { usePageEventDispatcher } from "@octopusdeploy/portal-routes";
import React from "react";
import { Action } from "~/analytics/Analytics";
import { createActionTypeField, createBoolField, createResourceTypeField } from "~/analytics/fields";
import { GitEventFieldKeys } from "~/areas/projects/components/VersionControl/Analytics/GitEventFields";
export interface CancelConfigureGitOptions {
    hasInitialCommitBranch: boolean;
}
export function useNotifyCancelConfigureGit() {
    const dispatcher = usePageEventDispatcher();
    return React.useCallback((options: CancelConfigureGitOptions) => 
    // Even though we use 'Git' rather than 'VCS' throughout the application,
    // these events should retain their existing name so that usages in Amplitude
    // (graphs/reports etc.) keep working as expected.
    dispatcher("Cancel Configuring VCS", {
        ...createResourceTypeField("Version Control Settings"),
        ...createActionTypeField(Action.Cancel),
        ...createBoolField(GitEventFieldKeys.InitialCommitBranch, options.hasInitialCommitBranch),
    }), [dispatcher]);
}
