import * as React from "react";
import styles from "./style.module.less";
interface ColorGroupProps {
    heading: string;
}
class ColorGroup extends React.Component<ColorGroupProps> {
    constructor(props: ColorGroupProps) {
        super(props);
    }
    render() {
        return (<div className={styles.colors}>
                <h4>{this.props.heading}</h4>
                {this.props.children}
            </div>);
    }
    static displayName = "ColorGroup";
}
export default ColorGroup;
