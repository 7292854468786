import * as React from "react";
import type { RunGoal } from "../RunbookRunNowLayout";
import { RunbookRunNowLayout } from "../RunbookRunNowLayout";
interface CreateRunbookRunPageProps {
    goal: RunGoal;
    runbookSnapshotId: string;
}
export function CreateRunbookRunForSnapshotPage({ goal, runbookSnapshotId }: CreateRunbookRunPageProps) {
    return (<>
            <RunbookRunNowLayout runbookSnapshotId={runbookSnapshotId} goal={goal}/>
        </>);
}
