import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectTriggerReleaseViews } from "../../CommonComponents/CommonProjectTriggerReleaseViews";
export const ProjectTriggerReleaseNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().releaseTriggerNew,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectTriggerReleaseViews create={true}/>;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
