import * as React from "react";
import Targets from "./Targets";
interface TargetListProps<TTarget> {
    targets: TTarget[];
    renderTarget: (target: TTarget) => React.ReactNode;
}
export abstract class TargetList<TTarget> extends React.Component<TargetListProps<TTarget>, never> {
    render() {
        return <Targets targets={this.props.targets.map((target) => this.props.renderTarget(target))}/>;
    }
    static displayName = "TargetList";
}
export default TargetList;
