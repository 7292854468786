/* # Background

## Module systems
At the time of writing, typescript is configured to transform files to CommonJS modules.
This means that `import` statements get replaced with `require`.
This is not a supported way of importing files natively in the browser,
but works for us because webpack knows how to handle CommonJS modules and processes these files
to replaces the "require" calls with its own function (__webpack_require).

## Webpack imports
At compile time, webpack parses all sources files to discover the import/require statements.
It then builds up a map of each import expression to its source file. In order to do this, it needs to statically
know the location where each import expression points to.

For this reason, webpack struggles with import expressions that are dynamic and depend on runtime values. For the docs:

    > It is not possible to use a fully dynamic import statement, such as import(foo).
    > Because foo could potentially be any path to any file in your system or project.
        Source: https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import

# The problem
If we try to import a module from a URL directly, webpack will fail to compile with:
    > Critical dependency: the request of a dependency is an expression.

At runtime, even if this file were to somehow use the __webpack_require function, it would fail to require the module since the
expression passed to this function would not exist in the collection of statically known webpack imports.

# The solution
By moving the dynamic loading out to its own file, we can use the `noParse` option to tell webpack not to parse this file.
This means that webpack won't interpret the import/require statements and replace them with __webpack_require

However, this would still fail because the file would be a plain CommonJS module using the `require` function to load
its dependencies, which does not exist natively in the browser.

To get around this problem, we opt out of using typescript for this file by using a plain JS file. This allows us to
use import directly, and this won't be transformed in any way.

We also need to assign state to the `exports` object directly here instead of using the `export` keyword. This is
because this file still gets included in a bundle generated by webpack, where webpack provides the exports object
to which this file can assign values.
*/
/**
 * @param {string} url - The url to import from
 * @return {Promise<unknown>} The import result as a promise
 */
exports.importFromDynamicExpression = (url) => import(url);
