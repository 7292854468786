import { CustomMenu, useMenuState, MenuItemButton, ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { AuditStreamConfigurationResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import { AuditActionWithStatus } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditActionWithStatus";
import { DeleteAuditStreamDialog } from "~/areas/configuration/components/AuditLayout/AuditStream/DeleteAuditStreamDialog";
import { EditAuditStreamDialog } from "~/areas/configuration/components/AuditLayout/AuditStream/EditAuditStreamDialog";
import { PauseAuditStreamDialog } from "~/areas/configuration/components/AuditLayout/AuditStream/PauseAuditStreamDialog";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
interface AuditStreamConfiguredButtonProps {
    auditStreamConfiguration: AuditStreamConfigurationResource;
    onUpdate: (value: AuditStreamConfigurationResource, message: string) => void;
}
export function AuditStreamConfiguredButton({ auditStreamConfiguration, onUpdate }: AuditStreamConfiguredButtonProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const [openEdit, setOpenEdit] = useState(false);
    const [openPause, setOpenPause] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const pauseOrResume = auditStreamConfiguration.Active ? "Pause" : "Resume";
    const onEditClick = () => {
        setOpenEdit(true);
        menuState.onClose();
    };
    const onPauseClick = () => {
        setOpenPause(true);
        menuState.onClose();
    };
    const onDeleteClick = () => {
        setOpenDelete(true);
        menuState.onClose();
    };
    return (<AuditActionWithStatus status={auditStreamConfiguration.Active ? "active" : "paused"}>
            <ActionButton type={ActionButtonType.Ternary} icon={<DropDownIcon />} iconPosition="right" label="Stream audit log" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <CustomMenu accessibleName={"Configure audit stream menu"} menuState={menuState} onClose={menuState.onClose}>
                <MenuItemButton key={"edit"} onClick={onEditClick}>
                    <div style={{ minWidth: "7.5rem" }}>Edit</div>
                </MenuItemButton>
                <MenuItemButton key={"pause"} onClick={onPauseClick}>
                    <div>{pauseOrResume}</div>
                </MenuItemButton>
                <MenuItemButton key={"delete"} onClick={onDeleteClick}>
                    <div>Delete</div>
                </MenuItemButton>
            </CustomMenu>
            <EditAuditStreamDialog auditStreamConfiguration={auditStreamConfiguration} onUpdate={onUpdate} open={openEdit} close={() => setOpenEdit(false)}/>
            <PauseAuditStreamDialog auditStreamConfiguration={auditStreamConfiguration} onUpdate={onUpdate} open={openPause} close={() => setOpenPause(false)}/>
            <DeleteAuditStreamDialog auditStreamConfiguration={auditStreamConfiguration} onUpdate={onUpdate} open={openDelete} close={() => setOpenDelete(false)}/>
        </AuditActionWithStatus>);
}
