import React, { useRef } from "react";
import { repository } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink";
export function AuthorizeGitHubAppLink() {
    const authorizeUrl = useRef(repository.GitHubApp.getAuthorizeAppUrl(window.location.href));
    return (<ExternalLink openInSelf href={authorizeUrl.current}>
            Authorize the Octopus Deploy GitHub app
        </ExternalLink>);
}
export function ConfigureGitHubAppLink() {
    const installUrl = useRef(repository.GitHubApp.getInstallationUrl(window.location.href));
    return (<ExternalLink openInSelf href={installUrl.current}>
            Configure the Octopus Deploy GitHub app
        </ExternalLink>);
}
