import type { DeprecationResource } from "@octopusdeploy/octopus-server-client/src/repositories/deprecationRepository";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { NotificationState, useAnalyticDeprecationNotificationDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import * as notificationLocalState from "~/components/Navbar/NotificationPoller/notificationLocalState";
import type { NotificationItem } from "~/components/Navbar/notifications.redux";
import { notificationActions, NotificationType } from "~/components/Navbar/notifications.redux";
import useLocalStorage from "~/hooks/useLocalStorage";
const notificationIdPrefix = "Notification/Deprecation";
const notificationStateKey = "Octopus.Deprecations.Notifications";
export default function DeprecationNotificationsPoller() {
    const dispatch = useDispatch();
    const analyticsDispatch = useAnalyticDeprecationNotificationDispatch();
    const deprecationNotificationIsEnabled = isFeatureToggleEnabled("DeprecationNotificationFeatureToggle");
    const [lastNotifiedInfos, setLastNotifiedInfos] = useLocalStorage<LastNotifiedInfo>(notificationStateKey, {});
    const notify = useCallback((deprecation: DeprecationResource): void => {
        markUnread(notificationId(deprecation.Id));
        dispatch(notificationActions.raiseNotification(notification(deprecation)));
        setLastNotifiedInfos((prevState) => ({
            ...prevState,
            [deprecation.Id]: {
                date: Date.now(),
                usages: deprecation.Usages,
            },
        }));
        analyticsDispatch("Deprecation Notification Raised", {
            state: NotificationState.Raised,
            deprecation: deprecation.Id,
        });
    }, [dispatch, analyticsDispatch, setLastNotifiedInfos]);
    useEffect(() => {
        if (!deprecationNotificationIsEnabled) {
            return;
        }
        async function fetchDeprecations(): Promise<void> {
            const deprecations = await repository.Deprecations.getDeprecations();
            for (const deprecation of deprecations.Deprecations) {
                if (deprecation.Usages <= 0)
                    continue;
                const lastNotifiedInfo = lastNotifiedInfos[deprecation.Id];
                if (!lastNotifiedInfo) {
                    notify(deprecation);
                    continue;
                }
                if (moreThanAWeekAgo(new Date(lastNotifiedInfo.date))) {
                    if (lastNotifiedInfo.usages < deprecation.Usages) {
                        notify(deprecation);
                        continue;
                    }
                    else {
                        dispatch(notificationActions.dismissNotification(notificationId(deprecation.Id)));
                        analyticsDispatch("Deprecation Notification Dismissed", {
                            state: NotificationState.Dismissed,
                            deprecation: deprecation.Id,
                        });
                        continue;
                    }
                }
                dispatch(notificationActions.raiseNotification(notification(deprecation)));
            }
            cleanUpLocalStorage(deprecations.Deprecations);
        }
        fetchDeprecations();
        // Run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Remove references to deprecations that no longer exist
    const cleanUpLocalStorage = useCallback((deprecations: DeprecationResource[]): void => {
        const activeDeprecationIds = new Set(deprecations.map((d) => d.Id));
        setLastNotifiedInfos((lastNotifiedInfos) => {
            const activeNotifications: LastNotifiedInfo = {};
            for (const [deprecationId, notifiedInfo] of Object.entries(lastNotifiedInfos)) {
                if (activeDeprecationIds.has(deprecationId)) {
                    activeNotifications[deprecationId] = notifiedInfo;
                }
                else {
                    markUnread(notificationId(deprecationId));
                }
            }
            return activeNotifications;
        });
    }, [setLastNotifiedInfos]);
    return <></>;
}
type LastNotifiedInfo = {
    [key: string]: {
        date: number;
        usages: number;
    };
};
function notificationId(deprecationId: string): string {
    return `${notificationIdPrefix}/${deprecationId}`;
}
function notification(deprecation: DeprecationResource): NotificationItem {
    return {
        id: notificationId(deprecation.Id),
        text: deprecation.Title,
        description: deprecation.Message,
        links: [{ type: "External", label: "Learn more", href: deprecation.Link }],
        type: NotificationType.warn,
    };
}
function moreThanAWeekAgo(lastNotifiedDate: Date) {
    return moment(lastNotifiedDate).isBefore(moment().subtract(1, "week"));
}
function markUnread(notificationId: string): void {
    notificationLocalState.clearValue(notificationId);
}
