import { VariableType } from "@octopusdeploy/octopus-server-client";
import { uniq } from "lodash";
import type { FilterableValue } from "~/areas/variables/VariableFilter/index";
import { getCertificateIndex } from "~/components/certificates";
import { getWorkerPoolIndex } from "~/components/workerPools";
const collectCertificateAndWorkerPoolIndexes = async (values: Array<FilterableValue>) => {
    const certificates = getIdsForType(values, VariableType.Certificate);
    const workerPools = getIdsForType(values, VariableType.WorkerPool);
    const certificateIndex = certificates.length === 0 ? undefined : await getCertificateIndex(certificates);
    const poolIndex = workerPools.length === 0 ? undefined : await getWorkerPoolIndex(workerPools);
    return { certificateIndex, poolIndex };
};
const getIdsForType = (values: Array<FilterableValue>, type: VariableType): string[] => {
    const ids = values
        .filter((val) => val.type === type)
        .map((val) => val.value)
        .filter((val): val is string => typeof val !== "undefined");
    return uniq(ids);
};
export { collectCertificateAndWorkerPoolIndexes };
