import { Divider, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, FilterValueStatus, GetCommonTemplatesQueryParameters, GetProjectTenantVariablesQueryParameters, TagSetResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { FilterValueStatusValues } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { FilterActions } from "~/areas/projects/components/Variables/TenantVariables/FilterActions";
import { styles } from "~/areas/projects/components/Variables/TenantVariables/styles";
import type { TenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import { FilterBuilder } from "~/areas/tenants/components/Filtering/FilterBuilder/FilterBuilder";
import { createEnvironmentFilter, createTagSetFilters, createTenantFilter, getExcludedEnvironmentValue, getExcludedTagSetValues, getExcludedTenantValue, getIncludedEnvironmentValue, getIncludedTagSetValues, getIncludedTenantValue, } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import type { FilterValue, Filter } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import CollapsibleFilter from "~/areas/tenants/components/HeaderBar/CollapsibleFilter";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
export type ProjectTenantVariablesFilterState = GetProjectTenantVariablesQueryParameters & GetCommonTemplatesQueryParameters & {
    pageNumber: number;
};
interface FiltersBarProps {
    totalVariablesCount: number;
    filteredVariablesCount: number | null;
    filterState: ProjectTenantVariablesFilterState;
    onFilterChange: (newFilterState: ProjectTenantVariablesFilterState) => void;
    filterData: TenantVariableFiltersData;
    unsavedChangesCount?: number;
}
export function ProjectTemplatesFiltersBar({ filterState, onFilterChange, totalVariablesCount, filteredVariablesCount, filterData, unsavedChangesCount }: FiltersBarProps) {
    const data: VariableFiltersData = {
        environments: filterData.environments,
        tenants: filterData.tenants,
        tagSets: filterData.tagSets,
    };
    const filters = buildVariableFilters(filterState, data);
    return <FiltersBar totalVariablesCount={totalVariablesCount} filteredVariablesCount={filteredVariablesCount} filterState={filterState} onFilterChange={onFilterChange} unsavedChangesCount={unsavedChangesCount} filters={filters}/>;
}
export function CommonTemplatesFiltersBar({ filterState, onFilterChange, totalVariablesCount, filteredVariablesCount, filterData, unsavedChangesCount }: FiltersBarProps) {
    const data: VariableFiltersData = {
        environments: null,
        tenants: filterData.tenants,
        tagSets: filterData.tagSets,
    };
    const filters = buildVariableFilters(filterState, data);
    return <FiltersBar totalVariablesCount={totalVariablesCount} filteredVariablesCount={filteredVariablesCount} filterState={filterState} onFilterChange={onFilterChange} unsavedChangesCount={unsavedChangesCount} filters={filters}/>;
}
interface FiltersBarInternalProps extends Omit<FiltersBarProps, "filterData"> {
    filters: Filter[];
}
function FiltersBar({ filterState, onFilterChange, totalVariablesCount, filteredVariablesCount, unsavedChangesCount, filters }: FiltersBarInternalProps) {
    const handleFilterChange = (newFilters: FilterValue[]) => {
        onFilterChange({
            ...filterState,
            filterByTenant: getIncludedTenantValue(newFilters),
            filterByExcludedTenant: getExcludedTenantValue(newFilters),
            filterByEnvironment: getIncludedEnvironmentValue(newFilters),
            filterByExcludedEnvironment: getExcludedEnvironmentValue(newFilters),
            filterByTags: getIncludedTagSetValues(newFilters),
            filterByExcludedTags: getExcludedTagSetValues(newFilters),
        });
    };
    const handleNameFilterChange = (newName: string) => {
        onFilterChange({
            ...filterState,
            filterByName: newName,
        });
    };
    const handleValueStatusFilterChange = (newValueStatus: FilterValueStatus) => {
        onFilterChange({
            ...filterState,
            filterByValueStatus: newValueStatus,
        });
    };
    return (<CollapsibleFilter totalCount={totalVariablesCount} filteredCount={filteredVariablesCount === totalVariablesCount ? null : filteredVariablesCount} entityName={"value"} actions={<FilterActions unsavedChanges={unsavedChangesCount}/>} secondaryContent={<FilterSearchBox placeholder={"Filter by variable name..."} value={filterState.filterByName} onChange={handleNameFilterChange} autoFocus={true}/>}>
            {filters.length > 0 && (<div className={styles.filterContainer}>
                    <div className={styles.filterSidebar}>
                        <RadioButtonGroup value={filterState.filterByValueStatus ?? FilterValueStatusValues.All} onChange={handleValueStatusFilterChange} title="Show">
                            <RadioButton label="All" value={FilterValueStatusValues.All}/>
                            <RadioButton label="Non-default" value={FilterValueStatusValues.Overridden}/>
                            <RadioButton label="Missing" value={FilterValueStatusValues.Missing}/>
                        </RadioButtonGroup>
                    </div>
                    <Divider orientation={"vertical"}/>
                    <FilterBuilder filters={filters} onChange={handleFilterChange}/>
                </div>)}
        </CollapsibleFilter>);
}
interface VariableFiltersData {
    environments: EnvironmentResource[] | null;
    tenants: Map<string, TenantResource> | null;
    tagSets: TagSetResource[] | null;
}
function buildVariableFilters(currentState: ProjectTenantVariablesFilterState, data: VariableFiltersData): Filter[] {
    const filters = [];
    if (data.environments) {
        filters.push(createEnvironmentFilter(data.environments, currentState.filterByEnvironment, currentState.filterByExcludedEnvironment));
    }
    if (data.tenants) {
        filters.push(createTenantFilter(Array.from(data.tenants.values()), currentState.filterByTenant, currentState.filterByExcludedTenant));
    }
    if (data.tagSets) {
        filters.push(...createTagSetFilters(data.tagSets, currentState.filterByTags, currentState.filterByExcludedTags));
    }
    return filters;
}
