import React from "react";
import ErrorContextProvider from "../ErrorContext";
export const withErrorsProvider = <TComponentProps extends {}>(Component: React.ComponentType<TComponentProps>) => {
    const WithErrorsProvider: React.ComponentType<TComponentProps> = (props) => {
        return (<ErrorContextProvider>
                <Component {...props}/>
            </ErrorContextProvider>);
    };
    return WithErrorsProvider;
};
