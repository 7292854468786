import { ActionButton, ActionButtonType, CustomMenu, useMenuState, MenuItemInternalLink } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ActionEvent } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { PermissionCheck } from "~/components/PermissionCheck/index";
function SampleProcessButtonWithRedirect({ project }: {
    project: ProjectResource;
}) {
    const dispatchAction = useAnalyticActionDispatch();
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const redirectToSampleProcess = () => {
        const ev: ActionEvent = {
            action: Action.Save,
            resource: "Deployment Process",
        };
        dispatchAction("Load Sample Deployment Process", ev);
    };
    return (<PermissionCheck permission={Permission.ProcessEdit}>
            <ActionButton type={ActionButtonType.Secondary} icon={<DropDownIcon />} iconPosition="right" label="Use sample" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <CustomMenu accessibleName={"Deploy release"} menuState={menuState}>
                <MenuItemInternalLink path={links.deploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, { loadSampleProcess: "ps" })} label={"Hello world (PowerShell)"} onClick={redirectToSampleProcess}></MenuItemInternalLink>
                <MenuItemInternalLink path={links.deploymentProcessPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }, { loadSampleProcess: "bash" })} label={"Hello world (Bash)"} onClick={redirectToSampleProcess}></MenuItemInternalLink>
            </CustomMenu>
        </PermissionCheck>);
}
export default SampleProcessButtonWithRedirect;
