import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import imageForRunbookTriggers from "../../Images/Trigger-Scheduled-Runbook.svg";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonProjectRunbookTriggersOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="ScheduledRunbookTriggers">Runbook triggers</ExternalLink> let you define a recurring schedule to automate execution of a runbook.
                </p>
                <ImageWithPlaceholder src={imageForRunbookTriggers} alt={"Deployment process"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
