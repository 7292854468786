import { css } from "@emotion/css";
import { Button, Divider, SimpleDataTable, Callout } from "@octopusdeploy/design-system-components";
import { text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentResource, ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import pluralize from "pluralize";
import * as React from "react";
import { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { defaultPanelSelectorDialogPageSize, PagingSection } from "~/areas/projects/components/ProjectTenants/PagingSection";
import type { NamedItemWithLogo } from "~/areas/projects/components/ProjectTenants/PanelSelector";
import { Leftovers } from "~/areas/tenants/components/DataTable/Cells/Leftovers";
import { TenantDataTableAllEnvironmentsCell } from "~/areas/tenants/components/DataTable/Cells/TenantDataTableEnvironmentsCell";
import { TenantNameCell } from "~/areas/tenants/components/DataTable/Cells/TenantNameCell";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
export interface NamedItemWithLogoAndEnvironments extends NamedItemWithLogo {
    Environments: ReferenceDataItem[];
}
interface SelectEnvironmentsProps {
    selectedItems: NamedItemWithLogoAndEnvironments[];
    getAvailableEnvironments: () => Promise<EnvironmentResource[]>;
    updateSelectedEnvironments: (environmentIds: string[]) => void;
    selectItemType: SelectItemType;
    doBusyTask: DoBusyTask;
    showUntenantedCallout?: boolean;
    showPreviouslySelectedEnvironments?: boolean;
}
export enum SelectItemType {
    Tenant = "Tenant",
    Project = "Project"
}
export default function SelectEnvironments({ selectedItems, getAvailableEnvironments, updateSelectedEnvironments, selectItemType, doBusyTask, showUntenantedCallout, showPreviouslySelectedEnvironments }: SelectEnvironmentsProps) {
    const selectedEnvironments = showPreviouslySelectedEnvironments ? [...new Set(selectedItems.flatMap((p) => p.Environments).map((e) => e.Id))] : [];
    const [availableEnvironments, setAvailableEnvironments] = useState<EnvironmentResource[] | null>(null);
    const [selectedEnvironmentIds, setSelectedEnvironmentIds] = useState<string[]>(selectedEnvironments);
    useDoBusyTaskEffect(doBusyTask, async () => {
        setAvailableEnvironments(await getAvailableEnvironments());
        updateSelectedEnvironments(showPreviouslySelectedEnvironments ? selectedEnvironmentIds : []);
    }, []);
    const onEnvironmentChange = (environmentIds: string[]) => {
        setSelectedEnvironmentIds(environmentIds);
        updateSelectedEnvironments(environmentIds);
    };
    if (availableEnvironments === null)
        return null;
    return (<div className={styles.container}>
            <div className={styles.panelContainer}>
                <SelectEnvironmentsPanel availableEnvironments={availableEnvironments} selectedEnvironmentIds={selectedEnvironmentIds} onEnvironmentChange={onEnvironmentChange} selectedItems={selectedItems} selectItemType={selectItemType}/>
                <Divider />
                <ConnectionPreview items={selectedItems} selectItemType={selectItemType} selectedEnvironmentIds={selectedEnvironmentIds} availableEnvironments={availableEnvironments} showUntenantedCallout={showUntenantedCallout}/>
            </div>
        </div>);
}
interface SelectEnvironmentsPanelProps {
    selectedItems: NamedItemWithLogoAndEnvironments[];
    selectedEnvironmentIds: string[];
    onEnvironmentChange: (environmentIds: string[]) => void;
    selectItemType: SelectItemType;
    availableEnvironments: EnvironmentResource[];
}
function SelectEnvironmentsPanel({ selectedItems, selectedEnvironmentIds, onEnvironmentChange, selectItemType, availableEnvironments }: SelectEnvironmentsPanelProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const onAssignAll = () => {
        dispatchAction("Assign All Available Environments", { resource: selectItemType, action: Action.Autofill });
        onEnvironmentChange(availableEnvironments.map((e) => e.Id));
    };
    return (<>
            <div className={styles.panelHeadingContainer}>
                <div className={styles.panelHeading}>Select environments for {pluralize(selectItemType.toLowerCase(), selectedItems.length, true)}</div>
            </div>
            <div className={styles.panelHeadingContainer}>
                <div>
                    <EnvironmentMultiSelect onChange={onEnvironmentChange} value={selectedEnvironmentIds} environments={availableEnvironments}/>
                </div>
                <div>
                    <Button label={"Assign all available environments"} onClick={onAssignAll} importance={"tertiary"}/>
                </div>
            </div>
        </>);
}
interface ConnectionPreviewProps {
    items: NamedItemWithLogoAndEnvironments[];
    selectedEnvironmentIds: string[];
    availableEnvironments: EnvironmentResource[];
    showUntenantedCallout?: boolean;
    selectItemType: SelectItemType;
}
function ConnectionPreview({ items, selectedEnvironmentIds, availableEnvironments, showUntenantedCallout, selectItemType }: ConnectionPreviewProps) {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPanelSelectorDialogPageSize);
    const selectedItemsToDisplay = items.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    return (<>
            <div className={styles.previewTitle}>Connection preview</div>
            {showUntenantedCallout ? <UntenantedDeploymentCallout selectItemType={selectItemType} items={items}/> : null}
            <div className={styles.tableContainer}>
                <SimpleDataTable columns={[
            {
                accessibleName: `${selectItemType} Name`,
                columnSize: "medium",
                title: selectItemType,
                render: (item) => <TenantNameCell tenantName={item.Name} tenantLogoLink={item.LogoLink}/>,
            },
            {
                accessibleName: "Environments",
                columnSize: "large",
                title: "Environments",
                render: (item) => <LocalEnvironmentsSummaryCell environmentNames={item.Environments.map((e) => e.Name)} summaryCount={3}/>,
            },
        ]} data={selectedItemsToDisplay} getRowKey={(tenant) => tenant.Id} accessibleName={`${selectItemType}s to Connect`}/>
            </div>
            <PagingSection itemCount={items.length} pageNumber={pageNumber} pageSize={pageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            setPageNumber(newPageNumber);
            setPageSize(newPageSize);
        }}/>
        </>);
}
interface LocalEnvironmentsSummaryCellProps {
    summaryCount: number;
    environmentNames: string[];
}
function LocalEnvironmentsSummaryCell({ summaryCount, environmentNames }: LocalEnvironmentsSummaryCellProps) {
    const [showAll, setShowAll] = useState<boolean>(false);
    const change = () => {
        setShowAll(!showAll);
    };
    return (<div className={styles.environments}>
            <TenantDataTableAllEnvironmentsCell environments={showAll ? environmentNames : environmentNames.slice(0, summaryCount)} hasMissingVariables={false}/>
            {environmentNames.length <= summaryCount ? <></> : <Leftovers count={environmentNames.length - summaryCount} name={"environment"} onShowAllChanged={change} showAll={showAll}/>}
        </div>);
}
interface TenantedDeploymentNoticeProps {
    items: NamedItemWithLogo[];
    selectItemType: SelectItemType;
}
function UntenantedDeploymentCallout({ items, selectItemType }: TenantedDeploymentNoticeProps) {
    if (items.length <= 0) {
        return null;
    }
    if (selectItemType === SelectItemType.Tenant) {
        return (<Callout key="callout" title={`You are about to connect ${pluralize("tenant", items.length, true)} to the project`} type={"information"}>
                By connecting the selected tenants, Octopus will enable tenanted deployments for this project
            </Callout>);
    }
    if (selectItemType === SelectItemType.Project) {
        return (<Callout key="callout" title={`You are about to connect ${pluralize("project", items.length, true)} to the tenant`} type={"information"}>
                If any projects do not have tenanted deployments enabled these will remain disabled. You will need to enable this manually in order to perform tenanted deployments.
            </Callout>);
    }
    return null;
}
export interface AvailableEnvironments {
    all: EnvironmentResource[];
    lookup: Map<string, EnvironmentResource>;
}
const styles = {
    container: css({
        width: "100%",
        height: "100%",
        padding: "1rem",
        backgroundColor: themeTokens.color.background.secondary.default,
    }),
    panelContainer: css({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${themeTokens.color.border.primary}`,
        borderRadius: "0.25rem",
        backgroundColor: themeTokens.color.background.primary.default,
        overflowY: "hidden",
    }),
    panelHeadingContainer: css({
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gap: "10rem",
        alignItems: "center",
        padding: "1rem 1rem",
    }),
    panelHeading: css({
        font: text.interface.body.bold.base,
        color: themeTokens.color.text.primary,
    }),
    previewTitle: css({
        padding: "1rem 1rem",
        font: text.interface.body.bold.base,
        color: themeTokens.color.text.primary,
    }),
    tableContainer: css({
        overflowY: "auto",
    }),
    environments: css({
        display: "flex",
        alignItems: "center",
    }),
};
