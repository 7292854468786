import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { useEffect, useState } from "react";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { CustomDialogActions, CustomDialogContent, CustomSaveDialogActions, SmallDialogFrame } from "~/components/DialogLayout/Custom";
import CustomSaveDialogLayout, { CustomSaveDialogTitleBar } from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import { Text } from "~/components/form";
type CodeEditorFeedbackDialogProps = {
    open: boolean;
    onSubmit: (feedback: string) => void;
    onClose: () => void;
};
export function CodeEditorFeedbackDialog({ open, onClose, onSubmit }: CodeEditorFeedbackDialogProps) {
    const [feedback, setFeedback] = useState<string>("");
    const onOkClick = async () => {
        if (feedback)
            onSubmit(feedback);
        return true;
    };
    useEffect(() => {
        setFeedback("");
    }, [open]);
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            gap: space[8],
        }),
    };
    return (<CustomDialog open={open} close={onClose} render={() => {
            return (<CustomSaveDialogLayout frame={SmallDialogFrame} close={onClose} open={open} renderTitle={() => <CustomSaveDialogTitleBar title="Code Editor Feedback"/>} onSaveClick={onOkClick} renderActions={(renderProps) => <CustomDialogActions actions={<CustomSaveDialogActions close={renderProps.close} saveButtonLabel="Submit" onSaveClick={renderProps.onSaveClick} saveDisabled={feedback === ""}/>}/>} renderContent={() => (<CustomDialogContent>
                                <div className={styles.container}>
                                    <p>We would love to get feedback on your experience of using the code editor to edit your deployment process.</p>
                                    <Text value={feedback} onChange={(value) => setFeedback(value)} multiline={true} autoFocus={true} label="Feedback"/>
                                </div>
                            </CustomDialogContent>)}/>);
        }}></CustomDialog>);
}
