import { APIOperationStatusProvider, useLoaderQuery } from "@octopusdeploy/octopus-react-client";
import type { Repository, RequestCorrelationContext } from "@octopusdeploy/octopus-server-client";
import type { QueryParamValues, QueryParamValuesSetter, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import React, { useMemo } from "react";
import { v4 } from "uuid";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import { useSetPageRegistrationEffect } from "~/routing/pageRegistrations/PageRegistrationContext";
import StringHelper from "~/utils/StringHelper/index";
interface PageProps<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData, LoaderContext, PageContext> {
    pageRegistration: PageRegistration<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext>;
    loaderContext: LoaderContext;
    pageContext: PageContext;
    routeParams: RouteParams;
    queryParams: QueryParamValues<QueryParams>;
    setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>;
}
export function Page<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData, LoaderContext, PageContext>({ pageRegistration, loaderContext, pageContext, queryParams, setQueryParams, routeParams, }: PageProps<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext>) {
    useSetPageRegistrationEffect(pageRegistration);
    const pageInstanceId = useMemo(() => v4(), []);
    const pageCorrelationContext = useMemo(() => ({
        pageid: pageRegistration.pageIdentity.pageId,
        pageinstanceid: pageInstanceId,
    } satisfies RequestCorrelationContext), [pageInstanceId, pageRegistration.pageIdentity.pageId]);
    const loader = pageRegistration.loader;
    if (loader === undefined) {
        // In future, all of our pages will probably have loaders.
        // While we migrate to that future state, many pages won't have loaders yet.
        // Instead of trying to accommodate for this scenario in a more sophisticated way,
        // this option keeps things very simple and isolates most of the ugliness to this component and the type assertion below.
        // Other options tend to add a lot of unnecessary complexity.
        // The only real downside is that a `loaderData` property is available during rendering, even if you haven't yet supplied a loader.
        // (fortunately its type will be `unknown`)
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const loaderData = undefined as unknown as LoaderData;
        return <PageWithoutLoader pageCorrelationContext={pageCorrelationContext}>{pageRegistration.render({ context: pageContext, loaderData, routeParams, queryParams, setQueryParams })}</PageWithoutLoader>;
    }
    return (<PageWithLoader<LoaderData> loader={(repository) => loader(repository, { routeParams, queryParams, context: loaderContext })} loadingTitleConfiguration={pageRegistration.loadingTitle} renderContentWhenLoaded={(loaderData) => pageRegistration.render({ context: pageContext, loaderData, routeParams, queryParams, setQueryParams })} pageCorrelationContext={pageCorrelationContext}/>);
}
function PageWithoutLoader({ children, pageCorrelationContext }: {
    children: ReactNode;
    pageCorrelationContext: RequestCorrelationContext;
}) {
    return (<ErrorContextProvider>
            <APIOperationStatusProvider correlationContext={pageCorrelationContext}>{children}</APIOperationStatusProvider>
        </ErrorContextProvider>);
}
interface PageWithLoaderProps<LoaderData> {
    pageCorrelationContext: RequestCorrelationContext;
    loader: (repository: Repository) => Promise<LoaderData>;
    loadingTitleConfiguration: string | undefined;
    renderContentWhenLoaded: (loaderData: LoaderData) => ReactNode;
}
function PageWithLoader<LoaderData>({ loader, renderContentWhenLoaded, loadingTitleConfiguration, pageCorrelationContext }: PageWithLoaderProps<LoaderData>) {
    const queryState = useLoaderQuery(loader, {
        ...pageCorrelationContext,
        operation: "Page load",
    });
    const loadingTitle = loadingTitleConfiguration ?? StringHelper.ellipsis;
    if (queryState.isLoading) {
        return <PageLoading title={loadingTitle}/>;
    }
    if (queryState.error !== null) {
        return <PageLoadingError error={queryState.error} title={loadingTitle}/>;
    }
    return (<ErrorContextProvider>
            <APIOperationStatusProvider correlationContext={pageCorrelationContext}>{renderContentWhenLoaded(queryState.result)}</APIOperationStatusProvider>
        </ErrorContextProvider>);
}
function PageLoading({ title }: {
    title: string;
}) {
    return <PaperLayoutVNext title={title} busy={true}/>;
}
function PageLoadingError({ error, title }: {
    error: Error;
    title: string | undefined;
}) {
    return <PaperLayoutVNext title={title} busy={false} errors={[error]}/>;
}
