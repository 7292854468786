import type { DeploymentActionResource } from "@octopusdeploy/octopus-server-client";
import type { DeploymentActionGitDependencyDetails } from "./GitResourceRule";
export function getDeploymentActionGitDependenciesWithDetails(actions: DeploymentActionResource[]): DeploymentActionGitDependencyDetails[] {
    return actions.flatMap((action) => action.GitDependencies
        ? action.GitDependencies.map((gd) => ({
            DeploymentActionSlug: action.Slug ?? action.Id,
            DeploymentActionName: action.Name,
            RepositoryUri: gd.RepositoryUri,
            GitDependencyName: gd.Name,
        }))
        : []);
}
