import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import { useDispatchLinkClicked } from "@octopusdeploy/portal-routes";
import { useCallback } from "react";
import * as React from "react";
import type { LinkEvent, AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import { formatUrl } from "~/components/Navigation/ExternalLink/ExternalLink";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import styles from "./style.module.less";
const href = "PricingOverview";
const linkLabel = "Enterprise tier";
interface AuditStreamUpsellDialogLayoutInternalState {
    trialRequested: boolean;
}
interface AuditStreamUpsellDialogLayoutInternalProps extends AuditStreamUpsellDialogLayoutProps {
    dispatchAction: AnalyticActionDispatcher;
    onUpsellLinkClicked: () => void;
}
class AuditStreamUpsellDialogLayoutInternal extends DataBaseComponent<AuditStreamUpsellDialogLayoutInternalProps, AuditStreamUpsellDialogLayoutInternalState> {
    constructor(props: AuditStreamUpsellDialogLayoutInternalProps) {
        super(props);
        this.state = {
            trialRequested: false,
        };
    }
    requestTrial = async () => {
        await this.doBusyTask(async () => {
            await repository.AuditStreamConfiguration.requestTrial();
            this.props.dispatchAction("Request Trial", { resource: "Audit Stream", action: Action.RequestTrial });
            this.setState({ trialRequested: true });
        });
    };
    cancelRequestingTrial = () => {
        this.props.dispatchAction("Cancel Requesting Trial", { resource: "Audit Stream", action: Action.Cancel });
        this.props.onClose();
    };
    render() {
        const { trialRequested } = this.state;
        const requestTrialBtn = <ActionButton key="Request Trial" type={ActionButtonType.Save} label={"Request trial"} onClick={this.requestTrial}/>;
        const cancelBtn = <ActionButton key="Maybe Later" type={ActionButtonType.Ternary} label={"Maybe later"} onClick={this.cancelRequestingTrial}/>;
        const closeBtn = <ActionButton key="Close" type={ActionButtonType.Save} label={"Close"} onClick={this.props.onClose}/>;
        return (<DialogLayout title="Monitor and investigate Octopus events by streaming audit logs" busy={this.state.busy} actions={trialRequested ? [closeBtn] : [cancelBtn, requestTrialBtn]} closeDialog={close}>
                <p>Streaming copies of your audit log data can help protect your intellectual property and maintain compliance for your organization.</p>
                <ul className={styles.list}>
                    <li>Access to your organization or repository settings</li>
                    <li>Changes in permissions</li>
                    <li>Git events</li>
                    <li>and more</li>
                </ul>
                <p>
                    Audit Streaming is a part of the{" "}
                    <ExternalLink href={href} trackAnalytics={false} onClick={this.props.onUpsellLinkClicked}>
                        {linkLabel}
                    </ExternalLink>
                    .
                </p>
                {trialRequested && (<Callout type={"success"} title="Your trial request has been sent">
                        <p>The Customer Success team will reach out to you with details of the trial.</p>
                    </Callout>)}
                {!trialRequested && <p>Request a trial of this feature from our Customer Success Team, who will contact you with the details of the trial.</p>}
            </DialogLayout>);
    }
    static displayName = "AuditStreamUpsellDialogLayoutInternal";
}
export interface AuditStreamUpsellDialogLayoutProps {
    onClose: () => void;
}
const AuditStreamUpsellDialogLayout: React.FC<AuditStreamUpsellDialogLayoutProps> = ({ onClose }) => {
    const dispatchAction = useAnalyticActionDispatch();
    const linkDispatchAction = useDispatchLinkClicked();
    const onUpsellLinkClicked = useCallback(() => {
        const ev: LinkEvent = {
            documentUrl: formatUrl(href),
            linkLabel,
        };
        linkDispatchAction("Visit Upsell Page", ev);
    }, [linkDispatchAction]);
    return <AuditStreamUpsellDialogLayoutInternal dispatchAction={dispatchAction} onUpsellLinkClicked={onUpsellLinkClicked} onClose={onClose}/>;
};
AuditStreamUpsellDialogLayout.displayName = "AuditStreamUpsellDialogLayout"
export default AuditStreamUpsellDialogLayout;
