import React from "react";
import CopyToClipboard from "~/components/CopyToClipboardButton/CopyToClipboardButton";
import styles from "./style.module.less";
interface CopyValueToClipboardProps {
    value: string;
    children?: React.ReactNode;
}
const CopyValueToClipboard: React.FC<CopyValueToClipboardProps> = ({ value, children }) => {
    return (<div className={styles.copyValue}>
            {children ? children : <strong>{value}</strong>}
            <CopyToClipboard value={value}/>
        </div>);
};
CopyValueToClipboard.displayName = "CopyValueToClipboard"
export default CopyValueToClipboard;
