import type { RequestContext, RequestCorrelationContext } from "@octopusdeploy/octopus-server-client";
const globalRequestAbortController = new AbortController();
// Our vision is that most requests use our hook-based data access building blocks, which supply a localised request context
// For everything else, including legacy repository instance and client usages, this global context provides
// - some consistent for the correlation context
// - a global abortSignal
export function createGlobalRequestContext(repository: "Global" | "None" | "ProjectContext", correlationContext?: RequestCorrelationContext): RequestContext {
    return {
        correlationContext: { ...correlationContext, repository },
        abortSignal: globalRequestAbortController.signal,
    };
}
