/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { ServerDocumentCounts, ServerStatusResource, SystemInfoResource, ActivityLogElement, TaskResource } from "@octopusdeploy/octopus-server-client";
import { TaskName } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { reverse } from "lodash";
import * as React from "react";
import ServerLogs from "~/areas/configuration/components/Diagnostics/ServerLogs";
import SystemDiagnosticReport from "~/areas/configuration/components/Diagnostics/SystemDiagnosticReport";
import SystemInformation from "~/areas/configuration/components/Diagnostics/SystemInformation";
import SystemIntegrityCheck from "~/areas/configuration/components/Diagnostics/SystemIntegrityCheck";
import { repository, session } from "~/clientInstance";
import type { DataBaseComponentState, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import ServerDocumentCountsTable from "./ServerDocumentCountTable";
interface DiagnosticsState extends DataBaseComponentState {
    status?: ServerStatusResource;
    systemInfo?: SystemInfoResource;
    documentCounts?: ServerDocumentCounts;
    logs?: ActivityLogElement[];
    latestIntegrityCheck?: TaskResource<{}>;
    redirectToTaskId?: string;
    hasLoadedOnce?: boolean;
    isHA: boolean;
}
export class DiagnosticsPage extends DataBaseComponent<{}, DiagnosticsState> {
    constructor(props: {}) {
        super(props);
        this.state = { isHA: false };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const nodes = repository.OctopusServerNodes.list();
            this.doRefresh = await this.startRefreshLoop(() => this.refresh(), 5000, false, timeOperationOptions.forRefresh());
            this.setState({ isHA: (await nodes).Items.length > 1 });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async refresh() {
        const useOptimization = session.featureToggles?.includes("PermissionCheckOptimizationFeatureToggle");
        const getLatestIntegrityCheck = useOptimization ? repository.Tasks.getUnpaginatedTasks({ name: TaskName.SystemIntegrityCheck, take: 1 }) : repository.Tasks.list({ name: TaskName.SystemIntegrityCheck, take: 1 });
        const status = await repository.ServerStatus.getServerStatus();
        const getSystemInfo = repository.ServerStatus.getSystemInfo(status);
        const getLogs = repository.ServerStatus.getLogs(status, { take: 5, includeDetail: false });
        const getDocumentCounts = repository.ServerStatus.getDocumentCounts(status);
        return {
            status,
            systemInfo: await getSystemInfo,
            documentCounts: await getDocumentCounts,
            logs: reverse(await getLogs),
            latestIntegrityCheck: (await getLatestIntegrityCheck).Items[0],
            hasLoadedOnce: true,
        };
    }
    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        const pageActions: PageAction[] = [
            { type: "navigate", buttonType: "secondary", label: "Auto deploy logs", path: links.diagnosticAutoDeployLogsPage.generateUrl() },
            { type: "navigate", buttonType: "secondary", label: "Scheduled trigger logs", path: links.diagnosticScheduledDeployLogsPage.generateUrl() },
            { type: "navigate", buttonType: "secondary", label: "Subscription logs", path: links.diagnosticSubscriptionLogsPage.generateUrl() },
            { type: "navigate", buttonType: "secondary", label: "Machine clean up events", path: links.diagnosticMachineCleanupRedirect.generateUrl() },
        ];
        const sideBar = this.state.systemInfo && (<div>
                <SystemInformation systemInfo={this.state.systemInfo} onCollectClicked={() => this.onCollectClicked()}/>
            </div>);
        const body = this.state.logs && (<div>
                <SystemDiagnosticReport serverStatus={this.state.status!}/>
                <hr />
                <SystemIntegrityCheck latestCheck={this.state.latestIntegrityCheck!} performIntegrityCheck={() => this.performIntegrityCheck()}/>
                <hr />
                <ServerLogs logs={this.state.logs} isHA={this.state.isHA}/>
                <hr />
                <ServerDocumentCountsTable documentCounts={this.state.documentCounts!}/>
            </div>);
        return (<PaperLayoutVNext title="Diagnostics" pageActions={pageActions} busy={this.state.busy} enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce} errors={this.errors}>
                <SidebarLayout sideBar={sideBar}>{body}</SidebarLayout>
            </PaperLayoutVNext>);
    }
    async onCollectClicked() {
        await this.doBusyTask(async () => {
            await repository.ServerStatus.gcCollect(this.state.status!);
        });
        await this.doRefresh();
    }
    async performIntegrityCheck() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createPerformIntegrityCheckTask();
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    private doRefresh: Refresh = () => Promise.resolve();
    static displayName = "DiagnosticsPage";
}
