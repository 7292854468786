import type { CertificateResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import CertificateSearch from "~/components/form/CertificateSearch/CertificateSearch";
interface CertificateSelectorProps {
    selectedCertificateId: string;
    tenantId?: string;
    onSelected(certificate: CertificateResource): void;
}
interface CertificateSelectorState extends DataBaseComponentState {
    certificate: CertificateResource | null;
}
export default class CertificateSelector extends DataBaseComponent<CertificateSelectorProps, CertificateSelectorState> {
    constructor(props: CertificateSelectorProps) {
        super(props);
        this.state = {
            certificate: null,
        };
    }
    onOk() {
        if (this.state.certificate) {
            this.props.onSelected(this.state.certificate);
            return true;
        }
        return false;
    }
    render() {
        return (<OkDialogLayout title="Select Certificate" busy={this.state.busy} errors={this.errors} onOkClick={() => this.onOk()}>
                <CertificateSearch selectedCertificateId={this.props.selectedCertificateId} tenantId={this.props.tenantId} onSelected={(certificate) => this.setState({ certificate })} doBusyTask={this.doBusyTask}/>
            </OkDialogLayout>);
    }
    static displayName = "CertificateSelector";
}
