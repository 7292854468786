import React from "react";
// Root path here is the app folder
const allSvgs = require.context("../../../", true, /\.svg$/);
export function AllSvgImageFiles() {
    return (<div style={{ paddingLeft: "1rem" }}>
            <p>This is the complete set of all *.svg image files in the portal package. Note that this excludes any images from the design system.</p>
            <AllImages />
        </div>);
}
function AllImages() {
    return (<>
            {allSvgs.keys().map((svgName) => (<SvgImage svgName={svgName} svgPath={allSvgs(svgName)}/>))}
        </>);
}
function SvgImage({ svgName, svgPath }: {
    svgName: string;
    svgPath: string;
}) {
    return (<>
            <h2>{svgName}</h2>
            <div style={{ paddingLeft: "1rem" }}>
                <img style={{ maxHeight: "500px", maxWidth: "500px" }} src={svgPath} alt={svgName}/>
            </div>
        </>);
}
