import React from "react";
import ErrorPanel from "~/components/ErrorPanel";
export const ErrorsForAction: React.FC<{
    actionErrors: string[];
}> = ({ actionErrors }) => {
    if (actionErrors.length === 0) {
        return null;
    }
    return <ErrorPanel message={"Validation issues"} errors={actionErrors} parsedHelpLinks={undefined} helpText={undefined} helpLink={undefined} statusCode={undefined} scrollToPanel={false}/>;
};
ErrorsForAction.displayName = "ErrorsForAction"
