import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import styles from "../../../primitiveComponents/dataDisplay/SvgImage/styles.module.less";
const StepRolling = (props: Partial<SvgImageProps>) => (<SvgImage viewBox="0 0 202 202" title="Step Rolling" {...props}>
        <g fillRule="nonzero" strokeWidth="1">
            <path className={styles.imgWhite} d="M100 135.6c-23.3-18.2-46.6-36.3-70-54.3L100 27l70 54.3-70 54.3z"/>
            <polygon className={styles.imgWhite} points="100 155.6 157.3 110.8 170 120.7 100 175 30 120.7 42.7 110.8"/>
        </g>
    </SvgImage>);
export { StepRolling };
