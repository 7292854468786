import type { Event as AnalyticsEvent } from "@amplitude/analytics-types/lib/esm/event";
import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DevToolbar from "~/components/DevTools/DevToolbar";
import { useDevDrawerTabs } from "~/components/DevTools/useDevDrawerTabs";
interface BusyTaskProps {
    doBusyTask: DoBusyTask;
    isBusy: boolean;
}
interface DevDrawerContentProps extends BusyTaskProps {
    analyticsEvents: AnalyticsEvent[];
}
function DevDrawerContentInternal({ doBusyTask, isBusy, analyticsEvents }: DevDrawerContentProps) {
    const tabs = useDevDrawerTabs(doBusyTask, analyticsEvents);
    const latestTabs = React.useRef(tabs);
    const [selectedTabLabel, setSelectedTabLabel] = React.useState(tabs[0] ? tabs[0].label : "");
    const selectedTab = tabs.find((t) => t.label === selectedTabLabel);
    React.useEffect(() => {
        latestTabs.current = tabs;
    }, [tabs]);
    React.useEffect(() => {
        if (!selectedTab && latestTabs.current.length > 0) {
            setSelectedTabLabel(latestTabs.current[0].label);
        }
    }, [selectedTab]);
    return (<div className={devDrawerContentStyles}>
            <DevToolbar tabs={tabs} onSelectTab={setSelectedTabLabel} selectedTab={selectedTabLabel}/>
            <TabPanelContent>{selectedTab?.content}</TabPanelContent>
        </div>);
}
const devDrawerContentStyles = css({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100%",
    width: "100%",
});
export class DevDrawerContent extends DataBaseComponent<Omit<DevDrawerContentProps, keyof BusyTaskProps>> {
    state = {
        busy: undefined,
    };
    render() {
        return <DevDrawerContentInternal {...this.props} doBusyTask={this.doBusyTask} isBusy={!!this.state.busy}/>;
    }
    static displayName = "DevDrawerContent";
}
const tabPanelContentStyles = css({
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflow: "auto",
    height: "100%",
    "&::-webkit-scrollbar": {
        width: 5,
        height: 5,
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: themeTokens.color.border.secondary,
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: themeTokens.color.border.primary,
        border: `solid 1px ${themeTokens.color.border.primary}`,
    },
    "&::-webkit-scrollbar-thumb:hover": {
        borderColor: themeTokens.color.border.selected,
    },
});
const TabPanelContent = ({ children }: {
    children: React.ReactNode;
}) => <div className={tabPanelContentStyles}>{children}</div>;
