import { css } from "@emotion/css";
import { EditIcon } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
export default function ChangedValueIndicator({ show }: {
    show: boolean;
}) {
    if (!show) {
        return null;
    }
    return (<div className={styles.editIcon}>
            <EditIcon />
        </div>);
}
const styles = {
    editIcon: css({
        color: themeTokens.color.icon.secondary,
        paddingLeft: space[8],
    }),
};
