import { useCallback, useEffect, useState } from "react";
import type { NavigationBlocker } from "~/components/OctopusRouter/NavigationBlocker";
/**
 * Represents a navigation attempt that has been blocked due to a NavigationBlocker
 * It's state is the combination of a NavigationBlocker and a request to navigate
 */
export type UserConfirmationState = 
// This type represents is a state machine that progresses sequentially
UserConfirmationRequest // 1. The initial request from react router
 | UserConfirmationRequestWithBlocker // 2. Combining the request with a blocker, if one exists
 | UserConfirmationResult; // 3. The result of accepting or cancelling a blocked navigation request
interface UserConfirmationRequest {
    message: string;
    callback: UserConfirmationCallback;
    setBlocker(blocker: NavigationBlocker): void;
    continueNavigation(): void;
    type: "request";
}
interface UserConfirmationRequestWithBlocker extends UserConfirmationData {
    setResult: (wasOk: boolean) => void;
    type: "request-with-blocker";
}
interface UserConfirmationResult extends UserConfirmationData {
    type: "result";
}
interface UserConfirmationData {
    message: string;
    navigationBlocker: NavigationBlocker;
}
type UserConfirmationCallback = (ok: boolean) => void;
export function useUserConfirmationState(navigationBlocker: NavigationBlocker | null) {
    const [userConfirmationState, setUserConfirmationState] = useState<UserConfirmationState | null>(null);
    // Due to the timing of when react-router invokes the `createUserConfirmationRequest` callback,
    // state may have recently been set (such as the navigation blocker) yet a render of this hook may not yet have run
    // As a result, the navigationBlocker would not necessarily be up to date by the time createUserConfirmationRequest is invoked
    // Instead, we combine the user confirmation request and the blocker in this effect,
    // At which point any state changes for the navigation blocker have happened
    useEffect(() => {
        if (userConfirmationState?.type === "request") {
            if (navigationBlocker === null) {
                userConfirmationState.continueNavigation();
            }
            else {
                userConfirmationState.setBlocker(navigationBlocker);
            }
        }
    }, [userConfirmationState, navigationBlocker]);
    const createUserConfirmationRequest = useCallback((message: string, callback: UserConfirmationCallback) => {
        setUserConfirmationState({
            type: "request",
            message,
            callback,
            continueNavigation: () => {
                callback(true);
                setUserConfirmationState(null);
            },
            setBlocker: (navigationBlocker: NavigationBlocker) => {
                const requestWithBlocker: UserConfirmationRequestWithBlocker = {
                    message,
                    navigationBlocker,
                    type: "request-with-blocker",
                    setResult: (wasOk: boolean) => {
                        setUserConfirmationState({
                            message,
                            navigationBlocker,
                            type: "result",
                        });
                        callback(wasOk);
                    },
                };
                setUserConfirmationState(requestWithBlocker);
            },
        });
        // It is important this dependency array is empty, because the initial value of this request is captured by the `RouterComponent` on its first render.
    }, []);
    return {
        userConfirmationState,
        createUserConfirmationRequest,
    };
}
