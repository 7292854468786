import type { AmazonWebServicesAccount, AzureServicePrincipal, Discriminator, GoogleCloudAccount, SensitiveValue, SshKeyPair, Token, UsernamePassword } from "@octopusdeploy/step-inputs";
import type { InputJsonSchema, UnionDefinition } from "@octopusdeploy/step-runtime-inputs";
export interface SampleDeploymentTargetInputs {
    string: string;
    sensitiveValue: SensitiveValue;
    union: Union;
    secondUnion: Union;
    boolean: boolean;
    region: "us-east1" | "us-east2" | "au-east1";
    select: "select-1" | "select-2" | "select-3";
    number: number;
    awsAccount: AmazonWebServicesAccount;
    azureAccount: AzureServicePrincipal;
    googleAccount: GoogleCloudAccount;
    sshKeyPair: SshKeyPair;
    token: Token;
    username: UsernamePassword;
}
export type Union = FirstType | SecondType;
export interface FirstType {
    type: Discriminator<"first">;
    valueFromFirst: string;
}
export interface SecondType {
    type: Discriminator<"second">;
    valueFromSecond: SensitiveValue;
}
const unionSchema: UnionDefinition = {
    anyOf: [
        {
            type: "object",
            properties: {
                type: { type: "string", const: "first", isDiscriminator: true },
                valueFromFirst: { type: "string" },
            },
            required: ["type", "valueFromFirst"],
        },
        {
            type: "object",
            properties: {
                type: { type: "string", const: "second", isDiscriminator: true },
                valueFromSecond: { type: "object", isSensitiveValue: true },
            },
            required: ["type", "valueFromSecond"],
        },
    ],
};
export const sampleDeploymentTargetInputSchema: InputJsonSchema = {
    definitions: {
        MyInputs: {
            type: "object",
            isRoot: true,
            properties: {
                string: { type: "string" },
                sensitiveValue: { type: "object", isSensitiveValue: true },
                union: unionSchema,
                secondUnion: unionSchema,
                boolean: { type: "boolean" },
                region: { type: "string", enum: ["us-east1", "us-east2"] },
                select: { type: "string", enum: ["select-1", "select-2", "select-3"] },
                number: { type: "number" },
                awsAccount: {
                    type: "string",
                    isAccount: true,
                    accountType: "AmazonWebServicesAccount",
                },
                azureAccount: {
                    type: "string",
                    isAccount: true,
                    accountType: "AzureServicePrincipal",
                },
                googleAccount: {
                    type: "string",
                    isAccount: true,
                    accountType: "GoogleCloudAccount",
                },
                sshKeyPair: {
                    type: "string",
                    isAccount: true,
                    accountType: "SshKeyPair",
                },
                token: {
                    type: "string",
                    isAccount: true,
                    accountType: "Token",
                },
                username: {
                    type: "string",
                    isAccount: true,
                    accountType: "UsernamePassword",
                },
            },
            required: ["string", "sensitiveValue", "union", "secondUnion", "boolean", "region", "select", "number", "awsAccount", "azureAccount", "googleAccount", "sshKeyPair", "token", "username"],
        },
    },
};
