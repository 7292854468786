/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { required } from "~/components/form";
import Text from "~/primitiveComponents/form/Text/Text";
import StringHelper from "~/utils/StringHelper";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
interface SendTestEmailState extends DataBaseComponentState {
    testEmailAddress?: string;
    redirectToTaskId?: string;
}
export default class SendTestEmail extends DataBaseComponent<{}, SendTestEmailState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        const looksLikeAnEmail = (email: string | null) => !StringHelper.isNullOrWhiteSpace(email) && email!.indexOf("@") !== -1;
        return (<OkDialogLayout title="Test Email" busy={this.state.busy} errors={this.errors} okButtonDisabled={!looksLikeAnEmail(this.state.testEmailAddress!)} onOkClick={() => this.sendTestEmail()}>
                <p>Who would you like to send the test email to?</p>
                <div>
                    <Text value={this.state.testEmailAddress!} onChange={(testEmailAddress) => this.setState({ testEmailAddress })} label="Test email" placeholder="test@example.com" validate={required("Please enter an email address")} autoFocus={true}/>
                </div>
            </OkDialogLayout>);
    }
    private async sendTestEmail() {
        const task = await repository.Tasks.createSendTestEmailTask(this.state.testEmailAddress!);
        this.setState({
            redirectToTaskId: task.Id,
        });
        return true;
    }
    static displayName = "SendTestEmail";
}
