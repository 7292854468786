/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Callout } from "@octopusdeploy/design-system-components";
import type { WorkerPoolSummaryResource, MachineModelHealthStatus, WorkerPoolResource, DynamicWorkerPoolResource, DynamicWorkerType } from "@octopusdeploy/octopus-server-client";
import { Permission, WorkerPoolType } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import { orderedHealthStatuses } from "~/areas/infrastructure/InfrastructureDetails";
import { DisabledMachineHealthSummaryLink, MachineHealthSummaryLink } from "~/areas/infrastructure/components/MachineHealthSummaryLink/MachineHealthSummaryLink";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import { MissingChip } from "~/components/Chips";
import { Loading } from "~/components/Loading/Loading";
import MarkdownDescription from "~/components/MarkdownDescription";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import type { OverflowMenuNavLink, OverflowMenuGenericItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { Section } from "~/components/Section/Section";
import SimpleExpander from "~/components/SimpleExpander";
import { withTheme } from "~/components/Theme";
import { OctopusIcon, OctopusIconType } from "~/primitiveComponents/dataDisplay/Icon";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import BaseMachinesSummary from "../../BaseMachinesSummary/BaseMachinesSummary";
import type { BaseMachinesSummaryProps, BaseMachinesSummaryState } from "../../BaseMachinesSummary/BaseMachinesSummary";
import { getIsDisabledFilterAsPerPrecedence, getHealthStatusFiltersAsPerPrecedence } from "../../BaseMachinesSummary/BaseMachinesSummaryFilter";
import { DeprecatedDynamicWorkerTypeChip } from "../DeprecatedWorkerType";
import type { WorkerPoolsSummaryFilter } from "./WorkerPoolsSummaryFilter";
import { createWorkerPoolsMachinesArgs } from "./WorkerPoolsSummaryFilter";
import styles from "./style.module.less";
interface WorkerPoolSummarySectionInternalProps extends BaseMachinesSummaryProps {
    workerPoolSummary: WorkerPoolSummaryResource;
    workerTypes: DynamicWorkerType[];
    filter: WorkerPoolsSummaryFilter;
}
class WorkerPoolSummarySectionInternal extends BaseMachinesSummary<WorkerPoolSummarySectionInternalProps, BaseMachinesSummaryState> {
    private requestRaceConditioner = new RequestRaceConditioner();
    constructor(props: WorkerPoolSummarySectionInternalProps) {
        super(props);
        this.state = {
            machinesResponse: null,
            machineHealthStatusFastLookup: {},
            currentPageIndex: 0,
            expanded: false,
            healthStatusFilter: "",
            isDisabledFilter: false,
            redirectToTasks: false,
            endpointRegistrations: [],
        };
    }
    componentDidUpdate(prevProps: WorkerPoolSummarySectionInternalProps) {
        if (this.state.expanded && !isEqual(prevProps.filter, this.props.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }
    render() {
        if (this.state.redirectToTasks) {
            return <InternalRedirect to={links.tasksPage.generateUrl()} push={true}/>;
        }
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        const workerPoolSummary = this.props.workerPoolSummary;
        const machinesHealthLinks = orderedHealthStatuses.map((status) => this.renderMachineSummaryLinks(this.props.workerPoolSummary, status));
        const summaryComponents = [...machinesHealthLinks, this.renderMachineDisabledSummaryLinks(workerPoolSummary)];
        const workerPool = workerPoolSummary.WorkerPool;
        const overflowMenuItems: Array<OverflowMenuNavLink[] | OverflowMenuGenericItem> = [
            [
                ...(workerPoolSummary && this.props.workerPoolSummary.WorkerPool.CanAddWorkers
                    ? [
                        OverflowMenuItems.navItem("Add Worker", links.newWorkerMachineWithWorkerPoolPage.generateUrl({ spaceId: workerPool.SpaceId, workerPoolId: workerPool.Id }), {
                            permission: Permission.WorkerEdit,
                        }),
                    ]
                    : []),
                OverflowMenuItems.navItem("Edit", links.editWorkerPoolPage.generateUrl({ spaceId: workerPool.SpaceId, workerPoolId: workerPool.Id }), {
                    permission: Permission.WorkerEdit,
                }),
            ],
        ];
        // Only show machine-related actions if they actually have some machines in this workerPool.
        if (workerPoolSummary.TotalMachines > 0) {
            if (this.state.machinesResponse) {
                const machineIds = this.state.machinesResponse.Items.map((x) => x.Id);
                overflowMenuItems.push(OverflowMenuItems.item(`Check Health for ${workerPoolSummary.TotalMachines} Worker${workerPoolSummary.TotalMachines === 1 ? "" : "s"}`, () => this.performHealthCheck(workerPool, machineIds), {
                    permission: Permission.WorkerEdit,
                }));
            }
            const tentacleIds = workerPoolSummary.MachineIdsForTentacleUpgrade;
            if (tentacleIds && tentacleIds.length > 0) {
                overflowMenuItems.push(OverflowMenuItems.confirmUpgrade(`Upgrade ${tentacleIds.length} Tentacle${tentacleIds.length === 1 ? "" : "s"} in this Worker Pool`, () => this.performTentacleUpgrade(workerPool), {
                    permission: Permission.WorkerEdit,
                }));
            }
            const calamariIds = workerPoolSummary.MachineIdsForCalamariUpgrade;
            if (calamariIds && calamariIds.length > 0) {
                overflowMenuItems.push(OverflowMenuItems.confirmUpgrade(`Upgrade Calamari on ${calamariIds.length} Deployment Target${calamariIds.length === 1 ? "" : "s"}`, () => this.performCalamariUpgrade(calamariIds), {
                    permission: Permission.WorkerEdit,
                }));
            }
        }
        const workerTypeId = (this.props.workerPoolSummary.WorkerPool as DynamicWorkerPoolResource).WorkerType;
        const workerType = this.props.workerTypes.find((x) => x.Type === workerTypeId);
        const titleContainer = (<div className={styles.cardTitleContainer}>
                <div className={styles.workerPoolIcon}>{this.getWorkerPoolIcon(workerPool)}</div>
                <div className={styles.workerPoolName}>{workerPool.Name}</div>
                {this.props.workerPoolSummary && this.props.workerPoolSummary.WorkerPool.CanAddWorkers && <div className={styles.workerPoolMachinesCount}>({workerPoolSummary.TotalMachines && workerPoolSummary.TotalMachines.toLocaleString()})</div>}
                {this.getDynamicWorkerDescription(workerTypeId, workerType)}
                <DeprecatedDynamicWorkerTypeChip workerType={workerType}/>
                <div className={styles.workerPoolSummaryCounts}>{summaryComponents}</div>
                <div className={styles.workerPoolOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems}/>
                </div>
            </div>);
        return (<Loading key={workerPool.Id} busy={this.state.busy} errors={this.errors}>
                <SimpleExpander errorKey={workerPool.Id} key={workerPool.Id} title={titleContainer} onDidExpand={(expanded) => {
                this.setState({ expanded });
                if (expanded) {
                    this.reloadDataAndCurrentPageIndex();
                }
                else {
                    this.setState({
                        machinesResponse: null,
                        currentPageIndex: 0,
                    });
                }
            }}>
                    {workerPool.Description && (<Section>
                            <MarkdownDescription markup={workerPool.Description}/>
                        </Section>)}
                    {workerPool.CanAddWorkers && this.renderMachinesList(workerPoolSummary)}
                    {workerPool.WorkerPoolType === WorkerPoolType.Dynamic && this.renderDynamicWorkerCallout()}
                </SimpleExpander>
            </Loading>);
    }
    renderDynamicWorkerCallout(): React.ReactNode {
        return (<Callout title={<span>
                        This is a{" "}
                        <ExternalLink href="DynamicWorkerPools" showIcon={true}>
                            Dynamic Worker Pool
                        </ExternalLink>
                    </span>} type={"information"}>
                There are no workers in this worker pool; Octopus will provide workers on-demand during deployments.
            </Callout>);
    }
    getWorkerPoolIcon(workerPool: WorkerPoolResource): React.ReactNode {
        switch (workerPool.WorkerPoolType) {
            case WorkerPoolType.Static:
                return withTheme((theme) => <OctopusIcon iconType={OctopusIconType.WorkerPool} color={theme.iconDark}/>);
            case WorkerPoolType.Dynamic:
                return withTheme((theme) => <OctopusIcon iconType={OctopusIconType.DynamicWorkerPool} color={theme.iconDark}/>);
        }
    }
    protected async loadData() {
        const isDisabled = getIsDisabledFilterAsPerPrecedence(this.props.isFiltering, this.props.filter, this.state.isDisabledFilter);
        const applicableHealthStatusFilters = getHealthStatusFiltersAsPerPrecedence(this.props.filter, this.props.isFiltering, this.state.healthStatusFilter);
        const workerPoolRequestArgs = createWorkerPoolsMachinesArgs(this.props.filter, isDisabled, applicableHealthStatusFilters);
        const promises = Promise.all([repository.WorkerPools.machines(this.props.workerPoolSummary.WorkerPool, workerPoolRequestArgs), endpointRegistry.getAllRegistrations()]);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(promises, ([machinesResponse, endpointRegistrations]) => {
            this.setMachineResponseState(machinesResponse);
            this.setState({ endpointRegistrations });
        });
    }
    private getDynamicWorkerDescription(workerTypeId: string, workerType: DynamicWorkerType | undefined) {
        if (this.props.workerPoolSummary && this.props.workerPoolSummary.WorkerPool.WorkerPoolType === WorkerPoolType.Dynamic) {
            if (workerType) {
                return (<div className={styles.workerPoolMachinesCount}>
                        {workerType.Description}
                        {workerType?.Type.endsWith("Default") ? " (default)" : ""}
                    </div>);
            }
            return (<div className={styles.workerPoolMachinesCount}>
                    <MissingChip lookupId={workerTypeId}></MissingChip>
                </div>);
        }
        return null;
    }
    private handleHealthSummaryLinkSelect = (e: React.MouseEvent, healthStatus: MachineModelHealthStatus) => {
        // The user may click a health status link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        // Clear any disabled filters when a healthStatus filter is clicked. You can't chain inline disabled and healthStatus
        // filters together because they use different and/or logic at the API and it causes UI confusion.
        this.setState({
            healthStatusFilter: healthStatus,
            isDisabledFilter: false,
        }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };
    private handleClearHealthSummaryFilter = (e: React.MouseEvent) => {
        // The user may click a health status link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        this.setState({ healthStatusFilter: "" }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };
    private renderMachineSummaryLinks(workerPoolSummary: WorkerPoolSummaryResource, healthStatus: MachineModelHealthStatus) {
        return (<MachineHealthSummaryLink key={healthStatus} healthStatus={healthStatus} onSelect={this.handleHealthSummaryLinkSelect} onClearSelect={this.handleClearHealthSummaryFilter} getLinkText={(status, count, friendlyName) => `${count.toLocaleString()}  ${friendlyName}`} count={workerPoolSummary.MachineHealthStatusSummaries[healthStatus]} allowSelection={!(this.props.filter.healthStatuses && this.props.filter.healthStatuses.length > 0)} isSelected={this.state.healthStatusFilter === healthStatus}/>);
    }
    private renderMachineDisabledSummaryLinks(workerPoolSummary: WorkerPoolSummaryResource) {
        return (<DisabledMachineHealthSummaryLink key="Disabled" onSelect={this.handleDisabledSummaryLinkSelect} onClearSelect={this.handleClearDisabledSummaryFilter} count={workerPoolSummary.TotalDisabledMachines} allowSelection={!this.props.filter.isDisabled} isSelected={this.state.isDisabledFilter}/>);
    }
    private handleClearDisabledSummaryFilter(e: React.MouseEvent) {
        // The user may click a health status link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        this.setState({ isDisabledFilter: false }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    }
    private handleDisabledSummaryLinkSelect(e: React.MouseEvent) {
        // The user may click a disabled link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        // Clear any healthStatus filters when disabled is clicked. You can't chain inline disabled and healthStatus
        // filters together because they use different and/or logic at the API and it causes UI confusion.
        this.setState({
            isDisabledFilter: true,
            healthStatusFilter: "",
        }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    }
    private async performHealthCheck(workerPool: WorkerPoolResource, machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createPerformHealthCheckTaskForWorkerPool(workerPool, machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    private async performTentacleUpgrade(workerPool: WorkerPoolResource) {
        return this.doBusyTask(async () => {
            await repository.Machines.upgradeAllWorkersInWorkerPool(workerPool.Id);
            this.setState({ redirectToTasks: true });
        });
    }
    private async performCalamariUpgrade(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnWorkersTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
}
type WorkerPoolSummarySectionProps = WorkerPoolSummarySectionInternalProps;
function WorkerPoolSummarySection(props: WorkerPoolSummarySectionProps) {
    return <WorkerPoolSummarySectionInternal {...props}/>;
}
export default WorkerPoolSummarySection;
