import type { GetCommonTemplatesWithValuesBffResponse, GetProjectTemplatesWithValuesBffResponse, ProjectResource } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import type { ProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
import type { TemplatesWarnings } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesNavigationTabs";
import { useCommonTemplatesFilterState } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesFilterState";
import { usePageState } from "~/areas/tenants/components/Paging/usePageState";
import { repository } from "~/clientInstance";
import { type DoBusyTask, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner/index";
interface Actions {
    setPageState: (newPageNumber: number, newPageSize: number) => void;
    setFilter: (newFilter: ProjectTenantVariablesFilterState) => void;
    resetFilter: (newFilter: Partial<ProjectTenantVariablesFilterState>) => void;
    onSave: () => Promise<void>;
}
export interface CommonTemplatesState {
    data: GetCommonTemplatesWithValuesBffResponse | null;
    warnings: TemplatesWarnings;
    pageSize: number;
    filterState: ProjectTenantVariablesFilterState;
    actions: Actions;
}
const pagingSelectionKey = "octoProjectTenantVariablesPagingSelection";
export function useCommonTemplatesState(project: ProjectResource, doBusyTask: DoBusyTask): CommonTemplatesState {
    const [data, setData] = useState<GetCommonTemplatesWithValuesBffResponse | null>(null);
    const [warnings, setWarnings] = useState<TemplatesWarnings>({ projectTemplates: false, commonTemplates: false });
    const [pageState, setPageState] = usePageState(pagingSelectionKey);
    const [filterState, setFilterState] = useCommonTemplatesFilterState();
    const [isInitialPageLoad, setIsInitialPageLoad] = useState<boolean>(true);
    const raceConditionerRef: Readonly<React.MutableRefObject<RequestRaceConditioner>> = React.useRef(new RequestRaceConditioner());
    const actions: Actions = {
        setPageState: (newPageNumber, newPageSize) => {
            setFilterState({ ...filterState, pageNumber: newPageNumber });
            setPageState(filterState.pageNumber, newPageSize);
        },
        setFilter: (newFilter) => setFilterState({ ...newFilter, pageNumber: 1 }),
        resetFilter: (newFilter) => setFilterState({ ...defaultFilter, ...newFilter }),
        onSave: async () => {
            await doBusyTask(async () => {
                await raceConditionerRef.current.avoidStaleResponsesForRequest(repository.Projects.getCommonTemplatesWithValues(project.Id, (filterState.pageNumber - 1) * pageState.pageSize, pageState.pageSize, filterState), (response) => {
                    setData(response);
                });
            });
        },
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        const loadCommonTemplates = () => repository.Projects.getCommonTemplatesWithValues(project.Id, (filterState.pageNumber - 1) * pageState.pageSize, pageState.pageSize, filterState);
        const updateDataState = (commonResponse: GetCommonTemplatesWithValuesBffResponse | null, projectResponse: GetProjectTemplatesWithValuesBffResponse | null) => {
            setData(commonResponse);
            setWarnings((previous) => ({ commonTemplates: commonResponse?.AnyMissingValues ?? previous.commonTemplates, projectTemplates: projectResponse?.AnyMissingValues ?? previous.projectTemplates }));
        };
        if (isInitialPageLoad) {
            const [commonResponse, projectResponse] = await Promise.all([loadCommonTemplates(), repository.Projects.getProjectTemplatesWithValues(project.Id, (filterState.pageNumber - 1) * pageState.pageSize, pageState.pageSize, filterState)]);
            updateDataState(commonResponse, projectResponse);
            setIsInitialPageLoad(false);
        }
        else {
            await raceConditionerRef.current.avoidStaleResponsesForRequest(loadCommonTemplates(), (response) => updateDataState(response, null));
        }
    }, [project, filterState, pageState.pageSize]);
    return { data, warnings, pageSize: pageState.pageSize, filterState, actions };
}
const defaultFilter: ProjectTenantVariablesFilterState = {
    filterByName: undefined,
    filterByEnvironment: undefined,
    filterByExcludedEnvironment: undefined,
    filterByTenant: undefined,
    filterByExcludedTenant: undefined,
    filterByTags: [],
    filterByExcludedTags: [],
    filterByValueStatus: undefined,
    filterByTemplateId: undefined,
    filterByLibraryVariableSetId: undefined,
    pageNumber: 1,
};
