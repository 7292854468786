/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import type { FormFieldProps } from "~/components/form";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import type { Options } from "../../primitiveComponents/form/Select/Options";
interface SelectInputProps extends FormFieldProps<string> {
    resetValue: string;
    options: Options;
    localNames?: string[];
    label: string;
    allowClear: boolean;
    warning?: string;
    defaultValueIndicator: JSX.Element | undefined;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}
const SelectInput: React.FC<SelectInputProps> = (props) => {
    const { value, resetValue, options, localNames, label, allowClear, warning, defaultValueIndicator, doBusyTask, onChange, ...rest } = props;
    const formProps = { ...rest, label };
    return (<React.Fragment>
            <BoundSelect value={value} resetValue={resetValue} variableLookup={{
            localNames,
        }} items={options} warning={warning} allowClear={allowClear} onChange={(x) => onChange(x!)} {...formProps}/>
            {defaultValueIndicator}
        </React.Fragment>);
};
SelectInput.displayName = "SelectInput"
export default SelectInput;
