import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectDeploymentOverview, CommonProjectDeploymentTopics } from "../../CommonComponents/CommonProjectDeploymentViews";
export const ProjectDeploymentNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().release().deployments.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectDeploymentOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectDeploymentTopics />;
    },
};
