import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { repository } from "~/clientInstance";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import Note from "../../../../../primitiveComponents/form/Note/Note";
import InternalLink from "../../../../Navigation/InternalLink/InternalLink";
import HelpImageTenants from "../../Images/Tenant.svg";
import { OverviewContainer, OverviewHighlightText, TermsText, TopicsContainer } from "./CommonViews";
export const CommonTenantOverviewViews = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spaceId = repository.spaceId!;
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    Use <ExternalLink href="MultiTenantDeployments">tenants</ExternalLink> to deploy your projects to multiple end-customers, with the software tailored to each specific customer.
                </p>
                <ImageWithPlaceholder src={HelpImageTenants} alt={"Tenants"}/> {/*//TODO - Jess - I think we need a better Tenant definition here.*/}
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Connecting Projects"}>By connecting tenants to projects you can control which projects will be deployed into which environments. Projects will need to be enabled for multi-tenancy and linked to environments.</Note>
                <Note heading={"Tenant Tag Sets"}>
                    Tagging lets you deal with tenants in groups instead of as individuals. You might have a tenant tag set called `Tenant importance` with tags `VIP`, `Standard` and `Trial`. At deployment time, each of these groups will get a
                    different version of your software. Modify your tag sets in the <InternalLink to={links.tagSetsPage.generateUrl({ spaceId })}>library</InternalLink>.
                </Note>
                <Note heading={"Tenant Variables"}>Tenants inherit Common Variables, values that already exist per project, and Project Variable that need values defined for each tenant.</Note>
            </TermsText>
        </OverviewContainer>);
};
export const CommonTenantTopics = () => {
    return <TopicsContainer />;
};
