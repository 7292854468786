import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import type { GetDeploymentFreezesResponse } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import { useState } from "react";
import { dashboardRefreshIntervalInMs } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import type { RenderDashboardProps } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import type { DataCube } from "~/areas/projects/components/DashboardDataSource/DataCube";
import ProgressionDataCube from "~/areas/projects/components/DashboardDataSource/ProgressionDataCube";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface UntenantedProjectDashboardDataSourceProps {
    project: ProjectResource;
    render: (props: RenderDashboardProps) => JSX.Element;
    doBusyTask: DoBusyTask;
    onDashboardLoad: () => void;
}
export default function UntenantedProjectDashboardDataSource({ doBusyTask, project, render, onDashboardLoad }: UntenantedProjectDashboardDataSourceProps) {
    const [cube, setCube] = useState<DataCube | undefined>(undefined);
    const dashboardRequest = useDoBusyTaskEffect(doBusyTask, async () => {
        setCube(await projectDashboardRefresh(project));
        onDashboardLoad();
    }, [project], { timeOperationOptions: timeOperationOptions.forRefresh() });
    useRefreshLoop(dashboardRequest, dashboardRefreshIntervalInMs);
    return render({ cube });
}
async function projectDashboardRefresh(project: ProjectResource): Promise<DataCube> {
    const dataPromise = repository.Progression.getProgression(project);
    const allChannelsPromise = repository.Channels.allFromProject(project);
    const deploymentFreezesPromise = isFeatureToggleEnabled("DeploymentFreezeFeatureToggle")
        ? repository.DeploymentFreezes.activeByProject(project)
        : new Promise<GetDeploymentFreezesResponse>((resolve, _) => {
            resolve({ DeploymentFreezes: [] });
        });
    const data = await dataPromise;
    const allChannels = await allChannelsPromise;
    const deploymentFreezes = await deploymentFreezesPromise;
    const progressionData = new ProgressionDataCube(data, project, deploymentFreezes.DeploymentFreezes);
    progressionData.addAdditionalChannels(allChannels);
    return progressionData;
}
