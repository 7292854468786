import type { FilterValueStatus } from "@octopusdeploy/octopus-server-client";
import { FilterValueStatusValues } from "@octopusdeploy/octopus-server-client";
import { createQueryParam, numberQueryParam, optionalStringQueryParam, useQueryStringParams } from "@octopusdeploy/portal-routes";
import { useMemo } from "react";
import type { ProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
export function useProjectTemplatesFilterState(): [
    ProjectTenantVariablesFilterState,
    (filter: ProjectTenantVariablesFilterState) => void
] {
    const searchParameter = optionalStringQueryParam("search");
    const environmentParameter = optionalStringQueryParam("environment");
    const excludedEnvironmentParameter = optionalStringQueryParam("excludedEnvironment");
    const tenantParameter = optionalStringQueryParam("tenant");
    const excludedTenantParameter = optionalStringQueryParam("excludedTenant");
    const tagsParameter = optionalStringQueryParam("tags");
    const excludedTagsParameter = optionalStringQueryParam("excludedTags");
    const valueStatusParameter = createQueryParam("valueStatus", validateValueStatus, (v) => v);
    const pageNumberParameter = numberQueryParam("pageNumber");
    const templateIdParameter = optionalStringQueryParam("templateId");
    const [filter, setFilter] = useQueryStringParams([
        searchParameter,
        environmentParameter,
        excludedEnvironmentParameter,
        tenantParameter,
        excludedTenantParameter,
        tagsParameter,
        excludedTagsParameter,
        valueStatusParameter,
        templateIdParameter,
        pageNumberParameter,
    ]);
    function setProjectTenantVariablesFilterState(filter: ProjectTenantVariablesFilterState) {
        setFilter({
            search: filter.filterByName,
            environment: filter.filterByEnvironment,
            excludedEnvironment: filter.filterByExcludedEnvironment,
            tenant: filter.filterByTenant,
            excludedTenant: filter.filterByExcludedTenant,
            tags: filter.filterByTags.length > 0 ? filter.filterByTags.join(",") : undefined,
            excludedTags: filter.filterByExcludedTags.length > 0 ? filter.filterByExcludedTags.join(",") : undefined,
            valueStatus: filter.filterByValueStatus,
            pageNumber: filter.pageNumber,
            templateId: filter.filterByTemplateId,
        });
    }
    const projectTenantVariablesFilterState = useMemo((): ProjectTenantVariablesFilterState => ({
        filterByName: filter.search,
        filterByEnvironment: filter.environment,
        filterByExcludedEnvironment: filter.excludedEnvironment,
        filterByTenant: filter.tenant,
        filterByExcludedTenant: filter.excludedTenant,
        filterByTags: filter.tags ? filter.tags.split(",") : [],
        filterByExcludedTags: filter.excludedTags ? filter.excludedTags.split(",") : [],
        filterByValueStatus: filter.valueStatus,
        filterByTemplateId: filter.templateId,
        filterByLibraryVariableSetId: undefined,
        pageNumber: filter.pageNumber ?? 1,
    }), [filter.search, filter.environment, filter.excludedEnvironment, filter.tenant, filter.excludedTenant, filter.tags, filter.excludedTags, filter.valueStatus, filter.pageNumber, filter.templateId]);
    return [projectTenantVariablesFilterState, setProjectTenantVariablesFilterState];
}
function validateValueStatus(valueStatus: string | undefined): FilterValueStatus | undefined {
    if (valueStatus === undefined)
        return undefined;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const possibleStatus = valueStatus as FilterValueStatus;
    return Object.values(FilterValueStatusValues).includes(possibleStatus) ? possibleStatus : undefined;
}
