import type { VariablesScopedToDocumentResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import { SinglyScopedVariableWarnings } from "~/areas/variables/SinglyScopedVariableWarnings/SinglyScopedVariableWarnings";
import { ScopeType } from "~/areas/variables/VariableSorting/sortVariables";
import { repository } from "~/clientInstance";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
interface DeleteEnvironmentDialogLayoutProps {
    environment: EnvironmentResource;
    onDelete(): void;
}
export const DeleteEnvironmentDialogLayout: React.FC<DeleteEnvironmentDialogLayoutProps> = (props) => {
    const [variablesScopedToThisEnvironment, setVariablesScopedToThisEnvironment] = useState<VariablesScopedToDocumentResource>();
    const [isLoading, setIsLoading] = useState(true);
    const onLoad = async () => {
        const variablesScopedToThisEnvironment = await repository.Environments.variablesScopedOnlyToThisEnvironment(props.environment);
        setVariablesScopedToThisEnvironment(variablesScopedToThisEnvironment);
        setIsLoading(false);
    };
    const onDeleteClick = async () => {
        await repository.Environments.del(props.environment);
        props.onDelete();
        return true;
    };
    return (<DeleteDialogLayout title={"Are you sure you want to delete this environment?"} onDeleteClick={onDeleteClick} onLoad={onLoad} renderContent={() => (<React.Fragment>
                    {variablesScopedToThisEnvironment && <SinglyScopedVariableWarnings variablesScopedToThisDocument={variablesScopedToThisEnvironment} documentType={ScopeType.Environment}/>}
                    {!isLoading && (<React.Fragment>
                            <p>Deleting this environment is permanent. There is no going back.</p>
                            <p>Do you wish to continue?</p>
                        </React.Fragment>)}
                </React.Fragment>)}/>);
};
DeleteEnvironmentDialogLayout.displayName = "DeleteEnvironmentDialogLayout"
