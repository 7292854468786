/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType, Checkbox } from "@octopusdeploy/design-system-components";
import type { ProjectContextRepository, DeploymentPreviewBaseResource, IProcessResource, ISnapshotResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { isReleaseResource, isRunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/ActionList";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import InputWithActions from "~/components/InputWithActions/InputWithActions";
import { Note } from "~/components/form";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import { baseSizeInPx } from "~/fontWeights";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import ActionSummary from "./ActionSummary";
import DeploymentStepsWorker from "./deploymentStepsWorker";
import type { ActionToggleInfo } from "./deploymentStepsWorker";
import styles from "./style.module.less";
interface ActionToggleProps {
    repository: ProjectContextRepository;
    previews: DeploymentPreviewBaseResource[];
    release: ISnapshotResource;
    selectedEnvironmentIds: string[];
    actionIds: string[];
    onActionIdsChanged(excludedActionIds: string[]): void;
    project: ProjectResource;
    gitRef?: string;
}
interface ActionToggleState extends DataBaseComponentState {
    process: IProcessResource;
    searchText: string;
}
export default class ActionToggle extends DataBaseComponent<ActionToggleProps, ActionToggleState> {
    private requestRaceConditioner = new RequestRaceConditioner();
    constructor(props: ActionToggleProps) {
        super(props);
        this.state = {
            process: null!,
            searchText: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            await this.requestRaceConditioner.avoidStaleResponsesForRequest((async () => {
                if (isReleaseResource(this.props.release)) {
                    const deploymentProcess = await this.props.repository.DeploymentProcesses.getForRelease(this.props.release);
                    return { process: deploymentProcess };
                }
                else if (isRunbookSnapshotResource(this.props.release)) {
                    const runbookProcess = await repository.Runbooks.getRunbookProcess(this.props.project, this.props.release.FrozenRunbookProcessId, this.props.gitRef);
                    return { process: runbookProcess };
                }
            })(), (response) => this.setState(response!), (response) => this.setState({ process: response!.process }));
        });
    }
    async UNSAFE_componentWillReceiveProps(nextProps: ActionToggleProps) {
        if (_.isEqual(this.props.selectedEnvironmentIds, nextProps.selectedEnvironmentIds) && _.isEqual(this.props.previews, nextProps.previews)) {
            return;
        }
        if (!(nextProps.selectedEnvironmentIds && nextProps.selectedEnvironmentIds.length > 0)) {
            this.setState({ searchText: "" });
        }
    }
    render() {
        const actions = this.getEnabledStepActions(this.props.previews);
        const filteredActions = this.state.searchText ? actions.filter((s) => s.details.ActionName.toLowerCase().includes(this.state.searchText.toLowerCase())) : actions;
        const noneButton = <ActionButton label="Deselect all" title="Clears all excluded steps" type={ActionButtonType.Ternary} onClick={this.deselectAll}/>;
        const allButton = (<ActionButton label={this.state.searchText ? "Select all filtered" : "Select all"} title={this.state.searchText ? `Selects the ${filteredActions.length} visible step${filteredActions.length === 1 ? "" : "s"} only, clearing other selections` : "Excludes all steps"} type={ActionButtonType.Ternary} onClick={() => this.selectAll(filteredActions)}/>);
        return (<ExpandableFormSection title="Excluded steps" errorKey="steps" help="Select steps to exclude." summary={this.buildSummary(actions)}>
                {this.props.selectedEnvironmentIds.length === 0 ? (<Note>Please select an environment to see applicable steps.</Note>) : (<div className={styles.stepToggle}>
                        <InputWithActions maxWidthInRem={baseSizeInPx} input={<FilterSearchBox placeholder="Filter by name..." value={this.state.searchText} onChange={this.handleSearchTextChanged}/>} actions={<ActionList actions={[allButton, noneButton]}/>}/>

                        {this.props.selectedEnvironmentIds.length > 0 && actions.length === 0 && <Note>No enabled steps returned.</Note>}

                        <div className={styles.note}>
                            <Note>{`${this.props.actionIds.length === 0 ? "No" : this.props.actionIds.length} step${this.props.actionIds.length === 1 ? " is" : "s are"} currently excluded.`}</Note>
                        </div>

                        <div className={styles.stepCheckboxes}>
                            {this.state.process &&
                    filteredActions.map((action) => {
                        const isExcluded = this.props.actionIds.some((ac) => ac === action.Id);
                        return (<Checkbox key={action.Id} label={<ActionSummary action={action} process={this.state.process} actionIsExcluded={isExcluded}/>} value={isExcluded} onChange={(value) => this.onActionToggled(value, action.Id)} disabled={!action.details.CanBeSkipped} note={!action.details.CanBeSkipped ? "This step is marked as required" : undefined}/>);
                    })}
                        </div>
                    </div>)}
            </ExpandableFormSection>);
    }
    private selectAll = (visibleActions: ActionToggleInfo[]) => {
        const excludedActionIds: string[] = visibleActions.filter((a) => a.details.CanBeSkipped).map((a) => a.Id);
        this.props.onActionIdsChanged(excludedActionIds);
    };
    private deselectAll = () => {
        this.props.onActionIdsChanged([]);
    };
    private buildSummary(actions: ActionToggleInfo[]) {
        if (actions && actions.length > 0 && this.props.actionIds && this.props.actionIds.length > 0) {
            const numOfExcludedActions = this.props.actionIds.length;
            const total = actions.length;
            return Summary.summary(this.buildSummaryContent(numOfExcludedActions, total));
        }
        else {
            return Summary.default("No steps excluded");
        }
    }
    private getEnabledStepActions(previews: DeploymentPreviewBaseResource[]) {
        const steps = DeploymentStepsWorker.getDeploymentSteps(previews);
        return steps.filter((x) => x.details.IsDisabled === false);
    }
    private onActionToggled = (checked: boolean, val: string) => {
        const excludedActionIds = checked ? this.props.actionIds.concat(val) : this.props.actionIds.filter((s) => s !== val);
        this.props.onActionIdsChanged(excludedActionIds);
    };
    private handleSearchTextChanged = (searchText: string) => {
        this.setState({ searchText });
    };
    private buildSummaryContent = (numOfExcludedActions: number, total: number) => {
        return (<div>
                {numOfExcludedActions} out of {total} {total > 1 ? "steps" : "step"} are excluded from the process
            </div>);
    };
    static displayName = "ActionToggle";
}
