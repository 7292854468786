import { css } from "@emotion/css";
import { EmptyStateDefaultIllustration } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import type { PropsWithChildren } from "react";
import * as React from "react";
import styles from "~/components/ProjectBasedActivation/styles.module.less";
interface OnboardingBlankPageProps {
    title: string;
    description?: React.ReactNode | string;
    image?: React.ReactElement;
    learnMore?: React.ReactNode;
    actionButtons?: React.ReactNode;
}
export default (props: PropsWithChildren<OnboardingBlankPageProps>) => {
    return (<div className={styles.blankPageOuterContainer}>
            <div className={cn(styles.blankPageContentContainer)}>
                {props.title && (<div>
                        <h2 className={styles.header}>{props.title}</h2>
                        {props.description && <div className={styles.description}>{props.description}</div>}
                        <strong>{props.learnMore && <div className={styles.externalLinks}>{props.learnMore}</div>}</strong>
                        {props.actionButtons}
                    </div>)}
            </div>
            <div className={illustrationWrapperStyles}>{props.image ?? <EmptyStateDefaultIllustration />}</div>
        </div>);
};
const illustrationWrapperStyles = css({
    width: "16.25rem",
    height: "16.25rem",
});
