import { isEqual } from "lodash";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { RunbookContextProps } from "./RunbookContext";
import { RunbookContextProvider } from "./RunbookContext";
//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RunbookContextLayoutState extends DataBaseComponentState {
}
interface RunbookContextLayoutRouteProps {
    runbookId: string;
}
type RunbookContextLayoutProps = RunbookContextLayoutRouteProps & {
    children: (context: RunbookContextProps) => React.ReactNode;
};
class RunbookContextLayout extends DataBaseComponent<RunbookContextLayoutProps, RunbookContextLayoutState> {
    shouldComponentUpdate(nextProps: RunbookContextLayoutProps) {
        return !isEqual(nextProps.runbookId, this.props.runbookId);
    }
    render() {
        return (<RunbookContextProvider id={this.props.runbookId} doBusyTask={this.doBusyTask}>
                {(runbookContext) => {
                return this.props.children(runbookContext);
            }}
            </RunbookContextProvider>);
    }
    static displayName = "RunbookContextLayout";
}
export default RunbookContextLayout;
