import type { ChannelVersionRuleResource, DeploymentActionPackageResource } from "@octopusdeploy/octopus-server-client";
import { displayName } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import styles from "./style.module.less";
interface RulesDescriptionListProps {
    rules: ChannelVersionRuleResource[];
}
const RulesDescriptionList: React.StatelessComponent<RulesDescriptionListProps> = (props) => {
    const buildActionsJsx = (actionPackages: DeploymentActionPackageResource[]) => {
        const nodes = [];
        for (let idx = 0; idx < actionPackages.length; idx++) {
            const actionPackage = actionPackages[idx];
            if (idx > 0) {
                if (actionPackages.length === idx + 1) {
                    nodes.push(" and ");
                }
                else {
                    nodes.push(", ");
                }
            }
            nodes.push(<strong key={idx}>{displayName(actionPackage)}</strong>);
        }
        if (actionPackages.length > 1) {
            nodes.push(" steps");
        }
        else {
            nodes.push(" step");
        }
        return nodes;
    };
    return (<ol className={styles.rulesList}>
            {props.rules.map((rule) => (<li key={rule.Id}>
                    <span>Applies to </span>
                    {React.Children.toArray(buildActionsJsx(rule.ActionPackages))} with a version range matching <strong>{rule.VersionRange}</strong>
                    {rule.Tag && (<span>
                            {" "}
                            and a pre-release tag matching <strong>{rule.Tag}</strong>
                        </span>)}
                </li>))}
        </ol>);
};
RulesDescriptionList.displayName = "RulesDescriptionList"
export default RulesDescriptionList;
