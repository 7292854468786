/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { GitCredentialResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import Onboarding from "~/areas/library/components/GitCredentials/Onboarding";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import MarkdownDescription from "~/components/MarkdownDescription";
import List from "~/components/PagingList";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
class GitCredentialList extends List<GitCredentialResource> {
}
interface GitCredentialsPagePropsInternal extends GitCredentialsPageProps {
    dispatchAction: AnalyticActionDispatcher;
}
interface GitCredentialsPageState extends DataBaseComponentState {
    gitCredentials: ResourceCollection<GitCredentialResource>;
}
class GitCredentialsPageInternal extends DataBaseComponent<GitCredentialsPagePropsInternal, GitCredentialsPageState> {
    constructor(props: GitCredentialsPagePropsInternal) {
        super(props);
        this.state = {
            gitCredentials: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async load() {
        const gitCredentials = await repository.GitCredentials.list();
        this.setState({
            gitCredentials,
        });
    }
    filter(filter: string, gitCredential: GitCredentialResource) {
        if (!filter || filter.length === 0 || !gitCredential) {
            return true;
        }
        return gitCredential.Name.toLowerCase().includes(filter.toLowerCase());
    }
    buildGitCredentialRow(gitCredential: GitCredentialResource) {
        return (<div>
                <ListTitle>{gitCredential.Name}</ListTitle>
                <MarkdownDescription markup={gitCredential.Description!}/>
            </div>);
    }
    render() {
        const addGitCredentialPageAction: PrimaryPageAction = {
            type: "navigate",
            hasPermissions: isAllowed({ permission: Permission.GitCredentialEdit }),
            label: "Add Git Credential",
            path: links.createGitCredentialPage.generateUrl({ spaceId: this.props.spaceId }),
            onClick: () => this.props.dispatchAction("Add Git Credential", { action: Action.Add, resource: "Git Credential", location: "Page" }),
        };
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayoutVNext title="Git Credentials" primaryAction={addGitCredentialPageAction} busy={this.state.busy} errors={this.errors}>
                    {this.state.gitCredentials && (<>
                            {this.state.gitCredentials.TotalResults == 0 && <Onboarding />}
                            {this.state.gitCredentials.TotalResults > 0 && (<GitCredentialList initialData={this.state.gitCredentials} onRow={(item) => this.buildGitCredentialRow(item)} onRowRedirectUrl={(item) => links.editGitCredentialPage.generateUrl({ spaceId: this.props.spaceId, gitCredentialId: item.Id })} onFilter={this.filter} apiSearchParams={["name"]} filterSearchEnabled={true} filterHintText="Filter by name..."/>)}
                        </>)}
                </PaperLayoutVNext>
            </LibraryLayout>);
    }
    static displayName = "GitCredentialsPageInternal";
}
interface GitCredentialsPageProps {
    spaceId: string;
}
export function GitCredentialsPage({ spaceId }: GitCredentialsPageProps) {
    const dispatchAction = useAnalyticActionDispatch();
    return <GitCredentialsPageInternal spaceId={spaceId} dispatchAction={dispatchAction}/>;
}
