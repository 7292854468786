import { Callout } from "@octopusdeploy/design-system-components";
import React from "react";
type TargetTagsExplainerProps = {
    hide: boolean;
    isKubernetesStep?: boolean;
    isGuideSetup?: boolean;
    onDismiss: () => void;
};
export function TargetTagsExplainer({ isKubernetesStep = false, onDismiss, hide }: TargetTagsExplainerProps) {
    if (hide)
        return null;
    return (<>
            <Callout type="information" title={"New concept"} canClose onClose={onDismiss}>
                During a deployment, Octopus will run this step for each{" "}
                {isKubernetesStep ? (<span>
                        Kubernetes cluster (known as <em>deployment targets</em>)
                    </span>) : (<span>
                        <em>deployment target</em>
                    </span>)}{" "}
                with any of the matching target tags. Deploying to additional {isKubernetesStep ? "clusters" : "targets"} is as simple as tagging them.
            </Callout>
            <br />
        </>);
}
