export const VariableNames = {
    Aws: {
        Account: "Octopus.Aws.Account",
        Regions: "Octopus.Aws.Regions",
        RoleArn: "Octopus.Aws.AssumedRole.Arn",
        RoleSessionName: "Octopus.Aws.AssumedRole.SessionName",
        RoleSessionDuration: "Octopus.Aws.AssumedRole.SessionDuration",
        RoleExternalId: "Octopus.Aws.AssumedRole.ExternalId",
    },
    Azure: {
        Account: "Octopus.Azure.Account",
    },
};
