import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import React from "react";
import { ExpandableFormSection, Summary } from "~/components/form";
type NewProjectDashboardDataSourceProps = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};
export function ProjectsPageOptimization({ isEnabled, onChange }: NewProjectDashboardDataSourceProps) {
    return (<ExpandableFormSection key="IsProjectsPageOptimizationEnabled" errorKey="IsProjectsPageOptimizationEnabled" title="Projects Page Optimization" summary={isEnabled ? Summary.summary("Enabled") : Summary.default("Disabled")} help="Changes the projects page to use new optimized endpoint.">
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <BooleanRadioButton value={true} label="Enabled"/>
                <BooleanRadioButton value={false} label="Disabled" isDefault={true}/>
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>);
}
