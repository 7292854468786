import { isEqual } from "lodash";
import { useMemo, useRef } from "react";
/**
 * Memoize a value using deep comparison.
 * This hook should be used very sparingly - usually there is a better way to memoize
 * using primitive values or object references instead.
 * @param value
 */
export function useDeepCompareMemo<T>(value: T) {
    const ref = useRef<T>(value);
    const signalRef = useRef<number>(0);
    if (!isEqual(value, ref.current)) {
        ref.current = value;
        signalRef.current += 1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => ref.current, [signalRef.current]);
}
