import { WorkerPoolType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { WorkerPoolChip } from "~/components/Chips";
import type { WorkerPoolIndex } from "../workerPools";
interface ReadonlyWorkerPoolProps {
    poolIndex?: WorkerPoolIndex;
    poolId: string;
    poolName?: string;
    poolType?: WorkerPoolType;
}
const ReadonlyWorkerPool: React.SFC<ReadonlyWorkerPoolProps> = (props) => {
    let poolName = props.poolName;
    let poolType = props.poolType;
    const poolResource = props.poolIndex && props.poolIndex[props.poolId];
    if (!poolName) {
        poolName = poolResource ? poolResource.Name : props.poolId;
    }
    if (!poolType) {
        poolType = poolResource ? poolResource.WorkerPoolType : WorkerPoolType.Static;
    }
    return <WorkerPoolChip workerPoolName={poolName} workerPoolType={poolType} key={props.poolId}/>;
};
ReadonlyWorkerPool.displayName = "ReadonlyWorkerPool"
export default ReadonlyWorkerPool;
