import React from "react";
import SimpleDataTable from "~/components/SimpleDataTable";
import type { ProfilingPageSession } from "./ProfilerCache/types";
import styles from "./RequestList.module.less";
interface ProfilingPageSessionListProps {
    profilingPageSessions: ReadonlyArray<ProfilingPageSession>;
    onSelect: (page: ProfilingPageSession) => void;
}
const BlankCell = "";
export function ProfilingPageSessionList({ profilingPageSessions, onSelect }: ProfilingPageSessionListProps) {
    return (<SimpleDataTable headerColumnClassNames={[styles.durationHeader, styles.sqlTimingsHeader]} data={profilingPageSessions} headerColumns={["Request Count", "Page"]} onRow={(item) => [
            item.trackedRequestProfilers.length,
            <a href={"#"} onClick={(e) => {
                    e.preventDefault();
                    onSelect(item);
                }}>
                    {item.page.Name}
                </a>,
        ]}/>);
}
