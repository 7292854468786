import * as React from "react";
import styles from "./style.module.less";
interface DisplayPropertiesProps {
    properties: ReadonlyArray<KeyValuePair>;
}
interface KeyValuePair {
    key: string;
    value: string | React.ReactElement | React.ReactFragment;
}
export default class DisplayProperties extends React.Component<DisplayPropertiesProps> {
    render() {
        return (<div>
                {this.props.properties.map((p) => {
                return (<div key={p.key} className={styles.row}>
                            <span className={styles.key}>{p.key}</span>
                            {": "}
                            {p.value}
                        </div>);
            })}
            </div>);
    }
    static displayName = "DisplayProperties";
}
