import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import image from "../../Images/Lifecycle.svg";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
const video = {
    youtubeId: "ofc-u61ukRA",
    shortlink: "OnboardingLifecyclesVideo",
};
export const CommonLibraryLifecycleOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    <ExternalLink href="OnboardingLifecycles">Lifecycles</ExternalLink> give you control over the way releases are promoted between environments.
                </p>
                <ImageWithPlaceholder src={image} alt={"Lifecycle"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
