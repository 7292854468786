export default class NameDuplicationHelper {
    public static nameTheCopyWithoutCollision(name: string, checkThese: string[]) {
        let plannedName = NameDuplicationHelper.nameTheCopy(name);
        let collisionIndex = checkThese.findIndex((f) => f === plannedName);
        while (collisionIndex !== -1) {
            plannedName = NameDuplicationHelper.nameTheCopy(checkThese[collisionIndex]);
            collisionIndex = checkThese.findIndex((f) => f === plannedName);
        }
        return plannedName;
    }
    public static nameTheCopy(name: string) {
        // TODO: nick, write some tests around this fragile code
        // beware of dragons
        const copySuffix = " - Copy";
        const lastIndexOfCopy = name.lastIndexOf(copySuffix);
        const lastIndexOfCopyWithN = name.lastIndexOf(" - Copy (");
        if (lastIndexOfCopyWithN !== -1) {
            const end = name.substr(lastIndexOfCopyWithN, name.length);
            const extracted = end.split(")")[0].split("(")[1];
            const copyNum = Number(extracted.trim());
            // the trim check is to cater for "var - Copy ( 4 )" which is a user manipulated one
            if (Number.isInteger(copyNum) && extracted.trim() === extracted && copyNum > 1) {
                return `${name.substr(0, lastIndexOfCopyWithN)} - Copy (${copyNum + 1})`;
            }
            else {
                // this is if the user is using the ` - Copy(??)` style will be preserved by a new suffix
                // example user has a name called "haha - Copy (takeThat!)"
                return `${name} - Copy (2)`;
            }
        }
        else if (lastIndexOfCopy !== -1) {
            return `${name.substr(0, lastIndexOfCopy)} - Copy (2)`;
        }
        return `${name}${copySuffix}`;
    }
}
