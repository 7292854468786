import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import EditScheduledTrigger from "../../Triggers/Scheduled/EditScheduledTriggers";
interface EditRunbookScheduledTriggersPageProps {
    spaceId: string;
    projectSlug: string;
    triggerId: string;
}
export function EditRunbookScheduledTriggersPage({ spaceId, projectSlug, triggerId }: EditRunbookScheduledTriggersPageProps) {
    return <EditScheduledTrigger create={false} triggerActionCategory={TriggerActionCategory.Runbook} triggerId={triggerId} spaceId={spaceId} projectSlug={projectSlug}/>;
}
