import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface DataTableRowHeaderColumnProps {
    className?: string;
    inSidebar?: boolean;
}
export class DataTableRowHeaderColumn extends React.Component<DataTableRowHeaderColumnProps> {
    render() {
        const { className, inSidebar, ...otherProps } = this.props;
        return (<th className={cn(className, styles.dataTableRowHeaderColumn, inSidebar ? styles.dataTableRowHeaderColumnInSidebar : null)} {...otherProps}>
                {this.props.children}
            </th>);
    }
    static displayName = "DataTableRowHeaderColumn";
}
