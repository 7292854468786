import { NavigationButton } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
export interface OnboardingProps {
    project: ProjectResource;
}
export default function NoCommonTemplateValuesOnboarding({ project }: OnboardingProps) {
    return (<OnboardingPage title="Connect tenants to set values for your common template variables" intro={<div>Common template values from the included variable sets are applied to each tenant connected to this project. To set values, you'll need to connect tenants to at least one environment in this project.</div>} actionButtons={<NavigationButton label="Manage Tenants" href={links.projectTenantsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}/>} learnMore={<ExternalLink href="MultiTenantVariables">Learn more</ExternalLink>}/>);
}
