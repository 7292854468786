import type { TriggerScheduleIntervalResource } from "@octopusdeploy/octopus-server-client";
import { TriggerScheduleIntervalType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
export default class ScheduleIntervalDescriptionHelper {
    constructor(private readonly interval: TriggerScheduleIntervalResource) { }
    getSummary(emptyText: string) {
        switch (this.interval.Interval) {
            case TriggerScheduleIntervalType.OnceDaily:
                return (<span>
                        Runs <strong>once</strong>
                    </span>);
            case TriggerScheduleIntervalType.OnceHourly:
                return (<span>
                        Runs every <strong>{this.interval.HourInterval === 1 ? "hour" : `${this.interval.HourInterval} hours`}</strong>
                    </span>);
            case TriggerScheduleIntervalType.OnceEveryMinute:
                return (<span>
                        Runs every <strong>{this.interval.MinuteInterval === 1 ? "minute" : `${this.interval.MinuteInterval} minutes`}</strong>
                    </span>);
            default:
                return emptyText || "Please select the triggers schedule";
        }
    }
}
