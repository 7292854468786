import * as React from "react";
import styles from "./tenantVariableName.module.less";
interface TenantVariableNameProps {
    name: string;
}
export function TenantVariableName({ name }: TenantVariableNameProps) {
    return (<div className={styles.name} title={name}>
            #{`{${name}}`}
        </div>);
}
