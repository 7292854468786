import * as React from "react";
import ExternalLink from "../../../../../../../components/Navigation/ExternalLink";
import { buildServerPackagingInstruction } from "../../Instructions";
import { CommonBuildServerPackagingTextForOctoExe, CommonBuildServerPackagingTextForIntegration, CommonTextForJavaSample } from "../CommonRegistrationText";
export const BuildServerPackaging: React.SFC<{}> = () => (<div>
        <p>
            When packaging Java applications from build servers, we recommend using <i>Octo.exe</i>.
        </p>
        {CommonTextForJavaSample}
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
        <p>
            The <ExternalLink href="GuidesJava">Java guides</ExternalLink> provide step by step instructions for deploying Java applications.
        </p>
    </div>);
BuildServerPackaging.displayName = "BuildServerPackaging"
export default buildServerPackagingInstruction(() => <BuildServerPackaging />);
