import type { PossiblyLoadedTrackedRequestProfiler, TrackedRequestProfilerResponse, TrackedRequestProfilerRequest } from "./types";
export function isUnresolvedProfilerRequest(data: PossiblyLoadedTrackedRequestProfiler): data is TrackedRequestProfilerRequest {
    if (!data) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const converted = data as TrackedRequestProfilerRequest;
    return converted.type === "unresolved-request";
}
export function isResolvedProfilerRequest(data: PossiblyLoadedTrackedRequestProfiler): data is TrackedRequestProfilerResponse {
    if (!data) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const converted = data as TrackedRequestProfilerResponse;
    return converted.type === "resolved-request";
}
