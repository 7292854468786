import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { PropertyValueResource, ActionTemplateParameterResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { CommonTemplatesValueChangeTracker, CommonTemplatesValueKey } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesValueChangeTracker";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import type { SourceItems } from "~/components/TenantVariableInput/TenantVariableInput";
import TenantVariableInput from "~/components/TenantVariableInput/TenantVariableInput";
interface CommonTemplateValueEditProps {
    template: ActionTemplateParameterResource;
    value: PropertyValueResource | undefined;
    sourceItems: SourceItems;
    doBusyTask: DoBusyTask;
    valueTracker: CommonTemplatesValueChangeTracker;
    valueKey: CommonTemplatesValueKey;
    bindableTemplateNames: string[];
    initialValue: PropertyValueResource | undefined;
}
export default function CommonTemplateValueEdit({ template, value, sourceItems, doBusyTask, valueTracker, valueKey, bindableTemplateNames, initialValue }: CommonTemplateValueEditProps) {
    const handleChange = (newValue: PropertyValueResource | undefined) => {
        if (newValue === initialValue) {
            valueTracker.remove(valueKey);
        }
        else {
            valueTracker.set(valueKey, newValue);
        }
    };
    return (<div className={editStyles.container}>
            <TenantVariableInput variableTemplate={template} sourceItems={sourceItems} localNames={bindableTemplateNames} doBusyTask={doBusyTask} value={value} warning={template.DefaultValue || value ? undefined : "Value required for deployment"} onChange={(newValue) => handleChange(newValue)} hideSearch={true} autoFocus={true}/>
        </div>);
}
const editStyles = {
    container: css({
        padding: `${space[12]} ${space[12]} ${space[4]} ${space[12]}`,
    }),
};
