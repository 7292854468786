import { MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { HasWarningsMachineIcon } from "./HasWarningsMachineIcon";
import { HealthyMachineIcon } from "./HealthyMachineIcon";
import type { IconSize } from "./IconSize";
import { UnavailableMachineIcon } from "./UnavailableMachineIcon";
import { UnhealthyMachineIcon } from "./UnhealthyMachineIcon";
import { UnknownMachineIcon } from "./UnknownMachineIcon";
interface MachineHealthStatusIconProps {
    healthStatus: MachineModelHealthStatus;
    title?: string;
    iconSize?: IconSize;
}
export function MachineHealthStatusIcon({ healthStatus, title, iconSize }: MachineHealthStatusIconProps) {
    switch (healthStatus) {
        case MachineModelHealthStatus.Healthy:
            return <HealthyMachineIcon title={title} iconSize={iconSize}/>;
        case MachineModelHealthStatus.HasWarnings:
            return <HasWarningsMachineIcon title={title} iconSize={iconSize}/>;
        case MachineModelHealthStatus.Unhealthy:
            return <UnhealthyMachineIcon title={title} iconSize={iconSize}/>;
        case MachineModelHealthStatus.Unavailable:
            return <UnavailableMachineIcon title={title} iconSize={iconSize}/>;
    }
    return <UnknownMachineIcon title={title} iconSize={iconSize}/>;
}
