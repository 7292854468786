import type { RequiredRouteParameter, RouteTemplate } from "@octopusdeploy/portal-routes";
import { isRouteParameter } from "@octopusdeploy/portal-routes";
export function formatRoutePath<T>(routeTemplate: RouteTemplate<T>) {
    return routeTemplate.map((part) => (typeof part === "string" ? part : isRouteParameter(part) ? formatRouteParameter(part) : optionalSpaceIdForSystemRoutes)).join("");
}
function formatRouteParameter<T extends string>(routeParameter: RequiredRouteParameter<T>) {
    // React router route parameters are prefixed with a ":"
    const matchRegex = routeParameter.matchRegExp ? `(${routeParameter.matchRegExp})` : "";
    return `:${routeParameter.name}${matchRegex}`;
}
const optionalSpaceIdForSystemRoutes = "/:spaceId(Spaces-[0-9]+)?";
