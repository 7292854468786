import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { AddProjectTemplateButton } from "~/areas/projects/components/Variables/TenantVariables/AddProjectTemplateButton";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
interface NoTemplatesOnboardingProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    onAddTemplate: (text: string) => void;
}
export default function NoTemplatesOnboarding({ project, doBusyTask, onAddTemplate }: NoTemplatesOnboardingProps) {
    return (<OnboardingPage title="Configure tenant-specific variables" intro={<div>Project templates allow you to specify variables that can have different values per tenant/environment combination.</div>} actionButtons={<AddProjectTemplateButton project={project} doBusyTask={doBusyTask} openSnackbar={onAddTemplate}/>} learnMore={<ExternalLink href="MultiTenantVariables">Learn more</ExternalLink>}/>);
}
