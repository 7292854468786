import type { PageAction } from "@octopusdeploy/design-system-components";
import { Callout } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { AuditArchiveResource, PagingCollection } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import DeleteDialog from "~/components/Dialog/DeleteDialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import PagingDataTable from "~/components/PagingDataTable";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import PermissionCheck, { hasPermission, isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { Text } from "~/components/form";
import ByteSizeFormatter from "~/utils/ByteSizeFormatter/ByteSizeFormatter";
import DateFormatter from "~/utils/DateFormatter";
import Empty from "./Empty";
const title = "Manage archived audit logs";
interface AuditArchivePageState extends DataBaseComponentState {
    selected: Array<AuditArchiveResource>;
    confirmationInputValue: string;
    isSure: boolean;
    initialData?: PagingCollection<AuditArchiveResource>;
    currentItems: Array<AuditArchiveResource>;
}
interface AuditArchiveInternalPageProps {
    deleteAuditDialogControls: DialogControls;
}
export function AuditArchivePage() {
    const deleteAuditDialogControls = useDialogTrigger();
    return <AuditArchiveInternalPage deleteAuditDialogControls={deleteAuditDialogControls}/>;
}
class AuditArchiveInternalPage extends DataBaseComponent<AuditArchiveInternalPageProps, AuditArchivePageState> {
    private readonly headerColumns = ["Audit log file name", "Archived on", "Size"];
    private deleteConfirmPhrase: string = "delete audit log files";
    constructor(props: AuditArchiveInternalPageProps) {
        super(props);
        this.state = {
            selected: [],
            confirmationInputValue: "",
            isSure: false,
            currentItems: [],
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.reload());
    }
    render() {
        const pageActions: PageAction[] = [
            {
                type: "button",
                label: "Delete selected",
                buttonType: "destructive",
                hasPermissions: isAllowed({
                    permission: Permission.EventRetentionDelete,
                    wildcard: true,
                }),
                disabled: !this.state.selected.length,
                onClick: this.props.deleteAuditDialogControls.openDialog,
            },
        ];
        return (<PaperLayoutVNext busy={this.state.busy} errors={this.errors} pageActions={pageActions} title={title} breadcrumbsItems={[{ label: "Audit", pageUrl: links.auditPage.generateUrl() }]}>
                <PermissionCheck permission={Permission.EventRetentionView} wildcard={true} alternate={<Callout type={"information"} title={"Permission required"}>
                            The {Permission.EventRetentionView} permission is required to view the audit log
                        </Callout>}>
                    {this.renderDialogs()}
                    {this.renderContent()}
                </PermissionCheck>
            </PaperLayoutVNext>);
    }
    private renderContent() {
        if (this.state.initialData && this.state.initialData?.TotalResults === 0) {
            return <Empty />;
        }
        return (this.state.initialData && (<PagingDataTable<AuditArchiveResource> showPagingInNumberedStyle={true} headerColumns={this.headerColumns} initialData={this.state.initialData} onRow={(row) => this.onRow(row)} onItemsChecked={hasPermission(Permission.EventRetentionDelete) ? (checked) => this.onItemsChecked(checked) : undefined} onNewItems={async (items) => {
                this.setState({ currentItems: items });
                return items;
            }}/>));
    }
    private onRow(resource: AuditArchiveResource) {
        return [
            <a href={resource.Links.Self}>
                <em className="fa-solid fa-download"/>
                &nbsp;
                {resource.Name}
            </a>,
            DateFormatter.dateToShortFormat(resource.ModifiedDate),
            ByteSizeFormatter(resource.FileBytes),
        ];
    }
    private renderDialogs() {
        const multipleSelected = this.state.selected.length > 1;
        return (<DeleteDialog open={this.props.deleteAuditDialogControls.isOpen} onClose={() => {
                this.props.deleteAuditDialogControls.closeDialog();
                this.setState({ confirmationInputValue: "", isSure: false });
            }} title="Are you sure you want to delete audit log files?" deleteButtonDisabled={!this.state.isSure} onDeleteClick={() => this.delete()} renderContent={() => (<>
                        <Callout title="This is a destructive action" type={"danger"}>
                            This action <strong>cannot</strong> be undone. This will permanently delete the selected audit log {multipleSelected ? "files" : "file"}.
                        </Callout>
                        <div>
                            <p>
                                Please type in <strong>{this.deleteConfirmPhrase}</strong> to confirm.
                            </p>
                            <Text value={this.state.confirmationInputValue} onChange={this.confirmationOnChange}/>
                        </div>
                    </>)}/>);
    }
    private confirmationOnChange = (value: string) => {
        const isSure = value === this.deleteConfirmPhrase;
        this.setState({ confirmationInputValue: value, isSure });
    };
    private onItemsChecked(selected: string[]) {
        const selectedIds = new Set(selected);
        this.setState({
            selected: this.state.currentItems.filter((resource) => selectedIds.has(resource.Id)),
        });
    }
    private async reload() {
        const archivesResponse = await repository.AuditArchive.list();
        this.setState({
            initialData: archivesResponse,
        });
    }
    private async delete() {
        await this.doBusyTask(async () => {
            await Promise.all(this.state.selected.map(async (resource) => await repository.AuditArchive.del(resource)));
            await this.reload();
        });
        return true;
    }
    static displayName = "AuditArchiveInternalPage";
}
