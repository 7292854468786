/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import BaseComponent from "~/components/BaseComponent";
import Number from "~/primitiveComponents/form/Number/Number";
import TimeSpanHelper from "~/utils/TimeSpanHelper";
import type FormFieldProps from "../FormFieldProps";
import styles from "./style.module.less";
interface TimeSpanSelectorProps extends FormFieldProps<string> {
    granularity?: string;
    error?: string;
}
class TimeSpanSelector extends BaseComponent<TimeSpanSelectorProps, {}> {
    public static DayGranularity: string = "dd";
    public static HourGranularity: string = "hh";
    public static MinuteGranularity: string = "mm";
    public static SecondGranularity: string = "ss";
    public static DayHourMinuteGranularity: string = "dd:hh:mm";
    public static HourMinuteSecondGranularity: string = "hh:mm:ss";
    public static MinuteSecondGranularity: string = "mm:ss";
    getGranularityOrDefault() {
        return this.props.granularity || TimeSpanSelector.DayHourMinuteGranularity;
    }
    render() {
        const { value } = this.props;
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(value);
        const granularity = this.getGranularityOrDefault();
        return (<>
                <div className={styles.container}>
                    {granularity.includes(TimeSpanSelector.DayGranularity) && (<div className={styles.digit}>
                            <Number label="Days" value={timeSpan!.Days} onChange={this.handleDayChange} min={0}/>
                        </div>)}
                    {granularity.includes(TimeSpanSelector.HourGranularity) && (<div className={styles.digit}>
                            <Number label="Hours" value={timeSpan!.Hours} onChange={this.handleHourChange} min={0} max={23}/>
                        </div>)}
                    {granularity.includes(TimeSpanSelector.MinuteGranularity) && (<div className={styles.digit}>
                            <Number label="Minutes" value={timeSpan!.Minutes} onChange={this.handleMinuteChange} min={0} max={59}/>
                        </div>)}
                    {granularity.includes(TimeSpanSelector.SecondGranularity) && (<div className={styles.digit}>
                            <Number label="Seconds" value={timeSpan!.Seconds} onChange={this.handleSecondChange} min={0} max={59}/>
                        </div>)}
                </div>
                {this.props.error && <div className={styles.error}>{this.props.error}</div>}
            </>);
    }
    private notifyChange(value: string) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }
    private handleDayChange = (value: number | undefined) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan!.Days = value ?? 0;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.notifyChange(returnVal);
    };
    private handleHourChange = (value: number | undefined) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan!.Hours = value ?? 0;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.notifyChange(returnVal);
    };
    private handleMinuteChange = (value: number | undefined) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan!.Minutes = value ?? 0;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.notifyChange(returnVal);
    };
    private handleSecondChange = (value: number | undefined) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan!.Seconds = value ?? 0;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.notifyChange(returnVal);
    };
    static displayName = "TimeSpanSelector";
}
export default TimeSpanSelector;
