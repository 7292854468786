import { Callout } from "@octopusdeploy/design-system-components";
import { FilterValueStatusValues } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
interface MissingValuesCalloutProps {
    filterState: ProjectTenantVariablesFilterState;
    setFilterState: (filter: ProjectTenantVariablesFilterState) => void;
}
export function MissingValuesCallout({ filterState, setFilterState }: MissingValuesCalloutProps) {
    const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setFilterState({ ...filterState, filterByValueStatus: FilterValueStatusValues.Missing });
    };
    return (<Callout type={"warning"} title="Provide missing variable values">
            If required variable values are not set it could cause deployments to fail. <ShowMissingValues onClick={onClick}/>
        </Callout>);
}
interface ShowMissingValuesProps {
    onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}
function ShowMissingValues({ onClick }: ShowMissingValuesProps) {
    return (<a href="#" onClick={onClick}>
            Show missing values
        </a>);
}
