import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
type DataTableHeaderColumnProps = React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement>;
export class DataTableHeaderColumn extends React.Component<DataTableHeaderColumnProps> {
    render() {
        const { className, ...otherProps } = this.props;
        return (<th className={cn(className, styles.dataTableHeaderColumn)} {...otherProps}>
                {this.props.children}
            </th>);
    }
    static displayName = "DataTableHeaderColumn";
}
