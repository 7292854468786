import type { SettingsMetadataItemResource, ResourceCollection, SettingsId } from "@octopusdeploy/octopus-server-client";
import { Permission, SettingsIdValues } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ReactNode } from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { SimplePagingList } from "../../../../components/PagingList";
interface SettingsState extends DataBaseComponentState {
    settingsResponse?: ResourceCollection<SettingsMetadataItemResource>;
}
class SettingsList extends SimplePagingList<SettingsRow> {
}
export class SettingsListPage extends DataBaseComponent<{}, SettingsState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const settingsResponse = await repository.Settings.list();
            this.setState({ settingsResponse });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        return (<PaperLayoutVNext title="Settings" busy={this.state.busy} errors={this.errors}>
                {this.state.settingsResponse && (<SettingsList items={getEnabledSettingsRows(this.state.settingsResponse.Items.map((i) => i.Id).filter(isAuthProviderSettingsId))} onRow={(item: SettingsRow) => this.buildSettingsRow(item)} onRowRedirectUrl={(setting) => setting.link}/>)}
            </PaperLayoutVNext>);
    }
    private buildSettingsRow(settingsMetadata: SettingsRow): ReactNode {
        return (<div>
                <ListTitle>{settingsMetadata.name}</ListTitle>
                <div>{settingsMetadata.description}</div>
            </div>);
    }
    static displayName = "SettingsListPage";
}
function getEnabledSettingsRows(enabledAuthProviders: AuthProvider[]): SettingsRow[] {
    const rows: Array<SettingsRow> = [
        {
            id: SettingsIdValues.ActiveDirectory,
            name: "Active Directory",
            description: "Active Directory authentication settings",
            link: links.activeDirectorySettingsPage.generateUrl(),
            isEnabled: enabledAuthProviders.includes(SettingsIdValues.ActiveDirectory),
        },
        { id: SettingsIdValues.ArchiveLimits, name: "Archive Limits", description: "Archive limit settings", link: links.archiveLimitsSettingsPage.generateUrl(), isEnabled: isAllowed({ permission: Permission.AdministerSystem }) },
        { id: SettingsIdValues.Authentication, name: "Authentication", description: "General authentication configuration settings", link: links.authenticationSettingsPage.generateUrl() },
        {
            id: SettingsIdValues.AzureAD,
            name: "Azure AD",
            description: "Azure active directory authentication settings",
            link: links.azureADSettingsPage.generateUrl(),
            isEnabled: enabledAuthProviders.includes(SettingsIdValues.AzureAD),
        },
        { id: SettingsIdValues.AzureDevOpsIssueTracker, name: "Azure DevOps Issue Tracker", description: "Azure DevOps Issue Tracker settings", link: links.azureDevOpsIssueTrackerSettingsPage.generateUrl() },
        { id: SettingsIdValues.EventRetention, name: "Event Retention", description: "Event retention settings", link: links.eventRetentionSettingsPage.generateUrl() },
        { id: SettingsIdValues.GithubIssueTracker, name: "GitHub Issue Tracker", description: "GitHub Issue Tracker settings", link: links.githubIssueTrackerSettingsPage.generateUrl() },
        {
            id: SettingsIdValues.GoogleApps,
            name: "Google Apps",
            description: "GoogleApps authentication settings",
            link: links.googleAppsSettingsPage.generateUrl(),
            isEnabled: enabledAuthProviders.includes(SettingsIdValues.GoogleApps),
        },
        {
            id: SettingsIdValues.GuestLogin,
            name: "Guest Login",
            description: "Guest login authentication settings",
            link: links.guestLoginSettingsPage.generateUrl(),
            isEnabled: enabledAuthProviders.includes(SettingsIdValues.GuestLogin),
        },
        { id: SettingsIdValues.JiraIntegration, name: "Jira Integration", description: "Jira Integration settings", link: links.jiraIntegrationSettingsPage.generateUrl() },
        {
            id: SettingsIdValues.JiraServiceManagementIntegration,
            name: "Jira Service Management Integration",
            description: "Jira Service Management Integration settings",
            link: links.jiraServiceManagementIntegrationSettingsPage.generateUrl(),
        },
        { id: SettingsIdValues.LDAP, name: "LDAP", description: "LDAP authentication settings", link: links.ldapSettingsPage.generateUrl(), isEnabled: enabledAuthProviders.includes(SettingsIdValues.LDAP) },
        {
            id: SettingsIdValues.OctopusID,
            name: "Octopus ID",
            description: "Octopus ID authentication settings",
            link: links.octopusIdSettingsPage.generateUrl(),
            isEnabled: isAllowed({ permission: Permission.AdministerSystem }) && enabledAuthProviders.includes(SettingsIdValues.OctopusID),
        },
        { id: SettingsIdValues.Okta, name: "Okta", description: "Okta authentication settings", link: links.oktaSettingsPage.generateUrl(), isEnabled: enabledAuthProviders.includes(SettingsIdValues.Okta) },
        { id: SettingsIdValues.RetentionPolicies, name: "Retention Policies", description: "Retention Policy settings", link: links.retentionPoliciesSettingsPage.generateUrl() },
        {
            id: SettingsIdValues.ServerFolders,
            name: "Server Folders",
            description: "The file storage folder settings. These may be relative to the server's HomeDirectory or may be absolute.",
            link: links.serverFoldersSettingsPage.generateUrl(),
        },
        { id: SettingsIdValues.ServiceNowIntegration, name: "ServiceNow Integration", description: "ServiceNow Integration settings", link: links.serviceNowIntegrationSettingsPage.generateUrl() },
        { id: SettingsIdValues.Updates, name: "Updates", description: "Controls how Octopus checks for upgrades", link: links.updatesSettingsPage.generateUrl(), isEnabled: isAllowed({ permission: Permission.AdministerSystem }) },
        {
            id: SettingsIdValues.UsernamePassword,
            name: "Username / Password",
            description: "Usernames and passwords managed by Octopus",
            link: links.usernamePasswordSettingsPage.generateUrl(),
            isEnabled: enabledAuthProviders.includes(SettingsIdValues.UsernamePassword),
        },
        { id: SettingsIdValues.WebPortal, name: "Web Portal", description: "Octopus web portal configuration settings", link: links.webPortalSettingsPage.generateUrl() },
        { id: SettingsIdValues.WebRequestProxy, name: "Web Request Proxy", description: "Server proxy configuration settings", link: links.webRequestProxySettingsPage.generateUrl() },
    ];
    return rows.filter((r) => r?.isEnabled ?? true);
}
function isAuthProviderSettingsId(s: SettingsId): s is AuthProvider {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return authProviders.includes(s as AuthProvider);
}
const authProviders = [
    SettingsIdValues.ActiveDirectory,
    SettingsIdValues.AzureAD,
    SettingsIdValues.GoogleApps,
    SettingsIdValues.LDAP,
    SettingsIdValues.Okta,
    SettingsIdValues.OctopusID,
    SettingsIdValues.GuestLogin,
    SettingsIdValues.UsernamePassword,
] as const;
type AuthProvider = (typeof authProviders)[number];
interface SettingsRow {
    id: SettingsId;
    name: string;
    description: string;
    link: LinkHref;
    isEnabled?: boolean;
}
