import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { MachineModelHealthStatus, WorkerMachineResource, WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ConfigurationProps, ConfigurationState } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialog";
import { ConfigurationDialog } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialog";
import { NfsDriverInstallationHelpContent, TentacleInstallationHelpContent, tentacleInstallationHelpImage, WorkerConfigurationHelpContent, workerConfigurationHelpImage, workerNfsDriverInstallationHelpImage, } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationDialogHelp";
import { ConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationMessages";
import type { BaseConfiguration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationPage";
import { InstallationPage } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/InstallationPage";
import { WorkerConfigurationPage } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/WorkerConfigurationPage";
import { createWorkerHelmCommand } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/createHelmCommand";
import { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import type { PagedOnboardingDialogPageData } from "~/components/OnboardingDialog/PagedOnboardingDialog";
import StringHelper from "~/utils/StringHelper/index";
type WorkerFieldErrors = {
    [K in keyof WorkerConfigurationResource]?: string;
};
interface WorkerConfigurationState extends ConfigurationState<WorkerConfigurationResource> {
    workerPools: WorkerPoolResource[];
    foundWorkerResource?: WorkerMachineResource;
}
export interface WorkerConfigurationResource extends BaseConfiguration {
    WorkerPools: string[];
}
export class KubernetesAgentWorkerConfigurationDialog extends ConfigurationDialog<WorkerConfigurationState, WorkerConfigurationResource> {
    constructor(props: ConfigurationProps) {
        super(props, EndpointSelectionScope.Worker, ConfigurationMessages.Name.Worker);
    }
    onWorkerFound = (worker: WorkerMachineResource) => {
        this.setState({ foundWorkerResource: worker });
    };
    onWorkerHealthStatusUpdated = (worker: WorkerMachineResource) => {
        this.setState({ foundWorkerResource: worker });
    };
    override initialModel() {
        return {
            ...this.getInitialBaseModel(),
            WorkerPools: [],
        };
    }
    override initialiseState() {
        this.state = {
            ...this.initialBaseState,
            workerPools: [],
        };
    }
    override async loadMachineTypeSpecificData() {
        const workerPoolsPromise = repository.WorkerPools.all();
        this.setState({ workerPools: await workerPoolsPromise });
    }
    override async validateMachineTypeSpecificData(model: WorkerConfigurationResource, fieldErrors: WorkerFieldErrors): Promise<void> {
        if (StringHelper.isNullOrWhiteSpace(model.Name)) {
            fieldErrors.Name = this.nameErrorMessages.Validation.NotProvided;
        }
        else {
            const existingWorker = await repository.Workers.list({ name: this.state.model.Name });
            if (existingWorker.TotalResults > 0) {
                fieldErrors.Name = this.nameErrorMessages.Validation.AlreadyExists;
            }
        }
        if (model.WorkerPools.length === 0) {
            fieldErrors.WorkerPools = ConfigurationMessages.WorkerPools.Validation.NotProvided;
        }
    }
    override machineIsFound() {
        return !!this.state.foundWorkerResource;
    }
    override machineHealthStatus(): MachineModelHealthStatus | undefined {
        return this.state.foundWorkerResource?.HealthStatus;
    }
    override getLinkToMachine() {
        if (this.state.foundWorkerResource) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return links.workerMachineSettingsPage.generateUrl({ spaceId: repository.spaceId!, machineId: this.state.foundWorkerResource.Id });
        }
        return undefined;
    }
    async refreshWorkerPools(newWorkerPool?: WorkerPoolResource): Promise<void> {
        await this.loadMachineTypeSpecificData();
        if (newWorkerPool) {
            this.setModelState({ WorkerPools: [...this.state.model.WorkerPools, newWorkerPool.Id] });
        }
    }
    override getPages() {
        const showRegenerateTokenButton = this.state.hasTokenExpired && !this.state.foundWorkerResource;
        const pages: PagedOnboardingDialogPageData[] = [
            {
                name: "Add Kubernetes Worker",
                title: "Add New Kubernetes Worker",
                helpPanelContent: <WorkerConfigurationHelpContent />,
                helpPanelImage: workerConfigurationHelpImage(),
                isPageModelValid: () => this.isModelValid(),
                onMovingToNextPage: async () => {
                    if (!this.state.requiresHaConfiguration) {
                        await this.refreshToken();
                    }
                },
                isBusy: this.state.isBusy,
                render: () => (<WorkerConfigurationPage workerPools={this.state.workerPools} model={this.state.model} onModelChange={(model) => this.setModelState(model)} canModifyServerUrls={this.state.canModifyServerUrls} showAdvanced={this.state.showAdvanced} toggleShowAdvanced={() => this.setState({ showAdvanced: !this.state.showAdvanced })} getFieldError={(name: string) => this.getFieldError(name)} usePersistentVolumeStorage={this.state.usePersistentVolumeStorage} onUsePersistentVolumeStorageChanged={(usePv) => this.setState({ usePersistentVolumeStorage: usePv })} requiresHaConfiguration={this.state.requiresHaConfiguration} refreshWorkerPools={(newWorkerPool?: WorkerPoolResource) => this.refreshWorkerPools(newWorkerPool)}/>),
            },
        ];
        if (this.state.requiresHaConfiguration) {
            pages.push(this.getHaConfigurationPage(<WorkerConfigurationHelpContent />, workerConfigurationHelpImage()));
        }
        if (!this.state.usePersistentVolumeStorage) {
            pages.push(this.getNfsCsiDriverInstallationPage(<NfsDriverInstallationHelpContent machineType={EndpointSelectionScope.Worker}/>, workerNfsDriverInstallationHelpImage()));
        }
        pages.push({
            name: "Install Kubernetes Worker",
            title: "Install Kubernetes Worker",
            helpPanelContent: <TentacleInstallationHelpContent machineType={EndpointSelectionScope.Worker}/>,
            helpPanelImage: tentacleInstallationHelpImage(),
            render: () => (<InstallationPage<WorkerMachineResource> model={this.state.model} onAgentFound={this.onWorkerFound} onAgentHealthStatusUpdated={this.onWorkerHealthStatusUpdated} hasTokenExpired={this.state.hasTokenExpired} accessTokenExpiry={this.state.accessTokenExpiry} generateHelmCommand={(selectedLanguage) => createWorkerHelmCommand(selectedLanguage, this.state.model, this.state.workerPools)} fetchAgent={async (name: string) => {
                    const workers = await repository.Workers.list({ partialName: name });
                    return workers.Items.find((t) => t.Name === name);
                }} machineType={EndpointSelectionScope.Worker}/>),
            customPrimaryButton: showRegenerateTokenButton && <ActionButton label="Regenerate token" onClick={() => this.refreshToken()} type={ActionButtonType.Primary}/>,
        });
        return pages;
    }
}
