import type { RunbookResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { MissingChip, RunbookChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface RunbookMultiSelectProps extends FormFieldProps<string[]> {
    items: RunbookResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}
const RunbookTypedMultiSelect = MultiSelect<RunbookResource>();
const RunbookMultiSelect: React.FC<RunbookMultiSelectProps> = (props) => {
    const chipRenderer = (r: RunbookResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <RunbookChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} runbook={item}/>} renderFallback={<MissingChip type={ChipIcon.Runbook} lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <RunbookTypedMultiSelect fieldName="runbooks" renderChip={chipRenderer} {...props}/>;
};
RunbookMultiSelect.displayName = "RunbookMultiSelect"
export default RunbookMultiSelect;
