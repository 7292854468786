import type { ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ContextualMissingChip, { ChannelChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
import type { SelectItem } from "../VirtualListWithKeyboard/SelectItem";
interface ChannelMultiSelectProps extends FormFieldProps<string[]> {
    items: ReferenceDataItem[];
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}
const ChannelTypedMultiSelect = MultiSelect<ReferenceDataItem>();
export const ChannelMultiSelect: React.FC<ChannelMultiSelectProps> = (props) => {
    const chipRenderer = (r: ReferenceDataItem | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item: ReferenceDataItem) => <ChannelChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} channelName={item.Name}/>} renderFallback={<ContextualMissingChip lookupKey={r.Id} type={ChipIcon.Channel} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <ChannelTypedMultiSelect fieldName="channels" renderChip={chipRenderer} {...props}/>;
};
ChannelMultiSelect.displayName = "ChannelMultiSelect"
