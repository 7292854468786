import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
export function createAddNewTargetTagEvent(area: string | undefined): AddNewTargetTagEvent {
    return {
        eventName: "Add New Target Tag",
        Area: area,
        Element: "Multiselect",
    };
}
interface AddNewTargetTagEvent extends AnalyticsEvent<"Add New Target Tag"> {
    Area?: string;
    Element: "Multiselect";
}
