/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface ActionListProps {
    actions: any[];
    alignStart?: boolean;
}
const ActionList: React.FC<ActionListProps> = (props) => {
    return <ActionListContainer alignStart={props.alignStart}>{props.actions.map((action, index) => (!action ? null : React.cloneElement(action, { ...action.props, key: index })))}</ActionListContainer>;
};
ActionList.displayName = "ActionList"
interface ActionListContainerProps {
    alignStart?: boolean;
}
export function ActionListContainer({ alignStart, children }: React.PropsWithChildren<ActionListContainerProps>) {
    return <div className={cn(styles.actionList, alignStart ? styles.alignStart : styles.alignEnd)}>{children}</div>;
}
ActionList.displayName = "ActionList";
export default ActionList;
