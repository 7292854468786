import { ActionButton, ActionButtonType, SimpleMenu, Tooltip, useMenuState } from "@octopusdeploy/design-system-components";
import { Environment } from "@octopusdeploy/utilities";
import { useMemo } from "react";
import * as React from "react";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
type LocalDevServerUrlHelperProps = {
    onServerAddressesChanged: (serverUri: string, serverCommsAddress: string) => void;
};
export const LocalDevServerUrlHelper = (props: LocalDevServerUrlHelperProps) => {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const backendUrl = useMemo(() => {
        const serverUrl = new URL(window.location.href).searchParams.get("octopus.server");
        return new URL(serverUrl || "http://localhost:8066");
    }, []);
    //if this is not development mode, return nothing
    if (!Environment.isInDevelopmentMode()) {
        return null;
    }
    const updateAddresses = (type: "DockerDesktop" | "Kind" | "Minikube") => {
        let domain = "localhost";
        switch (type) {
            case "DockerDesktop":
                domain = "host.docker.internal";
                break;
            case "Kind":
                domain = "dockerhost.default.svc.cluster.local";
                break;
            case "Minikube":
                domain = "host.minikube.internal";
                break;
        }
        props.onServerAddressesChanged(`${backendUrl.protocol}//${domain}:${backendUrl.port}/`, `http://${domain}:10943/`);
    };
    return (<Tooltip content={"Click this if you're running a local K8s cluster and aren't sure how to make that work (dev only)."}>
            <>
                <ActionButton type={ActionButtonType.Secondary} icon={<DropDownIcon />} iconPosition="right" label="DEV: Change Server URLs for a local cluster" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
                <SimpleMenu menuState={menuState} items={[
            {
                type: "button",
                label: "Docker Desktop",
                onClick: () => updateAddresses("DockerDesktop"),
            },
            {
                type: "button",
                label: "Kind",
                onClick: () => updateAddresses("Kind"),
            },
            {
                type: "button",
                label: "Minikube",
                onClick: () => updateAddresses("Minikube"),
            },
        ]} accessibleName={"LocalDevServerUrlButton"} compact={true}/>
            </>
        </Tooltip>);
};
