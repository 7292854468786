import * as React from "react";
import { LearnMoreResources, TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectRunbooksOverview } from "../../CommonComponents/CommonProjectRunbooksViews";
export const ProjectRunbooksRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().operations.runbooks,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbooksOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return (<TopicsContainer>
                <LearnMoreResources>
                    <div>
                        <ExternalLink href="RunbookExamples">Runbook examples</ExternalLink>
                    </div>
                </LearnMoreResources>
            </TopicsContainer>);
    },
};
