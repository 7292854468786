import type { EnvironmentResource, TagSetResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { Repository, Permission } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagSets from "~/components/tenantTagsets";
export interface TenantVariableFiltersData {
    tenants: Map<string, TenantResource> | null;
    environments: EnvironmentResource[] | null;
    tagSets: TagSetResource[] | null;
}
export const useTenantVariableFiltersData = (projectId: string, doBusyTask: DoBusyTask): TenantVariableFiltersData => {
    const [tenants, setTenants] = useState<Map<string, TenantResource> | null>(null);
    const [environments, setEnvironments] = useState<EnvironmentResource[] | null>(null);
    const [tagSets, setTagSets] = useState<TagSetResource[] | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const [tenants, environments, tagSets] = await Promise.all([loadTenants(projectId), loadEnvironments(), tenantTagSets.getAll()]);
        setTenants(tenants);
        setEnvironments(environments);
        setTagSets(tagSets);
    }, []);
    return { tenants, environments, tagSets };
};
const loadEnvironments = async (): Promise<EnvironmentResource[] | null> => {
    if (!hasPermission(Permission.EnvironmentView)) {
        return null;
    }
    return await repository.Environments.all();
};
const loadTenants = async (projectId: string): Promise<Map<string, TenantResource> | null> => {
    if (!hasPermission(Permission.TenantView)) {
        return null;
    }
    const collection = await repository.Tenants.list({ projectId, take: Repository.takeAll });
    return collection.Items.reduce((lookup, tenant) => lookup.set(tenant.Id, tenant), new Map<string, TenantResource>());
};
