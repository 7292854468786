/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RadioButton } from "@octopusdeploy/design-system-components";
import type { DeployReleaseProperties } from "@octopusdeploy/legacy-action-properties";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ChipIcon, ContextualMissingChip } from "~/components/Chips";
import { KeyValueEditList } from "~/components/EditList";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Summary } from "~/components/form";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/BoundRadioButtonGroup";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import Note from "../../../primitiveComponents/form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import InternalLink from "../../Navigation/InternalLink/InternalLink";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
type DeployReleaseSummaryProps = ActionSummaryProps;
type ProjectName = string;
type ProjectOrName = ProjectResource | ProjectName;
class DeployReleaseSummary extends BaseComponent<DeployReleaseSummaryProps, {
    projectOrName: ProjectOrName | null;
}> {
    constructor(props: DeployReleaseSummaryProps) {
        super(props);
        this.state = {
            projectOrName: null,
        };
    }
    async componentDidMount() {
        //TODO: realistically we can't load all projects here unless we have some form of caching layer, so we can't show a ghost chip if a project doesn't align
        //in the summary.
        const id = this.props.properties["Octopus.Action.DeployRelease.ProjectId"] as string;
        const project: ProjectOrName | null = id.includes("#{") ? null : await repository.Projects.get(id);
        this.setState({ projectOrName: project });
    }
    render() {
        return (<div>
                Deploy a release from project{" "}
                {this.state.projectOrName ? (<>
                        <b>{typeof this.state.projectOrName === "string" ? this.state.projectOrName : this.state.projectOrName.Name}</b>.
                    </>) : (<ContextualMissingChip lookupKey={this.props.properties["Octopus.Action.DeployRelease.ProjectId"]?.toString() ?? ""} type={ChipIcon.Project}/>)}
            </div>);
    }
    static displayName = "DeployReleaseSummary";
}
enum DeploymentCondition {
    Always = "Always",
    IfNotCurrentVersion = "IfNotCurrentVersion",
    IfNewer = "IfNewer"
}
interface DeployReleaseEditState {
    projects: Array<{
        value: string;
        text: string;
        slug: string;
        spaceId: string;
    }>;
}
type DeployReleaseEditProps = ActionEditProps<DeployReleaseProperties>;
const DeployReleaseEdit: React.FC<DeployReleaseEditProps> = (props) => {
    return <DeployReleaseEditInternal {...props}/>;
};
DeployReleaseEdit.displayName = "DeployReleaseEdit"
type DeployReleaseEditInternalProps = DeployReleaseEditProps;
class DeployReleaseEditInternal extends BaseComponent<DeployReleaseEditInternalProps, DeployReleaseEditState> {
    constructor(props: DeployReleaseEditInternalProps) {
        super(props);
        this.state = {
            projects: [],
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            if (!this.props.properties["Octopus.Action.DeployRelease.DeploymentCondition"]) {
                this.props.setProperties({ ["Octopus.Action.DeployRelease.DeploymentCondition"]: DeploymentCondition.Always });
            }
            const projects = await repository.Projects.summaries();
            //TODO: investigate ways we can protect consuming actions from needing to know what things are keyed by
            this.setState({ projects: projects.map((p) => ({ value: p.Id, text: p.Name, slug: p.Slug, spaceId: p.SpaceId })) });
        });
    }
    render() {
        return (<div>
                <ExpanderSectionHeading title="Deploy a release"/>
                <ExpandableFormSection errorKey="Octopus.Action.DeployRelease.ProjectId" isExpandedByDefault={this.props.expandedByDefault} title="Project" summary={this.summary()} help={<div>Select a project that will be deployed.</div>}>
                    <BoundSelect label="Select a Project" variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={""} items={this.state.projects} onChange={(projectId) => this.props.setProperties({ ["Octopus.Action.DeployRelease.ProjectId"]: projectId })} error={this.props.getFieldError("Octopus.Action.DeployRelease.ProjectId")} allowFilter={true} autoFocus value={this.props.properties["Octopus.Action.DeployRelease.ProjectId"]}/>
                    <Note>
                        See our <ExternalLink href="DeployReleaseStep">documentation</ExternalLink> for more information on deploying projects with the Deploy Release step.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.DeployRelease.DeploymentCondition" isExpandedByDefault={this.props.expandedByDefault} title="Deployment condition" summary={this.summaryDeployment()} help={<span>Control when this deployment should run.</span>}>
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={DeploymentCondition.Always} value={this.props.properties["Octopus.Action.DeployRelease.DeploymentCondition"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.DeployRelease.DeploymentCondition"]: x })} title="Deployment condition">
                        <RadioButton value={DeploymentCondition.Always} label="Always" isDefault/>
                        <RadioButton value={DeploymentCondition.IfNotCurrentVersion} label="If the selected release is not the current release in the environment"/>
                        <RadioButton value={DeploymentCondition.IfNewer} label="If the selected release has a higher version than the current release in the environment"/>
                    </BoundRadioButtonGroup>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.DeployRelease.Variables" isExpandedByDefault={this.props.expandedByDefault} title="Variables" summary={this.summaryVariables()} fillCardWidth={CardFill.FillRight} help={<span>Pass variables through to the child deployment.</span>}>
                    <KeyValueEditList items={this.props.properties["Octopus.Action.DeployRelease.Variables"]} name="Variable" separator="=" onChange={(val) => this.props.setProperties({ ["Octopus.Action.DeployRelease.Variables"]: val })} valueLabel="Value" keyLabel="Variable name" hideBindOnKey={true} localNames={this.props.localNames} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                    <Note>
                        See our <ExternalLink href="DeployReleaseStepVariables">documentation</ExternalLink> for more information on passing variables to deployments triggered by the Create Release step.
                    </Note>
                </ExpandableFormSection>
            </div>);
    }
    private summary(): SummaryNode {
        const type = this.props.properties["Octopus.Action.DeployRelease.ProjectId"];
        if (!type) {
            return Summary.placeholder("Select a project that will be deployed");
        }
        const projectId = this.props.properties["Octopus.Action.DeployRelease.ProjectId"];
        const project = this.state.projects.find((p) => p.value === projectId);
        //We have to use the slug from the project for the route links, as the value can potentially be an id or a name and we don't support name for the route links.
        const link = project ? (projectId.includes("#{") ? (<b>{project.text}</b> // Don't link to bound fields
        ) : (<InternalLink to={links.projectRootRedirect.generateUrl({ spaceId: project.spaceId, projectSlug: project.slug })}>{project.text}</InternalLink>)) : (<ContextualMissingChip lookupKey={projectId} type={ChipIcon.Project}/>);
        return Summary.summary(<span>Deploy a release from project {link}</span>);
    }
    private summaryDeployment(): SummaryNode {
        const condition = this.props.properties["Octopus.Action.DeployRelease.DeploymentCondition"];
        if (condition === DeploymentCondition.IfNotCurrentVersion) {
            return Summary.summary(<span>
                    Deploy the release if it is <b>not the current release</b> in the environment
                </span>);
        }
        if (condition === DeploymentCondition.IfNewer) {
            return Summary.summary(<span>
                    Deploy the release if it has a <b>higher version</b> than the current release in the environment
                </span>);
        }
        if (condition === DeploymentCondition.Always) {
            return Summary.default(<span>Deploy every time</span>);
        }
        return Summary.summary(<span>Deploy when the expression is true</span>);
    }
    private summaryVariables(): SummaryNode {
        const variables = JSON.parse(this.props.properties["Octopus.Action.DeployRelease.Variables"] || "{}");
        if (Object.keys(variables).length === 0) {
            return Summary.placeholder("No variables specified");
        }
        else {
            const text = Object.keys(variables)
                .map((m) => m + " = " + variables[m])
                .join(", ");
            return Summary.summary(text);
        }
    }
    static displayName = "DeployReleaseEditInternal";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    canRunInContainer: false,
    actionType: "Octopus.DeployRelease",
    summary: (properties, targetRolesAsCSV) => <DeployReleaseSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    canHaveChildren: (step) => false,
    canBeChild: true,
    editSections: { default: DeployReleaseEdit },
    targetRoleOption: (action) => TargetRoles.None,
    canBeRetried: false,
    canUseExecutionTimeouts: false,
});
