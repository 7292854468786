import { ActionButton } from "@octopusdeploy/design-system-components";
import type { ActionButtonProps } from "@octopusdeploy/design-system-components";
import type { ProjectResource, GitPersistenceSettings } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import DynamicConnectivityCheckDialog from "~/components/DynamicForm/DynamicConnectivityCheckDialog";
const TestConnectionButton: React.FC<{
    project: ProjectResource;
    model: GitPersistenceSettings;
} & Partial<ActionButtonProps>> = ({ project, model, ...otherProps }) => {
    const [open, setOpen] = React.useState(false);
    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);
    return (<React.Fragment>
            <ActionButton label="Test" {...otherProps} onClick={openDialog}/>
            <Dialog open={open}>
                <DynamicConnectivityCheckDialog title="Version Control Settings" url={project.Links["GitConnectionTest"]} values={model} onOkClick={closeDialog}/>
            </Dialog>
        </React.Fragment>);
};
TestConnectionButton.displayName = "TestConnectionButton"
export default TestConnectionButton;
