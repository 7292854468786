import React from "react";
import { OverviewContainer, OverviewHighlightText } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
export const CommonInsightsReportsView = () => (<OverviewContainer>
        <OverviewHighlightText>
            <p>
                <ExternalLink href="http://oc.to/Insights">DevOps insights</ExternalLink> in Octopus gives you better visibility into your company’s DevOps performance by surfacing the four key DORA metrics, so you can make more informed decisions on
                where to improve and celebrate your results.
            </p>
        </OverviewHighlightText>
    </OverviewContainer>);
