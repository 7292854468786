import type { SvgIconProps } from "@material-ui/core/SvgIcon";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import BlockIcon from "@material-ui/icons/Block";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import DirectionsIcon from "@material-ui/icons/Directions";
import DoneIcon from "@material-ui/icons/Done";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopy from "@material-ui/icons/FileCopy";
import FilterListIcon from "@material-ui/icons/FilterList";
import FingerPrintIcon from "@material-ui/icons/Fingerprint";
import GetAppIcon from "@material-ui/icons/GetApp";
import GroupIcon from "@material-ui/icons/Group";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import HttpsIcon from "@material-ui/icons/Https";
import InfoOutlineIcon from "@material-ui/icons/InfoOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NoteIcon from "@material-ui/icons/Note";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import AvPlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RateReviewIcon from "@material-ui/icons/RateReview";
import RemoveIcon from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import SearchIcon from "@material-ui/icons/Search";
import UpdateIcon from "@material-ui/icons/Update";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import * as React from "react";
export enum ThirdPartyIconType {
    Done,
    AvPlayCircleFilled,
    CheckCircle,
    Clear,
    Clock,
    SmallClear,
    Group,
    PlayArrow,
    GetApp,
    AccountBox,
    InfoOutline,
    MoreVert,
    RateReview,
    KeyboardArrowUp,
    KeyboardArrowDown,
    Https,
    Cancel,
    FilterList,
    FileCopy,
    HelpOutline,
    Search,
    ArrowRight,
    Notifications,
    Fingerprint,
    CheckBox,
    CheckBoxOutlineBlank,
    RadioButtonChecked,
    ExpandMore,
    ArrowDropDown,
    ArrowForward,
    Directions,
    VideoLibrary,
    Note,
    ArrowUpward,
    ArrowDownward,
    Remove,
    Block,
    SaveAlt,
    Collapse,
    Expand,
    HighlightOff,
    Update
}
interface ThirdPartyIconProps {
    iconType: ThirdPartyIconType;
    className?: string;
    style?: React.CSSProperties;
    color?: string;
}
export const ThirdPartyIcon: React.StatelessComponent<ThirdPartyIconProps> = (props) => {
    const propsForIcon: SvgIconProps = {
        className: props.className,
        style: props.style,
    };
    if (props.color) {
        // There appears to be a bug in Material-UI's icons, in that you cannot just set the htmlColor like it says in their documentation,
        // you also need to set the fill colour before things will actually kick in, which is why you'll see both the htmlColor and style/fill being set on
        // each change.
        propsForIcon.htmlColor = props.color;
        propsForIcon.style = { ...propsForIcon.style, fill: props.color };
    }
    switch (props.iconType) {
        case ThirdPartyIconType.Done:
            return <DoneIcon {...propsForIcon}/>;
        case ThirdPartyIconType.AvPlayCircleFilled:
            return <AvPlayCircleFilledIcon {...propsForIcon}/>;
        case ThirdPartyIconType.CheckCircle:
            return <CheckCircleIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Clear:
            return <ClearIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Clock:
            return <AccessTimeIcon {...propsForIcon}/>;
        case ThirdPartyIconType.SmallClear:
            return <ClearIcon viewBox="-5 0 35 25" {...propsForIcon}/>;
        case ThirdPartyIconType.Group:
            return <GroupIcon {...propsForIcon}/>;
        case ThirdPartyIconType.PlayArrow:
            return <PlayArrowIcon {...propsForIcon}/>;
        case ThirdPartyIconType.GetApp:
            return <GetAppIcon {...propsForIcon}/>;
        case ThirdPartyIconType.AccountBox:
            return <AccountBoxIcon {...propsForIcon}/>;
        case ThirdPartyIconType.InfoOutline:
            return <InfoOutlineIcon {...propsForIcon}/>;
        case ThirdPartyIconType.MoreVert:
            return <MoreVertIcon {...propsForIcon}/>;
        case ThirdPartyIconType.RateReview:
            return <RateReviewIcon {...propsForIcon}/>;
        case ThirdPartyIconType.KeyboardArrowUp:
            return <KeyboardArrowUpIcon {...propsForIcon}/>;
        case ThirdPartyIconType.KeyboardArrowDown:
            return <KeyboardArrowDownIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Https:
            return <HttpsIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Cancel:
            return <CancelIcon {...propsForIcon}/>;
        case ThirdPartyIconType.FilterList:
            return <FilterListIcon {...propsForIcon}/>;
        case ThirdPartyIconType.FileCopy:
            return <FileCopy {...propsForIcon}/>;
        case ThirdPartyIconType.HelpOutline:
            return <HelpOutlineIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Search:
            return <SearchIcon {...propsForIcon}/>;
        case ThirdPartyIconType.ArrowRight:
            return <ArrowRightIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Notifications:
            return <NotificationsIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Fingerprint:
            return <FingerPrintIcon {...propsForIcon}/>;
        case ThirdPartyIconType.CheckBox:
            return <CheckBoxIcon {...propsForIcon}/>;
        case ThirdPartyIconType.CheckBoxOutlineBlank:
            return <CheckBoxOutlineBlankIcon {...propsForIcon}/>;
        case ThirdPartyIconType.RadioButtonChecked:
            return <RadioButtonCheckedIcon {...propsForIcon}/>;
        case ThirdPartyIconType.ExpandMore:
            return <ExpandMoreIcon {...propsForIcon}/>;
        case ThirdPartyIconType.ArrowDropDown:
            return <ArrowDropDownIcon {...propsForIcon}/>;
        case ThirdPartyIconType.ArrowForward:
            return <ArrowForwardIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Directions:
            return <DirectionsIcon {...propsForIcon}/>;
        case ThirdPartyIconType.VideoLibrary:
            return <VideoLibraryIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Note:
            return <NoteIcon {...propsForIcon}/>;
        case ThirdPartyIconType.ArrowUpward:
            return <ArrowUpwardIcon {...propsForIcon}/>;
        case ThirdPartyIconType.ArrowDownward:
            return <ArrowDownwardIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Remove:
            return <RemoveIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Block:
            return <BlockIcon {...propsForIcon}/>;
        case ThirdPartyIconType.SaveAlt:
            return <SaveAlt {...propsForIcon}/>;
        case ThirdPartyIconType.Collapse:
            return <KeyboardArrowUpIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Expand:
            return <KeyboardArrowDownIcon {...propsForIcon}/>;
        case ThirdPartyIconType.HighlightOff:
            return <HighlightOffIcon {...propsForIcon}/>;
        case ThirdPartyIconType.Update:
            return <UpdateIcon {...propsForIcon}/>;
        default:
            exhaustiveCheck(props.iconType, "Not all icon types have been handled.");
    }
    return null;
};
ThirdPartyIcon.displayName = "ThirdPartyIcon"
