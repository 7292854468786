import type { VariableSetResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { CloudConnectionStatus } from "~/areas/projects/components/CloudConnection/CloudConnectionStatusIcon";
import CloudConnectionTile from "~/areas/projects/components/CloudConnection/CloudConnectionTile";
import { getVariable } from "~/areas/projects/components/CloudConnection/CloudConnectionUtilities";
import { VariableNames } from "~/areas/projects/components/CloudConnection/CloudConnectionVariables";
import type { CloudConnectionItem, ConfigurationStatus } from "~/areas/projects/components/CloudConnection/CloudConnections";
import { CloudConnectionUsageType } from "~/areas/projects/components/CloudConnection/CloudConnections";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
export function AzureTileStatus(connectionType: CloudConnectionUsageType, variables: VariableSetResource | undefined, libraryVariableSets: LoadedLibraryVariableSets[] | undefined): ConfigurationStatus {
    if (getVariable(VariableNames.Azure.Account, variables, libraryVariableSets)) {
        return "Configured";
    }
    return "Configure Now";
}
const AzureCloudConnectionTile: React.FC<CloudConnectionItem> = (props) => {
    let configurationWords = "Configure";
    let connectionStatus: CloudConnectionStatus | null = null;
    if (props.connectionType === CloudConnectionUsageType.TargetDiscovery && props.tileDetails === "Configured") {
        configurationWords = "Cloud target discovery enabled";
        connectionStatus = CloudConnectionStatus.Ok;
    }
    return <CloudConnectionTile provider={"Azure"} configurationStatus={configurationWords} connectionStatus={connectionStatus}></CloudConnectionTile>;
};
AzureCloudConnectionTile.displayName = "AzureCloudConnectionTile"
export default AzureCloudConnectionTile;
