import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GetMatchingRefsResponseV1, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import React, { useState } from "react";
import { repository } from "~/clientInstance";
import { useDoBusyTaskEffect, type DoBusyTask } from "~/components/DataBaseComponent";
import { Note } from "~/components/form";
import { GitRefChip } from "../../Releases/GitRefChip/GitRefChip";
type MatchingGitRefsProps = {
    project: ProjectResource;
    patterns: string[];
    doBusyTask: DoBusyTask;
};
export function MatchingGitRefs({ doBusyTask, project, patterns }: MatchingGitRefsProps) {
    const [matchingGitRefsResponse, setMatchingGitRefsResponse] = useState<GetMatchingRefsResponseV1 | undefined>(undefined);
    const [lastCheckedPatterns, setLastCheckedPatterns] = useState<string[]>([]);
    const updateMatchingGitRefs = async () => {
        // Only update the matching refs if the patterns have actually changed
        if (!isEqual(lastCheckedPatterns, patterns) && patterns.length > 0) {
            const matchingRefsResponse = await repository.Projects.getMatchingGitRefs(project, {
                patterns,
                skip: 0,
                take: 10,
            });
            setMatchingGitRefsResponse(matchingRefsResponse);
        }
        setLastCheckedPatterns(patterns);
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        await updateMatchingGitRefs();
    }, [patterns, project]);
    if (patterns.length === 0) {
        return (<Callout type="information" title="No rules configured">
                Any branch or tag can be used to create a release in this channel.
            </Callout>);
    }
    if (matchingGitRefsResponse === undefined) {
        return null;
    }
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            gap: space[8],
        }),
        refChipContainer: css({
            display: "flex",
            gap: space[8],
            flexWrap: "wrap",
        }),
    };
    return (<>
            {matchingGitRefsResponse.TotalCount === 0 && (<Callout type="warning" title="No matching branches/tags found">
                    We couldn't find any branches or tags that match the rules, this may mean that no releases will be able to be created for this channel.
                </Callout>)}
            {matchingGitRefsResponse.TotalCount > 0 && (<Callout type="information" title={matchingGitRefsResponse.TotalCount === 1 ? `Found ${matchingGitRefsResponse.TotalCount} matching branch/tag` : `Found ${matchingGitRefsResponse.TotalCount} matching branches/tags`}>
                    <div className={styles.container}>
                        <div className={styles.refChipContainer}>
                            {matchingGitRefsResponse.References.map((gitRef) => (<GitRefChip key={gitRef.CanonicalName} vcsRef={{ GitRef: gitRef.CanonicalName }} variant="outlined"/>))}
                        </div>
                        {matchingGitRefsResponse.TotalCount > matchingGitRefsResponse.References.length && <Note>Only the first {matchingGitRefsResponse.References.length} matching branches/tags are shown</Note>}
                    </div>
                </Callout>)}
        </>);
}
