/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css, cx } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { borderRadius } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import classNames from "classnames";
import * as React from "react";
import { client } from "~/clientInstance";
import InternalNavLink from "~/components/Navigation/InternalNavLink/InternalNavLink";
import styles from "./style.module.less";
interface LogoProps {
    url: string;
    size?: string;
    isDisabled?: boolean;
    editLink?: LinkHref;
    className?: string;
    isSquare?: boolean;
}
function Logo({ url, size = "3.1rem", isDisabled, editLink, className, isSquare }: LogoProps) {
    const [shouldShowImage, setShouldShowImage] = React.useState(false);
    const placeholderRef = React.useRef<HTMLDivElement>(null);
    useOnVisibilityChanged(placeholderRef, (isVisible) => {
        if (isVisible) {
            setShouldShowImage(isVisible);
        }
    });
    if (!url) {
        return <div />;
    }
    const resolvedUrl = url.startsWith("~") || url.startsWith("/") ? client.resolve(url) : url;
    const sizeStyles = css({
        width: size,
        height: size,
    });
    const logoPlaceholder = <div ref={placeholderRef} className={sizeStyles}></div>;
    const logoImage = <img src={resolvedUrl} className={cx(sizeStyles, { [styles.disabled]: isDisabled })} alt="Logo"/>;
    const content = shouldShowImage ? logoImage : logoPlaceholder;
    const borderRadiusStyling = css({
        borderRadius: isSquare ? borderRadius.small : "3.75rem",
    });
    return (<div className={classNames(styles.logo, borderRadiusStyling, className)}>
            {editLink ? (<Tooltip content="Edit logo" position="right">
                    <InternalNavLink to={editLink} className={styles.logoImageLink}>
                        {content}
                    </InternalNavLink>
                </Tooltip>) : (content)}
        </div>);
}
function useOnVisibilityChanged<T extends HTMLElement>(elementRef: React.RefObject<T>, callback: (isVisible: boolean) => void) {
    React.useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            callback(entry.isIntersecting);
        });
        const currentElement = elementRef?.current;
        if (currentElement) {
            observer.observe(currentElement);
        }
        return () => {
            observer.disconnect();
        };
    }, [callback, elementRef]);
}
export default Logo;
