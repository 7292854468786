/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import InsertVariableButton from "~/components/form/InsertVariableButton/InsertVariableButton";
import styles from "./style.module.less";
export interface VariableLookupProps {
    localNames?: string[];
    syntax?: ScriptingLanguage;
    textInputRef?(input: TextInputRef | null): void;
}
export interface TextInputRef {
    focus: () => void;
    insertAtCursor: (val: string) => void;
}
export interface GlobalConnectedProps {
    textInputRef(input: TextInputRef | null): void;
}
export function withVariableLookup() {
    return <TProps extends {}>(Comp: React.ComponentType<TProps & GlobalConnectedProps>) => {
        const WithVariableLookup: React.FC<TProps & VariableLookupProps> = (props) => {
            const myTextInputRef = React.useRef<TextInputRef | null>(null);
            const { localNames, syntax, textInputRef, ...otherProps } = props;
            const insert = React.useCallback((val: string) => {
                myTextInputRef?.current?.insertAtCursor(val);
                setTimeout(() => myTextInputRef?.current?.focus(), 0);
            }, [myTextInputRef]);
            const innerComponentProps = {
                ...otherProps,
                textInputRef: (ref: TextInputRef | null) => {
                    myTextInputRef.current = ref;
                    if (textInputRef)
                        textInputRef(ref);
                },
            };
            return (<div className={styles.container}>
                    <div className={styles.wide}>
                        <Comp key="component" {...(innerComponentProps as TProps & GlobalConnectedProps)}/>
                    </div>
                    <div key="buttons" className={styles.buttons}>
                        <InsertVariableButton syntax={props.syntax} localNames={props.localNames} onSelected={(v) => insert(v)}/>
                    </div>
                </div>);
        };
        WithVariableLookup.displayName = "WithVariableLookup"
        return WithVariableLookup;
    };
}
