import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import GitHubIcon from "~/primitiveComponents/dataDisplay/Icon/GitHubIcon";
interface GitHubFooterMessageProps {
    title: React.ReactNode;
    message?: React.ReactNode;
}
export function GitHubFooterMessage({ title, message }: GitHubFooterMessageProps) {
    const styles = {
        logo: css({
            height: space[24],
            width: space[24],
        }),
        text: css({
            textAlign: "center",
            margin: space[32],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexFlow: "column",
            gap: space[8],
        }),
        heading: css({
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexFlow: "row",
            gap: space[8],
        }),
    };
    return (<div className={styles.text}>
            <div className={styles.heading}>
                <GitHubIcon className={styles.logo}/> {title}
            </div>

            {message && <div>{message}</div>}
        </div>);
}
