/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import Chip from "@material-ui/core/Chip";
import type { ChipProps } from "@material-ui/core/Chip";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { isGitCommit, isGitTag, toGitCommitShort, toGitRefShort } from "@octopusdeploy/octopus-server-client";
import type { GitReference } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProjectContext } from "~/areas/projects/context";
import { withTheme } from "~/components/Theme";
import { GitBranchIcon, GitCommitIcon, GitTagIcon } from "~/primitiveComponents/dataDisplay/Icon";
export const GitRefChip: React.FC<{
    vcsRef: GitReference;
    showCommit?: boolean;
} & ChipProps> = ({ vcsRef, showCommit = true, ...chipProps }) => {
    const projectContext = React.useContext(ProjectContext);
    const isDefaultBranch = projectContext && vcsRef.GitRef == projectContext?.state.gitRef?.CanonicalName;
    // Material Chips don't really expect an icon in the label, so we need to bend the styling to our will
    const iconInLabelStyles = {
        verticalAlign: "sub",
        marginRight: space[4],
        marginLeft: space[8],
    };
    const gitRefIcon = isGitCommit(vcsRef.GitRef) ? <GitCommitIcon /> : isGitTag(vcsRef.GitRef) ? <GitTagIcon /> : <GitBranchIcon />;
    const gitRefText = vcsRef.GitRef && (<Tooltip key="ref" content={vcsRef.GitRef}>
            {toGitRefShort(vcsRef.GitRef)}
        </Tooltip>);
    const innerCommitChip = isGitCommit(vcsRef.GitRef)
        ? null
        : vcsRef.GitCommit && (<Tooltip content={vcsRef.GitCommit} key="commit">
                  <GitCommitIcon key="icon" className="MuiChip-icon MuiChip-iconSmall" style={iconInLabelStyles}/>
                  <span key="value">{toGitCommitShort(vcsRef.GitCommit)}</span>
              </Tooltip>);
    const label = showCommit ? [gitRefText, innerCommitChip] : gitRefText;
    return withTheme((theme) => {
        const chipStyles = {
            backgroundColor: theme.subtleButtonBackground,
        };
        // TODO: add background colours for default branch once we know what the dark theme colours are
        const defaultBranchChipStyles = chipStyles; // e.g. { backgroundColour: theme.whatAreWeCallingThisAndWhereElseDoWeUseIt }
        const styles = isDefaultBranch ? defaultBranchChipStyles : chipStyles;
        return (vcsRef && vcsRef.GitRef && <Chip icon={gitRefIcon} label={label} style={styles} {...chipProps} size="small"></Chip>) || null;
    });
};
GitRefChip.displayName = "GitRefChip"
