import type { ProjectResource, ReleaseResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import type { PagingDataTableProps } from "../../../../components/PagingDataTable/PagingDataTable";
import { PagingDataTable } from "../../../../components/PagingDataTable/PagingDataTable";
import { GitRefChip } from "./GitRefChip/GitRefChip";
import styles from "./style.module.less";
class PagingReleasesTableInternal extends PagingDataTable<ReleaseResource> {
}
interface Cells {
    ReleaseCell: React.FC<{
        project: ProjectResource;
        release: ReleaseResource;
    }>;
    ChannelCell: React.FC<{
        release: ReleaseResource;
    }>;
    ReleaseNotesCell: React.FC<{
        release: ReleaseResource;
    }>;
}
type PagingReleasesTableProps = {
    project: ProjectResource;
} & Cells & Omit<PagingDataTableProps<ReleaseResource>, "headerColumns" | "headerColumnClassNames" | "onRow">;
const GitRefCell: React.FC<{
    release: ReleaseResource;
}> = ({ release }) => {
    const vcsRef = release.VersionControlReference;
    return <div className={styles.marginTopBottom}>{vcsRef && <GitRefChip vcsRef={vcsRef} showCommit={false} className={styles.gitRefChip}/>}</div>;
};
GitRefCell.displayName = "GitRefCell"
const VcsPagingReleasesTable: React.FC<PagingReleasesTableProps> = ({ ReleaseCell, ChannelCell, ReleaseNotesCell, project, ...props }) => {
    const headerColumns = ["Release", "Channel", "Git Ref", "Release Notes"];
    const headerColumnClassNames = ["", "", "", styles.notesColumn];
    const vcsRows = (release: ReleaseResource): React.ReactNode => {
        return release && [<ReleaseCell project={project} release={release}/>, <ChannelCell release={release}/>, <GitRefCell release={release}/>, <ReleaseNotesCell release={release}/>];
    };
    return <PagingReleasesTableInternal headerColumns={headerColumns} headerColumnClassNames={headerColumnClassNames} onRow={vcsRows} {...props}/>;
};
VcsPagingReleasesTable.displayName = "VcsPagingReleasesTable"
const DatabasePagingReleasesTable: React.FC<PagingReleasesTableProps> = ({ ReleaseCell, ChannelCell, ReleaseNotesCell, project, ...props }) => {
    const headerColumns = ["Release", "Channel", "Release Notes"];
    const headerColumnClassNames = ["", "", styles.notesColumn];
    const databaseRows = (release: ReleaseResource): React.ReactNode => {
        return release && [<ReleaseCell project={project} release={release}/>, <ChannelCell release={release}/>, <ReleaseNotesCell release={release}/>];
    };
    return <PagingReleasesTableInternal headerColumns={headerColumns} headerColumnClassNames={headerColumnClassNames} onRow={databaseRows} {...props}/>;
};
DatabasePagingReleasesTable.displayName = "DatabasePagingReleasesTable"
export const PagingReleasesTable: React.FC<PagingReleasesTableProps> = (props) => {
    const projectContext = useProjectContext();
    return projectContext?.state.model?.IsVersionControlled ? <VcsPagingReleasesTable {...props}/> : <DatabasePagingReleasesTable {...props}/>;
};
PagingReleasesTable.displayName = "PagingReleasesTable"
