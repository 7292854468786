import type { ProjectedTeamReferenceDataItem, NamedReferenceItem, TeamMembership, SpaceResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ExternalSecurityGroupChip, SpaceChip } from "~/components/Chips";
import { SimplePagingList } from "~/components/PagingList";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import styles from "./ProjectedTeamsList.module.less";
type TeamMembershipTypes = ProjectedTeamReferenceDataItem | TeamMembership;
class ProjectedTeamsList<TMembership extends TeamMembershipTypes> extends SimplePagingList<TMembership> {
}
interface ProjectedTeamsListItemProps<TMembership extends TeamMembershipTypes> {
    projectedTeam: TMembership;
    spaceResource?: SpaceResource;
}
type Nullable<T> = T | null | undefined;
const ExternalGroupChips: React.FC<{
    externalGroups: Nullable<NamedReferenceItem[]>;
}> = ({ externalGroups }) => {
    if (!externalGroups) {
        return null;
    }
    return (<>
            {externalGroups.map((group) => (<ExternalSecurityGroupChip key={group.Id} group={group}/>))}
        </>);
};
ExternalGroupChips.displayName = "ExternalGroupChips"
interface ProjectedTeamDescriptionProps {
    isDirectAssignment: boolean;
    externalGroups?: NamedReferenceItem[];
}
const ProjectedTeamDescription: React.FC<ProjectedTeamDescriptionProps> = ({ isDirectAssignment, externalGroups }) => {
    if (!externalGroups) {
        return null;
    }
    return (<>
            {externalGroups && externalGroups.length > 0 && "Mapped from external source "}
            <ExternalGroupChips externalGroups={externalGroups}/>
            {isDirectAssignment && externalGroups && externalGroups.length > 0 && (<span>
                    {" "}
                    and also <strong>directly assigned to team.</strong>
                </span>)}
        </>);
};
ProjectedTeamDescription.displayName = "ProjectedTeamDescription"
const ProjectedTeamListItem: React.FC<ProjectedTeamsListItemProps<TeamMembershipTypes>> = ({ projectedTeam, spaceResource }) => (<ListTitle>
        <div className={styles.root}>
            <ThirdPartyIcon iconType={ThirdPartyIconType.Group} className={styles.icon}/>
            <div className={styles.detail}>
                <div className={styles.description}>
                    {"TeamName" in projectedTeam ? projectedTeam.TeamName : projectedTeam.Name}
                    {spaceResource && <SpaceChip space={spaceResource} description={`This user has access to the ${spaceResource.Name} space`}/>}
                </div>
                <div className={styles.externalGroups}>
                    <ProjectedTeamDescription isDirectAssignment={projectedTeam.IsDirectlyAssigned} externalGroups={projectedTeam.ExternalSecurityGroups}/>
                </div>
            </div>
        </div>
    </ListTitle>);
ProjectedTeamListItem.displayName = "ProjectedTeamListItem"
export { ProjectedTeamsList, ProjectedTeamListItem, ProjectedTeamsListItemProps, ProjectedTeamDescription, ProjectedTeamDescriptionProps, ExternalGroupChips };
export default ProjectedTeamsList;
