import type { Segment, TenantResource, ToggleResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import Chip from "~/components/Chips/Chip";
import { EnvironmentChip, TenantChip, tenantChipList } from "~/components/Chips/index";
import Tag from "~/components/Tag/index";
import { useOctopusTheme } from "~/components/Theme/index";
interface ToggleScopeProps {
    toggle: ToggleResource;
    environmentName: string;
    tenants: TenantResource[];
}
interface SegmentListProps {
    segments: Segment[];
}
interface ExcludedTenantListProps {
    excludedTenants: TenantResource[];
}
type EditableSegmentListProps = SegmentListProps & {
    onRequestDelete: (segment: Segment) => void;
    deleteButtonAccessibleName: string;
};
function formatSegment(segment: Segment) {
    return `${segment.Key}/${segment.Value}`;
}
export function SegmentList({ segments }: SegmentListProps) {
    const theme = useOctopusTheme();
    return (<>
            {segments.map((segment) => (<Tag tagName={formatSegment(segment)} description={formatSegment(segment)} tagColor={theme.info} key={formatSegment(segment)}/>))}
        </>);
}
export function ExcludedTenantList({ excludedTenants }: ExcludedTenantListProps) {
    const theme = useOctopusTheme();
    return (<>
            {excludedTenants.map((tenant) => (<TenantChip tenantName={tenant.Name} backgroundColor={theme.alertCalloutBackground} key={tenant.Id} markAsRemoved={true}/>))}
        </>);
}
export function EditableSegmentList({ segments, onRequestDelete, deleteButtonAccessibleName }: EditableSegmentListProps) {
    const theme = useOctopusTheme();
    return (<>
            {segments.map((segment) => (<Tag tagName={formatSegment(segment)} description={formatSegment(segment)} tagColor={theme.info} key={formatSegment(segment)} onRequestDelete={() => onRequestDelete(segment)} deleteButtonAccessibleName={deleteButtonAccessibleName}/>))}
        </>);
}
export default ({ toggle, environmentName, tenants }: ToggleScopeProps) => {
    const theme = useOctopusTheme();
    return (<span>
            <EnvironmentChip environmentName={environmentName}/>
            {toggle.TenantTargetingStrategy === "SpecificTenants" && (<>
                    {tenantChipList(tenants, toggle.TenantIds)}
                    {toggle.RolloutPercentage === null ? null : <Chip backgroundColor={theme.experimentBackground}>{toggle.RolloutPercentage}% of tenants</Chip>}
                </>)}
            {ExcludedTenantList({ excludedTenants: tenants.filter((tenant) => toggle.ExcludedTenantIds.includes(tenant.Id)) })}
            {SegmentList({ segments: toggle.Segments })}
        </span>);
};
