import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import * as React from "react";
import AuditStreamUpsellDialogLayout from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamUpsellDialogLayout";
import upsellImageDark from "~/areas/configuration/components/AuditLayout/assets/img-upsell-dark.svg";
import upsellImageLight from "~/areas/configuration/components/AuditLayout/assets/img-upsell-light.svg";
import OnboardingDialog from "~/components/OnboardingDialog/OnboardingDialog";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
export interface AuditStreamUpsellDialogProps {
    open: boolean;
    close: () => void;
}
export function AuditStreamUpsellDialog({ open, close }: AuditStreamUpsellDialogProps) {
    const isDarkMode = useThemePaletteType() === "dark";
    return (<AnalyticLinkLocationProvider location="Audit Stream Upsell Dialog">
            <OnboardingDialog name="audit-stream-upsell" helpPanelShowToggle={false} helpPanelImage={{ src: isDarkMode ? upsellImageDark : upsellImageLight, altText: "Audit Stream" }} helpPanelSize={"half"} helpPanelTheme={isDarkMode ? "dark" : "light"} open={open} close={close} useBackwardsCompatiblePadding={true}>
                <AuditStreamUpsellDialogLayout onClose={close}/>
            </OnboardingDialog>
        </AnalyticLinkLocationProvider>);
}
