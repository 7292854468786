/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { UserResource, NamedReferenceItem } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { Avatar } from "~/primitiveComponents/dataDisplay/Avatar/Avatar";
import workTeamImage from "../../../../primitiveComponents/dataDisplay/Avatar/img/work_team.svg";
import styles from "./MemberRow.module.less";
type MemberTypes = UserResource | NamedReferenceItem;
function isUser(item: MemberTypes): item is UserResource {
    return !!(item as UserResource).Username;
}
function nameWithUsername(u: UserResource) {
    return !!u && u.DisplayName !== u.Username ? `${u.DisplayName} (${u.Username})` : u.DisplayName;
}
function namedGroup(u: NamedReferenceItem) {
    return u.DisplayIdAndName ? `${u.DisplayName} (${u.Id})` : u.DisplayName;
}
interface MemberRowProps {
    member: MemberTypes;
}
export const MemberRow: React.StatelessComponent<MemberRowProps> = (props) => {
    const member = props.member;
    const image = isUser(member) ? (<Avatar avatarLink={member && member.Links && member.Links.Avatar} isService={member && member.IsService} size={24}/>) : (<img src={workTeamImage} className={styles.groupImage} alt={member.DisplayIdAndName ? "Role" : "Security group"}/>);
    const name = isUser(member) ? nameWithUsername(member) : namedGroup(member);
    const nameLink = member && member.Id && member.Id.toLowerCase().startsWith("users-") ? ( // only internal link to Octopus controlled user accounts
    <InternalLink to={links.editUserPage.generateUrl({ userId: member.Id })}>{name}</InternalLink>) : (name);
    return (<div className={styles.user}>
            {image}
            <span className={styles.userName}>{nameLink}</span>
        </div>);
};
MemberRow.displayName = "MemberRow"
