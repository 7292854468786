import { logger } from "@octopusdeploy/logging";
import type { Dispatch, SetStateAction } from "react";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { getValueInStorage, setValueInStorage, subscribeToStorageChanges } from "./localStorageHelpers";
type SetValue<T> = Dispatch<SetStateAction<T>>;
const noLocalStorageValue = Symbol();
function useLocalStorage<T>(key: string, fallbackValue: T): [
    T,
    SetValue<T>
] {
    const initialStoredValue = useMemo(() => {
        try {
            const item = getValueInStorage(key);
            const value = item ? JSON.parse(item) : noLocalStorageValue;
            return value;
        }
        catch (error) {
            logger.warn(error, "Failed to retrieve item with {key} from local storage", { key });
            return noLocalStorageValue;
        }
    }, [key]);
    const [storedValue, setStoredValue] = useState<T>(initialStoredValue);
    useEffect(() => {
        setStoredValue(initialStoredValue);
    }, [initialStoredValue]);
    const setValue: SetValue<T> = React.useCallback((value) => {
        if (typeof value === "function") {
            setStoredValue((prev) => {
                //We need to cast here since there is a bug in typescript around generics and type guards
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const next = (value as (value: T) => T)(prev);
                setValueInStorage(key, JSON.stringify(next));
                return next;
            });
        }
        else {
            const valueToStore = value;
            setStoredValue(valueToStore);
            setValueInStorage(key, JSON.stringify(valueToStore));
        }
    }, [key]);
    useSynchronizeLocalStateFromLocalStorageEffect(key, setStoredValue);
    return [storedValue === noLocalStorageValue ? fallbackValue : storedValue, setValue];
}
//In order to synchronize local component state, we need to capture storage event
//changes from other tabs as well as local storage changes made via this hook itself.
//we don't pick up direct usages of localStorage outside the use of these hooks, however
//we expect most people to be using these hooks.
function useSynchronizeLocalStateFromLocalStorageEffect<T>(key: string, setStoredValue: SetValue<T>) {
    useStorageUpdatedEffect(key, setStoredValue);
    useStorageEventEffect(key, setStoredValue);
}
//This effect allows us to subscribe to any changes made via these hooks and synchronize
//local state across all usages of these hooks. This is necessary as we don't use
//global state for these and each usage of this hook has it's own `React.useState`
function useStorageUpdatedEffect<T>(key: string, setStoredValue: SetValue<T>) {
    const handleCurrentTabStorageUpdated = useCallback((data: string) => {
        setStoredValue(JSON.parse(data));
    }, [setStoredValue]);
    React.useEffect(() => {
        return subscribeToStorageChanges(key, handleCurrentTabStorageUpdated);
    }, [handleCurrentTabStorageUpdated, key]);
}
//This effect allows us to subscribe to storage changes that happened in different
//tabs. This allows us to ensure changes happening for a particular storage key
//is replicated to the other tabs.
//
//NB: These events only fire in other tabs, not the current tab where the storage
//was updated from.
function useStorageEventEffect<T>(key: string, setStoredValue: SetValue<T>) {
    const updateStoredValueFromOtherStorageEvents = useCallback((event: StorageEvent) => {
        if (event.storageArea === localStorage) {
            if (event.key === key) {
                if (event.newValue) {
                    setStoredValue(JSON.parse(event.newValue));
                }
            }
        }
    }, [key, setStoredValue]);
    // NB: Storage events only get fired in other tabs. They do not get fired
    // in the source page where the local storage values got updated from.
    // Hooking into this event, ensures we can synchronise our local state
    // between different tabs
    useEffect(() => {
        window.addEventListener("storage", updateStoredValueFromOtherStorageEvents, true);
        return () => window.removeEventListener("storage", updateStoredValueFromOtherStorageEvents, true);
    }, [updateStoredValueFromOtherStorageEvents]);
}
export default useLocalStorage;
export { useLocalStorage };
