import { Callout } from "@octopusdeploy/design-system-components";
import type { TenantResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
interface DeleteTenantDialogProps {
    tenant: TenantResource;
    handleDeleteConfirm: () => Promise<boolean>;
}
export default function DeleteTenantDialog({ tenant, handleDeleteConfirm }: DeleteTenantDialogProps) {
    const numberOfConnectedProjects = Object.keys(tenant.ProjectEnvironments).length;
    return (<DeleteDialogLayout title={"Are you sure you want to delete this tenant?"} onDeleteClick={handleDeleteConfirm} renderContent={() => (<React.Fragment>
                    {numberOfConnectedProjects > 0 ? (<Callout title={`The tenant is connected to ${numberOfConnectedProjects} projects`} type={"warning"}>
                            Deleting the tenant will also remove the tenant from all connected projects.
                        </Callout>) : null}
                    <p>Do you wish to continue?</p>
                </React.Fragment>)}/>);
}
