import { pageId } from "@octopusdeploy/portal-routes";
export default () => ({
    tenants: pageId({ area: "Tenants", id: "Tenants", name: "Tenants" }),
    tenant: {
        root: pageId({ area: "Tenants", id: "Tenants", name: "Tenants" }),
        overview: pageId({ area: "Tenants", id: "Tenant.Overview", name: "Tenant Overview" }),
        settings: pageId({ area: "Tenants", id: "Tenant.Settings", name: "Tenant Settings" }),
        tasks: pageId({ area: "Tenants", id: "Tenant.Tasks", name: "Tenant Tasks" }),
        variables: pageId({ area: "Tenants", id: "Tenant.Variables", name: "Tenant Variables" }),
    },
});
