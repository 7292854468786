import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
export function createCloseDrawerEvent(drawer: string, correlationId: string | undefined): CloseDrawerEvent {
    return {
        eventName: "Close Drawer",
        Drawer: drawer,
        "Correlation Id": correlationId,
    };
}
interface CloseDrawerEvent extends AnalyticsEvent<"Close Drawer"> {
    Drawer: string;
    "Correlation Id"?: string;
}
