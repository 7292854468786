import { throttle } from "lodash";
import { useEffect, useState } from "react";
import * as React from "react";
import VariableCell from "~/areas/variables/VariableCell/VariableCell";
import { MeasureWidth } from "~/components/Measure/Measure";
import type { CellAligner } from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import styles from "./style.module.less";
interface VariableEditorHeadingsProps {
    cellAligner: CellAligner;
    cells: JSX.Element[];
    isDisplayedFullWidth: boolean;
    columnWidths: ReadonlyArray<number>;
    onWidthMeasured(cellIndex: number, width: number): void;
}
export function VariableEditorHeadings({ cellAligner, cells, isDisplayedFullWidth, columnWidths, onWidthMeasured }: VariableEditorHeadingsProps) {
    const [resizeCount, setResizeCount] = useState(0);
    const columnWidthsKey = columnWidths.map((c) => c.toString()).join("-");
    const [measurerKey, setMeasurerKey] = useState(`${isDisplayedFullWidth}-${columnWidthsKey}-${resizeCount}`);
    // If any of these things change, we need to re-measure
    useEffect(() => setMeasurerKey(`${isDisplayedFullWidth}-${columnWidthsKey}-${resizeCount}`), [columnWidthsKey, resizeCount, isDisplayedFullWidth]);
    useEffect(() => {
        const throttledRemeasure = throttle(() => setResizeCount((count) => count + 1), 100);
        window.addEventListener("resize", throttledRemeasure, { passive: true });
        return () => window.removeEventListener("resize", throttledRemeasure);
    }, []);
    return cellAligner(cells.map((c, index) => (<VariableCell key={index}>
                <MeasureWidth key={measurerKey} onMeasured={(width) => onWidthMeasured(index, width)}>
                    <div className={styles.headerRow}>{c}</div>
                </MeasureWidth>
            </VariableCell>)), { showResizeHandles: true });
}
