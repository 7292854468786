import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
export function ResilientPipelineCallouts(props: {
    isVariablesPage?: boolean;
    isRunbooksPage?: boolean;
}) {
    const projectContext = useProjectContext();
    const resilientExecutionsEnabled = isFeatureToggleEnabled("ResilientExecutionsFeatureToggle");
    if (!resilientExecutionsEnabled)
        return null;
    if (!projectContext.state.model.ExecuteDeploymentsOnResilientPipeline)
        return null;
    return (<div className={calloutsStyles}>
            <Callout type={"information"} title="This project has Resilient Deployments (pre-Alpha) enabled. This only effects deployments. not runbooks. To change this setting go to project settings."/>
        </div>);
}
const calloutsStyles = css({
    paddingLeft: "2rem",
    paddingRight: "2rem",
});
