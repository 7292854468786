import { BeaconInline } from "@octopusdeploy/design-system-components";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import type { ActionEvent } from "~/analytics/Analytics";
import { useAnalyticActionDispatch } from "~/analytics/Analytics";
import InternalLink from "~/components/Navigation/InternalLink";
import { useIsPortalUrlActive } from "~/components/Navigation/useIsPortalUrlActive";
import type { TaskState } from "./ProjectStatus";
import { ProjectStatusIcon } from "./ProjectStatusIcon";
import { ProjectStatusPopover } from "./ProjectStatusPopover";
import { RightArrowIcon } from "./RightArrowIcon";
import styles from "./style.module.less";
interface ProjectStatusItemProps {
    name: string;
    href?: LinkHref;
    state: TaskState;
    showIndicator?: boolean;
    popover?: ProjectStatusPopover;
    eventName?: string;
    eventAction?: ActionEvent;
}
export const ProjectStatusItem = ({ name, state, href, showIndicator: isInteractive, popover, eventName, eventAction }: ProjectStatusItemProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const [showPopover, setShowPopover] = useState<boolean>(false);
    const ref = React.createRef<HTMLDivElement>();
    useEffect(() => {
        setAnchorEl(ref.current);
    }, [ref]);
    const onMouseEnter = () => {
        setShowPopover(true);
    };
    const onMouseLeave = () => {
        setShowPopover(false);
    };
    const dispatchAction = useAnalyticActionDispatch();
    const isUrlActive = useIsPortalUrlActive();
    const onItemClicked = (href: LinkHref) => {
        if (!eventName || !eventAction) {
            return;
        }
        if (!isUrlActive(href, true)) {
            dispatchAction(eventName, eventAction);
        }
    };
    if (state === "Current") {
        return (<div ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {href ? (<InternalLink className={cn(styles.statusItemContainer, styles.active, styles.isLink)} to={href} onClick={() => onItemClicked(href)}>
                        {!isInteractive ? <ProjectStatusIcon state={state}/> : <BeaconInline size={"small"} ripple={0.5} open={true}/>}
                        <span>{name}</span>
                        <RightArrowIcon />
                    </InternalLink>) : (<div className={cn(styles.statusItemContainer, styles.active)}>
                        {!isInteractive ? <ProjectStatusIcon state={state}/> : <BeaconInline size={"small"} ripple={0.5} open={true}/>}
                        <span>{name}</span>
                        <RightArrowIcon />
                    </div>)}
                <ProjectStatusPopover popoverType={popover?.popoverType} unassociatedTargetRoles={popover?.unassociatedTargetRoles} onCustomAction={popover?.onCustomAction} open={showPopover} anchorEl={anchorEl} eventAction={eventAction}/>
            </div>);
    }
    return (<div className={cn(styles.statusItemContainer)}>
            <ProjectStatusIcon state={state}/>
            <span className={cn(styles.statusItemText)}>{name}</span>
        </div>);
};
