/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { FeedResource, GitHubFeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import AdvancedFeedCredentialsFormSection, { AuthenticationType } from "../AdvancedFeedCredentialsFormSection";
import RetryAttemptsFormSection from "../RetryAttemptsFormSection";
import type { FeedEditProps } from "./feedTypeRegistry";
import feedTypeRegistry from "./feedTypeRegistry";
const supportedAuthenticationTypes = [AuthenticationType.Anonymous, AuthenticationType.UsernameAndPassword, AuthenticationType.Token];
class GitHubFeed extends React.Component<FeedEditProps<GitHubFeedResource>> {
    render() {
        return (<React.Fragment>
                <RetryAttemptsFormSection backoffSeconds={this.props.feed.DownloadRetryBackoffSeconds} onBackoffSecondsChanged={(seconds) => this.props.onChange({ ...this.props.feed, DownloadRetryBackoffSeconds: seconds })} attemptCount={this.props.feed.DownloadAttempts} onAttemptCountChanged={(count) => this.props.onChange({ ...this.props.feed, DownloadAttempts: count })} containerKey={this.props.expandableContainerKey}/>
                <AdvancedFeedCredentialsFormSection model={{ username: this.props.feed.Username, password: this.props.feed.Password }} isNew={!this.props.feed.Id} onChanged={(model) => this.props.onChange({ ...this.props.feed, Password: model.password, Username: model.username })} supportedAuthenticationTypes={supportedAuthenticationTypes}/>
            </React.Fragment>);
    }
    static displayName = "GitHubFeed";
}
feedTypeRegistry.registerFeedType({
    type: FeedType.GitHub,
    edit: GitHubFeed as React.ComponentType<FeedEditProps<FeedResource>>,
    text: "GitHub Repository Feed",
    uriNotes: (<span>
            To use the standard public github endpoint, set the url to <code>https://api.github.com</code>
        </span>),
    uriDefault: "https://api.github.com",
});
