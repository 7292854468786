import type { EventCategoryResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { EventCategoryChip, MissingChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FormFieldProps } from "~/components/form";
import type { ComponentProps } from "../../utils/types";
import Lookup from "../Lookup";
interface EventCategoryMultiSelectProps extends FormFieldProps<string[]> {
    items: EventCategoryResource[];
    label?: string | JSX.Element;
    error?: string;
}
const EventCategoryTypedMultiSelect = MultiSelect<EventCategoryResource>();
type RenderChip = ComponentProps<typeof EventCategoryTypedMultiSelect>["renderChip"];
export const EventCategoryMultiSelect: React.FC<EventCategoryMultiSelectProps> = (props) => {
    const chipRenderer: RenderChip = (r, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <EventCategoryChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} eventCategory={item}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.EventCategory} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <EventCategoryTypedMultiSelect fieldName="event categories" renderChip={chipRenderer} {...props}/>;
};
EventCategoryMultiSelect.displayName = "EventCategoryMultiSelect"
