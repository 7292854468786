import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { InterruptionResource, FormParagraphControl } from "@octopusdeploy/octopus-server-client";
import type { TaskSummaryResource } from "@octopusdeploy/octopus-server-client/dist/src/resources/taskSummaryResource";
import { links } from "@octopusdeploy/portal-routes";
import { useCallback, useEffect } from "react";
import * as React from "react";
import TaskStatusIcon from "~/areas/projects/components/TaskStatusIcon/index";
import { repository } from "~/clientInstance";
import type { Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import Dialog from "~/components/Dialog/Dialog";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import InternalLink from "~/components/Navigation/InternalLink/index";
import TaskDetails from "~/components/TaskDetails/TaskDetails";
import Text from "~/primitiveComponents/form/Text/Text";
interface AssignTaskCellProps {
    doBusyTask: DoBusyTask;
    doRefresh: Refresh;
    task: TaskSummaryResource;
}
const getTruncatedInstructions = (interruption: InterruptionResource) => {
    const paragraphElement = interruption.Form.Elements.find((e) => e.Control.Type === "Paragraph");
    if (!paragraphElement) {
        return "";
    }
    // The following conversion is safe as there's a check for paragraph control type above
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const paragraphControl = paragraphElement.Control as FormParagraphControl;
    let instructions = paragraphControl.Text.substring(0, 259);
    if (paragraphControl.Text.length >= 259) {
        instructions = instructions.concat("...");
    }
    return instructions;
};
export function AssignTaskCell({ task, doBusyTask, doRefresh }: AssignTaskCellProps) {
    const [interruption, setInterruption] = React.useState<InterruptionResource>();
    const [hasResponsibility, setHasResponsibility] = React.useState<boolean>(false);
    const [note, setNote] = React.useState<string>("");
    const [assignedUser, setAssignedUser] = React.useState<string>("");
    const getAssignedUser = useCallback(() => {
        if (hasResponsibility)
            return "You";
        if (task.InterruptionSummaries[0].ResponsibleUser && task.InterruptionSummaries[0].ResponsibleUser.length > 0)
            return task.InterruptionSummaries[0].ResponsibleUser;
        return "No one";
    }, [hasResponsibility, task.InterruptionSummaries]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (task.InterruptionSummaries.length === 1 && task.InterruptionSummaries[0].InterruptionId && task.InterruptionSummaries[0].InterruptionId.length > 0) {
            const interruption = await repository.Interruptions.get(task.InterruptionSummaries[0].InterruptionId);
            setInterruption(interruption);
            setHasResponsibility(interruption.HasResponsibility);
        }
    }, []);
    useEffect(() => setAssignedUser(getAssignedUser()), [getAssignedUser, hasResponsibility]);
    const instructions = interruption ? getTruncatedInstructions(interruption) : "";
    const interventionDialogHeader = () => (<div className={styles.interventionDialogHeader}>
            <TaskStatusIcon item={task} iconOnly={true} iconClassName={styles.interventionDialogHeaderIcon}/>
            <span className={styles.interventionDialogHeaderText}>Manual approval required</span>
        </div>);
    const handleSubmit = async (result: string, closeDialog: () => void) => {
        const values = { Notes: note, Instructions: null, Result: result };
        interruption && (await repository.Interruptions.submit(interruption, values));
        closeDialog();
        await doRefresh();
    };
    const interventionDialogActions = (closeDialog: () => void) => {
        const proceedAction = <ActionButton label={"Proceed"} type={ActionButtonType.Primary} key="proceed" onClick={() => handleSubmit("Proceed", closeDialog)}></ActionButton>;
        const abortAction = (<OpenDialogButton label={"Abort"} type={ActionButtonType.Delete} key="abort" renderDialog={({ open, closeDialog }) => (<ConfirmationDialog open={open} title={"Confirm Abort"} onContinueClick={() => handleSubmit("Abort", closeDialog)} onClose={closeDialog}>
                        Are you sure you want to abort?
                    </ConfirmationDialog>)}></OpenDialogButton>);
        const cancelAction = <ActionButton label={"Cancel"} type={ActionButtonType.Secondary} key="cancel" onClick={closeDialog}></ActionButton>;
        return [cancelAction, abortAction, proceedAction];
    };
    const handleAssignToMe = async (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        if (interruption && interruption.CanTakeResponsibility && !hasResponsibility) {
            await repository.Interruptions.takeResponsibility(interruption);
            setHasResponsibility(true);
        }
    };
    const renderActionButton = () => {
        const actionButtonLabel = hasResponsibility ? "REVIEW" : "ASSIGN TO ME";
        return (<span onClick={(e) => handleAssignToMe(e)}>
                <OpenDialogButton label={actionButtonLabel} renderDialog={({ open, closeDialog }) => (<Dialog open={open}>
                            <DialogLayout title={interventionDialogHeader()} actions={interventionDialogActions(closeDialog)} closeDialog={closeDialog} dialogClassName={styles.interventionDialog}>
                                <div className={styles.interventionDialogTaskDetails}>
                                    <TaskDetails task={task} stripAllPadding={true} hideStatusIcon={true}/>
                                </div>

                                <Text label="Instructions" value={instructions} multiline={true} onChange={() => { }} disabled={true} hideUnderline={true}/>

                                <InternalLink to={links.taskPage.generateUrl({ taskId: task.Id })} openInSelf={false} onClick={closeDialog}>
                                    Open Task
                                </InternalLink>

                                <Text label="Notes" value={note} multiline={false} onChange={setNote} placeholder={"Type comments here..."}/>
                            </DialogLayout>
                        </Dialog>)}></OpenDialogButton>
            </span>);
    };
    const renderSingleApprovalCell = () => (<div className={styles.assignTaskCell}>
            <div className={styles.assignTaskCellText}>
                Assigned to:
                <strong> {assignedUser}</strong>
            </div>
            {interruption?.CanTakeResponsibility ? renderActionButton() : <></>}
        </div>);
    const renderMultipleApprovalCell = () => (<div className={styles.assignTaskCell}>
            <div className={styles.assignTaskCellText}>Awaiting {task.InterruptionSummaries.length} approvals</div>
        </div>);
    const approvalCell = () => {
        if (interruption)
            return renderSingleApprovalCell();
        if (task.InterruptionSummaries.length > 1)
            return renderMultipleApprovalCell();
        return <></>;
    };
    return approvalCell();
}
const styles = {
    assignTaskCell: css({
        display: "flex",
        justifyContent: "space-between",
    }),
    assignTaskCellText: css({
        paddingRight: space[12],
    }),
    interventionDialog: css({
        padding: space[16],
    }),
    interventionDialogHeader: css({
        fontSize: space[24],
    }),
    interventionDialogHeaderIcon: css({
        height: "2.2rem",
        width: "2.2rem",
    }),
    interventionDialogHeaderText: css({
        paddingLeft: space[12],
    }),
    interventionDialogTaskDetails: css({
        marginBottom: space[16],
    }),
};
