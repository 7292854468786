import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
interface DeleteBlueprintProps {
    blueprintName: string;
}
export default ({ blueprintName }: DeleteBlueprintProps) => (<div>
        <Callout title="This is a destructive action" type={"danger"}>
            This action <strong>cannot</strong> be undone. This will permanently delete the process blueprint <strong>{blueprintName}</strong>.
        </Callout>
    </div>);
