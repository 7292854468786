/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import type { VhdProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation, GetPrimaryPackageReference, InitialisePrimaryPackageReference, PackageSelectionMode, SetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import PackageDownloadOptions from "~/components/PackageDownloadOptions/PackageDownloadOptions";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import { ExpandableFormSection, FormSectionHeading } from "~/components/form";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/CommonSummaryHelper";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { getChangesToPackageReference } from "../getChangesToPackageReference";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
interface VhdActionSummaryState {
    feedName?: string;
}
class VhdActionSummary extends BaseComponent<ActionSummaryProps, VhdActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages!);
        return pkg ? (<div>
                {"Deploy VHD"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg}/>
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        to deployment targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>{" "}
                    </span>)}
            </div>) : (<Callout type={"warning"} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>);
    }
    static displayName = "VhdActionSummary";
}
type VhdActionEditState = {};
type VhdActionEditProps = ActionEditProps<VhdProperties>;
type VhdActionEditInternalProps = VhdActionEditProps & ActionWithFeeds;
class VhdActionEditInternal extends BaseComponent<VhdActionEditInternalProps, VhdActionEditState> {
    constructor(props: VhdActionEditInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.props.feeds), true);
    }
    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }
        const pkg = GetPrimaryPackageReference(this.props.packages);
        const help = this.props.feeds.length > 0 ? (<span>
                    This step is used to deploy a VHD to one or more machines. The machines require Windows Server 2012 or newer. You can configure the remote machines to deploy to in{" "}
                    <InternalLink to={links.infrastructureRootRedirect.generateUrl({ spaceId: repository.spaceId! })} openInSelf={false}>
                        Infrastructure
                    </InternalLink>
                    . The package that you wish to deploy should contain a single VHD or VHDX file in the root of the package. Learn more about{" "}
                    <ExternalLink href="DocumentationPackaging">what your packages should contain, and how to create them</ExternalLink>.
                </span>) : (<span>Choose the package you which to deploy</span>);
        return (<div>
                <FormSectionHeading title="Package Details"/>
                <ExpandableFormSection errorKey="package" isExpandedByDefault={this.props.expandedByDefault} title="Package" summary={CommonSummaryHelper.deferredPackageSummary(pkg, this.props.feeds)} help={help}>
                    <DeferredPackageSelector packageId={pkg.PackageId} feedId={pkg.FeedId} onPackageIdChange={(packageId) => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))} onFeedIdChange={(feedId) => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))} packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")} feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")} projectId={this.props.projectId} feeds={this.props.feeds} localNames={this.props.localNames} refreshFeeds={this.loadFeeds} parameters={this.props.parameters} packageSelectionMode={pkg.Properties["SelectionMode"]} packageSelectionModeError={this.props.getFieldError("SelectionMode")} onPackageSelectionModeChange={(value) => this.props.setPackages(SetPrimaryPackageReference(getChangesToPackageReference(value), this.props.packages))} packageParameterName={pkg.Properties["PackageParameterName"]} packageParameterError={this.props.getFieldError("PackageParameterName")} onPackageParameterChange={(packageParameter) => this.props.setPackages(SetPrimaryPackageReference({ Properties: { ...pkg.Properties, PackageParameterName: packageParameter } }, this.props.packages))}/>
                    {pkg.Properties["SelectionMode"] === PackageSelectionMode.Immediate && (<ProcessFeedLookup feedId={pkg.FeedId}>
                            {(feed) => (<PackageDownloadOptions packageAcquisitionLocation={pkg.AcquisitionLocation} onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))} feed={feed} projectId={this.props.projectId} localNames={this.props.localNames}/>)}
                        </ProcessFeedLookup>)}
                </ExpandableFormSection>
            </div>);
    }
    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    static displayName = "VhdActionEditInternal";
}
export function VhdActionEdit(props: PropsWithChildren<VhdActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    return <VhdActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds}/>;
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.Vhd",
    summary: (properties, targetRolesAsCSV, packages) => <VhdActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages}/>,
    editSections: { default: VhdActionEdit },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        permanent: ["Octopus.Features.Vhd"],
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomDirectory",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
        ],
        initial: ["Octopus.Features.SubstituteInFiles"],
    },
});
