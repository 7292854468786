import { css } from "@emotion/css";
import { InfoIcon, Tooltip } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
export default function DefaultValueIndicator({ show }: {
    show: boolean;
}) {
    if (!show) {
        return null;
    }
    return (<Tooltip content={"Inherited default value"}>
            <div className={styles.editIcon}>
                <InfoIcon />
            </div>
        </Tooltip>);
}
const styles = {
    editIcon: css({
        color: themeTokens.color.icon.tertiary, // Don't attract attention to this icon
    }),
};
