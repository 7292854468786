/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
/* eslint-disable no-eq-null */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { GitRefResource, FeedResource, WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import type { UserOnboardingResource } from "@octopusdeploy/octopus-server-client/src/resources/userOnboardingResource";
import * as React from "react";
import type { StepEditorEvent } from "~/analytics/Analytics";
import { ActionContainerImageSelectorFormSection } from "~/areas/projects/components/Process/Common/ActionContainerImageSelectorFormSection";
import { TargetRolesFormSection } from "~/areas/projects/components/Process/Common/TargetRolesFormSection";
import type { RunOn, RunOnServerOrWorkerPool } from "~/areas/projects/components/Process/types";
import { ExecutionLocation, RunOnDeploymentTarget, TargetRoles } from "~/areas/projects/components/Process/types";
import type { MixedExecutionLocationsConfig } from "~/components/Actions/pluginRegistry";
import { RoleChip, WorkerPoolChip } from "~/components/Chips";
import { WorkerPoolsContextualHelp, WorkerPoolsVariableContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { ExecuteOctopusServer } from "~/components/Images/ExecutionLocation/ExecuteOctopusServer";
import { ExecuteOctopusServerRoles } from "~/components/Images/ExecutionLocation/ExecuteOctopusServerRoles";
import { ExecuteTargets } from "~/components/Images/ExecutionLocation/ExecuteTargets";
import { ExecuteWorker } from "~/components/Images/ExecutionLocation/ExecuteWorker";
import { ExecuteWorkerRoles } from "~/components/Images/ExecutionLocation/ExecuteWorkerRoles";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ExpandableFormSection, Note, Select, Summary, UnstructuredFormSection } from "~/components/form";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import WorkerPoolVariableSelect from "~/components/form/WorkerPoolSelect/WorkerPoolVariableSelect";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";
import { generateDefaultActionContainer, isRunOnServerOrWorkerPool } from "./CommonProcessHelpers";
import styles from "./style.module.less";
interface ExecutionPlanProps {
    projectId: string;
    projectSlug: string;
    gitRef: GitRefResource | undefined;
    expandedByDefault: boolean;
    executionLocation: ActionExecutionLocation;
    runOnServerOrWorkerPoolCopy: RunOnServerOrWorkerPool | null;
    runOn: RunOn;
    targetRoleOption: TargetRoles;
    targetRoles: string;
    targetWorkerPool: string | null;
    targetWorkerPoolVariable: string | null;
    isChildStep: boolean;
    maxParallelism: string;
    availableRoles: string[];
    canRunOnWorker: boolean;
    availableWorkerPools: WorkerPoolResource[];
    isBuiltInWorkerEnabled: boolean;
    targetRolesError: string;
    imageNameError: string;
    runsOnServer: boolean;
    feeds: FeedResource[];
    canRunInContainer: boolean;
    containerImageRecommendation?: JSX.Element[];
    loadFeeds: (callback?: (feeds: FeedResource[]) => void) => Promise<void>;
    getFieldError(field: string): string;
    onRunOnChanged<T extends RunOn>(runOn: T): void;
    onTargetRolesChanged(roles: string[]): void;
    onTargetWorkerPoolChanged(workerPoolId: string, workerPoolVariable: string | null): void;
    onMaxParallelismChanged(max: string): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    analyticsStepEditorDispatch: (name: string, event: Omit<StepEditorEvent, "stepTemplate">) => void;
    mixedExecutionLocationsConfig: MixedExecutionLocationsConfig;
    disableInlineExecutionContainers: boolean;
    isKubernetesStep: boolean;
    userOnboarding: UserOnboardingResource | null;
    shouldRenderTargetRolesExpander: boolean;
    stepTemplateName: string;
}
interface ExecutionPlanState {
    showWindowSize: boolean;
    targetWorkerPoolId: string;
    targetWorkerPoolVariable: string | null;
    poolSelect: WorkerPoolSelect;
}
enum WorkerPoolSelect {
    Pool = "Pool",
    Variable = "Variable"
}
type Props = ExecutionPlanProps;
class ExecutionPlan extends React.Component<Props, ExecutionPlanState> {
    phaseEnvironments: string[] = [];
    environmentNameMap = {};
    constructor(props: Props) {
        super(props);
        this.state = this.getStateUpdate();
    }
    getStateUpdate(): ExecutionPlanState {
        return {
            showWindowSize: this.props.maxParallelism ? this.props.maxParallelism.length > 0 : false,
            targetWorkerPoolId: isRunOnServerOrWorkerPool(this.props.runOn) ? this.props.targetWorkerPool || this.getDefaultWorkerIdPoolForRunOn(this.props.runOn)! : null!,
            targetWorkerPoolVariable: this.props.targetWorkerPoolVariable!,
            poolSelect: !!this.props.targetWorkerPoolVariable ? WorkerPoolSelect.Variable : WorkerPoolSelect.Pool,
        };
    }
    componentDidUpdate(prevProps: Props) {
        if (prevProps.maxParallelism !== this.props.maxParallelism || prevProps.runOn !== this.props.runOn || prevProps.targetWorkerPoolVariable !== this.props.targetWorkerPoolVariable) {
            this.setState(this.getStateUpdate());
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: ExecutionPlanState) {
        return (nextProps.runOn !== this.props.runOn ||
            nextProps.targetRoles !== this.props.targetRoles ||
            nextProps.targetRoleOption !== this.props.targetRoleOption ||
            nextProps.maxParallelism !== this.props.maxParallelism ||
            nextProps.targetRolesError !== this.props.targetRolesError ||
            nextProps.imageNameError !== this.props.imageNameError ||
            nextProps.feeds !== this.props.feeds ||
            nextState.showWindowSize !== this.state.showWindowSize ||
            nextState.targetWorkerPoolId !== this.state.targetWorkerPoolId ||
            nextState.targetWorkerPoolVariable !== this.state.targetWorkerPoolVariable ||
            nextState.poolSelect !== this.state.poolSelect);
    }
    render() {
        return (<div>
                {this.props.mixedExecutionLocationsConfig.enabled && (<>
                        <ExpanderSectionHeading title={this.props.mixedExecutionLocationsConfig.title}/>
                        {this.props.mixedExecutionLocationsConfig.callout}
                    </>)}

                <ExpandableFormSection isExpandedByDefault={this.props.expandedByDefault} errorKey="ActionExecutionLocation" title="Execution Location" summary={this.executionLocationSummary()} help="Where will Octopus run this step?" fillCardWidth={CardFill.FillRight}>
                    {this.renderRunOnOptions()}
                </ExpandableFormSection>
                {this.renderRolesOptions()}
                {(!this.props.mixedExecutionLocationsConfig.enabled || !this.props.mixedExecutionLocationsConfig.allLocationsRequireActionContainer) && (<ActionContainerImageSelectorFormSection projectId={this.props.projectId} runOn={this.props.runOn} canRunInContainer={this.props.canRunInContainer} expandedByDefault={this.props.expandedByDefault} feeds={this.props.feeds} loadFeeds={this.props.loadFeeds} onRunOnChanged={this.props.onRunOnChanged} imageNameError={this.props.imageNameError} doBusyTask={this.props.doBusyTask} disableInlineExecutionContainers={this.props.disableInlineExecutionContainers}/>)}
            </div>);
    }
    private executionLocationSummary() {
        const summary = [<span>This step will run</span>];
        if (this.state.targetWorkerPoolId || this.state.targetWorkerPoolVariable) {
            summary.push(<span>
                    {" "}
                    on a <strong>worker</strong> from a worker pool
                </span>);
        }
        else {
            // keeping these as four cases not two and combining the if checks because I am
            // not sure of the priority of Octopus.Action.RunOnServer over props.executionLocation
            if (this.props.executionLocation === ActionExecutionLocation.AlwaysOnServer) {
                summary.push(<span>
                        {" "}
                        on the <strong>Octopus Server</strong>
                    </span>);
            }
            else if (this.props.executionLocation === ActionExecutionLocation.AlwaysOnTarget) {
                summary.push(<span>
                        {" "}
                        on each <strong>deployment target</strong>
                    </span>);
            }
            else if (this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget) {
                summary.push(<span>
                        {" "}
                        on each <strong>deployment target</strong>
                    </span>);
            }
            else {
                summary.push(<span>
                        {" "}
                        on the <strong>Octopus Server</strong>
                    </span>);
            }
        }
        if (this.props.targetRoleOption === TargetRoles.None) {
            summary.push(<p />);
            return Summary.summary(React.Children.toArray(summary));
        }
        if (this.props.runOn.executionLocation !== ExecutionLocation.DeploymentTarget &&
            (this.props.runOn.executionLocation === ExecutionLocation.OctopusServerForRoles || this.props.runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles)) {
            summary.push(<span> on behalf of each deployment target</span>);
        }
        return Summary.summary(React.Children.toArray(summary));
    }
    private workerPoolSummary() {
        if (this.state.poolSelect === WorkerPoolSelect.Variable) {
            return Summary.summary(<span>
                    The worker pool from the <strong>{this.state.targetWorkerPoolVariable}</strong> variable will be used
                </span>);
        }
        const worker = this.props.availableWorkerPools.find((element) => element.Id === this.state.targetWorkerPoolId);
        return worker ? Summary.summary(<WorkerPoolChip workerPoolName={worker.Name} workerPoolType={worker.WorkerPoolType}/>) : Summary.placeholder("No pool selected - Let Octopus pick the default pool");
    }
    private workerPoolsAvailable() {
        return this.props.availableWorkerPools.length > 0 && this.props.canRunOnWorker;
    }
    private renderRunOnOptions = () => {
        const imageHeight = "5rem";
        const alwaysOnServer = this.props.executionLocation === ActionExecutionLocation.AlwaysOnServer;
        if (alwaysOnServer && this.props.targetRoleOption !== TargetRoles.Optional) {
            if (this.workerPoolsAvailable()) {
                // Workers
                return (<div>
                        {this.props.targetRoleOption !== TargetRoles.None ? (<div className={styles.col}>
                                {" "}
                                <span>
                                    This step will run once on a <strong>worker</strong> on behalf of each deployment target
                                </span>
                                <ExecuteWorkerRoles height={imageHeight}/>
                                <Note>Execute on a worker on behalf of all the deployment targets with selected target tags.</Note>
                            </div>) : (<div className={styles.col}>
                                {" "}
                                <span>
                                    This step will run once on a <strong>worker</strong>
                                </span>
                                <ExecuteWorker height={imageHeight}/>
                                <Note>Execute once on a worker.</Note>
                            </div>)}
                    </div>);
            }
            else {
                // Octopus Server
                return (<div>
                        {this.props.targetRoleOption !== TargetRoles.None ? (<div className={styles.col}>
                                {" "}
                                <span>
                                    This step will run on the <strong>Octopus Server</strong> on behalf of each deployment target
                                </span>
                                <ExecuteOctopusServerRoles height={imageHeight}/>
                                <Note>Execute on the Octopus Server on behalf of all the deployment targets with selected target tags.</Note>
                            </div>) : (<div className={styles.col}>
                                {" "}
                                <span>
                                    This step will run on the <strong>Octopus Server</strong>
                                </span>
                                <ExecuteOctopusServer height={imageHeight}/>
                                <Note>Execute once on the Octopus Server.</Note>
                            </div>)}
                    </div>);
            }
        }
        if (this.props.executionLocation === ActionExecutionLocation.AlwaysOnTarget) {
            return (<div className={styles.col}>
                    <span>
                        This step will run on each <strong>deployment target</strong>
                    </span>
                    <ExecuteTargets height={imageHeight}/>
                    <Note>Execute on each deployment target with the selected target tags.</Note>
                </div>);
        }
        return (<div className={styles.row}>
                {this.renderExecutionLocationRadioGroup(alwaysOnServer)}

                <div className={styles.images}>
                    {this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget ? (<TransitionAnimation>
                            <ExecuteTargets height={imageHeight}/>
                            <Note>Execute on each deployment target with the selected target tags.</Note>
                        </TransitionAnimation>) : (<>
                            {this.props.runOn.executionLocation === ExecutionLocation.OctopusServer && (<TransitionAnimation>
                                    <ExecuteOctopusServer height={imageHeight}/>
                                    <Note>Execute once on the Octopus Server.</Note>
                                </TransitionAnimation>)}
                            {this.props.runOn.executionLocation === ExecutionLocation.OctopusServerForRoles && (<TransitionAnimation>
                                    <ExecuteOctopusServerRoles height={imageHeight}/>
                                    <Note>Execute on the Octopus Server on behalf of all the deployment targets with selected target tags.</Note>
                                </TransitionAnimation>)}
                            {this.props.runOn.executionLocation === ExecutionLocation.WorkerPool && (<TransitionAnimation>
                                    <ExecuteWorker height={imageHeight}/>
                                    <Note>Execute once on a worker.</Note>
                                </TransitionAnimation>)}
                            {this.props.runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles && (<TransitionAnimation>
                                    <ExecuteWorkerRoles height={imageHeight}/>
                                    <Note>Execute on a worker on behalf of all the deployment targets with selected target tags.</Note>
                                </TransitionAnimation>)}
                        </>)}
                </div>
            </div>);
    };
    private renderExecutionLocationRadioGroup(alwaysOnServer: boolean) {
        return (<RadioButtonGroup accessibleName={"The location where this step will run"} value={this.props.runOn.executionLocation} onChange={this.onRunOnSelectChanged}>
                {!this.props.isChildStep && this.workerPoolsAvailable() && <RadioButton value={ExecutionLocation.WorkerPool} label="Run once on a worker"/>}
                {!this.props.isChildStep && !this.workerPoolsAvailable() && <RadioButton disabled={!this.props.isBuiltInWorkerEnabled} value={ExecutionLocation.OctopusServer} label="Run on the Octopus Server"/>}
                {this.workerPoolsAvailable() ? (<RadioButton value={ExecutionLocation.WorkerPoolForRoles} label="Run on a worker on behalf of each deployment target"/>) : (<RadioButton disabled={!this.props.isBuiltInWorkerEnabled} value={ExecutionLocation.OctopusServerForRoles} label="Run on the Octopus Server on behalf of each deployment target"/>)}
                {!alwaysOnServer && <RadioButton value={ExecutionLocation.DeploymentTarget} label="Run on each deployment target"/>}
            </RadioButtonGroup>);
    }
    private onRunOnSelectChanged = (runOnTypeString: string) => {
        const runOnType = runOnTypeString as ExecutionLocation;
        if (runOnType === ExecutionLocation.DeploymentTarget) {
            this.props.onRunOnChanged(new RunOnDeploymentTarget());
        }
        else {
            const newRunOn: RunOnServerOrWorkerPool = this.props.runOnServerOrWorkerPoolCopy
                ? { ...this.props.runOnServerOrWorkerPoolCopy, executionLocation: runOnType }
                : { container: generateDefaultActionContainer(), runningInContainer: false, executionLocation: runOnType };
            this.setWorkerPoolDefault(this.state.poolSelect, newRunOn);
            this.props.onRunOnChanged(newRunOn);
        }
    };
    private renderRolesOptions = () => {
        if (this.props.targetRoleOption === TargetRoles.None) {
            return null;
        }
        if (this.props.isChildStep && !this.props.runsOnServer) {
            const roles = this.roleList(this.props.targetRoles);
            const pluralized = roles.length === 1 ? "" : "s";
            return (<UnstructuredFormSection>
                    This step is part of a rolling step, which runs on deployment targets with the following target {`tag${pluralized}`} {roles}
                </UnstructuredFormSection>);
        }
        const nodes = [];
        if (!(this.props.runOn.executionLocation === ExecutionLocation.DeploymentTarget) && (this.props.runOn.executionLocation === ExecutionLocation.WorkerPool || this.props.runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles)) {
            nodes.push(<ExpandableFormSection isExpandedByDefault={this.props.expandedByDefault} summary={this.workerPoolSummary()} title="Worker Pool" errorKey="Octopus.Action.WorkerPoolId" help="Which worker pool should Octopus use for this step?" contextualHelp={<WorkerPoolsContextualHelp />}>
                    <RadioButtonGroup value={this.state.poolSelect} onChange={this.onPoolSelectChanged}>
                        <RadioButton value={WorkerPoolSelect.Pool} label="Runs on a worker from a specific worker pool"/>
                        {this.state.poolSelect === WorkerPoolSelect.Pool && (<Select items={this.props.availableWorkerPools.map((e) => {
                        return { value: e.Id, text: e.Name };
                    })} value={this.state.targetWorkerPoolId ? this.state.targetWorkerPoolId : undefined} validate={(value) => (value == null ? "Please select a worker pool" : "")} label={"Select a pool"} onChange={(workerPoolId) => this.onTargetWorkerPoolChange(workerPoolId!, null!)} sortItems={false}/>)}
                        <RadioButton value={WorkerPoolSelect.Variable} label={<>
                                    <span>Runs on a worker from a pool selected via a variable</span> <WorkerPoolsVariableContextualHelp absolutePosition={true}/>
                                </>}/>
                        {this.state.poolSelect === WorkerPoolSelect.Variable && (<WorkerPoolVariableSelect doBusyTask={this.props.doBusyTask} projectId={this.props.projectId} gitRef={this.props.gitRef} value={this.state.targetWorkerPoolVariable ?? ""} onChange={(workerPoolVariable) => this.onTargetWorkerPoolChange(null!, workerPoolVariable)}/>)}
                    </RadioButtonGroup>
                </ExpandableFormSection>);
        }
        if (this.props.shouldRenderTargetRolesExpander) {
            nodes.push(<TargetRolesFormSection projectSlug={this.props.projectSlug} expandedByDefault={this.props.expandedByDefault} availableRoles={this.props.availableRoles} targetRoles={this.props.targetRoles} onTargetRolesChanged={this.props.onTargetRolesChanged} runOn={this.props.runOn} errorMessage={this.props.targetRolesError} analyticsStepEditorDispatch={this.props.analyticsStepEditorDispatch} isKubernetesStep={this.props.isKubernetesStep} doBusyTask={this.props.doBusyTask} userOnboarding={this.props.userOnboarding} analyticsArea="Execution Location" stepTemplateName={this.props.stepTemplateName} isGuidedSetup={false}/>);
        }
        if (this.props.isChildStep && this.props.runsOnServer) {
            const executionLocation = this.state.targetWorkerPoolId ? (<span>
                    {" "}
                    a <strong>worker</strong> from the worker pool
                </span>) : (<span>
                    {" "}
                    the <strong>Octopus Server</strong>
                </span>);
            const roles = this.roleList(this.props.targetRoles);
            const pluralized = roles.length === 1 ? "" : "s";
            nodes.push(<UnstructuredFormSection>
                    This step is part of a rolling step, which runs on {executionLocation} on behalf of each deployment target with the following target {`tag${pluralized}`} {roles}
                </UnstructuredFormSection>);
        }
        return React.Children.toArray(nodes);
    };
    private roleList(csv: string) {
        const list = ParseHelper.parseCSV(csv);
        return list.map((r) => <RoleChip role={r} key={"role-" + r} showContextualHelp/>);
    }
    private onPoolSelectChanged = (selectString: string) => {
        const select = selectString as WorkerPoolSelect;
        if (isRunOnServerOrWorkerPool(this.props.runOn)) {
            this.setWorkerPoolDefault(select, this.props.runOn);
        }
        this.setState({ poolSelect: select });
    };
    private setWorkerPoolDefault(select: WorkerPoolSelect, runOn: RunOnServerOrWorkerPool) {
        if (select === WorkerPoolSelect.Pool) {
            const workerPoolId = this.state.targetWorkerPoolId || this.getDefaultWorkerIdPoolForRunOn(runOn);
            this.onTargetWorkerPoolChange(workerPoolId!, null!);
        }
        else {
            this.onTargetWorkerPoolChange(null!, this.state.targetWorkerPoolVariable);
        }
    }
    private getDefaultWorkerIdPoolForRunOn(runOn: RunOnServerOrWorkerPool) {
        if (runOn.executionLocation === ExecutionLocation.WorkerPool || runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles) {
            const defaultPool = this.props.availableWorkerPools ? this.props.availableWorkerPools.filter((p) => p.IsDefault).pop() : null!;
            return defaultPool ? defaultPool.Id : null!;
        }
        return null;
    }
    private onTargetWorkerPoolChange = (workerPoolId: string, workerPoolVariable: string | null) => {
        this.setState({
            targetWorkerPoolId: workerPoolId,
            targetWorkerPoolVariable: workerPoolVariable,
        }, () => this.props.onTargetWorkerPoolChanged(workerPoolId, workerPoolVariable));
    };
    static displayName = "ExecutionPlan";
}
export default ExecutionPlan;
