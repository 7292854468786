import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonDeploymentTargetOverview } from "../../CommonComponents/CommonDeploymentTargetViews";
export const InfrastructureDeploymentTargetsSelectRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.machines.select,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDeploymentTargetOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
