import type { GitBranchResource, ProjectResource, GitRef, GitPersistenceSettings } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, isGitBranch, toGitBranch } from "@octopusdeploy/octopus-server-client";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import { repository } from "~/clientInstance";
const getTextValuePairForProjectBranch = (branch: GitBranchResource, project: Readonly<ProjectResource>): GitRefOption => {
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const isDefaultBranch = branch.Name === project.PersistenceSettings.DefaultBranch;
    return {
        value: branch.CanonicalName,
        isDefault: isDefaultBranch,
        canWrite: !branch.IsProtected,
    };
};
const buildProjectBranchNamesList = (branches: GitBranchResource[], project: Readonly<ProjectResource>): GitRefOption[] => {
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    return branches.map((branch) => getTextValuePairForProjectBranch(branch, project));
};
const getFilteredProjectBranchesList = async (project: Readonly<ProjectResource>, filterTerm: string): Promise<GitRefOption[]> => {
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const filteredBranchResources = await repository.Projects.searchBranches(project, filterTerm);
    let branches = filteredBranchResources.Items;
    // If the default branch is in the list, put it at the top
    const defaultBranchIndex = getDefaultBranchIndex(branches, project.PersistenceSettings);
    if (defaultBranchIndex !== -1) {
        const defaultBranch = branches.splice(defaultBranchIndex, 1);
        branches = [...defaultBranch, ...branches];
    }
    return buildProjectBranchNamesList(branches, project);
};
const getProjectBranchesList = async (project: Readonly<ProjectResource>, branchResources: GitBranchResource[], selectedRef: GitRef | undefined): Promise<GitRefOption[]> => {
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const vcsPersistenceSettings = project.PersistenceSettings;
    let branches = branchResources;
    // Add the currently selected branch to the list (if necessary)
    if (selectedRef && isGitBranch(selectedRef) && !branches.find((item) => item.CanonicalName === selectedRef)) {
        const selectedBranchResource = await repository.Projects.tryGetBranch(project, selectedRef);
        // It may be that the current branch has been deleted directly in the repo. Therefore, only add it if it was found.
        if (selectedBranchResource !== null) {
            branches = [selectedBranchResource, ...branches];
        }
    }
    const defaultBranchIndex = getDefaultBranchIndex(branches, vcsPersistenceSettings);
    // If the default branch isn't in the list, add it
    if (defaultBranchIndex === -1) {
        // It may be that the default branch has been deleted directly in the repo. Therefore, only add it if it was found.
        const branchResource = await repository.Projects.tryGetBranch(project, vcsPersistenceSettings.DefaultBranch);
        if (branchResource !== null) {
            // If we have found the default branch, put it at the top
            branches = [branchResource, ...branches];
        }
    }
    else {
        // If the default branch is already in the list, move it to the top
        const defaultBranch = branches.splice(defaultBranchIndex, 1);
        branches = [...defaultBranch, ...branches];
    }
    return buildProjectBranchNamesList(branches, project);
};
const getDefaultBranchIndex = (branches: GitBranchResource[], settings: GitPersistenceSettings) => {
    return branches.findIndex((item) => item.CanonicalName === settings.DefaultBranch || item.CanonicalName === toGitBranch(settings.DefaultBranch));
};
export { getProjectBranchesList, getFilteredProjectBranchesList, getTextValuePairForProjectBranch, buildProjectBranchNamesList };
