import React from "react";
import type { StoredStep, StoredAction } from "../../types";
import type { ProcessStateSelectors } from "../ProcessContextState";
export interface ProcessEditorSearchFilterNamedResource {
    Id?: string;
    Name?: string;
}
export interface ProcessEditorSearchFilter {
    filterKeyword: string;
    environment?: ProcessEditorSearchFilterNamedResource | null;
    channel?: ProcessEditorSearchFilterNamedResource | null;
    includeUnscoped?: boolean;
}
export const isSearchFilterNotEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
    return value !== null && value !== undefined;
};
export type ProcessEditorSearchFilteredStep = {
    step: StoredStep;
    index: number;
    filtered: boolean;
};
export type ProcessEditorSearchFilterResult = {
    filterCount: number;
    steps: ProcessEditorSearchFilteredStep[];
};
export const searchFilterEmptyResult: ProcessEditorSearchFilterResult = { filterCount: 0, steps: [] };
export function getEmptySearchFilter(): ProcessEditorSearchFilter {
    return {
        filterKeyword: "",
        environment: null,
        channel: null,
        includeUnscoped: true,
    };
}
export const useFilteredProcess = (selectors: ProcessStateSelectors, searchFilter: ProcessEditorSearchFilter) => {
    return React.useMemo(() => getFilteredProcess(selectors, searchFilter), [searchFilter, selectors]);
};
export const getStepContainsKeywordPredicate = (lookupActions: (step: StoredStep) => string[], keyword: string = "") => {
    return (step: StoredStep) => {
        const keywordLower = keyword.toLowerCase();
        return step.Name.toLowerCase().includes(keywordLower) || lookupActions(step).filter((name) => !!name && name.toLowerCase().includes(keywordLower)).length > 0;
    };
};
export const getStepEnvironmentPredicate = (lookupAction: (id: string) => StoredAction, environment: ProcessEditorSearchFilterNamedResource, includeUnscoped: boolean = true) => {
    return (step: StoredStep) => {
        return step.ActionIds.some((a) => {
            const action = lookupAction(a);
            return ((includeUnscoped && action.Environments.length === 0 && !action.ExcludedEnvironments.some((e) => e === environment.Id)) || // unscoped and not excluded
                (action.Environments.some((e) => e === environment.Id) && !action.ExcludedEnvironments.some((e) => e === environment.Id))); // scoped and not excluded
        });
    };
};
export const getStepHasChannelPredicate = (lookupAction: (id: string) => StoredAction, channel: ProcessEditorSearchFilterNamedResource, includeUnscoped: boolean = true) => {
    return (step: StoredStep) => {
        return step.ActionIds.some((a) => {
            const action = lookupAction(a);
            return ((includeUnscoped && action.Channels.length === 0) || // unscoped
                action.Channels.some((e) => e === channel.Id)); // scoped
        });
    };
};
export type ProcessPredicate = (step: StoredStep) => boolean;
export const getFilteredProcess = (selectors: ProcessStateSelectors, searchFilter: ProcessEditorSearchFilter = { filterKeyword: "" }) => {
    const { channel, environment, includeUnscoped, filterKeyword } = searchFilter;
    const { getActionById, getAllSteps } = selectors;
    const filters: ProcessPredicate[] = [
        getStepContainsKeywordPredicate((step) => step.ActionIds.map((x) => getActionById(x).Name), filterKeyword),
        //TODO: Channel not applicable for Runbook so yeah...
        channel ? getStepHasChannelPredicate(getActionById, channel, includeUnscoped) : null,
        environment ? getStepEnvironmentPredicate(getActionById, environment, includeUnscoped) : null,
    ].filter(isSearchFilterNotEmpty);
    return getAllSteps().reduce((prev, step, index) => {
        const shouldFilter = filters.every((predicate) => predicate(step));
        return {
            filterCount: shouldFilter ? prev.filterCount + 1 : prev.filterCount,
            steps: [
                ...prev.steps,
                {
                    step,
                    index: index + 1,
                    filtered: shouldFilter,
                },
            ],
        };
    }, searchFilterEmptyResult);
};
