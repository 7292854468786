/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionButton, ActionButtonType, CustomMenu, MenuItemButton, useMenuState, Callout } from "@octopusdeploy/design-system-components";
import type { MultiTenancyStatusResource, ProjectResource, TenantResource, TenantsFilterQueryParameters } from "@octopusdeploy/octopus-server-client";
import { Permission, Repository, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import { Note, required, Select, Text } from "~/components/form";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import { configurationActions } from "../../configuration/reducers/configurationArea";
interface AddOrCloneTenantProps {
    type?: ActionButtonType;
    project?: Readonly<ProjectResource> | null;
    disableCloning?: boolean;
    disableCloningReason?: string | undefined;
}
export function AddOrCloneTenant({ type = ActionButtonType.Primary, project = null, disableCloning = false, disableCloningReason = undefined }: AddOrCloneTenantProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const { closeDialog: closeAddDialog, openDialog: openAddDialog, isOpen: isAddDialogOpen } = useDialogTrigger();
    const { closeDialog: closeCloneDialog, openDialog: openCloneDialog, isOpen: isCloneDialogOpen } = useDialogTrigger();
    const trackAction = useAnalyticActionDispatch();
    const navigation = useSpaceAwareNavigation();
    function openAddTenantDialog() {
        openAddDialog();
        trackAction("Open Add New Tenant Dialog", { resource: "Tenant", action: Action.View });
    }
    function openCloneTenantDialog() {
        openCloneDialog();
        trackAction("Open Clone Tenant Dialog", { resource: "Tenant", action: Action.View });
    }
    function onBlankTenantCreated(tenant: TenantResource) {
        closeAddDialog();
        trackAction("Add Blank Tenant", { resource: "Tenant", action: Action.Add });
        navigation.navigate(links.tenantOverviewPage.generateUrl({ spaceId: tenant.SpaceId, tenantId: tenant.Id }));
    }
    function onClonedTenantCreated(tenant: TenantResource) {
        closeCloneDialog();
        trackAction("Add Tenant From Clone", { resource: "Tenant", action: Action.Add });
        navigation.navigate(links.tenantOverviewPage.generateUrl({ spaceId: tenant.SpaceId, tenantId: tenant.Id }));
    }
    return (<PermissionCheck permission={Permission.TenantCreate}>
            <ActionButton type={type} icon={<DropDownIcon />} iconPosition="right" label="Add Tenant" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <CustomMenu accessibleName={"Add Tenant"} menuState={menuState}>
                <MenuItemButton title={"Add blank tenant"} onClick={openAddTenantDialog}>
                    Add blank tenant
                </MenuItemButton>
                {disableCloning ? (<MenuItemButton disabled={true} disableReason={disableCloningReason}>
                        Clone an existing tenant
                    </MenuItemButton>) : (<MenuItemButton title={"Clone an existing tenant"} onClick={openCloneTenantDialog}>
                        Clone an existing tenant
                    </MenuItemButton>)}
            </CustomMenu>
            <Dialog open={isAddDialogOpen}>
                <AddTenantDialog title="Add New Tenant" project={project} tenantCreated={onBlankTenantCreated}/>
            </Dialog>
            <Dialog open={isCloneDialogOpen}>
                <CloneTenantDialog title="Clone Tenant" project={project} tenantCreated={onClonedTenantCreated}/>
            </Dialog>
        </PermissionCheck>);
}
interface AddTenantDialogInternalProps {
    title: string;
    project: Readonly<ProjectResource> | null;
    tenantCreated(tenant: TenantResource): any;
}
interface AddTenantDialogInternalState extends DataBaseComponentState {
    name: string;
    description?: string;
}
class AddTenantDialogInternal extends DataBaseComponent<GlobalConnectedProps & GlobalDispatchProps & AddTenantDialogInternalProps, AddTenantDialogInternalState> {
    constructor(props: GlobalConnectedProps & GlobalDispatchProps & AddTenantDialogInternalProps) {
        super(props);
        this.state = {
            name: "",
        };
    }
    save = async () => {
        return this.doBusyTask(async () => {
            const result = await createTenant(this.state.name, this.state.description, this.props.project, undefined);
            if (!this.props.isMultiTenancyEnabled) {
                const status = await repository.Tenants.status();
                this.props.onSpaceMultiTenancyStatusFetched(status);
            }
            this.props.tenantCreated(result);
            return true;
        });
    };
    render() {
        return (<SaveDialogLayout title={this.props.title} busy={this.state.busy} errors={this.errors} onSaveClick={this.save}>
                <Text label="New tenant name" value={this.state.name} onChange={(value) => this.setState({ name: value })} validate={required("Please enter a tenant name")} autoFocus={true}/>
                <Text label="Description" value={this.state.description || ""} onChange={(value) => this.setState({ description: value })}/>
                <NewTenantCallout project={this.props.project}/>
            </SaveDialogLayout>);
    }
    static displayName = "AddTenantDialogInternal";
}
interface NewTenantCalloutProps {
    project: Readonly<ProjectResource> | null;
}
function NewTenantCallout({ project }: NewTenantCalloutProps) {
    if (!project) {
        return null;
    }
    const tenantedDeploymentNotice = project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? " and Octopus will enable tenanted deployments for this project" : "";
    return (<Callout key="callout" title={`You are about to create a new tenant`} type={"information"}>
            The new tenant will be connected to the project {project.Name}
            {tenantedDeploymentNotice}.
        </Callout>);
}
interface CloneTenantDialogInternalProps {
    title: string;
    project: Readonly<ProjectResource> | null;
    tenantCreated(tenant: TenantResource): any;
}
interface CloneTenantDialogInternalState extends DataBaseComponentState {
    name: string;
    description?: string;
    cloneId?: string;
    tenants: DropdownMenuOption[];
}
class CloneTenantDialogInternal extends DataBaseComponent<GlobalConnectedProps & GlobalDispatchProps & CloneTenantDialogInternalProps, CloneTenantDialogInternalState> {
    constructor(props: GlobalConnectedProps & GlobalDispatchProps & CloneTenantDialogInternalProps) {
        super(props);
        this.state = {
            name: "",
            tenants: [],
        };
    }
    loadAllTenants = async (): Promise<DropdownMenuOption[]> => {
        const response = await repository.Tenants.tenantsSummaries();
        return response.TenantSummaries.map((t) => ({ value: t.Id, text: t.Name }));
    };
    loadTenantsWithConnectedProject = async (projectId: string) => {
        const filter: TenantsFilterQueryParameters = {
            filterByProject: projectId,
            filterByName: "",
            filterByExcludedName: "",
            filterByTags: [],
            filterByExcludedTags: [],
            filterByExcludedProject: undefined,
            filterByEnvironment: undefined,
            filterByExcludedEnvironment: undefined,
            includeMissingVariablesStatus: false,
        };
        const response = await repository.Tenants.tenantsOverview(1, Repository.takeAll, filter);
        return response.Tenants.map((t) => ({ value: t.Id, text: t.Name }));
    };
    loadTenants = async () => {
        if (this.props.project) {
            return await this.loadTenantsWithConnectedProject(this.props.project.Id);
        }
        return await this.loadAllTenants();
    };
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const tenants = await this.loadTenants();
            this.setState({ tenants });
        });
    }
    save = async () => {
        return this.doBusyTask(async () => {
            const result = await createTenant(this.state.name, this.state.description, this.props.project, this.state.cloneId);
            if (!this.props.isMultiTenancyEnabled) {
                const status = await repository.Tenants.status();
                this.props.onSpaceMultiTenancyStatusFetched(status);
            }
            this.props.tenantCreated(result);
            return true;
        });
    };
    render() {
        return (<SaveDialogLayout title={this.props.title} busy={this.state.busy} errors={this.errors} onSaveClick={this.save}>
                <Note>Configuration includes tenant tags, project and environment connections, and variable values.</Note>
                <Select value={this.state.cloneId} onChange={(value) => this.setState({ cloneId: value })} items={this.state.tenants} allowFilter={true} placeholder={"Select a tenant"} autoFocus={true}/>
                <Text label="New tenant name" value={this.state.name} onChange={(value) => this.setState({ name: value })} validate={required("Please enter a tenant name")}/>
                <Text label="Description" value={this.state.description || ""} onChange={(value) => this.setState({ description: value })}/>
                <ClonedTenantCallout project={this.props.project}/>
            </SaveDialogLayout>);
    }
    static displayName = "CloneTenantDialogInternal";
}
interface ClonedTenantCalloutProps {
    project: Readonly<ProjectResource> | null;
}
function ClonedTenantCallout({ project }: ClonedTenantCalloutProps) {
    if (project && project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
        return (<Callout key="callout" title={`You are about to create a new tenant`} type={"information"}>
                Octopus will enable tenanted deployments for this project.
            </Callout>);
    }
    return null;
}
interface CloneSpecificTenantDialogInternalProps {
    cloneId: string;
    cloneTenantName: string;
    tenantCreated(tenant: TenantResource): void;
}
interface CloneSpecificTenantDialogInternalState extends DataBaseComponentState {
    name: string;
    description?: string;
}
class CloneSpecificTenantDialogInternal extends DataBaseComponent<GlobalConnectedProps & GlobalDispatchProps & CloneSpecificTenantDialogInternalProps, CloneSpecificTenantDialogInternalState> {
    constructor(props: GlobalConnectedProps & GlobalDispatchProps & CloneSpecificTenantDialogInternalProps) {
        super(props);
        this.state = {
            name: "",
        };
    }
    save = async () => {
        return this.doBusyTask(async () => {
            const result = await createTenant(this.state.name, this.state.description, null, this.props.cloneId);
            if (!this.props.isMultiTenancyEnabled) {
                const status = await repository.Tenants.status();
                this.props.onSpaceMultiTenancyStatusFetched(status);
            }
            this.props.tenantCreated(result);
            return true;
        });
    };
    render() {
        return (<SaveDialogLayout title="Clone Tenant" busy={this.state.busy} errors={this.errors} onSaveClick={this.save}>
                <Text label="New tenant name" value={this.state.name} onChange={(value) => this.setState({ name: value })} validate={required("Please enter a tenant name")} autoFocus={true}/>
                <Text label="Description" value={this.state.description || ""} onChange={(value) => this.setState({ description: value })}/>
                <Callout type={"information"} title="Scoping" key="clone-warning">
                    Targets, Accounts, Certificates, Permissions and other resources that are scoped to <strong>{this.props.cloneTenantName}</strong> will not be automatically updated to include this new Tenant.
                </Callout>
            </SaveDialogLayout>);
    }
    static displayName = "CloneSpecificTenantDialogInternal";
}
const createTenant = async (name: string, description?: string, project?: Readonly<ProjectResource> | null, cloneId?: string) => {
    const args = { clone: cloneId };
    const createTenant = repository.Tenants.create({
        Name: name,
        Description: description,
        TenantTags: [],
        ProjectEnvironments: project ? { [project.Id]: [] } : {},
    }, args);
    const enableTenantedDeploymentsPromise = project && project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? enableTenantedDeployments(project) : Promise.resolve();
    const [createdTenant, _] = await Promise.all([createTenant, enableTenantedDeploymentsPromise]);
    return createdTenant;
};
const enableTenantedDeployments = async (project: Readonly<ProjectResource>) => {
    const modifyProject = async () => {
        const projectToModify = await repository.Projects.get(project.Id);
        projectToModify.TenantedDeploymentMode = TenantedDeploymentMode.TenantedOrUntenanted;
        await repository.Projects.modify(projectToModify);
    };
    const modifyRunbooks = async () => {
        const runbooks = await repository.Projects.getRunbooks(project, { take: Repository.takeAll });
        return runbooks.Items.map((runbook) => {
            runbook.MultiTenancyMode = TenantedDeploymentMode.TenantedOrUntenanted;
            return repository.Runbooks.modify(runbook);
        });
    };
    await Promise.all([modifyProject(), modifyRunbooks()]);
};
interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}
const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};
interface GlobalDispatchProps {
    onSpaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource) => void;
}
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch): GlobalDispatchProps => bindActionCreators({ onSpaceMultiTenancyStatusFetched: configurationActions.spaceMultiTenancyStatusFetched }, dispatch);
export const AddTenantDialog = connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(AddTenantDialogInternal);
const CloneTenantDialog = connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(CloneTenantDialogInternal);
export const CloneSpecificTenantDialog = connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(CloneSpecificTenantDialogInternal);
