import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import type { NotificationItem } from "~/components/Navbar/notifications.redux";
import { notificationActions, NotificationType } from "~/components/Navbar/notifications.redux";
import { getCurrentValue } from "./notificationLocalState";
const notificationId = "Notification/TargetTagsNotification";
function useTargetTagsNotification(): NotificationItem | undefined {
    const notificationWasRead = getCurrentValue(notificationId);
    if (notificationWasRead) {
        return undefined;
    }
    return {
        id: notificationId,
        text: "Target Roles are now Target Tags",
        description: "The functionality remains the same. This is only a name change to make our terminology clearer. No action needed.",
        type: NotificationType.info,
    };
}
export default function TargetTagsNotifications() {
    const dispatch = useDispatch();
    const notification = useTargetTagsNotification();
    useEffect(() => {
        if (notification !== undefined) {
            dispatch(notificationActions.raiseNotification(notification));
        }
    }, [notification, dispatch]);
    return <></>;
}
