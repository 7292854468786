import { LinearProgress } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { BusyState } from "~/components/BusyFromPromise/BusyFromPromise";
import BusyFromPromise from "~/components/BusyFromPromise/BusyFromPromise";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import type { RenderProps } from "~/components/Dialog/CustomDialog";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import type { PermissionCheckProps } from "~/components/PermissionCheck/PermissionCheck";
import type { DialogFrameProp } from "./CustomDialogFrames";
import { LargeDialogFrame } from "./CustomDialogFrames";
import CustomDialogTitleBar from "./CustomDialogTitleBar";
type CustomSaveDialogLayoutProps = RenderProps & DialogFrameProp & {
    errors?: Errors;
    busy?: BusyState;
    frame?: React.ComponentType;
    renderTitle?: (props: ActionRenderProps) => React.ReactNode;
    savePermission?: PermissionCheckProps;
    renderActions?: (props: ActionRenderProps) => React.ReactNode;
    renderContent?: (props: ActionRenderProps) => React.ReactNode;
    onSaveClick(): Promise<boolean>;
};
interface ActionRenderProps extends RenderProps {
    savePermission?: PermissionCheckProps;
    onSaveClick(): Promise<boolean>;
}
interface CustomSaveDialogTitleBar {
    title?: string;
    actions?: React.ReactNode;
    className?: string;
}
export function CustomSaveDialogTitleBar({ title = "Edit Details", actions, className }: CustomSaveDialogTitleBar) {
    return <CustomDialogTitleBar title={title} actions={actions} className={className}/>;
}
const CustomSaveDialogLayout: React.SFC<CustomSaveDialogLayoutProps> = ({ frame: Frame = LargeDialogFrame, renderContent, busy, errors, renderTitle = (actionRenderProps: ActionRenderProps) => <CustomSaveDialogTitleBar />, renderActions, children, ...renderProps }) => {
    return (<Frame>
            {renderTitle && renderTitle(renderProps)}
            <BusyFromPromise promise={busy}>{(isBusy: boolean) => <LinearProgress variant={"indeterminate"} show={isBusy}/>}</BusyFromPromise>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} scrollToPanel={false}/>}
            {renderContent && renderContent(renderProps)}
            {renderActions && renderActions(renderProps)}
        </Frame>);
};
CustomSaveDialogLayout.displayName = "CustomSaveDialogLayout"
export default CustomSaveDialogLayout;
