import * as React from "react";
export interface TabItemProps {
    label: React.ReactNode;
    value: string;
    onActive?: () => void;
    children?: React.ReactNode; // explicitly define children for typing reasons in TabContainer
    warning?: string;
}
export default class TabItem extends React.PureComponent<TabItemProps> {
    render(): React.ReactNode {
        return null;
    }
    static displayName = "TabItem";
}
