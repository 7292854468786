import { Permission, ControlType } from "@octopusdeploy/octopus-server-client";
import type { ActionTemplateParameterResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import ActionTemplateParameterList from "~/components/ActionTemplateParametersList/ActionTemplateParametersList";
import { Section } from "~/components/Section/Section";
interface VariableSetTemplatesProps {
    parameters: ActionTemplateParameterResource[];
    onParametersChanged(parameters: ActionTemplateParameterResource[]): void;
}
export default function VariableSetTemplates({ onParametersChanged, parameters }: VariableSetTemplatesProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const handleParametersChanged = (newParameters: ActionTemplateParameterResource[]) => {
        if (newParameters.length < parameters.length) {
            dispatchAction("Delete Library Variable Template", { resource: "Variable", action: Action.Delete });
        }
        else if (newParameters.length === parameters.length) {
            dispatchAction("Edit Library Variable Template", { resource: "Variable", action: Action.Update });
        }
        else {
            dispatchAction("Add Library Variable Template", { resource: "Variable", action: Action.Add });
        }
        onParametersChanged(newParameters);
    };
    return (<Section>
            <ActionTemplateParameterList parameters={parameters} name="template" excludedControlTypes={[ControlType.StepName, ControlType.Package]} editPermission={{
            permission: Permission.VariableEdit,
            wildcard: true,
        }} onParametersChanged={handleParametersChanged}/>
        </Section>);
}
