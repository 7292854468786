/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { Checkbox } from "@octopusdeploy/design-system-components";
import { fontSize, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink";
import FileUploadDropzone from "../../../../primitiveComponents/form/FileUploadDragDrop/FileUploadDragDrop";
import { SupportedPackageTypes } from "./Onboarding";
interface UploadPackageProps {
    afterPackageUpload(packageId: string): void;
}
interface UploadPackagePropsInternal extends UploadPackageProps {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
}
interface UploadPackageState extends DataBaseComponentState {
    file?: File | null;
    replace: boolean;
}
class UploadPackageInternal extends DataBaseComponent<UploadPackagePropsInternal, UploadPackageState> {
    constructor(props: UploadPackagePropsInternal) {
        super(props);
        this.state = { replace: false };
    }
    async save() {
        return this.doBusyTask(async () => {
            const ev: ActionEvent = {
                action: Action.Save,
                resource: "Package",
            };
            return await this.props.trackAction("Save Upload Package", ev, async () => {
                const pkg = await repository.Packages.upload(this.state!.file!, this.state.replace);
                this.props.afterPackageUpload(pkg.Id);
            });
        });
    }
    render() {
        const cancel = () => {
            const ev: ActionEvent = {
                action: Action.Cancel,
                resource: "Package",
            };
            this.props.dispatchAction("Cancel uploading Package", ev);
            return true;
        };
        return (<SaveDialogLayout title="Upload a Package" busy={this.state.busy} errors={this.errors} saveButtonLabel="Upload" busyButtonLabel="Uploading..." onSaveClick={() => this.save()} onCancelClick={cancel}>
                <div className={noteStyles}>
                    Package name must have a version number and no spaces e.g <code>hello-world.1.0.0.zip</code>
                    <br />
                    <ExternalLink href="SupportedPackages">Supported formats</ExternalLink>: {SupportedPackageTypes}.
                </div>
                <div className={dropzoneAreaStyles}>
                    <FileUploadDropzone label={"Drag and drop a package containing all the files to deploy or browse your files"} onFilesChanged={(file) => this.setState({ file: file[0] })} showAlerts={false}/>
                </div>
                <Checkbox label="Replace this version if it exists" value={this.state.replace} onChange={(replace) => this.setState({ replace })}/>
            </SaveDialogLayout>);
    }
    static displayName = "UploadPackageInternal";
}
export function PackageUpload(props: UploadPackageProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();
    return <UploadPackageInternal {...props} dispatchAction={dispatchAction} trackAction={trackAction}/>;
}
// Note: Consider migrating the <div /> to <Note /> once the latter's legacy styles have been updated
const noteStyles = css({
    marginTop: space[8],
    marginBottom: space[8],
    fontSize: fontSize.small,
    color: themeTokens.color.text.primary,
    a: {
        color: themeTokens.color.text.link.default,
    },
});
const dropzoneAreaStyles = css({
    margin: "10px 0",
});
