import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import DeleteDialog from "~/components/Dialog/DeleteDialog";
import { BaseComponent } from "../BaseComponent/BaseComponent";
import styles from "./style.module.less";
interface OpenDeleteDialogButtonProps {
    dialogTitle: string;
    type?: ActionButtonType;
    label?: string;
    disabled: boolean;
    dialogButtonLabel?: string;
    dialogButtonBusyLabel?: string;
    disableDeleteButton?: boolean;
    dialogOnClose?: () => void;
    onDeleteClick(): Promise<boolean>;
}
interface OpenDeleteDialogButtonState {
    open: boolean;
}
export default class OpenDeleteDialogButton extends BaseComponent<OpenDeleteDialogButtonProps, OpenDeleteDialogButtonState> {
    constructor(props: OpenDeleteDialogButtonProps) {
        super(props);
        this.state = { open: false };
    }
    render() {
        return (<div className={styles.buttonWrapper}>
                <ActionButton type={this.props.type || ActionButtonType.Delete} onClick={() => this.setState({ open: true })} label={this.props.label || "Delete"} disabled={this.props.disabled}/>
                <DeleteDialog deleteButtonDisabled={this.props.disableDeleteButton || false} title={this.props.dialogTitle} deleteButtonLabel={this.props.dialogButtonLabel} deleteButtonBusyLabel={this.props.dialogButtonBusyLabel} open={this.state.open} onClose={() => {
                this.setState({ open: false });
                if (this.props.dialogOnClose) {
                    this.props.dialogOnClose();
                }
            }} onDeleteClick={() => this.props.onDeleteClick()} renderContent={() => this.props.children}/>
            </div>);
    }
    static displayName = "OpenDeleteDialogButton";
}
