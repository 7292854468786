import type { ConnectTenantsToProjectTaskResource } from "@octopusdeploy/octopus-server-client";
import { BusinessProcessState } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import type { RefreshLoop } from "~/utils/RefreshLoop/refresh-loop";
const getFirstInProgressTask = (tasks: ConnectTenantsToProjectTaskResource[]) => tasks.find((t) => t.State === BusinessProcessState.InProgress);
const getFirstCompletedTask = (tasks: ConnectTenantsToProjectTaskResource[]) => tasks.find((t) => t.State === BusinessProcessState.Completed);
export function useConnectionTaskLoop(doBusyTask: DoBusyTask, projectId: string): [
    ConnectTenantsToProjectTaskResource | undefined | "NotLoaded",
    RefreshLoop
] {
    const [latestConnectionTask, setLatestConnectionTask] = useState<ConnectTenantsToProjectTaskResource | undefined | "NotLoaded">("NotLoaded");
    const connectingTenantsTaskRequest = useDoBusyTaskEffect(doBusyTask, async () => {
        const tasks = (await repository.Projects.getConnectingTenantsTask(projectId, 24)).Tasks;
        const latestTask = getFirstInProgressTask(tasks) ?? getFirstCompletedTask(tasks);
        setLatestConnectionTask(latestTask);
        if (!latestTask || latestTask.State === BusinessProcessState.Completed) {
            loop.stop();
        }
    }, []);
    const loop = useRefreshLoop(connectingTenantsTaskRequest, 5000);
    return [latestConnectionTask, loop];
}
