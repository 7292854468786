import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
export interface NotificationCallToActionClickedAnalyticsEvent extends AnalyticsEvent<"Notification call to action clicked"> {
    "Notification Id": string;
}
export function createNotificationCallToActionClickedAnalyticsEvent(notificationId: string): NotificationCallToActionClickedAnalyticsEvent {
    return {
        eventName: "Notification call to action clicked",
        "Notification Id": notificationId,
    };
}
