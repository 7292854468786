import type { DynamicEnvironmentOverviewState } from "@octopusdeploy/octopus-server-client";
export function getDynamicEnvironmentStateDescription(state: DynamicEnvironmentOverviewState) {
    switch (state) {
        case "Active":
            return "Active";
        case "Deprovisioned":
            return "Deprovisioned";
        case "Deprovisioning":
            return "Deprovisioning";
        case "DeprovisioningFailed":
            return "Error";
        default:
            return "Unknown";
    }
}
