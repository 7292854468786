// We import the log sinks as early as possible, so that our logging pipeline is established as early as possible
import "~/utils/logging/configureGlobalLoggerForPortal";
import "./polyfills";
import { logger } from "@octopusdeploy/logging";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "./global.module.less";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/regular.min.css";
import "@fortawesome/fontawesome-free/css/solid.min.css";
import type { AuthExtension } from "~/authExtensionsManager";
import { authExtensionManager } from "~/authExtensionsManager";
import "~/components/Actions";
import "~/components/Features";
import "~/areas/startup";
import store from "~/store";
import App from "./App";
declare global {
    interface Window {
        Octopus: {
            registerExtension: (name: string, type: string, extension: AuthExtension) => void;
        };
        showSnackbar: boolean;
        snackbarText: string;
        chmln?: {
            clear: () => void;
        };
    }
}
declare let module: __WebpackModuleApi.Module;
window.Octopus = {
    registerExtension: (name, type, extension) => {
        if (type !== "auth_provider") {
            throw new Error("Only auth provider extensions are currently supported");
        }
        authExtensionManager.registerAuthExtension(name, extension);
    },
};
function render(Component: typeof App) {
    ReactDOM.render(<Component />, document.getElementById("app"));
}
render(App);
//Do NOT move to a separate file as this wil break hot reloading
if (module.hot) {
    module.hot.accept("./store", () => {
        // When reducers change, replace them but keep the current app state
        logger.info("Store updated. Replacing root reducer");
        // If the callback to hot.accept throws
        // an exception, the HMR update will fail. If using
        // webpack-dev-server, the page will be reloaded,
        // and we lose the error. So try/catch/log.
        //
        try {
            const nextReducer = require("./store").reducers;
            store.replaceReducer(nextReducer);
        }
        catch (err) {
            logger.error(err, "Error updating store");
        }
    });
    module.hot.accept("./App", () => {
        try {
            const NextApp = require("./App").default;
            render(NextApp);
        }
        catch (err) {
            logger.error(err, "Error updating app");
        }
    });
}
