import { tags as t } from "@lezer/highlight";
import { colorScales, fontSize, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { IndentationMarkerConfiguration } from "@replit/codemirror-indentation-markers/src/config";
import type { CreateThemeOptions } from "@uiw/codemirror-themes";
import { createTheme } from "@uiw/codemirror-themes";
export const defaultSettingsGithubLight: CreateThemeOptions["settings"] = {
    background: `${themeTokens.color.codeEditor.background} !important`,
    foreground: themeTokens.color.codeEditor.foreground,
    selection: themeTokens.color.codeEditor.selection,
    selectionMatch: themeTokens.color.codeEditor.selectionMatch,
    fontSize: `${fontSize["medium"]} !important`,
};
const defaultGithubStyles = [
    { tag: [t.standard(t.tagName), t.tagName], color: themeTokens.color.codeEditor.code.syntax.tagName },
    { tag: [t.comment], color: themeTokens.color.codeEditor.code.syntax.comment },
    { tag: [t.bracket], color: themeTokens.color.codeEditor.code.syntax.bracket },
    { tag: [t.className], color: themeTokens.color.codeEditor.code.syntax.className },
    { tag: [t.propertyName], color: themeTokens.color.codeEditor.code.syntax.propertyName },
    { tag: [t.variableName], color: themeTokens.color.codeEditor.code.syntax.variableName },
    { tag: [t.attributeName], color: themeTokens.color.codeEditor.code.syntax.attributeName },
    { tag: [t.number], color: themeTokens.color.codeEditor.code.syntax.number },
    { tag: [t.operator], color: themeTokens.color.codeEditor.code.syntax.operator },
    { tag: [t.keyword], color: themeTokens.color.codeEditor.code.syntax.keyword },
    { tag: [t.typeName], color: themeTokens.color.codeEditor.code.syntax.typeName },
    { tag: [t.typeOperator], color: themeTokens.color.codeEditor.code.syntax.typeOperator },
    { tag: [t.string], color: themeTokens.color.codeEditor.code.syntax.string },
    { tag: [t.meta], color: themeTokens.color.codeEditor.code.syntax.meta },
    { tag: [t.regexp], color: themeTokens.color.codeEditor.code.syntax.regexp },
    { tag: [t.name], color: themeTokens.color.codeEditor.code.syntax.name },
    { tag: [t.quote], color: themeTokens.color.codeEditor.code.syntax.quote },
    { tag: [t.heading], color: themeTokens.color.codeEditor.code.syntax.heading, fontWeight: "bold" },
    { tag: [t.strong], color: themeTokens.color.codeEditor.code.syntax.strong, fontWeight: "bold" },
    { tag: [t.emphasis], color: themeTokens.color.codeEditor.code.syntax.emphasis, fontStyle: "italic" },
    { tag: [t.deleted], color: themeTokens.color.codeEditor.code.syntax.deleted, backgroundColor: themeTokens.color.codeEditor.code.syntax.deletedBackground },
    { tag: [t.atom], color: themeTokens.color.codeEditor.code.syntax.atom },
    { tag: [t.bool], color: themeTokens.color.codeEditor.code.syntax.bool },
    { tag: [t.special(t.variableName)], color: themeTokens.color.codeEditor.code.syntax.specialVariableName },
    { tag: [t.url], color: themeTokens.color.codeEditor.code.syntax.url },
    { tag: [t.escape], color: themeTokens.color.codeEditor.code.syntax.escape },
    { tag: [t.link], color: themeTokens.color.codeEditor.code.syntax.link, textDecoration: "underline" },
    { tag: [t.strikethrough], textDecoration: "line-through" },
    { tag: t.invalid, color: themeTokens.color.codeEditor.code.syntax.invalid },
];
export const githubLightInit = (options?: Partial<CreateThemeOptions>) => {
    const { theme = "light", settings = {}, styles = [] } = options || {};
    return createTheme({
        theme,
        settings: {
            ...defaultSettingsGithubLight,
            ...settings,
        },
        styles: [...defaultGithubStyles, ...styles],
    });
};
export const githubLight = githubLightInit();
export const defaultSettingsGithubDark: CreateThemeOptions["settings"] = {
    background: `${themeTokens.color.codeEditor.background} !important`,
    foreground: themeTokens.color.codeEditor.foreground,
    caret: colorScales.navy["200"],
    selection: themeTokens.color.codeEditor.selection,
    selectionMatch: themeTokens.color.codeEditor.selectionMatch,
    lineHighlight: colorScales.navy["800"],
    fontSize: `${fontSize["medium"]} !important`,
};
export const githubDarkInit = (options?: Partial<CreateThemeOptions>) => {
    const { theme = "dark", settings = {}, styles = [] } = options || {};
    return createTheme({
        theme,
        settings: {
            ...defaultSettingsGithubDark,
            ...settings,
        },
        styles: [...defaultGithubStyles, ...styles],
    });
};
export const githubDark = githubDarkInit();
export const yamlIndentationMarkerConfig: IndentationMarkerConfiguration = {
    colors: {
        light: themeTokens.color.codeEditor.code.indentationMarker.color,
        activeLight: themeTokens.color.codeEditor.code.indentationMarker.activeColor,
        dark: themeTokens.color.codeEditor.code.indentationMarker.color,
        activeDark: themeTokens.color.codeEditor.code.indentationMarker.activeColor,
    },
    thickness: 2,
    activeThickness: 2,
    hideFirstIndent: true,
};
