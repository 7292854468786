import type { ProjectGroupResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ChipIcon, MissingChip, ProjectGroupChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface ProjectGroupMultiSelectProps extends FormFieldProps<string[]> {
    items: ProjectGroupResource[];
    label?: string | JSX.Element;
    error?: string;
}
const ProjectGroupTypedMultiSelect = MultiSelect<ProjectGroupResource>();
export const ProjectGroupMultiSelect: React.FC<ProjectGroupMultiSelectProps> = (props) => {
    const chipRenderer = (r: ProjectGroupResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <ProjectGroupChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} projectGroup={item}/>} renderFallback={<MissingChip type={ChipIcon.ProjectGroup} lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <ProjectGroupTypedMultiSelect fieldName="project groups" renderChip={chipRenderer} {...props}/>;
};
ProjectGroupMultiSelect.displayName = "ProjectGroupMultiSelect"
