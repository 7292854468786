import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectTriggerOverview } from "../../CommonComponents/CommonProjectTriggerViews";
export const ProjectTriggerScheduledNewRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().scheduledTriggerNew,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectTriggerOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
