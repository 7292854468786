/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
class RowAndColumnCount {
    row: number = undefined!;
    column: number = undefined!;
}
export default class DashboardLimiter {
    private static totalCellsThatCanBeDrawn = 50000;
    private rowsAndColumnsPerSection: RowAndColumnCount[];
    private limitedRowsAndColumnsPerSection: RowAndColumnCount[] | null = null;
    constructor(rowsAndColumnsPerSection: RowAndColumnCount[]) {
        this.rowsAndColumnsPerSection = rowsAndColumnsPerSection;
    }
    public getLimit(index: number) {
        if (this.limitedRowsAndColumnsPerSection === null) {
            this.calculateLimits();
        }
        return this.limitedRowsAndColumnsPerSection![index];
    }
    calculateLimits() {
        const cellLimitPerSection = DashboardLimiter.totalCellsThatCanBeDrawn / this.rowsAndColumnsPerSection.length;
        this.limitedRowsAndColumnsPerSection = this.rowsAndColumnsPerSection.map((r) => this.limitRowsAndColumnsForSection(r, cellLimitPerSection));
    }
    limitRowsAndColumnsForSection(rowsAndColumnsForSection: RowAndColumnCount, cellLimitPerSection: number) {
        const aLotOfRows = 20;
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ieVersion = (document as any)["documentMode"];
        const maxColumnsWhenThereAreLotsOfRows = ieVersion // this is to prevent horrible vertical scrolling
            ? 10
            : 30;
        return rowsAndColumnsForSection.row > aLotOfRows ? this.limitColumns(rowsAndColumnsForSection, cellLimitPerSection, maxColumnsWhenThereAreLotsOfRows) : this.limitRows(rowsAndColumnsForSection, cellLimitPerSection);
    }
    limitColumns(rowsAndColumnsForSection: RowAndColumnCount, cellLimitPerSection: number, columnLimit: number) {
        const columnsToDraw = Math.min(rowsAndColumnsForSection.column, columnLimit);
        const cellsToDraw = Math.min(rowsAndColumnsForSection.row * columnsToDraw, cellLimitPerSection);
        const rowsToDraw = Math.ceil(cellsToDraw / columnsToDraw || 0);
        return { row: rowsToDraw, column: columnsToDraw };
    }
    limitRows(rowsAndColumnsForSection: RowAndColumnCount, cellLimitPerSection: number) {
        const rowsToDraw = rowsAndColumnsForSection.row;
        const cellsToDraw = Math.min(rowsAndColumnsForSection.column * rowsToDraw, cellLimitPerSection);
        const columnsToDraw = Math.ceil(cellsToDraw / rowsToDraw || 0);
        return { row: rowsToDraw, column: columnsToDraw };
    }
}
