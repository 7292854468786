import * as React from "react";
import { CommonLibraryGitCredentialsOverview, CommonLibraryGitCredentialsTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonLibraryGitCredentialsViews";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
export const LibraryGitCredentialsCreateRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.gitCredentials.create,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryGitCredentialsOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryGitCredentialsTopics />;
    },
};
