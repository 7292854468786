import { OctopusError } from "@octopusdeploy/octopus-server-client";
import type { AnalyticFields } from "@octopusdeploy/portal-analytics";
import { usePageEventDispatcher } from "@octopusdeploy/portal-routes";
import type { AnalyticErrorCallback } from "~/analytics/Analytics";
import { ActionStatus, getErrorFields } from "~/analytics/Analytics";
import { isErrors } from "~/components/DataBaseComponent/Errors";
import type { Errors } from "~/components/DataBaseComponent/index";
export type TrackedPageEventDispatcher = <T>(name: string, eventFields: AnalyticFields, action: (cb: AnalyticErrorCallback) => Promise<T>) => Promise<T>;
export function useTrackedPageEventDispatcher(): TrackedPageEventDispatcher {
    const eventDispatcher = usePageEventDispatcher();
    const getTimingFields = (duration: number, status: ActionStatus, statusCode: number | undefined, error: string[] | undefined) => ({
        ...getErrorFields(statusCode, error),
        Status: status,
        "Duration (ms)": duration.toFixed(2),
    });
    return <T>(name: string, eventFields: AnalyticFields, action: (cb: AnalyticErrorCallback) => Promise<T>): Promise<T> => {
        const start = performance.now();
        let errorList: Array<string> = [];
        let statusCode: number | undefined = undefined;
        let status: ActionStatus = ActionStatus.Success;
        const addErrors = (errors?: Array<string>, error?: string) => {
            if (errors && errors.length > 0) {
                errorList = [...errorList, ...errors];
            }
            else if (error) {
                errorList = [...errorList, error];
            }
        };
        const onError = (error: string | OctopusError | Errors) => {
            status = ActionStatus.Failed;
            if (error instanceof OctopusError) {
                statusCode = statusCode ?? error.StatusCode;
                addErrors(error.Errors, error.ErrorMessage);
            }
            else if (isErrors(error)) {
                statusCode = statusCode ?? error.statusCode;
                addErrors(error.errors, error.message);
            }
            else {
                addErrors(undefined, error);
            }
        };
        try {
            return action(onError);
        }
        catch (e) {
            if (e instanceof OctopusError) {
                onError(e);
            }
            else if (e instanceof Error) {
                onError(e.message);
            }
            throw e;
        }
        finally {
            const error = errorList && errorList.length > 0 ? errorList : undefined;
            eventDispatcher(name, {
                ...eventFields,
                ...getTimingFields(performance.now() - start, status, statusCode, error),
            });
        }
    };
}
