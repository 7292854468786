/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type RunbooksDashboardItemResource, type ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import classnames from "classnames";
import * as React from "react";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import DateFormatter from "~/utils/DateFormatter";
import { GitRefChip } from "../../Releases/GitRefChip/GitRefChip";
import { TaskStatusIcon } from "../../TaskStatusIcon/TaskStatusIcon";
import styles from "./style.module.less";
interface RunbookTaskStatusDetailsProps {
    project?: ProjectResource;
    item: RunbooksDashboardItemResource;
    style?: React.CSSProperties;
    runbookSnapshotNameClassName?: string;
}
const RunbookTaskStatusDetails: React.StatelessComponent<RunbookTaskStatusDetailsProps> = (props) => {
    const task = props.item;
    const link = links.projectRunbookRunDetailPage.generateUrl({
        spaceId: props.project ? props.project.SpaceId : repository.spaceId!,
        projectSlug: props.project ? props.project.Slug : task.ProjectId,
        runbookId: task.RunbookId,
        runbookSnapshotId: task.RunbookSnapshotId,
        runbookRunId: task.Id,
    });
    return (<InternalLink to={link} className={styles.taskLink} width="100%">
            <div className={styles.container} style={props.style}>
                <TaskStatusIcon item={task} smallIcon={false}/>
                <div className={styles.details}>
                    <span title={DateFormatter.dateToLongFormat(task.StartTime || task.QueueTime)!} className={styles.date}>
                        {DateFormatter.dateToShortFormat(task.StartTime || task.QueueTime)}
                    </span>
                    <span className={classnames(styles.snapshotName, props.runbookSnapshotNameClassName)} title={task.RunbookSnapshotName}>
                        {task.GitReference ? <GitRefChip vcsRef={task.GitReference}/> : task.RunbookSnapshotName}
                    </span>
                </div>
            </div>
        </InternalLink>);
};
RunbookTaskStatusDetails.displayName = "RunbookTaskStatusDetails"
RunbookTaskStatusDetails.displayName = "RunbookTaskStatusDetails";
export default RunbookTaskStatusDetails;
