import { css } from "@emotion/css";
import { Button, Callout } from "@octopusdeploy/design-system-components";
import { HasGitPersistenceSettings, IsUsingGitHubAuth } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, GitHubAppAuthorizationStatus, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import DataBaseComponent, { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
interface GitHubAppAuthCheckMessageProps {
    actionDescription: string;
}
function GitHubAppAuthCheckMessage({ actionDescription }: GitHubAppAuthCheckMessageProps) {
    const styles = {
        authMessageContainer: css({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        }),
        authMessageInstructions: css({
            alignSelf: "center",
        }),
    };
    return (<Callout title={"GitHub account required"} type={"warning"} canClose={false}>
            <div className={styles.authMessageContainer}>
                <div className={styles.authMessageInstructions}>Connect your GitHub account to Octopus to {actionDescription}.</div>
                <Button label="Authorize" onClick={() => {
            const route = repository.GitHubApp.getAuthorizeAppUrl(window.location.href);
            window.location.replace(route);
        }} importance={"neutral"}/>
            </div>
        </Callout>);
}
function GitHubAppAuthCheckGuestMessage() {
    return (<Callout title={"GitHub Authorization"} type={"information"} canClose={false}>
            Guest users cannot connect a GitHub account.
        </Callout>);
}
interface GitHubAppAuthCheckInternalProps {
    actionDescription: string;
    doBusyTask: DoBusyTask;
    forceRefresh?: boolean;
}
function GitHubAppAuthCheckInternal({ actionDescription, doBusyTask, forceRefresh }: GitHubAppAuthCheckInternalProps) {
    const [authorizationStatus, setAuthorizationStatus] = useState<GitHubAppAuthorizationStatus | undefined>(undefined);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const authorizationStatus = await repository.GitHubApp.getAuthorizationStatus();
        setAuthorizationStatus(authorizationStatus);
    }, [forceRefresh]);
    if (!authorizationStatus || authorizationStatus.IsAuthorized) {
        return null;
    }
    if (!authorizationStatus.CanAuthorize) {
        return <GitHubAppAuthCheckGuestMessage />;
    }
    return <GitHubAppAuthCheckMessage actionDescription={actionDescription}/>;
}
interface GitHubAppAuthCheckDatabaseComponentProps {
    actionDescription: string;
    forceRefresh?: boolean;
}
class GitHubAppAuthCheckDatabaseComponent extends DataBaseComponent<GitHubAppAuthCheckDatabaseComponentProps> {
    constructor(props: GitHubAppAuthCheckDatabaseComponentProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return (<ErrorContextProvider>
                <GitHubAppAuthCheckInternal actionDescription={this.props.actionDescription} doBusyTask={this.doBusyTask} forceRefresh={this.props.forceRefresh}/>
            </ErrorContextProvider>);
    }
    static displayName = "GitHubAppAuthCheckDatabaseComponent";
}
interface GitHubAuthCheckProps {
    actionDescription: string;
}
export function GitHubAppAuthCheck({ actionDescription }: GitHubAuthCheckProps) {
    if (!isFeatureToggleEnabled("GitHubConnectionsFeatureToggle")) {
        // Should only show if the feature is enabled. This is a cheap early bail out.
        return null;
    }
    return <GitHubAppAuthCheckDatabaseComponent actionDescription={actionDescription}/>;
}
interface ProjectGitHubAppAuthCheckProps {
    project: ProjectResource | undefined;
    permission: Permission;
}
// Shows the GitHub auth check for a project. Will only show if it is a Git project using GitHub connections and the user can edit the project.
export function ProjectGitHubAppAuthCheck({ project, permission }: ProjectGitHubAppAuthCheckProps) {
    // Should only show if the uesr has the right permission, it's a git project, and it's using GitHub auth
    const shouldShow = project && isAllowed({ permission: permission, project: project.Id, wildcard: true }) && HasGitPersistenceSettings(project.PersistenceSettings) && IsUsingGitHubAuth(project.PersistenceSettings.Credentials);
    if (!shouldShow) {
        return null;
    }
    return <GitHubAppAuthCheck actionDescription="to make changes to this project"/>;
}
