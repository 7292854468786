import React from "react";
import Card from "~/components/Card";
import { WelcomeDialogProjectIcon } from "../WelcomeDialogProjectIcon";
import type { WelcomeDialogCardProps } from "./WelcomeDialogCardProps";
import { cardStyles } from "./styles";
export function ViewProjectsCard({ busy, onClick }: WelcomeDialogCardProps) {
    return (<Card link={!busy && (<a href="#" onClick={onClick} className={cardStyles.link}>
                        View projects
                    </a>)} logo={<div className={cardStyles.logo(!busy)}>
                    <WelcomeDialogProjectIcon />
                </div>} header={<div className={cardStyles.header}>View projects</div>} content={"Check out your existing Octopus projects."} className={cardStyles.card} contentClassName={cardStyles.content} leftAlign={true}/>);
}
