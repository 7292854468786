import { css } from "@emotion/css";
import { fontWeight, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import ConfigureTargetHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-agent-configure-target-help.svg";
import ConfigureWorkerHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-agent-configure-worker-help.svg";
import InstallHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-agent-install-help.svg";
import NfsTargetHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-agent-nfs-target-help.svg";
import NfsWorkerHelpImage from "~/areas/infrastructure/components/MachineSettings/Endpoints/Images/k8s-agent-nfs-worker-help.svg";
import { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
const helpStyles = {
    content: css({ marginTop: 0, h2: { marginTop: 0, fontWeight: fontWeight["700"], color: themeTokens.color.dialog.helpPanel.text }, p: { color: themeTokens.color.dialog.helpPanel.text } }),
};
type TypedHelpContentProps = {
    machineType: EndpointSelectionScope;
};
export function TargetConfigurationHelpContent() {
    return (<span className={helpStyles.content}>
            <h2>Connect seamlessly to Kubernetes </h2>
            <p>Installing the Kubernetes agent in your cluster eliminates the need for firewall adjustments or extra authentication.</p>
            <p>The agent automatically updates itself during regular health checks to keep things running smoothly and securely.</p>
        </span>);
}
export function targetConfigurationHelpImage() {
    return { src: ConfigureTargetHelpImage, altText: "" };
}
export function WorkerConfigurationHelpContent() {
    return (<span className={helpStyles.content}>
            <h2>Autoscale workers with Kubernetes</h2>
            <p>The Octopus Kubernetes worker can efficiently execute deployments and standalone tasks for a variety of deployment targets.</p>
            <p>It consumes minimal resources when idle, but will instantly scale to accommodate new tasks as they come in.</p>
        </span>);
}
export function workerConfigurationHelpImage() {
    return { src: ConfigureWorkerHelpImage, altText: "" };
}
export function TentacleInstallationHelpContent(props: TypedHelpContentProps) {
    const machineTypeString = props.machineType === EndpointSelectionScope.Worker ? "worker" : "deployment target";
    return (<span className={helpStyles.content}>
            <h2>What happens after installation?</h2>
            <p>After running the Helm command, your new Kubernetes {machineTypeString} will automatically be registered in Octopus Deploy.</p>
            {props.machineType === EndpointSelectionScope.DeploymentTarget && (<>
                    <p>You can then deploy to it using our pre-built steps for YAML, Helm or Kustomize.</p>
                    <p>The agent automatically updates itself during regular health checks to keep things running smoothly and securely.</p>
                </>)}
            <p>
                Need help installing Helm? <ExternalLink href={"https://helm.sh/docs/intro/install/"}>Refer to the Helm docs</ExternalLink>
            </p>
        </span>);
}
export function tentacleInstallationHelpImage() {
    return { src: InstallHelpImage, altText: "" };
}
export function NfsDriverInstallationHelpContent(props: {
    machineType: EndpointSelectionScope;
}) {
    return (<span className={helpStyles.content}>
            <h2>Simplify installation with NFS</h2>
            <p>Network File System (NFS) provides a simple way to host shared storage that doesn’t require you to create volumes in your cluster.</p>
            <p>Run the command to install the NFS CSI driver, then click next for the Helm command to install the Octopus Kubernetes {props.machineType === EndpointSelectionScope.Worker ? "worker" : "agent"}. </p>
        </span>);
}
export function targetNfsDriverInstallationHelpImage() {
    return { src: NfsTargetHelpImage, altText: "" };
}
export function workerNfsDriverInstallationHelpImage() {
    return { src: NfsWorkerHelpImage, altText: "" };
}
