import { css } from "@emotion/css";
import type { DeploymentFreezeOverviewProject, SummaryView } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProjectChip } from "~/components/Chips/index";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
interface DeploymentFreezeDataTableProjectsCellProps {
    projects: SummaryView<DeploymentFreezeOverviewProject>;
}
export const DeploymentFreezeDataTableProjectsCell = ({ projects }: DeploymentFreezeDataTableProjectsCellProps) => {
    if (projects.Items.length === 0) {
        return (<DataTableRowColumn className={cellStyles}>
                <div className={noProjectsStyles}>No projects</div>
            </DataTableRowColumn>);
    }
    if (projects.Items.length === 1) {
        return (<DataTableRowColumn className={cellStyles}>
                <div className={projectCellStyles}>
                    <ProjectChip projectName={projects.Items[0].Name}/>
                </div>
            </DataTableRowColumn>);
    }
    const moreItems = projects.MoreItems ? projects.MoreItems : 0;
    return (<DataTableRowColumn className={cellStyles}>
            <div className={projectCellStyles}>
                <ProjectChip projectName={`${projects.Items.length + moreItems} projects`} noTooltip={true}/>
            </div>
        </DataTableRowColumn>);
};
const noProjectsStyles = css({
    margin: "4px",
});
const projectCellStyles = css({
    display: "flex",
    justifyContent: "flex-start",
    verticalAlign: "middle",
});
const cellStyles = css({ width: "15%" });
