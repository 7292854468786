import * as React from "react";
import { ChipIcon } from "~/components/Chips";
import { MissingChip, ProjectChip } from "~/components/Chips/index";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FormFieldProps } from "~/components/form";
import type { ComponentProps } from "~/utils/types";
import Lookup from "../Lookup";
interface ProjectMultiSelectProps extends FormFieldProps<string[]> {
    items: {
        Id: string;
        Name: string;
    }[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
const ProjectTypedMultiSelect = MultiSelect<{
    Id: string;
    Name: string;
}>();
type RenderChip = ComponentProps<typeof ProjectTypedMultiSelect>["renderChip"];
export const ProjectMultiSelect: React.FC<ProjectMultiSelectProps> = (props) => {
    const chipRenderer: RenderChip = (r, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <ProjectChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} projectName={item.Name}/>} renderFallback={<MissingChip type={ChipIcon.Project} lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <ProjectTypedMultiSelect fieldName="projects" renderChip={chipRenderer} accessibleName={props.accessibleName} {...props} items={props.items}/>;
};
ProjectMultiSelect.displayName = "ProjectMultiSelect"
