import { css } from "@emotion/css";
import { fontSize, fontWeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import type { TemplateValuesSource } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
export type TemplateValuesSourceListItemModel = {
    templateValuesSource: TemplateValuesSource;
    id: string;
    name: string;
    valueSummary: string | string[] | JSX.Element;
};
const styles = {
    root: css({
        cursor: "pointer",
    }),
    name: css({
        marginTop: 0,
        marginBottom: space["8"],
        fontSize: fontSize.medium,
        fontWeight: fontWeight["700"],
    }),
    valueSummary: css({
        marginBottom: 0,
        marginTop: space["8"],
        color: themeTokens.color.text.secondary,
        whiteSpace: "pre",
        overflowX: "hidden",
        textOverflow: "ellipsis",
    }),
};
export const TemplateValueSourceListItem = ({ item }: {
    item: TemplateValuesSourceListItemModel;
}) => {
    const valueSummary = typeof item.valueSummary === "string" || Array.isArray(item.valueSummary)
        ? Array<string>(0)
            .concat(item.valueSummary)
            .map((t) => <span key={t}>{t}</span>)
            .reduce((prev, curr) => (<>
                          {prev}
                          <br />
                          {curr}
                      </>))
        : item.valueSummary;
    return (<div className={styles.root}>
            <p className={styles.name}>{item.name}</p>
            <p className={styles.valueSummary}>{valueSummary}</p>
        </div>);
};
