import * as React from "react";
import { WorkerPoolsContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
export const ConfigurationMessages = {
    Name: {
        DeploymentTarget: {
            Validation: {
                AlreadyExists: "A deployment target with this name already exists. Please choose a different name.",
                NotProvided: "Please enter a name for this deployment target",
            },
            HelperText: "A short, memorable name for this deployment target.",
        },
        Worker: {
            Validation: {
                AlreadyExists: "A worker with this name already exists. Please choose a different name.",
                NotProvided: "Please enter a name for this worker",
            },
            HelperText: "A short, memorable name for this worker.",
        },
    },
    EnvironmentIds: {
        Validation: {
            NotProvided: "Please select an environment for this deployment target.",
        },
        HelperText: "Choose at least one environment for this deployment target.",
    },
    MachineRoles: {
        Validation: {
            NotProvided: "Please select a role for this deployment target.",
        },
        HelperText: (<>
                <ExternalLink href={"https://oc.to/TargetRoles"}>Target tags</ExternalLink> are used to select the deployment targets to execute a deployment or runbook against.
            </>),
    },
    WorkerPools: {
        Validation: {
            NotProvided: "Please select a worker pool for this worker.",
        },
        HelperText: (<>
                <strong>Worker Pools</strong> <WorkerPoolsContextualHelp /> are groups of workers that execute tasks.
            </>),
    },
    WorkerPool: {
        Validation: {
            NotProvided: "Please enter a name for this worker pool.",
        },
        HelperText: "A short, memorable name for this worker pool.",
    },
    ServerUri: {
        Validation: {
            NotProvided: "Please enter the Octopus Deploy Server URL",
            Invalid: "Please enter a valid Octopus Deploy Server URL",
        },
        HelperText: "Provide the full URL to your Octopus Deploy instance.",
    },
    ServerCommsAddresses: {
        Validation: {
            NotProvided: "Please enter a Server Communications URL",
            Invalid: "Please enter a valid Server Communications URL",
            NotUnique: "Each Server Communications URL must be unique",
        },
        HelperText: "The URL of the Octopus Server that the Agent will poll for work.",
    },
} as const;
