/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { MissingPermissionIllustration } from "@octopusdeploy/design-system-components";
import { text, space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { session } from "~/clientInstance";
import { EmptyPage } from "~/components/EmptyPage/EmptyPage";
import ExternalLink from "~/components/Navigation/ExternalLink";
export function RunbookNoPermissionsPage() {
    const title = `Hi there, ${session.currentUser!.DisplayName}. Your current permissions won’t allow you here.`;
    const description = "The content you\u2019re trying to access is restricted to Runbook Producers only. Use the navigation to return if you\u2019re here by accident. Otherwise, please contact your Octopus Deploy Administrator to review and update your permissions";
    const action = (<div className={actionStyles}>
            <div className={actionTextStyles}>
                See more information on &nbsp;
                <ExternalLink href="https://octopus.com/docs/runbooks/runbook-permissions" className={actionLinkStyles}>
                    Runbooks permissions
                </ExternalLink>
            </div>
        </div>);
    return (<main>
            <EmptyPage title={title} description={description} image={<MissingPermissionIllustration />} action={action}/>
        </main>);
}
const actionStyles = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: space["4"],
});
const actionTextStyles = css({
    font: text.interface.body.default.xSmall,
});
const actionLinkStyles = css({
    font: text.link.bold.medium,
});
