import { autocompletion, type Completion, type CompletionContext, startCompletion } from "@codemirror/autocomplete";
import { css } from "@codemirror/lang-css";
import { html } from "@codemirror/lang-html";
import { json } from "@codemirror/lang-json";
import { markdown } from "@codemirror/lang-markdown";
import { python } from "@codemirror/lang-python";
import { xml } from "@codemirror/lang-xml";
import { yaml } from "@codemirror/lang-yaml";
import { StreamLanguage } from "@codemirror/language";
import { csharp } from "@codemirror/legacy-modes/mode/clike";
import { coffeeScript } from "@codemirror/legacy-modes/mode/coffeescript";
import { dockerFile } from "@codemirror/legacy-modes/mode/dockerfile";
import { fSharp } from "@codemirror/legacy-modes/mode/mllike";
import { powerShell } from "@codemirror/legacy-modes/mode/powershell";
import { properties } from "@codemirror/legacy-modes/mode/properties";
import { shell } from "@codemirror/legacy-modes/mode/shell";
import { EditorSelection, type Extension, Prec } from "@codemirror/state";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { indentationMarkers } from "@replit/codemirror-indentation-markers";
import { keymap, StateEffect, tooltips } from "@uiw/react-codemirror";
import { EditorView } from "codemirror";
import type { CodeEditorLanguage } from "~/components/CodeEditor/CodeEditor";
import { Language, TextFormat } from "~/components/CodeEditor/CodeEditor";
import { githubDark, githubLight, yamlIndentationMarkerConfig } from "~/components/CodeEditor/CodeEditorThemes";
interface CodeEditorExtensionConfig {
    onFocusChange(focused: boolean): void;
    onEscPressed?(): void;
    isInFullScreen: boolean;
    toggleFullScreen(): void;
    language: CodeEditorLanguage;
    theme: string;
    lineWrap: boolean;
    completionSource: Completion[];
}
export const getLanguageExtensions = (language: CodeEditorLanguage): Extension => {
    switch (language) {
        // Legacy language modes from CodeMirror5
        case ScriptingLanguage.Bash:
            return StreamLanguage.define(shell);
        case ScriptingLanguage.CSharp:
            return StreamLanguage.define(csharp);
        case ScriptingLanguage.FSharp:
            return StreamLanguage.define(fSharp);
        case ScriptingLanguage.PowerShell:
            return StreamLanguage.define(powerShell);
        case Language.CoffeeScript:
            return StreamLanguage.define(coffeeScript);
        case Language.DockerFile:
            return StreamLanguage.define(dockerFile);
        case Language.INI:
            return StreamLanguage.define(properties);
        // CodeMirror6 supported languages
        case ScriptingLanguage.Python:
            return python();
        case TextFormat.JSON:
            return json();
        case TextFormat.XML:
            return xml();
        case TextFormat.YAML:
            return yaml();
        case Language.CSS:
            return css();
        case Language.HTML:
            return html();
        case Language.Markdown:
            return markdown();
        // No language highlighting
        case TextFormat.PlainText:
        default:
            return [];
    }
};
const getCodeEditorExtensions = ({ onFocusChange, onEscPressed, isInFullScreen, toggleFullScreen, language, theme, lineWrap, completionSource }: CodeEditorExtensionConfig) => {
    const nullStateEffect = StateEffect.define();
    const getLineWrapExtension = (wrapLines: boolean): Extension => (wrapLines ? EditorView.lineWrapping : []);
    const indentAtCursorCommand = (view: EditorView) => {
        view.dispatch(view.state.changeByRange((range) => ({
            changes: { from: range.from, to: range.to, insert: "  " },
            range: EditorSelection.cursor(range.from + 2),
        })));
        return true;
    };
    const isAlphabetic = (str: string) => /^[a-zA-Z.]+$/.test(str);
    const compareCompletions = (a: Completion, b: Completion) => {
        if (!isAlphabetic(a.label) && isAlphabetic(b.label))
            return 1;
        if (isAlphabetic(a.label) && !isAlphabetic(b.label))
            return -1;
        return a.label.localeCompare(b.label);
    };
    const autoCompleteCommand = (context: CompletionContext) => {
        const before = context.matchBefore(/[a-zA-Z0-9_#.]+/);
        // If completion wasn't explicitly started and there
        // is no word before the cursor, don't open completions.
        if (!context.explicit && !before)
            return null;
        return {
            from: before ? before.from : context.pos,
            options: completionSource,
        };
    };
    const escapeCommand = (target: EditorView): boolean => {
        // This one overrides the default full screen rule as the user wants to handle it explicitly
        if (onEscPressed) {
            onEscPressed();
        }
        else if (isInFullScreen) {
            toggleFullScreen();
        }
        return true;
    };
    return [
        Prec.highest(keymap.of([
            { key: "Escape", run: escapeCommand },
            { key: "Ctrl-i", run: startCompletion },
            { key: "Tab", run: indentAtCursorCommand },
        ])),
        EditorView.focusChangeEffect.of((_, focusing: boolean) => {
            onFocusChange(focusing);
            return nullStateEffect.of(null);
        }),
        getLanguageExtensions(language),
        getLineWrapExtension(lineWrap),
        autocompletion({
            activateOnTyping: false,
            override: [autoCompleteCommand],
            compareCompletions,
        }),
        tooltips({
            parent: document.body,
        }),
        theme === "dark" ? githubDark : githubLight,
        language === TextFormat.YAML ? indentationMarkers(yamlIndentationMarkerConfig) : [],
    ];
};
export default getCodeEditorExtensions;
