import { Checkbox } from "@octopusdeploy/design-system-components";
import { createInputValueAccessorForDeploymentTarget, getPathToInput } from "@octopusdeploy/step-runtime-inputs";
import type { ObjectRuntimeInputs, PathToInput } from "@octopusdeploy/step-runtime-inputs";
import type { CheckboxComponent } from "@octopusdeploy/step-ui";
import React from "react";
import { Note } from "~/components/StepPackageEditor/Inputs/Note/Note";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
export function getDeploymentTargetCheckboxContentSummary<StepInputs>(component: CheckboxComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessorForDeploymentTarget(component.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    return {
        isDefaultValue: false,
        value: inputValue ? "Yes" : "No",
    };
}
export interface DeploymentTargetCheckboxProps<StepInputs> {
    configuredStepUIProps: CheckboxComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (name: PathToInput) => string;
}
export function DeploymentTargetCheckbox<StepInputs>(props: DeploymentTargetCheckboxProps<StepInputs>) {
    const inputAccessor = createInputValueAccessorForDeploymentTarget<StepInputs, boolean>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);
    return (<>
            <Checkbox value={inputValue} onChange={(newValue: boolean) => {
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue);
            props.setInputs(updatedInputs);
        }} label={props.configuredStepUIProps.label} error={props.getFieldError(inputPath)}/>
            <Note note={props.configuredStepUIProps.note}/>
        </>);
}
