import moment from "moment";
import * as React from "react";
import DurationBetweenLabel from "./DurationBetweenLabel";
interface DurationSinceLabelProps {
    from: string;
    short?: boolean;
}
export const DurationSinceLabel: React.FC<DurationSinceLabelProps> = (props) => {
    const [currentTime, setCurrentTime] = React.useState(moment().toISOString());
    React.useEffect(() => {
        //as we can have multiple of these on the screen at once, we (attempt) to sync the setState
        //so that all times re-render in sync
        const millisecondsUntilNextRoundedSecond = 1000 - new Date().getMilliseconds();
        const timeoutId = setTimeout(() => {
            setCurrentTime(moment().toISOString());
        }, millisecondsUntilNextRoundedSecond);
        return () => clearTimeout(timeoutId);
    }, 
    //we are using a bit of a mind-bending dependency here - each time we set the time
    //we invalidate the effect, causing it to clear the existing timeout and create a new one
    [currentTime]);
    return <DurationBetweenLabel from={props.from} to={currentTime} short={props.short}/>;
};
DurationSinceLabel.displayName = "DurationSinceLabel"
