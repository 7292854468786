import { PageChanged } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { setPageForOperationTimer } from "~/utils/OperationTimer/timeOperation";
import { globalPortalLogContext } from "~/utils/logging/portalLoggingContext";
export function PageChangedCallbacks() {
    return (<PageChanged onChange={(page) => {
            globalPortalLogContext.setPage(page);
            setPageForOperationTimer(page);
        }}/>);
}
