import React from "react";
import { AreaChart as RechartsAreaChart, Area } from "recharts";
import type { ObjectPropertyPath } from "~/utils/ObjectPropertyPath/ObjectPropertyPath";
import type { ChartStrokeColor } from "../chartColors";
import { useThemedChartColors } from "../chartColors";
import ResponsiveChartContainer from "./ResponsiveChartContainer";
interface SparklineChartProps<TResource> {
    data: TResource[];
    dataKey: ObjectPropertyPath<TResource>;
    color: ChartStrokeColor;
}
export function TrendSparklineChart<TResource>({ data, dataKey, color }: SparklineChartProps<TResource>) {
    const { strokes } = useThemedChartColors();
    return (<div style={{ height: 30, maxWidth: 100, width: "100%" }}>
            <ResponsiveChartContainer>
                {({ width, height }) => (<RechartsAreaChart data={data} height={height} width={width}>
                        <Area type="linear" dataKey={dataKey} strokeWidth={2} stroke={strokes[color]} fillOpacity={0} isAnimationActive={false}/>
                    </RechartsAreaChart>)}
            </ResponsiveChartContainer>
        </div>);
}
