import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import React from "react";
import { ExpandableFormSection, Summary } from "~/components/form";
interface Props {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
}
export function GitHubStatusCheckPublishingFeature({ isEnabled, onChange }: Props) {
    return (<ExpandableFormSection key="IsGitHubStatusCheckPublishingEnabled" errorKey="IsGitHubStatusCheckPublishingEnabled" title="GitHub Status Check Publishing" summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")} help="Enable/Disable publishing deployment events as GitHub Status Checks">
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <BooleanRadioButton value={true} label="Enabled"/>
                <BooleanRadioButton value={false} label="Disabled" isDefault={true}/>
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>);
}
