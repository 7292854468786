import { Callout } from "@octopusdeploy/design-system-components";
import { ActionExecutionLocation, GetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import BaseComponent from "../../BaseComponent";
import { DisplayFeedName } from "../DisplayFeedName";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import pluginRegistry from "../pluginRegistry";
import { TentaclePackageActionEdit } from "../tentaclePackage/tentaclePackageAction";
interface NginxActionSummaryState {
    feedName: string;
}
class NginxActionSummary extends BaseComponent<ActionSummaryProps, NginxActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg /* there was a validation bug that allowed saving of steps to not pick a package */ ? (<div>
                {"Deploy a web application and configure NGINX using package"} <strong> {pkg.PackageId} </strong> {"from"} <DisplayFeedName pkg={pkg}/>
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        to deployment targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                    </span>)}
            </div>) : (<Callout type={"warning"} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>);
    }
    static displayName = "NginxActionSummary";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.Nginx",
    summary: (properties, targetRolesAsCsv, packages) => <NginxActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCsv} packages={packages}/>,
    editSections: { default: TentaclePackageActionEdit },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        permanent: ["Octopus.Features.Nginx"],
        optional: [
            "Octopus.Features.ConfigurationTransforms",
            "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.CustomDirectory",
            "Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles",
        ],
    },
});
