import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import type { CategoryDefinition } from "./endpointRegistry";
const category: CategoryDefinition = {
    category: "Cloud Region",
    title: (<React.Fragment>
            Let's setup a <strong>Cloud Region</strong> target.
        </React.Fragment>),
    help: (<Note>
            Learn more about <ExternalLink href="CloudRegionTargets">Cloud Regions</ExternalLink>
        </Note>),
    displayOrder: 45,
};
export default category;
