import type { SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { noOp } from "@octopusdeploy/utilities";
import { compact, flatten } from "lodash";
import * as React from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import OpenConfirmActionDialogMenuItem from "~/components/OverflowMenu/OpenConfirmActionDialogMenuItem";
import OpenConfirmUpgradeDialogMenuItem from "~/components/OverflowMenu/OpenConfirmUpgradeDialogMenuItem";
import OpenDeleteDialogMenuItem from "~/components/OverflowMenu/OpenDeleteDialogMenuItem";
import OpenDialogMenuItem from "~/components/OverflowMenu/OpenDialogMenuItem";
import type { MenuItem, OverflowMenuConfirmActionItem, OverflowMenuConfirmUpgradeItem, OverflowMenuDeleteItem, OverflowMenuDestructiveItem, OverflowMenuDialogItem, OverflowMenuRemoveItem } from "~/components/OverflowMenu/OverflowMenu";
import { isOverflowMenuConfirmActionItem, isOverflowMenuConfirmUpgradeItem, isOverflowMenuDeleteItem, isOverflowMenuDestructiveItem, isOverflowMenuDialogItem, isOverflowMenuDisabledItem, isOverflowMenuDownloadItem, isOverflowMenuGenericItem, isOverflowMenuNavLink, isOverflowMenuRemoveItem, isGroup, } from "~/components/OverflowMenu/OverflowMenu";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
export interface ConvertedOverflowMenuItem {
    menuItem: SimpleMenuItem;
    dialog?: React.ReactNode;
}
export interface ConvertedOverflowMenuItems {
    menuItems: SimpleMenuItem[];
    dialogs: React.ReactNode[];
}
export class OverflowMenuConverterVNext {
    static convert(item: MenuItem | null | undefined, onClose?: () => void): ConvertedOverflowMenuItem | null {
        if (!item) {
            return null;
        }
        if (isOverflowMenuDialogItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowMenuConverterVNext.alternate(item.permission.alternate);
            }
            return OverflowMenuConverterVNext.convertDialogMenuItem(item);
        }
        if (isOverflowMenuDeleteItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowMenuConverterVNext.alternate(item.permission.alternate);
            }
            return OverflowMenuConverterVNext.convertDeleteMenuItem(item);
        }
        if (isOverflowMenuRemoveItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowMenuConverterVNext.alternate(item.permission.alternate);
            }
            return OverflowMenuConverterVNext.convertRemoveMenuItem(item);
        }
        if (isOverflowMenuConfirmUpgradeItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowMenuConverterVNext.alternate(item.permission.alternate);
            }
            return OverflowMenuConverterVNext.convertConfirmUpgradeMenuItem(item);
        }
        if (isOverflowMenuConfirmActionItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowMenuConverterVNext.alternate(item.permission.alternate);
            }
            return OverflowMenuConverterVNext.convertConfirmActionMenuItem(item);
        }
        if (isOverflowMenuDownloadItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowMenuConverterVNext.alternate(item.permission.alternate);
            }
            return {
                menuItem: {
                    type: "download-link",
                    label: item.text,
                    href: item.link,
                    downloadFileName: item.filename,
                },
            };
        }
        if (isOverflowMenuNavLink(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowMenuConverterVNext.alternate(item.permission.alternate);
            }
            return {
                menuItem: {
                    type: "internal-link",
                    label: item.text,
                    path: item.path,
                },
            };
        }
        if (isOverflowMenuDisabledItem(item)) {
            return {
                menuItem: {
                    type: "disabled",
                    label: item.text,
                    disableReason: item.reason,
                },
            };
        }
        if (isOverflowMenuGenericItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return this.alternate(item.permission.alternate);
            }
        }
        if (isOverflowMenuDestructiveItem(item)) {
            if (item.permission && !isAllowed(item.permission)) {
                return OverflowMenuConverterVNext.alternate(item.permission.alternate);
            }
            return OverflowMenuConverterVNext.convertDestructiveMenuItem(item);
        }
        return {
            menuItem: {
                type: "button",
                label: item.text,
                title: item.title,
                onClick: item.onClick,
                // TODO: confirm the behaviour here
                // onClick: (e: MouseEvent) => {
                //     //Generic menu items could possibly keep focus so we explicitly close these.
                //     onClose();
                //     if (item.onClick) {
                //         //If explicitlyhandling click, we really should prevent default behaviors such as links triggering
                //         e.preventDefault();
                //         item.onClick();
                //     }
                // },
            },
        };
    }
    static convertAll(sourceMenuItems: Array<MenuItem | MenuItem[] | undefined | null>, onClose?: () => void): ConvertedOverflowMenuItems {
        if (!sourceMenuItems || sourceMenuItems.length === 0) {
            return {
                dialogs: [],
                menuItems: [],
            };
        }
        const result = sourceMenuItems &&
            sourceMenuItems
                .filter((item) => !!item)
                .map((item, index) => {
                if (isGroup(item)) {
                    const results = item.filter((subItem) => !!subItem).map((groupItem) => OverflowMenuConverterVNext.convert(groupItem, onClose));
                    if (results.length === 0) {
                        return null;
                    }
                    // This should be smart enough to know if it needs to add a divider at the start or end of a grouping/array.
                    if (index > 0 && !isGroup(sourceMenuItems[index - 1])) {
                        // Show the divider at the start of this grouping.
                        // I.e. The last thing wasn't a group, so we're good to create one here to indicate the start of a grouping.
                        results.splice(0, 0, { menuItem: { type: "divider" } });
                    }
                    else if (index < sourceMenuItems.length - 1) {
                        // Show the divider at the end of this grouping.
                        results.push({ menuItem: { type: "divider" } });
                    }
                    return results;
                }
                return [OverflowMenuConverterVNext.convert(item, onClose)];
            });
        const dialogs: React.ReactNode[] = [];
        const convertedMenuItems = compact(flatten(result)).map((r) => {
            if (r.dialog) {
                dialogs.push(r.dialog);
            }
            return r.menuItem;
        });
        return { dialogs, menuItems: convertedMenuItems };
    }
    static convertDeleteMenuItem(item: OverflowMenuDeleteItem): ConvertedOverflowMenuItem {
        let openDialog: (() => void) | undefined = undefined;
        const dialogMenuItem = (<OpenDeleteDialogMenuItem acceptOnClick={(click) => (openDialog = click)} label={item.text} disabled={false} deleteButtonDisabled={item.deleteButtonDisabled} dialogTitle={item.title} key={item.text} onDeleteClick={item.onClick} renderContent={(doBusyTask: DoBusyTask) => {
                if (typeof item.content === "function") {
                    return item.content(doBusyTask);
                }
                return item.content;
            }}/>);
        return {
            menuItem: {
                type: "button",
                label: item.text,
                onClick: () => (openDialog ? openDialog() : noOp()),
            },
            dialog: dialogMenuItem,
        };
    }
    static convertRemoveMenuItem(item: OverflowMenuRemoveItem): ConvertedOverflowMenuItem {
        let openDialog: (() => void) | undefined = undefined;
        const dialogMenuItem = (<OpenDeleteDialogMenuItem acceptOnClick={(click) => (openDialog = click)} label={item.text} disabled={false} deleteButtonDisabled={item.removeButtonDisabled} dialogTitle={item.title} deleteButtonLabel="Remove" deleteButtonBusyLabel="Removing" key={item.text} onDeleteClick={item.onClick} renderContent={() => item.content}/>);
        return {
            menuItem: {
                type: "button",
                label: item.text,
                onClick: () => (openDialog ? openDialog() : noOp()),
            },
            dialog: dialogMenuItem,
        };
    }
    static convertDestructiveMenuItem(item: OverflowMenuDestructiveItem): ConvertedOverflowMenuItem {
        let openDialog: (() => void) | undefined = undefined;
        const dialogMenuItem = (<OpenDeleteDialogMenuItem acceptOnClick={(click) => (openDialog = click)} label={item.text} disabled={false} deleteButtonDisabled={item.destructiveButtonDisable} dialogTitle={item.title} deleteButtonLabel={item.buttonLabel} deleteButtonBusyLabel={item.busyButtonLabel} key={item.text} onDeleteClick={item.onClick} renderContent={() => item.content}/>);
        return {
            menuItem: {
                type: "button",
                label: item.text,
                onClick: () => (openDialog ? openDialog() : noOp()),
            },
            dialog: dialogMenuItem,
        };
    }
    static convertConfirmUpgradeMenuItem(item: OverflowMenuConfirmUpgradeItem): ConvertedOverflowMenuItem {
        let openDialog: (() => void) | undefined = undefined;
        const dialogMenuItem = (<OpenConfirmUpgradeDialogMenuItem acceptOnClick={(click) => (openDialog = click)} label={item.text} disabled={false} dialogTitle={item.title} confirmButtonDisabled={item.confirmButtonDisabled} confirmButtonLabel="Continue" confirmButtonBusyLabel="Continuing" key={item.text} onConfirmUpgradeClick={() => item.onClick()} renderContent={() => item.content}/>);
        return {
            menuItem: {
                type: "button",
                label: item.text,
                onClick: () => (openDialog ? openDialog() : noOp()),
            },
            dialog: dialogMenuItem,
        };
    }
    static convertConfirmActionMenuItem(item: OverflowMenuConfirmActionItem): ConvertedOverflowMenuItem {
        let openDialog: (() => void) | undefined = undefined;
        const dialogMenuItem = <OpenConfirmActionDialogMenuItem acceptOnClick={(click) => (openDialog = click)} dialogTitle={item.dialogTitle} key={item.text} onConfirmActionClick={() => item.onClick()}/>;
        return {
            menuItem: {
                type: "button",
                label: item.text,
                onClick: () => (openDialog ? openDialog() : noOp()),
            },
            dialog: dialogMenuItem,
        };
    }
    static convertDialogMenuItem(item: OverflowMenuDialogItem): ConvertedOverflowMenuItem {
        let openDialog: (() => void) | undefined = undefined;
        //TODO: Find an alternative to this callback to get the click handler which doesn't result in overflow menus remaining open etc.
        const dialogMenuItem = (<OpenDialogMenuItem acceptOnClick={(click) => {
                openDialog = click;
            }} label={item.text} key={item.text}>
                {item.child}
            </OpenDialogMenuItem>);
        return {
            menuItem: {
                type: "button",
                label: item.text,
                onClick: () => (openDialog ? openDialog() : noOp()),
            },
            dialog: dialogMenuItem,
        };
    }
    static alternate(alternate: string): ConvertedOverflowMenuItem | null {
        return alternate
            ? {
                menuItem: {
                    type: "information",
                    key: alternate,
                    content: alternate,
                },
            }
            : null;
    }
}
