import type { WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import SortableList from "~/primitiveComponents/dataDisplay/SortableList/SortableList";
interface WorkerPoolSorterFormProps {
    saveDone(): void;
}
interface WorkerPoolSorterFormState extends DataBaseComponentState {
    workerPools: WorkerPoolResource[];
}
class WorkerPoolsSorter extends DataBaseComponent<WorkerPoolSorterFormProps, WorkerPoolSorterFormState> {
    constructor(props: WorkerPoolSorterFormProps) {
        super(props);
        this.state = {
            workerPools: [],
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const workerPools = await repository.WorkerPools.all();
            this.setState({ workerPools });
        });
    }
    async save() {
        const workerPoolIdsSorted = this.state.workerPools.map((workerPool) => {
            return workerPool.Id;
        });
        return this.doBusyTask(async () => {
            await repository.WorkerPools.sort(workerPoolIdsSorted);
            this.props.saveDone();
        });
    }
    render() {
        return (<SaveDialogLayout title="Reorder Worker Pools" busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()}>
                {this.state.workerPools.length > 0 && <SortableList items={this.state.workerPools} onOrderChanged={(x: WorkerPoolResource[]) => this.setState({ workerPools: x })}/>}
            </SaveDialogLayout>);
    }
    static displayName = "WorkerPoolsSorter";
}
export default WorkerPoolsSorter;
