import type { InputPathToValue } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs, PathToInput } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, getPathToInput, isBoundValue, isNotBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { CheckboxComponent, NoteExpression } from "@octopusdeploy/step-ui";
import React from "react";
import { ContextualHelp } from "~/components/StepPackageEditor/Inputs/Components/Input";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { BoundCheckbox } from "~/primitiveComponents/form/Checkbox/BoundCheckbox";
import { Note } from "../../Note/Note";
export function getStepCheckboxContentSummary<StepInputs>(component: CheckboxComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessor(component.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        return {
            isDefaultValue: false,
            value: inputValue ? "Yes" : "No",
        };
    }
    else {
        return {
            isDefaultValue: false,
            value: inputValue.expression,
        };
    }
}
interface StepCheckboxProps<StepInputs> {
    input: InputPathToValue<boolean>;
    label: string;
    note?: NoteExpression[];
    toggleableNote?: boolean;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (name: PathToInput) => string;
    localNames: string[] | undefined;
}
export function StepCheckbox<StepInputs>(props: StepCheckboxProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, boolean>(props.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.input);
    const value = isNotBoundValue(inputValue) ? inputValue : inputValue.expression;
    const resetValue = isNotBoundValue(inputValue) ? inputValue : inputValue.expression === "True";
    let label: string | JSX.Element = `${props.label}`;
    if (props.toggleableNote) {
        label = (<>
                <span>{props.label}</span> <ContextualHelp note={props.note}/>
            </>);
    }
    return (<>
            <BoundCheckbox variableLookup={{
            localNames: props.localNames,
        }} value={value} resetValue={resetValue} isBound={isBoundValue(inputValue)} onIsBoundChanged={(bound) => {
            if (bound && isNotBoundValue(inputValue)) {
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, {
                    type: "bound",
                    expression: inputValue ? "True" : "False",
                });
                props.setInputs(updatedInputs);
            }
        }} onChange={(newValue: string | boolean) => {
            if (typeof newValue === "string") {
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, {
                    type: "bound",
                    expression: newValue,
                });
                props.setInputs(updatedInputs);
            }
            else {
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue);
                props.setInputs(updatedInputs);
            }
        }} label={label} error={props.getFieldError(inputPath)}/>
            {!props.toggleableNote && <Note note={props.note}/>}
        </>);
}
