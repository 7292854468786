import cn from "classnames";
import moment from "moment";
import React from "react";
import type { TaskWithPosition } from "~/areas/tasks/components/Task/TaskQueueOrderedList";
import styles from "~/areas/tasks/components/Task/taskqueue.module.less";
import Chip from "~/components/Chips/Chip";
import TaskDetails from "~/components/TaskDetails/index";
import { useOctopusTheme } from "~/components/Theme/index";
interface TaskQueueRowProps {
    task: TaskWithPosition;
}
export default ({ task }: TaskQueueRowProps) => {
    const positionText = moment.localeData().ordinal(task.Position);
    const theme = useOctopusTheme();
    return (<div className={cn(styles.taskQueueRow)}>
            <span className={styles.taskPosition}>{positionText}</span>
            <div className={styles.taskDetails}>
                <TaskDetails task={task} stripTopBottomPadding={true}/>
            </div>
            {task.Current && (<div className={styles.taskCurrent}>
                    <Chip backgroundColor={theme.chipHighlight} labelColor={theme.darkText} noTooltip={true}>
                        <em className="fa-solid fa-arrow-left"></em> Your Task
                    </Chip>
                </div>)}
        </div>);
};
