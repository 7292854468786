import { pageId } from "@octopusdeploy/portal-routes";
export default () => ({
    runbookSnapshots: pageId({ area: "Project", id: "Project.RunbookSnapshots", name: "RunbookSnapshots" }),
    runbookSnapshotCreate: pageId({ area: "Project", id: "Project.RunbookSnapshotNew", name: "Add New RunbookSnapshot" }),
    runbookSnapshot: () => {
        return {
            root: pageId({ area: "Project", id: "Project.RunbookSnapshot", name: "RunbookSnapshot" }),
            edit: pageId({ area: "Project", id: "Project.RunbookSnapshotEdit", name: "Edit RunbookSnapshot" }),
            runbookRuns: {
                specific: pageId({ area: "Project", id: "Project.RunbookRun", name: "RunbookRun" }),
                retry: pageId({ area: "Project", id: "Project.RunbookRunRetry", name: "Retry RunbookRun" }),
                create: pageId({ area: "Project", id: "Project.RunbookRunNew", name: "Add New RunbookRun" }),
            },
        };
    },
});
