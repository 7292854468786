import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import moment from "moment";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import useLocalStorage from "~/hooks/useLocalStorage";
// When you want to test a new version of the deployment process code editor
// (e.g. a batch of improvements have been made) you can change this date to force
// the default to go back to the code editor again. If a user still decides to
// go back to the form editor after this then the local storage will get updated
// and they will be back to the form editor by default.
export const DateCurrentVersionOfProcessCodeEditorWasEnabledAsTheDefault = "2024-01-30";
// When a user switches from the code editor to the form editor, we want to ask
// for feedback as to why they did this so we can iterate and improve the code editing experience.
// But we don't want to ask them every time they switch, this would get annoying real quick.
// So we'll only ask them if we haven't for the below number of days.
export const NumberOfDaysBeforeAskingForFeedbackAgainWhenSwitchingToFormEditor = 7;
export function isProcessCodeEditorEnabled(project?: ProjectResource, processType?: ProcessType) {
    return isFeatureToggleEnabled("DeploymentProcessCodeEditorFeatureToggle") && project?.IsVersionControlled && processType === ProcessType.Deployment;
}
type ProcessCodeEditorState = {
    isEnabledByDefault: boolean;
    dateCalloutWasDismissed: string | undefined;
};
export function useProcessCodeEditorState() {
    const [state, setState] = useLocalStorage<ProcessCodeEditorState | undefined>("Octopus.DeploymentProcessCodeEditor.State", undefined);
    const isProcessCodeEditorFeatureToggleEnabled = isFeatureToggleEnabled("DeploymentProcessCodeEditorFeatureToggle");
    const isProcessCodeEditorEnabledByDefault = isProcessCodeEditorFeatureToggleEnabled && state?.isEnabledByDefault === true; // Only keep the code editor enabled by default if the user has explicitly chosen it
    const hasCalloutBeenDismissed = state !== undefined && state.dateCalloutWasDismissed !== undefined;
    const showCallout = isProcessCodeEditorFeatureToggleEnabled && !hasCalloutBeenDismissed;
    return {
        isProcessCodeEditorEnabledByDefault,
        showCallout,
        enableProcessCodeEditorByDefault: () => setState({ isEnabledByDefault: true, dateCalloutWasDismissed: state?.dateCalloutWasDismissed }),
        disableProcessCodeEditorByDefault: () => setState({
            isEnabledByDefault: false,
            dateCalloutWasDismissed: state?.dateCalloutWasDismissed,
        }),
        calloutDismissed: () => setState({
            isEnabledByDefault: state?.isEnabledByDefault ?? true,
            dateCalloutWasDismissed: moment().toISOString(),
        }),
    };
}
