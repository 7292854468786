import type { GetProjectsAffectedByChangeBffResponse, ProjectResource, TenantVariableResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import AffectedProjectsNotificationDialog from "~/areas/projects/components/Variables/TenantVariables/AffectedProjectsNotificationDialog";
import CommonTemplatesInlineContent from "~/areas/projects/components/Variables/TenantVariables/CommonTemplatesInlineContent";
import ProjectTenantVariablesInlineLayout, { type TenantVariablesPageSaveAction } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesInlineLayout";
import type { CommonTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesState";
import { useCommonTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesState";
import type { ChangedCommonTemplatesValue, CommonTemplatesValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesValueChangeTracker";
import { useCommonTemplatesValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesValueChangeTracker";
import { useTenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import { useTenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import { DataBaseComponent, type DoBusyTask, type Errors } from "~/components/DataBaseComponent/index";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
export default function ProjectTenantCommonTemplates() {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    return <CommonTemplatesPage project={project}/>;
}
interface CommonTemplatesPageProps {
    project: ProjectResource;
}
class CommonTemplatesPage extends DataBaseComponent<CommonTemplatesPageProps> {
    constructor(props: CommonTemplatesPageProps) {
        super(props);
        this.state = {};
    }
    render() {
        const { project } = this.props;
        return <CommonTemplatesPageInternal busy={this.state.busy} errors={this.errors} project={project} doBusyTask={this.doBusyTask}/>;
    }
    static displayName = "CommonTemplatesPage";
}
interface CommonTemplatesPageInternalProps {
    project: ProjectResource;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
}
function CommonTemplatesPageInternal({ project, busy, errors, doBusyTask }: CommonTemplatesPageInternalProps) {
    const contentState = useCommonTemplatesState(project, doBusyTask);
    const filterData = useTenantVariableFiltersData(project.Id, doBusyTask);
    const variableResourceOptions = useTenantVariableResourceOptions(doBusyTask);
    const valueChangeTracker = useCommonTemplatesValueChangeTracker();
    const { isOpen, openDialog } = useDialogTrigger();
    const [affectedProjects, setAffectedProjects] = useState<GetProjectsAffectedByChangeBffResponse | null>(null);
    const saveAction: TenantVariablesPageSaveAction = {
        onClick: async () => {
            await doBusyTask(async () => {
                const response = await getAffectedProjects(project, valueChangeTracker);
                setAffectedProjects(response);
                if (response && response.TotalCount > 0) {
                    openDialog();
                }
                else {
                    await save(valueChangeTracker, contentState);
                }
            });
        },
        disabled: !valueChangeTracker.hasAny(),
    };
    const saveByConfirmation = async () => await doBusyTask(async () => {
        await save(valueChangeTracker, contentState);
        setAffectedProjects(null);
    });
    const onConfirmationCancelled = () => setAffectedProjects(null);
    return (<ProjectTenantVariablesInlineLayout busy={busy} errors={errors} project={project} doBusyTask={doBusyTask} saveAction={saveAction} warnings={contentState.warnings} page={"common"}>
            {project ? <CommonTemplatesInlineContent project={project} doBusyTask={doBusyTask} contentState={contentState} filterData={filterData} variableResourceOptions={variableResourceOptions} valueChangeTracker={valueChangeTracker}/> : null}
            <Dialog open={isOpen}>
                <AffectedProjectsNotificationDialog affectedProjects={affectedProjects} onSave={saveByConfirmation} onCancel={onConfirmationCancelled}></AffectedProjectsNotificationDialog>
            </Dialog>
        </ProjectTenantVariablesInlineLayout>);
}
async function getAffectedProjects(project: ProjectResource, valueTracker: CommonTemplatesValueChangeTracker) {
    const changedSets = valueTracker.values().map((v) => ({ SetId: v.setId, TenantId: v.tenantId }));
    return await repository.Projects.getProjectsAffectedByCommonTemplateChange(project.Id, changedSets);
}
async function save(valueTracker: CommonTemplatesValueChangeTracker, contentState: CommonTemplatesState) {
    await saveChangedValues(valueTracker);
    await contentState.actions.onSave();
    valueTracker.reset();
}
async function saveChangedValues(valueTracker: CommonTemplatesValueChangeTracker) {
    const changedTenants = valueTracker.changedTenants();
    const tenantVariablesPromises = Array.from(changedTenants).map((id) => repository.Tenants.getVariablesByTenantId(id));
    const tenantVariables = await Promise.all(tenantVariablesPromises);
    const tenantVariablesLookup: {
        [tenantId: string]: TenantVariableResource;
    } = {};
    for (const tenantVariable of tenantVariables) {
        tenantVariablesLookup[tenantVariable.TenantId] = tenantVariable;
    }
    const modifiedTenantVariables = modifyTenantVariables(tenantVariablesLookup, valueTracker.values());
    const savingPromises = modifiedTenantVariables.map((variables) => repository.Tenants.setVariablesByTenantId(variables.TenantId, variables));
    await Promise.all(savingPromises);
}
function modifyTenantVariables(tenantVariables: {
    [tenantId: string]: TenantVariableResource;
}, changes: ChangedCommonTemplatesValue[]): TenantVariableResource[] {
    const modified = new Map<string, TenantVariableResource>();
    for (const { setId, templateId, tenantId, value } of changes) {
        const variables = tenantVariables[tenantId];
        variables.LibraryVariables[setId].Variables[templateId] = value ?? "";
        modified.set(tenantId, variables);
    }
    return Array.from(modified.values());
}
