import * as React from "react";
import { useReducer, memo } from "react";
import type { ActionType } from "typesafe-actions";
import { createAction, getType } from "typesafe-actions";
import type { RenderProps } from "../../Dialog/CustomDialog";
import type { DialogFrameProp } from "./CustomDialogFrames";
import { MediumDialogFrame } from "./CustomDialogFrames";
interface CustomWizardDialogLayoutProps extends RenderProps, DialogFrameProp {
    children: React.ReactNode;
    renderPage: (props: RenderCustomWizardDialogPageProps) => React.ReactNode;
}
interface CustomWizardDialogState {
    currentPageIndex: number;
}
interface RenderCustomWizardDialogPageProps {
    currentPageIndex: number;
    pageCount: number;
    moveNext: () => void;
    movePrevious: () => void;
    selectPage: (pageIndex: number) => void;
    close: () => void;
    content: React.ReactNode;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}
const wizardActions = {
    moveNextPage: createAction("WIZARD_PAGE_NEXT")(),
    movePreviousPage: createAction("WIZARD_PAGE_PREVIOUS")(),
    selectPage: createAction("WIZARD_PAGE_SELECT", (pageIndex: number) => ({ pageIndex }))(),
};
type WizardAction = ActionType<typeof wizardActions>;
const INITIAL_STATE: CustomWizardDialogState = {
    currentPageIndex: 0,
};
const createReducer = (pageCount: number) => {
    const wizardReducer = (state: CustomWizardDialogState, action: WizardAction) => {
        switch (action.type) {
            case getType(wizardActions.moveNextPage):
                if (state.currentPageIndex + 1 >= pageCount) {
                    return state;
                }
                return { ...state, currentPageIndex: state.currentPageIndex + 1 };
            case getType(wizardActions.movePreviousPage):
                if (state.currentPageIndex === 0) {
                    return state;
                }
                return { ...state, currentPageIndex: state.currentPageIndex - 1 };
            case getType(wizardActions.selectPage):
                if (action.payload.pageIndex <= pageCount && action.payload.pageIndex >= 0) {
                    return { ...state, currentPageIndex: action.payload.pageIndex };
                }
        }
        return state;
    };
    return wizardReducer;
};
function useWizard(children: React.ReactNode) {
    const pageCount = React.Children.count(children);
    const memoizedReducer = React.useMemo(() => createReducer(pageCount), [pageCount]);
    const [state, dispatch] = useReducer(memoizedReducer, INITIAL_STATE);
    const child = React.Children.toArray(children)[state.currentPageIndex];
    return {
        moveNext: () => dispatch(wizardActions.moveNextPage()),
        movePrevious: () => dispatch(wizardActions.movePreviousPage()),
        selectPage: (pageIndex: number) => dispatch(wizardActions.selectPage(pageIndex)),
        hasNextPage: state.currentPageIndex + 1 < pageCount,
        hasPreviousPage: state.currentPageIndex > 0,
        pageCount,
        content: child,
        ...state,
    };
}
export const CustomWizardDialogLayout: React.FC<CustomWizardDialogLayoutProps> = memo(({ frame: Frame = MediumDialogFrame, renderPage, open, close, children }) => {
    const { moveNext, movePrevious, pageCount, content, currentPageIndex, hasPreviousPage, hasNextPage, selectPage } = useWizard(children);
    return (<Frame>
            {renderPage({
            close,
            moveNext,
            movePrevious,
            pageCount,
            currentPageIndex,
            content,
            hasPreviousPage,
            hasNextPage,
            selectPage,
        })}
        </Frame>);
});
CustomWizardDialogLayout.displayName = "CustomWizardDialogLayout"
export default CustomWizardDialogLayout;
