import { css } from "@emotion/css";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { SimpleDataTable } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import { HasGitPersistenceSettings, Repository } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { useState } from "react";
import * as React from "react";
import DeleteRepository from "~/areas/blueprints/DeleteRepository";
import { repository } from "~/clientInstance";
import { DataBaseComponent, type DoBusyTask, type Errors, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { type MenuItem, OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
export interface BlueprintRepositoryItem {
    projectId: string;
    name: string;
    spaceName: string;
    spaceId: string;
    storageDirectory: string;
    defaultBranch: string;
}
interface BlueprintsRepositoriesPageInternalProps extends BlueprintsRepositoriesPageProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
}
const styles = {
    rowCellPadding: css({ paddingLeft: space[4] }),
};
export function BlueprintsRepositoriesPageInternal({ doBusyTask, busy, errors }: BlueprintsRepositoriesPageInternalProps) {
    const [repositories, setRepositories] = useState<BlueprintRepositoryItem[]>([]);
    const reload = useDoBusyTaskEffect(doBusyTask, async () => {
        const spaces = await repository.Spaces.all();
        const blueprintRepositoryItems: BlueprintRepositoryItem[] = [];
        for (const space of spaces) {
            const scopedRepository = await repository.forSpace(space.Id);
            try {
                const response = await scopedRepository.Projects.list({ take: Repository.takeAll, partialName: "Blueprints -" });
                for (const repositoryProject of response.Items) {
                    if (HasGitPersistenceSettings(repositoryProject.PersistenceSettings)) {
                        blueprintRepositoryItems.push({
                            projectId: repositoryProject.Id,
                            name: repositoryProject.Name.replace("Blueprints - ", ""),
                            spaceId: space.Id,
                            spaceName: space.Name,
                            storageDirectory: repositoryProject.PersistenceSettings.BasePath,
                            defaultBranch: repositoryProject.PersistenceSettings.DefaultBranch,
                        });
                    }
                }
            }
            catch (error) {
                logger.warn(error, "Unable to fetch projects for space {space}", { space: space.Name });
            }
        }
        setRepositories(blueprintRepositoryItems);
    }, []);
    const connectRepositoryAction: PrimaryPageAction = {
        type: "navigate",
        label: "Connect Repository",
        path: links.connectBlueprintsRepositoryPage.generateUrl({ spaceId: repository.spaceId || "" }),
    };
    return (<PaperLayoutVNext title="Repositories" primaryAction={connectRepositoryAction} busy={busy} errors={errors}>
            <SimpleDataTable columns={[
            {
                accessibleName: "Repository",
                columnSize: "medium",
                title: "Repository",
                render: (blueprintRepository) => (<div className={styles.rowCellPadding}>
                                <InternalLink to={links.editBlueprintsRepositoryPage.generateUrl({ projectId: blueprintRepository.projectId, spaceId: blueprintRepository.spaceId })}>{blueprintRepository.name}</InternalLink>
                            </div>),
            },
            {
                accessibleName: "Space",
                columnSize: "medium",
                title: "Space",
                render: (blueprintRepository) => <div className={styles.rowCellPadding}>{blueprintRepository.spaceName}</div>,
            },
            {
                accessibleName: "Storage directory",
                columnSize: "large",
                title: "Storage directory",
                render: (blueprintRepository) => <div className={styles.rowCellPadding}>{blueprintRepository.storageDirectory}</div>,
            },
            {
                accessibleName: "Default branch",
                columnSize: "large",
                title: "Default branch",
                render: (blueprintRepository) => <div className={styles.rowCellPadding}>{blueprintRepository.defaultBranch}</div>,
            },
            {
                columnSize: "small",
                title: "",
                render: (blueprintRepository) => <RepositoryOverflowMenu blueprintRepository={blueprintRepository} reload={reload}/>,
            },
        ]} data={repositories} getRowKey={(brm) => brm.projectId} accessibleName={"Repositories"}/>
        </PaperLayoutVNext>);
}
interface RepositoryOverflowMenuProps {
    blueprintRepository: BlueprintRepositoryItem;
    reload: () => Promise<boolean>;
}
function RepositoryOverflowMenu({ blueprintRepository, reload }: RepositoryOverflowMenuProps) {
    const overFlowActions: Array<MenuItem | MenuItem[]> = [];
    overFlowActions.push(OverflowMenuItems.deleteItem("Delete", "Are you sure you want to delete this repository?", async () => deleteRepository(blueprintRepository, reload), <DeleteRepository blueprintRepository={blueprintRepository}/>));
    return <OverflowMenu menuItems={overFlowActions}/>;
}
async function deleteRepository(blueprintRepository: BlueprintRepositoryItem, reload: () => Promise<boolean>) {
    const scopedRepository = await repository.forSpace(blueprintRepository.spaceId);
    const project = await scopedRepository.Projects.get(blueprintRepository.projectId);
    await scopedRepository.Projects.del(project);
    return await reload();
}
interface BlueprintsRepositoriesPageProps {
    spaceId: string;
}
export class BlueprintsRepositoriesPage extends DataBaseComponent<BlueprintsRepositoriesPageProps> {
    constructor(props: BlueprintsRepositoriesPageProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <BlueprintsRepositoriesPageInternal spaceId={this.props.spaceId} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}></BlueprintsRepositoriesPageInternal>;
    }
    static displayName = "BlueprintsRepositoriesPage";
}
