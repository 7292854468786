import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
export const ConfigurationFeaturesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.features,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
};
