import type { SpaceUsage } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import styles from "./style.module.less";
interface UsageDetailTableProps {
    spacesUsage: SpaceUsage[];
    isPtm: boolean;
}
const UsageDetailTable: React.FC<UsageDetailTableProps> = ({ spacesUsage = [], isPtm }) => (<div className="usage-detail">
        <h2>Usage by Space</h2>
        <DataTable className={styles.usageTable} title="Usage by Space">
            <DataTableHeader>
                <DataTableRow>
                    <DataTableHeaderColumn>Space</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Project</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Tenants</DataTableHeaderColumn>
                    <DataTableHeaderColumn>{isPtm ? "Machines" : "Targets"}</DataTableHeaderColumn>
                </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
                {spacesUsage.length > 0 ? (spacesUsage.map((usage) => (<DataTableRow key={usage.SpaceName}>
                            <DataTableRowColumn>{usage.SpaceName}</DataTableRowColumn>
                            <DataTableRowColumn>{usage.ProjectsCount}</DataTableRowColumn>
                            <DataTableRowColumn>{usage.TenantsCount}</DataTableRowColumn>
                            <DataTableRowColumn>{usage.MachinesCount}</DataTableRowColumn>
                        </DataTableRow>))) : (<DataTableRow>
                        <DataTableRowColumn colSpan={4}>No usage data found.</DataTableRowColumn>
                    </DataTableRow>)}
            </DataTableBody>
        </DataTable>
    </div>);
UsageDetailTable.displayName = "UsageDetailTable"
export { UsageDetailTable };
