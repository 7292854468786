import type { NamedResource, DocumentTypeResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { DocumentTypeChip, MissingChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface DocumentTypeMultiSelectProps extends FormFieldProps<string[]> {
    items: DocumentTypeResource[];
    label?: string | JSX.Element;
    error?: string;
}
const DocumentTypeTypedMultiSelect = MultiSelect<DocumentTypeResource>();
export const DocumentTypeMultiSelect: React.FC<DocumentTypeMultiSelectProps> = (props) => {
    const chipRenderer = (r: DocumentTypeResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element: NamedResource) => element.Id} render={(item) => <DocumentTypeChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} documentType={item}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.DocumentType}/>}/>);
    };
    return <DocumentTypeTypedMultiSelect fieldName="document types" renderChip={chipRenderer} {...props}/>;
};
DocumentTypeMultiSelect.displayName = "DocumentTypeMultiSelect"
