import * as React from "react";
import { Text } from "~/components/form/index";
import Note from "~/primitiveComponents/form/Note/Note";
interface KubernetesNamespacePickerProps {
    namespace: string;
    onChange: (namespace: string) => void;
    getFieldError: (key: string) => string;
}
const KubernetesNamespacePicker = (props: KubernetesNamespacePickerProps) => (<>
        <Text value={props.namespace} onChange={props.onChange} placeholder="default" error={props.getFieldError("Namespace")} label="Default Kubernetes namespace"/>
        <Note>Provide an optional default namespace that can be overridden by your step configuration and YAML.</Note>
    </>);
export default KubernetesNamespacePicker;
