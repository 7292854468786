import { flatten } from "lodash";
import * as React from "react";
interface TargetsProps {
    targets: React.ReactNode[];
}
export abstract class Targets extends React.Component<TargetsProps, never> {
    render() {
        const targets = this.props.targets;
        return (<span>
                {flatten(targets.map((target, index) => {
                const nodes = [target];
                if (index === targets.length - 1) {
                    return nodes;
                }
                nodes.push(<span key={targets.length + index}>{index === targets.length - 2 ? " and " : ", "}</span>);
                return nodes;
            }))}
            </span>);
    }
    static displayName = "Targets";
}
export default Targets;
