import { css } from "@emotion/css";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import URI from "urijs";
import CopyToClipboardButton from "~/components/CopyToClipboardButton/index";
import OpenDialogIconButton from "~/components/Dialog/OpenDialogIconButton";
import InfoDialogLayout from "~/components/DialogLayout/InfoDialogLayout";
import { Text } from "~/components/form/index";
export default function ShareFilterButton() {
    return (<OpenDialogIconButton icon={"Share"} toolTipContent={"Share filter"}>
            <ShareDialogLayout />
        </OpenDialogIconButton>);
}
function ShareDialogLayout() {
    const currentUri = URI(window.location).valueOf();
    return (<InfoDialogLayout title={"Share filter"}>
            All users who have view permission to this page can view this filter.
            <div className={style}>
                <Text value={currentUri} onChange={noOp} readOnly={true}/>
                <CopyToClipboardButton value={currentUri}/>
            </div>
        </InfoDialogLayout>);
}
const style = css({
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
});
