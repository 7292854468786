import { Callout, Checkbox } from "@octopusdeploy/design-system-components";
import { PriorityMode } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import Summary from "../../../../../components/form/Sections/Summary";
interface PriorityOptionsProps {
    priority: PriorityMode;
    onChange(value: boolean): void;
}
const PriorityOptions = (props: PriorityOptionsProps) => {
    return (<ExpandableFormSection title="Priority" errorKey="priority" help="Choose whether to deploy with priority" summary={buildSummary(props.priority)}>
            <Callout title="Early Access" type={"warning"}>
                This feature is still in development and may be removed pending feedback. We'd love to hear <ExternalLink href={"priority-deployments-feedback"}> your feedback</ExternalLink> after using the Priority Deployments feature.
            </Callout>
            <div>
                <Checkbox label="Priority" value={props.priority === PriorityMode.On} onChange={props.onChange}/>
            </div>
        </ExpandableFormSection>);
    function buildSummary(priorityMode: PriorityMode) {
        const summary = priorityMode === PriorityMode.On ? Summary.summary("Deploy with priority") : Summary.default("No priority");
        return summary;
    }
};
export default PriorityOptions;
