/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
import { borderRadius, space, themeTokens, fontWeight, fontSize } from "@octopusdeploy/design-system-tokens";
import { ActionHandlerCategory } from "@octopusdeploy/octopus-server-client";
import type { ActionTemplateCategoryResource, ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import ActionTemplateCategoryIconHelper from "~/components/ActionTemplates/ActionTemplateCategoryIconHelper";
interface CategorySelectorProps {
    categories: ActionTemplateCategoryResource[];
    selectedCategory?: ActionHandlerCategory | undefined;
    steps: ActionTemplateSearchResource[];
    onCategorySelected?: (selectedCategory: ActionHandlerCategory | undefined) => void;
}
const CategorySelector = (props: CategorySelectorProps) => {
    const categorySelected = (newCategory: ActionHandlerCategory | undefined): void => {
        props.onCategorySelected?.(newCategory);
    };
    return (<div className={cx(styles.container)}>
            <div className={cx(styles.title)}>Filter Categories</div>

            <div className={cx(styles.categoryGroups)}>
                <CategoryGroupInternal categories={props.categories} categoriesToMatch={[ActionHandlerCategory.Featured]} selectedCategory={props.selectedCategory} steps={props.steps} onCategorySelected={categorySelected}/>
                <CategoryGroupInternal name="Development and Scripting" categories={props.categories} categoriesToMatch={[ActionHandlerCategory.Script, ActionHandlerCategory.Package]} selectedCategory={props.selectedCategory} steps={props.steps} onCategorySelected={categorySelected}/>
                <CategoryGroupInternal name="Containers and Orchestration" categories={props.categories} categoriesToMatch={[ActionHandlerCategory.Kubernetes, ActionHandlerCategory.Docker]} selectedCategory={props.selectedCategory} steps={props.steps} onCategorySelected={categorySelected}/>
                <CategoryGroupInternal name="Infrastructure as Code" categories={props.categories} categoriesToMatch={[ActionHandlerCategory.Terraform]} selectedCategory={props.selectedCategory} steps={props.steps} onCategorySelected={categorySelected}/>
                <CategoryGroupInternal name="Cloud Native Services" categories={props.categories} categoriesToMatch={[ActionHandlerCategory.Aws, ActionHandlerCategory.Azure, ActionHandlerCategory.GoogleCloud]} selectedCategory={props.selectedCategory} steps={props.steps} onCategorySelected={categorySelected}/>
                <CategoryGroupInternal name="Server Environments" categories={props.categories} categoriesToMatch={[ActionHandlerCategory.JavaAppServer, ActionHandlerCategory.WindowsServer]} selectedCategory={props.selectedCategory} steps={props.steps} onCategorySelected={categorySelected}/>
                <CategoryGroupInternal name="Other" categories={props.categories} categoriesToMatch={[ActionHandlerCategory.Certificate, ActionHandlerCategory.Community, ActionHandlerCategory.StepTemplate, ActionHandlerCategory.Other]} selectedCategory={props.selectedCategory} steps={props.steps} onCategorySelected={categorySelected}/>
            </div>
        </div>);
};
interface CategoryGroupInternalProps {
    name?: string;
    categories: ActionTemplateCategoryResource[];
    categoriesToMatch: ActionHandlerCategory[];
    selectedCategory: ActionHandlerCategory | undefined;
    steps: ActionTemplateSearchResource[];
    onCategorySelected: (selectedCategory: ActionHandlerCategory | undefined) => void;
}
const CategoryGroupInternal = (props: CategoryGroupInternalProps) => {
    const getCategories = (): ActionTemplateCategoryResource[] => {
        const result = props.categories.filter((category) => isCategoryMatch(category) && !isCategoryEmpty(category)).sort(sortByPredefinedOrder);
        return result;
    };
    const sortByPredefinedOrder = (l: ActionTemplateCategoryResource, r: ActionTemplateCategoryResource): number => {
        const predefinedOrder = props.categoriesToMatch.map((x) => x.toString());
        return predefinedOrder.indexOf(l.Id) - predefinedOrder.indexOf(r.Id);
    };
    const isCategoryMatch = (category: ActionTemplateCategoryResource) => {
        return props.categoriesToMatch.some((y) => y === category.Id);
    };
    const isCategoryEmpty = (category: ActionTemplateCategoryResource) => {
        return !props.steps.some((step) => step.Categories.includes(category.Id));
    };
    const categoriesToDisplay = getCategories();
    if (!categoriesToDisplay || !categoriesToDisplay.length) {
        return <></>;
    }
    return (<>
            {props.name ? <div className={cx(styles.categoryGroup)}>{props.name}</div> : <></>}
            {categoriesToDisplay.map((x) => (<CategoryInternal key={x.Id} category={x} selectedCategory={props.selectedCategory} onCategorySelected={props.onCategorySelected}></CategoryInternal>))}
        </>);
};
interface CategoryInternalProps {
    category: ActionTemplateCategoryResource;
    selectedCategory: ActionHandlerCategory | undefined;
    onCategorySelected: (selectedCategory: ActionHandlerCategory | undefined) => void;
}
function isActionHandlerCategory(Id: string): Id is ActionHandlerCategory {
    const actionHandlerCategoryEntries = Object.entries(ActionHandlerCategory);
    return actionHandlerCategoryEntries.some(([key, value]) => value === Id);
}
const CategoryInternal = (props: CategoryInternalProps) => {
    if (!isActionHandlerCategory(props.category.Id)) {
        return <></>;
    }
    const iconSvgPath = ActionTemplateCategoryIconHelper.getCategorySvgElement(props.category.Id, false);
    return (<div className={cx(styles.category, props.selectedCategory === props.category.Id ? styles.activeCategory : "")} onClick={() => {
            props.onCategorySelected(isActionHandlerCategory(props.category.Id) ? props.category.Id : undefined);
        }}>
            {iconSvgPath}
            <div>{props.category.Name}</div>
        </div>);
};
export default CategorySelector;
const desktopBreakpoint = "1279px";
const styles = {
    container: css({
        padding: space[16],
        minWidth: "240px",
    }),
    title: css({
        color: themeTokens.color.text.primary,
        fontSize: fontSize["base"],
        fontWeight: fontWeight["700"],
        lineHeight: "24px",
        padding: `0 ${space[8]} ${space[8]} ${space[8]}`,
    }),
    categoryGroups: css({
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: space[8],
        [`@media (min-width: ${desktopBreakpoint})`]: {
            flexDirection: "column",
            gap: 0,
        },
    }),
    categoryGroup: css({
        display: "none",
        color: themeTokens.color.text.secondary,
        fontSize: fontSize["medium"],
        fontWeight: fontWeight["700"],
        lineHeight: "24px",
        padding: `${space[16]} ${space[8]} ${space[4]} ${space[8]}`,
        [`@media (min-width: ${desktopBreakpoint})`]: {
            display: "block",
        },
    }),
    category: css({
        backgroundColor: themeTokens.color.background.secondary.default,
        borderRadius: borderRadius.small,
        color: themeTokens.color.text.primary,
        fontSize: fontSize["medium"],
        fontWeight: fontWeight["400"],
        padding: `${space[6]} ${space[8]}`,
        display: "flex",
        alignItems: "center",
        [`@media (min-width: ${desktopBreakpoint})`]: {
            backgroundColor: "transparent",
        },
        svg: {
            marginRight: space[8],
            fill: themeTokens.color.icon.tertiary,
            height: "20px",
            width: "20px",
        },
        "&:hover": {
            backgroundColor: themeTokens.color.navigation.button.background.hover,
            color: themeTokens.color.navigation.link.text.hover,
            cursor: "pointer",
            svg: {
                fill: themeTokens.color.navigation.link.icon.hover,
            },
        },
    }),
    activeCategory: css({
        backgroundColor: themeTokens.color.button.background.tertiary.default,
        color: themeTokens.color.text.selected,
        borderRadius: borderRadius.small,
        svg: {
            fill: themeTokens.color.icon.selected,
        },
        [`@media (min-width: ${desktopBreakpoint})`]: {
            backgroundColor: themeTokens.color.button.background.tertiary.default,
        },
    }),
};
