import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import HelpImageWorkerPool from "../../Images/WorkerPool.svg";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
const video = {
    youtubeId: "v6621BId7fE",
    shortlink: "WorkerPoolsVideo",
};
export const CommonWorkerPoolOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    <ExternalLink href="WorkerPools">Worker pools</ExternalLink> allow you to organize workers into groups. One pool might be in a particular network security zone. Another pool might have a specific set of tools installed.
                </p>
                <ImageWithPlaceholder src={HelpImageWorkerPool} alt={"Worker Pool"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
