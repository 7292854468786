import { cx } from "@emotion/css";
import { Callout, List, Snackbar } from "@octopusdeploy/design-system-components";
import type { ActionTemplateParameterResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { ControlType, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { ProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
import { TemplateHelpText } from "~/areas/projects/components/Variables/TenantVariables/TemplateHelpText";
import { styles } from "~/areas/projects/components/Variables/TenantVariables/styles";
import MissingVariablesIcon from "~/areas/tenants/MissingVariablesIcon/MissingVariablesIcon";
import { repository } from "~/clientInstance";
import ActionTemplateParameterEditorDialog from "~/components/ActionTemplateParametersList/ActionTemplateParameterEditorDialog";
import { ActionTemplateParameterSorter } from "~/components/ActionTemplateParametersList/ActionTemplateParameterSorter";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
interface ProjectTemplatesSidebarContentProps {
    project: ProjectResource;
    pageSize: number;
    doBusyTask: DoBusyTask;
    filterState: ProjectTenantVariablesFilterState;
    setFilterState: (filter: ProjectTenantVariablesFilterState) => void;
    missingValueTemplateIds: Set<string>;
}
export default function ProjectTemplatesSidebarContent({ project, pageSize, doBusyTask, filterState, setFilterState, missingValueTemplateIds }: ProjectTemplatesSidebarContentProps) {
    const [activeTemplate, setActiveTemplate] = useState<ActionTemplateParameterResource | undefined>(undefined);
    const [templateFilterValue, setTemplateFilterValue] = useState("");
    const { isOpen: isViewEditTemplateDialogOpen, openDialog: openViewEditTemplateDialog } = useDialogTrigger();
    const { isOpen: isDeleteTemplateDialogOpen, openDialog: openDeleteTemplateDialog } = useDialogTrigger();
    const onRowClick = (id: string | undefined) => {
        setFilterState({
            ...filterState,
            filterByTemplateId: id,
            pageNumber: 1,
        });
    };
    const onDeleteTemplate = () => {
        // Only reset the filter if we're scoped to the deleted template
        if (activeTemplate?.Id === filterState.filterByTemplateId) {
            setFilterState({
                ...filterState,
                filterByTemplateId: undefined,
            });
        }
    };
    const handleOpenViewEditTemplateDialog = (template: ActionTemplateParameterResource) => {
        setActiveTemplate(template);
        openViewEditTemplateDialog();
    };
    const handleOpenDeleteTemplateDialog = (template: ActionTemplateParameterResource) => {
        setActiveTemplate(template);
        openDeleteTemplateDialog();
    };
    const handleReorderTemplates = async (newTemplates: ActionTemplateParameterResource[]) => {
        await doBusyTask(async () => {
            await repository.Projects.modify({ ...project, Templates: newTemplates });
        });
    };
    const sidebarMaxHeight = pageSize === 30 ? styles.sidebar30Height : pageSize === 50 ? styles.sidebar50Height : styles.sidebar100Height;
    return (<div className={cx(styles.sidebarContainer, sidebarMaxHeight)}>
            <div className={styles.sidebarFilter}>
                <FilterSearchBox placeholder={"Filter by template..."} fullWidth={true} value={templateFilterValue} onChange={setTemplateFilterValue}/>
                <OverflowMenu menuItems={[
            OverflowMenuItems.dialogItem("Reorder templates", <ActionTemplateParameterSorter title={"Reorder templates"} parameters={project.Templates} onOk={handleReorderTemplates} okButtonLabel={"Save"}/>, {
                permission: Permission.ProjectEdit,
                project: project.Id,
                wildcard: true,
            }),
        ]}/>
            </div>
            {templateFilterValue === "" ? (<div className={cx(styles.sidebarRow, filterState.filterByTemplateId ? undefined : styles.sidebarSelectedName)} onClick={() => onRowClick(undefined)}>
                    All project templates
                </div>) : null}

            <List items={project.Templates.filter((t) => t.Name.toLowerCase().includes(templateFilterValue.toLowerCase()))} renderRow={({ item: template }) => (<ProjectTemplatesSidebarRow project={project} template={template} onRowClick={onRowClick} isMissing={missingValueTemplateIds.has(template.Id)} isSelected={template.Id === filterState.filterByTemplateId} handleOpenViewEditTemplateDialog={handleOpenViewEditTemplateDialog} handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}/>)}/>
            <UpdateTemplateDialog open={isViewEditTemplateDialogOpen} template={activeTemplate} project={project} doBusyTask={doBusyTask}/>
            <DeleteTemplateDialog open={isDeleteTemplateDialogOpen} template={activeTemplate} project={project} doBusyTask={doBusyTask} onDelete={onDeleteTemplate}/>
        </div>);
}
interface ProjectTemplatesSidebarRowProps {
    project: ProjectResource;
    template: ActionTemplateParameterResource;
    onRowClick: (id: string | undefined) => void;
    isMissing: boolean;
    isSelected: boolean;
    handleOpenViewEditTemplateDialog: (template: ActionTemplateParameterResource) => void;
    handleOpenDeleteTemplateDialog: (template: ActionTemplateParameterResource) => void;
}
function ProjectTemplatesSidebarRow({ project, template, onRowClick, isMissing, isSelected, handleOpenViewEditTemplateDialog, handleOpenDeleteTemplateDialog }: ProjectTemplatesSidebarRowProps) {
    const menuItems = [
        OverflowMenuItems.item(isAllowed({
            permission: Permission.ProjectEdit,
            project: project.Id,
            wildcard: true,
        })
            ? "Edit template"
            : "View template", () => handleOpenViewEditTemplateDialog(template)),
        OverflowMenuItems.item("Delete template", () => handleOpenDeleteTemplateDialog(template), {
            permission: Permission.ProjectEdit,
            project: project.Id,
            wildcard: true,
        }),
    ];
    return (<div className={styles.sidebarRow} onClick={() => onRowClick(template.Id)}>
            <div>
                <div className={cx(styles.sidebarNameContainer, isSelected ? styles.sidebarSelectedName : undefined)}>
                    <div className={styles.sidebarName} title={template.Name}>
                        {template.Name}
                    </div>
                    <TemplateHelpText text={template.HelpText}/>
                    <MissingVariablesIcon show={isMissing}/>
                </div>
                <div className={styles.sidebarLabel}>{template.Label}</div>
            </div>
            <div>
                <OverflowMenu menuItems={menuItems}/>
            </div>
        </div>);
}
interface OverflowMenuDialogProps {
    open: boolean;
    template: ActionTemplateParameterResource | undefined;
    project: ProjectResource;
    doBusyTask: DoBusyTask;
}
function UpdateTemplateDialog({ open, template, project, doBusyTask }: OverflowMenuDialogProps) {
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const dispatchAction = useAnalyticActionDispatch();
    const handleUpdateTemplate = async (updated: ActionTemplateParameterResource) => {
        const resourceIndex = project.Templates.findIndex((t) => t.Id === updated.Id);
        const templatesBeforeUpdated = project.Templates.slice(0, resourceIndex);
        const templatesAfterUpdated = project.Templates.slice(resourceIndex + 1);
        await doBusyTask(async () => {
            await repository.Projects.modify({ ...project, Templates: [...templatesBeforeUpdated, updated, ...templatesAfterUpdated] });
            dispatchAction("Edit Project Variable Template", { resource: "Variable", action: Action.Update });
            setIsSnackbarOpen(true);
        });
    };
    return (<>
            <Dialog open={open}>
                <ActionTemplateParameterEditorDialog parameter={template} excludedControlTypes={[ControlType.StepName, ControlType.Package]} onOk={handleUpdateTemplate} name={"template"} editPermission={{
            permission: Permission.ProjectEdit,
            project: project.Id,
            wildcard: true,
        }}/>
            </Dialog>
            <Snackbar open={isSnackbarOpen} content={"Project templates updated"} autoHideDuration={3500} onClose={() => setIsSnackbarOpen(false)} textAlign={"center"}/>
        </>);
}
interface DeleteTemplateDialogProps extends OverflowMenuDialogProps {
    onDelete: () => void;
}
function DeleteTemplateDialog({ open, template, project, doBusyTask, onDelete }: DeleteTemplateDialogProps) {
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const dispatchAction = useAnalyticActionDispatch();
    const handleDeleteTemplate = async () => {
        const templatesWithoutRemovedOne = project.Templates.filter((t) => t.Id !== template?.Id);
        await doBusyTask(async () => {
            await repository.Projects.modify({ ...project, Templates: templatesWithoutRemovedOne });
            dispatchAction("Delete Project Variable Template", { resource: "Variable", action: Action.Delete });
            onDelete();
            setIsSnackbarOpen(true);
        });
        return true;
    };
    return (<>
            <Dialog open={open}>
                <DeleteDialogLayout title={"Are you sure you want to delete this template?"} onDeleteClick={() => handleDeleteTemplate()} renderContent={() => (<div>
                            <Callout title="This is a destructive action" type={"danger"}>
                                This action <strong>cannot</strong> be undone. This will permanently delete the <strong>{template?.Name}</strong> template from the project.
                            </Callout>
                            <p>Do you wish to continue?</p>
                        </div>)}/>
            </Dialog>
            <Snackbar open={isSnackbarOpen} content={"Project templates updated"} autoHideDuration={3500} onClose={() => setIsSnackbarOpen(false)} textAlign={"center"}/>
        </>);
}
