import type { AccountResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import isBound from "../BoundField/isBound";
import { OverflowSummary, PlaceholderSummary } from "../Sections/Summary";
type AccountSelectionSummaryProps = {
    accountId: string | undefined | null;
    accounts: AccountResource[];
    renderBound?: (accountId: string) => React.ReactNode;
    renderFound: (account: AccountResource) => React.ReactNode;
    renderMissing: (accountId: string) => React.ReactNode;
    renderFalsy?: () => React.ReactNode;
};
const defaultBoundRender: AccountSelectionSummaryProps["renderBound"] = (accountId) => (<OverflowSummary>
        Account is bound to <strong>{accountId}</strong>
    </OverflowSummary>);
export const AccountSelectionSummary: React.FC<AccountSelectionSummaryProps> = ({ accountId, accounts, renderBound = defaultBoundRender, renderFound, renderMissing, renderFalsy = () => <PlaceholderSummary>No account has been selected</PlaceholderSummary>, }) => {
    if (accountId === undefined || accountId === null) {
        return <React.Fragment>{renderFalsy()}</React.Fragment>;
    }
    if (isBound(accountId)) {
        return <React.Fragment>{renderBound(accountId)}</React.Fragment>;
    }
    const found = accounts.find((x) => x.Id === accountId);
    if (!found) {
        return <React.Fragment>{renderMissing(accountId)}</React.Fragment>;
    }
    return <React.Fragment>{renderFound(found)}</React.Fragment>;
};
AccountSelectionSummary.displayName = "AccountSelectionSummary"
