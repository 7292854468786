import { combineReducers } from "redux";
import type { AccountsState } from "./accounts";
import { default as accountsReducer } from "./accounts";
import type { MachinesState } from "./machines";
import { default as machinesReducer } from "./machines";
export * from "./selectors";
export interface InfrastructureAreaState {
    machines: MachinesState;
    accounts: AccountsState;
}
export default combineReducers<InfrastructureAreaState>({
    machines: machinesReducer,
    accounts: accountsReducer,
});
