/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Tooltip } from "@octopusdeploy/design-system-components";
import type { OctopusPackageVersionBuildInformationMappedResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import CommitDetails from "~/components/Commits/CommitDetails";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import SimpleExpander from "~/components/SimpleExpander/SimpleExpander";
import WorkItems from "~/components/WorkItems/WorkItems";
import { DataTable, DataTableBody, DataTableRow, DataTableRowHeaderColumn, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import DateFormatter from "~/utils/DateFormatter";
import styles from "./styles.module.less";
interface BuildInformationProps {
    data: OctopusPackageVersionBuildInformationMappedResource;
    heading?: string;
}
export default class BuildInformationTable extends DataBaseComponent<BuildInformationProps, DataBaseComponentState> {
    constructor(props: BuildInformationProps) {
        super(props);
        this.state = {};
    }
    buildTable() {
        return (<DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Build Environment</DataTableRowHeaderColumn>
                        <DataTableRowColumn>{this.props.data.BuildEnvironment}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Build</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            <ExternalLink href={this.props.data.BuildUrl}>{this.props.data.BuildNumber}</ExternalLink>
                        </DataTableRowColumn>
                    </DataTableRow>
                    {this.props.data && this.props.data.Branch && this.props.data.Branch.length > 0 && (<DataTableRow>
                            <DataTableRowHeaderColumn>Branch</DataTableRowHeaderColumn>
                            <DataTableRowColumn>{this.props.data.Branch}</DataTableRowColumn>
                        </DataTableRow>)}
                    <DataTableRow>
                        <DataTableRowHeaderColumn>Created</DataTableRowHeaderColumn>
                        <DataTableRowColumn>
                            <Tooltip content={DateFormatter.dateToLongFormat(this.props.data.Created)!}>{DateFormatter.dateToShortFormat(this.props.data.Created)}</Tooltip>
                        </DataTableRowColumn>
                    </DataTableRow>
                    {this.props.data && this.props.data.Commits && this.props.data.Commits.length > 0 && (<DataTableRow>
                            <DataTableRowHeaderColumn>Commits</DataTableRowHeaderColumn>
                            <DataTableRowColumn>
                                <CommitDetails data={this.props.data.Commits}/>
                            </DataTableRowColumn>
                        </DataTableRow>)}
                    {this.props.data && this.props.data.WorkItems && this.props.data.WorkItems.length > 0 && (<DataTableRow>
                            <DataTableRowHeaderColumn>Work Items</DataTableRowHeaderColumn>
                            <DataTableRowColumn>
                                <WorkItems data={this.props.data.WorkItems}/>
                            </DataTableRowColumn>
                        </DataTableRow>)}
                </DataTableBody>
            </DataTable>);
    }
    render() {
        return this.props.heading ? (<SimpleExpander title={<div className={styles.metadataPackageTitle}>{this.props.heading}</div>} key={this.props.data.PackageId} errorKey={this.props.data.PackageId}>
                {this.buildTable()}
            </SimpleExpander>) : (this.buildTable());
    }
    static displayName = "BuildInformationTable";
}
