import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationSubscriptionOverview } from "../../CommonComponents/CommonConfigurationSubscriptionViews";
export const ConfigurationSubscriptionRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.subscription,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationSubscriptionOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
