/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css, cx } from "@emotion/css";
import { Callout, CircularProgress, DatePicker } from "@octopusdeploy/design-system-components";
import { fontWeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { EnvironmentResource, OctopusServerNodeResource, ProjectResource, ResourceCollection, RunbookResource, SpaceResource, TaskListBffArgs, TaskResource, TaskTypeResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { isTaskListTab, Permission, Repository, TaskListTab, TaskState } from "@octopusdeploy/octopus-server-client";
import type { TaskSummaryResource, TaskSummaryResourceCollection } from "@octopusdeploy/octopus-server-client/dist/src/resources/taskSummaryResource";
import type { TaskDateFilterType, TaskFilterState } from "@octopusdeploy/portal-routes";
import { links, TaskDateFilterTypeValues, TaskFilterStateValues } from "@octopusdeploy/portal-routes";
import { orderBy } from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import * as React from "react";
import type { AnalyticActionDispatcher, AnalyticViewTaskStateTabDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch, useAnalyticViewTaskStateTabDispatch } from "~/analytics/Analytics";
import { formatTimeDifference } from "~/areas/projects/components/Releases/Deployments/NowOrLater/formatTimeDifference";
import CancelTaskDialog from "~/areas/tasks/components/Tasks/CancelTaskDialog";
import TaskQueueUpdatedSnackbar from "~/areas/tasks/components/Tasks/TaskQueueUpdatedSnackbar";
import { client, repository, session } from "~/clientInstance";
import AdvancedFilterLayout, { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import type { DataBaseComponentState, DoBusyTask, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import { Feature, FeatureToggle } from "~/components/FeatureToggle";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { SpaceMultiSelect } from "~/components/MultiSelect/SpaceMultiSelect";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PagingDataTable } from "~/components/PagingDataTable/PagingDataTable";
import PermissionCheck, { hasPermission, isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { AssignTaskCell } from "~/components/TaskDetails/AssignTaskCell";
import TaskDetails from "~/components/TaskDetails/TaskDetails";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import type { Item } from "~/primitiveComponents/form/Select/Select";
import Select from "~/primitiveComponents/form/Select/Select";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs/index";
import DateFormatter from "~/utils/DateFormatter";
import { timeOperation, timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
export interface Filter {
    ids?: string[];
    state?: TaskFilterState;
    project?: string;
    runbook?: string;
    environment?: string;
    name?: string;
    node?: string;
    tenant?: string;
    spaces: string[];
    includeSystem: boolean;
    hasPendingInterruptions?: boolean | null;
    hasWarningsOrErrors?: boolean;
    dateFilterType?: TaskDateFilterType;
    fromDate?: Date;
    toDate?: Date;
    activeTab?: TaskListTab;
}
export interface TasksQuery extends IQuery {
    serverNode?: string;
    state?: TaskFilterState;
    ids?: string[];
    project?: string;
    runbook?: string;
    environment?: string;
    name?: string;
    tenant?: string;
    spaces?: string[];
    hasPendingInterruptions?: string;
    includeSystem?: string;
    hasWarningsOrErrors?: string;
    dateFilterType?: TaskDateFilterType;
    fromDate?: string;
    toDate?: string;
    activeTab?: TaskListTab;
}
interface SnackbarState {
    show: boolean;
    text: string;
}
interface TasksState extends DataBaseComponentState {
    tasks?: TaskSummaryResourceCollection;
    projects: {
        Id: string;
        Name: string;
    }[];
    runbooks: {
        Id: string;
        Name: string;
        ProjectId: string;
    }[];
    environments: EnvironmentResource[];
    nodes: OctopusServerNodeResource[];
    tenants: TenantResource[];
    spaces: SpaceResource[];
    taskTypes: TaskTypeResource[];
    currentPageIndex: number; // We manage our own paging due to automatic refresh / timers.
    filter: Filter;
    hasLoadedOnce?: boolean;
    snackbar: SnackbarState;
    running: number | null;
    waiting: number | null;
    needsApproval: number | null;
    totalCountsInOtherSpaces: {
        [state: string]: number;
    };
}
class TaskResourceDataTable extends PagingDataTable<TaskSummaryResource> {
}
class FilterLayout extends AdvancedFilterLayout<Filter> {
}
function getTaskStatesFromFilterState(taskFilterState?: TaskFilterState) {
    switch (taskFilterState) {
        case TaskFilterStateValues.Incomplete:
            return [TaskState.Queued, TaskState.Executing, TaskState.Cancelling].join(",");
        case TaskFilterStateValues.Completed:
            return [TaskState.Canceled, TaskState.Success, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterStateValues.Unsuccessful:
            return [TaskState.Canceled, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterStateValues.Queued:
            return TaskState.Queued;
        case TaskFilterStateValues.Executing:
            return TaskState.Executing;
        case TaskFilterStateValues.Cancelling:
            return TaskState.Cancelling;
        case TaskFilterStateValues.Success:
            return TaskState.Success;
        case TaskFilterStateValues.Canceled:
            return TaskState.Canceled;
        case TaskFilterStateValues.TimedOut:
            return TaskState.TimedOut;
        case TaskFilterStateValues.Failed:
            return TaskState.Failed;
        case TaskFilterStateValues.Running:
            return [TaskState.Executing, TaskState.Cancelling].join(",");
        default:
            return undefined;
    }
}
function getTaskFilter(query: TasksQuery): Filter {
    return {
        node: query.serverNode,
        state: query.state,
        ids: arrayValueFromQueryString(query.ids),
        hasPendingInterruptions: query.hasPendingInterruptions === "true",
        hasWarningsOrErrors: query.hasWarningsOrErrors === "true",
        environment: query.environment,
        project: query.project,
        runbook: query.runbook,
        tenant: query.tenant,
        spaces: arrayValueFromQueryString(query.spaces),
        name: query.name,
        includeSystem: query.includeSystem === "true",
        dateFilterType: query.dateFilterType,
        fromDate: query.fromDate ? moment(query.fromDate).toDate() : moment().subtract(1, "month").startOf("day").toDate(),
        toDate: query.toDate ? moment(query.toDate).toDate() : moment().endOf("day").toDate(),
        activeTab: query.activeTab,
    };
}
export function getTaskQuery(filter: Filter): TasksQuery {
    return {
        serverNode: filter.node,
        state: filter.state,
        ids: filter.ids,
        environment: filter.environment,
        hasPendingInterruptions: filter.hasPendingInterruptions ? "true" : undefined,
        hasWarningsOrErrors: filter.hasWarningsOrErrors ? "true" : undefined,
        name: filter.name,
        project: filter.project,
        runbook: filter.runbook,
        tenant: filter.tenant,
        dateFilterType: filter.dateFilterType,
        fromDate: filter.dateFilterType && filter.fromDate ? moment(filter.fromDate).format() : undefined,
        toDate: filter.dateFilterType && filter.toDate ? moment(filter.toDate).format() : undefined,
        ...(filter.spaces.length !== 0 ? { spaces: filter.spaces } : {}),
        ...(filter.includeSystem ? { includeSystem: "true" } : {}),
        activeTab: filter.activeTab,
    };
}
const TasksQueryStringFilters = QueryStringFilters.For<Filter, TasksQuery>();
export interface TasksLayoutRenderProps {
    busy: Promise<void>;
    doBusyTask: DoBusyTask;
    hasLoadedOnce: boolean;
    errors: Errors;
    children: React.ReactNode;
}
export interface TaskCellRenderProps {
    task: TaskSummaryResource;
}
export interface TasksProps {
    restrictToProjectId?: string;
    restrictToRunbookId?: string;
    restrictToTenantId?: string;
    restrictToTaskTypes?: string[];
    hideAdvancedFilters?: boolean;
    renderLayout: (props: TasksLayoutRenderProps) => React.ReactElement<any>;
    renderCell?: (props: TaskCellRenderProps) => React.ReactElement<any>;
    onNewItems?(items: any[]): Promise<any[]>;
}
interface TasksInnerProps extends TasksProps {
    dispatchAction: AnalyticActionDispatcher;
    dispatchTabSwitchingAction: AnalyticViewTaskStateTabDispatcher;
}
class TasksInner extends DataBaseComponent<TasksInnerProps, TasksState> {
    private isRestrictedView = false;
    private timeLastRefereshTookInMilliseconds = 0;
    constructor(props: TasksInnerProps) {
        super(props);
        this.isRestrictedView = this.isRestrictedDocumentView();
        this.state = {
            currentPageIndex: 0,
            filter: createDefaultTaskFilter(this.props.restrictToTaskTypes),
            environments: [],
            nodes: [],
            projects: [],
            runbooks: [],
            tenants: [],
            taskTypes: [],
            spaces: [],
            snackbar: { show: false, text: "" },
            running: null,
            waiting: null,
            needsApproval: null,
            totalCountsInOtherSpaces: {},
        };
    }
    componentDidMount() {
        return this.doBusyTask(async () => {
            let restrictedToProject: ProjectResource | null = null;
            if (this.props.restrictToProjectId && hasPermission(Permission.ProjectView)) {
                restrictedToProject = await repository.Projects.get(this.props.restrictToProjectId);
            }
            let getProjects = Promise.resolve([] as {
                Id: string;
                Name: string;
            }[]);
            if (hasPermission(Permission.ProjectView)) {
                if (this.props.restrictToProjectId) {
                    getProjects = Promise.resolve([restrictedToProject!]);
                }
                else {
                    getProjects = repository.Projects.summaries();
                }
            }
            let getRunbooks = Promise.resolve([] as RunbookResource[]);
            if (hasPermission(Permission.RunbookView)) {
                if (this.props.restrictToRunbookId) {
                    getRunbooks = Promise.resolve([await repository.Runbooks.get(this.props.restrictToRunbookId)]);
                }
                else {
                    if (!!restrictedToProject) {
                        const projectRunbooks = await repository.Projects.getRunbooks(restrictedToProject, { take: Repository.takeAll });
                        getRunbooks = Promise.resolve(projectRunbooks.Items);
                    }
                    else {
                        getRunbooks = repository.Runbooks.all();
                    }
                }
            }
            const getEnvironments = hasPermission(Permission.EnvironmentView) ? repository.Environments.all() : Promise.resolve([] as EnvironmentResource[]);
            const getNodes = repository.OctopusServerNodes.all();
            const getTenants = !this.props.restrictToTenantId && isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            const getTaskTypes = repository.Tasks.taskTypes();
            const spaces = await repository.Users.getSpaces(session.currentUser!);
            const runbooks = await getRunbooks;
            const sortedRunbooks = orderBy(runbooks, [(x) => x.ProjectId], "asc");
            this.setState({
                projects: await getProjects,
                runbooks: sortedRunbooks,
                environments: await getEnvironments,
                nodes: await getNodes,
                tenants: await getTenants,
                spaces: spaces,
                taskTypes: await getTaskTypes,
            });
            this.doRefresh = await this.startRefreshLoop(() => this.searchInternal(this.state.filter, this.state.currentPageIndex), this.getRefreshInterval, false, timeOperationOptions.forRefresh());
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    getRefreshInterval = (hidden: boolean) => {
        if (this.timeLastRefereshTookInMilliseconds > 750) {
            // Delay is 5 seconds if it took 0.75s and then linearly up to 60 seconds if it took 90s or more
            const delay = Math.min(this.timeLastRefereshTookInMilliseconds * (20 / 3), 60 * 1000);
            // If hidden, 4 times as long, but no more than 2 minutes
            return hidden ? Math.min(delay * 4, 120 * 1000) : delay;
        }
        return hidden ? 20000 : 5000;
    };
    isRestrictedDocumentView(): boolean {
        return !!this.props.restrictToProjectId || !!this.props.restrictToRunbookId || !!this.props.restrictToTenantId || !!this.props.restrictToTaskTypes;
    }
    search(filter: Filter) {
        this.setState({ filter, hasLoadedOnce: false, currentPageIndex: 0 }, async () => this.doRefresh());
    }
    switchTab(filter: Filter) {
        this.props.dispatchTabSwitchingAction("View Task State Tab", { resource: "Task", selectedTab: filter.activeTab, action: Action.View });
        this.setState({ filter, hasLoadedOnce: false, currentPageIndex: 0, tasks: undefined }, async () => this.doRefresh());
    }
    composeDateFilter() {
        const { filter: { dateFilterType, fromDate, toDate }, } = this.state;
        // We add one second to 'toDate' because moment considers the end of day to be 11:59:59.
        // We convert it to UTC because that's how we store dates in the backend.
        switch (dateFilterType) {
            case TaskDateFilterTypeValues.CompletedTime:
                return {
                    fromCompletedDate: moment.utc(fromDate).format(),
                    toCompletedDate: moment.utc(toDate).add(1, "second").format(),
                };
            case TaskDateFilterTypeValues.QueueTime:
                return {
                    fromQueueDate: moment.utc(fromDate).format(),
                    toQueueDate: moment.utc(toDate).add(1, "second").format(),
                };
            case TaskDateFilterTypeValues.StartTime:
                return {
                    fromStartDate: moment.utc(fromDate).format(),
                    toStartDate: moment.utc(toDate).add(1, "second").format(),
                };
            default:
                return {};
        }
    }
    async searchInternal<K extends keyof Filter>(filter: Filter, currentPageIndex: number) {
        this.setState({ currentPageIndex });
        const startTime = moment();
        const searchFilter: TaskListBffArgs = {
            activeTab: filter.activeTab,
            states: getTaskStatesFromFilterState(filter.state),
            project: this.props.restrictToProjectId ? this.props.restrictToProjectId! : filter.project!,
            runbook: this.props.restrictToRunbookId ? this.props.restrictToRunbookId! : filter.runbook!,
            environment: filter.environment,
            name: filter.name,
            node: filter.node,
            tenant: this.props.restrictToTenantId ? this.props.restrictToTenantId : filter.tenant,
            spaces: getSpacesFilter(),
            skip: this.state.tasks ? currentPageIndex * this.state.tasks.ItemsPerPage : 0,
            ids: filter.ids && filter.ids.length ? filter.ids.join(",") : undefined!,
            hasPendingInterruptions: filter.hasPendingInterruptions ? true : undefined!,
            includeSystem: filter.includeSystem,
            hasWarningsOrErrors: filter.hasWarningsOrErrors ? true : undefined!,
            ...this.composeDateFilter(),
        };
        const useOptimization = session.featureToggles?.includes("PermissionCheckOptimizationFeatureToggle");
        const result = await timeOperation(timeOperationOptions.forRefresh(), async () => {
            const statisticsPromise = repository.Tasks.getStatistics({ ...searchFilter });
            const tasksListPromise = useOptimization ? repository.Tasks.getUnpaginatedTasks(searchFilter).then((res) => mapToSummary(res)) : repository.Tasks.getBffList(searchFilter);
            return {
                tasks: await tasksListPromise,
                statistics: await statisticsPromise,
            };
        });
        this.timeLastRefereshTookInMilliseconds = moment().diff(startTime, "milliseconds");
        if (this.props.onNewItems) {
            await this.props.onNewItems(result.tasks.Items);
        }
        return {
            tasks: result.tasks,
            hasLoadedOnce: true,
            running: result.statistics.Running,
            waiting: result.statistics.Waiting,
            needsApproval: result.statistics.NeedsApproval,
            totalCountsInOtherSpaces: result.statistics.TotalCountsInOtherSpaces,
        };
        function getSpacesFilter() {
            const hasTaskViewInAnySpace = session!.currentPermissions!.hasPermissionInAnyScope(Permission.TaskView);
            if (filter.spaces.length === 0) {
                if (hasTaskViewInAnySpace) {
                    return ["all"];
                }
                else {
                    return [];
                }
            }
            return filter.spaces;
        }
    }
    restrictedViewMode() {
        return !!this.props.restrictToProjectId || !!this.props.restrictToRunbookId || !!this.props.restrictToTenantId;
    }
    getProjectName() {
        const project = this.state.projects.find(({ Id }) => Id === this.props.restrictToProjectId);
        return project?.Name;
    }
    render() {
        return (<>
                <TasksQueryStringFilters filter={this.state.filter} onFilterChange={(filter) => this.search(filter)} getFilter={getTaskFilter} getQuery={getTaskQuery}/>
                {this.renderTable()}
                <TaskQueueUpdatedSnackbar open={this.state.snackbar.show} text={this.state.snackbar.text} onClose={() => this.setState({ snackbar: { show: false, text: "" } })}/>
            </>);
    }
    private renderTable() {
        const { tasks, running, waiting, needsApproval, totalCountsInOtherSpaces } = this.state;
        let tableContent: React.ReactNode = <></>;
        if (tasks) {
            if (isFeatureToggleEnabled("BulkManualInterventionsFeatureToggle") && this.state.filter.activeTab === TaskListTab.NeedsApproval) {
                tableContent = (<TasksDataTable headerColumns={["State", "Task", "Start Time", "Duration", "Assigned to"]} onRow={this.buildApprovalRow} currentPageIndex={this.state.currentPageIndex} tasks={tasks} onNewItems={this.props.onNewItems} onPageSelected={this.onPageSelected}/>);
            }
            else if (this.state.filter.activeTab === TaskListTab.Waiting) {
                const columns = isFeatureToggleEnabled("RemainingQueueDurationFeatureToggle") ? ["State", "Task", "Queued Time", "Start Time"] : ["State", "Task", "Queued Time"];
                tableContent = <TasksDataTable headerColumns={columns} onRow={this.buildWaitingRow} currentPageIndex={this.state.currentPageIndex} tasks={tasks} onNewItems={this.props.onNewItems} onPageSelected={this.onPageSelected}/>;
            }
            else {
                tableContent = (<TasksDataTable headerColumns={["State", "Task", "Start Time", "Completed Time", "Duration"]} onRow={this.buildFullRow} currentPageIndex={this.state.currentPageIndex} tasks={tasks} onNewItems={this.props.onNewItems} onPageSelected={this.onPageSelected}/>);
            }
        }
        else {
            tableContent = <Loading />;
        }
        const tableWithFilterLayout = this.props.hideAdvancedFilters ? (tableContent) : (<FilterLayout filter={this.state.filter} defaultFilter={createDefaultTaskFilter(this.props.restrictToTaskTypes, this.state.filter.activeTab)} additionalHeaderFilters={[this.stateFilter(Object.keys(TaskFilterStateValues).map((t) => ({ value: t, text: t })))]} onFilterReset={(resetFilter) => {
                this.search(resetFilter);
            }} filterSections={this.filterSections()} renderContent={() => tableContent}/>);
        const runningLabel = running === null ? TaskListTab.Running : `${TaskListTab.Running} (${running})`;
        const waitingLabel = waiting === null ? TaskListTab.Waiting : `${TaskListTab.Waiting} (${waiting})`;
        const needsApprovalLabel = needsApproval === null ? TaskListTab.NeedsApproval : `${TaskListTab.NeedsApproval} (${needsApproval})`;
        return this.renderWithLayout(<ControlledTabsContainer onChange={(value) => this.switchTab({ ...this.state.filter, activeTab: isTaskListTab(value) ? value : undefined })} value={this.state.filter.activeTab ?? TaskListTab.All}>
                <TabItem label={TaskListTab.All} value={TaskListTab.All}>
                    {<StatisticsInOtherSpacesPanel totals={totalCountsInOtherSpaces}/>}
                    {tableWithFilterLayout}
                </TabItem>
                <TabItem label={runningLabel} value={TaskListTab.Running}>
                    {<StatisticsInOtherSpacesPanel totals={totalCountsInOtherSpaces}/>}
                    {tableWithFilterLayout}
                </TabItem>
                <TabItem label={waitingLabel} value={TaskListTab.Waiting}>
                    {<StatisticsInOtherSpacesPanel totals={totalCountsInOtherSpaces}/>}
                    {tableWithFilterLayout}
                </TabItem>
                {isFeatureToggleEnabled("BulkManualInterventionsFeatureToggle") && (<TabItem label={needsApprovalLabel} value={TaskListTab.NeedsApproval}>
                        {<StatisticsInOtherSpacesPanel totals={totalCountsInOtherSpaces}/>}
                        {tableWithFilterLayout}
                    </TabItem>)}
                <TabItem label={TaskListTab.Completed} value={TaskListTab.Completed}>
                    {<StatisticsInOtherSpacesPanel totals={totalCountsInOtherSpaces}/>}
                    {tableWithFilterLayout}
                </TabItem>
            </ControlledTabsContainer>);
    }
    private stateFilter(items: Item[]) {
        return (<div className={styles.states}>
                <Select value={this.state.filter.state} onChange={(value) => {
                const state = value as TaskFilterState | undefined;
                this.search({ ...this.state.filter, state });
            }} items={items} allowClear={true} fieldName="task state" placeholder="All task states"/>
            </div>);
    }
    private filterSections() {
        return [
            {
                render: (<div>
                        <div className={styles.checkboxFiltersContainer}>
                            <AdvancedFilterCheckbox label="Awaiting manual intervention" value={this.state.filter.hasPendingInterruptions!} onChange={(hasPendingInterruptions) => this.search({ ...this.state.filter, hasPendingInterruptions })}/>
                            {this.renderIncludeSystem()}
                            <AdvancedFilterCheckbox label="Has warnings or errors" value={this.state.filter.hasWarningsOrErrors!} onChange={(hasWarningsOrErrors) => this.search({ ...this.state.filter, hasWarningsOrErrors })}/>
                        </div>
                        {this.renderSpaceSelector()}
                        <Select value={this.state.filter.name} allowFilter={true} onChange={(name) => this.search({ ...this.state.filter, name })} items={this.state.taskTypes.map((t) => ({ value: t.Id, text: t.Name }))} allowClear={true} fieldName="task type" placeholder="All task types" label="By task type"/>
                        {!this.restrictedViewMode() && (<Select value={this.state.filter.node} onChange={(node) => this.search({ ...this.state.filter, node })} items={this.state.nodes.map((n) => ({ value: n.Id, text: n.Name }))} allowClear={true} label="By node" placeholder="All nodes"/>)}
                        {this.renderSpaceSpecificSelectors()}
                        {this.renderDateSelectors()}
                    </div>),
            },
        ];
    }
    private renderWithLayout(children: React.ReactNode) {
        return this.props.renderLayout({ busy: this.state.busy!, doBusyTask: this.doBusyTask, errors: this.errors!, hasLoadedOnce: this.state.hasLoadedOnce ?? false, children });
    }
    private renderDateSelectors = () => {
        const fromError = this.state.filter.fromDate! > this.state.filter.toDate! ? "Must be before the \"To\" date" : undefined;
        const toError = this.state.filter.fromDate! > this.state.filter.toDate! ? "Must be after the \"From\" date" : undefined;
        return (<>
                <Select value={this.state.filter.dateFilterType} allowFilter={false} sortItems={false} onChange={(dateFilterType) => this.search({ ...this.state.filter, dateFilterType: dateFilterType as TaskDateFilterType })} items={Object.values(TaskDateFilterTypeValues).map((v) => ({ value: v, text: v }))} allowClear={true} fieldName="date filter type" placeholder="Select a date field" label="By date"/>
                {this.state.filter.dateFilterType && (<>
                        <div className={styles.datepickerWrap}>
                            <DatePicker value={this.state.filter.fromDate!} onChange={(fromDate) => this.search({ ...this.state.filter, fromDate })} label="From" error={fromError} format={datePickerFormat} variant={"inline"}/>
                        </div>
                        <div className={styles.datepickerWrap}>
                            <DatePicker value={this.state.filter.toDate!} onChange={(toDate) => this.search({ ...this.state.filter, toDate: moment(toDate).endOf("day").toDate() })} label="To" error={toError} format={datePickerFormat} variant={"inline"}/>
                        </div>
                    </>)}
            </>);
    };
    private renderSpaceSpecificSelectors = () => {
        // These are linked to access in the current space, because that's where the data will come from
        // we need to revisit how these will work going forward to make the filtering easier to do cross-space
        const isWithinASpace = client.spaceId;
        return (isWithinASpace && (<>
                    {!this.props.restrictToProjectId && (<PermissionCheck permission={Permission.ProjectView} wildcard={true}>
                            <Select value={this.state.filter.project} onChange={(project) => this.search({ ...this.state.filter, project })} items={this.state.projects.map((p) => ({ value: p.Id, text: p.Name }))} allowClear={true} allowFilter={true} fieldName="project" placeholder="All projects"/>
                        </PermissionCheck>)}
                    {!this.props.restrictToRunbookId && (<PermissionCheck permission={Permission.RunbookView} wildcard={true}>
                            <Select value={this.state.filter.runbook} onChange={(runbook) => this.search({ ...this.state.filter, runbook })} items={this.state.runbooks.map((r) => {
                    const defaultSelectItem = {
                        value: r.Id,
                        text: r.Name,
                    };
                    if (!!this.props.restrictToProjectId || !!this.props.restrictToRunbookId) {
                        return defaultSelectItem;
                    }
                    const runbookProject = this.state.projects.find((p) => p.Id === r.ProjectId);
                    if (!runbookProject) {
                        logger.error("Failed to find project for runbook. This should not happen.");
                        return defaultSelectItem;
                    }
                    return {
                        value: r.Id,
                        text: `${runbookProject.Name} - ${r.Name}`,
                    };
                })} allowClear={true} allowFilter={true} fieldName="runbook" placeholder="All runbooks"/>
                        </PermissionCheck>)}
                    <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                        <EnvironmentSelect value={this.state.filter.environment} onChange={(environment) => this.search({ ...this.state.filter, environment })} environments={this.state.environments} allowClear={true} allowFilter={true} fieldName="environment" placeholder="All environments"/>
                    </PermissionCheck>
                    {!this.props.restrictToTenantId && (<FeatureToggle feature={Feature.MultiTenancy}>
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <Select value={this.state.filter.tenant} onChange={(tenant) => this.search({ ...this.state.filter, tenant })} items={this.state.tenants.map((t) => ({ value: t.Id, text: t.Name }))} allowClear={true} allowFilter={true} fieldName="tenant" placeholder="All tenants" label="By tenant"/>
                            </PermissionCheck>
                        </FeatureToggle>)}
                </>));
    };
    private renderIncludeSystem = () => {
        if (this.restrictedViewMode()) {
            return null;
        }
        const hasSystemTaskView = session.currentPermissions!.scopeToSystem().hasPermissionInAnyScope(Permission.TaskView);
        if (hasSystemTaskView) {
            return <AdvancedFilterCheckbox label="Include system tasks" value={this.state.filter.includeSystem} onChange={(includeSystem) => this.search({ ...this.state.filter, includeSystem })}/>;
        }
        return null;
    };
    private renderSpaceSelector = () => {
        if (this.restrictedViewMode()) {
            return null;
        }
        const hasTaskViewInAnySpace = session.currentPermissions!.hasPermissionInAnyScope(Permission.TaskView);
        if (!hasTaskViewInAnySpace) {
            return (<div style={{ margin: "1rem 0 0 0" }}>
                    <Callout type={"information"} title={"Permission required"}>
                        You do not have {Permission.TaskView} permission in any given Space.
                    </Callout>
                </div>);
        }
        return (<SpaceMultiSelect items={this.state.spaces} label={"By space"} placeholder={this.state.filter.spaces.length ? undefined : "All spaces"} onChange={(spaces: string[]) => this.search({ ...this.state.filter, spaces })} value={this.state.filter.spaces}/>);
    };
    private doRefresh: Refresh = () => Promise.resolve();
    performMoveToTop = async (task: TaskSummaryResource) => {
        await this.doBusyTask(async () => {
            this.props.dispatchAction("Move Task to Top of Queue", { resource: "Task", action: Action.Update });
            await repository.Tasks.prioritize(task.Id);
            this.setState({ snackbar: { show: true, text: "Task queue updated" } });
            await this.doRefresh();
        });
    };
    performCancellation = async (task: TaskSummaryResource) => {
        this.props.dispatchAction("Cancel task", { resource: "Task", action: Action.Cancel });
        await repository.Tasks.cancel(task.Id);
        this.setState({ snackbar: { show: true, text: "Task cancellation initiated" } });
        await this.doRefresh();
    };
    private buildWaitingRow = (task: TaskSummaryResource) => {
        const queueTime = moment(task.QueueTime);
        if (isFeatureToggleEnabled("RemainingQueueDurationFeatureToggle")) {
            return [
                <StateCell state={task.State} queuePlacement={task.QueuePlacement}/>,
                this.renderCell(task),
                DateFormatter.dateToLongFormat(queueTime),
                task.EstimatedRemainingQueueDurationSeconds < 60 ? "soon" : `in ${formatTimeDifference(null, null, task.EstimatedRemainingQueueDurationSeconds)}`,
                <TaskOverflowMenu task={task} prioritize={(t: TaskSummaryResource) => this.performMoveToTop(t)} cancel={(t: TaskSummaryResource) => this.performCancellation(t)}/>,
            ];
        }
        return [
            <StateCell state={task.State} queuePlacement={task.QueuePlacement}/>,
            this.renderCell(task),
            DateFormatter.dateToLongFormat(queueTime) + " (" + queueTime.fromNow() + ")",
            <TaskOverflowMenu task={task} prioritize={(t: TaskSummaryResource) => this.performMoveToTop(t)} cancel={(t: TaskSummaryResource) => this.performCancellation(t)}/>,
        ];
    };
    private buildApprovalRow = (task: TaskSummaryResource) => {
        const queueTime = moment(task.QueueTime);
        return [
            <StateCell state={task.State} queuePlacement={task.QueuePlacement}/>,
            this.renderCell(task),
            DateFormatter.dateToLongFormat(task.StartTime),
            task.Duration,
            <AssignTaskCell task={task} doBusyTask={this.doBusyTask} doRefresh={this.doRefresh}/>,
            <TaskOverflowMenu task={task} prioritize={(t: TaskSummaryResource) => this.performMoveToTop(t)} cancel={(t: TaskSummaryResource) => this.performCancellation(t)}/>,
        ];
    };
    private buildFullRow = (task: TaskSummaryResource) => {
        const isScheduledTask = task.QueuePlacement === 0 && task.State === TaskState.Queued;
        const doNotShowDuration = (task.State === TaskState.Canceled && task.StartTime === null) || isScheduledTask;
        const timeUntilStart = isFeatureToggleEnabled("RemainingQueueDurationFeatureToggle") ? (task.EstimatedRemainingQueueDurationSeconds < 60 ? "soon" : `in ${formatTimeDifference(null, null, task.EstimatedRemainingQueueDurationSeconds)}`) : "";
        return [
            <StateCell state={task.State} queuePlacement={task.QueuePlacement}/>,
            this.renderCell(task),
            task.State === TaskState.Queued ? <span className={styles.scheduledTime}>{timeUntilStart}</span> : DateFormatter.dateToLongFormat(task.StartTime),
            DateFormatter.dateToLongFormat(task.CompletedTime),
            doNotShowDuration ? null : task.Duration,
            <TaskOverflowMenu task={task} prioritize={(t: TaskSummaryResource) => this.performMoveToTop(t)} cancel={(t: TaskSummaryResource) => this.performCancellation(t)}/>,
        ];
    };
    private renderCell(task: TaskSummaryResource) {
        if (this.props.renderCell) {
            return this.props.renderCell({ task });
        }
        return (
        // mark.siedle: We want this InternalLink here so users have the option of standard
        // anchor-behaviour (ie. right click) that you don't get with the onClick from our SimpleDataTable component.
        <InternalLink to={links.taskPage.generateUrl({ taskId: task.Id })}>
                <TaskDetails task={task} stripAllPadding={true}/>
            </InternalLink>);
    }
    private onPageSelected = async (skip: number, p: number) => {
        this.setState({ hasLoadedOnce: false, currentPageIndex: p }, async () => this.doRefresh());
    };
    static displayName = "TasksInner";
}
interface TaskOverflowMenuProps {
    task: TaskSummaryResource;
    prioritize: (task: TaskSummaryResource) => Promise<void>;
    cancel: (task: TaskSummaryResource) => Promise<void>;
}
const CancellableStates = new Set([TaskState.Queued, TaskState.Executing]);
function TaskOverflowMenu({ task, prioritize, cancel }: TaskOverflowMenuProps) {
    const menuItems = [];
    const queueTime = moment(task.QueueTime);
    const now = moment();
    const displayMoveTopOption = task.State === TaskState.Queued && queueTime.isBefore(now);
    if (displayMoveTopOption) {
        const moveTopItem = OverflowMenuItems.item("Move to top", () => prioritize(task), { permission: Permission.TaskEdit });
        menuItems.push(moveTopItem);
    }
    if (CancellableStates.has(task.State)) {
        const cancelItem = OverflowMenuItems.dialogItem("Cancel", <CancelTaskDialog task={task} cancelTask={cancel}/>, { permission: Permission.TaskCancel });
        menuItems.push(cancelItem);
    }
    if (menuItems.length === 0) {
        return null;
    }
    return (<div className={styles.taskOverflowMenu}>
            <OverflowMenu menuItems={menuItems}/>
        </div>);
}
interface TasksDataTableProps {
    tasks: TaskSummaryResourceCollection;
    onNewItems?(items: any[]): Promise<any[]>;
    currentPageIndex: number;
    onPageSelected: (skip: number, p: number) => Promise<void>;
    headerColumns: string[];
    onRow: (item: TaskSummaryResource) => React.ReactNode[];
}
function TasksDataTable({ tasks, onNewItems, currentPageIndex, onPageSelected, onRow, headerColumns }: TasksDataTableProps) {
    return (<TaskResourceDataTable title="Tasks" initialData={tasks} onRow={onRow} onRowRedirectUrl={(task: TaskSummaryResource) => links.taskPage.generateUrl({ taskId: task.Id })} onNewItems={onNewItems} rowColumnClassName={styles.taskDetailsCell} headerColumns={headerColumns} showPagingInNumberedStyle={true} currentPageIndex={currentPageIndex} onPageSelected={onPageSelected} rowClassName={styles.taskRow}/>);
}
function Loading() {
    return (<div className={styles.loading}>
            <CircularProgress size={"large"}/>
        </div>);
}
interface StateCellProps {
    state: TaskState;
    queuePlacement: number;
}
function StateCell({ state, queuePlacement }: StateCellProps) {
    if (state === TaskState.Queued) {
        const queuePlacementText = queuePlacement > 0 ? moment.localeData().ordinal(queuePlacement) : "Scheduled";
        return (<div className={styles.state}>
                <span>{state}</span>
                <span className={styles.queuePlacement}>{queuePlacementText}</span>
            </div>);
    }
    return (<div className={styles.state}>
            <span>{state}</span>
        </div>);
}
const datePickerFormat = "MMM dd, yyyy";
interface StatisticsInOtherSpacesPanelProps {
    totals: {
        [state: string]: number;
    };
}
function StatisticsInOtherSpacesPanel({ totals }: StatisticsInOtherSpacesPanelProps) {
    const statistics = mapTotalsToStatisticsMessage(totals);
    if (statistics.length === 0)
        return null;
    return <div className={cx(styles.stats, styles.otherSpaceStats)}>{statistics} in other spaces</div>;
}
interface TaskStatisticProps {
    count: number;
    setFilter?(e: React.MouseEvent<HTMLAnchorElement>): void;
    state: TaskFilterState | "Interrupted";
}
function TaskStatistic({ count, setFilter, state }: TaskStatisticProps) {
    return (<span>
            {pluralize("task", count, true)}{" "}
            {setFilter ? (<a href="#" onClick={(e) => setFilter(e)}>
                    {getStatisticLabel(state)}
                </a>) : (<>{getStatisticLabel(state)}</>)}
        </span>);
}
const mapTotalsToStatisticsMessage = (totals: {
    [state: string]: number;
}, setFilter?: (e: React.MouseEvent<HTMLAnchorElement>, filter: Partial<Filter>) => void) => {
    const result: JSX.Element[] = [];
    if (totals.Interrupted > 0) {
        result.push(<TaskStatistic key={"Interrupted"} count={totals.Interrupted} state={"Interrupted"} setFilter={setFilter ? (e) => setFilter(e, { hasPendingInterruptions: true }) : undefined}/>);
    }
    if (totals.Executing > 0) {
        result.push(<TaskStatistic key={TaskFilterStateValues.Executing} count={totals.Executing} state={TaskFilterStateValues.Executing} setFilter={setFilter ? (e) => setFilter(e, { state: TaskFilterStateValues.Executing }) : undefined}/>);
    }
    if (totals.Cancelling > 0) {
        result.push(<TaskStatistic key={TaskFilterStateValues.Cancelling} count={totals.Cancelling} state={TaskFilterStateValues.Cancelling} setFilter={setFilter ? (e) => setFilter(e, { state: TaskFilterStateValues.Cancelling }) : undefined}/>);
    }
    if (totals.Queued > 0) {
        result.push(<TaskStatistic key={TaskFilterStateValues.Queued} count={totals.Queued} state={TaskFilterStateValues.Queued} setFilter={setFilter ? (e) => setFilter(e, { state: TaskFilterStateValues.Queued }) : undefined}/>);
    }
    if (result.length === 1) {
        return result;
    }
    return result.map((v, i) => {
        if (i === result.length - 1) {
            return v;
        }
        if (i === result.length - 2) {
            return [v, <span key={i}> and </span>];
        }
        return [v, <span key={i}>, </span>];
    });
};
const getStatisticLabel = (state: TaskFilterState | "Interrupted") => {
    switch (state) {
        case TaskFilterStateValues.Queued:
            return "waiting in queue";
        case TaskFilterStateValues.Cancelling:
            return "cancelling";
        case TaskFilterStateValues.Executing:
            return "running";
        case "Interrupted":
            return "awaiting intervention";
        default:
            throw Error(`Unhandled task state: ${state}`);
    }
};
const mapToSummary = (collection: ResourceCollection<TaskResource<any>>): TaskSummaryResourceCollection => {
    return {
        Items: collection.Items.map((task) => ({ ...task, QueuePlacement: 0, InterruptionSummaries: [] })),
        ItemsPerPage: collection.ItemsPerPage,
        TotalResults: collection.TotalResults,
    };
};
function createDefaultTaskFilter(taskTypes?: string[], activeTab?: TaskListTab): Filter {
    const hasTaskViewInCurrentSpace = session.currentPermissions!.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.TaskView);
    const shouldFilterToCurrentSpace = client.spaceId && hasTaskViewInCurrentSpace;
    return getTaskFilter({
        spaces: shouldFilterToCurrentSpace ? [client.spaceId!] : [],
        includeSystem: shouldFilterToCurrentSpace ? "" : "true",
        name: taskTypes?.join(","),
        fromDate: moment().subtract(1, "month").startOf("day").format(),
        toDate: moment().endOf("day").format(),
        activeTab,
    });
}
function Tasks(props: TasksProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const dispatchTabSwitchingAction = useAnalyticViewTaskStateTabDispatch();
    return <TasksInner {...props} dispatchAction={dispatchAction} dispatchTabSwitchingAction={dispatchTabSwitchingAction}/>;
}
const styles = {
    taskDetailsCell: css({
        verticalAlign: "middle",
    }),
    stats: css({
        paddingLeft: space[16],
        paddingTop: space[16],
    }),
    states: css({
        width: "16rem",
    }),
    checkboxFiltersContainer: css({
        marginBottom: space[16],
    }),
    taskRow: css({
        ":hover": {
            backgroundColor: themeTokens.color.menuList.background.hover,
        },
    }),
    datepickerWrap: css({
        paddingBottom: space[16],
    }),
    otherSpaceStats: css({
        font: text.interface.body.default.small,
        color: themeTokens.color.text.tertiary,
    }),
    state: css({
        fontWeight: fontWeight["400"],
        display: "flex",
        gap: space[8],
    }),
    queuePlacement: css({
        color: themeTokens.color.text.tertiary,
    }),
    scheduledTime: css({
        color: themeTokens.color.text.tertiary,
    }),
    taskOverflowMenu: css({
        display: "flex",
        justifyContent: "flex-end",
    }),
    approvalCell: css({
        display: "flex",
        justifyContent: "flex-start",
    }),
    approvalCellAssigned: css({
        paddingRight: "0.75rem",
    }),
    loading: css({
        display: "flex",
        justifyContent: "center",
        paddingBottom: space[16],
    }),
};
export default Tasks;
