import type { AccountResource } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { FormFieldProps } from "~/components/form";
import { BoundAccountSelect } from "~/components/form/AccountSelect/AccountSelect";
import type { VariableLookupProps } from "~/components/form/VariableLookup/VariableLookup";
interface GoogleCloudAccountInputProps extends FormFieldProps<string> {
    resetValue?: string;
    items: AccountResource[];
    label: string;
    variableLookup: VariableLookupProps | undefined;
    warning?: string;
    allowClear: boolean;
    defaultValueIndicator: JSX.Element | undefined;
    onRequestRefresh: () => Promise<void>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}
const GoogleCloudAccountInput: React.FC<GoogleCloudAccountInputProps> = (props) => {
    const { value, resetValue, items, label, variableLookup, warning, allowClear, defaultValueIndicator, onRequestRefresh, ...rest } = props;
    const formProps = { ...rest, label };
    return (<React.Fragment>
            <BoundAccountSelect value={value} resetValue={resetValue} items={items} variableLookup={variableLookup} type={[AccountType.GoogleCloudAccount]} warning={warning} allowClear={allowClear} onRequestRefresh={onRequestRefresh} {...formProps}/>
            {defaultValueIndicator}
        </React.Fragment>);
};
GoogleCloudAccountInput.displayName = "GoogleCloudAccountInput"
export default GoogleCloudAccountInput;
