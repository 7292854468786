import { Divider } from "@octopusdeploy/design-system-components";
import classnames from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface MultilineTextContainerProps {
    readonly isFocused?: boolean;
    onClick?: () => void;
    hideDivider?: boolean;
    showHand?: boolean;
    monoSpacedFont?: boolean;
}
const MultilineTextContainer: React.FC<MultilineTextContainerProps> = ({ isFocused = false, children, onClick, hideDivider = false, showHand = true, monoSpacedFont = false }) => {
    return (<div className={styles.multilineContainer} onClick={() => {
            if (isFocused && onClick) {
                onClick();
            }
        }}>
            <div className={classnames({ [styles.multilineText]: true, [styles.multilineTextFocused]: isFocused, [styles.handCursor]: showHand, [styles.monospaced]: monoSpacedFont })}>{children}</div>
            {isFocused && !hideDivider ? <Divider /> : ""}
        </div>);
};
MultilineTextContainer.displayName = "MultilineTextContainer"
export default MultilineTextContainer;
