import * as React from "react";
import LoadMoreWrapper from "~/components/LoadMoreWrapper/LoadMoreWrapper";
import { withTheme } from "~/components/Theme/index";
import styles from "./style.module.less";
export const FilesList = (props: {
    filePaths: string[];
    actionName: string;
}) => withTheme((theme) => (<ul className={`fa-ul, ${styles.filesList}`}>
            <LoadMoreWrapper items={props.filePaths} initialTakeSize={5} useAnchorForLoadMore={true} showLoadAll={false} renderLoadMore={(children) => <li className={styles.loadMore}>{children}</li>} renderItem={(file, index) => (<li style={{ color: theme.iconNeutral }} className={styles.filesListItem} key={`${props.actionName}-fileItem-${index}`}>
                        <span className={`fa-li ${styles.fileListItemIcon}`}>
                            <i className="fa-solid fa-link"/>
                        </span>
                        {file}
                    </li>)}/>
        </ul>));
