/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Fade } from "@material-ui/core";
import { Callout, CircularProgress } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { ActionTemplateSearchResource, FeedResource, IProcessResource, ProcessType } from "@octopusdeploy/octopus-server-client";
import { GetPrimaryPackageReference, Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import ActionTemplateSelectorForBlueprint from "~/areas/blueprints/processEditor/ActionTemplateSelectorForBlueprint";
import type { ActionTemplateSelectorProps } from "~/areas/projects/components/Process/ActionTemplateSelector/ActionTemplateSelector";
import { assembleExistingAction, assembleNewAction, assembleParentStep, isRunOnServerOrWorkerPool, loadAvailableWorkerPools, whereConfiguredToRun } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { DeleteStepsCallout } from "~/areas/projects/components/Process/Common/DeleteStepsCallout";
import { useMaybeLoadedActionTemplatesFromContext } from "~/areas/projects/components/Process/Contexts/ProcessActionTemplatesContextProvider";
import type { ProcessContextProps } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import type { BoundErrorActionsType, ProcessErrorSelectors } from "~/areas/projects/components/Process/Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessErrorActions, useProcessErrorSelectors } from "~/areas/projects/components/Process/Contexts/ProcessErrors/ProcessErrorsContext";
import { useMaybeFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import type { ProcessQueryStringContextProps } from "~/areas/projects/components/Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessQueryStringContext } from "~/areas/projects/components/Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
import type { BoundWarningActionsType } from "~/areas/projects/components/Process/Contexts/ProcessWarnings/ProcessWarningsContext";
import { useProcessWarningActions } from "~/areas/projects/components/Process/Contexts/ProcessWarnings/ProcessWarningsContext";
import { ProcessContextFormPaperLayout } from "~/areas/projects/components/Process/CustomPaperLayouts/ProcessContextFormPaperLayout";
import { ProcessPaperLayout } from "~/areas/projects/components/Process/CustomPaperLayouts/ProcessPaperLayout";
import { withProjectDeploymentProcess } from "~/areas/projects/components/Process/Pages/index";
import ProcessActionDetails from "~/areas/projects/components/Process/ProcessActionDetails";
import ProcessParentStepDetails from "~/areas/projects/components/Process/ProcessParentStepDetails";
import ProcessSidebarLayout from "~/areas/projects/components/Process/ProcessSidebarLayout";
import type { ProcessStepsLayoutLoaderLookupData } from "~/areas/projects/components/Process/ProcessStepsLayoutLoader";
import type { ProcessStepActionState, ProcessStepLookupState } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import ProcessesMergedDialog from "~/areas/projects/components/Process/ProcessesMergedDialog";
import type { AssembledAction, ProcessFilter, StoredAction, StoredStep } from "~/areas/projects/components/Process/types";
import { EnvironmentOption, ExecutionLocation } from "~/areas/projects/components/Process/types";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { ProjectContextProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { ActionPlugin } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import BaseComponent from "~/components/BaseComponent/index";
import type { Errors } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { Loading } from "~/components/Loading/Loading";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { ConvertedOverflowMenuItems } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import { Section } from "~/components/Section/Section";
import PageTitleHelper from "~/utils/PageTitleHelper";
export enum ProcessPageIntent {
    Unknown = "Unknown",
    ChooseStepTemplates = "ChooseStepTemplates",
    ChooseChildStepTemplates = "ChooseChildStepTemplates",
    CreateNewAction = "CreateNewAction",
    CreateNewChildAction = "CreateNewChildAction",
    ViewAction = "ViewAction",
    ViewParentStep = "ViewParentStep"
}
export function isIntentToCreateNew(intent: ProcessPageIntent) {
    return intent === ProcessPageIntent.CreateNewAction || intent === ProcessPageIntent.CreateNewChildAction;
}
export function intentFromFilter(filter: ProcessFilter): ProcessPageIntent {
    if (filter.new && filter.actionType) {
        if (filter.parentStepId) {
            return ProcessPageIntent.CreateNewChildAction;
        }
        return ProcessPageIntent.CreateNewAction;
    }
    if (filter.stepTemplates) {
        return ProcessPageIntent.ChooseStepTemplates;
    }
    if (filter.childStepTemplates && filter.parentStepId) {
        return ProcessPageIntent.ChooseChildStepTemplates;
    }
    if (filter.actionId) {
        return ProcessPageIntent.ViewAction;
    }
    if (filter.parentStepId) {
        return ProcessPageIntent.ViewParentStep;
    }
    return ProcessPageIntent.Unknown;
}
export interface ProcessStepActionData {
    stepLookups: ProcessStepLookupState;
    stepOther: ProcessStepActionState;
    step: StoredStep;
    action: StoredAction;
}
export interface ProcessParentStepData {
    stepNumber: string;
    step: StoredStep;
    machineRoles: string[];
    isFirstStep: boolean;
    errors?: Errors | undefined;
}
type ProcessStepsLayoutForBlueprintProps = {
    lookups: ProcessStepsLayoutLoaderLookupData;
    errors: Errors | undefined;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
    isBuiltInWorkerEnabled: boolean;
};
interface ProcessPageState {
    actionData: ProcessStepActionData | null;
    parentStepData: ProcessParentStepData | null;
    redirectTo?: LinkHref;
    commitMessage: CommitMessageWithDetails;
    currentActionName: string;
    currentStepName: string;
    disableDirtyFormChecking?: boolean;
}
type ProcessStepsLayoutForBlueprintInternalProps = {
    lookups: ProcessStepsLayoutLoaderLookupData;
    errors: Errors | undefined;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
    isBuiltInWorkerEnabled: boolean;
    projectContext: ProjectContextProps;
    processContext: ProcessContextProps;
    processErrorActions: BoundErrorActionsType;
    processErrorSelectors: ProcessErrorSelectors;
    processWarningActions: BoundWarningActionsType;
    processQueryStringContext: ProcessQueryStringContextProps;
    feeds: FeedResource[];
    feedsLoaded: boolean;
    actionTemplates: ActionTemplateSearchResource[];
    actionTemplatesLoaded: boolean;
};
function getDefaultCommitMessage(): string {
    return "Update blueprint";
}
const loadingIndicatorForStep: JSX.Element = (<Section>
        <CircularProgress size="small"/>
    </Section>);
// This class is based off BaseComponent because it is required to ensure the asynchronous calls
// to setState don't result in a console error like "Warning: Can't perform a React state update on an unmounted component. ..."
class ProcessStepsLayoutForBlueprint extends BaseComponent<ProcessStepsLayoutForBlueprintInternalProps, ProcessPageState> {
    private isAddingStep = false;
    private isLoadingDataForActionId: string | undefined = undefined;
    private isLoadingDataForParentStepId: string | undefined = undefined;
    private openCommitDialog?: () => void;
    constructor(props: ProcessStepsLayoutForBlueprintInternalProps) {
        super(props);
        this.state = {
            actionData: null,
            parentStepData: null,
            commitMessage: { summary: "", details: "" },
            currentActionName: "",
            currentStepName: "",
        };
    }
    // We need to update state on mount as well since the blueprints steps layout can be accessed from the list. When this happens, we've already loaded
    // all the required data. This means we only render a single time and an update won't get triggered
    async componentDidMount() {
        await this.updateStateBasedOnCurrentIntent();
    }
    async componentDidUpdate() {
        await this.updateStateBasedOnCurrentIntent();
    }
    // Based on ProcessStepsLayout.tsx componentDidUpdate()
    private async updateStateBasedOnCurrentIntent() {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return;
        }
        const queryFilter = this.props.processQueryStringContext.state.queryFilter;
        if (!this.canSafelyNavigateToFilter(queryFilter)) {
            logger.warn("Failed to find action by ID in context, attempting to find action based on name.");
            // Something about the ID has likely changed server-side.
            // We'll attempt a lookup by name, then redirect to the ID.
            const foundByName = this.needToRedirectToStepBasedOnName(queryFilter, this.state.currentStepName, this.state.currentActionName, (actionId: string) => {
                this.props.processQueryStringContext.actions.showProcessAction(actionId);
            }, (parentStepId: string) => {
                this.props.processQueryStringContext.actions.showProcessParentStep(parentStepId);
            });
            if (foundByName)
                return;
            logger.warn("Failed to find action by ID or Name, falling back to empty step editor.");
            this.props.processQueryStringContext.actions.showEmptyStepEditor();
            return;
        }
        const currentIntent = intentFromFilter(queryFilter);
        switch (currentIntent) {
            case ProcessPageIntent.ViewAction:
                {
                    const { actionId } = queryFilter;
                    const { actionType, templateId } = queryFilter;
                    const guardAgainstAlreadyLoading = this.isLoadingDataForActionId === actionId;
                    if (guardAgainstAlreadyLoading) {
                        return;
                    }
                    const guardAgainstUnnecessaryReload = this.filtersAreAlignedWithActionData(actionId);
                    if (guardAgainstUnnecessaryReload) {
                        return;
                    }
                    await this.props.doBusyTask(async () => {
                        this.isLoadingDataForActionId = actionId;
                        let actionData: ProcessStepActionData | null = null;
                        try {
                            actionData = await this.loadActionData(actionId, actionType, templateId, currentIntent);
                        }
                        finally {
                            this.isLoadingDataForActionId = undefined;
                            // Set the current step/action name (as these get a default when we assembleNewAction).
                            let currentStepName = this.state.currentStepName;
                            let currentActionName = this.state.currentActionName;
                            if (actionData) {
                                const actionId = actionData.action.Id;
                                const { selectors } = this.props.processContext;
                                const action = selectors.getActionById(actionId);
                                const step = selectors.getStepById(action.ParentId);
                                currentStepName = step.Name;
                                currentActionName = action.Name;
                            }
                            this.setState({ actionData, parentStepData: null, currentStepName, currentActionName });
                        }
                    });
                }
                break;
            case ProcessPageIntent.ViewParentStep:
                {
                    const parentStepId = queryFilter.parentStepId;
                    const guardAgainstAlreadyLoading = this.isLoadingDataForParentStepId === parentStepId;
                    if (guardAgainstAlreadyLoading) {
                        return;
                    }
                    const guardAgainstUnnecessaryReload = !parentStepId || this.filtersAreAlignedWithParentStepData(parentStepId);
                    if (guardAgainstUnnecessaryReload) {
                        return;
                    }
                    await this.props.doBusyTask(async () => {
                        this.isLoadingDataForParentStepId = parentStepId;
                        let parentStepData: ProcessParentStepData | null = null;
                        try {
                            parentStepData = await this.loadParentStepData();
                        }
                        finally {
                            this.isLoadingDataForParentStepId = undefined;
                            this.setState({ parentStepData, actionData: null });
                        }
                    });
                }
                break;
            case ProcessPageIntent.CreateNewChildAction:
            case ProcessPageIntent.CreateNewAction:
                {
                    if (this.isAddingStep) {
                        return;
                    }
                    await this.props.doBusyTask(async () => {
                        this.isAddingStep = true;
                        let actionData: ProcessStepActionData | null = null;
                        try {
                            const { actionId, actionType, templateId, tags } = queryFilter;
                            actionData = await this.loadActionData(actionId, actionType, templateId, currentIntent, tags);
                            if (actionData && actionData.step && actionData.action) {
                                const step = actionData.step;
                                const action = actionData.action;
                                if (currentIntent === ProcessPageIntent.CreateNewAction) {
                                    this.props.processContext.actions.addStep(step, action);
                                }
                                else if (currentIntent === ProcessPageIntent.CreateNewChildAction && queryFilter.parentStepId) {
                                    this.props.processContext.actions.addChildAction(queryFilter.parentStepId, actionData.action);
                                }
                                this.setState({ parentStepData: null, actionData: null }, () => this.props.processQueryStringContext.actions.showProcessAction(action.Id));
                            }
                            else {
                                throw Error("Failed to create step or action.");
                            }
                        }
                        finally {
                            this.isAddingStep = false;
                            if (actionData && actionData.action) {
                                const action = actionData.action;
                                this.setState({ parentStepData: null, actionData: null }, () => this.props.processQueryStringContext.actions.showProcessAction(action.Id));
                            }
                            else {
                                this.setState({ parentStepData: null, actionData: null });
                            }
                        }
                    });
                }
                break;
        }
    }
    filtersAreAlignedWithActionData = (actionId: string | undefined): boolean => {
        const { actionData: currentActionData } = this.state;
        return !!actionId && !!currentActionData && currentActionData.action.Id === actionId;
    };
    filtersAreAlignedWithParentStepData = (parentStepId: string): boolean => {
        const { parentStepData: currentParentStepData } = this.state;
        return !!parentStepId && !!currentParentStepData && currentParentStepData.step.Id === parentStepId;
    };
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        const processContext = this.props.processContext;
        const { model: project, projectContextRepository } = this.props.projectContext.state;
        const { selectors, actions } = processContext;
        const actionLabel = "Commit";
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData() || !this.canSafelyNavigateToFilter(this.props.processQueryStringContext.state.queryFilter)) {
            return (<ProcessContextFormPaperLayout disableDirtyFormChecking={this.state.disableDirtyFormChecking} busy={true} doBusyTask={this.props.doBusyTask} model={undefined} cleanModel={undefined} onSaveClick={noOp} saveButtonLabel={actionLabel}/>);
        }
        const overflowMenuItems = [
            OverflowMenuItems.deleteItem("Delete all steps", "Are you sure you want to delete all steps from this process?", async () => {
                // Delete steps from our processResource directly and save on server. That will cause our context to get a new process.
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                const processToSave = selectors.getProcessResource() as IProcessResource;
                processToSave.Steps = [];
                await actions.saveOnServer(projectContextRepository, processToSave, (errors) => {
                    this.props.processErrorActions.setErrors(errors, selectors);
                    // The save action will give us errors only, clear any warnings.
                    this.props.processWarningActions.clearWarnings();
                }, () => {
                    this.props.processErrorActions.clearErrors();
                    this.props.processWarningActions.clearWarnings();
                });
                if (this.redirectToList) {
                    this.redirectToList();
                }
                return true;
            }, () => <DeleteStepsCallout />, { permission: [] }, false),
        ];
        const convertedItems: ConvertedOverflowMenuItems = OverflowMenuConverterVNext.convertAll(overflowMenuItems);
        const intent = intentFromFilter(this.props.processQueryStringContext.state.queryFilter);
        const filter = this.props.processQueryStringContext.state.queryFilter;
        let layout: React.ReactNode = null;
        if ((intent === ProcessPageIntent.CreateNewAction || intent === ProcessPageIntent.CreateNewChildAction) && filter.new && filter.actionType) {
            layout = <Loading busy={true}/>;
        }
        else if (intent === ProcessPageIntent.ChooseStepTemplates && filter.stepTemplates) {
            layout = this.renderActionTemplateSelectionPage();
        }
        else if (intent === ProcessPageIntent.ChooseChildStepTemplates && filter.childStepTemplates && filter.parentStepId) {
            layout = this.renderActionTemplateSelectionPage(filter.parentStepId);
        }
        else if (intent === ProcessPageIntent.ViewAction && filter.actionId) {
            if (filter.actionId === this.state.actionData?.action.Id) {
                layout = this.renderProcessStepDetailsPage(intent);
            }
            else {
                layout = loadingIndicatorForStep;
            }
        }
        else if (intent === ProcessPageIntent.ViewParentStep && filter.parentStepId) {
            if (filter.parentStepId === this.state.parentStepData?.step.Id) {
                layout = this.renderProcessStepDetailsPage(intent);
            }
            else {
                layout = loadingIndicatorForStep;
            }
        }
        else {
            logger.info("Failed to determine layout for intent {intent}. Assuming no results.", { intent });
            layout = (<>
                    <Section>
                        <Callout type={"information"} title={"Step could not be found"}>
                            {<span>The requested step could not be found on this branch. Please select from the available steps or review your current branch selection.</span>}
                        </Callout>
                    </Section>
                    <NoResults />
                </>);
        }
        const innerLayout = (<ProcessSidebarLayout render={() => {
                const animationReloadKey = !!filter.actionId ? filter.actionId : filter.parentStepId;
                return (<Fade in={true} mountOnEnter unmountOnExit key={animationReloadKey}>
                            <div>{layout}</div>
                        </Fade>);
            }}/>);
        return (<ProcessContextFormPaperLayout model={selectors.getModel()} cleanModel={selectors.getCleanModel()} busy={this.props.busy} doBusyTask={this.props.doBusyTask} errors={this.props.errors} // We have to pass errors here for our ConfirmNavigate to function correctly.
         saveButtonLabel={actionLabel} onSaveClick={noOp} overflowActions={convertedItems.menuItems} confirmNavigateSaveLabel={`${actionLabel} changes...`} disableDirtyFormChecking={this.state.disableDirtyFormChecking} hideAddStepButton={intent === ProcessPageIntent.ChooseStepTemplates}>
                {convertedItems.dialogs}
                <ProcessesMergedDialog open={selectors.isMerging() && !selectors.isMergeDialogClosed()} onClose={actions.mergeDialogClosed} onDiscard={actions.discardedChanges} onMerge={actions.mergedProcess} onAcceptClientChanges={actions.acceptedClientChanges}/>
                {selectors.isProcessMerged() && (<Callout title="Action Required" type={"warning"}>
                        This process has been merged with the server process but has not been saved. Please review the process before saving.
                    </Callout>)}
                {innerLayout}
            </ProcessContextFormPaperLayout>);
    }
    private redirectToList = () => {
        const process = this.props.processContext.state.model.process;
        const redirectTo = links.editBlueprintPage.generateUrl({ blueprintId: process?.Id ?? "", spaceId: process?.SpaceId ?? "" });
        this.setState({ redirectTo });
    };
    private canSafelyNavigateToFilter(filter: ProcessFilter): boolean {
        // Check if this action actually exists in our context (the user may have refreshed the screen and not yet saved, so our filter and context are out of sync).
        // The filter will tell us if we're looking at an action or parentStep.
        const { actionId, parentStepId } = filter;
        if (actionId && this.props.processContext.selectors.hasValidProcess() && !this.props.processContext.selectors.tryGetActionById(actionId)) {
            return false;
        }
        if (parentStepId && this.props.processContext.selectors.hasValidProcess() && !this.props.processContext.selectors.tryGetStepById(parentStepId)) {
            return false;
        }
        return true;
    }
    private needToRedirectToStepBasedOnName(filter: ProcessFilter, currentStepName: string, currentActionName: string, onFoundAction: (actionId: string) => void, onFoundParentStep: (parentStepId: string) => void): boolean {
        // The filter will tell us if we're looking at an action or parentStep.
        const { actionId, parentStepId } = filter;
        if (actionId && currentActionName && this.props.processContext.selectors.hasValidProcess()) {
            const shouldRedirectToAction = this.props.processContext.selectors.tryGetActionByName(currentActionName);
            if (shouldRedirectToAction) {
                onFoundAction(shouldRedirectToAction.Id);
                return true;
            }
        }
        if (parentStepId && currentStepName && this.props.processContext.selectors.hasValidProcess()) {
            const shouldRedirectToParentStep = this.props.processContext.selectors.tryGetStepByName(currentStepName);
            if (shouldRedirectToParentStep) {
                onFoundParentStep(shouldRedirectToParentStep.Id);
                return true;
            }
        }
        return false;
    }
    // @Cleanup: markse - This was common logic we were previously applying at the point of saving a single action. Flagging for later review.
    // For multi-step editing, we now loop over all actions and apply to each. Now sure how I feel about this happening right at the point of save.
    private applyCommonLogicToProcessResource(process: IProcessResource, getPluginForAction: (actionId: string) => ActionPlugin) {
        const availableWorkerPools = loadAvailableWorkerPools(this.props.lookups.workerPoolsSummary);
        process.Steps.forEach((step) => {
            step.Actions.forEach((action) => {
                const plugin = getPluginForAction(action.Id);
                const runOn = whereConfiguredToRun(!!step.Properties["Octopus.Action.TargetRoles"], action, availableWorkerPools, plugin);
                if (runOn) {
                    if (!isRunOnServerOrWorkerPool(runOn)) {
                        action.Container = { FeedId: null, Image: null, GitUrl: null, Dockerfile: null };
                    }
                    else {
                        if (runOn.executionLocation === ExecutionLocation.OctopusServer || runOn.executionLocation === ExecutionLocation.WorkerPool) {
                            step.Properties["Octopus.Action.TargetRoles"] = "";
                        }
                        if (runOn.executionLocation !== ExecutionLocation.WorkerPool && runOn.executionLocation !== ExecutionLocation.WorkerPoolForRoles) {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            action.WorkerPoolId = null!;
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            action.WorkerPoolVariable = null!;
                        }
                        action.Container = runOn.container;
                    }
                }
                if (!action.Name || action.Name.length === 0) {
                    const primaryPackage = GetPrimaryPackageReference(action.Packages);
                    if (primaryPackage) {
                        action.Name = primaryPackage.PackageId;
                    }
                }
            });
        });
    }
    private loadActionData = async (actionId: string | undefined, actionType: string | undefined, templateId: string | undefined, intent: ProcessPageIntent, targetTags?: string[]): Promise<ProcessStepActionData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return null;
        }
        let plugin: ActionPlugin | null = null;
        if (actionId) {
            plugin = this.props.processContext.selectors.getActionPlugin(actionId);
        }
        else if (actionType) {
            plugin = await pluginRegistry.getAction(actionType);
        }
        if (!plugin) {
            throw new Error("Failed to load plugin.");
        }
        const { feeds, actionTemplates, projectContext } = this.props;
        const processContextSelectors = this.props.processContext.selectors;
        const isNew = isIntentToCreateNew(intent);
        let result: AssembledAction;
        if (isNew) {
            if (!actionType) {
                throw Error("No action type was provided");
            }
            result = await assembleNewAction(actionType, plugin, actionTemplates, templateId, feeds, projectContext.state.model, processContextSelectors.getProcessType(), targetTags);
        }
        else {
            if (!actionId) {
                throw Error("Missing action id");
            }
            result = assembleExistingAction(actionId, processContextSelectors, actionTemplates, feeds);
        }
        PageTitleHelper.setPageTitle(result.pageTitle);
        if (!result.action) {
            logger.error("Failed to load action data", { result });
            throw new Error("Expecting an action to exist.");
        }
        const stepLookups = await this.loadLookupData(result.action);
        const stepOther: ProcessStepActionState = {
            actionTypeName: result.actionTypeName,
            pageTitle: result.pageTitle,
            isBuiltInWorkerEnabled: this.props.isBuiltInWorkerEnabled,
            environmentOption: (result.action.Environments || []).length > 0 ? EnvironmentOption.Include : (result.action.ExcludedEnvironments || []).length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All,
            runOn: result.action && plugin ? whereConfiguredToRun(!!result.step.Properties["Octopus.Action.TargetRoles"], result.action, stepLookups.availableWorkerPools, plugin) : null,
        };
        return { stepLookups, stepOther, step: result.step, action: result.action };
    };
    private loadParentStepData = async (): Promise<ProcessParentStepData | null> => {
        if (!this.hasLoadedContexts() || !this.hasLoadedNecessaryLookupData()) {
            return null;
        }
        const { parentStepId } = this.props.processQueryStringContext.state.queryFilter;
        if (!parentStepId) {
            throw new Error("Failed to find parentStepId");
        }
        const processContextSelectors = this.props.processContext.selectors;
        const result = assembleParentStep(parentStepId, processContextSelectors);
        PageTitleHelper.setPageTitle(result.pageTitle);
        const stepLookups = await this.loadLookupData(null);
        const stepNumber = this.props.processContext.selectors.getStepNumber(result.step.Id);
        const isFirstStep = this.props.processContext.selectors.isFirstStep(result.step.Id);
        return { stepNumber: stepNumber.toString(), step: result.step, machineRoles: stepLookups.machineRoles, isFirstStep };
    };
    private hasLoadedContexts(): boolean {
        const processContextHasLoaded = this.props.processContext.selectors.hasValidProcess();
        const projectContextHasLoaded = !!this.props.projectContext.state.model;
        const processQueryStringContextHasLoaded = !!this.props.processQueryStringContext.state.queryFilter;
        return processContextHasLoaded && projectContextHasLoaded && processQueryStringContextHasLoaded;
    }
    private hasLoadedNecessaryLookupData(): boolean {
        const { actionTemplatesLoaded, feedsLoaded } = this.props;
        return actionTemplatesLoaded && feedsLoaded;
    }
    private async loadLookupData(action: StoredAction | null): Promise<ProcessStepLookupState> {
        let actionTemplate;
        if ((this.props.processQueryStringContext.state.queryFilter.templateId || (action && action.Properties["Octopus.Action.Template.Id"])) && !hasPermission(Permission.ActionTemplateView)) {
            actionTemplate = { type: "No Permission" } as const;
        }
        else if (this.props.processQueryStringContext.state.queryFilter.templateId) {
            actionTemplate = await repository.ActionTemplates.get(this.props.processQueryStringContext.state.queryFilter.templateId);
        }
        else if (action && action.Properties["Octopus.Action.Template.Id"]) {
            actionTemplate = await repository.ActionTemplates.get(action.Properties["Octopus.Action.Template.Id"].toString());
        }
        else {
            actionTemplate = null;
        }
        const lookups: ProcessStepLookupState = {
            environments: Object.values(this.props.lookups.environmentsById),
            machineRoles: this.props.lookups.machineRoles,
            availableWorkerPools: loadAvailableWorkerPools(this.props.lookups.workerPoolsSummary),
            tagIndex: this.props.lookups.tagIndex,
            actionTemplate,
            channels: this.props.lookups.channelsById ? Object.values(this.props.lookups.channelsById) : [],
            projectVariables: undefined,
            libraryVariableSets: undefined,
            projectTriggers: this.props.lookups.projectTriggers,
            userOnboarding: this.props.lookups.userOnboarding,
        };
        return lookups;
    }
    private renderProcessStepDetailsPage = (intent: ProcessPageIntent) => {
        const { processContext, processErrorSelectors } = this.props;
        const processType = processContext.selectors.getProcessType();
        if (this.state.actionData && this.state.actionData.stepOther && this.state.actionData.stepLookups && this.state.actionData.action) {
            const actionId = this.state.actionData.action.Id;
            const { selectors } = this.props.processContext;
            const isNew = isIntentToCreateNew(intent) || selectors.isNewAction(actionId);
            const errors = processErrorSelectors.getActionFieldErrors(actionId, selectors);
            // TODO: Review this pattern with frontend. Do not copy.
            // From hereon, we reference action/step via the selectors, and actionData.action is now essentially stale / out of sync.
            // This is an unfortunately side-effect of managing both CreateNewAction and ViewAction under the one layout. There is a
            // handoff between the two intentions, so we use actionData as a middle-man/dumping-ground until we have the necessary information
            // in context.
            const action = selectors.getActionById(actionId);
            const cleanAction = selectors.tryGetCleanActionById(actionId);
            const step = selectors.getStepById(action.ParentId);
            return (<ProcessActionDetails doBusyTask={this.props.doBusyTask} step={step} busy={this.props.busy} action={action} cleanAction={cleanAction} setCurrentActionName={(actionName) => {
                    this.setState({ currentActionName: actionName });
                }} setCurrentStepName={(stepName) => {
                    this.setState({ currentStepName: stepName });
                }} stepLookups={this.state.actionData.stepLookups} stepOther={this.state.actionData.stepOther} actionTemplates={this.props.actionTemplates} processType={processType} isNew={isNew} errors={errors} refreshStepLookups={async () => {
                    await this.props.doBusyTask(async () => {
                        // This line is required to ensure that all other setState()
                        // calls have been completed before we load lookup data.
                        await new Promise((resolve) => setTimeout(resolve));
                        const stepLookups = await this.loadLookupData(selectors.getActionById(actionId));
                        const actionData = this.state.actionData;
                        if (actionData) {
                            actionData.stepLookups = stepLookups;
                            this.setState({ actionData });
                        }
                    });
                }} projectTriggers={this.props.lookups.projectTriggers} userOnboarding={this.props.lookups.userOnboarding} isGuidedSetup={false}/>);
        }
        else if (this.state.parentStepData && this.state.parentStepData.step) {
            const { selectors } = this.props.processContext;
            const stepId = this.state.parentStepData.step.Id;
            const step = selectors.getStepById(stepId);
            const cleanStep = selectors.tryGetCleanStepById(stepId);
            const isNew = isIntentToCreateNew(intent);
            const errors = processErrorSelectors.getStepFieldErrors(stepId);
            return (<ProcessParentStepDetails stepNumber={this.state.parentStepData.stepNumber} step={step} cleanStep={cleanStep} currentStepName={this.state.currentStepName} setCurrentStepName={(stepName) => {
                    this.setState({ currentStepName: stepName });
                }} machineRoles={this.state.parentStepData.machineRoles} isFirstStep={this.state.parentStepData.isFirstStep} isNew={isNew} errors={errors}/>);
        }
        return <ProcessPaperLayout processType={processType} busy={true}/>;
    };
    private renderActionTemplateSelectionPage = (parentStepId?: string) => {
        const { busy, errors } = this.props;
        const processType = this.props.processContext.selectors.getProcessType();
        return <EnhancedActionTemplateSelectionPageForBlueprint processType={processType} parentStepId={parentStepId} busy={busy} errors={errors}/>;
    };
    static displayName = "ProcessStepsLayoutForBlueprint";
}
const EnhancedProcessStepsLayoutForBlueprint: React.FC<ProcessStepsLayoutForBlueprintProps> = (props) => {
    const projectContext = useProjectContext();
    const processContext = useProcessContext();
    const processErrorActions = useProcessErrorActions();
    const processErrorSelectors = useProcessErrorSelectors();
    const processWarningActions = useProcessWarningActions();
    const processQueryStringContext = useProcessQueryStringContext();
    const maybeLoadedActionTemplates = useMaybeLoadedActionTemplatesFromContext();
    const actionTemplatesLoaded = maybeLoadedActionTemplates !== "NotLoaded";
    const actionTemplates = maybeLoadedActionTemplates === "NotLoaded" ? [] : maybeLoadedActionTemplates;
    const maybeFeeds = useMaybeFeedsFromContext();
    const feedsLoaded = maybeFeeds !== "NotLoaded";
    const feeds = maybeFeeds === "NotLoaded" ? [] : maybeFeeds;
    return (<ProcessStepsLayoutForBlueprint feeds={feeds} feedsLoaded={feedsLoaded} actionTemplates={actionTemplates} actionTemplatesLoaded={actionTemplatesLoaded} projectContext={projectContext} processContext={processContext} processErrorActions={processErrorActions} processWarningActions={processWarningActions} processQueryStringContext={processQueryStringContext} processErrorSelectors={processErrorSelectors} {...props}/>);
};
EnhancedProcessStepsLayoutForBlueprint.displayName = "EnhancedProcessStepsLayoutForBlueprint"
const WrappedActionTemplateSelectionPageForBlueprint = withProjectDeploymentProcess(ActionTemplateSelectorForBlueprint);
export const EnhancedActionTemplateSelectionPageForBlueprint: React.FC<Omit<ActionTemplateSelectorProps, "processId" | "processContext" | "projectContext" | "processQueryStringContext"> & {
    processType: ProcessType;
}> = ({ children, ...rest }) => {
    return <WrappedActionTemplateSelectionPageForBlueprint {...rest}>{children}</WrappedActionTemplateSelectionPageForBlueprint>;
};
EnhancedActionTemplateSelectionPageForBlueprint.displayName = "EnhancedActionTemplateSelectionPageForBlueprint"
export default EnhancedProcessStepsLayoutForBlueprint;
