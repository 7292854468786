/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, Checkbox } from "@octopusdeploy/design-system-components";
import type { ExternalSecurityGroupProviderResource, NamedReferenceItem } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { SimplePagingList } from "~/components/PagingList/SimplePagingList";
import { Text } from "~/components/form";
import { repository } from "../../../../clientInstance";
import type { DataBaseComponentState } from "../../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../../components/DataBaseComponent/DataBaseComponent";
class NamedReferenceList extends SimplePagingList<NamedReferenceItem> {
}
type GroupSelectionState = {
    [key: string]: boolean;
};
interface AddExternalGroupDialogContentProps {
    lookupUri: string;
    onChange(selected: NamedReferenceItem[]): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}
interface AddExternalGroupDialogContentState {
    selected: GroupSelectionState;
    searchText?: string;
    foundGroups?: NamedReferenceItem[];
}
class AddExternalGroupDialogContent extends React.Component<AddExternalGroupDialogContentProps, AddExternalGroupDialogContentState> {
    constructor(props: AddExternalGroupDialogContentProps) {
        super(props);
        this.state = {
            searchText: null!,
            selected: {},
            foundGroups: null!,
        };
    }
    handleSearch = async () => {
        await this.props.doBusyTask(async () => {
            const results = await repository.ExternalSecurityGroups.search(this.props.lookupUri, this.state.searchText!);
            this.setState({ foundGroups: results, selected: {} });
        });
    };
    renderRow = (group: NamedReferenceItem) => {
        const label = group.DisplayIdAndName ? `${group.DisplayName} (${group.Id})` : group.DisplayName;
        return <Checkbox value={this.state.selected[group.Id]} onChange={this.handleSelected(group.Id)} label={label}/>;
    };
    handleSelected = (groupId: string) => {
        return (e: {}) => {
            const newState = { selected: { ...this.state.selected, [groupId]: !this.state.selected[groupId] } };
            this.setState(newState);
            this.props.onChange(this.state.foundGroups!.filter((g) => !!newState.selected[g.Id]));
        };
    };
    render() {
        return (<div>
                <div>
                    <Text id="search" value={this.state.searchText!} onChange={(val) => this.setState({ searchText: val })}/>
                    <ActionButton label="Search" onClick={this.handleSearch}/>
                </div>
                {this.state.foundGroups ? <NamedReferenceList items={this.state.foundGroups} onRow={this.renderRow}/> : <span>Search for and select an external group</span>}
            </div>);
    }
    static displayName = "AddExternalGroupDialogContent";
}
interface AddExternalGroupDialogButtonProps {
    securityGroupProvider: ExternalSecurityGroupProviderResource;
    onSelected(items: NamedReferenceItem[]): void;
}
interface AddExternalGroupDialogButtonState extends DataBaseComponentState {
    selected: NamedReferenceItem[];
}
class AddExternalGroup extends DataBaseComponent<AddExternalGroupDialogButtonProps, AddExternalGroupDialogButtonState> {
    constructor(props: AddExternalGroupDialogButtonProps) {
        super(props);
        this.state = {
            selected: [],
        };
    }
    handleOkClick = () => {
        this.props.onSelected(this.state.selected);
        return true;
    };
    render() {
        const provider = this.props.securityGroupProvider;
        return (<OkDialogLayout title={`Add ${provider.Name} Group`} okButtonLabel="Add" busy={this.state.busy} errors={this.errors} onOkClick={this.handleOkClick}>
                <AddExternalGroupDialogContent onChange={(selected: NamedReferenceItem[]) => this.setState({ selected })} doBusyTask={this.doBusyTask} lookupUri={provider.LookupUri}/>
            </OkDialogLayout>);
    }
    static displayName = "AddExternalGroup";
}
export default AddExternalGroup;
