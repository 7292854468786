/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { TenantResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { ResetLabelStrategy } from "~/components/LabelStrategy/LabelStrategy";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import { TenantTagMultiSelect } from "~/components/MultiSelect/TenantTagMultiSelect";
import TenantFilterButton from "~/components/TenantTagDesignDialog";
interface AdvancedTenantSelectorProps {
    tagSets?: TagSetResource[];
    selectedTenantIds: string[];
    showTenants: boolean;
    tenants?: TenantResource[];
    selectedTenantTags: string[];
    doBusyTask: DoBusyTask;
    showPreviewButton: boolean;
    emptyFilterMeansAllTenants?: boolean;
    onChange(tenantIds: string[], tenantTags: string[]): void;
}
const AdvancedTenantSelector: React.SFC<AdvancedTenantSelectorProps> = (props: AdvancedTenantSelectorProps) => {
    return (<div>
            {props.showTenants && <TenantMultiSelect value={props.selectedTenantIds} items={props.tenants!} onChange={(tenantIds) => props.onChange(tenantIds, props.selectedTenantTags)}/>}
            <TenantTagMultiSelect value={props.selectedTenantTags} items={props.tagSets} onChange={(includedTenantTags) => props.onChange(props.selectedTenantIds, includedTenantTags)} doBusyTask={props.doBusyTask}/>
            {props.showPreviewButton && (<ResetLabelStrategy>
                    <TenantFilterButton selectedTags={props.selectedTenantTags} selectedTenants={props.selectedTenantIds} showTenants={props.showTenants} availableTenants={props.tenants?.map((x) => x.Id)} onUpdate={(selectedTenants, selectedTags) => props.onChange(selectedTenants, selectedTags)} emptyFilterMeansAllTenants={props.emptyFilterMeansAllTenants}/>
                </ResetLabelStrategy>)}
        </div>);
};
AdvancedTenantSelector.displayName = "AdvancedTenantSelector"
interface AdvancedTenantsAndTenantTagsSelectorProps {
    selectedTenantIds: string[];
    tenants: TenantResource[];
    selectedTenantTags: string[];
    doBusyTask: DoBusyTask;
    showPreviewButton: boolean;
    onChange(tenantIds: string[], tenantTags: string[]): void;
}
export const AdvancedTenantsAndTenantTagsSelector: React.SFC<AdvancedTenantsAndTenantTagsSelectorProps> = (props: AdvancedTenantsAndTenantTagsSelectorProps) => {
    return (<AdvancedTenantSelector selectedTenantIds={props.selectedTenantIds} showTenants={true} tenants={props.tenants} selectedTenantTags={props.selectedTenantTags} onChange={props.onChange} doBusyTask={props.doBusyTask} showPreviewButton={props.showPreviewButton}/>);
};
AdvancedTenantsAndTenantTagsSelector.displayName = "AdvancedTenantsAndTenantTagsSelector"
interface AdvancedTenantTagsSelectorProps {
    selectedTenantTags: string[];
    tagSets?: TagSetResource[];
    emptyFilterMeansAllTenants?: boolean;
    doBusyTask: DoBusyTask;
    showPreviewButton: boolean;
    onChange(tenantTags: string[]): void;
}
export const AdvancedTenantTagsSelector: React.SFC<AdvancedTenantTagsSelectorProps> = (props: AdvancedTenantTagsSelectorProps) => {
    return (<AdvancedTenantSelector tagSets={props.tagSets} selectedTenantIds={[]} showTenants={false} tenants={undefined} // Do NOT set this as an empty array, or stuff stops working :)
     selectedTenantTags={props.selectedTenantTags} onChange={(tenants, tags) => props.onChange(tags)} doBusyTask={props.doBusyTask} showPreviewButton={props.showPreviewButton} emptyFilterMeansAllTenants={props.emptyFilterMeansAllTenants}/>);
};
AdvancedTenantTagsSelector.displayName = "AdvancedTenantTagsSelector"
