import { css } from "@emotion/css";
import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client/src/repositories/serviceAccountOidcIdentitiesRepository";
import * as React from "react";
import DataBaseComponent from "~/components/DataBaseComponent";
import DeleteDialog from "~/components/DialogLayout/DeleteDialog";
type DeleteServiceAccountOidcIdentityDialogProps = {
    oidcIdentity: ServiceAccountOidcIdentityResource;
    onDelete: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => void;
};
export class DeleteServiceAccountOidcIdentityDialog extends DataBaseComponent<DeleteServiceAccountOidcIdentityDialogProps> {
    constructor(props: DeleteServiceAccountOidcIdentityDialogProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        const styles = {
            message: css({
                overflowWrap: "anywhere",
            }),
        };
        return (<DeleteDialog open={this.props.oidcIdentity != undefined} busy={this.state.busy} onClose={this.props.onCancel} errors={this.errors} onDeleteClick={() => this.doBusyTask(() => this.props.onDelete(this.props.oidcIdentity))} title="Delete OIDC Identity">
                <div className={styles.message}>
                    Are you sure you want to delete OIDC Identity <b>{this.props?.oidcIdentity.Name}</b>? This action cannot be undone.
                </div>
            </DeleteDialog>);
    }
    static displayName = "DeleteServiceAccountOidcIdentityDialog";
}
