import type { TagResource, MachineTenantTagSummaryResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Navigate } from "~/components/Navigation/Navigate";
import Tag from "~/components/Tag";
import styles from "../style.module.less";
interface TagSetContentRowProps {
    spaceId: string;
    tag: TagResource;
    tenantTagSummaries: MachineTenantTagSummaryResource;
}
export const TagSetContentRow = ({ spaceId, tag, tenantTagSummaries }: TagSetContentRowProps) => {
    const totalMachines = (tenantTagSummaries && tenantTagSummaries[tag.Id]) || 0;
    return (<>
            <div className={styles.tenantTagRowsContainer} key={tag.Name}>
                <div className={styles.tenantTagName}>
                    <Navigate render={(history) => (<Tag tagName={tag.Name} tagColor={tag.Color} key={tag.Name} description={tag.Description} onClick={() => history.navigate(links.deploymentTargetsPage.generateUrl({ spaceId }, { tenantTags: [tag.CanonicalTagName] }))}/>)}/>
                </div>
                <div className={styles.tenantTagMachinesCount}>{totalMachines.toLocaleString()}</div>
            </div>
        </>);
};
