import { css } from "@emotion/css";
import { ActionButtonType, EmptyStateInsightsIllustration, NavigationButton, NavigationButtonType, Callout, LinearProgress } from "@octopusdeploy/design-system-components";
import { OctoLink } from "@octopusdeploy/utilities";
import React, { useEffect, useState } from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import BusyFromPromise from "~/components/BusyFromPromise/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { Level1EmptyStateLayout } from "~/components/GettingStarted/Level1EmptyStateLayout";
type ReportUpsellInternalProps = {
    showLicenseExpired: boolean;
    busy: boolean;
};
function ReportUpsellInternal({ showLicenseExpired, busy }: ReportUpsellInternalProps) {
    const dispatchAction = useAnalyticSimpleActionDispatch();
    const [requestSent, setRequestSent] = useState(false);
    const onRequestTrial = async () => {
        await repository.InsightsReports.requestTrial();
        dispatchAction("Request for Insights trial submitted");
        setRequestSent(true);
        // Have to return here to close dialog
        return true;
    };
    const trackOpeningRequestDialog = () => dispatchAction("Request Insights trial dialog opened");
    useEffect(() => {
        dispatchAction("View Insights Reports Upsell page");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const intro = (<div className={introStyles}>
            <LinearProgress show={busy} variant={"indeterminate"}/>
            {requestSent && <RequestSentCallout />}
            {showLicenseExpired && <LicenseExpiredCallout />}
            <span>
                Access <strong>DORA metrics</strong> across your teams at a portfolio level to help quantify your performance across projects, environments or tenants. See what you need to do to improve next, increasing tempo and stability.
            </span>
            <span>Space-level DevOps Insights is a part of the Enterprise tier.</span>
            <span>Request a trial of this feature from our Customer Success Team.</span>
        </div>);
    const requestTrialButton = (<OpenDialogButton label="Request trial" type={ActionButtonType.Primary} onClick={trackOpeningRequestDialog}>
            <RequestInsightsTrialDialog onRequestTrial={onRequestTrial}/>
        </OpenDialogButton>);
    const learnMoreLink = <NavigationButton href={OctoLink.Create("InsightsReports")} external label="Learn more" type={NavigationButtonType.Ternary}/>;
    return <Level1EmptyStateLayout title={"Reduce uncertainty, save time"} intro={intro} image={<EmptyStateInsightsIllustration />} actionButtonsAndLinks={[requestTrialButton, learnMoreLink]}/>;
}
type ReportUpsellProps = {};
interface ReportUpsellState {
    hasFeatureRecentlyExpired: boolean;
}
export class ReportUpsell extends DataBaseComponent<ReportUpsellProps, ReportUpsellState> {
    constructor(props: ReportUpsellProps) {
        super(props);
        this.state = { hasFeatureRecentlyExpired: false, busy: undefined };
    }
    componentDidMount() {
        return this.doBusyTask(async () => {
            const licenseCheck = await repository.InsightsReports.getInsightsLicenseCheck();
            this.setState({ hasFeatureRecentlyExpired: licenseCheck.HasFeatureRecentlyExpired });
        });
    }
    render() {
        return <BusyFromPromise promise={this.state.busy}>{(busy) => <ReportUpsellInternal showLicenseExpired={this.state.hasFeatureRecentlyExpired} busy={busy}/>}</BusyFromPromise>;
    }
    static displayName = "ReportUpsell";
}
function RequestSentCallout() {
    return (<Callout title="Your request for access has been sent" type={"success"}>
            <p>The Customer Success Team will reach out to you with details of the trial.</p>
        </Callout>);
}
function LicenseExpiredCallout() {
    return (<Callout title="License expired" type={"information"}>
            <p>This feature is limited to the Enterprise tier.</p>
        </Callout>);
}
const introStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
});
interface RequestInsightsTrialDialogProps {
    onRequestTrial: () => Promise<boolean>;
}
function RequestInsightsTrialDialog({ onRequestTrial }: RequestInsightsTrialDialogProps) {
    return (<OkDialogLayout title="Request Trial" okButtonLabel="Continue" onOkClick={onRequestTrial}>
            <p>This will send a message to our Customer Success Team, who will contact you with the details of the trial.</p>
        </OkDialogLayout>);
}
