import { CustomMenu, useMenuState, MenuItemInternalLink, ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { DeploymentStepResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import type * as H from "history";
import { useEffect } from "react";
import * as React from "react";
import { addSampleStepsToProcessResource, bashStep, powerShellStep } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProcessErrorActions } from "~/areas/projects/components/Process/Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessWarningActions } from "~/areas/projects/components/Process/Contexts/ProcessWarnings/ProcessWarningsContext";
import { useProjectContext } from "~/areas/projects/context";
import { DropDownIcon } from "~/components/Button/DropDownIcon/DropDownIcon";
import { PermissionCheck } from "~/components/PermissionCheck";
interface SampleProcessButtonProps {
    href: LinkHref;
    location: H.Location;
    history: H.History;
}
enum SampleStepType {
    Bash,
    PowerShell
}
function generateSampleStep(stepType: SampleStepType): DeploymentStepResource {
    switch (stepType) {
        case SampleStepType.Bash:
            return bashStep();
        case SampleStepType.PowerShell:
            return powerShellStep();
        default:
            exhaustiveCheck(stepType, "Unsupported step type");
    }
}
function SampleProcessButton({ href, location, history }: SampleProcessButtonProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const processContext = useProcessContext();
    const { state: { projectContextRepository }, } = useProjectContext();
    const errorActions = useProcessErrorActions();
    const warningActions = useProcessWarningActions();
    const saveSampleStep = async (stepType: SampleStepType) => {
        const sampleStep = generateSampleStep(stepType);
        const sampleProcess = addSampleStepsToProcessResource(processContext.selectors.getProcessResource(), [sampleStep]);
        await processContext.actions.saveOnServer(projectContextRepository, sampleProcess, (errors) => {
            errorActions.setErrors(errors, processContext.selectors);
            warningActions.clearWarnings();
        }, () => {
            errorActions.clearErrors();
            warningActions.clearWarnings();
        });
    };
    useEffect(() => {
        const loadSampleProcessAutomatically = new URLSearchParams(location.search).get("loadSampleProcess");
        if (loadSampleProcessAutomatically) {
            saveSampleStep(loadSampleProcessAutomatically === "ps" ? SampleStepType.PowerShell : SampleStepType.Bash);
            const location = { ...history, search: "" };
            history.replace(location);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<PermissionCheck permission={Permission.ProcessEdit}>
            <ActionButton type={ActionButtonType.Secondary} icon={<DropDownIcon />} iconPosition="right" label="Use sample" onClick={openMenu} menuButtonAttributes={buttonAriaAttributes}/>
            <CustomMenu accessibleName={"Deploy release"} menuState={menuState}>
                <MenuItemInternalLink path={href} label={"Hello world (PowerShell)"} onClick={() => saveSampleStep(SampleStepType.PowerShell)}></MenuItemInternalLink>
                <MenuItemInternalLink path={href} label={"Hello world (Bash)"} onClick={() => saveSampleStep(SampleStepType.Bash)}></MenuItemInternalLink>
            </CustomMenu>
        </PermissionCheck>);
}
export default SampleProcessButton;
