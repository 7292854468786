/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css } from "@emotion/css";
import SvgRoleIcon from "@material-ui/icons/LocalOffer";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import * as React from "react";
import CopyToClipboardButton from "../CopyToClipboardButton";
import { useOctopusTheme } from "../Theme";
import { useThemePaletteType } from "../Theme/useThemePaletteType";
import Chip from "./Chip";
import { IconStyledForChip } from "./IconStyledForChips";
import type { CommonChipProps } from "./types";
type CopyToClipboardChipProps = {
    text: string;
} & CommonChipProps;
export function CopyToClipboardChip(props: CopyToClipboardChipProps): React.ReactElement | null {
    const theme = useOctopusTheme();
    const palette = useThemePaletteType();
    const isDarkTheme = palette === "dark";
    const styles = {
        chipContainer: css({
            ".MuiChip-root": {
                cursor: "pointer",
            },
            ".MuiChip-label": {
                paddingRight: "2px",
                borderTopRightRadius: "24px",
                borderBottomRightRadius: "24px",
            },
        }),
        copyToClipboardContentContainer: css({
            display: "flex",
            alignItems: "center",
        }),
        copyToClipboardButton: css({
            marginLeft: "12px",
            width: "20px",
            height: "20px",
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isDarkTheme ? colorScales.grey["300"] : colorScales.grey["600"],
            color: theme.chipBackground,
        }),
    };
    return (<div className={styles.chipContainer}>
            <CopyToClipboardButton value={props.text} showHoverTooltip={true}>
                <Chip noTooltip={true} icon={<IconStyledForChip iconComponent={SvgRoleIcon}/>} {...props}>
                    <div className={cn(styles.copyToClipboardContentContainer)}>
                        {props.text}
                        <div className={cn(styles.copyToClipboardButton)}>
                            <em className={"fa fa-clone"} aria-hidden="true"/>
                        </div>
                    </div>
                </Chip>
            </CopyToClipboardButton>
        </div>);
}
