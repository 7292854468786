import type { ReactNode } from "react";
import React from "react";
import useRequiredContext from "../../hooks/useRequiredContext";
type FieldError = string;
type FieldName = string;
type FieldErrors = Record<FieldName, FieldError>;
type FieldErrorSelectors = {
    getFieldError: (name: FieldName) => FieldError | undefined;
    getAllFieldErrors: () => FieldErrors;
};
const FieldErrorContext = React.createContext<FieldErrors | undefined>(undefined);
FieldErrorContext.displayName = "FieldErrorContext";
const FieldErrorSelectorsContext = React.createContext<FieldErrorSelectors | undefined>(undefined);
FieldErrorSelectorsContext.displayName = "FieldErrorSelectorContext";
type FieldErrorProviderProps = {
    fieldErrors: FieldErrors | undefined;
};
export const FieldErrorContextProvider: React.FC<FieldErrorProviderProps> = ({ fieldErrors = {}, children }) => {
    const selectors: FieldErrorSelectors = React.useMemo(() => ({
        getFieldError: (name) => {
            if (Object.prototype.hasOwnProperty.call(fieldErrors, name)) {
                return fieldErrors[name];
            }
            const found = Object.keys(fieldErrors).find((k) => k.toLowerCase() === name.toLowerCase());
            if (found) {
                return fieldErrors[found];
            }
            const foundPartialMatch = Object.keys(fieldErrors).find((k) => k.endsWith("." + name));
            if (foundPartialMatch) {
                return fieldErrors[foundPartialMatch];
            }
        },
        getAllFieldErrors: () => fieldErrors ?? {},
    }), [fieldErrors]);
    return (<FieldErrorContext.Provider value={fieldErrors}>
            <FieldErrorSelectorsContext.Provider value={selectors}>{children}</FieldErrorSelectorsContext.Provider>
        </FieldErrorContext.Provider>);
};
FieldErrorContextProvider.displayName = "FieldErrorContextProvider"
export const useFieldErrors = () => {
    return useRequiredContext(FieldErrorSelectorsContext);
};
type FieldErrorConsumerProps = {
    children: (value: FieldErrors) => ReactNode;
};
export const FieldErrorConsumer: React.FC<FieldErrorConsumerProps> = (props) => {
    const { getAllFieldErrors } = useFieldErrors();
    const errors = getAllFieldErrors();
    return <React.Fragment>{props.children(errors)}</React.Fragment>;
};
FieldErrorConsumer.displayName = "FieldErrorConsumer"
