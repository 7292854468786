import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import image from "../../Images/Spaces.svg";
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
export const CommonConfigurationSpaceOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    With <ExternalLink href="spaces">spaces</ExternalLink>, you can partition your Octopus Server so that each of your teams can only access the projects, environments, and infrastructure they work with from the spaces they are
                    members of.
                </p>
                <ImageWithPlaceholder src={image} alt={"Spaces"}/>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Giving users access to a space"}>Users are given access to a space via a team. A team will have user roles scoped to a space therefore granting access to that space.</Note>
                <Note heading={"Space managers"}>Behind the scenes, a space managers team is created, and any users that are nominated to be a space manager, are put in that team.</Note>
                <Note>The space manager is the administrator for a space and is responsible for managing users and teams within the space and assigning permissions to them.</Note>
                <Note heading={"Switching between spaces"}>In the top left, we show what space you are in and it is here you can switch between spaces.</Note>
            </TermsText>
        </OverviewContainer>);
};
