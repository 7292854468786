import { Callout } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
interface SmtpNeedsConfigurationCalloutProps {
    configured: boolean;
    hasPermissions: boolean;
}
export const SmtpNeedsConfigurationCallout: React.FC<SmtpNeedsConfigurationCalloutProps> = ({ configured, hasPermissions }) => {
    if (configured) {
        return null;
    }
    const text = hasPermissions ? (<>
            You will need to <InternalLink to={links.smtpPage.generateUrl()}>configure SMTP</InternalLink> before emails can be sent.
        </>) : (<>
            SMTP needs to be configured in this instance before emails can be sent. Contact your Octopus Administrator for more information. <ExternalLink href="SMTPConfig">Learn More</ExternalLink>.
        </>);
    return (<Callout type={"warning"} title="SMTP is not configured">
            {text}
        </Callout>);
};
SmtpNeedsConfigurationCallout.displayName = "SmtpNeedsConfigurationCallout"
