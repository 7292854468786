/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ProgressionResource, ChannelProgressionResource, DashboardItemResource, TagSetResource, DashboardProjectResource, DashboardProjectGroupResource, DashboardTenantResource, LifecycleResource, ProjectResource, ReleaseProgressionResource, ReleaseResource, DashboardEnvironmentResource, } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import type { GetDeploymentFreezes } from "@octopusdeploy/octopus-server-client/dist/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import { keyBy, uniq } from "lodash";
import type { DataCube } from "./DataCube";
class ProgressionDataCube implements DataCube {
    data: ProgressionResource;
    nextAvailableDeployments: {
        [releaseId: string]: {
            [environmentId: string]: string[];
        };
    } = {};
    blockedReleases: string[] = [];
    tenantTagIndex: {
        [tenantId: string]: string[];
    } = {};
    lifecycleIndex: {
        [lifecycleId: string]: LifecycleResource;
    };
    tagSetIndex: {
        [canonicalTagName: string]: TagSetResource;
    } = {};
    channelIndex: {
        [channelId: string]: ChannelProgressionResource;
    } = {};
    releaseIndex: {
        [releaseId: string]: ReleaseResource;
    } = {};
    projectIndex: {
        [projectId: string]: DashboardProjectResource;
    } = {};
    projectGroupIndex: {
        [projectGroupId: string]: DashboardProjectGroupResource;
    } = {};
    tenantIndex: {
        [tenantId: string]: DashboardTenantResource;
    } = {};
    environmentIndex: {
        [environmentId: string]: DashboardEnvironmentResource;
    } = {};
    channelEnvironments: {
        [index: string]: string[];
    };
    missingVariableTenantsPromise: Promise<string[]> = Promise.resolve([]);
    deployments: DashboardItemResource[] = [];
    constructor(data: ProgressionResource, project: ProjectResource, deploymentFreezes: GetDeploymentFreezes[]) {
        this.data = data;
        this.channelIndex = this.getAllChannelsFromReleases(this.data.Releases);
        this.lifecycleIndex = {};
        this.releaseIndex = {};
        this.nextAvailableDeployments = {};
        this.projectIndex[project.Id] = {
            Slug: project.Slug,
            Name: project.Name,
            IsDisabled: project.IsDisabled,
            Id: project.Id,
            Links: project.Links,
            EnvironmentIds: null!,
            ProjectGroupId: project.ProjectGroupId,
            TenantedDeploymentMode: project.TenantedDeploymentMode,
            CanPerformUntenantedDeployment: project.TenantedDeploymentMode !== TenantedDeploymentMode.Tenanted,
            IsFrozen: false, // don't need to compute frozen  as we only care about frozen environments in this case
        };
        this.channelEnvironments = Object.keys(data.ChannelEnvironments).reduce((idx: {
            [channelId: string]: string[];
        }, channelId) => {
            idx[channelId] = data.ChannelEnvironments[channelId].map((k) => k.Id);
            return idx;
        }, {});
        this.deployments =
            !data || !data.Releases
                ? []
                : data.Releases.reduce<DashboardItemResource[]>((a, r) => {
                    if (r.HasUnresolvedDefect) {
                        this.blockedReleases.push(r.Release.Id);
                    }
                    this.releaseIndex[r.Release.Id] = r.Release;
                    // may not have access to this due to lacking permission
                    if (r.NextDeployments) {
                        this.nextAvailableDeployments[r.Release.Id] = r.NextDeployments.reduce<{
                            [environmentId: string]: string[];
                        }>((ax: {
                            [environmentId: string]: string[];
                        }, e) => {
                            ax[e] = [null!]; //The progression dataset is currently only used for non tenant releases
                            return ax;
                        }, {});
                    }
                    const g = Object.keys(r.Deployments).map((j) => r.Deployments[j]);
                    a = a.concat(...g);
                    return a;
                }, []);
        this.environmentIndex = {};
        const frozenEnvironments = uniq(deploymentFreezes.flatMap((df) => Object.values(df.ProjectEnvironmentScope).flatMap((env) => env)));
        for (const envRefData of this.data.Environments) {
            this.environmentIndex[envRefData.Id] = {
                Id: envRefData.Id,
                Name: envRefData.Name,
                IsFrozen: frozenEnvironments.indexOf(envRefData.Id) > -1,
                Links: {},
            };
        }
    }
    public addAdditionalChannels(additionalChannels: ChannelProgressionResource[]) {
        const keyedAdditionalChannels = keyBy(additionalChannels, (channel) => channel.Id);
        // When adding additional channels, any existing version of the channel should always win
        this.channelIndex = { ...keyedAdditionalChannels, ...this.channelIndex };
    }
    private getAllChannelsFromReleases(releases: ReleaseProgressionResource[]): {
        [channelId: string]: ChannelProgressionResource;
    } {
        const channels = releases.map((release) => release.Channel).filter((c) => c !== null);
        return keyBy(channels, (channel) => channel.Id);
    }
}
export default ProgressionDataCube;
