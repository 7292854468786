/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
import { logger } from "@octopusdeploy/logging";
import type { EnabledFeatureToggles, FeaturesConfigurationResource, FeatureToggle, InstallationHistoryResource, UserPermissionSetResource, UserResource } from "@octopusdeploy/octopus-server-client";
import { UserPermissions } from "@octopusdeploy/octopus-server-client";
import { OctopusFeatureProvider } from "@octopusdeploy/openfeature";
import { OpenFeature } from "@openfeature/web-sdk";
import { configurationActions } from "~/areas/configuration/reducers/configurationArea";
import { repository } from "~/clientInstance";
import { FeatureToggleProvider } from "~/featureToggleProvider";
import { OpenFeatureLoggerImpl } from "~/openFeatureLoggerImpl";
import store from "~/store";
type OnChangeSubscription = () => void;
export class Session {
    currentUser: UserResource | null = undefined!;
    currentPermissions: UserPermissions | null = undefined!;
    featureToggles: ReadonlyArray<FeatureToggle> | null = null;
    featureToggleProvider: FeatureToggleProvider | undefined = undefined!;
    features: FeaturesConfigurationResource | null = undefined!;
    installationHistory: InstallationHistoryResource[] = [];
    private onChangeSubscriptions: OnChangeSubscription[] = [];
    async start(user: UserResource, featureConfigurations: FeaturesConfigurationResource, featureToggles: EnabledFeatureToggles, installationHistory: InstallationHistoryResource[]) {
        logger.info("Starting session for {user}.", { user });
        this.currentUser = user;
        this.featureToggles = featureToggles.EnabledFeatureToggles;
        this.features = featureConfigurations;
        this.installationHistory = installationHistory;
        await this.initializeOctopusFeatureToggles(user, featureToggles.EnabledFeatureToggles);
        store.dispatch(configurationActions.featuresFetched(featureConfigurations));
        this.onChange();
    }
    end() {
        if (this.currentUser) {
            logger.info("Ending session for {user}.", { user: this.currentUser });
        }
        this.currentUser = null;
        this.currentPermissions = null;
        this.featureToggles = null;
        this.features = null;
        store.dispatch(configurationActions.featuresReset());
        this.onChange();
    }
    async initializeOctopusFeatureToggles(user: UserResource, featureToggles: ReadonlyArray<FeatureToggle>) {
        try {
            const clientIdentifier = await repository.ServerConfiguration.featureToggleClientIdentifier();
            if (clientIdentifier) {
                const provider = new OctopusFeatureProvider({ clientIdentifier: clientIdentifier, logger: new OpenFeatureLoggerImpl(logger) });
                await OpenFeature.setProviderAndWait(provider);
            }
            else {
                const provider = new FeatureToggleProvider(featureToggles);
                this.featureToggleProvider = provider;
                await OpenFeature.setProviderAndWait(provider);
            }
            await OpenFeature.setContext({
                username: user.Username,
                displayName: user.DisplayName,
                emailAddress: user.EmailAddress,
                serverUri: window.location.hostname,
            });
        }
        catch (e) {
            logger.error(e, "Failed to initialize Octopus Feature Toggles");
        }
    }
    refreshPermissions(userPermission: UserPermissionSetResource) {
        this.currentPermissions = UserPermissions.Create(userPermission.SpacePermissions, userPermission.SystemPermissions, userPermission.Teams);
        this.onChange();
    }
    isAuthenticated() {
        return this.currentUser != null;
    }
    subscribe(onChange: OnChangeSubscription) {
        this.onChangeSubscriptions.push(onChange);
        return () => {
            this.onChangeSubscriptions = this.onChangeSubscriptions.filter((s) => s !== onChange);
        };
    }
    onChange() {
        this.onChangeSubscriptions.forEach((s) => s());
    }
    setFeatureToggles(newToggles: FeatureToggle[]) {
        if (!this.currentUser) {
            return;
        }
        this.featureToggles = newToggles;
        this.featureToggleProvider?.updateFeatures(newToggles);
    }
}
export default Session;
