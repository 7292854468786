import type { DynamicEnvironmentResource, ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import { sortBy } from "lodash";
import * as React from "react";
import { ChipIcon, DynamicEnvironmentChip, ContextualMissingChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FocusableComponent } from "~/components/VirtualListWithKeyboard/FocusableComponent";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
export const DynamicEnvironmentTypedMultiSelect = MultiSelect<ReferenceDataItem>();
interface DynamicEnvironmentMultiSelectBase extends FormFieldProps<string[]> {
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    multiSelectRef?(component: FocusableComponent | null): void;
    accessibleName?: string;
    onRemove?: (nameOrId: string) => void;
    fallbackLabel?: string;
    fallbackDescription?: string;
    lookupCollection?: ReferenceDataItem[];
}
interface DynamicEnvironmentMultiSelectWithEnvironmentResources extends DynamicEnvironmentMultiSelectBase {
    environments: DynamicEnvironmentResource[];
    items?: never;
}
interface DynamicEnvironmentMultiSelectWithoutEnvironmentResources extends DynamicEnvironmentMultiSelectBase {
    environments?: never;
    items: ReferenceDataItem[];
}
type DynamicEnvironmentMultiSelectProps = DynamicEnvironmentMultiSelectWithEnvironmentResources | DynamicEnvironmentMultiSelectWithoutEnvironmentResources;
export const DynamicEnvironmentMultiSelect: React.FC<DynamicEnvironmentMultiSelectProps> = (props) => {
    let computedItems: ReferenceDataItem[] = [];
    if (props.environments) {
        computedItems = sortBy(props.environments, (x) => x.Name).map((e) => ({ Id: e.Id, Name: e.Name }));
    }
    else if (props.items) {
        computedItems = props.items;
    }
    const chipRenderer = (r: DynamicEnvironmentResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={computedItems} lookupId={r.Id} getIdFromElement={(element: ReferenceDataItem) => element.Id} render={(item: ReferenceDataItem) => <DynamicEnvironmentChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} environmentName={item.Name}/>} renderFallback={<ContextualMissingChip lookupKey={r.Id} type={ChipIcon.Environment} text={props.fallbackLabel} description={props.fallbackDescription} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <DynamicEnvironmentTypedMultiSelect fieldName="dynamic environments" renderChip={chipRenderer} accessibleName={props.accessibleName} sortItems={false} items={computedItems} {...props}/>;
};
DynamicEnvironmentMultiSelect.displayName = "DynamicEnvironmentMultiSelect"
