import type { BlueprintResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
export type ProcessBlueprintContextProps = {
    localNames: string[];
    blueprint: BlueprintResource;
};
export interface WithProcessBlueprintContextInjectedProps {
    processBlueprintContext?: ProcessBlueprintContextProps;
}
export const ProcessBlueprintContext = React.createContext<ProcessBlueprintContextProps | undefined>(undefined);
export const useOptionalProcessBlueprintContext = () => {
    return React.useContext(ProcessBlueprintContext);
};
export function buildProcessBlueprintContextProps(resource: BlueprintResource): ProcessBlueprintContextProps {
    return {
        blueprint: resource,
        localNames: [...resource.Parameters].sort((a, b) => a.Name.localeCompare(b.Name)).map((p) => p.Name),
    };
}
