import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useProjectContext } from "~/areas/projects/context/index";
import OnboardingLoader from "~/components/GettingStarted/onboardingLoader";
import SuccessfulDeploymentDialog from "~/components/ProjectBasedActivation/SuccessfulDeploymentDialog";
export function GettingStartedFooter() {
    const { state: projectState } = useProjectContext();
    const loader = useMemo(() => new OnboardingLoader(projectState.model.Id, projectState.model.SpaceId), [projectState.model.Id, projectState.model.SpaceId]);
    const [showDialog, setShowDialog] = useState(false);
    const [hasBeenSuccessfullyDeployed, setHasBeenSuccessfullyDeployed] = useState<boolean | undefined>(undefined);
    const [spaceHasSuccessfulDeployments, setSpaceHasSuccessfulDeployments] = useState<boolean | undefined>(undefined);
    useEffect(() => {
        const unsubscribe = loader.subscribe((status) => {
            if (hasBeenSuccessfullyDeployed === false && spaceHasSuccessfulDeployments === false && status.HasBeenSuccessfullyDeployed === true) {
                setShowDialog(true);
            }
            setHasBeenSuccessfullyDeployed(status.HasBeenSuccessfullyDeployed);
            setSpaceHasSuccessfulDeployments(status.SpaceHasSuccessfulDeployments);
        });
        return unsubscribe;
    }, [loader, hasBeenSuccessfullyDeployed, spaceHasSuccessfulDeployments]);
    const dismissDialog = () => {
        setShowDialog(false);
    };
    return <SuccessfulDeploymentDialog open={showDialog} close={dismissDialog}/>;
}
