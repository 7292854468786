import { isEqual } from "lodash";
import type { Reducer } from "redux";
import type { ActionType } from "typesafe-actions";
import { createAction, getType } from "typesafe-actions";
export const expanderActions = {
    onExpanderStateChanged: createAction("EXPANDER_STATE_CHANGED", (payload: ExpanderStateChangedPayload) => payload)(),
    onExpanderContainerDestroyed: createAction("EXPANDER_CONTAINER_DESTROYED", (payload: PossiblySpecifiesContainerKey) => payload)(),
    onExpanderSetInitialState: createAction("EXPANDER_CONTAINER_INITIAL_STATE", (payload: SetInitialExpanderStatePayload) => payload)(),
    onExpandExpanders: createAction("EXPANDER_EXPAND", (payload: ExpandExpandersPayload) => payload)(),
    onToggleAll: createAction("EXPANDER_TOGGLE_ALL", (payload: ToggleAllExpandersPayload) => payload)(),
    onExpanderCreated: createAction("EXPANDER_CREATED", (payload: ExpanderCreatedPayload) => payload)(),
    onAllExpandersCreated: createAction("ALL_EXPANDERS_CREATED", (payload: AllExpandersCreatedPayload) => payload)(),
};
export type ExpanderActions = ActionType<typeof expanderActions>;
type ContainerKey = string;
type ExpanderKey = string;
type IsExpanded = boolean;
export type ExpanderState = IsExpanded | typeof UseInitialValueOfContainer;
export const UseInitialValueOfContainer = undefined;
interface PossiblySpecifiesContainerKey {
    containerKey: ContainerKey | null;
}
interface SpecifiesContainerKey {
    containerKey: ContainerKey;
}
interface ExpandExpandersPayload extends PossiblySpecifiesContainerKey {
    expandersWithKeys: ReadonlyArray<ExpanderKey>;
}
interface ToggleAllExpandersPayload extends PossiblySpecifiesContainerKey {
    expanded: IsExpanded;
}
interface ExpanderCreatedPayload extends SpecifiesContainerKey {
    key: ExpanderKey;
    expanded: ExpanderState;
}
interface AllExpandersCreatedPayload extends SpecifiesContainerKey {
    keys: ExpanderKey[];
    expanded: IsExpanded;
}
interface ExpanderStateChangedPayload extends PossiblySpecifiesContainerKey {
    key: ExpanderKey;
    expanded: IsExpanded;
}
interface SetInitialExpanderStatePayload extends PossiblySpecifiesContainerKey {
    initialState: IsExpanded;
}
export const defaultContainerKey: ContainerKey = "default";
export type ExpanderValues = {
    [errorKey: string]: ExpanderState;
};
export interface ExpanderContainer {
    expanderValues: ExpanderValues;
    initialState: IsExpanded;
    expandingAll: boolean;
}
export type ExpandersState = {
    [containerKey: string]: ExpanderContainer | undefined;
};
const expanders: Reducer<ExpandersState, ExpanderActions> = (state = {}, action: ExpanderActions): ExpandersState => {
    switch (action.type) {
        case getType(expanderActions.onExpandExpanders):
            return expanderExpandErrors(state, action.payload, getContainerKeyOrDefault());
        case getType(expanderActions.onToggleAll):
            return expanderToggleAll(state, action.payload, getContainerKeyOrDefault());
        case getType(expanderActions.onExpanderStateChanged):
            return expanderStateChanged(state, action.payload, getContainerKeyOrDefault());
        case getType(expanderActions.onExpanderContainerDestroyed):
            return expanderContainerDestroyed(state, getContainerKeyOrDefault());
        case getType(expanderActions.onExpanderSetInitialState):
            return expanderContainerInitialState(state, action.payload, getContainerKeyOrDefault());
        case getType(expanderActions.onExpanderCreated):
            return expanderCreated(state, action.payload);
        case getType(expanderActions.onAllExpandersCreated):
            return allExpandersCreated(state, action.payload);
        default:
            return state;
    }
    function getContainerKeyOrDefault() {
        return action.payload.containerKey || defaultContainerKey;
    }
};
function expanderExpandErrors(state: ExpandersState, action: ExpandExpandersPayload, containerKey: ContainerKey) {
    const container: ExpanderContainer = state[containerKey] || defaultExpanderContainer();
    const errored = action.expandersWithKeys
        .map((e) => findMatchingKey(container.expanderValues, e))
        .filter(errorKeyWasFound)
        .reduce((p, expanderKeyWithError) => ({ ...p, [expanderKeyWithError]: true }), {});
    const updatedContainer: ExpanderContainer = {
        ...container,
        expanderValues: {
            ...container.expanderValues,
            ...errored,
        },
    };
    if (isEqual(container, updatedContainer)) {
        // don't mutate state if we aren't changing anything to avoid infinite update loops
        return state;
    }
    return {
        ...state,
        [containerKey]: updatedContainer,
    };
    function errorKeyWasFound(possibleErrorKey: ExpanderKey | undefined): possibleErrorKey is ExpanderKey {
        return possibleErrorKey !== undefined;
    }
}
function findMatchingKey(allExpanderValues: ExpanderValues, expanderKeyWithError: ExpanderKey): ExpanderKey | undefined {
    // given an object with some pipe-delimited keys like:
    // {
    //     "first|second": true
    // }
    // given "first" return "first|second". This allows us to set errorkeys that
    // match multiple possible errors (ie if you have an expander with mutiple controls)
    // remove "Steps[12]." and "Actions[0]." from the start of the error key. Our validation adds that
    // to indicate which step the error is in
    let modKey = expanderKeyWithError.replace(/^Steps\[[0-9]+\]\./, "");
    modKey = modKey.replace(/^Actions\[[0-9]+\]\./, "");
    return Object.keys(allExpanderValues).find((k) => k.split("|").some((f) => f.toLowerCase() === modKey.toLowerCase()));
}
function expanderToggleAll(state: ExpandersState, action: ToggleAllExpandersPayload, containerKey: ContainerKey) {
    const container: ExpanderContainer = state[containerKey] || defaultExpanderContainer();
    const updatedContainer: ExpanderContainer = {
        ...container,
        expandingAll: action.expanded,
        expanderValues: setAllToValue(container.expanderValues, action.expanded),
    };
    return {
        ...state,
        [containerKey]: updatedContainer,
    };
}
function expanderStateChanged(state: ExpandersState, action: ExpanderStateChangedPayload, containerKey: ContainerKey) {
    const container: ExpanderContainer = state[containerKey] || defaultExpanderContainer();
    const updatedContainer: ExpanderContainer = {
        ...container,
        expandingAll: false,
        expanderValues: {
            ...container.expanderValues,
            [action.key.toLowerCase()]: action.expanded,
        },
    };
    return {
        ...state,
        [containerKey]: updatedContainer,
    };
}
function expanderContainerDestroyed(state: ExpandersState, containerKey: ContainerKey) {
    const copy = { ...state };
    delete copy[containerKey];
    return copy;
}
function expanderContainerInitialState(state: ExpandersState, action: SetInitialExpanderStatePayload, containerKey: ContainerKey) {
    // set the initial state of the expander container, if there are already
    // expanders attached, set them to that state as well
    const container: ExpanderContainer = state[containerKey] || defaultExpanderContainer();
    const updatedContainer: ExpanderContainer = {
        expanderValues: setAllToValue(container.expanderValues, UseInitialValueOfContainer),
        initialState: action.initialState,
        expandingAll: false,
    };
    return {
        ...state,
        [containerKey]: updatedContainer,
    };
}
function expanderCreated(state: ExpandersState, action: ExpanderCreatedPayload) {
    const container: ExpanderContainer = state[action.containerKey] || defaultExpanderContainer();
    const existingValue = container.expanderValues[action.key];
    const expanderValue = existingValue !== UseInitialValueOfContainer ? existingValue : action.expanded;
    const updatedContainer = {
        ...container,
        expanderValues: {
            ...container.expanderValues,
            [action.key.toLowerCase()]: expanderValue,
        },
    };
    return {
        ...state,
        [action.containerKey]: updatedContainer,
    };
}
function allExpandersCreated(state: ExpandersState, action: AllExpandersCreatedPayload) {
    // add a full set of expanders to a container. overwrite any existing.
    const container: ExpanderContainer = state[action.containerKey] || defaultExpanderContainer();
    const expanderValues = action.keys.reduce((acc: {
        [key: string]: IsExpanded;
    }, key: ExpanderKey) => {
        acc[key.toLowerCase()] = action.expanded;
        return acc;
    }, {});
    return {
        ...state,
        [action.containerKey]: {
            ...container,
            expanderValues,
        },
    };
}
export function defaultExpanderContainer(): ExpanderContainer {
    return { initialState: false, expanderValues: {}, expandingAll: false };
}
function setAllToValue(expanderValues: ExpanderValues, to: ExpanderState): ExpanderValues {
    const newExpanderValues: ExpanderValues = {};
    Object.keys(expanderValues).forEach((k) => {
        newExpanderValues[k.toLowerCase()] = to;
    });
    return newExpanderValues;
}
export default expanders;
