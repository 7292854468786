/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Repository, RunbookSnapshotResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import { repository } from "../../clientInstance";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { SkeletonLoadingLayout } from "../SkeletonLoadingLayout/SkeletonLoadingLayout";
interface ProjectRunbookChildRedirectState extends DataBaseComponentState {
    redirectTo: LinkHref;
}
interface ProjectRunbookChildRedirectProps {
    spaceId: string;
    getRunbookSnapshot: (repository: Repository) => Promise<RunbookSnapshotResource>;
    getRedirectPath: (projectId: string, runbookId: string) => LinkHref;
}
class ProjectRunbookChildRedirect extends DataBaseComponent<ProjectRunbookChildRedirectProps, ProjectRunbookChildRedirectState> {
    constructor(props: ProjectRunbookChildRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const runbookSnapshot = await this.props.getRunbookSnapshot(repository);
            const redirectTo = this.props.getRedirectPath(runbookSnapshot.ProjectId, runbookSnapshot.RunbookId);
            this.setState({
                redirectTo,
            });
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={redirectTo}/>;
    }
    static displayName = "ProjectRunbookChildRedirect";
}
export const RunbookSnapshotRedirect: React.SFC<{
    spaceId: string;
    runbookSnapshotId: string;
}> = (props) => {
    return (<ProjectRunbookChildRedirect spaceId={props.spaceId} getRunbookSnapshot={(r) => r.RunbookSnapshots.get(props.runbookSnapshotId)} getRedirectPath={(projectId: string, runbookId: string) => links.projectRunbookSnapshotInfoPage.generateUrl({ spaceId: props.spaceId, projectSlug: projectId, runbookId, runbookSnapshotId: props.runbookSnapshotId })}/>);
};
RunbookSnapshotRedirect.displayName = "RunbookSnapshotRedirect"
