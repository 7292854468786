/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { VariableType, ScopeValues } from "@octopusdeploy/octopus-server-client";
import type { Dictionary } from "lodash";
import { compact, findLast, flatMap, flatten, isEqual, keyBy, memoize, sum, zipWith } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import { fetchAllAccounts } from "~/areas/infrastructure/reducers/accounts";
import { SampleProjectTourStep } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStep";
import type { CellFocus } from "~/areas/variables/CellFocus/CellFocus";
import { FocusableCellType, RowType } from "~/areas/variables/CellFocus/CellFocus";
import type { OpenReferenceVariableDialogArgs, OpenVariableDialogArgs } from "~/areas/variables/EditVariableDialog/EditVariableDialog";
import { EditVariableDialog, FocusField } from "~/areas/variables/EditVariableDialog/EditVariableDialog";
import { convertToFilterableValue } from "~/areas/variables/ReadonlyVariableResource";
import type SensitiveFieldStates from "~/areas/variables/SensitiveFieldStates";
import VariableAdd from "~/areas/variables/VariableAdd/VariableAdd";
import { VariableEditorHeadings } from "~/areas/variables/VariableEditorHeadings/VariableEditorHeadings";
import type { VariableQuery } from "~/areas/variables/VariableFilter";
import type { VariableFilter } from "~/areas/variables/VariableFilter/VariableFilter";
import { createEmptyFilter } from "~/areas/variables/VariableFilter/VariableFilter";
import type { VariableFilterLayoutProps } from "~/areas/variables/VariableFilterLayout/VariableFilterLayout";
import { default as VariableFilterLayout } from "~/areas/variables/VariableFilterLayout/VariableFilterLayout";
import type { AllVariableMessages, ValueMessages } from "~/areas/variables/VariableMessages/VariableMessages";
import type { VariableRowRenderer, VariableRowRenderProps } from "~/areas/variables/VariableRowRenderer/VariableRowRenderer";
import getVariableRowRenderers from "~/areas/variables/VariableRowRenderer/VariableRowRenderer";
import { VariableStatus } from "~/areas/variables/VariableStatusIcon";
import type { FilteredVariableModel, VariableModel } from "~/areas/variables/VariablesModel/VariablesModel";
import { VariablesModel, VariableValueModel } from "~/areas/variables/VariablesModel/VariablesModel";
import { collectCertificateAndWorkerPoolIndexes } from "~/areas/variables/collectCertificateAndWorkerPoolIndexes";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { withTheme } from "~/components/Theme";
import type { CertificateIndex } from "~/components/certificates";
import type { SensitiveState } from "~/components/form/Sensitive/Sensitive";
import { getTagIndex } from "~/components/tenantTagsets";
import type { TagIndex } from "~/components/tenantTagsets";
import type { CellAligner, RenderArgs, RowRenderArgs } from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import ScrollTable from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import type { BorderCss } from "~/utils/BorderCss/BorderCss";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import type { WorkerPoolIndex } from "../../../components/workerPools";
import mergeScopeValues from "../MergeScopeValues";
import { isAnyValueScopedToTenantTags } from "../isAnyValueScopedToTenantTags";
import { isReferenceType } from "../isReferenceType";
import { VariableEditorScopingProvider } from "./VariableEditorScopingContext";
import { getVariablesMessagesForEditor } from "./conversions";
import type { VariableEditorScope } from "./types";
interface VariableEditorProps {
    initialVariables: ReadonlyArray<VariableModel>;
    scopeValues: ScopeValues;
    isTenanted: boolean;
    doBusyTask: DoBusyTask;
    cellFocusResetKey: string;
    onVariablesChanged(variables: ReadonlyArray<VariableModel>): void;
    onLoad?(): void;
    scope: VariableEditorScope;
    gitVariables: boolean;
}
interface VariableEditorState {
    filter: VariableFilter;
    queryFilter?: VariableFilter;
    focus?: CellFocus;
    editVariableDialog?: OpenVariableDialogArgs | OpenReferenceVariableDialogArgs;
    model?: VariableEditorModel;
    sensitiveFieldStates: SensitiveFieldStates;
    tagIndex?: TagIndex;
    certificateIndex?: CertificateIndex;
    poolIndex?: WorkerPoolIndex;
    measuredScopeCellWidth: number | undefined;
    relativeColumnWidths: ReadonlyArray<number>;
}
interface VariableEditorModel {
    readonly variables: VariablesModel;
    readonly deletedValueIds: ReadonlyArray<string>;
    readonly draftVariable: VariableModel;
}
const blankRowHeight = 48;
const defaultRelativeColumnWidths = [5, 8, 6];
const emptyTagIndex: TagIndex = {};
const FilterLayout: React.SFC<VariableFilterLayoutProps<VariableFilter>> = (props) => VariableFilterLayout<VariableFilter>(props);
FilterLayout.displayName = "FilterLayout"
const VariableQueryStringFilters = QueryStringFilters.For<VariableFilter, VariableQuery>();
class VariableEditor extends React.Component<VariableEditorProps, VariableEditorState> {
    private readonly getExistingVariableValuesMap: (variables: ReadonlyArray<VariableModel>) => Dictionary<VariableValueModel>;
    private readonly getMessages: (model: VariableEditorModel) => AllVariableMessages;
    private readonly getScopeValues: (scopeValues: ScopeValues) => ScopeValues;
    private readonly countValues: (variables: ReadonlyArray<VariableModel>) => number;
    constructor(props: VariableEditorProps) {
        super(props);
        this.getExistingVariableValuesMap = memoize((variables: ReadonlyArray<VariableModel>) => createExistingVariableValuesMap(variables));
        this.getScopeValues = memoize((scopeValues: ScopeValues) => createScopeValues(scopeValues));
        this.getMessages = memoize((model: VariableEditorModel) => createMessages(model));
        this.countValues = memoize((variables: ReadonlyArray<VariableModel>) => countValues(variables));
        const initialModel = getModel(this.availableScopes, this.props.initialVariables);
        this.state = {
            sensitiveFieldStates: {},
            filter: createEmptyFilter(),
            model: initialModel,
            focus: undefined,
            measuredScopeCellWidth: undefined,
            relativeColumnWidths: defaultRelativeColumnWidths,
        };
    }
    async componentDidMount() {
        if (this.props.onLoad) {
            this.props.onLoad();
        }
        await this.props.doBusyTask(async () => {
            await this.updateIndexes();
        });
    }
    private async updateIndexes() {
        const allVariables = this.state.model!.variables.variables.concat(this.state.model!.draftVariable);
        const values = flatMap(allVariables, (v) => v.values);
        const filterableValues = values.map((val) => convertToFilterableValue(val));
        if (isAnyValueScopedToTenantTags(filterableValues) && !this.state.tagIndex) {
            this.setState({ tagIndex: await getTagIndex() });
        }
        this.setState(await collectCertificateAndWorkerPoolIndexes(filterableValues));
    }
    componentDidUpdate(prevProps: VariableEditorProps) {
        if (this.props.cellFocusResetKey !== prevProps.cellFocusResetKey) {
            this.setState({ focus: undefined });
        }
        if (this.props.initialVariables === prevProps.initialVariables && this.props.scopeValues === prevProps.scopeValues) {
            return;
        }
        this.setState({
            sensitiveFieldStates: {},
            model: getModel(this.getScopeValues(this.props.scopeValues), this.props.initialVariables),
        });
    }
    render() {
        return withTheme((theme) => {
            if (!this.state.model) {
                return null;
            }
            const messages = this.getMessages(this.state.model);
            const filteredVariables = this.getFilteredVariables();
            const rows = this.getVariableRowRenderers(filteredVariables, messages);
            return (<VariableEditorScopingProvider scope={this.props.scope}>
                    {this.state.model.variables && (<React.Fragment>
                            <VariableQueryStringFilters key="queryStringFilters" filter={this.state.filter} getQuery={this.queryFromFilters} getFilter={this.getFilter} onFilterChange={(filter) => this.setState({ filter, queryFilter: filter })}/>
                            <FilterLayout key="filterLayout" filter={this.state.filter} queryFilter={this.state.queryFilter!} availableScopes={this.availableScopes} defaultFilter={createEmptyFilter()} messages={messages} isTenanted={this.props.isTenanted} onFilterChanged={(filter) => this.setState({ filter })} doBusyTask={this.props.doBusyTask} renderContent={(filterPanelIsVisible) => (<div>
                                        <div tabIndex={0} onFocus={() => this.setState({ focus: undefined })}/>

                                        <ScrollTable relativeColumnWidths={this.state.relativeColumnWidths} onColumnWidthsChanged={(relativeColumnWidths) => this.setState({ relativeColumnWidths })} minimumColumnWidthsInPx={[200, 200, 230]} overscanRowCount={10} rowCount={rows.length} shouldVirtualize={this.countValues(this.props.initialVariables) > 100} rowHeight={(index) => {
                            const variableRowRenderer = rows[index];
                            return variableRowRenderer.height;
                        }} headers={({ cellAligner, columnWidthsInPercent, borderStyle }: RenderArgs) => [
                            <div style={{ borderBottom: borderStyle.borderCssString, width: "100%", backgroundColor: theme.paper1 }}>
                                                    <VariableEditorHeadings columnWidths={columnWidthsInPercent} isDisplayedFullWidth={!filterPanelIsVisible} cellAligner={cellAligner} onWidthMeasured={(index, width) => {
                                    if (index === 2) {
                                        this.setState({ measuredScopeCellWidth: width });
                                    }
                                }} cells={[<span>Name</span>, <span>Value</span>, <span>Scope</span>]}/>
                                                </div>,
                            this.renderNewVariableRow(cellAligner, borderStyle),
                        ]} rowRenderer={({ cellAligner, index, isVisible, columnWidthsInPercent, borderStyle }: RowRenderArgs): React.ReactNode => {
                            const variableRowRenderer = rows[index];
                            return variableRowRenderer.render(cellAligner, isVisible, !filterPanelIsVisible, borderStyle, columnWidthsInPercent);
                        }} bodyContentWrapper={(children) => <SampleProjectTourStep stepId={"Variable"}>{children}</SampleProjectTourStep>}/>
                                        <div tabIndex={0} onFocus={() => this.setState({ focus: undefined })}/>
                                        <EditVariableDialog title="Edit Variable" openDialogArgs={this.state.editVariableDialog} availableScopes={this.availableScopes} isTenanted={this.props.isTenanted} onDone={(value: VariableValueModel, name: string) => this.updateVariablesState((prev) => prev.updateValueAndName(value, name))} onClosed={() => this.setState({ editVariableDialog: undefined })}/>
                                    </div>)}/>
                        </React.Fragment>)}
                </VariableEditorScopingProvider>);
        });
    }
    private findVariableByValue(value: VariableValueModel): VariableModel {
        return this.state.model!.variables.variables.find((variable) => variable.values.some((v) => v.Id === value.Id))!;
    }
    private getVariableRowRenderers(filteredVariables: ReadonlyArray<FilteredVariableModel>, messages: AllVariableMessages): ReadonlyArray<VariableRowRenderer> {
        return flatMap<FilteredVariableModel, VariableRowRenderer>(filteredVariables, (variable, index) => {
            const variableMessages = messages.variableMessages[variable.originalIndex];
            const unfilteredVariable = getVariables(this.state.model).variables[variable.originalIndex];
            const filteredVariableMessages = zipWith(unfilteredVariable.values, variableMessages.valuesMessages, (value: VariableValueModel, valueMessages: ValueMessages) => ({ value, messages: valueMessages }))
                .filter((z) => variable.values.some((v) => v.Id === z.value.Id))
                .map((z) => z.messages);
            const variableRowRenderProps: VariableRowRenderProps = {
                variable: unfilteredVariable,
                variableIndex: index,
                values: variable.values,
                valueMessages: filteredVariableMessages,
                availableScopes: this.availableScopes,
                sensitiveFieldStates: this.state.sensitiveFieldStates,
                tagIndex: this.state.tagIndex ? this.state.tagIndex : emptyTagIndex,
                certificateIndex: this.state.certificateIndex!,
                poolIndex: this.state.poolIndex!,
                gitVariables: this.props.gitVariables,
                variableMessages,
                doBusyTask: this.props.doBusyTask,
                focus: this.state.focus && this.state.focus.rowType === RowType.Edit ? { variableId: this.state.focus.variableId, cell: this.state.focus.cell } : undefined,
                getValueStatus: (value: VariableValueModel) => this.getValueStatus(value, this.state.model),
                onDuplicateVariable: this.onDuplicateVariable,
                onDuplicateValue: this.onDuplicateValue,
                onAddValue: this.onAddValue,
                onResetChanges: this.onResetChanges,
                onDeleteValue: this.deleteValue,
                undoDeleteValue: this.undoDelete,
                openVariableEditor: this.openVariableEditor,
                changingToReferenceType: this.changingToReferenceType,
                onBlur: this.onBlur,
                onFocus: this.onFocus,
                onMergeClicked: this.onMergeClicked,
                rename: this.rename,
                onNameChanged: this.onNameChanged,
                onValueChanged: this.onValueChanged,
                onNavigateUp: this.onNavigateUp,
                onNavigateDown: this.onNavigateDown,
                onSensitiveStateChanged: this.onSensitiveStateChanged,
                getExistingVariable: this.getExistingVariable,
                scopeCellWidth: this.state.measuredScopeCellWidth,
            };
            return [...getVariableRowRenderers(variableRowRenderProps)];
        });
    }
    private getExistingVariable = (value: VariableValueModel) => this.findExistingValue(value.Id);
    private onDuplicateVariable = (variable: VariableModel) => this.updateVariablesState((prev) => prev.duplicateVariable(variable, (value: VariableValueModel) => !isValueDeleted(value, this.state.model!.deletedValueIds)));
    private onDuplicateValue = (value: VariableValueModel) => this.updateVariablesState((prev) => prev.duplicate(value));
    private onAddValue = (variable: VariableModel, selectedValue: VariableValueModel) => this.updateVariablesState((prev) => prev.addValueToVariable(variable, selectedValue.Type));
    private onResetChanges = (value: VariableValueModel) => {
        this.updateSensitiveState(value.Id, undefined);
        this.updateVariablesState((prev) => prev.resetChanges(this.findExistingValue(value.Id)!));
    };
    private undoDelete = (variable: VariableValueModel) => this.updateDeletedVariableIds((prev) => [...prev.filter((id) => id !== variable.Id)]);
    private openVariableEditor = (value: VariableValueModel, name: string, focus: FocusField) => this.setState({ editVariableDialog: { value, name, focus, referenceType: undefined } });
    private changingToReferenceType = (value: VariableValueModel, name: string, referenceType: VariableType) => {
        if (isReferenceType(referenceType)) {
            this.setState({ editVariableDialog: { value, name, focus: FocusField.Value, referenceType } });
        }
        else {
            this.setState({ editVariableDialog: { value, name, focus: FocusField.Value } });
        }
    };
    private onMergeClicked = (variable: VariableModel, value: VariableValueModel) => this.updateVariablesState((prev) => prev.merge(variable));
    private rename = (variable: VariableModel) => this.updateVariablesState((prev) => prev.automaticRenameToAvoidCollision(variable));
    private onNameChanged = (variable: VariableModel, name: string) => this.updateVariablesState((prev) => prev.rename(variable, name));
    private onValueChanged = (value: VariableValueModel) => this.updateVariablesState((prev) => prev.updateValue(value));
    private onSensitiveStateChanged = (variable: VariableValueModel, state: SensitiveState) => this.updateSensitiveState(variable.Id, state);
    private onNavigateDown = (variable: VariableValueModel) => {
        if (!this.state.focus || this.state.focus.cell === FocusableCellType.ScopeEdit) {
            return; // do nothing, because you could be in an autocomplete in the scope cell
        }
        const nextVariable = getNextNavigateDownVariable(this.getFilteredVariables(), this.state.model, this.state.focus.cell, variable.Id);
        if (nextVariable) {
            this.setState((prev) => ({ focus: { ...prev.focus!, variableId: nextVariable.Id } }));
        }
    };
    private onNavigateUp = (value: VariableValueModel) => {
        if (!this.state.focus || this.state.focus.cell === FocusableCellType.ScopeEdit) {
            return; // do nothing, because you could be in an autocomplete in the scope cell
        }
        const previousVariable = getPreviousVariable(this.getFilteredVariables(), this.state.model, this.state.focus);
        if (previousVariable) {
            this.setState((prev) => ({ focus: { ...prev.focus!, variableId: previousVariable.Id } }));
        }
        else {
            this.setState((prev: VariableEditorState) => {
                const addVariableIds = prev.model!.draftVariable.values.map((v) => v.Id);
                if (addVariableIds.length) {
                    const variableId = prev.focus!.cell === FocusableCellType.Name ? addVariableIds[0] : addVariableIds[addVariableIds.length - 1];
                    return {
                        focus: {
                            ...prev.focus!,
                            rowType: RowType.Add,
                            variableId,
                        },
                    };
                }
                return {};
            });
        }
        function getPreviousVariable(filteredVariables: ReadonlyArray<FilteredVariableModel>, model: VariableEditorModel | undefined, focus: CellFocus): VariableValueModel | undefined {
            const containingGroupIndex = filteredVariables.findIndex((variable) => variable.values.some((v) => v.Id === value.Id));
            const precedingVariables = filteredVariables.slice(0, containingGroupIndex);
            if (focus.cell === FocusableCellType.Name) {
                const lastGroupWithANonDeletedVariable = findLast(precedingVariables, (variable) => variable.values.some((v) => !isValueDeleted(v, model!.deletedValueIds)));
                if (lastGroupWithANonDeletedVariable) {
                    return lastGroupWithANonDeletedVariable.values[0];
                }
            }
            else {
                const containingVariable = filteredVariables[containingGroupIndex];
                const valueIndex = containingVariable.values.findIndex((v) => v.Id === value.Id);
                const precedingValues = containingVariable.values.slice(0, valueIndex);
                const allPrecedingValues = [...flatten<VariableValueModel>(precedingVariables.map((g) => [...g.values])), ...precedingValues];
                return findLast(allPrecedingValues, (v) => !isValueDeleted(v, model!.deletedValueIds));
            }
        }
    };
    private onBlur = (variable: VariableValueModel, blurredFrom: FocusableCellType) => this.setState((prev) => {
        if (blurredFrom === prev.focus!.cell && prev.focus!.variableId === variable.Id && prev.focus!.rowType === RowType.Edit) {
            return { focus: undefined };
        }
        return {};
    });
    private onFocus = (variable: VariableValueModel, focus: FocusableCellType) => this.setState({ focus: { cell: focus, variableId: variable.Id, rowType: RowType.Edit } });
    private renderNewVariableRow(cellAligner: CellAligner, borderStyle: BorderCss) {
        return (<VariableAdd availableScopes={this.availableScopes} borderStyle={borderStyle} sensitiveFieldStates={this.state.sensitiveFieldStates} tagIndex={this.state.tagIndex ? this.state.tagIndex : {}} isTenanted={this.props.isTenanted} cellAligner={cellAligner} variable={this.state.model!.draftVariable} doBusyTask={this.props.doBusyTask} certificateIndex={this.state.certificateIndex!} poolIndex={this.state.poolIndex!} onAdded={this.handleNewVariableAdded} onChanged={(draftVariable) => this.updateModel((prev) => ({ ...prev, draftVariable }))} focus={this.state.focus && this.state.focus.rowType === RowType.Add
                ? {
                    variableId: this.state.focus.variableId,
                    cell: this.state.focus.cell,
                }
                : undefined} onFocus={(focus) => {
                if (!focus) {
                    this.setState({ focus: undefined });
                }
                else {
                    this.setState({ focus: { cell: focus.cell, variableId: focus.variableId, rowType: RowType.Add } });
                }
            }} onBlur={(blurredFrom) => this.setState((prev) => {
                if (prev.focus!.rowType === RowType.Add && prev.focus!.variableId === blurredFrom.variableId && prev.focus!.cell === blurredFrom.cell) {
                    return { focus: undefined };
                }
                return {};
            })} onNavigateDown={() => this.setState((prev) => {
                const cellToFocus = prev.focus ? prev.focus.cell : FocusableCellType.Name;
                const nextVariable = getNextNavigateDownVariable(this.getFilteredVariables(), prev.model, cellToFocus, null);
                if (nextVariable) {
                    return { focus: { rowType: RowType.Edit, variableId: nextVariable.Id, cell: cellToFocus } };
                }
                return {};
            })} onSensitiveStateChanged={(id, state) => this.updateSensitiveState(id, state)} scopeCellWidth={this.state.measuredScopeCellWidth} gitVariables={this.props.gitVariables}/>);
    }
    private handleNewVariableAdded = () => this.updateModel((prev) => ({
        ...prev,
        draftVariable: createEmptyDraftVariable(),
        variables: prev.variables.addVariable(prev.draftVariable),
    }));
    private deleteValue = (value: VariableValueModel) => this.updateModel((prev) => {
        if (this.getValueStatus(value, prev) === VariableStatus.New) {
            return {
                ...prev,
                variables: prev.variables.delete(value),
            };
        }
        else {
            return {
                ...prev,
                deletedValueIds: [...prev.deletedValueIds, value.Id],
            };
        }
    });
    private updateDeletedVariableIds(getUpdatedVariableIds: (previousDeletedVariableIds: ReadonlyArray<string>) => ReadonlyArray<string>) {
        this.updateModel((prev) => ({
            ...prev,
            deletedValueIds: getUpdatedVariableIds(prev.deletedValueIds),
        }));
    }
    private updateVariablesState(getUpdatedVariables: (variables: VariablesModel) => VariablesModel) {
        this.updateModel((prev) => ({
            ...prev,
            variables: getUpdatedVariables(prev.variables),
        }));
    }
    private updateModel(getUpdatedModel: (previousModel: VariableEditorModel) => VariableEditorModel) {
        this.setState((prevState) => ({ model: getUpdatedModel(prevState.model!) }), async () => {
            const updatedModel = this.state.model;
            const nonDeletedVariables = getNonDeletedVariables(getAllVariables(updatedModel!), updatedModel!.deletedValueIds);
            await this.updateIndexes();
            this.props.onVariablesChanged(nonDeletedVariables);
        });
    }
    private updateSensitiveState(variableId: string, state: SensitiveState | undefined) {
        this.setState((prev) => ({ sensitiveFieldStates: { ...prev.sensitiveFieldStates, [variableId]: state } }));
    }
    private getFilteredVariables() {
        const messages = this.getMessages(this.state.model!);
        return getVariables(this.state.model).filterVariables(this.state.filter, messages, this.availableScopes, convertToFilterableValue);
    }
    private get availableScopes(): ScopeValues {
        return this.getScopeValues(this.props.scopeValues);
    }
    private getValueStatus(value: VariableValueModel, model: VariableEditorModel | undefined): VariableStatus {
        if (isValueDeleted(value, model!.deletedValueIds)) {
            return VariableStatus.Deleted;
        }
        const existingValue: VariableValueModel = this.findExistingValue(value.Id)!;
        if (existingValue) {
            return valuesAreEquivalent(value, existingValue) ? VariableStatus.Existing : VariableStatus.Modified;
        }
        return VariableStatus.New;
    }
    private findExistingValue(valueId: string): VariableValueModel | undefined {
        const existingVariable = this.getExistingVariableValuesMap(this.props.initialVariables)[valueId];
        return !existingVariable ? undefined : existingVariable;
    }
    private queryFromFilters = (filter: VariableFilter): VariableQuery => {
        const query: VariableQuery = {
            name: filter.name,
            value: filter.value,
            description: filter.description,
            filterEmptyValues: filter.filterEmptyValues ? "true" : undefined,
            filterDuplicateNames: filter.filterDuplicateNames ? "true" : undefined,
            filterNonPrintableCharacters: filter.filterNonPrintableCharacters ? "true" : undefined,
            filterVariableSubstitutionSyntax: filter.filterVariableSubstitutionSyntax ? "true" : undefined,
            environment: [...(filter.scope.Environment! as string[])],
            machine: [...(filter.scope.Machine! as string[])],
            role: [...(filter.scope.Role! as string[])],
            action: [...(filter.scope.Action! as string[])],
            channel: [...(filter.scope.Channel! as string[])],
            tenantTag: [...(filter.scope.TenantTag! as string[])],
            process: [...(filter.scope.ProcessOwner! as string[])],
        };
        return query;
    };
    private getFilter = (query: VariableQuery): VariableFilter => {
        const filter: VariableFilter = {
            name: query.name || "",
            value: query.value || "",
            description: query.description || "",
            filterEmptyValues: query.filterEmptyValues === "true",
            filterDuplicateNames: query.filterDuplicateNames === "true",
            filterNonPrintableCharacters: query.filterNonPrintableCharacters === "true",
            filterVariableSubstitutionSyntax: query.filterVariableSubstitutionSyntax === "true",
            scope: {
                Environment: arrayValueFromQueryString(query.environment),
                Machine: arrayValueFromQueryString(query.machine),
                Role: arrayValueFromQueryString(query.role),
                Action: arrayValueFromQueryString(query.action),
                Channel: arrayValueFromQueryString(query.channel),
                TenantTag: arrayValueFromQueryString(query.tenantTag),
                ProcessOwner: arrayValueFromQueryString(query.process),
            },
        };
        return filter;
    };
    static displayName = "VariableEditor";
}
function getNonDeletedVariables(variables: ReadonlyArray<VariableModel>, deletedValueIds: ReadonlyArray<string>): ReadonlyArray<VariableModel> {
    return compact(variables.map((variable) => {
        const values = variable.values.filter((v) => !isValueDeleted(v, deletedValueIds));
        if (values.length === 0) {
            return null;
        }
        return {
            name: variable.name,
            values,
        };
    }));
}
function countValues(variables: ReadonlyArray<VariableModel>): number {
    return sum(variables.map((v) => v.values.length));
}
function createScopeValues(scopeValues: ScopeValues): ScopeValues {
    return mergeScopeValues([scopeValues]);
}
function createMessages(model: VariableEditorModel): AllVariableMessages {
    const nonDeletedVariables = model.variables.variables.map<VariableModel>((variable) => ({
        name: variable.name,
        values: variable.values.map((v) => (isValueDeleted(v, model.deletedValueIds) ? null! : v)),
    }));
    return getVariablesMessagesForEditor(nonDeletedVariables);
}
function isValueDeleted(value: VariableValueModel, deletedValueIds: ReadonlyArray<string>) {
    return deletedValueIds.find((id) => id === value.Id);
}
function getVariables(model?: VariableEditorModel): VariablesModel {
    return model ? model.variables : new VariablesModel([]);
}
function createExistingVariableValuesMap(variables: ReadonlyArray<VariableModel>): Dictionary<VariableValueModel> {
    return keyBy(getAllValues(variables), (v) => v.Id);
}
function getAllVariables(model: VariableEditorModel) {
    return isVariableEmpty(model.draftVariable) ? model.variables.variables : [...model.variables.variables, model.draftVariable];
}
function getModel(availableScopes: ScopeValues, variables: ReadonlyArray<VariableModel>): VariableEditorModel {
    const sortedVariables = new VariablesModel(variables).sort(availableScopes);
    const deletedValueIds: string[] = [];
    return {
        variables: sortedVariables,
        deletedValueIds,
        draftVariable: createEmptyDraftVariable(),
    };
}
function isVariableEmpty(variable: VariableModel) {
    return variable.name === "";
}
function createEmptyDraftVariable(): VariableModel {
    return { name: "", values: [new VariableValueModel()] };
}
function valuesAreEquivalent(left: VariableValueModel, right: VariableValueModel) {
    return isEqual(left, right);
}
function getAllValues(variables: ReadonlyArray<VariableModel>): ReadonlyArray<VariableValueModel> {
    return flatten(variables.map((variable) => [...variable.values]));
}
function getNextNavigateDownVariable(filteredVariables: ReadonlyArray<FilteredVariableModel>, model: VariableEditorModel | undefined, focusedCell: FocusableCellType, currentVariableId: string | null): VariableValueModel | undefined {
    const containingVariableIndex = filteredVariables.findIndex((v) => v.values.some((value) => value.Id === currentVariableId));
    const subsequentVariables = filteredVariables.slice(containingVariableIndex + 1);
    if (focusedCell === FocusableCellType.Name) {
        const firstVariableWithANonDeletedValue = subsequentVariables.find((variable) => variable.values.some((v) => !isValueDeleted(v, model!.deletedValueIds)));
        if (firstVariableWithANonDeletedValue) {
            return firstVariableWithANonDeletedValue.values[0];
        }
    }
    else {
        const containingVariable = filteredVariables[containingVariableIndex];
        const nextValuesInSameVariable = containingVariable ? containingVariable.values.slice(containingVariable.values.findIndex((v) => v.Id === currentVariableId) + 1) : [];
        const allSubsequentVariables = [...nextValuesInSameVariable, ...flatten<VariableValueModel>(subsequentVariables.map((variable) => [...variable.values]))];
        return allSubsequentVariables.find((v) => !isValueDeleted(v, model!.deletedValueIds));
    }
}
const mapGlobalStateToProps = (state: GlobalState, props: any) => ({});
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch) => bindActionCreators({ onLoad: fetchAllAccounts }, dispatch);
const ConnectedVariableEditor = connect<{}, {}, VariableEditorProps, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(VariableEditor);
export default ConnectedVariableEditor;
