import { CircularProgress, InlineSnackbar } from "@octopusdeploy/design-system-components";
import type { NewStaticWorkerPoolResource, WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { OctopusError, WorkerPoolType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { type MouseEvent, useCallback, useMemo, useState } from "react";
import { ConfigurationMessages } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationMessages";
import { ConfigurationPage, ConfigurationPageStorageClassHelperContent } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationPage";
import type { WorkerConfigurationResource } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentWorkerConfigurationDialog";
import type { WorkerPoolConfiguration, WorkerPoolFieldErrors } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/WorkerPoolCreationForm";
import { WorkerPoolCreationForm } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/WorkerPoolCreationForm";
import { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import type { WorkerPoolMultiSelectProps } from "~/components/MultiSelect/WorkerPoolMultiSelect";
import { WorkerPoolMultiSelect } from "~/components/MultiSelect/WorkerPoolMultiSelect";
interface WorkerPoolMultiSelectWithAddRefreshProps extends WorkerPoolMultiSelectProps {
    addAction: (e?: MouseEvent) => void;
    onRequestRefresh(): Promise<void>;
}
const WorkerPoolMultiSelectWithAddRefresh = ({ addAction, onRequestRefresh, ...selectProps }: WorkerPoolMultiSelectWithAddRefreshProps) => {
    const [refreshing, setRefreshing] = useState(false);
    const handleRequestRefresh = async () => {
        setRefreshing(true);
        try {
            await onRequestRefresh();
        }
        finally {
            setRefreshing(false);
        }
    };
    const actions = [
        refreshing ? <CircularProgress size="small"/> : <IconButtonWithTooltip onClick={handleRequestRefresh} toolTipContent="Refresh" icon="Refresh"/>,
        <IconButtonWithTooltip toolTipContent={"Add"} onClick={(e) => addAction(e)} icon="Add"/>,
    ];
    return <WorkerPoolMultiSelect {...selectProps} actionButtons={actions}/>;
};
type WorkerConfigurationPageProps = {
    model: WorkerConfigurationResource;
    onModelChange: <K extends keyof WorkerConfigurationResource>(model: Pick<WorkerConfigurationResource, K>) => void;
    workerPools: WorkerPoolResource[];
    canModifyServerUrls: boolean;
    showAdvanced: boolean;
    toggleShowAdvanced: () => void;
    getFieldError: (fieldName: string) => string;
    usePersistentVolumeStorage: boolean;
    onUsePersistentVolumeStorageChanged: (value: boolean) => void;
    requiresHaConfiguration: boolean;
    refreshWorkerPools(newWorkerPool?: WorkerPoolResource): Promise<void>;
};
export function WorkerConfigurationPage(props: WorkerConfigurationPageProps) {
    const { getFieldError, refreshWorkerPools } = props;
    const initialWorkerPoolConfiguration = useMemo(() => ({ name: "" }), []);
    const [workerPoolConfiguration, setWorkerPoolConfiguration] = React.useState<WorkerPoolConfiguration>(initialWorkerPoolConfiguration);
    const [isWorkerPoolFormOpen, setIsWorkerPoolFormOpen] = React.useState(false);
    const [workerPoolFieldErrors, setWorkerPoolFieldErrors] = React.useState<WorkerPoolFieldErrors>({});
    const [showWorkerPoolSnackBar, setShowWorkerPoolSnackBar] = React.useState(false);
    const [isCreating, setIsCreating] = React.useState(false);
    const resetWorkerPoolForm = useCallback(() => {
        setWorkerPoolConfiguration(initialWorkerPoolConfiguration);
        setWorkerPoolFieldErrors({});
        setIsWorkerPoolFormOpen(false);
    }, [initialWorkerPoolConfiguration]);
    const validateWorkerPoolForm: () => boolean = useCallback(() => {
        if (isWorkerPoolFormOpen && workerPoolConfiguration.name.length === 0) {
            setWorkerPoolFieldErrors({ name: ConfigurationMessages.WorkerPool.Validation.NotProvided });
            return false;
        }
        return true;
    }, [isWorkerPoolFormOpen, workerPoolConfiguration.name.length]);
    const updateModelAndClearServerValidationError = (model: WorkerPoolConfiguration) => {
        setWorkerPoolConfiguration(model);
        setWorkerPoolFieldErrors({});
    };
    const handleOnCreate = (workerPoolConfiguration: WorkerPoolConfiguration) => {
        setIsCreating(true);
        tryCreateWorkerPool(workerPoolConfiguration).then(() => setIsCreating(false));
    };
    const tryCreateWorkerPool = useCallback(async (workerPoolConfiguration: WorkerPoolConfiguration) => {
        const isValid = validateWorkerPoolForm();
        if (isValid) {
            const workerPool: NewStaticWorkerPoolResource = {
                Name: workerPoolConfiguration.name,
                SortOrder: -1,
                IsDefault: false,
                CanAddWorkers: false,
                Description: "",
                WorkerPoolType: WorkerPoolType.Static,
            };
            try {
                const result = await repository.WorkerPools.create(workerPool, {});
                await refreshWorkerPools(result);
                resetWorkerPoolForm();
                setShowWorkerPoolSnackBar(true);
            }
            catch (error) {
                if (OctopusError.isOctopusError(error) && error.Errors && error.Errors.length > 0) {
                    setWorkerPoolFieldErrors({ name: error?.Errors[0] });
                }
            }
        }
    }, [validateWorkerPoolForm, refreshWorkerPools, resetWorkerPoolForm]);
    return (<ConfigurationPage configuration={props.model} onConfigurationChange={(configuration) => props.onModelChange({ ...props.model, ...configuration })} canModifyServerUrls={props.canModifyServerUrls} showAdvanced={props.showAdvanced} toggleShowAdvanced={props.toggleShowAdvanced} getFieldError={getFieldError} usePersistentVolumeStorage={props.usePersistentVolumeStorage} onUsePersistentVolumeStorageChanged={props.onUsePersistentVolumeStorageChanged} requiresHaConfiguration={props.requiresHaConfiguration} nameErrorMessages={ConfigurationMessages.Name.Worker} storageClassHelperText={ConfigurationPageStorageClassHelperContent(EndpointSelectionScope.Worker)} customFormComponents={<>
                    <WorkerPoolMultiSelectWithAddRefresh items={props.workerPools.filter((wp) => wp.CanAddWorkers)} openOnFocus={false} error={getFieldError("WorkerPools")} onChange={(WorkerPools) => props.onModelChange({ WorkerPools })} helperText={getFieldError("WorkerPools") ? "" : ConfigurationMessages.WorkerPools.HelperText} value={props.model.WorkerPools} addAction={(_) => setIsWorkerPoolFormOpen(true)} onRequestRefresh={refreshWorkerPools}/>
                    {isWorkerPoolFormOpen ? (<WorkerPoolCreationForm model={workerPoolConfiguration} onModelChange={updateModelAndClearServerValidationError} onCreate={handleOnCreate} onCancel={resetWorkerPoolForm} notProvidedErrorMessage={ConfigurationMessages.WorkerPool.Validation.NotProvided} helpText={ConfigurationMessages.WorkerPool.HelperText} error={workerPoolFieldErrors} isBusy={isCreating}/>) : null}
                    <InlineSnackbar variant="success" show={showWorkerPoolSnackBar} content={"Worker Pool Created"} onClose={() => setShowWorkerPoolSnackBar(false)} autoHideDuration={3000}/>
                </>}></ConfigurationPage>);
}
