import type { ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { VcsErrorPanel } from "~/areas/projects/components/VersionControl/VcsErrorPanel";
import { useProjectContext } from "~/areas/projects/context/index";
import ExternalLink from "~/components/Navigation/ExternalLink";
import SectionNote from "~/components/SectionNote/SectionNote";
interface SettingsNoteSectionProps {
    isVersionControlled: boolean;
}
function SettingsNoteSectionInternal(props: SettingsNoteSectionProps & {
    gitError: ValidateGitRefV2Response | undefined;
}) {
    const nonVcsSection = (<SectionNote>
            <div>
                Converting a project to version control allows you to store your deployment process and variables in a Git repository. Learn more about <ExternalLink href={"ConfigAsCodeEap"}>Config as Code.</ExternalLink>
            </div>
        </SectionNote>);
    const vcsErrorSection = <VcsErrorPanel content={<>{props.gitError?.Message}</>}/>;
    return (<>
            {props.isVersionControlled && props.gitError && vcsErrorSection}
            {!props.isVersionControlled && nonVcsSection}
        </>);
}
export function SettingsNoteSection(props: SettingsNoteSectionProps) {
    const projectContext = useProjectContext();
    return <SettingsNoteSectionInternal {...props} gitError={projectContext.state.gitRefValidationError}/>;
}
