import { type PageDefinition, PageProvider } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import type { PageIdentity } from "~/routing/pageRegistrations/PageIdentity";
import type { UnknownPageRegistration } from "~/routing/pageRegistrations/PageRegistration";
type SetPageRegistrationCallback = (registration: UnknownPageRegistration) => void;
const PageRegistrationContext = React.createContext<UnknownPageRegistration | null>(null);
const SetPageRegistrationContext = React.createContext<SetPageRegistrationCallback | null>(null);
interface PageRegistrationProviderProps {
    children: React.ReactNode;
}
const fallbackPageIdentity: PageIdentity = { pageId: "App", pageName: "App", pageArea: "App" };
export function PageRegistrationProvider({ children }: PageRegistrationProviderProps) {
    const [currentRegistration, setCurrentRegistration] = useState<UnknownPageRegistration | null>(null);
    const { pageId, pageArea, pageName } = currentRegistration?.pageIdentity ?? fallbackPageIdentity;
    const currentPage: PageDefinition = React.useMemo(() => ({ Id: pageId, Name: pageName, Area: pageArea }), [pageId, pageArea, pageName]);
    return (<PageProvider page={currentPage}>
            <SetPageRegistrationContext.Provider value={setCurrentRegistration}>
                <PageRegistrationContext.Provider value={currentRegistration}>{children}</PageRegistrationContext.Provider>
            </SetPageRegistrationContext.Provider>
        </PageProvider>);
}
export function usePageRegistration() {
    return React.useContext(PageRegistrationContext);
}
export function useSetPageRegistrationEffect(pageRegistration: UnknownPageRegistration) {
    const setPageRegistration = useSetPageRegistration();
    React.useEffect(() => setPageRegistration(pageRegistration), [setPageRegistration, pageRegistration]);
}
function useSetPageRegistration() {
    const setPageRegistration = React.useContext(SetPageRegistrationContext);
    if (setPageRegistration === null) {
        throw new Error("The PageRegistrationProvider has not been added to the component hierarchy.");
    }
    return setPageRegistration;
}
