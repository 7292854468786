import { ControlType, VariableType } from "@octopusdeploy/octopus-server-client";
import type { VariableValueModel } from "../VariablesModel";
export function convertToNewType(originalValue: VariableValueModel, newType: VariableType): VariableValueModel {
    const preserveData = originalValue.Type === newType || (originalValue.Type === VariableType.String && newType === VariableType.Sensitive);
    const converted = {
        ...originalValue,
        Value: preserveData ? originalValue.Value : null,
        Prompt: preserveData ? originalValue.Prompt : null,
        IsSensitive: preserveData ? originalValue.IsSensitive : false,
        Type: newType,
    };
    if (converted.Prompt) {
        // Prompted sensitive-variables will always have a display-type of sensitive
        if (newType === VariableType.Sensitive) {
            converted.Prompt.DisplaySettings = { "Octopus.ControlType": ControlType.Sensitive };
        }
        else if (newType !== VariableType.String) {
            // Any non-string variable types (certificates, accounts, etc) cannot currently be prompted variables
            converted.Prompt = null;
        }
    }
    return converted;
}
