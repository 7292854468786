import { css } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import WarningIcon from "~/components/WarningIcon/index";
export function VcsErrorPanel(props: {
    content: JSX.Element;
}) {
    return (<div className={vcsErrorPanelStyles}>
            <div className={titleContainerStyles}>
                <WarningIcon color="dangerHeaderText"/>
                <span>There was an error accessing version control.</span>
            </div>
            <div className={contentStyles}>{props.content}</div>
        </div>);
}
const vcsErrorPanelStyles = css({
    padding: space["16"],
    backgroundColor: themeTokens.color.background.danger,
    boxShadow: `inset 3px 0 0 ${themeTokens.color.background.danger}`,
});
const titleContainerStyles = css({
    color: themeTokens.color.text.danger,
});
const contentStyles = css({
    paddingTop: space["16"],
});
