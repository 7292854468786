/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { LinearProgress } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { Unsubscribe, SpaceResource } from "@octopusdeploy/octopus-server-client";
import { client, repository, session } from "app/clientInstance";
import InternalRedirect from "app/components/Navigation/InternalRedirect/index";
import * as React from "react";
import type { MapStateToProps } from "react-redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import type { RouteComponentProps } from "react-router";
import type { Dispatch } from "redux";
import { configurationActions } from "~/areas/configuration/reducers/configurationArea";
import type { SpaceRouteParams } from "~/components/Navbar/SpaceRouteParams";
import { spaceLoaderLinks } from "~/components/SpaceLoader/spaceLoaderLinks";
import useLocalStorage from "~/hooks/useLocalStorage";
import store from "~/store";
import callAll from "~/utils/callAll";
export interface SpaceNotFoundContext {
    // If you have access to zero space, they we don't need to prompt users to enter a space context,
    // and they can continue to navigate around because they are already in the "system" context
    isAlsoInSystemContext: boolean;
    missingSpaceId: string; // This id of the space which could not be found
}
type SystemContext = "system";
export type SpaceContext = SpaceResource | SystemContext | SpaceNotFoundContext;
export function isSpecificSpaceContext(spaceContext: SpaceContext): spaceContext is SpaceResource {
    return !!(spaceContext as SpaceResource).Id;
}
export function isSpaceOrSystemContext(spaceContext: SpaceContext): spaceContext is SpaceResource | SystemContext {
    return spaceContext === "system" || isSpecificSpaceContext(spaceContext);
}
export function isSpaceNotFound(spaceContext: SpaceContext): spaceContext is SpaceNotFoundContext {
    return !isSpaceOrSystemContext(spaceContext);
}
interface SpaceLoaderState {
    redirectTo: string | null;
    currentSpaceContext?: SpaceContext;
}
interface GlobalDispatchProps {
    onUserAccessibleSpacesLoaded(spaces: SpaceResource[]): void;
}
interface GlobalConnectedProps {
    spaces: SpaceResource[] | null; // null indicates that the spaces haven't yet been loaded
}
interface ProvidedProps {
    lastKnownSpaceId?: string;
    onChangeSpace?: (id: string) => void;
    render(spaceContext: SpaceContext): React.ReactNode;
}
type PropsExceptReduxProps = ProvidedProps & RouteComponentProps<SpaceRouteParams>;
type SpaceLoaderProps = PropsExceptReduxProps & GlobalDispatchProps & GlobalConnectedProps;
//eslint-disable-next-line react/no-unsafe
class SpaceLoader extends React.Component<SpaceLoaderProps, SpaceLoaderState> {
    private environmentsChangedSubscriptionCleanup: Unsubscribe | null = null;
    constructor(props: SpaceLoaderProps) {
        super(props);
        this.state = {
            redirectTo: null,
        };
    }
    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<SpaceLoaderProps>) {
        const nextId = nextProps.match.params.spaceId || (nextProps.match.isExact && nextProps.match.url === spaceLoaderLinks.appRoot && nextProps.lastKnownSpaceId);
        const foundSpace = nextProps.spaces && nextProps.spaces.find((x) => x.Id === nextId);
        if (nextProps.spaces && nextProps.spaces !== this.props.spaces) {
            await this.switchToSpace(nextProps.spaces, foundSpace ? foundSpace.Id : nextProps.match.params.spaceId, nextProps.location.pathname);
        }
    }
    async componentDidMount() {
        this.environmentsChangedSubscriptionCleanup = client.subscribe((event) => {
            if (event.type === "SpaceModified" || event.type === "SpaceDeleted" || event.type === "SpaceCreated") {
                this.loadSpaces();
            }
        });
        return this.loadSpaces();
    }
    componentWillUnmount() {
        this.environmentsChangedSubscriptionCleanup?.();
    }
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo}/>;
        }
        return this.state.currentSpaceContext ? this.props.render(this.state.currentSpaceContext) : <LinearProgress variant={"indeterminate"} show={true}/>;
    }
    private loadSpaces = async () => {
        try {
            const spaces = await repository.Users.getSpaces(session.currentUser!);
            // This updates redux, which makes this component update as part of componentWillReceiveProps
            this.props.onUserAccessibleSpacesLoaded(spaces);
        }
        catch (e) {
            logger.error(e, "Failed to load spaces");
        }
    };
    private async switchToSpace(spaces: SpaceResource[], spaceId: string, pathname: string) {
        try {
            if (spaceId) {
                await this.stepIntoSelectedSpaceId(spaces, spaceId);
            }
            else {
                // default to the first space returned from the API, but never resolve to a user's private space
                const automaticallySelectedSpace = spaces.find((s) => s.IsDefault) || spaces.find((space) => !space.IsPrivate);
                if (automaticallySelectedSpace) {
                    this.redirectToSpace(automaticallySelectedSpace, pathname);
                }
                else if (this.state.currentSpaceContext !== "system") {
                    await this.stepIntoNoSpaceSelectedContext();
                    this.setState({ currentSpaceContext: "system" });
                }
            }
        }
        catch (e) {
            logger.error(e, "Failed to switch to space");
        }
    }
    private redirectToSpace(space: SpaceResource, pathname: string) {
        this.setState({ redirectTo: `${spaceLoaderLinks.space(space.Id)}${pathname}` });
    }
    private async stepIntoSelectedSpaceId(spaces: SpaceResource[], spaceId: string) {
        const selectedSpace = spaces.find((s) => s.Id === spaceId);
        if (selectedSpace) {
            const alreadyInTheSelectedSpace = this.state.currentSpaceContext && isSpecificSpaceContext(this.state.currentSpaceContext) && selectedSpace.Id === this.state.currentSpaceContext.Id;
            if (!alreadyInTheSelectedSpace) {
                await this.stepIntoSpace(selectedSpace);
            }
        }
        else {
            await this.stepIntoNoSpaceSelectedContext();
            const isAlsoInSystemContext = spaces.length === 0;
            this.setState({ currentSpaceContext: { isAlsoInSystemContext, missingSpaceId: spaceId } });
        }
    }
    private async stepIntoNoSpaceSelectedContext() {
        repository.switchToSystem();
        await this.refreshPermissions();
    }
    private async stepIntoSpace(space: SpaceResource) {
        await repository.switchToSpace(space.Id);
        const refreshPermissions = this.refreshPermissions();
        const status = await repository.Tenants.status();
        store.dispatch(configurationActions.spaceMultiTenancyStatusFetched(status));
        this.props.onChangeSpace!(space.Id);
        if (this.props.match.params.spaceId !== space.Id) {
            this.setState({ redirectTo: space.Id });
        }
        await refreshPermissions;
        this.setState({ currentSpaceContext: space });
    }
    private async refreshPermissions() {
        const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser!, true);
        session.refreshPermissions(permissionSet);
    }
    static displayName = "SpaceLoader";
}
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch): GlobalDispatchProps => {
    return {
        onUserAccessibleSpacesLoaded: (spaces: SpaceResource[]) => {
            dispatch(configurationActions.userAccessibleSpacesFetched(spaces));
        },
    };
};
const mapGlobalStateToProps: MapStateToProps<GlobalConnectedProps, PropsExceptReduxProps, GlobalState> = (state) => {
    return {
        spaces: state.configurationArea.spaces ? state.configurationArea.spaces.usersAccessibleSpaces : null,
    };
};
const withPersistedSpace = (Component: React.ComponentType<SpaceLoaderProps>) => {
    const WithPersistedSpace: React.FC<SpaceLoaderProps> = (props) => {
        const [lastKnownSpaceId, setValue] = useLocalStorage<string>("octo-space", props.lastKnownSpaceId!);
        const handleSpaceChange = React.useMemo(() => callAll(setValue, props.onChangeSpace), [setValue, props.onChangeSpace]);
        return <Component {...props} lastKnownSpaceId={lastKnownSpaceId} onChangeSpace={handleSpaceChange}/>;
    };
    WithPersistedSpace.displayName = "WithPersistedSpace"
    return WithPersistedSpace;
};
export default withRouter(connect(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(withPersistedSpace(SpaceLoader)));
