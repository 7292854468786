import { type AccountResource, AccountType, type CertificateResource, type WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import type { SourceItems } from "~/components/TenantVariableInput/TenantVariableInput";
export interface TenantVariableResourceOptions {
    accounts: AccountsOptions;
    certificates: TenantCertificatesLazyOptions;
    workerPools: WorkerPoolsLazyOptions;
    get: (tenantId: string) => LoadedSourceItems;
}
export interface LoadedSourceItems extends SourceItems {
    certificates: {
        items(): Promise<CertificateResource[]>;
        onRequestRefresh(): Promise<boolean>;
        loadedItems: CertificateResource[];
    };
    workerPools: {
        items(): Promise<WorkerPoolResource[]>;
        onRequestRefresh(): Promise<boolean>;
        loadedItems: WorkerPoolResource[];
    };
}
export function useTenantVariableResourceOptions(doBusyTask: DoBusyTask): TenantVariableResourceOptions {
    const accounts = useAccountsOptions(doBusyTask);
    const certificates = useTenantCertificatesLazyOptions(doBusyTask);
    const workerPools = useWorkerPoolsLazyOptions(doBusyTask);
    const getForTenant = (tenantId: string): LoadedSourceItems => ({
        accounts,
        certificates: certificates.get(tenantId),
        workerPools,
        tenantId,
    });
    return { accounts, certificates, workerPools, get: getForTenant };
}
interface AccountsOptions {
    type: AccountType | AccountType[];
    items: AccountResource[];
    onRequestRefresh(): Promise<void>;
}
function useAccountsOptions(doBusyTask: DoBusyTask): AccountsOptions {
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);
    const [allAccounts, setAllAccounts] = useState<AccountResource[]>([]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        await doBusyTask(async () => {
            const accounts = await repository.Accounts.all();
            setAllAccounts(accounts);
        });
    }, [reloadTrigger]);
    const refresh = async (): Promise<void> => {
        setReloadTrigger((currentTrigger) => !currentTrigger);
        return Promise.resolve();
    };
    return {
        type: [AccountType.AzureServicePrincipal, AccountType.AzureSubscription, AccountType.AzureOidc],
        items: allAccounts,
        onRequestRefresh: refresh,
    };
}
interface TenantCertificatesLazyOptions {
    get: (tenantId: string) => {
        items(): Promise<CertificateResource[]>;
        onRequestRefresh(): Promise<boolean>;
        loadedItems: CertificateResource[];
    };
}
function useTenantCertificatesLazyOptions(doBusyTask: DoBusyTask): TenantCertificatesLazyOptions {
    const [allCertificates, setAllCertificates] = useState<Map<string, CertificateResource[]>>(new Map());
    const getOrLoad = async (tenantId: string): Promise<CertificateResource[]> => {
        if (allCertificates.has(tenantId)) {
            return allCertificates.get(tenantId) || [];
        }
        let loadedCertificates: CertificateResource[] = [];
        await doBusyTask(async () => {
            loadedCertificates = await repository.Certificates.listForTenant(tenantId);
            const updated = new Map(allCertificates);
            updated.set(tenantId, loadedCertificates);
            setAllCertificates(updated);
        });
        return loadedCertificates;
    };
    const refresh = async (tenantId: string): Promise<boolean> => doBusyTask(async () => {
        const tenantCertificates = await repository.Certificates.listForTenant(tenantId);
        const updated = new Map(allCertificates);
        updated.set(tenantId, tenantCertificates);
        setAllCertificates(updated);
        return true;
    });
    const buildForTenant = (tenantId: string) => {
        return {
            items: () => getOrLoad(tenantId),
            onRequestRefresh: () => refresh(tenantId),
            loadedItems: allCertificates.get(tenantId) ?? [],
        };
    };
    return { get: buildForTenant };
}
interface WorkerPoolsLazyOptions {
    items(): Promise<WorkerPoolResource[]>;
    onRequestRefresh(): Promise<boolean>;
    loadedItems: WorkerPoolResource[];
}
function useWorkerPoolsLazyOptions(doBusyTask: DoBusyTask): WorkerPoolsLazyOptions {
    const [workerPools, setWorkerPools] = useState<WorkerPoolResource[]>([]);
    const getOrLoad = async (): Promise<WorkerPoolResource[]> => {
        if (workerPools.length > 0) {
            return workerPools;
        }
        let loadedWorkerPools: WorkerPoolResource[] = [];
        await doBusyTask(async () => {
            loadedWorkerPools = await repository.WorkerPools.all();
            setWorkerPools(loadedWorkerPools);
        });
        return loadedWorkerPools;
    };
    const refresh = async (): Promise<boolean> => doBusyTask(async () => {
        const loadedWorkerPools = await repository.WorkerPools.all();
        setWorkerPools(loadedWorkerPools);
        return true;
    });
    return { items: getOrLoad, onRequestRefresh: refresh, loadedItems: workerPools };
}
