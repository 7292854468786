import { css } from "@emotion/css";
import { ActionButtonType, EmptyStateDashboardIllustration } from "@octopusdeploy/design-system-components";
import { text } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import PermissionCheck from "app/components/PermissionCheck/PermissionCheck";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action } from "~/analytics/Analytics";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { Level1EmptyStateLayout } from "~/components/GettingStarted/Level1EmptyStateLayout";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import AddNewProjectDialog from "~/components/ProjectBasedActivation/AddNewProjectDialog";
interface ProjectOnboardingProps {
    hasProjectGroups: boolean;
    hasProjects: boolean;
    dispatchAction: AnalyticActionDispatcher;
}
export function Onboarding({ hasProjectGroups, hasProjects, dispatchAction }: ProjectOnboardingProps) {
    const onboardingIntro = (<div className={onboardingIntroContainerStyles}>
            <span>We'll guide you along the process of adding your first project through to releasing your first deployment.</span>
        </div>);
    return (<Level1EmptyStateLayout title={"Deploy your first application"} intro={onboardingIntro} actionButtonsAndLinks={[
            <PermissionCheck permission={Permission.ProjectCreate} projectGroup="*">
                    <OpenDialogButton type={ActionButtonType.Primary} label={"Add Project"} disabled={!hasProjectGroups} dialogWidth={"1000px"} renderDialog={(renderProps) => (<AddNewProjectDialog open={renderProps.open} close={(project) => {
                        if (!hasProjects) {
                            if (project) {
                                dispatchAction("Save Project", { action: Action.Save, resource: "Project" });
                            }
                            else {
                                dispatchAction("Cancel Project Creation", { action: Action.Cancel, resource: "Project" });
                            }
                        }
                        renderProps.closeDialog();
                    }}/>)} onClick={() => dispatchAction("Add Project", { resource: "Project", action: Action.Add })}/>
                </PermissionCheck>,
            <div>
                    <ExternalVideoLink href="OnboardingGettingStartedVideo" className={learnMoreLinkStyles}>
                        Watch the video (3 mins)
                    </ExternalVideoLink>
                </div>,
        ]} image={<EmptyStateDashboardIllustration />}/>);
}
const onboardingIntroContainerStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
});
const learnMoreLinkStyles = css({
    font: text.link.default.medium,
    alignSelf: "flex-start",
});
