import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import type { SpaceContext } from "app/components/SpaceLoader/SpaceLoader";
import { isEqual } from "lodash";
import type { ReactNode } from "react";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ErrorPanel from "~/components/ErrorPanel";
import { Navbar } from "~/components/Navbar";
import Sticky from "~/components/Sticky/Sticky";
import SystemMessagesBanner from "~/components/SystemMessagesBanner/SystemMessagesBanner";
import { clearUnhandledErrors, currentUnhandledErrorSelector } from "~/components/UnhandledError/reducers";
import type { UnhandledErrorState } from "~/components/UnhandledError/reducers";
import { zIndexStickyTopPanel } from "~/theme";
import { client } from "../../clientInstance";
import { ContextualHelpLayout } from "../ContextualHelpLayout/ContextualHelpLayout";
interface MainNavbarLayoutInternalProps {
    unhandledError: UnhandledErrorState;
    onErrorClose(): void;
    spaceContext: SpaceContext;
    children: ReactNode;
}
class MainNavbarLayoutInternal extends React.Component<MainNavbarLayoutInternalProps> {
    // @Performance - HashRouter was causing re-renders when query-strings were changing.
    // We should only be re-rendering our layout if these top-level props change.
    shouldComponentUpdate(nextProps: MainNavbarLayoutInternalProps) {
        const unhandledErrorChanged = !isEqual(nextProps.unhandledError, this.props.unhandledError);
        const onErrorCloseChanged = !isEqual(nextProps.onErrorClose, this.props.onErrorClose);
        return unhandledErrorChanged || onErrorCloseChanged;
    }
    render() {
        return (<>
                <MainNavbar spaceContext={this.props.spaceContext} onErrorClose={this.props.onErrorClose} unhandledError={this.props.unhandledError}/>
                <ContextualHelpLayout>{this.props.children}</ContextualHelpLayout>
            </>);
    }
    private onErrorClose = () => {
        this.props.onErrorClose();
    };
    static displayName = "MainNavbarLayoutInternal";
}
function MainNavbar({ spaceContext, unhandledError, onErrorClose }: {
    spaceContext: SpaceContext;
    unhandledError: UnhandledErrorState;
    onErrorClose(): void;
}) {
    const serverInfo = client.tryGetServerInformation();
    const version = serverInfo ? serverInfo.version : undefined;
    return (<Sticky innerZ={zIndexStickyTopPanel} enableTransforms={false}>
            <div id="toppanel">
                <AnalyticLinkLocationProvider location="Main Navigation">{<Navbar spaceContext={spaceContext}/>}</AnalyticLinkLocationProvider>
                {unhandledError && (<AnalyticLinkLocationProvider location="Main Navigation Error">
                        <ErrorPanel message={`An unexpected error occurred in Octopus v${version}: ${unhandledError.message}`} errors={unhandledError.errors} fullWidth={true} canClose={true} onErrorClose={onErrorClose}/>
                    </AnalyticLinkLocationProvider>)}
                <SystemMessagesBanner />
            </div>
        </Sticky>);
}
interface GlobalDispatchProps {
    onErrorClose(): void;
}
export const MainNavbarLayout = connect((state: GlobalState) => {
    return {
        unhandledError: currentUnhandledErrorSelector(state),
    };
}, (dispatch): GlobalDispatchProps => {
    return {
        onErrorClose: bindActionCreators(clearUnhandledErrors, dispatch),
    };
})(MainNavbarLayoutInternal);
