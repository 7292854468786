export type ArtifactEqualToPredicate<TArtifact> = (a1: TArtifact, a2: TArtifact) => boolean;
export const saveArtifact = <TArtifact extends {
    Name?: string;
}>(updatedArtifact: TArtifact, artifacts: TArtifact[], editingArtifact: TArtifact | undefined, equalsToPredicate: ArtifactEqualToPredicate<TArtifact>, namePrefix: string): [
    string,
    TArtifact[]
] => {
    // clone the existing artifacts
    const updatedArtifacts = [...artifacts];
    // This regex is used to find the indexes of all the values artifacts
    const regEx = new RegExp(`${namePrefix}-(?<idx>[0-9]+)`);
    if (editingArtifact) {
        //when editing, we find existing item based on the predicate, then override it
        const index = updatedArtifacts.findIndex((artifact) => equalsToPredicate(artifact, editingArtifact));
        updatedArtifacts[index] = updatedArtifact;
    }
    else {
        const valuesArtifactIndexes = updatedArtifacts
            .map((artifact) => artifact.Name)
            .map((name) => name?.match(regEx))
            .map((matchArray) => (matchArray?.groups ? parseInt(matchArray.groups.idx) : 0));
        //if there is no existing artifacts to get indexes from, then the maxIndex must be 0
        const maxIndex = valuesArtifactIndexes.length > 0 ? Math.max(...valuesArtifactIndexes) : 0;
        const newIndex = maxIndex + 1;
        updatedArtifact = { ...updatedArtifact, Name: `${namePrefix}-${newIndex}` };
        updatedArtifacts.push(updatedArtifact);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return [updatedArtifact.Name!, updatedArtifacts];
};
export const removeArtifact = <TArtifact>(artifactToRemove: TArtifact, artifacts: TArtifact[], equalsToPredicate: ArtifactEqualToPredicate<TArtifact>): TArtifact[] => {
    const updatedArtifacts = [...artifacts];
    const index = updatedArtifacts.findIndex((gd) => equalsToPredicate(gd, artifactToRemove));
    updatedArtifacts.splice(index, 1);
    return updatedArtifacts;
};
