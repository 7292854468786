import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
export interface CommonProjectTriggerReleaseViewsProps {
    create: boolean;
}
export const CommonProjectTriggerReleaseViews = ({ create }: CommonProjectTriggerReleaseViewsProps) => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="ExternalFeedTriggers">External feed triggers</ExternalLink> allow you to specify container images or Helm charts that will automatically trigger a release of your project.
                </p>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Channels"}>Each trigger creates releases for a single channel. Selected packages for the release will be restricted to the channel version rules configured for that step/package.</Note>
                <Note>Each channel may only be referenced by a single external feed trigger at a time.</Note>
            </TermsText>
            <TermsText>
                <Note heading={"Trigger sources"}>Packages from Helm chart or container image registries are supported for external feed triggers. Select one or more packages to monitor for new versions.</Note>
                <Note>When a new version of any one of the select packages is detected, a new release will be created using the project's release versioning rules.</Note>
            </TermsText>
            {!create && (<TermsText>
                    <Note heading={"History"}>The results of each trigger execution will be updated periodically and captured in this section.</Note>
                    <Note>
                        Full logs are captured in the system task log, these require the system level <code>TaskView</code> permission to view.
                    </Note>
                </TermsText>)}
        </OverviewContainer>);
};
