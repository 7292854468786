import type { KubernetesConfigMapProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import { KubernetesConfigMapComponent } from "~/components/Actions/kubernetes/kubernetesConfigMapComponent";
import type { ScriptPackageProperties } from "~/components/Actions/script/ScriptPackageReferenceDialog";
import type { ActionEditProps } from "../../Actions/pluginRegistry";
import pluginRegistry from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
class KubernetesConfigMapFeature extends BaseComponent<ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>, never> {
    render() {
        return (<KubernetesConfigMapComponent properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} errors={this.props.errors} projectId={this.props.projectId} gitRef={this.props.gitRef} expandedByDefault={this.props.expandedByDefault} importLabels={false}/>);
    }
    static displayName = "KubernetesConfigMapFeature";
}
pluginRegistry.registerFeature({
    featureName: "Octopus.Features.KubernetesConfigMap",
    title: "Config Map",
    description: "Defines a Kubernetes ConfigMap resource, which is a collection of key/value settings",
    edit: KubernetesConfigMapFeature,
    priority: 20,
});
