import { Callout } from "@octopusdeploy/design-system-components";
import type { NewRunbookResource, ProjectResource, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { HasVariablesInGit, HasRunbooksInGit, RunbookEnvironmentScope, GuidedFailureMode, HasGitPersistenceSettings } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { required, Text, MarkdownEditor, Select, Note } from "~/components/form";
export interface AddRunbookProps {
    projectId: string;
    cloneId?: string;
    onProcessCreated(id: string): Promise<void>;
}
interface AddRunbookState extends DataBaseComponentState {
    name: string;
    description: string;
    selectedCloneId: string | undefined;
    projectRunbooks: RunbookResource[];
    showAdvanced: boolean;
    commitMessage: CommitMessageWithDetails;
}
type Props = AddRunbookProps & WithProjectContextInjectedProps;
const defaultCommitSummary = "Create new Runbook";
class AddRunbookInternal extends DataBaseComponent<Props, AddRunbookState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            selectedCloneId: this.props.cloneId,
            projectRunbooks: [],
            commitMessage: {
                details: "",
                summary: "",
            },
            showAdvanced: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const projectRunbooks: RunbookResource[] = await this.getProjectRunbooks(project);
            this.setState((_) => ({
                projectRunbooks,
            }));
        });
    }
    getProjectRunbooks = async (project: ProjectResource): Promise<RunbookResource[]> => {
        if (!this.props.cloneId) {
            return [];
        }
        return await repository.Projects.getAllRunbooksByProjectV2(project);
    };
    async save() {
        const newRunbookResource: NewRunbookResource = {
            Name: this.state.name,
            Description: this.state.description,
            MultiTenancyMode: this.props.projectContext.state.model.TenantedDeploymentMode, //Default to the project's setting
            EnvironmentScope: RunbookEnvironmentScope.All,
            DefaultGuidedFailureMode: GuidedFailureMode.EnvironmentDefault,
            RunRetentionPolicy: {
                QuantityToKeep: 100,
                ShouldKeepForever: false,
            },
            ForcePackageDownload: false,
            ProjectId: this.props.projectId,
        };
        const isGitRunbook = HasRunbooksInGit(this.props.projectContext.state.model.PersistenceSettings);
        return this.doBusyTask(async () => {
            let result: RunbookResource | undefined = undefined;
            if (this.state.selectedCloneId) {
                if (isGitRunbook) {
                    throw new Error("Cloning runbooks is not supported for Git runbooks."); // This will be supported before release, but not right now.
                }
                // If we are cloning, we still need to use the old create or clone endpoint.
                result = await repository.Runbooks.create(newRunbookResource, { clone: this.state.selectedCloneId });
            }
            else {
                result = isGitRunbook
                    ? await repository.Runbooks.createRunbook(newRunbookResource, this.props.projectContext.state.model.SpaceId, this.props.projectId, this.state.name, this.props.projectContext.state.gitRef?.CanonicalName)
                    : await repository.Runbooks.createRunbook(newRunbookResource, this.props.projectContext.state.model.SpaceId, this.props.projectId, this.state.name);
            }
            await this.props.onProcessCreated(result.Id);
            // Update project summary
            await this.props.projectContext.actions.onProjectUpdated(this.props.projectContext.state.model, this.props.projectContext.state.gitRef);
        });
    }
    render() {
        const persistenceSettings = this.props.projectContext.state.model.PersistenceSettings;
        return (<SaveDialogLayout title={"Add New Runbook"} busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()} saveButtonLabel={HasRunbooksInGit(persistenceSettings) ? "Commit" : "Save"}>
                {HasGitPersistenceSettings(persistenceSettings) && HasVariablesInGit(persistenceSettings) && !HasRunbooksInGit(persistenceSettings) && (<Callout type={"information"} title="Version control project">
                        Runbooks will use variables from the <code>{persistenceSettings.DefaultBranch}</code> branch.
                    </Callout>)}
                <Text label="Name" accessibleName="New runbook name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a Runbook name")} autoFocus={true}/>
                <MarkdownEditor value={this.state.description} label="Description" accessibleName="New runbook description" bottomNoteText="Any description will be shown at the top of this Runbook's Overview page." onChange={(description) => this.setState({ description })}/>
                {this.props.cloneId && this.state.projectRunbooks && this.renderRunbooks(this.state.projectRunbooks)}
            </SaveDialogLayout>);
    }
    private renderRunbooks(runbooks: RunbookResource[]) {
        return [
            <Select allowClear={true} allowFilter={true} value={this.state.selectedCloneId} onChange={(cloneId) => this.setState({ selectedCloneId: cloneId })} items={runbooks.map((pg) => ({ value: pg.Id, text: pg.Name }))} label="Clone from"/>,
            <Note>Select an existing Runbook to clone. The steps in the other Runbook will be copied into the new Runbook when it is created.</Note>,
        ];
    }
    static displayName = "AddRunbookInternal";
}
const AddRunbook: React.FC<AddRunbookProps> = (props) => {
    const projectContext = useProjectContext();
    return <AddRunbookInternal {...props} projectContext={projectContext}/>;
};
AddRunbook.displayName = "AddRunbook"
export default AddRunbook;
