import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryCertificateOverview } from "../../CommonComponents/CommonLibraryCertificateViews";
export const LibraryCertificatesArchiveRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.certificates.archive,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryCertificateOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
