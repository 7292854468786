import { logger } from "@octopusdeploy/logging";
import * as React from "react";
import { withTheme } from "~/components/Theme";
import avatarPlaceHolder from "./img/avatar-placeholder.svg";
import octopusServiceImage from "./img/octopus-service-440.svg";
import userDefaultImage from "./img/user-placeholder.svg";
import styles from "./style.module.less";
interface AvatarProps {
    size?: number;
    isService: boolean;
    avatarLink: string;
}
export const Avatar: React.StatelessComponent<AvatarProps> = (props) => {
    const [avatarImage, setAvatarImage] = React.useState("");
    const [hasLoadImageError, setHasLoadImageError] = React.useState(false);
    const size = props.size || 25;
    React.useEffect(() => {
        let avatarSource = props.isService ? octopusServiceImage : avatarPlaceHolder;
        if (props.avatarLink && !props.isService) {
            try {
                const url = new URL(props.avatarLink);
                url.searchParams.set("d", "404");
                url.searchParams.set("s", size.toString());
                avatarSource = url.toString();
            }
            catch (error) {
                logger.error(error, "Invalid URL for Avatar");
                setHasLoadImageError(true);
                return;
            }
        }
        const loadAvatarImage = (imageSource: string) => {
            const img = new Image();
            img.onload = () => {
                setAvatarImage(imageSource);
                setHasLoadImageError(false);
            };
            img.onerror = () => setHasLoadImageError(true);
            img.src = imageSource;
        };
        loadAvatarImage(avatarSource);
    }, [props.isService, props.avatarLink, props.size, size]);
    return withTheme((theme) => (
    // Note: Do NOT use Material-UI's Avatar component to render avatars, as we need a fallback background image and
    // if you try to do this with the <img> tag, it'll then show broken external images on closed networks (I.e. networks
    // that can't access gravatar).
    <div className={styles.avatarContainer} style={{
            width: size + "px",
            height: size + "px",
            backgroundColor: theme.whiteConstant,
            backgroundImage: hasLoadImageError ? `url("${userDefaultImage}")` : `url("${avatarImage}")`,
        }} aria-label="User Avatar" role="img"></div>));
};
Avatar.displayName = "Avatar"
export default Avatar;
