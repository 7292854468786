import { Callout } from "@octopusdeploy/design-system-components";
import type { AccountResource, ProjectResource, VariableSetResource, GitRef } from "@octopusdeploy/octopus-server-client";
import { AccountType, VariableType } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { Action, useAnalyticCloudConnectionsDispatch } from "~/analytics/Analytics";
import { CloudConnectionsDrawer } from "~/areas/projects/components/CloudConnection/CloudConnectionDrawer";
import { getVariable, setVariableValue } from "~/areas/projects/components/CloudConnection/CloudConnectionUtilities";
import { VariableNames } from "~/areas/projects/components/CloudConnection/CloudConnectionVariables";
import type { CloudConnectionItem } from "~/areas/projects/components/CloudConnection/CloudConnections";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import { repository } from "~/clientInstance";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import { FormSection } from "~/components/form/Sections/index";
export interface AzureCloudConnectionDrawerDetailsProps {
    cloudConnectionItem: CloudConnectionItem;
    variables: VariableSetResource | undefined;
    saveVariables: (variableSet: VariableSetResource, commitMessage: CommitMessageWithDetails | undefined) => Promise<VariableSetResource>;
    showDrawer: boolean;
    setShowDrawer: (show: boolean) => void;
    refreshVariables: () => void;
    haveVariablesChanged: (currentVariables: VariableSetResource) => Promise<boolean>;
    project: ProjectResource;
    gitRef?: GitRef;
}
interface AzureCloudConnectionDrawerDetails {
    type: "Azure";
    accountId?: string;
    accountIdValidationMessage?: string;
}
function getDrawerDetails(cloudConnection: CloudConnectionItem, variables: VariableSetResource | undefined): AzureCloudConnectionDrawerDetails {
    const details: AzureCloudConnectionDrawerDetails = { type: "Azure" };
    if (variables) {
        const account = getVariable(VariableNames.Azure.Account, variables);
        if (account?.Value) {
            details.accountId = account.Value;
        }
    }
    return details;
}
const AzureCloudConnectionDrawer: React.FC<AzureCloudConnectionDrawerDetailsProps> = (props) => {
    const [drawerDetails, setDrawerDetails] = useState<AzureCloudConnectionDrawerDetails>(getDrawerDetails(props.cloudConnectionItem, props.variables));
    const [variablesChanged, setVariablesChanged] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<AccountResource[]>([]);
    const dispatchAction = useAnalyticCloudConnectionsDispatch();
    const refreshAccounts = async (): Promise<void> => {
        const accountsToSet = await repository.Accounts.all();
        setAccounts(accountsToSet);
    };
    const validateDrawer = (): boolean => {
        let valid = true;
        let accountIdValidationMessage: string | undefined = undefined;
        if (!drawerDetails.accountId) {
            accountIdValidationMessage = "Account ID is required";
            valid = false;
        }
        if (!valid) {
            setDrawerDetails({ ...drawerDetails, accountIdValidationMessage: accountIdValidationMessage });
        }
        return valid;
    };
    async function SaveDrawerDetails(commitMessage: CommitMessageWithDetails | undefined) {
        if (props.variables) {
            if (drawerDetails?.accountId) {
                setVariableValue(props.variables, VariableNames.Azure.Account, drawerDetails.accountId, VariableType.AzureAccount);
            }
            dispatchAction("Save Cloud Connections Drawer", {
                action: Action.Save,
                resource: "Cloud Connections",
                cloudProvider: "Azure",
            });
            await props.saveVariables(props.variables, commitMessage);
        }
    }
    return (<CloudConnectionsDrawer actionName={`Azure Cloud Connection`} open={props.showDrawer} cancel={() => {
            dispatchAction(`Close Cloud Connections Drawer`, {
                action: Action.Cancel,
                resource: "Cloud Connections",
                cloudProvider: "Azure",
            });
            props.setShowDrawer(false);
            setDrawerDetails(getDrawerDetails(props.cloudConnectionItem, props.variables));
            if (variablesChanged) {
                props.refreshVariables();
            }
            setVariablesChanged(false);
        }} okDisabled={variablesChanged} onSubmit={async (commitMessage) => {
            if (drawerDetails && props.variables) {
                if (!validateDrawer())
                    return;
                const varChanged = await props.haveVariablesChanged(props.variables);
                setVariablesChanged(varChanged);
                if (varChanged) {
                    return;
                }
                await SaveDrawerDetails(commitMessage);
            }
            props.setShowDrawer(false);
            setDrawerDetails(getDrawerDetails(props.cloudConnectionItem, props.variables));
        }} onLoad={refreshAccounts} setShowDrawer={props.setShowDrawer} project={props.project} gitRef={props.gitRef}>
            {variablesChanged ? (<Callout title="Unable to save your Cloud Connection" type={"danger"}>
                    Changes to the Cloud Connection could not be saved, because another user has made changes to the variables between when you started editing and when you saved your changes. Please close the drawer to reload Cloud Connections.
                </Callout>) : (<></>)}
            <FormSection title="Account" help="Select or connect your Azure account">
                <AccountSelect disabled={variablesChanged} onRequestRefresh={refreshAccounts} type={[AccountType.AzureServicePrincipal, AccountType.AzureOidc]} value={drawerDetails.accountId ?? ""} error={drawerDetails.accountIdValidationMessage} warning={undefined} allowClear={true} onChange={(accountId) => {
            if (drawerDetails) {
                setDrawerDetails({ ...drawerDetails, accountId: accountId });
            }
        }} items={accounts}/>
            </FormSection>
        </CloudConnectionsDrawer>);
};
AzureCloudConnectionDrawer.displayName = "AzureCloudConnectionDrawer"
export default AzureCloudConnectionDrawer;
