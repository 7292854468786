/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { StartTrigger, SpecialVariables } from "@octopusdeploy/octopus-server-client";
import type { ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useProjectContext } from "~/areas/projects/context";
import Roles from "~/components/Actions/Roles";
import type { Errors } from "~/components/DataBaseComponent";
import { StepRolling } from "~/components/Images/Process/StepRolling";
import { useProcessContext } from "../Contexts/ProcessContext";
import { useProcessErrorSelectors } from "../Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessWarningSelectors } from "../Contexts/ProcessWarnings/ProcessWarningsContext";
import type { ProcessListLayoutLoaderLookupData } from "../ProcessListLayoutLoader";
import type { StoredStep } from "../types";
import ProcessListItem from "./ProcessListItem";
import processListItemStyles from "./ProcessListItem.module.less";
import ProcessListPageListItemForAction from "./ProcessListPageListItemForAction";
interface ProcessListPageListItemForParentStepProps {
    lookups: ProcessListLayoutLoaderLookupData;
    actionTemplates: ActionTemplateSearchResource[];
    step: StoredStep;
    stepIndex?: number;
    errors: Errors | undefined;
}
const ProcessListPageListItemForParentStep: React.FC<ProcessListPageListItemForParentStepProps> = ({ lookups, actionTemplates, errors, step, stepIndex }) => {
    const { selectors, actions } = useProcessContext();
    const { state: { model: project, gitRef }, } = useProjectContext();
    const maxParallelism = step.Properties[SpecialVariables.Action.MaxParallelism];
    const showWindowSize = typeof maxParallelism === "string" && maxParallelism ? maxParallelism.length > 0 : false;
    const parentStepLabel = React.useMemo(() => showWindowSize ? (<span>Rolling deployment</span>) : (<span>
                    Multi-step deployment across
                    <br />
                    deployment targets
                </span>), [showWindowSize]);
    const icon = React.useMemo(() => <StepRolling height="2.6rem" className={processListItemStyles.stepIcon}/>, []);
    const processErrorSelectors = useProcessErrorSelectors();
    const stepErrors = processErrorSelectors.getStepErrors(step.Id);
    const processWarningSelectors = useProcessWarningSelectors();
    const stepWarnings = processWarningSelectors.getStepWarnings(step.Id);
    return (<div key={step.Id} className={processListItemStyles.group}>
            <ProcessListItem actionType={parentStepLabel} icon={icon} actionErrors={stepErrors} actionWarnings={stepWarnings} detailsUrl={selectors.getStepDetailsUrl(project.Slug, gitRef, step.Id, null)} isParentGroup={true} index={`${selectors.getStepNumber(step.Id)}.`} name={step.Name} isRunInParallelWithLast={step.StartTrigger === StartTrigger.StartWithPrevious && !selectors.isFirstStep(step.Id)} isDisabled={selectors.isStepDisabled(step.Id)} notes={null}>
                {step.Properties[SpecialVariables.Action.MaxParallelism] ? <span>Rolling deployment</span> : <span>Multi-step deployment</span>}
                &nbsp;across deployment targets with&nbsp;
                <Roles rolesAsCSV={step.Properties[SpecialVariables.Action.TargetRoles] as string}/>
            </ProcessListItem>
            {step.ActionIds.map((actionId, index) => {
            const action = selectors.getActionById(actionId);
            return <ProcessListPageListItemForAction key={action.Id} actionTemplates={actionTemplates} step={step} action={action} actionIndex={index + 1} stepIndex={stepIndex} lookups={lookups}/>;
        })}
        </div>);
};
ProcessListPageListItemForParentStep.displayName = "ProcessListPageListItemForParentStep"
export default ProcessListPageListItemForParentStep;
