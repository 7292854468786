import type { InputPathToObject } from "@octopusdeploy/step-inputs";
import type { PlainObjectTypeDefinition, ObjectRuntimeInputs, DiscriminatorProperty } from "@octopusdeploy/step-runtime-inputs";
import type { InitialInputs } from "@octopusdeploy/step-ui";
import { getSchemaForInputObject } from "~/components/StepPackageEditor/Inputs/schemaTraversal";
import { getSingle } from "~/utils/getSingle";
// We determine which option is selected based on the discriminator properties.
// That is, if the current input values has a discriminator property with the value "foo",
// then we consider an option selected if it also has the same value of the same discriminator property ("foo").
export function getSelectedOption(pathToInput: InputPathToObject<unknown>, options: DiscriminatorOption<unknown>[], inputSchema: PlainObjectTypeDefinition) {
    // We need to check the input schema to work out which properties are discriminators.
    const schemaForCurrentInput = getSchemaForInputObject(pathToInput, inputSchema);
    const selectedOptions = options.filter((o) => matchesUnionSchema(schemaForCurrentInput, o.newValue));
    return getSingle(selectedOptions, "Multiple options match the selected value, based on the value of their discriminators. Ensure that each option has a unique set of discriminator values.", "None of the available options matched the selected value, based on the values of their discriminators.");
}
export function getSelectedOptionForPrimitive<T>(value: T, options: DiscriminatorOption<T>[]) {
    const selectedOptions = options.filter((o) => o.newValue === value);
    //TODO: step-api We probably want to return something here to represent the bound case instead of throwing.
    return getSingle(selectedOptions, "Multiple options match the selected value, based on the value of their discriminators. Ensure that each option has a unique set of discriminator values.", "None of the available options matched the selected value, based on the values of their discriminators.");
}
export interface DiscriminatorOption<Input> {
    label: string;
    newValue: InitialInputs<Input>;
}
function matchesUnionSchema(inputSchema: PlainObjectTypeDefinition, optionValue: ObjectRuntimeInputs<unknown> | InitialInputs<unknown>): boolean {
    return inputSchema.discriminatorProperties.every(discriminatorValueMatches);
    function discriminatorValueMatches(discriminatorProperty: DiscriminatorProperty): boolean {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const key: keyof typeof optionValue = discriminatorProperty.discriminatorName as keyof typeof optionValue;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const currentValueOfDiscriminator = optionValue[key] as unknown;
        return currentValueOfDiscriminator === discriminatorProperty.type.const;
    }
}
