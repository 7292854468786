import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
const video = {
    youtubeId: "uEjXuPttRO4",
    shortlink: "MachinePolicyVideo",
};
export const CommonInfrastructureMachinePolicyOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    <ExternalLink href="OnboardingMachinePoliciesLearnMore">Machine policies</ExternalLink> are groups of settings that can be applied to Tentacle and SSH endpoints to modify their behavior.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
