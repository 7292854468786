import { css } from "@emotion/css";
import { noOp } from "@octopusdeploy/design-system-components/src/utils/noOp";
import * as React from "react";
import { CodeEditor, TextFormat } from "~/components/CodeEditor/CodeEditor";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
type GitHubActionsLoginExampleProps = {
    serverUrl: string;
    serviceAccountId: string;
};
export function GitHubActionsLoginExample({ serverUrl, serviceAccountId }: GitHubActionsLoginExampleProps) {
    const gitHubActionsSampleYaml = `- name: Login to Octopus Deploy 
  uses: OctopusDeploy/login@v1
  with: 
    server: ${serverUrl}
    service_account_id: ${serviceAccountId}`;
    const styles = {
        gitHubActionsUsageCodeEditorContainer: css({
            minHeight: "10rem !important",
        }),
    };
    return (<>
            <h4>GitHub Actions</h4>
            <CodeEditor containerClassName={styles.gitHubActionsUsageCodeEditorContainer} language={TextFormat.YAML} onChange={noOp} value={gitHubActionsSampleYaml} readOnly showCopyButton={true} allowFullScreen={false}/>
            <br />
            <Note>
                Copy the <code>OctopusDeploy/login</code> action above and paste into your workflow to use this OIDC identity.{" "}
                <ExternalLink href="ServiceAccountOidcIdentitiesGitHubActions">Learn more about using OpenID Connect in GitHub Actions with Octopus.</ExternalLink>
            </Note>
        </>);
}
