import React from "react";
import Card from "~/components/Card";
import { WelcomeDialogPlusIcon } from "../WelcomeDialogPlusIcon";
import type { WelcomeDialogCardProps } from "./WelcomeDialogCardProps";
import { cardStyles } from "./styles";
export function NewProjectCard({ busy, onClick }: WelcomeDialogCardProps) {
    return (<Card link={!busy && (<a href="#" onClick={onClick} className={cardStyles.link}>
                        Create new project
                    </a>)} logo={<div className={cardStyles.logo(!busy)}>
                    <WelcomeDialogPlusIcon />
                </div>} header={<div className={cardStyles.header}>New project</div>} content={"Create and deploy your first application with Octopus."} className={cardStyles.card} contentClassName={cardStyles.content} leftAlign={true}/>);
}
