import type { EnvironmentResource, EnvironmentSummaryResource, MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import { orderedHealthStatuses } from "~/areas/infrastructure/InfrastructureDetails";
import { DisabledMachineHealthSummaryLink, MachineHealthSummaryLink } from "~/areas/infrastructure/components/MachineHealthSummaryLink/MachineHealthSummaryLink";
import type { EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import { Loading } from "~/components/Loading/Loading";
import MarkdownDescription from "~/components/MarkdownDescription";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import type { OverflowMenuGenericItem, OverflowMenuNavLink } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { Section } from "~/components/Section/Section";
import SimpleExpander from "~/components/SimpleExpander";
import { withTheme } from "~/components/Theme";
import { OctopusIcon, OctopusIconType } from "~/primitiveComponents/dataDisplay/Icon";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import type { BaseMachinesSummaryProps, BaseMachinesSummaryState } from "../BaseMachinesSummary/BaseMachinesSummary";
import BaseMachinesSummary from "../BaseMachinesSummary/BaseMachinesSummary";
import { getHealthStatusFiltersAsPerPrecedence, getIsDisabledFilterAsPerPrecedence } from "../BaseMachinesSummary/BaseMachinesSummaryFilter";
import type { EnvironmentSummaryFilter } from "./EnvironmentSummaryFilter";
import { createEnvironmentMachinesArgs } from "./EnvironmentSummaryFilter";
import styles from "./style.module.less";
interface EnvironmentSummarySectionInternalProps extends BaseMachinesSummaryProps {
    environmentSummary: EnvironmentSummaryResource;
    filter: EnvironmentSummaryFilter;
    endpointRegistrations: EndpointRegistration[];
}
class EnvironmentSummarySectionInternal extends BaseMachinesSummary<EnvironmentSummarySectionInternalProps, BaseMachinesSummaryState> {
    private requestRaceConditioner = new RequestRaceConditioner();
    constructor(props: EnvironmentSummarySectionInternalProps) {
        super(props);
        this.state = {
            machinesResponse: null,
            machineHealthStatusFastLookup: {},
            currentPageIndex: 0,
            expanded: false,
            healthStatusFilter: "",
            isDisabledFilter: false,
            redirectToTasks: false,
            endpointRegistrations: props.endpointRegistrations,
        };
    }
    componentDidUpdate(prevProps: Readonly<EnvironmentSummarySectionInternalProps>) {
        if (this.state.expanded && !isEqual(prevProps.filter, this.props.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }
    render() {
        if (this.state.redirectToTasks) {
            return <InternalRedirect to={links.tasksPage.generateUrl()} push={true}/>;
        }
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        const environmentSummary = this.props.environmentSummary;
        const machineHealthLinks = orderedHealthStatuses.map((status) => this.renderMachineSummaryLinks(environmentSummary, status));
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(environmentSummary);
        const summaryComponents = [...machineHealthLinks, machinesDisabledLinks];
        const environment = environmentSummary.Environment;
        const overflowMenuItems: Array<OverflowMenuNavLink[] | OverflowMenuGenericItem> = [
            [
                OverflowMenuItems.navItem("Add Deployment Target", links.newDeploymentTargetWithEnvironmentPage.generateUrl({ spaceId: environment.SpaceId, environmentId: environment.Id }), {
                    permission: Permission.MachineCreate,
                    environment: environment.Id,
                    tenant: "*",
                }),
                OverflowMenuItems.navItem("Edit", links.infrastructureEnvironmentPage.generateUrl({ spaceId: environment.SpaceId, environmentId: environment.Id }), {
                    permission: Permission.EnvironmentEdit,
                    environment: environment.Id,
                }),
            ],
        ];
        // Only show machine-related actions if they actually have some machines in this environment.
        if (environmentSummary.TotalMachines > 0) {
            if (this.state.machinesResponse) {
                const machineIds = this.state.machinesResponse.Items.map((x) => x.Id);
                overflowMenuItems.push(OverflowMenuItems.item(`Check Health for ${environmentSummary.TotalMachines} Deployment Target${environmentSummary.TotalMachines === 1 ? "" : "s"}`, () => this.performHealthCheck(environment, machineIds), {
                    permission: Permission.MachineEdit,
                    environment: environment.Id,
                    tenant: "*",
                }));
            }
            const tentacleIds = environmentSummary.MachineIdsForTentacleUpgrade;
            if (tentacleIds && tentacleIds.length > 0) {
                overflowMenuItems.push(OverflowMenuItems.confirmUpgrade(`Upgrade ${tentacleIds.length} Tentacle${tentacleIds.length === 1 ? "" : "s"} in this Environment`, () => this.performTentacleUpgrade(environment), {
                    permission: Permission.MachineEdit,
                    environment: environment.Id,
                    tenant: "*",
                }));
            }
            const calamariIds = environmentSummary.MachineIdsForCalamariUpgrade;
            if (calamariIds && calamariIds.length > 0) {
                overflowMenuItems.push(OverflowMenuItems.confirmUpgrade(`Upgrade Calamari on ${calamariIds.length} Deployment Target${calamariIds.length === 1 ? "" : "s"}`, () => this.performCalamariUpgrade(calamariIds), {
                    permission: Permission.MachineEdit,
                    environment: environment.Id,
                    tenant: "*",
                }));
            }
        }
        const titleContainer = withTheme((theme) => (<div className={styles.cardTitleContainer}>
                <div className={styles.environmentIcon}>
                    <OctopusIcon iconType={OctopusIconType.Environment} style={{ fill: theme.iconDark }}/>
                </div>
                <div className={styles.environmentName}>{environment.Name}</div>
                <div className={styles.environmentMachinesCount}>{`(${environmentSummary.TotalMachines && environmentSummary.TotalMachines.toLocaleString()} deployment target${environmentSummary.TotalMachines === 1 ? "" : "s"})`}</div>
                <div className={styles.environmentSummaryCounts}>{summaryComponents}</div>
                <div className={styles.environmentOverflowActions}>
                    <OverflowMenu menuItems={overflowMenuItems}/>
                </div>
            </div>));
        return (<Loading key={environment.Id} busy={this.state.busy} errors={this.errors}>
                <SimpleExpander errorKey={environment.Id} key={environment.Id} title={titleContainer} onDidExpand={(expanded) => {
                this.setState({ expanded });
                if (expanded) {
                    this.reloadDataAndCurrentPageIndex();
                }
                else {
                    this.setState({
                        machinesResponse: null,
                        currentPageIndex: 0,
                    });
                }
            }}>
                    {environment.Description && (<Section>
                            <MarkdownDescription markup={environment.Description}/>
                        </Section>)}
                    {this.renderMachinesList(this.props.environmentSummary)}
                </SimpleExpander>
            </Loading>);
    }
    protected async loadData() {
        const isDisabled = getIsDisabledFilterAsPerPrecedence(this.props.isFiltering, this.props.filter, this.state.isDisabledFilter);
        const applicableHealthStatusFilters = getHealthStatusFiltersAsPerPrecedence(this.props.filter, this.props.isFiltering, this.state.healthStatusFilter);
        const args = createEnvironmentMachinesArgs(this.props.filter, isDisabled, applicableHealthStatusFilters);
        const promises = Promise.all([repository.Environments.machines(this.props.environmentSummary.Environment, args), endpointRegistry.getAllRegistrations()]);
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(promises, ([machinesResponse, endpointRegistrations]) => {
            this.setMachineResponseState(machinesResponse);
            this.setState({ endpointRegistrations });
        });
    }
    private renderMachineSummaryLinks(environmentSummary: EnvironmentSummaryResource, healthStatus: MachineModelHealthStatus) {
        return (<MachineHealthSummaryLink key={healthStatus} healthStatus={healthStatus} onSelect={this.handleHealthSummaryLinkSelect} onClearSelect={this.handleClearHealthSummaryFilter} getLinkText={(status, count, friendlyName) => `${count.toLocaleString()}  ${friendlyName}`} count={environmentSummary.MachineHealthStatusSummaries[healthStatus]} allowSelection={!(this.props.filter.healthStatuses && this.props.filter.healthStatuses.length > 0)} isSelected={this.state.healthStatusFilter === healthStatus}/>);
    }
    private handleHealthSummaryLinkSelect = (e: React.MouseEvent<Element, MouseEvent>, healthStatus: MachineModelHealthStatus) => {
        // The user may click a health status link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        // Clear any disabled filters when a healthStatus filter is clicked. You can't chain inline disabled and healthStatus
        // filters together because they use different and/or logic at the API and it causes UI confusion.
        this.setState({
            healthStatusFilter: healthStatus,
            isDisabledFilter: false,
        }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };
    private handleClearHealthSummaryFilter = (e: React.MouseEvent) => {
        // The user may click a health status link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        this.setState({ healthStatusFilter: "" }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };
    private renderMachineDisabledSummaryLinks(environmentSummary: EnvironmentSummaryResource) {
        return (<DisabledMachineHealthSummaryLink key="Disabled" onSelect={this.handleDisabledSummaryLinkSelect} onClearSelect={this.handleClearDisabledSummaryFilter} count={environmentSummary.TotalDisabledMachines} allowSelection={!this.props.filter.isDisabled} isSelected={this.state.isDisabledFilter}/>);
    }
    private handleClearDisabledSummaryFilter(e: React.MouseEvent<Element, MouseEvent>) {
        // The user may click a health status link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        this.setState({ isDisabledFilter: false }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    }
    private handleDisabledSummaryLinkSelect = (e: React.MouseEvent<Element, MouseEvent>) => {
        // The user may click a health status link to open an expander (but it shouldn't ever close it).
        if (this.state.expanded) {
            e.preventDefault();
            e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
        }
        // Clear any healthStatus filters when disabled is clicked. You can't chain inline disabled and healthStatus
        // filters together because they use different and/or logic at the API and it causes UI confusion.
        this.setState({
            isDisabledFilter: true,
            healthStatusFilter: "",
        }, () => {
            if (this.state.expanded) {
                this.reloadDataAndCurrentPageIndex();
            }
        });
    };
    private async performHealthCheck(environment: EnvironmentResource, machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createPerformHealthCheckTaskForEnvironment(environment, machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    private async performTentacleUpgrade(environment: EnvironmentResource): Promise<boolean> {
        return this.doBusyTask(async () => {
            await repository.Machines.upgradeAllTargetsInEnvironment(environment.Id);
            this.setState({ redirectToTasks: true });
        });
    }
    private async performCalamariUpgrade(machineIds: string[]) {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createUpdateCalamariOnTargetsTask(machineIds);
            this.setState({ redirectToTaskId: task.Id });
        });
    }
}
type EnvironmentSummarySectionProps = EnvironmentSummarySectionInternalProps;
function EnvironmentSummarySection(props: EnvironmentSummarySectionProps) {
    return <EnvironmentSummarySectionInternal {...props}/>;
}
export default EnvironmentSummarySection;
