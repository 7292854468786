/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RunbookSnapshotResource, ResourceCollection, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import type { ProjectContextProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { RunbookSnapshotPublishedChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import List from "~/components/PagingList";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import type { RunbookContextProps } from "../RunbookContext";
import { useRunbookContext } from "../RunbookContext";
class RunbookSnapshotsList extends List<RunbookSnapshotResource> {
}
interface RunbookSnapshotsState extends DataBaseComponentState {
    runbookSnapshotsResponse: ResourceCollection<RunbookSnapshotResource>;
}
interface RunbookSnapshotsPageInternalProps extends RunbookSnapshotsPageProps {
    runbookContext: RunbookContextProps;
    projectContext: ProjectContextProps;
}
class RunbookSnapshotsPageInternal extends DataBaseComponent<RunbookSnapshotsPageInternalProps, RunbookSnapshotsState> {
    constructor(props: RunbookSnapshotsPageInternalProps) {
        super(props);
        this.state = {
            runbookSnapshotsResponse: null!,
        };
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: RunbookSnapshotsPageInternalProps) {
        const nextRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const currentRunbook = prevProps.runbookContext.state && prevProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }
    async reload() {
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }
        await this.doBusyTask(async () => {
            const runbookSnapshotsResponse = await repository.Runbooks.getRunbookSnapshots(runbook, { skip: 0, take: 30 });
            this.setState({
                runbookSnapshotsResponse,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!project || !runbook) {
            return <ProjectPaperLayout busy={true} errors={this.errors}/>;
        }
        return (<ProjectPaperLayout busy={this.state.busy} errors={this.errors} title="Snapshots" breadcrumbsItems={runbook && [
                { label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) },
                { label: runbook.Name, pageUrl: links.projectRunbookOverviewPage.generateUrl({ spaceId: runbook.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }) },
            ]}>
                {this.state.runbookSnapshotsResponse && (<RunbookSnapshotsList initialData={this.state.runbookSnapshotsResponse} onRow={(item: any) => this.buildRow(runbook, item)} onRowRedirectUrl={(runbookSnapshot: RunbookSnapshotResource) => links.projectRunbookSnapshotInfoPage.generateUrl({ spaceId: runbookSnapshot.SpaceId, projectSlug: runbookSnapshot.ProjectId, runbookId: runbookSnapshot.RunbookId, runbookSnapshotId: runbookSnapshot.Id })} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..."/>)}
            </ProjectPaperLayout>);
    }
    private filter(filter: string, resource: RunbookSnapshotResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private buildRow(runbook: RunbookResource, runbookSnapshot: RunbookSnapshotResource) {
        return (<ListTitle>
                {runbookSnapshot.Name}
                {runbook && runbook.PublishedRunbookSnapshotId === runbookSnapshot.Id && (<>
                        &nbsp;
                        <RunbookSnapshotPublishedChip />
                    </>)}
            </ListTitle>);
    }
    static displayName = "RunbookSnapshotsPageInternal";
}
interface RunbookSnapshotsPageProps {
    projectSlug: string;
}
export function RunbookSnapshotsPage({ projectSlug }: RunbookSnapshotsPageProps) {
    const projectContext = useProjectContext();
    const runbookContext = useRunbookContext();
    return <RunbookSnapshotsPageInternal projectSlug={projectSlug} runbookContext={runbookContext} projectContext={projectContext}/>;
}
