/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import type { DialogProps as DesignSystemDialogProps } from "@octopusdeploy/design-system-components";
import { Dialog as DesignSystemDialog } from "@octopusdeploy/design-system-components";
import * as React from "react";
import ErrorContextProvider from "../ErrorContext/ErrorContext";
export interface DialogProps extends DesignSystemDialogProps {
    title?: string;
    actions?: React.ReactNode;
}
function Dialog({ open, dialogWidth, fullScreen, title, actions, children }: DialogProps) {
    return (<DesignSystemDialog open={open} dialogWidth={dialogWidth} fullScreen={fullScreen}>
            <ErrorContextProvider>
                {title && <DialogTitle>{title}</DialogTitle>}
                {children}
                {actions && <DialogActions>{actions}</DialogActions>}
            </ErrorContextProvider>
        </DesignSystemDialog>);
}
export default Dialog;
