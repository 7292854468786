import type { CommonInputProps } from "@octopusdeploy/step-ui";
import React from "react";
import type { RenderedComponentAndSummary } from "~/components/StepPackageEditor/EditStepPackageInputs";
import { getInputContentReactKey, Input } from "~/components/StepPackageEditor/Inputs/Components/Input";
interface UnnamedSectionParams<StepInputs, TInputComponents> {
    content: TInputComponents[];
    isExpandedByDefault: boolean;
    getRenderedComponentAndSummary(component: TInputComponents): RenderedComponentAndSummary;
}
export function UnnamedSection<StepInputs, TInputComponents extends CommonInputProps>(props: UnnamedSectionParams<StepInputs, TInputComponents>) {
    return (<>
            {props.content.map((content) => (<Input<StepInputs, TInputComponents> content={content} isExpandedByDefault={props.isExpandedByDefault} key={getInputContentReactKey(content)} getRenderedComponentAndSummary={props.getRenderedComponentAndSummary}/>))}
        </>);
}
export function getUnnamedSectionKey<TInputComponents extends CommonInputProps>(content: TInputComponents[]) {
    return content.map((c) => c.label).join("-");
}
