/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FeedType } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
interface OverviewFilterRedirectState extends DataBaseComponentState {
    redirectTo?: LinkHref;
}
interface RedirectFeedBasedOnTypeProps {
    spaceId: string;
    feedId: string;
}
export class RedirectFeedBasedOnType extends DataBaseComponent<RedirectFeedBasedOnTypeProps, OverviewFilterRedirectState> {
    constructor(props: RedirectFeedBasedOnTypeProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const id = this.props.feedId;
            const feed = await repository.Feeds.get(id);
            const spaceId = feed.SpaceId ?? this.props.spaceId;
            const redirectTo = feed.FeedType === FeedType.BuiltIn ? links.builtInRepositoryPage.generateUrl({ spaceId }) : links.editFeedPage.generateUrl({ spaceId, feedId: id });
            this.setState({ redirectTo });
        });
    }
    render() {
        const to = this.state.redirectTo;
        if (!to) {
            return (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={to}/>;
    }
    static displayName = "RedirectFeedBasedOnType";
}
