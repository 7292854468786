import * as React from "react";
interface EmailLinkProps extends React.HTMLProps<HTMLElement> {
    emailAddress: string;
}
const EmailLink: React.SFC<EmailLinkProps> = (props: EmailLinkProps) => {
    if (props.emailAddress) {
        const href = "mailto:" + props.emailAddress;
        return (<a href={href} aria-label={props.emailAddress}>
                {props.emailAddress}
            </a>);
    }
    else {
        return null;
    }
};
EmailLink.displayName = "EmailLink"
export default EmailLink;
