/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { PackageReference, TriggerResource } from "@octopusdeploy/octopus-server-client";
import React, { Fragment, useMemo } from "react";
import { AvailableTriggerCallout, MultipleRelatedTriggersCallout, SingleRelatedTriggerCallout } from "./RelatedTriggerCallouts";
import { getRelatedTriggersForReferencedPackages, getPackageNamesUsedInTriggers } from "./triggerPackageRelations";
export interface RelatedTriggersDependencies {
    spaceId: string;
    projectSlug: string;
    projectTriggers: TriggerResource[];
    deploymentActionSlug: string;
}
interface CalloutTriggerConnectionsProps {
    dependencies: RelatedTriggersDependencies;
    packageReferences: PackageReference[];
    displayAvailableTriggerCallout?: boolean;
}
export function CalloutReferencedPackageTriggerConnections({ dependencies, packageReferences, displayAvailableTriggerCallout }: CalloutTriggerConnectionsProps) {
    const { spaceId, projectSlug } = dependencies;
    const relatedTriggers = useMemo(() => getRelatedTriggersForReferencedPackages(dependencies, packageReferences), [dependencies, packageReferences]);
    const packageNames = getPackageNamesUsedInTriggers(relatedTriggers, packageReferences);
    // We only want to display the available trigger callout when it applies
    // to the enabled package selector feeds and when it won't clutter the UI.
    // In practice this normally means when the main package selection for a
    // step is a list of packages.
    if (relatedTriggers.length === 0 && displayAvailableTriggerCallout) {
        return <AvailableTriggerCallout spaceId={spaceId} projectSlug={projectSlug}/>;
    }
    // No package name means it's a primary package which are handled separately
    if (packageNames.length === 0) {
        return <></>;
    }
    const uniqueTriggerCount = [...new Set(relatedTriggers.map((trigger) => trigger.Id))].length;
    if (uniqueTriggerCount === 1) {
        const triggerId = relatedTriggers[0].Id;
        if (packageNames.length === 1) {
            return <SingleRelatedTriggerCallout triggerId={triggerId} text={<>The package {strongPackageName(packageNames[0])} is connected to an external feed trigger.</>} spaceId={spaceId} projectSlug={projectSlug}/>;
        }
        else {
            return <SingleRelatedTriggerCallout triggerId={triggerId} text={<>The {createPackageNameSentence(packageNames)} packages are connected to an external feed trigger.</>} spaceId={spaceId} projectSlug={projectSlug}/>;
        }
    }
    else {
        if (packageNames.length === 1) {
            return <MultipleRelatedTriggersCallout text={<>The package {strongPackageName(packageNames[0])} is connected to external feed triggers.</>} spaceId={spaceId} projectSlug={projectSlug}/>;
        }
        else {
            return <MultipleRelatedTriggersCallout text={<>The {createPackageNameSentence(packageNames)} packages are connected to external feed triggers.</>} spaceId={spaceId} projectSlug={projectSlug}/>;
        }
    }
}
const createPackageNameSentence = (packageNames: string[]) => {
    switch (packageNames.length) {
        case 0:
            return <></>;
        case 1:
            return <>{strongPackageName(packageNames[0])}</>;
        case 2:
            return (<>
                    {strongPackageName(packageNames[0])} and {strongPackageName(packageNames[1])}
                </>);
        default:
            const lastPackageName = packageNames.pop();
            const secondLastPackageName = packageNames.pop();
            return (<>
                    {packageNames.map((name) => strongPackageName(name, true))} {strongPackageName(secondLastPackageName!)} and {strongPackageName(lastPackageName!)}
                </>);
    }
};
const strongPackageName = (name: string, withComma: boolean = false) => {
    return (<Fragment key={name}>
            <strong>{name}</strong>
            {withComma && <>,</>}
        </Fragment>);
};
