/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { StepCard } from "~/areas/projects/components/Process/ActionTemplateSelector/StepCard";
import ActionTemplateCardList from "~/components/ActionTemplates/ActionTemplateCardList";
import filterActionTemplates from "~/components/ActionTemplates/filterActionTemplates";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import Section from "~/components/Section/index";
import styles from "./style.module.less";
interface BuiltinActionTemplateListProps {
    templates: ActionTemplateSearchResource[];
    filter?: string;
    onPostSelectionUrlRequested?: (template: {
        Type: string;
        Id: string;
    } | string) => LinkHref;
}
function BuiltinActionTemplateList(props: BuiltinActionTemplateListProps) {
    const filteredResults = filterActionTemplates(props.templates, props.filter!);
    const navigation = useSpaceAwareNavigation();
    if (filteredResults.length === 0 && props.filter) {
        return <p className={styles.noBuiltInSteps}>There are no built-in step templates that match your filter criteria.</p>;
    }
    return (<Section sectionHeader="Built-in Step Templates">
            <ActionTemplateCardList>
                {filteredResults.map((template) => {
            return (<StepCard key={template.Type + template.Id} heading={template.Name} subHeading="By Octopus Deploy" description={template.Description} logo={template.Links.Logo} dotpoints={template.Features} primaryButtonLabel="Add Step" onPrimaryButtonClick={() => {
                    navigation.navigate(props.onPostSelectionUrlRequested!(template));
                }}/>);
        })}
            </ActionTemplateCardList>
        </Section>);
}
export default BuiltinActionTemplateList;
