import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "../../CommonComponents/CommonViews";
export const ProjectDeploymentsSettingsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().deploymentSettings,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (<OverviewContainer>
                <OverviewHighlightText>
                    <p>The settings on this page control how releases are created and deployed.</p>
                </OverviewHighlightText>
            </OverviewContainer>);
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return (<TopicsContainer>
                <LearnMoreResources>
                    <div>
                        <ExternalLink href="ConfigAsCodeEditing">Editing version-controlled projects</ExternalLink>
                    </div>
                </LearnMoreResources>
            </TopicsContainer>);
    },
};
