import cn from "classnames";
import * as React from "react";
import { useEffect } from "react";
import styles from "~/components/TaskLogLines/taskLogBlock.module.less";
export const TaskLogBlockLoader = () => {
    const [isPinned, setIsPinned] = React.useState<boolean>();
    const selfRef = React.useRef<HTMLAnchorElement>(null);
    useEffect(() => {
        if (isPinned) {
            selfRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    });
    const pinClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsPinned(!isPinned);
    };
    return (<a ref={selfRef} href="" aria-label="Step executing" onClick={pinClick} className={cn(styles.currentCommandExecuting)}>
            <em className="fa-solid fa-spinner fa-pulse"></em>
            {isPinned && (<>
                    &nbsp;
                    <em className="fa-solid fa-map-pin"></em>
                </>)}
        </a>);
};
