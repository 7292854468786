/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import Note from "../../../primitiveComponents/form/Note/Note";
import type { DataBaseComponentState } from "../../DataBaseComponent";
import { DataBaseComponent } from "../../DataBaseComponent";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import CertificateVariableSelect from "../../form/CertificateSelect/CertificateVariableSelect";
import { VariableLookupAutoComplete } from "../../form/VariableLookupAutoComplete";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { IngressTlsCertificate } from "./kubernetesIngressComponent";
interface IngressTlsCertificateProps {
    tlsCertificate: IngressTlsCertificate;
    ingressRuleHosts: string[]; // Used for auto-complete values
    localNames: string[];
    projectId: string;
    gitRef: GitRefResource | undefined;
    onSave(hostCertificate: IngressTlsCertificate): boolean;
}
interface IngressTlsCertificateState extends DataBaseComponentState {
    tlsCertificate: IngressTlsCertificate;
    certificateSource: CertificateSource;
}
enum CertificateSource {
    IngressDefault = "IngressDefault",
    Octopus = "Octopus",
    Secret = "Secret"
}
class IngressTlsHostList extends RemoveItemsList<string> {
}
export class IngressTlsCertificateDialog extends DataBaseComponent<IngressTlsCertificateProps, IngressTlsCertificateState> {
    constructor(props: IngressTlsCertificateProps) {
        super(props);
        this.state = {
            tlsCertificate: null!,
            certificateSource: CertificateSource.IngressDefault,
        };
    }
    componentDidMount() {
        let certificateSource = CertificateSource.IngressDefault;
        if (!!this.props.tlsCertificate.secretName) {
            certificateSource = CertificateSource.Secret;
        }
        else if (!!this.props.tlsCertificate.certificateVariableName) {
            certificateSource = CertificateSource.Octopus;
        }
        this.setState({
            tlsCertificate: this.props.tlsCertificate,
            certificateSource,
        });
    }
    render() {
        return (<OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title="Configure TLS certificate">
                {this.state.tlsCertificate && (<div>
                        <Note>
                            See the Kubernetes documentation for more information on <ExternalLink href="KubernetesIngressTls">ingress TLS</ExternalLink>.
                        </Note>
                        <RadioButtonGroup onChange={(certificateSource) => {
                    this.setState({ certificateSource }, () => {
                        this.setTlsCertificateState({
                            certificateVariableName: certificateSource === CertificateSource.Octopus ? this.state.tlsCertificate.certificateVariableName : null!,
                            secretName: certificateSource === CertificateSource.Secret ? this.state.tlsCertificate.secretName : null!,
                        });
                        window.dispatchEvent(new Event("resize"));
                    });
                }} value={this.state.certificateSource}>
                            <RadioButton value={CertificateSource.IngressDefault} label="Ingress Default" isDefault={true}/>
                            {this.renderDefaultCertOption()}
                            <RadioButton value={CertificateSource.Octopus} label="Octopus Certificate"/>
                            {this.renderOctopusCertOption()}
                            <RadioButton value={CertificateSource.Secret} label="Existing Secret"/>
                            {this.renderSecretCertOption()}
                        </RadioButtonGroup>
                        <IngressTlsHostList listActions={[<ActionButton key="add" label="Add Host" onClick={() => this.addHost()}/>]} data={this.state.tlsCertificate.hosts} onRow={(host, hostIndex) => (<VariableLookupAutoComplete name="KubernetesIngressTlsHost" localNames={this.props.localNames} value={host} onChange={(x) => this.onHostChange(hostIndex, x)} label="Host" getOptions={(searchText) => this.getHostOptions(searchText)} allowAnyTextValue={true} placeholder="acme.com"/>)}/>
                        <Note>
                            <br />
                            Configure the hosts which TLS should be configured for. For example, <em>sslexample.foo.com</em>
                        </Note>
                    </div>)}
            </OkDialogLayout>);
    }
    renderOctopusCertOption = () => {
        if (this.state.certificateSource !== CertificateSource.Octopus) {
            return null;
        }
        return (<React.Fragment>
                <CertificateVariableSelect projectId={this.props.projectId} gitRef={this.props.gitRef} doBusyTask={this.doBusyTask} value={this.state.tlsCertificate.certificateVariableName!} onChange={(x) => this.setTlsCertificateState({ certificateVariableName: x })}/>
                <Note>
                    Optionally select a variable which contains a <ExternalLink href="CertificatesDocumentation">certificate</ExternalLink>.
                </Note>
                <Note>This field is bound to a variable as it is generally desired to use a different certificate for different environments.</Note>
            </React.Fragment>);
    };
    renderDefaultCertOption = () => {
        if (this.state.certificateSource !== CertificateSource.IngressDefault) {
            return null;
        }
        return <Note>Your ingress controller has default certificates defined, or is using SSL passthrough</Note>;
    };
    renderSecretCertOption = () => {
        if (this.state.certificateSource !== CertificateSource.Secret) {
            return null;
        }
        return (<div style={{ marginRight: "1em" }}>
                <VariableLookupText localNames={this.props.localNames} value={this.state.tlsCertificate.secretName!} onChange={(x) => this.setTlsCertificateState({ secretName: x })} label="Secret"/>
                <Note>An existing secret in kubernetes. This secret must be in the same namespace to be accessible.</Note>
            </div>);
    };
    save = () => {
        const tlsCertificate = this.state.tlsCertificate;
        return this.props.onSave(tlsCertificate);
    };
    addHost = () => {
        const hosts = [...this.state.tlsCertificate.hosts, ""];
        this.setTlsCertificateState({ hosts });
    };
    onHostChange = (hostIdx: number, value: string) => {
        const hosts = [...this.state.tlsCertificate.hosts];
        hosts[hostIdx] = value;
        this.setTlsCertificateState({ hosts });
    };
    getHostOptions = async (searchText: string) => {
        const take = 7;
        const results = _.chain(this.props.ingressRuleHosts)
            .filter((v) => !!v)
            .filter((v) => !searchText || v.toLowerCase().includes(searchText.toLowerCase()))
            .value();
        return {
            items: results.slice(0, take).map((v) => ({ Id: v, Name: v })),
            containsAllResults: results.length <= take,
        };
    };
    private setTlsCertificateState<K extends keyof IngressTlsCertificate>(state: Pick<IngressTlsCertificate, K>, callback?: () => void) {
        this.setChildState1("tlsCertificate", state);
    }
    static displayName = "IngressTlsCertificateDialog";
}
