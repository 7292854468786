import { css } from "@emotion/css";
import { GitBranchIcon, List } from "@octopusdeploy/design-system-components";
import { borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { CommitDetail, DeploymentResource, OctopusSpaceRepository, ReleaseChanges, ReleasePackageVersionBuildInformation, WorkItemLink } from "@octopusdeploy/octopus-server-client";
import type { PropsWithChildren } from "react";
import * as React from "react";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import Markdown from "~/components/Markdown/index";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import StringHelper from "~/utils/StringHelper/index";
interface BuildInformationProps {
    deploymentId: string;
    doBusyTask: DoBusyTask;
    taskScopedRepository: OctopusSpaceRepository;
}
export default function BuildInformation({ deploymentId, doBusyTask, taskScopedRepository }: BuildInformationProps) {
    const [deployment, setDeployment] = React.useState<DeploymentResource | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const deployment = await taskScopedRepository.Deployments.get(deploymentId);
        setDeployment(deployment);
    }, [taskScopedRepository, deploymentId]);
    if (deployment === null) {
        return null;
    }
    const deploymentHasBuildInfo = deployment.Changes.some((c) => c.BuildInformation.length > 0);
    if (!deploymentHasBuildInfo) {
        return (<OnboardingPage title="Integrate your commits and work items with Octopus" intro="Build information allows you to easily determine what has changed between each new deployment." learnMore={<ExternalLink href={"BuildInfo"} label={"Learn more"}>
                        Learn more
                    </ExternalLink>}/>);
    }
    // Reverse the release changes so that the most recent ones are on top
    return <List items={deployment.Changes.slice().reverse()} renderRow={({ item }) => <ReleaseChanges change={item}/>} rowKey={(item) => item.Version}/>;
}
function ReleaseChanges({ change }: {
    change: ReleaseChanges;
}) {
    if (change.BuildInformation.length === 0 && StringHelper.isNullOrWhiteSpace(change.ReleaseNotes)) {
        return (<div className={styles.releaseChange}>
                <EmptyReleaseChange version={change.Version}/>
            </div>);
    }
    return (<div className={styles.releaseChange}>
            <table className={styles.table}>
                <colgroup>
                    <col className={styles.nameColumnGroup}/>
                    <col className={styles.bodyColumnGroup}/>
                </colgroup>
                <thead>
                    <tr>
                        <th className={styles.nameColumnHeader}>Release {change.Version}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {StringHelper.isNullOrWhiteSpace(change.ReleaseNotes) ? null : (<tr className={styles.borderTop}>
                            <NameColumn name={"Release notes"}/>
                            <BodyColumn>
                                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                                <Markdown markup={change.ReleaseNotes!} noMargins={true}/>
                            </BodyColumn>
                        </tr>)}
                    {change.BuildInformation.map((b) => (<PackageChanges buildInformation={b}/>))}
                </tbody>
            </table>
        </div>);
}
function EmptyReleaseChange({ version }: {
    version: string;
}) {
    return (<table className={styles.table}>
            <thead>
                <tr>
                    <th className={styles.nameColumnHeader}>Release {version}</th>
                </tr>
            </thead>
            <tbody>
                <tr className={styles.borderTop}>
                    <BodyColumn>
                        <span className={styles.noBuildInfo}>No build information was provided for this release</span>
                    </BodyColumn>
                </tr>
            </tbody>
        </table>);
}
function PackageChanges({ buildInformation }: {
    buildInformation: ReleasePackageVersionBuildInformation;
}) {
    return (<>
            <tr className={styles.borderTop}>
                <NameColumn name={"Package"}/>
                <BodyColumn>
                    {buildInformation.PackageId} ({buildInformation.Version})
                </BodyColumn>
            </tr>
            <tr>
                <NameColumn name={"Build"}/>
                <BodyColumn>
                    <div className={styles.linkContainer}>
                        <ExternalLink href={buildInformation.BuildUrl} label={buildInformation.BuildNumber}>
                            {buildInformation.BuildNumber}
                        </ExternalLink>
                        <div className={styles.buildContainer}>
                            <GitBranchIcon emphasis={"muted"} size={14}/> {buildInformation.Branch}
                        </div>
                        {buildInformation.BuildEnvironment === "" ? null : <span>({buildInformation.BuildEnvironment})</span>}
                    </div>
                </BodyColumn>
            </tr>
            {buildInformation.Commits.map((c, i) => (<tr>
                    <NameColumn name={i === 0 ? "Commits" : undefined}/>
                    <BodyColumn>
                        <CommitDetail commitDetail={c}/>
                    </BodyColumn>
                </tr>))}
            {buildInformation.WorkItems.map((w, i) => (<tr>
                    <NameColumn name={i === 0 ? "Work items" : undefined}/>
                    <BodyColumn>
                        <WorkItem workItem={w}/>
                    </BodyColumn>
                </tr>))}
        </>);
}
function CommitDetail({ commitDetail }: {
    commitDetail: CommitDetail;
}) {
    const shortCommitSha = commitDetail.Id.substring(0, 7);
    return (<div className={styles.linkContainer}>
            {commitDetail.LinkUrl ? (<ExternalLink href={commitDetail.LinkUrl} label={shortCommitSha}>
                    {shortCommitSha}
                </ExternalLink>) : (<div>{shortCommitSha}</div>)}
            <div>{commitDetail.Comment}</div>
        </div>);
}
function WorkItem({ workItem }: {
    workItem: WorkItemLink;
}) {
    if (workItem.LinkUrl) {
        return (<div className={styles.linkContainer}>
                <ExternalLink href={workItem.LinkUrl} label={workItem.Id}>
                    {workItem.Id}
                </ExternalLink>
                {workItem.Id === workItem.Description ? "" : workItem.Description}
            </div>);
    }
    return (<div className={styles.linkContainer}>
            {workItem.Id}
            {workItem.Description && workItem.Id !== workItem.Description ? workItem.Description : ""}
        </div>);
}
function NameColumn({ name }: {
    name: string | undefined;
}) {
    return <td className={styles.nameColumn}>{name}</td>;
}
function BodyColumn({ children }: PropsWithChildren<{}>) {
    return <td className={styles.bodyColumn}>{children}</td>;
}
const styles = {
    linkContainer: css({
        display: "flex",
        gap: space[12],
        alignItems: "center",
    }),
    buildContainer: css({
        display: "flex",
        gap: space[4],
        alignItems: "center",
    }),
    releaseChange: css({
        padding: `${space[16]} 0`,
        width: "100%",
    }),
    table: css({
        borderCollapse: "collapse",
        tableLayout: "fixed",
        width: "100%",
    }),
    nameColumnGroup: css({
        width: "20%",
    }),
    nameColumnHeader: css({
        display: "flex",
        font: text.table.cell.bold.base,
        padding: `${space[6]} ${space[16]}`,
        textAlign: "start",
    }),
    nameColumn: css({
        font: text.table.cell.bold.medium,
        padding: `${space[6]} ${space[16]}`,
        verticalAlign: "top",
    }),
    bodyColumnGroup: css({
        width: "80%",
    }),
    bodyColumn: css({
        font: text.table.cell.default.medium,
        padding: `${space[6]} ${space[16]}`,
    }),
    noBuildInfo: css({
        color: themeTokens.color.text.tertiary,
    }),
    borderTop: css({
        borderTop: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
    }),
};
