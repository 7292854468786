/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RadioButtonGroup, RadioButton, Callout } from "@octopusdeploy/design-system-components";
import type { ResourcesById, DeleteMachinesBehavior, EnvironmentResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode, AccountType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { FormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import { FormBaseComponent } from "~/components/FormBaseComponent/FormBaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { Form } from "~/components/FormPaperLayout/Form";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import Section from "~/components/Section";
import SimpleExpander from "~/components/SimpleExpander/SimpleExpander";
import { Select, ExpandableFormSection, Summary, FormSectionHeading, UnstructuredFormSection, FormSection, required } from "~/components/form";
import AccountSelect from "~/components/form/AccountSelect/AccountSelect";
import CertificateSelect from "~/components/form/CertificateSelect/CertificateSelect";
import TimeSpanSelector from "~/components/form/TimeSpanSelector/TimeSpanSelector";
import ColorPicker from "~/primitiveComponents/form/ColorPicker/ColorPicker";
import Note from "~/primitiveComponents/form/Note/Note";
import FileUploadDragDrop from "../../primitiveComponents/form/FileUploadDragDrop/FileUploadDragDrop";
import Text from "../../primitiveComponents/form/Text/Text";
import type { KeyValuePair } from "../EditList/KeyValueEditList";
import StringKeyValueEditList, { KeyValueEditList } from "../EditList/KeyValueEditList";
import StringEditList from "../EditList/StringEditList";
import Sensitive from "../form/Sensitive/Sensitive";
import SensitiveFileUpload from "../form/SensitiveFileUpload/SensitiveFileUpload";
import WorkerPoolSelect from "../form/WorkerPoolSelect/WorkerPoolSelect";
import { getMockEnvironmentsById } from "./Data/environments";
interface Model {
    text: string;
    radioButtonGroup: string;
    typedRadioButtonGroup: DeleteMachinesBehavior;
    timeSpanText: string;
    select: string;
    environmentsById: {};
    colorPicker?: string;
    formError?: string;
    certificateId?: string;
    workerPoolId?: string;
    accountId?: string;
    superSecret?: SensitiveValue;
    fileUpload?: File;
    sensitiveFileUpload?: SensitiveValue;
    commaDelimitedList: string;
    objectHash: string;
    keyValuePairs: KeyValuePair[];
    textForExpandableFormSection: string;
    textForFormSection: string;
}
interface PersonFilter {
    name: string;
    age?: number;
    favoriteTvShow: string;
}
const title = "Sample";
const SampleFormPageInner = FormPage<ResourcesById<EnvironmentResource>>();
const SampleFormPage: React.SFC = () => {
    return (<SampleFormPageInner title={title} load={() => {
            return new Promise((resolve, reject) => {
                resolve(getMockEnvironmentsById());
            });
        }} renderWhenLoaded={(environmentsById) => {
            return <SampleForm environmentsById={environmentsById}/>;
        }}/>);
};
SampleFormPage.displayName = "SampleFormPage"
interface SampleFormProps {
    environmentsById: ResourcesById<EnvironmentResource>;
}
class SampleForm extends FormBaseComponent<SampleFormProps, FormBaseComponentState<Model>, Model> {
    constructor(props: SampleFormProps) {
        super(props);
        this.state = {
            model: this.createDefaultModel(),
            cleanModel: this.createDefaultModel(),
        };
    }
    render() {
        return (<Form model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={this.saveWithValidationError}>
                {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({ saveButtonLabel: "Click me to see validation errors" })} title={title} busy={false} errors={this.errors}>
                        <FormContent>
                            <FormSectionHeading title="Expanders"/>
                            <SimpleExpander errorKey={"SimpleExpander"} key={"SimpleExpander"} title={<Section>Simple Expander</Section>}>
                                <Section>Some simple expander content!</Section>
                            </SimpleExpander>
                            <ExpandableFormSection errorKey="ExpandableFormSectionext" title="Expandable Form Section" summary={this.state.model.textForExpandableFormSection ? Summary.summary(this.state.model.textForExpandableFormSection) : Summary.placeholder("Placeholder text when no value has been entered")} help="Enter text">
                                <Text label="Text" value={this.state.model.textForExpandableFormSection} onChange={(textForExpandableFormSection) => this.setModelState({ textForExpandableFormSection })}/>
                                <Note>This is sample note</Note>
                            </ExpandableFormSection>
                            <FormSection title="Form Section" help={"Enter some text."} includeBorder={true}>
                                <Text value={this.state.model.textForFormSection} onChange={(textForFormSection) => this.setModelState({ textForFormSection })} label="Name" validate={required("Please enter some text")} error={this.getFieldError("name")}/>
                                <Note>A short, memorable, unique name for this team. eg. ACME Project Contributors.</Note>
                            </FormSection>
                            <UnstructuredFormSection>
                                <Callout type={"warning"} title={"Unstructured Form Section Component"}>
                                    <div style={{ textAlign: "center" }}>
                                        Here is a non-expanding information panel that may apply to a whole section, rather than an individual Summary. You can also use it to explain the current state of the UI. E.g. why certain parts of the UI are
                                        not visible.
                                    </div>
                                </Callout>
                            </UnstructuredFormSection>
                            <UnstructuredFormSection stretchContent={true}>
                                <Callout type={"warning"} title={"Unstructured Form Section with 'stretchContent' set to 'True' - The same Unstructured Form Section but without margin or padding so the content fills the entire panel. Mostly used with Callouts like this one"}/>
                            </UnstructuredFormSection>
                            <FormSectionHeading title="Form Field Components"/>
                            <ExpandableFormSection errorKey="text" title="Text" summary={this.state.model.text ? Summary.summary(this.state.model.text) : Summary.placeholder("Placeholder text when no value has been entered")} help="Enter text">
                                <Text label="Text" value={this.state.model.text} onChange={(text) => this.setModelState({ text })}/>
                                <Note>This is sample note</Note>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="multiline" title="Multiline" summary={Summary.placeholder("Placeholder multiline text when no value has been entered")} help="Enter text">
                                <Text label="Text" value="" multiline={true} onChange={(x) => undefined}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="select" title="Select" summary={Summary.summary("Summary text when a non-default value has been selected or you have content in a text box etc")} help="Select select">
                                <Select error={this.state.model.formError === "Error" ? "There is an error" : undefined} warning={this.state.model.formError === "Warning" ? "I must warn you" : undefined} label="Select" value="Option2" items={["Option1", "Option2", "Option3"].map((op) => ({ value: op, text: op }))} onChange={(x) => undefined}/>
                                <CertificateSelect error={this.state.model.formError === "Error" ? "There is an error" : undefined} warning={this.state.model.formError === "Warning" ? "I must warn you" : undefined} allowClear={true} value={this.state.model.certificateId!} items={() => Promise.resolve([
                    {
                        Id: "ABC",
                        Name: "Cert One",
                        CertificateData: { HasValue: false, NewValue: "" },
                        Password: { HasValue: false, NewValue: "" },
                        TenantIds: [],
                        EnvironmentIds: [],
                        TenantTags: [],
                        Links: {},
                        SpaceId: "Spaces-1",
                    },
                    {
                        Id: "ABC2",
                        Name: "Cert Two",
                        CertificateData: { HasValue: false, NewValue: "" },
                        Password: { HasValue: false, NewValue: "" },
                        TenantIds: [],
                        EnvironmentIds: [],
                        TenantTags: [],
                        Links: {},
                        SpaceId: "Spaces-1",
                    },
                ])} onRequestRefresh={() => Promise.resolve<boolean>(true)} onChange={(certificateId) => this.setModelState({ certificateId })} doBusyTask={this.doBusyTask}/>
                                <WorkerPoolSelect error={this.state.model.formError === "Error" ? "There is an error" : undefined} warning={this.state.model.formError === "Warning" ? "I must warn you" : undefined} allowClear={true} value={this.state.model.workerPoolId!} onChange={(workerPoolId) => this.setModelState({ workerPoolId })} doBusyTask={this.doBusyTask} items={() => Promise.resolve([])} onRequestRefresh={() => Promise.resolve<boolean>(true)}/>
                                <AccountSelect onRequestRefresh={Promise.resolve} type={[AccountType.AzureSubscription, AccountType.SshKeyPair, AccountType.UsernamePassword]} value={this.state.model.accountId!} error={this.state.model.formError === "Error" ? "There is an error" : undefined} warning={this.state.model.formError === "Warning" ? "I must warn you" : undefined} allowClear={true} onChange={(accountId) => this.setModelState({ accountId })} items={[
                    {
                        Id: "ABC",
                        Name: "An Azure Account",
                        TenantIds: [],
                        EnvironmentIds: [],
                        TenantTags: [],
                        Description: "Desc",
                        Links: {
                            Self: "",
                            Usages: "",
                        },
                        AccountType: AccountType.AzureSubscription,
                        TenantedDeploymentParticipation: TenantedDeploymentMode.Tenanted,
                        SpaceId: "Spaces-1",
                    },
                    {
                        Id: "ABC2",
                        Name: "A Ssh Account",
                        TenantIds: [],
                        EnvironmentIds: [],
                        TenantTags: [],
                        Description: "Desc",
                        Links: {
                            Self: "",
                            Usages: "",
                        },
                        AccountType: AccountType.SshKeyPair,
                        TenantedDeploymentParticipation: TenantedDeploymentMode.Tenanted,
                        SpaceId: "Spaces-1",
                    },
                    {
                        Id: "ABC3",
                        Name: "A Username Account",
                        TenantIds: [],
                        EnvironmentIds: [],
                        TenantTags: [],
                        Description: "Desc",
                        Links: {
                            Self: "",
                            Usages: "",
                        },
                        AccountType: AccountType.UsernamePassword,
                        TenantedDeploymentParticipation: TenantedDeploymentMode.Tenanted,
                        SpaceId: "Spaces-1",
                    },
                ]}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="radioButtonGroup" title="RadioButtonGroup" summary={this.state.model.formError
                    ? Summary.summary(<span>
                                                  Sample summary as a sentence with selected value <strong>{this.state.model.formError}</strong> highlighted.
                                              </span>)
                    : Summary.default("Summary text when a non-default value has been selected")} help="RadioButtonGroup">
                                <RadioButtonGroup title="This is a label" value={this.state.model.formError || "None"} onChange={(formError) => this.setModelState({ formError })}>
                                    <RadioButton value="None" label="None" isDefault={true}/>
                                    <Note>Note for None</Note>
                                    <RadioButton value="Error" label="Error"/>
                                    <Note>Note for Error</Note>
                                    <RadioButton value="Warning" label="Warning"/>
                                    <Note>Note for Warning</Note>
                                </RadioButtonGroup>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="typedRadioButtonGroup" title="Typed Radio Button Group" summary={Summary.summary("Sample of a typed radio button group")} help="TypedRadioButtonGroup">
                                <RadioButtonGroup value={this.state.model.typedRadioButtonGroup} onChange={(x) => this.setState((prevState) => (prevState!.model.typedRadioButtonGroup = x))}>
                                    <RadioButton value="DoNotDelete" label="Do not delete deployment targets automatically" isDefault={true}/>
                                    <RadioButton value="DeleteUnavailableMachines" label="Automatically delete unavailable deployment targets"/>
                                </RadioButtonGroup>
                                {this.state.model.typedRadioButtonGroup === "DeleteUnavailableMachines" ? (<div>
                                        <TimeSpanSelector value={this.state.model.timeSpanText} onChange={(x) => this.setState((prevState) => (prevState!.model.timeSpanText = x))}/>
                                    </div>) : null}
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="colorSelector" title="Color Picker" summary={Summary.summary("Samplecolor selector")} help="Color selector">
                                <ColorPicker value={this.state.model.colorPicker || ""} defaultColor="#ff0000" label="Color" onChange={(color) => this.setState((prevState) => (prevState!.model.colorPicker = color))}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="comma seperated list" title="Comma Delimited String List" summary={Summary.summary("String is internally split by ',' and recombined before passing back")} help="TypedRadioButtonGroup">
                                <StringEditList name="Animal" label="I want a...." items={this.state.model.commaDelimitedList} onChange={(x) => this.setState((prevState) => (prevState!.model.commaDelimitedList = x))}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="Parsed Object Hash" title="Parsed Object Hash" summary={Summary.summary("Value is a stringified object hash that is internally parsed and passed back as a string")} help="TypedRadioButtonGroup">
                                <StringKeyValueEditList separator="=" name="Details" keyLabel="Property" valueLabel="Value" items={this.state.model.objectHash} onChange={(x) => this.setState((prevState) => (prevState!.model.objectHash = x))}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="Key value array" title="Key value array" summary={Summary.summary("Array of key value pairs")} help="TypedRadioButtonGroup">
                                <KeyValueEditList separator="=" name="Details" keyLabel="Property" valueLabel="Value" items={() => this.state.model.keyValuePairs} onChange={(x) => this.setState((prevState) => (prevState!.model.keyValuePairs = x))}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="typedRadioButtonGroup" title="Typed Radio Button Group" summary={Summary.summary("Sample of a typed radio button group")} help="TypedRadioButtonGroup">
                                <RadioButtonGroup value={this.state.model.typedRadioButtonGroup} onChange={(x) => this.setState((prevState) => (prevState!.model.typedRadioButtonGroup = x))}>
                                    <RadioButton value="DoNotDelete" label="Do not delete machines automatically" isDefault={true}/>
                                    <RadioButton value="DeleteUnavailableMachines" label="Automatically delete unavailable machines"/>
                                </RadioButtonGroup>
                                {this.state.model.typedRadioButtonGroup === "DeleteUnavailableMachines" ? (<div>
                                        <TimeSpanSelector value={this.state.model.timeSpanText} onChange={(x) => this.setState((prevState) => (prevState!.model.timeSpanText = x))}/>
                                    </div>) : null}
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="sensitive" title="Sensitive Password" summary={Summary.summary("Sample of sensitive input")} help="Enter a password and see the result..">
                                <Sensitive label="Super secret" value={this.state.model.superSecret!} onChange={(value) => this.setModelState({ superSecret: value })}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="file" title="File Uploading" summary={Summary.summary("Sample of file uploading")} help="Pretend to upload the file">
                                <FileUploadDragDrop label={"Drag and drop a file or select to browse"} onFilesChanged={(files: File[]) => {
                    this.setModelState({ fileUpload: files[0] });
                }}/>
                                <SensitiveFileUpload label="Secret file upload" value={this.state.model.sensitiveFileUpload!} onChange={(sensitiveFileUpload) => this.setModelState({ sensitiveFileUpload })}/>
                            </ExpandableFormSection>
                            <ExpandableFormSection errorKey="timeSpanSelector" title="Time Span" summary={Summary.summary("Sample of a timespan selector")} help="TimeSpanSelector">
                                <TimeSpanSelector value={this.state.model.timeSpanText} onChange={(x) => this.setState((prevState) => (prevState!.model.timeSpanText = x))} error="with an error"/>
                            </ExpandableFormSection>
                            <ExpandableFormSection isExpandedByDefault={true} errorKey="expandedByDefault" title="An Expander That Expanded by Default" summary={Summary.summary("Sample expander that expanded by default")} help="This expander is expanded by default, it lines up with the Redux store's state so Expand all and Collapse all buttons should work.">
                                <div>It is about the expander</div>
                            </ExpandableFormSection>
                        </FormContent>
                    </PaperLayoutVNext>)}
            </Form>);
    }
    private createDefaultModel() {
        return {
            isLoaded: false,
            text: null as unknown as string,
            radioButtonGroup: "Value2",
            typedRadioButtonGroup: "DeleteUnavailableMachines" as DeleteMachinesBehavior,
            timeSpanText: "1.00:05:00",
            select: "Option2",
            emptyProgression: { NextDeployments: [] as string[] },
            singleEnvironmentProgression: { NextDeployments: ["Environments-1"] },
            multipleEnvironmentProgression: { NextDeployments: ["Environments-1", "Environments-2"] },
            environmentsById: {
                "Environments-1": { Id: "Environments-1", Name: "Environment 1" },
                "Environments-2": { Id: "Environments-2", Name: "Environment 2" },
            },
            commaDelimitedList: "cat,dog,monkey",
            objectHash: JSON.stringify({ name: "bob", phone: "555-callme" }),
            keyValuePairs: [{ key: "bob", value: "555-callme" }],
            textForExpandableFormSection: null as unknown as string,
            textForFormSection: null as unknown as string,
        };
    }
    private saveWithValidationError = async () => {
        this.setValidationErrors("Sample validation error");
        return false;
    };
    static displayName = "SampleForm";
}
export default SampleFormPage;
