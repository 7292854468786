import { Callout } from "@octopusdeploy/design-system-components";
import React from "react";
import type { StoredAction } from "~/areas/projects/components/Process/types";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
type HelmV2DeprecationCalloutProps = {
    action: StoredAction;
};
export const HelmV2DeprecationCallout = ({ action }: HelmV2DeprecationCalloutProps) => {
    if (action.ActionType !== "Octopus.HelmChartUpgrade" || (action.Properties["Octopus.Action.Helm.ClientVersion"] !== "V2" && !action.Properties["Octopus.Action.Helm.WasAutoUpdatedToV3"])) {
        return null;
    }
    return (<Callout title="Action required: Migrate to Helm V3" type={"warning"} canClose={false}>
            Helm V2 is deprecated and support will be completely removed in Octopus Server 2025.1. For information on how to migrate to Helm V3, see the <ExternalLink href={"HelmV2ToV3Migration"}>official migration guide</ExternalLink>.
        </Callout>);
};
