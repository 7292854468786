import type { EnvironmentResource, IId, ResourceWithSlug, WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import type { BaseConfiguration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/ConfigurationPage";
import { escapeAllDoubleQuotes, generateAgentAsTargetNamespace, generateAgentAsWorkerNamespace, generateReleaseName, getValidAgentName } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentHelperMethods";
import type { TargetConfigurationResource, HelmChartMajorVersion } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentTargetConfigurationDialog";
import type { WorkerConfigurationResource } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentWorkerConfigurationDialog";
import type { Language, TextFormat } from "~/components/CodeEditor/CodeEditor";
type ResourceWithSlugAndId = ResourceWithSlug & IId;
// This value represents the highest major version of the Kubernetes Agent Helm Chart
// that is supported by this version of Server.
// If this value is being bumped, please also bump the value in the backend:
// KubernetesAgentUpgradeManager.LatestSupportedMajorVersion
// Note that while the "kubernetes-agent-as-worker" feature flag is in use, this isn't used.
const kubernetesAgentLatestSupportedMajorVersion = 2;
function getSlugsForIds(resourceIds: string[], resources: ResourceWithSlugAndId[]) {
    return resourceIds
        .map((id) => resources.find((res) => res.Id === id)?.Slug ?? "")
        .filter((slug) => slug !== "") // This shouldn't do anything, because slugs are required on environments
        .join(",");
}
function getMachineTagsList(machineTags: string[]) {
    // Machine tags can contain double quotes, so we'll escape them so they don't break the helm command
    return machineTags.map((mr) => escapeAllDoubleQuotes(mr)).join(",");
}
export function createTargetHelmCommand(language: ScriptingLanguage | Language | TextFormat, model: TargetConfigurationResource, environments: EnvironmentResource[], helmChartMajorVersion: HelmChartMajorVersion) {
    const machineTags = getMachineTagsList(model.MachineRoles);
    const environmentSlugs = getSlugsForIds(model.EnvironmentIds, environments);
    const defaultNamespace = model.DefaultNamespace.trim();
    const agentName = getValidAgentName(model.Name);
    const namespace = generateAgentAsTargetNamespace(model.Name);
    const targetSpecificValues = getTargetSpecificHelmChartValues(helmChartMajorVersion)(agentName, environmentSlugs, machineTags, defaultNamespace);
    return createHelmCommand(language, model, namespace, targetSpecificValues, `${helmChartMajorVersion}.*.*`);
}
export function createWorkerHelmCommand(language: ScriptingLanguage | Language | TextFormat, model: WorkerConfigurationResource, workerPools: WorkerPoolResource[]) {
    const workerPoolSlugs = getSlugsForIds(model.WorkerPools, workerPools);
    const agentName = getValidAgentName(model.Name);
    const namespace = generateAgentAsWorkerNamespace(model.Name);
    const workerSpecificValues = [
        { name: "agent.name", value: agentName },
        { name: "agent.worker.enabled", value: "true" },
        { name: "agent.worker.initial.workerPools", value: `{${workerPoolSlugs}}` },
    ];
    return createHelmCommand(language, model, namespace, workerSpecificValues, "2.*.*");
}
function createHelmCommand(language: ScriptingLanguage | Language | TextFormat, model: BaseConfiguration, namespace: string, machineSpecificValues: {
    name: string;
    value: string;
}[], version: string) {
    const lineBreak = language === ScriptingLanguage.Bash ? "\\" : "`";
    const releaseName = generateReleaseName(model.Name);
    const storageClassName = model.StorageClassName.trim();
    if (!model.Space) {
        throw new Error("Attempted to render Kubernetes Agent Configuration Dialog outside of a space. This should never happen.");
    }
    const machineSpecificValuesString = machineSpecificValues.map((v) => `--set ${v.name}="${v.value}" ${lineBreak}`).join("\n");
    let setValues = `--set agent.acceptEula="Y" ${lineBreak}
--set agent.serverUrl="${model.ServerUri}" ${lineBreak}
--set agent.serverCommsAddresses="{${model.ServerNodeCommsAddresses.map((n) => n.commsAddress).join(",")}}" ${lineBreak}
--set agent.space="${model.Space.Name}" ${lineBreak}
${machineSpecificValuesString}
--set agent.bearerToken="${model.AccessToken}" ${lineBreak}`;
    if (!!storageClassName) {
        //add the persistence options after the initial set
        setValues += `
--set persistence.storageClassName="${storageClassName}" ${lineBreak}`;
    }
    return `helm upgrade --install --atomic ${lineBreak}
${setValues}
--version "${version}" ${lineBreak}
--create-namespace --namespace ${namespace} ${lineBreak}
${releaseName} ${lineBreak}
oci://registry-1.docker.io/octopusdeploy/kubernetes-agent`;
}
const getTargetSpecificHelmChartValues = (helmChartMajorVersion: HelmChartMajorVersion) => {
    switch (helmChartMajorVersion) {
        case 1:
            return getValuesForHelmChartV1Target;
        case 2:
            return getValuesForHelmChartV2Target;
        default:
            throw new Error(`Unsupported Helm chart major version: ${helmChartMajorVersion}`);
    }
};
function getValuesForHelmChartV1Target(agentName: string, environmentSlugs: string, machineTags: string, defaultNamespace: string) {
    const targetSpecificValues = [
        { name: "agent.targetName", value: agentName },
        { name: "agent.targetEnvironments", value: `{${environmentSlugs}}` },
        { name: "agent.targetRoles", value: `{${machineTags}}` },
    ];
    if (defaultNamespace) {
        targetSpecificValues.push({ name: "agent.defaultNamespace", value: defaultNamespace });
    }
    return targetSpecificValues;
}
function getValuesForHelmChartV2Target(agentName: string, environmentSlugs: string, machineTags: string, defaultNamespace: string) {
    const targetSpecificValues = [
        { name: "agent.name", value: agentName },
        { name: "agent.deploymentTarget.initial.environments", value: `{${environmentSlugs}}` },
        { name: "agent.deploymentTarget.initial.tags", value: `{${machineTags}}` },
        { name: "agent.deploymentTarget.enabled", value: `true` },
    ];
    if (defaultNamespace) {
        targetSpecificValues.push({ name: "agent.deploymentTarget.initial.defaultNamespace", value: defaultNamespace });
    }
    return targetSpecificValues;
}
