import type { SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
export const getImportExportMenuItems = (spaceId: string): SimpleMenuItem[] => [
    {
        type: "internal-link",
        label: "Import Projects",
        path: links.importProjectsPage.generateUrl({ spaceId }),
    },
    {
        type: "internal-link",
        label: "Export Projects",
        path: links.exportProjectsPage.generateUrl({ spaceId }),
    },
    {
        type: "internal-link",
        label: "View Import Export Tasks",
        path: links.importExportProjectTasksPage.generateUrl({ spaceId }),
    },
];
