import type { IconButtonElement, IconButtonProps, TooltipContent } from "@octopusdeploy/design-system-components";
import { IconButton, Tooltip } from "@octopusdeploy/design-system-components";
import React from "react";
export type IconButtonWithTooltipProps = IconButtonProps & {
    toolTipContent: TooltipContent;
};
export const IconButtonWithTooltip = React.forwardRef<IconButtonElement, IconButtonWithTooltipProps>(({ toolTipContent, ...iconButtonProps }, ref) => {
    return (<Tooltip content={toolTipContent}>
            <IconButton ref={ref} {...iconButtonProps}/>
        </Tooltip>);
});
type IconButtonWithOptionalTooltipProps = IconButtonProps & {
    toolTipContent?: TooltipContent;
};
export const IconButtonWithOptionalTooltip = React.forwardRef<IconButtonElement, IconButtonWithOptionalTooltipProps>(({ toolTipContent, ...iconButtonProps }, ref) => {
    if (toolTipContent) {
        return <IconButtonWithTooltip ref={ref} toolTipContent={toolTipContent} {...iconButtonProps}/>;
    }
    return <IconButton ref={ref} {...iconButtonProps}/>;
});
