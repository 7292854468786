/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Callout } from "@octopusdeploy/design-system-components";
import type { ActionTemplateParameterResource, FeedResource, PackageReference, PackageReferenceProperties } from "@octopusdeploy/octopus-server-client";
import { feedTypeSupportsExtraction, PackageAcquisitionLocation, PackageSelectionMode } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import type { RunOn } from "~/areas/projects/components/Process/types";
import DeferredPackageSelector from "~/components/PackageSelector/DeferredPackageSelector";
import { required } from "~/components/form";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import Note from "../../../primitiveComponents/form/Note/Note";
import type { DataBaseComponentState } from "../../DataBaseComponent";
import { DataBaseComponent } from "../../DataBaseComponent";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import PackageDownloadOptions from "../../PackageDownloadOptions/PackageDownloadOptions";
import isBound from "../../form/BoundField/isBound";
import { GeneratePackageNameFromId } from "../commonActionHelpers";
interface PackageReferenceProps {
    packageReference: ScriptPackageReference;
    runOn?: RunOn;
    feeds: FeedResource[];
    localNames: string[];
    projectId: string;
    parameters?: ActionTemplateParameterResource[];
    onChange(PackageReference: ScriptPackageReference): boolean;
    refreshFeeds(): Promise<any>;
}
interface ScriptPackageReferenceState extends DataBaseComponentState {
    packageReferenceId: string;
    name: string;
    packageId: string;
    feedId: string;
    extract: string;
    purpose: string;
    acquisitionLocation: string;
    originalName: string;
    originalPackageId: string;
    showNameDefaultedMessage: boolean;
    showNameChangeWarning: boolean;
    packageSelectionMode: PackageSelectionMode;
    packageParameterName: string;
}
export type ScriptPackageReference = PackageReference<ScriptPackageProperties>;
export interface ScriptPackageProperties extends PackageReferenceProperties {
    Extract: string;
    PackageParameterName?: string;
    SelectionMode?: PackageSelectionMode;
    Purpose?: string;
}
export class ScriptPackageReferenceDialog extends DataBaseComponent<PackageReferenceProps, ScriptPackageReferenceState> {
    constructor(props: PackageReferenceProps) {
        super(props);
        this.state = {
            packageReferenceId: this.props.packageReference.Id,
            name: this.props.packageReference.Name!,
            packageId: this.props.packageReference.PackageId,
            feedId: this.props.packageReference.FeedId,
            extract: this.props.packageReference.Properties["Extract"],
            purpose: this.props.packageReference.Properties["Purpose"] || "",
            acquisitionLocation: this.props.packageReference.AcquisitionLocation,
            originalName: this.props.packageReference.Name!,
            originalPackageId: this.props.packageReference.PackageId,
            showNameDefaultedMessage: false,
            showNameChangeWarning: false,
            packageSelectionMode: this.props.packageReference.Properties["SelectionMode"] || PackageSelectionMode.Immediate,
            packageParameterName: this.props.packageReference.Properties["PackageParameterName"]!,
        };
    }
    render() {
        const feed = _.find(this.props.feeds, (f) => f.Id === this.state.feedId);
        const showExtract = this.shouldShowExtractOption(this.state.feedId);
        return (<OkDialogLayout onOkClick={this.save} busy={this.state.busy} errors={this.errors} title="Reference a Package">
                {this.props.packageReference && (<div>
                        <DeferredPackageSelector packageId={this.state.packageId} feedId={this.state.feedId} onPackageIdChange={(packageId) => this.onPackageIdChange(packageId)} onFeedIdChange={(feedId) => this.onFeedIdChange(feedId)} localNames={this.props.localNames} projectId={this.props.projectId} feeds={this.props.feeds} refreshFeeds={this.props.refreshFeeds} 
            // Deferred package selection properties
            parameters={this.props.parameters} packageSelectionMode={this.state.packageSelectionMode} onPackageSelectionModeChange={(packageSelectionMode) => this.onPackageSelectionModeChange(packageSelectionMode)} packageParameterName={this.state.packageParameterName} onPackageParameterChange={(packageParameter) => this.onPackageParameterChange(packageParameter)}/>
                        {this.state.showNameChangeWarning && (<Callout type={"warning"} title={"The name has been changed"}>
                                If you have external dependencies on this field (custom scripts, build-server plugins, external feed triggers, etc) these will need to be updated.
                            </Callout>)}
                        <DebounceText label="Name" value={this.state.name} onChange={(name) => this.onNameChange(name)} validate={required("Please supply a Name")}/>
                        <Note>
                            The name is used to identify the package reference. Learn more about the <ExternalLink href="ScriptStepPackageReferenceName">package name</ExternalLink>.
                        </Note>
                        <PackageDownloadOptions packageAcquisitionLocation={this.state.acquisitionLocation} onPackageAcquisitionLocationChanged={(acquisitionLocation) => this.setState({ acquisitionLocation: acquisitionLocation! })} runOn={this.props.runOn} showNotAcquiredOption={true} feed={feed} projectId={this.props.projectId} localNames={this.props.localNames}/>
                        {showExtract && (<React.Fragment>
                                <BoundStringCheckbox variableLookup={{
                        localNames: this.props.localNames,
                    }} resetValue={this.props.packageReference.Properties["Extract"]} value={this.state.extract} label="Extract package during deployment" onChange={(extract) => this.setState({ extract })}/>
                                <Note>
                                    Learn more about <ExternalLink href="ScriptStepPackageReferencesFromCustomScripts">Accessing Package References from a Custom Script</ExternalLink>.
                                </Note>
                            </React.Fragment>)}
                    </div>)}
            </OkDialogLayout>);
    }
    save = () => {
        if (this.state.packageSelectionMode === PackageSelectionMode.Immediate) {
            if (!this.state.name) {
                this.setValidationErrors("Please enter a name");
                return false;
            }
            if (!this.state.packageId) {
                this.setValidationErrors("Please select a package ID");
                return false;
            }
            if (!this.state.feedId) {
                this.setValidationErrors("Please select a feed ID");
                return false;
            }
        }
        else {
            if (!this.state.packageParameterName) {
                this.setValidationErrors("Please select a package parameter");
                return false;
            }
        }
        const packageReference = {
            Id: this.state.packageReferenceId,
            Name: this.state.name,
            PackageId: this.state.packageId,
            FeedId: this.state.feedId,
            AcquisitionLocation: this.state.acquisitionLocation,
            Properties: {
                Extract: this.state.extract,
                SelectionMode: this.state.packageSelectionMode,
                PackageParameterName: this.state.packageParameterName,
                Purpose: this.state.purpose,
            },
        };
        return this.props.onChange(packageReference);
    };
    onPackageSelectionModeChange = (packageSelectionMode: PackageSelectionMode) => {
        this.setState({
            packageSelectionMode,
            packageParameterName: "",
        });
    };
    onPackageParameterChange = (packageParameter: string) => {
        let name = this.state.name;
        let showNameChangeWarning = false;
        if (!!packageParameter && !name) {
            name = GeneratePackageNameFromId(packageParameter);
            showNameChangeWarning = this.shouldShowNameChangeWarning(name);
        }
        this.setState({
            packageId: null!,
            name,
            packageParameterName: packageParameter,
            showNameChangeWarning,
        });
    };
    onPackageIdChange = (packageId: string) => {
        let name = this.state.name;
        let showNameDefaultedMessage = false;
        let showNameChangeWarning = false;
        // If `Name` was the default value (the previous package ID), then update it to the new package ID
        if (!!packageId && !isBound(packageId) && !(!!this.state.originalName && this.state.originalName !== GeneratePackageNameFromId(this.state.originalPackageId))) {
            name = GeneratePackageNameFromId(packageId);
            showNameChangeWarning = this.shouldShowNameChangeWarning(name);
            showNameDefaultedMessage = !showNameChangeWarning; // Showing both the info and the warning gets a bit noisy
        }
        this.setState({
            packageId,
            name,
            showNameDefaultedMessage,
            showNameChangeWarning,
        });
    };
    onFeedIdChange = (feedId: string) => {
        this.setState({ feedId });
        // If the feed doesn't support extracting packages, then reset extract to "False"
        if (!this.shouldShowExtractOption(feedId)) {
            this.setState({ extract: "False" });
        }
    };
    onNameChange = (name: string) => {
        this.setState({
            name,
            showNameChangeWarning: this.shouldShowNameChangeWarning(name),
        });
    };
    shouldShowExtractOption = (feedId: string) => {
        const feed = _.find(this.props.feeds, (f) => f.Id === feedId);
        // Show the option to extract if the feed-type supports it
        // or if the feed is bound to a variable.
        return (!feed || feedTypeSupportsExtraction(feed.FeedType)) && this.state.acquisitionLocation !== PackageAcquisitionLocation.NotAcquired;
    };
    shouldShowNameChangeWarning = (name: string) => {
        // If the name is changing from a previous value, then we'll show a warning regarding external dependencies
        return !!name && !!this.state.originalName && name !== this.state.originalName;
    };
    static displayName = "ScriptPackageReferenceDialog";
}
