/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { CommunicationStyle, CommunicationStyleResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { EndpointCommunicationStyleChip, MissingChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface EndpointCommunicationStyleMultiSelectProps extends FormFieldProps<CommunicationStyle[]> {
    items: CommunicationStyleResource[];
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
const EndpointCommunicationStyleTypedMultiSelect = MultiSelect<CommunicationStyleResource>();
export const EndpointCommunicationStyleMultiSelect: React.FC<EndpointCommunicationStyleMultiSelectProps> = (props) => {
    const { onChange, ...otherProps } = props;
    const chipRenderer = (r: CommunicationStyleResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element: CommunicationStyleResource) => element.Id} render={(item: CommunicationStyleResource) => {
                return <EndpointCommunicationStyleChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} commStyle={item}/>;
            }} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.CommunicationStyle}/>}/>);
    };
    return <EndpointCommunicationStyleTypedMultiSelect fieldName="communication styles" renderChip={chipRenderer} onChange={(value) => onChange(value as CommunicationStyle[])} {...otherProps}/>;
};
EndpointCommunicationStyleMultiSelect.displayName = "EndpointCommunicationStyleMultiSelect"
