/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import { FilterTextChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import { AdvancedFilterCheckbox, AdvancedFilterTextInput } from "../AdvancedFilterLayout";
import type { IQuery } from "../QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "../QueryStringFilters/QueryStringFilters";
import type { SelectItem } from "../VirtualListWithKeyboard/SelectItem";
import StyleGuideEntry from "./StyleGuideEntry";
export interface IQueryStringFilter {
    checkbox: boolean;
    text: string;
    environments: Environment[];
}
export interface IQueryStringQuery extends IQuery {
    checkbox: string;
    text: string;
    environments: string[];
}
interface IProps {
    filter: IQueryStringFilter;
    onFilterChange: (filter: IQueryStringFilter) => void;
}
export function defaultQueryStringFilter() {
    const filter: IQueryStringFilter = {
        checkbox: false,
        text: undefined!,
        environments: [],
    };
    return filter;
}
enum Environment {
    Dev = "Dev",
    Prod = "Prod"
}
const allEnvironments = Object.keys(Environment).reduce<Environment[]>((p, c: string) => [...p, Environment[c as Environment]], []);
const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => <FilterTextChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${r.Name}`} filterText={r.Id}/>;
const DemoQueryStringFilters = QueryStringFilters.For<IQueryStringFilter, IQueryStringQuery>();
const DemoMultiSelect = MultiSelect<SelectItem>();
export function renderQueryStringFiltersEntry(props: IProps) {
    return (<StyleGuideEntry heading="QueryStringFilters" context={<span>Synchronises a filter with the query string.</span>}>
            <ul>
                <li>Each filter type used with this component will require a matching query type that represents the filter normalised in a query string</li>
                <li>Query types may only contain properties of type string[], string or undefined</li>
                <li>Synchronisation is two way and updates in real-time if the query string is manually modified.</li>
            </ul>
            <AdvancedFilterCheckbox label="Checkbox" value={props.filter.checkbox} onChange={(checkbox) => props.onFilterChange({ ...props.filter, checkbox })}/>
            <AdvancedFilterTextInput fieldName={"Text"} value={props.filter.text} onChange={(text) => props.onFilterChange({ ...props.filter, text })}/>
            <DemoQueryStringFilters filter={props.filter} getQuery={(filter) => getQuery(filter)} getFilter={(query) => getFilter(query)} onFilterChange={(filter) => props.onFilterChange(filter)}/>
            <DemoMultiSelect items={allEnvironments.map((i) => ({ Id: i, Name: i }))} value={props.filter.environments} fieldName="Environments" renderChip={chipRenderer} onChange={(environments: string[]) => props.onFilterChange({ ...props.filter, environments: environments as Environment[] })}/>
        </StyleGuideEntry>);
}
function getQuery(filter: IQueryStringFilter): IQueryStringQuery {
    const query: IQueryStringQuery = {
        checkbox: filter.checkbox ? "true" : undefined!,
        text: filter.text,
        environments: filter.environments,
    };
    return query;
}
function getFilter(query: IQueryStringQuery): IQueryStringFilter {
    const filter: IQueryStringFilter = {
        checkbox: query.checkbox === "true",
        text: query.text,
        environments: arrayValueFromQueryString(query.environments) as Environment[],
    };
    return filter;
}
