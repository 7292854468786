import React from "react";
import { useHiddenFeatures } from "~/areas/configuration/components/FeaturesLayout/useHiddenFeatures";
import { FormSectionHeading } from "~/components/form";
export interface ExperimentalFeaturesGroupProps {
    children?: React.ReactNode;
}
export function ExperimentalFeaturesGroup(props: ExperimentalFeaturesGroupProps) {
    const areHiddenFeaturesShown = useHiddenFeatures();
    if (!areHiddenFeaturesShown)
        return null;
    return (<React.Fragment>
            <FormSectionHeading key="ExperimentalFeaturesGroup" title={"Experimental"}/>
            {props.children}
        </React.Fragment>);
}
