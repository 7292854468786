import { Tooltip } from "@octopusdeploy/design-system-components";
import type { InsightsTrendDirection, SparklineTrendResource } from "@octopusdeploy/octopus-server-client";
import moment from "moment";
import type { ReactNode } from "react";
import React from "react";
import { Note } from "~/components/form";
import { TrendSparklineChart } from "../../../Charts/TrendSparklineChart";
import type { ChartStrokeColor } from "../../../chartColors";
import styles from "./SparklineTrendPair.module.less";
interface Props<T extends string | number> {
    sectionTitle: string;
    leftData: SparklineTrendResource<T>[];
    rightData?: SparklineTrendResource<T>[];
    leftTitle?: string;
    rightTitle?: string;
    valueFormatter?: (val: T | null) => string | number | null;
    dataPointFormatter?: (val: T | null) => number;
    note: ReactNode;
    directionColorLookup?: Record<InsightsTrendDirection, ChartStrokeColor>;
}
const defaultDirectionColorLookup: Record<InsightsTrendDirection, ChartStrokeColor> = {
    None: "cyan",
    Steady: "cyan",
    Up: "red",
    Down: "green",
};
export const frequencyTrendColors: Record<InsightsTrendDirection, ChartStrokeColor> = {
    None: "cyan",
    Steady: "cyan",
    Up: "green",
    Down: "red",
};
// The only current case where the datapoint is a string is Lead Time (which is a duration)
const defaultDataPointFormatter = (val: string | number | null) => {
    if (!val)
        return 0;
    if (typeof val === "string")
        return moment.duration(val).asMinutes();
    return val;
};
export function SparklineTrendPair<T extends string | number>({ sectionTitle, leftData, rightData, valueFormatter = (val) => val, dataPointFormatter = defaultDataPointFormatter, note, leftTitle, rightTitle, directionColorLookup = defaultDirectionColorLookup, }: Props<T>) {
    return (<div className={styles.container}>
            <div className={styles.sectionHeading}>
                <h2 className={styles.title}>{sectionTitle}</h2>
            </div>
            <div className={styles.contentContainer}>
                <SparklineTrendList title={leftTitle} data={leftData} valueFormatter={valueFormatter} dataPointFormatter={dataPointFormatter} colorLookup={directionColorLookup}/>
                {rightData !== undefined && <SparklineTrendList title={rightTitle} data={rightData} valueFormatter={valueFormatter} dataPointFormatter={dataPointFormatter} colorLookup={directionColorLookup}/>}
            </div>
            <div className={styles.contentContainer}>
                <Note>{note}</Note>
            </div>
        </div>);
}
interface SparklineTrendListProps<T extends string | number> {
    title?: string;
    data: SparklineTrendResource<T>[];
    valueFormatter?: (val: T | null) => string | number | null;
    dataPointFormatter?: (val: T | null) => number;
    colorLookup: Record<InsightsTrendDirection, ChartStrokeColor>;
}
function SparklineTrendList<T extends string | number>({ title, data, valueFormatter = (val) => val, dataPointFormatter = defaultDataPointFormatter, colorLookup }: SparklineTrendListProps<T>) {
    return (<div className={styles.listContainer}>
            {title && (<div className={styles.listHeading}>
                    <span>{title}</span>
                </div>)}
            <ul>
                {data.length === 0 && <li className={styles.nothingToShow}>Nothing to show</li>}
                {data.map((row) => {
            const valueJsx = <span className={styles.rowValue}>{valueFormatter(row.Current)}</span>;
            return (<li key={row.Id} className={styles.listItem}>
                            <span className={styles.rowTitle}>{row.Name}</span>
                            <TrendSparklineChart data={row.DataPoints.map((p) => ({ value: dataPointFormatter(p) }))} dataKey="value" color={colorLookup[row.Direction]}/>
                            <div className={styles.rowContainer}>{row.Previous ? <Tooltip content={`Previous: ${valueFormatter(row.Previous)}`}>{valueJsx}</Tooltip> : valueJsx}</div>
                        </li>);
        })}
            </ul>
        </div>);
}
