import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { GitCredentialResource, GitDependencyReference } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import PermissionCheck from "app/components/PermissionCheck/PermissionCheck";
import ExpandableFormSection, { type SummaryNode } from "app/components/form/Sections/ExpandableFormSection";
import React, { useState } from "react";
import GitCredentialSelect from "~/areas/projects/components/ProjectSettings/VersionControl/GitCredentialSelect";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { Note, required, Summary } from "~/components/form/index";
export type SimpleGitRepositorySourceSelectorProps = {
    gitDependency: GitDependencyReference;
    onGitDependencyChanged: (gitDependency: GitDependencyReference) => void;
    gitCredentials: GitCredentialResource[];
    refreshGitCredentials: () => Promise<unknown>;
    expandedByDefault: boolean;
    localNames: string[] | undefined;
    getFieldError(field: string): string;
    errorKeyPrefix: string | undefined;
};
export const SimpleGitRepositorySourceSelector = (props: SimpleGitRepositorySourceSelectorProps) => {
    const [gitDependency, setGitDependency] = useState<GitDependencyReference>(props.gitDependency);
    const repoUrlSummary = (): SummaryNode => (gitDependency.RepositoryUri ? Summary.summary(gitDependency.RepositoryUri) : Summary.placeholder("Enter a Git repository URL"));
    const setResourceProperty = (property: keyof GitDependencyReference, value: string) => {
        const updated = {
            ...gitDependency,
            [property]: value,
        };
        setGitDependency(updated);
        props.onGitDependencyChanged(updated);
    };
    const isUsingLibraryAuth = (): boolean => gitDependency.GitCredentialType === "Library";
    const authSummary = (): SummaryNode => {
        if (isUsingLibraryAuth()) {
            const id = gitDependency.GitCredentialId;
            const credential = props.gitCredentials.find((c) => c.Id === id);
            return Summary.summary(React.Children.toArray([
                <span>Using library credential</span>,
                <span>
                        <strong>{credential ? ` ${credential.Name}` : ""}</strong>
                    </span>,
            ]));
        }
        else {
            return Summary.summary(React.Children.toArray([
                <span>
                        Authenticated as an <strong>Anonymous</strong> user
                    </span>,
            ]));
        }
    };
    const setCredentialType = (val: string) => {
        if (val === "Anonymous") {
            const updated: GitDependencyReference = {
                ...gitDependency,
                GitCredentialType: val,
                GitCredentialId: undefined,
            };
            setGitDependency(updated);
            props.onGitDependencyChanged(updated);
        }
        else {
            setResourceProperty("GitCredentialType", val);
        }
    };
    const branchSummary = (): SummaryNode => gitDependency.DefaultBranch
        ? Summary.summary(<span>
                      Default branch is <strong>{gitDependency.DefaultBranch}</strong>
                  </span>)
        : Summary.placeholder("Configure default branch");
    return (<>
            <ExpandableFormSection errorKey={`${props.errorKeyPrefix}Url`} title="Repository URL" summary={repoUrlSummary()} help="Specify the URL of the git repository" isExpandedByDefault={props.expandedByDefault}>
                <VariableLookupText key="Url" localNames={props.localNames} value={gitDependency.RepositoryUri} onChange={(url) => setResourceProperty("RepositoryUri", url)} label="Repository URL" error={props.getFieldError("RepositoryUri")} validate={required("Enter a Git repository URL.")} accessibleName={"URL for Git repository"}/>
                <Note>
                    The HTTPS URL to your git repo. E.g.{" "}
                    <code>
                        https://
                        <wbr />
                        github.com/
                        <wbr />
                        OctopusDeploy/
                        <wbr />
                        OctopusClients.git
                    </code>
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey={`${props.errorKeyPrefix}Authentication`} isExpandedByDefault={props.expandedByDefault} title="Authentication" summary={authSummary()} help={"Specify how to authenticate to the git repository"}>
                <RadioButtonGroup value={gitDependency.GitCredentialType} onChange={(val) => setCredentialType(val)} error={props.getFieldError("GitCredentialType")} accessibleName="GitCredentialType">
                    <RadioButton value={"Library"} label="Git credentials" accessibleName="GitCredentialTypeLibrary"/>
                    {isUsingLibraryAuth() && (<PermissionCheck permission={Permission.GitCredentialView} alternate={<Note>You need the 'GitCredentialView' permission to change the Git credential.</Note>}>
                            <GitCredentialSelect items={props.gitCredentials} onChange={(id) => setResourceProperty("GitCredentialId", id)} value={gitDependency.GitCredentialId ?? ""} onRequestRefresh={props.refreshGitCredentials} error={props.getFieldError("GitCredentialId")} helperText="Select the Git credential to use" data-testid="gitCredentialSelect"/>
                            <Note> Use credential from the Git credential library </Note>
                        </PermissionCheck>)}
                    <RadioButton value={"Anonymous"} label="Anonymous" accessibleName="GitCredentialTypeAnonymous"/>
                </RadioButtonGroup>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey={`${props.errorKeyPrefix}DefaultBranch`} title="Branch Settings" summary={branchSummary()} help="Specify the default branch you want to use" isExpandedByDefault={props.expandedByDefault}>
                <VariableLookupText key="DefaultBranch" localNames={props.localNames} value={gitDependency.DefaultBranch} onChange={(defaultBranch) => setResourceProperty("DefaultBranch", defaultBranch)} label="Default Branch" error={props.getFieldError("DefaultBranch")} validate={required("Enter the default branch for your Git repository.")} accessibleName={"Name of the default branch on the Git repository"}/>
            </ExpandableFormSection>
        </>);
};
