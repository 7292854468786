import { css } from "@emotion/css";
import { Button } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { GitPersistenceSettings, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { PersistenceSettingsType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { repository } from "~/clientInstance";
import { DevToolsTab } from "~/components/DevTools/DevToolsContext";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
export function GitRunbooksDevToolsTab({ project }: {
    project: ProjectResource | undefined;
}): JSX.Element | null {
    // if the project is undefined, don't show the tab
    if (!project) {
        return null;
    }
    // if the project is not version controlled, don't show the tab
    if (project.PersistenceSettings.Type !== PersistenceSettingsType.VersionControlled) {
        return null;
    }
    // if project has no git conversion state, don't show the tab
    if (!isIContainsConversionState(project)) {
        return null;
    }
    return (<FeatureToggleVisibility toggle="GitRunbooksFeatureToggle">
            <DevToolsTab name={`Git Runbooks`}>
                <DevToolsTabContent project={project}/>
            </DevToolsTab>
        </FeatureToggleVisibility>);
}
// State keeping logic and rendering logic must be in separate components for dev tools tab to refresh when state changes
function DevToolsTabContent({ project }: {
    project: ProjectResource & IContainsConversionState;
}): JSX.Element {
    const [busy, setBusy] = React.useState(false);
    const [runbooksAreInGit, setRunbooksAreInGit] = React.useState(project.PersistenceSettings.ConversionState.RunbooksAreInGit);
    const styles = {
        container: css({
            margin: space[16],
        }),
    };
    async function VersionControlRunbooks(project: ProjectResource): Promise<void> {
        setBusy(true);
        try {
            await repository.Projects.migrateRunbooksToGit(project);
            setRunbooksAreInGit(true);
        }
        catch (error) {
            logger.error("Failed to convert runbooks to Git", error);
            throw error;
        }
        finally {
            setBusy(false);
        }
    }
    return (<div className={styles.container}>
            {runbooksAreInGit ? (<p>Congratulations! Your runbooks are version controlled with Git.</p>) : (<Button disabled={busy} label="Convert to Version Controlled Runbooks" onClick={async () => {
                if (project) {
                    await VersionControlRunbooks(project);
                }
            }} importance="primary"/>)}
        </div>);
}
interface IContainsConversionState {
    PersistenceSettings: GitPersistenceSettings;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isIContainsConversionState(obj: any): obj is IContainsConversionState {
    return !!obj && "PersistenceSettings" in obj && "ConversionState" in obj.PersistenceSettings;
}
