/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { TaskStatusMessages, SpaceResource, TaskDetailsResource, TaskResource, ResourceCollection } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import TaskProgress from "~/areas/tasks/components/Task/TaskProgress";
import TaskQueueCallout from "~/areas/tasks/components/Task/TaskQueueCallout";
import TaskQueueOrderedList from "~/areas/tasks/components/Task/TaskQueueOrderedList";
import TaskStatusMessagesCallout from "~/areas/tasks/components/Task/TaskStatusMessagesCallout";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import PageDivider from "~/components/PageDivider/index";
import Section from "~/components/Section/index";
import { Note } from "~/components/form/index";
interface QueuedTaskDetailsProps {
    task: TaskResource;
    taskDetails: TaskDetailsResource;
    doBusyTask: DoBusyTask;
}
export default function QueuedTaskDetails({ task, taskDetails, doBusyTask }: QueuedTaskDetailsProps) {
    const [queuedBehind, setQueuedBehind] = useState<ResourceCollection<TaskResource<{}>> | null>(null);
    const [tasksAtLimit, setTasksAtLimit] = useState<TaskResource[]>([]);
    const [tasksInQueue, setTasksInQueue] = useState<TaskResource[]>([]);
    const [space, setSpace] = useState<SpaceResource | null>(null);
    const [messages, setMessages] = useState<TaskStatusMessages | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        setSpace(await repository.Spaces.get(task.SpaceId!));
    }, [task.SpaceId]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const taskScopedRepository = task.SpaceId ? await repository.forSpace(task.SpaceId) : repository.forSystem();
        const queuedBehindPromise = taskScopedRepository.Tasks.getQueuedBehind(task);
        const messagesPromise = taskScopedRepository.Tasks.getTaskStatusMessages(task);
        const blockingTasksPromise = repository.Tasks.getBlockingTasks(task);
        const queuedBehind = await queuedBehindPromise;
        const messages = await messagesPromise;
        const blockingTasks = await blockingTasksPromise;
        setQueuedBehind(queuedBehind);
        setMessages(messages);
        setTasksAtLimit(blockingTasks.TasksAtLimit);
        setTasksInQueue(blockingTasks.TasksInQueue);
    }, [task] // task is updated in the parent refresh loop, so this effect will run every time after the parent refresh loop
    );
    if (messages === null || space === null || queuedBehind === null) {
        return null;
    }
    return (<div className={styles.container}>
            <TaskStatusMessagesCallout messages={messages}/>
            <TaskProgress details={taskDetails}/>
            <TaskQueueCallout space={space} queueLength={queuedBehind.TotalResults} tasksAtLimit={tasksAtLimit}/>
            <PageDivider>&nbsp;</PageDivider>
            <Section sectionHeader="Blocking Tasks">
                <Note>Tasks listed below are excluded from running concurrently. This task can run once all previous tasks in the queue have completed.</Note>
                <TaskQueueOrderedList currentTask={task} tasksInQueue={tasksInQueue}/>
            </Section>
        </div>);
}
const styles = {
    container: css({
        paddingTop: space[8],
    }),
};
