import * as React from "react";
import styles from "./style.module.less";
const CardTitle: React.StatelessComponent<{
    children: React.ReactNode;
}> = (props) => {
    return <h3 className={styles.cardTitle}>{props.children}</h3>;
};
CardTitle.displayName = "CardTitle"
CardTitle.displayName = "CardTitle";
export default CardTitle;
