/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { space, themeTokens, lineHeight, borderWidth } from "@octopusdeploy/design-system-tokens";
import { AnalyticSessionProvider, useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { PageProvider, usePage } from "@octopusdeploy/portal-routes";
import { OctopusSessionUserProvider, useOctopusSessionUser } from "@octopusdeploy/session";
import cn from "classnames";
import { cloneDeep } from "lodash";
import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { MemoryRouter } from "react-router-dom";
import * as showdown from "showdown";
import * as xss from "xss";
import filterXss from "xss";
import TextWithLinks from "~/components/TextWithLinks/TextWithLinks";
interface MarkdownProps {
    markup: string;
    mutedTextColor?: boolean;
    noMargins?: boolean;
}
const Markdown: React.FC<MarkdownProps> = (props) => {
    //TODO: Page changed won't get synchronised to this detached tree. Ideally we would use something
    //which doesn't generate a detached react tree which would preserve our existing contexts etc.
    const currentPage = usePage();
    const currentUser = useOctopusSessionUser();
    const analyticsSession = useAnalyticSession();
    showdown.extension("LinkRenderer", () => {
        return [
            {
                type: "lang",
                filter: (text, converter, options) => {
                    //Showdown creates a separate detached component hierarchy, therefore we have to setup any
                    //contexts again.
                    const rendered = renderToStaticMarkup(<MemoryRouter>
                            <OctopusSessionUserProvider currentUser={currentUser}>
                                <AnalyticSessionProvider session={analyticsSession}>
                                    <PageProvider page={currentPage}>
                                        <TextWithLinks message={text} allowHtml={true}/>
                                    </PageProvider>
                                </AnalyticSessionProvider>
                            </OctopusSessionUserProvider>
                        </MemoryRouter>);
                    //this is less than ideal, but as we've had to render without the normal router available, we cant resolve links as well as we'd like.
                    return rendered.replace("href=\"/", "href=\"#/");
                },
            },
        ];
    });
    const markdownConverter = new showdown.Converter({
        tables: true,
        strikethrough: true,
        openLinksInNewWindow: true,
        disableForced4SpacesIndentedSublists: true,
        literalMidWordUnderscores: true,
    });
    markdownConverter.useExtension("LinkRenderer");
    const potentiallyUnsafeHtml = markdownConverter.makeHtml(props.markup);
    const xssFilterWhitelist = cloneDeep(xss.whiteList);
    xssFilterWhitelist["em"]!.push("style");
    xssFilterWhitelist["em"]!.push("class");
    xssFilterWhitelist["a"]!.push("rel");
    const html = filterXss(potentiallyUnsafeHtml, { whiteList: xssFilterWhitelist });
    return (<div className={cn(styles.base, {
            [styles.mutedTextColorStyling]: props.mutedTextColor,
            [styles.noMarginsStyling]: props.noMargins,
        })} dangerouslySetInnerHTML={{ __html: html }}/>);
};
Markdown.displayName = "Markdown"
export default Markdown;
const listPaddingLeft = space[40];
const styles = {
    base: css({
        lineHeight: lineHeight.xSmall,
        "& .list": {
            paddingLeft: listPaddingLeft,
        },
        ul: {
            listStyleType: "disc",
            paddingLeft: listPaddingLeft,
        },
        ol: {
            listStyleType: "decimal",
            paddingLeft: listPaddingLeft,
        },
        blockquote: {
            borderLeft: [borderWidth["4"], "solid", themeTokens.color.border.primary].join(" "),
            paddingLeft: space["16"],
        },
    }),
    mutedTextColorStyling: css({
        p: {
            color: themeTokens.color.text.secondary,
        },
    }),
    noMarginsStyling: css({
        p: {
            margin: 0,
        },
    }),
};
