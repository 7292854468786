import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, FilterIcon } from "@octopusdeploy/design-system-components";
import type { DashboardConfigurationResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ProjectsDashboardAdvancedFiltersDialogLayout } from "~/areas/dashboard/DashboardConfiguration/ProjectsDashboardAdvancedFiltersDialogLayout";
import { Skeleton } from "~/areas/projects/components/Projects/Skeleton";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
interface ProjectsDashboardAdvancedFiltersProps {
    onAdvancedFilterChange: () => void;
    doBusyTask: DoBusyTask;
}
export function ProjectsDashboardAdvancedFilters({ onAdvancedFilterChange, doBusyTask }: ProjectsDashboardAdvancedFiltersProps) {
    const { dashboardConfiguration, setDashboardConfiguration } = useDashboardConfigurationData(doBusyTask);
    const appliedFilterCount = getAppliedConfigurationFiltersCount(dashboardConfiguration);
    const { isOpen: isAdvancedFiltersDialogOpen, openDialog: openAdvancedFiltersDialog, closeDialog: closeAdvancedFiltersDialog } = useDialogTrigger();
    const onDashboardConfigurationChange = (newValue: DashboardConfigurationResource) => {
        setDashboardConfiguration(newValue);
        onAdvancedFilterChange();
    };
    return (<>
            <ActionButton type={ActionButtonType.Ternary} label={`Advanced filters ${appliedFilterCount === 0 ? "" : ` (${appliedFilterCount})`}`} icon={<FilterIcon />} onClick={openAdvancedFiltersDialog}/>
            <CustomDialog close={closeAdvancedFiltersDialog} open={isAdvancedFiltersDialogOpen} render={() => <ProjectsDashboardAdvancedFiltersDialogLayout onDashboardConfigurationChange={onDashboardConfigurationChange} open={isAdvancedFiltersDialogOpen} close={closeAdvancedFiltersDialog}/>}/>
        </>);
}
function useDashboardConfigurationData(doBusyTask: DoBusyTask) {
    const [dashboardConfiguration, setDashboardConfiguration] = React.useState<DashboardConfigurationResource | undefined>(undefined);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const result = await repository.DashboardConfiguration.get();
        setDashboardConfiguration(result);
    }, []);
    return { dashboardConfiguration, setDashboardConfiguration };
}
function getAppliedConfigurationFiltersCount(configurationData?: DashboardConfigurationResource): number {
    if (!configurationData) {
        return 0;
    }
    const filtersToCount = [configurationData.IncludedProjectIds, configurationData.IncludedProjectGroupIds, configurationData.IncludedEnvironmentIds, [...configurationData.IncludedTenantIds, ...configurationData.IncludedTenantTags]];
    return filtersToCount.reduce((prev, item) => {
        if (item.length === 0) {
            return prev;
        }
        return prev + 1;
    }, 0);
}
export function ProjectsDashboardAdvancedFiltersSkeleton() {
    return (<div className={skeletonStyles}>
            <Skeleton shape={"Rounded"} borderRadius={"small"}/>
        </div>);
}
const skeletonStyles = css({
    height: "2.25rem",
    width: "11.125rem",
});
