import type { LinkHref } from "@octopusdeploy/portal-routes";
import { resolvePathWithSpaceId } from "@octopusdeploy/portal-routes";
import type { LocationDescriptor } from "history";
import type { useHistory } from "react-router";
import { isUrl } from "../InternalLink/isUrl";
import type { UrlResolver } from "../useUrlResolver";
export class SpaceAwareNavigation {
    constructor(private readonly history: ReturnType<typeof useHistory>, private readonly spaceId: string | undefined, private readonly urlResolver: UrlResolver) {
        this.history = history;
        this.spaceId = spaceId;
        this.urlResolver = urlResolver;
    }
    navigate = (path: LinkHref | LocationDescriptor) => {
        this.history.push(this.resolve(path));
    };
    redirect = (path: LinkHref | LocationDescriptor) => {
        this.history.replace(this.resolve(path));
    };
    open = (path: LinkHref | LocationDescriptor) => {
        window.open(`#${this.resolve(path)}`);
    };
    private resolve = (path: LinkHref | LocationDescriptor) => {
        return isUrl(path) ? this.urlResolver.resolve(path) : resolvePathWithSpaceId(path, this.spaceId);
    };
}
