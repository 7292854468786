import type { ActionType } from "typesafe-actions";
import { createAction } from "typesafe-actions";
const drawerActions = {
    openDrawer: createAction("DRAWER:OPEN")(),
    closeDrawer: createAction("DRAWER:CLOSE")(),
    toggleDrawer: createAction("DRAWER:TOGGLE")(),
    setDrawerVisibility: createAction("DRAWER:VALUE", (value: boolean) => value)(),
};
type DrawerAction = ActionType<typeof drawerActions>;
const { openDrawer, closeDrawer, setDrawerVisibility, toggleDrawer } = drawerActions;
export { openDrawer, closeDrawer, setDrawerVisibility, toggleDrawer, DrawerAction };
export default drawerActions;
