import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import styles from "./style.module.less";
type Props = {
    name: string;
    number: string;
    stepType: string;
    docsLink?: string;
};
const StepName = ({ name, number, stepType, docsLink }: Props) => (<div className={styles.container}>
        <div className={styles.stepTitle}>
            <div className={styles.stepType}>{stepType}</div>
            <div className={styles.stepSubTitleContainer}>
                <div>
                    <span className={styles.stepNumber}>{number}.</span>
                    <span>{name}</span>
                </div>
                {docsLink && (<div className={styles.stepTitleDocsLink}>
                        <ExternalLink href={docsLink} trackAnalytics={true}>
                            Docs
                        </ExternalLink>
                    </div>)}
            </div>
        </div>
    </div>);
export default StepName;
