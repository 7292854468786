import Editor, { loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import * as React from "react";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
type ProcessCodeEditorProps = {
    code: string;
    onChange: (code: string | undefined) => void;
    readOnly: boolean;
};
function ProcessCodeEditor({ code, onChange, readOnly }: ProcessCodeEditorProps) {
    const themePaletteType = useThemePaletteType();
    loader.config({ monaco });
    return (<Editor height="60vh" defaultLanguage="hcl" value={code} onChange={onChange} theme={themePaletteType === "dark" ? "vs-dark" : "light"} options={{
            readOnly,
        }}/>);
}
export default ProcessCodeEditor;
