import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import image from "../../Images/Release.svg";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const video = {
    youtubeId: "syfl59pR4ZU",
    shortlink: "OnboardingReleasesVideo",
};
export const CommonProjectReleaseOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    <ExternalLink href="OnboardingReleasesLearnMore">Releases</ExternalLink> are a snapshot of your deployment process, configuration variables, and software packages. A release is deployed to the environments defined in the project's
                    lifecycle.
                </p>
                <ImageWithPlaceholder src={image} alt={"Environments"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonProjectReleaseTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="ConfigAsCodeDeploying">Deploying releases on version-controlled projects</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
