import React, { forwardRef, useImperativeHandle, useState } from "react";
import type { TemplateValuesSectionComponent, TemplateValuesSectionProps } from "~/components/Actions/helmChartUpgrade/HelmTemplateValuesDrawer";
import type { InlineYamlTemplateValuesSource } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import { CodeEditor, TextFormat } from "~/components/CodeEditor/CodeEditor";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { ExpandableFormSection, Note, Summary } from "~/components/form/index";
import StringHelper from "~/utils/StringHelper/index";
export const InlineYamlTemplateValuesSection = forwardRef<TemplateValuesSectionComponent, TemplateValuesSectionProps<InlineYamlTemplateValuesSource>>((props: TemplateValuesSectionProps<InlineYamlTemplateValuesSource>, ref) => {
    const [yaml, setYaml] = useState<string>(props.editingTemplateValuesSource?.Value || "");
    const getSummary = () => {
        if (yaml) {
            return Summary.summary("An inline YAML values file has been provided");
        }
        return Summary.placeholder("No inline YAML values file has been provided");
    };
    useImperativeHandle(ref, () => ({
        validateAndSave: () => {
            if (StringHelper.isNullOrWhiteSpace(yaml)) {
                return "Inline YAML is required.";
            }
            return {
                Type: "InlineYaml",
                Value: yaml,
            };
        },
    }));
    return (<ExpandableFormSection summary={getSummary()} title={"Inline YAML"} errorKey={"Octopus.Action.Helm.YamlValues"} isExpandedByDefault={true}>
            <CodeEditor allowFullScreen value={yaml} language={TextFormat.YAML} onChange={(x) => setYaml(x)} autoComplete={props.yamlAutocompleteResults} showToolbar={true} showCopyButton={true} showInsertVariableButton={true} localNames={props.localNames}/>
            <Note>
                Enter the YAML that will be provided as a values file. This field supports the <ExternalLink href="VariableSubstitutionSyntaxExtended">extended template syntax</ExternalLink>.
            </Note>
        </ExpandableFormSection>);
});
