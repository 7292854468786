import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import Note from "~/primitiveComponents/form/Note/Note";
import type { CategoryDefinition } from "./endpointRegistry";
const category: CategoryDefinition = {
    category: "Kubernetes",
    title: (<React.Fragment>
            Let's connect to a <strong>Kubernetes</strong> cluster.
        </React.Fragment>),
    help: (<Note>
            Learn more about connecting to <ExternalLink href="k8sTargetComparison">Kubernetes clusters</ExternalLink>
        </Note>),
    displayOrder: 10,
};
export default category;
