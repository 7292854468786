/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PhaseResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import SortableList from "~/primitiveComponents/dataDisplay/SortableList";
interface SortPhasesProps {
    phases: PhaseResource[];
    onPhasesSorted(sortedPhases: PhaseResource[]): void;
}
interface SortPhasesState {
    sortedPhases: PhaseResource[];
    errors: Errors;
    busy: Promise<void>;
}
export default class SortPhases extends React.Component<SortPhasesProps, SortPhasesState> {
    constructor(props: SortPhasesProps) {
        super(props);
        this.state = {
            sortedPhases: props.phases,
            errors: null!,
            busy: null!,
        };
    }
    render() {
        return (<OkDialogLayout title="Reorder Phases" busy={this.state.busy} errors={this.state.errors} onOkClick={() => this.props.onPhasesSorted(this.state.sortedPhases)}>
                <SortableList items={this.state.sortedPhases} onOrderChanged={(orderedItems: PhaseResource[]) => this.setState({ sortedPhases: orderedItems })}/>
            </OkDialogLayout>);
    }
    static displayName = "SortPhases";
}
