import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { client } from "~/clientInstance";
import ErrorPanel from "~/components/ErrorPanel/index";
import { clearUnhandledErrors, currentUnhandledErrorSelector } from "~/components/UnhandledError/reducers/index";
export function UnhandledErrorPanel() {
    const { unhandledError, clearErrors } = useUnhandledErrors();
    const serverInfo = client.tryGetServerInformation();
    const version = serverInfo ? serverInfo.version : undefined;
    return <>{unhandledError && <ErrorPanel message={`An unexpected error occurred in Octopus v${version}: ${unhandledError.message}`} errors={unhandledError.errors} fullWidth={true} canClose={true} onErrorClose={clearErrors}/>}</>;
}
function useUnhandledErrors() {
    const unhandledError = useSelector(currentUnhandledErrorSelector);
    const dispatch = useDispatch();
    const clearErrors = React.useCallback(() => {
        dispatch(clearUnhandledErrors());
    }, [dispatch]);
    return { unhandledError, clearErrors };
}
