import type { SshKeyPairAccountResource, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ExpandableFormSection, Summary, FormSectionHeading, ErrorPanel } from "~/components/form/";
import type { SummaryNode } from "~/components/form/";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import SensitiveFileUpload from "~/components/form/SensitiveFileUpload/SensitiveFileUpload";
import { required } from "~/components/form/Validators";
import Text from "~/primitiveComponents/form/Text/Text";
import type { AccountEditBaseProps, AccountEditModel } from "./AccountEditBase";
import AccountEditBaseDrawer from "./AccountEditBaseDrawer";
import AccountEditBasePaper from "./AccountEditBasePaper";
interface SshKeyPairModel extends AccountEditModel {
    username: string;
    passphrase: SensitiveValue;
    privateKeyFile: SensitiveValue;
}
export default function SshKeyPairAccountEdit(props: AccountEditBaseProps<SshKeyPairAccountResource>) {
    switch (props.type) {
        case "paper":
            return <SshKeyPairAccountEditPaper {...props}/>;
        case "drawer":
            return <SshKeyPairAccountEditDrawer {...props}/>;
        default:
            return <ErrorPanel message="Invalid layout"/>;
    }
}
class SshKeyPairAccountEditPaper extends AccountEditBasePaper<SshKeyPairAccountResource, SshKeyPairModel> {
    getPartialModel(account?: SshKeyPairAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (username) => this.setModelState({ username }), (privateKeyFile) => this.setModelState({ privateKeyFile }), (passphrase) => this.setModelState({ passphrase }), this.getFieldError);
    }
}
class SshKeyPairAccountEditDrawer extends AccountEditBaseDrawer<SshKeyPairAccountResource, SshKeyPairModel> {
    getPartialModel(account?: SshKeyPairAccountResource) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return [];
    }
    getTestDialog() {
        return null;
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, (username) => this.setModelState({ username }), (privateKeyFile) => this.setModelState({ privateKeyFile }), (passphrase) => this.setModelState({ passphrase }), this.getFieldError);
    }
}
function getPartialResource(modelState: SshKeyPairModel): Partial<SshKeyPairAccountResource> & {
    AccountType: AccountType;
} {
    return {
        AccountType: AccountType.SshKeyPair,
        Username: modelState.username,
        PrivateKeyPassphrase: modelState.passphrase,
        PrivateKeyFile: modelState.privateKeyFile,
    };
}
function getPartialModel(account?: SshKeyPairAccountResource): Partial<SshKeyPairModel> {
    if (!account) {
        return {
            username: "",
            passphrase: {
                HasValue: false,
            },
            privateKeyFile: {
                HasValue: false,
            },
        };
    }
    return {
        username: account.Username,
        passphrase: account.PrivateKeyPassphrase,
        privateKeyFile: account.PrivateKeyFile,
    };
}
function usernameSummary(modelState: SshKeyPairModel): SummaryNode {
    return modelState.username ? Summary.summary(modelState.username) : Summary.placeholder("No username provided");
}
function passphraseSummary(modelState: SshKeyPairModel): SummaryNode {
    return modelState.passphrase && modelState.passphrase.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No passphrase provided");
}
function privateKeySummary(modelState: SshKeyPairModel): SummaryNode {
    if (modelState.privateKeyFile && modelState.privateKeyFile.HasValue) {
        return Summary.summary("A private key has been provided");
    }
    return Summary.placeholder("No private key provided");
}
function customExpandableFormSections(modelState: SshKeyPairModel, onUsernameChange: (newValue: string) => void, onPrivateKeyFileChange: (newValue: SensitiveValue) => void, onPassphraseChange: (newValue: SensitiveValue) => void, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    baseElements.push(<FormSectionHeading title="Ssh Key Pair Settings" key={"header"}/>, <ExpandableFormSection key="username" errorKey="username" title="Username" focusOnExpandAll summary={usernameSummary(modelState)} help="The username to use when authenticating against the remote host." containerKey="AccountEdit">
            <Text value={modelState.username} onChange={onUsernameChange} label="Username" validate={required("Please enter an username")} error={getFieldError("username")}/>
        </ExpandableFormSection>, <ExpandableFormSection key="privatekey" errorKey="privateKeyFile" title="Private Key File" summary={privateKeySummary(modelState)} help="Upload the private key file portion of the key pair." containerKey="AccountEdit">
            <SensitiveFileUpload label="Private key file" value={modelState.privateKeyFile} onChange={onPrivateKeyFileChange} error={getFieldError("privateKeyFile")}/>
        </ExpandableFormSection>, <ExpandableFormSection key="passphrase" errorKey="passphrase" title="Passphrase" summary={passphraseSummary(modelState)} help="The passphrase for the private key, if required." containerKey="AccountEdit">
            <Sensitive value={modelState.passphrase} onChange={onPassphraseChange} label="Passphrase"/>
        </ExpandableFormSection>);
    return baseElements;
}
