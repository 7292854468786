/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
const ComponentRow = (props: any) => {
    return <div className={cn(styles.componentRow, props.className)}>{props.children}</div>;
};
(ComponentRow as any).displayName = "ComponentRow";
export default ComponentRow;
