import React from "react";
import type { StoredAction } from "../types";
import { ChangeStepPackageVersionCallout } from "./ChangeStepPackageVersionCallout";
interface StepPackageVersionBannerProps {
    action: StoredAction;
    cleanAction: StoredAction | null;
    onUpgrade: (targetVersion: string) => void;
    onCancel: () => void;
    error?: string;
}
export function StepPackageVersionBanner({ action, cleanAction, error, onUpgrade, onCancel }: StepPackageVersionBannerProps) {
    const currentMajorVersion = getStepPackageMajorVersion(action);
    const inProgress = cleanAction !== null && cleanAction !== undefined && action.StepPackageVersion !== cleanAction.StepPackageVersion;
    const releaseNotesHref = action.plugin.releaseNotesUrl;
    if ((!isStepPackageUpdateAvailable(action) && !inProgress) || currentMajorVersion === undefined)
        return null;
    const highestAvailableMajorVersion = getStepPackageHighestAvailableMajorVersion(action);
    return (<ChangeStepPackageVersionCallout highestAvailableMajorVersion={highestAvailableMajorVersion} currentMajorVersion={currentMajorVersion} previousMajorVersion={getStepPackageMajorVersion(cleanAction)} releaseNotesHref={releaseNotesHref} onUpgradeClick={() => onUpgrade(highestAvailableMajorVersion.toString())} onCancelClick={onCancel} inProgress={inProgress} error={error}/>);
}
function isStepPackageUpdateAvailable(action: StoredAction): boolean {
    const currentMajorVersion = getStepPackageMajorVersion(action);
    if (action.AvailableStepPackageVersions === undefined || currentMajorVersion === undefined)
        return false;
    const highestAvailableMajorVersion = getStepPackageHighestAvailableMajorVersion(action);
    return highestAvailableMajorVersion > currentMajorVersion;
}
function getStepPackageMajorVersion(action: StoredAction | null) {
    if (!action) {
        return;
    }
    return action.StepPackageVersion == undefined ? undefined : getMajorVersionNumber(action.StepPackageVersion);
}
function getStepPackageAvailableMajorVersions(action: StoredAction) {
    const availableMajorVersions = action.AvailableStepPackageVersions?.map((v) => getMajorVersionNumber(v)) || [];
    return availableMajorVersions;
}
function getStepPackageHighestAvailableMajorVersion(action: StoredAction) {
    const availableMajorVersions = getStepPackageAvailableMajorVersions(action);
    return Math.max(...availableMajorVersions);
}
export function getMajorVersionNumber(version: string): number {
    return parseInt(version.split(".")[0]);
}
