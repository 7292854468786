import { FeedType, PackageAcquisitionLocation } from "@octopusdeploy/octopus-server-client";
import React, { forwardRef, useCallback, useImperativeHandle } from "react";
import type { DeepPartial } from "redux";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import type { TemplateValuesSectionComponent, TemplateValuesSectionProps } from "~/components/Actions/helmChartUpgrade/HelmTemplateValuesDrawer";
import type { PackagedHelmValuesReference } from "~/components/Actions/helmChartUpgrade/PackagedHelmValuesDialog";
import type { PackageTemplateValuesSource } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import PackageSelector from "~/components/PackageSelector/PackageSelector";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { ExpandableFormSection, Note, Summary } from "~/components/form/index";
const DefaultYamlFileLocation = "values.yaml";
export const PackageTemplateValuesSection = forwardRef<TemplateValuesSectionComponent, TemplateValuesSectionProps<PackageTemplateValuesSource>>((props: TemplateValuesSectionProps<PackageTemplateValuesSource>, ref) => {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const [packageReference, setPackageReference] = React.useState<PackagedHelmValuesReference>(props.editingPackageReference || {
        Id: null!,
        Name: null!,
        FeedId: null!,
        PackageId: null!,
        AcquisitionLocation: PackageAcquisitionLocation.ExecutionTarget,
        Properties: { ValuesFilePath: "", PerformVariableReplace: "False", Extract: "true" },
    });
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
    const [valuesFilePaths, setValuesFilePaths] = React.useState<string>(props.editingTemplateValuesSource?.ValuesFilePaths || "");
    const feeds = useFeedsFromContext();
    const refreshFeedsCtx = useRefreshFeedsFromContext();
    const getSummary = () => {
        if (packageReference.PackageId) {
            return Summary.summary("Includes yaml files from package");
        }
        return Summary.placeholder("No yaml files included from package");
    };
    useImperativeHandle(ref, () => ({
        validateAndSave: () => {
            if (!packageReference.PackageId) {
                return "Package ID is required";
            }
            if (!packageReference.FeedId) {
                return "An external feed must be selected";
            }
            if (!valuesFilePaths) {
                return "A values file must be defined";
            }
            return {
                valuesSource: {
                    Type: "Package",
                    PackageId: packageReference.PackageId,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    PackageName: packageReference.Name!,
                    ValuesFilePaths: valuesFilePaths,
                },
                packageReference,
            };
        },
    }));
    const updatePackageReference = (updatePackageReference: DeepPartial<PackagedHelmValuesReference>) => setPackageReference((prev) => ({
        ...prev,
        ...updatePackageReference,
        Properties: {
            ...prev.Properties,
            ...updatePackageReference.Properties,
        },
    }));
    const refreshFeeds = useCallback(async () => {
        await refreshFeedsCtx();
    }, [refreshFeedsCtx]);
    return (<ExpandableFormSection summary={getSummary()} help={<span>Files sourced from package</span>} title={"Files in a package"} errorKey={"PackageTemplateValuesSection"} isExpandedByDefault={true}>
            <PackageSelector packageId={packageReference.PackageId} feedId={packageReference.FeedId} onPackageIdChange={(id) => updatePackageReference({ PackageId: id })} onFeedIdChange={(id) => updatePackageReference({ FeedId: id })} localNames={props.localNames} projectId={props.projectId} feeds={feeds} feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.GitHub, FeedType.Maven, FeedType.ArtifactoryGeneric]} refreshFeeds={refreshFeeds}/>
            <div style={{ paddingTop: "1rem" }}>
                <VariableLookupText localNames={props.localNames} value={valuesFilePaths} onChange={(path) => setValuesFilePaths(path)} placeholder={DefaultYamlFileLocation} label="Relative path to values files"/>
                <Note>
                    A newline-separated list of file names, relative to the package root to be included as additional <code>--values</code> files. Variable replacement will be run on these files before they are used. Extended template and wildcard
                    syntax is supported. E.g., <em>values.{`#{Octopus.Environment.Name}`}.yaml</em>, <em>**\specific-folder\*.yaml</em>.
                </Note>
            </div>
        </ExpandableFormSection>);
});
