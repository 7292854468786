/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButtonType, Switch } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, FeatureToggleResource, TenantResource, ToggleResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useState } from "react";
import EditToggleDialog from "~/areas/projects/components/FeatureToggles/EditToggleDialog";
import ToggleScope from "~/areas/projects/components/FeatureToggles/ToggleScope";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import DialogOpener from "~/components/Dialog/DialogOpener";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import { Form } from "~/components/FormPaperLayout/Form";
import Markdown from "~/components/Markdown/Markdown";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { RemoveItemsList } from "~/components/RemoveItemsList/RemoveItemsList";
import { ExpandableFormSection, FormSection, MarkdownEditor, required, Summary } from "~/components/form/index";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import SlugEditor from "~/primitiveComponents/form/Slugs/SlugEditor";
import Text from "~/primitiveComponents/form/Text/Text";
interface FeatureTogglePageProps {
    spaceId: string;
    featureToggleSlug: string;
}
interface FeatureTogglePageInternalProps extends FeatureTogglePageProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
}
interface EditState {
    toggleEdit: ToggleResource;
    toggleEditIdx: number;
}
class ToggleList extends RemoveItemsList<ToggleResource> {
}
export class FeatureTogglePage extends DataBaseComponent<FeatureTogglePageProps> {
    constructor(props: FeatureTogglePageProps) {
        super(props);
        this.state = {};
    }
    render() {
        return <FeatureTogglePageInternal spaceId={this.props.spaceId} featureToggleSlug={this.props.featureToggleSlug} doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "FeatureTogglePage";
}
function FeatureTogglePageInternal({ spaceId, featureToggleSlug, doBusyTask, busy, errors }: FeatureTogglePageInternalProps) {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const [lookupData, setLookupData] = useState<{
        environments: EnvironmentResource[];
        tenants: TenantResource[];
    } | null>(null);
    const [model, setModel] = useState<FeatureToggleResource | undefined>(undefined);
    const [cleanModel, setCleanModel] = useState<FeatureToggleResource | undefined>(undefined);
    const { navigate } = useSpaceAwareNavigation();
    useDoBusyTaskEffect(doBusyTask, async () => {
        const environmentsPromise = repository.Projects.getAvailableEnvironmentsForProject(project.Id);
        const tenantsPromise = repository.Tenants.all({ projectId: project.Id });
        const modelPromise = repository.FeatureToggles.get(project, featureToggleSlug);
        const environmentsResponse = await environmentsPromise;
        const model = await modelPromise;
        setLookupData({
            environments: environmentsResponse.Environments,
            tenants: await tenantsPromise,
        });
        setModel(model);
        setCleanModel(cloneDeep(model));
    }, []);
    async function deleteFeature(): Promise<boolean> {
        if (!model)
            return false;
        await repository.FeatureToggles.delete(project, model.Id);
        navigate(links.featureTogglesPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }));
        return true;
    }
    const legacyOverflowActions: MenuItem[] = [OverflowMenuItems.deleteItemDefault("feature toggle", deleteFeature)];
    const overflowMenu = OverflowMenuConverterVNext.convertAll(legacyOverflowActions);
    async function handleSaveClick() {
        if (!model)
            return;
        await doBusyTask(async () => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const existingModel = model as FeatureToggleResource;
            const updateResponse = await repository.FeatureToggles.modify(project, {
                ...existingModel,
                Toggles: existingModel.Toggles.map((t) => ({
                    ...t,
                    //TenantIds: t.TenantIds,
                })),
            });
            setModel(updateResponse);
            setCleanModel(cloneDeep(updateResponse));
        });
    }
    return (<Form onSaveClick={handleSaveClick} model={model} cleanModel={cleanModel}>
            {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({})} breadcrumbsItems={[{ label: "Feature Toggles", pageUrl: links.featureTogglesPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }]} title={model?.Name ?? ""} busy={busy} errors={errors} overflowActions={overflowMenu.menuItems}>
                    <FormContent>
                        {overflowMenu.dialogs}
                        {lookupData && model && <EditFeatureToggle environments={lookupData.environments} tenants={lookupData.tenants} model={model} setModel={setModel}/>}
                    </FormContent>
                </PaperLayoutVNext>)}
        </Form>);
}
interface EditFeatureToggleProps {
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    model: FeatureToggleResource;
    setModel: React.Dispatch<React.SetStateAction<FeatureToggleResource | undefined>>;
}
function EditFeatureToggle({ environments, tenants, model, setModel }: EditFeatureToggleProps) {
    const [editState, setEditState] = useState<EditState | null>(null);
    function nameSummary() {
        if (!model.Name)
            return Summary.placeholder("Please enter a name for the feature");
        return Summary.summary(<NameSummaryWithSlug name={model.Name} slug={model.Slug}/>);
    }
    function noteSummary() {
        if (!model.Description)
            return Summary.placeholder("Enter markdown notes");
        return Summary.summary(<Markdown markup={model.Description}/>);
    }
    function toggleRow(toggle: ToggleResource, idx: number) {
        const environment = environments.find((e) => e.Id === toggle.DeploymentEnvironmentId);
        if (!environment) {
            throw new Error("Could not find environment");
        }
        const rowTenants = tenants.filter((t) => toggle.TenantIds.indexOf(t.Id) !== -1 || toggle.ExcludedTenantIds.indexOf(t.Id) !== -1);
        const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => e.stopPropagation();
        return (<div>
                <span onClick={handleClick}>
                    <Switch value={toggle.IsEnabled} onChange={(value) => setToggle(value, idx)}/>
                </span>
                <ToggleScope toggle={toggle} environmentName={environment.Name} tenants={rowTenants}/>
            </div>);
    }
    function setToggle(value: boolean, idx: number) {
        const toggles = [...model.Toggles];
        toggles[idx] = { ...toggles[idx], IsEnabled: value };
        setModel({ ...model, Toggles: toggles });
    }
    async function addToggle(toggle: ToggleResource) {
        setModel({ ...model, Toggles: [...model.Toggles, toggle] });
        return true;
    }
    async function saveToggle(toggle: ToggleResource) {
        const toggles = [...model.Toggles];
        if (editState && editState.toggleEditIdx !== null) {
            toggles[editState.toggleEditIdx] = toggle;
        }
        else {
            toggles.push(toggle);
        }
        setModel({ ...model, Toggles: toggles });
        return true;
    }
    async function removeToggle(toggle: ToggleResource) {
        setModel({
            ...model,
            Toggles: model.Toggles.filter((t) => t !== toggle),
        });
    }
    return (<div>
            {editState && (<DialogOpener open={true} onClose={() => setEditState(null)} wideDialog={true}>
                    <EditToggleDialog title="Edit" onSave={saveToggle} environments={environments} tenants={tenants} toggle={editState.toggleEdit}/>
                </DialogOpener>)}

            <ExpandableFormSection errorKey="Name" title="Name" summary={nameSummary()}>
                <Text value={model.Name} label={"Feature Name"} validate={required("Please provide a name for the feature")} onChange={(value) => {
            setModel({ ...model, Name: value });
        }}/>
                <SlugEditor label="Slug" value={model.Slug} originalSlug={model.Slug} readOnly={true} onChange={(x) => { }}/>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Description" title="Notes" summary={noteSummary()}>
                <MarkdownEditor label="Notes" value={model.Description} onChange={(value) => {
            setModel({ ...model, Description: value });
        }}/>
            </ExpandableFormSection>
            <FormSection title="Toggles" includeBorder={true}>
                <OpenDialogButton label="Add Toggle" type={ActionButtonType.Secondary} dialogWidth={"1000px"}>
                    <EditToggleDialog title="Add" onSave={addToggle} environments={environments} tenants={tenants}/>
                </OpenDialogButton>
                <ToggleList data={model.Toggles} onRow={(toggle, idx) => toggleRow(toggle, idx)} onRowTouch={(t) => setEditState({ toggleEdit: t, toggleEditIdx: model.Toggles.indexOf(t) })} onRemoveRow={removeToggle}/>
            </FormSection>
        </div>);
}
