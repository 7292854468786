/**
 * DNS labels can only be lowercase alphanumeric (with hyphens), and can't start/end with hyphens.
 * See https://kubernetes.io/docs/concepts/overview/working-with-objects/names/#dns-label-names
 * Note that Helm has slightly different requirements in certain circumstances. For example, release names
 * can only be a max of 53 characters.
 * @param value
 * @param maxLength
 */
export function getAsValidDnsLabel(value: string, maxLength: number = 63) {
    return value
        .replace(/[^0-9a-z-]/gi, "") // Only alphanumeric and hyphens
        .replace(/^-+|-+$/g, "") // No hyphens at start or end.
        .toLowerCase()
        .substring(0, maxLength);
}
// Release names have a max length of 53, see https://helm.sh/docs/chart_template_guide/getting_started/#adding-a-simple-template-call
export function getValidReleaseName(agentName: string) {
    return getAsValidDnsLabel(agentName, 53);
}
export function getValidAgentName(agentName: string) {
    return escapeAllDoubleQuotes(agentName).trim();
}
export function escapeAllDoubleQuotes(value: string) {
    return value.replace(/"/g, "\\\"");
}
export function generateAgentAsTargetNamespace(agentName: string): string {
    const validAgentName = getValidAgentName(agentName);
    return getAsValidDnsLabel(`octopus-agent-${validAgentName}`);
}
export function generateAgentAsWorkerNamespace(agentName: string): string {
    const validAgentName = getValidAgentName(agentName);
    return getAsValidDnsLabel(`octopus-worker-${validAgentName}`);
}
export function generateReleaseName(agentName: string): string {
    return getValidReleaseName(agentName);
}
