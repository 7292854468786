import { Callout } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { PermissionCheck } from "~/components/PermissionCheck";
import type { ScriptModule } from "../ScriptModules";
import { ScriptModulesWithActions } from "../ScriptModules";
interface RunbookProcessOverviewSidebarProps {
    spaceId: string;
    projectId: string;
    includedScriptModules: ScriptModule[];
    onDataChanged: () => void;
}
export const RunbookProcessOverviewSidebar: React.FC<RunbookProcessOverviewSidebarProps> = (props) => {
    return (<PermissionCheck permission={Permission.LibraryVariableSetView} wildcard={true} alternate={<Callout type={"information"} title={"Permission required"}>
                    The {Permission.LibraryVariableSetView} permission is required to view any included script modules
                </Callout>}>
            {props.includedScriptModules && (<div>
                    <ScriptModulesWithActions spaceId={props.spaceId} projectId={props.projectId} includedScriptModules={props.includedScriptModules} onDataChanged={props.onDataChanged}/>
                </div>)}
        </PermissionCheck>);
};
RunbookProcessOverviewSidebar.displayName = "RunbookProcessOverviewSidebar"
