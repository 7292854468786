import type { TomcatDeployProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import pluginRegistry from "~/components/Actions/pluginRegistry";
import TomcatFeatureTemplate from "~/components/Actions/tomcat/tomcatFeatureTemplate";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
class DeployToTomcatFeature extends BaseComponent<ActionEditProps<TomcatDeployProperties>, never> {
    render() {
        return (<TomcatFeatureTemplate projectId={this.props.projectId} gitRef={this.props.gitRef} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} getFieldError={this.props.getFieldError} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault}/>);
    }
    static displayName = "DeployToTomcatFeature";
}
pluginRegistry.registerFeature({
    featureName: "Octopus.Features.TomcatDeployManager",
    title: "Tomcat deployment feature",
    description: "Deploys packages to Tomcat via the manager API",
    edit: DeployToTomcatFeature,
    priority: 1,
});
