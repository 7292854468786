import type { PropertyValueResource } from "@octopusdeploy/octopus-server-client";
function convertPropertyValueResourceToString(value?: PropertyValueResource): string {
    if (typeof value === "string") {
        return value;
    }
    if (!value || !value.HasValue) {
        return "";
    }
    // Always return masked string for passwords. We don't display it so it doesn't matter what the value is
    // but it can't be undefined because that has special meaning (missing value)
    return "*****";
}
export default convertPropertyValueResourceToString;
