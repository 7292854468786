import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonProjectRunbookSnapshotOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="RunbookPublishing">Runbook Snapshots</ExternalLink> are a snapshot of your runbook process, configuration variables, and software packages. A snapshot can be run to any available environment.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
