import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import React, { useMemo } from "react";
import { InsightsReportLoadingLayout } from "~/areas/insights/components/InsightsReportLayout/InsightsReportLayout";
import { repository } from "~/clientInstance";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import type { InsightsReportPageContext } from "~/routing/pageRegistrations/InsightsPageRegistration";
import type { StandardPageContext } from "~/routing/pageRegistrations/StandardPageContext";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface InsightsReportLoaderProps {
    pageContext: StandardPageContext;
    reportId: string;
    children: (insightsReportPageContext: InsightsReportPageContext) => ReactNode;
}
interface State {
    report: InsightsReportResource | null;
}
export class InsightsReportLoader extends DataBaseComponent<InsightsReportLoaderProps, State> {
    constructor(props: InsightsReportLoaderProps) {
        super(props);
        this.state = {
            report: null,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    refreshReport = async () => {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.for("RefreshByLayout") });
    };
    private async load() {
        const report = await repository.InsightsReports.get(this.props.reportId);
        this.setState({ report });
    }
    render() {
        return this.state.report ? (<InsightsReportLoaded report={this.state.report} refreshReport={this.refreshReport} standardPageContext={this.props.pageContext}>
                {(insightsReportPageContext) => this.props.children(insightsReportPageContext)}
            </InsightsReportLoaded>) : (<InsightsReportLoadingLayout busy={Boolean(this.state.busy)} errors={this.errors}/>);
    }
    static displayName = "InsightsReportLoader";
}
interface InsightsReportedLoaded {
    standardPageContext: StandardPageContext;
    report: InsightsReportResource;
    refreshReport: () => Promise<void>;
    children: (insightsReportPageContext: InsightsReportPageContext) => ReactNode;
}
function InsightsReportLoaded({ report, refreshReport, standardPageContext, children }: InsightsReportedLoaded) {
    const insightsReportPageContext: InsightsReportPageContext = useMemo(() => ({
        ...standardPageContext,
        report,
        refreshReport,
    }), [refreshReport, report, standardPageContext]);
    return <>{children(insightsReportPageContext)}</>;
}
