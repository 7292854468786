import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryFeedOverview } from "../../CommonComponents/CommonLibraryFeedViews";
export const LibraryFeedTestRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.feed.test,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryFeedOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
