/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { TeamResource, TeamMembership, UserResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import InfoDialogLayout from "~/components/DialogLayout/InfoDialogLayout";
import { SimplePagingList } from "~/components/PagingList";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import { ProjectedTeamDescription } from "../ProjectedTeamsList/ProjectedTeamsList";
import projectedTeamStyles from "../ProjectedTeamsList/ProjectedTeamsList.module.less";
import { MemberRow } from "./MemberRow";
interface UserMember {
    user: UserResource;
    membership: TeamMembership;
}
interface AllMembersProps {
    team: TeamResource;
}
interface AllMembersState extends DataBaseComponentState {
    userMembers: UserMember[];
}
class AllMembers extends DataBaseComponent<AllMembersProps, AllMembersState> {
    constructor(props: AllMembersProps) {
        super(props);
        this.state = { userMembers: [] };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const memberships = await repository.TeamMembership.previewTeam(this.props.team);
            const users = await repository.Users.all({ ids: memberships.map((m) => m.UserId) });
            const userMembers = memberships.map((membership) => ({ user: users.find((u) => u.Id === membership.UserId)!, membership }));
            this.setState({
                userMembers,
            });
        });
    }
    render() {
        return (<InfoDialogLayout title="All Members" errors={this.errors} busy={this.state.busy}>
                <SimplePagingList items={this.state.userMembers} onRowKey={(member) => `${member.membership.TeamId}-${member.user.Id}`} onRow={(userMember) => (<ListTitle>
                            <MemberRow member={userMember.user}/>
                            <div className={projectedTeamStyles.externalGroups}>
                                <ProjectedTeamDescription isDirectAssignment={userMember.membership.IsDirectlyAssigned} externalGroups={userMember.membership.ExternalSecurityGroups}/>
                            </div>
                        </ListTitle>)} onFilter={(filter, userMember) => !filter || filter === "" || !userMember || userMember.user.DisplayName.toLowerCase().includes(filter.toLowerCase())} filterHintText="Filter by name..."/>
            </InfoDialogLayout>);
    }
    static displayName = "AllMembers";
}
export default AllMembers;
