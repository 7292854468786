import * as React from "react";
import { CommonLibraryBuildInformationRepositoryOverview } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonLibraryBuildInformationRepositoryViews";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
export const LibraryBuildInformationRepositoryPackageRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.buildInformationRepository.package,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryBuildInformationRepositoryOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
