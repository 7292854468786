import * as React from "react";
import { CommonProjectsTopics, CommonProjectsView } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonProjectsViews";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
export const ProjectsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.projects.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectsView />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => <CommonProjectsTopics />,
};
