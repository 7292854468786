/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as _ from "lodash";
import * as React from "react";
import Select, { BoundSelect } from "../../../primitiveComponents/form/Select/Select";
import Text from "../../../primitiveComponents/form/Text/Text";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ExtendedKeyValueEditList } from "../../EditList/ExtendedKeyValueEditList";
import { CardFill, default as ExpandableFormSection } from "../../form/Sections/ExpandableFormSection";
import Summary from "../../form/Sections/Summary";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { ContainerPackageDetails, LifecycleHandler } from "./kubernetesDeployContainersAction";
interface LifecycleHandlerProps {
    handler: LifecycleHandler;
    hookType: "PostStart" | "PreStop";
    onChange: (handler: LifecycleHandler) => void;
}
enum LifeCycleHandlerType {
    None,
    Exec = "Exec",
    HttpGet = "HttpGet",
    TcpSocket = "TcpSocket"
}
function getHandlerType(lifecycleHandler: LifecycleHandler): LifeCycleHandlerType {
    if (lifecycleHandler.Exec) {
        return LifeCycleHandlerType.Exec;
    }
    if (lifecycleHandler.HttpGet) {
        return LifeCycleHandlerType.HttpGet;
    }
    if (lifecycleHandler.TcpSocket) {
        return LifeCycleHandlerType.TcpSocket;
    }
    return LifeCycleHandlerType.None;
}
export class LifecycleHandlerFormSection extends BaseComponent<LifecycleHandlerProps, never> {
    static Validate = (data: ContainerPackageDetails, setError: (msg: string) => void): void => {
        if (!data.Lifecycle) {
            return;
        }
        ValidateHook("PostStart");
        ValidateHook("PreStop");
        function ValidateHook(hookType: "PostStart" | "PreStop") {
            const hook = data.Lifecycle[hookType];
            if (!hook) {
                return;
            }
            const handlerType = getHandlerType(hook);
            if (handlerType === LifeCycleHandlerType.Exec) {
                if (!hook.Exec || !hook.Exec.command || hook.Exec.command.join("\n") === "") {
                    setError("A command must be supplied for the `PostStart` lifecycle hook if the handler type is `Exec`.");
                }
            }
            else if (handlerType === LifeCycleHandlerType.HttpGet) {
                if (!hook.HttpGet || !hook.HttpGet.port) {
                    setError("A port must be supplied for the `PostStart` lifecycle hook if the handler type is `HttpGet`.");
                }
            }
            else if (handlerType === LifeCycleHandlerType.TcpSocket) {
                if (!hook.TcpSocket || !hook.TcpSocket.port) {
                    setError("A port must be supplied for the `PostStart` lifecycle hook if the handler type is `TcpSocket`.");
                }
            }
        }
    };
    constructor(props: LifecycleHandlerProps) {
        super(props);
    }
    onChangeType = (type: LifeCycleHandlerType) => {
        let newHandler: LifecycleHandler = {};
        switch (type) {
            case LifeCycleHandlerType.TcpSocket:
                newHandler = { TcpSocket: { port: null! } };
                break;
            case LifeCycleHandlerType.HttpGet:
                newHandler = { HttpGet: { port: null! } };
                break;
            case LifeCycleHandlerType.Exec:
                newHandler = { Exec: { command: [] } };
                break;
        }
        this.props.onChange(newHandler);
    };
    getHelp = () => {
        if (this.props.hookType === "PostStart") {
            return "This hook executes immediately after a container is created. " + "However, there is no guarantee that the hook will execute before the container ENTRYPOINT. " + "No parameters are passed to the handler.";
        }
        return "This hook is called immediately before a container is terminated. " + "It is blocking, meaning it is synchronous, so it must complete before the call to delete the container can be sent. " + "No parameters are passed to the handler.";
    };
    hookSummary = () => {
        const handler = this.props.handler || {};
        const handlerType = getHandlerType(handler);
        switch (handlerType) {
            case LifeCycleHandlerType.Exec:
                return Summary.summary("Command will be invoked");
            case LifeCycleHandlerType.HttpGet: {
                const httpHandler = handler.HttpGet;
                const txt = (httpHandler!.scheme || "http") + "://" + (httpHandler!.host || "<<Port IP>>") + "/" + (httpHandler!.path || "") + ":" + httpHandler!.port;
                return Summary.summary(<span>
                        Http GET to <strong>{txt}</strong>
                    </span>);
            }
            case LifeCycleHandlerType.TcpSocket: {
                const tcpHandler = handler.TcpSocket;
                const txt = (tcpHandler!.host || "<<Port IP>>") + ":" + tcpHandler!.host;
                return Summary.summary(<span>
                        TCP connection to <strong>{txt}</strong>
                    </span>);
            }
        }
        return Summary.default("No handler has been configured");
    };
    render() {
        const handler = this.props.handler || {};
        const handlerType = getHandlerType(handler);
        return (<ExpandableFormSection isExpandedByDefault={false} title={this.props.hookType} errorKey={"Lifecycle" + this.props.hookType} summary={this.hookSummary()} help={this.getHelp()} fillCardWidth={CardFill.FillAll} forceMobileBehaviour={true}>
                <Select value={handlerType as string} items={[
                { text: "(None)", value: LifeCycleHandlerType.None as string },
                { text: "Command", value: LifeCycleHandlerType.Exec as string },
                { text: "HttpGet", value: LifeCycleHandlerType.HttpGet as string },
                { text: "TCP Socket", value: LifeCycleHandlerType.TcpSocket as string },
            ]} label="Handler type" onChange={(a) => this.onChangeType(a as LifeCycleHandlerType)}/>
                {handlerType === LifeCycleHandlerType.Exec && (<div>
                        <Text label="Command" value={(_.get(handler, "Exec.command") || []).join("\n")} multiline={true} rows={5} rowsMax={5} onChange={(command) => this.props.onChange({ Exec: { ...handler.Exec, command: command.split("\n") } })}/>
                    </div>)}
                {handlerType === LifeCycleHandlerType.HttpGet && (<div>
                        <BoundSelect value={handler!.HttpGet!.scheme} onChange={(scheme) => this.props.onChange({ HttpGet: { ...handler.HttpGet!, scheme } })} label="Scheme" items={[
                    { value: "HTTP", text: "HTTP" },
                    { value: "HTTPS", text: "HTTPS" },
                ]} resetValue={"HTTP"}/>
                        <Text label="Host" placeholder={"pod IP"} value={handler.HttpGet!.host!} onChange={(host) => this.props.onChange({ HttpGet: { ...handler.HttpGet!, host } })}/>
                        <Text label="Path" value={handler.HttpGet!.path!} onChange={(path) => this.props.onChange({ HttpGet: { ...handler.HttpGet!, path } })}/>
                        <VariableLookupText label="Port" value={handler.HttpGet!.port} onChange={(port) => this.props.onChange({ HttpGet: { ...handler.HttpGet, port } })}/>
                        <p>
                            <strong>HTTP Header</strong>
                        </p>
                        <p>Add HTTP headers to be sent with the request.</p>
                        <ExtendedKeyValueEditList items={() => handler.HttpGet!.httpHeaders || []} name="HTTP Header" onChange={(headers) => this.props.onChange({ HttpGet: { ...handler.HttpGet!, httpHeaders: headers } })} onAdd={() => this.repositionDialog()} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} addToTop={true}/>
                    </div>)}

                {handlerType === LifeCycleHandlerType.TcpSocket && (<div>
                        <Text label="Host" placeholder={"pod IP"} value={handler!.TcpSocket!.host!} onChange={(host) => this.props.onChange({ TcpSocket: { ...handler.TcpSocket!, host } })}/>
                        <Text label="Port" value={handler.TcpSocket!.port} onChange={(port) => this.props.onChange({ TcpSocket: { ...handler.TcpSocket, port } })}/>
                    </div>)}
            </ExpandableFormSection>);
    }
    private repositionDialog() {
        window.dispatchEvent(new Event("resize"));
    }
    static displayName = "LifecycleHandlerFormSection";
}
