import React from "react";
import Card from "~/components/Card";
import { WelcomeDialogInstanceIcon } from "../WelcomeDialogInstanceIcon";
import type { WelcomeDialogCardProps } from "./WelcomeDialogCardProps";
import { cardStyles } from "./styles";
export function ViewSampleInstanceCard({ busy, onClick }: WelcomeDialogCardProps) {
    return (<Card link={!busy && (<a href="https://samples.octopus.app/app" target="_blank" rel="noreferrer" onClick={onClick} className={cardStyles.link}>
                        View samples instance
                    </a>)} logo={<div className={cardStyles.logo(!busy)}>
                    <WelcomeDialogInstanceIcon />
                </div>} header={<div className={cardStyles.header}>View sample instance</div>} content={"Explore Octopus samples as a reference for best-practices."} className={cardStyles.card} contentClassName={cardStyles.content} leftAlign={true}/>);
}
