import { ProcessType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProcessPaperLayout } from "~/areas/projects/components/Process/CustomPaperLayouts/ProcessPaperLayout";
import { useRunbookContext } from "~/areas/projects/components/Runbooks/RunbookContext";
import ProcessStepsLayoutLoader from "../Process/ProcessStepsLayoutLoader";
export function RunbookProcessStepsPage() {
    const context = useRunbookContext();
    if (!context.state.runbook) {
        return <ProcessPaperLayout processType={ProcessType.Runbook} busy={true}/>;
    }
    return <ProcessStepsLayoutLoader processId={context.state.runbook.RunbookProcessId} processType={ProcessType.Runbook}/>;
}
