import { ActionButton, ActionButtonType, Callout, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { ActionProperties, BlueprintResource } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import { isEmpty } from "lodash";
import * as React from "react";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { ExpandableFormSection, Select, Summary } from "~/components/form/index";
import Text from "~/primitiveComponents/form/Text/Text";
interface BlueprintSelectorProps {
    actionProperties: ActionProperties;
    doBusyTask: DoBusyTask;
    onBlueprintOptionSelected: (projectId: string, slug: string) => void;
    onBranchSelected: (value: string | undefined) => void;
    onCommitSelected: (value: string | undefined) => void;
    onBranchHeadCommitChanged: (value: string) => void;
    isNew: boolean;
    currentBlueprint: BlueprintResource | undefined;
    busy?: Promise<void>;
}
export function BlueprintSelector({ onBlueprintOptionSelected, onBranchSelected, onCommitSelected, onBranchHeadCommitChanged, actionProperties, doBusyTask, isNew, currentBlueprint, busy }: BlueprintSelectorProps) {
    const selectedBranch = convertPropertyValueResourceToString(actionProperties["Octopus.Action.Blueprint.Reference.Branch"]);
    const selectedProject = convertPropertyValueResourceToString(actionProperties["Octopus.Action.Blueprint.Reference.ProjectId"]);
    const selectedSlug = convertPropertyValueResourceToString(actionProperties["Octopus.Action.Blueprint.Reference.Slug"]);
    const selectedCommit = convertPropertyValueResourceToString(actionProperties["Octopus.Action.Blueprint.Reference.Commit"]);
    const selectedBlueprintOption: BlueprintIdWithoutGitRef | undefined = selectedProject && selectedSlug ? `${selectedProject}|${selectedSlug}` : undefined;
    const [blueprints, setBlueprints] = useState<BlueprintOption[]>([]);
    const [branches, setBranches] = useState<BranchOption[]>([]);
    const [updateMethod, setUpdateMethod] = useState<UpdateMethod>(selectedCommit ? UpdateMethod.UseCommit : UpdateMethod.FollowHead);
    const [branchHeadCommit, setBranchHeadCommit] = useState<string | undefined>(undefined);
    const isExpandedByDefault = isNew || !currentBlueprint || !busy;
    const onBranchChanged = (value: string | undefined) => {
        if (value) {
            onBranchSelected(value);
        }
    };
    const onUpdateMethodChanged = (value: UpdateMethod) => {
        setUpdateMethod(value);
        if (value === UpdateMethod.FollowHead) {
            onCommitSelected(undefined);
        }
        else {
            if (!selectedCommit) {
                onCommitSelected(branchHeadCommit);
            }
        }
    };
    const onUpdateToCurrentHeadClick = () => {
        if (branchHeadCommit) {
            onCommitSelected(branchHeadCommit);
        }
    };
    const onBlueprintOptionChanged = (value: string | undefined) => {
        if (value) {
            const { projectId, slug } = extractBlueprintIdWithoutGitRef(value);
            onBlueprintOptionSelected(projectId, slug);
            if (projectId !== selectedProject) {
                setUpdateMethod(UpdateMethod.FollowHead);
                onBranchSelected(undefined);
                onCommitSelected(undefined);
            }
        }
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        // Get branch HEAD commit when Blueprint or branch changes
        if (!selectedProject || !selectedBranch) {
            return;
        }
        const branchHead = await repository.Blueprints.getBranchHead(selectedProject, selectedBranch);
        setBranchHeadCommit(branchHead.Commit);
        onBranchHeadCommitChanged(branchHead.Commit);
    }, [selectedBranch, selectedProject]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        // Refresh branches when selected Blueprint repository changes
        if (!selectedProject) {
            return;
        }
        const project = await repository.Projects.get(selectedProject);
        const branches = await repository.Projects.getBranches(project);
        setBranches(branches.Items.map((b) => ({ value: b.CanonicalName, text: b.Name })));
    }, [selectedProject]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const options = await loadBlueprints();
        setBlueprints(options);
    }, []);
    const title = selectedBlueprintOption ? "Change Blueprint" : "Select Blueprint";
    const summary = selectedBlueprintOption ? Summary.placeholder("Change blueprint by selecting one from the list") : null;
    return (<ExpandableFormSection isExpandedByDefault={isExpandedByDefault} errorKey="selectedBlueprint" title={title} summary={summary}>
            <Select value={selectedBlueprintOption} allowClear={false} onChange={onBlueprintOptionChanged} items={blueprints} label="Select blueprint"/>
            {selectedBlueprintOption && (<>
                    <Select value={selectedBranch} allowClear={false} onChange={onBranchChanged} items={branches} label="Select branch"/>
                    {selectedBranch && (<RadioButtonGroup value={updateMethod} onChange={onUpdateMethodChanged}>
                            <RadioButton value={UpdateMethod.FollowHead} label={`Follow HEAD`} isDefault={true}/>
                            <RadioButton value={UpdateMethod.UseCommit} label={`Use commit`}/>
                        </RadioButtonGroup>)}
                    {updateMethod === UpdateMethod.UseCommit && (<>
                            <Text value={selectedCommit} label="Commit" onChange={noOp} disabled={true}/>
                            {branchHeadCommit !== selectedCommit && <ActionButton label={`Update to current HEAD: ${branchHeadCommit?.substring(0, 7)}`} type={ActionButtonType.Ternary} onClick={onUpdateToCurrentHeadClick}/>}
                        </>)}
                    <BlueprintNotFoundCallout blueprint={currentBlueprint} selectedBranch={selectedBranch} busy={busy}/>
                </>)}
        </ExpandableFormSection>);
}
enum UpdateMethod {
    FollowHead = "FollowHead",
    UseCommit = "UseCommit"
}
interface BranchOption {
    value: string;
    text: string;
}
type BlueprintIdWithoutGitRef = `${string}|${string}`;
function createBlueprintIdWithoutGitRef(projectId: string, slug: string): BlueprintIdWithoutGitRef {
    return `${projectId}|${slug}`;
}
function extractBlueprintIdWithoutGitRef(id: string): {
    projectId: string;
    slug: string;
} {
    const extracted = id.split(`|`);
    return {
        projectId: extracted[0],
        slug: extracted[1],
    };
}
interface BlueprintOption {
    value: BlueprintIdWithoutGitRef;
    text: string;
}
async function loadBlueprints(): Promise<BlueprintOption[]> {
    const blueprints = await repository.Blueprints.all();
    return blueprints.map((b) => ({ value: createBlueprintIdWithoutGitRef(b.ProjectId, b.Slug), text: b.Name }));
}
interface BlueprintNotFoundCalloutProps {
    blueprint: BlueprintResource | undefined;
    selectedBranch: string | undefined;
    busy?: Promise<void>;
}
function BlueprintNotFoundCallout({ blueprint, selectedBranch, busy }: BlueprintNotFoundCalloutProps) {
    const displayBlueprintNotFoundCallout = !isEmpty(selectedBranch) && !blueprint && !busy;
    return (<>
            {displayBlueprintNotFoundCallout && (<Callout type={"danger"} title={"Blueprint Not Found"}>
                    <div>The blueprint cannot be found on the selected branch or commit.</div>
                </Callout>)}
        </>);
}
