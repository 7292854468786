/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { DeepPartial, Reducer, AnyAction, StoreEnhancer, Middleware } from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import asyncActionsMiddleware from "redux-thunk";
const composeEnhancers: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const configureStore = <T extends {}>(preloadedState?: DeepPartial<T>) => {
    return <TAction extends AnyAction>(rootReducer: Reducer<T, TAction>) => {
        const middlewares: Middleware[] = [asyncActionsMiddleware];
        const enhancers = [applyMiddleware(...middlewares)];
        const composedEnhancers: StoreEnhancer = composeEnhancers(...enhancers);
        const store = createStore(rootReducer, preloadedState, composedEnhancers);
        return store;
    };
};
export default configureStore;
