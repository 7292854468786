import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonInfrastructureAccountOverview, CommonInfrastructureAccountTopics } from "../../CommonComponents/CommonInfrastructureAccountViews";
export const InfrastructureAccountRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.account,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureAccountOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureAccountTopics />;
    },
};
