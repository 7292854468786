import { Action } from "~/analytics/Analytics";
import type { TaskState } from "./ProjectStatus";
export const statusItemsForUndefinedProject = [
    {
        status: "project",
        label: "Project",
        eventName: "Click On Project Status Bar",
        eventAction: {
            action: Action.Add,
            resource: "Project",
        },
    },
    {
        status: "environment",
        label: "Environments",
        eventName: "Click On Project Status Bar",
        eventAction: {
            action: Action.Add,
            resource: "Environment",
        },
    },
    {
        status: "process",
        label: "Deployment process",
        eventName: "Click On Project Status Bar",
        eventAction: {
            action: Action.Add,
            resource: "Deployment Process",
        },
    },
    {
        status: "deployment",
        label: "Release and deploy",
        eventName: "Click On Project Status Bar",
        eventAction: {
            action: Action.Add,
            resource: "Release",
        },
    },
];
export const stateForUndefinedProject: TaskState[] = ["Current", "Pending", "Pending", "Pending"];
