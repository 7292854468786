import { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import type { Language, TextFormat } from "~/components/CodeEditor/CodeEditor";
export function createHelmCommand(language: ScriptingLanguage | Language | TextFormat) {
    const lineBreak = language === ScriptingLanguage.Bash ? "\\" : "`";
    return `helm upgrade --install --atomic ${lineBreak}
--repo https://raw.githubusercontent.com/kubernetes-csi/csi-driver-nfs/master/charts ${lineBreak}
--namespace kube-system ${lineBreak}
--version v4.6.0 ${lineBreak}
csi-driver-nfs ${lineBreak}
csi-driver-nfs`;
}
