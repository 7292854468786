/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ActionTemplateParameterResource, TenantVariableTemplateResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import isBound from "~/components/form/BoundField/isBound";
import type FormFieldProps from "~/components/form/FormFieldProps";
import type { VariableLookupProps } from "~/components/form/VariableLookup/VariableLookup";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import styles from "./style.module.less";
export type ResetValue<TValue> = (TValue | string) | (() => TValue | string);
export function isCallbackResetValue<TValue>(value: ResetValue<TValue | string>): value is () => TValue | string {
    return typeof value === "function";
}
export interface BoundFieldProps<TValue> {
    boundRows?: number;
    variableLookup?: VariableLookupProps;
    resetValue: ResetValue<TValue>;
    parameter?: ActionTemplateParameterResource | TenantVariableTemplateResource;
    hideBindButton?: boolean;
    label?: string | JSX.Element;
    isBound?: boolean; // if you want to track isBound externally
    onIsBoundChanged?(value: boolean): void;
    variableComponent?: JSX.Element;
}
interface BoundFieldState {
    isBound: boolean;
}
// This is a placeholder until we can implement BoundField to match the new designs
export const withBoundField = <TValue, TPropsExceptValue>(Comp: React.ComponentType<FormFieldProps<TValue> & TPropsExceptValue>) => {
    type TProps = Omit<TPropsExceptValue, "value"> & BoundFieldProps<TValue> & FormFieldProps<TValue | string>;
    return class extends React.Component<TProps, BoundFieldState> {
        public static defaultProps: Partial<BoundFieldProps<TValue>> = {
            hideBindButton: false,
        };
        // I really want to type the index signature below, but Typescript has broken support for enums in indexers.
        // I could use [index in ControlType] but that means all enum values need to be present, not just a subset.
        private parameterUnboundValueRanges: {
            [index: string]: (param: any, value: any) => boolean;
        } = {
            Checkbox: (param: any, value: any) => {
                if (!value) {
                    return false;
                }
                const lower = value.toLowerCase();
                return lower === "true" || lower === "false";
            },
            Select: (param: any, value: any) => {
                const selectOptions = param["Octopus.SelectOptions"];
                const allOptions = selectOptions ? param["Octopus.SelectOptions"].split("\n") : [];
                return allOptions.map((option: string) => option.split("|")[0]).indexOf(value) >= 0;
            },
            Sensitive: (param: any, value: any) => {
                return typeof value === "undefined" || typeof value === "object";
            },
        };
        constructor(props: TProps) {
            super(props);
            const shouldBind = this.shouldBind(props.value, props.parameter);
            this.state = { isBound: shouldBind };
            if (shouldBind && this.props.onIsBoundChanged) {
                this.props.onIsBoundChanged(true);
            }
        }
        render() {
            const component = this.getIsBound() ? (this.props.variableComponent ? (this.props.variableComponent) : this.props.variableLookup ? (<VariableLookupText syntax={this.props.variableLookup.syntax} localNames={this.props.variableLookup.localNames} label={this.props.label} value={this.props.value as string} onChange={this.props.onChange} multiline={true} rows={this.props.boundRows || 5} rowsMax={this.props.boundRows || 5}/>) : (<DebounceText label={this.props.label} value={this.props.value as string} onChange={this.props.onChange} multiline={true} rows={this.props.boundRows || 5} rowsMax={this.props.boundRows || 5}/>)) : (<Comp {...this.getWrappedComponentProps()}/>);
            return (<div className={styles.container}>
                    {component}
                    <div className={styles.buttons}>{this.binding()}</div>
                </div>);
        }
        private getWrappedComponentProps() {
            // `as any` because object rest not support in generics yet, tracked by https://github.com/Microsoft/TypeScript/issues/10727
            const { resetValue, parameter, hideBindButton, isBound: desIsBound, onIsBoundChanged, variableLookup, ...otherProps } = this.props as any;
            return otherProps as TPropsExceptValue & FormFieldProps<TValue>;
        }
        private getIsBound(): boolean {
            return this.props.isBound ?? this.state.isBound ?? false;
        }
        binding = () => {
            if (this.props.hideBindButton) {
                return null;
            }
            const onClick = this.getIsBound() ? this.unbind : this.bind;
            const text = this.getIsBound() ? /*tabIndex on svg is not supported in IE11, need to use focusable instead*/ "Unbind" : "Bind to a variable";
            const icon = this.getIsBound() ? "Unbind" : "Bind";
            return <IconButtonWithTooltip onClick={onClick} toolTipContent={text} icon={icon}/>;
        };
        // TODO: This needs the <Clear/> unbind icon set up properly so it switches from code icon to clear icon.
        // The clear icons is used on fields to remove or reset so this felt consistent.
        private shouldBind(value: string | null | undefined | TValue, parameter: ActionTemplateParameterResource | TenantVariableTemplateResource | undefined | null) {
            if (value && typeof value === "string") {
                if (isBound(value)) {
                    return true;
                }
                if (parameter) {
                    const displaySettings = parameter.DisplaySettings;
                    const controlType = displaySettings["Octopus.ControlType"];
                    if (controlType) {
                        const isInRange = this.parameterUnboundValueRanges[controlType];
                        if (isInRange && !isInRange(displaySettings, value)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }
        private boundText() {
            if (this.getIsBound()) {
                return "Remove custom bindings from this field";
            }
            return "Apply custom bindings to this field";
        }
        private bind = () => {
            const value = this.props.value || "";
            if (typeof value !== "string" && this.props.onChange) {
                this.props.onChange("");
            }
            this.setState({ isBound: true });
            if (this.props.onIsBoundChanged) {
                this.props.onIsBoundChanged(true);
            }
        };
        private unbind = () => {
            this.setState({ isBound: false });
            if (this.props.onIsBoundChanged) {
                this.props.onIsBoundChanged(false);
            }
            const resetValue: ResetValue<TValue> = this.props.resetValue;
            const valueResource = isCallbackResetValue(resetValue) ? resetValue() : resetValue;
            if (this.props.onChange) {
                this.props.onChange(valueResource);
            }
        };
    };
};
