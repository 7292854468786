import { ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ActionTemplateParameterResource, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { ControlType, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { useProjectTemplatesFilterState } from "~/areas/projects/components/Variables/TenantVariables/useProjectTemplatesFilterState";
import { repository } from "~/clientInstance";
import ActionTemplateParameterEditorDialog from "~/components/ActionTemplateParametersList/ActionTemplateParameterEditorDialog";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
interface AddProjectTemplateButtonProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    openSnackbar: (text: string) => void;
}
export function AddProjectTemplateButton({ project, doBusyTask, openSnackbar }: AddProjectTemplateButtonProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const [filterState, setFilterState] = useProjectTemplatesFilterState();
    const handleAddProjectTemplate = async (newProjectTemplate: ActionTemplateParameterResource) => {
        await doBusyTask(async () => {
            const updatedProject = await repository.Projects.modify({ ...project, Templates: [...project.Templates, newProjectTemplate] });
            dispatchAction("Add Project Variable Template", { resource: "Variable", action: Action.Add });
            displayCreatedTemplate(updatedProject, newProjectTemplate);
            openSnackbar("Project templates updated");
        });
    };
    const displayCreatedTemplate = (project: ProjectResource, newTemplate: ActionTemplateParameterResource) => {
        const savedTemplate = newTemplate.Name ? project.Templates.find((t) => t.Name === newTemplate.Name) : project.Templates.find((t) => t.Label === newTemplate.Label);
        if (savedTemplate) {
            setFilterState({
                ...filterState,
                filterByTemplateId: savedTemplate.Id,
                pageNumber: 1,
            });
        }
    };
    return (<OpenDialogButton label={"Add project template"} type={ActionButtonType.Secondary} accessibleName={"Add project template"}>
            <ActionTemplateParameterEditorDialog onOk={handleAddProjectTemplate} excludedControlTypes={[ControlType.StepName, ControlType.Package]} name={"template"} editPermission={{
            permission: Permission.VariableEdit,
            project: project?.Id,
            wildcard: true,
        }}/>
        </OpenDialogButton>);
}
