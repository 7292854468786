/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getTaskStartTime, TaskState } from "@octopusdeploy/octopus-server-client";
import type { TaskDetailsResource } from "@octopusdeploy/octopus-server-client";
import moment from "moment";
import * as React from "react";
import DurationBetweenLabel, { DurationBetweenLabelTitleMode } from "~/components/TimeLabels/DurationBetweenLabel";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import { Note } from "~/components/form";
interface TaskProgressProps {
    details: TaskDetailsResource;
}
export default class TaskProgress extends React.PureComponent<TaskProgressProps> {
    constructor(props: TaskProgressProps) {
        super(props);
    }
    render() {
        const task = this.props.details.Task;
        if (!task.HasBeenPickedUpByProcessor) {
            return this.getQueuedMessage();
        }
        if (!task.IsCompleted) {
            return this.getRunningMessage();
        }
        if (task.State === TaskState.TimedOut) {
            return this.getTimeoutMessage();
        }
        return this.getCompletedMessage();
    }
    getCompletedMessage() {
        const task = this.props.details.Task;
        const startTime = getTaskStartTime(task);
        return task.State === TaskState.Canceled && task.StartTime === null ? (<Note>This task was canceled prior to starting</Note>) : (<Note>
                This task started <TimeFromNowLabel time={startTime!}/> and ran for {task.Duration}
            </Note>);
    }
    getTimeoutMessage() {
        const task = this.props.details.Task;
        const queueTimeLbl = <TimeFromNowLabel time={task.QueueTime!}/>;
        const expiryLbl = <DurationBetweenLabel from={task.QueueTime!} to={task.QueueTimeExpiry!} titleMode={DurationBetweenLabelTitleMode.To}/>;
        const actualLbl = <DurationBetweenLabel from={task.QueueTime!} to={task.LastUpdatedTime!} titleMode={DurationBetweenLabelTitleMode.To}/>;
        return (<Note>
                The task was scheduled to run {queueTimeLbl} with an expiry of {expiryLbl} but the Octopus Server attempted to run the task {actualLbl} after the scheduled time.
            </Note>);
    }
    getRunningMessage() {
        const task = this.props.details.Task;
        const startTime = getTaskStartTime(task);
        const progress = this.props.details.Progress;
        const estimate = progress && progress.EstimatedTimeRemaining && " and " + progress.EstimatedTimeRemaining;
        return (<Note>
                This task started <TimeFromNowLabel time={startTime!}/>
                {estimate}
            </Note>);
    }
    getQueuedMessage() {
        const task = this.props.details.Task;
        const mustBegin = task.QueueTimeExpiry && (<span>
                {" "}
                The task must begin execution within <DurationBetweenLabel from={task.QueueTime!} to={task.QueueTimeExpiry} titleMode={DurationBetweenLabelTitleMode.Both}/> of the scheduled start time.
            </span>);
        const lbl = moment(task.QueueTime).isBefore(moment()) ? "This task was queued" : "This task has been queued to run";
        return (<Note>
                {" "}
                {lbl} <TimeFromNowLabel time={task.QueueTime!}/>.{mustBegin}
            </Note>);
    }
    static displayName = "TaskProgress";
}
