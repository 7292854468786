import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationAuditOverview, CommonConfigurationAuditTopics } from "../../CommonComponents/CommonConfigurationAuditViews";
export const ConfigurationAuditRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.audit,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationAuditOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationAuditTopics />;
    },
};
