import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import styles from "../../../primitiveComponents/dataDisplay/SvgImage/styles.module.less";
const ExecuteWorkerRoles = (props: Partial<SvgImageProps>) => (<SvgImage viewBox="0 0 151 47" title="Execute Worker Roles" {...props}>
        <g fillRule="evenodd" strokeWidth="1">
            <rect className={styles.imgBlueGrey} width="107.38" height="45.87" x="42.75" y=".21" fillRule="nonzero" rx="1.87"/>
            <path className={styles.imgDark} d="M20 19.972h19.672v-4.18c-.027-.366.102-.613.386-.742.283-.129.624-.008 1.02.362l4.625 4.56c.375.317.563.621.563.912 0 .291-.115.55-.344.774L41.078 26.4c-.413.3-.753.391-1.02.275-.268-.116-.396-.401-.386-.856v-4.544H20v-1.302z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M96.654 25v2.969a2.11 2.11 0 0 0-.756.461 1.507 1.507 0 0 0-.416.672h-3.178c-.203.086-.304.214-.304.382 0 .17.101.295.304.377h3.178C95.78 30.621 96.285 31 97 31s1.21-.38 1.487-1.139h17.495c.213.432.408.712.586.839.667.475 1.523.341 1.947-.08.292-.291.45-.544.472-.759 10.132.018 15.395.018 15.79 0 .042-.002.188.63.573 1.007.337.33.84.432 1.034.432 1.236 0 1.694-1.146 1.68-1.329 0-.002 1.604 0 3.407 0 .188 0 .395-.227.395-.447s-.207-.438-.395-.438c-.676 0-3.405.007-3.407 0a2.218 2.218 0 0 0-.497-.89c-.113-.118-.34-.25-.678-.396l-.03-1.602c-.168-.217-.28-.365-.335-.442-.113-.16-.338.296-.413.247-.095-.062-.033 1.768-.043 1.76-.047-.034-.56.216-.856.559-.313.363-.408.82-.436.812-.09-.028-15.789 0-15.789-.032 0-.164-.15-.51-.472-.78a1.638 1.638 0 0 0-.618-.322v-1.75c0-.273-.134-.21-.176-.376-.048-.19-.468.268-.532.23-.058-.033-.03.986-.035 1.493-.002.186 0 .403 0 .403s-.508.177-.79.457c-.114.112-.241.327-.382.645H98.487a1.437 1.437 0 0 0-.355-.672c-.167-.17-.408-.314-.724-.43v-3h-.754z"/>
            <rect className={styles.imgLight} width="12.75" height="11.75" x="110.5" y="14" fillRule="nonzero"/>
            <rect className={styles.imgLight} width="13" height="11" x="90.5" y="14.75" fillRule="nonzero"/>
            <rect className={styles.imgLight} width="13.25" height="12.25" x="130" y="14.25" fillRule="nonzero"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M98.75 19.07h.73a3 3 0 0 0-2-2v.73a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.72a.43.43 0 0 1 .43.43v.85a.43.43 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2h-.73a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M97.16 26.82h7.24a.42.42 0 0 0 .42-.42V13.47a.42.42 0 0 0-.42-.42H88.85a.42.42 0 0 0-.42.42V26.4c0 .232.188.42.42.42h7.24m5.64-6.47a.43.43 0 0 1-.43.42h-1a3.83 3.83 0 0 1-2.87 2.88v1a.43.43 0 0 1-.43.42h-.8a.42.42 0 0 1-.42-.42v-1a3.83 3.83 0 0 1-2.88-2.88H92a.43.43 0 0 1-.43-.42v-.85a.43.43 0 0 1 .43-.43h1a3.83 3.83 0 0 1 2.88-2.88v-1a.42.42 0 0 1 .42-.42h.85a.43.43 0 0 1 .43.42v1a3.83 3.83 0 0 1 2.87 2.88h1a.43.43 0 0 1 .43.43l-.15.85zm17.02-1.28h.73a3 3 0 0 0-2-2v.73a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.72a.43.43 0 0 1 .43.43v.85a.43.43 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2h-.73a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M117.16 26.82h7.24a.42.42 0 0 0 .42-.42V13.47a.42.42 0 0 0-.42-.42h-15.55a.42.42 0 0 0-.42.42V26.4c0 .232.188.42.42.42h7.24m5.64-6.47a.43.43 0 0 1-.43.42h-1a3.83 3.83 0 0 1-2.87 2.88v1a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-1a3.83 3.83 0 0 1-2.88-2.88h-1a.43.43 0 0 1-.43-.42v-.85a.43.43 0 0 1 .43-.43h1a3.83 3.83 0 0 1 2.88-2.88v-1a.42.42 0 0 1 .42-.42h.85a.43.43 0 0 1 .43.42v1a3.83 3.83 0 0 1 2.87 2.88h1a.43.43 0 0 1 .43.43v.85zm17.02-1.28h.73a3 3 0 0 0-2-2v.73a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.72a.43.43 0 0 1 .43.43v.85a.43.43 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2h-.73a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M137.16 26.82h7.24a.42.42 0 0 0 .42-.42V13.47a.42.42 0 0 0-.42-.42h-15.55a.42.42 0 0 0-.42.42V26.4c0 .232.188.42.42.42h7.24m5.64-6.47a.43.43 0 0 1-.43.42h-1a3.83 3.83 0 0 1-2.87 2.88v1a.43.43 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-1a3.83 3.83 0 0 1-2.88-2.88h-1a.43.43 0 0 1-.43-.42v-.85a.43.43 0 0 1 .43-.43h1a3.83 3.83 0 0 1 2.88-2.88v-1a.42.42 0 0 1 .42-.42h.85a.43.43 0 0 1 .43.42v1a3.83 3.83 0 0 1 2.87 2.88h1a.43.43 0 0 1 .43.43v.85z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M63.87 5.55H48.8a.89.89 0 0 0-.88.89v26.91a.88.88 0 0 0 .88.88h32.36a.87.87 0 0 0 .88-.88V6.44a.88.88 0 0 0-.88-.89H66.1"/>
            <path className={styles.imgLight} fillRule="nonzero" d="M58 19.43a7.11 7.11 0 0 0 13.94 0H58zM63.69 12h2.63l.85-3.5A1.45 1.45 0 0 0 66 7.88h-2a1.47 1.47 0 0 0-1.22.66l.91 3.46z"/>
            <path className={styles.imgLight} fillRule="nonzero" d="M72.58 15.77h-.29a7.29 7.29 0 0 0-3.84-6.41l-.85 3.5a.73.73 0 0 1-.71.56h-3.75a.74.74 0 0 1-.7-.53l-1-3.49a7.28 7.28 0 0 0-3.77 6.37h-.29a1.08 1.08 0 1 0 0 2.15h15.19a1.08 1.08 0 0 0 0-2.15h.01z"/>
            <circle className={styles.imgLight} cx="68.64" cy="31.13" r="1" fillRule="nonzero"/>
            <circle className={styles.imgLight} cx="61.46" cy="31.14" r="1" fillRule="nonzero"/>
            <path className={styles.imgLight} fillRule="nonzero" d="M75 32.14a10.58 10.58 0 0 0-4.87-6.51v6.49l4.87.02zm-15.17-1.53v-5a10.59 10.59 0 0 0-4.89 6.52h4.89v-1.52zm1.17-.77a31.2 31.2 0 0 0 7.9 0v-4.25a7.73 7.73 0 0 1-7.9 0v4.25z"/>
            <circle className={styles.imgCyan} cx="13.42" cy="20.03" r="12.6" fillRule="nonzero"/>
            <path className={styles.imgWhite} fillRule="nonzero" d="M7.2 24c1.14-.73 2.48-2 2-3.49a9.06 9.06 0 0 1-.71-2.38 5.4 5.4 0 0 1 .32-2.18 5.53 5.53 0 0 1 6.83-3.24A4.93 4.93 0 0 1 18.87 19c-.62 1.49-.89 2.64.48 3.8.36.31 1.26.78 1.25 1.34 0 .73-1.44-.16-1.6-.29.19.32 2 2.21.85 2.34-1.15.13-2-1.35-2.63-2-1.07-1.07-.88 1.29-.89 1.78 0 .77-.55 2.33-1.52 1.31-.8-.83-.5-2.17-1.06-3.1-.56-.93-1.63 1-1.89 1.39-.26.39-1.71 2.43-2.27 1.37-.47-.88.27-2.24.64-3-.14.28-1.07.71-1.34.85A3.65 3.65 0 0 1 7 25.2c-1.44-.08-.34-.85.25-1.23L7.2 24z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M64.446 32v4.948a3.357 3.357 0 0 0-1.21.769 2.53 2.53 0 0 0-.664 1.12h-5.086c-.324.143-.486.356-.486.637s.162.49.486.628h5.086C63.047 41.367 63.856 42 65 42s1.937-.633 2.38-1.898h5.007c.409-.112.613-.32.613-.628 0-.307-.204-.52-.613-.638H67.38c-.111-.462-.3-.835-.568-1.12-.267-.284-.654-.523-1.159-.716v-5h-1.207z"/>
        </g>
    </SvgImage>);
export { ExecuteWorkerRoles };
