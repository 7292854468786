/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import VariableCell from "~/areas/variables/VariableCell/VariableCell";
import type { PopoverPosition } from "~/components/PopoverWhenFocused/PopoverWhenFocused";
import { PopoverWhenFocused } from "~/components/PopoverWhenFocused/PopoverWhenFocused";
import styles from "./style.module.less";
interface FocusActionsLayoutProps {
    isFocused: boolean;
    actions: JSX.Element[];
    position?: PopoverPosition;
    onClickOutside?: () => void;
}
const position = { top: 0, left: 0, right: 0 };
export default class FocusActionsLayout extends React.Component<FocusActionsLayoutProps> {
    render() {
        return (<PopoverWhenFocused isFocused={this.props.isFocused} position={this.props.position ? this.props.position : position} onClickOutside={this.props.isFocused && this.props.onClickOutside ? this.props.onClickOutside : null!}>
                {this.props.children}
                {this.props.isFocused && (<VariableCell className={styles.alignRightContainer}>
                        <div className={styles.spacer}/>
                        <div className={styles.actionsContainer}>{this.props.actions.map((a, index) => React.cloneElement(a, { key: index }))}</div>
                    </VariableCell>)}
            </PopoverWhenFocused>);
    }
    static displayName = "FocusActionsLayout";
}
