import { pageId } from "@octopusdeploy/portal-routes";
export default () => {
    return {
        me: pageId({ area: "User Profile", id: "UserProfile", name: "User Profile" }),
        logins: pageId({ area: "User Profile", id: "UserProfile.Logins", name: "User Profile Logins" }),
        details: pageId({ area: "User Profile", id: "UserProfile.Details", name: "User Profile Details" }),
        apiKeys: pageId({ area: "User Profile", id: "UserProfile.ApiKeys", name: "User Profile API Keys" }),
        activity: pageId({ area: "User Profile", id: "UserProfile.Activity", name: "User Profile Activity" }),
        register: pageId({ area: "User Profile", id: "UserRegister", name: "Register" }),
        signIn: pageId({ area: "User Profile", id: "UserSignIn", name: "Sign In" }),
        signOut: pageId({ area: "User Profile", id: "UserSignOut", name: "Sign Out" }),
    };
};
