import type { ActionTemplateSearchResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import useRequiredContext from "~/hooks/useRequiredContext";
type ProcessActionTemplatesContextValue = ActionTemplateSearchResource[] | "NotLoaded";
type ProcessActionTemplatesRefreshContextValue = () => Promise<unknown>;
const ProcessActionTemplatesContext = React.createContext<ProcessActionTemplatesContextValue>("NotLoaded");
const RefreshProcessActionTemplatesContext = React.createContext<ProcessActionTemplatesRefreshContextValue | undefined>(undefined);
export function useActionTemplatesFromContext() {
    const maybeLoadedActionTemplates = useMaybeLoadedActionTemplatesFromContext();
    return maybeLoadedActionTemplates === "NotLoaded" ? [] : maybeLoadedActionTemplates;
}
export function useMaybeLoadedActionTemplatesFromContext() {
    return useRequiredContext(ProcessActionTemplatesContext, "ProcessActionTemplatesContext");
}
export function useRefreshActionTemplatesFromContext() {
    return useRequiredContext(RefreshProcessActionTemplatesContext, "RefreshProcessActionTemplatesContext");
}
export function useActionTemplateFromContext(id: string) {
    const actionTemplates = useActionTemplatesFromContext();
    return React.useMemo(() => {
        return actionTemplates.find((f) => f.Id === id);
    }, [actionTemplates, id]);
}
type ProcessActionTemplatesContextProviderProps = {
    templates: ActionTemplateSearchResource[] | "NotLoaded";
    refreshActionTemplates: () => Promise<unknown>;
};
export function ProcessActionTemplatesContextProvider(props: React.PropsWithChildren<ProcessActionTemplatesContextProviderProps>) {
    return (<ProcessActionTemplatesContext.Provider value={props.templates}>
            <RefreshProcessActionTemplatesContext.Provider value={props.refreshActionTemplates}>{props.children}</RefreshProcessActionTemplatesContext.Provider>
        </ProcessActionTemplatesContext.Provider>);
}
