import type { GitBranchResource, GitRef, ResourceCollection, GitDependencyRequestContext } from "@octopusdeploy/octopus-server-client";
import { isGitBranch, toGitBranch } from "@octopusdeploy/octopus-server-client";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import { repository } from "~/clientInstance";
const getTextValuePairForBranch = (branch: GitBranchResource, defaultBranch: string): GitRefOption => {
    const isDefaultBranch = branch.Name === defaultBranch;
    return {
        value: branch.CanonicalName,
        isDefault: isDefaultBranch,
        canWrite: !branch.IsProtected,
    };
};
const buildBranchNamesList = (branches: GitBranchResource[], defaultBranch: string): GitRefOption[] => branches.map((branch) => getTextValuePairForBranch(branch, defaultBranch));
const getFilteredBranchesList = async (requestContext: GitDependencyRequestContext, defaultBranch: string, filterTerm: string): Promise<GitRefOption[]> => {
    const filteredBranchResources = await repository.Projects.getBranchesForGitDependency(requestContext, filterTerm);
    let branches = filteredBranchResources.Items;
    // If the default branch is in the list, put it at the top
    const defaultBranchIndex = getDefaultBranchIndex(branches, defaultBranch);
    if (defaultBranchIndex !== -1) {
        const defaultBranch = branches.splice(defaultBranchIndex, 1);
        branches = [...defaultBranch, ...branches];
    }
    return buildBranchNamesList(branches, defaultBranch);
};
const getExternalBranchesList = async (requestContext: GitDependencyRequestContext, selectedRef: GitRef | undefined, defaultBranch: string): Promise<[
    GitRefOption[],
    number
]> => {
    const branchResources: ResourceCollection<GitBranchResource> = await repository.Projects.getBranchesForGitDependency(requestContext);
    let branches = branchResources.Items;
    // Add the currently selected branch to the list (if necessary)
    if (selectedRef && isGitBranch(selectedRef) && !branches.find((item) => item.CanonicalName === selectedRef)) {
        const selectedBranchResource = await repository.Projects.getBranchForGitDependency(requestContext, selectedRef);
        // It may be that the current branch has been deleted directly in the repo. Therefore, only add it if it was found.
        if (selectedBranchResource !== null) {
            branches = [selectedBranchResource, ...branches];
        }
    }
    // Add the default branch to the list (if necessary)
    const defaultBranchIndex = getDefaultBranchIndex(branches, defaultBranch);
    // If the default branch isn't in the list, add it
    if (defaultBranchIndex === -1) {
        // It may be that the default branch has been deleted directly in the repo. Therefore, only add it if it was found.
        const branchResource = await repository.Projects.getBranchForGitDependency(requestContext, defaultBranch);
        // If we have found the default branch, put it at the top
        if (branchResource !== null) {
            branches = [branchResource, ...branches];
        }
    }
    else {
        // If the default branch is already in the list, move it to the top
        const defaultBranch = branches.splice(defaultBranchIndex, 1);
        branches = [...defaultBranch, ...branches];
    }
    return [buildBranchNamesList(branches, defaultBranch), branchResources.TotalResults];
};
const getDefaultBranchIndex = (branches: GitBranchResource[], defaultBranch: string) => branches.findIndex((item) => item.CanonicalName === defaultBranch || item.CanonicalName === toGitBranch(defaultBranch));
export { getExternalBranchesList, getFilteredBranchesList, getTextValuePairForBranch, buildBranchNamesList };
