import { css, cx } from "@emotion/css";
import React from "react";
type YouTubePlayerProps = {
    videoId: string;
};
export function YouTubePlayer({ videoId }: YouTubePlayerProps) {
    return (<iframe className={cx(style)} src={`https://www.youtube.com/embed/${videoId}?rel=0`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen sandbox="allow-presentation allow-scripts allow-same-origin allow-popups"></iframe>);
}
const style = css({
    width: "100%",
    aspectRatio: "16 / 9",
});
