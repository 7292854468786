/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { values } from "lodash";
import type * as React from "react";
export interface RenderRegistrationCardProps {
    registration: CategorizedPageHelpRegistration;
}
export interface CategorizedPageHelpRegistration {
    page: PageDefinition;
    renderOverview: (props: RenderRegistrationCardProps) => React.ReactElement | null;
    renderTopics: (props: RenderRegistrationCardProps) => React.ReactElement | null;
}
export type PageHelpRegistration = CategorizedPageHelpRegistration;
export function isPageHelpRegistration(value: any): value is PageHelpRegistration {
    const type = typeof value;
    if (!value || type !== "object") {
        return false;
    }
    const converted = value as CategorizedPageHelpRegistration;
    return Boolean(converted.renderOverview) && typeof converted.renderOverview === "function" && Boolean(converted.renderTopics) && typeof converted.renderTopics === "function";
}
class PageHelpRegistry {
    private registrations: Record<string, CategorizedPageHelpRegistration> = {};
    public registerPageHelp(registration: CategorizedPageHelpRegistration) {
        this.registrations[registration.page.Id] = registration;
    }
    public getAllRegistrations(): PageHelpRegistration[] {
        return values(this.registrations);
    }
}
const registry = new PageHelpRegistry();
export default registry;
