/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css, cx } from "@emotion/css";
import { ListItem } from "@material-ui/core";
import { IconButton, Tooltip } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentActionResource, DeploymentStepResource } from "@octopusdeploy/octopus-server-client";
import { SpecialVariables, StartTrigger } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import BlueprintSidebarLink from "~/areas/projects/components/Process/Blueprints/BlueprintSidebarLink";
import { useActionTemplatesFromContext } from "~/areas/projects/components/Process/Contexts/ProcessActionTemplatesContextProvider";
import { useProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProcessErrorSelectors } from "~/areas/projects/components/Process/Contexts/ProcessErrors/ProcessErrorsContext";
import { useProcessQueryStringContext } from "~/areas/projects/components/Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
import { useProcessWarningSelectors } from "~/areas/projects/components/Process/Contexts/ProcessWarnings/ProcessWarningsContext";
import { ProcessActionErrorIndicator } from "~/areas/projects/components/Process/ErrorIndicators/ProcessActionErrorIndicator";
import { ProcessActionWarningIndicator } from "~/areas/projects/components/Process/ErrorIndicators/ProcessActionWarningIndicator";
import deploymentPartStyles from "~/areas/projects/components/Process/ListItems/ProcessListItem.module.less";
import deploymentPartForSidebarStyles from "~/areas/projects/components/Process/ListItems/ProcessListItemForSidebar.module.less";
import getActionLogoUrl from "~/areas/projects/components/getActionLogoUrl";
import { StepRolling } from "~/components/Images/Process/StepRolling";
type ListItemLinkProps = React.PropsWithChildren<{
    className?: string;
    onClick: () => void;
}>;
const ListItemLinkInternal: React.FC<ListItemLinkProps> = ({ onClick, ...rest }) => {
    const clickHandler = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        ev.stopPropagation();
        ev.preventDefault();
        onClick();
    };
    return <ListItem button component="a" disableGutters={true} style={{ paddingTop: 0, paddingBottom: 0 }} {...rest} onClick={clickHandler}/>;
};
ListItemLinkInternal.displayName = "ListItemLinkInternal"
const ListItemLink = React.memo(ListItemLinkInternal);
interface BlueprintActionSidebarItemProps {
    action: DeploymentActionResource;
    step: DeploymentStepResource;
    index: string;
    isCurrent: boolean;
    isDisabled: boolean;
    isChild: boolean;
    actionIndex: number;
    blueprintId: string;
}
const BlueprintActionSidebarItem: React.FC<BlueprintActionSidebarItemProps> = (props) => {
    const actionTemplates = useActionTemplatesFromContext();
    const action = props.action;
    const step = props.step;
    const isChildAction = props.isChild;
    const stepNumber = props.index;
    let actionTypeName = action.ActionType;
    const actionTemplate = actionTemplates.find((x) => x.Type === action.ActionType);
    if (actionTemplate) {
        actionTypeName = actionTemplate.Name;
    }
    const { actions: queryStringActions } = useProcessQueryStringContext();
    const onClick = React.useCallback(() => {
        queryStringActions.showProcessBlueprintAction(props.blueprintId, action.Id);
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        //The current query string actions are tied to the same context as selectors. In order to avoid onClick breaking
        //memoization, we should move the actions into its own separate context.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.blueprintId, action.Id]);
    return (<BlueprintSidebarLink actionType={actionTypeName} logoUrl={getActionLogoUrl(action)} isCurrentAction={props.isCurrent} index={`${stepNumber}${props.actionIndex}.`} isDisabled={props.isDisabled} name={action.Name} isRunInParallelWithLast={step.StartTrigger === StartTrigger.StartWithPrevious && !isChildAction} onClick={onClick}/>);
};
BlueprintActionSidebarItem.displayName = "BlueprintActionSidebarItem"
interface BlueprintParentStepSidebarItemProps {
    step: DeploymentStepResource;
    isCurrent: boolean;
    isDisabled: boolean;
    index: string;
    renderChildAction: (action: DeploymentActionResource, step: DeploymentStepResource, index: string, actionIndex: number, blueprintId: string) => React.ReactNode;
    blueprintId: string;
}
const BlueprintParentStepSidebarItem: React.FC<BlueprintParentStepSidebarItemProps> = ({ step, index, isCurrent, isDisabled, renderChildAction, blueprintId }) => {
    const maxParallelism = step.Properties[SpecialVariables.Action.MaxParallelism];
    const showWindowSize = maxParallelism ? maxParallelism.toString().length > 0 : false;
    const { actions: queryStringActions } = useProcessQueryStringContext();
    const onClick = React.useCallback(() => {
        queryStringActions.showProcessBlueprintParentStep(blueprintId, step.Id);
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        //The current query string actions are tied to the same context as selectors. In order to avoid onClick breaking
        //memoization, we should move the actions into its own separate context.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blueprintId, step.Id]);
    const parentStepLabel = React.useMemo(() => showWindowSize ? (<span>Rolling deployment</span>) : (<span>
                    Multi-step deployment across
                    <br />
                    deployment targets
                </span>), [showWindowSize]);
    const icon = React.useMemo(() => <StepRolling height="1.6rem" className={deploymentPartStyles.stepIcon}/>, []);
    return (<div key={step.Id} className={deploymentPartForSidebarStyles.group}>
            <BlueprintSidebarLink actionType={parentStepLabel} icon={icon} isCurrentAction={isCurrent} index={index} isDisabled={isDisabled} name={step.Name} isRunInParallelWithLast={step.StartTrigger === StartTrigger.StartWithPrevious} onClick={onClick}/>
            {step.Actions.map((action, i) => {
            return renderChildAction(action, step, index, i + 1, blueprintId);
        })}
        </div>);
};
BlueprintParentStepSidebarItem.displayName = "BlueprintParentStepSidebarItem"
interface BlueprintSidebarItemProps {
    actionId: string;
    isCurrent: boolean;
    onShowContextMenu: (event: React.MouseEvent<Element, MouseEvent>, actionId: string) => void;
    blueprintId: string;
}
const BlueprintSidebarItem: React.FC<BlueprintSidebarItemProps> = ({ onShowContextMenu, blueprintId, actionId, isCurrent }) => {
    const { selectors } = useProcessContext();
    const blueprintsContext = useProcessBlueprintsFromContext();
    const action = selectors.getActionById(actionId);
    const step = selectors.getStepById(action.ParentId);
    const stepNumber = selectors.getStepNumber(step.Id);
    const { actions: queryStringActions, state: { queryFilter }, } = useProcessQueryStringContext();
    const onClick = React.useCallback(() => {
        queryStringActions.showProcessAction(action.Id);
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        //The current query string actions are tied to the same context as selectors. In order to avoid onClick breaking
        //memoization, we should move the actions into its own separate context.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action.Id]);
    const processErrorSelectors = useProcessErrorSelectors();
    const processWarningSelectors = useProcessWarningSelectors();
    //TODO: revisit, we should remove the selectors from `getActionErrors` and consider passing in the result of the associated selectors instead to avoid
    //having to get a new set of errors every time process state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const actionErrors = React.useMemo(() => processErrorSelectors.getActionErrors(action.Id, selectors), [action.Id, processErrorSelectors]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const actionWarnings = React.useMemo(() => processWarningSelectors.getActionWarnings(action.Id, selectors), [action.Id, processWarningSelectors]);
    const handleShowContextMenu = React.useCallback((event: React.MouseEvent<Element, MouseEvent>) => {
        onShowContextMenu(event, action.Id);
    }, [action.Id, onShowContextMenu]);
    const isStepDisabled = selectors.isActionDisabled(action.Id);
    const renderChildAction = React.useCallback((action: DeploymentActionResource, step: DeploymentStepResource, index: string, actionIndex: number, blueprintId: string) => {
        return (<BlueprintActionSidebarItem key={action.Id} blueprintId={blueprintId} index={index} action={action} step={step} isCurrent={queryFilter.blueprintId === blueprintId && queryFilter.blueprintActionId === action.Id} isDisabled={isStepDisabled || action.IsDisabled} isChild={true} actionIndex={actionIndex}/>);
    }, [queryFilter, isStepDisabled]);
    const blueprint = blueprintsContext.find((b) => b.Id === blueprintId);
    const errors = [...actionErrors];
    if (!blueprint) {
        errors.push("Blueprint not found");
    }
    return (<div className={cx(stylesInt.blueprintContainer, stylesInt.blueprintItemBackground)}>
            <StepLink isDisabled={isStepDisabled} isCurrentAction={isCurrent} onClick={onClick}>
                {withDisabledTooltipWrapper(<div className={stylesInt.blueprintHeadingText}>{action.Name}</div>, isStepDisabled)}
                {errors.length > 0 && <ProcessActionErrorIndicator actionErrors={errors}/>}
                {actionWarnings.length > 0 && <ProcessActionWarningIndicator actionWarnings={actionWarnings}/>}
                <div className={stylesInt.blueprintHeadingOverflow}>
                    <IconButton icon="OverflowMenu" onClick={handleShowContextMenu}/>
                </div>
            </StepLink>

            {blueprint?.Steps.map((step, i) => {
            if (step.Actions.length === 1) {
                const action = step.Actions[0];
                return (<BlueprintActionSidebarItem key={action.Id} blueprintId={blueprintId} index={`${stepNumber}.`} action={action} actionIndex={i + 1} step={step} isCurrent={queryFilter.blueprintId === blueprint.Id && queryFilter.blueprintActionId === action.Id} isDisabled={isStepDisabled || action.IsDisabled} isChild={false}/>);
            }
            return (<BlueprintParentStepSidebarItem key={step.Id} blueprintId={blueprintId} index={`${stepNumber}.${i + 1}.`} step={step} isCurrent={queryFilter.blueprintId === blueprint.Id && queryFilter.blueprintParentStepId === step.Id} renderChildAction={renderChildAction} isDisabled={isStepDisabled}/>);
        })}
        </div>);
};
BlueprintSidebarItem.displayName = "BlueprintSidebarItem"
const stylesInt = {
    blueprintItemBackground: css({
        backgroundColor: `${themeTokens.color.background.secondary.default} !important`,
    }),
    blueprintHeadingText: css({
        padding: `${space[12]} ${space[12]} ${space[12]} ${space[12]}`,
        fontSize: "0.9rem",
        flexGrow: 1,
    }),
    blueprintHeadingOverflow: css({
        paddingRight: space[12],
    }),
    blueprintContainer: css({
        zIndex: 0,
    }),
    activeStep: css({
        color: `${themeTokens.color.text.link.default} !important`,
        fontWeight: 600,
    }),
    disabledStep: css({
        color: `${themeTokens.color.button.text.disabled} !important`,
    }),
};
function withDisabledTooltipWrapper(children: React.ReactNode, isDisabled: boolean) {
    if (isDisabled) {
        return (<Tooltip content="Disabled" style={{ width: "100%" }}>
                {children}
            </Tooltip>);
    }
    return children;
}
type StepLinkProps = React.PropsWithChildren<{
    isDisabled: boolean;
    isCurrentAction: boolean;
    onClick: () => void;
    className?: string;
}>;
const StepLinkInternal: React.FC<StepLinkProps> = ({ children, isDisabled, isCurrentAction, onClick, className }) => {
    return (<ListItemLink className={cx(className, isCurrentAction ? stylesInt.activeStep : null, isDisabled ? stylesInt.disabledStep : null)} onClick={onClick}>
            {children}
        </ListItemLink>);
};
StepLinkInternal.displayName = "StepLinkInternal"
const StepLink = React.memo(StepLinkInternal);
export default React.memo(BlueprintSidebarItem);
