import * as React from "react";
import ExternalLink from "../../../../../../../components/Navigation/ExternalLink";
import { buildServerPackagingInstruction } from "../../Instructions";
import { CommonBuildServerPackagingTextForOctoExe, CommonBuildServerPackagingTextForIntegration, CommonBuildServerPackagingTextForOctoPack } from "../CommonRegistrationText";
export const AspNetBuildServerPackaging: React.SFC<{}> = () => (<div>
        <p>
            When packaging .NET applications from build servers, we have extensions available that will typically use either <i>OctoPack</i> or <i>Octo.exe</i> to package your application behind the scenes.
        </p>
        {CommonBuildServerPackagingTextForOctoPack}
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
        <p>
            The <ExternalLink href="GuidesASPNet">ASP.NET guides</ExternalLink> provide step by step instructions for deploying ASP.NET applications.
        </p>
    </div>);
AspNetBuildServerPackaging.displayName = "AspNetBuildServerPackaging"
export default buildServerPackagingInstruction(() => <AspNetBuildServerPackaging />);
