import type { ProcessType, ProjectResource } from "@octopusdeploy/octopus-server-client";
export type StepPackageContext = {};
export type ProjectStepPackageContext = StepPackageContext & {
    project: ProjectResource;
    processType: ProcessType;
};
export function isProjectStepPackageContext(context: StepPackageContext): context is ProjectStepPackageContext {
    const key: keyof ProjectStepPackageContext = "project";
    return key in context;
}
