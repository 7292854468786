import { logger } from "@octopusdeploy/logging";
import type { ReactNode } from "react";
import React, { useMemo, useEffect, useContext, createContext, useCallback, useState } from "react";
/**
 * Represents the intent to block navigation away from the current page
 */
export interface NavigationBlocker {
    confirmationText: string;
    onSaveClick: () => Promise<void>;
    saveLabel: string;
}
type UnregisterNavigationBlocker = () => void;
type RegisterNavigationBlocker = (navigationBlocker: NavigationBlocker) => UnregisterNavigationBlocker;
const registerNavigationBlockerContext = createContext<RegisterNavigationBlocker | null>(null);
export function useNavigationBlockerState() {
    const [navigationBlocker, setNavigationBlocker] = useState<NavigationBlocker | null>(null);
    const registerNavigationBlocker: RegisterNavigationBlocker = useCallback((navigationBlocker) => {
        setNavigationBlocker(navigationBlocker);
        return () => {
            setNavigationBlocker((prev) => {
                if (prev !== navigationBlocker) {
                    logger.error("Tried to unregister a navigation blocker when it has already been replaced by another navigation blocker.");
                    return prev;
                }
                return null;
            });
        };
    }, []);
    return { navigationBlocker, registerNavigationBlocker };
}
export function RegisterNavigationBlockerProvider({ children, registerNavigationBlocker }: {
    registerNavigationBlocker: RegisterNavigationBlocker;
    children: ReactNode;
}) {
    return <registerNavigationBlockerContext.Provider value={registerNavigationBlocker}>{children}</registerNavigationBlockerContext.Provider>;
}
export function useNavigationBlocker({ when, confirmationText, saveLabel, onSaveClick }: {
    when: boolean;
    confirmationText: string;
    onSaveClick: () => Promise<void>;
    saveLabel: string;
}) {
    const registerNavigationBlocker = useContext(registerNavigationBlockerContext);
    if (registerNavigationBlocker === null) {
        throw new Error("RegisterNavigationBlockerProvider not found");
    }
    const navigationBlocker = useMemo<NavigationBlocker>(() => ({
        confirmationText,
        saveLabel,
        onSaveClick,
    }), [confirmationText, onSaveClick, saveLabel]);
    useEffect(() => {
        if (when) {
            return registerNavigationBlocker(navigationBlocker);
        }
    }, [navigationBlocker, registerNavigationBlocker, when]);
}
