import type { TenantResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { MissingChip, TenantChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface TenantMultiSelectProps extends FormFieldProps<string[]> {
    items: TenantResource[];
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
const TenantTypedMultiSelect = MultiSelect<TenantResource>();
export const TenantMultiSelect: React.FC<TenantMultiSelectProps> = (props) => {
    const chipRenderer = (r: TenantResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <TenantChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} tenantName={item.Name}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.Tenant} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <TenantTypedMultiSelect fieldName="tenants" renderChip={chipRenderer} {...props}/>;
};
TenantMultiSelect.displayName = "TenantMultiSelect"
