import type { DeploymentFreezeDetailEnvironment, DeploymentFreezeDetailProject, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
interface EditDeploymentFreezeEnvironmentsDialogProps {
    project: DeploymentFreezeDetailProject;
    onEnvironmentUpdate: (project: DeploymentFreezeDetailProject, selectedEnvironments: DeploymentFreezeDetailEnvironment[]) => void;
}
interface EditDeploymentFreezeEnvironmentsDialogState extends DataBaseComponentState {
    environments: EnvironmentResource[];
    selectedEnvironments: DeploymentFreezeDetailEnvironment[];
}
export default class EditDeploymentFreezeEnvironmentsDialog extends DataBaseComponent<EditDeploymentFreezeEnvironmentsDialogProps, EditDeploymentFreezeEnvironmentsDialogState> {
    constructor(props: EditDeploymentFreezeEnvironmentsDialogProps) {
        super(props);
        this.state = {
            environments: [],
            selectedEnvironments: this.props.project.Environments,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const response = await repository.Projects.getAvailableEnvironmentsForProjectsAcrossAllSpaces([this.props.project.Id]);
            this.setState({ environments: response.Environments });
        });
    }
    async save(): Promise<boolean> {
        this.props.onEnvironmentUpdate(this.props.project, this.state.selectedEnvironments);
        return true;
    }
    render() {
        return (<SaveDialogLayout title="Update Environments" busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()}>
                <div>
                    <EnvironmentMultiSelect label={"Select environments"} environments={this.state.environments} onChange={(environmentIds) => this.setState({ selectedEnvironments: this.state.environments.filter((e) => environmentIds.includes(e.Id)) })} value={this.state.selectedEnvironments.map((e) => e.Id)}/>
                </div>
            </SaveDialogLayout>);
    }
    static displayName = "EditDeploymentFreezeEnvironmentsDialog";
}
