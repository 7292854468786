import React from "react";
import type { TooltipProps } from "recharts";
import type { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import type { ObjectPropertyPath } from "~/utils/ObjectPropertyPath/ObjectPropertyPath";
import styles from "./styles.module.less";
interface TypedPayload<TResource> extends Payload<ValueType, NameType> {
    dataKey: ObjectPropertyPath<TResource>;
    payload: TResource;
}
type PayloadArray<TResource> = Array<TypedPayload<TResource>>;
export type RechartsTooltipProps = TooltipProps<ValueType, NameType>;
interface RenderProps<TResource> extends Omit<RechartsTooltipProps, "payload"> {
    payload: PayloadArray<TResource>;
}
interface TooltipBaseProps<TResource> extends RechartsTooltipProps {
    children: (renderProps: RenderProps<TResource>) => React.ReactElement;
}
export function TooltipBase<TResource>({ children, ...props }: TooltipBaseProps<TResource>) {
    if (props.active && props.payload && props.payload.length) {
        const { payload, ...rest } = props;
        const payloadArray: PayloadArray<TResource> = payload
            .filter((p) => p.payload !== undefined)
            .map((p) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const path = p.dataKey as ObjectPropertyPath<TResource>;
            return {
                ...p,
                payload: p.payload,
                dataKey: path,
            };
        });
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return <div className={styles.tooltipBase}>{children({ ...rest, payload: payloadArray })}</div>;
    }
    return null;
}
