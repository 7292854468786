import type { TooltipContent } from "@octopusdeploy/design-system-components";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import * as React from "react";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import navigationChipStyles from "./NavigationChip.module.less";
import { StyledMaterialChip } from "./StyledMaterialChip";
export interface NavigationChipProps {
    to: LinkHref;
    accessibleName: string;
    description?: string | TooltipContent;
}
export const NavigationChip: React.FC<NavigationChipProps> = ({ to, accessibleName, children, description }) => {
    return (<InternalLink to={to} accessibleName={accessibleName}>
            <StyledMaterialChip className={navigationChipStyles.clickable} label={children} tooltipContent={description ?? children}/>
        </InternalLink>);
};
NavigationChip.displayName = "NavigationChip"
export default NavigationChip;
