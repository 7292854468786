/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eq-null */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { Range } from "@codemirror/state";
import { StateField, StateEffect, RangeSet } from "@codemirror/state";
import type { VersionRuleTestResponse } from "@octopusdeploy/octopus-server-client";
import type { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import ReactCodeMirror, { hoverTooltip, Decoration, gutter, GutterMarker } from "@uiw/react-codemirror";
import cn from "classnames";
import { EditorView } from "codemirror";
import { forwardRef, useImperativeHandle } from "react";
import * as React from "react";
import { rulesTesterCodeMirrorStyles } from "~/areas/projects/components/Channels/RulesTester/rulesTesterStyles";
import { githubDark, githubLight } from "~/components/CodeEditor/CodeEditorThemes";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import styles from "./style.module.less";
interface CodeMirrorProps {
    value: string;
    onChange: (text: string) => void;
}
const addTextMarks = StateEffect.define<Range<Decoration>[]>();
const filterTextMarks = StateEffect.define<{
    (from: number, to: number): boolean;
}>();
const textMarkField = StateField.define({
    create() {
        return Decoration.none;
    },
    update(value, tr) {
        value = value.map(tr.changes);
        for (const effect of tr.effects) {
            if (!effect.value) {
                return value;
            }
            if (effect.is(addTextMarks)) {
                value = value.update({ add: effect.value, sort: true });
            }
            else if (effect.is(filterTextMarks)) {
                value = value.update({ filter: effect.value });
            }
        }
        return value;
    },
    provide: (f) => EditorView.decorations.from(f),
});
const validRuleTextMark = Decoration.mark({
    attributes: { class: styles.validRule },
});
const invalidRuleTextMark = Decoration.mark({
    attributes: { class: styles.invalidRule },
});
const badRuleTextMark = Decoration.mark({
    attributes: { class: styles.badRule },
});
const gutterMarkerEffect = StateEffect.define<{
    pos: number;
    clearAll?: boolean;
    alert?: string;
    icon?: string;
    tooltip?: string;
}>({
    map: (val, mapping) => ({
        pos: mapping.mapPos(val.pos),
        clearAll: val.clearAll,
        alert: val.alert,
        icon: val.icon,
        tooltip: val.tooltip,
    }),
});
declare class GutterMarkerWithMessage extends GutterMarker {
    getMessage(): string;
}
const gutterMarkState = StateField.define<RangeSet<GutterMarkerWithMessage>>({
    create() {
        return RangeSet.empty;
    },
    update(set, transaction) {
        set = set.map(transaction.changes);
        for (const e of transaction.effects) {
            if (e.is(gutterMarkerEffect)) {
                if (e.value.clearAll) {
                    set = set.update({ filter: (_) => false });
                }
                else {
                    set = set.update({ add: [createGutterMark(e.value.alert!, e.value.icon!, e.value.tooltip!).range(e.value.pos)] });
                }
            }
        }
        return set;
    },
});
const createGutterMark = (alert: string, icon: string, tooltip: string) => new (class extends GutterMarker {
    toDOM() {
        const marker = document.createElement("div");
        marker.className = `${styles.codeGutter} ${alert}`;
        const i = marker.appendChild(document.createElement("i"));
        i.className = `fa-solid fa-${icon}`;
        return marker;
    }
    getMessage(): string {
        return tooltip;
    }
})();
const placeholderGutterMarker = createGutterMark(styles.validRuleIcon, "check", "");
const wordHover = hoverTooltip((view, pos, side) => ({
    pos,
    above: true,
    create(view) {
        const dom = document.createElement("div");
        const message = view.state.field(gutterMarkState).iter(view.state.doc.lineAt(pos).from).value?.getMessage() ?? null;
        dom.textContent = message;
        dom.className = message ? styles.tooltip : styles.hiddenTooltip;
        return { dom };
    },
}));
const gutterMarkExtension = [
    gutterMarkState,
    gutter({
        class: "cm-marker-gutter",
        markers: (v) => v.state.field(gutterMarkState),
        initialSpacer: () => placeholderGutterMarker,
    }),
    EditorView.baseTheme({
        ".cm-marker-gutter .cm-gutterElement": {
            color: "red",
            paddingLeft: "5px",
            cursor: "default",
        },
    }),
];
export interface RulesTesterElement {
    setValue(text: string): void;
    showResults(getResultFn: (version: string) => VersionRuleTestResponse): void;
}
export const RulesTester = forwardRef<RulesTesterElement, CodeMirrorProps>((props, ref) => {
    let codeMirrorRef: ReactCodeMirrorRef | undefined = undefined;
    const theme = useThemePaletteType();
    useImperativeHandle(ref, () => ({
        setValue: (text: string) => {
            setValue(text);
        },
        showResults: (getResultFn) => {
            showResults(getResultFn);
        },
    }));
    const setValue = (text: string): void => {
        const editor = codeMirrorRef?.view;
        if (editor) {
            editor.dispatch({
                changes: { from: 0, to: editor.state.doc.length, insert: text },
            });
        }
    };
    const removeTextMarksRange = (a: number, b: number) => {
        codeMirrorRef?.view?.dispatch({
            effects: filterTextMarks.of((from, to) => to <= a || from >= b),
        });
    };
    const clearAllMarks = (view: EditorView) => {
        view.dispatch({
            effects: [gutterMarkerEffect.of({ pos: 0, clearAll: true }), filterTextMarks.of((_, __) => false)],
        });
    };
    /**
     * This method will find the start of the maven qualifier or nuget tag.
     * @param {string} line The text to inspect
     * @returns {number} The start of the qualifier or tag
     */
    const getQualifierSeparator = (line: string): number => {
        const result = /(?:(?:v|V)?(?:\d+[.-]?)*\d+)(?=[^0-9]|$)/.exec(line);
        if (result === null) {
            /*
             If there was no match, we have an invalid semver, or a maven
             version that is considered to be all qualifier. In this case
             we return 0.
            */
            return 0;
        }
        /*
            If we found no qualifier or tag, return -1. Otherwise return
            the index of the start of the qualifier or tag.
         */
        return result[0].length === line.length ? -1 : result[0].length;
    };
    const showResults = (getResult: (version: string) => VersionRuleTestResponse) => {
        if (!codeMirrorRef?.view) {
            return;
        }
        clearAllMarks(codeMirrorRef.view);
        const doc = codeMirrorRef.view.state.doc;
        for (let n = 1; n <= doc.lines; n++) {
            const line = doc.line(n);
            if (line.text.length === 0) {
                continue;
            }
            const ruleResult = getResult(line.text);
            if (ruleResult?.Errors.length > 0) {
                codeMirrorRef.view.dispatch({
                    effects: [
                        gutterMarkerEffect.of({
                            pos: line.from,
                            alert: styles.badRuleIcon,
                            icon: "exclamation-triangle",
                            tooltip: "Not a valid version",
                        }),
                        addTextMarks.of([badRuleTextMark.range(line.from, line.to)]),
                    ],
                });
                continue;
            }
            if (ruleResult.SatisfiesPreReleaseTag && ruleResult.SatisfiesVersionRange) {
                codeMirrorRef.view.dispatch({
                    effects: [
                        gutterMarkerEffect.of({
                            pos: line.from,
                            alert: styles.validRuleIcon,
                            icon: "check",
                            tooltip: "Version will trigger this rule",
                        }),
                        addTextMarks.of([validRuleTextMark.range(line.from, line.to)]),
                    ],
                });
            }
            else if (ruleResult.SatisfiesPreReleaseTag && !ruleResult.SatisfiesVersionRange) {
                const firstDash = getQualifierSeparator(line.text);
                if (firstDash === -1) {
                    codeMirrorRef.view.dispatch({
                        effects: [
                            gutterMarkerEffect.of({
                                pos: line.from,
                                alert: styles.invalidRuleIcon,
                                icon: "times",
                                tooltip: "Version not compatible with this rule",
                            }),
                            addTextMarks.of([invalidRuleTextMark.range(line.from, line.to)]),
                        ],
                    });
                    continue;
                }
                codeMirrorRef.view.dispatch({
                    effects: [
                        gutterMarkerEffect.of({
                            pos: line.from,
                            alert: styles.invalidRuleIcon,
                            icon: "times",
                            tooltip: "Pre-release tag is compatible but the version is not",
                        }),
                        addTextMarks.of([invalidRuleTextMark.range(line.from, line.from + firstDash)]),
                        addTextMarks.of([validRuleTextMark.range(line.from + firstDash, line.to)]),
                    ],
                });
            }
            else if (!ruleResult.SatisfiesPreReleaseTag && ruleResult.SatisfiesVersionRange) {
                const firstDash = getQualifierSeparator(line.text);
                if (firstDash === -1) {
                    codeMirrorRef.view.dispatch({
                        effects: [
                            gutterMarkerEffect.of({
                                pos: line.from,
                                alert: styles.invalidRuleIcon,
                                icon: "times",
                                tooltip: "Pre-release tag is not compatible with this rule",
                            }),
                            addTextMarks.of([validRuleTextMark.range(line.from, line.to)]),
                        ],
                    });
                    continue;
                }
                codeMirrorRef.view.dispatch({
                    effects: [
                        gutterMarkerEffect.of({
                            pos: line.from,
                            alert: styles.invalidRuleIcon,
                            icon: "times",
                            tooltip: "Pre-release tag is not compatible with this rule",
                        }),
                        addTextMarks.of([validRuleTextMark.range(line.from, line.from + firstDash)]),
                        addTextMarks.of([invalidRuleTextMark.range(line.from + firstDash, line.to)]),
                    ],
                });
            }
            else {
                codeMirrorRef.view.dispatch({
                    effects: [
                        gutterMarkerEffect.of({
                            pos: line.from,
                            alert: styles.invalidRuleIcon,
                            icon: "times",
                            tooltip: "Version not compatible with this rule",
                        }),
                        addTextMarks.of([invalidRuleTextMark.range(line.from, line.to)]),
                    ],
                });
            }
        }
    };
    return (<ReactCodeMirror ref={(ref: any) => {
            codeMirrorRef = ref;
        }} className={cn(rulesTesterCodeMirrorStyles)} theme={theme === "dark" ? githubDark : githubLight} value={props.value} onChange={(text: any, viewUpdate) => props.onChange(text)} basicSetup={{ lineNumbers: false }} extensions={[EditorView.lineWrapping, gutterMarkExtension, textMarkField, wordHover]}/>);
});
