/* eslint-disable @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-non-null-assertion */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { ActionTemplateParameterResource } from "@octopusdeploy/octopus-server-client";
import { ControlType, PackageSelectionMode } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useOptionalProcessBlueprintContext } from "~/areas/projects/components/Process/Blueprints/BlueprintContext";
import BaseComponent from "~/components/BaseComponent";
import { Note } from "~/components/form";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import type { PackageSelectorProps } from "./PackageSelector";
import PackageSelector from "./PackageSelector";
interface DeferredPackageSelectorProps extends PackageSelectorProps {
    packageSelectionMode: PackageSelectionMode;
    packageSelectionModeError?: string;
    parameters?: ActionTemplateParameterResource[];
    packageParameterName: string;
    packageParameterError?: string;
    onPackageSelectionModeChange(value: PackageSelectionMode): void;
    onPackageParameterChange(value: string): void;
}
export default class DeferredPackageSelector extends BaseComponent<DeferredPackageSelectorProps> {
    constructor(props: DeferredPackageSelectorProps) {
        super(props);
    }
    render() {
        return <Selector {...this.props}/>;
    }
    static displayName = "DeferredPackageSelector";
}
function Selector(props: DeferredPackageSelectorProps) {
    const blueprintContext = useOptionalProcessBlueprintContext();
    if (props.projectId && !blueprintContext?.blueprint) {
        return <PackageSelector {...props}/>;
    }
    const packageSelectionMode = props.packageSelectionMode || PackageSelectionMode.Immediate;
    const onChange = (value: PackageSelectionMode) => props.onPackageSelectionModeChange(value);
    const blueprintParameters = blueprintContext?.blueprint.Parameters || [];
    return (<RadioButtonGroup value={packageSelectionMode} error={props.packageSelectionModeError} onChange={onChange}>
            <RadioButton value={PackageSelectionMode.Immediate} label="Select specific package"/>
            {packageSelectionMode === PackageSelectionMode.Immediate && <PackageSelector {...props}/>}
            <RadioButton value={PackageSelectionMode.Deferred} label="Let the project select the package"/>
            {packageSelectionMode === PackageSelectionMode.Deferred && <PackageParameterSelector {...{ ...props, blueprintParameters }}/>}
        </RadioButtonGroup>);
}
interface PackageParameterSelectorProps extends DeferredPackageSelectorProps {
    blueprintParameters: ActionTemplateParameterResource[];
}
function PackageParameterSelector(props: PackageParameterSelectorProps) {
    const parameters = (props.parameters || []).concat(props.blueprintParameters);
    const parameterOptions = parameters.filter((p) => p.DisplaySettings["Octopus.ControlType"] === ControlType.Package)?.map((p: ActionTemplateParameterResource) => ({ value: p.Name, text: p.Label || p.Name })) || [];
    const variableLookup = { localNames: props.localNames };
    return (<div>
            <BoundSelect variableLookup={variableLookup} value={props.packageParameterName} allowClear={true} resetValue={""} onChange={(value) => props.onPackageParameterChange(value!)} items={parameterOptions} error={props.packageParameterError} autoFocus={props.packageSelectionMode === PackageSelectionMode.Deferred} label="Package parameter" empty={parameterOptions?.length === 0 ? "No package parameters found" : undefined} hideBindButton={true}/>
            <Note>When using this step template in a process, the user will be prompted to select a package.</Note>
        </div>);
}
