import React from "react";
import type { Deferred } from "~/components/Dialog/PromiseConfirmationDialog";
import PromiseConfirmationDialog from "~/components/Dialog/PromiseConfirmationDialog";
export const ConflictConfirmationDialog: React.FC = () => {
    const [state, setState] = React.useState<{
        confirmReadPromise?: Deferred<boolean>;
    }>();
    return state && state.confirmReadPromise ? (<PromiseConfirmationDialog title="Conflict" continueButtonLabel="Restore the step" deferred={state.confirmReadPromise}>
            Someone else has deleted this step from the deployment process. Would you like to add the step back?
        </PromiseConfirmationDialog>) : null;
};
ConflictConfirmationDialog.displayName = "ConflictConfirmationDialog"
