import type { CronTriggerScheduleResource, ServerTimezoneResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { ExpandableFormSection, Note, Text } from "~/components/form";
import ScheduleTimezone from "./ScheduleTimezone";
import getCronExpressionSummary from "./getCronExpressionSummary";
interface CronExpressionScheduledTriggerEditorProps {
    schedule: CronTriggerScheduleResource;
    timezones: ServerTimezoneResource[];
    onScheduleChange(schedule: CronTriggerScheduleResource): void;
}
export class CronExpressionScheduledTriggerEditor extends React.Component<CronExpressionScheduledTriggerEditorProps, {}> {
    render() {
        const scheduleCronExpression = this.props.schedule.CronExpression;
        const scheduleTimezone = this.props.schedule.Timezone;
        return (<div>
                <ExpandableFormSection errorKey="ScheduleCronExpression" title="Cron Expression" summary={getCronExpressionSummary(scheduleCronExpression)} help="Enter the cron expression to configure when the trigger should execute">
                    <Text label="Cron expression" value={scheduleCronExpression} onChange={this.onScheduleCronExpressionChange}/>
                    <Note>{getCronExpressionSummary(scheduleCronExpression).node}</Note>
                    <Note>
                        For information about cron expressions, see our docs:
                        <ExternalLink href="CronExpressions"> Constructing a cron expression in Octopus</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ScheduleTimezone timezone={scheduleTimezone} timezones={this.props.timezones} onTimezoneChanged={this.onScheduleTimezoneChange}/>
            </div>);
    }
    onScheduleCronExpressionChange = (cronExpression: string) => {
        this.props.onScheduleChange({
            ...this.props.schedule,
            CronExpression: cronExpression,
        });
    };
    onScheduleTimezoneChange = (timezone: string) => {
        this.props.onScheduleChange({
            ...this.props.schedule,
            Timezone: timezone,
        });
    };
    static displayName = "CronExpressionScheduledTriggerEditor";
}
