import { ActionButton, Callout } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import ActionList from "~/components/ActionList";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import PermissionCheck from "~/components/PermissionCheck/PermissionCheck";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import Note from "~/primitiveComponents/form/Note/Note";
import DateFormatter from "~/utils/DateFormatter/DateFormatter";
import { semibold } from "../../../../fontWeights";
import styles from "./style.module.less";
interface BuiltInRepositoryIndexingProps {
    totalPackages: number;
    lastSyncStatus?: string;
    lastSyncId?: string;
    lastSyncCompletedTime?: string;
    isBuiltInRepoSyncEnabled: boolean;
    busy: Promise<void>;
    onReindexClick(): Promise<boolean>;
    setIsBuiltInRepoSyncEnabled(value: boolean): Promise<boolean>;
}
class BuiltInRepositoryIndexing extends BaseComponent<BuiltInRepositoryIndexingProps, DataBaseComponentState> {
    constructor(props: BuiltInRepositoryIndexingProps) {
        super(props);
        this.state = {};
    }
    toggleReindexEnabled() {
        return this.props.setIsBuiltInRepoSyncEnabled(!this.props.isBuiltInRepoSyncEnabled);
    }
    onReindexClick() {
        return this.props.onReindexClick();
    }
    getLastSyncStatus(theme: OctopusTheme): JSX.Element {
        const statusText = this.props.lastSyncStatus || "Unknown";
        const statusLink = this.props.lastSyncId ? (<InternalLink to={links.taskPage.generateUrl({ taskId: this.props.lastSyncId })} weight={semibold}>
                {this.props.lastSyncCompletedTime ? DateFormatter.momentAgo(this.props.lastSyncCompletedTime) : statusText}
            </InternalLink>) : (statusText);
        let statusTextHighlightElement: JSX.Element | null = null;
        if (this.props.lastSyncStatus === "Succeeded") {
            statusTextHighlightElement = (<span className={styles.taskSucceeded}>
                    <em className="fa-solid fa-check" style={{ color: theme.success }}/> Last re-index: <strong>{statusText} </strong>
                </span>);
        }
        else if (this.props.lastSyncStatus === "Failed") {
            statusTextHighlightElement = (<span className={styles.taskFailed}>
                    <em className="fa-solid fa-exclamation-triangle" style={{ color: theme.danger }}/> Last re-index: <strong>{statusText}</strong>
                </span>);
        }
        else {
            statusTextHighlightElement = (<span>
                    Last re-index: <strong>{statusText}</strong>
                </span>);
        }
        return (<Note style={{ margin: "1rem 0" }}>
                <div>{statusTextHighlightElement}</div>
                <div style={{ paddingTop: "0.5rem" }}>{statusLink}</div>
            </Note>);
    }
    render() {
        const message = this.props.isBuiltInRepoSyncEnabled ? (<span>
                The built-in package repository <strong>will</strong> be re-indexed at startup.
            </span>) : (<span>
                The built-in package repository <strong>will not</strong> be re-indexed at startup.
            </span>);
        return withTheme((theme) => (<div>
                <h4>Package Indexing</h4>
                <p>
                    Number of packages: <strong>{this.props.totalPackages}</strong>
                </p>
                {this.getLastSyncStatus(theme)}
                <p>{message}</p>
                <PermissionCheck permission={Permission.ConfigureServer} alternate={<Callout type={"information"} title={"Permission required"}>
                            The {Permission.ConfigureServer} permission is required to change the repository indexing status
                        </Callout>}>
                    <div className={styles.actionButtonGroup}>
                        <ActionList actions={[
                <ActionButton label={this.props.isBuiltInRepoSyncEnabled ? "Disable" : "Enable"} busyLabel={this.props.isBuiltInRepoSyncEnabled ? "Disabling..." : "Enabling..."} onClick={() => this.toggleReindexEnabled()} disabled={this.state.busy}/>,
                <ActionButton label="Re-index now" onClick={() => this.onReindexClick()} busyLabel="Queuing task..." disabled={this.state.busy}/>,
            ]} alignStart={true}/>
                    </div>
                </PermissionCheck>
                <Note>
                    Octopus can automatically re-index the built-in repository at startup to ensure that it is in sync. <ExternalLink href="ReIndexBuiltInRepository">Learn more.</ExternalLink>
                </Note>
            </div>));
    }
    static displayName = "BuiltInRepositoryIndexing";
}
export default BuiltInRepositoryIndexing;
