import moment from "moment/moment";
export function formatTimeDifference(fromDate: moment.Moment | string | undefined | null, toDate: moment.Moment | string | undefined | null, diffInSeconds?: number) {
    if (!fromDate && !diffInSeconds) {
        return null;
    }
    let diff = 0;
    if (diffInSeconds) {
        diff = diffInSeconds;
    }
    else {
        const fromMoment = moment(fromDate);
        const toMoment = toDate ? moment(toDate) : moment();
        diff = fromMoment.diff(toMoment, "s");
    }
    const duration = moment.duration(diff, "s");
    const mins = duration.minutes();
    const hours = duration.hours();
    const days = Math.floor(duration.asDays());
    let result = mins === 1 ? "1 minute" : `${mins} minutes`;
    result = `${hours === 0 ? "" : `${hours === 1 ? "1 hour" : `${hours} hours`} and `}${result}`;
    result = `${days === 0 ? "" : `${days === 1 ? "1 day" : `${days} days`}, `}${result}`;
    return result;
}
