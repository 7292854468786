/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ProjectResource, RunbooksDashboardItemResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import DateFormatter from "~/utils/DateFormatter";
import { TaskStatusDetails } from "./TaskStatusDetails";
interface RunbookRunTaskStatusDetailsProps {
    project: ProjectResource;
    run: RunbooksDashboardItemResource;
    noPadding?: boolean;
}
export function RunbookRunTaskStatusDetails({ project, run, noPadding }: RunbookRunTaskStatusDetailsProps) {
    const startTime = run.StartTime || run.QueueTime;
    const date = DateFormatter.dateToCustomFormat(startTime, "MMMM Do YYYY h:mm A") ?? undefined;
    const link = links.projectRunbookRunDetailPage.generateUrl({
        spaceId: project.SpaceId,
        projectSlug: project.Slug,
        runbookId: run.RunbookId,
        runbookSnapshotId: run.RunbookSnapshotId,
        runbookRunId: run.Id,
    });
    return <TaskStatusDetails task={run} title={run.RunName} subtitle={date} link={link} noPadding={noPadding}/>;
}
