export function truncateActionNotes(originalNotes: string | null, numOfLinesToShow: number): [
    string | null,
    boolean
] {
    const lines = originalNotes ? originalNotes.split(/\r\n|\r|\n/, numOfLinesToShow + 1) : [];
    if (lines.length > numOfLinesToShow) {
        const truncated = lines.slice(0, numOfLinesToShow);
        return [truncated.join("\n"), true];
    }
    return [originalNotes, false];
}
