import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { Navigation } from "~/components/NavigationSidebarLayout/index";
import { PortalPageHeaderPrimary } from "~/components/PageHeaderPrimary/index";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
interface BlueprintsLayoutProps {
    spaceId: string;
}
export function BlueprintsLayout({ spaceId, children }: PropsWithChildren<BlueprintsLayoutProps>) {
    const navItems = compact([Navigation.navItem("Blueprints", links.blueprintsPage.generateUrl({ spaceId: spaceId }), true), Navigation.navItem("Repositories", links.blueprintsRepositoriesPage.generateUrl({ spaceId: spaceId }), true)]);
    return (<main>
            <PortalPageLayout navItems={navItems} content={children} header={<PortalPageHeaderPrimary title="Blueprints"/>} enablePageLayoutVNext={true}/>
        </main>);
}
