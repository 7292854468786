import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import { GitHubAccountType } from "@octopusdeploy/octopus-server-client";
import type { GitHubAppInstallation } from "@octopusdeploy/octopus-server-client";
import React from "react";
interface GitHubInstallationLogoProps {
    installation?: GitHubAppInstallation;
    small?: boolean;
}
export function GitHubInstallationLogo({ installation, small }: GitHubInstallationLogoProps) {
    if (!installation) {
        return null;
    }
    return <GitHubAvatar small={small} type={installation.AccountType} avatarUrl={installation.AccountAvatarUrl} alt={`${installation.AccountLogin} logo`}/>;
}
export interface GitHubAvatarProps {
    small?: boolean;
    type: GitHubAccountType;
    avatarUrl: string;
    alt?: string;
}
export function GitHubAvatar({ small, type, avatarUrl, alt }: GitHubAvatarProps) {
    const size = small ? space[24] : space[48];
    const radius = type == GitHubAccountType.Organization ? "25%" : "50%";
    const styles = {
        avatar: css({
            display: "block",
            width: size,
            borderRadius: radius,
        }),
    };
    return <img aria-hidden className={styles.avatar} src={avatarUrl} alt={alt}/>;
}
