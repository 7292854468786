import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { repository } from "~/clientInstance";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { Note } from "~/components/form";
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
const video = {
    youtubeId: "UtoWLWTjIE8",
    shortlink: "OnboardingVersionControlledProjectVideo",
};
export const CommonProjectGitView = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spaceId = repository.spaceId!;
    const styles = {
        helpContent: css({
            "& ol": {
                listStyleType: "decimal",
                paddingLeft: space[24],
            },
        }),
    };
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>Config as Code lets you store your project configuration in version control.</p>
                <ExternalLink href="ConfigAsCode">Config as code</ExternalLink> allows you to store your project configuration in version control.
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Connect with GitHub"}>
                    <div className={styles.helpContent}>
                        <p>Seamlessly and securely connect your GitHub repositories using the Octopus GitHub App. This avoids the need to use and rotate personal access tokens.</p>
                        <br />
                        <p>Connect to GitHub using the Octopus GitHub App:</p>
                        <ol>
                            <li>
                                Choose <strong>GitHub</strong>, then <strong>CONNECT TO GITHUB</strong>.
                            </li>
                            <li>
                                On the <InternalLink to={links.gitConnectionsPage.generateUrl({ spaceId })}>GitHub Connections</InternalLink> page, follow the prompts to install the Octopus Deploy GitHub App.
                            </li>
                            <li>
                                When you return to this page, click the refresh icon next to <strong>CONNECT TO GITHUB</strong>.
                            </li>
                            <li>
                                Under <strong>GitHub Repository</strong>, select your GitHub accounts and repositories from the dropdown.
                            </li>
                            <li>
                                Click <strong>CONFIGURE...</strong> in the top right corner, then <strong>COMMIT</strong>.
                            </li>
                        </ol>
                    </div>
                </Note>
                <Note heading={"Other Git Providers"}>
                    <p>To connect using Git Credentials, choose Other Git Provider.</p>
                </Note>
            </TermsText>
        </OverviewContainer>);
};
