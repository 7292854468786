import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";
import { DefaultTopics } from "../../CommonComponents/DefaultViews";
export const ProjectTasksRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().tasks,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (<OverviewContainer>
                <OverviewHighlightText>
                    <p>
                        The <b>tasks</b> section is where you can check on the status and history of the background tasks <b>associated with this project</b> that your Octopus Server is managing.
                    </p>
                </OverviewHighlightText>
            </OverviewContainer>);
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <DefaultTopics />;
    },
};
