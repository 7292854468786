import { css } from "@emotion/css";
import { Divider, Tooltip } from "@octopusdeploy/design-system-components";
import type { FormFieldProps } from "@octopusdeploy/design-system-components/src/components/Form/FormFieldProps";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitRefResource, ProjectResource, ChannelGitResourceRuleResource, ReferenceDataItem, DeploymentActionGitDependencyResource, ValidateGitRefV2Response, OctopusError } from "@octopusdeploy/octopus-server-client";
import { getGitRefDescription, isGitCommit, isGitTag } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import React from "react";
import { ChipIcon, DeploymentActionGitDependencyChip, MissingChip } from "~/components/Chips";
import Lookup from "~/components/Lookup";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { SummaryNode } from "~/components/form";
import { Note, Summary } from "~/components/form";
import { GitProtectionRules } from "./GitProtectionRules";
import { getGitReferencePatternDescription } from "./getGitReferenceRulePatternsDescription";
export function gitDependencyChipDisplayName(gitDependency: DeploymentActionGitDependencyDetails) {
    const deploymentActionName = gitDependency.DeploymentActionName ?? gitDependency.DeploymentActionSlug;
    return !!gitDependency.GitDependencyName ? `${deploymentActionName}/${gitDependency.GitDependencyName}` : deploymentActionName;
}
export function gitDependencyChipTooltipContent(gitDependency: DeploymentActionGitDependencyDetails) {
    const deploymentActionName = gitDependency.DeploymentActionName ?? gitDependency.DeploymentActionSlug;
    return !!gitDependency.GitDependencyName
        ? `Git resource '${gitDependency.GitDependencyName}' from step '${deploymentActionName}' using repository ${gitDependency.RepositoryUri}`
        : `Step '${deploymentActionName}' using repository ${gitDependency.RepositoryUri}`;
}
export function getGitResourceRuleSummary(rule: ChannelGitResourceRuleResource, actions: DeploymentActionGitDependencyDetails[]): SummaryNode {
    if (rule.GitDependencyActions.length === 0) {
        return Summary.summary("No steps selected");
    }
    const gitResourceRulePatternsDescription = getGitReferencePatternDescription(rule.Rules);
    if (gitResourceRulePatternsDescription === undefined) {
        return Summary.summary("No Git protection rules defined");
    }
    const gitResourceRuleAppliesTo = (<>
            Applies to{" "}
            {rule.GitDependencyActions.map((gda) => {
            const gitDependencyDetails = actions.find((a) => a.DeploymentActionSlug === gda.DeploymentActionSlug && a.GitDependencyName === gda.GitDependencyName);
            if (!gitDependencyDetails)
                return gda.DeploymentActionSlug;
            return (<Tooltip key={`${gitDependencyDetails.DeploymentActionName ?? gitDependencyDetails.DeploymentActionSlug}/${gitDependencyDetails.GitDependencyName}`} content={gitDependencyChipTooltipContent(gitDependencyDetails)}>
                        <strong>{gitDependencyChipDisplayName(gitDependencyDetails)}</strong>
                    </Tooltip>);
        })}{" "}
            {rule.GitDependencyActions.length > 1 ? "steps" : "step"}
        </>);
    return Summary.summary(<>
            {gitResourceRuleAppliesTo} with {gitResourceRulePatternsDescription}
        </>);
}
export type DeploymentActionGitDependencyDetails = {
    DeploymentActionSlug: string;
    DeploymentActionName: string;
    RepositoryUri: string;
    GitDependencyName: string;
};
interface DeploymentActionGitDependencyReferenceDataItem extends ReferenceDataItem {
    ActionDetails?: DeploymentActionGitDependencyDetails;
    GitDependency: DeploymentActionGitDependencyResource;
}
type GitResourceRuleProps = {
    index: number;
    project: ProjectResource;
    gitRef?: GitRefResource;
    gitRefValidationError?: ValidateGitRefV2Response;
    allDeploymentActionsWithGitDependencies: DeploymentActionGitDependencyDetails[];
    deploymentActionsError?: OctopusError;
    availableDeploymentActionsWithGitDependencies: DeploymentActionGitDependencyDetails[];
    rule: ChannelGitResourceRuleResource;
    onRuleChanged: (rule: ChannelGitResourceRuleResource) => void;
};
interface DeploymentActionGitDependencyMultiSelectProps extends FormFieldProps<string[]> {
    project: ProjectResource;
    gitRef?: GitRefResource;
    items: DeploymentActionGitDependencyReferenceDataItem[];
    customLabel?: string;
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}
const DeploymentActionGitDependencyTypedMultiSelect = MultiSelect<DeploymentActionGitDependencyReferenceDataItem>();
const DeploymentActionGitDependencyMultiSelect: React.FC<DeploymentActionGitDependencyMultiSelectProps> = (props) => {
    const chipRenderer = (r: DeploymentActionGitDependencyReferenceDataItem | SelectItem, onRequestDelete: () => void) => {
        const missingStepDescription = props.project.IsVersionControlled
            ? `Step '${r.Id}' does not exist in the deployment process on the '${props.gitRef?.Name}' ${getGitRefDescription(props.gitRef?.CanonicalName)}.`
            : `Step '${r.Id}' does not exist in the deployment process.`;
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element: DeploymentActionGitDependencyReferenceDataItem) => element.Id} render={(item: DeploymentActionGitDependencyReferenceDataItem) => {
                if (item.ActionDetails)
                    return <DeploymentActionGitDependencyChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} actionGitDependency={item.ActionDetails}/>;
                return <MissingChip lookupId={r.Id} description={missingStepDescription} text={r.Id} type={ChipIcon.StepAction} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>;
            }} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.StepAction} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return (<DeploymentActionGitDependencyTypedMultiSelect accessibleName="gitresourcesteps" fieldName="git resource steps" renderChip={chipRenderer} renderItem={(item) => {
            const name = item.ActionDetails?.DeploymentActionName ?? item.Name;
            return {
                primaryText: item.GitDependency.GitDependencyName !== "" ? `${name}/${item.GitDependency.GitDependencyName}` : name,
                secondaryText: item.ActionDetails?.RepositoryUri,
            };
        }} {...props}/>);
};
DeploymentActionGitDependencyMultiSelect.displayName = "DeploymentActionGitDependencyMultiSelect"
export function GitResourceRule({ index, project, gitRef, gitRefValidationError, allDeploymentActionsWithGitDependencies, deploymentActionsError, availableDeploymentActionsWithGitDependencies, rule, onRuleChanged }: GitResourceRuleProps) {
    const handleGitProtectionPatternChanged = (patterns: string[]) => {
        if (isEqual(rule.Rules, patterns))
            return;
        onRuleChanged({
            ...rule,
            Rules: patterns,
        });
    };
    const availableDeploymentActionReferenceItems = availableDeploymentActionsWithGitDependencies.map<DeploymentActionGitDependencyReferenceDataItem>((action) => {
        return {
            Id: action.GitDependencyName !== "" ? `${action.DeploymentActionSlug}/${action.GitDependencyName}` : action.DeploymentActionSlug,
            Name: action.DeploymentActionName,
            ActionDetails: action,
            GitDependency: {
                DeploymentActionSlug: action.DeploymentActionSlug,
                GitDependencyName: action.GitDependencyName,
            },
        };
    });
    const selectedDeploymentActionReferenceItems = rule.GitDependencyActions.map<DeploymentActionGitDependencyReferenceDataItem>((gitDependencyAction) => {
        const deploymentAction = allDeploymentActionsWithGitDependencies.find((a) => a.DeploymentActionSlug === gitDependencyAction.DeploymentActionSlug && a.GitDependencyName === gitDependencyAction.GitDependencyName);
        return {
            Id: gitDependencyAction.GitDependencyName !== "" ? `${gitDependencyAction.DeploymentActionSlug}/${gitDependencyAction.GitDependencyName}` : gitDependencyAction.DeploymentActionSlug,
            Name: deploymentAction?.DeploymentActionName ?? gitDependencyAction.DeploymentActionSlug,
            ActionDetails: deploymentAction,
            GitDependency: gitDependencyAction,
        };
    });
    const allDeploymentActionReferenceItems = [...availableDeploymentActionReferenceItems, ...selectedDeploymentActionReferenceItems];
    const versionControlRefHelpText = () => {
        const gitRefCanonical = gitRef?.CanonicalName;
        let gitRefType = "branch";
        if (isGitCommit(gitRefCanonical)) {
            gitRefType = "commit";
        }
        else if (isGitTag(gitRefCanonical)) {
            gitRefType = "tag";
        }
        return (<Note>
                Showing steps from the <code>{gitRef?.Name}</code> {gitRefType}. A different {gitRefType} can be selected from the deployment process screen.
            </Note>);
    };
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            gap: space[8],
        }),
    };
    return (<div data-testid={`GitResourceRule-${index}`} className={styles.container}>
            <div>
                <DeploymentActionGitDependencyMultiSelect project={project} gitRef={gitRef} items={allDeploymentActionReferenceItems} value={selectedDeploymentActionReferenceItems.map((x) => x.Id)} label="External repository step(s)" onChange={(indexes) => {
            const selectedActionGitDependencies = allDeploymentActionReferenceItems.filter((action, _) => indexes.includes(action.Id)).map<DeploymentActionGitDependencyResource>((action) => action.GitDependency);
            onRuleChanged({
                ...rule,
                GitDependencyActions: selectedActionGitDependencies,
            });
        }} openOnFocus={false}/>
                {project.IsVersionControlled && !gitRefValidationError && !deploymentActionsError && (<>
                        {versionControlRefHelpText()}
                        <Divider />
                    </>)}
            </div>
            <GitProtectionRules patterns={rule.Rules} onPatternsChanged={handleGitProtectionPatternChanged}/>
        </div>);
}
