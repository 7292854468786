/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import React, { useState } from "react";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs/index";
export enum LogoTypeSelection {
    NotSet = "NotSet",
    Icon = "Icon"
}
interface BlueprintIconEditLayoutProps {
    iconEditor: React.ReactElement;
    onTabChange: (logoType: LogoTypeSelection) => void;
}
const BlueprintIconEditLayout = ({ iconEditor, onTabChange }: BlueprintIconEditLayoutProps) => {
    const [selectedTab, setSelectedTab] = useState<string>(LogoTypeSelection.Icon);
    const onTabSelect = (tab: string) => {
        setSelectedTab(tab);
        const selectedLogoType: LogoTypeSelection = LogoTypeSelection[tab as keyof typeof LogoTypeSelection];
        onTabChange(selectedLogoType);
    };
    return (<div className={styles.content}>
            <ControlledTabsContainer value={selectedTab} onChange={(tab) => onTabSelect(tab)}>
                <TabItem label="Icon" value={LogoTypeSelection.Icon} key={LogoTypeSelection.Icon}>
                    <TransitionAnimation> {iconEditor} </TransitionAnimation>{" "}
                </TabItem>
            </ControlledTabsContainer>
        </div>);
};
export default BlueprintIconEditLayout;
const styles = {
    content: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "visible",
        width: "375px",
        "@media (max-width: @screen-sm)": {
            width: "100%",
            gridAutoRows: "auto",
        },
    }),
};
