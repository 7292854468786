import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { DynamicExtensionsFeatureMetadata, DynamicExtensionsFeaturesMetadataResource, DynamicExtensionsFeaturesValuesResource } from "@octopusdeploy/octopus-server-client";
import isNil from "lodash/isNil";
import React from "react";
import Markdown from "~/components/Markdown";
import { ExpandableFormSection, Note, Summary } from "~/components/form";
type DynamicExtensionsFeatureProps = {
    dynamicFeaturesMetadata: DynamicExtensionsFeaturesMetadataResource;
    dynamicFeaturesValues: DynamicExtensionsFeaturesValuesResource;
    onChange: (key: string, value: string) => void;
};
export function DynamicExtensionsFeature({ dynamicFeaturesMetadata, dynamicFeaturesValues, onChange }: DynamicExtensionsFeatureProps) {
    return (<>
            {dynamicFeaturesMetadata.Features.map((metadata) => (<ExpandableFormSection key={metadata.Key} errorKey={`DynamicExtensions_${metadata.Key}`} title={metadata.Name} summary={Summary.summary(getDynamicFeatureOptionName(metadata, dynamicFeaturesValues))}>
                    <RadioButtonGroup value={getDynamicFeaturesValue(metadata, dynamicFeaturesValues)} onChange={(value) => onChange(metadata.Key, value)}>
                        {Object.entries(metadata.Options).map(([key, value], i) => (<RadioButton key={key} value={key} label={value}/>))}
                    </RadioButtonGroup>
                    <Note style={{ marginTop: "1rem" }}>
                        <Markdown markup={metadata.Description}/>
                    </Note>
                </ExpandableFormSection>))}
        </>);
}
function getDynamicFeatureOptionName(metadata: DynamicExtensionsFeatureMetadata, values: DynamicExtensionsFeaturesValuesResource): string {
    const optionKey = getDynamicFeaturesValue(metadata, values);
    const optionName = metadata.Options[optionKey];
    return optionName;
}
function getDynamicFeaturesValue(metadata: DynamicExtensionsFeatureMetadata, values: DynamicExtensionsFeaturesValuesResource): string {
    const key = values.Values[metadata.Key];
    return isNil(key) ? metadata.Default : key;
}
