import { Callout } from "@octopusdeploy/design-system-components";
import type { TaskSummaryResource } from "@octopusdeploy/octopus-server-client/dist/src/resources/taskSummaryResource";
import { useState } from "react";
import * as React from "react";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
interface CancelTaskDialogProps {
    task: TaskSummaryResource;
    cancelTask: (t: TaskSummaryResource) => Promise<void>;
}
export default function CancelTaskDialog({ task, cancelTask }: CancelTaskDialogProps) {
    // Keep initially given task in state unchanged to make sure that we are cancelling task user has selected.
    // Because task list is refreshed "in the background", it is possible that initially selected task and
    // the task when user clicks "Yes" are different.
    const [selectedTask] = useState(task);
    const cancel = async () => {
        await cancelTask(selectedTask);
        return true;
    };
    return (<DeleteDialogLayout title="Are you sure you want to cancel this task?" onDeleteClick={() => cancel()} deleteButtonLabel="Yes" deleteButtonBusyLabel="Cancelling..." cancelButtonLabel="No" renderContent={() => (<>
                    <Callout title={selectedTask.Description} type={"warning"}/>
                    <p>Cancelling this task is permanent. There is no going back.</p>
                    <p>Do you wish to continue?</p>
                </>)}/>);
}
