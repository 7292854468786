import * as React from "react";
import ExternalLink from "../../../../../../../components/Navigation/ExternalLink";
import { buildServerPackagingInstruction } from "../../Instructions";
import { CommonBuildServerPackagingTextForOctoExe, CommonBuildServerPackagingTextForIntegration } from "../CommonRegistrationText";
export const BuildServerPackaging: React.SFC<{}> = () => (<div>
        <p>
            When packaging .NET Core applications from build servers, we recommend using <code>dotnet publish</code> on the project followed by <code>octo.exe pack</code> on the output directory.
        </p>
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
        <p>
            The <ExternalLink href="GuidesASPNetCore">ASP.NET Core guides</ExternalLink> provide step by step instructions for deploying ASP.NET Core applications.
        </p>
    </div>);
BuildServerPackaging.displayName = "BuildServerPackaging"
export default buildServerPackagingInstruction(() => <BuildServerPackaging />);
