import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonProjectTenantsOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="Tenants">Tenants</ExternalLink> allow you to easily create customer specific deployment pipelines without duplicating project configuration.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonProjectTenantsTopics = () => {
    return <></>;
};
