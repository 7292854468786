/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { Text } from "~/components/form";
interface AddExternalRoleState extends DataBaseComponentState {
    roleId: string;
    displayName: string;
}
interface AddExternalRoleProps {
    onSelected(roleId: string, displayName: string): boolean;
}
class AddExternalRole extends DataBaseComponent<AddExternalRoleProps, AddExternalRoleState> {
    constructor(props: AddExternalRoleProps) {
        super(props);
        this.state = {
            roleId: null!,
            displayName: null!,
        };
    }
    render() {
        return (<OkDialogLayout title="Add External Group/Role" okButtonLabel="Add" busy={this.state.busy} errors={this.errors} onOkClick={() => this.props.onSelected(this.state.roleId, this.state.displayName)}>
                <Text label="Group/Role ID" value={this.state.roleId} onChange={(roleId) => this.setState({ roleId })}/>
                <Text label="Display Name" value={this.state.displayName} onChange={(displayName) => this.setState({ displayName })}/>
            </OkDialogLayout>);
    }
    static displayName = "AddExternalRole";
}
export default AddExternalRole;
