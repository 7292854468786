import type { Event as AnalyticsEvent } from "@amplitude/analytics-types";
import { OctopusRoutingProvider } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import { OctopusClientProvider } from "@octopusdeploy/octopus-react-client";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { UserPermissions, OctopusError, AuthenticationError } from "@octopusdeploy/octopus-server-client";
import type { AnalyticSession } from "@octopusdeploy/portal-analytics";
import { useAnalyticSession, AnalyticSessionProvider } from "@octopusdeploy/portal-analytics";
import { OctopusSessionProvider } from "@octopusdeploy/session";
import * as React from "react";
import { useState } from "react";
import { Switch } from "react-router-dom";
import { AmplitudeSession } from "~/analytics/AmplitudeSession";
import AuthenticationLayout from "~/areas/authentication/AuthenticationLayout";
import Register from "~/areas/authentication/Register";
import SignIn from "~/areas/authentication/SignIn";
import SignOut from "~/areas/authentication/SignOut";
import { client, repository, session } from "~/clientInstance";
import { AuthenticatedRoutes } from "~/components/AuthenticationRoutes/AuthenticatedRoutes";
import { LayoutWithDevTools } from "~/components/DevTools/LayoutWithDevTools";
import ReloadableRoute from "~/components/ReloadableRoute";
import SecureRoute from "~/components/SecureRoute";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import type { InAppMessaging } from "~/inAppMessaging/InAppMessaging";
import { PortalDesignSystemLink } from "../Navigation/PortalDesignSystemLink";
import { useIsPortalUrlActive } from "../Navigation/useIsPortalUrlActive";
import { authRoutes } from "./authRoutes";
type SessionStartEvent = (user: UserResource) => Promise<void>;
const securedSpaceOrSystemRoute = "/:spaceId(Spaces-[0-9]+)?";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AuthenticationRoutes(props: React.ComponentProps<any>) {
    const fallbackAnalyticSession = useAnalyticSession();
    const theme = useThemePaletteType();
    const [analyticsSession, setAnalyticsSession] = useState<AnalyticSession | undefined>(undefined);
    const [analyticsEvents, setAnalyticsEvents] = useState<AnalyticsEvent[]>([]);
    const [inAppMessagingSession, setInAppMessagingSession] = useState<InAppMessaging | undefined>(undefined);
    const onSessionStart: SessionStartEvent = async (user) => {
        try {
            const [featureConfigurations, featureToggles, installationHistory] = await Promise.all([repository.FeaturesConfiguration.get(), repository.ServerConfiguration.enabledFeatureToggles(), getInstallationHistory()]);
            await session.start(user, featureConfigurations, featureToggles, installationHistory);
        }
        catch (error) {
            throw getSessionStartAuthenticationError(error);
        }
        if (analyticsSession === undefined) {
            const serverInfo = client.tryGetServerInformation();
            if (serverInfo) {
                const session = await AmplitudeSession.create(serverInfo.installationId, user.Id, theme, (event) => setAnalyticsEvents((prev) => [...prev, event]));
                setAnalyticsSession(session);
                // session.track("Sign In", {});
            }
        }
        /*if (inAppMessagingSession === undefined) {
            const session = await InAppMessaging.create();
            setInAppMessagingSession(session);
        }
         */
    };
    const onSessionEnd = () => {
        analyticsSession?.track("Sign Out", {});
        analyticsSession?.end();
        setAnalyticsSession(undefined);
        inAppMessagingSession?.clear();
        setInAppMessagingSession(undefined);
    };
    async function getInstallationHistory() {
        try {
            return await repository.InstallationHistory.getInstallationHistory();
        }
        catch (error) {
            // Failing to fetch InstallationHistory should not prevent login - so this is performed pre-emptively.
            // Will result in the 'Release Notes' linking to an error page.
            logger.error("Failed to fetch InstallationHistory");
        }
        return [];
    }
    const palette = useThemePaletteType();
    const userPermissions = useCurrentUserPermissions();
    return (<OctopusRoutingProvider Link={PortalDesignSystemLink} useIsUrlActive={useIsPortalUrlActive}>
            <OctopusSessionProvider currentUser={session.currentUser} currentPermissions={userPermissions}>
                <OctopusClientProvider client={client}>
                    <AnalyticSessionProvider session={analyticsSession ?? fallbackAnalyticSession}>
                        <LayoutWithDevTools analyticsEvents={analyticsEvents}>
                            <Switch>
                                <ReloadableRoute path={authRoutes.signIn} render={(props) => (<AuthenticationLayout>
                                            <SignIn {...props} onSessionStarted={onSessionStart}/>
                                        </AuthenticationLayout>)}/>
                                <ReloadableRoute path={authRoutes.signOut} render={(props) => (<AuthenticationLayout>
                                            <SignOut {...props} onSessionEnded={onSessionEnd}/>
                                        </AuthenticationLayout>)}/>
                                <ReloadableRoute path={authRoutes.register(":inviteCode")} render={(props) => (<AuthenticationLayout>
                                            <Register {...props} onSessionStarted={onSessionStart}/>
                                        </AuthenticationLayout>)}/>
                                <SecureRoute path={securedSpaceOrSystemRoute} render={() => <AuthenticatedRoutes path={securedSpaceOrSystemRoute}/>} analyticsSession={analyticsSession}/>
                            </Switch>
                        </LayoutWithDevTools>
                    </AnalyticSessionProvider>
                </OctopusClientProvider>
            </OctopusSessionProvider>
        </OctopusRoutingProvider>);
}
function getSessionStartAuthenticationError(error: unknown): AuthenticationError {
    let reason = "There was an unknown error";
    if (error instanceof OctopusError) {
        reason = error.StatusCode === 401 ? "This can happen if the Octopus authentication cookie is blocked." : `There was a problem communicating with the Octopus Server: ${error.ErrorMessage}`;
    }
    else if (error instanceof Error) {
        reason = `There was an unknown error: ${error.message}`;
    }
    const message = "The sign in succeeded but we failed to get the resultant permissions for this user account. ";
    return new AuthenticationError(message + reason);
}
function useCurrentUserPermissions() {
    const [userPermissions, setUserPermissions] = useState(() => session.currentPermissions ?? UserPermissions.None);
    React.useEffect(() => session.subscribe(() => {
        setUserPermissions(session.currentPermissions ?? UserPermissions.None);
    }), []);
    return userPermissions;
}
