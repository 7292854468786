import * as React from "react";
import styles from "./style.module.less";
interface PagingButtonProps {
    label?: string;
    icon?: JSX.Element;
    isActive: boolean;
    onPageSelected?(event: React.MouseEvent): void;
    disabled?: boolean;
}
function PagingButton(props: PagingButtonProps) {
    const activeClass = props.isActive ? styles.pagingButtonActive : "";
    return (<button className={`${styles.pagingButton} ${activeClass}`} onClick={props.onPageSelected} title={props.label} disabled={props.disabled ?? false}>
            {props.label ? <span className={styles.pagingButtonText}>{props.label}</span> : props.icon}
        </button>);
}
export default PagingButton;
