import * as React from "react";
import SectionHeading from "~/primitiveComponents/dataDisplay/Section/SectionHeading";
interface DataTableSectionHeadingProps {
    title: string | JSX.Element;
}
class DataTableSectionHeading extends React.Component<DataTableSectionHeadingProps, {}> {
    render() {
        return <SectionHeading title={this.props.title}/>;
    }
    static displayName = "DataTableSectionHeading";
}
export default DataTableSectionHeading;
