import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import Note from "../../../primitiveComponents/form/Note/Note";
const AzureServiceFabricSdkRequiredInfo = (props: {}) => {
    return (<Callout type={"information"} title={"Service Fabric SDK install required"}>
            The <ExternalLink href="ServiceFabricSdkDownload">Service Fabric SDK</ExternalLink> must be installed on the <strong>Octopus Server</strong> for this step.{" "}
            <Note>
                {" "}
                Learn more about <ExternalLink href="ServiceFabricDeployingApps">Deploying to Service Fabric</ExternalLink>.
            </Note>
        </Callout>);
};
export default AzureServiceFabricSdkRequiredInfo;
