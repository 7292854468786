import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ActionTemplateParameterResource, PropertyValueResource, VariableType, WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ChangedValueIndicator from "~/areas/projects/components/Variables/TenantVariables/ChangedValueIndicator";
import DefaultValueIndicator from "~/areas/projects/components/Variables/TenantVariables/DefaultValueIndicator";
import ReadonlyInlineVariableValue from "~/areas/projects/components/Variables/TenantVariables/ReadonlyInlineVariableValue";
import type { ProjectTenantVariableValueChangeTracker, ProjectTenantVariableValueKey } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import type { LoadedSourceItems } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import type { PopoverPosition } from "~/components/PopoverWhenFocused/PopoverWhenFocused";
import PopoverWhenFocused from "~/components/PopoverWhenFocused/index";
import ProjectTemplateValueEdit from "./ProjectTemplateValueEdit";
interface ProjectTemplateValueCellProps {
    template: ActionTemplateParameterResource;
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    value: PropertyValueResource | undefined;
    valueType: VariableType;
    sourceItems: LoadedSourceItems;
    doBusyTask: DoBusyTask;
    valueTracker: ProjectTenantVariableValueChangeTracker;
    valueKey: ProjectTenantVariableValueKey;
    focused: boolean;
    onFocus: () => void;
    onBlur: () => void;
    bindableTemplateNames: string[];
    hasEditPermissions: boolean;
    hasValueChanged: boolean;
    initialValue: PropertyValueResource | undefined;
}
export default function ProjectTemplateValueInlineCell({ template, value, valueType, accounts, certificates, workerPools, sourceItems, doBusyTask, valueTracker, valueKey, focused, onFocus, onBlur, bindableTemplateNames, hasEditPermissions, hasValueChanged, initialValue, }: ProjectTemplateValueCellProps) {
    return hasEditPermissions ? (<ProjectTemplateValueEditableCell template={template} accounts={accounts} certificates={certificates} workerPools={workerPools} value={value} valueType={valueType} sourceItems={sourceItems} doBusyTask={doBusyTask} valueTracker={valueTracker} valueKey={valueKey} focused={focused} onFocus={onFocus} onBlur={onBlur} hasEditPermissions={hasEditPermissions} bindableTemplateNames={bindableTemplateNames} hasValueChanged={hasValueChanged} initialValue={initialValue}/>) : (<ProjectTemplateValueReadonlyCell template={template} value={value} valueType={valueType} accounts={accounts} certificates={certificates} workerPools={workerPools} hasEditPermissions={hasEditPermissions} sourceItems={sourceItems} hasValueChanged={hasValueChanged}/>);
}
interface ProjectTemplateValueEditableCell extends ProjectTemplateValueCellProps {
    hasEditPermissions: boolean;
}
function ProjectTemplateValueEditableCell({ template, value, valueType, accounts, certificates, workerPools, sourceItems, doBusyTask, valueTracker, valueKey, focused, onFocus, onBlur, hasEditPermissions, bindableTemplateNames, hasValueChanged, initialValue, }: ProjectTemplateValueEditableCell) {
    const calculateFocusedPopoverPosition = (): PopoverPosition => {
        // Move the popover up to account for the height of the template name label. This aligns the popover input with the preview input
        return {
            top: -12,
            left: 0,
            right: 0,
        };
    };
    return (<div onFocus={onFocus} onClick={onFocus} className={cellStyles.container}>
            <PopoverWhenFocused isFocused={focused} onClickOutside={onBlur} dynamicPosition={calculateFocusedPopoverPosition}>
                {focused ? (<ProjectTemplateValueEdit template={template} value={value} valueKey={valueKey} valueTracker={valueTracker} sourceItems={sourceItems} doBusyTask={doBusyTask} bindableTemplateNames={bindableTemplateNames} initialValue={initialValue}/>) : (<ProjectTemplateValueReadonlyCell template={template} value={value} valueType={valueType} accounts={accounts} certificates={certificates} workerPools={workerPools} hasEditPermissions={hasEditPermissions} sourceItems={sourceItems} hasValueChanged={hasValueChanged}/>)}
            </PopoverWhenFocused>
        </div>);
}
interface ProjectTemplateValueReadonlyCellProps {
    template: ActionTemplateParameterResource;
    value: PropertyValueResource | undefined;
    valueType: VariableType;
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    hasEditPermissions: boolean;
    sourceItems: LoadedSourceItems;
    hasValueChanged: boolean;
}
function ProjectTemplateValueReadonlyCell({ template, value, valueType, accounts, certificates, workerPools, hasEditPermissions, sourceItems, hasValueChanged }: ProjectTemplateValueReadonlyCellProps) {
    const hasDefaultValue = !!(!value && template.DefaultValue);
    return (<div className={cellStyles.readonlyContainer}>
            <ReadonlyInlineVariableValue value={value} variableType={valueType} defaultValue={template.DefaultValue} accounts={accounts} certificates={certificates} workerPools={workerPools} hasEditPermissions={hasEditPermissions} sourceItems={sourceItems}/>
            <DefaultValueIndicator show={hasDefaultValue}/>
            <ChangedValueIndicator show={hasValueChanged}/>
        </div>);
}
const cellStyles = {
    container: css({
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "4rem",
    }),
    readonlyContainer: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: space[8],
        width: "100%",
        height: "100%",
        padding: `${space[8]} ${space[12]}`,
    }),
};
