import type { HelmChartUpgradeProperties } from "@octopusdeploy/legacy-action-properties";
import { GetPrimaryPackageReference } from "@octopusdeploy/octopus-server-client";
import type { PackagedHelmValuesReference } from "~/components/Actions/helmChartUpgrade/PackagedHelmValuesDialog";
import type { TemplateValuesSources } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import { getValuesPackageReferences } from "~/components/Actions/helmChartUpgrade/helmPackageHelpers";
import StringHelper from "~/utils/StringHelper/index";
export const tryMigrateToTemplateValuesSources = (properties: HelmChartUpgradeProperties, packages: Array<PackagedHelmValuesReference>) => {
    const templateValueSources: TemplateValuesSources = [];
    //key values
    const keyValues = properties["Octopus.Action.Helm.KeyValues"];
    if (!StringHelper.isNullOrWhiteSpace(keyValues)) {
        templateValueSources.push({
            Type: "KeyValues",
            Value: JSON.parse(keyValues),
        });
    }
    //inline yaml
    const inlineYaml = properties["Octopus.Action.Helm.YamlValues"];
    if (!StringHelper.isNullOrWhiteSpace(inlineYaml)) {
        templateValueSources.push({
            Type: "InlineYaml",
            Value: inlineYaml,
        });
    }
    //chart package
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const primaryPackage = GetPrimaryPackageReference(packages) as PackagedHelmValuesReference;
    if (primaryPackage && !StringHelper.isNullOrWhiteSpace(primaryPackage.Properties.ValuesFilePath)) {
        templateValueSources.push({
            Type: "Chart",
            ValuesFilePaths: primaryPackage.Properties.ValuesFilePath,
        });
    }
    //packages
    const packageReferences = getValuesPackageReferences(packages);
    for (const packageReference of packageReferences) {
        templateValueSources.push({
            Type: "Package",
            PackageId: packageReference.PackageId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            PackageName: packageReference.Name!,
            ValuesFilePaths: packageReference.Properties.ValuesFilePath,
        });
    }
    return templateValueSources;
};
