import { Tooltip } from "@octopusdeploy/design-system-components";
import React from "react";
import { useOctopusTheme } from "~/components/Theme";
import styles from "./ProcessActionErrorIndicator.module.less";
interface ProcessActionErrorIndicatorProps {
    actionErrors: string[];
    accessibleName?: string;
}
export const ProcessActionErrorIndicator: React.FC<ProcessActionErrorIndicatorProps> = ({ actionErrors, accessibleName }) => {
    const theme = useOctopusTheme();
    const items = actionErrors.map((x, index) => <li key={index}>{x}</li>);
    return (<Tooltip content={<ul className={styles.itemContainer}>{items}</ul>}>
            <div className={styles.indicatorContainer}>
                <em className="fa-solid fa-exclamation-triangle" style={{ color: theme.danger }} role="img" aria-label={accessibleName}/>
            </div>
        </Tooltip>);
};
ProcessActionErrorIndicator.displayName = "ProcessActionErrorIndicator"
