import _ from "lodash";
import React from "react";
import styles from "./HighlightMatches.module.less";
interface HighlightSingleMatchesProps {
    text: string;
    highlight: string;
}
const HighlightSingleMatches: React.FC<HighlightSingleMatchesProps> = ({ text = "", highlight = "" }) => {
    if (!highlight.trim()) {
        return <span aria-label={text}>{text}</span>;
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, "gi");
    const parts = text.split(regex);
    return (<span aria-label={text}>
            {parts
            .filter((part) => part)
            .map((part, i) => regex.test(part) ? (
        // prettier-ignore
        <mark key={i} className={styles.highlight}>{part}</mark>) : (<span key={i}>{part}</span>))}
        </span>);
};
HighlightSingleMatches.displayName = "HighlightSingleMatches"
export default HighlightSingleMatches;
