import { RadioButtonGroup, RadioButton, Callout } from "@octopusdeploy/design-system-components";
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { AmazonWebServicesAccessKeyAccountResource, AmazonWebServicesOidcAccountResource, ReferenceDataItem, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import React from "react";
import { subjectKeyChipList } from "~/components/Chips";
import Dialog from "~/components/Dialog/Dialog";
import { OpenIdSubjectMultiSelect, AvailableExecutionSubjectKeys, DefaultDeploymentSubjectKeys, AvailableDeploymentSubjectKeys, AvailableRunbookSubjectKeys, AvailableHealthCheckSubjectKeys, DefaultHealthCheckSubjectKeys, AvailableAccountTestSubjectKeys, DefaultAccountTestSubjectKeys, SubjectKeyOrder, } from "~/components/MultiSelect/OpenIdSubjectMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { ExpandableFormSection, Summary, FormSectionHeading, ErrorPanel } from "~/components/form";
import Sensitive, { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import Note from "~/primitiveComponents/form/Note/Note";
import Text from "~/primitiveComponents/form/Text/Text";
import type { AccountEditModel, AccountEditBaseProps } from "./AccountEditBase";
import AccountEditBaseDrawer from "./AccountEditBaseDrawer";
import AccountEditBasePaper from "./AccountEditBasePaper";
import { SaveAndTestAmazonWebServicesAccountDialog } from "./SaveAndTestAccountDialog";
import styles from "./style.module.less";
interface AmazonWebServicesAccessKeyAuth {
    accessKey: string;
    secretKey: SensitiveValue;
}
interface AmazonWebServicesOidcAuth {
    roleArn: string;
    sessionDuration: string;
    deploymentSubjectKeys: string[];
    healthSubjectKeys: string[];
    accountTestSubjectKeys: string[];
}
interface AmazonWebServicesAccountModel extends AccountEditModel {
    accountType: AmazonWebServicesAccountTypes;
    authentication: AmazonWebServicesAccessKeyAuth | AmazonWebServicesOidcAuth;
}
const defaultAmazonWebServicesAuth: AmazonWebServicesAccessKeyAuth = {
    accessKey: "",
    secretKey: {
        HasValue: false,
    },
};
const defaultAmazonWebServicesOidcAuth: AmazonWebServicesOidcAuth = {
    roleArn: "",
    sessionDuration: "3600",
    deploymentSubjectKeys: [],
    healthSubjectKeys: [],
    accountTestSubjectKeys: [],
};
type AmazonWebServicesAccountTypes = AccountType.AmazonWebServicesAccount | AccountType.AmazonWebServicesOidcAccount;
type AmazonWebServicesAccountResources = AmazonWebServicesAccessKeyAccountResource | AmazonWebServicesOidcAccountResource;
type AmazonWebServicesAuthTypes = AmazonWebServicesAccessKeyAuth | AmazonWebServicesOidcAuth;
export default function AmazonWebServicesAccountEdit(props: AccountEditBaseProps<AmazonWebServicesAccountResources>) {
    switch (props.type) {
        case "paper":
            return <AmazonWebServicesAccountEditPaper {...props}/>;
        case "drawer":
            return <AmazonWebServicesAccountEditDrawer {...props}/>;
        default:
            return <ErrorPanel message="Invalid layout"/>;
    }
}
class AmazonWebServicesAccountEditPaper extends AccountEditBasePaper<AmazonWebServicesAccountResources, AmazonWebServicesAccountModel> {
    getPartialModel(account?: AmazonWebServicesAccountResources) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return getPageActions((performTest: boolean) => this.handleSaveClick(performTest));
    }
    getTestDialog() {
        return getTestDialog(this.state.accountData?.account?.Id, this.state.showTestDialog, (success: boolean) => this.onTestDone(success));
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.accountData === undefined, this.state.model, (state, accountType, model) => this.setState({
            model: {
                ...state,
                accountType: accountType,
                authentication: {
                    ...model,
                },
            },
        }), (auth) => this.setState((prev) => ({
            model: {
                ...prev.model,
                authentication: {
                    ...prev.model.authentication,
                    ...auth,
                },
            },
        })), this.getFieldError);
    }
}
class AmazonWebServicesAccountEditDrawer extends AccountEditBaseDrawer<AmazonWebServicesAccountResources, AmazonWebServicesAccountModel> {
    getPartialModel(account?: AmazonWebServicesAccountResources) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return getPageActions((performTest: boolean) => this.handleSaveClick(performTest));
    }
    getTestDialog() {
        return getTestDialog(this.state.accountData?.account?.Id, this.state.showTestDialog, (success: boolean) => this.onTestDone(success));
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.accountData === undefined, this.state.model, (state, accountType, model) => this.setState({
            model: {
                ...state,
                accountType: accountType,
                authentication: {
                    ...model,
                },
            },
        }), (auth) => this.setState((prev) => ({
            model: {
                ...prev.model,
                authentication: {
                    ...prev.model.authentication,
                    ...auth,
                },
            },
        })), this.getFieldError);
    }
}
function getPartialModel(account?: AmazonWebServicesAccountResources): Partial<AmazonWebServicesAccountModel> | undefined {
    if (!account) {
        return {
            accountType: AccountType.AmazonWebServicesAccount,
            authentication: cloneDeep(defaultAmazonWebServicesAuth),
        };
    }
    const model = {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        accountType: account.AccountType as AmazonWebServicesAccountTypes,
    };
    if (account.AccountType === AccountType.AmazonWebServicesAccount) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const awsAccount = account as AmazonWebServicesAccessKeyAccountResource;
        return {
            ...model,
            authentication: {
                accessKey: awsAccount.AccessKey,
                secretKey: awsAccount.SecretKey,
            },
        };
    }
    else if (account.AccountType === AccountType.AmazonWebServicesOidcAccount) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const awsOidcAccount = account as AmazonWebServicesOidcAccountResource;
        return {
            ...model,
            authentication: {
                roleArn: awsOidcAccount.RoleArn,
                sessionDuration: awsOidcAccount.SessionDuration,
                deploymentSubjectKeys: awsOidcAccount.DeploymentSubjectKeys,
                healthSubjectKeys: awsOidcAccount.HealthCheckSubjectKeys,
                accountTestSubjectKeys: awsOidcAccount.AccountTestSubjectKeys,
            },
        };
    }
}
function isAccessKeyAccount(variable: object): variable is AmazonWebServicesAccessKeyAuth {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (variable as AmazonWebServicesAccessKeyAuth).accessKey !== undefined;
}
function isOidcAccount(variable: object): variable is AmazonWebServicesOidcAuth {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (variable as AmazonWebServicesOidcAuth).roleArn !== undefined;
}
function getPartialResource(modelState: AmazonWebServicesAccountModel): (Partial<AmazonWebServicesAccountResources> & {
    AccountType: AccountType;
}) | undefined {
    const resource = {
        AccountType: modelState.accountType,
    };
    if (isAccessKeyAccount(modelState.authentication)) {
        return {
            ...resource,
            AccessKey: modelState.authentication.accessKey,
            SecretKey: modelState.authentication.secretKey,
        };
    }
    return {
        ...resource,
        RoleArn: modelState.authentication.roleArn,
        SessionDuration: modelState.authentication.sessionDuration,
        DeploymentSubjectKeys: modelState.authentication.deploymentSubjectKeys,
        HealthCheckSubjectKeys: modelState.authentication.healthSubjectKeys,
        AccountTestSubjectKeys: modelState.authentication.accountTestSubjectKeys,
    };
}
function accessKeySummary(accessKey: string) {
    return accessKey ? Summary.summary(accessKey) : Summary.placeholder("No access key provided");
}
function secretKeySummary(secretKey: SensitiveValue) {
    return secretKey && secretKey.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No secret key provided");
}
function roleArnSummary(roleArn: string) {
    return roleArn ? Summary.summary(roleArn) : Summary.placeholder("No RoleArn provided");
}
function sessionDurationSummary(sessionDuration: string) {
    return sessionDuration ? Summary.summary(sessionDuration) : Summary.placeholder("The default session duration is not being overridden");
}
function authenticationMethodSummary(accountType?: AmazonWebServicesAccountTypes) {
    return accountType === null
        ? Summary.placeholder("Select the Amazon Web Services authentication method")
        : accountType === AccountType.AmazonWebServicesAccount
            ? Summary.summary(<span>Use an Access Key</span>)
            : Summary.summary(<span>Use OpenID Connect</span>);
}
function getPageActions(handleSaveClick: (performTest: boolean) => void): PageAction[] {
    return [{ type: "button", buttonType: "secondary", onClick: () => handleSaveClick(true), label: "Save and test" }];
}
function getTestDialog(accountId: string | undefined, showTestDialog: boolean, onOkClick: (success: boolean) => void): React.ReactNode {
    return (accountId !== undefined && (<Dialog open={showTestDialog}>
                <SaveAndTestAmazonWebServicesAccountDialog onOkClick={onOkClick} accountId={accountId}/>
            </Dialog>));
}
function customExpandableFormSections(isNew: boolean, modelState: AmazonWebServicesAccountModel, onAuthenticationMethodChange: (state: AmazonWebServicesAccountModel, accType: AmazonWebServicesAccountTypes, auth: AmazonWebServicesAccessKeyAuth | AmazonWebServicesOidcAuth) => void, onAuthenticationChange: <AmazonWebServicesAuthTypes, K extends keyof AmazonWebServicesAuthTypes>(newState: Pick<AmazonWebServicesAuthTypes, K>) => void, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    const sectionHeading = isNew ? "Amazon Web Services Details" : modelState.accountType === AccountType.AmazonWebServicesAccount ? "Access Key Details" : "OIDC Details";
    baseElements.push(<FormSectionHeading title={sectionHeading} key={"header"}/>);
    if (isNew) {
        baseElements.push(<ExpandableFormSection isExpandedByDefault={true} key="AuthenticationMethod" title="Authentication Method" errorKey="accountType" summary={authenticationMethodSummary(modelState.accountType)} help="Select the Amazon Web Services authentication method to show the relevant form inputs below.">
                <RadioButtonGroup value={modelState.accountType} onChange={(x) => {
                const model = x === AccountType.AmazonWebServicesAccount ? cloneDeep(defaultAmazonWebServicesAuth) : cloneDeep(defaultAmazonWebServicesOidcAuth);
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                onAuthenticationMethodChange(modelState, x as AmazonWebServicesAccountTypes, model);
            }}>
                    <RadioButton value={AccountType.AmazonWebServicesAccount} label="Use an Access Key" isDefault={true}/>
                    <RadioButton value={AccountType.AmazonWebServicesOidcAccount} label="Use OpenID Connect"/>
                </RadioButtonGroup>
            </ExpandableFormSection>);
    }
    if (isAccessKeyAccount(modelState.authentication)) {
        baseElements.push(accessKeyExpandableFormSection(modelState.authentication, onAuthenticationChange, getFieldError));
    }
    if (isOidcAccount(modelState.authentication)) {
        baseElements.push(oidcExpandableFormSection(modelState.authentication, onAuthenticationChange, getFieldError));
    }
    return baseElements;
}
function accessKeyExpandableFormSection(model: AmazonWebServicesAccessKeyAuth, setAccessKeyAuth: <AmazonWebServicesAccessKeyAuth, K extends keyof AmazonWebServicesAccessKeyAuth>(newState: Pick<AmazonWebServicesAccessKeyAuth, K>) => void, getFieldError: (field: string) => string): React.ReactElement {
    return (<div key="AWS-AccessKey">
            <ExpandableFormSection key="accessKey" errorKey="accessKey" title="Access Key" summary={accessKeySummary(model.accessKey)} isExpandedByDefault={true} help="The AWS access key to use when authenticating against Amazon Web Services.">
                <Text value={model.accessKey} onChange={(accessKey) => setAccessKeyAuth({ accessKey })} label="Access Key" error={getFieldError("AccessKey")}/>
                <Note>
                    Refer to the <ExternalLink href="AwsDocsManagingAccessKeys">AWS documentation</ExternalLink> for information on generating access and secret keys.
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection key="secretKey" errorKey="secretKey" title="Secret Key" summary={secretKeySummary(model.secretKey)} isExpandedByDefault={true} help="The AWS secret key to use when authenticating against Amazon Web Services.">
                <Sensitive value={model.secretKey} onChange={(secretKey) => setAccessKeyAuth({ secretKey })} label="Secret Key" error={getFieldError("SecretKey")}/>
            </ExpandableFormSection>
        </div>);
}
function oidcExpandableFormSection(model: AmazonWebServicesOidcAuth, setOidcAuth: <AmazonWebServicesOidcAuth, K extends keyof AmazonWebServicesOidcAuth>(newState: Pick<AmazonWebServicesOidcAuth, K>) => void, getFieldError: (field: string) => string): React.ReactElement {
    return (<div key="AWS-OIDC">
            <Callout type={"warning"} title="Early Access">
                The OpenID Connect feature is still in development. We'd love to hear <ExternalLink href={"OpenIDConnectFeedback"}> your feedback</ExternalLink>.
            </Callout>
            <ExpandableFormSection key="roleArn" errorKey="roleArn" title="Role Arn" summary={roleArnSummary(model.roleArn)} isExpandedByDefault={true} help="The Amazon Resource Name (ARN) of the role that the caller is assuming.">
                <Text value={model.roleArn} onChange={(roleArn) => setOidcAuth({ roleArn })} label="Role ARN" error={getFieldError("RoleArn")}/>
            </ExpandableFormSection>
            <ExpandableFormSection key="sessionDuration" errorKey="sessionDuration" title="Session Duration" summary={sessionDurationSummary(model.sessionDuration)} help="The duration, in seconds, of the role session.">
                <Text value={model.sessionDuration} onChange={(sessionDuration) => setOidcAuth({ sessionDuration })} label="Session Duration" error={getFieldError("SessionDuration")}/>
                <Note>
                    The value can range from 900 seconds (15 minutes) up to the maximum session duration setting for the role. This setting can have a value from 1 hour to 12 hours. If you specify a value higher than this setting, the operation
                    fails.
                </Note>
            </ExpandableFormSection>
            <FormSectionHeading title="Subject Claim" key={"SubjectClaim"}/>
            <ExpandableFormSection errorKey="deploymentSubjectKeys" key="deploymentSubjectKeys" title="Deployments & Runbooks" help="Select keys to include for a deployment or runbook." summary={subjectKeySummary(model.deploymentSubjectKeys)}>
                <OpenIdSubjectMultiSelect onChange={(v: string[]) => setOidcAuth({ deploymentSubjectKeys: v })} value={model.deploymentSubjectKeys} subjectKeys={AvailableExecutionSubjectKeys}/>
                <Note>
                    Subject claim formats:
                    <br />
                    <b>Deployment:</b> <span>{generateSampleSubject(model.deploymentSubjectKeys, DefaultDeploymentSubjectKeys, "deployment", AvailableDeploymentSubjectKeys)}</span>
                    <br />
                    <b>Runbook:</b> <span>{generateSampleSubject(model.deploymentSubjectKeys, DefaultDeploymentSubjectKeys, "runbook", AvailableRunbookSubjectKeys)}</span>
                    <br />
                    <span className={styles.secondaryTextColor}>If no keys are specified, the subject claim will default to: {DefaultDeploymentSubjectKeys.join(", ")}. Any keys not selected will not be included in the subject claim.</span>
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="healthSubjectKeys" key="healthSubjectKeys" title="Health Checks" help="Select keys to include for a health check." summary={subjectKeySummary(model.healthSubjectKeys)}>
                <OpenIdSubjectMultiSelect onChange={(v: string[]) => setOidcAuth({ healthSubjectKeys: v })} value={model.healthSubjectKeys} subjectKeys={AvailableHealthCheckSubjectKeys}/>
                <Note>
                    <b>Subject claim format: </b>
                    <span>{generateSampleSubject(model.healthSubjectKeys, DefaultHealthCheckSubjectKeys, "health", AvailableHealthCheckSubjectKeys)}</span>
                    <br />
                    <span className={styles.secondaryTextColor}>If no keys are specified, the subject claim will default to: {DefaultHealthCheckSubjectKeys.join(", ")}. Any keys not selected will not be included in the subject claim.</span>
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="accountTestSubjectKeys" key="accountTestSubjectKeys" title="Account Test" help="Select keys to include for an account test." summary={subjectKeySummary(model.accountTestSubjectKeys)}>
                <OpenIdSubjectMultiSelect onChange={(v: string[]) => setOidcAuth({ accountTestSubjectKeys: v })} value={model.accountTestSubjectKeys} subjectKeys={AvailableAccountTestSubjectKeys}/>
                <Note>
                    <b>Subject claim format: </b>
                    <span>{generateSampleSubject(model.accountTestSubjectKeys, DefaultAccountTestSubjectKeys, "test", AvailableAccountTestSubjectKeys)}</span>
                    <br />
                    <span className={styles.secondaryTextColor}>If no keys are specified, the subject claim will default to: {DefaultAccountTestSubjectKeys.join(", ")}. Any keys not selected will not be included in the subject claim.</span>
                </Note>
            </ExpandableFormSection>
        </div>);
}
function subjectKeySummary(subjectKeys: string[]) {
    if (subjectKeys && subjectKeys.length > 0) {
        return Summary.summary(subjectKeyChipList(subjectKeys));
    }
    else {
        return Summary.summary("Using default subject keys");
    }
}
function generateSampleSubject(requestedKeys: string[], defaultKeys: string[], type: string, possibleItems: ReferenceDataItem[]): string {
    const possibleKeys = possibleItems.map((k) => k.Id);
    const keys: string[] = requestedKeys && requestedKeys.length > 0 ? requestedKeys : defaultKeys;
    return keys
        .filter((k) => possibleKeys.indexOf(k) > -1)
        .sort((a, b) => SubjectKeyOrder.indexOf(a) - SubjectKeyOrder.indexOf(b))
        .map((k) => (k === "type" ? `type:${type}` : `${k}:[${k}-slug]`))
        .join(":");
}
