import * as React from "react";
import type { SFC } from "react";
import ExpandableFormSection from "../../form/Sections/ExpandableFormSection";
import Summary from "../../form/Sections/Summary";
import { VariableLookupText } from "../../form/VariableLookupText";
const KubernetesNamespaceFormSection: SFC<{
    namespace: string;
    onChange: (namespace: string) => void;
}> = (props) => {
    const namespace = props.namespace;
    let summary = Summary.default(<span>The Kubernetes context will be set with the namespace configured on the Kubernetes deployment target, or the default namespace if none is specified on the target.</span>);
    if (!!namespace) {
        summary = Summary.summary(<span>
                The Kubernetes context with be set with the namespace <strong>{namespace}</strong>
            </span>);
    }
    return (<ExpandableFormSection title="Namespace" summary={summary} errorKey="Namespace" help="Specify the namespace of the Kubernetes context">
            <VariableLookupText label="Namespace" value={namespace} placeholder="Deployment Target Namespace" onChange={props.onChange}/>
        </ExpandableFormSection>);
};
export default KubernetesNamespaceFormSection;
