import type { ActionProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import ConfigureFeaturesContent from "./ConfigureFeaturesContent";
interface OpenFeatureDialogProps {
    actionType: string;
    properties: ActionProperties;
    saveDone(enabledFeatures: string): void;
}
const OpenFeatureDialog: React.StatelessComponent<OpenFeatureDialogProps> = (props: OpenFeatureDialogProps) => {
    return (<OpenDialogButton label="Configure features" dialogWidth={"1000px"}>
            <ConfigureFeaturesContent actionType={props.actionType} properties={props.properties} saveDone={props.saveDone}/>
        </OpenDialogButton>);
};
OpenFeatureDialog.displayName = "OpenFeatureDialog"
OpenFeatureDialog.displayName = "OpenFeatureDialog";
export default OpenFeatureDialog;
