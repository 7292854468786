import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { OverviewContainer, OverviewHighlightText } from "../../CommonComponents/CommonViews";
export const ProjectTenantProjectTemplatesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().variables.tenant.projectTemplates,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (<OverviewContainer>
                <OverviewHighlightText>
                    <p>Project templates define which variable values are required for each tenant connected to this project.</p>
                    <br />
                    <p>Project templates allow tenants to provide different variable values for each project/environment combination.</p>
                    <br />
                    <p>
                        Learn more about&nbsp;
                        <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                    </p>
                </OverviewHighlightText>
            </OverviewContainer>);
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
