import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { EnvironmentResource, FeatureToggleResource, TenantResource, ToggleResource, CreateFeatureToggleCommand, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useState } from "react";
import { FeatureTogglesDataTableNameCell } from "~/areas/projects/components/FeatureToggles/FeatureTogglesDataTableNameCell";
import ToggleScope from "~/areas/projects/components/FeatureToggles/ToggleScope";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { DataBaseComponent, useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { GroupedDataTable } from "~/components/GroupedDataTable/GroupedDataTable";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import Text from "~/primitiveComponents/form/Text/Text";
export class FeatureTogglesPage extends DataBaseComponent<{}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    render() {
        return <FeatureTogglesLayout doBusyTask={this.doBusyTask} busy={this.state.busy} errors={this.errors}/>;
    }
    static displayName = "FeatureTogglesPage";
}
interface FeatureTogglesLayoutProps {
    doBusyTask: DoBusyTask;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
}
interface FeatureToggleState {
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    featureToggles: FeatureToggleResource[];
}
function FeatureTogglesLayout({ doBusyTask, busy, errors }: FeatureTogglesLayoutProps) {
    const projectContext = useProjectContext();
    const project = projectContext.state && projectContext.state.model;
    const [data, setData] = useState<FeatureToggleState | null>(null);
    const { navigate } = useSpaceAwareNavigation();
    const { isOpen: isAddToggleDialogOpen, openDialog: openAddToggleDialog } = useDialogTrigger();
    useDoBusyTaskEffect(doBusyTask, async () => {
        const featureTogglesResponsePromise = repository.FeatureToggles.list(projectContext.state.model);
        const environmentsPromise = repository.Environments.all();
        const featureTogglesResponse = await featureTogglesResponsePromise;
        // Fetch all the tenants that are configured on toggles. Because we don't need to select tenants on this page, we can avoid tenants.all()
        const tenantIds = featureTogglesResponse.Features.flatMap((f) => f.Toggles)
            .flatMap((t) => t.TenantIds)
            .reduce((acc, tenantId) => acc.add(tenantId), new Set<string>());
        const excludedTenantIds = featureTogglesResponse.Features.flatMap((f) => f.Toggles)
            .flatMap((t) => t.ExcludedTenantIds)
            .reduce((acc, tenantId) => acc.add(tenantId), new Set<string>());
        const tenants = await repository.Tenants.all({ ids: [...tenantIds, ...excludedTenantIds] });
        const sortedFeatureToggles = featureTogglesResponse.Features.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        setData({
            environments: await environmentsPromise,
            tenants,
            featureToggles: sortedFeatureToggles,
        });
    }, []);
    const addToggleAction: PrimaryPageAction = {
        type: "button",
        label: "Add Feature Toggle",
        onClick: openAddToggleDialog,
    };
    return (<ProjectPaperLayout title="Feature Toggles" fullWidth={true} pageActions={undefined} primaryAction={addToggleAction} busy={busy} errors={errors}>
            <Dialog open={isAddToggleDialogOpen}>
                <AddFeatureToggle project={project} onOk={(toggle) => {
            navigate(links.featureTogglePage.generateUrl({ spaceId: toggle.SpaceId, projectSlug: project.Slug, featureToggleSlug: toggle.Slug }));
        }}/>
            </Dialog>
            {data && <FeatureToggleTable featureToggles={data.featureToggles} tenants={data.tenants} environments={data.environments}/>}
        </ProjectPaperLayout>);
}
interface FeatureToggleRow {
    Feature?: FeatureToggleResource;
    Toggle?: ToggleResource;
    RowKey: string;
    IsChild: boolean;
}
function FeatureToggleTable({ featureToggles, tenants, environments }: FeatureToggleState) {
    const toggleRow = (toggle: ToggleResource) => {
        const environment = environments.find((e) => e.Id === toggle.DeploymentEnvironmentId);
        return <ToggleScope toggle={toggle} environmentName={environment?.Name ?? "Unknown"} tenants={tenants}/>;
    };
    function buildToggleRows(features: FeatureToggleResource[]): FeatureToggleRow[] {
        const rows: FeatureToggleRow[] = [];
        for (const feature of features) {
            const enabledToggles = feature.Toggles.filter((f) => f.IsEnabled);
            let isChild = false;
            for (const toggle of enabledToggles) {
                rows.push({
                    Feature: feature,
                    Toggle: toggle,
                    RowKey: `${feature.Id}${toggle.DeploymentEnvironmentId}`,
                    IsChild: isChild,
                });
                isChild = true;
            }
            // IF there are no enabled toggles, then push a row with just the feature name
            if (enabledToggles.length === 0) {
                rows.push({
                    Feature: feature,
                    RowKey: `${feature.Id}`,
                    IsChild: false,
                });
            }
        }
        return rows;
    }
    return (<GroupedDataTable data={buildToggleRows(featureToggles)} columns={[
            {
                title: "Feature",
                width: "50%",
                render: (data) => (data.Feature && !data.IsChild ? <FeatureTogglesDataTableNameCell featureToggle={data.Feature}/> : <></>),
                isChild: (data) => data.IsChild,
            },
            {
                title: "Enabled For",
                width: "50%",
                render: (data) => (data.Toggle ? toggleRow(data.Toggle) : <div />),
            },
        ]} getRowKey={(data) => data.RowKey}/>);
}
interface AddFeatureToggleProps {
    project: Readonly<ProjectResource>;
    onOk(featureToggleResource: FeatureToggleResource): void;
}
class AddFeatureToggle extends DataBaseComponent<AddFeatureToggleProps, {
    name: string;
}> {
    constructor(props: AddFeatureToggleProps) {
        super(props);
        this.state = { name: "" };
    }
    async onOk() {
        await this.doBusyTask(async () => {
            const newFeatureToggle: CreateFeatureToggleCommand = { Name: this.state.name, ProjectId: this.props.project.Id, Toggles: [] };
            const createResponse = await repository.FeatureToggles.create(this.props.project, newFeatureToggle);
            return this.props.onOk(createResponse);
        });
    }
    render() {
        return (<OkDialogLayout title="Add Feature Toggle" busy={this.state.busy} errors={this.errors} okButtonDisabled={!this.state.name} onOkClick={() => this.onOk()}>
                <Text value={this.state.name} onChange={(value) => this.setState({ name: value })} label="Name" autoFocus/>
            </OkDialogLayout>);
    }
    static displayName = "AddFeatureToggle";
}
