import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { Text } from "~/components/form";
import type { DoBusyTask } from "../../../../components/DataBaseComponent/DataBaseComponent";
interface DeleteProjectProps {
    projectName: string;
    projectId: string;
    doBusyTask: DoBusyTask;
    onChange(canDelete: boolean): void;
}
interface DeleteProjectState {
    confirmationProjectName: string;
}
export default class DeleteProject extends React.Component<DeleteProjectProps, DeleteProjectState> {
    constructor(props: DeleteProjectProps) {
        super(props);
        this.state = {
            confirmationProjectName: "",
        };
    }
    render() {
        return (<div>
                <Callout title="This is a destructive action" type={"danger"}>
                    This action <strong>cannot</strong> be undone. This will permanently delete the <strong>{this.props.projectName}</strong> project and all of its contents, including releases and deployment history.
                </Callout>
                <div>
                    <p>Please type in the name of the project ({this.props.projectName}) to confirm.</p>
                    <Text value={this.state.confirmationProjectName} onChange={this.onChange}/>
                </div>
            </div>);
    }
    private onChange = (confirmationProjectName: string) => {
        this.setState({ confirmationProjectName });
        this.props.onChange(confirmationProjectName.replace(/\s+/g, " ").toLowerCase() === this.props.projectName.replace(/\s+/g, " ").toLowerCase());
    };
    static displayName = "DeleteProject";
}
