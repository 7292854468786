import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonDashboardOverview, CommonDashboardTopics } from "../../CommonComponents/CommonDashboardViews";
export const DashboardRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.dashboard.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDashboardOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDashboardTopics />;
    },
};
