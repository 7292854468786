import { css } from "@emotion/css";
import { colorScales, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
const plusIconStyles = css({
    backgroundColor: themeTokens.color.icon.selected,
    width: "50px",
    height: "50px",
    padding: "15px",
    borderRadius: "25px",
});
export function WelcomeDialogPlusIcon() {
    return (<div className={plusIconStyles}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5385 1.53846C11.5385 0.6875 10.851 0 10 0C9.14904 0 8.46154 0.6875 8.46154 1.53846V8.46154H1.53846C0.6875 8.46154 0 9.14904 0 10C0 10.851 0.6875 11.5385 1.53846 11.5385H8.46154V18.4615C8.46154 19.3125 9.14904 20 10 20C10.851 20 11.5385 19.3125 11.5385 18.4615V11.5385H18.4615C19.3125 11.5385 20 10.851 20 10C20 9.14904 19.3125 8.46154 18.4615 8.46154H11.5385V1.53846Z" fill={colorScales.white}/>
            </svg>
        </div>);
}
