export enum RowType {
    Add,
    Edit
}
export enum FocusableCellType {
    Name = "Name",
    Value = "Value",
    ScopeEdit = "ScopeEdit",
    ScopeViewAll = "ScopeViewAll"
}
export interface CellFocus {
    readonly rowType: RowType;
    readonly cell: FocusableCellType;
    readonly variableId: string;
}
