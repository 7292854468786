import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form/index";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TermsText, TopicsContainer } from "./CommonViews";
export const CommonProjectDeploymentOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <ExternalLink href="Deployments" label={"Deployments"}>
                    Deployments
                </ExternalLink>{" "}
                are tasks which execute the deployment process of a selected release to a specific environment. You can deploy a release as many times as you want to.
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Deployment changes"}>You can configure what changes to show for each deployment in the deployment settings.</Note>
                <Note heading={"Build information"}>Build information allows you to easily determine what has changed between all the packages in each new deployment.</Note>
                <Note heading={"Artifacts"}>Use artifacts to collect files from remote machines and copy them to Octopus.</Note>
            </TermsText>
        </OverviewContainer>);
};
export const CommonProjectDeploymentTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>{<ExternalLink href="DeploymentPatterns">Deployment patterns</ExternalLink>}</div>
                <div>{<ExternalLink href="BuildInfo">Build information</ExternalLink>}</div>
                <div>{<ExternalLink href="Artifacts">Artifacts</ExternalLink>}</div>
            </LearnMoreResources>
        </TopicsContainer>);
};
