/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import type { EnvironmentSummaryFilter } from "../EnvironmentsLayout/EnvironmentSummaryFilter";
import type { WorkerPoolsSummaryFilter } from "../WorkerPools/WorkerPoolsLayout/WorkerPoolsSummaryFilter";
export const getHealthStatusFiltersAsPerPrecedence = (filter: WorkerPoolsSummaryFilter | EnvironmentSummaryFilter, isFiltering: boolean, healthStatusFilter: string | null): MachineModelHealthStatus[] => {
    if (isFiltering) {
        if (filter.healthStatuses && filter.healthStatuses.length > 0) {
            return filter.healthStatuses;
        }
        return [];
    }
    if (healthStatusFilter) {
        return [MachineModelHealthStatus[healthStatusFilter as keyof typeof MachineModelHealthStatus]];
    }
    return [];
};
export const getIsDisabledFilterAsPerPrecedence = (isFiltering: boolean, filter: WorkerPoolsSummaryFilter | EnvironmentSummaryFilter, isDisabledFilter: boolean): boolean => {
    if (isFiltering) {
        return filter.isDisabled;
    }
    return isDisabledFilter;
};
