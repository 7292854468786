import type { ReleaseResource, IHaveSnapshotGitReference, GitReference } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useOptionalProjectContext } from "~/areas/projects/context";
import Section from "~/components/Section";
import { FormSectionHeading, Note } from "~/components/form";
import { GitRefChip } from "../GitRefChip/GitRefChip";
const ProcessSnapshot: React.FC<{
    vcsRef: GitReference;
}> = ({ vcsRef }) => {
    return (<>
            When this release was created, a snapshot of the deployment process was taken from <GitRefChip vcsRef={vcsRef}/>
        </>);
};
ProcessSnapshot.displayName = "ProcessSnapshot"
export const ProcessSnapshotSection: React.FC<{
    resource: IHaveSnapshotGitReference;
}> = ({ resource }) => {
    const projectContext = useOptionalProjectContext();
    return ((projectContext?.state.model?.IsVersionControlled && resource && resource.VersionControlReference && (<div>
                <h4>Process Snapshot</h4>
                <ProcessSnapshot vcsRef={resource.VersionControlReference}/>
            </div>)) ||
        null);
};
ProcessSnapshotSection.displayName = "ProcessSnapshotSection"
export const ProcessSnapshotFormSection: React.FC<{
    release: ReleaseResource;
}> = ({ release }) => {
    const projectContext = useOptionalProjectContext();
    return ((projectContext?.state.model?.IsVersionControlled && release && release.VersionControlReference && (<>
                <FormSectionHeading title="Process Snapshot"/>
                <Section>
                    <Note>
                        <ProcessSnapshot vcsRef={release.VersionControlReference}/>
                    </Note>
                </Section>
            </>)) ||
        null);
};
ProcessSnapshotFormSection.displayName = "ProcessSnapshotFormSection"
