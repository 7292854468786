/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import _ from "lodash";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import Markdown from "~/components/Markdown";
import { buildPartialReleaseNotes } from "../releaseNoteHelper";
interface ReleaseChangesDetailProps {
    changesMarkdown: string;
    maxLines?: number;
}
interface ReleaseChangesDetailState extends DataBaseComponentState {
    expanded: boolean;
}
export default class ReleaseChangesDetail extends DataBaseComponent<ReleaseChangesDetailProps, ReleaseChangesDetailState> {
    constructor(props: ReleaseChangesDetailProps) {
        super(props);
        this.state = {
            expanded: false,
        };
    }
    render() {
        const [markdown, showButton] = determineRendering(this.props.changesMarkdown, this.props.maxLines, this.state.expanded);
        const buttonLabel = this.state.expanded ? "Show less" : "Show more";
        return (<div>
                <Markdown markup={markdown}/>
                {showButton && <ActionButton type={ActionButtonType.Ternary} onClick={() => this.toggleExpanded()} label={buttonLabel}/>}
            </div>);
    }
    private toggleExpanded(): void {
        this.setState({ expanded: !this.state.expanded });
    }
    static displayName = "ReleaseChangesDetail";
}
export function determineRendering(releaseNotes: string, maxLines: number | undefined, expanded: boolean): [
    string,
    boolean
] {
    const shouldTruncate = _.isNumber(maxLines) && maxLines > 0;
    if (!shouldTruncate) {
        return [releaseNotes, false];
    }
    const [truncatedMarkdown, wasTruncated] = buildPartialReleaseNotes(releaseNotes, maxLines!);
    const showButton = wasTruncated;
    const markdown = expanded ? releaseNotes : truncatedMarkdown;
    return [markdown, showButton];
}
