import type { AccountResource, ActionTemplateParameterResource, GitRefResource, FeedResource, PackageReference, GitDependencyReference, GitCredentialResource, ProjectResource, ProcessType, TriggerResource, } from "@octopusdeploy/octopus-server-client";
export type SetPackages = (packages: (previous: Array<PackageReference>) => Array<PackageReference>) => void;
export type SetGitDependencies = (gitDependencies: (previous: GitDependencyReference[] | null) => GitDependencyReference[]) => void;
interface CommonStepInputDependencies {
    setPackages: SetPackages;
    setGitDependencies: SetGitDependencies;
    feeds: FeedResource[];
    refreshFeeds: () => Promise<void>;
    accounts: AccountResource[];
    refreshAccounts: () => Promise<unknown>;
    gitCredentials: GitCredentialResource[];
    refreshGitCredentials: () => Promise<unknown>;
}
export interface ProjectStepInputDependencies extends CommonStepInputDependencies {
    projectId: string;
    gitRef: GitRefResource | undefined;
    project: ProjectResource;
    processType: ProcessType;
    deploymentActionSlug: string;
    projectTriggers: TriggerResource[];
}
export interface ActionTemplateStepInputDependencies extends CommonStepInputDependencies {
    parameters: ActionTemplateParameterResource[];
    localNames: string[];
}
export type StepInputDependencies = ProjectStepInputDependencies | ActionTemplateStepInputDependencies;
export function isProjectStepInputDependencies(dependencies: StepInputDependencies): dependencies is ProjectStepInputDependencies {
    const key: keyof ProjectStepInputDependencies = "projectId";
    return key in dependencies;
}
