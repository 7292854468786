import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import React from "react";
import EditScheduledTriggers from "./EditScheduledTriggers";
interface EditDeploymentScheduledTriggerPageProps {
    spaceId: string;
    projectSlug: string;
    triggerId: string;
}
export function EditDeploymentScheduledTriggerPage({ spaceId, projectSlug, triggerId }: EditDeploymentScheduledTriggerPageProps) {
    return <EditScheduledTriggers create={false} triggerActionCategory={TriggerActionCategory.Deployment} spaceId={spaceId} projectSlug={projectSlug} triggerId={triggerId}/>;
}
