import { LinearProgress } from "@octopusdeploy/design-system-components";
import React from "react";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { CustomDialogContent, CustomDialogTitleBar, SmallDialogFrame } from "~/components/DialogLayout/Custom";
interface ProgressDialogProps {
    status: ProgressDialogStatus;
    title: string;
    content: string | React.ReactNode;
}
export type ProgressDialogStatus = {
    type: "in progress";
    percentComplete: number;
} | {
    type: "not in progress";
};
const ProgressDialog: React.FC<ProgressDialogProps> = ({ status, title, content }) => {
    const computedContent = typeof content === "string" ? <p>{content}</p> : content;
    return (<CustomDialog open={status.type === "in progress"} close={() => null} render={() => (<SmallDialogFrame>
                    <CustomDialogTitleBar title={title}/>
                    <CustomDialogContent>
                        {computedContent}
                        <LinearProgress show={true} variant={"determinate"} value={status.type === "in progress" ? status.percentComplete : 100}/>
                    </CustomDialogContent>
                </SmallDialogFrame>)}/>);
};
ProgressDialog.displayName = "ProgressDialog"
export default ProgressDialog;
