import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
import { useTrackEvent, useAnalyticSession } from "@octopusdeploy/portal-analytics";
import type { ThemePaletteType } from "~/theme/index";
export interface ThemeChangedOptions {
    selectedTheme: ThemePaletteType;
    previousTheme: ThemePaletteType;
}
export function useTrackThemeChanged() {
    const session = useAnalyticSession();
    const trackEvent = useTrackEvent();
    return (options: ThemeChangedOptions) => {
        session.setTheme(options.selectedTheme);
        trackEvent(createThemeChangedEvent(options));
    };
}
interface ThemeChangedEvent extends AnalyticsEvent<"Theme Changed"> {
    "Selected Theme": "light" | "dark";
    "Previous Theme": "light" | "dark";
}
function createThemeChangedEvent(options: ThemeChangedOptions): ThemeChangedEvent {
    return {
        eventName: "Theme Changed",
        "Previous Theme": options.previousTheme,
        "Selected Theme": options.selectedTheme,
    };
}
