import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectGitView } from "../../CommonComponents/CommonGitViews";
import { LearnMoreResources, TopicsContainer } from "../../CommonComponents/CommonViews";
export const ProjectVersionControlSettingsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().settings.versionControl,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectGitView />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return (<TopicsContainer>
                <LearnMoreResources>
                    <div>
                        <ExternalLink href="ConfigAsCodeConfiguration">Connecting projects to Git</ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="ConfigAsCodeMoving">Moving version control</ExternalLink>
                    </div>
                    <div>
                        <ExternalLink href="GitHubApp">GitHub App</ExternalLink>
                    </div>
                </LearnMoreResources>
            </TopicsContainer>);
    },
};
