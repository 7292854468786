/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import { ActionExecutionLocation, SpecialVariables } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "~/components/form";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
class HealthCheckActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        const healthCheckTypeName = this.humanise(this.props.properties[SpecialVariables.Action.HealthCheck.Type] as string);
        return (<div>
                {" "}
                Perform a {healthCheckTypeName}{" "}
                {this.props.targetRolesAsCSV && (<span>
                        for <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                    </span>)}
            </div>);
    }
    private humanise(type: string) {
        switch (type) {
            case "FullHealthCheck":
                return "full health check";
            case "ConnectionTest":
                return "connection check";
        }
        return "check";
    }
    static displayName = "HealthCheckActionSummary";
}
class HealthCheckActionEdit extends BaseComponent<ActionEditProps, never> {
    componentDidMount() {
        const properties = this.props.properties;
        this.props.setProperties({
            ["Octopus.Action.HealthCheck.Type"]: properties["Octopus.Action.HealthCheck.Type"] || "FullHealthCheck",
            ["Octopus.Action.HealthCheck.ErrorHandling"]: properties["Octopus.Action.HealthCheck.ErrorHandling"] || "TreatExceptionsAsErrors",
            ["Octopus.Action.HealthCheck.IncludeMachinesInDeployment"]: properties["Octopus.Action.HealthCheck.IncludeMachinesInDeployment"] || "DoNotAlterMachines",
        }, true);
    }
    render() {
        const properties = this.props.properties;
        return (<div>
                <ExpanderSectionHeading title="Health check"/>
                <ExpandableFormSection errorKey="Octopus.Action.HealthCheck.Type" isExpandedByDefault={this.props.expandedByDefault} title="Health check type" summary={properties["Octopus.Action.HealthCheck.Type"] === "FullHealthCheck" ? Summary.default("Perform a full health check") : Summary.summary("Perform a connection-only test")} help="Choose to perform a full health check or a connection-only test">
                    <RadioButtonGroup value={properties["Octopus.Action.HealthCheck.Type"] as string} onChange={(val) => this.props.setProperties({ ["Octopus.Action.HealthCheck.Type"]: val })}>
                        <RadioButton value="FullHealthCheck" label="Perform a full health check" isDefault={true}/>
                        <RadioButton value="ConnectionTest" label="Perform a connection-only test"/>
                    </RadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.HealthCheck.ErrorHandling" isExpandedByDefault={this.props.expandedByDefault} title="Health check errors" summary={properties["Octopus.Action.HealthCheck.ErrorHandling"] === "TreatExceptionsAsErrors" ? Summary.default("Fail the deployment if any targets are unavailable") : Summary.summary("Skip deployment targets that are unavailable")} help="Select which action to take on a health check error">
                    <RadioButtonGroup value={properties["Octopus.Action.HealthCheck.ErrorHandling"] as string} onChange={(val) => this.props.setProperties({ ["Octopus.Action.HealthCheck.ErrorHandling"]: val })}>
                        <RadioButton value="TreatExceptionsAsErrors" label="Fail the deployment" isDefault={true}/>
                        <RadioButton value="TreatExceptionsAsWarnings" label="Skip deployment targets that are unavailable"/>
                    </RadioButtonGroup>
                </ExpandableFormSection>

                <ExpanderSectionHeading title="Machine Selection"/>
                <ExpandableFormSection errorKey="Octopus.Action.HealthCheck.IncludeMachinesInDeployment" isExpandedByDefault={this.props.expandedByDefault} title="New Deployment Targets" summary={properties["Octopus.Action.HealthCheck.IncludeMachinesInDeployment"] === "DoNotAlterMachines"
                ? Summary.default("Ignore any newly available deployment targets")
                : Summary.summary("Include new deployment targets in the deployment")} help="Select which action to take on new machines.">
                    <RadioButtonGroup value={properties["Octopus.Action.HealthCheck.IncludeMachinesInDeployment"] as string} onChange={(val) => this.props.setProperties({ ["Octopus.Action.HealthCheck.IncludeMachinesInDeployment"]: val })}>
                        <RadioButton value="DoNotAlterMachines" label="Ignore any newly available deployment targets" isDefault={true}/>
                        <RadioButton value="IncludeCheckedMachines" label="Include new deployment targets in the deployment"/>
                    </RadioButtonGroup>
                </ExpandableFormSection>
            </div>);
    }
    static displayName = "HealthCheckActionEdit";
}
pluginRegistry.registerAction({
    actionType: "Octopus.HealthCheck",
    summary: (properties, targetRolesAsCSV) => <HealthCheckActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: { default: HealthCheckActionEdit },
    canHaveChildren: (step) => false,
    canBeChild: false,
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    canRunOnWorker: false,
    canRunInContainer: false,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false,
    canBeRetried: false,
    canUseExecutionTimeouts: false,
});
