import * as _ from "lodash";
import * as React from "react";
import type { ActionEditProps, ActionPlugin, FeaturePlugin } from "../Actions/pluginRegistry";
import { default as pluginRegistry } from "../Actions/pluginRegistry";
import { FormSectionHeading, Note, UnstructuredFormSection } from "../form";
import styles from "./style.module.less";
interface FeatureEditorPropsInternal extends ActionEditProps {
    enabledFeatures: string;
    openFeaturesElement?: JSX.Element;
    isNew: boolean;
    plugin: ActionPlugin;
    expandedByDefault: boolean;
}
type FeatureEditorProps = FeatureEditorPropsInternal;
interface FeatureEditorState {
    plugins: FeaturePlugin[];
}
//eslint-disable-next-line react/no-unsafe
export default class FeatureEditor extends React.Component<FeatureEditorProps, FeatureEditorState> {
    constructor(props: FeatureEditorProps) {
        super(props);
        this.state = {
            plugins: [],
        };
    }
    componentDidMount() {
        this.refreshPlugins(this.props.enabledFeatures);
    }
    UNSAFE_componentWillReceiveProps(newProps: FeatureEditorProps) {
        if (newProps.enabledFeatures !== this.props.enabledFeatures) {
            this.refreshPlugins(newProps.enabledFeatures);
        }
    }
    render() {
        return (<div>
                {this.state.plugins.map((plugin) => {
                return (<div key={plugin.title}>
                            <FormSectionHeading title={plugin.title}/>
                            <plugin.edit key={"featureeditor-" + plugin.featureName} plugin={this.props.plugin} projectId={this.props.projectId} gitRef={this.props.gitRef} localNames={this.props.localNames} properties={this.props.properties} packages={this.props.packages} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} setProperties={this.props.setProperties} getFieldError={this.props.getFieldError} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault}/>
                        </div>);
            })}
                <UnstructuredFormSection>
                    <div className={styles.featuresActionContainer}>
                        {this.props.openFeaturesElement && <div>{this.props.openFeaturesElement}</div>}
                        <Note>You can add or manage additional features used by this step.</Note>
                    </div>
                </UnstructuredFormSection>
            </div>);
    }
    private refreshPlugins(enabledFeatures: string) {
        const featureNames = (enabledFeatures || "").split(",");
        const allFeatures = pluginRegistry.getAllFeatures();
        const plugins = _.chain(allFeatures)
            .filter((feature) => featureNames.includes(feature.featureName))
            .sortBy((feature) => feature.priority)
            .value();
        this.setState({ plugins });
    }
    static displayName = "FeatureEditor";
}
