import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import type { GitCredentialResource } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useAnalyticTrackedActionDispatch, useAnalyticViewDispatch, useCorrelationId } from "~/analytics/Analytics";
import { createSaveGitCredentialsAnalyticsEvent } from "~/areas/library/components/GitCredentials/amplitudeAnalytics";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import useLocalStorage from "~/hooks/useLocalStorage";
import { DrawerWithForm } from "~/primitiveComponents/dataDisplay/DrawerWithForm/DrawerWithForm";
import { GitCredentialPageInternal } from "./GitCredentialPage";
export interface GitCredentialDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (gitCredential: GitCredentialResource) => void;
}
export function GitCredentialDrawer({ isOpen, onClose, onSave }: GitCredentialDrawerProps) {
    const [calloutDismissed, setCalloutDismissed] = useLocalStorage(`Octopus.Callout.Drawer.GitCredential.Dismissed`, false);
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchView = useAnalyticViewDispatch();
    const trackEvent = useTrackEvent();
    const correlationId = useCorrelationId();
    async function handleOnSave(gitCredential: GitCredentialResource, isNew: boolean) {
        onSave(gitCredential);
        trackEvent(createSaveGitCredentialsAnalyticsEvent(isNew ? "New" : "Existing"));
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    async function handleOnDelete() { }
    function onCloseCallout() {
        setCalloutDismissed(true);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spaceId = repository.spaceId!;
    return (<GitCredentialPageInternal spaceId={spaceId} gitCredentialId="" create={true} onSave={handleOnSave} onDelete={handleOnDelete} trackAction={trackAction} dispatchView={dispatchView} resetOnSave={true}>
            {({ formContent, createSaveAction, busy, errors, title, overflowActions }) => {
            const primaryAction = createSaveAction({});
            return (<DrawerWithForm isOpen={isOpen} onClose={onClose} busy={busy} errors={errors} onSubmit={() => {
                    if (primaryAction?.type === "button")
                        primaryAction.onClick(undefined);
                }} expandAllOnMount={true} showExpandAll={true} expandableContainerKey={"GitCredentialDrawer"} customActions={[
                    <ActionButton label="Cancel" onClick={onClose} type={ActionButtonType.Secondary}/>,
                    <ActionButton onClick={() => primaryAction.onClick(undefined)} label={primaryAction.label} type={ActionButtonType.Primary}></ActionButton>,
                ]} title={title} variant="customActions" correlationId={correlationId}>
                        {!calloutDismissed ? (<Callout type={"information"} title="Finalise Git credential" hideTitle={false} canClose={true} onClose={onCloseCallout}>
                                <div>
                                    After you create a new Git credential, you can find it in{" "}
                                    <InternalLink to={links.gitCredentialsPage.generateUrl({ spaceId })} openInSelf={false}>
                                        Git Credentials
                                    </InternalLink>
                                </div>
                            </Callout>) : (<></>)}
                        {formContent}
                    </DrawerWithForm>);
        }}
        </GitCredentialPageInternal>);
}
