import * as React from "react";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
import type { DeleteDialogLayoutInternalProps } from "~/components/Dialog/DeleteDialogLayout";
import Dialog from "~/components/Dialog/Dialog";
export type DeleteDialogProps = DeleteDialogLayoutInternalProps & {
    open: boolean;
};
//We need to ensure our dialog layout lives below the `Dialog` in the component hierarchy
//to ensure busy task errors bubble up to the correct error context as part of `DoBusyTask` usages.
const DeleteDialog = (props: DeleteDialogProps) => (<Dialog open={props.open || false}>
        <DeleteDialogLayout {...props}/>
    </Dialog>);
export default DeleteDialog;
