import React from "react";
import { getGitReferencePatternDescription } from "./getGitReferenceRulePatternsDescription";
export type GitReferenceRuleDescriptionProps = {
    rules: string[];
};
export function GitReferenceRuleDescription({ rules }: GitReferenceRuleDescriptionProps) {
    const gitReferenceRulesDescription = getGitReferencePatternDescription(rules);
    if (gitReferenceRulesDescription === undefined) {
        return <>Releases can be created from any Git reference</>;
    }
    return <>Releases can only be created from {gitReferenceRulesDescription}</>;
}
