import { Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
export function deprovisionProjectMenuItem(environmentId: string, projectId: string, projectName: string, onDeprovisionProject: (environmentId: string, projectId: string) => Promise<void>): MenuItem {
    return OverflowMenuItems.destructiveItem("Deprovision project", `Are you sure you want to deprovision project '${projectName}'?`, "Deprovision", "Deprovisioning", async () => {
        await onDeprovisionProject(environmentId, projectId);
        return true;
    }, <div>
            <p>This action will deprovision the project within this environment.</p>
            <p>Do you wish to continue?</p>
        </div>, { permission: Permission.EnvironmentDelete, environment: "*" });
}
