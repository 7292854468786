/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { ProjectResource, ChannelResource, ResourceCollection, LifecycleResource, DeploymentProcessRepository, DeploymentActionResource, ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, OctopusError, Permission } from "@octopusdeploy/octopus-server-client";
import { ProjectPaperLayout } from "app/areas/projects/components/ProjectPaperLayout";
import { flatMap } from "lodash";
import * as React from "react";
import type { match } from "react-router";
import { useRouteMatch } from "react-router";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { withProjectContext } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import List from "~/components/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import type { TagIndex } from "~/components/tenantTagsets";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import Channel from "./Channel";
interface ChannelsState extends DataBaseComponentState {
    project: ProjectResource;
    channelsResponse: ResourceCollection<ChannelResource>;
    lifecycles: Lifecycles;
    tagIndex: TagIndex;
    deploymentActions?: DeploymentActionResource[];
}
export interface Lifecycles {
    [name: string]: LifecycleResource;
}
class ChannelsList extends List<ChannelResource> {
}
interface ChannelsProps extends WithProjectContextInjectedProps {
    match: match<ProjectRouteParams>;
}
class ChannelsInternal extends DataBaseComponent<ChannelsProps, ChannelsState> {
    private match: match<ProjectRouteParams> = null!;
    constructor(props: ChannelsProps) {
        super(props);
        this.match = this.props.match;
        this.state = {
            project: null!,
            channelsResponse: null!,
            lifecycles: {},
            tagIndex: null!,
            deploymentActions: undefined,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { model: project, projectContextRepository, gitRefValidationError } = this.props.projectContext.state;
            await RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);
            const [channelsResponse, lifecycleResources, tagIndex, deploymentActions] = await Promise.all([
                repository.Projects.getChannels(project, 0, 30),
                repository.Lifecycles.all(),
                tenantTagsets.getTagIndex(),
                this.getDeploymentActionsWithErrorHandlingForGitProjects(project, projectContextRepository.DeploymentProcesses, gitRefValidationError),
            ]);
            const lifecycles: Lifecycles = {};
            lifecycleResources.forEach((lifecycle) => {
                lifecycles[lifecycle.Id] = lifecycle;
            });
            this.setState({
                channelsResponse,
                lifecycles,
                tagIndex,
                project,
                deploymentActions,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) });
    }
    async getDeploymentActionsWithErrorHandlingForGitProjects(project: ProjectResource, repository: DeploymentProcessRepository, gitRefValidationError: ValidateGitRefV2Response | undefined) {
        try {
            if (gitRefValidationError) {
                return [];
            }
            else {
                const process = await repository.get();
                return flatMap(process.Steps, (step) => step.Actions);
            }
        }
        catch (exception) {
            if (HasGitPersistenceSettings(project.PersistenceSettings) && OctopusError.isOctopusError(exception)) {
                // We only want to catch the error if it's a Git project and an Octopus exception. Otherwise, error
                // as usual
                this.setState((current) => ({ ...current, deploymentActionsError: exception }));
                return [];
            }
            else {
                throw exception;
            }
        }
    }
    render() {
        const primaryPageAction: PrimaryPageAction = {
            type: "navigate",
            label: "Add Channel",
            path: `${this.props.match.url}/create`,
            hasPermissions: isAllowed({ permission: Permission.ProcessEdit, project: this.state.project && this.state.project.Id, tenant: "*" }),
        };
        return (<ProjectPaperLayout busy={this.state.busy} errors={this.errors} title="Channels" primaryAction={primaryPageAction}>
                {this.state.channelsResponse && (<ChannelsList initialData={this.state.channelsResponse} onRow={(item) => this.buildRow(item)} onRowRedirectUrl={(channel: ChannelResource) => `${this.match.url}/edit/${channel.Id}`} onRowAccessibleName={(channel: ChannelResource) => `${channel.Name}`} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["partialName"]} filterHintText="Filter by name..."/>)}
            </ProjectPaperLayout>);
    }
    private filter(filter: string, resource: ChannelResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }
    private buildRow(channel: ChannelResource) {
        return <Channel key={channel.Id} channel={channel} project={this.state.project} lifecycles={this.state.lifecycles} tagIndex={this.state.tagIndex} deploymentActions={this.state.deploymentActions}/>;
    }
    static displayName = "ChannelsInternal";
}
export const Channels = withProjectContext(ChannelsInternal);
export const ChannelsWithMatch = () => {
    // Currently the Channels component requires the match to construct urls
    // This could be refactored to navigate properly using route parameter values
    // For the time being we just pass the match through to support the current implementation
    // This enables us to register this component against a page in the routing infrastructure
    const match = useRouteMatch<ProjectRouteParams>();
    return <Channels match={match!}/>;
};
