import { Alert as MaterialAlert } from "@material-ui/lab";
import type { PropsWithChildren } from "react";
import React from "react";
export interface AlertProps {
    severity: "error";
    onClose?(): void;
}
export function Alert({ children, onClose, severity }: PropsWithChildren<AlertProps>) {
    return (<MaterialAlert variant="filled" severity={severity} onClose={onClose} icon={false}>
            {children}
        </MaterialAlert>);
}
