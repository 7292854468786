import cn from "classnames";
import * as React from "react";
import { TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import { DefaultOverview, DefaultTopics } from "./Registrations/CommonComponents/DefaultViews";
import pageHelpRegistry from "./Registry/pageHelpRegistry";
interface PageHelpViewProps {
    className?: string;
    pageId: string | undefined;
}
export const PageHelpOverviewView: React.FC<PageHelpViewProps> = ({ pageId, className }) => {
    const registrations = usePageHelpRegistrationsForPage(pageId);
    if (!registrations || registrations.length === 0) {
        return <DefaultOverview />;
    }
    return (<div className={cn(className)}>
            {registrations.map((x) => {
            const element = x.renderOverview({
                registration: x,
            });
            return element ? React.cloneElement(element, { key: x.page.Id }) : <DefaultOverview key={x.page.Id}/>;
        })}
        </div>);
};
PageHelpOverviewView.displayName = "PageHelpOverviewView"
export const PageHelpTopicsView: React.FC<PageHelpViewProps> = ({ pageId, className }) => {
    const registrations = usePageHelpRegistrationsForPage(pageId);
    if (!registrations || registrations.length === 0) {
        return (<>
                <DefaultTopics />
            </>);
    }
    return (<div className={cn(className)}>
            {registrations.map((x, index) => {
            const element = x.renderTopics({
                registration: x,
            });
            if (!element) {
                return <TopicsContainer />;
            }
            const elementToReturn = element ? React.cloneElement(element, { key: x.page.Id }) : <DefaultTopics />;
            return <div key={index}>{elementToReturn}</div>;
        })}
        </div>);
};
PageHelpTopicsView.displayName = "PageHelpTopicsView"
function usePageHelpRegistrationsForPage(pageId: string | undefined) {
    return React.useMemo(() => pageHelpRegistry.getAllRegistrations().filter((x) => pageId === x.page.Id), [pageId]);
}
