import type * as React from "react";
import type { SpaceAwareNavigation } from "./SpaceAwareNavigation/SpaceAwareNavigation";
import { useSpaceAwareNavigation } from "./SpaceAwareNavigation/useSpaceAwareNavigation";
interface NavigateProps {
    render: (navigation: SpaceAwareNavigation) => React.ReactElement | null;
}
export const Navigate: React.FC<NavigateProps> = ({ render }) => {
    const navigation = useSpaceAwareNavigation();
    return render(navigation);
};
Navigate.displayName = "Navigate"
export default Navigate;
