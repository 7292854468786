import type { ProjectContextRepository, AccountResource, IProcessResource, ActionTemplateSearchResource, FeedResource, GitCredentialResource, GitRefResource, ProjectResource, BlueprintResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import { useRequiredContext } from "~/hooks";
import { useBoundDispatch } from "~/utils/Reducers";
import type { ProcessContextModelState, ProcessPageSupportedActions } from "../types";
import type { ProcessActions, ProcessStateSelectors } from "./ProcessContextState";
import { actions } from "./ProcessContextState";
export interface ProcessContextLookupState {
    actionTemplates: ActionTemplateSearchResource[] | "NotLoaded";
    feeds: FeedResource[] | "NotLoaded";
    accounts: AccountResource[];
    gitCredentials: GitCredentialResource[];
    blueprints: BlueprintResource[] | "NotLoaded";
}
export type ProcessContextProps = {
    state: ProcessContextModelState;
    actions: ProcessContextProviderSetupActions;
    selectors: ProcessStateSelectors;
};
export const ProcessContext = React.createContext<ProcessContextProps | undefined>(undefined);
export const useProcessContext = () => {
    return useRequiredContext(ProcessContext, "Process");
};
export const useOptionalProcessContext = () => {
    return React.useContext(ProcessContext);
};
export const loadProcess = (projectContextRepository: ProjectContextRepository, processType: ProcessType, id: string, project: ProjectResource, gitRef?: Readonly<GitRefResource>): Promise<IProcessResource> => {
    if (processType === ProcessType.Deployment) {
        return projectContextRepository.DeploymentProcesses.get();
    }
    else if (processType === ProcessType.Runbook) {
        const isGitRunbook = HasRunbooksInGit(project.PersistenceSettings) && gitRef;
        if (isGitRunbook) {
            return repository.Runbooks.getRunbookProcess(project, id, gitRef.CanonicalName);
        }
        else {
            return repository.Runbooks.getRunbookProcess(project, id);
        }
    }
    else if (processType === ProcessType.Blueprint) {
        return repository.Blueprints.get(id).then((blueprint) => ({
            ...blueprint,
            SpaceId: project.SpaceId,
            Version: 0,
            Links: {
                Self: "",
                Web: "",
                Project: "",
                Template: "",
            },
        }));
    }
    throw new Error("Unknown scope provided");
};
export interface ProcessContextProviderSetup {
    lookupsState: ProcessContextLookupState;
    state: ProcessContextModelState;
    setState: React.Dispatch<React.SetStateAction<ProcessContextLookupState>>;
    actions: ProcessContextProviderSetupActions;
    selectors: ProcessStateSelectors;
}
type BoundActionsType = Omit<ReturnType<typeof useBoundProcessActions>, "setProcess" | "conflictDetected">;
export interface ProcessContextProviderSetupActions extends ProcessPageSupportedActions, BoundActionsType {
    saveOnServer: (projectContextRepository: ProjectContextRepository, process: IProcessResource, onError: (errors: Errors) => void, onSuccess: () => void, gitRef?: string) => Promise<IProcessResource | null>;
    refreshFromServer: () => Promise<boolean>;
    setProcess: (process: IProcessResource, updateCleanModel: boolean) => Promise<void>;
    conflictDetected: (serverProcess: IProcessResource, stagedProcess: IProcessResource) => Promise<void>;
}
export const useBoundProcessActions = (dispatch: React.Dispatch<ProcessActions>) => {
    return useBoundDispatch(dispatch, actions);
};
export interface WithProcessContextInjectedProps {
    processContext: ProcessContextProps;
}
export function withProcessContext<T>(Component: React.ComponentType<T & WithProcessContextInjectedProps>) {
    const WithProcessContext: React.FC<T> = (props) => {
        const context = useProcessContext();
        return <Component processContext={context} {...props}/>;
    };
    WithProcessContext.displayName = "WithProcessContext"
    return WithProcessContext;
}
export type WithOptionalProcessContextInjectedProps = Partial<WithProcessContextInjectedProps>;
export function withOptionalProcessContext<T>(Component: React.ComponentType<T & WithOptionalProcessContextInjectedProps>) {
    const WithOptionalProcessContext: React.FC<T> = (props) => {
        const context = useOptionalProcessContext();
        return <Component processContext={context} {...props}/>;
    };
    WithOptionalProcessContext.displayName = "WithOptionalProcessContext"
    return WithOptionalProcessContext;
}
