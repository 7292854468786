import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryStepTemplateOverview } from "../../CommonComponents/CommonLibraryStepTemplateViews";
export const LibraryStepTemplateRunRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.stepTemplate.run,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryStepTemplateOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
