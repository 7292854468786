import * as React from "react";
import VariableCell from "~/areas/variables/VariableCell/VariableCell";
import ToolTipMessages from "~/primitiveComponents/dataDisplay/ToolTipMessages/ToolTipMessages";
import styles from "./style.module.less";
interface VariableMessageCellProps {
    warningMessages?: ReadonlyArray<string>;
}
// This cell may appear in the same column as the the VariableNameCell, so the warnings for both of these cells should be aligned
const VariableMessageCell: React.SFC<VariableMessageCellProps> = (props: VariableMessageCellProps) => {
    return (<VariableCell className={styles.container}>
            <div className={styles.spacer}/>
            <ToolTipMessages warningMessages={props.warningMessages}/>
        </VariableCell>);
};
VariableMessageCell.displayName = "VariableMessageCell"
export default VariableMessageCell;
