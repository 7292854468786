import type { VariableType, ScopeValues } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { FocusableCellType } from "~/areas/variables/CellFocus/CellFocus";
import type { FocusField } from "~/areas/variables/EditVariableDialog/EditVariableDialog";
import type SensitiveFieldStates from "~/areas/variables/SensitiveFieldStates";
import SingleVariableRow, { SingleVariableRowHeight } from "~/areas/variables/SingleVariableRow/SingleVariableRow";
import VariableHeaderRow, { VariableHeaderRowHeight } from "~/areas/variables/VariableHeaderRow/VariableHeaderRow";
import type { VariableMessages, ValueMessages } from "~/areas/variables/VariableMessages/VariableMessages";
import VariableMultiValueRow, { VariableMultiValueRowHeight } from "~/areas/variables/VariableMultiValueRow/VariableMultiValueRow";
import { VariableStatus } from "~/areas/variables/VariableStatusIcon";
import type { VariableModel } from "~/areas/variables/VariablesModel/VariablesModel";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import type { CertificateIndex } from "~/components/certificates";
import type { SensitiveState } from "~/components/form/Sensitive/Sensitive";
import type { TagIndex } from "~/components/tenantTagsets";
import type { CellAligner } from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import type { BorderCss } from "~/utils/BorderCss/BorderCss";
import type { WorkerPoolIndex } from "../../../components/workerPools";
import type { VariableValueModel } from "../VariablesModel";
import styles from "./style.module.less";
export interface VariableRowRenderProps {
    variable: VariableModel;
    variableIndex: number;
    values: ReadonlyArray<VariableValueModel>;
    valueMessages: ReadonlyArray<ValueMessages>;
    availableScopes: ScopeValues;
    sensitiveFieldStates: SensitiveFieldStates;
    tagIndex: TagIndex;
    certificateIndex: CertificateIndex;
    poolIndex: WorkerPoolIndex;
    variableMessages: VariableMessages;
    focus?: {
        variableId: string;
        cell: FocusableCellType;
    };
    gitVariables: boolean;
    doBusyTask: DoBusyTask;
    scopeCellWidth: number | undefined;
    getValueStatus(variableValue: VariableValueModel): VariableStatus;
    getExistingVariable(value: VariableValueModel): VariableValueModel | undefined;
    onDuplicateVariable(variable: VariableModel): void;
    onDuplicateValue(value: VariableValueModel): void;
    onAddValue(variable: VariableModel, selectedValue: VariableValueModel): void;
    onResetChanges(v: VariableValueModel): void;
    onDeleteValue(value: VariableValueModel): void;
    undoDeleteValue(value: VariableValueModel): void;
    openVariableEditor(value: VariableValueModel, name: string, focus?: FocusField): void;
    changingToReferenceType(value: VariableValueModel, name: string, refrenceType: VariableType): void;
    onBlur(value: VariableValueModel, blurredFrom: FocusableCellType): void;
    onFocus(value: VariableValueModel, focus?: FocusableCellType): void;
    onMergeClicked(variable: VariableModel, value: VariableValueModel): void;
    rename(variable: VariableModel): void;
    onNameChanged(variable: VariableModel, name: string): void;
    onValueChanged(value: VariableValueModel): void;
    onNavigateUp(value: VariableValueModel): void;
    onNavigateDown(value: VariableValueModel): void;
    onSensitiveStateChanged(value: VariableValueModel, state: SensitiveState): void;
}
// N.B. For performance reasons, it is important that the properties passed to variable row don't change between renders if possible
// So be careful to avoid using lambdas here
export default function getVariableRowRenderers(props: VariableRowRenderProps): ReadonlyArray<VariableRowRenderer> {
    const isVariableDeleted = props.variable.values.every((v) => props.getValueStatus(v) === VariableStatus.Deleted);
    const isMultiValueVariable = props.variable.values.length > 1;
    const alternateRow = props.variableIndex % 2 !== 0;
    if (isMultiValueVariable) {
        return [createVariableHeaderRowRenderer(), ...createVariableValueRowRenderers()];
    }
    return [createSingleVariableRowRenderer()];
    function createVariableHeaderRowRenderer(): VariableRowRenderer {
        return {
            height: VariableHeaderRowHeight,
            render(cellAligner: CellAligner): React.ReactNode {
                const isNameCellFocused = props.focus && props.focus.variableId === props.variable.values[0].Id && props.focus.cell === FocusableCellType.Name;
                const variableGroupHeaderRow = (<VariableHeaderRow isVariableDeleted={isVariableDeleted} variable={props.variable} value={props.variable.values[0]} variableMessages={props.variableMessages} cellAligner={cellAligner} onDuplicateVariable={props.onDuplicateVariable} onAddValue={props.onAddValue} onResetChanges={props.onResetChanges} onDeleteValue={props.onDeleteValue} undoDeleteValue={props.undoDeleteValue} openVariableEditor={props.openVariableEditor} isNameCellFocused={isNameCellFocused} onFocus={props.onFocus} onBlur={props.onBlur} onMergeClicked={props.onMergeClicked} onDontMergeClicked={props.rename} onNameChanged={props.onNameChanged} onNavigateUp={props.onNavigateUp} onNavigateDown={props.onNavigateDown} variableStatuses={props.variable.values.map((v) => props.getValueStatus(v))} showOverflowMenu={props.variable.values.some((v) => v.IsEditable)} allValuesEditable={props.variable.values.every((v) => v.IsEditable)}/>);
                return <div className={alternateRow ? styles.alternativeGroup : null}>{variableGroupHeaderRow}</div>;
            },
        };
    }
    function createVariableValueRowRenderers(): VariableRowRenderer[] {
        return props.values.map((value, index) => {
            return {
                height: VariableMultiValueRowHeight,
                render(cellAligner: CellAligner, isVisible: boolean, isDisplayedFullWidth: boolean, borderStyle: BorderCss): React.ReactNode {
                    const valueMessages = props.valueMessages[index];
                    const existingVariable = props.getExistingVariable(value);
                    const valueRow = (<VariableMultiValueRow status={props.getValueStatus(value)} sensitiveState={props.sensitiveFieldStates[value.Id]} existingValue={existingVariable} isVariableDeleted={isVariableDeleted} availableScopes={props.availableScopes} tagIndex={props.tagIndex} certificateIndex={props.certificateIndex} poolIndex={props.poolIndex} variable={props.variable} value={value} variableMessages={props.variableMessages} valueMessages={valueMessages} showNameCell={!isMultiValueVariable} gitVariables={props.gitVariables} cellAligner={cellAligner} doBusyTask={props.doBusyTask} borderStyle={borderStyle} onDuplicateVariable={props.onDuplicateVariable} onDuplicate={props.values.length > 1 ? props.onDuplicateValue : undefined} onAddValue={props.onAddValue} onResetChanges={props.onResetChanges} onDeleteValue={props.onDeleteValue} undoDelete={props.undoDeleteValue} openVariableEditor={props.openVariableEditor} changingToReferenceType={props.changingToReferenceType} focus={props.focus && props.focus.variableId === value.Id ? props.focus.cell : undefined} onFocus={props.onFocus} onBlur={props.onBlur} onMergeClicked={props.onMergeClicked} onDontMergeClicked={props.rename} onNameChanged={props.onNameChanged} onValueChanged={props.onValueChanged} onNavigateUp={props.onNavigateUp} onNavigateDown={props.onNavigateDown} onSensitiveStateChanged={props.onSensitiveStateChanged} scopeCellWidth={props.scopeCellWidth}/>);
                    return (<div className={alternateRow ? styles.alternativeGroup : null} style={{ borderBottom: index === props.values.length - 1 ? borderStyle.borderCssString : undefined }}>
                            {valueRow}
                        </div>);
                },
            };
        });
    }
    function createSingleVariableRowRenderer(): VariableRowRenderer {
        const index = 0;
        const value = props.values[index];
        return {
            height: SingleVariableRowHeight,
            render(cellAligner: CellAligner, isVisible: boolean, isDisplayedFullWidth: boolean, borderStyle: BorderCss): React.ReactNode {
                const valueMessages = props.valueMessages[index];
                const existingVariable = props.getExistingVariable(value);
                const variableRow = (<SingleVariableRow status={props.getValueStatus(value)} sensitiveState={props.sensitiveFieldStates[value.Id]} existingValue={existingVariable} isVariableDeleted={isVariableDeleted} availableScopes={props.availableScopes} tagIndex={props.tagIndex} certificateIndex={props.certificateIndex} poolIndex={props.poolIndex} variable={props.variable} value={value} variableMessages={props.variableMessages} valueMessages={valueMessages} showNameCell={!isMultiValueVariable} gitVariables={props.gitVariables} cellAligner={cellAligner} doBusyTask={props.doBusyTask} onDuplicateVariable={props.onDuplicateVariable} onDuplicate={props.values.length > 1 ? props.onDuplicateValue : undefined} onAddValue={props.onAddValue} onResetChanges={props.onResetChanges} onDelete={props.onDeleteValue} undoDelete={props.undoDeleteValue} openVariableEditor={props.openVariableEditor} changingToReferenceType={props.changingToReferenceType} focus={props.focus && props.focus.variableId === value.Id ? props.focus.cell : undefined} onFocus={props.onFocus} onBlur={props.onBlur} onMergeClicked={props.onMergeClicked} onDontMergeClicked={props.rename} onNameChanged={props.onNameChanged} onVariableChanged={props.onValueChanged} onNavigateUp={props.onNavigateUp} onNavigateDown={props.onNavigateDown} onSensitiveStateChanged={props.onSensitiveStateChanged} scopeCellWidth={props.scopeCellWidth}/>);
                return (<div className={alternateRow ? styles.alternativeGroup : null} style={{ borderBottom: borderStyle.borderCssString }}>
                        {variableRow}
                    </div>);
            },
        };
    }
}
export interface VariableRowRenderer {
    height: number;
    render(cellAligner: CellAligner, isVisible: boolean, isDisplayedFullWidth: boolean, borderStyle: BorderCss, columnWidthsInPercent: ReadonlyArray<number>): React.ReactNode;
}
