import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import HelpImageEnvironments from "../../Images/Environments.svg";
import { TopicsContainer, OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
const video = {
    youtubeId: "tPb6CLHyNLA",
    shortlink: "OnboardingEnvironmentsVideo",
};
export const CommonEnvironmentOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    An <ExternalLink href="OnboardingEnvironmentsLearnMore">environment</ExternalLink> groups together all the deployment targets used to host your software application, like a web server and a database server.
                </p>
                <p>
                    Octopus deploys releases to environments. For instance, from <i>Development</i> to <i>Test</i> and finally into <i>Production</i>.
                </p>
                <ImageWithPlaceholder src={HelpImageEnvironments} alt={"Environments"}/>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Environments and lifecycles"}>
                    Ultimately, environments will be used in a <ExternalLink href="OnboardingLifecycles">lifecycle</ExternalLink> which will define the deployment pipeline for your software. The default lifecycle will automatically add your
                    environments as phases, in the order they appear on the environments page.
                </Note>
            </TermsText>
        </OverviewContainer>);
};
export const CommonEnvironmentTopics = () => {
    return <TopicsContainer />;
};
