/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Checkbox } from "@octopusdeploy/design-system-components";
import type { LetsEncryptConfigurationResource, ServerConfigurationResource, ConfigureLetsEncryptArguments, ServerConfigurationSettingsSetResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { flatten } from "lodash";
import * as React from "react";
import URI from "urijs";
import { repository } from "~/clientInstance";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import { default as NoActionDialog } from "~/components/Dialog/NoActionDialog";
import ErrorPanel from "~/components/ErrorPanel";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent/FormBaseComponent";
import { Form } from "~/components/FormPaperLayout/Form";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { Section } from "~/components/Section/Section";
import { Summary } from "~/components/form";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Text from "~/primitiveComponents/form/Text/Text";
import { isLetsEncryptSupported, letsEncryptNotSupportedErrorMessage } from "./LetsEncryptPage";
// test scenarios
// * try and bind on http://localhost                                                                       -> should prevent
// * try and bind on http://127.0.0.1                                                                       -> should prevent
// * try and bind on http://foo.somedomain.com, with no https://foo.somedomain.com binding                  -> should show warning about restarting server
// * try and bind on http://foo.somedomain.com/octopus, with http://foo.somedomain.com binding              -> should show warning about restarting server
// * try and bind on http://foo.somedomain.com, with https://foo.somedomain.com binding                     -> should just work
// * try and bind on http://foo.somedomain.com/Octopus, with no http://foo.somedomain.com binding           -> should show warning about not listening on 80
// * try and bind on https://foo.somedomain.com/Octopus, with no http://foo.somedomain.com binding          -> should show warning about not listening on 80
// * try and bind on https://foo.somedomain.com/Octopus, with http://foo.somedomain.com/Octopus binding     -> should show warning about not listening on 80
// * try and bind on https://foo.somedomain.com/Octopus, with http://foo.somedomain.com binding             -> should just work
// * try and bind on https://foo.somedomain.com, with http://foo.somedomain.com binding                     -> should just work
// * try and bind on https://foo.somedomain.com, with http://foo.somedomain.com:80 binding                  -> should just work
// * try and bind on https://foo.somedomain.com, with no http://foo.somedomain.com binding                  -> should show warning about not listening on 80
// * try and bind on https://foo.somedomain.com:8066, with no http://foo.somedomain.com binding             -> should show warning about not listening on 80
interface ConfigureLetsEncryptState extends OptionalFormBaseComponentState<ConfigureLetsEncryptArguments> {
    letsEncryptConfiguration?: LetsEncryptConfigurationResource;
    serverConfiguration?: ServerConfigurationResource;
    bindings?: string[];
    redirectToTaskId?: string;
    showLocalHostError?: boolean;
    showNotListeningOn80Warning?: boolean;
    confirmRestart?: boolean;
}
export class ConfigureLetsEncryptPage extends FormBaseComponent<{}, ConfigureLetsEncryptState, ConfigureLetsEncryptArguments> {
    static getBindings(serverConfiguration: ServerConfigurationSettingsSetResource[]) {
        const values = flatten(serverConfiguration.map((c) => c.ConfigurationValues));
        const listenPrefixes = values.filter((v) => v.Key === "Octopus.WebPortal.ListenPrefixes")[0];
        return listenPrefixes
            ? listenPrefixes.Value.split(",")
                .filter((prefix) => !!prefix && prefix !== "")
                .map((prefix) => prefix.replace(/\/$/, ""))
            : [];
    }
    static getBinding(args: ConfigureLetsEncryptArguments) {
        let url = "https://" + args.DnsName;
        if (args.HttpsPort !== 443) {
            url = url + ":" + args.HttpsPort;
        }
        if (args.Path !== undefined) {
            url = url + args.Path;
        }
        return url;
    }
    static guessDnsNameAndPath(bindings: string[]) {
        const binding = bindings.map((b) => URI(b)).filter((h) => h.hostname().toLowerCase() !== "localhost")[0];
        return {
            dnsName: binding && binding.hostname(),
            path: binding && binding.path(),
        };
    }
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        if (isLetsEncryptSupported())
            return this.doBusyTask(async () => {
                const [config, settings] = await Promise.all([repository.LetsEncryptConfiguration.get(), repository.ServerConfiguration.settings()]);
                const bindings = ConfigureLetsEncryptPage.getBindings(settings);
                const defaultDnsNameAndPath = ConfigureLetsEncryptPage.guessDnsNameAndPath(bindings);
                const args: ConfigureLetsEncryptArguments = {
                    DnsName: config.DnsName || defaultDnsNameAndPath.dnsName || "",
                    RegistrationEmailAddress: config.RegistrationEmailAddress || "",
                    AcceptLetsEncryptTermsOfService: config.AcceptLetsEncryptTermsOfService || false,
                    HttpsPort: config.HttpsPort || 443,
                    IPAddress: config.IPAddress || "0.0.0.0",
                    Path: config.Path || defaultDnsNameAndPath.path || "",
                };
                this.setState({
                    letsEncryptConfiguration: config,
                    bindings,
                    model: args,
                });
            });
    }
    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        const title = "Configure Let's Encrypt";
        if (!isLetsEncryptSupported()) {
            return (<PaperLayoutVNext title={title}>
                    <ErrorPanel message={letsEncryptNotSupportedErrorMessage}/>
                </PaperLayoutVNext>);
        }
        const body = this.state.letsEncryptConfiguration && (<div>
                <Section>
                    <h4>Register with Let's Encrypt and setup auto-renewing SSL certificates for the Octopus Portal</h4>
                </Section>
                <ExpandableFormSection errorKey="DnsName" title="DNS Name" focusOnExpandAll summary={this.state.model!.DnsName ? Summary.summary(this.state.model!.DnsName) : Summary.placeholder("No DNS name specified")} help={<span>
                            The DNS name that the server should listen on for HTTPS requests. Do not include a prefix such as 'https://'.
                            <br />
                            <strong>Note:</strong> Let's Encrypt must be able to communicate with this DNS name on port 80.
                        </span>}>
                    <Text label="DNS Name" value={this.state.model!.DnsName} autoFocus onChange={(DnsName) => this.setModelState({ DnsName })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Path" title="Virtual Directory" summary={this.state.model!.DnsName ? Summary.summary(ConfigureLetsEncryptPage.getBinding(this.state.model!)) : Summary.placeholder("Specify a DNS name as well")} help="The virtual directory that the Octopus Portal should be available on. Leave blank for no virtual directory.">
                    <Text label="Path" value={this.state.model!.Path} onChange={(Path) => this.setModelState({ Path })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="HttpsPort" title="HTTPS Port" summary={this.state.model!.HttpsPort ? Summary.summary(this.state.model!.HttpsPort) : Summary.placeholder("No port specified")} help="The port that the server should listen on for HTTPS requests.">
                    <Text label="HTTPS Port" value={this.state.model!.HttpsPort.toString()} onChange={(v) => this.setModelState({ HttpsPort: !!v ? parseInt(v, 10) : null! })} type="number" min={0}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="AcceptLetsEncryptTermsOfService" title="Accept ToS" summary={this.state.model!.AcceptLetsEncryptTermsOfService ? Summary.summary("Terms of Service have been accepted") : Summary.placeholder("Terms of Service have not been accepted")} help={<span>
                            You must agree to the <ExternalLink href="LetsEncryptTermsOfService">Let's Encrypt Terms of Service</ExternalLink>.
                        </span>}>
                    <Checkbox label="I agree to the Let's Encrypt Terms of Service." value={this.state.model!.AcceptLetsEncryptTermsOfService} onChange={(v) => this.setModelState({ AcceptLetsEncryptTermsOfService: !!v })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="RegistrationEmailAddress" title="Registration Email" summary={this.state.model!.RegistrationEmailAddress ? Summary.summary(this.state.model!.RegistrationEmailAddress) : Summary.placeholder("No email specified")} help="The email address with which to register with Let's Encrypt.">
                    <Text type="email" label="Registration email" value={this.state.model!.RegistrationEmailAddress} onChange={(RegistrationEmailAddress) => this.setModelState({ RegistrationEmailAddress })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="IPAddress" title="IP Address" summary={this.state.model!.IPAddress === "0.0.0.0" ? Summary.summary("All IP Addresses") : Summary.summary(this.state.model!.IPAddress)} help={<span>
                            The IP address to which the certificate should be bound.
                            <br />
                            <strong>Note:</strong> The default is 0.0.0.0 which binds the certificate to all IP addresses on the server.
                        </span>}>
                    <Text label="IP address" value={this.state.model!.IPAddress} onChange={(IPAddress) => this.setModelState({ IPAddress })}/>
                </ExpandableFormSection>
            </div>);
        const localHostErrorDialog = (<NoActionDialog title="Cannot Use Localhost" open={this.state.showLocalHostError!} onCloseClick={() => this.setState({ showLocalHostError: false })}>
                <p>Let's Encrypt cannot use localhost as the binding. Let's Encrypt needs to communicate with the Octopus Portal to validate domain control.</p>
                <p>Please select a non-localhost binding.</p>
            </NoActionDialog>);
        const showNotListeningOn80Warning = this.state.model && (<ConfirmationDialog title="Not Listening on HTTP Port 80" continueButtonLabel="Continue Anyway" continueButtonBusyLabel="Queuing task..." open={this.state.showNotListeningOn80Warning!} onClose={() => this.setState({ showNotListeningOn80Warning: false })} onContinueClick={() => this.continueAfterPort80Warning()}>
                <p>The Octopus Portal is not currently listening on {this.getHttpUrl()} on port 80. Let's Encrypt communicates with the Portal over the HTTP protocol to validate domain control.</p>
                <p>Please cancel and use the Octopus Server Manager to setup a HTTP binding for this url.</p>
            </ConfirmationDialog>);
        const restartServerWarningDialog = this.state.model && (<ConfirmationDialog title="Restart Server?" continueButtonLabel="Continue" continueButtonBusyLabel="Queuing task..." open={this.state.confirmRestart!} onClose={() => this.setState({ confirmRestart: false })} onContinueClick={() => this.save()}>
                <p>Applying the new certificate from Let's Encrypt will require a restart of the Octopus Server, as the Portal is not currently listening on {ConfigureLetsEncryptPage.getBinding(this.state.model)}.</p>
                <p>Are you sure you want to continue?</p>
            </ConfirmationDialog>);
        return (<Form model={this.state.model} cleanModel={this.state.cleanModel} saveText={null!} savePermission={{ permission: Permission.AdministerSystem }} onSaveClick={() => this.doBusyTask(() => this.onSaveClick()!)}>
                {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({
                    saveButtonLabel: "Register",
                    saveButtonBusyLabel: "Registering...",
                })} title={title} busy={this.state.busy} errors={this.errors}>
                        <FormContent expandAllOnMount={this.state.letsEncryptConfiguration ? !this.state.letsEncryptConfiguration.Enabled : false}>
                            {body}
                            {localHostErrorDialog}
                            {showNotListeningOn80Warning}
                            {restartServerWarningDialog}
                        </FormContent>
                    </PaperLayoutVNext>)}
            </Form>);
    }
    getHttpUrl() {
        const binding = ConfigureLetsEncryptPage.getBinding(this.state.model!);
        return "http://" + URI(binding).hostname();
    }
    onSaveClick() {
        const httpUrl = this.getHttpUrl();
        if (httpUrl === "http://localhost" || httpUrl === "http://127.0.0.1") {
            this.setState({ showLocalHostError: true });
            return;
        }
        if (!this.isServerListeningOn(httpUrl)) {
            this.setState({ showNotListeningOn80Warning: true });
            return;
        }
        return this.continueAfterPort80Warning();
    }
    continueAfterPort80Warning() {
        const binding = ConfigureLetsEncryptPage.getBinding(this.state.model!);
        if (this.willCauseServerRestart(binding)) {
            this.setState({ confirmRestart: true });
            return;
        }
        return this.save();
    }
    async save() {
        const task = await repository.Tasks.createConfigureLetsEncryptTask(this.state.model!);
        this.setState({ redirectToTaskId: task.Id });
    }
    isServerListeningOn(httpUrl: string) {
        return this.state.bindings!.filter((b) => URI(b).equals(URI(httpUrl))).length > 0 || this.state.bindings!.filter((b) => URI(b).equals(URI("http://localhost"))).length > 0;
    }
    willCauseServerRestart(url: string) {
        return this.state.bindings!.filter((b) => URI(b).equals(URI(url))).length === 0;
    }
    static displayName = "ConfigureLetsEncryptPage";
}
