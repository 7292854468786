/* eslint-disable @typescript-eslint/no-explicit-any */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { GoogleCloudAuthenticationProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { ExpandableFormSection, Summary } from "~/components/form";
import { GoogleCloudAccountVariableSelect } from "~/components/form/AccountSelect/AccountVariableSelect";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import Note from "~/primitiveComponents/form/Note/Note";
export default abstract class GoogleCloudAuthenticationComponent extends BaseComponent<ActionEditProps<GoogleCloudAuthenticationProperties, any>, never> {
    static GoogleCloudLoginComponent: React.FC<ActionEditProps<GoogleCloudAuthenticationProperties, any>> = (props) => {
        const properties = props.properties;
        const useVMServiceAccount = properties["Octopus.Action.GoogleCloud.UseVMServiceAccount"];
        const impersonateServiceAccount = properties["Octopus.Action.GoogleCloud.ImpersonateServiceAccount"];
        return (<React.Fragment>
                <RadioButtonGroup value={useVMServiceAccount} onChange={(x) => {
                props.setProperties({ ["Octopus.Action.GoogleCloud.UseVMServiceAccount"]: x });
                props.setProperties({ ["Octopus.Action.GoogleCloudAccount.Variable"]: "" });
            }} title="When running in a Compute Engine virtual machine, use the associated VM service account">
                    <RadioButton value={"True"} label="Yes"/>
                    <RadioButton value={"False"} label="No"/>
                </RadioButtonGroup>

                {useVMServiceAccount === "False" &&
                (props.projectId ? (<GoogleCloudAccountVariableSelect projectId={props.projectId} gitRef={props.gitRef} allowClear={true} value={properties["Octopus.Action.GoogleCloudAccount.Variable"]} onChange={(val) => props.setProperties({ ["Octopus.Action.GoogleCloudAccount.Variable"]: val })}/>) : (<VariableLookupText label="Google Cloud Account variable" localNames={props.localNames} value={props.properties["Octopus.Action.GoogleCloudAccount.Variable"]} onChange={(val) => props.setProperties({ ["Octopus.Action.GoogleCloudAccount.Variable"]: val })}/>))}

                <RadioButtonGroup value={impersonateServiceAccount} onChange={(x) => {
                props.setProperties({ ["Octopus.Action.GoogleCloud.ImpersonateServiceAccount"]: x });
            }} title="Impersonate service accounts">
                    <RadioButton value={"True"} label="Yes"/>
                    <RadioButton value={"False"} label="No"/>
                </RadioButtonGroup>
                <Note>
                    Learn more about <ExternalLink href="GCPImpersonateServiceAccount">Impersonating Service Accounts</ExternalLink>.
                </Note>

                {impersonateServiceAccount === "True" && (<VariableLookupText localNames={props.localNames} value={properties["Octopus.Action.GoogleCloud.ServiceAccountEmails"]} label="Service Account Emails" onChange={(val) => props.setProperties({ ["Octopus.Action.GoogleCloud.ServiceAccountEmails"]: val })} error={props.getFieldError("Octopus.Action.GoogleCloud.ServiceAccountEmails")}/>)}
            </React.Fragment>);
    };
    private getAccountSummary() {
        const properties = this.props.properties;
        const useVMServiceAccount = properties["Octopus.Action.GoogleCloud.UseVMServiceAccount"] === "True";
        const accountVariable = properties["Octopus.Action.GoogleCloudAccount.Variable"];
        if (useVMServiceAccount) {
            return Summary.summary(<span>The Compute Engine virtual machine service account will be used</span>);
        }
        return accountVariable
            ? Summary.summary(<span>
                      The Google cloud account <strong>{accountVariable}</strong> will be used
                  </span>)
            : Summary.placeholder("The account variable has not been provided");
    }
    static ProjectField: React.FC<ActionEditProps<GoogleCloudAuthenticationProperties, any>> = (props) => {
        return (<React.Fragment>
                <VariableLookupText localNames={props.localNames} value={props.properties["Octopus.Action.GoogleCloud.Project"]} label="Project" onChange={(val) => props.setProperties({ ["Octopus.Action.GoogleCloud.Project"]: val })} error={props.getFieldError("Octopus.Action.GoogleCloud.Project")}/>
            </React.Fragment>);
    };
    private getProjectSummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.GoogleCloud.Project"]) {
            return Summary.summary(<span>
                    Using <strong>{properties["Octopus.Action.GoogleCloud.Project"]}</strong> as the default project
                </span>);
        }
        return Summary.placeholder("Specify the default Google cloud project");
    }
    static RegionField: React.FC<ActionEditProps<GoogleCloudAuthenticationProperties, any>> = (props) => {
        return (<React.Fragment>
                <VariableLookupText localNames={props.localNames} value={props.properties["Octopus.Action.GoogleCloud.Region"]} label="Region" onChange={(val) => props.setProperties({ ["Octopus.Action.GoogleCloud.Region"]: val })} error={props.getFieldError("Octopus.Action.GoogleCloud.Region")}/>
                {GoogleCloudAuthenticationComponent.getGcpRegionZoneNote()}
            </React.Fragment>);
    };
    private getRegionSummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.GoogleCloud.Region"]) {
            return Summary.summary(<span>
                    Using <strong>{properties["Octopus.Action.GoogleCloud.Region"]}</strong> as the default region
                </span>);
        }
        return Summary.placeholder("Specify the default Google cloud region");
    }
    static ZoneField: React.FC<ActionEditProps<GoogleCloudAuthenticationProperties, any>> = (props) => {
        return (<React.Fragment>
                <VariableLookupText localNames={props.localNames} value={props.properties["Octopus.Action.GoogleCloud.Zone"]} label="Zone" onChange={(val) => props.setProperties({ ["Octopus.Action.GoogleCloud.Zone"]: val })} error={props.getFieldError("Octopus.Action.GoogleCloud.Zone")}/>
                {GoogleCloudAuthenticationComponent.getGcpRegionZoneNote()}
            </React.Fragment>);
    };
    private getZoneSummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.GoogleCloud.Zone"]) {
            return Summary.summary(<span>
                    Using <strong>{properties["Octopus.Action.GoogleCloud.Zone"]}</strong> as the default zone
                </span>);
        }
        return Summary.placeholder("Specify the default Google cloud zone");
    }
    private static getGcpRegionZoneNote() {
        return (<Note>
                View the <ExternalLink href="GCPRegionsZones">GCP Regions and Zones</ExternalLink> documentation for a current list of the available region and zone codes.
            </Note>);
    }
    render() {
        return (<React.Fragment>
                <ExpandableFormSection errorKey="Octopus.Action.GoogleCloudAccount.Variable" isExpandedByDefault={this.props.expandedByDefault} title="Google Cloud Account" help="Enter the Google cloud account details" summary={this.getAccountSummary()}>
                    <GoogleCloudAuthenticationComponent.GoogleCloudLoginComponent {...this.props}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.GoogleCloud.Project" isExpandedByDefault={this.props.expandedByDefault} title="Project" summary={this.getProjectSummary()} help={"Specify the default project"}>
                    <GoogleCloudAuthenticationComponent.ProjectField {...this.props}/>
                    <Note>
                        This sets{" "}
                        <ExternalLink href="GCPDefaultProject">
                            <code>CLOUDSDK_CORE_PROJECT</code> environment variable
                        </ExternalLink>
                        .
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.GoogleCloud.Region" isExpandedByDefault={this.props.expandedByDefault} title="Region" summary={this.getRegionSummary()} help={"Specify the default region"}>
                    <GoogleCloudAuthenticationComponent.RegionField {...this.props}/>
                    <Note>
                        This sets{" "}
                        <ExternalLink href="GCPDefaultRegionAndZone">
                            <code>CLOUDSDK_COMPUTE_REGION</code> environment variable
                        </ExternalLink>
                        .
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.GoogleCloud.Zone" isExpandedByDefault={this.props.expandedByDefault} title="Zone" summary={this.getZoneSummary()} help={"Specify the default zone"}>
                    <GoogleCloudAuthenticationComponent.ZoneField {...this.props}/>
                    <Note>
                        This sets{" "}
                        <ExternalLink href="GCPDefaultRegionAndZone">
                            <code>CLOUDSDK_COMPUTE_ZONE</code> environment variable
                        </ExternalLink>
                        .
                    </Note>
                </ExpandableFormSection>
            </React.Fragment>);
    }
    static displayName = "GoogleCloudAuthenticationComponent";
}
