import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, Divider, EmptyStateDefaultIllustration, IconButton, List, ListItem } from "@octopusdeploy/design-system-components";
import { colorScales, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import pluralize from "pluralize";
import * as React from "react";
import { useState } from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { defaultPanelSelectorDialogPageSize, PagingSection } from "~/areas/projects/components/ProjectTenants/PagingSection";
import { FilterBuilder } from "~/areas/tenants/components/Filtering/FilterBuilder/FilterBuilder";
import type { Filter, FilterValue } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import CollapsibleFilter from "~/areas/tenants/components/HeaderBar/CollapsibleFilter";
import { ListItemContentWithButtons } from "~/components/BorderedListItem";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import Logo from "~/components/Logo/index";
export interface AvailableItems {
    totalCount: number;
    records: NamedItemWithLogo[];
}
export interface NamedItemWithLogo {
    Id: string;
    LogoLink: string;
    Name: string;
    SpaceId?: string;
}
interface ColumnSelectItemsProps {
    items: AvailableItems;
    selectedItems: NamedItemWithLogo[];
    updateSelectedItems: (ids: NamedItemWithLogo[]) => void;
    filters: Filter[];
    onFilterChanged: (filterValues: FilterValue[]) => Promise<boolean>;
    onNameFilterChanged: (name: string) => Promise<boolean>;
    isFiltered: boolean;
    filteredName?: string;
    selectItemType: SelectItemType;
}
export enum SelectItemType {
    Tenant = "Tenant",
    Project = "Project"
}
export default function PanelSelector({ items, selectedItems, updateSelectedItems, filters, onFilterChanged, onNameFilterChanged, selectItemType, isFiltered, filteredName }: ColumnSelectItemsProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const selectedItemIds = selectedItems.reduce((set, item) => set.add(item.Id), new Set<string>());
    const unselectedItems = items.records.filter((t) => !selectedItemIds.has(t.Id));
    const handleSelect = (item: NamedItemWithLogo) => updateSelectedItems([...selectedItems, item]);
    const handleRemove = (item: NamedItemWithLogo) => updateSelectedItems(selectedItems.filter((t) => t.Id !== item.Id));
    const handleSelectAll = () => {
        dispatchAction(`Select All ${pluralize(selectItemType)}`, { resource: selectItemType, action: Action.Add });
        updateSelectedItems([...selectedItems, ...unselectedItems]);
        setUnselectedPageNumber(1);
    };
    const [unselectedPageNumber, setUnselectedPageNumber] = useState(1);
    const [unselectedPageSize, setUnselectedPageSize] = useState(defaultPanelSelectorDialogPageSize);
    const unselectedItemsToDisplay = unselectedItems.slice((unselectedPageNumber - 1) * unselectedPageSize, unselectedPageNumber * unselectedPageSize);
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [selectedPageSize, setSelectedPageSize] = useState(defaultPanelSelectorDialogPageSize);
    const selectedItemsToDisplay = selectedItems.slice((selectedPageNumber - 1) * selectedPageSize, selectedPageNumber * selectedPageSize);
    const filterChanged = async (filterValues: FilterValue[]) => {
        await onFilterChanged(filterValues);
        setUnselectedPageNumber(1);
    };
    const nameChanged = async (name: string) => {
        await onNameFilterChanged(name);
        setUnselectedPageNumber(1);
    };
    const selectedFilteredCount = () => {
        if (isFiltered) {
            return unselectedItems.length;
        }
        if (selectedItems.length > 0) {
            return unselectedItems.length;
        }
        return null;
    };
    return (<div className={styles.panelContainer}>
            <div className={styles.listContainer}>
                <CollapsibleFilter totalCount={items.totalCount} filteredCount={selectedFilteredCount()} entityName={selectItemType} secondaryContent={<div className={styles.nameFilter}>
                            <FilterSearchBox value={filteredName} onChange={nameChanged} debounceDelay={600} placeholder={`Filter by ${selectItemType.toLowerCase()} name...`}/>
                        </div>} ariaLabel={"current-filter-selection"}>
                    {filters.length > 0 ? <FilterBuilder filters={filters} onChange={filterChanged} display={"compact"}/> : null}
                </CollapsibleFilter>
                <Divider />
                <SelectAllButton count={unselectedItems.length} handleSelectAll={handleSelectAll}/>
                <div className={styles.itemsList}>
                    <List items={unselectedItemsToDisplay} rowKey={(x) => x.Id} renderRow={({ item: item }) => <UnselectedItemRow item={item} handleSelect={handleSelect}/>} empty={isFiltered && items.records.length === 0 ? <NoFilteredItemsLabel /> : <NoAvailableItemsLabel count={items.totalCount} itemType={selectItemType}/>}/>
                </div>
                <PagingSection itemCount={unselectedItems.length} pageNumber={unselectedPageNumber} pageSize={unselectedPageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            setUnselectedPageNumber(newPageNumber);
            setUnselectedPageSize(newPageSize);
        }}/>
            </div>
            <div className={styles.listContainer}>
                <div className={styles.panelHeading}>{pluralize(selectItemType.toLowerCase(), selectedItems.length, true)} selected</div>
                <Divider />
                <div className={styles.itemsList}>
                    <List items={selectedItemsToDisplay} rowKey={(x) => x.Id} renderRow={({ item: tenant }) => <SelectedItemRow item={tenant} handleRemove={handleRemove}/>} empty={<div className={styles.emptySelectedList}>
                                <div className={styles.emptyStateIllustrationWrapper}>
                                    <EmptyStateDefaultIllustration />
                                </div>
                                Looks like you haven't selected any {pluralize(selectItemType.toLowerCase())} yet.
                            </div>}/>
                </div>
                <PagingSection itemCount={selectedItems.length} pageNumber={selectedPageNumber} pageSize={selectedPageSize} onPagingSelectionChange={(newPageNumber, newPageSize) => {
            setSelectedPageNumber(newPageNumber);
            setSelectedPageSize(newPageSize);
        }}/>
            </div>
        </div>);
}
function SelectAllButton({ count, handleSelectAll }: {
    count: number;
    handleSelectAll: () => void;
}) {
    if (count === 0) {
        return null;
    }
    const label = count > 1 ? `Select all ${count} results` : "Select 1 result";
    return (<>
            <div className={styles.selectAllButton}>
                <ActionButton label={label} onClick={handleSelectAll} type={ActionButtonType.Ternary}/>
            </div>
            <Divider />
        </>);
}
function NoAvailableItemsLabel({ count, itemType }: {
    count: number;
    itemType: SelectItemType;
}) {
    return <div className={styles.emptyUnselectedList}>{count > 0 ? `All available ${pluralize(itemType).toLowerCase()} have been selected.` : `All available ${pluralize(itemType).toLowerCase()} have already been connected.`}</div>;
}
function NoFilteredItemsLabel() {
    return <div className={styles.emptyUnselectedList}>No projects match the selected filters</div>;
}
function UnselectedItemRow({ item, handleSelect }: {
    item: NamedItemWithLogo;
    handleSelect: (item: NamedItemWithLogo) => void;
}) {
    return (<ListItem>
            <button className={styles.listItemButtonRow} onClick={() => handleSelect(item)}>
                <div className={styles.listItemButtonRowRoot}>
                    <div className={styles.listItemButtonRowContent}>
                        <div className={styles.listItem}>
                            <Logo url={item.LogoLink} size={"2rem"}/>
                            {item.Name}
                        </div>
                    </div>
                    <div className={styles.listItemButtonRowIcons}>
                        <BluePlusIcon />
                    </div>
                </div>
            </button>
        </ListItem>);
}
function SelectedItemRow({ item, handleRemove }: {
    item: NamedItemWithLogo;
    handleRemove: (item: NamedItemWithLogo) => void;
}) {
    return (<ListItem>
            <ListItemContentWithButtons content={<div className={styles.listItem}>
                        <Logo url={item.LogoLink} size={"2rem"}/>
                        {item.Name}
                    </div>} buttons={<IconButton customIcon={<RedMinusIcon />} onClick={() => handleRemove(item)}/>}/>
        </ListItem>);
}
function RedMinusIcon() {
    return (<svg className={styles.icon} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M26 19C26.5 19 27 19.5 27 20C27 20.5625 26.5 21 26 21H14C13.4375 21 13 20.5625 13 20C13 19.5 13.4375 19 14 19H26ZM36 20C36 28.875 28.8125 36 20 36C11.125 36 4 28.875 4 20C4 11.1875 11.125 4 20 4C28.8125 4 36 11.1875 36 20ZM20 6C12.25 6 6 12.3125 6 20C6 27.75 12.25 34 20 34C27.6875 34 34 27.75 34 20C34 12.3125 27.6875 6 20 6Z" fill={colorScales.red[500]}/>
        </svg>);
}
function BluePlusIcon() {
    return (<svg className={styles.icon} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 26V21H14C13.4375 21 13 20.5625 13 20C13 19.5 13.4375 19 14 19H19V14C19 13.5 19.4375 13 20 13C20.5 13 21 13.5 21 14V19H26C26.5 19 27 19.5 27 20C27 20.5625 26.5 21 26 21H21V26C21 26.5625 20.5 27 20 27C19.4375 27 19 26.5625 19 26ZM36 20C36 28.875 28.8125 36 20 36C11.125 36 4 28.875 4 20C4 11.1875 11.125 4 20 4C28.8125 4 36 11.1875 36 20ZM20 6C12.25 6 6 12.3125 6 20C6 27.75 12.25 34 20 34C27.6875 34 34 27.75 34 20C34 12.3125 27.6875 6 20 6Z" fill={colorScales.blue[500]}/>
        </svg>);
}
const styles = {
    nameFilter: css({
        flexGrow: "1",
    }),
    panelContainer: css({
        display: "grid",
        gridTemplateColumns: "1.5fr 1fr",
        gap: "1rem",
        width: "100%",
        height: "100%",
        padding: "1rem",
        backgroundColor: themeTokens.color.background.secondary.default,
    }),
    emptyUnselectedList: css({
        paddingTop: space[8],
        color: themeTokens.color.text.tertiary,
        font: text.interface.body.default.medium,
    }),
    emptySelectedList: css({
        color: themeTokens.color.text.tertiary,
        font: text.interface.body.default.medium,
        height: "100%",
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }),
    emptyStateIllustrationWrapper: css({
        width: "20rem",
    }),
    listContainer: css({
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${themeTokens.color.border.primary}`,
        borderRadius: "0.25rem",
        backgroundColor: themeTokens.color.background.primary.default,
        overflowY: "hidden",
    }),
    itemsList: css({
        overflowY: "auto",
        padding: "0rem 1rem 0.5rem 1rem",
        height: "100%",
    }),
    listItem: css({
        display: "inline-flex",
        alignItems: "center",
        gap: "1rem",
        height: "2rem",
    }),
    listItemButtonRow: css({
        padding: 0,
        border: 0,
        background: themeTokens.color.background.primary.default,
        boxShadow: "none",
        width: "100%",
        cursor: "pointer",
        "&:hover, &:focus": {
            backgroundColor: themeTokens.color.background.primary.hovered,
        },
    }),
    listItemButtonRowRoot: css({
        display: "flex",
        padding: space[16],
        gap: space[16],
    }),
    listItemButtonRowContent: css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        font: text.interface.body.default.base,
        color: themeTokens.color.text.primary,
    }),
    listItemButtonRowIcons: css({
        display: "flex",
        alignItems: "center",
    }),
    panelHeading: css({
        font: text.interface.body.bold.base,
        color: themeTokens.color.text.primary,
        padding: "1rem 1rem",
    }),
    icon: css({
        width: "24px",
        height: "24px",
        fill: "currentcolor",
        display: "block",
    }),
    selectAllButton: css({
        padding: "0.5rem 1rem",
    }),
};
