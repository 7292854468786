import React from "react";
import { ExpandableFormSection, Note, Summary, Text } from "~/components/form";
type HelpSidebarSupportLinkProps = {
    link?: string;
    label?: string;
    linkError?: string;
    labelError?: string;
    onChangeLink: (link: string) => void;
    onChangeLinkLabel: (label: string) => void;
};
export function HelpSidebarSupportLink({ link, label, linkError, labelError, onChangeLink, onChangeLinkLabel }: HelpSidebarSupportLinkProps) {
    return (<ExpandableFormSection key="HelpSidebarSupportLink|HelpSidebarSupportLinkLabel" errorKey="HelpSidebarSupportLink|HelpSidebarSupportLinkLabel" title="Customize the Help Sidebar" summary={link ? Summary.summary(link) : Summary.default("Octopus support")} help="Customize the Help sidebar support link in the Octopus web portal UI.">
            <Note>
                The sidebar includes links to Octopus support and resources. This feature lets you add a custom link, so users can contact your internal support system. If you disable the sidebar feature, you can find the support link in the top
                profile menu.
            </Note>
            <Text value={link || ""} onChange={onChangeLink} label="Support Link" helperText="Must follow one of the following valid URI schemes: http:, https:, mailto:, tel:" error={linkError} autoFocus={!!linkError}/>
            <Text value={label || ""} onChange={onChangeLinkLabel} label="Support Link Label" helperText="Must be 2 to 38 characters. If left blank, this will default to 'Your organization's internal support'" error={labelError} autoFocus={!!labelError}/>
        </ExpandableFormSection>);
}
