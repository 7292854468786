import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "~/primitiveComponents/form/Note/Note";
interface BindingState extends DataBaseComponentState {
    binding: VolumeBinding;
}
interface VolumeBinding {
    host: string;
    container: string;
    readOnly: string;
    noCopy: string;
}
interface BindingProps {
    binding: VolumeBinding;
    localNames: string[];
    project: ProjectResource;
    projectId: string;
    existingContainerMappings: string[];
    onAdd(Binding: VolumeBinding): boolean;
}
export default class VolumeBindingDialog extends DataBaseComponent<BindingProps, BindingState> {
    constructor(props: BindingProps) {
        super(props);
        this.state = {
            binding: props.binding,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            /* trigger the dialog location fix in doBusyTask */
        });
    }
    save = () => {
        return this.props.onAdd(this.state.binding);
    };
    render() {
        const existingError = this.props.existingContainerMappings.indexOf(this.state.binding.container) === -1 ? undefined : "A volume binding already exists for this path";
        return (<OkDialogLayout onOkClick={this.save} okButtonDisabled={existingError !== undefined || this.state.binding.container === ""} busy={this.state.busy} errors={this.errors} title={"Add Volume Binding"}>
                {this.state.binding && (<div>
                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.container} onChange={(x) => this.setChildState1("binding", { container: x })} error={existingError} label="Container Dest"/>
                        <Note> {existingError === undefined && "Mount point inside container to create volume."}</Note>

                        <VariableLookupText localNames={this.props.localNames} value={this.state.binding.host} onChange={(x) => this.setChildState1("binding", { host: x })} label="Host Source"/>
                        <Note>An optional absolute path or a name value to a resource on the host.</Note>
                        <br />
                        <BoundStringCheckbox variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"false"} value={this.state.binding.readOnly} onChange={(x) => this.setChildState1("binding", { readOnly: x })} label="Read Only" note={<span>Volume defaults mode to read-write.</span>}/>
                        <br />
                        <BoundStringCheckbox variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"false"} value={this.state.binding.noCopy} onChange={(x) => this.setChildState1("binding", { noCopy: x })} label="No Copy" note={<span>Disable automatic copying volume path in the container to the volume storage location.</span>}/>
                    </div>)}
            </OkDialogLayout>);
    }
    static displayName = "VolumeBindingDialog";
}
export { VolumeBinding };
