import { Callout } from "@octopusdeploy/design-system-components";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import useLocalStorage from "~/hooks/useLocalStorage";
export const GitTriggerFeedbackCallout = (): JSX.Element => {
    const [dateDismissed, setDateDismissed] = useLocalStorage(`Octopus.Callout.GitFeedTriggersFeedback.DateDismissed`, new Date("2000-01-1"));
    const [numberOfDismissals, setNumberOfDismissals] = useLocalStorage(`Octopus.Callout.GitFeedTriggersFeedback.NumberOfDismissals`, 0);
    const feedbackLink = "https://oc.to/GitTriggersFeedback";
    const onCloseCallout = () => {
        setDateDismissed(new Date());
        setNumberOfDismissals(numberOfDismissals + 1);
    };
    const isOutsideDismissalPeriod = isNowOutsideDismissalPeriod(dateDismissed, numberOfDismissals);
    return isOutsideDismissalPeriod ? (<Callout title="Git Triggers Feedback" type={"information"} canClose={true} onClose={onCloseCallout}>
            We'd love to <ExternalLink href={feedbackLink}>hear about your experience</ExternalLink> using Git triggers.
        </Callout>) : (<></>);
};
const isNowOutsideDismissalPeriod = (dateOfLastDismissal: Date, numberOfDismissals: number): boolean => {
    const today = new Date();
    const timeBombDate = new Date("2030-01-01");
    const dismissUntil = new Date(dateOfLastDismissal);
    dismissUntil.setDate(dismissUntil.getDate() + numberOfDismissals * 14);
    return today < timeBombDate && today > dismissUntil;
};
