import { LinearProgress } from "@octopusdeploy/design-system-components";
import type { UnknownQueryParam } from "@octopusdeploy/portal-routes";
import { useQueryStringParams } from "@octopusdeploy/portal-routes";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { Route } from "react-router-dom";
import { AsyncInternalRedirect } from "~/routing/AsyncInternalRedirect";
import { legacyRouteRegistrations } from "~/routing/legacyRouteRegistrations/allLegacyRouteRegistrations";
import type { LegacyRouteRegistration } from "~/routing/legacyRouteRegistrations/createLegacyRouteRegistrations";
import { formatRoutePath } from "~/routing/pageRegistrations/formatRoutePath";
export function allLegacyRedirectRoutes() {
    const registrations: UnknownLegacyRouteRegistration[] = Object.values(legacyRouteRegistrations);
    return registrations.map(createLegacyRouteComponent);
}
export function createLegacyRouteComponent<RouteParams, QueryParams extends UnknownQueryParam[]>(legacyRouteRegistration: LegacyRouteRegistration<RouteParams, QueryParams>) {
    return (<Route exact key={legacyRouteRegistration.route.getDebugString()} path={formatRoutePath(legacyRouteRegistration.route.template)} render={(routeProps) => <LegacyRoute routeRegistration={legacyRouteRegistration} routeProps={routeProps}/>}></Route>);
}
function LegacyRoute<RouteParams, QueryParams extends UnknownQueryParam[]>({ routeRegistration, routeProps }: {
    routeRegistration: LegacyRouteRegistration<RouteParams, QueryParams>;
    routeProps: RouteComponentProps;
}) {
    const [queryParams] = useQueryStringParams<QueryParams>(routeRegistration.route.queryParameters);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const routeParams: RouteParams = routeProps.match.params as RouteParams;
    return <AsyncInternalRedirect loader={() => routeRegistration.redirect(routeParams, queryParams)} renderWhenLoading={<LinearProgress variant={"indeterminate"} show={true}/>}/>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnknownLegacyRouteRegistration = LegacyRouteRegistration<any, UnknownQueryParam[]>;
