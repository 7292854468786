import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonDeploymentTargetOverview, CommonDeploymentTargetTopics } from "../../CommonComponents/CommonDeploymentTargetViews";
export const InfrastructureDeploymentTargetDeploymentsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.deploymentTarget.runbookRuns,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDeploymentTargetOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonDeploymentTargetTopics />;
    },
};
