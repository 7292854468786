import { VariableType } from "@octopusdeploy/octopus-server-client";
interface VariableTypeDetails {
    value: VariableType;
    text: string;
}
/**
 * A collection that defines the variable types available to the UI
 */
export const RawVariableTypeDetailsMap: VariableTypeDetails[] = [
    { value: VariableType.AzureAccount, text: "Azure Account" },
    { value: VariableType.GoogleCloudAccount, text: "Google Cloud Account" },
    { value: VariableType.AmazonWebServicesAccount, text: "AWS Account" },
    { value: VariableType.UsernamePasswordAccount, text: "Username Password Account" },
    { value: VariableType.Certificate, text: "Certificate" },
    { value: VariableType.WorkerPool, text: "Worker Pool" },
    { value: VariableType.Sensitive, text: "Sensitive" },
    { value: VariableType.String, text: "Text" },
];
