import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { AsyncInternalRedirect } from "../AsyncInternalRedirect";
interface DefaultInsightsPageRedirectProps {
    spaceId: string;
}
export function DefaultInsightsPageRedirect({ spaceId }: DefaultInsightsPageRedirectProps) {
    return (<AsyncInternalRedirect loader={async () => {
            const licenseCheck = await repository.InsightsReports.getInsightsLicenseCheck();
            return licenseCheck.HasInsightsLicenseFeature ? links.insightReportListPage.generateUrl({ spaceId }) : links.insightsUpsellPage.generateUrl({ spaceId });
        }} renderWhenLoading={<SkeletonLoadingLayout errors={undefined}/>}/>);
}
