import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import { type DeploymentProcessResource, type ProjectResource, type TriggerGitFilterResource, type TriggerResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DeploymentActionGitDependencyChip } from "~/components/Chips";
import { getGitTriggerSourceItems } from "./GitTriggerSources";
interface GitTriggerDescriptionProps {
    trigger: TriggerResource;
    project: ProjectResource;
    deploymentProcess: DeploymentProcessResource;
}
export function GitTriggerDescription({ trigger, project, deploymentProcess }: GitTriggerDescriptionProps) {
    const styles = {
        description: css({ lineHeight: space[24] }),
    };
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const sources = (trigger.Filter as TriggerGitFilterResource).Sources;
    if (sources.length === 0)
        return <>No repositories monitored</>;
    const sourceItems = getGitTriggerSourceItems(project, sources, deploymentProcess);
    const deploymentActionChips = sourceItems
        .filter((item) => item.selected)
        .flatMap((item) => {
        return item.steps.flatMap((step) => (<DeploymentActionGitDependencyChip key={`${item.id}-${step.action.Id}-${step.gitDependencyName}`} actionGitDependency={{
                DeploymentActionName: step.action.Name,
                DeploymentActionSlug: step.action.Slug ?? step.action.Name,
                GitDependencyName: step.gitDependencyName ?? "",
                RepositoryUri: step.repositoryUrl,
            }}/>));
    });
    if (deploymentActionChips.length > 1) {
        return <span className={styles.description}>Create a release when a commit is pushed to the Git repositories connected to {deploymentActionChips.length} steps</span>;
    }
    return <span className={styles.description}>Create a release when a commit is pushed to the Git repository connected to {deploymentActionChips}</span>;
}
