import { pageId } from "@octopusdeploy/portal-routes";
export default () => {
    return {
        tasks: {
            root: pageId({ area: "Tasks", id: "Tasks", name: "Tasks" }),
            console: pageId({ area: "Tasks", id: "Tasks.ScriptConsole", name: "Script Console" }),
        },
        task: () => {
            return {
                root: pageId({ area: "Tasks", id: "Task", name: "Task" }),
                raw: pageId({ area: "Tasks", id: "Task.Raw", name: "Raw Task Log" }),
            };
        },
    };
};
