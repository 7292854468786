/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ActionProperties, SensitiveValue } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ValueInPropertiesOrErrorsHasChanged } from "~/utils/ShouldUpdate/ValueInPropertiesHasChanged";
import { BoundStringCheckbox } from "../../../primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "../../../primitiveComponents/form/Note/Note";
import { BoundSelect } from "../../../primitiveComponents/form/Select/Select";
import type { ActionEditProps } from "../../Actions/pluginRegistry";
import pluginRegistry from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "../../form";
import isBound from "../../form/BoundField/isBound";
import { BoundSensitive } from "../../form/Sensitive/Sensitive";
import getSensitiveResetValue from "../../form/Sensitive/getSensitiveResetValue";
import { VariableLookupText } from "../../form/VariableLookupText";
const StringProperties = {
    "Octopus.Action.WindowsService.CreateOrUpdateService": "",
    "Octopus.Action.WindowsService.ServiceName": "",
    "Octopus.Action.WindowsService.DisplayName": "",
    "Octopus.Action.WindowsService.Description": "",
    "Octopus.Action.WindowsService.ExecutablePath": "",
    "Octopus.Action.WindowsService.Arguments": "",
    "Octopus.Action.WindowsService.ServiceAccount": "",
    "Octopus.Action.WindowsService.CustomAccountName": "",
    "Octopus.Action.WindowsService.StartMode": "",
    "Octopus.Action.WindowsService.Dependencies": "",
    "Octopus.Action.WindowsService.DesiredStatus": "",
};
const SensitiveProperties = {
    "Octopus.Action.WindowsService.CustomAccountPassword": "",
};
const AllProperties = {
    ...StringProperties,
    ...SensitiveProperties,
};
type WindowsServiceEditProperties = {
    [P in keyof typeof StringProperties]: string;
} & {
    [P in keyof typeof SensitiveProperties]: SensitiveValue;
};
class WindowsServiceEdit extends BaseComponent<ActionEditProps<WindowsServiceEditProperties>, never> {
    serviceAccounts = [
        { text: "Local System", value: "LocalSystem" },
        { text: "Network Service", value: "NT Authority\\NetworkService" },
        { text: "Local Service", value: "NT Authority\\LocalService" },
        { text: "Custom user...", value: "_CUSTOM" },
    ];
    startModes = [
        { text: "Automatic", value: "auto" },
        { text: "Automatic (delayed)", value: "delayed-auto" },
        { text: "Manual", value: "demand" },
        { text: "Disabled", value: "disabled" },
        { text: "Unchanged", value: "unchanged" },
    ];
    desiredStatuses = [
        { text: "Started", value: "Started" },
        { text: "Stopped", value: "Stopped" },
        { text: "Unchanged", value: "Unchanged" },
        { text: "Default (based on start mode)", value: "Default" },
    ];
    shouldComponentUpdate(newProps: ActionEditProps<WindowsServiceEditProperties>): boolean {
        return ValueInPropertiesOrErrorsHasChanged(AllProperties, newProps, this.props);
    }
    serviceSummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.WindowsService.CreateOrUpdateService"] === "False") {
            return Summary.placeholder("No windows service will be installed");
        }
        if (properties["Octopus.Action.WindowsService.ServiceName"]) {
            return Summary.summary(<span>
                    Windows service <strong>{properties["Octopus.Action.WindowsService.ServiceName"]}</strong> will be installed
                </span>);
        }
        return Summary.summary("A windows service will be installed but a Service Name has not been configured");
    }
    executableSummary() {
        const properties = this.props.properties;
        if (properties["Octopus.Action.WindowsService.ExecutablePath"]) {
            return Summary.summary(<span>
                    The executable is <strong>{properties["Octopus.Action.WindowsService.ExecutablePath"]}</strong>
                </span>);
        }
        else {
            return Summary.placeholder("No executable has been configured for the windows service");
        }
    }
    startupSummary() {
        const properties = this.props.properties;
        const user = this.serviceAccounts.find((p) => p.value === properties["Octopus.Action.WindowsService.ServiceAccount"]);
        const nodes = [];
        if (user && user.value === "_CUSTOM") {
            if (properties["Octopus.Action.WindowsService.CustomAccountName"]) {
                nodes.push(<span>
                        The service will run as <strong>{properties["Octopus.Action.WindowsService.CustomAccountName"]}</strong>
                    </span>);
            }
            else {
                nodes.push(<span>
                        The service will run as <strong>Custom User Account</strong>
                    </span>);
            }
        }
        else if (isBound(properties["Octopus.Action.WindowsService.ServiceAccount"])) {
            nodes.push("The service user account is set by a bound variable");
        }
        else if (user !== undefined) {
            nodes.push(<span>
                    The service will run as <strong>{user.text}</strong>
                </span>);
        }
        const startMode = this.startModes.find((p) => p.value === properties["Octopus.Action.WindowsService.StartMode"]);
        if (isBound(properties["Octopus.Action.WindowsService.StartMode"])) {
            nodes.push(", the start mode is set by a bound variable");
        }
        else if (startMode !== undefined) {
            nodes.push(<span>
                    , the start mode is <strong>{startMode.text}</strong>
                </span>);
        }
        if (properties["Octopus.Action.WindowsService.StartMode"] !== "disabled" && properties["Octopus.Action.WindowsService.DesiredStatus"]) {
            const desiredStatus = this.desiredStatuses.find((p) => p.value === properties["Octopus.Action.WindowsService.DesiredStatus"]);
            if (isBound(properties["Octopus.Action.WindowsService.DesiredStatus"])) {
                nodes.push(", the state is set by a bound variable");
            }
            else if (desiredStatus !== undefined) {
                nodes.push(<span>
                        , the state is <strong>{desiredStatus.text}</strong>
                    </span>);
            }
        }
        return Summary.summary(React.Children.toArray(nodes));
    }
    render() {
        const properties = this.props.properties;
        return (<div>
                <ExpandableFormSection errorKey="Octopus.Action.WindowsService.ServiceName|Octopus.Action.WindowsService.DisplayName" isExpandedByDefault={this.props.expandedByDefault} title="Windows Service" summary={this.serviceSummary()} help="Configure a windows service deployment.">
                    {this.props.plugin.actionType !== "Octopus.WindowsService" && (<div>
                            <BoundStringCheckbox variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"False"} value={properties["Octopus.Action.WindowsService.CreateOrUpdateService"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.CreateOrUpdateService"]: x })} label="Install a Windows Service"/>
                            <Note>
                                If enabled, Tentacle will use <code>sc.exe</code> to attempt to create a Windows Service using the settings below. If the service already exists, Tentacle will stop the service, reconfigure it, and start it again.
                            </Note>
                        </div>)}

                    <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.WindowsService.ServiceName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.ServiceName"]: x })} label="Service name" error={this.props.getFieldError("Octopus.Action.WindowsService.ServiceName")}/>
                    <Note>The name of the Windows Service to create or configure.</Note>
                    <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.WindowsService.DisplayName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.DisplayName"]: x })} error={this.props.getFieldError("Octopus.Action.WindowsService.DisplayName")} label="Display name"/>
                    <Note>Optional display name of the Windows Service.</Note>
                    <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.WindowsService.Description"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.Description"]: x })} error={this.props.getFieldError("Octopus.Action.WindowsService.Description")} label="Description"/>
                    <Note>User-friendly description of the service.</Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.WindowsService.ExecutablePath|Octopus.Action.WindowsService.Arguments" isExpandedByDefault={this.props.expandedByDefault} title="Windows Service: Executable" summary={this.executableSummary()} help="Configure the executable of the service.">
                    <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.WindowsService.ExecutablePath"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.ExecutablePath"]: x })} label="Executable path" error={this.props.getFieldError("Octopus.Action.WindowsService.ExecutablePath")}/>
                    <Note>
                        Path to the executable file for the service. The path should be relative to the package installation directory; for example:
                        <ul>
                            <li>
                                <code>MyService.exe</code>
                            </li>
                            <li>
                                <code>bin\MyService.exe</code>
                            </li>
                        </ul>
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.WindowsService.Arguments"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.Arguments"]: x })} error={this.props.getFieldError("Octopus.Action.WindowsService.Arguments")} multiline={true} label="Arguments"/>
                    <Note>Command line arguments that will be passed to the service when it starts each time.</Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.WindowsService.ServiceAccount|Octopus.Action.WindowsService.ServiceAccount|Octopus.Action.WindowsService.Dependencies" isExpandedByDefault={this.props.expandedByDefault} title="Windows Service: Startup" summary={this.startupSummary()} help="Configure the startup options of the service.">
                    <BoundSelect variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"LocalSystem"} value={properties["Octopus.Action.WindowsService.ServiceAccount"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.ServiceAccount"]: x })} items={this.serviceAccounts} error={this.props.getFieldError("Octopus.Action.WindowsService.ServiceAccount")} placeholder="Service account" label="Service account"/>
                    <Note>Which built-in account will the service run under.</Note>

                    {(properties["Octopus.Action.WindowsService.ServiceAccount"] === "_CUSTOM" || isBound(properties["Octopus.Action.WindowsService.ServiceAccount"])) && (<div>
                            <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.WindowsService.CustomAccountName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.CustomAccountName"]: x })} label="Custom account name"/>
                            <Note>
                                The Windows/domain account of the custom user that the service will run under. Example: <code>YOURDOMAIN\YourAccount</code>. You will need to ensure that this user has the 'Log on as a service' right.
                            </Note>
                            <BoundSensitive variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={getSensitiveResetValue(this.props.properties["Octopus.Action.WindowsService.CustomAccountPassword"])} value={properties["Octopus.Action.WindowsService.CustomAccountPassword"] as any} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.CustomAccountPassword"]: x as any })} label="Custom account password"/>
                            <Note>The password for the custom account given above.</Note>
                        </div>)}

                    <BoundSelect variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"auto"} value={properties["Octopus.Action.WindowsService.StartMode"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.StartMode"]: x })} items={this.startModes} error={this.props.getFieldError("Octopus.Action.WindowsService.StartMode")} placeholder="Start mode" label="Start mode"/>
                    <Note>
                        When will the service start? Use <em>Unchanged</em> to use the current status.
                    </Note>

                    {properties["Octopus.Action.WindowsService.StartMode"] !== "disabled" && (<div>
                            <BoundSelect variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"Default"} value={properties["Octopus.Action.WindowsService.DesiredStatus"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.DesiredStatus"]: x })} items={this.desiredStatuses} error={this.props.getFieldError("Octopus.Action.WindowsService.DesiredStatus")} placeholder="State" label="State"/>
                            <Note>Defines the state of the service after it has been deployed.</Note>
                            <Note>
                                <ul>
                                    <li>
                                        The <code>Started</code> option will start the service.
                                    </li>
                                    <li>
                                        The <code>Stopped</code> option will leave the service stopped.
                                    </li>
                                    <li>
                                        The <code>Unchanged</code> option will start the service if it already exists, and if it was running or starting. Otherwise it will leave the service stopped.
                                    </li>
                                    <li>
                                        The <code>Default</code> option will start the service if the start mode is <code>Automatic</code> or <code>Automatic (delayed)</code>. Otherwise it will leave the service stopped.
                                    </li>
                                </ul>
                            </Note>
                        </div>)}

                    <VariableLookupText localNames={this.props.localNames} value={properties["Octopus.Action.WindowsService.Dependencies"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.WindowsService.Dependencies"]: x })} error={this.props.getFieldError("Octopus.Action.WindowsService.Dependencies")} label="Dependencies"/>
                    <Note>
                        Any dependencies that the service has. Separate the names using forward slashes (/). For example: <code>LanmanWorkstation/TCPIP/MSSQL</code>.
                    </Note>
                </ExpandableFormSection>
            </div>);
    }
    static displayName = "WindowsServiceEdit";
}
pluginRegistry.registerFeature({
    featureName: "Octopus.Features.WindowsService",
    title: "Windows Service",
    description: "Creates or reconfigures a Windows Service using _sc.exe_",
    edit: WindowsServiceEdit,
    priority: 8,
    enable: (properties: ActionProperties) => {
        properties["Octopus.Action.WindowsService.CreateOrUpdateService"] = "True";
        properties["Octopus.Action.WindowsService.ServiceAccount"] = "LocalSystem";
        properties["Octopus.Action.WindowsService.StartMode"] = "auto";
        properties["Octopus.Action.WindowsService.DesiredStatus"] = "Default";
    },
    preSave: (properties: ActionProperties) => {
        if (properties["Octopus.Action.WindowsService.ServiceAccount"] !== "_CUSTOM" && !isBound(properties["Octopus.Action.WindowsService.ServiceAccount"])) {
            properties["Octopus.Action.WindowsService.CustomAccountName"] = null;
            properties["Octopus.Action.WindowsService.CustomAccountPassword"] = null;
        }
    },
    validate: (properties: ActionProperties, errors: any) => {
        if (!properties["Octopus.Action.WindowsService.ServiceName"]) {
            errors["Octopus.Action.WindowsService.ServiceName"] = "Please enter a service name for the Windows Service.";
        }
        if (!properties["Octopus.Action.WindowsService.ExecutablePath"]) {
            errors["Octopus.Action.WindowsService.ExecutablePath"] = "Please enter an executable path for this Windows Service.";
        }
    },
    disable: (properties: ActionProperties) => {
        Object.keys(properties)
            .filter((name) => {
            return name.indexOf("Octopus.Action.WindowsService.") === 0;
        })
            .forEach((name) => {
            delete properties[name];
        });
    },
});
