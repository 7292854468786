import { useMemo } from "react";
import * as React from "react";
import useLocalStorage from "~/hooks/useLocalStorage";
interface CollapsedColumnState {
    [key: string]: boolean;
}
type CollapseColumnCallback = (prev: boolean) => boolean;
const getColumnCollapsed = (columns: CollapsedColumnState) => (id: string) => Boolean(columns[id]);
const collapsedColumnsStateKey = "Octopus.Projects.CollapsedColumns";
export function useCollapsableColumns() {
    const [collapsedColumns, setCollapsedColumns] = useLocalStorage<CollapsedColumnState>(collapsedColumnsStateKey, {});
    const collapseColumn = React.useCallback((columnId: string, getNextCollapsedValue: CollapseColumnCallback) => setCollapsedColumns((prev) => {
        const isCollapsed = getColumnCollapsed(prev)(columnId);
        const nextValue = getNextCollapsedValue(isCollapsed);
        const hasChanged = isCollapsed !== nextValue;
        return hasChanged ? { ...prev, [columnId]: nextValue } : prev;
    }), [setCollapsedColumns]);
    const isColumnCollapsed = useMemo(() => getColumnCollapsed(collapsedColumns), [collapsedColumns]);
    return { isColumnCollapsed, collapseColumn };
}
