import { css } from "@emotion/css";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import { letterSpacing, lineHeight, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { CSSProperties } from "react";
import { useImperativeHandle, useCallback, useEffect } from "react";
import * as React from "react";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { useTabConversion } from "./useTabConversion";
import type { RenderTabCallback } from "./useTabConversion";
export interface ControlledTabComponentProps {
    children: React.ReactNode;
    value: string;
    afterTabsElement?: JSX.Element;
    onChange: (value: string) => void;
    tabContainerStyle?: CSSProperties;
    variant?: "standard" | "scrollable" | "fullWidth";
}
interface PropsWithOctopusTheme {
    octopusTheme: OctopusTheme;
}
const useMuiStyles = makeStyles(() => ({
    root: (props: PropsWithOctopusTheme) => ({
        color: themeTokens.color.text.secondary,
        width: "100%",
    }),
    indicator: (props: PropsWithOctopusTheme) => ({ backgroundColor: themeTokens.color.text.selected }),
}));
const useTabStyles = makeStyles(() => ({
    tabsContainer: (props: {
        tabContainerStyle?: CSSProperties;
    }) => ({
        ...props.tabContainerStyle,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }),
}));
const rootTabStyle: CSSProperties = {
    minWidth: "7.23rem", // Needed for DrawerWrapperLayout.
    textTransform: "none",
    font: text.button.default.medium,
    letterSpacing: letterSpacing.wide,
    lineHeight: lineHeight.xSmall,
};
const rootTabStyles = css({
    "&.Mui-selected": {
        color: themeTokens.color.text.selected,
    },
});
export interface TabsRef {
    focusActiveTab: () => void;
}
export const ControlledTabsContainer = React.forwardRef<TabsRef, ControlledTabComponentProps>(({ onChange, variant, afterTabsElement, tabContainerStyle, children, value }, ref) => {
    const octopusTheme = useOctopusTheme();
    const activeTabRef = React.useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
        focusActiveTab: () => {
            activeTabRef.current?.focus();
        },
    }));
    const classes = useMuiStyles({ octopusTheme });
    const tabStyles = useTabStyles({ tabContainerStyle });
    const defaultTabRender: RenderTabCallback = React.useMemo(() => ({ index, value: tabValue, label }) => <Tab ref={tabValue === value ? activeTabRef : undefined} style={rootTabStyle} className={rootTabStyles} tabIndex={index} value={tabValue} label={label} key={tabValue}/>, [value]);
    const { tabs, content, getTabDefinition } = useTabConversion(children, value, defaultTabRender);
    useEffect(() => {
        const tab = getTabDefinition(value);
        if (tab?.onActive)
            tab.onActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleTabChange = useCallback((event: React.ChangeEvent<{}>, tabValue: string) => {
        const tab = getTabDefinition(tabValue);
        if (tab && tab.onActive && tabValue !== value) {
            tab.onActive();
        }
        if (onChange) {
            onChange(tabValue);
        }
    }, [getTabDefinition, onChange, value]);
    //MUI won't handle an undefined value as not provided and will log errors to the console, we therefore only provide the value
    //when it is defined
    return (<React.Fragment>
            <div className={tabStyles.tabsContainer}>
                <Tabs classes={classes} {...(value !== undefined ? { value } : {})} onChange={handleTabChange} variant={variant ?? "scrollable"} scrollButtons="off">
                    {tabs}
                </Tabs>
                {afterTabsElement}
            </div>
            {content}
        </React.Fragment>);
});
