import type { IconSvgResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import IconEditorSvg from "~/components/form/IconEditor/IconEditorSvg";
import styles from "./style.module.less";
interface IconSelectionButtonProps {
    icon: IconSvgResource;
    selectedIconColor: string | undefined;
    selectedIconId: string | undefined;
    onSelect: (id: string) => void;
}
const IconSelectionButton = ({ icon, onSelect, selectedIconId, selectedIconColor }: IconSelectionButtonProps) => {
    const isSelected = selectedIconId === icon.id;
    return (<button name={icon.label} aria-label={icon.label} aria-pressed={isSelected} className={isSelected ? [styles.iconPickerItem, styles.selected].join(" ") : styles.iconPickerItem} onClick={(e) => {
            if (isSelected) {
                onSelect("");
                e.currentTarget.blur();
            }
            else {
                onSelect(icon.id);
            }
        }} style={isSelected ? { backgroundColor: selectedIconColor } : {}} onKeyPress={(e) => {
            if (e.key === "Enter") {
                () => (isSelected ? onSelect("") : onSelect(icon.id));
            }
        }}>
            <IconEditorSvg icon={icon} isSelected={isSelected}/>
        </button>);
};
interface IconSelectorProps {
    icons: IconSvgResource[];
    selectedIconId: string | undefined;
    selectedIconColor: string | undefined;
    onSelect: (id: string) => void;
}
const IconSelector = ({ icons, selectedIconId, selectedIconColor, onSelect }: IconSelectorProps) => (<div className={styles.scrollableContainer}>
        <div className={styles.iconPickerContainer}>
            {icons.map((iconSvgResource) => (<IconSelectionButton key={iconSvgResource.id} selectedIconId={selectedIconId} selectedIconColor={selectedIconColor} icon={iconSvgResource} onSelect={(iconId) => onSelect(iconId)}/>))}
        </div>
    </div>);
export default IconSelector;
