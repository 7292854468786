import React from "react";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink";
export const CommonInsightsMeanTimeToRecoveryOverview = () => (<OverviewContainer>
        <OverviewHighlightText>
            <p>
                <b>Mean time to recovery</b> is the time period taken to restore service when an incident or outage occurs.
            </p>
        </OverviewHighlightText>
        <OverviewHighlightText>
            <p>
                <b>How it's calculated</b>
                <br />
                For a given failed deployment, its time to recovery is the time period from its completion time, to the completion time of the next successful deployment within the same scope.
            </p>
        </OverviewHighlightText>
    </OverviewContainer>);
export const CommonInsightsMeanTimeToRecoveryTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="InsightsMeanTimeToRecovery">Mean time to recovery</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
