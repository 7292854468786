/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { PackageReference, TriggerFeedFilterResource, TriggerResource } from "@octopusdeploy/octopus-server-client";
import type { StepInputDependencies, ProjectStepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { RelatedTriggersDependencies } from "./CalloutReferencedPackageTriggerConnections";
export const getPackageNamesUsedInTriggers = (triggers: TriggerResource[], packageReferences: PackageReference[]): string[] => {
    const allPackageNamesInTriggers = triggers.flatMap((trigger) => (trigger.Filter as TriggerFeedFilterResource).Packages.map((pkg) => pkg.PackageReference));
    // Forced to string[] as the linter is not picking up that `!!triggerPackageName` will ensure it's a value
    return packageReferences.filter((ref) => !!ref && allPackageNamesInTriggers.includes(ref.Name)).map((ref) => ref.Name) as string[];
};
export const getRelatedTriggersForPrimaryPackage = (dependencies: RelatedTriggersDependencies | undefined): TriggerResource[] => {
    if (!dependencies)
        return [];
    return getTriggersReferencingPackage(dependencies.projectTriggers, dependencies.deploymentActionSlug, null);
};
export const getRelatedTriggersForReferencedPackages = (dependencies: RelatedTriggersDependencies | undefined, packageReferences: PackageReference[]): TriggerResource[] => {
    if (!dependencies)
        return [];
    return packageReferences.flatMap((packageReference) => getTriggersReferencingPackage(dependencies.projectTriggers, dependencies.deploymentActionSlug, packageReference));
};
const getTriggersReferencingPackage = (triggers: TriggerResource[] | Readonly<TriggerResource[]>, deploymentActionSlug: string, packageReference: PackageReference | null) => {
    const triggersReferencingPackage = triggers.filter((trigger) => {
        const triggerPackages = (trigger.Filter as TriggerFeedFilterResource).Packages;
        const relatesToDeploymentAction = triggerPackages?.map((pkg) => pkg.DeploymentActionSlug).includes(deploymentActionSlug);
        const relatesToPackage = triggerPackages?.filter((pkg) => !pkg.PackageReference || pkg.PackageReference === packageReference?.Name).length > 0;
        return relatesToDeploymentAction && relatesToPackage;
    });
    return triggersReferencingPackage;
};
export const buildRelatedTriggerDependencies = (inputDependencies: StepInputDependencies | undefined): RelatedTriggersDependencies | undefined => {
    const projectStepInputDependencies = inputDependencies as ProjectStepInputDependencies;
    if (!projectStepInputDependencies?.project)
        return undefined;
    const project = projectStepInputDependencies?.project;
    return {
        spaceId: project.SpaceId,
        projectSlug: project.Slug,
        projectTriggers: projectStepInputDependencies?.projectTriggers,
        deploymentActionSlug: projectStepInputDependencies?.deploymentActionSlug,
    };
};
