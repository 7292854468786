import { css } from "@emotion/css";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { PortalGlobalLayout } from "~/components/GlobalLayout/PortalGlobalLayout";
import { MainNavbarLayout } from "~/components/MainNavbarLayout/MainNavbarLayout";
import RootRoutes from "~/components/RootRoutes/index";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
import SpaceLoader from "~/components/SpaceLoader/SpaceLoader";
import { allLegacyRedirectRoutes } from "~/routing/allLegacyRedirectRoutes";
export function AuthenticatedRoutes({ path }: {
    path: string;
}) {
    const isVerticalNavEnabled = useIsVerticalNavigationEnabled();
    const Layout = isVerticalNavEnabled ? PortalGlobalLayout : MainNavbarLayout;
    useEffect(() => {
        // This effect to apply body class styles can be removed when the vertical navigation feature toggle is removed.
        // We only need this so we can programatically switch the overflow property when vertical navigation is enabled.
        // We can just apply the style directly to the body element instead when we remove the toggle.
        const bodyClass = isVerticalNavEnabled ? verticalNavigationBodyStyles : legacyBodyStyles;
        document.body.classList.add(bodyClass);
        return () => {
            document.body.classList.remove(bodyClass);
        };
    }, [isVerticalNavEnabled]);
    return (<Switch>
            {/*We try to match legacy redirects first, because: */}
            {/*- They might partially match against other route segments and we don't want to interleave them with route segments because this adds more complexity. */}
            {/*- If there's ambiguity between a non-legacy route and a legacy route, we prefer the legacy route. For example:*/}
            {/*    - /projects/new vs /projects/{projectSlug} where {projectSlug} is 'new'. If we preferred the non-legacy route, then we would never match against the legacy route, so why even define it?  */}
            {allLegacyRedirectRoutes()}
            <Route path={path} render={() => (<SpaceLoader render={(spaceContext) => (<Layout spaceContext={spaceContext}>
                                <RootRoutes spaceContext={spaceContext}/>
                            </Layout>)}/>)}></Route>
        </Switch>);
}
const legacyBodyStyles = css({
    overflow: "auto",
});
const verticalNavigationBodyStyles = css({
    overflow: "clip",
});
