import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
export function SpaceRootRedirect({ spaceId }: {
    spaceId: string;
}) {
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    if (isVerticalNavigationEnabled) {
        return <InternalRedirect to={links.projectsPage.generateUrl({ spaceId })}/>;
    }
    return <InternalRedirect to={links.dashboardPage.generateUrl({ spaceId })}/>;
}
