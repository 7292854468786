import cn from "classnames";
import * as React from "react";
import formStyles from "./style.module.less";
interface NoteProps {
    style?: React.CSSProperties;
    checkboxNote?: boolean;
    className?: string;
    heading?: string;
}
const Note: React.StatelessComponent<NoteProps> = (props) => {
    const customStyle = props.checkboxNote ? { ...props.style, display: "block" } : props.style;
    return (<div>
            <div className={formStyles.header}>{props.heading}</div>
            <div className={cn(formStyles.note, props.className)} style={customStyle}>
                {props.children}
            </div>
        </div>);
};
Note.displayName = "Note"
export default Note;
