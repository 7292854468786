import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectTriggerReleaseViews } from "../../CommonComponents/CommonProjectTriggerReleaseViews";
export const ProjectTriggerReleaseRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().releaseTrigger,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectTriggerReleaseViews create={false}/>;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
