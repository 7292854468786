import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import { GuidedFailureMode } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import Note from "~/primitiveComponents/form/Note/Note";
import { DeploymentModelType } from "../../Runbooks/RunbookRunNowLayout";
interface FailureModeProps {
    title?: string;
    defaultGuidedFailureMode?: GuidedFailureMode;
    guidedFailureMode: GuidedFailureMode;
    modelType: DeploymentModelType;
    onModeChanged(mode: GuidedFailureMode): void;
}
const FailureMode: React.StatelessComponent<FailureModeProps> = (props) => {
    const modelTypeLabel = props.modelType === DeploymentModelType.Deployment ? "deployment" : "run";
    return (<ExpandableFormSection title={props.title || "Failure mode"} errorKey="failureMode" help={`Choose what will happen when a ${modelTypeLabel} error occurs`} summary={buildSummary()}>
            <div>
                <RadioButtonGroup value={props.guidedFailureMode} onChange={props.onModeChanged}>
                    <RadioButton value={GuidedFailureMode.Off} label="Do not use guided failure mode" isDefault={isDefault(GuidedFailureMode.Off)}/>
                    <RadioButton value={GuidedFailureMode.EnvironmentDefault} label="Use the default setting from the target environment" isDefault={isDefault(GuidedFailureMode.EnvironmentDefault)}/>
                    <RadioButton value={GuidedFailureMode.On} label="Use guided failure mode" isDefault={isDefault(GuidedFailureMode.On)}/>
                    <Note>
                        When <ExternalLink href="GuidedFailures">Guided Failure</ExternalLink> is enabled, if an error is encountered during the {modelTypeLabel} Octopus will pause and allow you to correct the error before proceeding. Otherwise, any
                        errors encountered will result in the {modelTypeLabel} failing.
                    </Note>
                </RadioButtonGroup>
            </div>
        </ExpandableFormSection>);
    function isDefault(mode: GuidedFailureMode): boolean {
        const defaultValue = props.defaultGuidedFailureMode ? props.defaultGuidedFailureMode : GuidedFailureMode.EnvironmentDefault;
        return defaultValue === mode;
    }
    function buildSummary() {
        let result = "";
        switch (props.guidedFailureMode) {
            case GuidedFailureMode.Off:
                result = "Do not use guided failure mode";
                break;
            case GuidedFailureMode.EnvironmentDefault:
                result = "Use the default setting from the target environment";
                break;
            case GuidedFailureMode.On:
                result = "Use guided failure mode";
                break;
            default:
                result = "Select a failure mode";
                break;
        }
        return isDefault(props.guidedFailureMode) ? Summary.default(result) : Summary.summary(result);
    }
};
FailureMode.displayName = "FailureMode"
export default FailureMode;
