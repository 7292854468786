import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import image from "../../Images/Project.svg";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
const video = {
    youtubeId: "gfaRUIlQybA",
    shortlink: "OnboardingProjectsVideo",
};
export const CommonProjectOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    A <ExternalLink href="OnboardingProjectsLearnMore">project</ExternalLink> is the collection of deployment steps, configuration variables, packages and assets that Octopus brings together and executes during the process of
                    deploying your software.
                </p>
                <ImageWithPlaceholder src={image} alt={"Project"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
