import { css, cx } from "@emotion/css";
import { List } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { LibraryVariableSetInfo } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links, VariableSetTabValues } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import { useState } from "react";
import type { ProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
import { styles } from "~/areas/projects/components/Variables/TenantVariables/styles";
import MissingVariablesIcon from "~/areas/tenants/MissingVariablesIcon/MissingVariablesIcon";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
interface CommonTemplatesSidebarContentProps {
    variableSets: LibraryVariableSetInfo[];
    spaceId: string;
    pageSize: number;
    filterState: ProjectTenantVariablesFilterState;
    setFilterState: (filter: ProjectTenantVariablesFilterState) => void;
    missingValueVariableSetIds: Set<string>;
}
export default function CommonTemplatesSidebarContent({ variableSets, spaceId, pageSize, filterState, setFilterState, missingValueVariableSetIds }: CommonTemplatesSidebarContentProps) {
    const [variableSetFilterValue, setVariableSetFilterValue] = useState("");
    const onRowClick = (id: string | undefined) => {
        setFilterState({
            ...filterState,
            filterByLibraryVariableSetId: id,
            pageNumber: 1,
        });
    };
    const sidebarMaxHeight = pageSize === 30 ? styles.sidebar30Height : pageSize === 50 ? styles.sidebar50Height : styles.sidebar100Height;
    return (<div className={cx(styles.sidebarContainer, sidebarMaxHeight)}>
            <div className={cx(styles.sidebarFilter, commonTemplatesSidebarFilter)}>
                <FilterSearchBox placeholder={"Filter by variable set..."} fullWidth={true} value={variableSetFilterValue} onChange={setVariableSetFilterValue}/>
            </div>
            {variableSetFilterValue === "" ? (<div className={cn(styles.sidebarRow, filterState.filterByLibraryVariableSetId ? undefined : styles.sidebarSelectedName)} onClick={() => onRowClick(undefined)}>
                    All variable sets
                </div>) : null}
            <List items={variableSets.filter((t) => t.Name.toLowerCase().includes(variableSetFilterValue.toLowerCase()))} renderRow={({ item: variableSet }) => (<CommonTemplatesSidebarRow spaceId={spaceId} variableSet={variableSet} onRowClick={onRowClick} isMissing={missingValueVariableSetIds.has(variableSet.Id)} isSelected={variableSet.Id === filterState.filterByLibraryVariableSetId}/>)}/>
        </div>);
}
interface CommonTemplatesSidebarRowProps {
    spaceId: string;
    variableSet: LibraryVariableSetInfo;
    onRowClick: (id: string | undefined) => void;
    isMissing: boolean;
    isSelected: boolean;
}
function CommonTemplatesSidebarRow({ spaceId, variableSet, onRowClick, isMissing, isSelected }: CommonTemplatesSidebarRowProps) {
    const menuItems = [
        OverflowMenuItems.navItem("Edit in variable sets", links.editVariableSetPage.generateUrl({ spaceId, variableSetId: variableSet.Id }, { activeTab: VariableSetTabValues.VariableTemplates }), {
            permission: Permission.LibraryVariableSetEdit,
        }),
    ];
    return (<div className={styles.sidebarRow} onClick={() => onRowClick(variableSet.Id)}>
            <div>
                <div className={cn(styles.sidebarNameContainer, isSelected ? styles.sidebarSelectedName : undefined)}>
                    {variableSet.Name}
                    <MissingVariablesIcon show={isMissing}/>
                </div>
                <div className={styles.sidebarLabel}>{variableSet.Description}</div>
            </div>
            <div>
                <OverflowMenu menuItems={menuItems}/>
            </div>
        </div>);
}
// Common templates doesn't have an overflow icon next to the search filter, so we need to provide the padding on the right manually
const commonTemplatesSidebarFilter = css({
    paddingRight: space[16],
});
