import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
export interface NotificationsIconClickedAnalyticsEvent extends AnalyticsEvent<"Notifications Icon Clicked"> {
    "Has Active Alert": boolean;
}
export function createNotificationsIconClickedAnalyticsEvent(hasActiveAlert: boolean): NotificationsIconClickedAnalyticsEvent {
    return {
        eventName: "Notifications Icon Clicked",
        "Has Active Alert": hasActiveAlert,
    };
}
