// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import Delete from "@material-ui/icons/Delete";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import PlayArrow from "@material-ui/icons/PlayArrow";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import Stop from "@material-ui/icons/Stop";
import React from "react";
import PanelStack, { usePanelStack } from "~/components/PanelStack";
import HideOnScroll from "./HideOnScroll";
import MiniProfilerRequestView from "./MiniProfilerRequestView";
import type { ProfilingActions } from "./ProfilerCache";
import type { ProfilingPageSession } from "./ProfilerCache/types";
import { ProfilingPageSessionDetail } from "./ProfilingPageSessionDetail";
import { ProfilingPageSessionList } from "./ProfilingPageSessionList";
interface MiniProfilerPanelProps {
    pageSessions: ReadonlyArray<ProfilingPageSession>;
    actions: ProfilingActions;
    enabled: boolean;
}
interface PlayOrStopProps {
    enabled: boolean;
    actions: ProfilingActions;
}
function PlayOrStop({ enabled, actions }: PlayOrStopProps) {
    if (enabled) {
        return (<IconButton onClick={actions.stop}>
                <Stop />
            </IconButton>);
    }
    else {
        return (<IconButton onClick={actions.start}>
                <PlayArrow />
            </IconButton>);
    }
}
interface MiniProfilerToolbarProps {
    enabled: boolean;
    actions: ProfilingActions;
}
function MiniProfilerToolbar({ enabled, actions }: MiniProfilerToolbarProps) {
    return (<HideOnScroll>
            <AppBar position="static" variant="elevation" color="transparent">
                <Toolbar variant="dense">
                    <PlayOrStop enabled={enabled} actions={actions}/>
                    <IconButton aria-label="Clear profiling results" onClick={actions.clear}>
                        <Delete />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </HideOnScroll>);
}
type PossibleStackPanels = PageRequestSummaryPanel | PageRequestsListPanel | RequestDetailPanel;
type RequestDetailPanel = {
    type: "profiler-detail";
    profilerId: string;
};
type PageRequestsListPanel = {
    type: "view-profiling-page-session";
    pageName: string;
    pageSessionId: string;
};
type PageRequestSummaryPanel = {
    type: "profiling-page-session-summary";
};
export function MiniProfilerPanel({ pageSessions, actions, enabled }: MiniProfilerPanelProps) {
    const { openPanel, closePanel, panels, panel, canNavigateBack } = usePanelStack<PossibleStackPanels>({ type: "profiling-page-session-summary" });
    return (<>
            <MiniProfilerToolbar enabled={enabled} actions={actions}/>
            <PanelStack<PossibleStackPanels> canNavigateBack={canNavigateBack} onOpenPanel={openPanel} onClosePanel={closePanel} panel={panel} renderPanel={(item) => {
            switch (item.type) {
                case "profiling-page-session-summary":
                    return {
                        title: "Page Sessions",
                        element: (<ProfilingPageSessionList profilingPageSessions={pageSessions} onSelect={(pageSession) => {
                                openPanel({ type: "view-profiling-page-session", pageSessionId: pageSession.pageSessionId, pageName: pageSession.page.Name });
                            }}/>),
                    };
                case "view-profiling-page-session":
                    return {
                        title: `Requests for session ${item.pageSessionId} on page ${item.pageName}`,
                        element: (<ProfilingPageSessionDetail profilers={pageSessions.find((x) => x.pageSessionId === item.pageSessionId)?.trackedRequestProfilers ?? []} onSelect={(profiler) => {
                                openPanel({ type: "profiler-detail", profilerId: profiler.Id });
                            }}/>),
                    };
                case "profiler-detail":
                    return { title: `Detail for profiler ${item.profilerId}`, element: <MiniProfilerRequestView profilerId={item.profilerId}/> };
            }
        }}/>
        </>);
}
