import type { LibraryVariableSetProjectUsage, LibraryVariableSetReleaseUsageEntry, LibraryVariableSetRunbookSnapshotUsageEntry } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import Chip from "~/components/Chips/Chip";
import InternalLink from "~/components/Navigation/InternalLink";
import SimpleDataTable from "~/components/SimpleDataTable";
import { pluraliseSummary, pluraliseHelp } from "./VariableSetUsage";
export const VariableSetReleaseUsages: React.FC<{
    spaceId: string;
    usage: LibraryVariableSetProjectUsage[];
}> = ({ spaceId, usage }) => {
    return (<SimpleDataTable<LibraryVariableSetProjectUsage> data={usage.filter((x) => x.Releases.length > 0)} headerColumns={["Project Name", "Release Version"]} onRow={(usageEntry) => [
            usageEntry.ProjectName,
            usageEntry.Releases.map((release: LibraryVariableSetReleaseUsageEntry) => (<InternalLink to={links.releasePage.generateUrl({ spaceId, projectSlug: usageEntry.ProjectSlug, releaseVersion: release.ReleaseVersion })} key={`${release.ReleaseId}-releaseSnapshotLink`}>
                        <Chip>{release.ReleaseVersion}</Chip>
                    </InternalLink>)),
        ]}/>);
};
VariableSetReleaseUsages.displayName = "VariableSetReleaseUsages"
export const VariableSetRunbookUsages: React.FC<{
    spaceId: string;
    usage: LibraryVariableSetProjectUsage[];
}> = ({ spaceId, usage }) => {
    return (<SimpleDataTable<LibraryVariableSetProjectUsage> data={usage.filter((x) => x.RunbookSnapshots.length > 0)} headerColumns={["Project Name", "Runbook Snapshots"]} onRow={(usageEntry) => [
            usageEntry.ProjectName,
            usageEntry.RunbookSnapshots.map((snapshot: LibraryVariableSetRunbookSnapshotUsageEntry) => (<InternalLink to={links.runbookSnapshotRedirect.generateUrl({ spaceId, runbookSnapshotId: snapshot.SnapshotId })} key={snapshot.SnapshotId}>
                        <Chip>{snapshot.SnapshotName}</Chip>
                    </InternalLink>)),
        ]}/>);
};
VariableSetRunbookUsages.displayName = "VariableSetRunbookUsages"
export function getReleaseUsageSummary(type: string, count: number, countOfReleasesUserCannotSee: number) {
    return pluraliseSummary(count, `This ${type}`, "has been", "has not been", "release snapshot", countOfReleasesUserCannotSee);
}
export function getRunbookSnapshotUsageSummary(type: string, count: number, countOfRunbookSnapshotsUserCannotSee: number) {
    return pluraliseSummary(count, `This ${type}`, "has been", "has not been", "runbook snapshot", countOfRunbookSnapshotsUserCannotSee);
}
export function getReleaseUsageHelp(type: string, count: number, countOfReleasesUserCannotSee: number) {
    return pluraliseHelp(count, `This ${type}`, "has been", "has not been", "release", countOfReleasesUserCannotSee);
}
export function getRunbookSnapshotUsageHelp(type: string, count: number, countOfRunbookSnapshotsUserCannotSee: number) {
    return pluraliseHelp(count, `This ${type}`, "has been", "has not been", "runbook snapshot", countOfRunbookSnapshotsUserCannotSee);
}
export function scriptModuleReleaseUsageSummary(usagesInReleaseSnapshots: LibraryVariableSetProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageSummary("script module", usageCount, countOfReleasesUserCannotSee);
}
export function scriptModuleRunbookSnapshotUsageSummary(usagesInRunbookSnapshots: LibraryVariableSetProjectUsage[], countOfRunbookSnapshotsUserCannotSee: number) {
    const usageCount = usagesInRunbookSnapshots.reduce((prevValue, currValue) => prevValue + currValue.RunbookSnapshots.length, 0);
    return getRunbookSnapshotUsageSummary("script module", usageCount, countOfRunbookSnapshotsUserCannotSee);
}
export function variableSetReleaseUsageSummary(usagesInReleaseSnapshots: LibraryVariableSetProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageSummary("variable set", usageCount, countOfReleasesUserCannotSee);
}
export function variableSetRunbookSnapshotUsageSummary(usagedInRunbookSnapshots: LibraryVariableSetProjectUsage[], countOfRunbookSnapshotsUserCannotSee: number) {
    const usageCount = usagedInRunbookSnapshots.reduce((prevValue, currValue) => prevValue + currValue.RunbookSnapshots.length, 0);
    return getRunbookSnapshotUsageSummary("variable set", usageCount, countOfRunbookSnapshotsUserCannotSee);
}
export function scriptModuleReleaseUsageHelp(usagesInReleaseSnapshots: LibraryVariableSetProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageHelp("script module", usageCount, countOfReleasesUserCannotSee);
}
export function scriptModuleRunbookUsageHelp(usagesInRunbookSnapshots: LibraryVariableSetProjectUsage[], countOfRunbookSnapshotsUserCannotSee: number) {
    const usageCount = usagesInRunbookSnapshots.reduce((prevValue, currValue) => prevValue + currValue.RunbookSnapshots.length, 0);
    return getRunbookSnapshotUsageHelp("script module", usageCount, countOfRunbookSnapshotsUserCannotSee);
}
export function variableSetReleaseUsageHelp(usagesInReleaseSnapshots: LibraryVariableSetProjectUsage[], countOfReleasesUserCannotSee: number) {
    const usageCount = usagesInReleaseSnapshots.reduce((prevValue, currValue) => prevValue + currValue.Releases.length, 0);
    return getReleaseUsageHelp("variable set", usageCount, countOfReleasesUserCannotSee);
}
export function variableSetRunbookUsageHelp(usagesInRunbookSnapshots: LibraryVariableSetProjectUsage[], countOfRunbookSnapshotsUserCannotSee: number) {
    const usageCount = usagesInRunbookSnapshots.reduce((prevValue, currValue) => prevValue + currValue.RunbookSnapshots.length, 0);
    return getRunbookSnapshotUsageHelp("variable set", usageCount, countOfRunbookSnapshotsUserCannotSee);
}
