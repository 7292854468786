/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { AccountResource, AmazonWebServicesAccessKeyAccountResource, AmazonWebServicesOidcAccountResource, AzureEnvironment, AzureOidcAccountResource, AzureServicePrincipalAccountResource, AzureSubscriptionAccountResource, EnvironmentResource, GoogleCloudAccountResource, SshKeyPairAccountResource, TenantResource, TokenAccountResource, UsernamePasswordAccountResource, } from "@octopusdeploy/octopus-server-client";
import { AccountType, Permission } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import * as React from "react";
import type { AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { useCorrelationId, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent";
import FormPage from "~/components/FormPage/FormPage";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { ErrorPanel } from "~/components/form";
import { InfrastructureLayoutBusy } from "../InfrastructureLayout/InfrastructureLayout";
import type { AccountDrawerControlProps } from "./AccountEditBase";
import AmazonWebServicesAccountEdit from "./AmazonWebServicesAccountEdit";
import AzureAccountEdit from "./AzureAccountEdit";
import GoogleCloudAccountEdit from "./GoogleCloudAccountEdit";
import SshKeyPairAccountEdit from "./SshKeyPairAccountEdit";
import TokenAccountEdit from "./TokenAccountEdit";
import UsernamePasswordAccountEdit from "./UsernamePasswordAccountEdit";
interface NewAccountData {
    accountType: AccountType;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    azureEnvironments: AzureEnvironment[];
}
interface AccountData extends NewAccountData {
    account: AccountResource;
}
export type AccountInitialData = NewAccountData | AccountData;
const AccountEditFormPage = FormPage<AccountInitialData>();
const Title = "Accounts";
const IsNew = "IsNew";
interface NewAccountEditProps {
    createNew: true;
    accountType: AccountType;
}
interface ExistingAccountEditProps {
    createNew: false;
    accountId: string;
}
export function AccountPage(props: NewAccountEditProps | ExistingAccountEditProps) {
    const trackAction = useAnalyticTrackedActionDispatch();
    const trackEvent = useTrackEvent();
    const correlationId = useCorrelationId();
    return (<AccountEditFormPage title={Title} load={async () => {
            const environments = repository.Environments.all();
            const tenants = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
            const azureEnvironments = repository.Accounts.getIsolatedAzureEnvironments();
            if (!props.createNew) {
                const account = await repository.Accounts.get(props.accountId);
                return {
                    environments: await environments,
                    tenants: await tenants,
                    azureEnvironments: await azureEnvironments,
                    account,
                    accountType: account.AccountType,
                };
            }
            else {
                return {
                    environments: await environments,
                    tenants: await tenants,
                    azureEnvironments: await azureEnvironments,
                    accountType: props.accountType,
                };
            }
        }} reloadOnPropChanges={true} // Our accountType prop needs to force a reload.
     renderWhenLoaded={(data) => <AccountEditInternal trackAction={trackAction} initialData={data} trackEvent={trackEvent} correlationId={correlationId} type="paper"/>} renderAlternate={(args) => <InfrastructureLayoutBusy title={Title} {...args}/>}/>);
}
type AccountEditInternalProps = {
    initialData: AccountInitialData;
    trackAction: AnalyticTrackedActionDispatcher;
    trackEvent?: ReturnType<typeof useTrackEvent>;
    correlationId?: string;
};
type AccountEditInternalPaperProps = {
    type: "paper";
} & AccountEditInternalProps;
type AccountEditInternalDrawerProps = {
    type: "drawer";
} & AccountEditInternalProps & AccountDrawerControlProps;
export class AccountEditInternal extends BaseComponent<AccountEditInternalPaperProps | AccountEditInternalDrawerProps> {
    constructor(props: AccountEditInternalPaperProps | AccountEditInternalDrawerProps) {
        super(props);
    }
    hasAccount(check: object): check is AccountData {
        return (check as AccountData).account !== undefined;
    }
    render() {
        const data = this.props.initialData;
        const account = this.hasAccount(data) ? data.account : IsNew;
        const commonProps = {
            ...data,
            ...this.props,
        };
        switch (this.props.initialData.accountType) {
            case AccountType.SshKeyPair:
                return <SshKeyPairAccountEdit {...commonProps} account={account as SshKeyPairAccountResource}/>;
            case AccountType.Token:
                return <TokenAccountEdit {...commonProps} account={account as TokenAccountResource}/>;
            case AccountType.UsernamePassword:
                return <UsernamePasswordAccountEdit {...commonProps} account={account as UsernamePasswordAccountResource}/>;
            case AccountType.AzureSubscription:
            case AccountType.AzureOidc:
            case AccountType.AzureServicePrincipal:
                return <AzureAccountEdit {...commonProps} account={account as AzureServicePrincipalAccountResource | AzureSubscriptionAccountResource | AzureOidcAccountResource}/>;
            case AccountType.AmazonWebServicesAccount:
            case AccountType.AmazonWebServicesOidcAccount:
                return <AmazonWebServicesAccountEdit {...commonProps} account={account as AmazonWebServicesAccessKeyAccountResource | AmazonWebServicesOidcAccountResource}/>;
            case AccountType.GoogleCloudAccount:
                return <GoogleCloudAccountEdit {...commonProps} account={account as GoogleCloudAccountResource}/>;
            default:
                return <ErrorPanel message="Invalid path"/>;
        }
    }
    static displayName = "AccountEditInternal";
}
