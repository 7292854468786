import type { PropsWithChildren } from "react";
import * as React from "react";
import { GreenTickIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/GreenTickIcon";
import { PauseIcon } from "~/areas/configuration/components/AuditLayout/AuditStream/PauseIcon";
import { ActiveStatusIcon } from "~/components/ActiveStatusIcon/ActiveStatusIcon";
type AuditStreamStatus = "active" | "paused" | "inactive";
interface AuditActionWithStatusProps {
    status: AuditStreamStatus;
}
export function AuditActionWithStatus({ status, children }: PropsWithChildren<AuditActionWithStatusProps>) {
    return (<div>
            {getStatusIcon(status)}
            {children}
        </div>);
}
function getStatusIcon(status: AuditStreamStatus) {
    switch (status) {
        case "active":
            return <GreenTickIcon />;
        case "inactive":
            return <ActiveStatusIcon active={false}/>;
        case "paused":
            return <PauseIcon />;
    }
}
