import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
export const UserSignOutRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.currentUser.signOut,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
};
