import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { Section } from "~/components/Section/Section";
import SectionNote from "~/components/SectionNote/SectionNote";
export const BackupPage: React.StatelessComponent<{}> = (props) => (<PaperLayoutVNext title="Backup">
        <SectionNote>
            Your Octopus database can be backed up by configuring backups for SQL Server and the file system. For more information, please see the <ExternalLink href="BackupRestore">documentation on backups and restores</ExternalLink>.
        </SectionNote>
        <Section>
            <Callout type={"warning"} title="Master Key">
                Certain data in your Octopus Deploy database, such as sensitive variables and passwords for NuGet feeds, is encrypted. You won't be able to restore this data without a{" "}
                <ExternalLink href="SaveMyMasterKey">copy of your Octopus master key</ExternalLink>
            </Callout>
        </Section>
    </PaperLayoutVNext>);
BackupPage.displayName = "BackupPage"
