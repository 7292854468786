import type { FeatureToggle } from "@octopusdeploy/octopus-server-client";
import type { AnyProviderEvent, EvaluationContext, Hook, JsonValue, Logger, Provider, ProviderEventEmitter, ProviderMetadata, ResolutionDetails } from "@openfeature/web-sdk";
import { ProviderEvents, ErrorCode, OpenFeatureEventEmitter } from "@openfeature/web-sdk";
export class FeatureToggleProvider implements Provider {
    metadata: ProviderMetadata;
    private featureToggles: ReadonlyArray<FeatureToggle>;
    constructor(featureToggles: ReadonlyArray<FeatureToggle>) {
        this.metadata = { name: "environment-variable-featuretoggles" };
        this.featureToggles = featureToggles;
        this.events = new OpenFeatureEventEmitter();
    }
    hooks?: Hook[] | undefined;
    updateFeatures(featureToggles: ReadonlyArray<FeatureToggle>) {
        this.featureToggles = featureToggles;
        this.events?.emit(ProviderEvents.ConfigurationChanged);
    }
    events?: ProviderEventEmitter<AnyProviderEvent> | undefined;
    onContextChange?(oldContext: EvaluationContext, newContext: EvaluationContext): void | Promise<void> {
        return Promise.resolve();
    }
    resolveBooleanEvaluation(flagKey: string, defaultValue: boolean, context: EvaluationContext, logger: Logger): ResolutionDetails<boolean> {
        const envVar = this.featureToggles.find((t) => t.toLowerCase() === flagKey.toLowerCase());
        if (!envVar) {
            return { value: defaultValue, errorCode: ErrorCode.FLAG_NOT_FOUND };
        }
        return { value: true };
    }
    resolveStringEvaluation(flagKey: string, defaultValue: string, context: EvaluationContext, logger: Logger): ResolutionDetails<string> {
        throw new Error("Method not implemented.");
    }
    resolveNumberEvaluation(flagKey: string, defaultValue: number, context: EvaluationContext, logger: Logger): ResolutionDetails<number> {
        throw new Error("Method not implemented.");
    }
    resolveObjectEvaluation<T extends JsonValue>(flagKey: string, defaultValue: T, context: EvaluationContext, logger: Logger): ResolutionDetails<T> {
        throw new Error("Method not implemented.");
    }
}
