import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppInstallation } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { GitHubConnectionStatus, GitHubChipList, GitHubConnectionStatusChip } from "./ChipList/GitHubChips";
import { GitHubInstallationLogo } from "./GitHubInstallationLogo";
interface GitHubAppInstallationDisplayProps {
    installation: GitHubAppInstallation;
    connectionStatus?: GitHubConnectionStatus;
}
export default function GitHubAppInstallationDisplay({ installation, connectionStatus = GitHubConnectionStatus.None }: GitHubAppInstallationDisplayProps) {
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            padding: space[16],
            gap: space[16],
            alignItems: "center",
        }),
        accountName: css({
            flexGrow: 1,
        }),
    };
    return (<div className={styles.container}>
            <GitHubInstallationLogo installation={installation}/>
            <h3 className={styles.accountName}>{installation.AccountLogin}</h3>
            <GitHubChipList>
                <GitHubConnectionStatusChip status={connectionStatus}/>
            </GitHubChipList>
        </div>);
}
