import * as React from "react";
import { parseGitProtectionPatterns, userFriendlyBranchAndTagsDisplay } from "~/areas/projects/components/Channels/GitProtectionRules/GitProtectionRules";
export function getGitReferencePatternDescription(patterns: string[]) {
    if (patterns.length === 0) {
        return undefined;
    }
    const { branches, tags, other } = parseGitProtectionPatterns(patterns);
    const matchingRefTypeDescriptions: Array<{
        description: string;
        value: string;
    }> = [];
    if (branches.length > 0) {
        matchingRefTypeDescriptions.push({
            description: "branches",
            value: userFriendlyBranchAndTagsDisplay(branches),
        });
    }
    if (tags.length > 0) {
        matchingRefTypeDescriptions.push({
            description: "tags",
            value: userFriendlyBranchAndTagsDisplay(tags),
        });
    }
    if (other.length > 0) {
        matchingRefTypeDescriptions.push({
            description: "other Git references",
            value: other.join(", "),
        });
    }
    return matchingRefTypeDescriptions.map((d, index) => (<span key={index}>
            {index > 0 ? (index >= matchingRefTypeDescriptions.length - 1 ? " or " : ", ") : ""}
            {d.description} matching <strong>{d.value}</strong>
        </span>));
}
