import type { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
import { withTheme } from "~/components/Theme";
const OpenIdConnectIcon = (props: SvgIconProps) => withTheme((theme) => (<SvgIcon viewBox="0 0 48 48" fill={theme.iconNeutral} {...props}>
            <path fill="#9E9E9E" d="M44,27l-1-9l-2.9,1.9c-2.7-1.7-6.1-2.9-9.9-3.5c0,0-1.9-0.4-4.4-0.4s-4.8,0.3-4.8,0.3C11.3,17.5,4,23,4,29.6C4,36.4,11.5,42,23,43v-3.9c-7.9-1.1-12.9-4.8-12.9-9.5c0-4.4,4.6-8.1,10.9-9.3c0,0,4.9-1.1,9.2,0.2c2.1,0.5,4,1.2,5.6,2.2L32,25L44,27z"/>
            <path d="M23 8L23 43 29 40 29 5z"/>
            <path fill="#FF9800" d="M23 8L23 43 29 40 29 5z"/>
        </SvgIcon>));
export default OpenIdConnectIcon;
