/* eslint-disable @typescript-eslint/no-explicit-any */
import { Callout } from "@octopusdeploy/design-system-components";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
interface PendingInterruptionsProps {
    pendingInterruptions: Array<TaskResource<any>>;
}
const PendingInterruptions: React.StatelessComponent<PendingInterruptionsProps> = (props) => {
    return (<div>
            {props.pendingInterruptions && props.pendingInterruptions.length > 0 && (<Callout type={"warning"} title={props.pendingInterruptions.length === 1 ? "1 other release still has a pending interruption" : props.pendingInterruptions.length + " other releases still have pending interruptions that should be reviewed."}>
                    <ul>
                        {props.pendingInterruptions.map((p, index) => (<li key={index}>
                                <InternalLink to={links.taskPage.generateUrl({ taskId: p.Id })}>{p.Description}</InternalLink>
                            </li>))}
                    </ul>
                </Callout>)}
        </div>);
};
PendingInterruptions.displayName = "PendingInterruptions"
export default PendingInterruptions;
