import { text } from "@octopusdeploy/design-system-tokens";
import pluralize from "pluralize";
import * as React from "react";
import { styles } from "~/areas/projects/components/Variables/TenantVariables/styles";
import ShareFilterButton from "~/components/ShareFilterButton/ShareFilterButton";
interface FilterActionsProps {
    unsavedChanges?: number;
}
export function FilterActions({ unsavedChanges }: FilterActionsProps) {
    return (<div className={styles.toolbarIconsContainer}>
            <UnsavedChangesCount count={unsavedChanges}/>
            <ShareFilterButton />
        </div>);
}
function UnsavedChangesCount({ count }: {
    count: number | undefined;
}) {
    if (!count) {
        return null;
    }
    const changes = pluralize("unsaved change", count);
    return (<span style={{ font: text.interface.body.default.small }}>
            {count} {changes}
        </span>);
}
