import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
export function createUIFeatureFlagToggledEvent(isEnabled: boolean, featureFlagKey: string): UIFeatureEnabledEvent | UIFeatureDisabledEvent {
    return isEnabled ? createUIFeatureEnabledEvent(featureFlagKey) : createUIFeatureDisabledEvent(featureFlagKey);
}
interface UIFeatureEnabledEvent extends AnalyticsEvent<"UI Feature Enabled"> {
    "Feature Flag Key": string;
}
function createUIFeatureEnabledEvent(featureFlagKey: string): UIFeatureEnabledEvent {
    return {
        eventName: "UI Feature Enabled",
        "Feature Flag Key": featureFlagKey,
    };
}
interface UIFeatureDisabledEvent extends AnalyticsEvent<"UI Feature Disabled"> {
    "Feature Flag Key": string;
}
function createUIFeatureDisabledEvent(featureFlagKey: string): UIFeatureDisabledEvent {
    return {
        eventName: "UI Feature Disabled",
        "Feature Flag Key": featureFlagKey,
    };
}
