import type { GetReleaseDetailBffResponseProgression } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
export interface LifecycleStatus {
    deployed: {
        [key: string]: boolean;
    };
    tasks: {
        [key: string]: string;
    };
    warning: {
        [key: string]: boolean;
    };
    executing: {
        [key: string]: boolean;
    };
    queued: {
        [key: string]: boolean;
    };
    progress: {
        [key: string]: string;
    };
}
export function mapProgressionToStatus(progression?: GetReleaseDetailBffResponseProgression): LifecycleStatus {
    const p: LifecycleStatus = {
        deployed: {},
        tasks: {},
        warning: {},
        executing: {},
        queued: {},
        progress: {},
    };
    if (!progression) {
        return p;
    }
    progression.Phases.forEach((phase) => {
        let phaseStatus = phase.Progress.toString();
        if (phase.Blocked) {
            phaseStatus = "Blocked";
        }
        p.progress[phase.Name] = phaseStatus;
        phase.Deployments.forEach((deployment) => {
            p.deployed[deployment.DeploymentEnvironmentId] = true;
            p.tasks[deployment.DeploymentEnvironmentId] = deployment.TaskId;
            p.warning[deployment.DeploymentEnvironmentId] = deployment.TaskHasWarningsOrErrors;
            p.executing[deployment.DeploymentEnvironmentId] = deployment.TaskState !== TaskState.Success;
            p.queued[deployment.DeploymentEnvironmentId] = deployment.TaskState === TaskState.Queued;
        });
    });
    return p;
}
