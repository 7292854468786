import type { ConnectTenantsToProjectTaskResource, ProjectTenant } from "@octopusdeploy/octopus-server-client";
import type React from "react";
import { useRef, useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import useLocalStorage from "~/hooks/useLocalStorage";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner/index";
const projectTenantsPagingSizeKey = "octoProjectTenantsPagingSize";
interface ProjectTenantsTableState {
    tenants: ProjectTenant[];
    filteredTenantsCount: number;
    totalTenantCount: number;
}
interface ProjectTenantsStateActions {
    setPageNumber: (newPageNumber: number) => void;
    setPageSize: (newPageSize: number) => void;
    refreshTable: () => void;
    setSearchName: (name: string) => void;
}
interface TenantsPageState {
    pageNumber: number;
    pageSize: number;
}
interface ProjectTenantsState {
    searchName: string;
    tableState: ProjectTenantsTableState | null;
    pageState: TenantsPageState;
    actions: ProjectTenantsStateActions;
}
interface ProjectTenantsFilterParams {
    pageNumber: number;
    name: string;
}
export function useProjectTenantsState(projectId: string, doBusyTask: DoBusyTask, updateShowActionButtons: (show: boolean) => void, latestConnectionTask: ConnectTenantsToProjectTaskResource | undefined): ProjectTenantsState {
    const [pageSize, setPageSize] = useLocalStorage(projectTenantsPagingSizeKey, 30);
    const [tableState, setTableState] = useState<ProjectTenantsTableState | null>(null);
    const [dataVersion, setDataVersion] = useState(1);
    const [filter, setFilter] = useState<ProjectTenantsFilterParams>({ pageNumber: 1, name: "" });
    const raceConditionerRef: Readonly<React.MutableRefObject<RequestRaceConditioner>> = useRef(new RequestRaceConditioner());
    useDoBusyTaskEffect(doBusyTask, async () => {
        await raceConditionerRef.current.avoidStaleResponsesForRequest(repository.Projects.getTenants(projectId, filter.pageNumber, pageSize, filter.name), (response) => {
            setTableState({ tenants: response.Tenants, totalTenantCount: response.TotalTenantCount, filteredTenantsCount: response.FilteredTenantCount });
            updateShowActionButtons(response.TotalTenantCount !== 0);
        });
    }, [pageSize, latestConnectionTask?.ConnectionsCompleted, latestConnectionTask?.Id, dataVersion, filter]);
    const actions: ProjectTenantsStateActions = {
        setPageNumber: (newPageNumber) => setFilter({ ...filter, pageNumber: newPageNumber }),
        setPageSize: (newPageSize) => setPageSize(newPageSize),
        refreshTable: () => setDataVersion(dataVersion + 1),
        setSearchName: (name: string) => setFilter({ ...filter, name, pageNumber: 1 }), // Reset to first page when filter changed
    };
    return {
        searchName: filter.name,
        tableState,
        pageState: {
            pageNumber: filter.pageNumber,
            pageSize,
        },
        actions,
    };
}
