import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, LinearProgress, List, ListItem } from "@octopusdeploy/design-system-components";
import { fontSize, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import React from "react";
import { SampleProjectTourStep } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStep";
import FilterSearchBox from "~/components/FilterSearchBox";
import { NoResults } from "~/components/NoResults/NoResults";
import { ListInternalLink } from "~/components/PagingList/ListInternalLink";
import Section from "~/components/Section";
interface LoadMoreListProps<R extends {
    Id: string;
    Name: string;
}> {
    loadingMore?: boolean;
    items: R[];
    renderRow: (item: R) => React.ReactNode;
    getNavigationUrl: (item: R) => LinkHref;
    getAccessibleName: (item: R) => string;
    showLoadMore: boolean;
    onLoadMore: () => Promise<void> | void;
    onFilterChanged: (value: string) => void;
}
export function LoadMoreList<R extends {
    Id: string;
    Name: string;
}>({ loadingMore, items, renderRow, getNavigationUrl, getAccessibleName, showLoadMore, onLoadMore, onFilterChanged }: LoadMoreListProps<R>) {
    const styles = {
        filterContainer: css({
            fontSize: fontSize["medium"],
        }),
        loadMoreContainer: css({
            padding: space[16],
        }),
        loadMoreActions: css({
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            alignItems: "center",
        }),
        loadMoreSubtitle: css({
            color: themeTokens.color.text.secondary,
            fontSize: fontSize["xSmall"],
        }),
    };
    return (<>
            <Section>
                <div className={styles.filterContainer} key="filterSearch">
                    <FilterSearchBox placeholder={"Filter by name..."} debounceDelay={500} onChange={onFilterChanged}/>
                </div>
            </Section>
            <SampleProjectTourStep stepId={"Runbooks"}>
                <List empty={<EmptyListResult />} items={items} rowKey={(item) => item.Id} renderRow={(item) => <LoadMoreListItem item={item.item} renderRow={renderRow} getNavigationUrl={getNavigationUrl} getAccessibleName={getAccessibleName}/>}/>
            </SampleProjectTourStep>
            {showLoadMore && (<div className={styles.loadMoreContainer}>
                    <div className={styles.loadMoreActions}>
                        {loadingMore ? (<LinearProgress variant={"indeterminate"} show={true}/>) : (<>
                                <ActionButton type={ActionButtonType.Secondary} label="Load more" onClick={onLoadMore}/>
                                <div className={styles.loadMoreSubtitle}>Or use filters to narrow the search results</div>
                            </>)}
                    </div>
                </div>)}
        </>);
}
interface LoadMoreListItemProps<R> {
    item: R;
    renderRow: (item: R) => React.ReactNode;
    getNavigationUrl: (item: R) => LinkHref;
    getAccessibleName: (item: R) => string;
}
function LoadMoreListItem<R>({ item, renderRow, getNavigationUrl, getAccessibleName }: LoadMoreListItemProps<R>) {
    const redirectUrl = getNavigationUrl(item);
    const accessibleName = getAccessibleName(item);
    const styles = {
        listItemContent: css({
            padding: space[16],
            lineHeight: "16px",
            fontSize: "0.875rem",
            wordBreak: "break-word",
        }),
    };
    return (<ListItem>
            <ListInternalLink href={redirectUrl} accessibleName={accessibleName}>
                <div className={styles.listItemContent}>{renderRow(item)}</div>
            </ListInternalLink>
        </ListItem>);
}
function EmptyListResult() {
    return (<Section>
            <NoResults />
        </Section>);
}
