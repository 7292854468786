import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationDiagnosticsOverview, CommonConfigurationDiagnosticsTopics } from "../../CommonComponents/CommonConfigurationDiagnosticsViews";
export const ConfigurationDiagnosticLogsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.diagnostics.logs.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationDiagnosticsOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationDiagnosticsTopics />;
    },
};
