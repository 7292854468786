import type { InputPathToValue } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs, PathToInput } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, getPathToInput, isNotBoundValue } from "@octopusdeploy/step-runtime-inputs";
import type { NoteExpression, MultilineTextComponent } from "@octopusdeploy/step-ui";
import React from "react";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { Note } from "../../Note/Note";
export function getMultilineTextContentSummary<StepInputs>(component: MultilineTextComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessor(component.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (isNotBoundValue(inputValue)) {
        return {
            isDefaultValue: false,
            value: inputValue,
        };
    }
    else {
        return {
            isDefaultValue: false,
            value: inputValue.expression,
        };
    }
}
interface MultilineTextProps<StepInputs> {
    input: InputPathToValue<string>;
    label: string;
    rows: number;
    note?: NoteExpression[];
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    localNames: string[] | undefined;
    getFieldError: (name: PathToInput) => string;
}
export function MultilineText<StepInputs>(props: MultilineTextProps<StepInputs>) {
    const inputAccessor = createInputValueAccessor<StepInputs, string>(props.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.input);
    const value = isNotBoundValue(inputValue) ? inputValue : inputValue.expression;
    // todo-step-ui hook up validation errors
    return (<>
            <VariableLookupText localNames={props.localNames} value={value} multiline={true} rows={props.rows} onChange={(newValue) => {
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue);
            props.setInputs(updatedInputs);
        }} label={props.label} error={props.getFieldError(inputPath)}/>
            <Note note={props.note}/>
        </>);
}
