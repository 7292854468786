import * as React from "react";
type SetPageNavVisibility = (value: boolean) => void;
const InPageNavVisibilityContext = React.createContext(false);
const SetInPageNavVisibilityContext = React.createContext<SetPageNavVisibility | null>(null);
export function useIsInPageNavVisible() {
    return React.useContext(InPageNavVisibilityContext);
}
export function useSetInPageNavVisible() {
    return React.useContext(SetInPageNavVisibilityContext);
}
interface InPageVisibilityProviderProps {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
}
export function InPageNavVisibilityProvider({ isVisible, setIsVisible, children }: React.PropsWithChildren<InPageVisibilityProviderProps>) {
    return (<SetInPageNavVisibilityContext.Provider value={setIsVisible}>
            <InPageNavVisibilityContext.Provider value={isVisible}>{children}</InPageNavVisibilityContext.Provider>
        </SetInPageNavVisibilityContext.Provider>);
}
