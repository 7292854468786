/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ReferenceType, ScopeValues, ScriptingLanguage, VariablePromptOptions } from "@octopusdeploy/octopus-server-client";
import { AccountType, VariableType } from "@octopusdeploy/octopus-server-client";
import { load } from "js-yaml";
import * as React from "react";
import ScopeSelector from "~/areas/variables/ScopeSelector/ScopeSelector";
import { editorModeOptions } from "~/areas/variables/TextFormatSelector/TextFormatSelector";
import type { Language } from "~/components/CodeEditor/CodeEditor";
import { CodeEditor, TextFormat } from "~/components/CodeEditor/CodeEditor";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { CustomDialogActions, CustomDialogContent, CustomDialogTitleBar, CustomExtraContent, FullScreenDialogFrame, MediumDialogFrame, ToggleFrame } from "~/components/DialogLayout/Custom";
import CustomSaveDialogLayout from "~/components/DialogLayout/Custom/CustomSaveDialogLayout";
import DisplayProperties from "~/components/DisplayProperties/DisplayProperties";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import AccountSearch from "~/components/form/AccountSearch";
import CertificateSearch from "~/components/form/CertificateSearch/CertificateSearch";
import type { TextInputRef } from "~/components/form/VariableLookup/VariableLookup";
import WorkerPoolSearch from "~/components/form/WorkerPoolSearch/WorkerPoolSearch";
import Select from "~/primitiveComponents/form/Select/Select";
import type { TextInput } from "~/primitiveComponents/form/Text/Text";
import Text from "~/primitiveComponents/form/Text/Text";
import { RawVariableTypeDetailsMap } from "../VariableDetails";
import { isMultilineValue } from "../VariableValueCell";
import MultilineTextContainer from "../VariableValueCell/MultilineValueContainer";
import type { VariableValueModel } from "../VariablesModel";
import { isReferenceType } from "../isReferenceType";
import EditorPrompt from "./PromptedVariableDetails";
import { convertToNewType } from "./convertToNewType";
import styles from "./style.module.less";
export interface OpenVariableDialogArgs {
    value: VariableValueModel;
    name: string;
    focus: FocusField;
}
export interface WithReferenceTypeDialogArgs {
    referenceType: ReferenceType;
}
export type OpenReferenceVariableDialogArgs = OpenVariableDialogArgs & WithReferenceTypeDialogArgs;
export interface EditVariableDialogProps {
    title: string;
    openDialogArgs?: OpenVariableDialogArgs | OpenReferenceVariableDialogArgs;
    availableScopes: ScopeValues;
    isTenanted: boolean;
    onDone: (value: VariableValueModel, name: string) => void;
    onClosed: () => void;
}
interface EditVariableState extends DataBaseComponentState {
    value: VariableValueModel;
    name: string;
    editorMode: TextFormat | ScriptingLanguage | Language;
    page: Page;
}
enum Page {
    Value,
    Scope
}
export enum FocusField {
    Name,
    Description,
    Value,
    Scope
}
interface VariableCodeEditorViewProps {
    className?: string;
    onValueChange: (value: string) => void;
    value?: string;
    onEscPressed?(): void;
    editorMode: TextFormat | ScriptingLanguage | Language;
    onEditorModeChange: (mode: TextFormat | ScriptingLanguage | Language) => void;
}
const updateValue = (update: Partial<VariableValueModel>) => {
    return (prevState: EditVariableState) => ({
        value: {
            ...prevState.value,
            ...update,
        },
    });
};
const updatePrompt = (update: Partial<VariablePromptOptions> | null) => {
    return (prevState: EditVariableState) => ({
        value: {
            ...prevState.value,
            Prompt: {
                ...prevState.value.Prompt,
                ...update,
            },
        },
    });
};
const resetPromptValues = (shouldPrompt: boolean) => {
    return (prevState: EditVariableState) => ({
        value: {
            ...prevState.value,
            Prompt: shouldPrompt
                ? {
                    Label: "",
                    Description: "",
                    Required: false,
                }
                : null,
        },
    });
};
class VariableCodeEditorView extends React.Component<VariableCodeEditorViewProps> {
    constructor(props: VariableCodeEditorViewProps) {
        super(props);
    }
    render() {
        const { value, onValueChange, onEscPressed, children } = this.props;
        return (<div className={this.props.className}>
                <div className={styles.editor}>
                    <CodeEditor containerClassName={styles.editorContainer} value={value ?? ""} allowFullScreen={false} fullHeight={true} label="Value" language={this.props.editorMode} onEscPressed={onEscPressed} onChange={onValueChange} showToolbar={true} showCopyButton={true} scriptingLanguageSelectorOptions={{
                supportedLanguages: editorModeOptions.map((option) => option.value as ScriptingLanguage | Language | TextFormat),
                onScriptingLanguageChanged: (syntax: ScriptingLanguage | Language | TextFormat) => {
                    this.props.onEditorModeChange(syntax);
                },
            }}/>
                </div>
                {children}
            </div>);
    }
    static displayName = "VariableCodeEditorView";
}
export class EditVariableDialog extends React.Component<EditVariableDialogProps> {
    render() {
        const args = this.props.openDialogArgs;
        const open = !!args;
        return <CustomDialog open={open} close={this.props.onClosed} render={(renderProps) => <Inner {...this.props} open={open}/>}/>;
    }
    static displayName = "EditVariableDialog";
}
class Inner extends DataBaseComponent<EditVariableDialogProps & {
    open: boolean;
}, EditVariableState> {
    private nameInput: TextInput | null = null;
    private descriptionInput: TextInput | null = null;
    private valueInput?: TextInputRef;
    private nextFocus?: FocusField;
    constructor(props: EditVariableDialogProps & {
        open: boolean;
    }) {
        super(props);
        this.state = getInitialState(props.openDialogArgs!);
        this.nextFocus = this.props.openDialogArgs!.focus;
    }
    componentDidMount() {
        this.selectInputIfRequired();
    }
    componentDidUpdate(prevProps: EditVariableDialogProps, prevState: EditVariableState) {
        this.selectInputIfRequired();
    }
    UNSAFE_componentWillReceiveProps(nextProps: EditVariableDialogProps) {
        if (nextProps.openDialogArgs !== this.props.openDialogArgs) {
            this.setState(getInitialState(nextProps.openDialogArgs!));
            this.nextFocus = nextProps.openDialogArgs!.focus;
        }
    }
    render() {
        return (<ToggleFrame initialFrame={MediumDialogFrame} alternateFrame={FullScreenDialogFrame} render={({ frame, isInitialFrame, toggleFrame }) => (<CustomSaveDialogLayout frame={frame} open={this.props.open} errors={this.errors} close={this.onDialogClose} busy={this.state.busy} onSaveClick={() => {
                    this.props.onDone(this.state.value, this.state.name);
                    this.props.onClosed();
                    return Promise.resolve(true);
                }} renderActions={(actionProps) => <CustomDialogActions actions={this.getRightSideActions()} additionalActions={this.getLeftSideActions()}/>} renderTitle={() => (<CustomDialogTitleBar title={this.props.title} actions={<IconButtonWithTooltip toolTipContent={`${isInitialFrame ? "Enter" : "Exit"} full screen`} onClick={toggleFrame} icon={isInitialFrame ? "EnterFullScreen" : "ExitFullScreen"} style={{ minWidth: "1rem" }}/>}/>)} renderContent={(renderProps) => (<React.Fragment>
                                {this.state.page === Page.Scope && (<CustomExtraContent className={styles.detailsHighlight}>
                                        <DisplayProperties properties={[
                            { key: "Name", value: this.state.name },
                            {
                                key: "Value",
                                value: this.getValueToDisplayByType(this.state.value.Value!),
                            },
                        ]}/>
                                    </CustomExtraContent>)}
                                <CustomDialogContent>
                                    {this.state.page === Page.Value && (<div className={styles.content}>
                                            <div className={styles.inputRow}>
                                                <Text textInputRef={(e) => (this.nameInput = e)} label={"Name"} value={this.state.name} onChange={(name) => this.setState({ name })}/>
                                                <Select label="Variable type" value={this.state.value.Type} items={RawVariableTypeDetailsMap} onChange={(Type) => this.setState((prevState) => ({ value: convertToNewType(prevState!.value, Type as VariableType) }))}/>
                                                <Text textInputRef={(e) => (this.descriptionInput = e)} value={this.state.value.Description!} label="Description" onChange={(Description) => this.setState(updateValue({ Description }))}/>
                                                <EditorPrompt variable={this.state.value} onPromptChange={(shouldPrompt) => this.setStateAndResizeDialog(resetPromptValues(shouldPrompt))} prompt={!!this.state.value.Prompt} description={this.state.value.Prompt ? this.state.value.Prompt.Description : null!} label={this.state.value.Prompt ? this.state.value.Prompt.Label : null!} displaySettings={this.state.value.Prompt && this.state.value.Prompt.DisplaySettings ? this.state.value.Prompt.DisplaySettings : {}} required={this.state.value.Prompt ? this.state.value.Prompt.Required : null!} onDescriptionChange={(Description) => this.setState(updatePrompt({ Description }))} onLabelChange={(Label) => this.setState(updatePrompt({ Label }))} onRequiredChange={(Required) => this.setState(updatePrompt({ Required }))} onDisplaySettingsChange={(DisplaySettings) => this.setStateAndResizeDialog(updatePrompt({ DisplaySettings }))}/>
                                            </div>
                                            <div className={styles.value}>
                                                {this.state.value.Type === VariableType.Certificate && (<CertificateSearch selectedCertificateId={this.state.value.Value!} onSelected={({ Id: Value }) => this.setState(updateValue({ Value }))} doBusyTask={this.doBusyTask}/>)}
                                                {this.state.value.Type === VariableType.WorkerPool && (<WorkerPoolSearch selectedWorkerPoolId={this.state.value.Value!} onSelected={({ Id: Value }) => this.setState(updateValue({ Value }))} doBusyTask={this.doBusyTask}/>)}
                                                {this.state.value.Type === VariableType.AmazonWebServicesAccount && (<AccountSearch selectedAccountId={this.state.value.Value!} onSelected={({ Id: Value }) => this.setState(updateValue({ Value }))} primaryAccountType={AccountType.AmazonWebServicesAccount} doBusyTask={this.doBusyTask}/>)}
                                                {this.state.value.Type === VariableType.AzureAccount && (<AccountSearch selectedAccountId={this.state.value.Value!} onSelected={({ Id: Value }) => this.setState(updateValue({ Value }))} primaryAccountType={AccountType.AzureSubscription} doBusyTask={this.doBusyTask}/>)}
                                                {this.state.value.Type === VariableType.GoogleCloudAccount && (<AccountSearch selectedAccountId={this.state.value.Value!} onSelected={({ Id: Value }) => this.setState(updateValue({ Value }))} primaryAccountType={AccountType.GoogleCloudAccount} doBusyTask={this.doBusyTask}/>)}
                                                {this.state.value.Type === VariableType.UsernamePasswordAccount && (<AccountSearch selectedAccountId={this.state.value.Value!} onSelected={({ Id: Value }) => this.setState(updateValue({ Value }))} primaryAccountType={AccountType.UsernamePassword} doBusyTask={this.doBusyTask}/>)}
                                                {(this.state.value.Type === VariableType.Sensitive || this.state.value.Type === VariableType.String) && (<VariableCodeEditorView value={this.state.value.Value!} onValueChange={(Value) => this.setState(updateValue({ Value }))} editorMode={this.state.editorMode} onEditorModeChange={(editorMode) => this.setState({ editorMode })}/>)}
                                            </div>
                                        </div>)}
                                    {this.state.page === Page.Scope && (<div>
                                            <div>
                                                <ScopeSelector value={this.state.value.Scope} availableScopes={this.props.availableScopes} variableType={this.state.value.Type} onScopeSelected={(Scope) => this.setState(updateValue({ Scope }))} allowTenantTagSelection={this.props.isTenanted} useCompactControls={false} doBusyTask={this.doBusyTask}/>
                                            </div>
                                        </div>)}
                                </CustomDialogContent>
                            </React.Fragment>)}/>)}/>);
    }
    private getValueToDisplayByType(value: string) {
        if (isMultilineValue(value)) {
            return (<MultilineTextContainer isFocused={true} hideDivider={true} showHand={false}>
                    {value}
                </MultilineTextContainer>);
        }
        return value;
    }
    private selectInputIfRequired() {
        const focusedField = this.nextFocus;
        this.nextFocus = null!;
        // Focus after a timeout to give the form time to display
        window.setTimeout(() => {
            if (this.state.value) {
                if (this.nameInput && focusedField === FocusField.Name) {
                    this.nameInput.select();
                }
                if (this.valueInput && focusedField === FocusField.Value) {
                    this.valueInput.focus();
                }
                if (this.descriptionInput && focusedField === FocusField.Description) {
                    this.descriptionInput.select();
                }
                // Don't highlight any inputs from the scope selector if this.props.focus === FocusField.Scope,
                // because a) the user probably wants to configure things like tenant tags, rather than the first input in scope selector
                // and b) because of the show popup animation, the autocomplete popup will probably be in the wrong position
            }
        }, 0);
    }
    private setStateAndResizeDialog = async <K extends keyof EditVariableState>(state: Pick<EditVariableState, K>) => {
        // Perform in doBusy to trigger our dialog resize fix #dialogResizeHack
        await this.doBusyTask(async () => {
            this.setState(state);
        });
    };
    private getLeftSideActions() {
        return (<React.Fragment>
                {this.state.page === Page.Value && (<ActionButton key="define scope" label="Define scope" type={ActionButtonType.Secondary} onClick={() => {
                    this.setState({ page: Page.Scope });
                }}/>)}

                {this.state.page !== Page.Value && (<ActionButton key="previous" label="Previous" type={ActionButtonType.Secondary} onClick={() => {
                    this.setState({ page: Page.Value });
                }}/>)}
            </React.Fragment>);
    }
    private getRightSideActions() {
        return (<React.Fragment>
                <ActionButton key="cancel" label="Cancel" onClick={this.props.onClosed}/>
                <ActionButton key="done" label="Done" type={ActionButtonType.Primary} disabled={this.isDisabled()} onClick={() => {
                this.props.onDone(this.state.value, this.state.name);
                this.props.onClosed();
            }}/>
            </React.Fragment>);
    }
    private isDisabled() {
        const name = this.state.name ? this.state.name : "";
        // certificate type must have a value, as it's not supported for use in "prompt for a value"
        if (!name) {
            return true;
        }
        if (!this.state.value.Value && isReferenceType(this.state.value.Type)) {
            return true;
        }
        return false;
    }
    private onDialogClose = () => {
        this.props.onClosed();
    };
    static displayName = "Inner";
}
function isVariableReferenceDialogArgs(args: OpenVariableDialogArgs | OpenReferenceVariableDialogArgs): args is OpenReferenceVariableDialogArgs {
    return (args as OpenReferenceVariableDialogArgs).referenceType !== undefined;
}
function getInitialState(openDialogArgs: OpenVariableDialogArgs | OpenReferenceVariableDialogArgs): EditVariableState {
    const value = variableAdjustment(openDialogArgs);
    return {
        value: value,
        name: openDialogArgs.name,
        editorMode: initialEditorModeValue(value),
        page: openDialogArgs.focus === FocusField.Scope ? Page.Scope : Page.Value,
    };
}
function variableAdjustment(openDialogArgs: OpenReferenceVariableDialogArgs | OpenVariableDialogArgs) {
    return !isVariableReferenceDialogArgs(openDialogArgs) ? openDialogArgs.value : convertToNewType(openDialogArgs.value, openDialogArgs.referenceType);
}
function initialEditorModeValue(value: VariableValueModel): TextFormat | ScriptingLanguage | Language {
    if (value.Type === VariableType.String && !!value.Value) {
        try {
            JSON.parse(value.Value);
            return TextFormat.JSON;
        }
        catch {
            /* empty */
        }
        try {
            const gg = load(value.Value);
            if (typeof gg !== "string") {
                return TextFormat.YAML;
            }
        }
        catch {
            /* empty */
        }
    }
    return editorModeOptions[0].value as TextFormat | ScriptingLanguage | Language;
}
