import type { ProcessType } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ProcessListForBlueprint from "~/areas/blueprints/processEditor/ProcessListForBlueprint";
import { useMaybeLoadedActionTemplatesFromContext } from "~/areas/projects/components/Process/Contexts/ProcessActionTemplatesContextProvider";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import type { ProcessListLayoutLoaderLookupData } from "~/areas/projects/components/Process/ProcessListLayoutLoader";
import type { Errors } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
interface ProcessPageProps {
    processType: ProcessType;
    lookups: ProcessListLayoutLoaderLookupData;
    errors?: Errors;
    busy?: boolean;
    doBusyTask: DoBusyTask;
    isBuiltInWorkerEnabled: boolean;
}
const ProcessListLayoutForBlueprint: React.FC<ProcessPageProps> = (props) => {
    const location = useLocation();
    const history = useHistory();
    const processContext = useProcessContext();
    const feeds = useFeedsFromContext();
    const actionTemplates = useMaybeLoadedActionTemplatesFromContext();
    const hasLoadedNecessaryLookupData = (): boolean => {
        const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, wildcard: true });
        return actionTemplates !== "NotLoaded" && (!feedViewPermissionGranted || (feedViewPermissionGranted && feeds && feeds.length > 0));
    };
    if (!processContext.selectors.hasValidProcess() || !hasLoadedNecessaryLookupData()) {
        return null;
    }
    const { busy, errors, lookups } = props;
    return <ProcessListForBlueprint lookups={lookups} busy={busy} errors={errors} doBusyTask={props.doBusyTask} location={location} history={history}/>;
};
ProcessListLayoutForBlueprint.displayName = "ProcessListLayoutForBlueprint"
export default React.memo(ProcessListLayoutForBlueprint);
