import { Checkbox, RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { KubernetesObjectStatusInput, InputPathToObject } from "@octopusdeploy/step-inputs";
import { createObjectValueAccessor } from "@octopusdeploy/step-runtime-inputs";
import type { ObjectRuntimeInputs, PathToInput } from "@octopusdeploy/step-runtime-inputs";
import type { KubernetesObjectStatusComponent } from "@octopusdeploy/step-ui";
import React, { useState } from "react";
import { StatusCheckOption, statusCheckOptionLabels } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import Chip from "~/components/Chips/Chip";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import { withTheme } from "~/components/Theme/index";
import { Note } from "~/components/form/index";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import Number from "~/primitiveComponents/form/Number/Number";
import type { StepInputDependencies } from "../../../StepInputDependencies";
import styles from "./style.module.less";
export function getKubernetesObjectStatusSummary<StepInputs>(component: KubernetesObjectStatusComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createObjectValueAccessor(component.input);
    const status = inputAccessor.getInputValue(inputs);
    if (status.enabled) {
        return { isDefaultValue: true, value: "Check that Kubernetes objects are running successfully" };
    }
    else {
        return { isDefaultValue: false, value: "Don't do any verification checks" };
    }
}
interface KubernetesObjectStatusProps<StepInputs> {
    configuredStepUIProps: KubernetesObjectStatusComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    dependencies: StepInputDependencies;
    getFieldError: (path: PathToInput) => string;
    localNames: string[] | undefined;
    input: InputPathToObject<KubernetesObjectStatusInput>;
}
const RecommendedOptionChip = () => withTheme((theme) => (<Chip noTooltip={true} backgroundColor={theme.successBackground} labelColor={theme.successText}>
            Recommended
        </Chip>));
const defaultDeploymentTimeout = 180;
export function KubernetesObjectStatus<StepInputs>(props: KubernetesObjectStatusProps<StepInputs>) {
    const inputAccessor = createObjectValueAccessor<StepInputs, KubernetesObjectStatusInput>(props.configuredStepUIProps.input);
    const status = inputAccessor.getInputValue(props.inputs);
    const [statusCheckOption, setStatusCheckOption] = useState<StatusCheckOption>(status.enabled ? StatusCheckOption.enabled : StatusCheckOption.disabled);
    const [timeoutEnabled, setTimeoutEnabled] = useState<boolean>(typeof status.timeout === "number" ? status.timeout !== 0 : true);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const [waitForJobs, setWaitForJobs] = useState<boolean>(status.waitForJobs as boolean);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const [timeout, setTimeout] = useState<number>(status.timeout as number);
    return (<>
            <RadioButtonGroup<StatusCheckOption> onChange={(value) => {
            status.enabled = value === StatusCheckOption.enabled;
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, status);
            props.setInputs(updatedInputs);
            setStatusCheckOption(value);
        }} value={statusCheckOption}>
                <RadioButton value={StatusCheckOption.enabled} label={statusCheckOptionLabels.enabled} isDefault={true} accessibleName={statusCheckOptionLabels.enabled}/>
                <Note>Get real-time status updates while this step is running and validate the status of your Kubernetes objects before considering this step successful.</Note>
                {statusCheckOption === StatusCheckOption.enabled && (<>
                        <div className={styles.inputWithHelp}>
                            <Checkbox noMargin={true} value={timeoutEnabled} onChange={(value) => {
                status.timeout = value ? defaultDeploymentTimeout : 0;
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, status);
                props.setInputs(updatedInputs);
                setTimeoutEnabled(value);
                setTimeout(status.timeout);
            }} label={"Step timeout"}/>
                            &nbsp;
                            <PopoverHelp trigger={"click"} placement={"right-start"}>
                                {timeoutHelp}
                            </PopoverHelp>
                        </div>
                        {timeoutEnabled && (<div className={styles.indented}>
                                <Number label={"Timeout period in seconds"} min={1} value={timeout} onChange={(value) => {
                    status.timeout = value || 1;
                    const updatedInputs = inputAccessor.changeInputValue(props.inputs, status);
                    props.setInputs(updatedInputs);
                    setTimeout(status.timeout);
                }}/>
                            </div>)}
                        <div className={styles.inputWithHelp} style={{ paddingBottom: "1rem" }}>
                            <Checkbox noMargin={true} value={waitForJobs} onChange={(value) => {
                status.waitForJobs = value;
                const updatedInputs = inputAccessor.changeInputValue(props.inputs, status);
                props.setInputs(updatedInputs);
                setWaitForJobs(value);
            }} label={"Wait for Jobs to complete during this step"}/>
                            &nbsp;
                            <PopoverHelp trigger={"click"} placement={"right-start"}>
                                {waitForJobsHelp}
                            </PopoverHelp>
                        </div>
                    </>)}
                <RadioButton value={StatusCheckOption.disabled} label={statusCheckOptionLabels.disabled} accessibleName={statusCheckOptionLabels.disabled}/>
                <Note>Octopus will mark this step as complete without waiting for any updates from Kubernetes about the deployment.</Note>
            </RadioButtonGroup>
        </>);
}
const timeoutHelp = (<>
        <div>The step timeout is the maximum amount of time a deployment step is allowed to run before being terminated.</div>
        <br />
        <div>This setting is intended to prevent a step from running indefinitely or causing delays in the overall deployment process.</div>
        <br />
        <ExternalLink href="KOS">Docs</ExternalLink>
    </>);
const waitForJobsHelp = (<>
        <div>By default, Octopus won’t wait for the Kubernetes Jobs created during a step to run to completion before marking the step as complete.</div>
        <br />
        <div>If you enable wait for Jobs to complete during the step, Octopus will ensure the Jobs have successfully finished executing before it considers the step successful.</div>
        <br />
        <div>When combining this option with the step timeout, it's important to allocate a sufficient step timeout period.</div>
        <br />
        <ExternalLink href="KOS">Docs</ExternalLink>
    </>);
