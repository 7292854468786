import type { AuditStreamConfigurationResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { AuditStreamConfiguredButton } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamConfiguredButton";
import { AuditStreamNotConfiguredButton } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamNotConfiguredButton";
import { AuditStreamUpsellButton } from "~/areas/configuration/components/AuditLayout/AuditStream/AuditStreamUpsellButton";
interface AuditStreamButtonProps {
    auditStreamConfiguration: AuditStreamConfigurationResource | null;
    onUpdate: (auditStreamConfiguration: AuditStreamConfigurationResource, message: string) => void;
}
export function AuditStreamButton({ auditStreamConfiguration, onUpdate }: AuditStreamButtonProps) {
    if (!auditStreamConfiguration) {
        return <AuditStreamUpsellButton />;
    }
    if (!auditStreamConfiguration.StreamConfigurationResource) {
        return <AuditStreamNotConfiguredButton auditStreamConfiguration={auditStreamConfiguration} onUpdate={onUpdate}/>;
    }
    return <AuditStreamConfiguredButton auditStreamConfiguration={auditStreamConfiguration} onUpdate={onUpdate}/>;
}
