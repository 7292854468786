import * as React from "react";
import ReleaseChangesDetail from "~/areas/projects/components/Releases/ReleaseChanges/ReleaseChangesDetail";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
import ExternalLink from "~/components/Navigation/ExternalLink";
import StringHelper from "~/utils/StringHelper/index";
import { Section } from "../../../../../components/Section/Section";
interface ChangesProps {
    changesMarkdown: string;
}
function Changes(props: ChangesProps) {
    return (<Section>
            <div>
                {StringHelper.isNullOrWhiteSpace(props.changesMarkdown) ? (<OnboardingPage title="Configure changes for your deployments" intro={<div>Deployment changes provide a summary of changes across all releases since the previous deployment</div>} learnMore={<ExternalLink href="DeploymentNotes">Learn more</ExternalLink>}/>) : (<ReleaseChangesDetail changesMarkdown={props.changesMarkdown}/>)}
            </div>
        </Section>);
}
export default Changes;
