import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationTeamOverview } from "../../CommonComponents/CommonConfigurationTeamViews";
export const ConfigurationTeamsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.teams.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationTeamOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
