import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
interface DismissTargetTagsExplainerEventProperties {
    stepTemplateName: string;
    isKubernetesStep: boolean;
    area: string;
}
interface DismissTargetTagsExplainerEvent extends AnalyticsEvent<"Dismiss Target Tags Explainer"> {
    StepTemplateName: string;
    IsKubernetesStep: boolean;
    Area: string;
    Element: "Callout";
}
export function createDismissTargetTagsExplainerEvent(properties: DismissTargetTagsExplainerEventProperties): DismissTargetTagsExplainerEvent {
    return {
        eventName: "Dismiss Target Tags Explainer",
        StepTemplateName: properties.stepTemplateName,
        IsKubernetesStep: properties.isKubernetesStep,
        Area: properties.area,
        Element: "Callout",
    };
}
