import type { ActivityElement, KubernetesStepStatus, KubernetesTaskResourceStatusResource } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import moment from "moment/moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useAnalyticViewDispatch } from "~/analytics/Analytics";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import { ExpansionButtons } from "~/components/form/index";
import Select from "~/primitiveComponents/form/Select/Select";
import KubernetesStepStatusExpander from "./KubernetesStepStatusExpander";
import styles from "./style.module.less";
export interface StepFilter {
    namespace?: string;
    targetName?: string;
    name?: string;
}
interface KubernetesStatusComponentProps {
    status: KubernetesTaskResourceStatusResource;
    activityLogs: Pick<ActivityElement, "Children">[];
    stepsWithKubernetesActions: string[];
    projectId?: string;
}
export function getStepActivity(stepName: string, activityLogs: Pick<ActivityElement, "Children">[]): Pick<ActivityElement, "Name" | "Status" | "Ended" | "Started"> | undefined {
    function stepNameDeconstruct(): [
        stepParentNumber: string,
        stepSanitisedName: string
    ] {
        const leftPart = stepName.substring(0, stepName.indexOf(":"));
        const childNumberIndex = leftPart.indexOf(".");
        const parentNumberPart = childNumberIndex === -1 ? leftPart : leftPart.substring(0, childNumberIndex);
        const stepParentNumber = `${parentNumberPart}: `;
        return [stepParentNumber, stepName.substring(leftPart.length + 2)];
    }
    function lookupActivityInChildren(name: string, activity: ActivityElement): ActivityElement[] {
        if (activity.Name === name) {
            return [activity];
        }
        const results: ActivityElement[] = [];
        for (const childActivity of activity.Children) {
            results.push(...lookupActivityInChildren(name, childActivity));
        }
        return results;
    }
    const [stepParentNumber, stepSanitisedName] = stepNameDeconstruct();
    for (const activityLog of activityLogs) {
        for (const activity of activityLog.Children) {
            if (activity.Name.startsWith(stepParentNumber)) {
                if (activity.Name === stepName) {
                    return activity;
                }
                const results = lookupActivityInChildren(stepSanitisedName, activity);
                if (results.length === 0) {
                    return undefined;
                }
                if (results.length === 1) {
                    return results[0];
                }
                let started: string | undefined = moment().add(99, "y").toString();
                let ended: string | undefined = moment().subtract(99, "y").toString();
                let status: ActivityStatus = ActivityStatus.Pending;
                for (const result of results) {
                    if (result.Started && moment(result.Started) < moment(started)) {
                        started = result.Started;
                    }
                    if (!result.Ended || ended === undefined) {
                        ended = undefined;
                    }
                    else if (moment(result.Ended) > moment(ended)) {
                        ended = result.Ended;
                    }
                }
                if (results.every((item) => item.Status === ActivityStatus.Skipped)) {
                    status = ActivityStatus.Skipped;
                }
                else if (results.some((item) => item.Status === ActivityStatus.Failed)) {
                    status = ActivityStatus.Failed;
                }
                else if (results.every((item) => item.Status === ActivityStatus.Success || item.Status === ActivityStatus.SuccessWithWarning)) {
                    status = ActivityStatus.Success;
                }
                else if (results.some((item) => item.Status === ActivityStatus.Running)) {
                    status = ActivityStatus.Running;
                }
                return { Name: stepSanitisedName, Started: started, Ended: ended, Status: status };
            }
        }
    }
    return undefined;
}
function KubernetesDeploymentStatus(props: KubernetesStatusComponentProps) {
    const dispatchView = useAnalyticViewDispatch(props.projectId);
    const [filter, setFilter] = useState<StepFilter>({});
    useEffect(() => {
        dispatchView("Navigate to Object Status Tab", { resource: "Kubernetes Deployment Status" });
        const timeoutId = window.setTimeout(() => dispatchView("View Object Status Tab", { resource: "Kubernetes Deployment Status" }), 5000);
        return () => window.clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function getStepStatus(stepName: string) {
        return props.status.KubernetesStepsStatus.find((v) => v.StepName === stepName);
    }
    return (<>
            <FilterSection filter={filter} statuses={props.status.KubernetesStepsStatus} setState={(filter) => setFilter(filter)}/>
            <ExpansionButtons expandAllOnMount={true}/>
            {props.stepsWithKubernetesActions.map((stepName) => {
            const activity = getStepActivity(stepName, props.activityLogs) ?? { Name: stepName, Started: "", Ended: "", Status: ActivityStatus.Pending };
            return <KubernetesStepStatusExpander key={stepName} stepName={stepName} status={getStepStatus(stepName)} filter={filter} stepActivity={activity}/>;
        })}
        </>);
}
function FilterSection(props: {
    statuses: KubernetesStepStatus[];
    filter: StepFilter;
    setState: (filter: StepFilter) => void;
}) {
    const controls: React.ReactNode[] = [];
    const distinctListOfItems = (filter: (kss: KubernetesStepStatus) => string[]) => props.statuses
        .flatMap(filter)
        .filter((value, index, array) => array.indexOf(value) === index)
        .map((value) => ({ value, text: value }));
    const targetNames = distinctListOfItems((kss) => kss.KubernetesObjects.map((o) => o.ClusterName));
    if (targetNames.length > 1) {
        controls.push(<div className={styles.selectWrap} key={1}>
                <Select value={props.filter.targetName} onChange={(targetName) => props.setState({ ...props.filter, targetName })} items={targetNames} allowClear={true} label="Filter by target" placeholder="All targets"/>
            </div>);
    }
    const namespaceNames = distinctListOfItems((kss) => kss.KubernetesObjects.map((o) => o.Namespace));
    if (namespaceNames.length > 1) {
        controls.push(<div className={styles.selectWrap} key={2}>
                <Select value={props.filter.namespace} onChange={(namespace) => props.setState({ ...props.filter, namespace })} items={namespaceNames} allowClear={true} label="Filter by namespace" placeholder="All namespaces"/>
            </div>);
    }
    return (<section className={`${styles.filterContainer} ${styles.leftRightContainer}`}>
            <div>
                <FilterSearchBox placeholder="Filter by name..." value={props.filter.name} onChange={(name: string) => props.setState({ ...props.filter, name })}/>
            </div>
            <div>{controls}</div>
        </section>);
}
export default KubernetesDeploymentStatus;
