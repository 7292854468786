import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { ReactNode } from "react";
import type { DataBaseComponentState, DoBusyTask } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import DialogLayoutConnect from "~/components/Dialog/DialogLayoutConnect";
import styles from "~/components/Dialog/style.module.less";
import type { DialogLayoutDispatchProps } from "~/components/DialogLayout/DialogLayout";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import FormComponent from "~/components/FormComponent/FormComponent";
export interface DeleteDialogLayoutInternalProps {
    title: string;
    deleteButtonLabel?: string;
    deleteButtonBusyLabel?: string;
    deleteButtonDisabled?: boolean;
    cancelButtonLabel?: string;
    renderContent(doBusyTask: DoBusyTask): ReactNode;
    onDeleteClick(): Promise<boolean> | void;
    onClose?(): void;
    onLoad?(): void;
}
export class DeleteDialogLayoutInternal extends DataBaseComponent<DeleteDialogLayoutInternalProps & DialogLayoutDispatchProps, DataBaseComponentState> {
    constructor(props: DeleteDialogLayoutInternalProps & DialogLayoutDispatchProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        if (this.props.onLoad) {
            await this.doBusyTask(async () => {
                this.props.onLoad && (await this.props.onLoad());
            });
        }
    }
    private onDeleteClick = () => {
        if (this.isDisabled()) {
            return;
        }
        return this.doBusyTask(async () => {
            const result = await this.props.onDeleteClick();
            if (result) {
                this.closeDialog();
            }
        });
    };
    private isDisabled = () => this.props.deleteButtonDisabled || this.state.busy;
    closeDialog() {
        this.props.onClose && this.props.onClose();
        this.props.close();
    }
    render() {
        const cancel = <ActionButton key="Cancel" label={this.props.cancelButtonLabel || "Cancel"} disabled={this.state.busy} onClick={() => this.closeDialog()}/>;
        const del = (<ActionButton key="Delete" label={this.props.deleteButtonLabel || "Delete"} type={ActionButtonType.Delete} busyLabel={this.props.deleteButtonBusyLabel || "Deleting..."} disabled={this.isDisabled()} onClick={() => this.onDeleteClick()}/>);
        return (<DialogLayout actions={[cancel, del]} title={this.props.title} busy={this.state.busy} headerClassName={styles.dialogHeader} closeDialog={() => this.closeDialog()} errors={this.errors}>
                <FormComponent onFormSubmit={this.onDeleteClick}>{this.props.renderContent(this.doBusyTask)}</FormComponent>
            </DialogLayout>);
    }
    static displayName = "DeleteDialogLayoutInternal";
}
const DeleteDialogLayout = DialogLayoutConnect.to<DeleteDialogLayoutInternalProps>(DeleteDialogLayoutInternal);
DeleteDialogLayout.displayName = "DeleteDialogLayout";
export default DeleteDialogLayout;
