import type { TenantsFilterQueryParameters } from "@octopusdeploy/octopus-server-client";
import { type QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { useMemo } from "react";
import type { TenantsPageQueryParams } from "~/areas/tenants/Tenants/TenantsPage";
export type TenantsFilterState = TenantsFilterQueryParameters & {
    pageNumber: number;
};
export function useTenantsFilterState(queryParams: TenantsPageQueryParams, setQueryParams: QueryParamValuesSetter<TenantsPageQueryParams>): [
    TenantsFilterState,
    (filter: TenantsFilterState) => void
] {
    function setTenantsFilterState(filter: TenantsFilterState) {
        setQueryParams({
            search: filter.filterByName,
            excludedSearch: filter.filterByExcludedName,
            project: filter.filterByProject,
            excludedProject: filter.filterByExcludedProject,
            environment: filter.filterByEnvironment,
            excludedEnvironment: filter.filterByExcludedEnvironment,
            tags: filter.filterByTags.length > 0 ? filter.filterByTags.join(",") : undefined,
            excludedTags: filter.filterByExcludedTags.length > 0 ? filter.filterByExcludedTags.join(",") : undefined,
            includeMissingVariablesStatus: filter.includeMissingVariablesStatus,
            pageNumber: filter.pageNumber,
        });
    }
    const tenantsFilterState = useMemo((): TenantsFilterState => ({
        filterByName: queryParams.search,
        filterByExcludedName: queryParams.excludedSearch,
        filterByProject: queryParams.project,
        filterByExcludedProject: queryParams.excludedProject,
        filterByEnvironment: queryParams.environment,
        filterByExcludedEnvironment: queryParams.excludedEnvironment,
        filterByTags: queryParams.tags ? queryParams.tags.split(",") : [],
        filterByExcludedTags: queryParams.excludedTags ? queryParams.excludedTags.split(",") : [],
        includeMissingVariablesStatus: queryParams.includeMissingVariablesStatus,
        pageNumber: queryParams.pageNumber ?? 1,
    }), [
        queryParams.search,
        queryParams.excludedSearch,
        queryParams.project,
        queryParams.excludedProject,
        queryParams.environment,
        queryParams.excludedEnvironment,
        queryParams.tags,
        queryParams.excludedTags,
        queryParams.includeMissingVariablesStatus,
        queryParams.pageNumber,
    ]);
    return [tenantsFilterState, setTenantsFilterState];
}
