/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { FormFieldProps } from "~/components/form";
import WorkerPoolVariableSelect from "~/components/form/WorkerPoolSelect/WorkerPoolVariableSelect";
interface WorkPoolVariableInputProps extends FormFieldProps<string> {
    label: string;
    projectId: string;
    gitRef: GitRefResource | undefined;
    allowClear: boolean;
    defaultValueIndicator: JSX.Element | undefined;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}
const WorkerPoolVariableInput: React.FC<WorkPoolVariableInputProps> = (props) => {
    const { value, label, projectId, allowClear, defaultValueIndicator, doBusyTask, onChange, ...rest } = props;
    const formProps = { ...rest, label };
    return (<React.Fragment>
            <WorkerPoolVariableSelect value={value} projectId={projectId} doBusyTask={doBusyTask} allowClear={allowClear} onChange={(x) => onChange(x!)} {...formProps}/>
            {defaultValueIndicator}
        </React.Fragment>);
};
WorkerPoolVariableInput.displayName = "WorkerPoolVariableInput"
export default WorkerPoolVariableInput;
