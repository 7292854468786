import type { PopoverPlacement } from "@octopusdeploy/design-system-components";
import { ExternalLink, PopoverSequential } from "@octopusdeploy/design-system-components";
import { HasGitPersistenceSettings, type ProjectResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { useAnalyticGitProtectionRulesOnboardingDispatch } from "~/analytics/Analytics";
import onboardingIllustration from "~/areas/projects/components/Channels/GitProtectionRules/Onboarding/assets/onboarding-illustration.svg";
interface GitProtectionRuleOnboardingPopoverProps {
    anchorEl: HTMLElement | null;
    placement: PopoverPlacement;
    onOnboardingStepChanged: (step: GitProtectionRulesOnboardingStep) => void;
    onClose: () => void;
    project: ProjectResource;
}
export type GitProtectionRulesOnboardingStepType = "gitProtectionRules" | "gitReferenceRules" | "gitResourceRules";
export type GitProtectionRulesOnboardingStep = {
    key: GitProtectionRulesOnboardingStepType;
    title: string;
    description: React.ReactNode;
};
const gitProtectionRulesOnboardingStep: GitProtectionRulesOnboardingStep = {
    key: "gitProtectionRules",
    title: "Git Protection Rules",
    description: (<>
            <p>Git protection rules allow you to control the use of files from Git repositories during deployments, ensuring that important environments such as Production are protected.</p>
            <ExternalLink href="ChannelGitProtectionRules" label="Docs"/>
        </>),
};
const gitReferenceRulesOnboardingStep: GitProtectionRulesOnboardingStep = {
    key: "gitReferenceRules",
    title: "Project Repository",
    description: (<>
            <p>For projects that are version controlled, you can restrict which branches and tags can be used as the source of the deployment process and variables when creating a release.</p>
            <p>Use wildcard syntax to match on multiple branches, tags or other Git references.</p>
            <ExternalLink href="ChannelGitReferenceRules" label="Docs"/>
        </>),
};
const gitResourceRulesOnboardingStep: GitProtectionRulesOnboardingStep = {
    key: "gitResourceRules",
    title: "External Repository Rules",
    description: (<>
            <p>You can use external repository rules to restrict which branches and tags can be used in steps that source files from external Git repositories.</p>
            <p>Use wildcard syntax to match on multiple branches, tags or other Git references.</p>
            <ExternalLink href="ChannelGitResourceRules" label="Docs"/>
        </>),
};
export const GitProtectionRulesOnboardingPopover = ({ anchorEl, placement, onClose, onOnboardingStepChanged, project }: GitProtectionRuleOnboardingPopoverProps) => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const onboardingDispatch = useAnalyticGitProtectionRulesOnboardingDispatch();
    const onboardingSteps: GitProtectionRulesOnboardingStep[] = HasGitPersistenceSettings(project.PersistenceSettings)
        ? [gitProtectionRulesOnboardingStep, gitReferenceRulesOnboardingStep, gitResourceRulesOnboardingStep]
        : [gitProtectionRulesOnboardingStep, gitResourceRulesOnboardingStep];
    const handleClose = () => {
        onboardingDispatch("Git Protection Rules Onboarding: Close", { title: onboardingSteps[currentStep].title });
        onClose();
    };
    const handleFinish = () => {
        onboardingDispatch("Git Protection Rules Onboarding: Finish", {});
        onClose();
    };
    const onBack = () => {
        onboardingDispatch("Git Protection Rules Onboarding: Click Back", { title: onboardingSteps[currentStep].title });
        setCurrentStep((prev) => prev - 1);
        onOnboardingStepChanged(onboardingSteps[currentStep - 1]);
    };
    const onNext = () => {
        onboardingDispatch("Git Protection Rules Onboarding: Click Next", { title: onboardingSteps[currentStep].title });
        setCurrentStep((prev) => prev + 1);
        onOnboardingStepChanged(onboardingSteps[currentStep + 1]);
    };
    return (<PopoverSequential totalSteps={onboardingSteps.length} currentStep={currentStep + 1} title={onboardingSteps[currentStep].title} description={onboardingSteps[currentStep].description} image={onboardingIllustration} placement={placement} onClose={handleClose} open={!!anchorEl} anchorEl={anchorEl} onBack={onBack} onNext={onNext} onFinish={handleFinish}/>);
};
