/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { ResourceCollection, ExternalFeedResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ActionEvent, AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { repository } from "../../../../clientInstance";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import ExternalFeedList from "./ExternalFeedList";
import Onboarding from "./Onboarding";
interface FeedsState extends DataBaseComponentState {
    feeds: ResourceCollection<ExternalFeedResource>;
}
interface ExternalFeedsPageProps {
    spaceId: string;
}
interface ExternalFeedsPagePropsInternal extends ExternalFeedsPageProps {
    dispatchAction: AnalyticActionDispatcher;
}
class ExternalFeedsPageInternal extends DataBaseComponent<ExternalFeedsPagePropsInternal, FeedsState> {
    constructor(props: ExternalFeedsPagePropsInternal) {
        super(props);
        this.state = {
            feeds: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.loadFeeds(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const addFeedPageAction: PrimaryPageAction = {
            type: "navigate",
            hasPermissions: isAllowed({ permission: Permission.FeedEdit }),
            label: "Add Feed",
            path: links.createFeedPage.generateUrl({ spaceId: this.props.spaceId }),
            onClick: () => {
                const ev: ActionEvent = {
                    action: Action.Add,
                    resource: "External Feed",
                };
                this.props.dispatchAction("Add Feed", ev);
            },
        };
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayoutVNext title="External Feeds" primaryAction={addFeedPageAction} busy={this.state.busy} errors={this.errors}>
                    {this.state.feeds != null && (<div>
                            {this.state.feeds.TotalResults === 0 && <Onboarding />}
                            {this.state.feeds.TotalResults > 0 && (<React.Fragment>
                                    <ExternalFeedList spaceId={this.props.spaceId} feeds={this.state.feeds} onDelete={this.handleFeedDelete}/>
                                </React.Fragment>)}
                        </div>)}
                </PaperLayoutVNext>
            </LibraryLayout>);
    }
    private handleFeedDelete = async () => {
        await this.loadFeeds();
        return true;
    };
    private loadFeeds = async () => {
        const feeds = await repository.Feeds.listExternal();
        this.setState({
            feeds,
        });
    };
    static displayName = "ExternalFeedsPageInternal";
}
export function ExternalFeedsPage({ spaceId }: ExternalFeedsPageProps) {
    const dispatchAction = useAnalyticActionDispatch();
    return <ExternalFeedsPageInternal spaceId={spaceId} dispatchAction={dispatchAction}/>;
}
