import React from "react";
import type { FormFieldProps } from "~/components/form";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
interface CheckboxInputProps extends FormFieldProps<string> {
    resetValue: string;
    label: string;
    localNames?: string[];
    warning?: string;
    defaultValueIndicator: JSX.Element | undefined;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
}
const CheckboxInput: React.FC<CheckboxInputProps> = (props) => {
    const { value, resetValue, label, localNames, warning, defaultValueIndicator, doBusyTask, ...rest } = props;
    const formProps = { ...rest, label };
    return (<React.Fragment>
            <BoundStringCheckbox value={value} resetValue={resetValue} variableLookup={{
            localNames,
        }} warning={warning} {...formProps}/>
            {defaultValueIndicator}
        </React.Fragment>);
};
CheckboxInput.displayName = "CheckboxInput"
export default CheckboxInput;
