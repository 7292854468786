import { ProcessType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { ProcessPaperLayout } from "~/areas/projects/components/Process/CustomPaperLayouts/ProcessPaperLayout";
import { useProjectContext } from "~/areas/projects/context/index";
import ProcessListLayoutDataLoader from "./ProcessListLayoutLoader";
export function DeploymentProcessListPage() {
    const context = useProjectContext();
    if (!context.state.model) {
        return <ProcessPaperLayout processType={ProcessType.Deployment} busy={true}/>;
    }
    return <ProcessListLayoutDataLoader processId={context.state.model.DeploymentProcessId} processType={ProcessType.Deployment}/>;
}
