import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
export function createAddAccountAnalyticsEvent(eventProperties: AddAccountAnalyticsEventProperties): AddAccountAnalyticsEvent {
    return {
        eventName: "Add Account",
        ...eventProperties,
    };
}
export interface AddAccountAnalyticsEvent extends AnalyticsEvent<"Add Account">, AddAccountAnalyticsEventProperties {
}
export interface AddAccountAnalyticsEventProperties {
    Location: AddAccountLocation;
    "Account Type": string;
    "Correlation Id"?: string;
}
export function createSaveAccountAnalyticsEvent(eventProperties: SaveAccountAnalyticsEventProperties): SaveAccountAnalyticsEvent {
    return {
        eventName: "Save Account",
        ...eventProperties,
    };
}
export function createSaveAndTestAccountAnalyticsEvent(eventProperties: SaveAccountAnalyticsEventProperties): SaveAndTestAccountAnalyticsEvent {
    return {
        eventName: "Save and Test Account",
        ...eventProperties,
    };
}
export interface SaveAccountAnalyticsEvent extends AnalyticsEvent<"Save Account">, SaveAccountAnalyticsEventProperties {
}
export interface SaveAndTestAccountAnalyticsEvent extends AnalyticsEvent<"Save and Test Account">, SaveAccountAnalyticsEventProperties {
}
export interface SaveAccountAnalyticsEventProperties {
    Location: AddAccountLocation;
    "Account Type": string;
    "Entity Type": "New" | "Existing";
    Status: "Success" | "Failure";
    "Correlation Id"?: string;
}
type AddAccountLocation = "Drawer" | "Page";
