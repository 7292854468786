import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectProcessOverview, CommonProjectProcessTopics } from "../../CommonComponents/CommonProjectProcessViews";
export const ProjectStepTemplatesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().steptemplates,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectProcessOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectProcessTopics />;
    },
};
