import type { CommonInputProps, FormContent, SectionComponent, SectionGroupComponent } from "@octopusdeploy/step-ui";
import React from "react";
import { Section } from "~/components/StepPackageEditor/Structure/Section/Section";
import { SectionGroup } from "~/components/StepPackageEditor/Structure/SectionGroup/SectionGroup";
import { UnnamedSection } from "~/components/StepPackageEditor/Structure/UnnamedSection/UnnamedSection";
import { groupInputComponents } from "~/components/StepPackageEditor/Structure/groupInputComponents";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
interface EditStepInputsProps<StepInputs, TInputComponents> {
    formContent: FormContent<TInputComponents>[];
    isExpandedByDefault: boolean;
    getRenderedComponentAndSummary(component: TInputComponents): RenderedComponentAndSummary;
}
export type RenderedComponentAndSummary = {
    summary: InputSummary;
    renderedComponent: React.ReactNode | null;
};
export function EditStepPackageInputs<StepInputs, TInputComponents extends CommonInputProps>(props: EditStepInputsProps<StepInputs, TInputComponents>) {
    const groupedComponents = groupInputComponents<SectionGroupComponent<TInputComponents> | SectionComponent<TInputComponents>, TInputComponents>(props.formContent, (component): component is SectionGroupComponent<TInputComponents> | SectionComponent<TInputComponents> => "type" in component && (component.type === "section" || component.type === "section group"));
    return (<>
            {groupedComponents.map((groupedContent, i) => {
            if (Array.isArray(groupedContent)) {
                return <UnnamedSection<StepInputs, TInputComponents> key={i} content={groupedContent} getRenderedComponentAndSummary={props.getRenderedComponentAndSummary} isExpandedByDefault={props.isExpandedByDefault}/>;
            }
            switch (groupedContent.type) {
                case "section group":
                    return (<SectionGroup<StepInputs, TInputComponents> key={groupedContent.title} sectionGroup={groupedContent} isExpandedByDefault={props.isExpandedByDefault} getRenderedComponentAndSummary={props.getRenderedComponentAndSummary}/>);
                case "section":
                    return <Section<StepInputs, TInputComponents> key={groupedContent.title} section={groupedContent} isExpandedByDefault={props.isExpandedByDefault} getRenderedComponentAndSummary={props.getRenderedComponentAndSummary}/>;
            }
        })}
        </>);
}
