import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { HandWaveIcon } from "@octopusdeploy/design-system-components/src/components/Icon/HandWaveIcon";
import { fontSize, fontWeight, lineHeight, space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import { KubernetesStep } from "./AddProject";
interface WelcomeToProjectDialogProps {
    isOpen: boolean;
    isVersionControlled: boolean;
    selectedStep: string | null | undefined;
    close(): void;
}
export function WelcomeToProjectDialog(props: WelcomeToProjectDialogProps) {
    const renderContent = () => {
        if (props.isVersionControlled) {
            return (<>
                    <div>It’s time to configure version control.</div>
                    <span>These parts of your project can be version-controlled:</span>
                    <ul>
                        <li>Deployment process</li>
                        <li>Deployment settings</li>
                        <li>Non-sensitive variables</li>
                    </ul>
                </>);
        }
        if (!props.selectedStep) {
            return <div>It’s time to create your deployment process. Define how to deploy your software by adding steps to your deployment process.</div>;
        }
        return (<>
                <div>It’s time to create your deployment process – the recipe for deploying your software. </div>
                <div>To help you get started, we added a step to your deployment process where you will define how to deploy {getStepName(props.selectedStep)}.</div>
            </>);
    };
    const getStepName = (selectedStep: string) => {
        switch (selectedStep) {
            case KubernetesStep.Kustomize:
                return "with Kustomize";
            case KubernetesStep.Helm:
                return "your Helm chart";
            default:
                return "your YAML";
        }
    };
    return (<Dialog open={props.isOpen}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <HandWaveIcon />
                    <div>Welcome to your project</div>
                </div>
                <div className={styles.content}>{renderContent()}</div>
                <div>
                    <ActionButton label="Thanks, got it" type={ActionButtonType.Primary} onClick={props.close}></ActionButton>
                </div>
            </div>
        </Dialog>);
}
const styles = {
    container: css({
        padding: space["32"],
    }),
    header: css({
        fontSize: fontSize["xLarge"],
        fontWeight: fontWeight["700"],
        lineHeight: lineHeight["large"],
        display: "flex",
        marginBottom: space["16"],
        "& svg": {
            width: "32px",
            height: "32px",
            marginRight: space["8"],
        },
    }),
    content: css({
        marginBottom: space["24"],
        lineHeight: lineHeight["small"],
        "& div": {
            marginBottom: space["24"],
        },
        "& ul": {
            margin: 0,
            paddingLeft: space["24"],
            listStyleType: "disc",
            lineHeight: lineHeight["small"],
        },
    }),
};
