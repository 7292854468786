import type { NavigationButtonProps } from "@octopusdeploy/design-system-components";
import { NavigationButton } from "@octopusdeploy/design-system-components";
import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import { PermissionCheck } from "~/components/PermissionCheck";
import { isRunbookProcessState, processScopedEditPermission } from "../Process/Common/CommonProcessHelpers";
import { useProcessContext } from "../Process/Contexts/ProcessContext";
type AddRunbookStepButtonPropChanges = Omit<NavigationButtonProps, "href" | "label"> & {
    href?: NavigationButtonProps["href"];
    label?: NavigationButtonProps["label"];
};
interface WithLabel {
    label?: string;
}
type AddRunbookStepButtonProps = AddRunbookStepButtonPropChanges & WithLabel & {
    spaceId: string;
    projectId: string;
    processId: string;
    runbookId: string;
    projectSlug: string;
};
const AddRunbookStepButton: React.FC<AddRunbookStepButtonProps> = ({ spaceId, projectId, projectSlug, processId, runbookId, label, ...rest }) => {
    const href = links.projectRunbookProcessStepsPage.generateUrl({ spaceId, projectSlug, runbookId, processId }, { stepTemplates: true });
    const processContext = useProcessContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    return (<PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
            <NavigationButton label={label ?? "Add Step"} href={href} {...rest} onClick={() => dispatchAction("Add Deployment Step", { resource: "Runbook Process", action: Action.Add })}/>
        </PermissionCheck>);
};
AddRunbookStepButton.displayName = "AddRunbookStepButton"
type AddDeploymentsStepButtonProps = {
    spaceId: string;
    projectId: string;
    projectSlug: string;
    gitRef: GitRefResource | undefined;
} & WithLabel;
const AddDeploymentsStepButton: React.FC<AddDeploymentsStepButtonProps> = ({ spaceId, projectId, projectSlug, gitRef, label, ...rest }) => {
    const href = getAddProcessStepUrl({ spaceId, projectSlug, gitRef });
    const processContext = useProcessContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    return (<PermissionCheck permission={processScopedEditPermission(processContext.selectors.getProcessType())} project={projectId} wildcard={true}>
            <NavigationButton label={label ?? "Add Step"} href={href} {...rest} onClick={() => dispatchAction("Add Deployment Step", { resource: "Deployment Process", action: Action.Add })}/>
        </PermissionCheck>);
};
AddDeploymentsStepButton.displayName = "AddDeploymentsStepButton"
interface GetProcessStepTemplatesUrlOptions {
    spaceId: string;
    projectSlug: string;
    gitRef: GitRefResource | undefined;
}
function getAddProcessStepUrl({ spaceId, projectSlug, gitRef }: GetProcessStepTemplatesUrlOptions) {
    if (gitRef) {
        return links.branchDeploymentProcessStepsPage.generateUrl({ spaceId, projectSlug, branchName: gitRef.CanonicalName }, { stepTemplates: true });
    }
    return links.deploymentProcessStepsPage.generateUrl({ spaceId, projectSlug }, { stepTemplates: true });
}
export type ContextAddStepNavigationButtonProps = AddRunbookStepButtonPropChanges & WithLabel;
const ContextAddStepNavigationButton: React.FC<ContextAddStepNavigationButtonProps> = (props) => {
    const processContext = useProcessContext();
    const project = useProjectContext();
    const process = processContext.state.model.process;
    if (isRunbookProcessState(process)) {
        return <AddRunbookStepButton spaceId={process.SpaceId} projectSlug={project.state.model.Slug} projectId={project.state.model.Id} processId={process.Id} runbookId={process.RunbookId} {...props} label={props.label}/>;
    }
    else {
        return <AddDeploymentsStepButton spaceId={project.state.model.SpaceId} projectSlug={project.state.model.Slug} projectId={project.state.model.Id} gitRef={project.state.gitRef} {...props} label={props.label}/>;
    }
};
ContextAddStepNavigationButton.displayName = "ContextAddStepNavigationButton"
export default ContextAddStepNavigationButton;
