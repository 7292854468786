import type { ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { MachineChip, MissingChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
import type { SelectItem } from "../VirtualListWithKeyboard/SelectItem";
interface MachineMultiSelectProps extends FormFieldProps<string[]> {
    items: ReferenceDataItem[];
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}
const MachineTypedMultiSelect = MultiSelect<ReferenceDataItem>();
export const MachineMultiSelect: React.FC<MachineMultiSelectProps> = (props) => {
    const chipRenderer = (r: ReferenceDataItem | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <MachineChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} machineName={item.Name}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.Machine} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <MachineTypedMultiSelect fieldName="targets" renderChip={chipRenderer} {...props}/>;
};
MachineMultiSelect.displayName = "MachineMultiSelect"
