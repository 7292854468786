import type { WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { repository } from "~/clientInstance";
const getWorkerPoolIndex = async (ids?: string[]): Promise<WorkerPoolIndex> => {
    const idx: {
        [poolId: string]: WorkerPoolResource;
    } = {};
    const pools = await repository.WorkerPools.all({ ids });
    if (pools) {
        pools.forEach((p) => (idx[p.Id] = p));
    }
    return idx;
};
interface WorkerPoolIndex {
    [poolId: string]: WorkerPoolResource;
}
export { getWorkerPoolIndex, WorkerPoolIndex };
