import * as React from "react";
import { TemplateHelpText } from "~/areas/projects/components/Variables/TenantVariables/TemplateHelpText";
import { TenantVariableName } from "~/areas/projects/components/Variables/TenantVariables/TenantVariableName";
import { styles } from "~/areas/projects/components/Variables/TenantVariables/styles";
interface TemplateNameCellProps {
    template: TemplateInfo | null;
}
interface TemplateInfo {
    Id: string;
    Name: string;
    Label: string;
    HelpText: string;
}
export function TemplateNameCell({ template }: TemplateNameCellProps) {
    if (!template) {
        return null;
    }
    return (<div className={styles.templateInfo}>
            <div className={styles.templateName}>
                <TenantVariableName name={template.Name}/>
                <TemplateHelpText text={template.HelpText}/>
            </div>
            <div className={styles.cellLabel}>{template.Label}</div>
        </div>);
}
