import type { AnalyticsEvent, AnalyticsEventPageProperties } from "@octopusdeploy/portal-analytics";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import { useCallback } from "react";
import { IsPageSearchResult } from "~/globalSearch/GlobalSearchHelpers";
import type { GlobalSearchResult } from "~/globalSearch/GlobalSearchHelpers";
export function useTrackGlobalSearchItemSelected() {
    const trackEvent = useTrackEvent();
    const page = usePage();
    return useCallback((searchResult: GlobalSearchResult) => {
        trackEvent(createGlobalSearchItemSelectedEvent(searchResult, page));
    }, [page, trackEvent]);
}
type GlobalSearchItemSelectedEvent = AnalyticsEvent<"Global search item selected"> & AnalyticsEventPageProperties & (GlobalSearchPageItemSelectedProperties | GlobalSearchSpaceResourceItemSelectedProperties);
interface GlobalSearchPageItemSelectedProperties {
    "Selection Type": "Page";
    "Selected Page": string;
    "Selected Page Area": string;
}
interface GlobalSearchSpaceResourceItemSelectedProperties {
    "Selection Type": "Space Resource";
    "Selected Resource Type": string;
}
function createGlobalSearchItemSelectedEvent(searchResult: GlobalSearchResult, page: PageDefinition): GlobalSearchItemSelectedEvent {
    if (IsPageSearchResult(searchResult)) {
        return {
            eventName: "Global search item selected",
            "Page Name": page.Name,
            "Page Area": page.Area,
            "Selection Type": "Page",
            "Selected Page": searchResult.page.name,
            "Selected Page Area": searchResult.page.area,
        };
    }
    return {
        eventName: "Global search item selected",
        "Page Name": page.Name,
        "Page Area": page.Area,
        "Selection Type": "Space Resource",
        "Selected Resource Type": searchResult.Type,
    };
}
