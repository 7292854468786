/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { links } from "@octopusdeploy/portal-routes";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { BuiltInTask } from "../Task/Task";
interface InterruptionToProjectRedirectState extends DataBaseComponentState {
    redirectTo: LinkHref;
}
interface InterruptionToProjectRedirectProps {
    spaceId?: string;
    interruptionId: string;
}
export class InterruptionToProjectRedirect extends DataBaseComponent<InterruptionToProjectRedirectProps, InterruptionToProjectRedirectState> {
    constructor(props: InterruptionToProjectRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const interruptionId = this.props.interruptionId;
            const interruption = await repository.Interruptions.get(interruptionId);
            const task = await repository.Tasks.get(interruption.TaskId);
            if (task.Name === BuiltInTask.Deploy && task.Arguments.DeploymentId) {
                this.setState({ redirectTo: links.deploymentToProjectTaskRedirect.generateUrl({ spaceId: task.SpaceId!, deploymentId: task.Arguments.DeploymentId }) });
                return;
            }
            if (task.Name === BuiltInTask.RunbookRun && task.Arguments.RunbookRunId) {
                this.setState({ redirectTo: links.runbookRunRedirect.generateUrl({ spaceId: task.SpaceId!, runbookRunId: task.Arguments.RunbookRunId }) });
                return;
            }
        });
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>);
        }
        return <InternalRedirect push={false} to={redirectTo}/>;
    }
    static displayName = "InterruptionToProjectRedirect";
}
