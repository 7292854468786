import React from "react";
import { TermsText } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import { Note } from "~/components/form/index";
export const CommonInsightsReportHelp = () => (<TermsText>
        <Note heading={"The 'Split by' selector"}>allows you to compare differences between projects, project groups, environments, or environment groups.</Note>
        <Note heading={"The 'Date range' selector"}>allows you to choose the time-frame and granularity of the data in the chart.</Note>
    </TermsText>);
export const CommonProjectInsightsHelp = () => (<TermsText>
        <Note heading={"The 'Date range' selector"}>allows you to choose the time-frame and granularity of the data in the chart.</Note>
    </TermsText>);
