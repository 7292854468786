import React from "react";
import Card from "~/components/Card";
import { WelcomeDialogSignIcon } from "../WelcomeDialogSignIcon";
import type { WelcomeDialogCardProps } from "./WelcomeDialogCardProps";
import { cardStyles } from "./styles";
type SampleProjectCardProps = WelcomeDialogCardProps & {
    newCopy?: boolean;
};
export function SampleProjectCard({ busy, onClick, newCopy }: SampleProjectCardProps) {
    return (<Card link={!busy && (<a href="#" onClick={onClick} className={cardStyles.link}>
                        {newCopy ? "Octopus 101" : "Create sample project"}
                    </a>)} logo={<div className={cardStyles.logo(!busy)}>
                    <WelcomeDialogSignIcon />
                </div>} header={<div className={cardStyles.header}>Sample project</div>} content={newCopy ? "Learn the basics with a fully editable sample project." : "Explore a sample project to learn about key Octopus features."} className={cardStyles.card} contentClassName={cardStyles.content} leftAlign={true}/>);
}
