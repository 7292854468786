import { Checkbox, Callout } from "@octopusdeploy/design-system-components";
import { Permission, type ChannelResource, type GitReferenceSatisfiesChannelGitReferenceRulesResponse, type ProjectResource, type ReleaseResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import InternalLink from "~/components/Navigation/InternalLink";
import { PermissionCheck } from "~/components/PermissionCheck";
export type ChannelRulesNotSatisfiedCalloutProps = {
    project: ProjectResource;
    release: ReleaseResource;
    channel: ChannelResource;
    gitReferenceRuleValidation?: GitReferenceSatisfiesChannelGitReferenceRulesResponse;
    actionsWithPackageVersionRuleViolations: string[];
    actionsThatViolateGitResourceRules: Set<string>;
    onIgnoreChannelRulesChange: (ignoreChannelRules: boolean) => void;
};
export function ChannelRulesNotSatisfiedCallout({ project, release, channel, gitReferenceRuleValidation, actionsWithPackageVersionRuleViolations, onIgnoreChannelRulesChange, actionsThatViolateGitResourceRules, }: ChannelRulesNotSatisfiedCalloutProps) {
    const channelGitProtectionRulesFeatureEnabled = isFeatureToggleEnabled("ChannelGitProtectionRulesFeatureToggle");
    if (!channelGitProtectionRulesFeatureEnabled)
        return null;
    const violatesGitProtectionRules = gitReferenceRuleValidation ? !gitReferenceRuleValidation.SatisfiesGitReferenceRules : false;
    const violatesPackageRules = actionsWithPackageVersionRuleViolations && actionsWithPackageVersionRuleViolations.length > 0;
    const violatesGitResourceRules = actionsThatViolateGitResourceRules.size > 0;
    if (!violatesGitProtectionRules && !violatesPackageRules && !violatesGitResourceRules)
        return null;
    return (<Callout type={project.AllowIgnoreChannelRules ? "warning" : "danger"} title="Channel Rules Not Satisfied">
            <p>
                This release does not satisfy the rules for the{" "}
                <InternalLink to={links.editChannelPage.generateUrl({
            spaceId: project.SpaceId,
            projectSlug: project.Slug,
            channelId: channel?.Id ?? "",
        })} showOpensInNewTabIcon={true} openInSelf={false}>
                    {channel?.Name} channel
                </InternalLink>
                . Please review the errors.
            </p>
            {project.AllowIgnoreChannelRules && <Checkbox label="Ignore channel rules and create this release" accessibleName="Ignore Channel Rules" value={release.IgnoreChannelRules ?? false} onChange={onIgnoreChannelRulesChange}/>}
            <PermissionCheck permission={Permission.ProjectEdit} project={project.Id}>
                <p>
                    The option to allow creation of releases that do not satisfy channel rules can be managed in{" "}
                    <InternalLink to={links.projectSettingsPage.generateUrl({
            spaceId: project.SpaceId,
            projectSlug: project.Slug,
        })} showOpensInNewTabIcon={true} openInSelf={false}>
                        Project Settings
                    </InternalLink>
                    .
                </p>
            </PermissionCheck>
        </Callout>);
}
