import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { BlueprintRepositoryItem } from "~/areas/blueprints/BlueprintsRepositoriesPage";
interface DeleteRepositoryProps {
    blueprintRepository: BlueprintRepositoryItem;
}
export default ({ blueprintRepository }: DeleteRepositoryProps) => (<div>
        <Callout title="This is a destructive action" type={"danger"}>
            This action <strong>cannot</strong> be undone. This will permanently delete the repository <strong>{blueprintRepository.name}</strong> and remove access to the blueprints contained within.
        </Callout>
    </div>);
