import { ActionButton } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import { actions as dialogActions, selectors as dialogSelectors } from "~/components/Dialog/store";
import ExampleDialogContent from "~/components/StyleGuide/ExampleDialogContent";
interface ExampleComponentThatShowsDialogsProps {
    someProp?: boolean;
}
interface ExampleComponentThatShowsDialogsState extends DataBaseComponentState {
    someState?: string;
}
interface GlobalDispatchProps {
    openDialog(id: string): void;
}
interface StateProps {
    dialogAOpen: boolean;
    dialogBOpen: boolean;
}
type Props = ExampleComponentThatShowsDialogsProps & StateProps & GlobalDispatchProps;
class ExampleComponentThatShowsDialogsInternal extends DataBaseComponent<Props, ExampleComponentThatShowsDialogsState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }
    doSomeProcess() {
        // Do stuff
        this.props.openDialog(Math.random() < 0.5 ? "DialogA" : "DialogB");
    }
    render() {
        return (<div>
                <ActionButton onClick={() => this.doSomeProcess()} label="Do a process that opens a dialog"/>
                <Dialog open={this.props.dialogAOpen}>
                    <ExampleDialogContent title="Dialog A"/>
                </Dialog>
                <Dialog open={this.props.dialogBOpen}>
                    <ExampleDialogContent title="Dialog B"/>
                </Dialog>
            </div>);
    }
    static displayName = "ExampleComponentThatShowsDialogsInternal";
}
const mapGlobalStateToProps = (state: GlobalState) => {
    return {
        dialogAOpen: dialogSelectors.createDialogOpenSelector("DialogA")(state),
        dialogBOpen: dialogSelectors.createDialogOpenSelector("DialogB")(state),
    };
};
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch) => {
    return {
        openDialog: (key: string) => {
            dispatch(dialogActions.open(key));
        },
    };
};
const ExampleComponentThatShowsDialogs = connect<StateProps, GlobalDispatchProps, ExampleComponentThatShowsDialogsProps, GlobalState>(mapGlobalStateToProps, mapGlobalActionDispatchersToProps)(ExampleComponentThatShowsDialogsInternal);
export default ExampleComponentThatShowsDialogs;
