import React from "react";
import { useTrackThemeChanged } from "~/components/Theme/analytics/useTrackThemeChanged";
import useLocalStorage from "~/hooks/useLocalStorage";
import type { ThemePaletteType } from "~/theme/index";
export function useThemePaletteType(): ThemePaletteType {
    const { coercedPaletteType } = useThemeFromLocalStorage();
    return coercedPaletteType;
}
export function useSetThemePaletteType(): SetThemeValue {
    const { coercedPaletteType, setPaletteType } = useThemeFromLocalStorage();
    const trackThemeChanged = useTrackThemeChanged();
    return React.useCallback((value) => {
        setPaletteType(value);
        trackThemeChanged({ previousTheme: coercedPaletteType, selectedTheme: value });
    }, [coercedPaletteType, trackThemeChanged, setPaletteType]);
}
export function useInitializeThemeInStorageEffect() {
    const { paletteType, setPaletteType, coercedPaletteType } = useThemeFromLocalStorage();
    React.useEffect(() => {
        if (paletteType === "unassigned") {
            setPaletteType(coercedPaletteType);
        }
    }, [coercedPaletteType, paletteType, setPaletteType]);
}
type SetThemeValue = (value: ThemePaletteType) => void;
interface ThemeFromLocalStorageResult {
    paletteType: ThemePaletteType | "unassigned";
    coercedPaletteType: ThemePaletteType;
    setPaletteType: SetThemeValue;
}
function useThemeFromLocalStorage(): ThemeFromLocalStorageResult {
    const [paletteFromStorage, setPaletteType] = useLocalStorage<ThemePaletteType | "unassigned">("theme", "unassigned");
    const browserPreferredPalette = getBrowserPreferredPalette("light");
    return {
        paletteType: paletteFromStorage,
        coercedPaletteType: paletteFromStorage === "unassigned" ? browserPreferredPalette : paletteFromStorage,
        setPaletteType,
    };
}
function getBrowserPreferredPalette(fallback: ThemePaletteType): ThemePaletteType {
    if (!window.matchMedia) {
        return fallback;
    }
    else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
    }
    else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
        return "light";
    }
    else {
        return fallback;
    }
}
