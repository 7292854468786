/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Callout } from "@octopusdeploy/design-system-components";
import type { ActivityElement, ActivityLogElement, ArtifactResource, GitRefResource } from "@octopusdeploy/octopus-server-client";
import { ActivityLogEntryCategory } from "@octopusdeploy/octopus-server-client";
import _, { flatten } from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import ArtifactLink from "~/areas/tasks/components/Task/Artifacts/ArtifactLink";
import { generateUriForLine, getStatusIcon } from "~/areas/tasks/components/Task/TaskLog/TaskLogUtil";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import TextWithLinks from "../../../../../components/TextWithLinks/TextWithLinks";
import { RetryCountdown } from "../RetryCountdown";
import styles from "./style.module.less";
interface TaskSummaryLineComponentProps {
    element: UniqueActivityElement;
    artifacts: ArtifactResource[];
    showFatalsInline?: boolean;
    onLogNavigation(elementId: string): void;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    gitRef?: GitRefResource;
    depth?: number;
}
type TaskSummaryLineProps = TaskSummaryLineComponentProps & RouteComponentProps<any>;
class TaskSummaryLineInternal extends React.PureComponent<TaskSummaryLineProps> {
    render(): false | JSX.Element {
        const element = this.props.element;
        const childrenToShow = ((element.Children as UniqueActivityElement[]) || []).filter((e) => (this.props.depth ?? 0) < 1 ||
            e.Children.length > 1 ||
            ((this.props.depth ?? 0) >= 2 && e.ShowAtSummaryLevel) ||
            this.getLogsWithCategory(e, true, ActivityLogEntryCategory.Highlight).length > 0 ||
            this.getLogsWithCategory(e, true, ActivityLogEntryCategory.Fatal).length > 0);
        const highlights = this.getLogsWithCategory(element, childrenToShow.length === 0, ActivityLogEntryCategory.Highlight).map((h, i) => (<div key={i}>
                <TextWithLinks message={h.MessageText} allowHtml={false}/>
            </div>));
        const fatals = this.getLogsWithCategory(element, false, ActivityLogEntryCategory.Fatal).map((h, i) => (<div key={i} className={styles.jumpToLine}>
                <TextWithLinks message={h.MessageText} allowHtml={false}/>{" "}
                <a href={"#" + generateUriForLine(this.props, element.uniqueId, h.Number ?? 0)} className={styles.jumpToLineLink}>
                    Go to line
                </a>
            </div>));
        const header = this.props.showFatalsInline || childrenToShow.length === 0 ? (<div>
                    <div className={styles.taskSummaryLine}>
                        {getStatusIcon(element.Status!)}
                        <a className={styles.taskSummaryLineText} href={""} onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                this.props.onLogNavigation(element.uniqueId);
            }}>
                            {element.Name}
                        </a>
                    </div>
                    {fatals && fatals.length > 0 && <div className={styles.fatalBlock}>{fatals}</div>}
                </div>) : fatals && fatals.length > 0 ? (<Callout title="Failed" type={"danger"}>
                    {fatals}
                </Callout>) : null;
        const childStyle = this.props.showFatalsInline ? styles.taskSummaryChildren : styles.taskSummaryTopLevel;
        const children = childrenToShow.length > 0 && (<div className={childStyle}>
                {childrenToShow.map((e) => (<TaskSummaryLine key={e.uniqueId} element={e} artifacts={this.props.artifacts} onLogNavigation={this.props.onLogNavigation} depth={(this.props.depth ?? 0) + 1} showFatalsInline={true} stepsCorrelationIds={this.props.stepsCorrelationIds} gitRef={this.props.gitRef}/>))}
            </div>);
        const lastLogElement = _.last(element.LogElements);
        const showRetryCountdown = lastLogElement && lastLogElement.Category === ActivityLogEntryCategory.Retry;
        return (<>
                {element.Name && (<div>
                        {header}
                        {showRetryCountdown && (<div className={styles.retryBlock}>
                                <RetryCountdown to={lastLogElement.MessageText}/>
                            </div>)}
                        {children}
                        {highlights && highlights.length > 0 && <div className={styles.highlightBlock}>{highlights}</div>}
                        {this.getArtifacts(childrenToShow.length === 0)}
                    </div>)}
            </>);
    }
    private getLogsWithCategory(element: ActivityElement, recurse: boolean, category: ActivityLogEntryCategory): ActivityLogElement[] {
        const highlights = (element.LogElements || []).filter((e) => e.Category === category);
        return recurse ? highlights.concat(flatten(element.Children.map((c) => this.getLogsWithCategory(c, recurse, category)))) : highlights;
    }
    private getArtifacts(isLeaf: boolean) {
        const element = this.props.element;
        const artifactResources = this.props.artifacts.filter((a) => (a.LogCorrelationId && isLeaf ? a.LogCorrelationId.startsWith(element.Id) : a.LogCorrelationId === element.Id));
        const elements = artifactResources.map((a) => (<div>
                <ArtifactLink artifact={a}/>
            </div>));
        return elements.length > 0 && <div className={styles.artifactsBlock}>{elements}</div>;
    }
    static displayName = "TaskSummaryLineInternal";
}
const TaskSummaryLine = withRouter(TaskSummaryLineInternal);
export default TaskSummaryLine;
