export function buildPartialReleaseNotes(originalReleaseNotes: string, numOfLinesToShow: number): [
    string,
    boolean
] {
    const lines = originalReleaseNotes ? originalReleaseNotes.split(/\r\n|\r|\n/, numOfLinesToShow + 1) : [];
    if (lines.length > numOfLinesToShow) {
        const truncated = lines.slice(0, numOfLinesToShow);
        return [truncated.join("\n"), true];
    }
    return [originalReleaseNotes, false];
}
