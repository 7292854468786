import type { MachineResource } from "@octopusdeploy/octopus-server-client";
import { EndpointsHelper, isStepPackageEndpointResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useDispatch } from "react-redux";
import { machineActions } from "~/areas/infrastructure/reducers/machines";
import { getMachineIconUrl } from "~/utils/MachineIconUrlFetchers/machineIconUrlFetchers";
export function useOnMachineSavedDispatch() {
    const dispatch = useDispatch();
    return React.useCallback((machine: MachineResource) => {
        const machineIconUrl = getMachineIconUrl(machine);
        dispatch(machineActions.machineSaved({
            id: machine.Id,
            name: machine.Name,
            machineType: EndpointsHelper.getFriendlyName(machine),
            machineIconUrl,
            isDisabled: machine.IsDisabled,
            isStepPackage: isStepPackageEndpointResource(machine.Endpoint),
            healthStatus: machine.HealthStatus,
        }));
    }, [dispatch]);
}
