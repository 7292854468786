import { Callout } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { useAggregateAPIOperationStatus, useMutation, useQuery } from "@octopusdeploy/octopus-react-client";
import type { Repository, SigningKeyConfigurationResource, SigningKeyResource } from "@octopusdeploy/octopus-server-client";
import { compact } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import SidebarLayout from "~/components/SidebarLayout";
import SimpleDataTable from "~/components/SimpleDataTable";
import { Note } from "~/components/form";
import DateFormatter from "~/utils/DateFormatter";
import { EditSigningKeyConfiguration } from "./EditSigningKeyConfiguration";
export async function signingKeysPageLoader(repository: Repository): Promise<LoaderData> {
    const configuration = repository.SigningKeyConfiguration.get();
    const signingKeys = repository.SigningKeys.all();
    return {
        configuration: await configuration,
        signingKeys: await signingKeys,
    };
}
interface LoaderData {
    configuration: SigningKeyConfigurationResource;
    signingKeys: SigningKeyResource[];
}
interface SigningKeysPageProps {
    loaderData: LoaderData;
}
export function SigningKeysPage({ loaderData }: SigningKeysPageProps) {
    const { errors, isInProgress } = useAggregateAPIOperationStatus();
    const { isOpen: isConfirmRotateDialogOpen, closeDialog: closeConfirmRotateDialog, openDialog: openConfirmRotateDialog } = useDialogTrigger();
    const [configuration, setConfiguration] = useState<SigningKeyConfigurationResource>(loaderData.configuration);
    const { result: signingKeys, refetch: refetchSigningKeys } = useQuery((repository) => repository.SigningKeys.all(), [], "Signing Keys", { initialResult: loaderData.signingKeys });
    const { execute: rotateSigningKey, isExecuting: isRotatingSigningKey } = useMutation({
        name: "Rotate Signing Key",
        action: (repository) => repository.SigningKeys.rotate(),
        afterComplete: async () => refetchSigningKeys(),
    });
    const { execute: revokeSigningKey, error: revokeError } = useMutation({
        name: "Revoke Signing Key",
        action: (repository: Repository, signingKey: SigningKeyResource) => repository.SigningKeys.revoke(signingKey.Id),
        afterComplete: async () => refetchSigningKeys(),
    });
    const primaryAction: PrimaryPageAction = {
        type: "button",
        disabled: isRotatingSigningKey,
        label: "Rotate Signing Key",
        onClick: openConfirmRotateDialog,
    };
    return (<PaperLayoutVNext title={signingKeysPageTitle} primaryAction={primaryAction} busy={isInProgress} errors={errors}>
            <SidebarLayout sideBar={<SigningKeysSidebar configuration={configuration} setConfiguration={setConfiguration}/>}>
                <ConfirmRotationDialog isOpen={isConfirmRotateDialogOpen} onConfirmRotate={rotateSigningKey} onClose={closeConfirmRotateDialog}/>
                {<SigningKeyTable data={signingKeys} headerColumns={["State", "Id", "Created", "Expired", ""]} onRow={(key) => renderRow(key, signingKeys, configuration, revokeSigningKey, revokeError)}/>}
            </SidebarLayout>
        </PaperLayoutVNext>);
}
class SigningKeyTable extends SimpleDataTable<SigningKeyResource> {
}
function renderRow(signingKey: SigningKeyResource, signingKeys: SigningKeyResource[], configuration: SigningKeyConfigurationResource, revoke: (signingKey: SigningKeyResource) => Promise<void>, revokeError: Error | null) {
    return [
        signingKey.Expired ? "Expired" : "Active",
        signingKey.Id,
        DateFormatter.dateToShortFormat(signingKey.Created),
        signingKey.Expired ? DateFormatter.dateToShortFormat(signingKey.Expired) : <i>(Rotating on {calculateNextRotation(signingKeys, configuration)})</i>,
        getOverflowMenuItems(signingKey, revoke, revokeError),
    ];
}
function calculateNextRotation(signingKeys: SigningKeyResource[], configuration: SigningKeyConfigurationResource) {
    const activeKey = signingKeys.find((k) => !k.Expired);
    if (!activeKey) {
        return null;
    }
    const rotationMoment = moment(activeKey.Created);
    rotationMoment.add(configuration.ExpireAfterDays, "d");
    return DateFormatter.dateToShortFormat(rotationMoment);
}
function SigningKeysSidebar({ setConfiguration, configuration }: {
    configuration: SigningKeyConfigurationResource;
    setConfiguration: (configuration: SigningKeyConfigurationResource) => void;
}) {
    return (<div>
            <h4>Configuration</h4>
            <p>
                The active signing key will be rotated every <b>{toDaysString(configuration.ExpireAfterDays)}</b>.
            </p>
            <p>
                Expired keys will be revoked <b>{toDaysString(configuration.RevokeAfterDays)}</b> after they expire.
                <Note>Until then they will continue to validate incoming requests.</Note>
            </p>
            <OpenDialogButton label="Change">
                <EditSigningKeyConfiguration onSaveDone={(configuration) => setConfiguration(configuration)}/>
            </OpenDialogButton>
        </div>);
}
function getOverflowMenuItems(signingKey: SigningKeyResource, revokeSigningKey: (signingKey: SigningKeyResource) => Promise<void>, revokeError: null | Error) {
    if (!signingKey.Expired) {
        return;
    }
    const revoke = OverflowMenuItems.dialogItem("Revoke", <OkDialogLayout title={"Revoke Signing Key"} errors={revokeError === null ? null : [revokeError]} okButtonLabel="Revoke" onOkClick={async () => {
            await revokeSigningKey(signingKey);
            return true;
        }}>
            <p>Are you sure you would like to revoke this signing key?</p>
            <Callout title={"Revoking a signing key is permanent. There is no going back."} type={"warning"}/>
        </OkDialogLayout>);
    return <OverflowMenu menuItems={compact([revoke])}/>;
}
function toDaysString(days: number) {
    return `${days} ${days > 1 ? "days" : "day"}`;
}
interface ConfirmRotationDialogProps {
    isOpen: boolean;
    onConfirmRotate: () => Promise<void>;
    onClose: () => void;
}
function ConfirmRotationDialog({ isOpen, onConfirmRotate, onClose }: ConfirmRotationDialogProps) {
    return (<ConfirmationDialog title="Rotate Signing Key" continueButtonLabel="Rotate" open={isOpen} onClose={onClose} onContinueClick={async () => {
            await onConfirmRotate();
            return true;
        }}>
            <p>Are you sure you want to continue?</p>
            <Callout title="This will expire the active signing key and create a new one" type={"information"}>
                <p>Expired signing keys will continue to validate incoming requests until revoked.</p>
            </Callout>
        </ConfirmationDialog>);
}
export const signingKeysPageTitle = "Signing Keys";
