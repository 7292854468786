import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import styles from "../../../primitiveComponents/dataDisplay/SvgImage/styles.module.less";
const ExecuteTargets = (props: Partial<SvgImageProps>) => (<SvgImage viewBox="0 0 151 26" title="Execute Targets" {...props}>
        <g fillRule="evenodd" strokeWidth="1">
            <path className={styles.imgDark} d="M25.083 12.972h19.672v-4.18c-.027-.366.102-.613.386-.742.283-.129.624-.008 1.02.362l4.625 4.56c.375.317.563.621.563.912 0 .291-.115.55-.344.774L46.161 19.4c-.413.3-.753.391-1.02.275-.268-.116-.396-.401-.386-.856v-4.544H25.083v-1.302z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M104.28 12.5h.72a3 3 0 0 0-2-2v.73a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.7a.43.43 0 0 1 .43.43v.85a.42.42 0 0 1-.43.42h-.7a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2h-.72a.42.42 0 0 1-.43-.42v-.85a.43.43 0 0 1 .43-.43z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M102.69 20.25h7.23a.42.42 0 0 0 .42-.42V6.9a.42.42 0 0 0-.42-.42H94.38a.42.42 0 0 0-.43.42v12.93a.42.42 0 0 0 .43.42h7.23m5.64-6.47a.42.42 0 0 1-.42.42h-1a3.85 3.85 0 0 1-2.83 2.88v1a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-1a3.85 3.85 0 0 1-2.88-2.88h-1a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43h1a3.85 3.85 0 0 1 2.88-2.88v-.94a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.94a3.85 3.85 0 0 1 2.88 2.88h1a.42.42 0 0 1 .42.43l-.05.85zm17.03-1.28h.72a3 3 0 0 0-2-2v.73a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.7a.43.43 0 0 1 .43.43v.85a.42.42 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2h-.72a.42.42 0 0 1-.43-.42v-.85a.43.43 0 0 1 .45-.43z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M122.69 20.25h7.23a.42.42 0 0 0 .42-.42V6.9a.42.42 0 0 0-.42-.42h-15.54a.42.42 0 0 0-.43.42v12.93a.42.42 0 0 0 .43.42h7.23m5.64-6.47a.42.42 0 0 1-.42.42h-1a3.85 3.85 0 0 1-2.83 2.88v1a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-1a3.85 3.85 0 0 1-2.88-2.88h-1a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43h1a3.85 3.85 0 0 1 2.88-2.88v-.94a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.94a3.85 3.85 0 0 1 2.88 2.88h1a.42.42 0 0 1 .42.43l-.05.85zM64 12.5h.72a3 3 0 0 0-2-2v.73a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.72a.43.43 0 0 1 .43.43v.85a.42.42 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2H64a.42.42 0 0 1-.43-.42v-.85a.43.43 0 0 1 .43-.43z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M62.44 20.25h7.23a.42.42 0 0 0 .42-.42V6.9a.42.42 0 0 0-.42-.42H54.13a.42.42 0 0 0-.43.42v12.93a.42.42 0 0 0 .43.42h7.23M67 13.78a.42.42 0 0 1-.42.42h-1a3.85 3.85 0 0 1-2.88 2.88v1a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-1a3.85 3.85 0 0 1-2.88-2.88h-1a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43h1A3.85 3.85 0 0 1 61 9.62v-.94a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.94a3.85 3.85 0 0 1 2.88 2.88h1a.42.42 0 0 1 .42.43v.85zm17-1.28h.72a3 3 0 0 0-2-2v.73a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.72a.43.43 0 0 1 .43.43v.85a.42.42 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2H84a.42.42 0 0 1-.43-.42v-.85a.43.43 0 0 1 .43-.43z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M82.44 20.25h7.23a.42.42 0 0 0 .42-.42V6.9a.42.42 0 0 0-.42-.42H74.13a.42.42 0 0 0-.43.42v12.93a.42.42 0 0 0 .43.42h7.23M87 13.78a.42.42 0 0 1-.42.42h-1a3.85 3.85 0 0 1-2.88 2.88v1a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-1a3.85 3.85 0 0 1-2.88-2.88h-1a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43h1A3.85 3.85 0 0 1 81 9.62v-.94a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.94a3.85 3.85 0 0 1 2.88 2.88h1a.42.42 0 0 1 .42.43v.85zm57.28-1.28h.72a3 3 0 0 0-2-2v.73a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-.73a3 3 0 0 0-2 2h.7a.43.43 0 0 1 .43.43v.85a.42.42 0 0 1-.43.42h-.72a3 3 0 0 0 2 2v-.72a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.72a3 3 0 0 0 2-2h-.72a.42.42 0 0 1-.43-.42v-.85a.43.43 0 0 1 .45-.43z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M142.69 20.25h7.23a.42.42 0 0 0 .42-.42V6.9a.42.42 0 0 0-.42-.42h-15.54a.42.42 0 0 0-.43.42v12.93a.42.42 0 0 0 .43.42h7.23m5.64-6.47a.42.42 0 0 1-.42.42h-1a3.85 3.85 0 0 1-2.83 2.88v1a.42.42 0 0 1-.43.42h-.85a.42.42 0 0 1-.42-.42v-1a3.85 3.85 0 0 1-2.88-2.88h-.95a.42.42 0 0 1-.42-.42v-.85a.42.42 0 0 1 .42-.43h.95a3.85 3.85 0 0 1 2.88-2.88v-.94a.42.42 0 0 1 .42-.43h.85a.43.43 0 0 1 .43.43v.94a3.85 3.85 0 0 1 2.88 2.88h1a.42.42 0 0 1 .42.43l-.05.85z"/>
            <circle className={styles.imgCyan} cx="12.92" cy="12.86" r="12.6" fillRule="nonzero"/>
            <path className={styles.imgWhite} fillRule="nonzero" d="M6.7 16.8c1.14-.74 2.48-2 2-3.49a9.06 9.06 0 0 1-.7-2.38 5.45 5.45 0 0 1 .32-2.19 5.54 5.54 0 0 1 6.83-3.23 4.92 4.92 0 0 1 3.26 6.32c-.62 1.49-.89 2.64.48 3.79.36.32 1.26.79 1.25 1.35 0 .73-1.44-.16-1.6-.29.19.32 2 2.2.85 2.34-1.15.14-2-1.35-2.63-2-1.07-1.07-.88 1.29-.89 1.78 0 .77-.55 2.33-1.52 1.31-.8-.83-.5-2.17-1.06-3.11s-1.63 1-1.89 1.39c-.26.39-1.71 2.44-2.27 1.37-.47-.88.27-2.24.64-3-.14.28-1.07.71-1.34.84a3.56 3.56 0 0 1-1.98.4c-1.44-.09-.34-.85.25-1.23v.03z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M61.654 19v2.969a2.11 2.11 0 0 0-.756.461 1.507 1.507 0 0 0-.416.672h-3.178c-.203.086-.304.214-.304.382 0 .17.101.295.304.377h3.178C60.78 24.621 61.285 25 62 25s1.21-.38 1.487-1.139h17.495c.213.432.408.712.586.839.667.475 1.523.341 1.947-.08.292-.291.45-.544.472-.759 8.542.018 13.01.018 13.403 0 .27-.012.02-.147 0-.407-.02-.261.044-.324-.045-.352-.09-.028-13.358.032-13.358 0 0-.164-.119-.51-.472-.78-.19-.144-.596-.294-.618-.322-.04-.05 0-.075 0-.52v-1.23c0-.273-.134-.21-.176-.376-.048-.19-.468.268-.532.23-.058-.033-.03.986-.035 1.493-.002.186 0 .372 0 .372s-.417.123-.851.543c-.1.096-.206.292-.32.59H63.486a1.437 1.437 0 0 0-.355-.672c-.167-.17-.408-.314-.724-.43v-3h-.754z"/>
            <path className={styles.imgDark} fillRule="nonzero" d="M101.654 19v2.969a2.11 2.11 0 0 0-.756.461 1.507 1.507 0 0 0-.416.672h-3.178c-.203.086-.304.214-.304.382 0 .17.101.295.304.377h3.178c.297.76.803 1.139 1.518 1.139s1.21-.38 1.487-1.139h17.495c.213.432.408.712.586.839.667.475 1.523.341 1.947-.08.292-.291.45-.544.472-.759 10.132.018 15.395.018 15.79 0 .042-.002.188.63.573 1.007.337.33.84.432 1.034.432 1.236 0 1.694-1.146 1.68-1.329 0-.002 1.604 0 3.407 0 .188 0 .395-.227.395-.447s-.207-.438-.395-.438c-.676 0-3.405.007-3.407 0a2.218 2.218 0 0 0-.497-.89c-.113-.118-.34-.25-.678-.396l-.03-1.602c-.168-.217-.28-.365-.335-.442-.113-.16-.338.296-.413.247-.095-.062-.033 1.768-.043 1.76-.047-.034-.56.216-.856.559-.313.363-.408.82-.436.812-.09-.028-15.789 0-15.789-.032 0-.164-.15-.51-.472-.78a1.638 1.638 0 0 0-.618-.322v-1.75c0-.273-.134-.21-.176-.376-.048-.19-.468.268-.532.23-.058-.033-.03.986-.035 1.493-.002.186 0 .403 0 .403s-.508.177-.79.457c-.114.112-.241.327-.382.645h-17.495a1.437 1.437 0 0 0-.355-.672c-.167-.17-.408-.314-.724-.43v-3h-.754z"/>
        </g>
    </SvgImage>);
export { ExecuteTargets };
