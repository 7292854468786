import type { ActivityLogElement } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import { getActivityElementClass } from "~/areas/tasks/components/Task/TaskLog/TaskLogUtil";
import { TaskLogGapRange } from "~/components/TaskLogLines/TaskLogGapRange";
import styles from "~/components/TaskLogLines/style.module.less";
interface TaskLogGapProps {
    line: ActivityLogElement;
    showTimestamps: boolean;
    isFetchDisabled: boolean;
    splitGaps: boolean;
    onFetchRange(start: number, end: number): void;
}
export const TaskLogGap = ({ line, showTimestamps, isFetchDisabled, onFetchRange, splitGaps }: TaskLogGapProps) => {
    const lineGaps: {
        start: number;
        end: number;
    }[] = [];
    let start = line.Number ?? 1;
    const end = line.GapLastNumber ?? 0;
    const maxRangeToLoad = 1000;
    if (splitGaps) {
        while (start < end) {
            const nearestThousand = start + maxRangeToLoad / 2 + (maxRangeToLoad - ((start + maxRangeToLoad / 2) % maxRangeToLoad));
            if (end - nearestThousand < 500) {
                break;
            }
            if (nearestThousand < end) {
                lineGaps.push({ start, end: nearestThousand });
                start = nearestThousand + 1;
            }
        }
    }
    lineGaps.push({ start, end });
    return (<div className={styles.gap}>
            <div className={cn(styles.logLine, getActivityElementClass(line.Category))}>
                <div className={styles.lineNumber}></div>
                {showTimestamps && (<React.Fragment>
                        <div className={styles.occurred}></div>
                        <div className={styles.category}></div>
                    </React.Fragment>)}
                <div className={styles.message}>
                    {!splitGaps ? (<TaskLogGapRange message={`…show all…`} start={start} end={end + 5000} onFetchRange={onFetchRange} isFetchDisabled={isFetchDisabled}/>) : (lineGaps.map((g) => {
            return <TaskLogGapRange message={`…show lines ${g.start} to ${g.end}…`} key={g.start} start={g.start} end={g.end} onFetchRange={onFetchRange} isFetchDisabled={isFetchDisabled}/>;
        }))}
                </div>
            </div>
        </div>);
};
