enum ActionKeys {
    FORM_DIRTYCHANGED = "FORM_DIRTYCHANGED"
}
interface IDirtyChangedAction {
    type: ActionKeys.FORM_DIRTYCHANGED;
    dirty: boolean;
}
type ActionTypes = IDirtyChangedAction;
export function createFormDirtyChangedAction(dirty: boolean): IDirtyChangedAction {
    return { type: ActionKeys.FORM_DIRTYCHANGED, dirty };
}
export interface FormReducerState {
    dirty: boolean;
}
const formReducer = (state: FormReducerState = { dirty: false }, action: ActionTypes): FormReducerState => {
    switch (action.type) {
        case ActionKeys.FORM_DIRTYCHANGED:
            return state.dirty === action.dirty ? state : { ...state, dirty: action.dirty };
        default:
            return state;
    }
};
export default formReducer;
