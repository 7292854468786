import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { GetProjectsAffectedByChangeBffResponse, IIdName } from "@octopusdeploy/octopus-server-client";
import pluralize from "pluralize";
import * as React from "react";
import { DataBaseComponent, type DataBaseComponentState } from "~/components/DataBaseComponent/index";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
interface AffectedProjectsNotificationDialogProps {
    affectedProjects: GetProjectsAffectedByChangeBffResponse | null;
    onSave: () => Promise<boolean>;
    onCancel: () => void;
}
export default class AffectedProjectsNotificationDialog extends DataBaseComponent<AffectedProjectsNotificationDialogProps, DataBaseComponentState> {
    constructor(props: AffectedProjectsNotificationDialogProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (<SaveDialogLayout title={"Save variables"} busy={this.state.busy} errors={this.errors} onSaveClick={this.props.onSave} onCancelClick={this.props.onCancel}>
                <Callout type={"information"} title={"Other projects are connected to these variables"}>
                    <AffectedProjectsNotification affected={this.props.affectedProjects}></AffectedProjectsNotification>
                </Callout>
            </SaveDialogLayout>);
    }
    static displayName = "AffectedProjectsNotificationDialog";
}
interface AffectedProjectsNotificationProps {
    affected: GetProjectsAffectedByChangeBffResponse | null;
}
function AffectedProjectsNotification({ affected }: AffectedProjectsNotificationProps) {
    if (!affected) {
        return null;
    }
    return (<>
            <div>
                Saving these variable values will affect {affected.TotalCount} {pluralize(`other project`, affected.TotalCount)}:
            </div>
            <DisplayedProjects projects={affected.DisplayedProjects}></DisplayedProjects>
            <HiddenProjects count={affected.HiddenProjectsCount}></HiddenProjects>
        </>);
}
interface DisplayedProjectsProps {
    projects: IIdName[];
}
function DisplayedProjects({ projects }: DisplayedProjectsProps) {
    return (<ul className={styles.projectNames}>
            {projects.map((p) => (<li key={p.Id}>{p.Name}</li>))}
        </ul>);
}
interface HiddenProjectsProps {
    count: number;
}
function HiddenProjects({ count }: HiddenProjectsProps) {
    if (count > 0) {
        return (<div key={"hidden-projects"}>
                + {count} {pluralize(`more project`, count)}{" "}
            </div>);
    }
    return null;
}
const styles = {
    title: css({
        color: themeTokens.color.text.secondary,
        fontWeight: "600",
    }),
    projectNames: css({
        margin: "0",
        listStylePosition: "inside",
        listStyleType: "disc",
    }),
};
