import type { ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition, RootInputSchema } from "@octopusdeploy/step-runtime-inputs";
import { createObjectInputPaths } from "@octopusdeploy/step-runtime-inputs";
import type { DeploymentTargetInputComponent } from "@octopusdeploy/step-ui";
import * as React from "react";
import { useCallback } from "react";
import type { DeploymentTargetInputDependencies } from "~/components/StepPackageDeploymentTargetEditor/DeploymentTargetInputDependencies";
import type { StepPackageDeploymentTarget } from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTarget";
import { EditStepPackageInputs } from "~/components/StepPackageEditor/EditStepPackageInputs";
import { createGetRenderedComponentAndSummaryForDeploymentTargetFactory } from "./Inputs/createGetRenderedComponentAndSummaryForDeploymentTargetFactory";
export interface TypedStepPackageDeploymentTargetEditorProps<StepInputs> {
    inputs: ObjectRuntimeInputs<StepInputs>;
    getRootInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => RootInputSchema;
    stepPackage: StepPackageDeploymentTarget<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (name: PathToInput) => string;
    isExpandedByDefault: boolean;
    inputDependencies: DeploymentTargetInputDependencies;
}
export function TypedStepPackageDeploymentTargetEditor<StepInputs>(props: TypedStepPackageDeploymentTargetEditorProps<StepInputs>) {
    const { inputs, getRootInputSchema, setInputs, stepPackage, getFieldError, inputDependencies, isExpandedByDefault } = props;
    const { ui } = stepPackage;
    const getInputSchema = useCallback<(inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition>((inputs) => {
        const rootInputSchema = getRootInputSchema(inputs);
        return { type: "object", nonDiscriminatorProperties: rootInputSchema.properties, discriminatorProperties: [] };
    }, [getRootInputSchema]);
    const inputPaths = createObjectInputPaths<StepInputs>(getInputSchema(inputs));
    const formContent = ui.editInputsForm(inputPaths);
    const getRenderedComponentAndSummary = createGetRenderedComponentAndSummaryForDeploymentTargetFactory(inputs, setInputs, getInputSchema, inputPaths, getFieldError, inputDependencies);
    return <EditStepPackageInputs<StepInputs, DeploymentTargetInputComponent> isExpandedByDefault={isExpandedByDefault} formContent={formContent} getRenderedComponentAndSummary={getRenderedComponentAndSummary}/>;
}
