import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import type { CertificateResource } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import { useState } from "react";
import * as React from "react";
import { useAnalyticTrackedActionDispatch, useCorrelationId } from "~/analytics/Analytics";
import type { CertificateStateTypes } from "~/areas/library/components/Certificates/Certificate/CertificatePage";
import { CertificateInternal } from "~/areas/library/components/Certificates/Certificate/CertificatePage";
import { createSaveCertificateAnalyticsEvent } from "~/areas/library/components/Certificates/Certificate/amplitudeAnalytics";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import useLocalStorage from "~/hooks/useLocalStorage";
import { DrawerWithForm } from "~/primitiveComponents/dataDisplay/DrawerWithForm/DrawerWithForm";
import StringHelper from "~/utils/StringHelper/index";
interface FormState {
    model: CertificateResource | undefined;
    cleanModel: CertificateResource | undefined;
    busy: Promise<void> | undefined;
    errors?: Errors;
}
export interface CertificateDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (feed: CertificateResource) => void;
}
export const CertificateDrawer = ({ isOpen, onClose, onSave }: CertificateDrawerProps) => {
    const [state, setState] = React.useState<CertificateStateTypes>({
        state: "create",
    });
    const [calloutDismissed, setCalloutDismissed] = useLocalStorage(`Octopus.Callout.Drawer.Certificate.Dismissed`, false);
    const [formState, setFormState] = React.useState<FormState>({
        busy: undefined,
        model: undefined,
        errors: undefined,
        cleanModel: undefined,
    });
    const [actions, setActions] = useState<ReactNode[]>();
    const trackAction = useAnalyticTrackedActionDispatch();
    const trackEvent = useTrackEvent();
    const correlationId = useCorrelationId();
    const onStateTransition = (currentState: CertificateStateTypes | null, newState: CertificateStateTypes) => {
        setState((prevState) => {
            if (prevState.state !== "new" && newState.state === "new" && newState.certificate) {
                trackEvent(createSaveCertificateAnalyticsEvent());
                onSave(newState.certificate);
            }
            return { ...newState };
        });
        const mappedActions: ReactNode[] = [<ActionButton label="Cancel" onClick={onClose} type={ActionButtonType.Secondary}/>];
        if (newState.secondaryActions) {
            newState.secondaryActions.map((action) => {
                if (action.type === "button")
                    mappedActions.push(<ActionButton onClick={() => action.onClick(undefined)} label={action.label} type={ActionButtonType.Secondary}/>);
            });
        }
        const primaryAction = newState.primaryAction;
        if (primaryAction?.type === "button") {
            mappedActions.push(<ActionButton onClick={() => primaryAction.onClick(undefined)} label={primaryAction.label} type={ActionButtonType.Primary}/>);
        }
        setActions(mappedActions);
    };
    const onCloseCallout = () => {
        setCalloutDismissed(true);
    };
    const title = state.state === "create" ? "New Certificate" : formState.model ? formState.model.Name : StringHelper.ellipsis;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spaceId = formState.model?.SpaceId ?? repository.spaceId!;
    return (<DrawerWithForm isOpen={isOpen} onClose={onClose} title={title} busy={formState.busy} errors={formState.errors} onSubmit={() => {
            if (state.primaryAction?.type === "button")
                state.primaryAction.onClick(undefined);
        }} expandAllOnMount={true} showExpandAll={true} expandableContainerKey={"CertificateDrawer"} variant="customActions" customActions={actions} correlationId={correlationId}>
            {!calloutDismissed && state.state === "create" ? (<Callout type={"information"} title="Finalise certificate" hideTitle={false} canClose={true} onClose={onCloseCallout}>
                    <div>
                        After you create this certificate, you can find it in{" "}
                        <InternalLink to={links.certificatesPage.generateUrl({ spaceId })} openInSelf={false}>
                            Certificates
                        </InternalLink>
                    </div>
                </Callout>) : (<></>)}
            <CertificateInternal certificateId={undefined} onFormStateChanged={setFormState} onStateTransition={onStateTransition} create={true} trackAction={trackAction} correlationId={correlationId}/>
        </DrawerWithForm>);
};
