export class UniqueSequenceGenerator {
    private currentValue: number;
    constructor() {
        this.currentValue = 0;
    }
    next() {
        this.currentValue++;
        return this.currentValue;
    }
}
