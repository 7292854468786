/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RadioButton } from "@octopusdeploy/design-system-components";
import type { DockerStopActionProperties } from "@octopusdeploy/legacy-action-properties";
import { ActionExecutionLocation } from "@octopusdeploy/octopus-server-client";
import { flatten } from "lodash";
import * as React from "react";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { StepMultiSelect } from "~/components/MultiSelect/StepMultiSelect";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/BoundRadioButtonGroup";
import StringCheckbox from "../../../primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "../../../primitiveComponents/form/Note/Note";
import ParseHelper from "../../../utils/ParseHelper/ParseHelper";
import KeyValueItemList from "../../EditList/KeyValueEditList";
import ExpandableFormSection from "../../form/Sections/ExpandableFormSection";
import ExpanderSectionHeading from "../../form/Sections/FormSectionHeading";
import type { SummaryNode } from "../../form/Sections/Summary";
import Summary from "../../form/Sections/Summary";
import { VariableLookupText } from "../../form/VariableLookupText";
import Roles from "../Roles";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
class DockerStopActionSummary extends BaseComponent<ActionSummaryProps> {
    render() {
        return (<div>
                Stop a Docker Resource
                {this.props.targetRolesAsCSV && (<span>
                        {" "}
                        on deployment targets with <Roles rolesAsCSV={this.props.targetRolesAsCSV}/>
                    </span>)}
            </div>);
    }
    static displayName = "DockerStopActionSummary";
}
interface DockerStopActionState {
    name: string;
    steps: any;
}
class DockerStopAction extends BaseComponent<ActionEditProps<DockerStopActionProperties>, DockerStopActionState> {
    constructor(props: ActionEditProps<DockerStopActionProperties>) {
        super(props);
        this.state = {
            name: "XX",
            steps: [],
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            if (this.props.getProcessResource) {
                const process = this.props.getProcessResource();
                const actions = flatten(process.Steps.map((step, stepIndex) => {
                    return step.Actions.map((action, actionIndex) => {
                        let num = "" + stepIndex + 1;
                        if (step.Actions.length > 1) {
                            num = num + "." + (actionIndex + 1);
                        }
                        return {
                            ...action,
                            NumberedName: num + ". " + action.Name,
                        };
                    });
                })).filter((action) => action.ActionType === "Octopus.DockerNetwork" || action.ActionType === "Octopus.DockerRun");
                this.setState({ steps: actions });
            }
        });
    }
    render() {
        const selectedStopAndRemove = this.props.properties["Octopus.Action.Docker.Remove"] && this.props.properties["Octopus.Action.Docker.Remove"] === "True";
        return (<div>
                <ExpanderSectionHeading title="Rules"/>
                <ExpandableFormSection errorKey="Octopus.Action.Docker.Remove" isExpandedByDefault={this.props.expandedByDefault} title="Perform" summary={this.summaryRemove()} help="Action to take place.">
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={""} value={this.props.properties["Octopus.Action.Docker.Remove"] || "False"} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.Remove"]: val })}>
                        <RadioButton value={"False"} label={"Stop"} isDefault={true}/>
                        <RadioButton value={"True"} label={"Stop and remove"}/>
                    </BoundRadioButtonGroup>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Docker.RemoveSteps" isExpandedByDefault={this.props.expandedByDefault} title="From Steps" summary={this.summarySteps()} help={`Resources created from these Docker steps should be ${selectedStopAndRemove ? "stopped and removed" : "stopped"}.`}>
                    <StepMultiSelect items={this.state.steps} label="Docker steps" value={ParseHelper.parseCSV(this.props.properties["Octopus.Action.Docker.RemoveSteps"])} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.RemoveSteps"]: ParseHelper.encodeCSV(val) })}/>
                    <Note>
                        Note that leaving blank will {selectedStopAndRemove ? "stop and remove" : "stop"} resources created from <em>all</em> docker steps and therefore may {selectedStopAndRemove ? "stop and remove" : "stop"} resources from the same
                        release if this step runs after other Docker steps.
                    </Note>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Docker.RemoveByEnvironment|Octopus.Action.Docker.RemoveByRelease|Octopus.Action.Docker.RemoveByTenant" isExpandedByDefault={this.props.expandedByDefault} title="Resources Matching" summary={this.summaryMatches()} help="Containers created though Octopus are tagged with the relevant context to allow the following selection during this stop process.">
                    <StringCheckbox value={"True"} onChange={(x) => undefined} disabled={true} label="Project" note={<span>Resources will be removed where they match the current project. This cannot be disabled.</span>}/>
                    <br />
                    <StringCheckbox value={this.props.properties["Octopus.Action.Docker.RemoveByEnvironment"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Docker.RemoveByEnvironment"]: x })} label="Environment" note={<span>Resources will be removed only where they also match the current environment.</span>}/>
                    <br />
                    <StringCheckbox value={this.props.properties["Octopus.Action.Docker.RemoveByRelease"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Docker.RemoveByRelease"]: x })} label="Release" note={<span>Resources will be removed only where they also match the current release.</span>}/>
                    <br />
                    <StringCheckbox value={this.props.properties["Octopus.Action.Docker.RemoveByTenant"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.Docker.RemoveByTenant"]: x })} label="Tenant (if applicable)" note={<span>If the release includes tenants then resources will be removed only where they also match the currently deploying tenant.</span>}/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Docker.RemoveCustomTags" isExpandedByDefault={this.props.expandedByDefault} title="Custom Tag Filters" summary={this.summaryTags()} help={<span>
                            These tags will be included when searching for resources using the <code>--filter "label=X"</code> argument. If the value is not provided, the resource will be included so long as the tag is present with any value
                        </span>}>
                    <KeyValueItemList items={this.props.properties["Octopus.Action.Docker.RemoveCustomTags"]} separator="=" name="Custom tag" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.RemoveCustomTags"]: val })} keyLabel="Label" valueLabel="Value"/>
                </ExpandableFormSection>

                <ExpandableFormSection errorKey="Octopus.Action.Docker.StopTimeout" isExpandedByDefault={this.props.expandedByDefault} title="Timeout (sec)" summary={this.propertySummary("Octopus.Action.Docker.StopTimeout", "No timeout value supplied")} help={<span>
                            Seconds to wait for process to stop before killing it (Docker default 10) . The main process inside the container will receive <code>SIGTERM</code>, and after a grace period, <code>SIGKILL</code>.
                        </span>}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.Docker.StopTimeout"]} label="Timeout (sec)" placeholder="10" onChange={(val) => this.props.setProperties({ ["Octopus.Action.Docker.StopTimeout"]: val })}/>
                </ExpandableFormSection>
            </div>);
    }
    private summaryTags() {
        const tags = JSON.parse(this.props.properties["Octopus.Action.Docker.RemoveCustomTags"] || "{}");
        if (Object.keys(tags).length === 0) {
            return Summary.placeholder("No custom tags specified");
        }
        else {
            const text = Object.keys(tags)
                .map((m) => m + " = " + tags[m])
                .join(", ");
            return Summary.summary(text);
        }
    }
    private summarySteps() {
        const removeStepIdsString = this.props.properties["Octopus.Action.Docker.RemoveSteps"];
        if (removeStepIdsString) {
            const removeStepsCount = removeStepIdsString.split(",").length;
            return Summary.summary(`${removeStepsCount} ${removeStepsCount === 1 ? "step has" : "steps have"} been selected`);
        }
        return Summary.placeholder("No steps have been selected");
    }
    private summaryMatches() {
        const matches = [];
        if ((this.props.properties["Octopus.Action.Docker.RemoveByEnvironment"] || "false").toLowerCase() === "true") {
            matches.push("environment");
        }
        if ((this.props.properties["Octopus.Action.Docker.RemoveByRelease"] || "false").toLowerCase() === "true") {
            matches.push("release");
        }
        if ((this.props.properties["Octopus.Action.Docker.RemoveByTenant"] || "false").toLowerCase() === "true") {
            matches.push("tenant (if applicable)");
        }
        if (matches.length === 0) {
            return Summary.default("This project");
        }
        return Summary.summary(`The relevant ${matches.join(", ")} and this project`);
    }
    private propertySummary(property: string, defaultValue: any): SummaryNode {
        const subnet = (this.props.properties as any)[property];
        return subnet ? Summary.summary(subnet) : Summary.placeholder(defaultValue);
    }
    private summaryRemove() {
        const remove = this.props.properties["Octopus.Action.Docker.Remove"];
        if (!remove || remove === "False") {
            return Summary.default("Stop");
        }
        else if (remove === "True") {
            return Summary.summary("Stop and Remove");
        }
        return Summary.summary(remove);
    }
    static displayName = "DockerStopAction";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.DockerStop",
    summary: (properties, targetRolesAsCSV) => <DockerStopActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    editSections: { default: DockerStopAction },
});
