import * as React from "react";
import type { IngressRule } from "~/components/Actions/kubernetes/kubernetesIngressComponent";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import SortableList from "~/primitiveComponents/dataDisplay/SortableList/SortableList";
interface IngressRuleSorterFormProps {
    title: string;
    ingressRules: IngressRule[];
    saveDone(ingressRules: IngressRule[]): void;
}
interface IngressRuleSorterFormState extends DataBaseComponentState {
    ingressRules: IngressRule[];
}
class IngressRuleSorter extends DataBaseComponent<IngressRuleSorterFormProps, IngressRuleSorterFormState> {
    constructor(props: IngressRuleSorterFormProps) {
        super(props);
        this.state = {
            ingressRules: props.ingressRules,
        };
    }
    render() {
        const ingressRules = this.state.ingressRules;
        const body = ingressRules && <SortableList onOrderChanged={this.rulesOrderChanged} items={ingressRules}/>;
        return (<SaveDialogLayout title={this.props.title} busy={this.state.busy} errors={this.errors} onSaveClick={async () => {
                this.props.saveDone(this.state.ingressRules);
                return true;
            }}>
                {body}
            </SaveDialogLayout>);
    }
    private rulesOrderChanged = (ingressRules: IngressRule[]) => {
        this.setState({ ingressRules });
    };
    static displayName = "IngressRuleSorter";
}
export default IngressRuleSorter;
