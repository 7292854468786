import { HelpIcon, NavigationBar, Tooltip } from "@octopusdeploy/design-system-components";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { usePage } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import configurationSelectors from "~/areas/configuration/reducers/selectors";
import drawerActions from "~/components/ContextualHelpLayout/reducers/actions";
import { isDrawerOpen } from "~/components/ContextualHelpLayout/reducers/index";
import { createHelpPanelAnalyticsEvent } from "~/components/Navbar/analytics/HelpPanelAnalyticsEvent";
export function HelpNavigationActionButton() {
    const dispatch = useDispatch();
    const trackEvent = useTrackEvent();
    const page = usePage();
    const drawerOpen = useSelector(isDrawerOpen);
    const toggleDrawer = useCallback(() => {
        trackEvent(createHelpPanelAnalyticsEvent(page, drawerOpen));
        dispatch(drawerActions.toggleDrawer());
    }, [dispatch, drawerOpen, page, trackEvent]);
    const isHelpSidebarEnabled = useSelector(configurationSelectors.createFeatureEnabledSelector((x) => x.isHelpSidebarEnabled));
    if (!isHelpSidebarEnabled) {
        return null;
    }
    return (<Tooltip content="Help">
            <NavigationBar.IconButton onClick={toggleDrawer} accessibleName={drawerOpen ? "Hide help" : "Show help"} aria-expanded={drawerOpen} icon={<HelpIcon />}/>
        </Tooltip>);
}
