import type { Url } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import React from "react";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import ErrorPanel from "~/components/ErrorPanel/index";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { RedirectAs404 } from "~/components/NotFound/NotFound";
import type { RedirectTarget } from "~/routing/legacyRouteRegistrations/createLegacyRouteRegistrations";
export interface AsyncInternalRedirectProps {
    loader: () => RedirectTarget;
    renderWhenLoading: ReactNode;
}
export class AsyncInternalRedirect extends DataBaseComponent<AsyncInternalRedirectProps, {
    redirectionTarget: string | Url | null | typeof notFoundRedirect;
}> {
    constructor(props: AsyncInternalRedirectProps) {
        super(props);
        this.state = { redirectionTarget: null };
    }
    componentDidMount() {
        this.doBusyTask(async () => {
            const redirectionTarget = await this.props.loader();
            this.setState({ redirectionTarget: redirectionTarget ?? notFoundRedirect });
        });
    }
    render() {
        if (this.state.redirectionTarget === null) {
            const errors = this.errors;
            if (errors) {
                return <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>;
            }
            else {
                return this.props.renderWhenLoading;
            }
        }
        if (this.state.redirectionTarget === notFoundRedirect) {
            return <RedirectAs404 />;
        }
        return <InternalRedirect push={false} to={this.state.redirectionTarget}/>;
    }
    static displayName = "AsyncInternalRedirect";
}
const notFoundRedirect = Symbol();
