/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Callout } from "@octopusdeploy/design-system-components";
import type { BreadcrumbItem, PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { ActivityElement, ArtifactResource, GitRefResource, InterruptionResource, ISnapshotResource, KubernetesTaskResourceStatusResource, OctopusSpaceRepository, OctopusSystemRepository, ReleaseResource, ResourceCollection, RunbookResource, RunbookSnapshotResource, SpaceResource, TaskDetailsResource, TaskResource, } from "@octopusdeploy/octopus-server-client";
import { isReleaseResource, isRunbookSnapshotResource, Permission, ProjectContextRepository, Repository, TaskName, TaskState as TaskStateEnum, toGitBranchWhenUnknown } from "@octopusdeploy/octopus-server-client";
import type { LinkHref, TaskDateFilterType } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { Environment } from "@octopusdeploy/utilities";
import _, { memoize } from "lodash";
import moment from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { ContainersFeedbackCallout } from "~/areas/ContainersFeedbackCallout";
import { GitRefChip } from "~/areas/projects/components/Releases/GitRefChip/GitRefChip";
import { PublishSnapshotDialogLayout } from "~/areas/projects/components/Runbooks/PublishSnapshotDialogLayout/PublishSnapshotDialogLayout";
import RetryRunbookRunDialogLayout from "~/areas/projects/components/Runbooks/RetryRunbookRunDialogLayout/RetryRunbookRunDialogLayout";
import { RunbookSnapshotInfo } from "~/areas/projects/components/Runbooks/RunbookSnapshots/RunbookSnapshotInfo";
import { TaskStatusIcon } from "~/areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import type { WithOptionalProjectContextInjectedProps } from "~/areas/projects/context/index";
import { useOptionalProjectContext } from "~/areas/projects/context/index";
import { AdHocScriptTaskSummary } from "~/areas/tasks/components/Task/AdHocScriptTaskSummary";
import BuildInformation from "~/areas/tasks/components/Task/BuildInformation/BuildInformation";
import Changes from "~/areas/tasks/components/Task/Changes/Changes";
import KubernetesDeploymentStatus from "~/areas/tasks/components/Task/K8sStatus/KubernetesDeploymentStatus";
import retrieveStepsWithKubernetesActions from "~/areas/tasks/components/Task/K8sStatus/retrieveStepsWithKubernetesActions";
import { TaskHistory } from "~/areas/tasks/components/Task/TaskHistory/TaskHistory";
import TaskLog from "~/areas/tasks/components/Task/TaskLog/TaskLog";
import { activityElementIdFromUniqueId, activityElementUniqueIdFromId, getSelectedLogLine, isVerbose, toggleVerbose } from "~/areas/tasks/components/Task/TaskLog/TaskLogUtil";
import { client, repository } from "~/clientInstance";
import { RunbookSnapshotPublishedChip } from "~/components/Chips";
import type { DataBaseComponentState, Refresh } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import InternalLink from "~/components/Navigation/InternalLink";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import { createGlobalRequestContext } from "~/globalRequestContext";
import { UrlNavigationTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import TabItem from "~/primitiveComponents/navigation/Tabs/TabItem";
import { Level2PageLayout } from "~/routing/pageRegistrations/Level2PageLayout";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import ModifyTaskStateDialog from "./ModifyTaskStateDialog";
import TaskSummary from "./TaskSummary/TaskSummary";
export enum BuiltInTask {
    Deploy = "Deploy",
    RunbookRun = "RunbookRun",
    ExportProjects = "ExportProjects",
    ImportProjects = "ImportProjects",
    BulkDeployment = "BulkDeployment"
}
interface TaskState extends DataBaseComponentState {
    task?: TaskResource<any>;
    taskSpace?: SpaceResource;
    taskScopedRepository?: OctopusSpaceRepository | OctopusSystemRepository;
    kubernetesOctopusStatus: KubernetesTaskResourceStatusResource;
    stepsWithKubernetesActions: string[];
    taskDetails?: TaskDetailsResource;
    artifacts?: ArtifactResource[];
    interruptions?: ResourceCollection<InterruptionResource>;
    activityElements?: UniqueActivityElement[];
    verbose: boolean;
    tail: boolean;
    reloads: number;
    cancelPending: boolean;
    moveToTopPending: boolean;
    redirectTo?: LinkHref;
    breadcrumbTitle?: string;
    breadcrumbPath?: LinkHref;
    breadcrumbsItems?: BreadcrumbItem[];
    hasLoadedOnce?: boolean;
    snapshot: ISnapshotResource;
    changesMarkdown: string;
    runbook?: RunbookResource;
    taskDeploymentActionTypes: string[];
    processId?: string;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    gitRef?: GitRefResource;
    ranges: ActivityLogOptionsRange[];
    isFetchDisabled: boolean;
    initialTab: string;
    manuallyExpandedIds: string[] | undefined;
}
interface ActivityLogOptionsRange {
    id: string;
    start: number;
    end: number;
}
interface TaskComponentProps {
    task?: TaskResource<any>;
    taskId: string;
    projectId?: string;
    tenantId?: string;
    environmentId?: string;
    getPrimaryPageAction?(Task: TaskResource<any>): PrimaryPageAction | undefined;
    getAdditionalPageActions?(Task: TaskResource<any>): PageAction[];
    additionalRefresh?(Task: TaskResource<any>): Promise<void>;
    delayRender(): boolean;
    defaultBreadcrumbItems?: BreadcrumbItem[];
    showBreadcrumbBackIcon?: boolean;
}
type TaskProps = TaskComponentProps & RouteComponentProps<any> & WithOptionalProjectContextInjectedProps;
type InternalTaskProps = TaskProps & {
    dispatchAction: AnalyticActionDispatcher;
    isVerticalNavigationEnabled: boolean;
    publishRunbookDialogControls: DialogControls;
    retryRunbookDialogControls: DialogControls;
};
const statesThatCanBeModified = [TaskStateEnum.Success, TaskStateEnum.Failed, TaskStateEnum.Canceled];
class InternalTask extends DataBaseComponent<InternalTaskProps, TaskState> {
    private timeLastRefreshTookInSeconds: number = 0;
    /**
     * Use memoization to remove unnecessary network calls as the UI is updated.
     * This allows us to regenerate the state by excluding or including machines,
     * and not call back to the server for deployment process info that will not
     * have changed.
     */
    repositoryDeploymentProcessesGet = memoize((release: ReleaseResource) => this.props.projectContext!.state.projectContextRepository.DeploymentProcesses.getForRelease(release));
    repositoryRunbookProcessGet = memoize((id: string, taskSpaceScopedRepository: OctopusSpaceRepository) => taskSpaceScopedRepository.RunbookProcess.get(id));
    constructor(props: InternalTaskProps) {
        super(props);
        this.state = {
            reloads: 0,
            verbose: isVerbose(this.props),
            tail: true,
            cancelPending: false,
            moveToTopPending: false,
            snapshot: null!,
            changesMarkdown: null!,
            taskDeploymentActionTypes: [],
            kubernetesOctopusStatus: { KubernetesStepsStatus: [] },
            stepsWithKubernetesActions: [],
            processId: undefined,
            stepsCorrelationIds: undefined,
            ranges: this.getSelectedRanges(),
            isFetchDisabled: false,
            initialTab: "taskSummary",
            manuallyExpandedIds: undefined,
        };
    }
    getSelectedRanges(): ActivityLogOptionsRange[] {
        const selection = getSelectedLogLine(this.props);
        if (selection) {
            return [
                {
                    start: selection.line < 50 ? 0 : selection.line - 50,
                    end: selection.line + 50,
                    id: activityElementIdFromUniqueId(selection.elementUniqueId),
                },
            ];
        }
        return [];
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = this.props.task ? this.props.task : await repository.Tasks.get(this.props.taskId, { spaces: ["all"] });
            const taskScopedRepository = task.SpaceId ? await repository.forSpace(task.SpaceId) : repository.forSystem();
            const taskSpace = task.SpaceId ? await repository.Spaces.get(task.SpaceId) : undefined;
            this.setState({
                task,
                taskSpace,
                taskScopedRepository,
            }, () => this.doBusyTask(async () => {
                this.doRefresh = await this.startRefreshLoop(() => this.refresh(this.state.verbose, this.state.ranges), this.getRefreshInterval, false, timeOperationOptions.forRefresh());
            }));
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    isDeploymentTask = () => this.state.task?.Name === BuiltInTask.Deploy && this.state.task.Arguments.DeploymentId === this.props.match.params.deploymentId;
    isRunbookTask = () => this.state.task?.Name === BuiltInTask.RunbookRun && this.state.task.Arguments.RunbookRunId === this.props.match.params.runbookRunId;
    isGitRunbookTask = () => isRunbookSnapshotResource(this.state.snapshot) && this.state.snapshot.GitReference?.GitCommit;
    async refresh(verbose: boolean, ranges: ActivityLogOptionsRange[]) {
        const startTime = moment();
        const { task, taskScopedRepository } = this.state;
        if (task === undefined || taskScopedRepository === undefined) {
            throw new Error("Refreshing task failed as the initial data for the task was not loaded, this should never happen.");
        }
        this.setState({ verbose, ranges, reloads: this.state.reloads + 1, isFetchDisabled: true });
        const rangeStrings = ranges.map((r) => [r.id, r.start, r.end].join(":"));
        const taskDetailArgs = { verbose, tail: 50, ranges: rangeStrings.join("|") };
        const taskDetailsPromise = taskScopedRepository.Tasks.details(task, taskDetailArgs);
        const interruptionsPromise = this.isTaskSpaceScopedRepository(taskScopedRepository) &&
            isAllowed({
                permission: Permission.InterruptionViewSubmitResponsible,
                project: this.props.projectId,
                environment: this.props.environmentId,
                tenant: this.props.tenantId,
            }, this.state.task?.SpaceId ? { spaceId: this.state.task.SpaceId } : "system")
            ? taskScopedRepository.Interruptions.list({ regarding: this.props.taskId })
            : Promise.resolve([] as any as ResourceCollection<InterruptionResource>);
        const taskDetails = await taskDetailsPromise;
        const activityElements = taskDetails.ActivityLogs.map((e: ActivityElement, n: number) => this.setIdPrefix(e, n));
        // Only supply breadcrumbs if this is a deployment task (as it will be redirected to the project area where it needs a breadcrumb).
        let snapshot: ReleaseResource | RunbookSnapshotResource | undefined;
        let breadcrumbTitle: string | undefined;
        let breadcrumbPath: LinkHref | undefined;
        let breadcrumbsItems: BreadcrumbItem[] | undefined;
        let kubernetesStatusUpdatePromise: Promise<KubernetesTaskResourceStatusResource> = Promise.resolve({ KubernetesStepsStatus: [] });
        let stepsWithKubernetesActions: string[] = [];
        const isDeploymentTask = this.isDeploymentTask();
        let changesMarkdown = null;
        // No point retrieving kubernetes status updates more than once for a completed task
        if (this.state.taskDetails?.Task.IsCompleted) {
            kubernetesStatusUpdatePromise = Promise.resolve(this.state.kubernetesOctopusStatus);
        }
        else {
            kubernetesStatusUpdatePromise = this.state.task!.SpaceId ? taskScopedRepository.Tasks.retrieveKubernetesStatusUpdate(this.state.task!) : Promise.resolve({ KubernetesStepsStatus: [] });
        }
        if (isDeploymentTask && this.isTaskSpaceScopedRepository(taskScopedRepository)) {
            const deployment = await taskScopedRepository.Deployments.getDeploymentSummaryForTask(this.props.match.params.deploymentId);
            changesMarkdown = deployment.ChangesMarkdown;
            snapshot = deployment ? await taskScopedRepository.Releases.get(deployment.ReleaseId) : null!;
            if (isReleaseResource(snapshot)) {
                const project = deployment ? await taskScopedRepository.Projects.get(deployment.ProjectId) : null;
                if (project) {
                    try {
                        if (taskDetails.Task.IsCompleted) {
                            let gitRef: GitRefResource | undefined;
                            const snapshotGitRef = toGitBranchWhenUnknown(snapshot.VersionControlReference?.GitRef);
                            if (project.IsVersionControlled === true && !!snapshotGitRef) {
                                gitRef = await taskScopedRepository.Projects.getGitRef(project, snapshotGitRef);
                                this.setState({ gitRef: gitRef });
                            }
                            const projectContextRepository = new ProjectContextRepository(client, project, gitRef, createGlobalRequestContext("ProjectContext"));
                            const deploymentProcess = await projectContextRepository.DeploymentProcesses.get();
                            // Filter step correlation ids by those present in the deployment process
                            const correlationIds: {
                                [key: string]: string;
                            } = {};
                            deployment.ExecutionPlanLogContext.Steps.forEach((context) => {
                                if (deploymentProcess.Steps.some((step) => step.Slug === context.Slug)) {
                                    correlationIds[context.CorrelationId] = context.Slug;
                                }
                            });
                            if (correlationIds) {
                                this.setState({ stepsCorrelationIds: correlationIds });
                            }
                        }
                    }
                    catch (e) {
                        logger.warn("Failed to get deployment process for project", e);
                    }
                }
                breadcrumbTitle = snapshot ? `Release ${snapshot.Version}` : null!;
                breadcrumbPath = snapshot ? links.releasePage.generateUrl({ spaceId: project!.SpaceId, projectSlug: project!.Slug, releaseVersion: snapshot.Version }) : null!;
                breadcrumbsItems = [
                    { label: "Releases", pageUrl: links.releasesPage.generateUrl({ spaceId: project!.SpaceId, projectSlug: project!.Slug }) },
                    { label: snapshot.Version, pageUrl: breadcrumbPath },
                ];
                const process = await this.repositoryDeploymentProcessesGet(snapshot);
                stepsWithKubernetesActions = retrieveStepsWithKubernetesActions(process.Steps);
            }
            const actionTypesBffResponse = await taskScopedRepository.Deployments.getDeploymentProcessActionTypes(deployment.DeploymentProcessId);
            this.setState({ taskDeploymentActionTypes: actionTypesBffResponse.ActionTypes });
        }
        const isRunbookTask = !!(taskDetails.Task.Name === BuiltInTask.RunbookRun && taskDetails.Task.Arguments.RunbookRunId === this.props.match.params.runbookRunId && this.isTaskSpaceScopedRepository(taskScopedRepository));
        let runbook: RunbookResource | undefined;
        const gitRunbooksEnabled = isFeatureToggleEnabled("GitRunbooksFeatureToggle");
        if (isRunbookTask) {
            const runbookRun = await taskScopedRepository.RunbookRuns.get(this.props.match.params.runbookRunId);
            snapshot = runbookRun ? await taskScopedRepository.RunbookSnapshots.get(runbookRun.RunbookSnapshotId) : null!;
            if (gitRunbooksEnabled) {
                const project = await taskScopedRepository.Projects.get(runbookRun.ProjectId);
                const gitRef = snapshot.GitReference?.GitCommit ? snapshot.GitReference.GitRef : undefined;
                runbook = await taskScopedRepository.Runbooks.getRunbook(project, runbookRun.RunbookId, gitRef);
            }
            else {
                runbook = await taskScopedRepository.Runbooks.get(runbookRun.RunbookId);
            }
            if (isRunbookSnapshotResource(snapshot)) {
                const process = await this.repositoryRunbookProcessGet(snapshot.FrozenRunbookProcessId, taskScopedRepository);
                stepsWithKubernetesActions = retrieveStepsWithKubernetesActions(process.Steps);
                const project = runbookRun ? await taskScopedRepository.Projects.get(runbookRun.ProjectId) : null;
                breadcrumbTitle = runbook ? `${runbook.Name}` : null!;
                breadcrumbPath = snapshot ? links.projectRunbookRunsListPage.generateUrl({ spaceId: runbook.SpaceId, projectSlug: project!.Slug, runbookId: runbook.Id }) : null!;
                breadcrumbsItems = [
                    { label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project!.SpaceId, projectSlug: project!.Slug }) },
                    { label: breadcrumbTitle, pageUrl: breadcrumbPath },
                ];
                const correlationIds: {
                    [key: string]: string;
                } = {};
                if (runbookRun.ExecutionPlanLogContext) {
                    runbookRun.ExecutionPlanLogContext.Steps.forEach((context) => {
                        if (process.Steps.some((step) => step.Slug === context.Slug)) {
                            correlationIds[context.CorrelationId] = context.Slug;
                        }
                    });
                }
                if (correlationIds) {
                    this.setState({ processId: runbook.RunbookProcessId, stepsCorrelationIds: correlationIds });
                }
            }
        }
        const artifacts = this.loadArtifactsPromise();
        const result = {
            taskDetails,
            activityElements,
            artifacts: await artifacts,
            interruptions: await interruptionsPromise,
            task: taskDetails.Task,
            breadcrumbTitle: breadcrumbTitle!,
            breadcrumbPath: breadcrumbPath!,
            breadcrumbsItems,
            hasLoadedOnce: true,
            snapshot: snapshot!,
            changesMarkdown: changesMarkdown!,
            runbook: runbook!,
            kubernetesOctopusStatus: await kubernetesStatusUpdatePromise,
            stepsWithKubernetesActions,
        };
        if (this.props.additionalRefresh) {
            await this.props.additionalRefresh(taskDetails.Task);
        }
        this.timeLastRefreshTookInSeconds = moment().diff(startTime, "seconds");
        if (task.State == TaskStateEnum.Executing) {
            // Switch to task log when the task starts. But don't switch back
            this.setState({ initialTab: "taskLog" });
        }
        this.setState({ isFetchDisabled: false });
        return result;
    }
    getRefreshInterval = (hidden: boolean) => {
        if (!this.state.task) {
            return 2000;
        }
        if (this.timeLastRefreshTookInSeconds >= 5) {
            // Refresh time is terrible, back right off
            return hidden ? 120000 : 20000;
        }
        const refreshIsFast = this.timeLastRefreshTookInSeconds < 2;
        const completedRecently = this.state.task.CompletedTime && moment().diff(moment(this.state.task.CompletedTime), "seconds") < 15;
        if (completedRecently && refreshIsFast) {
            // Make sure we get the final logs that get written after the task officially completes
            return hidden ? 10000 : 2000;
        }
        if (this.state.task.IsCompleted) {
            // Keep refreshing after completion in case auto-deploy kicks in or someone else changes that task state
            return hidden ? 60000 : 20000;
        }
        const isQueuedOrStartedRecently = !this.state.task.StartTime || moment().diff(moment(this.state.task.StartTime), "seconds") < 15;
        if (isQueuedOrStartedRecently && refreshIsFast) {
            // Refresh often so the user can see it start and see it finish quickly if it's a short deployment
            return hidden ? 5000 : 1000;
        }
        return hidden ? 30000 : 5000;
    };
    // This is a bit hacky since auto-deploys that kick off from same deployment will have the same task prefix
    setIdPrefix(element: ActivityElement, n: number): UniqueActivityElement {
        return {
            ...element,
            uniqueId: activityElementUniqueIdFromId(element.Id, n),
            Children: element.Children ? element.Children.map((c) => this.setIdPrefix(c, n)) : null!,
        };
    }
    setVerbose = (value: boolean) => {
        toggleVerbose(this.props, value);
        this.setState({ verbose: value }, async () => this.doRefresh());
    };
    recurseCountLogs(elements?: ActivityElement[]): number {
        if (!elements) {
            return 0;
        }
        return _.sum(elements.map((a) => a.LogElements.length + this.recurseCountLogs(a.Children)));
    }
    fetchRange = (element: ActivityElement, start: number, end: number) => {
        let ranges = this.state.ranges.slice();
        ranges.push({
            start: start,
            end: end,
            id: element.Id,
        });
        // Around 10,000 logs and the UX starts to freeze up
        // So above that, keep only the most recently selected range
        // But allow them to have at least 2 ranges open even if that pushes us past 10K (so they can compare logs)
        if (this.recurseCountLogs(this.state.taskDetails?.ActivityLogs) > 10000 && ranges.length > 2) {
            ranges = [ranges[ranges.length - 1]];
        }
        this.setState({ ranges }, async () => this.doRefresh());
    };
    isAlreadyPublished = () => {
        const runbook = this.state.runbook;
        const snapshot = this.state.snapshot;
        if (!runbook || !snapshot) {
            return false;
        }
        return runbook.PublishedRunbookSnapshotId === snapshot.Id;
    };
    isTaskSpaceScopedAndCompleted = () => {
        const task = this.state.task;
        const taskScopedRepository = this.state.taskScopedRepository;
        return task && task.IsCompleted && this.isTaskSpaceScopedRepository(taskScopedRepository);
    };
    isRunbookSnapshotExist = () => this.state.runbook && this.state.snapshot;
    hasEditRunbookPermission = () => isAllowed({ permission: Permission.RunbookEdit, project: this.props.projectId, wildcard: true }, this.state.task?.SpaceId ? { spaceId: this.state.task.SpaceId } : "system");
    canPublishRunbook = () => this.isRunbookSnapshotExist() && this.hasEditRunbookPermission() && !this.isAlreadyPublished() && !this.state.snapshot.GitReference?.GitCommit;
    renderEditStateButton = () => {
        const task = this.state.task;
        if (!task!.IsCompleted || statesThatCanBeModified.indexOf(task!.State) === -1) {
            return null;
        }
        return OverflowMenuItems.dialogItem("Edit state", <ModifyTaskStateDialog availableStates={statesThatCanBeModified} currentTaskState={task!.State} onStateChanged={this.changeTaskState}/>, {
            permission: Permission.TaskEdit,
            project: this.props.projectId,
            environment: this.props.environmentId,
            tenant: "*",
            spaceScope: task?.SpaceId ? { spaceId: task.SpaceId } : "system",
        });
    };
    renderTryAgainButton = () => {
        const task = this.state.task!;
        if (!task.IsCompleted) {
            return null;
        }
        if (isFeatureToggleEnabled("GitRunbooksFeatureToggle") && this.isGitRunbookTask() && task.IsCompleted && this.state.snapshot) {
            return OverflowMenuItems.item("Re-run", this.props.retryRunbookDialogControls.openDialog);
        }
        if (task.Name === TaskName.AdHocScript) {
            if (task.Arguments.ActionTemplateId) {
                const path = links.runStepTemplatePage.generateUrl({ spaceId: task.SpaceId!, templateId: task.Arguments.ActionTemplateId }, { retry: task.Id });
                return OverflowMenuItems.navItem("Modify and re-run", path);
            }
            else {
                return OverflowMenuItems.navItem("Modify and re-run", links.scriptConsolePage.generateUrl({ retry: task.Id }));
            }
        }
        else if (task.Name === TaskName.BulkDeployment) {
            return OverflowMenuItems.item(task.FinishedSuccessfully ? "Re-run" : "Try again", this.rerun, {
                permission: Permission.DeploymentCreate,
                project: "*",
                environment: "*",
                tenant: "*",
                spaceScope: task?.SpaceId ? { spaceId: task.SpaceId } : "system",
            });
        }
        else if (task.CanRerun) {
            return OverflowMenuItems.item(task.FinishedSuccessfully ? "Re-run" : "Try again", this.rerun, {
                permission: Permission.TaskCreate,
                project: this.props.projectId,
                environment: this.props.environmentId,
                tenant: "*",
                spaceScope: task?.SpaceId ? { spaceId: task.SpaceId } : "system",
            });
        }
        else {
            return this.renderRedeployButton();
        }
    };
    renderRedeployButton = () => {
        const isRelease = isReleaseResource(this.state.snapshot);
        const deployToLabel = isRelease ? "Re-deploy..." : "Re-run...";
        const executePermission = isRelease ? Permission.DeploymentCreate : Permission.RunbookRunCreate;
        const routePath = isRelease
            ? links.createDeploymentPage.generateUrl({ spaceId: this.state.snapshot.SpaceId, projectSlug: this.state.snapshot.ProjectId, releaseVersion: this.state.snapshot.Id }, { previousDeploymentId: this.state.taskDetails!.Task.Arguments.DeploymentId })
            : links.createRunbookRunForSnapshotPage.generateUrl({ spaceId: this.state.snapshot.SpaceId, projectSlug: this.state.snapshot.ProjectId, runbookId: this.state.runbook!.Id, runbookSnapshotId: this.state.snapshot.Id }, { previousRunbookRunId: this.state.taskDetails!.Task.Arguments.RunbookRunId });
        return OverflowMenuItems.navItem(deployToLabel, routePath, { permission: executePermission, project: this.state.snapshot.ProjectId, wildcard: true, spaceScope: this.state.task?.SpaceId ? { spaceId: this.state.task.SpaceId } : "system" });
    };
    renderDeployToButton = () => {
        const canRenderDeployTo = this.state.snapshot && this.state.taskDetails && !this.state.taskDetails.Task.HasPendingInterruptions && this.state.taskDetails.Task.State !== TaskStateEnum.Executing && !this.isGitRunbookTask();
        if (!canRenderDeployTo) {
            return null;
        }
        const isRelease = isReleaseResource(this.state.snapshot);
        const deployToLabel = isRelease ? "Deploy to..." : "Run on...";
        const executePermission = isRelease ? Permission.DeploymentCreate : Permission.RunbookRunCreate;
        const routePath = isRelease
            ? links.createDeploymentPage.generateUrl({ spaceId: this.state.snapshot.SpaceId, projectSlug: this.state.snapshot.ProjectId, releaseVersion: this.state.snapshot.Id })
            : links.createRunbookRunForSnapshotPage.generateUrl({ spaceId: this.state.snapshot.SpaceId, projectSlug: this.state.snapshot.ProjectId, runbookId: this.state.runbook!.Id, runbookSnapshotId: this.state.snapshot.Id });
        return OverflowMenuItems.navItem(deployToLabel, routePath, { permission: executePermission, project: this.state.snapshot.ProjectId, wildcard: true, spaceScope: this.state.task?.SpaceId ? { spaceId: this.state.task.SpaceId } : "system" });
    };
    renderDeleteExecutionButton = () => {
        const canRenderDeleteDeploymentButton = !!(this.state.snapshot && this.state.taskDetails && this.state.taskDetails.Task.Name === BuiltInTask.Deploy && this.state.taskDetails.Task.Arguments.DeploymentId);
        const canRenderDeleteRunbookRunButton = !!(this.state.snapshot && this.state.taskDetails && this.state.taskDetails.Task.Name === BuiltInTask.RunbookRun && this.state.taskDetails.Task.Arguments.RunbookRunId);
        if (!canRenderDeleteDeploymentButton && !canRenderDeleteRunbookRunButton) {
            return null;
        }
        const isRelease = isReleaseResource(this.state.snapshot);
        const deletePermission = isRelease ? { permission: Permission.DeploymentDelete } : { permission: Permission.RunbookEdit, project: this.props.projectId, wildcard: true, spaceId: this.state.task?.SpaceId };
        return OverflowMenuItems.deleteItem(`Delete ${isRelease ? "deployment" : "run"}...`, `Are you sure you want to delete this ${isRelease ? "deployment" : "run"}?`, this.handleDeleteConfirm, <div>
                <p>Deleting this {isRelease ? "deployment" : "run"} is permanent. There is no going back.</p>
                <p>Do you wish to continue?</p>
            </div>, deletePermission);
    };
    renderViewPreviousDeploymentsButton = () => {
        const task = this.state.task;
        const projectContext = this.props.projectContext;
        if (!projectContext || !task || !task.SpaceId) {
            return null;
        }
        if (!this.isDeploymentTask()) {
            return null;
        }
        let dateFilterParams: {
            dateFilterType?: TaskDateFilterType;
            fromDate?: Date;
            toDate?: Date;
        } = {};
        if (task.StartTime) {
            dateFilterParams = this.createDateFilterParams(task.StartTime, "Start Time");
        }
        else if (task.QueueTime) {
            dateFilterParams = this.createDateFilterParams(task.QueueTime, "Queue Time");
        }
        return OverflowMenuItems.navItem("View previous deployments", links.projectTasksPage.generateUrl({ spaceId: task.SpaceId, projectSlug: projectContext.state.model.Slug }, { ...dateFilterParams, spaces: [task.SpaceId], environment: this.props.environmentId, name: BuiltInTask.Deploy, tenant: this.props.tenantId }));
    };
    createDateFilterParams = (taskInitialTime: string, type: TaskDateFilterType) => {
        const toDate = new Date(taskInitialTime);
        toDate.setSeconds(toDate.getSeconds() - 1); // Don't include the current task in the filtered tasks list
        const fromDate = new Date(toDate);
        fromDate.setMonth(toDate.getMonth() - 6);
        return { dateFilterType: type, fromDate, toDate };
    };
    handleDeleteConfirm = async () => {
        if (!this.isTaskSpaceScopedRepository(this.state.taskScopedRepository)) {
            return true;
        }
        if (isReleaseResource(this.state.snapshot)) {
            const deployment = await this.state.taskScopedRepository.Deployments.get(this.state.taskDetails!.Task.Arguments.DeploymentId);
            await this.state.taskScopedRepository.Deployments.del(deployment);
            this.setState({ redirectTo: links.releaseRedirect.generateUrl({ spaceId: this.state.snapshot.SpaceId, releaseId: this.state.snapshot.Id }) });
        }
        else if (isRunbookSnapshotResource(this.state.snapshot)) {
            const runbookRun = await this.state.taskScopedRepository.RunbookRuns.get(this.state.taskDetails!.Task.Arguments.RunbookRunId);
            await this.state.taskScopedRepository.RunbookRuns.del(runbookRun);
            this.setState({
                redirectTo: links.projectRunbookSnapshotInfoPage.generateUrl({ spaceId: this.state.snapshot.SpaceId, projectSlug: this.state.snapshot.ProjectId, runbookId: this.state.snapshot.RunbookId, runbookSnapshotId: this.state.snapshot.Id }),
            });
        }
        return true;
    };
    changeTaskState = async (newTaskState: string, reason: string) => {
        await this.doBusyTask(async () => {
            this.setState({ task: await this.state.taskScopedRepository!.Tasks.changeState(this.state.task!, newTaskState, reason) });
        });
    };
    rerun = async () => {
        await this.doBusyTask(async () => {
            const newTask = await this.state.taskScopedRepository!.Tasks.rerun(this.state.task!);
            this.setState({ redirectTo: links.taskPage.generateUrl({ taskId: newTask.Id }) });
        });
    };
    performMoveToTop = async () => {
        await this.doBusyTask(async () => {
            try {
                this.props.dispatchAction("Move Task to Top of Queue", { resource: "Task", action: Action.Update });
                this.setState({ moveToTopPending: true });
                await this.state.taskScopedRepository!.Tasks.prioritize(this.state.task!.Id);
                await this.doRefresh();
            }
            finally {
                this.setState({ moveToTopPending: false });
            }
        });
    };
    getMoveToTopPageAction = (): null | PageAction => {
        const task = this.state.task!;
        if (task.State !== TaskStateEnum.Queued) {
            return null;
        }
        return {
            type: "button",
            buttonType: "secondary",
            label: "Move to top",
            disabled: this.state.moveToTopPending,
            busyLabel: "Updating...",
            onClick: () => this.performMoveToTop(),
            hasPermissions: isAllowed({ permission: Permission.TaskEdit, project: this.props.projectId, environment: this.props.environmentId, tenant: "*" }, task?.SpaceId ? { spaceId: task.SpaceId } : "system"),
        };
    };
    performCancel = async () => {
        await this.doBusyTask(async () => {
            try {
                this.props.dispatchAction("Cancel task", { resource: "Task", action: Action.Cancel });
                this.setState({ cancelPending: true });
                await this.state.taskScopedRepository!.Tasks.cancel(this.state.task!.Id);
                await this.doRefresh();
            }
            finally {
                this.setState({ cancelPending: false });
            }
        });
    };
    getCancelPageAction = (): null | PageAction => {
        const task = this.state.task!;
        if (task.IsCompleted) {
            return null;
        }
        const isCancelling = this.state.cancelPending || task.State === TaskStateEnum.Cancelling;
        return {
            type: "button",
            buttonType: "destructive",
            label: "Cancel",
            disabled: isCancelling,
            busyLabel: "Cancelling...",
            onClick: this.performCancel,
            hasPermissions: isAllowed({ permission: Permission.TaskCancel, project: this.props.projectId, environment: this.props.environmentId, tenant: "*" }, task?.SpaceId ? { spaceId: task.SpaceId } : "system"),
        };
    };
    renderDevTools() {
        if (Environment.isInDevelopmentMode()) {
            return (<div style={{
                    background: "lightyellow",
                    padding: "10px 20px",
                    color: "black",
                    position: "fixed",
                    bottom: "20px",
                    right: "20px",
                    height: "auto",
                    width: "auto",
                    fontSize: "12px",
                    zIndex: 10000,
                    border: "1px solid black",
                }}>
                    {this.state.taskDetails && <div>Actual log is {(this.state.taskDetails?.PhysicalLogSize / 1024 / 1024).toLocaleString()} MB on disk</div>}
                    <div>Fetched {this.recurseCountLogs(this.state.taskDetails?.ActivityLogs)} logs from server</div>
                    <div>Reloads {this.state.reloads}</div>
                    <div>Last refresh took (s): {this.timeLastRefreshTookInSeconds}</div>
                    <div>
                        Ranges:{" "}
                        {this.state.ranges.map((r, n) => (<span key={n}>
                                {r.start}:{r.end}&nbsp;
                            </span>))}
                    </div>
                </div>);
        }
    }
    renderPublishSnapshotDialog() {
        const runbook = this.state.runbook;
        const snapshot = this.state.snapshot;
        const taskScopedRepository = this.state.taskScopedRepository;
        const task = this.state.task;
        if (!task?.IsCompleted || !this.isTaskSpaceScopedRepository(taskScopedRepository) || !this.canPublishRunbook())
            return null;
        return (<Dialog open={this.props.publishRunbookDialogControls.isOpen}>
                <PublishSnapshotDialogLayout close={this.props.publishRunbookDialogControls.closeDialog} onPublishSnapshotDialogClicked={async () => {
                await this.doBusyTask(async () => {
                    runbook!.PublishedRunbookSnapshotId = snapshot.Id;
                    await taskScopedRepository.Runbooks.modify(runbook!);
                    const path = links.projectRunbookSnapshotInfoPage.generateUrl({ spaceId: runbook!.SpaceId, projectSlug: runbook!.ProjectId, runbookId: runbook!.Id, runbookSnapshotId: snapshot.Id });
                    this.setState({ redirectTo: path });
                });
            }}/>
            </Dialog>);
    }
    renderTitleChip() {
        if (!isRunbookSnapshotResource(this.state.snapshot)) {
            // We only show chips for Runbooks
            return undefined;
        }
        if (this.isAlreadyPublished()) {
            return <RunbookSnapshotPublishedChip noMargin={true}/>;
        }
        if (this.state.snapshot.GitReference?.GitCommit) {
            // This is Git Runbook if GitCommit is not null
            return <GitRefChip vcsRef={this.state.snapshot.GitReference}/>;
        }
        return undefined;
    }
    renderRetryRunbookRunDialog() {
        if (!isFeatureToggleEnabled("GitRunbooksFeatureToggle")) {
            return null;
        }
        const snapshot = this.state.snapshot;
        if (!snapshot) {
            // The snapshot (for tests at least) can be null. If it is, we can't
            // show the dialog anyway, so bail out early.
            return null;
        }
        const taskScopedRepository = this.state.taskScopedRepository;
        const task = this.state.task;
        const project = this.props.projectContext?.state.model;
        const gitRef = snapshot.GitReference?.GitRef;
        if (!task?.IsCompleted || !this.isTaskSpaceScopedRepository(taskScopedRepository) || !this.isRunbookTask() || !this.isGitRunbookTask() || !gitRef || !project)
            return null;
        return (<Dialog open={this.props.retryRunbookDialogControls.isOpen}>
                <RetryRunbookRunDialogLayout close={this.props.retryRunbookDialogControls.closeDialog} onRetryRunbookRunDialogClicked={async () => {
                await this.doBusyTask(async () => {
                    const run = await taskScopedRepository.Runbooks.retryGitRunbook(project, this.props.match.params.runbookRunId);
                    const redirectPath = links.branchProjectRunbookRunDetailPage.generateUrl({
                        spaceId: project.SpaceId,
                        projectSlug: project.Slug,
                        branchName: gitRef,
                        runbookId: run.Resource.RunbookId,
                        runbookSnapshotId: run.Resource.RunbookSnapshotId,
                        runbookRunId: run.Resource.Id,
                    });
                    this.setState({ redirectTo: redirectPath });
                });
            }}/>
            </Dialog>);
    }
    render() {
        const redirectTo = this.state.redirectTo;
        if (redirectTo) {
            return <InternalRedirect to={redirectTo} push={false}/>;
        }
        const selectedElement = this.state.manuallyExpandedIds && this.state.manuallyExpandedIds.length > 0 ? undefined : getSelectedLogLine(this.props)?.elementUniqueId;
        const gitRunbooksEnabled = isFeatureToggleEnabled("GitRunbooksFeatureToggle");
        const details = this.state.taskDetails;
        const task = this.state.task!;
        const canRender = task && details && !this.props.delayRender();
        const overflowMenu = this.getOverflowMenu(canRender!);
        let pageActions: PageAction[] = [];
        if (canRender) {
            const additionalActions = this.props.getAdditionalPageActions ? this.props.getAdditionalPageActions(task) : [];
            if (this.isTaskSpaceScopedAndCompleted() && this.canPublishRunbook()) {
                additionalActions.push({
                    type: "button",
                    label: "Publish",
                    extraContext: "Publishing makes the runbook available to consumers and triggers.",
                    buttonType: "secondary",
                    onClick: this.props.publishRunbookDialogControls.openDialog,
                });
            }
            pageActions = [this.getMoveToTopPageAction(), this.getCancelPageAction(), ...additionalActions].filter((x): x is PageAction => !!x);
        }
        let primaryPageAction: PrimaryPageAction | undefined;
        if (canRender) {
            if (this.props.getPrimaryPageAction?.(task)) {
                primaryPageAction = this.props.getPrimaryPageAction?.(task);
            }
            else if (this.isRunbookTask()) {
                if (task.IsCompleted && !task.FinishedSuccessfully) {
                    if (gitRunbooksEnabled && this.isGitRunbookTask()) {
                        primaryPageAction = {
                            type: "button",
                            label: "Try Again...",
                            onClick: this.props.retryRunbookDialogControls.openDialog,
                            hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: task.ProjectId!, environment: this.props.environmentId, wildcard: true }),
                        };
                    }
                    else {
                        const tryAgainLink = links.createRunbookRunForSnapshotPage.generateUrl({ spaceId: task.SpaceId!, projectSlug: task.ProjectId!, runbookId: this.state.runbook!.Id, runbookSnapshotId: this.state.snapshot.Id }, { previousRunbookRunId: this.props.match.params.runbookRunId });
                        primaryPageAction = {
                            type: "navigate",
                            label: "Try again...",
                            path: tryAgainLink,
                            hasPermissions: isAllowed({ permission: Permission.RunbookRunCreate, project: task.ProjectId!, environment: this.props.environmentId, wildcard: true }),
                        };
                    }
                }
            }
        }
        const interruptionsCount = canRender && !task.IsCompleted && this.state.interruptions && this.state.interruptions.Items ? this.state.interruptions.Items.filter((i) => i.IsPending).length : 0;
        const summaryWarning = interruptionsCount > 0 ? `This task has interruption${interruptionsCount > 1 ? "s" : ""} preventing it from continuing` : null;
        const PageLayout = this.props.projectContext !== undefined && this.props.isVerticalNavigationEnabled ? Level2PageLayout : PaperLayoutVNext;
        return (<PageLayout title={task ? task.Description : "Task"} titleLogo={task ? <TaskStatusIcon item={task!}/> : undefined} titleChip={this.renderTitleChip()} breadcrumbsItems={this.state.breadcrumbsItems ?? this.props.defaultBreadcrumbItems} showBreadcrumbBackIcon={this.props.showBreadcrumbBackIcon} busy={this.state.busy} enableLessIntrusiveLoadingIndicator={false} errors={this.errors} primaryAction={primaryPageAction} pageActions={pageActions} overflowActions={overflowMenu.menuItems} fullWidth={true}>
                {canRender && (<>
                        {this.renderPublishSnapshotDialog()}
                        {this.renderRetryRunbookRunDialog()}
                        {overflowMenu.dialogs}
                        {this.state.taskSpace && this.state.taskSpace.Id != client.spaceId && (<Callout hideTitle={true} type={"information"}>
                                You are viewing a task from Space: <strong>{this.state.taskSpace.Name}</strong>. <InternalLink to={links.spaceRootRedirect.generateUrl({ spaceId: this.state.taskSpace.Id })}>Change to this Space.</InternalLink>
                            </Callout>)}
                        <ContainersFeedbackCallout onTaskPage={true} actionTypes={this.state.taskDeploymentActionTypes}></ContainersFeedbackCallout>
                        {this.renderDevTools()}
                        <UrlNavigationTabsContainer defaultValue={this.state.initialTab}>
                            <TabItem label="Task Summary" value="taskSummary" warning={summaryWarning!}>
                                <TaskSummary task={task} projectId={this.props.projectId} environmentId={this.props.environmentId} tenantId={this.props.tenantId} artifacts={this.state.artifacts} interruptions={this.state.interruptions} activityElements={this.state.activityElements} stepsCorrelationIds={this.state.stepsCorrelationIds} taskDetails={this.state.taskDetails} doRefresh={this.doRefresh} snapshot={this.state.snapshot} gitRef={this.state.gitRef}/>
                            </TabItem>
                            <TabItem label="Task Log" value="taskLog">
                                <TaskLog details={details!} verbose={this.state.verbose} activityElements={this.state.activityElements!} processId={this.state.processId} stepsCorrelationIds={this.state.stepsCorrelationIds} tail={this.state.tail} initialExpandedId={selectedElement} manuallyExpandedIds={this.state.manuallyExpandedIds} onFetchRange={(element, start, end) => this.fetchRange(element, start, end)} setVerbose={this.setVerbose} isFetchDisabled={this.state.isFetchDisabled} gitRef={this.state.gitRef} onExpandedIdsChanged={(expandedIds) => this.setState({ manuallyExpandedIds: expandedIds })}/>
                            </TabItem>
                            <TabItem label="History" value="history" onActive={() => this.props.dispatchAction("View History", { action: Action.View, resource: "Task" })}>
                                {task ? <TaskHistory task={task} projectId={this.props.projectId} environmentId={this.props.environmentId} tenantId={this.props.tenantId}/> : null}
                            </TabItem>
                            {this.state.changesMarkdown === null ? null : (<TabItem label="Changes" value="changes" onActive={() => this.props.dispatchAction("View Changes", { action: Action.View, resource: "Task" })}>
                                    <Changes changesMarkdown={this.state.changesMarkdown}/>
                                </TabItem>)}
                            {this.isDeploymentTask() ? (<TabItem label="Build Information" value="buildInformation" onActive={() => this.props.dispatchAction("View Build Information", { action: Action.View, resource: "Task" })}>
                                    {this.isTaskSpaceScopedRepository(this.state.taskScopedRepository) ? (<BuildInformation deploymentId={this.props.match.params.deploymentId} doBusyTask={this.doBusyTask} taskScopedRepository={this.state.taskScopedRepository}/>) : null}
                                </TabItem>) : null}
                            {gitRunbooksEnabled && this.isRunbookTask() && this.props.projectContext && this.state.runbook && isRunbookSnapshotResource(this.state.snapshot) ? (<TabItem label="General" value="general" onActive={() => this.props.dispatchAction("View General Information", { action: Action.View, resource: "Task" })}>
                                    <RunbookSnapshotInfo doBusyTask={this.doBusyTask} project={this.props.projectContext.state.model} runbook={this.state.runbook} runbookSnapshot={this.state.snapshot} runbookRunId={this.props.match.params.runbookRunId} variableSnapshotRefreshKey="" hideUpdateVariableSnapshot disableQueryStringFilters/>
                                </TabItem>) : null}
                            {this.state.stepsWithKubernetesActions.length > 0 && (<TabItem label="Kubernetes Object Status" value="kubernetesDeploymentStatus">
                                    <KubernetesDeploymentStatus status={this.state.kubernetesOctopusStatus} activityLogs={this.state.taskDetails?.ActivityLogs || []} stepsWithKubernetesActions={this.state.stepsWithKubernetesActions} projectId={this.props.projectId}/>
                                </TabItem>)}
                            {task!.Name === TaskName.AdHocScript && (<TabItem label={task!.Arguments.ActionTemplateId ? "Template Parameters" : "Script body"} value="adHocScriptSummary">
                                    <AdHocScriptTaskSummary task={task!}/>
                                </TabItem>)}
                        </UrlNavigationTabsContainer>
                    </>)}
            </PageLayout>);
    }
    private doRefresh: Refresh = () => Promise.resolve();
    private getOverflowMenu = (canRender: boolean) => {
        return OverflowMenuConverterVNext.convertAll(canRender ? [this.renderEditStateButton(), this.renderTryAgainButton(), this.renderDeployToButton(), this.renderDeleteExecutionButton(), this.renderViewPreviousDeploymentsButton()] : []);
    };
    private loadArtifactsPromise = () => {
        return this.isTaskSpaceScopedRepository(this.state.taskScopedRepository) &&
            isAllowed({
                permission: Permission.ArtifactView,
                project: this.props.projectId,
                environment: this.props.environmentId,
                tenant: this.props.tenantId,
            }, this.state.task?.SpaceId ? { spaceId: this.state.task.SpaceId } : "system")
            ? this.state.taskScopedRepository.Artifacts.list({
                regarding: this.props.taskId,
                take: Repository.takeAll,
                order: "asc",
            }).then((r) => r.Items)
            : Promise.resolve([] as ArtifactResource[]);
    };
    private isTaskSpaceScopedRepository = (repository: OctopusSpaceRepository | OctopusSystemRepository | undefined): repository is OctopusSpaceRepository => {
        return repository !== undefined && "Projects" in repository;
    };
    static displayName = "InternalTask";
}
function Task(props: TaskComponentProps & RouteComponentProps) {
    const projectContext = useOptionalProjectContext();
    const dispatchAction = useAnalyticActionDispatch();
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    const publishRunbookDialogControls = useDialogTrigger();
    const retryRunbookDialogControls = useDialogTrigger();
    return (<InternalTask {...props} projectContext={projectContext} dispatchAction={dispatchAction} isVerticalNavigationEnabled={isVerticalNavigationEnabled} publishRunbookDialogControls={publishRunbookDialogControls} retryRunbookDialogControls={retryRunbookDialogControls}/>);
}
export default withRouter(Task);
