import { useState } from "react";
import useLocalStorage from "~/hooks/useLocalStorage";
export type PageState = {
    pageSize: number;
    pageNumber: number;
};
export function usePageState(localStorageKey: string, defaultPageSize: number = 30): [
    PageState,
    (newPageNumber: number, newPageSize: number) => void
] {
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useLocalStorage(localStorageKey, defaultPageSize);
    const setPageState = (newPageNumber: number, newPageSize: number) => {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
    };
    return [{ pageSize, pageNumber }, setPageState];
}
