import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonUserProfileOverview, CommonUserProfileTopics } from "../../CommonComponents/CommonUserProfileViews";
export const UserProfileLoginsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.currentUser.logins,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonUserProfileOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonUserProfileTopics />;
    },
};
