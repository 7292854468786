/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { useOctopusLinkComponent } from "@octopusdeploy/design-system-components";
import type { PhaseResource, EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import PhaseStatusIcon from "~/components/PhaseStatusIcon/PhaseStatusIcon";
import iconStyles from "~/components/PhaseStatusIcon/style.module.less";
import type { LifecycleStatus } from "../../../../../utils/MapProgressionToStatus/MapProgressionToStatus";
import { getSingleDeploymentTarget } from "../../../../../utils/PhaseHelper/phaseHelpers";
import envCount from "./envCount";
import styles from "./style.module.less";
interface Props {
    phases: PhaseResource[];
    environmentsById: Record<string, EnvironmentResource>;
    lifecycleStatus?: LifecycleStatus;
    className?: string;
}
class LifecycleMapInternal extends React.Component<Props, never> {
    render() {
        return (<div className={cn(styles.lifecycleMap, this.props.className)}>
                <ul className={lifeCyclePhaseListStyles}>
                    {this.props.phases.map((p) => (<li className={styles.phase} key={p.Name}>
                            {this.phaseHasSingleTargetEnvironmentWithSameName(p) ? <PhaseOnly phase={p} lifecycleStatus={this.props.lifecycleStatus} environmentsById={this.props.environmentsById}/> : <this.PhaseAndAllTargetEnvironments phase={p}/>}
                        </li>))}
                </ul>
            </div>);
    }
    phaseHasSingleTargetEnvironmentWithSameName = (p: PhaseResource): boolean => {
        const env = this.props.environmentsById[getSingleDeploymentTarget(p)];
        return envCount(p) === 1 && env && p.Name === env.Name;
    };
    PhaseAndAllTargetEnvironments = (props: {
        phase: PhaseResource;
    }) => {
        return (<div>
                <PhaseStatusIcon phase={props.phase} status={this.props.lifecycleStatus!}/>
                <span className={styles.phaseName}>{props.phase.Name}</span>
                <this.PhaseConstraintAny phase={props.phase}/>
                <PhaseConstraintOptional phase={props.phase}/>
                <ul>
                    {props.phase.AutomaticDeploymentTargets.map((e) => (<this.AutomaticDeploymentTarget key={e} target={e}/>))}
                    {props.phase.OptionalDeploymentTargets.map((e) => (<this.OptionalDeploymentTarget key={`${e}-opt`} target={e}/>))}
                </ul>
            </div>);
    };
    PhaseConstraintAny = (props: {
        phase: PhaseResource;
    }) => {
        const p = props.phase;
        const show: boolean = p.MinimumEnvironmentsBeforePromotion > 0 && p.MinimumEnvironmentsBeforePromotion < envCount(p) && !p.IsOptionalPhase;
        return show ? <span className={styles.phaseConstraint}> (any {p.MinimumEnvironmentsBeforePromotion})</span> : null;
    };
    AutomaticDeploymentTarget = (props: {
        target: string;
    }) => {
        return this.props.environmentsById[props.target] ? (<li className={styles.target} title="Automatically deploy to this environment" key={props.target}>
                <span className={cn(iconStyles.phaseIcon, iconStyles.automatic)}/>
                <this.EnvironmentName {...props}/>
            </li>) : null;
    };
    OptionalDeploymentTarget = (props: {
        target: string;
    }) => {
        return this.props.environmentsById[props.target] ? (<li className={styles.target} title="Manually deploy to this environment" key={props.target}>
                <span className={cn(iconStyles.phaseIcon, iconStyles.optional)}/>
                <this.EnvironmentName {...props}/>
            </li>) : null;
    };
    EnvironmentName = (props: {
        target: string;
    }) => {
        return this.props.lifecycleStatus!.tasks[props.target] ? <a href="#/tasks/{{ tasks[e] }}">{this.props.environmentsById[props.target].Name}</a> : <span>{this.props.environmentsById[props.target].Name}</span>;
    };
    static displayName = "LifecycleMapInternal";
}
interface PhaseOnlyProps {
    phase: PhaseResource;
    lifecycleStatus: LifecycleStatus | undefined;
    environmentsById: Record<string, EnvironmentResource>;
}
const PhaseOnly = ({ phase, lifecycleStatus, environmentsById }: PhaseOnlyProps) => {
    const Link = useOctopusLinkComponent();
    const s = lifecycleStatus!;
    const target = getSingleDeploymentTarget(phase);
    const task = s.tasks[target];
    const phaseName = task ? (
    // eslint-disable-next-line react/forbid-elements
    <Link className={styles.phaseName} href={links.taskPage.generateUrl({ taskId: task })}>
            {environmentsById[target].Name}
        </Link>) : (<span className={styles.phaseName}>{environmentsById[target].Name}</span>);
    return (<div>
            <PhaseStatusIcon phase={phase} status={s}/>
            {phaseName}
            <PhaseConstraintOptional phase={phase}/>
        </div>);
};
const PhaseConstraintOptional = ({ phase }: {
    phase: PhaseResource;
}) => {
    return phase.IsOptionalPhase ? <span className={styles.phaseConstraint}> (optional)</span> : null;
};
//We upgraded to mui4, so the list can now be an embedded list as legacy mui used divs. This
//changes the behaviour for phases as it would now be using browser default styles. In order to
//avoid this from impacting usage locations, we have opted to add the previous margins back in.
//Ideally, we should set the margins and padding to 0 and verify usage locations and update
//where necessary. This may mean adding container elements above the component to add the appropriate
//padding and margins.
const lifeCyclePhaseListStyles = css({
    marginTop: "1em",
    marginBottom: "1em",
});
export default LifecycleMapInternal;
