import { css } from "@emotion/css";
import { Callout } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { links } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import React from "react";
import { ExternalLinkIcon } from "~/components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
interface LinkProps {
    spaceId: string;
    projectSlug: string;
}
interface RelatedTriggerCalloutProps extends LinkProps {
    text: ReactNode;
}
export interface SingleRelatedTriggerCalloutProps extends RelatedTriggerCalloutProps {
    triggerId: string;
}
export function AvailableTriggerCallout({ spaceId, projectSlug }: LinkProps) {
    return (<>
            <Callout type={"new-feature"} hideTitle>
                You can use this package to automatically trigger release creation when a new version is available in the external feed.{" "}
                <InternalLink to={links.createReleaseCreationFeedTriggerPage.generateUrl({ spaceId, projectSlug })} openInSelf={false}>
                    Create a trigger
                    <ExternalLinkIcon />
                </InternalLink>
            </Callout>
            <div className={styles.spacer}/>
        </>);
}
export function SingleRelatedTriggerCallout({ text, spaceId, projectSlug, triggerId }: SingleRelatedTriggerCalloutProps) {
    return (<>
            <Callout type={"information"} hideTitle>
                {text}{" "}
                <InternalLink to={links.editReleaseCreationFeedTriggerPage.generateUrl({ spaceId, projectSlug, triggerId })} openInSelf={false}>
                    View trigger
                    <ExternalLinkIcon />
                </InternalLink>
            </Callout>
            <div className={styles.spacer}/>
        </>);
}
export function MultipleRelatedTriggersCallout({ text, spaceId, projectSlug }: RelatedTriggerCalloutProps) {
    return (<>
            <Callout type={"information"} hideTitle>
                {text}{" "}
                <InternalLink to={links.deploymentTriggersPage.generateUrl({ spaceId, projectSlug })} openInSelf={false}>
                    View triggers
                    <ExternalLinkIcon />
                </InternalLink>
            </Callout>
            <div className={styles.spacer}/>
        </>);
}
export interface RelatedTriggerModifiedCalloutProps extends LinkProps {
    disableLink: boolean;
}
export interface SingleRelatedTriggerModifiedCalloutProps extends RelatedTriggerModifiedCalloutProps {
    triggerId: string;
}
export function SingleRelatedTriggerModifiedCallout({ spaceId, projectSlug, triggerId, disableLink }: SingleRelatedTriggerModifiedCalloutProps) {
    return (<>
            <Callout type={"warning"} title="You’re modifying a package connected to an external feed trigger">
                After saving your changes, you’ll need to update your trigger to keep it working as expected.{" "}
                {!disableLink && (<InternalLink to={links.editReleaseCreationFeedTriggerPage.generateUrl({ spaceId, projectSlug, triggerId })} openInSelf={false}>
                        View trigger
                        <ExternalLinkIcon />
                    </InternalLink>)}
            </Callout>
            <div className={styles.spacer}/>
        </>);
}
export function MultipleRelatedTriggersModifiedCallout({ spaceId, projectSlug, disableLink }: RelatedTriggerModifiedCalloutProps) {
    return (<>
            <Callout type={"warning"} title="You’re modifying a package connected to external feed triggers">
                After saving your changes, you’ll need to update your triggers to keep them working as expected.{" "}
                {!disableLink && (<InternalLink to={links.deploymentTriggersPage.generateUrl({ spaceId, projectSlug })} openInSelf={false}>
                        View triggers
                        <ExternalLinkIcon />
                    </InternalLink>)}
            </Callout>
            <div className={styles.spacer}/>
        </>);
}
const styles = {
    spacer: css({ marginBottom: space[16] }),
};
