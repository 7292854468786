import type { PackageReference } from "@octopusdeploy/octopus-server-client";
import { GetNamedPackageReferences, DOCKER_IMAGE_REFERENCE_PURPOSE } from "@octopusdeploy/octopus-server-client";
import type { PackagedHelmValuesProperties, PackagedHelmValuesReference } from "~/components/Actions/helmChartUpgrade/PackagedHelmValuesDialog";
export function GetHelmExePackageReference(packages: Array<PackageReference<PackagedHelmValuesProperties>>): PackageReference<PackagedHelmValuesProperties> | undefined {
    return packages?.find((pkg) => IsHelmExePackageReference(pkg));
}
export function IsHelmExePackageReference(pkg: PackageReference<PackagedHelmValuesProperties>): boolean {
    return pkg.Name === "HelmExe";
}
export function IsDockerImageReference(pkg: PackageReference<PackagedHelmValuesProperties>): boolean {
    return pkg.Properties.Purpose === DOCKER_IMAGE_REFERENCE_PURPOSE;
}
export function ClearHelmExePackage(packages: Array<PackageReference<PackagedHelmValuesProperties>>): Array<PackageReference<PackagedHelmValuesProperties>> {
    return packages.filter((pkg) => !IsHelmExePackageReference(pkg));
}
export const getValuesPackageReferences = (packages: PackagedHelmValuesReference[]) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return GetNamedPackageReferences(packages)
        .filter((pkg) => !IsHelmExePackageReference(pkg) && !IsDockerImageReference(pkg))
        .filter((pkg) => pkg.Name?.startsWith("ValuesPack") || false) as PackagedHelmValuesReference[];
};
