/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import type { OpenDialogProps } from "~/components/Dialog/OpenDialogConnect";
import { OpenDialogConnect } from "~/components/Dialog/OpenDialogConnect";
import dialogIdGenerator from "~/components/Dialog/dialogIdGenerator";
interface RenderDialogProps {
    open: boolean;
    sequence: string;
}
interface OpenDialogMenuItemProps {
    label: string;
    ref?: any;
    renderDialog?: (props: RenderDialogProps) => React.ReactElement<any>;
    acceptOnClick?: (onClick: () => void) => void;
}
type Props = OpenDialogMenuItemProps & OpenDialogProps;
export class OpenDialogMenuItemInternal extends React.Component<Props, {}> {
    private text: string;
    private uniqueId: string;
    constructor(props: Props) {
        super(props);
        this.text = this.props.label;
        this.uniqueId = dialogIdGenerator.next();
    }
    onClick() {
        this.props.openDialog(this.uniqueId);
    }
    render() {
        if (this.props.acceptOnClick) {
            this.props.acceptOnClick(this.onClick.bind(this));
        }
        return this.props.renderDialog ? this.props.renderDialog({ open: this.props.openDialogs[this.uniqueId], sequence: this.uniqueId }) : <Dialog open={this.props.openDialogs[this.uniqueId]}>{this.props.children}</Dialog>;
    }
    static displayName = "OpenDialogMenuItemInternal";
}
const OpenDialogMenuItem = OpenDialogConnect.to(OpenDialogMenuItemInternal, true);
export default OpenDialogMenuItem;
