import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonLibraryScriptModuleOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="ScriptModules">Script modules</ExternalLink> allow you to create collections of language specific functions that can be used in deployment processes across multiple projects.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
