/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { SFC } from "react";
interface ScriptingLanguageSelectorProps {
    value: ScriptingLanguage;
    onChange: (value: ScriptingLanguage) => void;
    autoFocus?: boolean;
    doNotAutoFocus?: boolean;
    supportedLanguages?: SupportedLanguage;
}
enum SupportedLanguage {
    All,
    PowerShell,
    PowerShellAndBash
}
export { SupportedLanguage };
const ScriptingLanguageSelector: SFC<ScriptingLanguageSelectorProps> = (props: ScriptingLanguageSelectorProps) => {
    const options = determineAvailableLanguages(props.supportedLanguages || SupportedLanguage.All, props);
    return (<RadioButtonGroup horizontal={true} value={props.value} autoFocus={props.autoFocus} onChange={(value) => props.onChange(value as ScriptingLanguage)}>
            {options}
        </RadioButtonGroup>);
};
function determineAvailableLanguages(supportedLanguages: SupportedLanguage, props: ScriptingLanguageSelectorProps) {
    const options = [<RadioButton value={ScriptingLanguage.PowerShell} label="PowerShell" key={ScriptingLanguage.PowerShell}/>];
    if (supportedLanguages === SupportedLanguage.All || supportedLanguages === SupportedLanguage.PowerShellAndBash) {
        options.push(<RadioButton value={ScriptingLanguage.Bash} label="Bash" key={ScriptingLanguage.Bash}/>);
    }
    if (supportedLanguages === SupportedLanguage.All) {
        options.push(<RadioButton value={ScriptingLanguage.CSharp} label="C#" key={ScriptingLanguage.CSharp}/>);
    }
    if (supportedLanguages === SupportedLanguage.All) {
        options.push(<RadioButton value={ScriptingLanguage.FSharp} label="F#" key={ScriptingLanguage.FSharp}/>);
        options.push(<RadioButton value={ScriptingLanguage.Python} label="Python3" key={ScriptingLanguage.Python}/>);
    }
    return options;
}
ScriptingLanguageSelector.displayName = "ScriptingLanguageSelector";
ScriptingLanguageSelector.defaultProps = {
    doNotAutoFocus: true,
};
export default ScriptingLanguageSelector;
