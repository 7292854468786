import * as React from "react";
import OnboardingPage from "../../../../../components/GettingStarted/OnboardingPage";
import { AuthorizeGitHubAppLink, ConfigureGitHubAppLink } from "./GitHubAppLinks";
export const GitConnectionAuthorizeOnboarding = () => {
    const OnboardingTextForGitHubAuthorize = "Connect your Octopus and GitHub accounts to configure GitHub connections.";
    return <OnboardingPage title="Connect your Octopus and GitHub accounts" intro={<span>{OnboardingTextForGitHubAuthorize}</span>} learnMore={<AuthorizeGitHubAppLink />}/>;
};
export const GitConnectionInstallOnboarding = () => {
    const OnboardingTextForGitHubInstall = "Enable smooth and secure connectivity to GitHub by installing the Octopus Deploy GitHub App in your GitHub account.";
    return <OnboardingPage title="Configure the GitHub App" intro={<span>{OnboardingTextForGitHubInstall}</span>} learnMore={<ConfigureGitHubAppLink />}/>;
};
