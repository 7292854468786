import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface DataTableProps {
    className?: string;
    title?: string;
}
export class DataTable extends React.Component<DataTableProps> {
    render() {
        const title = this.props.title ? { ["aria-label"]: this.props.title } : null;
        return (<table {...title} className={cn(this.props.className, styles.dataTable)}>
                {this.props.children}
            </table>);
    }
    static displayName = "DataTable";
}
