import type { ProjectResource, TenantVariableResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ProjectTemplatesInlineContent from "~/areas/projects/components/Variables/TenantVariables/ProjectTemplatesInlineContent";
import type { TenantVariablesPageSaveAction } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesInlineLayout";
import ProjectTenantVariablesInlineLayout from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesInlineLayout";
import { useProjectTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/useProjectTemplatesState";
import type { ChangedProjectTemplateVariableValue, ProjectTenantVariableValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import { useProjectTenantVariableValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import { useTenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import { useTenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import { useProjectContext } from "~/areas/projects/context/index";
import { repository } from "~/clientInstance";
import { DataBaseComponent, type DoBusyTask, type Errors } from "~/components/DataBaseComponent/index";
export default function ProjectTenantProjectTemplates() {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    return <ProjectTemplatesPage project={project}/>;
}
interface ProjectTemplatesPageProps {
    project: ProjectResource;
}
class ProjectTemplatesPage extends DataBaseComponent<ProjectTemplatesPageProps> {
    constructor(props: ProjectTemplatesPageProps) {
        super(props);
        this.state = {};
    }
    render() {
        const { project } = this.props;
        return <ProjectTemplatesPageInternal busy={this.state.busy} errors={this.errors} project={project} doBusyTask={this.doBusyTask}/>;
    }
    static displayName = "ProjectTemplatesPage";
}
interface ProjectTemplatesPageInternalProps {
    project: ProjectResource;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
}
function ProjectTemplatesPageInternal({ project, busy, errors, doBusyTask }: ProjectTemplatesPageInternalProps) {
    const contentState = useProjectTemplatesState(project, doBusyTask);
    const filterData = useTenantVariableFiltersData(project.Id, doBusyTask);
    const variableResourceOptions = useTenantVariableResourceOptions(doBusyTask);
    const valueChangeTracker = useProjectTenantVariableValueChangeTracker();
    const saveAction: TenantVariablesPageSaveAction = {
        onClick: async () => {
            await saveProjectTemplateVariableValues(doBusyTask, valueChangeTracker);
            await contentState.actions.onSaveProjectTemplates();
            valueChangeTracker.reset();
        },
        disabled: !valueChangeTracker.hasAny(),
    };
    return (<ProjectTenantVariablesInlineLayout busy={busy} errors={errors} project={project} doBusyTask={doBusyTask} saveAction={saveAction} page={"project"} warnings={contentState.warnings}>
            {project ? <ProjectTemplatesInlineContent project={project} doBusyTask={doBusyTask} contentState={contentState} filterData={filterData} variableResourceOptions={variableResourceOptions} variableValueTracker={valueChangeTracker}/> : null}
        </ProjectTenantVariablesInlineLayout>);
}
async function saveProjectTemplateVariableValues(doBusyTask: DoBusyTask, valueTracker: ProjectTenantVariableValueChangeTracker) {
    return doBusyTask(async () => {
        const changedTenants = valueTracker.changedTenants();
        const tenantVariablesPromises = Array.from(changedTenants).map((id) => repository.Tenants.getVariablesByTenantId(id));
        const tenantVariables = await Promise.all(tenantVariablesPromises);
        const tenantVariablesLookup: {
            [tenantId: string]: TenantVariableResource;
        } = {};
        for (const tenantVariable of tenantVariables) {
            tenantVariablesLookup[tenantVariable.TenantId] = tenantVariable;
        }
        const modifiedTenantVariables = modifyTenantVariables(tenantVariablesLookup, valueTracker.values());
        const savingPromises = modifiedTenantVariables.map((variables) => repository.Tenants.setVariablesByTenantId(variables.TenantId, variables));
        await Promise.all(savingPromises);
    });
}
function modifyTenantVariables(tenantVariables: {
    [tenantId: string]: TenantVariableResource;
}, changes: ChangedProjectTemplateVariableValue[]): TenantVariableResource[] {
    const modified = new Map<string, TenantVariableResource>();
    for (const { projectId, templateId, tenantId, environmentId, value } of changes) {
        const variables = tenantVariables[tenantId];
        variables.ProjectVariables[projectId].Variables[environmentId][templateId] = value ?? "";
        modified.set(tenantId, variables);
    }
    return Array.from(modified.values());
}
