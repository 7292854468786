/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { CertificateResource, Certificate } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ThumbprintText from "~/components/ThumbprintText";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import DateFormatter from "~/utils/DateFormatter";
interface BasicCertificateDisplayProps {
    certificate: Certificate;
}
const BasicCertificateDisplay = (props: BasicCertificateDisplayProps) => {
    return (<React.Fragment>
            <DataTableRow>
                <DataTableRowColumn>Subject</DataTableRowColumn>
                <DataTableRowColumn>{props.certificate.SubjectDistinguishedName}</DataTableRowColumn>
            </DataTableRow>
            <DataTableRow>
                <DataTableRowColumn>Issuer</DataTableRowColumn>
                <DataTableRowColumn>{props.certificate.IssuerDistinguishedName}</DataTableRowColumn>
            </DataTableRow>
            <DataTableRow>
                <DataTableRowColumn>Not After</DataTableRowColumn>
                <DataTableRowColumn>
                    {DateFormatter.dateToLongFormat(props.certificate.NotAfter)}
                    {props.certificate.IsExpired && <span> expired</span>}
                </DataTableRowColumn>
            </DataTableRow>
            <DataTableRow>
                <DataTableRowColumn>Not Before</DataTableRowColumn>
                <DataTableRowColumn>{DateFormatter.dateToLongFormat(props.certificate.NotBefore)}</DataTableRowColumn>
            </DataTableRow>
            <DataTableRow>
                <DataTableRowColumn>Thumbprint</DataTableRowColumn>
                <DataTableRowColumn>
                    <ThumbprintText thumbprint={props.certificate.Thumbprint!}/>
                </DataTableRowColumn>
            </DataTableRow>
            <DataTableRow>
                <DataTableRowColumn>Version</DataTableRowColumn>
                <DataTableRowColumn>{props.certificate.Version}</DataTableRowColumn>
            </DataTableRow>
            <DataTableRow>
                <DataTableRowColumn>Serial Number</DataTableRowColumn>
                <DataTableRowColumn>{props.certificate.SerialNumber}</DataTableRowColumn>
            </DataTableRow>
            <DataTableRow>
                <DataTableRowColumn>Signature Algorithm</DataTableRowColumn>
                <DataTableRowColumn>{props.certificate.SignatureAlgorithmName}</DataTableRowColumn>
            </DataTableRow>
        </React.Fragment>);
};
interface DetailedCertificateDisplay {
    certificate: CertificateResource;
}
const DetailedCertificateDisplay = (props: DetailedCertificateDisplay) => {
    return (<React.Fragment>
            {props.certificate.Archived && (<DataTableRow>
                    <DataTableRowColumn>Archived</DataTableRowColumn>
                    <DataTableRowColumn>Archived on {props.certificate.Archived}</DataTableRowColumn>
                </DataTableRow>)}
            <DataTableRow>
                <DataTableRowColumn>Has Private Key</DataTableRowColumn>
                <DataTableRowColumn>{props.certificate.HasPrivateKey ? "Yes" : "No"}</DataTableRowColumn>
            </DataTableRow>
            <BasicCertificateDisplay certificate={props.certificate}/>
            {props.certificate.SubjectAlternativeNames && props.certificate.SubjectAlternativeNames.length > 0 && (<DataTableRow>
                    <DataTableRowColumn>Subject Alternative Names</DataTableRowColumn>
                    <DataTableRowColumn>
                        {props.certificate.SubjectAlternativeNames.map((san: string) => (<>
                                <span>{san}</span>
                                <br />
                            </>))}
                    </DataTableRowColumn>
                </DataTableRow>)}
        </React.Fragment>);
};
interface CertificateDetailProps {
    certificate: CertificateResource | Certificate; //CertificateResource or X509Certificate
}
function isCertificateResource(cert: CertificateResource | Certificate): cert is CertificateResource {
    const converted = cert as CertificateResource;
    return converted.CertificateData !== undefined && converted.CertificateData.HasValue;
}
const CertificateDetail = (props: CertificateDetailProps) => {
    const Display = isCertificateResource(props.certificate) ? DetailedCertificateDisplay : BasicCertificateDisplay;
    return (<DataTable>
            <DataTableBody>
                <Display certificate={props.certificate as CertificateResource}/>
            </DataTableBody>
        </DataTable>);
};
export default CertificateDetail;
