import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
const video = {
    youtubeId: "f_JPU7sAE8M",
    shortlink: "ManagingUsersAndTeamsVideo",
};
export const CommonConfigurationRoleOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    <ExternalLink href="UserRoles">User roles</ExternalLink> and group permissions play a major part in the Octopus security model. These roles are assigned to teams and they dictate what the members of those teams can do in Octopus.
                </p>
                <p>Octopus comes with a common set of built-in roles, and you can create custom user roles to suit your needs.</p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
