/* eslint-disable @typescript-eslint/no-explicit-any */
import type * as H from "history";
import type { PropsWithChildren, ReactNode } from "react";
import * as React from "react";
import type { match as Match, match } from "react-router";
import { useLocation, useRouteMatch } from "react-router";
import { generatePath } from "react-router-dom";
import { repository } from "~/clientInstance";
import BaseComponent from "~/components/BaseComponent/index";
import StringHelper from "~/utils/StringHelper/index";
import { useSetInPageNavVisible } from "../GlobalLayout/InPageNavVisibilityContext";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { SkeletonLoadingLayout } from "../SkeletonLoadingLayout/SkeletonLoadingLayout";
export interface RedirectProjectIfNotSlugProps {
    children: ReactNode;
}
export function RedirectProjectIfNotSlug({ children }: PropsWithChildren<RedirectProjectIfNotSlugProps>) {
    const setVisible = useSetInPageNavVisible();
    React.useEffect(() => {
        setVisible?.(true);
    }, [setVisible]);
    return (<SlugSafeRedirect parameter={"projectSlug"} regexp={/^Projects-[0-9]+$/} getRealParam={loadSlugFromProjectId} loadingComponent={<ProjectSlugLoading />}>
            {children}
        </SlugSafeRedirect>);
}
function ProjectSlugLoading() {
    return (<main>
            <SkeletonLoadingLayout errors={undefined}/>
        </main>);
}
interface ProjectLocationState {
    project?: {
        Slug: string;
    };
}
async function loadSlugFromProjectId(projectId: string, location: H.Location) {
    if (locationHasProjectState(location)) {
        return location.state.project.Slug;
    }
    return (await repository.Projects.get(projectId)).Slug;
}
function locationHasProjectState(location: H.Location): location is H.Location<Required<ProjectLocationState>> {
    if (!location || !location?.state) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = location as H.Location<ProjectLocationState>;
    if (!casted.state?.project) {
        return false;
    }
    return typeof casted.state.project.Slug === "string";
}
export function RedirectReleaseVersion({ children }: React.PropsWithChildren<{}>) {
    return (<SlugSafeRedirect parameter="releaseVersion" regexp={/^Releases-[0-9]+$/} getRealParam={loadVersionFromReleaseId} loadingComponent={releaseVersionLoading()}>
            {children}
        </SlugSafeRedirect>);
}
async function loadVersionFromReleaseId(releaseId: string) {
    return (await repository.Releases.get(releaseId)).Version;
}
function releaseVersionLoading() {
    return <div>{StringHelper.ellipsis}</div>;
}
type SlugSafeRedirectProps = {
    parameter: string;
    regexp: RegExp;
    getRealParam(param: string, location?: H.Location, match?: Match<any>): Promise<string>;
    loadingComponent: React.ReactElement;
};
type SlugSafeRedirectState = {
    refreshId: number;
    paramValue?: string;
};
export function SlugSafeRedirect(props: PropsWithChildren<SlugSafeRedirectProps>) {
    const location = useLocation();
    const match = useRouteMatch<any>();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return <SlugSafeRedirectInternal {...props} location={location} match={match!}/>;
}
type SlugSafeRedirectInternalProps = {
    parameter: string;
    regexp: RegExp;
    getRealParam(param: string, location?: H.Location, match?: Match<any>): Promise<string>;
    loadingComponent: React.ReactElement;
    location: H.Location;
    match: match<any>;
};
class SlugSafeRedirectInternal extends BaseComponent<SlugSafeRedirectInternalProps, SlugSafeRedirectState> {
    refreshId: number = 0;
    constructor(props: SlugSafeRedirectInternalProps) {
        super(props);
        this.state = { refreshId: 0 };
    }
    async UNSAFE_componentWillMount() {
        const { parameter, regexp, match } = this.props;
        this.refreshId += 1;
        const param = match.params[parameter];
        if (param && regexp.test(param)) {
            try {
                const paramValue = await this.props.getRealParam(param, this.props.location, this.props.match);
                this.setState({ paramValue, refreshId: this.refreshId });
            }
            catch (ex) {
                this.setState({ paramValue: param, refreshId: this.refreshId });
            }
        }
        else {
            this.setState({ paramValue: param, refreshId: this.refreshId });
        }
    }
    render() {
        if (this.isLoading()) {
            return this.props.loadingComponent;
        }
        if (this.isRedirecting()) {
            return this.redirect();
        }
        return React.Children.only(this.props.children);
    }
    isLoading() {
        return this.state.refreshId !== this.refreshId;
    }
    isRedirecting() {
        const urlParam = this.props.match.params[this.props.parameter];
        return urlParam !== this.state.paramValue;
    }
    redirect() {
        const newPath = this.calculateNewPath(this.props);
        const redirect = {
            ...this.props.location,
            pathname: newPath,
            state: {
                ...(typeof this.props.location.state === "object" ? this.props.location.state : {}),
                [this.props.parameter]: this.state.paramValue,
            },
        };
        return <InternalRedirect to={redirect} push={false}/>;
    }
    calculateNewPath(props: SlugSafeRedirectInternalProps): string {
        const newUrl = generatePath(props.match.path, {
            ...props.match.params,
            [props.parameter]: this.state.paramValue,
        });
        return newUrl + props.location.pathname.substr(props.match.url.length);
    }
    static displayName = "SlugSafeRedirectInternal";
}
