/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { MissingPermissionIllustration } from "@octopusdeploy/design-system-components";
import { text, space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { session } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { EmptyPage } from "../EmptyPage/EmptyPage";
export function NoPermissionsPage() {
    const title = `Hi there, ${session.currentUser!.DisplayName}. Your current permissions won’t allow you here.`;
    const description = "If you believe this is incorrect, please contact your Octopus Deploy Administrator to review & update your permissions.";
    const action = (<div className={actionStyles}>
            <div className={actionTextStyles}>For more information see our</div>
            <ExternalLink href="GettingStarted" className={actionLinkStyles}>
                Help Docs
            </ExternalLink>
        </div>);
    return (<main>
            <EmptyPage title={title} description={description} image={<MissingPermissionIllustration />} action={action}/>
        </main>);
}
const actionStyles = css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: space["4"],
});
const actionTextStyles = css({
    font: text.interface.body.default.xSmall,
});
const actionLinkStyles = css({
    font: text.link.bold.medium,
});
