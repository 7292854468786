import { ProcessType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import image from "../../Images/DeploymentProcess.svg";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const video = {
    youtubeId: "0oWRg_TxWxM",
    shortLink: "OnboardingDeploymentProcessVideo",
};
export const CommonProjectProcessOverview = (props: {
    processType?: ProcessType;
}) => {
    const isRunbookProcess = props.processType && props.processType === ProcessType.Runbook;
    let processDescriptionElement = (<p>
            The <ExternalLink href="OnboardingDeploymentProcessLearnMore">deployment process</ExternalLink> is like a recipe for deploying your software. You define the recipe by adding steps and variables to a project. Each step contains a specific
            action (or set of actions) that is executed as part of the deployment process each time your software is deployed.
        </p>);
    if (isRunbookProcess) {
        processDescriptionElement = (<p>
                The <ExternalLink href="OnboardingDeploymentProcessLearnMore">runbook process</ExternalLink> is like a recipe. You define the recipe by adding steps to a runbook. Each step contains a specific action (or set of actions) that is
                executed as part of the runbook process each time your runbook is run.
            </p>);
    }
    return (<OverviewContainer>
            <OverviewHighlightText>
                {!isRunbookProcess && (<p>
                        <YouTubePlayer videoId={video.youtubeId}/>
                    </p>)}
                {processDescriptionElement}
                <ImageWithPlaceholder src={image} alt={"Deployment process"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonProjectProcessTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="ConfigAsCodeEditing">Editing version-controlled projects</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
