import * as React from "react";
import styles from "./style.module.less";
interface IconButtonListProps {
    buttons?: JSX.Element[];
}
const IconButtonList: React.StatelessComponent<IconButtonListProps> = ({ buttons = [] }) => {
    return <div className={styles.buttonList}>{buttons.map((button, index) => (!button ? null : React.cloneElement(button, { ...button.props, key: index })))}</div>;
};
IconButtonList.displayName = "IconButtonList"
IconButtonList.displayName = "IconButtonList";
export default IconButtonList;
