import type { TagResource, TagSetResource, MachineTenantTagSummaryResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { sortBy } from "lodash";
import * as React from "react";
import { useState } from "react";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { ResultsLink } from "../ResultsLink";
import styles from "../style.module.less";
import { TagSetContent } from "./TagSetContent";
interface TagSetsContentWithThemeProps {
    spaceId: string;
    tagSummaries: MachineTenantTagSummaryResource;
    tagSets: TagSetResource[];
    numberOfItemsToRender: number;
    theme: OctopusTheme;
}
const onFilter = (filter: string, resource: TagResource) => {
    return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
};
const createTags = (tagSet: TagSetResource, filterText: string, maxTagsToReturn: number) => {
    return sortBy(tagSet.Tags.filter((item) => onFilter(filterText, item)), (t) => t.SortOrder).slice(0, maxTagsToReturn);
};
const TagSetsContentWithTheme = ({ spaceId, tagSummaries, tagSets, numberOfItemsToRender, theme }: TagSetsContentWithThemeProps) => {
    const [filterText, setFilterText] = useState("");
    const totalTagSetTags = tagSets.reduce((a, b) => a + b.Tags.length, 0);
    // limit tags to numberOfItemsToRender, not tag sets.
    let tagCount = 0;
    return (<>
            {(totalTagSetTags > 1 || filterText) && (<FilterSearchBox placeholder="Filter..." onChange={(value) => setFilterText(value.toLowerCase())} containerClassName={styles.cardContentContainerFilter} inputClassName={styles.dashboardFilterInputClassName} iconColor={theme.secondaryText} iconStyle={{ width: "20px", height: "20px", top: "14px" }}/>)}
            <div className={styles.cardContentContainerRows}>
                {tagSets.map((ts) => {
            const countRemaining = numberOfItemsToRender - tagCount;
            const maxTagsToReturn = Math.min(countRemaining, ts.Tags.length);
            if (countRemaining === 0 || maxTagsToReturn === 0)
                return;
            const tags = createTags(ts, filterText, maxTagsToReturn);
            tagCount += maxTagsToReturn;
            return <TagSetContent spaceId={spaceId} tagSet={ts} tags={tags} tenantTagSummaries={tagSummaries}/>;
        })}
                {<ResultsLink totalCount={tagSets.length} renderedCount={tagSets.length} link={links.tagSetsPage.generateUrl({ spaceId })}/>}
            </div>
        </>);
};
interface TagSetsContentProps {
    spaceId: string;
    tagSummaries: MachineTenantTagSummaryResource | undefined;
    summaryResource: TagSetResource[];
    numberOfItemsToRender: number;
}
export const TagSetsContent = ({ spaceId, tagSummaries, summaryResource, numberOfItemsToRender }: TagSetsContentProps) => {
    const theme = useOctopusTheme();
    return tagSummaries !== undefined ? <TagSetsContentWithTheme spaceId={spaceId} tagSummaries={tagSummaries} tagSets={summaryResource} numberOfItemsToRender={numberOfItemsToRender} theme={theme}/> : null;
};
