import React from "react";
import { AllPngImageFiles } from "~/components/DevTools/Images/AllPngImageFiles";
import { AllSvgImageFiles } from "~/components/DevTools/Images/AllSvgImageFiles";
import { EmbeddedImages } from "~/components/DevTools/Images/EmbeddedImages";
import { ControlledTabsContainer, TabItem } from "~/primitiveComponents/navigation/Tabs/index";
enum ImageTabOption {
    AllPngs = "All pngs",
    EmbeddedImages = "Embedded Images",
    EmbeddedSvgs = "Embedded Svgs"
}
function isImageTabOption(value: string): value is ImageTabOption {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.values(ImageTabOption).includes(value as ImageTabOption);
}
export function Images() {
    const [imageTab, setImageTab] = React.useState<ImageTabOption>(ImageTabOption.EmbeddedImages);
    const handleChangeImageTab = React.useCallback((value: string) => {
        if (isImageTabOption(value)) {
            setImageTab(value);
        }
    }, []);
    return (<div>
            <ControlledTabsContainer value={imageTab} onChange={handleChangeImageTab}>
                <TabItem label="Embedded Images" value={ImageTabOption.EmbeddedImages}>
                    <EmbeddedImages />
                </TabItem>
                <TabItem label="All *.svg images" value={ImageTabOption.EmbeddedSvgs}>
                    <AllSvgImageFiles />
                </TabItem>
                <TabItem label="All *.png images" value={ImageTabOption.AllPngs}>
                    <AllPngImageFiles />
                </TabItem>
            </ControlledTabsContainer>
        </div>);
}
