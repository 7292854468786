export const NumberRegex = /^\d+$/;
/**
 * http://yaml.org/type/bool.html
 */
export const BooleanRegex = /^(true|True|TRUE|false|False|FALSE)$/;
export const KubernetesResourceQuantityRegex = /^([+-]?[0-9.]+)([eEinumkKMGTP]*[-+]?[0-9]*)$/;
export const KubernetesNameRegex = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/;
export const KubernetesWildcardIngressHostRegex = /^\*\.[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/;
export const KubernetesIngressHostRegex = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/;
export const KubernetesLabelKeyRegex = /^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/;
export const Dns1132Regex = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/;
export const KubernetesPathTypeRegex = /^(Exact|ImplementationSpecific|Prefix)$/;
