import * as React from "react";
import { OverviewContainer, OverviewHighlightText } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
export const ProjectITSMProvidersSettingsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().settings.itsmProviders,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return (<OverviewContainer>
                <OverviewHighlightText>
                    <p>
                        ITSM Providers allows users to block the execution of specifically configured deployments unless they have a corresponding approved Change Request. Octopus supports{" "}
                        <ExternalLink href="ServiceNowIntegration">Service Now</ExternalLink> and <ExternalLink href="JiraServiceManagementIntegration">Jira Service Management</ExternalLink>.
                    </p>
                </OverviewHighlightText>
            </OverviewContainer>);
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return null;
    },
};
