import React, { useEffect } from "react";
import { useNotifyGitConnectionEvent } from "./useNotifyGitConnectionEvent";
export type GitHubConnectionAnalyticViewProps = {
    name: string;
};
export function GitConnectionAnalyticView(props: GitHubConnectionAnalyticViewProps) {
    const dispatch = useNotifyGitConnectionEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(props.name), []);
    return <></>;
}
