/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { MenuTargetAriaAttributes } from "@octopusdeploy/design-system-components";
import { SimpleMenu, useMenuState } from "@octopusdeploy/design-system-components";
import { NavigationSideBarTab } from "@octopusdeploy/design-system-components/src/components/NavigationSideBar/NavigationSideBarTab";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { MouseEventHandler } from "react";
import * as React from "react";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { UserAvatar, useUserAccountMenuItems } from "./UserAccountMenu";
interface ExposedUserAccountMenuProps {
    currentUser: UserResource;
    spaceContext: SpaceContext;
}
export function UserAccountMenuVNext({ currentUser, spaceContext }: ExposedUserAccountMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const userAccountMenuItems = useUserAccountMenuItems(currentUser, spaceContext);
    return (<>
            <OpenUserAccountMenuButton currentUser={currentUser} onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            openMenu(e);
        }} ariaAttributes={buttonAriaAttributes} isMenuOpen={menuState.isOpen}/>
            <SimpleMenu accessibleName={"User account"} items={userAccountMenuItems} menuState={menuState} anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
        }}/>
        </>);
}
interface OpenUserAccountMenuButtonProps {
    onClick: MouseEventHandler;
    currentUser: UserResource;
    ariaAttributes: MenuTargetAriaAttributes;
    isMenuOpen: boolean;
}
function OpenUserAccountMenuButton({ onClick, currentUser, ariaAttributes }: OpenUserAccountMenuButtonProps) {
    return (<NavigationSideBarTab icon={<UserAvatar user={currentUser} size={32}/>} href={links.currentUserRootRedirect.generateUrl()} accessibleName={currentUser.DisplayName} showLinkAsActive={"if path partially matches"} onClick={onClick} {...ariaAttributes}/>);
}
