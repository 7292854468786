import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
const video = {
    youtubeId: "Uc5TFqswJkM",
    shortLink: "OnboardingOperationsRunbookVideo",
};
export const CommonProjectRunbooksOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    <ExternalLink href="OnboardingRunbooksLearnMore">Runbooks</ExternalLink> can be used to automate routine or emergency operations-centric processes, for instance, disaster recovery and database backups.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonProjectRunbooksTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="OnboardingRunbooksLearnMore">Runbooks</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
