/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-eq-null */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton, RadioButton, RadioButtonGroup, Callout } from "@octopusdeploy/design-system-components";
import type { KubernetesDeploymentProperties } from "@octopusdeploy/legacy-action-properties";
import type { KubernetesServerSideApplyProperties } from "@octopusdeploy/legacy-action-properties/src/KubernetesServerSideApplyProperties";
import type { KubernetesStatusCheckComponentProperties } from "@octopusdeploy/legacy-action-properties/src/KubernetesStatusCheckComponentProperties";
import { ActionExecutionLocation, containerRegistryFeedTypes, isContainerImageRegistry, PackageAcquisitionLocation, PackageSelectionMode } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import { load as jsyaml } from "js-yaml";
import * as _ from "lodash";
import { clone } from "lodash";
import * as React from "react";
import { useOptionalProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { CalloutModifiedTriggerConnections } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutModifiedTriggerConnections";
import { CalloutReferencedPackageTriggerConnections } from "~/areas/projects/components/Triggers/ProcessCallouts/CalloutReferencedPackageTriggerConnections";
import { buildRelatedTriggerDependencies } from "~/areas/projects/components/Triggers/ProcessCallouts/triggerPackageRelations";
import CombinedVolumeDialog, { ConfigMapType, EmptyDirType, HostPathType, LinkedResource, PersistentVolumeClaimType, RawYamlType, SecretType } from "~/components/Actions/kubernetes/combinedVolumeDialog";
import { EditResourceYaml } from "~/components/Actions/kubernetes/editResourceYaml";
import { kubernetesMixedExecutionLocationConfig } from "~/components/Actions/kubernetes/kubernetesMixedExecutionLocationConfig";
import KubernetesStatusCheckComponent, { InitialStatusCheckWithTimeoutProperties } from "~/components/Actions/kubernetes/kubernetesStatusCheckComponent";
import NodeAffinityDialog from "~/components/Actions/kubernetes/nodeAffinityDialog";
import PodAffinityDialog from "~/components/Actions/kubernetes/podAffinityDialog";
import TolerationDialog from "~/components/Actions/kubernetes/tolerationDialog";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { FeedSelector } from "~/components/Selectors/FeedSelector";
import isBound from "~/components/form/BoundField/isBound";
import { DataTable, DataTableBody, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/BoundRadioButtonGroup";
import { toBoolString } from "~/utils/fieldConverters";
import { ProcessFeedLookup, useFeedsFromContext, useRefreshFeedsFromContext } from "../../../areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import ListTitle from "../../../primitiveComponents/dataDisplay/ListTitle/ListTitle";
import Note from "../../../primitiveComponents/form/Note/Note";
import { JsonUtils } from "../../../utils/jsonUtils";
import DialogOpener from "../../Dialog/DialogOpener";
import type { KeyValueOption } from "../../EditList/ExtendedKeyValueEditList";
import StringExtendedKeyValueEditList from "../../EditList/ExtendedKeyValueEditList";
import StringKeyValueEditList from "../../EditList/KeyValueEditList";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import { FormSectionHeading } from "../../form";
import { default as ExpandableFormSection } from "../../form/Sections/ExpandableFormSection";
import Summary from "../../form/Sections/Summary";
import { VariableLookupText } from "../../form/VariableLookupText";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionWithDirtyState, ActionWithFeeds } from "../commonActionHelpers";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
import type { ScriptPackageProperties, ScriptPackageReference } from "../script/ScriptPackageReferenceDialog";
import ContainerDialog, { CommandCheck, HttpGetCheck, TcpSocketCheck } from "./containerDialog";
import { getKubernetesTargetDiscoveryCloudProviders } from "./getKubernetesTargetDiscoveryCloudProviders";
import { exportDeployment, importDeployment } from "./importYaml";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";
import PersistentVolumeClaimDialog from "./persistentVolumeClaimDialog";
import { ServerSideApplyFormSection } from "./serverSideApplyFormSection";
import styles from "./style.module.less";
export const RequiredAffinity = "Required";
export const PreferredAffinity = "Preferred";
export const ExistsOperator = "Exists";
export const InOperator = "In";
export const DoesNotExistOperator = "DoesNotExist";
export const NotInOperator = "NotIn";
export const GreaterThanOperator = "Gt";
export const LessThanOperator = "Lt";
interface KubernetesDeploymentState {
    containers: ContainerPackageDetails[];
    editContainer: ContainerPackageDetails;
    editContainerIndex: number;
    persistentVolumeClaims: PersistentVolumeClaimInstanceDetails[];
    editPersistentVolumeClaim: PersistentVolumeClaimInstanceDetails;
    editPersistentVolumeClaimIndex: number;
    combinedVolumes: CombinedVolumeDetails[];
    editSecretVolume: CombinedVolumeDetails;
    editSecretVolumeIndex: number;
    podAffinityDetails: PodAffinityDetails[];
    editPodAffinity: PodAffinityDetails;
    editPodAffinityIndex: number;
    podAntiAffinityDetails: PodAffinityDetails[];
    editPodAntiAffinity: PodAffinityDetails;
    editPodAntiAffinityIndex: number;
    nodeAffinityDetails: NodeAffinityDetails[];
    editNodeAffinity: NodeAffinityDetails;
    editNodeAffinityIndex: number;
    editTolerationIndex: number | null;
    editToleration: TolerationDetails | null;
    tolerations: TolerationDetails[];
    resourceYaml: string;
    hostAliases: KeyValueOption[];
}
export interface ResourceRequirementsDetails {
    cpu: string;
    memory: string;
    ephemeralStorage: string;
    amdGpu: string;
    nvidiaGpu: string;
    storage: string;
}
/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#resourcerequirements-v1-core
 */
export interface ResourceRequirements {
    limits: ResourceRequirementsDetails;
    requests: ResourceRequirementsDetails;
}
/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.12/#selinuxoptions-v1-core
 */
export interface SELinuxOptions {
    level: string;
    role: string;
    type: string;
    user: string;
}
/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#securitycontext-v1-core
 */
export interface SecurityContext {
    allowPrivilegeEscalation: string;
    privileged: string;
    readOnlyRootFilesystem: string;
    runAsGroup: string;
    runAsNonRoot: string;
    runAsUser: string;
    capabilities: Capabilities;
    seLinuxOptions: SELinuxOptions;
}
/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.12/#capabilities-v1-core
 */
export interface Capabilities {
    add: string[];
    drop: string[];
}
/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#execaction-v1-core
 */
export interface ExecAction {
    command: string[];
}
/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#httpgetaction-v1-core
 */
export interface HTTPGetAction {
    scheme?: string;
    host?: string;
    path?: string;
    port: string;
    httpHeaders?: KeyValueOption[];
}
/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#tcpsocketaction-v1-core
 */
export interface TCPSocketAction {
    host?: string;
    port: string;
}
/**
 * https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.11/#probe-v1-core
 */
export interface Probe {
    failureThreshold: string;
    initialDelaySeconds: string;
    periodSeconds: string;
    successThreshold: string;
    timeoutSeconds: string;
    type: string;
    exec: ExecAction;
    httpGet: HTTPGetAction;
    tcpSocket: TCPSocketAction;
}
export interface DataSource {
    ApiGroup: string | null;
    Name: string | null;
    Kind: string | null;
}
export interface LabelSelector {
    MatchExpressions: KeyValueOption[] | null;
}
export interface Metadata {
    Name: string | null;
    AnnotationsRaw: KeyValueOption[] | null;
    LabelsRaw: {
        [key: string]: string;
    } | null;
}
export interface PvcSpec {
    VolumeName: string | null;
    VolumeMode: string | null;
    AccessModes: string[] | null;
    StorageClassName: string | null;
    Selector: LabelSelector | null;
    DataSource: DataSource | null;
    Resources: ResourceRequirements | null;
}
/**
 * Each package also has details about the ports and environment vars.
 */
export interface PersistentVolumeClaimDetails {
    Metadata: Metadata | null;
    Spec: PvcSpec | null;
}
export interface HostAliasDetails {
    Ip: string;
    Hostnames: string;
}
/**
 * Each package also has details about the ports and environment vars.
 */
export interface ContainerDetails {
    Name?: string;
    InitContainer?: string;
    ImagePullPolicy?: string;
    Ports: KeyValueOption[];
    EnvironmentVariables: KeyValueOption[];
    SecretEnvironmentVariables: KeyValueOption[];
    SecretEnvFromSource: KeyValueOption[];
    ConfigMapEnvironmentVariables: KeyValueOption[];
    ConfigMapEnvFromSource: KeyValueOption[];
    FieldRefEnvironmentVariables: KeyValueOption[];
    VolumeMounts: KeyValueOption[];
    TerminationMessagePath: string;
    TerminationMessagePolicy: string;
    LivenessProbe: Probe;
    ReadinessProbe: Probe;
    StartupProbe: Probe;
    Resources: ResourceRequirements;
    Command: string[];
    Args: string[];
    SecurityContext: SecurityContext;
    Lifecycle: {
        PostStart: LifecycleHandler;
        PreStop: LifecycleHandler;
    };
    CreateFeedSecrets?: string;
}
export type LifecycleHandler = {
    Exec?: ExecAction;
    HttpGet?: HTTPGetAction;
    TcpSocket?: TCPSocketAction;
};
/**
 * Internally we treat the details of a package and the container ports and env vars
 * as a single object. Even though these details are stored in two different locations
 * (the package list and a step property), the ui treats it as one thing.
 */
export interface ContainerPackageDetails extends ScriptPackageReference, ContainerDetails {
    IsNew?: boolean;
}
export interface PersistentVolumeClaimInstanceDetails extends PersistentVolumeClaimDetails {
    IsNew?: boolean;
}
class HostAliasList extends RemoveItemsList<HostAliasDetails> {
}
class ContainerList extends RemoveItemsList<ContainerPackageDetails> {
}
class PersistentVolumeClaimList extends RemoveItemsList<PersistentVolumeClaimInstanceDetails> {
}
class PodAffinityList extends RemoveItemsList<PodAffinityDetails> {
}
class NodeAffinityList extends RemoveItemsList<NodeAffinityDetails> {
}
class TolerationsList extends RemoveItemsList<TolerationDetails> {
}
interface GitRepoDetails {
    Repository: string;
    Revision: string;
}
interface SecretOrConfigMapDetails {
    ReferenceName: string;
    /**
     * This is set to "LinkedResource" if the volume name needs to match the
     * resource (secret or config map) that is created as part of the step,
     * and "Custom" if it references a custom resource.
     */
    ReferenceNameType: string;
    Items: KeyValueOption[];
}
interface EmptyDirDetails {
    EmptyDirMedium: string;
}
interface LocalDetails {
    LocalPath: string;
}
interface HostPathDetails {
    HostPathType: string;
    HostPathPath: string;
}
interface RawYamlDetails {
    RawYaml: string;
}
/**
 * We use a single dialog to capture the details of a volume,
 * and the dialog state therefor can contain all the values
 * that make up all the types of volumes.
 */
export interface CombinedVolumeDetails extends SecretOrConfigMapDetails, EmptyDirDetails, HostPathDetails, LocalDetails, RawYamlDetails, GitRepoDetails {
    Name: string;
    Type: string;
}
/**
 * These are the details for a pod (anti)affinity rule
 */
export interface PodAffinityDetails {
    Type: string;
    Weight: string;
    TopologyKey: string;
    NamespacesList: string;
    InMatch: KeyValueOption[];
    ExistMatch: KeyValueOption[];
}
/**
 * These are the details for a node affinity rule
 */
export interface NodeAffinityDetails {
    Type: string;
    Weight: string;
    InMatch: KeyValueOption[];
    ExistMatch: KeyValueOption[];
}
// https://kubernetes.io/docs/concepts/configuration/taint-and-toleration/
export interface TolerationDetails {
    Key: string;
    Operator: string;
    Value: string;
    Effect: string;
}
class KubernetesDeployContainersActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }
    render() {
        return <div>Deploy containers to Kubernetes, optionally exposed by a service and an ingress rule.</div>;
    }
    static displayName = "KubernetesDeployContainersActionSummary";
}
class CombinedVolumeList extends RemoveItemsList<CombinedVolumeDetails> {
}
interface StandaloneMode {
    standalone?: boolean;
}
export type KubernetesDeployContainersActionProperties = KubernetesDeploymentProperties & KubernetesStatusCheckComponentProperties & KubernetesServerSideApplyProperties;
type KubernetesDeployContainersActionEditProps = ActionEditProps<KubernetesDeployContainersActionProperties, ScriptPackageProperties> & StandaloneMode;
type KubernetesDeployContainersActionEditInternalProps = KubernetesDeployContainersActionEditProps & ActionWithFeeds & StandaloneMode & ActionWithDirtyState;
class KubernetesDeployContainersActionEditInternal extends BaseComponent<KubernetesDeployContainersActionEditInternalProps, KubernetesDeploymentState> {
    constructor(props: KubernetesDeployContainersActionEditInternalProps) {
        super(props);
        this.state = {
            containers: this.getContainerPackageDetails(this.props.properties["Octopus.Action.KubernetesContainers.Containers"], this.props.packages),
            editContainer: null!,
            editContainerIndex: null!,
            persistentVolumeClaims: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PersistentVolumeClaims"], []),
            editPersistentVolumeClaim: null!,
            editPersistentVolumeClaimIndex: null!,
            combinedVolumes: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"], []),
            editSecretVolume: null!,
            editSecretVolumeIndex: null!,
            editPodAffinityIndex: null!,
            editPodAffinity: null!,
            podAffinityDetails: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []),
            editPodAntiAffinityIndex: null!,
            editPodAntiAffinity: null!,
            podAntiAffinityDetails: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []),
            editNodeAffinityIndex: null!,
            editNodeAffinity: null!,
            nodeAffinityDetails: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []),
            editTolerationIndex: null,
            editToleration: null,
            tolerations: JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.Tolerations"], []),
            resourceYaml: exportDeployment(this.props, this.props.feeds),
            hostAliases: this.getHostAliasDetails(this.props.properties["Octopus.Action.KubernetesContainers.HostAliases"]),
        };
    }
    async componentDidMount() {
        // Default to one replica
        if (!this.props.properties["Octopus.Action.KubernetesContainers.Replicas"]) {
            this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Replicas"]: "1" });
        }
        // Default to a Rolling Deployment
        if (!this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"]) {
            this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: "RollingUpdate" });
        }
        // Default to OrderedReady
        if (!this.props.properties["Octopus.Action.KubernetesContainers.PodManagementPolicy"]) {
            this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodManagementPolicy"]: "OrderedReady" });
        }
        // Default to an external service name for a stateful set
        if (!this.props.properties["Octopus.Action.KubernetesContainers.ServiceNameType"]) {
            this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceNameType"]: "External" });
        }
        // Default to a creating a deployment
        if (!this.props.properties["Octopus.Action.KubernetesContainers.DeploymentResourceType"]) {
            this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentResourceType"]: "Deployment" });
        }
        // This step never has unnamed packages
        if (this.props.properties["Octopus.Action.Package.FeedId"] || this.props.properties["Octopus.Action.Package.PackageId"]) {
            this.props.setProperties({ ["Octopus.Action.Package.FeedId"]: "" });
            this.props.setProperties({ ["Octopus.Action.Package.PackageId"]: "" });
            this.props.setPackages(this.props.packages.filter((pkg) => pkg.Name), true);
        }
        if (this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.Enabled"] === undefined) {
            this.setDefaultServerSideApply();
        }
    }
    private setDefaultServerSideApply() {
        this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.Enabled"]: "True" });
        this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"]: "True" });
    }
    UNSAFE_componentWillReceiveProps(nextProps: KubernetesDeployContainersActionEditInternalProps) {
        if (this.props.properties["Octopus.Action.KubernetesContainers.Containers"] !== nextProps.properties["Octopus.Action.KubernetesContainers.Containers"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.PersistentVolumeClaims"] !== nextProps.properties["Octopus.Action.KubernetesContainers.PersistentVolumeClaims"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"] !== nextProps.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.PodAffinity"] !== nextProps.properties["Octopus.Action.KubernetesContainers.PodAffinity"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"] !== nextProps.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"] !== nextProps.properties["Octopus.Action.KubernetesContainers.NodeAffinity"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.Tolerations"] !== nextProps.properties["Octopus.Action.KubernetesContainers.Tolerations"] ||
            this.props.properties["Octopus.Action.Package.FeedId"] !== nextProps.properties["Octopus.Action.Package.FeedId"] ||
            this.props.properties["Octopus.Action.Package.PackageId"] !== nextProps.properties["Octopus.Action.Package.PackageId"] ||
            this.props.properties["Octopus.Action.KubernetesContainers.HostAliases"] !== nextProps.properties["Octopus.Action.KubernetesContainers.HostAliases"]) {
            this.setState({
                containers: this.getContainerPackageDetails(nextProps.properties["Octopus.Action.KubernetesContainers.Containers"], nextProps.packages),
                combinedVolumes: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.CombinedVolumes"], []),
                persistentVolumeClaims: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.PersistentVolumeClaims"], []),
                podAffinityDetails: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []),
                podAntiAffinityDetails: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []),
                nodeAffinityDetails: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []),
                tolerations: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.Tolerations"], []),
                hostAliases: JsonUtils.tryParseArray(nextProps.properties["Octopus.Action.KubernetesContainers.HostAliases"], []),
            });
        }
        const yaml = exportDeployment(nextProps, nextProps.feeds);
        if (this.state.resourceYaml !== yaml) {
            this.setState({ resourceYaml: yaml });
        }
    }
    render() {
        const editContainerDialog = (<DialogOpener open={!!this.state.editContainer} onClose={this.resetContainers} wideDialog={true}>
                <ContainerDialog standalone={this.props.standalone} containerDetails={this.state.editContainer} projectId={this.props.projectId!} gitRef={this.props.gitRef} doBusyTask={this.props.doBusyTask} localNames={this.props.localNames!} onAdd={(item) => this.saveContainer(item)} feeds={this.props.feeds} volumes={this.state.combinedVolumes} refreshFeeds={this.loadFeeds} 
        // deferred package properties
        parameters={this.props.parameters}/>
            </DialogOpener>);
        const editPersistentVolumeClaim = (<DialogOpener open={!!this.state.editPersistentVolumeClaim} onClose={this.resetPersistentVolumeClaims} wideDialog={true}>
                <PersistentVolumeClaimDialog standalone={this.props.standalone} persistentVolumeClaimDetails={this.state.editPersistentVolumeClaim} projectId={this.props.projectId!} gitRef={this.props.gitRef} doBusyTask={this.props.doBusyTask} localNames={this.props.localNames!} onAdd={(item) => this.savePersistentVolumeClaim(item)}/>
            </DialogOpener>);
        const editSecretVolumeDialog = (<DialogOpener open={!!this.state.editSecretVolume} onClose={this.resetCombinedVolume} wideDialog={true}>
                <CombinedVolumeDialog standalone={this.props.standalone} combinedVolumeDetails={this.state.editSecretVolume} projectId={this.props.projectId!} gitRef={this.props.gitRef} doBusyTask={this.props.doBusyTask} localNames={this.props.localNames!} featuresEnabled={this.props.properties["Octopus.Action.EnabledFeatures"]} onAdd={(item) => this.saveCombinedVolume(item)}/>
            </DialogOpener>);
        const editPodAffinity = (<DialogOpener open={!!this.state.editPodAffinity} onClose={this.resetPodAffinity} wideDialog={true}>
                <PodAffinityDialog podAffinityDetails={this.state.editPodAffinity} projectId={this.props.projectId!} gitRef={this.props.gitRef} doBusyTask={this.props.doBusyTask} localNames={this.props.localNames!} antiAffinity={false} onAdd={(item) => this.savePodAffinity(item)}/>
            </DialogOpener>);
        const editPodAntiAffinity = (<DialogOpener open={!!this.state.editPodAntiAffinity} onClose={this.resetPodAntiAffinity} wideDialog={true}>
                <PodAffinityDialog podAffinityDetails={this.state.editPodAntiAffinity} projectId={this.props.projectId!} gitRef={this.props.gitRef} doBusyTask={this.props.doBusyTask} localNames={this.props.localNames!} antiAffinity={true} onAdd={(item) => this.savePodAntiAffinity(item)}/>
            </DialogOpener>);
        const editNodeAffinity = (<DialogOpener open={!!this.state.editNodeAffinity} onClose={this.resetNodeAffinity} wideDialog={true}>
                <NodeAffinityDialog nodeAffinityDetails={this.state.editNodeAffinity} projectId={this.props.projectId!} gitRef={this.props.gitRef} doBusyTask={this.props.doBusyTask} localNames={this.props.localNames!} onAdd={(item) => this.saveNodeAffinity(item)}/>
            </DialogOpener>);
        const editTolerations = (<DialogOpener open={!!this.state.editToleration} onClose={this.resetToleration} wideDialog={false}>
                <TolerationDialog toleration={this.state.editToleration!} projectId={this.props.projectId} localNames={this.props.localNames} onAdd={(item) => this.saveToleration(item)}/>
            </DialogOpener>);
        const deploymentResource = getDeploymentResource(this.props);
        const deploymentStrategy = this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"];
        const showLegacyWait = (deploymentResource === "deployment" || deploymentResource === "daemonset" || deploymentResource === "statefulset") && (deploymentStrategy === "RollingUpdate" || deploymentStrategy === "Recreate");
        const relatedTriggersDependencies = buildRelatedTriggerDependencies(this.props.inputDependencies);
        return (<div>
                {editContainerDialog}
                {editPersistentVolumeClaim}
                {editSecretVolumeDialog}
                {editPodAffinity}
                {editPodAntiAffinity}
                {editNodeAffinity}
                {editTolerations}
                {!this.props.standalone && <FormSectionHeading title="Deployment"/>}
                {!this.props.standalone && (<ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.DeploymentYaml" isExpandedByDefault={false} title="Edit YAML" summary={Summary.placeholder("Edit the resource YAML")} help={"Edit the resource YAML."}>
                        {this.props.feeds.some((f) => isContainerImageRegistry(f.FeedType)) ? (<div>
                                <EditResourceYaml yaml={this.state.resourceYaml} onSave={(value) => this.setState({ resourceYaml: value }, () => importDeployment(this.props, this.props.feeds, value))}/>
                            </div>) : (<FeedSelector feedId={undefined} refreshFeeds={this.loadFeeds} feeds={this.props.feeds.filter((f) => isContainerImageRegistry(f.FeedType))} localNames={undefined} feedType={containerRegistryFeedTypes()} allowBoundVariables={false} onChange={noOp}/>)}
                    </ExpandableFormSection>)}
                <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.DeploymentResourceType"} isExpandedByDefault={this.props.expandedByDefault} title="Resource Type" summary={this.resourceTypeSummary()} help={"Select whether to deploy a Deployment, StatefulSet, DaemonSet or Job."}>
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentResourceType"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentResourceType"]: val })}>
                        <RadioButton value="Deployment" label={<span>Deployment</span>}/>
                        <Note>
                            A Deployment provides declarative updates for Pods ReplicaSets. <ExternalLink href="KubernetesDeployment">More information</ExternalLink>.
                        </Note>
                        <RadioButton value="StatefulSet" label={<span>StatefulSet</span>}/>
                        <Note>
                            StatefulSet is the workload API object used to manage stateful applications. <ExternalLink href="KubernetesStatefulSet">More information</ExternalLink>.
                        </Note>
                        <RadioButton value="DaemonSet" label={<span>DaemonSet</span>}/>
                        <Note>
                            A DaemonSet ensures that all (or some) nodes run a copy of a pod. <ExternalLink href="KubernetesDaemonSet">More information</ExternalLink>.
                        </Note>
                        <RadioButton value="Job" label={<span>Job</span>}/>
                        <Note>
                            A Job creates one or more Pods and will continue to retry execution of the Pods until a specified number of them successfully terminate. <ExternalLink href="KubernetesJobResource">More information</ExternalLink>.
                        </Note>
                    </RadioButtonGroup>
                </ExpandableFormSection>
                {getDeploymentResource(this.props) === "deployment" && (<span>
                        <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.DeploymentName|" +
                    "Octopus.Action.KubernetesContainers.Replicas|" +
                    "Octopus.Action.KubernetesContainers.RevisionHistoryLimit|" +
                    "Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds|" +
                    "Octopus.Action.KubernetesContainers.DeploymentLabels"} isExpandedByDefault={this.props.expandedByDefault} title="Deployment" summary={this.deploymentSummary()} help={"Enter the details for the deployment."}>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DeploymentName")} label="Deployment name"/>
                            <Note>The name of the deployment must be unique, and is used by Kubernetes when updating an existing resource.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesDeploymentName">deployment names</ExternalLink>.
                            </Note>
                            {!this.props.standalone && (<div>
                                    <Note>
                                        Blue/green deployment strategies create a new uniquely named deployment resource each time, and directs the service to the new pods. The Octopus deployment ID will be appended to the deployment name e.g.{" "}
                                        <code>my-deployment</code>
                                        will become <code>my-deployment-deployments-981</code>.
                                    </Note>
                                    <Note>
                                        Learn more about <ExternalLink href="KubernetesBlueGreenDeploymentStrategy">blue/green deployments</ExternalLink>.
                                    </Note>
                                </div>)}
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.Replicas"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Replicas"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.Replicas")} label="Replicas" placeholder="1 (default)"/>
                            <Note>The number of pod replicas to create from this deployment.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesReplicas">replicas</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.RevisionHistoryLimit"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.RevisionHistoryLimit"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.RevisionHistoryLimit")} label="Revision history limit" placeholder=""/>
                            <Note>The number of revisions to retain.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesRevisionHistoryLimit">revision history limits</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.ProgressDeadlineSeconds")} label="Progression deadline in seconds" placeholder="600 (default)"/>
                            <Note>The maximum time for a deployment to make progress before it's considered to be failed. Blue/Green deployments will point the service to the new deployment only once the new deployments has succeeded.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesProgressDeadlineSeconds">progression deadlines</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.TerminationGracePeriodSeconds")} label="Pod termination grace period in seconds" placeholder="30 (default)"/>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesTerminationGracePeriodSeconds">pod termination grace period</ExternalLink>.
                            </Note>
                            <p>
                                <strong>Labels</strong>
                                <br />
                                Add labels to be applied to the deployment resource, pods managed by the deployment resource, the service and the ingress.
                            </p>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesAddLabel">labels</ExternalLink>.
                            </Note>
                            <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]} name="Label" separator="" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.DeploymentStyle" isExpandedByDefault={this.props.expandedByDefault} title="Deployment Strategy" summary={this.deploymentStyleSummary()} help={"Choose how the deployment will be updated."}>
                            <RadioButtonGroup value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: val })}>
                                <RadioButton value="Recreate" label={<span>
                                            <strong>Recreate deployments</strong> delete existing pods before creating new pods
                                        </span>}/>
                                <Note>Use this option when container versions can not be mixed. This strategy does result in downtime.</Note>
                                <Note>
                                    Learn more about the <ExternalLink href="KubernetesRecreateDeploymentStrategy">recreate strategy</ExternalLink>.
                                </Note>
                                <RadioButton value="RollingUpdate" label={<span>
                                            <strong>Rolling update deployments</strong> deploys new pods while remove older pods
                                        </span>}/>
                                <Note>This option requires that two container versions can run side by side, and avoids downtime.</Note>
                                <Note>
                                    Learn more about the <ExternalLink href="KubernetesRollingUpdateDeploymentStrategy">rolling update strategy</ExternalLink>.
                                </Note>
                                {!this.props.standalone && (<RadioButton value="BlueGreen" label={<span>
                                                <strong>Blue/Green deployments</strong> create a new deployment resource and points the service to new pods
                                            </span>}/>)}
                                {!this.props.standalone && (<div>
                                        <Note>This strategy requires that two container versions can run side by side, and ensures that traffic is cut over to the new pods in a single operation with no downtime.</Note>
                                        <Note>
                                            Learn more about the <ExternalLink href="KubernetesBlueGreenDeploymentStrategy">blue/green strategy</ExternalLink>.
                                        </Note>
                                    </div>)}
                                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "RollingUpdate" && (<div>
                                        <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxUnavailable"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.MaxUnavailable")} label="Max Unavailable"/>
                                        <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.MaxSurge"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxSurge"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.MaxSurge")} label="Max Surge"/>
                                    </div>)}
                            </RadioButtonGroup>
                        </ExpandableFormSection>
                    </span>)}
                {getDeploymentResource(this.props) === "daemonset" && (<span>
                        <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.DeploymentName|" + "Octopus.Action.KubernetesContainers.RevisionHistoryLimit"} isExpandedByDefault={this.props.expandedByDefault} title="DaemonSet" summary={this.deploymentSummary()} help={"Enter the details for the daemon set."}>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DeploymentName")} label="DaemonSet name"/>
                            <Note>The name of the daemon set must be unique, and is used by Kubernetes when updating an existing resource.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesDeploymentName">daemon set names</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.RevisionHistoryLimit"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.RevisionHistoryLimit"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.RevisionHistoryLimit")} label="Revision history limit" placeholder=""/>
                            <Note>The number of revisions to retain.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesRevisionHistoryLimit">revision history limits</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.MinReadySeconds"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.MinReadySeconds"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.MinReadySeconds")} label="Minimum ready time in seconds" placeholder="0 (default)"/>
                            <Note>The minimum number of seconds for which a newly created DaemonSet pod should be ready without any of its container crashing, for it to be considered available.</Note>
                            <Note>
                                Learn more about the <ExternalLink href="KubernetesDaemonSetMinReadySeconds">min ready seconds</ExternalLink> option.
                            </Note>
                            <p>
                                <strong>Labels</strong>
                                <br />
                                Add labels to be applied to the deployment resource, pods managed by the deployment resource, the service and the ingress.
                            </p>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesAddLabel">labels</ExternalLink>.
                            </Note>
                            <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]} name="Label" separator="" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.DeploymentStyle" isExpandedByDefault={this.props.expandedByDefault} title="Deployment Strategy" summary={this.deploymentStyleSummary()} help={"Choose how the daemon set will be updated."}>
                            <RadioButtonGroup value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: val })}>
                                <RadioButton value="RollingUpdate" label={<span>
                                            <strong>Rolling update deployments</strong> deploys new pods while remove older pods
                                        </span>}/>
                                <Note>This option requires that two container versions can run side by side, and avoids downtime.</Note>
                                <Note>
                                    Learn more about the <ExternalLink href="KubernetesRollingUpdateDeploymentStrategy">rolling update strategy</ExternalLink>.
                                </Note>
                                <RadioButton value="OnDelete" label={<span>
                                            <strong>OnDelete</strong> will recreate pods only when the existing pods are manually deleted
                                        </span>}/>
                                {!this.props.standalone && (<RadioButton value="BlueGreen" label={<span>
                                                <strong>Blue/Green deployments</strong> create a new deployment resource and points the service to new pods
                                            </span>}/>)}
                                {!this.props.standalone && (<div>
                                        <Note>This strategy requires that two container versions can run side by side, and ensures that traffic is cut over to the new pods in a single operation with no downtime.</Note>
                                        <Note>
                                            Learn more about the <ExternalLink href="KubernetesBlueGreenDeploymentStrategy">blue/green strategy</ExternalLink>.
                                        </Note>
                                    </div>)}
                                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "RollingUpdate" && (<VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.MaxUnavailable"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.MaxUnavailable")} label="Max Unavailable"/>)}
                            </RadioButtonGroup>
                        </ExpandableFormSection>
                    </span>)}
                {getDeploymentResource(this.props) === "statefulset" && (<span>
                        <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.DeploymentName|" +
                    "Octopus.Action.KubernetesContainers.Replicas|" +
                    "Octopus.Action.KubernetesContainers.DeploymentLabels|" +
                    "Octopus.Action.KubernetesContainers.RevisionHistoryLimit"} isExpandedByDefault={this.props.expandedByDefault} title="StatefulSet" summary={this.deploymentSummary()} help={"Enter the details for the stateful set."}>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DeploymentName")} label="StatefulSet name"/>
                            <Note>The name of the stateful set must be unique, and is used by Kubernetes when updating an existing resource.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesDeploymentName">stateful set names</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.Replicas"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Replicas"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.Replicas")} label="Replicas" placeholder="1 (default)"/>
                            <Note>The number of pod replicas to create from this deployment.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesReplicas">replicas</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.RevisionHistoryLimit"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.RevisionHistoryLimit"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.RevisionHistoryLimit")} label="Revision history limit" placeholder=""/>
                            <Note>The number of revisions to retain.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesRevisionHistoryLimit">revision history limits</ExternalLink>.
                            </Note>
                            <p>
                                <strong>Pod management policy</strong>
                            </p>
                            <RadioButtonGroup value={this.props.properties["Octopus.Action.KubernetesContainers.PodManagementPolicy"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodManagementPolicy"]: val })}>
                                <RadioButton value="OrderedReady" label={<span>OrderedReady</span>}/>
                                <Note>Pods are created in increasing order (pod-0, then pod-1, etc) and the controller will wait until each pod is ready before continuing. When scaling down, the pods are removed in the opposite order.</Note>
                                <RadioButton value="Parallel" label={<span>Parallel</span>}/>
                                <Note>Creates pods in parallel to match the desired scale without waiting, and on scale down will delete all pods at once.</Note>
                            </RadioButtonGroup>
                            <p>
                                <strong>Service name</strong>
                            </p>
                            <RadioButtonGroup value={this.props.properties["Octopus.Action.KubernetesContainers.ServiceNameType"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ServiceNameType"]: val })}>
                                <RadioButton value="External" label={<span>External service</span>}/>
                                <Note>The name of the service that governs this stateful set. This service must exist before the stateful set is deployed.</Note>
                                {this.props.properties["Octopus.Action.KubernetesContainers.ServiceNameType"] === "External" && (<div>
                                        <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.StatefulSetServiceName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.StatefulSetServiceName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.StatefulSetServiceName")} label="Service name"/>
                                        <Note>The name of the service that governs this StatefulSet.</Note>
                                    </div>)}
                                <RadioButton value="Managed" label={<span>Service managed by this step</span>}/>
                                <Note>Use the service resource managed by this step to governs this stateful set.</Note>
                            </RadioButtonGroup>
                            <p>
                                <strong>Labels</strong>
                                <br />
                                Add labels to be applied to the deployment resource, pods managed by the deployment resource, the service and the ingress.
                            </p>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesAddLabel">labels</ExternalLink>.
                            </Note>
                            <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]} name="Label" separator="" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                        </ExpandableFormSection>
                        <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.DeploymentStyle" isExpandedByDefault={this.props.expandedByDefault} title="Deployment Strategy" summary={this.deploymentStyleSummary()} help={"Choose how the stateful set will be updated."}>
                            <RadioButtonGroup value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentStyle"]: val })}>
                                <RadioButton value="RollingUpdate" label={<span>
                                            <strong>Rolling update deployments</strong> deploys new pods while removing older pods.
                                        </span>}/>
                                <Note>This option requires that two container versions can run side by side, and avoids downtime.</Note>
                                <Note>
                                    Learn more about the <ExternalLink href="KubernetesRollingUpdateDeploymentStrategy">rolling update strategy</ExternalLink>.
                                </Note>
                                <RadioButton value="OnDelete" label={<span>
                                            <strong>On Delete</strong> will recreate pods only when the existing pods are manually deleted.
                                        </span>}/>
                                {!this.props.standalone && (<RadioButton value="BlueGreen" label={<span>
                                                <strong>Blue/Green deployments</strong> create a new deployment resource and points the service to new pods
                                            </span>}/>)}
                                {!this.props.standalone && (<div>
                                        <Note>This strategy requires that two container versions can run side by side, and ensures that traffic is cut over to the new pods in a single operation with no downtime.</Note>
                                        <Note>
                                            Learn more about the <ExternalLink href="KubernetesBlueGreenDeploymentStrategy">blue/green strategy</ExternalLink>.
                                        </Note>
                                    </div>)}
                            </RadioButtonGroup>
                        </ExpandableFormSection>
                    </span>)}
                {getDeploymentResource(this.props) === "job" && (<span>
                        <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.DeploymentName|" +
                    "Octopus.Action.KubernetesContainers.Completions|" +
                    "Octopus.Action.KubernetesContainers.Parallelism|" +
                    "Octopus.Action.KubernetesContainers.BackoffLimit|" +
                    "Octopus.Action.KubernetesContainers.ActiveDeadlineSeconds|" +
                    "Octopus.Action.KubernetesContainers.TtlSecondsAfterFinished|" +
                    "Octopus.Action.KubernetesContainers.DeploymentLabels"} isExpandedByDefault={this.props.expandedByDefault} title="Job" summary={this.deploymentSummary()} help={"Enter the details for the job."}>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DeploymentName")} label="Job name"/>
                            <Note>The name of the job must be unique, and is used by Kubernetes when updating an existing resource.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesDeploymentName">job names</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.Completions"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Completions"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.Completions")} label="Completions" placeholder="1 (default)"/>
                            <Note>Using completions to initiate several pods one after the other.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesCompletions">completions</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.Parallelism"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Parallelism"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.Parallelism")} label="Parallelism"/>
                            <Note>An optional value to specify how many pods should run in parallel.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesParallelism">parallelism</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.BackoffLimit"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.BackoffLimit"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.BackoffLimit")} label="Backoff Limit"/>
                            <Note>Specify the maximum number of pods should be created for this job.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesBackoffLimit">backoff limit</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.ActiveDeadlineSeconds"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ActiveDeadlineSeconds"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.ActiveDeadlineSeconds")} label="Active Deadline Seconds"/>
                            <Note>Specify an execution timeout for the job.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesActiveDeadlineSeconds">active deadline seconds</ExternalLink>.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.TtlSecondsAfterFinished"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.TtlSecondsAfterFinished"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.TtlSecondsAfterFinished")} label="TTL Seconds After Finished"/>
                            <Note>The total number of seconds to be waited to clean up the job after its execution completes.</Note>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesTtlSecondsAfterFinished">ttl limit seconds after finished</ExternalLink>.
                            </Note>
                            <p>
                                <strong>Labels</strong>
                                <br />
                                Add labels to be applied to the deployment resource, pods managed by the deployment resource, the service and the ingress.
                            </p>
                            <Note>
                                Learn more about <ExternalLink href="KubernetesAddLabel">labels</ExternalLink>.
                            </Note>
                            <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]} name="Label" separator="" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                        </ExpandableFormSection>
                    </span>)}
                <KubernetesStatusCheckComponent jobsSupported={getDeploymentResource(this.props) === "job"} timeoutSupported={true} statusCheckSupported={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] !== "BlueGreen"} notSupportedReason={<Callout title="This feature isn’t currently supported for Blue/Green deployments" type={"information"}>
                            Please select <strong>Recreate deployments</strong> or <strong>Rolling update deployments</strong> above to enable the Kubernetes Object Status feature.
                        </Callout>} showLegacyWait={showLegacyWait} properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} errors={this.props.errors} busy={this.props.busy} expandedByDefault={this.props.expandedByDefault} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask}/>
                <ExpandableFormSection errorKey="Octopus.Action.Kubernetes.SecretVolumes" isExpandedByDefault={this.props.expandedByDefault} title="Volumes" summary={this.volumeSummary()} help={"Add volumes to be exposed to the containers in this step."}>
                    <Note>
                        Volumes can reference externally managed storage devices, or can reference config map and secret resources managed by this step through the <strong>ConfigMap</strong> and <strong>Secret</strong> features.
                    </Note>
                    <Note>
                        Learn more about <ExternalLink href="KubernetesSecretVolumes">volumes</ExternalLink>.
                    </Note>
                    <CombinedVolumeList listActions={[<ActionButton key="add" label="Add Volume" onClick={() => this.addCombinedVolume()}/>]} data={this.state.combinedVolumes} onRow={this.combinedVolumeListItem} onRowTouch={(item) => this.editCombinedVolume(item)} onRemoveRow={(item) => this.removeCombinedVolume(item)}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Kubernetes.HostAliases" isExpandedByDefault={this.props.expandedByDefault} title="Host Aliases" summary={this.hostAliasSummary()} help={"Add Host Aliases."}>
                    <StringExtendedKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.HostAliases"]} name="Host Alias" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.HostAliases"]: val })} valueLabel="Host names, a comma seperated list" keyLabel="IP" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef} addToTop={true}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.Containers" isExpandedByDefault={this.props.expandedByDefault} title="Containers" summary={this.containersSummary()} help={"Add containers that make up the pod managed by this deployment"}>
                    <ContainerList listActions={[<ActionButton key="add" label="Add Container" onClick={() => this.addContainerViaDialog()}/>]} data={this.state.containers} onRow={this.containerListItem} onRowTouch={(item) => this.editContainerViaDialog(item)} onRemoveRow={(item) => this.removeContainerViaDialog(item)}/>

                    {relatedTriggersDependencies && <CalloutReferencedPackageTriggerConnections dependencies={relatedTriggersDependencies} packageReferences={this.state.containers} displayAvailableTriggerCallout={true}/>}
                    {relatedTriggersDependencies && <CalloutModifiedTriggerConnections dependencies={relatedTriggersDependencies} packageReferences={this.state.containers} modelDirty={this.props.modelDirty ?? false}/>}
                </ExpandableFormSection>
                {getDeploymentResource(this.props) === "statefulset" && (<ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.PersistentVolumeClaims" isExpandedByDefault={this.props.expandedByDefault} title="Persistent Volume Claims" summary={this.statefulSetPersistentVolumeClaimSummary()} help={"Add persistent volume claims used by the stateful set"}>
                        <PersistentVolumeClaimList listActions={[<ActionButton key="add" label="Add PVC" onClick={() => this.addPersistentVolumeClaimViaDialog()}/>]} data={this.state.persistentVolumeClaims} onRow={this.persistentVolumeClaimListItem} onRowTouch={(item) => this.editPersistentVolumeClaimViaDialog(item)} onRemoveRow={(item) => this.removePersistentVolumeClaimViaDialog(item)}/>
                    </ExpandableFormSection>)}
                <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.PriorityClassName"} title="Pod Priority Class Name" summary={this.podPriorityClassName()} help={"Set the Priority Class Name for your pod"}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PriorityClassName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PriorityClassName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PriorityClassName")} label="Pod priority class name" multiline={false}/>
                    <Note>
                        Learn more about <ExternalLink href="K8sPodPriorityDocs">pod priority class names</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.RestartPolicy"} title="Pod Restart Policy" summary={this.podRestartPolicySummary()} help={"Define the restart policy for the pods in this deployment."}>
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"Always"} value={this.props.properties["Octopus.Action.KubernetesContainers.RestartPolicy"] || "Always"} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.RestartPolicy"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.RestartPolicy")} title="The restart policy">
                        {getDeploymentResource(this.props) !== "job" && <RadioButton value={"Always"} label="Always"/>}
                        <RadioButton value={"OnFailure"} label="On Failure"/>
                        <RadioButton value={"Never"} label="Never"/>
                    </BoundRadioButtonGroup>
                    <Note>
                        A pod's restart policy determines how and when it should restart. <ExternalLink href="K8sRestartPolicyDocs">More information</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.DnsPolicy"} title="DNS Policy" summary={this.podDnsPolicySummary()} help={"Define the DNS policy for the pods in this deployment."}>
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"ClusterFirst"} value={this.props.properties["Octopus.Action.KubernetesContainers.DnsPolicy"] || "ClusterFirst"} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DnsPolicy"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DnsPolicy")} title="The DNS policy">
                        <RadioButton value={"ClusterFirst"} label="Cluster First"/>
                        <RadioButton value={"ClusterFirstWithHostNet"} label="Cluster First With Host Net"/>
                        <RadioButton value={"Default"} label="Default"/>
                        <RadioButton value={"None"} label="None"/>
                    </BoundRadioButtonGroup>
                    <Note>
                        A pod's DNS policy determines how name resolution is performed. <ExternalLink href="K8SPodDNSPolicy">More information</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.DnsConfigNameservers|Octopus.Action.KubernetesContainers.DnsConfigSearches|Octopus.Action.KubernetesContainers.DnsConfigOptions"} title="DNS Config" summary={this.podDnsConfigSummary()} help={"Define the DNS configuration for the pods in this deployment."}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigNameservers"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DnsConfigNameservers"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DnsConfigNameservers")} label="Nameservers" multiline={true}/>
                    <Note>A line separated list of up to 3 DNS name server IP addresses.</Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigSearches"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DnsConfigSearches"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DnsConfigSearches")} label="Searches" multiline={true}/>
                    <Note>A line separated list of up to 6 DNS search domains for host-name lookup.</Note>
                    <StringExtendedKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigOptions"]} name="Options" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DnsConfigOptions"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                    <Note>A list of DNS resolver options. Names are required, values are optional.</Note>
                    <Note>
                        A pod's DNS configuration provides settings when the pod DNS policy is set to `None`. <ExternalLink href="K8SPodDNSConfiguration">More information</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey={"Octopus.Action.KubernetesContainers.HostNetwork"} title="Host Networking" summary={this.podHostNetworkSummary()} help={"Define if the policy allows the use of HostNetwork in the pod spec."}>
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"False"} value={this.props.properties["Octopus.Action.KubernetesContainers.HostNetwork"] || "False"} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.HostNetwork"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.HostNetwork")} title="Host networking">
                        <RadioButton value={"True"} label="Enable host networking"/>
                        <RadioButton value={"False"} label="Disable host networking"/>
                    </BoundRadioButtonGroup>
                    <Note>
                        Controls whether the pod may use the node network namespace. Doing so gives the pod access to the loopback device, services listening on localhost, and could be used to snoop on network activity of other pods on the same node.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey={"Octopus.Action.Kubernetes.PodSecurityFsGroup|" +
                "Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup|" +
                "Octopus.Action.KubernetesContainers.PodSecurityRunAsUser|" +
                "Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot|" +
                "Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"} title="Pod Security Context" summary={this.podSecuritySummary()} help={"The security context to be applied to the pods."}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecurityFsGroup")} label="FSGroup"/>
                    <Note>
                        A special supplemental group ID that applies to all containers in a pod. <ExternalLink href="KubernetesSecurityContext">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup")} label="Run as group ID"/>
                    <Note>
                        The group ID to run the entrypoint of the container process. Introduced in Kubernetes 1.10. <ExternalLink href="KubernetesSecurityContext">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecurityRunAsUser")} label="Run as user ID"/>
                    <Note>
                        The user ID to run the entrypoint of the container process. <ExternalLink href="KubernetesSecurityContext">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"]} onChange={(x) => this.props.setProperties({
                ["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"]: 
                // get the items
                x
                    .split(",")
                    // trim the items
                    .map((y) => y.trim())
                    // remove empty items
                    .filter((y) => y)
                    // join the list again
                    .join(", ") +
                    // was the last char a comma and optional whitespace? If so, add it back on
                    (/,\s*$/.exec(x) || [""])[0],
            })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups")} label="Supplemental groups"/>
                    <Note>
                        A comma separated list of group IDs applied to the first process run in each container, in addition to the container's primary group ID.{" "}
                        <ExternalLink href="KubernetesSecurityContextSupplementalGroups">More information</ExternalLink>.
                    </Note>
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"False"} value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot")} title="Run as non-root">
                        <RadioButton value={"True"} label="Enable run as non-root"/>
                        <RadioButton value={"False"} label="Do not enable run as non-root"/>
                    </BoundRadioButtonGroup>
                    <Note>
                        Require that containers run as a non-root user. <ExternalLink href="KubernetesSecurityContextRunAsNonRoot">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel")} label="SELinux level"/>
                    <Note>
                        The SELinux level that applies to the container. <ExternalLink href="KubernetesSecurityContextSELinux">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole")} label="SELinux role"/>
                    <Note>
                        The SELinux role that applies to the container. <ExternalLink href="KubernetesSecurityContextSELinux">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType")} label="SELinux type"/>
                    <Note>
                        The SELinux type that applies to the container. <ExternalLink href="KubernetesSecurityContextSELinux">More information</ExternalLink>.
                    </Note>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser")} label="SELinux user"/>
                    <Note>
                        The SELinux user that applies to the container. <ExternalLink href="KubernetesSecurityContextSELinux">More information</ExternalLink>.
                    </Note>
                    <StringExtendedKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]} name="Sysctl" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                    <Note>
                        The list of namespaced sysctls used for the pod. Introduced in Kubernetes 1.11. <ExternalLink href="KubernetesSecuritySysctl">More information</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.PodAffinity" isExpandedByDefault={this.props.expandedByDefault} title="Pod Affinity / Anti-Affinity" summary={this.podAffinitySummary()} help={"Define the pod affinity or anti-affinity that determines where the pod resources created by this deployment resource will be placed."}>
                    <PodAffinityList listActions={[<ActionButton key="add" label="Add Pod Affinity" onClick={() => this.addPodAffinity()}/>]} data={this.state.podAffinityDetails} onRow={this.podAffinityListItem(false)} onRowTouch={(item) => this.editPodAffinity(item)} onRemoveRow={(item) => this.removePodAffinity(item)}/>
                    <PodAffinityList listActions={[<ActionButton key="add" label="Add Pod Anti-Affinity" onClick={() => this.addPodAntiAffinity()}/>]} data={this.state.podAntiAffinityDetails} onRow={this.podAffinityListItem(true)} onRowTouch={(item) => this.editPodAntiAffinity(item)} onRemoveRow={(item) => this.removePodAntiAffinity(item)}/>
                    <Note>
                        Pod affinity rules determine which nodes a pod will be placed on based on the presence or absence of other pods. <ExternalLink href="K8SPodAffinity">More information</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.NodeAffinity" isExpandedByDefault={this.props.expandedByDefault} title="Node Affinity" summary={this.nodeAffinitySummary()} help={"Define the node affinity that determines where the pod resources created by this deployment resource will be placed."}>
                    <NodeAffinityList listActions={[<ActionButton key="add" label="Add Node Affinity" onClick={() => this.addNodeAffinity()}/>]} data={this.state.nodeAffinityDetails} onRow={this.nodeAffinityListItem} onRowTouch={(item) => this.editNodeAffinity(item)} onRemoveRow={(item) => this.removeNodeAffinity(item)}/>
                    <Note>
                        Pods can be scheduled onto a node as long as at least one of the <code>Required</code> rules is satisfied. In effect this means the
                        <code>Required</code> rules are combined with the boolean "or" operator. <code>Preferred</code> rules will attempt to be satisfied, but if not the pods will still be scheduled.
                    </Note>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.Tolerations" isExpandedByDefault={this.props.expandedByDefault} title="Tolerations" summary={this.tolerationsSummary()} help={"Add tolerations to allow pod resources to be scheduled on tainted nodes"}>
                    <TolerationsList listActions={[<ActionButton key="add" label="Add Toleration" onClick={() => this.addToleration()}/>]} data={this.state.tolerations} onRow={this.tolerationListItem} onRowTouch={(item) => this.editToleration(item)} onRemoveRow={(item) => this.removeToleration(item)}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.PodAnnotations" isExpandedByDefault={this.props.expandedByDefault} title="Pod Annotations" summary={this.podAnnotationsSummary()} help={"Add annotations to configure the pods resources created by the deployment resource."}>
                    <StringExtendedKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.PodAnnotations"]} name="Annotation" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAnnotations"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef} addToTop={true}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.DeploymentAnnotations" isExpandedByDefault={this.props.expandedByDefault} title={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentResourceType"] + " Annotations"} summary={this.deploymentAnnotationsSummary()} help={"Add annotations to configure the " + this.props.properties["Octopus.Action.KubernetesContainers.DeploymentResourceType"] + " resource."}>
                    <StringExtendedKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentAnnotations"]} name="Annotation" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentAnnotations"]: val })} valueLabel="Value" keyLabel="Name" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef} addToTop={true}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.ServiceAccountName" isExpandedByDefault={this.props.expandedByDefault} title="Service Account Name" summary={this.serviceAccountNameSummary()} help={"Configure the service account for the pod"}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodServiceAccountName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodServiceAccountName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodServiceAccountName")} label="Service Account Name"/>
                    <Note>
                        When processes in containers inside pods access the cluster API they are authenticated as a <ExternalLink href="KubernetesPodServiceAccountName">service account</ExternalLink>.
                    </Note>
                    <Note>Leaving this field blank results in no service account being specified in the pod spec. When the pods are created, the default service account in the same namespace will be automatically assigned.</Note>
                </ExpandableFormSection>
                <ExpandableFormSection isExpandedByDefault={this.props.expandedByDefault} title="Readiness Gates" errorKey={"ContainerDetailsReadinessGates"} summary={this.kubernetesReadinessGatesSummary()} help={"Define the readiness gates to assign to the container."}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.PodReadinessGates"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodReadinessGates"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.PodReadinessGates")} label="Pod readiness gates" multiline={true}/>
                    <Note>
                        A new line separated list of pod readiness gates. <ExternalLink href={"KubernetesReadinessGates"}>More information</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                {!this.props.standalone && (<KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={(ns) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })}/>)}
                <FormSectionHeading title="Additional Configuration Options"/>
                <ServerSideApplyFormSection enabled={this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.Enabled"] === "True"} forceConflicts={this.props.properties["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"] === "True"} onEnabledChange={(value) => this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.Enabled"]: toBoolString(value) })} onForceConflictsChange={(value) => this.props.setProperties({ ["Octopus.Action.Kubernetes.ServerSideApply.ForceConflicts"]: toBoolString(value) })}></ServerSideApplyFormSection>
            </div>);
    }
    private newContainer = (): ContainerPackageDetails => {
        const item: ContainerPackageDetails = {
            Id: null!,
            IsNew: true,
            Ports: [],
            EnvironmentVariables: [],
            SecretEnvironmentVariables: [],
            SecretEnvFromSource: [],
            ConfigMapEnvironmentVariables: [],
            ConfigMapEnvFromSource: [],
            CreateFeedSecrets: "True",
            FieldRefEnvironmentVariables: [],
            VolumeMounts: [],
            Name: "",
            PackageId: "",
            FeedId: "",
            AcquisitionLocation: PackageAcquisitionLocation.NotAcquired,
            Properties: {
                Extract: "False",
                PackageParameterName: "",
                SelectionMode: PackageSelectionMode.Immediate,
            },
            Command: [],
            Args: [],
            Resources: {
                requests: {
                    memory: "",
                    cpu: "",
                    ephemeralStorage: "",
                    amdGpu: "",
                    nvidiaGpu: "",
                    storage: "",
                },
                limits: {
                    memory: "",
                    cpu: "",
                    ephemeralStorage: "",
                    amdGpu: "",
                    nvidiaGpu: "",
                    storage: "",
                },
            },
            TerminationMessagePath: "",
            TerminationMessagePolicy: "",
            LivenessProbe: {
                failureThreshold: "",
                initialDelaySeconds: "",
                periodSeconds: "",
                successThreshold: "",
                timeoutSeconds: "",
                type: "",
                exec: {
                    command: [],
                },
                httpGet: {
                    host: "",
                    path: "",
                    port: "",
                    scheme: "",
                    httpHeaders: [],
                },
                tcpSocket: {
                    host: "",
                    port: "",
                },
            },
            StartupProbe: {
                failureThreshold: "",
                initialDelaySeconds: "",
                periodSeconds: "",
                successThreshold: "",
                timeoutSeconds: "",
                type: "",
                exec: {
                    command: [],
                },
                httpGet: {
                    host: "",
                    path: "",
                    port: "",
                    scheme: "",
                    httpHeaders: [],
                },
                tcpSocket: {
                    host: "",
                    port: "",
                },
            },
            ReadinessProbe: {
                failureThreshold: "",
                initialDelaySeconds: "",
                periodSeconds: "",
                successThreshold: "",
                timeoutSeconds: "",
                type: "",
                exec: {
                    command: [],
                },
                httpGet: {
                    host: "",
                    path: "",
                    port: "",
                    scheme: "",
                    httpHeaders: [],
                },
                tcpSocket: {
                    host: "",
                    port: "",
                },
            },
            Lifecycle: {
                PreStop: null!,
                PostStart: null!,
            },
            SecurityContext: {
                allowPrivilegeEscalation: "",
                privileged: "",
                readOnlyRootFilesystem: "",
                runAsGroup: "",
                runAsNonRoot: "",
                runAsUser: "",
                capabilities: {
                    add: [],
                    drop: [],
                },
                seLinuxOptions: {
                    level: "",
                    role: "",
                    type: "",
                    user: "",
                },
            },
        };
        return item;
    };
    private addContainerViaDialog = () => {
        const item = this.newContainer();
        this.setState({
            editContainer: item,
            editContainerIndex: null!,
        });
    };
    private editContainerViaDialog = (item: ContainerPackageDetails) => {
        this.setState({
            editContainer: clone(item),
            editContainerIndex: this.state.containers.indexOf(item),
        });
    };
    private removeContainerViaDialog = (item: ContainerPackageDetails) => {
        const packages = [...this.props.packages];
        packages.splice(packages.findIndex((pkg) => pkg.Name === item.Name), 1);
        this.props.setPackages(packages);
        const items = [...this.state.containers].filter((container) => packages.findIndex((pkg) => pkg.Name === container.Name) !== -1);
        this.saveContainersJson(items);
    };
    private resetContainers = () => {
        this.setState({
            editContainer: null!,
            editContainerIndex: null!,
        });
    };
    private saveContainer = (item: ContainerPackageDetails) => {
        const packageReferences = [...this.props.packages];
        const items = [...this.state.containers];
        if (this.state.editContainerIndex === null) {
            packageReferences.push(item);
            items.push(item);
        }
        else {
            packageReferences[this.state.editContainerIndex] = item;
            items[this.state.editContainerIndex] = item;
        }
        /*
            The creation or not of a feed secret is synchronised with any other containers that reference
            the same feed. This ensures that one container isn't trying to create a feed secret while
            another is not, because if one container creates the secret, all containers from that feed
            use the secret.
         */
        items.filter((i) => i.FeedId == item.FeedId).forEach((i) => (i.CreateFeedSecrets = item.CreateFeedSecrets || "True"));
        this.props.setPackages(packageReferences);
        this.saveContainersJson(items);
        this.resetContainers();
        return true;
    };
    private containerListItem = (pkg: ContainerPackageDetails) => {
        return (<div>
                <ListTitle>{pkg.Name}</ListTitle>

                <ProcessFeedLookup feedId={pkg.FeedId}>
                    {(feed) => (<div className={styles.summaryText}>
                            Configuring the <strong>{pkg.PackageId}</strong> {pkg.InitContainer === "True" && <strong>init</strong>} container from feed <strong>{feed ? feed.Name : pkg.FeedId}</strong>
                        </div>)}
                </ProcessFeedLookup>

                {_.get(pkg, "Resources.requests.memory") && (<div className={styles.summaryText}>
                        Requesting <strong>{pkg.Resources.requests.memory}</strong> of memory
                        {pkg.Resources.limits.memory && (<span>
                                {" "}
                                with a limit of <strong>{pkg.Resources.limits.memory}</strong>
                            </span>)}
                    </div>)}

                {!_.get(pkg, "Resources.requests.memory") && _.get(pkg, "Resources.limits.memory") && (<div className={styles.summaryText}>
                        Limited to <strong>{pkg.Resources.limits.memory}</strong> of memory
                    </div>)}

                {_.get(pkg, "Resources.requests.cpu") && (<div className={styles.summaryText}>
                        Requesting <strong>{pkg.Resources.requests.cpu}</strong> of CPU
                        {pkg.Resources.limits.cpu && (<span>
                                {" "}
                                with a limit of <strong>{pkg.Resources.limits.cpu}</strong>
                            </span>)}
                    </div>)}

                {!_.get(pkg, "Resources.requests.cpu") && _.get(pkg, "Resources.limits.cpu") && (<div className={styles.summaryText}>
                        Limited to <strong>{pkg.Resources.limits.cpu}</strong> of CPU
                    </div>)}

                {_.get(pkg, "Resources.limits.nvidiaGpu") && (<div className={styles.summaryText}>
                        Limited to <strong>{pkg.Resources.limits.nvidiaGpu}</strong> of Nvidia GPU
                    </div>)}

                {_.get(pkg, "Resources.limits.amdGpu") && (<div className={styles.summaryText}>
                        Limited to <strong>{pkg.Resources.limits.amdGpu}</strong> of AMD GPU
                    </div>)}

                {_.get(pkg, "Resources.requests.ephemeralStorage") && (<div className={styles.summaryText}>
                        Requesting <strong>{pkg.Resources.requests.ephemeralStorage}</strong> of ephemeral storage
                        {pkg.Resources.limits.ephemeralStorage && (<span>
                                {" "}
                                with a limit of <strong>{pkg.Resources.limits.ephemeralStorage}</strong>
                            </span>)}
                    </div>)}

                {!_.get(pkg, "Resources.requests.ephemeralStorage") && _.get(pkg, "Resources.limits.ephemeralStorage") && (<div className={styles.summaryText}>
                        Limited to <strong>{pkg.Resources.limits.ephemeralStorage}</strong> of ephemeral storage
                    </div>)}

                {pkg.Ports && _.isArray(pkg.Ports) && pkg.Ports.length !== 0 && (<div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Ports</div>
                        {pkg.Ports.map((rule) => (<div key={rule.key} className={styles.summarySectionValue}>
                                {rule.key && <span>{rule.key}: </span>}
                                {rule.value}/{rule.option || "TCP"}
                            </div>))}
                    </div>)}

                {pkg.EnvironmentVariables && _.isArray(pkg.EnvironmentVariables) && pkg.EnvironmentVariables.length !== 0 && (<div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Environment Variables</div>
                        {pkg.EnvironmentVariables.map((envVar) => (<div key={envVar.key} className={styles.summarySectionValue}>
                                {envVar.key} = "{envVar.value}"
                            </div>))}
                    </div>)}

                {pkg.SecretEnvironmentVariables && _.isArray(pkg.SecretEnvironmentVariables) && pkg.SecretEnvironmentVariables.length !== 0 && (<div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Secret Environment Variables</div>
                        {pkg.SecretEnvironmentVariables.map((envVar) => (<div className={styles.summarySectionValue}>
                                {envVar.key} = {envVar.value}/{envVar.option}
                            </div>))}
                    </div>)}

                {pkg.SecretEnvFromSource && _.isArray(pkg.SecretEnvFromSource) && pkg.SecretEnvFromSource.length !== 0 && (<div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Environment Variables from Secret</div>
                        {pkg.SecretEnvFromSource.map((envVar) => (<div className={styles.summarySectionValue}>
                                Environment variables from {_.lowerCase(envVar.option) === "true" && <span>an optional </span>}secret {envVar.key}
                                {envVar.value && <span> with prefix {envVar.value}</span>}
                            </div>))}
                    </div>)}

                {pkg.ConfigMapEnvironmentVariables && _.isArray(pkg.ConfigMapEnvironmentVariables) && pkg.ConfigMapEnvironmentVariables.length !== 0 && (<div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>ConfigMap Environment Variables</div>
                        {pkg.ConfigMapEnvironmentVariables.map((envVar) => (<div key={envVar.key} className={styles.summarySectionValue}>
                                {envVar.key} = {envVar.value}/{envVar.option}
                            </div>))}
                    </div>)}

                {pkg.ConfigMapEnvFromSource && _.isArray(pkg.ConfigMapEnvFromSource) && pkg.ConfigMapEnvFromSource.length !== 0 && (<div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Environment Variables from Config Map</div>
                        {pkg.ConfigMapEnvFromSource.map((envVar) => (<div className={styles.summarySectionValue}>
                                Environment variables from {_.lowerCase(envVar.option) === "true" && <span>an optional </span>}config map {envVar.key}
                                {envVar.value && <span> with prefix {envVar.value}</span>}
                            </div>))}
                    </div>)}

                {pkg.FieldRefEnvironmentVariables && _.isArray(pkg.FieldRefEnvironmentVariables) && pkg.FieldRefEnvironmentVariables.length !== 0 && (<div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Field Reference Environment Variables</div>
                        {pkg.FieldRefEnvironmentVariables.map((envVar) => (<div key={envVar.key} className={styles.summarySectionValue}>
                                {envVar.key} = "{envVar.value}"
                            </div>))}
                    </div>)}

                {pkg.VolumeMounts && _.isArray(pkg.VolumeMounts) && pkg.VolumeMounts.length !== 0 && (<div className={styles.summarySection}>
                        <div className={styles.summarySectionKey}>Volume Mounts</div>
                        {pkg.VolumeMounts.map((volMount, idx) => (<div key={volMount.key} className={styles.summaryTable}>
                                <DataTable>
                                    <DataTableBody>
                                        <DataTableRow>
                                            <DataTableRowColumn className={styles.summaryTableKey}>Name</DataTableRowColumn>
                                            <DataTableRowColumn className={styles.summaryTableValue}>{volMount.key} </DataTableRowColumn>
                                        </DataTableRow>
                                        <DataTableRow>
                                            <DataTableRowColumn className={styles.summaryTableKey}>Path</DataTableRowColumn>
                                            <DataTableRowColumn className={styles.summaryTableValue}>{volMount.value}</DataTableRowColumn>
                                        </DataTableRow>
                                        {volMount.option && (<DataTableRow>
                                                <DataTableRowColumn className={styles.summaryTableKey}>SubPath</DataTableRowColumn>
                                                <DataTableRowColumn className={styles.summaryTableValue}>{volMount.option}</DataTableRowColumn>
                                            </DataTableRow>)}
                                        <DataTableRow>
                                            <DataTableRowColumn className={styles.summaryTableKey}>Read Only</DataTableRowColumn>
                                            <DataTableRowColumn className={styles.summaryTableValue}>{volMount.option === "True" ? "True" : "False"}</DataTableRowColumn>
                                        </DataTableRow>
                                    </DataTableBody>
                                </DataTable>
                            </div>))}
                    </div>)}

                {pkg.StartupProbe && pkg.StartupProbe.type && (<div className={styles.summaryText}>
                        Performing a{pkg.StartupProbe.type === CommandCheck && <strong> exec </strong>}
                        {pkg.StartupProbe.type === HttpGetCheck && <strong> HTTP </strong>}
                        {pkg.StartupProbe.type === TcpSocketCheck && <strong> TCP socket </strong>}
                        startup probe check
                        {pkg.StartupProbe.initialDelaySeconds && (<span>
                                {" "}
                                after an initial delay of <strong>{pkg.StartupProbe.initialDelaySeconds}</strong> seconds
                            </span>)}
                        {pkg.StartupProbe.periodSeconds && (<span>
                                {" "}
                                every <strong>{pkg.StartupProbe.periodSeconds}</strong> seconds
                            </span>)}
                        {pkg.StartupProbe.timeoutSeconds && (<span>
                                {" "}
                                timing out after <strong>{pkg.StartupProbe.timeoutSeconds}</strong> seconds
                            </span>)}
                        {pkg.StartupProbe.successThreshold && (<span>
                                {" "}
                                succeeding after <strong>{pkg.StartupProbe.successThreshold}</strong> consecutive successes
                            </span>)}
                        {pkg.StartupProbe.failureThreshold && (<span>
                                {" "}
                                failing after <strong>{pkg.StartupProbe.failureThreshold}</strong> consecutive errors
                            </span>)}
                    </div>)}

                {pkg.ReadinessProbe && pkg.ReadinessProbe.type && (<div className={styles.summaryText}>
                        Performing a{pkg.ReadinessProbe.type === CommandCheck && <strong> exec </strong>}
                        {pkg.ReadinessProbe.type === HttpGetCheck && <strong> HTTP </strong>}
                        {pkg.ReadinessProbe.type === TcpSocketCheck && <strong> TCP socket </strong>}
                        readiness probe check
                        {pkg.ReadinessProbe.initialDelaySeconds && (<span>
                                {" "}
                                after an initial delay of <strong>{pkg.ReadinessProbe.initialDelaySeconds}</strong> seconds
                            </span>)}
                        {pkg.ReadinessProbe.periodSeconds && (<span>
                                {" "}
                                every <strong>{pkg.ReadinessProbe.periodSeconds}</strong> seconds
                            </span>)}
                        {pkg.ReadinessProbe.timeoutSeconds && (<span>
                                {" "}
                                timing out after <strong>{pkg.ReadinessProbe.timeoutSeconds}</strong> seconds
                            </span>)}
                        {pkg.ReadinessProbe.successThreshold && (<span>
                                {" "}
                                succeeding after <strong>{pkg.ReadinessProbe.successThreshold}</strong> consecutive successes
                            </span>)}
                        {pkg.ReadinessProbe.failureThreshold && (<span>
                                {" "}
                                failing after <strong>{pkg.ReadinessProbe.failureThreshold}</strong> consecutive errors
                            </span>)}
                    </div>)}

                {pkg.LivenessProbe && pkg.LivenessProbe.type && (<div className={styles.summaryText}>
                        Performing a{pkg.LivenessProbe.type === CommandCheck && <strong> exec </strong>}
                        {pkg.LivenessProbe.type === HttpGetCheck && <strong> HTTP </strong>}
                        {pkg.LivenessProbe.type === TcpSocketCheck && <strong> TCP socket </strong>}
                        liveness probe check
                        {pkg.LivenessProbe.initialDelaySeconds && (<span>
                                {" "}
                                after an initial delay of <strong>{pkg.LivenessProbe.initialDelaySeconds}</strong> seconds
                            </span>)}
                        {pkg.LivenessProbe.periodSeconds && (<span>
                                {" "}
                                every <strong>{pkg.LivenessProbe.periodSeconds}</strong> seconds
                            </span>)}
                        {pkg.LivenessProbe.timeoutSeconds && (<span>
                                {" "}
                                timing out after <strong>{pkg.LivenessProbe.timeoutSeconds}</strong> seconds
                            </span>)}
                        {pkg.LivenessProbe.failureThreshold && (<span>
                                {" "}
                                restarting after <strong>{pkg.LivenessProbe.failureThreshold}</strong> consecutive errors
                            </span>)}
                    </div>)}

                {pkg.Command && pkg.Command.filter((c) => c && c.trim()).length !== 0 && (<div className={styles.summaryText}>
                        Overriding container command with <strong>{pkg.Command.filter((c) => c && c.trim()).join(" ")}</strong>
                    </div>)}
                {pkg.Args && pkg.Args.filter((c) => c && c.trim()).length !== 0 && (<div className={styles.summaryText}>
                        Passing the arguments <strong>{pkg.Args.filter((c) => c && c.trim()).join(" ")}</strong>
                    </div>)}

                {pkg.SecurityContext && (<div className={styles.summaryText}>
                        {pkg.SecurityContext.allowPrivilegeEscalation && pkg.SecurityContext.allowPrivilegeEscalation.trim().toUpperCase() === "TRUE" && (<div>
                                Privilege escalation <strong>enabled</strong>
                            </div>)}
                        {pkg.SecurityContext.privileged && pkg.SecurityContext.privileged.trim().toUpperCase() === "TRUE" && (<div>
                                Privileged mode <strong>enabled</strong>
                            </div>)}
                        {pkg.SecurityContext.runAsNonRoot && pkg.SecurityContext.runAsNonRoot.trim().toUpperCase() === "TRUE" && (<div>
                                Run as non-root <strong>enabled</strong>
                            </div>)}
                        {pkg.SecurityContext.readOnlyRootFilesystem && pkg.SecurityContext.readOnlyRootFilesystem.trim().toUpperCase() === "TRUE" && (<div>
                                Read only root filesystem <strong>enabled</strong>
                            </div>)}
                        {pkg.SecurityContext.allowPrivilegeEscalation && isBound(pkg.SecurityContext.allowPrivilegeEscalation) && (<div>
                                Privilege escalation set to <strong>{pkg.SecurityContext.allowPrivilegeEscalation.trim()}</strong>
                            </div>)}
                        {pkg.SecurityContext.privileged && isBound(pkg.SecurityContext.privileged) && (<div>
                                Privileged mode set to <strong>{pkg.SecurityContext.privileged.trim()}</strong>
                            </div>)}
                        {pkg.SecurityContext.runAsNonRoot && isBound(pkg.SecurityContext.runAsNonRoot) && (<div>
                                Run as non-root set to <strong>{pkg.SecurityContext.runAsNonRoot.trim()}</strong>
                            </div>)}
                        {pkg.SecurityContext.readOnlyRootFilesystem && isBound(pkg.SecurityContext.readOnlyRootFilesystem) && (<div>
                                Read only root file system set to <strong>{pkg.SecurityContext.readOnlyRootFilesystem.trim()}</strong>
                            </div>)}
                        {pkg.SecurityContext.runAsUser && pkg.SecurityContext.runAsUser.trim() && (<div>
                                Run as user <strong>{pkg.SecurityContext.runAsUser.trim()}</strong>
                            </div>)}
                        {pkg.SecurityContext.runAsGroup && pkg.SecurityContext.runAsGroup.trim() && (<div>
                                Run as group <strong>{pkg.SecurityContext.runAsGroup.trim()}</strong>
                            </div>)}
                        {_.get(pkg, "SecurityContext.capabilities.add") && pkg.SecurityContext.capabilities.add.length !== 0 && (<div>
                                Adding the capabilities <strong>{pkg.SecurityContext.capabilities.add.join(", ")}</strong>
                            </div>)}
                        {_.get(pkg, "SecurityContext.capabilities.drop") && pkg.SecurityContext.capabilities.drop.length !== 0 && (<div>
                                Dropping the capabilities <strong>{pkg.SecurityContext.capabilities.drop.join(", ")}</strong>
                            </div>)}
                        {_.get(pkg, "SecurityContext.seLinuxOptions.level") && pkg.SecurityContext.seLinuxOptions.level.trim() && (<div>
                                Setting the SELinux level to <strong>{pkg.SecurityContext.seLinuxOptions.level.trim()}</strong>
                            </div>)}
                        {_.get(pkg, "SecurityContext.seLinuxOptions.role") && pkg.SecurityContext.seLinuxOptions.role.trim() && (<div>
                                Setting the SELinux role to <strong>{pkg.SecurityContext.seLinuxOptions.role.trim()}</strong>
                            </div>)}
                        {_.get(pkg, "SecurityContext.seLinuxOptions.type") && pkg.SecurityContext.seLinuxOptions.type.trim() && (<div>
                                Setting the SELinux type to <strong>{pkg.SecurityContext.seLinuxOptions.type.trim()}</strong>
                            </div>)}
                        {_.get(pkg, "SecurityContext.seLinuxOptions.user") && pkg.SecurityContext.seLinuxOptions.user.trim() && (<div>
                                Setting the SELinux user to <strong>{pkg.SecurityContext.seLinuxOptions.user.trim()}</strong>
                            </div>)}
                    </div>)}

                {pkg.ImagePullPolicy && (<div>
                        Using image pull policy <strong>{pkg.ImagePullPolicy}</strong>
                    </div>)}
            </div>);
    };
    private newPersistentVolumeClaim = (): PersistentVolumeClaimDetails => {
        const item: PersistentVolumeClaimInstanceDetails = {
            IsNew: true,
            Metadata: {
                Name: "",
                AnnotationsRaw: [],
                LabelsRaw: {},
            },
            Spec: {
                VolumeName: "",
                VolumeMode: "",
                AccessModes: [],
                DataSource: {
                    ApiGroup: "",
                    Name: "",
                    Kind: "",
                },
                StorageClassName: "",
                Selector: {
                    MatchExpressions: [],
                },
                Resources: {
                    requests: {
                        memory: "",
                        cpu: "",
                        ephemeralStorage: "",
                        amdGpu: "",
                        nvidiaGpu: "",
                        storage: "",
                    },
                    limits: {
                        memory: "",
                        cpu: "",
                        ephemeralStorage: "",
                        amdGpu: "",
                        nvidiaGpu: "",
                        storage: "",
                    },
                },
            },
        };
        return item;
    };
    private addPersistentVolumeClaimViaDialog = () => {
        const item = this.newPersistentVolumeClaim();
        this.setState({
            editPersistentVolumeClaim: item,
            editPersistentVolumeClaimIndex: null!,
        });
    };
    private editPersistentVolumeClaimViaDialog = (item: PersistentVolumeClaimInstanceDetails) => {
        this.setState({
            editPersistentVolumeClaim: clone(item),
            editPersistentVolumeClaimIndex: this.state.persistentVolumeClaims.indexOf(item),
        });
    };
    private removePersistentVolumeClaimViaDialog = (item: PersistentVolumeClaimInstanceDetails) => {
        const persistentVolumeClaims = [...this.state.persistentVolumeClaims];
        persistentVolumeClaims.splice(this.state.persistentVolumeClaims.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PersistentVolumeClaims"]: JSON.stringify(persistentVolumeClaims) });
    };
    private resetPersistentVolumeClaims = () => {
        this.setState({
            editPersistentVolumeClaim: null!,
            editPersistentVolumeClaimIndex: null!,
        });
    };
    private savePersistentVolumeClaim = (item: PersistentVolumeClaimInstanceDetails) => {
        const items = [...this.state.persistentVolumeClaims];
        if (this.state.editPersistentVolumeClaimIndex === null) {
            items.push(item);
        }
        else {
            items[this.state.editPersistentVolumeClaimIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PersistentVolumeClaims"]: JSON.stringify(items) });
        this.resetPersistentVolumeClaims();
        return true;
    };
    private persistentVolumeClaimListItem = (pkg: PersistentVolumeClaimInstanceDetails) => {
        const accessModes = pkg.Spec?.AccessModes?.filter((a: string) => _.trim(a)) || [];
        return (<div>
                <ListTitle>{pkg.Metadata?.Name}</ListTitle>
                {pkg.Spec?.VolumeName && (<div className={styles.summaryText}>
                        Volume name is <strong>{pkg.Spec?.VolumeName}</strong>
                    </div>)}
                {pkg.Spec?.VolumeMode && (<div className={styles.summaryText}>
                        Volume mode is <strong>{pkg.Spec?.VolumeMode}</strong>
                    </div>)}
                {accessModes.length !== 0 && (<div className={styles.summaryText}>
                        Access mode{accessModes.length > 1 ? <span>s are</span> : <span> is</span>} <strong>{accessModes.join(", ")}</strong>
                    </div>)}
                {pkg.Spec?.StorageClassName && (<div className={styles.summaryText}>
                        Storage class name is <strong>{pkg.Spec?.StorageClassName}</strong>
                    </div>)}
                {pkg.Metadata?.LabelsRaw && _.keys(pkg.Metadata?.LabelsRaw).length !== 0 && (<div className={styles.summaryText}>
                        Label{_.keys(pkg.Metadata?.LabelsRaw).length > 1 ? <span>s are</span> : <span> is</span>}{" "}
                        <strong>
                            {_.keys(pkg.Metadata?.LabelsRaw).map((l, idx1) => (<span>
                                    {l}: {_.get(pkg.Metadata?.LabelsRaw, l)}
                                    {idx1 < (_.keys(pkg.Metadata?.LabelsRaw).length || 0) - 1 ? "; " : ""}
                                </span>))}
                        </strong>
                    </div>)}
                {pkg.Metadata?.AnnotationsRaw && pkg.Metadata?.AnnotationsRaw.length !== 0 && (<div className={styles.summaryText}>
                        Annotation{pkg.Metadata?.AnnotationsRaw.length > 1 ? <span>s are</span> : <span> is</span>}{" "}
                        <strong>
                            {pkg.Metadata?.AnnotationsRaw.map((l, idx1) => (<span>
                                    {l.key}: {l.value}
                                    {idx1 < (pkg?.Metadata?.AnnotationsRaw?.length || 0) - 1 ? "; " : ""}
                                </span>))}
                        </strong>
                    </div>)}
                {pkg.Spec?.Selector?.MatchExpressions && pkg.Spec?.Selector?.MatchExpressions.length !== 0 && (<div className={styles.summaryText}>
                        Label selector{pkg.Spec?.Selector.MatchExpressions.length > 1 ? <span>s are</span> : <span> is</span>}{" "}
                        <strong>
                            {pkg.Spec?.Selector.MatchExpressions.map((l, idx1) => (<span>
                                    {l.key} {l.value} {l.option}
                                    {idx1 < (pkg?.Spec?.Selector?.MatchExpressions?.length || 0) - 1 ? "; " : ""}
                                </span>))}
                        </strong>
                    </div>)}
                {pkg.Spec?.DataSource && pkg.Spec.DataSource?.Name && (<div className={styles.summaryText}>
                        Data source is{" "}
                        <strong>
                            {pkg.Spec.DataSource.ApiGroup} {pkg.Spec.DataSource.Kind} {pkg.Spec.DataSource.Name}
                        </strong>
                    </div>)}
                {pkg?.Spec?.Resources?.requests?.storage && (<div className={styles.summaryText}>
                        Requesting <strong>{pkg.Spec.Resources.requests.storage}</strong> of storage
                        {pkg.Spec.Resources.limits.storage && (<span>
                                {" "}
                                with a limit of <strong>{pkg.Spec.Resources.limits.storage}</strong>
                            </span>)}
                    </div>)}
                {!pkg?.Spec?.Resources?.requests?.storage && pkg?.Spec?.Resources?.limits?.storage && (<div className={styles.summaryText}>
                        Limited to <strong>{pkg.Spec.Resources.limits.storage}</strong> of storage
                    </div>)}
            </div>);
    };
    private addCombinedVolume = () => {
        const item: CombinedVolumeDetails = {
            Items: [],
            Name: "",
            ReferenceName: "",
            ReferenceNameType: "Custom",
            EmptyDirMedium: "",
            HostPathType: "Directory",
            HostPathPath: "",
            LocalPath: "",
            Type: "ConfigMap",
            RawYaml: "",
            Repository: "",
            Revision: "",
        };
        this.setState({
            editSecretVolume: item,
            editSecretVolumeIndex: null!,
        });
    };
    private editCombinedVolume = (item: CombinedVolumeDetails) => {
        this.setState({
            editSecretVolume: clone(item),
            editSecretVolumeIndex: this.state.combinedVolumes.indexOf(item),
        });
    };
    private removeCombinedVolume = (item: CombinedVolumeDetails) => {
        const volumes = [...this.state.combinedVolumes];
        volumes.splice(this.state.combinedVolumes.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CombinedVolumes"]: JSON.stringify(volumes) });
    };
    private resetCombinedVolume = () => {
        this.setState({
            editSecretVolume: null!,
            editSecretVolumeIndex: null!,
        });
    };
    private saveCombinedVolume = (item: CombinedVolumeDetails) => {
        const volumes = [...this.state.combinedVolumes];
        if (this.state.editSecretVolumeIndex === null) {
            volumes.push(item);
        }
        else {
            volumes[this.state.editSecretVolumeIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CombinedVolumes"]: JSON.stringify(volumes) });
        this.resetCombinedVolume();
        return true;
    };
    private combinedVolumeListItem = (volume: CombinedVolumeDetails) => {
        return (<div>
                {this.configMapSummary(volume, false)}
                {this.secretSummary(volume, false)}
                {this.emptyDirSummary(volume, false)}
                {this.hostPathSummary(volume, false)}
                {this.persistentVolumeClaimSummary(volume, false)}
                {this.rawYamlSummary(volume, false)}
            </div>);
    };
    private addPodAffinity = () => {
        const item: PodAffinityDetails = {
            Type: RequiredAffinity,
            NamespacesList: "",
            TopologyKey: "",
            Weight: "",
            InMatch: [],
            ExistMatch: [],
        };
        this.setState({
            editPodAffinity: item!,
            editPodAffinityIndex: null!,
        });
    };
    private savePodAffinity = (item: PodAffinityDetails) => {
        const podAffinityDetails = [...this.state.podAffinityDetails];
        if (this.state.editPodAffinityIndex === null) {
            podAffinityDetails.push(item);
        }
        else {
            podAffinityDetails[this.state.editPodAffinityIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAffinity"]: JSON.stringify(podAffinityDetails) });
        this.resetPodAffinity();
        return true;
    };
    private editPodAffinity = (item: PodAffinityDetails) => {
        this.setState({
            editPodAffinity: clone(item),
            editPodAffinityIndex: this.state.podAffinityDetails.indexOf(item),
        });
    };
    private removePodAffinity = (item: PodAffinityDetails) => {
        const podAffinitySettings = [...this.state.podAffinityDetails];
        podAffinitySettings.splice(this.state.podAffinityDetails.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAffinity"]: JSON.stringify(podAffinitySettings) });
    };
    private resetPodAffinity = () => {
        this.setState({
            editPodAffinity: null!,
            editPodAffinityIndex: null!,
        });
    };
    private podAffinityListItem = (antiAffinity: boolean) => (podAffinity: PodAffinityDetails) => {
        return (<span>
                <strong>{podAffinity.Type}</strong> {antiAffinity ? "anti-affinity" : "affinity"}
                {podAffinity.Type === PreferredAffinity && podAffinity.Weight && (<span>
                        {" "}
                        with weight <strong>{podAffinity.Weight}</strong>
                    </span>)}
                <span>
                    {" "}
                    matching topology key <strong>{podAffinity.TopologyKey}</strong>
                </span>
                {podAffinity.NamespacesList && podAffinity.NamespacesList.trim() && (<span>
                        , pods in namespace{podAffinity.NamespacesList.indexOf(",") !== -1 && <span>s</span>} <strong>{podAffinity.NamespacesList.trim()}</strong> where
                    </span>)}
                {_.concat(_.chain(podAffinity.InMatch)
                .flatMap((match) => [
                <span>
                                {" "}
                                label key <strong>{match.key}</strong>
                                {match.value === InOperator && (<span>
                                        {" "}
                                        is <strong>in</strong>
                                    </span>)}
                                {match.value === NotInOperator && (<span>
                                        {" "}
                                        is <strong>not in</strong>
                                    </span>)}
                                <span>
                                    {" "}
                                    value{match.option.indexOf(",") !== -1 && "s"} <strong>{match.option}</strong>
                                </span>
                            </span>,
                <span>, </span>,
            ])
                .value(), _.chain(podAffinity.ExistMatch)
                .flatMap((match) => [
                <span>
                                {match.value === ExistsOperator && (<span>
                                        {" "}
                                        label key <strong>{match.key} exists</strong>
                                    </span>)}
                                {match.value === DoesNotExistOperator && (<span>
                                        {" "}
                                        label key <strong>{match.key} does not exist</strong>
                                    </span>)}
                            </span>,
                <span>, </span>,
            ])
                .value()).slice(0, -1)}
            </span>);
    };
    private addPodAntiAffinity = () => {
        const item: PodAffinityDetails = {
            Type: RequiredAffinity,
            NamespacesList: "",
            TopologyKey: "",
            Weight: "",
            InMatch: [],
            ExistMatch: [],
        };
        this.setState({
            editPodAntiAffinity: item,
            editPodAntiAffinityIndex: null!,
        });
    };
    private savePodAntiAffinity = (item: PodAffinityDetails) => {
        const podAffinityDetails = [...this.state.podAntiAffinityDetails];
        if (this.state.editPodAntiAffinityIndex === null) {
            podAffinityDetails.push(item);
        }
        else {
            podAffinityDetails[this.state.editPodAntiAffinityIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAntiAffinity"]: JSON.stringify(podAffinityDetails) });
        this.resetPodAntiAffinity();
        return true;
    };
    private editPodAntiAffinity = (item: PodAffinityDetails) => {
        this.setState({
            editPodAntiAffinity: clone(item),
            editPodAntiAffinityIndex: this.state.podAntiAffinityDetails.indexOf(item),
        });
    };
    private removePodAntiAffinity = (item: PodAffinityDetails) => {
        const podAffinitySettings = [...this.state.podAntiAffinityDetails];
        podAffinitySettings.splice(this.state.podAntiAffinityDetails.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.PodAntiAffinity"]: JSON.stringify(podAffinitySettings) });
    };
    private resetPodAntiAffinity = () => {
        this.setState({
            editPodAntiAffinity: null!,
            editPodAntiAffinityIndex: null!,
        });
    };
    private addNodeAffinity = () => {
        const item: NodeAffinityDetails = {
            Type: RequiredAffinity,
            Weight: "",
            InMatch: [],
            ExistMatch: [],
        };
        this.setState({
            editNodeAffinity: item,
            editNodeAffinityIndex: null!,
        });
    };
    private saveNodeAffinity = (item: NodeAffinityDetails) => {
        const nodeAffinityDetails = [...this.state.nodeAffinityDetails];
        if (this.state.editNodeAffinityIndex === null) {
            nodeAffinityDetails.push(item);
        }
        else {
            nodeAffinityDetails[this.state.editNodeAffinityIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.NodeAffinity"]: JSON.stringify(nodeAffinityDetails) });
        this.resetNodeAffinity();
        return true;
    };
    private editNodeAffinity = (item: NodeAffinityDetails) => {
        this.setState({
            editNodeAffinity: clone(item),
            editNodeAffinityIndex: this.state.nodeAffinityDetails.indexOf(item),
        });
    };
    private removeNodeAffinity = (item: NodeAffinityDetails) => {
        const nodeAffinitySettings = [...this.state.nodeAffinityDetails];
        nodeAffinitySettings.splice(this.state.nodeAffinityDetails.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.NodeAffinity"]: JSON.stringify(nodeAffinitySettings) });
    };
    private resetNodeAffinity = () => {
        this.setState({
            editNodeAffinity: null!,
            editNodeAffinityIndex: null!,
        });
    };
    private nodeAffinityListItem = (nodeAffinity: NodeAffinityDetails) => {
        return (<span>
                <strong>{nodeAffinity.Type}</strong> affinity
                {nodeAffinity.Type === PreferredAffinity && nodeAffinity.Weight && (<span>
                        {" "}
                        with weight <strong>{nodeAffinity.Weight}</strong>
                    </span>)}{" "}
                where
                {_.concat(_.chain(nodeAffinity.InMatch)
                .flatMap((match) => [
                <span key={match.key + "a0"}>
                                {" "}
                                label key <strong>{match.key}</strong>
                                {match.value === InOperator && (<span>
                                        {" "}
                                        is <strong>in</strong>
                                    </span>)}
                                {match.value === NotInOperator && (<span>
                                        {" "}
                                        is <strong>not in</strong>
                                    </span>)}
                                {match.value === GreaterThanOperator && (<span>
                                        {" "}
                                        is <strong>greater than</strong>
                                    </span>)}
                                {match.value === LessThanOperator && (<span>
                                        {" "}
                                        is <strong>less than</strong>
                                    </span>)}
                                <span>
                                    {" "}
                                    value{match.option.indexOf(",") !== -1 && "s"} <strong>{match.option}</strong>
                                </span>
                            </span>,
                <span key={match.key + "a1"}>, </span>,
            ])
                .value(), _.chain(nodeAffinity.ExistMatch)
                .flatMap((match) => [
                <span key={match.key + "b0"}>
                                {match.value === ExistsOperator && (<span>
                                        {" "}
                                        label key <strong>{match.key} exists</strong>
                                    </span>)}
                                {match.value === DoesNotExistOperator && (<span>
                                        {" "}
                                        label key <strong>{match.key} does not exist</strong>
                                    </span>)}
                            </span>,
                <span key={match.key + "b0"}>, </span>,
            ])
                .value()).slice(0, -1)}
            </span>);
    };
    private addToleration = () => {
        const item: TolerationDetails = {
            Key: "",
            Operator: "",
            Value: "",
            Effect: "",
        };
        this.setState({
            editToleration: item,
            editTolerationIndex: null!,
        });
    };
    private editToleration = (item: TolerationDetails) => {
        this.setState({
            editToleration: clone(item),
            editTolerationIndex: this.state.tolerations.indexOf(item),
        });
    };
    private saveToleration = (item: TolerationDetails) => {
        const tolerations = [...this.state.tolerations];
        if (this.state.editTolerationIndex === null) {
            tolerations.push(item);
        }
        else {
            tolerations[this.state.editTolerationIndex] = item;
        }
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Tolerations"]: JSON.stringify(tolerations) });
        this.resetToleration();
        return true;
    };
    private removeToleration = (item: TolerationDetails) => {
        const tolerations = [...this.state.tolerations];
        tolerations.splice(this.state.tolerations.indexOf(item), 1);
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Tolerations"]: JSON.stringify(tolerations) });
    };
    private resetToleration = () => {
        this.setState({
            editToleration: null!,
            editTolerationIndex: null!,
        });
    };
    private tolerationListItem = (toleration: TolerationDetails) => {
        return (<span>
                {!!toleration.Key && (<span>
                        Key: <strong>{toleration.Key}</strong>{" "}
                    </span>)}
                {!!toleration.Operator && (<span>
                        Operator: <strong>{toleration.Operator}</strong>{" "}
                    </span>)}
                {!!toleration.Value && (<span>
                        Value: <strong>{toleration.Value}</strong>{" "}
                    </span>)}
                {!!toleration.Effect && (<span>
                        Effect: <strong>{toleration.Effect}</strong>
                    </span>)}
            </span>);
    };
    /**
     * We only want to save the container specific information in the step property. Package details are saved
     * on a property on the step, and so we don't duplicate that information here.
     * @param items
     */
    private saveContainersJson(items: ContainerPackageDetails[]) {
        const containerDetails = items.map((item) => ({
            Name: item.Name,
            Ports: item.Ports,
            EnvironmentVariables: item.EnvironmentVariables,
            SecretEnvironmentVariables: item.SecretEnvironmentVariables,
            ConfigMapEnvironmentVariables: item.ConfigMapEnvironmentVariables,
            FieldRefEnvironmentVariables: item.FieldRefEnvironmentVariables,
            ConfigMapEnvFromSource: item.ConfigMapEnvFromSource,
            SecretEnvFromSource: item.SecretEnvFromSource,
            VolumeMounts: item.VolumeMounts,
            Resources: item.Resources,
            LivenessProbe: item.LivenessProbe,
            ReadinessProbe: item.ReadinessProbe,
            StartupProbe: item.StartupProbe,
            Command: item.Command,
            Args: item.Args,
            InitContainer: item.InitContainer,
            ImagePullPolicy: item.ImagePullPolicy,
            SecurityContext: item.SecurityContext,
            TerminationMessagePath: item.TerminationMessagePath,
            TerminationMessagePolicy: item.TerminationMessagePolicy,
            Lifecycle: item.Lifecycle,
            CreateFeedSecrets: item.CreateFeedSecrets || "True",
        }));
        this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Containers"]: JSON.stringify(containerDetails, null, 1) });
    }
    private resourceTypeSummary() {
        return Summary.summary(<span>
                Step configures a <strong>{this.props.properties["Octopus.Action.KubernetesContainers.DeploymentResourceType"]}</strong>
            </span>);
    }
    private deploymentStyleSummary() {
        if (!this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"]) {
            return Summary.placeholder("The deployment strategy has not been defined");
        }
        return Summary.summary(<span>
                Update the {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentResourceType"]} with the
                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "RollingUpdate" && (<span>
                        {" "}
                        <strong>rolling deployment</strong> strategy
                        {(getDeploymentResource(this.props) === "deployment" || getDeploymentResource(this.props) === "daemonset") && this.props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"] && (<span>
                                {" "}
                                with a maximum of <strong>{this.props.properties["Octopus.Action.KubernetesContainers.MaxUnavailable"]}</strong> unavailable
                                {getDeploymentResource(this.props) === "deployment" && this.props.properties["Octopus.Action.KubernetesContainers.MaxSurge"] && <span> and</span>}
                            </span>)}
                        {getDeploymentResource(this.props) === "deployment" && this.props.properties["Octopus.Action.KubernetesContainers.MaxSurge"] && (<span>
                                {" "}
                                a maximum surge of <strong>{this.props.properties["Octopus.Action.KubernetesContainers.MaxSurge"]}</strong>
                            </span>)}
                        {getDeploymentResource(this.props) === "statefulset" && this.props.properties["Octopus.Action.KubernetesContainers.Partition"] && (<span>
                                {" "}
                                and a partition of <strong>{this.props.properties["Octopus.Action.KubernetesContainers.Partition"]}</strong>
                            </span>)}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "BlueGreen" && (<span>
                        {" "}
                        <strong>blue/green deployment</strong> strategy
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "Recreate" && (<span>
                        {" "}
                        <strong>recreate deployment</strong> strategy
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentStyle"] === "OnDelete" && (<span>
                        {" "}
                        <strong>on delete deployment</strong> strategy
                    </span>)}
            </span>);
    }
    private deploymentSummary() {
        if (!(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"] || this.props.properties["Octopus.Action.KubernetesContainers.Replicas"] || this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"])) {
            return Summary.placeholder("No Kubernetes deployment resource has been defined");
        }
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}));
        return Summary.summary(<span>
                <span>Create a {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentResourceType"]}</span>
                {this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"] && (<span>
                        {" "}
                        called <strong>{this.props.properties["Octopus.Action.KubernetesContainers.DeploymentName"]}</strong>
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.Replicas"] && getDeploymentResource(this.props) !== "job" && (<span>
                        {" "}
                        with <strong>{this.props.properties["Octopus.Action.KubernetesContainers.Replicas"]}</strong> replicas
                    </span>)}
                {labels.length !== 0 && (<span>
                        {" "}
                        and the label{labels.length > 1 && <span>s</span>}{" "}
                        {_.chain(labels)
                    .flatMap((pair) => [
                    <strong>
                                    {pair[0]}: {pair[1]}
                                </strong>,
                    <span>, </span>,
                ])
                    .slice(0, -1)
                    .value()}
                    </span>)}
            </span>);
    }
    private getContainerPackageDetails(containers: string, packages: ScriptPackageReference[]): ContainerPackageDetails[] {
        const containerArray: ContainerDetails[] = JsonUtils.tryParseArray(containers, []);
        return packages.map((packageReference) => _.assign({ Id: null }, packageReference, containerArray.find((container) => packageReference.Name === container.Name)));
    }
    private configMapSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === ConfigMapType) {
            return (<span>
                    {prefix && <span> the </span>}
                    <strong>{volume.Type}</strong>
                    {volume.ReferenceNameType !== LinkedResource && (<span>
                            {" "}
                            named <strong>{volume.ReferenceName}</strong>
                        </span>)}
                    {volume.ReferenceNameType === LinkedResource && <span> defined in the feature below</span>}
                    {volume.Items && volume.Items.length !== 0 && (<span>
                            <span> with items: </span>
                            {_.chain(volume.Items)
                        .flatMap((item) => [
                        <strong>
                                        {item.key} {"=>"} {item.value}
                                    </strong>,
                        <span>, </span>,
                    ])
                        .slice(0, -1)
                        .value()}
                        </span>)}
                </span>);
        }
    }
    private secretSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === SecretType) {
            return (<span>
                    {prefix && <span> the </span>}
                    <strong>{volume.Type}</strong>
                    {volume.ReferenceNameType !== LinkedResource && (<span>
                            {" "}
                            named <strong>{volume.ReferenceName}</strong>
                        </span>)}
                    {volume.ReferenceNameType === LinkedResource && <span> defined in the feature below</span>}
                    {volume.Items && volume.Items.length !== 0 && (<span>
                            <span> with items: </span>
                            {_.chain(volume.Items)
                        .flatMap((item) => [
                        <strong>
                                        {item.key} {"=>"} {item.value}
                                    </strong>,
                        <span>, </span>,
                    ])
                        .slice(0, -1)
                        .value()}
                        </span>)}
                </span>);
        }
    }
    private emptyDirSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === EmptyDirType) {
            return (<span>
                    {prefix && <span> an </span>}
                    <strong>Empty Dir</strong>
                    {volume.EmptyDirMedium && (<span>
                            {" "}
                            using medium <strong>{volume.EmptyDirMedium}</strong>
                        </span>)}
                </span>);
        }
    }
    private hostPathSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === HostPathType) {
            return (<span>
                    {" "}
                    a <strong>Host Path</strong>
                    {volume.HostPathType && (<span>
                            {" "}
                            of type <strong>{volume.HostPathType}</strong>
                        </span>)}
                    {volume.HostPathPath && (<span>
                            {" "}
                            from path <strong>{volume.HostPathPath}</strong>
                        </span>)}
                </span>);
        }
    }
    private persistentVolumeClaimSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === PersistentVolumeClaimType) {
            return (<span>
                    {prefix && <span> the </span>}
                    <strong>Persistent Volume Claim</strong>
                    {volume.ReferenceName && (<span>
                            {" "}
                            called <strong>{volume.ReferenceName}</strong>
                        </span>)}
                </span>);
        }
    }
    private rawYamlSummary(volume: CombinedVolumeDetails, prefix: boolean) {
        if (volume.Type === RawYamlType) {
            try {
                // Query the YAML and try and find the type of volume
                const yaml = jsyaml(volume.RawYaml);
                if (yaml !== null && typeof yaml === "object") {
                    const keys = Object.keys(yaml);
                    if (keys.length !== 0) {
                        return (<span>
                                {prefix && <span> the </span>}
                                <strong>Raw YAML</strong> definition for a <strong>{keys[0]}</strong>
                            </span>);
                    }
                }
            }
            catch {
                // ignore and fall through to the default summary
            }
            return (<span>
                    {prefix && <span> the </span>}
                    <strong>Raw YAML</strong> definition
                </span>);
        }
    }
    private podPriorityClassName() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.PriorityClassName"]) {
            return Summary.summary(<span>
                    Pod priority class name set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PriorityClassName"]}</strong>
                </span>);
        }
        return Summary.default(<span>No priority class name specified</span>);
    }
    private podRestartPolicySummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.RestartPolicy"]) {
            return Summary.summary(<span>
                    Pod Restart Policy set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.RestartPolicy"]}</strong>
                </span>);
        }
        return Summary.default(<span>No pod restart policy specified</span>);
    }
    private volumeSummary() {
        if (this.state.combinedVolumes.length === 0) {
            return Summary.default("No volumes have been included");
        }
        return Summary.summary(<span>
                <span>
                    Deploy volume{this.state.combinedVolumes.length !== 1 ? "s" : ""} called <strong>{this.state.combinedVolumes.map((v) => v.Name).join(", ")}</strong>
                </span>
            </span>);
    }
    private podDnsPolicySummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.DnsPolicy"]) {
            return Summary.summary(<span>
                    DNS Policy set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.DnsPolicy"]}</strong>
                </span>);
        }
        return Summary.default(<span>No DNS policy specified</span>);
    }
    private podHostNetworkSummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.HostNetwork"]) {
            return Summary.summary(<span>
                    Host network access set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.HostNetwork"]}</strong>
                </span>);
        }
        return Summary.default(<span>No host network access specified</span>);
    }
    private podDnsConfigSummary() {
        if (!this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigNameservers"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigSearches"] &&
            JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigOptions"], []).length === 0) {
            return Summary.default(<span>No DNS configuration options specified.</span>);
        }
        return Summary.summary(<span>
                {this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigNameservers"] && (<span>
                        Nameservers set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigNameservers"].split("\n").join(", ")}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigSearches"] && (<span>
                        Search domains set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigSearches"].split("\n").join(", ")}</strong>.{" "}
                    </span>)}
                {JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigOptions"], []).length !== 0 && (<span>
                        Options set to{" "}
                        <strong>
                            {JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.DnsConfigOptions"], [])
                    .map((x: KeyValueOption) => x.key + (x.value ? ": " + x.value : ""))
                    .join(", ")}
                        </strong>
                        .{" "}
                    </span>)}
            </span>);
    }
    private podSecuritySummary() {
        if (!this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"] &&
            !this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"] &&
            JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"], []).length === 0) {
            return Summary.default("No pod security settings configured.");
        }
        return Summary.summary(<span>
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"] && (<span>
                        FSGroup set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityFsGroup"]}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"] && (<span>
                        Run as group set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsGroup"]}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"] && (<span>
                        Run as user set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsUser"]}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"] && (<span>
                        Run as non-root set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecurityRunAsNonRoot"]}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"] && (<span>
                        SELinux level set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxLevel"]}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"] && (<span>
                        SELinux role set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxRole"]}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"] && (<span>
                        SELinux type set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxType"]}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"] && (<span>
                        SELinux user set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySeLinuxUser"]}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"] && (<span>
                        Supplemental groups set to <strong>{this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySupplementalGroups"]}</strong>.{" "}
                    </span>)}
                {this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"] && (JSON.parse(this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]) as KeyValueOption[]).length !== 0 && (<span>
                        Sysctls set to{" "}
                        <strong>
                            {_.chain(JSON.parse(this.props.properties["Octopus.Action.KubernetesContainers.PodSecuritySysctls"]))
                    .flatMap((item: KeyValueOption) => [
                    <span>
                                        {item.key}={item.value}
                                    </span>,
                    <span>, </span>,
                ])
                    .slice(0, -1)
                    .value()}
                        </strong>
                        .{" "}
                    </span>)}
            </span>);
    }
    private containersSummary() {
        if (this.state.containers.length === 0) {
            return Summary.placeholder("No containers have been included");
        }
        return Summary.summary(<span>
                <span>Deploy </span>
                <span>
                    {_.chain(this.state.containers)
                .filter((c) => c.InitContainer !== "True")
                .flatMap((container, idx1) => (<span key={idx1}>
                                image <strong>{container.PackageId}</strong>
                                {container.Ports && container.Ports.length !== 0 && (<span>
                                        {" "}
                                        exposing port{container.Ports.length > 1 && <span>s</span>}:{" "}
                                        {_.chain(container.Ports)
                        .flatMap((port, idx) => (<span key={port.value}>
                                                    <strong>
                                                        {port.value}:{port.option || "TCP"}
                                                    </strong>
                                                    {idx === container.Ports.length - 1 ? "" : ","}{" "}
                                                </span>))
                        .value()}
                                    </span>)}
                                {idx1 < this.state.containers.length - 1 ? ";" : ""}
                            </span>))
                .value()}
                </span>
            </span>);
    }
    private statefulSetPersistentVolumeClaimSummary() {
        if (this.state.persistentVolumeClaims.length === 0) {
            return Summary.default("No persistent volume claims have been included");
        }
        const getFilteredAccessMode = (pvc: PersistentVolumeClaimInstanceDetails) => pvc.Spec?.AccessModes?.filter((a: string) => _.trim(a)) || [];
        return Summary.summary(<span>
                {_.chain(this.state.persistentVolumeClaims)
                .filter((pvc) => pvc != null)
                .flatMap((pvc, idx1) => (<span key={idx1}>
                            {pvc.Metadata?.Name && (<span>
                                    Name: <strong>{pvc.Metadata.Name}</strong>{" "}
                                </span>)}
                            {pvc.Spec?.VolumeName && (<span>
                                    Volume Name: <strong>{pvc.Spec.VolumeName}</strong>{" "}
                                </span>)}
                            {pvc.Spec?.VolumeMode && (<span>
                                    Volume Mode: <strong>{pvc.Spec.VolumeMode}</strong>{" "}
                                </span>)}
                            {getFilteredAccessMode(pvc).length != 0 && (<span>
                                    Access Modes: <strong>{getFilteredAccessMode(pvc).join(", ")}</strong>{" "}
                                </span>)}
                            {pvc.Spec?.StorageClassName && (<span>
                                    Storage Class Name: <strong>{pvc.Spec.StorageClassName}</strong>{" "}
                                </span>)}
                            {pvc.Spec?.Resources?.requests?.storage && (<span>
                                    Storage request: <strong>{pvc.Spec.Resources.requests.storage}</strong>{" "}
                                </span>)}
                            {pvc.Spec?.Resources?.limits?.storage && (<span>
                                    Storage limit: <strong>{pvc.Spec.Resources.limits.storage}</strong>{" "}
                                </span>)}
                            {pvc.Spec?.DataSource?.ApiGroup && pvc.Spec?.DataSource?.Kind && pvc.Spec?.DataSource?.Name && (<span>
                                    Data source:{" "}
                                    <strong>
                                        {pvc.Spec?.DataSource?.ApiGroup} {pvc.Spec?.DataSource?.Kind} {pvc.Spec?.DataSource?.Name}
                                    </strong>{" "}
                                </span>)}
                            {(_.get(pvc, "Selector.MatchExpressions") || []).length != 0 && (<span>
                                    Match Expressions:{" "}
                                    {_.chain(pvc.Spec?.Selector?.MatchExpressions)
                        .flatMap((m: KeyValueOption, idx2) => (<span>
                                                <strong>
                                                    {m.key} {m.value} {m.option}
                                                </strong>
                                                {idx2 < (pvc.Spec?.Selector?.MatchExpressions?.length || 0) - 1 ? ", " : ""}
                                            </span>))
                        .value()}
                                </span>)}
                            {idx1 < this.state.persistentVolumeClaims.length - 1 ? "; " : ""}
                        </span>))
                .value()}
            </span>);
    }
    private podAffinitySummary() {
        const podAffinity: PodAffinityDetails[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAffinity"], []);
        const podAntiAffinity: PodAffinityDetails[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAntiAffinity"], []);
        if (podAffinity.length === 0 && podAntiAffinity.length === 0) {
            return Summary.default(<span>No affinity rules defined</span>);
        }
        return Summary.summary(<span>
                {_.concat(_.chain(podAffinity)
                .flatMap((a) => [this.podAffinityListItem(false)(a), <span>; </span>])
                .value(), _.chain(podAntiAffinity)
                .flatMap((a) => [this.podAffinityListItem(true)(a), <span>; </span>])
                .value()).slice(0, -1)}
            </span>);
    }
    private nodeAffinitySummary() {
        const podAffinity: NodeAffinityDetails[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.NodeAffinity"], []);
        if (podAffinity.length === 0) {
            return Summary.default(<span>No affinity rules defined</span>);
        }
        return Summary.summary(<span>
                {_.chain(podAffinity)
                .flatMap((a, idx) => (<span key={idx}>
                            {this.nodeAffinityListItem(a)}
                            {idx < podAffinity.length ? "; " : ""}
                        </span>))
                .value()}
            </span>);
    }
    private tolerationsSummary() {
        const tolerations: TolerationDetails[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.Tolerations"], []);
        if (tolerations.length === 0) {
            return Summary.default(<span>No tolerations defined</span>);
        }
        return Summary.summary(<span>
                {_.chain(tolerations)
                .flatMap((a, idx) => <div key={idx}>{this.tolerationListItem(a)}</div>)
                .value()}
            </span>);
    }
    private serviceAccountNameSummary() {
        const serviceAccountName = this.props.properties["Octopus.Action.KubernetesContainers.PodServiceAccountName"];
        if (!!serviceAccountName) {
            return Summary.summary(<span>
                    <strong>{serviceAccountName}</strong> will be used as the pod service account
                </span>);
        }
        return Summary.default(<span>No pod service account specified</span>);
    }
    private loadFeeds = async () => {
        await this.props.refreshFeeds();
    };
    private deploymentAnnotationsSummary() {
        const annotations: KeyValueOption[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentAnnotations"], []);
        if (annotations.length === 0) {
            return Summary.default("No annotations have been included");
        }
        return Summary.summary(<span>
                Add the annotation{annotations.length > 1 && <span>s</span>}{" "}
                {_.chain(annotations)
                .flatMap((annotation) => [
                <strong>
                            {annotation.key}: {annotation.value}
                        </strong>,
                <span>, </span>,
            ])
                .slice(0, -1)
                .value()}
            </span>);
    }
    private podAnnotationsSummary() {
        const annotations: KeyValueOption[] = JsonUtils.tryParseArray(this.props.properties["Octopus.Action.KubernetesContainers.PodAnnotations"], []);
        if (annotations.length === 0) {
            return Summary.default("No annotations have been included");
        }
        return Summary.summary(<span>
                Add the annotation{annotations.length > 1 && <span>s</span>}{" "}
                {_.chain(annotations)
                .flatMap((annotation, idx) => (<span key={annotation.key}>
                            <strong>
                                {annotation.key}: {annotation.value}
                            </strong>
                            {idx < annotations.length ? ", " : ""}
                        </span>))
                .value()}
            </span>);
    }
    private kubernetesReadinessGatesSummary() {
        const gates = (this.props.properties["Octopus.Action.KubernetesContainers.PodReadinessGates"] || "").trim();
        if (gates) {
            return Summary.summary(<span>
                    Readiness gates: <strong>{gates.replace(/\n/g, " ")}</strong>
                </span>);
        }
        return Summary.default("No readiness gates defines");
    }
    private getHostAliasDetails(hostAliases: string): KeyValueOption[] {
        const hostAliasArray: KeyValueOption[] = JsonUtils.tryParseArray(hostAliases, []);
        return hostAliasArray;
    }
    private hostAliasSummary() {
        if (this.state.hostAliases.length === 0) {
            return Summary.default("No host aliases have been included");
        }
        return Summary.summary(<span>
                <span>
                    Host Alias{this.state.hostAliases.length !== 1 ? "es" : ""} <strong>{this.state.hostAliases.map((v) => `Ip: ${v.key}, hostnames: ${v.value}`).join(" | ")}</strong>
                </span>
            </span>);
    }
    static displayName = "KubernetesDeployContainersActionEditInternal";
}
/**
 * This value may be set directly from a YAML import, so we can't assume capitalisation, or whitespace.
 * The property may also not be set, as it was introduced in a later update to the step, and an unset
 * value means the resource type is Deployment.
 * So this method returns the resource type as a trimmed lowercase string, with a default for unset properties,
 * so we have a sanitised value to compare to.
 * @param props The component properties
 */
export function getDeploymentResource(props: KubernetesDeployContainersActionEditProps) {
    return (props.properties["Octopus.Action.KubernetesContainers.DeploymentResourceType"] || "Deployment").toLowerCase().trim();
}
function KubernetesDeployContainersActionEdit(props: React.PropsWithChildren<KubernetesDeployContainersActionEditProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const processContext = useOptionalProcessContext();
    return <KubernetesDeployContainersActionEditInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds} modelDirty={!_.isEqual(processContext?.state.model, processContext?.state.cleanModel)}/>;
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployContainers",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployContainersActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    editSections: {
        top: (props: KubernetesDeployContainersActionEditProps) => <KubernetesDeployContainersActionEdit {...props}/>,
        default: (_: KubernetesDeployContainersActionEditProps) => <></>,
    },
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => true,
    features: {
        initial: ["Octopus.Features.KubernetesService", "Octopus.Features.KubernetesIngress", "Octopus.Features.KubernetesConfigMap", "Octopus.Features.KubernetesSecret"],
        optional: ["Octopus.Features.KubernetesService", "Octopus.Features.KubernetesIngress", "Octopus.Features.KubernetesConfigMap", "Octopus.Features.KubernetesSecret", "Octopus.Features.KubernetesCustomResource"],
    },
    targetDiscoveryCloudConnectionProviders: getKubernetesTargetDiscoveryCloudProviders,
    getInitialProperties: () => InitialStatusCheckWithTimeoutProperties,
    canUseExecutionTimeouts: false,
    mixedExecutionLocations: kubernetesMixedExecutionLocationConfig,
    disableInlineExecutionContainers: true,
    docsLink: "configureKubernetesResources",
});
