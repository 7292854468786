import type { AccessTokenResource } from "@octopusdeploy/octopus-server-client/dist/src/resources/accessTokenResource";
import { jwtDecode, type JwtPayload } from "jwt-decode";
import type { Moment } from "moment";
import moment from "moment";
import { repository } from "~/clientInstance";
export async function generateBearerToken(): Promise<{
    token: AccessTokenResource;
    expiry: Moment;
}> {
    const newToken = await repository.Users.generateAccessToken();
    const payload = jwtDecode<JwtPayload>(newToken.AccessToken);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const expiryTime = moment(payload.exp! * 1000); //The payload time is seconds since Epoch, need to convert to milliseconds
    return { token: newToken, expiry: expiryTime };
}
