import type { ResourceCollection, OctopusPackageVersionBuildInformationMappedResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import BaseComponent from "~/components/BaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import PagingDataTable from "~/components/PagingDataTable";
import styles from "./style.module.less";
class BuildInformationResourceDataTable extends PagingDataTable<OctopusPackageVersionBuildInformationMappedResource> {
}
interface LatestBuildInformationListProps {
    spaceId: string;
    initialPackages: ResourceCollection<OctopusPackageVersionBuildInformationMappedResource>;
}
export class LatestBuildInformationList extends BaseComponent<LatestBuildInformationListProps> {
    render() {
        return (!!this.props.initialPackages && (<BuildInformationResourceDataTable initialData={this.props.initialPackages} onRow={(item) => this.buildRow(item)} filterSearchEnabled={true} onFilter={this.filter} apiSearchParams={["partialPackageId"]} headerColumns={["Package ID", "Highest version", "Source", "Branch", "Build"]} headerColumnClassNames={["", ""]} onEmpty={this.handleOnEmpty}/>));
    }
    private filter(filter: string, resource: OctopusPackageVersionBuildInformationMappedResource) {
        if (!filter || filter.length === 0) {
            return true;
        }
        const lowerCaseFilter = filter.toLowerCase();
        const matchesPackageId = resource.PackageId ? resource.PackageId.toLowerCase().includes(lowerCaseFilter) : false;
        const matchesVersion = resource.Version ? resource.Version.toLowerCase().includes(lowerCaseFilter) : false;
        return matchesPackageId || matchesVersion;
    }
    private handleOnEmpty = () => <div>No build information found</div>;
    private buildRow = (resource: OctopusPackageVersionBuildInformationMappedResource) => [
        <InternalLink to={links.buildInformationVersionsPage.generateUrl({ spaceId: this.props.spaceId, packageId: resource.PackageId })}>{resource.PackageId}</InternalLink>,
        <div className={styles.row}>
            <InternalLink to={links.buildInformationDetailPage.generateUrl({ spaceId: this.props.spaceId, id: resource.Id })}>{resource.Version}</InternalLink>
        </div>,
        resource.BuildEnvironment,
        resource.Branch,
        <ExternalLink href={resource.BuildUrl}>{resource.BuildNumber}</ExternalLink>,
    ];
    static displayName = "LatestBuildInformationList";
}
export default LatestBuildInformationList;
