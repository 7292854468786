/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import type { EmailProperties, EmailTeamIdProperties } from "@octopusdeploy/legacy-action-properties";
import type { GetTeamsLookupBffResponseTeam, SmtpIsConfiguredResource } from "@octopusdeploy/octopus-server-client";
import { ActionExecutionLocation, EmailPriority, Permission, SpecialVariables } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { SmtpNeedsConfigurationCallout } from "~/areas/configuration/components/Smtp/SmtpNeedsConfigurationCallout";
import { TargetRoles } from "~/areas/projects/components/Process/types";
import { repository } from "~/clientInstance";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ChipIcon, TeamChip } from "~/components/Chips";
import LookupResourceChipComponent from "~/components/LookupResourceChip";
import MoreInfo from "~/components/MoreInfo/MoreInfo";
import { TeamMultiSelect } from "~/components/MultiSelect/TeamMultiSelect";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Summary } from "~/components/form";
import ExpanderSectionHeading from "~/components/form/Sections/FormSectionHeading";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundRadioButtonGroup } from "~/primitiveComponents/form/RadioButton/BoundRadioButtonGroup";
import EmailLink from "~/primitiveComponents/navigation/EmailLink";
import ParseHelper from "../../../utils/ParseHelper/ParseHelper";
import Targets from "../Targets";
import type { ActionSummaryProps } from "../actionSummaryProps";
import type { ActionEditProps } from "../pluginRegistry";
import pluginRegistry from "../pluginRegistry";
class TeamsList extends Targets {
}
const LookupTeamChip = LookupResourceChipComponent<GetTeamsLookupBffResponseTeam>();
interface EmailActionSummaryState {
    teams?: GetTeamsLookupBffResponseTeam[];
}
class EmailActionSummary extends BaseComponent<ActionSummaryProps, EmailActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        const teams = await repository.Teams.lookupBff(this.getTeamIds());
        this.setState({ teams });
    }
    render() {
        const properties = this.props.properties;
        const teamIds = this.getTeamIds();
        return (<div>
                Send an email to <EmailLink emailAddress={properties[SpecialVariables.Action.Email.To] as string}/>
                {teamIds && this.state.teams && (<span>
                        <TeamsList targets={teamIds.map((id) => (<LookupTeamChip lookupCollection={this.state.teams!} key={id} lookupId={id} type={ChipIcon.Team} chipRender={(team) => <TeamChip team={team}/>}/>))}/>
                    </span>)}
                {properties[SpecialVariables.Action.Email.CC] || properties[SpecialVariables.Action.Email.CCTeamIds] || properties[SpecialVariables.Action.Email.Bcc] || properties[SpecialVariables.Action.Email.BccTeamIds]
                ? " (as well as CC/BCC)"
                : null}
            </div>);
    }
    private getTeamIds() {
        const toTeamIds = this.props.properties[SpecialVariables.Action.Email.ToTeamIds] as string;
        const teamIds = toTeamIds ? toTeamIds.split(",") : [];
        return teamIds;
    }
    static displayName = "EmailActionSummary";
}
interface EmailActionEditState {
    teams: GetTeamsLookupBffResponseTeam[];
    bodyHelpExpanded: boolean;
    smtpIsConfigured?: SmtpIsConfiguredResource;
}
class EmailActionEdit extends BaseComponent<ActionEditProps<EmailProperties>, EmailActionEditState> {
    constructor(props: ActionEditProps<EmailProperties>) {
        super(props);
        this.state = {
            teams: [],
            bodyHelpExpanded: false,
        };
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const [teams, smtpIsConfigured] = await Promise.all([repository.Teams.lookupBff(), repository.SmtpConfiguration.IsConfigured()]);
            this.setState({
                teams: teams,
                smtpIsConfigured,
            });
        });
    }
    render() {
        const properties = this.props.properties;
        return (<div>
                <ExpanderSectionHeading title="Send an Email"/>
                <SmtpNeedsConfigurationCallout configured={this.state.smtpIsConfigured ? this.state.smtpIsConfigured.IsConfigured : true} hasPermissions={isAllowed({ permission: Permission.ConfigureServer })}/>
                <ExpandableFormSection errorKey="Octopus.Action.Email.To" isExpandedByDefault={this.props.expandedByDefault} title="To" summary={this.toSummary()} help={<div>
                            At least one <em>To</em> address or <em>Team</em> must be entered.
                        </div>}>
                    <VariableLookupText localNames={this.props.localNames} label="To" placeholder="Separate multiple email addresses with ; or ," value={properties["Octopus.Action.Email.To"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.To"]: val })} error={this.props.getFieldError("Octopus.Action.Email.To")}/>
                    <TeamMultiSelect label="To Teams" items={this.state.teams} value={ParseHelper.parseCSV(properties["Octopus.Action.Email.ToTeamIds"])} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.ToTeamIds"]: ParseHelper.encodeCSV(val) })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.CC" isExpandedByDefault={this.props.expandedByDefault} title="CC" summary={this.ccSummary()}>
                    <VariableLookupText localNames={this.props.localNames} label="CC" placeholder="Separate multiple email addresses with ; or ," value={properties["Octopus.Action.Email.CC"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.CC"]: val })}/>
                    <TeamMultiSelect label="CC Teams" items={this.state.teams} value={ParseHelper.parseCSV(properties["Octopus.Action.Email.CCTeamIds"])} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.CCTeamIds"]: ParseHelper.encodeCSV(val) })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.Bcc" isExpandedByDefault={this.props.expandedByDefault} title="BCC" summary={this.bccSummary()}>
                    <VariableLookupText localNames={this.props.localNames} label="BCC" placeholder="Separate multiple email addresses with ; or ," value={properties["Octopus.Action.Email.Bcc"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.Bcc"]: val })}/>
                    <TeamMultiSelect label="BCC Teams" items={this.state.teams} value={ParseHelper.parseCSV(properties["Octopus.Action.Email.BccTeamIds"])} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.BccTeamIds"]: ParseHelper.encodeCSV(val) })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.Priority" isExpandedByDefault={this.props.expandedByDefault} title="Priority" help="Select the priority of the email." summary={this.prioritySummary()}>
                    <BoundRadioButtonGroup variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={""} value={properties["Octopus.Action.Email.Priority"] || EmailPriority.Normal} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.Priority"]: val as EmailPriority })}>
                        <RadioButton value={EmailPriority.Low} label="Low"/>
                        <RadioButton value={EmailPriority.Normal} label="Normal"/>
                        <RadioButton value={EmailPriority.High} label="High"/>
                    </BoundRadioButtonGroup>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.Subject" isExpandedByDefault={this.props.expandedByDefault} title="Subject" help="Provide the subject line of the email." summary={this.subjectSummary()}>
                    <VariableLookupText localNames={this.props.localNames} label="Subject" value={properties["Octopus.Action.Email.Subject"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.Subject"]: val })} error={this.props.getFieldError("Octopus.Action.Email.Subject")}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.Email.Body" isExpandedByDefault={this.props.expandedByDefault} title="Body" help="Provide the email body as raw text or HTML." summary={this.bodySummary()}>
                    <VariableLookupText localNames={this.props.localNames} label="Body" multiline={true} value={properties["Octopus.Action.Email.Body"]} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.Body"]: val })}/>
                    <MoreInfo showLabel={"Show extended template syntax."} hideLabel={"Hide extended template syntax."}>
                        <div>
                            <p>
                                <b>Extended template syntax</b>
                            </p>
                            <span>
                                Conditional <code>if</code> and <code>unless</code>:<pre>{"#{if MyVar}...#{/if}"}</pre>
                            </span>
                            <br />
                            <span>
                                Iteration over variable sets or comma-separated values with <code>each</code>:<pre>{"#{each mv in MyVar}...#{mv}...#{/each}"}</pre>
                            </span>
                        </div>
                    </MoreInfo>
                    <RadioButtonGroup title="Email body format" value={properties["Octopus.Action.Email.IsHtml"] || "False"} onChange={(val) => this.props.setProperties({ ["Octopus.Action.Email.IsHtml"]: val })}>
                        <RadioButton value="False" label="Body is plain text" isDefault={true}/>
                        <RadioButton value="True" label="Body is HTML"/>
                    </RadioButtonGroup>
                </ExpandableFormSection>
            </div>);
    }
    toSummary(): SummaryNode {
        return this.emailSummary("No recipient addresses set", "Octopus.Action.Email.To", "Octopus.Action.Email.ToTeamIds");
    }
    ccSummary(): SummaryNode {
        return this.emailSummary("No CC addresses set", "Octopus.Action.Email.CC", "Octopus.Action.Email.CCTeamIds");
    }
    bccSummary(): SummaryNode {
        return this.emailSummary("No BCC addresses set", "Octopus.Action.Email.Bcc", "Octopus.Action.Email.BccTeamIds");
    }
    emailSummary(nothing: string, text: string, keyedTeamProperty: keyof EmailTeamIdProperties): SummaryNode {
        const bcc = (this.props.properties as any)[text];
        const teams = ParseHelper.parseCSV(this.props.properties[keyedTeamProperty]);
        if (!bcc && teams.length === 0) {
            return Summary.placeholder(nothing);
        }
        const chips = teams.map((t) => <LookupTeamChip lookupCollection={this.state.teams} key={t} lookupId={t} type={ChipIcon.Environment} chipRender={(team) => <TeamChip team={team}/>}/>);
        return Summary.summary(<div>
                {bcc} {chips}
            </div>);
    }
    subjectSummary(): SummaryNode {
        const val = this.props.properties["Octopus.Action.Email.Subject"];
        return val ? Summary.summary(val) : Summary.placeholder("The email subject has not been provided");
    }
    prioritySummary(): SummaryNode {
        const val = this.props.properties["Octopus.Action.Email.Priority"] || EmailPriority.Normal;
        return val === EmailPriority.Normal ? Summary.default("Normal") : Summary.summary(val);
    }
    bodySummary(): SummaryNode {
        const val = this.props.properties["Octopus.Action.Email.Body"];
        return val ? Summary.summary("The email body has been provided") : Summary.placeholder("An email body has not been provided");
    }
    static displayName = "EmailActionEdit";
}
pluginRegistry.registerAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    canRunOnWorker: false,
    actionType: "Octopus.Email",
    canRunInContainer: false,
    summary: (properties, targetRolesAsCSV) => <EmailActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV}/>,
    canHaveChildren: (step) => false,
    canBeChild: true,
    editSections: { default: EmailActionEdit },
    targetRoleOption: (action) => TargetRoles.None,
    canBeRetried: false,
    canUseExecutionTimeouts: false,
});
