import type { GitRefResource } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useParams } from "react-router";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, ActionStatus, useAnalyticActionDispatch } from "~/analytics/Analytics";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
interface StepEditorLinkProps {
    element: UniqueActivityElement;
    processId?: string;
    stepsCorrelationIds?: {
        [key: string]: string;
    };
    branch?: GitRefResource;
    className?: string;
}
export const StepEditorLink = (props: StepEditorLinkProps) => {
    const dispatchAction = useAnalyticActionDispatch();
    const { element, className, processId, stepsCorrelationIds, branch } = props;
    const { spaceId, projectSlug, projectId, runbookId } = useParams<{
        spaceId?: string;
        projectSlug?: string;
        projectId?: string;
        runbookId?: string;
    }>();
    if (!spaceId || !projectSlug) {
        return null;
    }
    if (!stepsCorrelationIds || !stepsCorrelationIds?.[element.Id]) {
        return null;
    }
    return runbookId
        ? RunbookStepEditorLink(element, spaceId, projectSlug, dispatchAction, stepsCorrelationIds, runbookId, processId, projectId, className)
        : DeploymentStepEditorLink(element, spaceId, projectSlug, dispatchAction, stepsCorrelationIds, projectId, branch, className);
};
const DeploymentStepEditorLink = (element: UniqueActivityElement, spaceId: string, projectSlug: string, dispatchAction: AnalyticActionDispatcher, stepsCorrelationIds: {
    [key: string]: string;
}, projectId?: string, branch?: GitRefResource, className?: string) => {
    const permissions: Permission.ProcessEdit | Permission.ProcessView | false = isAllowed({
        permission: Permission.ProcessEdit,
        project: projectId,
    })
        ? Permission.ProcessEdit
        : isAllowed({
            permission: Permission.ProcessView,
            project: projectId,
        })
            ? Permission.ProcessView
            : false;
    if (!permissions) {
        return null;
    }
    const stepLink = branch
        ? links.branchDeploymentProcessStepByStepSlugRedirect.generateUrl({ spaceId, projectSlug, branchName: branch.CanonicalName, stepSlug: stepsCorrelationIds[element.Id] })
        : links.deploymentProcessStepByStepSlugRedirect.generateUrl({ spaceId, projectSlug, stepSlug: stepsCorrelationIds[element.Id] });
    const actionStatus = element.Status === ActivityStatus.Success ? ActionStatus.Success : element.Status === ActivityStatus.Failed ? ActionStatus.Failed : element.Status === ActivityStatus.SuccessWithWarning ? ActionStatus.SuccessWithWarning : undefined;
    return (<InternalLink className={className} to={stepLink} onClick={() => dispatchAction("Click Edit Step Link", { resource: "Deployment Process", action: Action.View, isCaCenabled: branch === undefined ? "False" : "True", status: actionStatus })}>
            {permissions === Permission.ProcessEdit ? "Edit step" : "View step"}
        </InternalLink>);
};
const RunbookStepEditorLink = (element: UniqueActivityElement, spaceId: string, projectSlug: string, dispatchAction: AnalyticActionDispatcher, stepsCorrelationIds: {
    [key: string]: string;
}, runbookId: string, processId?: string, projectId?: string, className?: string) => {
    if (!processId) {
        return null;
    }
    const permissions: Permission.RunbookEdit | Permission.RunbookView | false = isAllowed({
        permission: Permission.RunbookEdit,
        project: projectId,
    })
        ? Permission.RunbookEdit
        : isAllowed({
            permission: Permission.RunbookView,
            project: projectId,
        })
            ? Permission.RunbookView
            : false;
    if (!permissions) {
        return null;
    }
    const stepLink = links.projectRubookProcessStepByStepSlugRedirect.generateUrl({ spaceId, projectSlug, runbookId, processId, stepSlug: stepsCorrelationIds[element.Id] });
    const actionStatus = element.Status === ActivityStatus.Success ? ActionStatus.Success : element.Status === ActivityStatus.Failed ? ActionStatus.Failed : element.Status === ActivityStatus.SuccessWithWarning ? ActionStatus.SuccessWithWarning : undefined;
    return (<InternalLink className={className} to={stepLink} onClick={() => dispatchAction("Click Edit Step Link", { resource: "Runbook Process", action: Action.View, status: actionStatus })}>
            {permissions === Permission.RunbookEdit ? "Edit step" : "View step"}
        </InternalLink>);
};
