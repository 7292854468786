import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonConfigurationSubscriptionOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="Subscriptions">Subscriptions</ExternalLink> allow you to subscribe to events that are happening within Octopus, so you can be notified when events have occurred and react accordingly.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
