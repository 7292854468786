import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { MaintenanceConfigurationResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { Section } from "~/components/Section/Section";
import { Note } from "~/components/form";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface MaintenanceState extends DataBaseComponentState {
    configuration?: MaintenanceConfigurationResource;
}
export class MaintenancePage extends DataBaseComponent<{}, MaintenanceState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const configuration = await repository.MaintenanceConfiguration.get();
            this.setState({ configuration });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    setMaintenanceMode(value: boolean) {
        return this.doBusyTask(async () => {
            const configuration = await repository.MaintenanceConfiguration.get();
            configuration.IsInMaintenanceMode = value;
            await repository.MaintenanceConfiguration.modify(configuration);
            this.setState({ configuration });
        });
    }
    render() {
        const config = this.state.configuration;
        const toggleMaintenanceModePageAction: PrimaryPageAction | undefined = config
            ? {
                type: "button",
                label: config.IsInMaintenanceMode ? "Disable maintenance mode" : "Enable maintenance mode",
                busyLabel: config.IsInMaintenanceMode ? "Disabling..." : "Enabling...",
                disabled: this.state.busy,
                onClick: () => this.setMaintenanceMode(!config.IsInMaintenanceMode),
            }
            : undefined;
        const body = config && (<Section>
                <Note>
                    When maintenance mode is enabled, users with the <strong>Administer System</strong> permission will be able to make changes and initiate deployments, just like normal. All other users will be prevented from making changes or
                    starting new tasks. Learn about <ExternalLink href="MaintenanceMode">maintenance mode</ExternalLink>.
                </Note>
                <p>
                    Maintenance mode is currently <strong>{config.IsInMaintenanceMode ? "Enabled" : "Disabled"}</strong>
                </p>
            </Section>);
        return (<PaperLayoutVNext title="Maintenance" busy={this.state.busy} errors={this.errors} primaryAction={toggleMaintenanceModePageAction}>
                {body}
            </PaperLayoutVNext>);
    }
    static displayName = "MaintenancePage";
}
