/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { AzureContainerRegistryFeedResource, FeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import AdvancedFeedCredentialsFormSection, { AuthenticationType } from "../AdvancedFeedCredentialsFormSection";
import type { FeedEditProps } from "./feedTypeRegistry";
import feedTypeRegistry from "./feedTypeRegistry";
const supportedAuthenticationTypes = [AuthenticationType.UsernameAndPassword];
class AzureContainerRegistryFeed extends React.Component<FeedEditProps<AzureContainerRegistryFeedResource>> {
    render() {
        return (<React.Fragment>
                <AdvancedFeedCredentialsFormSection model={{ username: this.props.feed.Username, password: this.props.feed.Password }} isNew={!this.props.feed.Id} onChanged={(model) => this.props.onChange({ ...this.props.feed, Password: model.password, Username: model.username })} supportedAuthenticationTypes={supportedAuthenticationTypes} containerKey={this.props.expandableContainerKey}/>
            </React.Fragment>);
    }
    static displayName = "AzureContainerRegistryFeed";
}
feedTypeRegistry.registerFeedType({
    type: FeedType.AzureContainerRegistry,
    edit: AzureContainerRegistryFeed as React.ComponentType<FeedEditProps<FeedResource>>,
    text: "Azure Container Registry",
    uriNotes: (<React.Fragment>
            <div>
                See Azure Container Registry <ExternalLink href="ACRRegistryLinks">documentation</ExternalLink>.
                <br />
                Configure an <ExternalLink href="U3Ak1K">Azure Contaner Registry</ExternalLink> in Octopus.
            </div>
        </React.Fragment>),
    uriDefault: "https://{myoctoregistry-on}.azurecr.io",
});
