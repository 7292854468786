import type { Client } from "@octopusdeploy/octopus-server-client";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import last from "lodash/last";
import React from "react";
import { v4 } from "uuid";
import type { PageSessionId, TrackedRequest } from "./types";
export type TrackedRequestsForPageSession = {
    pageSessionId: PageSessionId;
    page: PageDefinition;
    trackedRequests: ReadonlyArray<TrackedRequest>;
};
export const useMiniProfilerTrackedRequests = (client: Client, enabled: boolean) => {
    const [trackedRequests, setTrackedRequests] = React.useState<TrackedRequestsForPageSession[]>([]);
    const page = usePage();
    React.useEffect(() => {
        if (!page) {
            return;
        }
        return client.subscribeToResponses("miniProfiler", (details) => {
            if (details.miniProfileId.length > 0) {
                if (enabled) {
                    setTrackedRequests((prev) => {
                        const trackedRequest = { originalRequestUrl: details.url, profilerId: details.miniProfileId[details.miniProfileId.length - 1] };
                        const lastPage = last(prev);
                        if (!lastPage || lastPage.page.Id !== page.Id) {
                            return [...prev, { pageSessionId: v4(), page: { ...page }, trackedRequests: [trackedRequest] }];
                        }
                        return [...prev.slice(0, prev.length - 1), { ...lastPage, trackedRequests: [...lastPage.trackedRequests, trackedRequest] }];
                    });
                }
            }
        });
    }, [client, enabled, page]);
    return { trackedRequests, clearTrackedRequests: () => setTrackedRequests([]) };
};
