import { css } from "@emotion/css";
import { ChevronLeftIcon, DrawnOctopusIllustration } from "@octopusdeploy/design-system-components";
import { text } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { Route, useLocation } from "react-router-dom";
import { repository } from "~/clientInstance";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { EmptyPage } from "../EmptyPage/EmptyPage";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
export const stateFor404Redirect = Symbol();
export const RedirectAs404 = () => <Route render={({ location }) => <InternalRedirect to={{ ...location, state: stateFor404Redirect }}/>}/>;
export function NotFound() {
    const { pathname } = useLocation();
    React.useEffect(() => {
        logger.info(`The path "{pathname}" did not match any routes`, { pathname });
    }, [pathname]);
    const action = (<InternalLink className={goBackLinkStyles} to={repository.spaceId ? links.spaceRootRedirect.generateUrl({ spaceId: repository.spaceId }) : links.rootRedirect.generateUrl()}>
            <ChevronLeftIcon />
            Go back home
        </InternalLink>);
    return (<main>
            <EmptyPage title="Unable to locate page..." image={<DrawnOctopusIllustration />} description={"Our badly drawn octopus suggests retracing your steps."} action={action}/>
        </main>);
}
const goBackLinkStyles = css({
    display: "flex",
    alignItems: "center",
    font: text.link.bold.medium,
});
