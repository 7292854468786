/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DeploymentStepResource, DeploymentActionResource, IProcessResource, ProcessType, DeploymentActionContainer } from "@octopusdeploy/octopus-server-client";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import type { ActionPlugin } from "../../../../components/Actions/pluginRegistry";
export interface ProcessPageSupportedActions {
    refreshLookupData: () => Promise<void>;
}
export interface AssembledAction {
    step: StoredStep;
    action: StoredAction | null;
    actionTypeName: string;
    pageTitle: string;
}
export interface AssembleParentStepResult {
    step: StoredStep;
    pageTitle: string;
}
export enum EnvironmentOption {
    All = "all",
    Include = "include",
    Exclude = "exclude"
}
export enum ExecutionLocation {
    OctopusServer = "OctopusServer",
    WorkerPool = "WorkerPool",
    WorkerPoolForRoles = "WorkerPoolForRoles",
    OctopusServerForRoles = "OctopusServerForRoles",
    DeploymentTarget = "DeploymentTarget"
}
export type RunOnServerOrWorkerPool = RunOnBuiltInWorker | RunOnWorkerPool;
export type RunOn = RunOnDeploymentTarget | RunOnServerOrWorkerPool;
export class RunOnDeploymentTarget implements RunOnModel {
    executionLocation = ExecutionLocation.DeploymentTarget;
}
export class RunOnBuiltInWorker implements RunOnModel {
    executionLocation: ExecutionLocation.OctopusServer | ExecutionLocation.OctopusServerForRoles = undefined!;
    runningInContainer: boolean = undefined!;
    container: DeploymentActionContainer = undefined!;
}
export class RunOnWorkerPool implements RunOnModel {
    executionLocation: ExecutionLocation.WorkerPool | ExecutionLocation.WorkerPoolForRoles = undefined!;
    runningInContainer: boolean = undefined!;
    container: DeploymentActionContainer = undefined!;
}
export enum TargetRoles {
    Optional,
    None,
    Required
}
export interface RunOnModel {
    executionLocation: ExecutionLocation;
}
export interface ProcessFilter {
    actionId?: string;
    actionType?: string;
    reloadKey?: string;
    stepTemplates?: string;
    childStepTemplates?: string;
    templateId?: string;
    parentStepId?: string;
    blueprintId?: string;
    blueprintActionId?: string;
    blueprintParentStepId?: string;
    new?: string;
    actionCategory?: string;
    tags?: string[];
}
export interface ProcessQuery extends IQuery, ProcessFilter {
}
export type ByIdLookup<TModel> = {
    byId: {
        [id: string]: TModel;
    };
};
export type AllIds = {
    allIds: string[];
};
export interface LookupState<T> {
    [key: string]: T;
}
export interface StoredErrors {
    steps: {
        [id: string]: {
            [key: string]: string;
        };
    };
    actions: {
        [id: string]: {
            [key: string]: string;
        };
    };
    global: {
        [key: string]: string;
    };
    globalMessage: string;
}
export type ActionError = {
    key: string;
    stepId: string;
    actionId: string;
    value: string;
};
export type StepError = {
    key: string;
    stepId: string;
    value: string;
};
export type GlobalError = {
    key: string;
    value: string;
};
export type ErrorValuePair = {
    key: string;
    value: string;
};
export type ProcessError = ActionError | StepError | GlobalError;
export type StoredStep = Omit<DeploymentStepResource, "Actions"> & {
    ActionIds: string[];
};
export interface StoredAction extends DeploymentActionResource {
    ParentId: string;
    plugin: ActionPlugin;
}
export type ProcessState = Omit<IProcessResource, "Steps"> | null;
export type StepsState = ByIdLookup<StoredStep> & AllIds;
export type ActionsState = ByIdLookup<StoredAction> & AllIds;
export type StoredModelState = {
    process: ProcessState;
    actions: ActionsState;
    steps: StepsState;
};
export type MergeModelState = {
    staged: StoredModelState;
    server: StoredModelState;
    processMerged: boolean;
    dialogClosed: boolean;
};
export type ProcessContextModelState = {
    model: StoredModelState;
    cleanModel: StoredModelState;
    mergeModel: MergeModelState;
    processType: ProcessType;
};
export type MetaStepProperties = Omit<StoredStep, "Properties" | "Actions" | "Id">;
export type MetaActionProperties = Omit<StoredAction, "Properties" | "Id" | "ActionType" | "Links">;
export type SelectorType<TTarget extends {
    [key: string]: (...args: never[]) => unknown;
}> = {
    [K in keyof TTarget]: ReturnType<TTarget[K]>;
};
//#region Warning guidance / validation
export interface Warnings {
    message: string;
    warnings: string[];
    parsedHelpLinks?: string[];
    helpText?: string;
    helpLink?: string;
    fieldWarnings: {
        [other: string]: string;
    };
    details?: {
        [key: string]: string;
    };
}
export interface StoredWarnings {
    steps: {
        [id: string]: {
            [key: string]: string;
        };
    };
    actions: {
        [id: string]: {
            [key: string]: string;
        };
    };
    global: {
        [key: string]: string;
    };
    globalMessage: string;
}
export type ActionWarning = {
    key: string;
    stepId: string;
    actionId: string;
    value: string;
};
export type StepWarning = {
    key: string;
    stepId: string;
    value: string;
};
export type GlobalWarning = {
    key: string;
    value: string;
};
export type WarningValuePair = {
    key: string;
    value: string;
};
export type ProcessWarning = ActionWarning | StepWarning | GlobalWarning;
//#endregion
