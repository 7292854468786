import moment from "moment";
import type { Moment } from "moment";
export default class DateFormatter {
    public static timestamp(): string {
        return Date.now().toString();
    }
    public static dateToShortFormat(dateTime: string | Moment | undefined): string | null {
        if (!dateTime) {
            return null;
        }
        const dateMoment = this.momentFromTextOrObject(dateTime);
        return dateMoment.format("lll"); // Sep 11, 2018 1:02 PM
    }
    public static dateToLongFormat(dateTime: string | Moment | undefined | null): string | null {
        if (!dateTime) {
            return null;
        }
        const dateMoment = this.momentFromTextOrObject(dateTime);
        return dateMoment.format("LLLL Z"); // Tuesday, September 11, 2018 1:02 PM +10:00
    }
    public static dateToLongFormatWithSeconds(dateTime: string | Moment | undefined): string | null {
        if (!dateTime) {
            return null;
        }
        const dateMoment = this.momentFromTextOrObject(dateTime);
        return dateMoment.format("dddd, MMMM D, YYYY h:mm:ss A"); // Tuesday, September 11, 2018 1:02:36 PM
    }
    public static dateToCustomFormat(dateTime: string | Moment | undefined | null, format: string): string | null {
        if (!dateTime) {
            return null;
        }
        const dateMoment = this.momentFromTextOrObject(dateTime);
        return dateMoment.format(format);
    }
    public static moment(input: string, format: string): string {
        const when = new Date(Date.parse(input));
        const m = moment(when);
        format = format || "LLLL";
        const display = format === "ago" ? m.fromNow() : m.format(format);
        return display;
    }
    public static momentAgo(input: string): string {
        const when = new Date(Date.parse(input));
        return moment(when).fromNow();
    }
    public static momentFrom(fromDate: string, toDate: string): string | null {
        if (!fromDate) {
            return null;
        }
        const fromMoment = moment(fromDate);
        const toMoment = toDate ? moment(toDate) : moment();
        const diff = fromMoment.diff(toMoment);
        const duration = moment.duration(diff);
        const mins = duration.minutes();
        const hours = duration.hours();
        const days = Math.floor(duration.asDays());
        let result = mins === 1 ? "1 minute" : mins + " minutes";
        result = (hours !== 0 ? (hours === 1 ? "1 hour" : hours + " hours") + " and " : "") + result;
        result = (days !== 0 ? (days === 1 ? "1 day" : days + " days") + ", " : "") + result;
        return result;
    }
    private static momentFromTextOrObject(dateTime: string | Moment) {
        const dateMoment = typeof dateTime === "object" ? dateTime : moment(dateTime);
        return dateMoment;
    }
}
