import { pageId } from "@octopusdeploy/portal-routes";
export default () => ({
    releases: pageId({ area: "Project", id: "Project.Releases", name: "Releases" }),
    releaseCreate: pageId({ area: "Project", id: "Project.ReleaseNew", name: "Add New Release" }),
    release: () => {
        return {
            root: pageId({ area: "Project", id: "Project.ReleaseVersion", name: "Release Version" }),
            edit: pageId({ area: "Project", id: "Project.ReleaseVersionEdit", name: "Edit Release Version" }),
            deployments: {
                specific: pageId({ area: "Project", id: "Project.Deployment", name: "Deployment" }),
                retry: pageId({ area: "Project", id: "Project.DeploymentRetry", name: "Retry Deployment" }),
                create: pageId({ area: "Project", id: "Project.DeploymentNew", name: "Add New Deployment" }),
            },
        };
    },
});
