import * as React from "react";
import { RunbookRunNowLayout } from "./RunbookRunNowLayout";
type RerunSnapshotProps = {
    runbookSnapshotId: string;
};
type CreateRunProps = {};
type RunbookRunNowPageProps = RerunSnapshotProps | CreateRunProps;
export function RunbookRunNowPage(props: RunbookRunNowPageProps) {
    return <RunbookRunNowLayout runbookSnapshotId={isRerunSnapshotProps(props) ? props.runbookSnapshotId : undefined} goal={{}}/>;
}
function isRerunSnapshotProps(props: RunbookRunNowPageProps): props is RerunSnapshotProps {
    return "runbookSnapshotId" in props;
}
