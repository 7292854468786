function addFeature(currentFeatures: string, featureToAdd: string) {
    if (currentFeatures) {
        return `${currentFeatures},${featureToAdd}`;
    }
    else {
        return featureToAdd;
    }
}
function removeFeature(currentFeatures: string, featureToAdd: string) {
    const oldFeatures = currentFeatures.split(",");
    return oldFeatures.filter((v: string) => !v || v !== featureToAdd).join(",");
}
export function toggleFeature(currentFeatures: string, featureToToggle: string) {
    if (!currentFeatures) {
        currentFeatures = "";
    }
    if (currentFeatures.includes(featureToToggle)) {
        return removeFeature(currentFeatures, featureToToggle);
    }
    else {
        return addFeature(currentFeatures, featureToToggle);
    }
}
