import * as React from "react";
import type { SvgImageProps } from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import SvgImage from "../../../primitiveComponents/dataDisplay/SvgImage/SvgImage";
import styles from "../../../primitiveComponents/dataDisplay/SvgImage/styles.module.less";
const AzureVm = (props: Partial<SvgImageProps>) => (<SvgImage viewBox="0 0 70 70" title="Azure Vm" {...props}>
        <g fillRule="nonzero" strokeWidth="1" transform="translate(6, 0)">
            <path className={styles.imgNavy} d="M27.333 45.748V57.5c-1.169.431-2.155 1.04-2.958 1.826-.803.786-1.345 1.673-1.625 2.66H10.312c-.791.341-1.187.846-1.187 1.514s.396 1.165 1.188 1.491H22.75c1.162 3.006 3.141 4.509 5.938 4.509 2.796 0 4.736-1.503 5.819-4.509H46.75c1-.264 1.5-.761 1.5-1.491 0-.73-.5-1.234-1.5-1.514H34.507c-.272-1.098-.735-1.985-1.389-2.66-.653-.675-1.598-1.243-2.833-1.702V45.748h-2.952z"/>
            <g>
                <rect className={styles.imgCyan} width="57" height="48" x=".25" y=".5" rx="3"/>
                <path className={styles.imgWhite} d="M29.1 22.62H29l-13.56-7.83c-.16 0-.16-.15-.16-.31a.51.51 0 0 1 .16-.3L29 6.5h.46l13.51 7.83c.15 0 .15.15.15.31s0 .3-.15.3l-13.41 7.68h-.46M27.26 41.5h-.16l-13.5-7.83c-.16 0-.16-.15-.16-.31V17.71a.52.52 0 0 1 .16-.31h.46l13.51 7.83c.15 0 .15.15.15.31v15.65a.53.53 0 0 1-.15.31h-.31m3.84 0h-.16c-.15 0-.15-.15-.15-.31v-15.5a.57.57 0 0 1 .15-.31l13.51-7.83h.46c.16 0 .16.16.16.31v15.5a.52.52 0 0 1-.16.31L31.4 41.5h-.3"/>
            </g>
        </g>
    </SvgImage>);
export { AzureVm };
