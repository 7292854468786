import { Tooltip } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
interface MissingVariablesIcon {
    show: boolean;
    withoutTooltip?: boolean;
}
const MissingVariablesIcon: React.StatelessComponent<MissingVariablesIcon> = (props) => {
    if (!props.show) {
        return null;
    }
    if (props.withoutTooltip) {
        return <AlertIcon />;
    }
    return (<Tooltip content={`Some of the required variable values have not been set`}>
            <AlertIcon />
        </Tooltip>);
};
MissingVariablesIcon.displayName = "MissingVariablesIcon"
function AlertIcon() {
    return <em className={"fa-solid fa-triangle-exclamation"} style={{ color: themeTokens.color.icon.warning }}/>;
}
export default MissingVariablesIcon;
