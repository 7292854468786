/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Checkbox } from "@octopusdeploy/design-system-components";
import type { ChangeRequestSettings, JiraServiceManagementChangeRequestSettings } from "@octopusdeploy/octopus-server-client";
import { ChangeRequestSettingsType, ServiceNowChangeRequestSettings } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ExpandableFormSection, FormSectionHeading, Summary } from "~/components/form";
import Text from "~/primitiveComponents/form/Text/Text";
import type { JiraServiceManagementProjectSettings, ServiceNowProjectSettings } from "./getItsmChangeRequestConfiguration";
interface ChangeRequestSettingsProps {
    context: ServiceNowProjectSettings | JiraServiceManagementProjectSettings;
    changeRequestSettings?: ChangeRequestSettings;
    onChangeRequestSettingsChanged(changeRequestSettings: ChangeRequestSettings): void;
}
const ItsmChangeRequestSettings: React.StatelessComponent<ChangeRequestSettingsProps> = (props) => {
    if (props.context.ExtensionId === "servicenow-integration") {
        const snowSettings = (props.changeRequestSettings as ServiceNowChangeRequestSettings) ?? { Type: ChangeRequestSettingsType.ServiceNow };
        return (<ServiceNowChangeRequestSettings changeRequestSettings={snowSettings} isStandardChange={!!(props.context.Values.StandardChangeTemplateName && props.context.Values.StandardChangeTemplateName !== "")} onChangeRequestSettingsChanged={props.onChangeRequestSettingsChanged}/>);
    }
    const jsmSettings = (props.changeRequestSettings as JiraServiceManagementChangeRequestSettings) ?? { Type: ChangeRequestSettingsType.JiraServiceManagement };
    return <JsmIssueSettings changeRequestSettings={jsmSettings} onChangeRequestSettingsChanged={props.onChangeRequestSettingsChanged}/>;
};
ItsmChangeRequestSettings.displayName = "ItsmChangeRequestSettings"
interface ServiceNowChangeRequestSettingsProps {
    changeRequestSettings: ServiceNowChangeRequestSettings;
    isStandardChange: boolean;
    onChangeRequestSettingsChanged(changeRequestSettings: ServiceNowChangeRequestSettings): void;
}
const ServiceNowChangeRequestSettings: React.StatelessComponent<ServiceNowChangeRequestSettingsProps> = (props) => {
    return (<>
            <FormSectionHeading title="ServiceNow Change Request settings"/>

            {!!!props.changeRequestSettings.IsEmergencyChange && (<ExpandableFormSection title="Change Request Number" errorKey="changeRequestNumber" help="If a CR already exists for this deployment, enter it here and a new CR will not be created, and instead, the supplied CR number will be used during the approval check." summary={buildChangeRequestNumberSummary()}>
                    <Text value={props.changeRequestSettings.ChangeRequestNumber ?? ""} onChange={(number) => props.onChangeRequestSettingsChanged({ ...props.changeRequestSettings, ChangeRequestNumber: number })} label="Change Request Number"/>
                </ExpandableFormSection>)}
            {(!props.changeRequestSettings.ChangeRequestNumber || props.changeRequestSettings.ChangeRequestNumber === "") && (<ExpandableFormSection title="Emergency Change" errorKey="emergencyChange" help={`If this is a high priority change, check this checkbox and an Emergency Change Request will be raised instead of a ${props.isStandardChange ? "Standard" : "Normal"} Change Request`} summary={buildEmergencyChangeSummary()}>
                    <Checkbox label="Emergency Change" value={props.changeRequestSettings.IsEmergencyChange ?? false} onChange={(isEmergencyChange) => props.onChangeRequestSettingsChanged({ ...props.changeRequestSettings, IsEmergencyChange: isEmergencyChange })}/>
                </ExpandableFormSection>)}
        </>);
    function buildChangeRequestNumberSummary() {
        return !!!props.changeRequestSettings.ChangeRequestNumber || props.changeRequestSettings.ChangeRequestNumber === ""
            ? Summary.summary(`A ${props.isStandardChange ? "Standard" : "Normal"} Change Request will be created by Octopus`)
            : Summary.summary(`Change Request '${props.changeRequestSettings.ChangeRequestNumber}' will be used for approval`);
    }
    function buildEmergencyChangeSummary() {
        return !!props.changeRequestSettings.IsEmergencyChange || props.changeRequestSettings.IsEmergencyChange
            ? Summary.summary("An Emergency Change Request will be created by Octopus")
            : Summary.summary(`A ${props.isStandardChange ? "Standard" : "Normal"} change will be created by Octopus`);
    }
};
ServiceNowChangeRequestSettings.displayName = "ServiceNowChangeRequestSettings"
interface JsmIssueSettingsProps {
    changeRequestSettings: JiraServiceManagementChangeRequestSettings;
    onChangeRequestSettingsChanged(changeRequestSettings: JiraServiceManagementChangeRequestSettings): void;
}
const JsmIssueSettings: React.StatelessComponent<JsmIssueSettingsProps> = (props) => {
    return (<>
            <FormSectionHeading title="Jira Service Management Issue settings"/>

            <ExpandableFormSection title="Issue Number" errorKey="issueNumber" help="If an Issue already exists, enter this here and a new Issue will not be created, and instead, the supplied Issue number will be used during the approval check." summary={buildSummary}>
                <Text value={props.changeRequestSettings.IssueNumber ?? ""} onChange={(number) => props.onChangeRequestSettingsChanged({ ...props.changeRequestSettings, IssueNumber: number })} label="Issue Number"/>
            </ExpandableFormSection>
        </>);
    function buildSummary() {
        return props.changeRequestSettings.IssueNumber === "" ? Summary.summary("A new Issue will be created by Octopus") : Summary.summary(`Issue ${props.changeRequestSettings.IssueNumber} will be used for approval`);
    }
};
JsmIssueSettings.displayName = "JsmIssueSettings"
export default ItsmChangeRequestSettings;
