/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { IIdName } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ContextualMissingChip, TeamChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface TeamMultiSelectProps extends FormFieldProps<string[]> {
    items: IIdName[];
    canBeDeleted?: (team: IIdName) => boolean;
    label?: string | JSX.Element;
    error?: string;
    descriptionPostfix?: (team: IIdName) => string;
}
const TeamTypedMultiSelect = MultiSelect<IIdName>();
export const TeamMultiSelect: React.FC<TeamMultiSelectProps> = (props) => {
    const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => {
                const onRequestDeleteProp = props.canBeDeleted!(item) ? { onRequestDelete } : {};
                return <TeamChip {...onRequestDeleteProp} deleteButtonAccessibleName={`Delete ${item.Name}`} team={item} descriptionPostfix={props.descriptionPostfix ? props.descriptionPostfix(item) : null!}/>;
            }} renderFallback={<ContextualMissingChip lookupKey={r.Id} type={ChipIcon.Team} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <TeamTypedMultiSelect label="Select teams" renderChip={chipRenderer} {...props}/>;
};
TeamMultiSelect.displayName = "TeamMultiSelect"
TeamMultiSelect.defaultProps = {
    canBeDeleted: (team) => true,
};
