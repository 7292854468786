import { css, cx } from "@emotion/css";
import { Pagination, useIsMobile } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import { Permission, type ProjectsDashboardFilter, type UserFavouriteProjectSummary } from "@octopusdeploy/octopus-server-client";
import type { AnalyticsEvent } from "@octopusdeploy/portal-analytics";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { useState, useCallback } from "react";
import * as React from "react";
import { DashboardProjectGroups, ProjectGroupDeploymentsSkeleton } from "~/areas/dashboard/DashboardOverview/DashboardProjectGroups";
import type { FavouriteProjectsData } from "~/areas/projects/components/DashboardDataSource/DataSet";
import { repository, session } from "~/clientInstance";
import { getErrorsFromError } from "~/components/DataBaseComponent/Errors";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import { ProjectsDashboardAdvancedFilters, ProjectsDashboardAdvancedFiltersSkeleton } from "./ProjectsDashboardAdvancedFilters";
import { ProjectsDashboardSearchBox, ProjectsDashboardSearchBoxSkeleton } from "./ProjectsDashboardSearchBox";
import { Skeleton } from "./Skeleton";
export interface ProjectsFilter {
    page: number;
    pageSize: ProjectsPageSize;
    searchValue: string;
}
export const projectsPageSizes = [50, 100, 150] as const;
export type ProjectsPageSize = (typeof projectsPageSizes)[number];
export const defaultProjectsPageSize: ProjectsPageSize = 50;
export interface ProjectsDashboardProps {
    spaceId: string;
    doBusyTask: DoBusyTask;
    filter: ProjectsFilter;
    setFilter: (newFilter: ProjectsFilter) => void;
    onAddProjectToGroup: (projectGroupId: string) => void;
}
export function ProjectsDashboard({ doBusyTask, spaceId, filter, setFilter, onAddProjectToGroup }: ProjectsDashboardProps) {
    const trackEvent = useTrackEvent();
    const isMobile = useIsMobile();
    const { isLoading: isLoadingInitialDashboard, result: initialDashboardData, refetch: refetchInitialDashboard, error: initialDashboardError } = useInitialDashboardQuery(getDashboardFiltersQueryParams(filter));
    const { isLoading: isLoadingDetailedDashboard, result: detailedDashboardData, refetch: refetchDetailedDashboard, error: detailedDashboardError } = useDetailedDashboardQuery(getDashboardFiltersQueryParams(filter));
    const favouriteProjectsData = useFavouriteData(doBusyTask);
    const dashboardData = (isLoadingDetailedDashboard ? null : detailedDashboardData) ?? initialDashboardData;
    const onSearchValueChanged = (newValue: string) => {
        setFilter({ ...filter, searchValue: newValue, page: 1 });
    };
    const onPageChange = (page: number) => {
        setFilter({ ...filter, page });
    };
    const onPageSizeChange = (pageSize: number) => {
        setFilter({ ...filter, page: 1, pageSize: isProjectsPageSize(pageSize) ? pageSize : defaultProjectsPageSize });
        trackEvent(createPageSizeChangedEvent(pageSize));
    };
    const onAdvancedFiltersChanged = () => {
        setFilter({ ...filter, page: 1 });
        refetchInitialDashboard();
        refetchDetailedDashboard();
    };
    const loadingErrors = [initialDashboardError, detailedDashboardError].filter((error) => error !== null);
    if (dashboardData === null) {
        return (<div className={cx(projectsDashboardStyles, { [projectsDashboardMobileStyles]: isMobile })}>
                {loadingErrors.map((error, index) => (<DashboardErrorPanel error={error} key={index}/>))}
                {!initialDashboardError && !detailedDashboardError && <InitialLoadingSkeleton />}
            </div>);
    }
    const { TotalResults, ItemsPerPage, ProjectGroups } = dashboardData;
    const pagination = <Pagination label="Projects" totalResults={TotalResults} itemsPerPageOptions={projectsPageSizes} selectedItemsPerPage={ItemsPerPage} currentPage={filter.page} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange}/>;
    //We shouldn't show the skeleton when loading the detailed data, so we only show this when both the initial and
    //detailed data is being reloaded. Currently, this will happen whenever the advanced filter is changed or if changing
    //filters such as the page number / search.
    const showSkeletonLoading = isLoadingDetailedDashboard && isLoadingInitialDashboard;
    return (<div className={cx(projectsDashboardStyles, { [projectsDashboardMobileStyles]: isMobile })}>
            <div className={filterAndPaginationContainerStyles}>
                <div className={cx(searchBoxAndAdvancedFiltersWrapperStyles, { [filterAndPaginationMobileStyles]: isMobile })}>
                    <ProjectsDashboardSearchBox searchValue={filter.searchValue} onSearchValueChange={onSearchValueChanged}/>
                    <ProjectsDashboardAdvancedFilters doBusyTask={doBusyTask} onAdvancedFilterChange={onAdvancedFiltersChanged}/>
                </div>
                {pagination}
            </div>
            {loadingErrors.map((error, index) => (<DashboardErrorPanel error={error} key={index}/>))}
            {showSkeletonLoading ? <ProjectGroupDeploymentsSkeleton /> : <DashboardProjectGroups projectGroups={ProjectGroups} favouriteProjectsData={favouriteProjectsData} spaceId={spaceId} onAddProjectToGroup={onAddProjectToGroup}/>}
            {!showSkeletonLoading && <div className={bottomPaginationContainerStyles}>{pagination}</div>}
        </div>);
}
function DashboardErrorPanel({ error }: {
    error: Error;
}) {
    const convertedError = getErrorsFromError(error);
    return <ErrorPanel message={convertedError.message} errors={convertedError.errors} parsedHelpLinks={convertedError.parsedHelpLinks} helpText={convertedError.helpText} helpLink={convertedError.helpLink} statusCode={convertedError.statusCode}/>;
}
interface PageSizeChangedEvent extends AnalyticsEvent<"Projects Dashboard Pagination: User Changed PageSize"> {
    pageSize: number;
}
function createPageSizeChangedEvent(pageSize: number): PageSizeChangedEvent {
    return {
        eventName: "Projects Dashboard Pagination: User Changed PageSize",
        pageSize,
    };
}
function InitialLoadingSkeleton() {
    const isMobile = useIsMobile();
    return (<>
            <div className={filterAndPaginationContainerStyles}>
                <div className={cx(searchBoxAndAdvancedFiltersWrapperStyles, { [filterAndPaginationMobileStyles]: isMobile })}>
                    <ProjectsDashboardSearchBoxSkeleton />
                    <ProjectsDashboardAdvancedFiltersSkeleton />
                </div>
                <PaginationSkeleton />
            </div>
            <ProjectGroupDeploymentsSkeleton />
        </>);
}
function PaginationSkeleton() {
    return (<div className={paginationSkeletonStyles}>
            <Skeleton shape={"Rounded"} borderRadius={"medium"}/>
        </div>);
}
const paginationSkeletonStyles = css({
    height: "100%",
    width: "17.25rem",
});
const projectsDashboardStyles = css({
    display: "flex",
    flexDirection: "column",
    gap: space[24],
    padding: `0 ${space["32"]} ${space["32"]}`,
});
const projectsDashboardMobileStyles = css({
    padding: `0 ${space["16"]} ${space["32"]}`,
});
const filterAndPaginationContainerStyles = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${space["8"]} 0`,
    "@media (max-width: 1024px)": {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: space["4"],
    },
});
const filterAndPaginationMobileStyles = css({
    flexDirection: "column",
    alignItems: "flex-start",
    gap: space["4"],
});
const searchBoxAndAdvancedFiltersWrapperStyles = css({
    display: "flex",
    alignItems: "center",
    flex: 1,
    gap: space["16"],
});
const bottomPaginationContainerStyles = css({
    alignSelf: "flex-end",
});
function useFavouriteData(doBusyTask: DoBusyTask): FavouriteProjectsData {
    const [favouriteProjects, setFavourites] = useState<UserFavouriteProjectSummary[]>([]);
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (hasPermission(Permission.ProjectView)) {
            const result = await repository.Users.getProjectFavourites();
            setFavourites(result.Projects);
        }
        else {
            setFavourites([]);
        }
    }, []);
    const onFavouriteProjectChanged = useCallback((projectId: string, isFavourite: boolean) => doBusyTask(async () => {
        if (isFavourite) {
            const { Project } = await repository.Users.createProjectFavourite(projectId);
            setFavourites((prev) => [...prev, Project]);
        }
        else {
            await repository.Users.deleteProjectFavourite(projectId);
            setFavourites((prev) => prev.filter((p) => p.Id !== projectId));
        }
    }), [doBusyTask]);
    return { favouriteProjects, onFavouriteProjectChanged };
}
function useInitialDashboardQuery(dashboardFilters: ProjectsDashboardFilter) {
    const { skip, take, projectOrGroupPartialName } = dashboardFilters;
    return useInlineStatusQuery(async (repository) => repository.Dashboards.getInitialProjectsDashboard({ skip, take, projectOrGroupPartialName }), [skip, take, projectOrGroupPartialName], "Initial Dashboard Data");
}
function useDetailedDashboardQuery({ skip, take, projectOrGroupPartialName }: ProjectsDashboardFilter) {
    return useInlineStatusQuery(async (repository) => {
        if (session.features?.IsProjectsPageOptimizationEnabled ?? false) {
            return repository.Dashboards.getProjectsPage({ skip, take, projectOrGroupPartialName });
        }
        return repository.Dashboards.getDetailedProjectsDashboard({ skip, take, projectOrGroupPartialName });
    }, [skip, take, projectOrGroupPartialName], "Detailed Dashboard Data", { refetchIntervalInMs: dashboardRefreshIntervalInMs });
}
const dashboardRefreshIntervalInMs = 6000;
function getDashboardFiltersQueryParams(filters: ProjectsFilter) {
    const skip = (filters.page - 1) * filters.pageSize;
    const take = filters.pageSize;
    const projectOrGroupPartialName = filters.searchValue?.toLowerCase() ?? "";
    return { skip, take, projectOrGroupPartialName };
}
export function isProjectsPageSize(pageSize: number | undefined): pageSize is ProjectsPageSize {
    if (pageSize === undefined)
        return false;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (projectsPageSizes as readonly number[]).includes(pageSize);
}
