import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../../components/GettingStarted/OnboardingPage";
export const OnboardingTextForCertificates = "Securely store and manage certificates in Octopus and add a layer of security for your projects and infrastructure.";
const CertificateOnboarding = () => {
    return (<OnboardingPage title="Add certificates to use in your projects" intro={<span>{OnboardingTextForCertificates}</span>} learnMore={<>
                    <ExternalLink href="CertificatesDocumentation">Learn more</ExternalLink>
                </>}/>);
};
export default CertificateOnboarding;
