import { usePageEventDispatcher } from "@octopusdeploy/portal-routes/src/analytics/PageEventDispatcher";
import React from "react";
import { Action } from "~/analytics/Analytics";
import { createActionTypeField, createResourceTypeField, createStringField } from "~/analytics/fields";
import { VersionControlSettingsType } from "../../ProjectSettings/VersionControl/VersionControlSettingsTypeSelector";
export function useNotifyGitCredentialTypeSelected() {
    const dispatcher = usePageEventDispatcher();
    return React.useCallback((selectedType: VersionControlSettingsType) => dispatcher("Git credential type selected", {
        ...createResourceTypeField("Version Control Settings"),
        ...createActionTypeField(Action.Select),
        ...createStringField("Credential Mode", VersionControlSettingsType[selectedType]),
    }), [dispatcher]);
}
