/* eslint-disable @typescript-eslint/consistent-type-assertions,@typescript-eslint/no-non-null-assertion */
import { ActionButton, Checkbox } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { FormCheckboxControl, FormTextAreaControl, FormVariableValueControl, FormParagraphControl, FormSubmitButtonGroupControl, FormElement, FormControl } from "@octopusdeploy/octopus-server-client";
import { FormControlType, ControlType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { client } from "~/clientInstance";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import Markdown from "~/components/Markdown/index";
import { required } from "~/components/form/Validators";
import Note from "~/primitiveComponents/form/Note/Note";
import Text, { DebounceText } from "~/primitiveComponents/form/Text/Text";
import StringCheckbox from "../../primitiveComponents/form/Checkbox/StringCheckbox";
import selectOptionsToItems from "../../primitiveComponents/form/Select/Options";
import Select from "../../primitiveComponents/form/Select/Select";
import styles from "./style.module.less";
interface FormElementProps<TControl extends FormControl> {
    control: TControl;
    disabled?: boolean;
    value: string;
    onChange(value: string): void;
}
const CheckboxFormElement = (props: FormElementProps<FormCheckboxControl>) => <Checkbox label={props.control.Text} disabled={props.disabled} value={props.value === "True"} onChange={(val) => props.onChange(val ? "True" : "False")}/>;
const TextAreaFormElement = (props: FormElementProps<FormTextAreaControl>) => {
    return <DebounceText label={props.control.Label} multiline={true} disabled={props.disabled} onChange={props.onChange} value={props.value}/>;
};
const VariableValueFormElement = (props: FormElementProps<FormVariableValueControl>) => {
    return (<div>
            <div>{getVariableInputControl(props)}</div>
            <Note>{props.control.Description}</Note>
        </div>);
};
const getVariableInputControl = (props: FormElementProps<FormVariableValueControl>) => {
    const controlType = props.control.DisplaySettings && props.control.DisplaySettings["Octopus.ControlType"] ? props.control.DisplaySettings["Octopus.ControlType"] : ControlType.SingleLineText; // Control type defaults to single-line text
    switch (controlType) {
        case ControlType.SingleLineText:
        case ControlType.MultiLineText:
        case ControlType.Sensitive:
            return (<Text label={props.control.Label} multiline={controlType === ControlType.MultiLineText} disabled={props.disabled} onChange={props.onChange} type={controlType === ControlType.Sensitive ? "password" : ""} validate={props.control.Required ? required(`Please enter a value for ${props.control.Label}`) : undefined} value={props.value}/>);
        case ControlType.Checkbox:
            return <StringCheckbox value={props.value} label={props.control.Label} onChange={props.onChange} disabled={props.disabled}/>;
        case ControlType.Select:
            return (<Select value={props.value} label={props.control.Label} items={selectOptionsToItems(props.control.DisplaySettings["Octopus.SelectOptions"])} onChange={(value) => props.onChange(value!)} disabled={props.disabled} allowClear={!props.control.Required} validate={props.control.Required ? required(`Please enter a value for ${props.control.Label}`) : undefined} sortItems={false}/>);
        default:
            throw new Error("Unexpected control-type:" + controlType);
    }
};
const linkResolver = (match: string, p1: string, p2: string, offset: number, all: string) => {
    const url = p2.startsWith("~/") ? client.resolve(p2) : p2;
    return `[${p1}](${url})`;
};
const ParagraphFormElement = (props: {
    control: FormParagraphControl;
}) => {
    // if ResolveLinks is set, pass the target url on any Markdown links [text](url)
    // through our resolver so we can link within Octopus
    // eslint-disable-next-line no-useless-escape
    const markDown = props.control.ResolveLinks ? props.control.Text.replace(/\[([^\]]+)\]\(([^\)]+)\)/, linkResolver) : props.control.Text;
    return <Markdown markup={markDown}/>;
};
interface SubmitButtonGroupFormElementProps {
    control: FormSubmitButtonGroupControl;
    disabled?: boolean;
    onSubmit(value: string): void;
}
const SubmitButtonGroupFormElement = (props: SubmitButtonGroupFormElementProps) => {
    return (<div className={styles.buttonContainer}>
            {props.control.Buttons.map((btn, idx) => {
            if (btn.RequiresConfirmation) {
                return (<OpenDialogButton label={btn.Text} disabled={props.disabled} key={idx}>
                            <OkDialogLayout title={`Confirm ${btn.Value}`} okButtonLabel="Yes" cancelButtonLabel="No" onOkClick={() => {
                        props.onSubmit(btn.Value);
                        return true;
                    }}>
                                <div>Are you sure you want to {btn.Value}?</div>
                            </OkDialogLayout>
                        </OpenDialogButton>);
            }
            return (<ActionButton key={idx} disabled={props.disabled} label={btn.Text} onClick={() => {
                    props.onSubmit(btn.Value);
                }}/>);
        })}
        </div>);
};
interface GenericFormElementProps {
    element: FormElement;
    value: string;
    disabled?: boolean;
    onChanged(value: string): void;
    onButtonSelected(name: string): void;
}
const FormResourceInput = (props: GenericFormElementProps) => {
    //IsValue Required
    const { disabled, element, value } = props;
    const control = element.Control;
    switch (control.Type) {
        case FormControlType.Paragraph: {
            return <ParagraphFormElement control={control as FormParagraphControl}/>;
        }
        case FormControlType.Checkbox: {
            return <CheckboxFormElement control={control as FormCheckboxControl} disabled={disabled} onChange={props.onChanged} value={value}/>;
        }
        case FormControlType.TextArea: {
            return <TextAreaFormElement control={control as FormTextAreaControl} disabled={disabled} onChange={props.onChanged} value={value}/>;
        }
        case FormControlType.VariableValue: {
            return <VariableValueFormElement control={control as FormVariableValueControl} disabled={disabled} onChange={props.onChanged} value={value}/>;
        }
        case FormControlType.SubmitButtonGroup: {
            return <SubmitButtonGroupFormElement control={control as FormSubmitButtonGroupControl} disabled={disabled} onSubmit={props.onButtonSelected}/>;
        }
        default:
            logger.warn("Unknown control type on {formElement}", { formElement: element });
            return <div>Unknown control type "{control.Type}"</div>;
    }
};
export default FormResourceInput;
