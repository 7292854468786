import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
const video = {
    youtubeId: "f_JPU7sAE8M",
    shortlink: "ManagingUsersAndTeamsVideo",
};
export const CommonConfigurationUserOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    Manage permissions by assigning <ExternalLink href="ManagingUsersAndTeams">users</ExternalLink> to teams that are accessible in one space or all spaces (system team).
                </p>
                <p>
                    Learn more about <ExternalLink href="OctopusInvites">user invites</ExternalLink>.
                </p>
                <p>
                    Octopus Cloud user? Learn more about <ExternalLink href="OctopusCloudInvites">user invites for Octopus Cloud</ExternalLink>.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
