import * as React from "react";
import { CommonInsightsReportHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsDeploymentFrequencyOverview, CommonInsightsDeploymentFrequencyTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsDeploymentFrequencyViews";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration } from "../../../Registry/pageHelpRegistry";
export const InsightsReportDeploymentFrequencyRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.insights.report.frequency,
    renderOverview: (props) => (<>
            <CommonInsightsDeploymentFrequencyOverview /> <CommonInsightsReportHelp />
        </>),
    renderTopics: (props) => <CommonInsightsDeploymentFrequencyTopics />,
};
