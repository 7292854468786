import { HostingEnvironment } from "@octopusdeploy/octopus-server-client";
import { Environment } from "@octopusdeploy/utilities";
import React from "react";
import { repository } from "~/clientInstance";
import { FormSectionHeading } from "~/components/form";
type EarlyAccessFeaturesGroupProps = {
    children: React.ReactNode;
};
export function EarlyAccessFeaturesGroup({ children }: EarlyAccessFeaturesGroupProps) {
    const shouldShowEarlyAccessSection = repository.Licenses.getCurrentStatus().then((licenseStatus) => {
        return Environment.isInDevelopmentMode() || licenseStatus.HostingEnvironment === HostingEnvironment.OctopusCloud;
    });
    if (!shouldShowEarlyAccessSection)
        return null;
    return (<React.Fragment>
            <FormSectionHeading key="ExperimentalFeaturesGroup" title={"Early Access"}/>
            {children}
        </React.Fragment>);
}
