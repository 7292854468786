import { BooleanRadioButton, BooleanRadioButtonGroup } from "@octopusdeploy/design-system-components";
import React from "react";
import { NewFeatureChip } from "~/components/Chips";
import type { SummaryNode } from "~/components/form";
import { ExpandableFormSection, Summary } from "~/components/form";
export interface ServerSideApplyProps {
    enabled: boolean;
    forceConflicts: boolean;
}
export interface ServerSideApplyFormSectionProps {
    enabled: boolean;
    onEnabledChange: (value: boolean) => void;
    forceConflicts: boolean;
    onForceConflictsChange: (value: boolean) => void;
}
export function ServerSideApplyFormSection({ enabled, onEnabledChange, forceConflicts, onForceConflictsChange }: ServerSideApplyFormSectionProps) {
    const serverSideApplyForKubernetes = (): SummaryNode => {
        if (!enabled)
            return Summary.summary(<>
                    Make Kubernetes changes using <strong>client-side</strong> apply
                </>);
        if (!forceConflicts)
            return Summary.summary(<>
                    Make Kubernetes changes using <strong>server-side</strong> apply and <strong>fail the step</strong> when conflicts occur
                </>);
        return Summary.default(<>
                Make Kubernetes changes using <strong>server-side</strong> apply and <strong>force changes</strong> when conflicts occur
            </>);
    };
    return (<ExpandableFormSection errorKey="serverSideApplyForKubernetes" title="Server-Side Apply" chip={<NewFeatureChip />} summary={serverSideApplyForKubernetes()} help="Choose the mechanism to use when calling kubectl apply">
            <BooleanRadioButtonGroup accessibleName="Server-side Apply for Kubernetes" value={enabled} onChange={onEnabledChange}>
                <BooleanRadioButton value={true} label={<>
                            Use server-side apply (<code>--server-side</code>)
                        </>} isDefault={true}/>
                <BooleanRadioButton value={false} label="Use client-side apply"/>
            </BooleanRadioButtonGroup>
            {enabled && (<>
                    <strong>Conflict handling</strong>
                    <BooleanRadioButtonGroup accessibleName="Force Conflicts" value={forceConflicts} onChange={onForceConflictsChange}>
                        <BooleanRadioButton value={true} label={<>
                                    Forcefully overwrite conflicts (<code>--force-conflicts</code>)
                                </>} isDefault={true}/>
                        <BooleanRadioButton value={false} label="Return errors for conflicts"/>
                    </BooleanRadioButtonGroup>
                </>)}
        </ExpandableFormSection>);
}
