import * as React from "react";
import SectionHeading from "~/primitiveComponents/dataDisplay/Section/SectionHeading";
interface FormSectionHeadingProps {
    title: string | JSX.Element;
    description?: string | JSX.Element;
}
class FormSectionHeading extends React.Component<FormSectionHeadingProps, {}> {
    render() {
        return <SectionHeading title={this.props.title} description={this.props.description}/>;
    }
    static displayName = "FormSectionHeading";
}
export default FormSectionHeading;
