import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonLibraryFeedOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    Using <ExternalLink href="OnboardingPackageRepositoriesLearnMore">external feeds</ExternalLink>, Octopus can consume packages from TeamCity, VSTS, Docker Registry, MyGet, Maven, locally or hosted remotely.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
