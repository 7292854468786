/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import type { AccountResource, TagSetResource, EnvironmentResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import { tenantChipListIncludingMissing, environmentChipListIncludingMissing } from "~/components/Chips";
import MarkdownDescription from "~/components/MarkdownDescription";
import Tag from "~/components/Tag";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import styles from "./AccountSummary.module.less";
interface AccountSummaryProps {
    account: AccountResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tenantTags: TagSetResource[];
    selected?: boolean;
    showSelection?: boolean;
    onClick(): void;
}
const AccountSummary: React.SFC<AccountSummaryProps> = ({ account, onClick, environments, tenants, tenantTags, showSelection, selected }) => {
    return (<div className={styles.container} onClick={onClick}>
            {showSelection && (<div className={styles.select}>
                    <BooleanRadioButtonGroup value={selected ?? false} noMargin={true} onChange={noOp}>
                        <BooleanRadioButton value={true}/>
                    </BooleanRadioButtonGroup>
                </div>)}
            <div className={styles.summary}>
                <div className={styles.titleContainer}>
                    <div className={styles.title}>
                        <ListTitle>{account.Name}</ListTitle>
                    </div>
                </div>
                {account.Description && (<div className={styles.row}>
                        <MarkdownDescription markup={account.Description}/>
                    </div>)}
                <div className={styles.row}>
                    <span>{environmentChipListIncludingMissing(environments, account.EnvironmentIds)}</span>
                    {(!!account.TenantIds.length || !!account.TenantTags.length) && (<span>
                            {tenantChipListIncludingMissing(tenants, account.TenantIds)}
                            {account.TenantTags.map((tt) => {
                const result = tenantTags.find((t) => !!t.Tags.find((tag) => tag.CanonicalTagName === tt));
                if (result) {
                    const fu = result.Tags.find((tag) => tag.CanonicalTagName === tt)!;
                    return <Tag key={fu.Id} description={fu.Description} tagName={fu.Name} tagColor={fu.Color}/>;
                }
            })}
                        </span>)}
                </div>
            </div>
        </div>);
};
AccountSummary.displayName = "AccountSummary"
export default AccountSummary;
export { AccountSummary, AccountSummaryProps };
