import type { PackagingRegistration } from "../../Registry/packagingRegistry";
import BuildServerPackagingInstruction from "./BuildServerPackagingInstruction";
import CommandLinePackagingInstruction from "./CommandLinePackagingInstruction";
import ManualPackagingInstruction from "./ManualPackagingInstruction";
export const OtherPackagingRegistration: PackagingRegistration = {
    displayOrder: 600,
    instructions: [BuildServerPackagingInstruction, ManualPackagingInstruction, CommandLinePackagingInstruction],
    name: "Other",
    id: "Other",
};
export default OtherPackagingRegistration;
