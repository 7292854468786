/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css } from "@emotion/css";
import { CircularProgress, Tooltip } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import * as React from "react";
import type { IScriptActionContext } from "~/components/Actions/script/ScriptActionContext";
import { ScriptActionContext } from "~/components/Actions/script/ScriptActionContext";
import type { IconButtonWithTooltipProps } from "~/components/IconButtonWithTooltip";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import { SimplePagingList } from "~/components/PagingList/SimplePagingList";
import type { Origin } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import { codeEditorVariablesList } from "~/utils/ScriptIntellisense/scriptIntellisense";
import { isTooltipHoverable } from "~/utils/TooltipHelper/isTooltipHoverable";
import BaseComponent from "../../BaseComponent";
import FilterSearchBox from "../../FilterSearchBox";
interface InsertVariableButtonProps {
    localNames?: string[];
    syntax?: ScriptingLanguage;
    onSelected(value: string): void;
    button?: React.FC<IconButtonWithTooltipProps>;
    anchorOrigin?: Origin;
    transformOrigin?: Origin;
    prompt?: ReactNode;
    showHoverTooltip?: boolean;
}
interface InsertVariableButtonState {
    serverNames: string[];
    filter: string;
    isInsertPopupOpen: boolean;
    isRefreshing: boolean;
}
export default class InsertVariableButton extends BaseComponent<InsertVariableButtonProps, InsertVariableButtonState> {
    private currentVariablesKey?: string;
    static contextType = ScriptActionContext;
    showInsertPopupButton: HTMLElement = undefined!;
    context: IScriptActionContext | undefined;
    private buttonComponent: React.FC<IconButtonWithTooltipProps> = IconButtonWithTooltip;
    constructor(props: InsertVariableButtonProps) {
        super(props);
        if (props.button) {
            this.buttonComponent = props.button;
        }
        this.state = {
            serverNames: [],
            isInsertPopupOpen: false,
            filter: "",
            isRefreshing: false,
        };
    }
    async componentDidMount() {
        await this.reloadVariables();
    }
    async componentDidUpdate(prevProps: InsertVariableButtonProps) {
        if (this.context?.key != this.currentVariablesKey) {
            await this.reloadVariables();
        }
    }
    async reloadVariables(clearCachedVariables?: boolean) {
        let variableNames: string[] = [];
        if (this.context) {
            this.currentVariablesKey = this.context.key;
            variableNames = await this.context.loadVariables(clearCachedVariables);
        }
        this.setState({
            serverNames: variableNames,
        });
    }
    showInsertPopup = (event: React.MouseEvent) => {
        event.preventDefault();
        this.setState({
            isInsertPopupOpen: true,
        });
    };
    hideInsertPopup = () => {
        this.setState({
            isInsertPopupOpen: false,
        });
    };
    handleSelected = (value: string) => {
        this.props.onSelected(value);
        this.hideInsertPopup();
    };
    render() {
        //TODO Insert variable icon to be added
        const results = codeEditorVariablesList(this.state.serverNames, this.props.localNames ?? [], this.props.syntax, this.state.filter);
        const ButtonComponent = this.buttonComponent;
        return (<Tooltip content={"Insert Variable"} open={isTooltipHoverable(this.props.showHoverTooltip)}>
                <div ref={this.setRef}>
                    <ButtonComponent toolTipContent="Insert a variable" onClick={this.showInsertPopup} icon="InsertVariable" accessibleName={`Insert Variable`}/>
                    <Popover open={this.state.isInsertPopupOpen} anchorEl={this.showInsertPopupButton} onClose={this.hideInsertPopup} anchorOrigin={this.props.anchorOrigin ?? { horizontal: "center", vertical: "bottom" }} transformOrigin={this.props.transformOrigin ?? { horizontal: "center", vertical: "top" }}>
                        <div className={containerStyles} onKeyDown={this.onKeyDown}>
                            <div className={filterStyles.container}>
                                <FilterSearchBox autoFocus={true} value={this.state.filter} fullWidth={true} placeholder="Find..." onChange={(filter) => this.setState({ filter })}/>
                                <div className={filterStyles.buttons}>
                                    {this.state.isRefreshing ? (<CircularProgress size="small"/>) : (<IconButtonWithTooltip onClick={async () => {
                    this.setState({ isRefreshing: true });
                    const clearCachedVariables = true;
                    await this.reloadVariables(clearCachedVariables);
                    this.setState({ isRefreshing: false });
                }} toolTipContent="Refresh" icon="Refresh"/>)}
                                </div>
                            </div>
                            {this.state.isInsertPopupOpen && (<div className={menuContainerStyles}>
                                    <SimplePagingList hideItemPadding items={results} onRow={({ display, code }) => (<div className={listItemStyles} onClick={() => this.handleSelected(code)}>
                                                {display}
                                            </div>)} onRowKey={(item) => item.display}/>
                                </div>)}
                            {this.props.prompt && <div className={promptStyles}>{this.props.prompt}</div>}
                        </div>
                    </Popover>
                </div>
            </Tooltip>);
    }
    private onKeyDown = (event: React.KeyboardEvent) => {
        if (event.keyCode === 27 /*esc*/) {
            this.setState({ isInsertPopupOpen: false });
        }
        if (event.keyCode === 13 /*enter*/) {
            event.preventDefault();
        }
    };
    private setRef = (el: HTMLDivElement) => {
        this.showInsertPopupButton = el;
    };
    static displayName = "InsertVariableButton";
}
const space500 = `${500 / 16}rem`;
const containerStyles = css({
    display: "flex",
    flexDirection: "column",
    width: space500,
});
const filterStyles = {
    container: css({
        display: "flex",
        alignItems: "center",
        marginLeft: space[16],
        marginRight: space[16],
    }),
    buttons: css({
        marginLeft: space[8],
        marginBottom: space[8],
    }),
};
const space400 = `${400 / 16}rem`;
const menuContainerStyles = css({
    overflowY: "auto",
    height: space400,
});
const listItemStyles = css({
    cursor: "pointer",
    padding: space[16],
});
const promptStyles = css({
    marginLeft: space[16],
    marginRight: space[16],
    paddingTop: space[16],
    paddingBottom: space[16],
    borderTop: `1px solid ${themeTokens.color.border.secondary}`,
});
