/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment from "moment";
import * as React from "react";
import type { SFC } from "react";
import DateFormatter from "~/utils/DateFormatter";
import styles from "./style.module.less";
interface TimeFromNowLabelProps {
    time: string;
    useShortDateFormat?: boolean;
}
const TimeFromNowLabel: SFC<TimeFromNowLabelProps> = (props) => {
    return (<span className={styles.when}>
            {props.useShortDateFormat ? <abbr title={DateFormatter.momentAgo(props.time)}>{DateFormatter.dateToShortFormat(props.time)}</abbr> : <abbr title={DateFormatter.dateToLongFormat(props.time)!}>{moment(props.time).fromNow()}</abbr>}
        </span>);
};
export default TimeFromNowLabel;
