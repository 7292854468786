import type * as H from "history";
import type { FromLocationState } from "./types";
export const locationStateIncludesFrom = (location: H.Location): location is H.Location<FromLocationState> => {
    if (!location || !location?.state) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = location as H.Location<Partial<FromLocationState>>;
    if (!casted.state.from) {
        return false;
    }
    return typeof casted.state.from.pathname === "string";
};
