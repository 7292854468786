/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ControlType } from "@octopusdeploy/octopus-server-client";
import type { ActionTemplateParameterResource } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import Markdown from "~/components/Markdown/index";
import FormSection from "../form/Sections/FormSection";
import type { ActionTemplateParameterInputProps } from "./ActionTemplateParameterInput";
import ActionTemplateParameterInput from "./ActionTemplateParameterInput";
type HelpTextMarkupProvider = (label: string) => string;
// The purpose of this class is to provide consistent labels/help text etc for components that are external to the actual input
export default class ActionTemplateParameterInputFormElement extends React.Component<ActionTemplateParameterInputProps, never> {
    shouldComponentUpdate(newProps: ActionTemplateParameterInputProps) {
        return hasParameterChanged(newProps, this.props);
    }
    render() {
        const parameter = this.props.parameter;
        const label = getLabel(parameter);
        return (<FormSection title={label} help={getHelpText(parameter)}>
                <ActionTemplateParameterInput {...this.props}/>
            </FormSection>);
    }
    static displayName = "ActionTemplateParameterInputFormElement";
}
function hasParameterChanged(newParam: ActionTemplateParameterInputProps, oldParam: ActionTemplateParameterInputProps): boolean {
    return !_.isEqual(newParam.value, oldParam.value) || !_.isEqual(newParam.error, oldParam.error) || !_.isEqual(newParam.warning, oldParam.warning) || !_.isEqual(newParam.sourceItems, oldParam.sourceItems);
}
function getLabel(parameter: ActionTemplateParameterResource) {
    return parameter.Label || parameter.Name;
}
function getHelpText(parameter: ActionTemplateParameterResource, customEmptyHelpTextMarkup: HelpTextMarkupProvider = null!, packageParameterIsUsed?: boolean) {
    const label = parameter.Label || parameter.Name;
    const helpText = parameter.HelpText
        ? parameter.HelpText
        : customEmptyHelpTextMarkup
            ? customEmptyHelpTextMarkup(label)
            : parameter.DisplaySettings["Octopus.ControlType"] !== ControlType.Package || packageParameterIsUsed
                ? `Provide a value for ${label}`
                : "";
    return <Markdown markup={helpText}/>;
}
export { hasParameterChanged, getLabel, getHelpText };
