import * as React from "react";
import { CommonProjectRunbookOverview } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonProjectRunbookViews";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
export const ProjectRunbooksRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
