import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonInfrastructureProxyOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="ProxySupport">Machine proxies</ExternalLink> allow you to specify a proxy server for Octopus to use when communicating with a Tentacle or SSH Target, you can also specify a proxy server when a Tentacle and the
                    Octopus Server make web requests to other servers.
                </p>
                <p>
                    To configure a proxy for the Octopus Server, modify the
                    <InternalLink to={links.webRequestProxySettingsPage.generateUrl()}> web request proxy</InternalLink>. Learn more about
                    <ExternalLink href="ProxySupport"> proxies</ExternalLink>.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
