import { css } from "@emotion/css";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { fontSize, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { DropzoneArea } from "material-ui-dropzone";
import * as React from "react";
import { useErrorActions } from "~/components/ErrorContext/ErrorContext";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { CloudUpIcon } from "~/primitiveComponents/form/FileUploadDragDrop/CloudUpIcon";
interface FileUploadDropzoneProps {
    onFilesChanged: (newValue: File[]) => void;
    label: string;
    initialFiles?: File[];
    filesLimit?: number;
    showAlerts?: boolean;
    showFileChips?: boolean;
}
const FileUploadDropzone: React.FC<FileUploadDropzoneProps> = (props) => {
    const MAX_SUPPORTED_FILE_SIZE = 1000000000000; // 1TB in bytes
    const { clearErrors } = useErrorActions();
    const handleChange = (files: File[]) => {
        clearErrors();
        props.onFilesChanged(files);
    };
    const handleDelete = (deletedFile: File) => {
        clearErrors();
        props.onFilesChanged([deletedFile]);
    };
    const useStyles = makeStyles(() => createStyles({
        previewChip: (props: {
            octopusTheme: OctopusTheme;
        }) => ({
            maxWidth: 500,
            height: 24.5,
            width: "auto",
            backgroundColor: props.octopusTheme.chipBackground,
            border: "none",
            overflow: "visible",
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
        }),
        deleteIcon: (props: {
            octopusTheme: OctopusTheme;
        }) => ({
            width: 24,
            height: 24,
            color: props.octopusTheme.iconNeutral,
            "&:hover": {
                color: props.octopusTheme.iconNeutral,
            },
            // Workaround for Material UI CSS styling specificity issue: https://stackoverflow.com/a/69799758
            "&.MuiChip-deleteIcon": {
                marginLeft: 0,
                marginRight: 0,
            },
        }),
        label: () => ({
            paddingLeft: 12,
            paddingRight: 12,
            fontSize: fontSize.xSmall,
            overflow: "visible",
            textAlign: "left",
        }),
    }));
    const useMuiStyles = makeStyles(() => ({
        root: (props: {
            octopusTheme: OctopusTheme;
        }) => ({ backgroundColor: props.octopusTheme.primaryBackground }),
        active: (props: {
            octopusTheme: OctopusTheme;
        }) => ({ backgroundImage: "none", backgroundColor: props.octopusTheme.secondaryBackground, borderColor: props.octopusTheme.infoBorder }),
        invalid: (props: {
            octopusTheme: OctopusTheme;
        }) => ({ backgroundImage: "none", backgroundColor: props.octopusTheme.dangerTaskLogBackground, borderColor: props.octopusTheme.dangerBorder }),
    }));
    const octopusTheme = useOctopusTheme();
    const previewChipStyles = useStyles({ octopusTheme });
    const classes = useMuiStyles({ octopusTheme });
    const filesLimit = props.filesLimit ?? 1;
    const previewText = filesLimit > 1 ? "Selected files" : "Selected file";
    const getDropRejectMessage = (rejectedFile: File, acceptedFiles: string[], maxFileSize: number) => {
        let message = `File ${rejectedFile.name} was rejected. `;
        if (!acceptedFiles.includes(rejectedFile.type)) {
            message += "File type not supported. ";
        }
        const maxFileSizeInGb = maxFileSize / 1000000000 + " GB";
        if (rejectedFile.size > maxFileSize) {
            message += "File is too big. Size limit is " + maxFileSizeInGb + ". To upload a larger file we recommend using Octo.exe";
        }
        return message;
    };
    return (<div role="input" className={dropzoneAreaStyles}>
            <DropzoneArea dropzoneText={props.label} showPreviews={props.showFileChips === undefined ? true : props.showFileChips} showAlerts={!props.showAlerts === undefined ? true : props.showAlerts} showPreviewsInDropzone={false} useChipsForPreview previewGridProps={{ container: { spacing: 1, direction: "row" } }} previewChipProps={{ classes: { root: previewChipStyles.previewChip, deleteIcon: previewChipStyles.deleteIcon, label: previewChipStyles.label } }} classes={classes} previewText={previewText} onChange={handleChange} onDelete={handleDelete} filesLimit={filesLimit} initialFiles={props.initialFiles} getDropRejectMessage={getDropRejectMessage} maxFileSize={MAX_SUPPORTED_FILE_SIZE} 
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TS2322: Type '() => JSX.Element' is not assignable to type 'ReactElement<any, string | JSXElementConstructor<any>>'.
    // There is a bug in the `material-ui-dropzone` types for `Icon`: https://github.com/Yuvaleros/material-ui-dropzone/issues/267
    Icon={CloudUpIcon}/>
        </div>);
};
FileUploadDropzone.displayName = "FileUploadDropzone"
export default FileUploadDropzone;
const dropzoneAreaStyles = css({
    "> div:first-child": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "10rem",
        marginBottom: space["16"],
        p: {
            fontSize: fontSize.medium,
            color: themeTokens.color.text.secondary,
            maxWidth: "370px",
            marginTop: space[8],
            marginBottom: 0,
        },
        div: {
            marginTop: space[24],
            marginBottom: space[24],
            // Used to reserve the order of the dropzone text and icon in <DropzoneArea />
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "center",
            justifyContent: "center",
        },
    },
});
