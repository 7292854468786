import { Callout } from "@octopusdeploy/design-system-components";
import type { TaskResource } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import styles from "./style.module.less";
type CreateTestAccountTaskPropsCallback = (accountId: string) => Promise<TaskResource<{}>>;
interface TestAccountTaskProps {
    createTask: CreateTestAccountTaskPropsCallback;
}
interface SaveAndTestAccountProps {
    accountId: string;
    onOkClick: (success: boolean) => void;
}
interface SaveAndTestAccountState extends DataBaseComponentState {
    accountTestTask?: TaskResource<{}>;
    testTask?: TaskResource<{}>;
    testTaskFinished?: boolean;
}
export default class SaveAndTestAccountDialog extends DataBaseComponent<SaveAndTestAccountProps & TestAccountTaskProps, SaveAndTestAccountState> {
    constructor(props: SaveAndTestAccountProps & TestAccountTaskProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const task = await this.props.createTask(this.props.accountId);
            this.setState({ accountTestTask: task });
            await this.startRefreshLoop(() => this.refreshAccountTest(task.Id), 1500);
        });
    }
    async refreshAccountTest(id: string): Promise<{}> {
        if (this.state.testTaskFinished) {
            // this will keep running because how beginRefreshLoop is, but will be cleaned up on leaving the page
            // unless there's a way to stop `beginRefreshLoop`
            return {};
        }
        await this.doBusyTask(async () => {
            const testTask = await repository.Tasks.get(id);
            const testTaskFinished = testTask.State === TaskState.Success || testTask.State === TaskState.Failed || testTask.State === TaskState.TimedOut;
            this.setState({
                testTask,
                testTaskFinished,
            });
            return { testTask };
        });
        return {};
    }
    render() {
        return (<OkDialogLayout title={"Verifying account"} hideCancel={true} busy={!this.state.testTaskFinished || this.state.busy} errors={this.errors} onOkClick={() => this.props.onOkClick(!!this.state.testTask && this.state.testTask.State === TaskState.Success)}>
                {this.state.testTask && this.state.testTask.ErrorMessage && (<Callout title="Failed" type={"danger"}>
                        <div className={styles.failedAccountVerification}>{this.state.testTask.ErrorMessage}</div>
                    </Callout>)}

                {this.state.testTask && this.state.testTask.State === TaskState.Success && (<Callout title="Success" type={"success"}>
                        <div className={styles.testingSuccess}>
                            <span>The account was verified successfully.</span>
                        </div>
                    </Callout>)}
            </OkDialogLayout>);
    }
    static displayName = "SaveAndTestAccountDialog";
}
const SaveAndTestAzureAccountDialog: React.SFC<SaveAndTestAccountProps> = ({ accountId, onOkClick }) => {
    return <SaveAndTestAccountDialog accountId={accountId} onOkClick={onOkClick} createTask={(accId) => repository.Tasks.createTestAzureAccountTask(accId)}/>;
};
SaveAndTestAzureAccountDialog.displayName = "SaveAndTestAzureAccountDialog"
const SaveAndTestAmazonWebServicesAccountDialog: React.SFC<SaveAndTestAccountProps> = ({ accountId, onOkClick }) => {
    return <SaveAndTestAccountDialog accountId={accountId} onOkClick={onOkClick} createTask={(accId) => repository.Tasks.createTestAwsAccountTask(accId)}/>;
};
SaveAndTestAmazonWebServicesAccountDialog.displayName = "SaveAndTestAmazonWebServicesAccountDialog"
export { SaveAndTestAzureAccountDialog, SaveAndTestAmazonWebServicesAccountDialog, SaveAndTestAccountProps };
