/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { logger } from "@octopusdeploy/logging";
import type { ProcessType, Form, FormVariableValueControl } from "@octopusdeploy/octopus-server-client";
import { ProcessTypeAliasMap } from "@octopusdeploy/octopus-server-client";
import { sortBy } from "lodash";
import * as React from "react";
import FormResourceInput from "../../../../../components/FormResourceInput/FormResourceInput";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import Summary from "../../../../../components/form/Sections/Summary";
interface PromptVariablesProps {
    form: Form;
    onParameterChanged(info: any): any;
    processType: ProcessType;
}
const PromptVariables: React.StatelessComponent<PromptVariablesProps> = (props) => {
    return (<div>
            {props.form && props.form.Elements.length > 0 && (<ExpandableFormSection title="Parameters" errorKey="parameters" isExpandedByDefault={true} help={`Parameters will apply to all ${ProcessTypeAliasMap[props.processType].alias.plural}`} summary={buildSummary()}>
                    <div>
                        {sortBy(props.form.Elements, [(x) => (x.Control as FormVariableValueControl).Label || x.Name], "asc").map((element, idx) => (<FormResourceInput key={idx} element={element} onButtonSelected={() => logger.error("Buttons not expected here")} onChanged={(value) => props.onParameterChanged({ VariableName: element.Name, Value: value })} value={props.form.Values[element.Name]}/>))}
                    </div>
                </ExpandableFormSection>)}
        </div>);
    function buildSummary() {
        const numOfParams = props.form.Elements ? props.form.Elements.length : 0;
        const count = props.form.Elements.filter((e) => props.form.Values[e.Name]).length;
        return count > 0
            ? Summary.summary(<div>
                      {numOfParams} {numOfParams > 1 ? "parameters" : "parameter"} in total, {count} provided.
                  </div>)
            : Summary.placeholder("Enter the parameter values");
    }
};
PromptVariables.displayName = "PromptVariables"
export default PromptVariables;
