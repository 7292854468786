/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Checkbox, RadioButton, RadioButtonGroup, Callout } from "@octopusdeploy/design-system-components";
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { AzureEnvironment, AzureOidcAccountResource, AzureServicePrincipalAccountResource, AzureSubscriptionAccountResource, SensitiveValue, ReferenceDataItem } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import { cloneDeep } from "lodash";
import React from "react";
import { client } from "~/clientInstance";
import { subjectKeyChipList } from "~/components/Chips/index";
import Dialog from "~/components/Dialog/Dialog";
import { AvailableAccountTestSubjectKeys, AvailableDeploymentSubjectKeys, AvailableExecutionSubjectKeys, AvailableHealthCheckSubjectKeys, AvailableRunbookSubjectKeys, DefaultAccountTestSubjectKeys, DefaultDeploymentSubjectKeys, DefaultHealthCheckSubjectKeys, OpenIdSubjectMultiSelect, SubjectKeyOrder, } from "~/components/MultiSelect/OpenIdSubjectMultiSelect";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { ErrorPanel, ExpandableFormSection, FormSectionHeading, Note, Select, Summary } from "~/components/form";
import type { Item } from "~/primitiveComponents/form/Select/Select";
import Sensitive, { ObfuscatedPlaceholder } from "../../../../components/form/Sensitive/Sensitive";
import SensitiveFileUpload from "../../../../components/form/SensitiveFileUpload/SensitiveFileUpload";
import Text from "../../../../primitiveComponents/form/Text/Text";
import type { AccountEditBaseProps, AccountEditModel } from "./AccountEditBase";
import AccountEditBaseDrawer from "./AccountEditBaseDrawer";
import AccountEditBasePaper from "./AccountEditBasePaper";
import { SaveAndTestAzureAccountDialog } from "./SaveAndTestAccountDialog";
import styles from "./style.module.less";
interface ServicePrincipalAuth {
    clientId: string;
    tenantId: string;
    password: SensitiveValue;
    resourceManagementBaseUri: string;
    activeDirectoryBaseUri: string;
}
interface ManagementCertAuth {
    certificateBytes: SensitiveValue; // optional cannot send null to API
    certificateThumbprint: string;
    serviceManagementBaseUri: string;
    serviceManagementEndpointSuffix: string;
}
interface AzureOidcAuth {
    clientId: string;
    tenantId: string;
    resourceManagementBaseUri: string;
    activeDirectoryBaseUri: string;
    deploymentSubjectKeys: string[];
    healthSubjectKeys: string[];
    accountTestSubjectKeys: string[];
    audience: string;
}
interface AzureModel extends AccountEditModel {
    subscriptionNumber: string;
    azureEnvironment: string;
    usingAzureEnvironment: boolean;
    accountType: AzureAccountTypes;
    authentication: ServicePrincipalAuth | ManagementCertAuth | AzureOidcAuth;
}
const defaultServicePrincipal: ServicePrincipalAuth = {
    password: {
        HasValue: false,
    },
    clientId: "",
    tenantId: "",
    activeDirectoryBaseUri: "",
    resourceManagementBaseUri: "",
};
const defaultAzureOidc: AzureOidcAuth = {
    clientId: "",
    tenantId: "",
    activeDirectoryBaseUri: "",
    resourceManagementBaseUri: "",
    deploymentSubjectKeys: [],
    healthSubjectKeys: [],
    accountTestSubjectKeys: [],
    audience: "api://AzureADTokenExchange",
};
const defaultManagementCertificate: ManagementCertAuth = {
    certificateBytes: {
        HasValue: false,
    },
    certificateThumbprint: "",
    serviceManagementBaseUri: "",
    serviceManagementEndpointSuffix: "",
};
type AzureAccountTypes = AccountType.AzureSubscription | AccountType.AzureServicePrincipal | AccountType.AzureOidc;
type AzureAccountResources = AzureServicePrincipalAccountResource | AzureSubscriptionAccountResource | AzureOidcAccountResource;
export default function AzureAccountEdit(props: AccountEditBaseProps<AzureAccountResources>) {
    switch (props.type) {
        case "paper":
            return <AzureAccountEditPaper {...props}/>;
        case "drawer":
            return <AzureAccountEditDrawer {...props}/>;
        default:
            return <ErrorPanel message="Invalid layout"/>;
    }
}
interface ExpandableFormSectionChanges {
    subscriptionId: (value: string) => void;
    accountType: (value: string) => void;
    azureEnvironmentCheckbox: (value: boolean) => void;
    azureEnvironmentFields: (value: string | undefined) => void;
}
interface ServicePrincipalAndOidcExpandableFormSectionChanges {
    tenantId: (value: string) => void;
    clientId: (value: string) => void;
    deploymentSubjectKeys: (value: string[]) => void;
    healthSubjectKeys: (value: string[]) => void;
    accountTestSubjectKeys: (value: string[]) => void;
    audience: (value: string) => void;
    password: (value: SensitiveValue) => void;
}
interface ManagementCertificateExpandableEnvironmentFormSectionsOnChange {
    serviceManagementBaseUri: (value: string) => void;
    serviceManagementEndpointSuffix: (value: string) => void;
}
interface AAdAndResourceManagerExpandableEnvironmentFormSectionsOnChange {
    activeDirectoryBaseUri: (value: string) => void;
    resourceManagementBaseUri: (value: string) => void;
}
interface ManagementCertificateExpandableFormSectionsOnChange {
    certificateBytes: (value: SensitiveValue) => void;
}
type FormOnChange = ExpandableFormSectionChanges & ServicePrincipalAndOidcExpandableFormSectionChanges & ManagementCertificateExpandableEnvironmentFormSectionsOnChange & AAdAndResourceManagerExpandableEnvironmentFormSectionsOnChange & ManagementCertificateExpandableFormSectionsOnChange;
class AzureAccountEditPaper extends AccountEditBasePaper<AzureAccountResources, AzureModel> {
    getPartialModel(account?: AzureAccountResources) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return getPageActions(() => this.handleSaveClick(true));
    }
    getTestDialog() {
        return getTestDialog(this.state.accountData?.account?.Id, this.state.showTestDialog, (success: boolean) => this.onTestDone(success));
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, this.state.accountData === undefined, this.props.azureEnvironments, this.state.accountData?.account.Links.PublicKey, new AzureAccountChangeHandlers(this.props.azureEnvironments, this.state.model, (state, callback) => this.setModelState(state, callback)).getOnChangeHandlers(), this.getFieldError);
    }
}
class AzureAccountEditDrawer extends AccountEditBaseDrawer<AzureAccountResources, AzureModel> {
    getPartialModel(account?: AzureAccountResources) {
        return getPartialModel(account);
    }
    getPartialResource() {
        return getPartialResource(this.state.model);
    }
    getPageActions() {
        return getPageActions(() => this.handleSaveClick(true));
    }
    getTestDialog() {
        return getTestDialog(this.state.accountData?.account?.Id, this.state.showTestDialog, (success: boolean) => this.onTestDone(success));
    }
    customExpandableFormSections() {
        return customExpandableFormSections(this.state.model, this.state.accountData === undefined, this.props.azureEnvironments, this.state.accountData?.account.Links.PublicKey, new AzureAccountChangeHandlers(this.props.azureEnvironments, this.state.model, (state, callback) => this.setModelState(state, callback)).getOnChangeHandlers(), this.getFieldError);
    }
}
class AzureAccountChangeHandlers {
    constructor(private readonly azureEnvironments: AzureEnvironment[], private readonly modelState: AzureModel, private readonly setModelState: (state: Pick<AzureModel, keyof AzureModel>, callback?: () => void) => void) { }
    setManagementCertAuth<K extends keyof ManagementCertAuth>(state: Pick<ManagementCertAuth, K>) {
        this.setModelState({
            ...this.modelState,
            authentication: {
                ...this.modelState.authentication,
                ...state,
            },
        });
    }
    setServicePrincipalAuth<K extends keyof ServicePrincipalAuth>(state: Pick<ServicePrincipalAuth, K>) {
        this.setModelState({
            ...this.modelState,
            authentication: {
                ...this.modelState.authentication,
                ...state,
            },
        });
    }
    setOidcAuth<K extends keyof AzureOidcAuth>(state: Pick<AzureOidcAuth, K>) {
        this.setModelState({
            ...this.modelState,
            authentication: {
                ...this.modelState.authentication,
                ...state,
            },
        });
    }
    setAzureEnvironmentCheckbox(v: boolean) {
        const updatedModelState = { ...this.modelState, usingAzureEnvironment: v };
        if (v === false) {
            updatedModelState.azureEnvironment = "";
            updatedModelState.authentication = {
                ...updatedModelState.authentication,
                serviceManagementEndpointSuffix: "",
                serviceManagementBaseUri: "",
                activeDirectoryBaseUri: "",
                resourceManagementBaseUri: "",
            };
        }
        this.setModelState(updatedModelState);
    }
    clearAzureEnvironmentFields() {
        const updatedModelState = { ...this.modelState, azureEnvironment: "" };
        if (isManagementCertificateAccount(updatedModelState.authentication)) {
            updatedModelState.authentication = {
                ...updatedModelState.authentication,
                serviceManagementEndpointSuffix: "",
                serviceManagementBaseUri: "",
            };
        }
        if (isServicePrincipalAccount(updatedModelState.authentication) || isOidcAccount(updatedModelState.authentication)) {
            updatedModelState.authentication = {
                ...updatedModelState.authentication,
                activeDirectoryBaseUri: "",
                resourceManagementBaseUri: "",
            };
        }
        this.setModelState(updatedModelState);
    }
    setAzureEnvironmentFields(environmentName: string | undefined) {
        if (!environmentName) {
            this.clearAzureEnvironmentFields();
        }
        else {
            const environment = this.azureEnvironments.find((x) => x.Name === environmentName);
            const updatedModelState = { ...this.modelState, azureEnvironment: environmentName };
            if (!environment) {
                return;
            }
            if (isServicePrincipalAccount(this.modelState.authentication) || isOidcAccount(this.modelState.authentication)) {
                updatedModelState.authentication = {
                    ...updatedModelState.authentication,
                    activeDirectoryBaseUri: environment.AuthenticationEndpoint,
                    resourceManagementBaseUri: environment.ResourceManagerEndpoint,
                };
            }
            if (isManagementCertificateAccount(this.modelState.authentication)) {
                updatedModelState.authentication = {
                    ...updatedModelState.authentication,
                    serviceManagementEndpointSuffix: environment.StorageEndpointSuffix,
                    serviceManagementBaseUri: environment.ManagementEndpoint,
                };
            }
            this.setModelState({ ...updatedModelState });
        }
    }
    getOnChangeHandlers(): FormOnChange {
        return {
            subscriptionId: (subscriptionNumber) => this.setModelState({ ...this.modelState, subscriptionNumber }),
            accountType: (x) => {
                const model = x === AccountType.AzureServicePrincipal ? cloneDeep(defaultServicePrincipal) : x === AccountType.AzureOidc ? cloneDeep(defaultAzureOidc) : cloneDeep(defaultManagementCertificate);
                this.setModelState({
                    ...this.modelState,
                    accountType: x as AzureAccountTypes,
                    authentication: {
                        ...model,
                    },
                });
            },
            azureEnvironmentCheckbox: (v) => this.setAzureEnvironmentCheckbox(v),
            azureEnvironmentFields: (v) => this.setAzureEnvironmentFields(v),
            tenantId: (value) => (isServicePrincipalAccount(this.modelState.authentication) ? this.setServicePrincipalAuth({ tenantId: value }) : this.setOidcAuth({ tenantId: value })),
            clientId: (value) => (isServicePrincipalAccount(this.modelState.authentication) ? this.setServicePrincipalAuth({ clientId: value }) : this.setOidcAuth({ clientId: value })),
            deploymentSubjectKeys: (v: string[]) => this.setOidcAuth({ deploymentSubjectKeys: v }),
            healthSubjectKeys: (v: string[]) => this.setOidcAuth({ healthSubjectKeys: v }),
            accountTestSubjectKeys: (v: string[]) => this.setOidcAuth({ accountTestSubjectKeys: v }),
            audience: (value) => this.setOidcAuth({ audience: value }),
            password: (value) => this.setServicePrincipalAuth({ password: value }),
            certificateBytes: (value) => this.setManagementCertAuth({ certificateBytes: value }),
            activeDirectoryBaseUri: (value) => this.setServicePrincipalAuth({ activeDirectoryBaseUri: value }),
            serviceManagementBaseUri: (value) => this.setManagementCertAuth({ serviceManagementBaseUri: value }),
            resourceManagementBaseUri: (value) => this.setServicePrincipalAuth({ resourceManagementBaseUri: value }),
            serviceManagementEndpointSuffix: (value) => this.setManagementCertAuth({ serviceManagementEndpointSuffix: value }),
        };
    }
}
function getPartialModel(account?: AzureAccountResources): Partial<AzureModel> | undefined {
    if (!account) {
        return {
            accountType: AccountType.AzureServicePrincipal,
            authentication: cloneDeep(defaultServicePrincipal),
            azureEnvironment: "",
        };
    }
    const model = {
        accountType: account.AccountType as AzureAccountTypes,
        subscriptionNumber: account.SubscriptionNumber,
        azureEnvironment: account.AzureEnvironment,
    };
    if (account.AccountType === AccountType.AzureServicePrincipal) {
        const spAccount = account as AzureServicePrincipalAccountResource;
        const usingAzureEnvironment = !!account.AzureEnvironment || !!spAccount.ActiveDirectoryEndpointBaseUri || !!spAccount.ResourceManagementEndpointBaseUri;
        return {
            ...model,
            usingAzureEnvironment,
            authentication: {
                password: spAccount.Password,
                tenantId: spAccount.TenantId,
                clientId: spAccount.ClientId,
                activeDirectoryBaseUri: spAccount.ActiveDirectoryEndpointBaseUri,
                resourceManagementBaseUri: spAccount.ResourceManagementEndpointBaseUri,
            },
        };
    }
    else if (account.AccountType === AccountType.AzureOidc) {
        const oidcAccount = account as AzureOidcAccountResource;
        const usingAzureEnvironment = !!account.AzureEnvironment || !!oidcAccount.ActiveDirectoryEndpointBaseUri || !!oidcAccount.ResourceManagementEndpointBaseUri;
        return {
            ...model,
            usingAzureEnvironment,
            authentication: {
                tenantId: oidcAccount.TenantId,
                clientId: oidcAccount.ClientId,
                activeDirectoryBaseUri: oidcAccount.ActiveDirectoryEndpointBaseUri,
                resourceManagementBaseUri: oidcAccount.ResourceManagementEndpointBaseUri,
                deploymentSubjectKeys: oidcAccount.DeploymentSubjectKeys,
                healthSubjectKeys: oidcAccount.HealthCheckSubjectKeys,
                accountTestSubjectKeys: oidcAccount.AccountTestSubjectKeys,
                audience: oidcAccount.Audience,
            },
        };
    }
    else if (account.AccountType === AccountType.AzureSubscription) {
        const mgtCertAccount = account as AzureSubscriptionAccountResource;
        const usingAzureEnvironment = !!account.AzureEnvironment || !!mgtCertAccount.ServiceManagementEndpointBaseUri || !!mgtCertAccount.ServiceManagementEndpointSuffix;
        return {
            ...model,
            usingAzureEnvironment,
            authentication: {
                certificateBytes: mgtCertAccount.CertificateBytes,
                certificateThumbprint: mgtCertAccount.CertificateThumbprint,
                serviceManagementBaseUri: mgtCertAccount.ServiceManagementEndpointBaseUri,
                serviceManagementEndpointSuffix: mgtCertAccount.ServiceManagementEndpointSuffix,
            },
        };
    }
}
function isManagementCertificateAccount(variable: object): variable is ManagementCertAuth {
    return (variable as ManagementCertAuth).certificateThumbprint !== undefined;
}
function isServicePrincipalAccount(variable: object): variable is ServicePrincipalAuth {
    return (variable as ServicePrincipalAuth).password !== undefined;
}
function isOidcAccount(variable: object): variable is AzureOidcAuth {
    return (variable as AzureOidcAuth).clientId !== undefined && "password" in variable === false;
}
function getPartialResource(modelState: AzureModel): (Partial<AzureAccountResources> & {
    AccountType: AccountType;
}) | undefined {
    const resource = {
        AccountType: modelState.accountType,
        AzureEnvironment: modelState.azureEnvironment,
        SubscriptionNumber: modelState.subscriptionNumber,
    };
    if (isManagementCertificateAccount(modelState.authentication)) {
        return {
            ...resource,
            CertificateBytes: modelState.authentication.certificateBytes,
            ServiceManagementEndpointBaseUri: modelState.authentication.serviceManagementBaseUri,
            ServiceManagementEndpointSuffix: modelState.authentication.serviceManagementEndpointSuffix,
        };
    }
    else if (isServicePrincipalAccount(modelState.authentication)) {
        return {
            ...resource,
            Password: modelState.authentication.password,
            TenantId: modelState.authentication.tenantId,
            ClientId: modelState.authentication.clientId,
            ActiveDirectoryEndpointBaseUri: modelState.authentication.activeDirectoryBaseUri,
            ResourceManagementEndpointBaseUri: modelState.authentication.resourceManagementBaseUri,
        };
    }
    else {
        return {
            ...resource,
            TenantId: modelState.authentication.tenantId,
            ClientId: modelState.authentication.clientId,
            Audience: modelState.authentication.audience,
            ActiveDirectoryEndpointBaseUri: modelState.authentication.activeDirectoryBaseUri,
            ResourceManagementEndpointBaseUri: modelState.authentication.resourceManagementBaseUri,
            DeploymentSubjectKeys: modelState.authentication.deploymentSubjectKeys,
            HealthCheckSubjectKeys: modelState.authentication.healthSubjectKeys,
            AccountTestSubjectKeys: modelState.authentication.accountTestSubjectKeys,
        };
    }
}
function subscriptionSummary(modelState: AzureModel) {
    return modelState.subscriptionNumber ? Summary.summary(modelState.subscriptionNumber) : Summary.placeholder("No subscription provided");
}
function clientIdSummary(model: ServicePrincipalAuth | AzureOidcAuth) {
    return model.clientId ? Summary.summary(model.clientId) : Summary.placeholder("No client\\application ID provided");
}
function tenantIdSummary(model: ServicePrincipalAuth | AzureOidcAuth) {
    return model.tenantId ? Summary.summary(model.tenantId) : Summary.placeholder("No tenant ID provided");
}
function audienceSummary(model: AzureOidcAuth) {
    return model.audience ? Summary.summary(model.audience) : Summary.placeholder("Federated Identity audience");
}
function passwordSummary(model: ServicePrincipalAuth) {
    return model.password && model.password.HasValue ? Summary.summary(ObfuscatedPlaceholder) : Summary.placeholder("No password provided");
}
function resourceManagementUriSummary(model: ServicePrincipalAuth | AzureOidcAuth) {
    return model.resourceManagementBaseUri ? Summary.summary(model.resourceManagementBaseUri) : Summary.placeholder("The default endpoint is not being overridden");
}
function activeDirectoryUriSummary(model: ServicePrincipalAuth | AzureOidcAuth) {
    return model.activeDirectoryBaseUri ? Summary.summary(model.activeDirectoryBaseUri) : Summary.placeholder("The default endpoint is not being overridden");
}
function serviceManagementUriSummary(model: ManagementCertAuth) {
    return model.serviceManagementBaseUri ? Summary.summary(model.serviceManagementBaseUri) : Summary.placeholder("The default base URI is not being overridden");
}
function serviceManagementEndpointSuffixSummary(model: ManagementCertAuth) {
    return model.serviceManagementEndpointSuffix ? Summary.summary(model.serviceManagementEndpointSuffix) : Summary.placeholder("The default endpoint suffix is not being overridden");
}
function managementCertificateSummary(model: ManagementCertAuth) {
    if (!model.certificateBytes) {
        return Summary.placeholder("No certificate provided");
    }
    if (!model.certificateBytes.HasValue) {
        return Summary.placeholder("No certificate provided");
    }
    if (!model.certificateBytes.NewValue) {
        return Summary.summary(model.certificateThumbprint);
    }
    return Summary.summary("New certificate selected to be uploaded");
}
function subjectKeySummary(subjectKeys: string[]) {
    if (subjectKeys && subjectKeys.length > 0) {
        return Summary.summary(subjectKeyChipList(subjectKeys));
    }
    else {
        return Summary.summary("Using default subject keys");
    }
}
function azureEnvironmentCheckboxSummary(modelState: AzureModel) {
    return modelState.azureEnvironment !== ""
        ? Summary.summary(<span>
                  Selected the <b>{modelState.azureEnvironment}</b> Azure Environment
              </span>)
        : Summary.placeholder(<span>
                  Using the default <b>AzureCloud</b> Environment
              </span>);
}
function resourceManagementUriLabel(modelState: AzureModel) {
    return modelState.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Resource Management Endpoint Base Uri";
}
function authenticationMethodSummary(modelState: AzureModel) {
    return modelState.accountType === null
        ? Summary.placeholder("Select the Azure authentication method")
        : modelState.accountType === AccountType.AzureServicePrincipal
            ? Summary.summary(<span>Use a Service Principal</span>)
            : isOidcAccount(modelState.authentication)
                ? Summary.summary(<span>Use OpenID Connect</span>)
                : Summary.summary(<span>Use a Management Certificate</span>);
}
function activeDirectoryURILabel(modelState: AzureModel) {
    return modelState.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Active Directory Endpoint Base Uri";
}
function serviceManagementBaseUriLabel(modelState: AzureModel) {
    return modelState.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Service Management Endpoint Base Uri";
}
function serviceManagementEndpointSuffixLabel(modelState: AzureModel) {
    return modelState.azureEnvironment === "" ? "Select an Azure Environment to be able to edit this field" : "Service Management Endpoint Suffix";
}
function getPageActions(onClick: () => void): PageAction[] {
    return [{ type: "button", buttonType: "secondary", onClick, label: "Save and test" }];
}
function getTestDialog(accountId: string | undefined, showTestDialog: boolean, onOkClick: (success: boolean) => void): React.ReactNode {
    return (accountId && (<Dialog open={showTestDialog}>
                <SaveAndTestAzureAccountDialog onOkClick={onOkClick} accountId={accountId}/>
            </Dialog>));
}
function customExpandableFormSections(modelState: AzureModel, isNew: boolean, azureEnvironments: AzureEnvironment[], publicKey: string | undefined, onChange: FormOnChange, getFieldError: (field: string) => string): React.ReactElement[] {
    const baseElements: React.ReactElement[] = [];
    const sectionHeading = isNew ? "Azure Details" : getAccountDescription(modelState);
    baseElements.push(<FormSectionHeading title={sectionHeading} key={"header"}/>, <ExpandableFormSection errorKey="SubscriptionNumber" key="subscriptionID" title="Subscription ID" summary={subscriptionSummary(modelState)} help="Your Azure subscription ID. This is a GUID in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx." containerKey="AccountEdit">
            <Text value={modelState.subscriptionNumber} onChange={onChange.subscriptionId} label="Subscription" error={getFieldError("SubscriptionNumber")}/>
        </ExpandableFormSection>);
    if (isNew) {
        baseElements.push(<ExpandableFormSection isExpandedByDefault={true} key="AuthenticationMethod" title="Authentication Method" errorKey="accountType" summary={authenticationMethodSummary(modelState)} help="Select the Azure authentication method to show the relevant form inputs below." containerKey="AccountEdit">
                <RadioButtonGroup value={modelState.accountType} onChange={onChange.accountType}>
                    <RadioButton value={AccountType.AzureServicePrincipal} label="Use a Service Principal" isDefault={true}/>
                    <RadioButton value={AccountType.AzureSubscription} label="Use a Management Certificate"/>
                    <RadioButton value={AccountType.AzureOidc} label="Use OpenID Connect"/>
                </RadioButtonGroup>
            </ExpandableFormSection>);
    }
    if (isServicePrincipalAccount(modelState.authentication) || isOidcAccount(modelState.authentication)) {
        baseElements.push(servicePrincipalAndOidcExpandableFormSections(modelState.authentication, modelState, onChange, getFieldError));
    }
    if (isManagementCertificateAccount(modelState.authentication)) {
        baseElements.push(managementCertificateExpandableFormSections(modelState.authentication, publicKey, onChange));
    }
    baseElements.push(<FormSectionHeading title="Azure Environment" key={"EnvironmentHeader"}/>, <ExpandableFormSection errorKey="AzureEnvironment" key={"AzureEnvironment"} title="Azure Environment" summary={azureEnvironmentCheckboxSummary(modelState)} help={<span>
                    Check this box only if you are using an isolated Azure Environment. <ExternalLink href="AzureEnvironments">Learn more about them here</ExternalLink>.
                </span>} containerKey="AccountEdit">
            <Checkbox label="Configure Isolated Azure Environment connection." value={modelState.usingAzureEnvironment || false} onChange={onChange.azureEnvironmentCheckbox}/>
            {modelState.usingAzureEnvironment && (<div>
                    <Select value={modelState.azureEnvironment} onChange={onChange.azureEnvironmentFields} items={toItemArray(azureEnvironments)} label="Azure Environment" allowClear={true}/>
                </div>)}
        </ExpandableFormSection>);
    if ((isServicePrincipalAccount(modelState.authentication) || isOidcAccount(modelState.authentication)) && modelState.usingAzureEnvironment) {
        baseElements.push(AAdAndResourceManagerExpandableEnvironmentFormSections(modelState.authentication, modelState, onChange, getFieldError));
    }
    if (isManagementCertificateAccount(modelState.authentication) && modelState.usingAzureEnvironment) {
        baseElements.push(managementCertificateExpandableEnvironmentFormSections(modelState.authentication, modelState, onChange, getFieldError));
    }
    return baseElements;
}
function getAccountDescription(modelState: AzureModel) {
    switch (modelState.accountType) {
        case AccountType.AzureServicePrincipal:
            return "Service Principal Details";
        case AccountType.AzureOidc:
            return "Service Principal OpenID Connect Details";
        default:
            return "Management Certificate Details";
    }
}
function servicePrincipalAndOidcExpandableFormSections(model: ServicePrincipalAuth | AzureOidcAuth, modelState: AzureModel, onChange: ServicePrincipalAndOidcExpandableFormSectionChanges, getFieldError: (value: string) => string): React.ReactElement {
    return (<div key="SP-Oidc">
            {isOidcAccount(modelState.authentication) && (<Callout type={"warning"} title="Early Access">
                    The OpenID Connect feature is still in development. We'd love to hear <ExternalLink href={"OpenIDConnectFeedback"}> your feedback</ExternalLink>.
                </Callout>)}
            <ExpandableFormSection errorKey="TenantId" key="tenantid" title="Tenant ID" summary={tenantIdSummary(model)} help={`Your Azure Active Directory Tenant ID. This is a GUID in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.`} containerKey="AccountEdit">
                <Text value={model.tenantId} onChange={onChange.tenantId} label="Active Directory Tenant ID" error={getFieldError("TenantId")}/>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="ClientId" key="clientId" title="Application ID" summary={clientIdSummary(model)} help={`Your Azure Active Directory Application ID. This is a GUID in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.
                    This value is known as Application ID in the Azure Portal and the API, and previously referred to as Client ID in the old Azure Portal.`} containerKey="AccountEdit">
                <Text value={model.clientId} onChange={onChange.clientId} label="Active Directory Application ID" error={getFieldError("ClientId")}/>
            </ExpandableFormSection>
            {isServicePrincipalAccount(modelState.authentication) && (<ExpandableFormSection errorKey="password" key="password" title="Application Password / Key" summary={passwordSummary(model as ServicePrincipalAuth)} help={`The password for the Azure Active Directory application. This value is known as Key in the Azure Portal, and Password in the API.`} containerKey="AccountEdit">
                    <Sensitive value={(model as ServicePrincipalAuth).password} label="Active Directory Password/Key" onChange={onChange.password}/>
                </ExpandableFormSection>)}
            {isOidcAccount(modelState.authentication) && (<>
                    <FormSectionHeading title="Subject Claim" key={"SubjectClaim"}/>
                    <ExpandableFormSection errorKey="deploymentSubjectKeys" key="deploymentSubjectKeys" title="Deployments & Runbooks" help="Select keys to include for a deployment or runbook." summary={subjectKeySummary(modelState.authentication.deploymentSubjectKeys)} containerKey="AccountEdit">
                        <OpenIdSubjectMultiSelect onChange={onChange.deploymentSubjectKeys} value={modelState.authentication.deploymentSubjectKeys} subjectKeys={AvailableExecutionSubjectKeys}/>
                        <Note>
                            Subject claim formats:
                            <br />
                            <b>Deployment:</b> <span>{generateSampleSubject(modelState.authentication.deploymentSubjectKeys, DefaultDeploymentSubjectKeys, "deployment", AvailableDeploymentSubjectKeys)}</span>
                            <br />
                            <b>Runbook:</b> <span>{generateSampleSubject(modelState.authentication.deploymentSubjectKeys, DefaultDeploymentSubjectKeys, "runbook", AvailableRunbookSubjectKeys)}</span>
                            <br />
                            <span className={styles.secondaryTextColor}>If no keys are specified, the subject claim will default to: {DefaultDeploymentSubjectKeys.join(", ")}. Any keys not selected will not be included in the subject claim.</span>
                        </Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="healthSubjectKeys" key="healthSubjectKeys" title="Health Checks" help="Select keys to include for a health check." summary={subjectKeySummary(modelState.authentication.healthSubjectKeys)} containerKey="AccountEdit">
                        <OpenIdSubjectMultiSelect onChange={onChange.healthSubjectKeys} value={modelState.authentication.healthSubjectKeys} subjectKeys={AvailableHealthCheckSubjectKeys}/>
                        <Note>
                            <b>Subject claim format: </b>
                            <span>{generateSampleSubject(modelState.authentication.healthSubjectKeys, DefaultHealthCheckSubjectKeys, "health", AvailableHealthCheckSubjectKeys)}</span>
                            <br />
                            <span className={styles.secondaryTextColor}>If no keys are specified, the subject claim will default to: {DefaultHealthCheckSubjectKeys.join(", ")}. Any keys not selected will not be included in the subject claim.</span>
                        </Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="accountTestSubjectKeys" key="accountTestSubjectKeys" title="Account Test" help="Select keys to include for an account test." summary={subjectKeySummary(modelState.authentication.accountTestSubjectKeys)} containerKey="AccountEdit">
                        <OpenIdSubjectMultiSelect onChange={onChange.accountTestSubjectKeys} value={modelState.authentication.accountTestSubjectKeys} subjectKeys={AvailableAccountTestSubjectKeys}/>
                        <Note>
                            <b>Subject claim format: </b>
                            <span>{generateSampleSubject(modelState.authentication.accountTestSubjectKeys, DefaultAccountTestSubjectKeys, "test", AvailableAccountTestSubjectKeys)}</span>
                            <br />
                            <span className={styles.secondaryTextColor}>If no keys are specified, the subject claim will default to: {DefaultAccountTestSubjectKeys.join(", ")}. Any keys not selected will not be included in the subject claim.</span>
                        </Note>
                    </ExpandableFormSection>
                    <FormSectionHeading title="Other Claims" key={"OtherClaims"}/>
                    <ExpandableFormSection errorKey="Audience" key="audience" title="Audience" summary={audienceSummary(model as AzureOidcAuth)} help={`Federated credentials audience, this value is used to establish a connection between external workload identities and Microsoft Entra ID`} containerKey="AccountEdit">
                        <Text value={(model as AzureOidcAuth).audience} onChange={onChange.audience} label="Audience" error={getFieldError("Audience")}/>
                    </ExpandableFormSection>
                </>)}
        </div>);
}
function managementCertificateExpandableFormSections(model: ManagementCertAuth, publicKey: string | undefined, onChange: ManagementCertificateExpandableFormSectionsOnChange): JSX.Element {
    return (<div key="MC">
            <ExpandableFormSection errorKey="managementCert" key="managementCert" title="Management certificate (.pfx)" summary={managementCertificateSummary(model)} help="Leave blank to let Octopus generate a new certificate or provide a password free .pfx file." containerKey="AccountEdit">
                <SensitiveFileUpload availablePlaceholder={model.certificateThumbprint} label="Management certificate (.pfx)" value={model.certificateBytes} onChange={onChange.certificateBytes}/>
                <Callout type={"information"} title="Using your certificate">
                    <p>To give permission for Octopus to deploy to your Azure subscription, upload the public key portion (.cer) of the certificate to the Azure portal:</p>
                    <ol className={styles.certInstructionList}>
                        <li>
                            Download the certificate public key
                            <br />
                            {publicKey && <ExternalLink href={client.resolve(publicKey)}>{model.certificateThumbprint}</ExternalLink>}
                        </li>
                        <li>
                            Log in to the <ExternalLink href="ManageAzure">Azure management portal</ExternalLink>
                        </li>
                        <li>
                            Click <strong>All services</strong>
                        </li>
                        <li>
                            Click <strong>Subscriptions</strong> from the list, then select the subscription that you want to associate with the certificate
                        </li>
                        <li>
                            Click <strong>Management certificates</strong>
                        </li>
                        <li>
                            Click the <strong>Upload</strong> toolbar button
                        </li>
                        <li>
                            Click <strong>Upload</strong> and wait for your certificate to upload
                        </li>
                    </ol>
                </Callout>
            </ExpandableFormSection>
        </div>);
}
function AAdAndResourceManagerExpandableEnvironmentFormSections(model: ServicePrincipalAuth | AzureOidcAuth, modelState: AzureModel, onChange: AAdAndResourceManagerExpandableEnvironmentFormSectionsOnChange, getFieldError: (value: string) => string): JSX.Element {
    return (<div key="SPEE">
            <ExpandableFormSection errorKey="ADbaseUri" key="ADbaseUri" title="AD Endpoint Base Uri" summary={activeDirectoryUriSummary(model)} help="Set this only if you need to override the default Active Directory Endpoint. In most cases you should leave the pre-populated value as is" containerKey="AccountEdit">
                <Text value={model.activeDirectoryBaseUri} onChange={onChange.activeDirectoryBaseUri} label={activeDirectoryURILabel(modelState)} disabled={modelState.azureEnvironment === ""} error={getFieldError("ActiveDirectoryEndpointBaseUri")}/>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="RMbaseUri" key="RMbaseUri" title="Resource Management Base Uri" summary={resourceManagementUriSummary(model)} help="Set this only if you need to override the default Resource Management Endpoint. In most cases you should leave the pre-populated value as is" containerKey="AccountEdit">
                <Text value={model.resourceManagementBaseUri} onChange={onChange.resourceManagementBaseUri} label={resourceManagementUriLabel(modelState)} disabled={modelState.azureEnvironment === ""} error={getFieldError("ResourceManagementEndpointBaseUri")}/>
            </ExpandableFormSection>
        </div>);
}
function managementCertificateExpandableEnvironmentFormSections(model: ManagementCertAuth, modelState: AzureModel, onChange: ManagementCertificateExpandableEnvironmentFormSectionsOnChange, getFieldError: (value: string) => string): JSX.Element {
    return (<div key="MCEE">
            <ExpandableFormSection errorKey="SMbaseUri" key="SMbaseUri" title="Service Management Base Uri" summary={serviceManagementUriSummary(model)} help="Set this only if you need to override the default Service Management Base URI. In most cases you should leave the pre-populated value as is" containerKey="AccountEdit">
                <Text value={model.serviceManagementBaseUri} onChange={onChange.serviceManagementBaseUri} label={serviceManagementBaseUriLabel(modelState)} disabled={modelState.azureEnvironment === ""} error={getFieldError("ServiceManagementEndpointBaseUri")}/>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="SMendpointSuffix" key="SMendpointSuffix" title="Storage Endpoint Suffix" summary={serviceManagementEndpointSuffixSummary(model)} help="Set this only if you need to override the default Storage Endpoint Suffix. In most cases you should leave the pre-populated value as is" containerKey="AccountEdit">
                <Text value={model.serviceManagementEndpointSuffix} onChange={onChange.serviceManagementEndpointSuffix} label={serviceManagementEndpointSuffixLabel(modelState)} disabled={modelState.azureEnvironment === ""} error={getFieldError("ServiceManagementEndpointSuffix")}/>
            </ExpandableFormSection>
        </div>);
}
function toItemArray(azureEnvironments: AzureEnvironment[]): Item[] {
    const items: Item[] = [];
    azureEnvironments.forEach((ae) => {
        items.push({ value: ae.Name, text: ae.DisplayName });
    });
    return items;
}
function generateSampleSubject(requestedKeys: string[], defaultKeys: string[], type: string, possibleItems: ReferenceDataItem[]): string {
    const possibleKeys = possibleItems.map((k) => k.Id);
    const keys: string[] = requestedKeys && requestedKeys.length > 0 ? requestedKeys : defaultKeys;
    return keys
        .filter((k) => possibleKeys.indexOf(k) > -1)
        .sort((a, b) => SubjectKeyOrder.indexOf(a) - SubjectKeyOrder.indexOf(b))
        .map((k) => (k === "type" ? `type:${type}` : `${k}:[${k}-slug]`))
        .join(":");
}
