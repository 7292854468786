import React, { useReducer } from "react";
import type { ActionType } from "typesafe-actions";
import { createAction, getType } from "typesafe-actions";
import type { Errors } from "~/components/DataBaseComponent";
import { useRequiredContext } from "~/hooks";
import { useBoundDispatch } from "~/utils/Reducers";
import type { StoredErrors, ProcessError, ActionError, StepError, ErrorValuePair } from "../../types";
import type { ProcessStateSelectors } from "../ProcessContextState";
export const actions = {
    setErrors: createAction("SET:ERRORS", (errors: Errors, selectors: ProcessStateSelectors) => ({
        errors: parseAllErrors(errors, selectors),
        message: parseMessage(errors),
    }))(),
    clearErrors: createAction("CLEAR:ERRORS", () => ({
        errors: [],
        message: "",
    }))(),
};
type ActionCreators = typeof actions;
type ErrorActions = ActionType<ActionCreators>;
const getStepRegex = () => /Steps\[(\d+)\].([a-zA-Z.]*)/;
const getActionRegex = () => /Steps\[(\d+)\]\.Actions\[(\d+)\].([a-zA-Z0-9[\].]*)/;
export const INITIAL_ERRORS_STATE: StoredErrors = {
    steps: {},
    actions: {},
    global: {},
    globalMessage: "",
};
const parseActionErrorDetails = (error: ErrorValuePair, selectors: ProcessStateSelectors): ActionError | undefined => {
    const actionRegex = getActionRegex();
    const actionResult = actionRegex.exec(error.key);
    if (!actionResult || actionResult.length !== 4) {
        return;
    }
    //The key is unique for a given action i.e. Step[1].Action[2].Name which means we will have a `Name` entry against the action, keyed by the actual action ID.
    //This results in something like { actions: { actionId: { Name: error }}}
    const key = actionResult[3].toLocaleLowerCase();
    const stepIndex = Number(actionResult[1]);
    const actionIndex = Number(actionResult[2]);
    const step = selectors.getStepByIndex(stepIndex);
    const actionId = step.ActionIds[actionIndex];
    return {
        key,
        stepId: step.Id,
        actionId,
        value: error.value,
    };
};
const parseStepErrorDetail = (error: ErrorValuePair, selectors: ProcessStateSelectors): StepError | undefined => {
    const stepRegex = getStepRegex();
    const stepResult = stepRegex.exec(error.key);
    if (!stepResult || stepResult.length !== 3) {
        return;
    }
    //The key is unique per step i.e. Step[1].Name which means we will have a `Name` entry against the step, keyed by the actual step ID.
    //This results in something like { steps: { stepId: { Name: error }}}
    const key = stepResult[2].toLocaleLowerCase();
    const stepIndex = Number(stepResult[1]);
    const step = selectors.getStepByIndex(stepIndex);
    return {
        key,
        stepId: step.Id,
        value: error.value,
    };
};
function isActionError(error: ProcessError): error is ActionError {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = error as ActionError;
    return Object.prototype.hasOwnProperty.call(casted, "actionId") && !!casted.actionId;
}
function isStepError(error: ProcessError): error is StepError {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const casted = error as StepError;
    return Object.prototype.hasOwnProperty.call(casted, "stepId") && !!casted.stepId && !isActionError(error);
}
const parseErrorDetails = (error: ErrorValuePair, selectors: ProcessStateSelectors): ProcessError => {
    // This parser starts with the most specific error details (step.actions), falls through to steps, then global errors.
    const actionErrorDetails = parseActionErrorDetails(error, selectors);
    if (actionErrorDetails) {
        return actionErrorDetails;
    }
    const stepErrorDetails = parseStepErrorDetail(error, selectors);
    if (stepErrorDetails) {
        return stepErrorDetails;
    }
    return error;
};
const parseAllErrors = (errors: Errors, selectors: ProcessStateSelectors) => {
    const errorDetails = errors.details && Object.keys(errors.details).length > 0 ? errors.details : errors.errors;
    return Object.entries(errorDetails).map(([key, value]) => parseErrorDetails({ key, value }, selectors));
};
const parseMessage = (errors: Errors) => {
    const message = errors.message;
    return message;
};
const reduceErrors = (errors: ProcessError[], message: string) => {
    return errors.reduce<StoredErrors>((prev, current) => {
        if (isActionError(current)) {
            return {
                ...prev,
                actions: {
                    ...prev.actions,
                    [current.actionId]: {
                        ...prev.actions[current.actionId],
                        [current.key]: current.value,
                    },
                },
                globalMessage: message,
            };
        }
        else if (isStepError(current)) {
            return {
                ...prev,
                steps: {
                    ...prev.steps,
                    [current.stepId]: {
                        ...prev.steps[current.stepId],
                        [current.key]: current.value,
                    },
                },
                globalMessage: message,
            };
        }
        else {
            return {
                ...prev,
                global: {
                    ...prev.global,
                    [current.key]: current.value,
                },
                globalMessage: message,
            };
        }
    }, INITIAL_ERRORS_STATE);
};
export const processErrorsReducer: React.Reducer<StoredErrors, ErrorActions> = (state, action) => {
    switch (action.type) {
        case getType(actions.setErrors): {
            if (action.payload.errors.length === 0) {
                return state;
            }
            return {
                ...state,
                ...reduceErrors(action.payload.errors, action.payload.message),
            };
        }
        case getType(actions.clearErrors): {
            return INITIAL_ERRORS_STATE;
        }
    }
    return state;
};
const getActionErrorLookup = (state: StoredErrors) => {
    return (actionId: string, processSelectors: ProcessStateSelectors) => {
        const actionErrors = state.actions[actionId];
        const action = processSelectors.getActionById(actionId);
        const step = processSelectors.getStepById(action.ParentId);
        const hasSingleChild = step.ActionIds.length === 1;
        return {
            ...(hasSingleChild ? state.steps[step.Id] ?? {} : {}),
            ...(actionErrors ?? {}),
        };
    };
};
const getActionErrors = (state: StoredErrors) => {
    return (actionId: string, processSelectors: ProcessStateSelectors): string[] => {
        return Object.values(getActionErrorLookup(state)(actionId, processSelectors));
    };
};
const getStepErrors = (state: StoredErrors) => {
    return (stepId: string): string[] => {
        return Object.values(state.steps[stepId] ?? {});
    };
};
const getStepFieldErrors = (state: StoredErrors) => {
    return (stepId: string) => {
        return { ...state.steps[stepId] };
    };
};
const getGlobalErrors = (state: StoredErrors) => {
    return (): string[] => {
        return Object.values(state.global);
    };
};
const getGlobalErrorMessage = (state: StoredErrors) => {
    return (): string => {
        return state.globalMessage;
    };
};
const getActionFieldError = (state: StoredErrors) => {
    return (actionId: string, processSelectors: ProcessStateSelectors, field: string) => {
        const errors = getActionErrorLookup(state)(actionId, processSelectors);
        return errors[field.toLowerCase()];
    };
};
const getActionFieldErrors = (state: StoredErrors) => {
    return (actionId: string, processSelectors: ProcessStateSelectors) => {
        const errors = getActionErrorLookup(state)(actionId, processSelectors);
        return { ...errors };
    };
};
const getStepFieldError = (state: StoredErrors) => {
    return (stepId: string, field: string) => {
        const errors = state.steps[stepId] ?? {};
        return errors[field.toLowerCase()];
    };
};
export interface ProcessErrorSelectors {
    getActionErrors: ReturnType<typeof getActionErrors>;
    getStepErrors: ReturnType<typeof getStepErrors>;
    getGlobalErrors: ReturnType<typeof getGlobalErrors>;
    getGlobalErrorMessage: ReturnType<typeof getGlobalErrorMessage>;
    getActionFieldError: ReturnType<typeof getActionFieldError>;
    getStepFieldError: ReturnType<typeof getStepFieldError>;
    getActionFieldErrors: ReturnType<typeof getActionFieldErrors>;
    getStepFieldErrors: ReturnType<typeof getStepFieldErrors>;
}
export const getSelectors = (state: StoredErrors): ProcessErrorSelectors => {
    return {
        getActionErrors: getActionErrors(state),
        getStepErrors: getStepErrors(state),
        getGlobalErrors: getGlobalErrors(state),
        getGlobalErrorMessage: getGlobalErrorMessage(state),
        getActionFieldError: getActionFieldError(state),
        getStepFieldError: getStepFieldError(state),
        getActionFieldErrors: getActionFieldErrors(state),
        getStepFieldErrors: getStepFieldErrors(state),
    };
};
const useBoundProcessActions = (dispatch: React.Dispatch<ErrorActions>) => {
    return useBoundDispatch(dispatch, actions);
};
export type BoundErrorActionsType = ReturnType<typeof useBoundProcessActions>;
interface ProcessErrorStateContextProps {
    selectors: ProcessErrorSelectors;
}
interface ProcessErrorActionContextProps {
    actions: BoundErrorActionsType;
}
const ProcessErrorSelectorContext = React.createContext<ProcessErrorStateContextProps | undefined>(undefined);
const ProcessErrorActionContext = React.createContext<ProcessErrorActionContextProps | undefined>(undefined);
export const useProcessErrorSelectors = () => {
    const selectorsContext = useRequiredContext(ProcessErrorSelectorContext, "Process Errors State");
    return selectorsContext.selectors;
};
export const useProcessErrorActions = () => {
    const actionsContext = useRequiredContext(ProcessErrorActionContext, "Process Error Actions");
    return actionsContext.actions;
};
const useProcessErrorReducer = () => {
    return useReducer(processErrorsReducer, INITIAL_ERRORS_STATE);
};
export interface ProcessErrorSelectorsProps {
    children: (selectors: ProcessErrorSelectors) => React.ReactElement | null;
}
export const ProcessErrorSelectors: React.FC<ProcessErrorSelectorsProps> = ({ children }) => {
    const selectors = useProcessErrorSelectors();
    return children(selectors);
};
ProcessErrorSelectors.displayName = "ProcessErrorSelectors"
export interface ProcessErrorActionsProps {
    children: (actions: BoundErrorActionsType) => React.ReactElement | null;
}
export const ProcessErrorActions: React.FC<ProcessErrorActionsProps> = ({ children }) => {
    const errorActions = useProcessErrorActions();
    return children(errorActions);
};
ProcessErrorActions.displayName = "ProcessErrorActions"
export const ProcessErrorsController: React.FC = React.memo((props) => {
    const [state, dispatch] = useProcessErrorReducer();
    const boundActions = useBoundDispatch(dispatch, actions);
    const selectors = React.useMemo(() => getSelectors(state), [state]);
    return (<ProcessErrorSelectorContext.Provider value={{ selectors }}>
            <ProcessErrorActionContext.Provider value={{ actions: boundActions }}>{props.children}</ProcessErrorActionContext.Provider>
        </ProcessErrorSelectorContext.Provider>);
});
ProcessErrorsController.displayName = "ProcessErrorsController"
export interface WithProcessErrorActionsContextInjectedProps {
    processErrorActions: BoundErrorActionsType;
}
export function withProcessErrorActionsContext<T>(Component: React.ComponentType<T & WithProcessErrorActionsContextInjectedProps>) {
    const WithProcessErrorActionsContext: React.FC<T> = (props) => {
        const context = useProcessErrorActions();
        return <Component processErrorActions={context} {...props}/>;
    };
    WithProcessErrorActionsContext.displayName = "WithProcessErrorActionsContext"
    return WithProcessErrorActionsContext;
}
export interface WithProcessErrorSelectorContextInjectedProps {
    processErrorSelectors: ProcessErrorSelectors;
}
export function withProcessErrorSelectorsContext<T>(Component: React.ComponentType<T & WithProcessErrorSelectorContextInjectedProps>) {
    const WithProcessErrorSelectorsContext: React.FC<T> = (props) => {
        const context = useProcessErrorSelectors();
        return <Component processErrorSelectors={context} {...props}/>;
    };
    WithProcessErrorSelectorsContext.displayName = "WithProcessErrorSelectorsContext"
    return WithProcessErrorSelectorsContext;
}
