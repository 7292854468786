import React from "react";
import { useQueryParams } from "~/components/ContextualHelpLayout/useQueryParams";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
export const CommonInfrastructureAccountOverview = () => {
    const getQueryParam = useQueryParams();
    const accountType = getQueryParam("accountType");
    switch (accountType) {
        case "AzureSubscription":
            return (<Overview>
                    <p>
                        To deploy to Azure cloud, add your <strong>Azure subscription</strong> to Octopus.
                    </p>
                </Overview>);
        case "AmazonWebServicesAccount":
            return (<Overview>
                    <p>
                        To deploy to AWS, add your <strong>AWS account</strong> to Octopus.
                    </p>
                </Overview>);
        case "GoogleCloudAccount":
            return (<Overview>
                    <p>
                        To deploy to Google Cloud, add your <strong>Google Cloud account</strong> to Octopus.
                    </p>
                </Overview>);
        case "SshKeyPair":
            return (<Overview>
                    <p>
                        An <strong>SSH key pair</strong> account is one of the more secure authentication methods available for connecting to SSH deployment targets.
                    </p>
                </Overview>);
        case "UsernamePassword":
            return (<Overview>
                    <p>
                        A <strong>username/password</strong> account can be used to connect SSH deployment targets and services, like Google Cloud Platform, if you are using the Kubernetes functionality in Octopus.
                    </p>
                </Overview>);
        case "Token":
            return (<Overview>
                    <p>
                        <strong>Tokens</strong> can be added to Octopus as accounts. This is useful, for instance, if you are deploying to Kubernetes deployment targets.
                    </p>
                </Overview>);
        default:
            return (<Overview>
                    <ExternalLink href="OnboardingAccountsLearnMore">Accounts</ExternalLink> help you to centralise account details used during your deployments, including things like username/password, tokens, Azure and AWS credentials and SSH key
                    pairs.
                </Overview>);
    }
};
export const CommonInfrastructureAccountTopics = () => {
    const getQueryParam = useQueryParams();
    const accountType = getQueryParam("accountType");
    switch (accountType) {
        case "AzureSubscription":
            return (<Topics>
                    <ExternalLink href="AzureAccount">Azure Subscription account setup guide</ExternalLink>
                </Topics>);
        case "AmazonWebServicesAccount":
            return (<Topics>
                    <ExternalLink href="AWSAccount">AWS account setup guide</ExternalLink>
                </Topics>);
        case "GoogleCloudAccount":
            return (<Topics>
                    <ExternalLink href="GoogleCloudAccount">Google Cloud account setup guide</ExternalLink>
                </Topics>);
        case "SshKeyPair":
            return (<Topics>
                    <ExternalLink href="SSHKeyPairAcount">SSH key pair account setup guide</ExternalLink>
                </Topics>);
        case "UsernamePassword":
            return (<Topics>
                    <ExternalLink href="UsernamePasswordAccount">Username and password account setup guide</ExternalLink>
                </Topics>);
        case "Token":
            return (<Topics>
                    <ExternalLink href="TokenAccount">Token account setup guide</ExternalLink>
                </Topics>);
        default:
            return (<Topics>
                    <ExternalLink href="OnboardingAccountsLearnMore">Accounts</ExternalLink>
                </Topics>);
    }
};
const Overview = ({ children }: {
    children: React.ReactNode;
}) => (<OverviewContainer>
        <OverviewHighlightText>{children}</OverviewHighlightText>
    </OverviewContainer>);
const Topics = ({ children }: {
    children: React.ReactNode;
}) => (<TopicsContainer>
        <LearnMoreResources>{children}</LearnMoreResources>
    </TopicsContainer>);
