import { BooleanRadioButtonGroup, BooleanRadioButton } from "@octopusdeploy/design-system-components";
import React from "react";
import { ExpandableFormSection, Summary } from "~/components/form";
type BulkDeploymentProps = {
    isEnabled: boolean;
    onChange: (isEnabled: boolean) => void;
};
export function BulkDeployment({ isEnabled, onChange }: BulkDeploymentProps) {
    return (<ExpandableFormSection key="IsBulkDeploymentCreationEnabled" errorKey="IsBulkDeploymentCreationEnabled" title="Bulk Deployment Creation" summary={isEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")} help="Use a server task when creating two or more deployments">
            <BooleanRadioButtonGroup value={isEnabled} onChange={onChange}>
                <BooleanRadioButton value={true} label="Enabled" isDefault={true}/>
                <BooleanRadioButton value={false} label="Disabled"/>
            </BooleanRadioButtonGroup>
        </ExpandableFormSection>);
}
