import type { Logger } from "@octopusdeploy/logging";
import { logger } from "@octopusdeploy/logging";
import type { Logger as OpenFeatureLogger } from "@openfeature/web-sdk";
export class OpenFeatureLoggerImpl implements OpenFeatureLogger {
    constructor(readonly logger: Logger) { }
    error(...args: unknown[]): void {
        logger.unstructuredLogEvent("error", this.getMessage(args), ...args);
    }
    warn(...args: unknown[]): void {
        logger.unstructuredLogEvent("warning", this.getMessage(args), ...args);
    }
    info(...args: unknown[]): void {
        logger.unstructuredLogEvent("information", this.getMessage(args), ...args);
    }
    debug(...args: unknown[]): void {
        logger.unstructuredLogEvent("debug", this.getMessage(args), ...args);
    }
    getMessage(...args: unknown[]): string {
        return typeof args[0] === "string" ? args[0] : JSON.stringify(args[0]);
    }
}
