import type { EventGroupResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ChipIcon, EventGroupChip, MissingChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface EventGroupMultiSelectProps extends FormFieldProps<string[]> {
    items: EventGroupResource[];
    label?: string | JSX.Element;
    error?: string;
}
const EventGroupTypedMultiSelect = MultiSelect<EventGroupResource>();
export const EventGroupMultiSelect: React.FC<EventGroupMultiSelectProps> = (props) => {
    const chipRenderer = (r: EventGroupResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <EventGroupChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} eventGroup={item}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.EventGroup} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <EventGroupTypedMultiSelect fieldName="event groups" renderChip={chipRenderer} {...props}/>;
};
EventGroupMultiSelect.displayName = "EventGroupMultiSelect"
