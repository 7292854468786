import { Tooltip } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
export enum VariableStatus {
    Existing,
    New,
    Deleted,
    Modified
}
interface VariableStatusIconProps {
    status: VariableStatus;
}
const VariableStatusIcon = (props: VariableStatusIconProps) => {
    switch (props.status) {
        case VariableStatus.New:
            return (<Tooltip content={"New"}>
                    <em className={cn("fa-solid fa-star", styles.newIcon)}/>
                </Tooltip>);
        case VariableStatus.Deleted:
            return (<Tooltip content={"Deleted"}>
                    <em className={cn("fa-solid fa-xmark", styles.deletedIcon)}/>
                </Tooltip>);
        case VariableStatus.Modified:
            return (<Tooltip content={"Changed"}>
                    <em className={cn("fa-solid fa-pencil", styles.editIcon)}/>
                </Tooltip>);
        default:
            return null;
    }
};
export default VariableStatusIcon;
