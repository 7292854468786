/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IconButton } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { FormFieldProps } from "~/components/form";
import { Note, Text } from "~/components/form";
import ParseHelper from "~/utils/ParseHelper/ParseHelper";
import type { TextOtherProps } from "../Text/Text";
import styles from "./style.module.less";
type NumberOtherOmitProps = Omit<TextOtherProps, "type">;
interface NumberOtherProps {
    note?: string;
    label?: string;
}
type NumberProps = NumberOtherOmitProps & FormFieldProps<number | undefined> & NumberOtherProps;
export default function NumberPicker(props: NumberProps) {
    const { value, onChange, label, note, ...otherProps } = props;
    const handleChange = (newValue: string) => {
        const newValueParsed = newValue ? ParseHelper.safeParseInt(newValue, 0) : undefined;
        if (onChange)
            onChange(newValueParsed);
    };
    const increment = () => {
        if (value === undefined)
            handleChange("1");
        else
            handleChange((value + 1).toString());
    };
    const decrement = () => {
        if (value === undefined)
            handleChange("0");
        else
            handleChange((value - 1).toString());
    };
    return (<>
            <label className={styles.label}>{label}</label>
            <div className={styles.numberPicker}>
                <div className={styles.digit}>
                    <IconButton accessibleName="Increment minutes" icon="ArrowUp" onClick={() => increment()} tabIndex={-1}/>
                    <Text type="number" onChange={handleChange} onKeyPress={(e) => {
            if (e.key === ".") {
                e.preventDefault();
            }
        }} value={value === undefined ? "" : value.toString()}/>
                    <IconButton accessibleName="Decrement minutes" icon="ArrowDown" onClick={() => decrement()} tabIndex={-1}/>
                </div>
            </div>
            <Note>{note}</Note>
        </>);
}
