import type { FeatureToggleResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/index";
import { withProjectContext } from "~/areas/projects/context/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
interface FeatureTogglesDataTableNameCellProps {
    featureToggle: FeatureToggleResource;
}
function FeatureTogglesDataTableNameCellInternal(props: FeatureTogglesDataTableNameCellProps & WithProjectContextInjectedProps) {
    const project = props.projectContext.state.model;
    return <InternalLink to={links.featureTogglePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, featureToggleSlug: props.featureToggle.Slug })}>{props.featureToggle.Name}</InternalLink>;
}
export const FeatureTogglesDataTableNameCell = withProjectContext(FeatureTogglesDataTableNameCellInternal);
