import type { AnonymousVcsCredentials, UsernamePasswordVcsCredentials, ReferenceVcsCredentials, GitHubVcsCredentials, GitHubAppConnections, GitHubAppConnectionSummary, GitCredentialResource, GitHubAppRepositories, } from "@octopusdeploy/octopus-server-client";
import { AuthenticationType, IsUsingAnonymousAuth, IsUsingGitHubAuth, IsUsingReferenceAuth, IsUsingUsernamePasswordAuth } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { useNotifyGitCredentialTypeSelected } from "../../../VersionControl/Analytics/useNotifyGitCredentialTypeSelected";
import { VersionControlSettingsTypeSelector, VersionControlSettingsType } from "../VersionControlSettingsTypeSelector";
import GenericVersionControlSettingsDetails from "./GenericVersionControlSettingsDetails";
import { GitHubVersionControlSettingsDetails } from "./GitHubVersionControlSettingsDetails";
export interface VersionControlSettingsConnectionDetailsProps {
    busy?: Promise<unknown> | boolean;
    getFieldError: (key: string) => string | undefined;
    spaceId: string;
    isNew: boolean;
    gitCredentials: GitCredentialResource[] | undefined;
    refreshGitCredentials: () => Promise<boolean>;
    connections: GitHubAppConnections | undefined;
    refreshGitConnections: () => Promise<void>;
    getRepositoriesForConnection: (connection: GitHubAppConnectionSummary) => Promise<GitHubAppRepositories | undefined>;
    url: string;
    setUrl: (url: string) => void;
    credential: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials | GitHubVcsCredentials;
    setCredential: (credentials: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials | GitHubVcsCredentials) => void;
}
export default function VersionControlSettingsConnectionDetails({ busy, getFieldError, spaceId, isNew, gitCredentials, refreshGitCredentials, connections, refreshGitConnections, getRepositoriesForConnection, url, setUrl, credential, setCredential, }: VersionControlSettingsConnectionDetailsProps) {
    const gitHubConnectionsAreEnabled = isFeatureToggleEnabled("GitHubConnectionsFeatureToggle");
    const recordTypeSelected = useNotifyGitCredentialTypeSelected();
    const [selectedType, setSelectedType] = useState<VersionControlSettingsType>(() => {
        if (IsUsingGitHubAuth(credential)) {
            return VersionControlSettingsType.GitHub;
        }
        else {
            return VersionControlSettingsType.Generic;
        }
    });
    function selectedTypeChanged(newType: VersionControlSettingsType) {
        setSelectedType(newType);
        recordTypeSelected(newType);
        setUrl("");
    }
    // This will gives us a correctly typed credential object for each of the credential selectors. If the current credential is not of the
    // correct type, we'll get the default value for that type. On the first modify of the credential, we'll call onCredentialsChanged and
    // then we'll have the correct type.
    //
    // This is nice because it means that we if we don't modify the credential it won't change the current value on the model. If we change
    // back to the original type, all of the values should still be there.
    const githubCredentials: GitHubVcsCredentials = IsUsingGitHubAuth(credential) ? credential : { Type: AuthenticationType.GitHub, Id: "" };
    const otherCredentials: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials = IsUsingAnonymousAuth(credential) || IsUsingUsernamePasswordAuth(credential) || IsUsingReferenceAuth(credential) ? credential : { Type: AuthenticationType.Reference, Id: "" };
    return (<>
            {gitHubConnectionsAreEnabled && <VersionControlSettingsTypeSelector selectedType={selectedType} setSelectedType={selectedTypeChanged}/>}
            {selectedType === VersionControlSettingsType.GitHub && (<GitHubVersionControlSettingsDetails spaceId={spaceId} isNew={isNew} connections={connections} refreshGitConnections={refreshGitConnections} getRepositoriesForConnection={getRepositoriesForConnection} setGitHubConnectionDetails={(connectionId, url) => {
                setCredential({
                    Type: AuthenticationType.GitHub,
                    Id: connectionId ?? "",
                });
                setUrl(url ?? "");
            }} connectionId={githubCredentials.Id} url={url}/>)}
            {selectedType === VersionControlSettingsType.Generic && (<GenericVersionControlSettingsDetails busy={busy} getFieldError={getFieldError} gitCredentials={gitCredentials} refreshGitCredentials={refreshGitCredentials} credential={otherCredentials} url={url} setRepositoryDetails={(url, credential) => {
                setCredential(credential ?? { Type: AuthenticationType.Anonymous });
                setUrl(url ?? "");
            }}/>)}
        </>);
}
