import { css } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ReleaseResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import { ExpandableFormSection, Summary } from "~/components/form";
interface LastReleaseCreatedSummaryProps {
    spaceId: string;
    projectSlug: string;
    release: ReleaseResource | undefined;
}
const dateOptions: Intl.DateTimeFormatOptions = { weekday: "long", year: "numeric", month: "long", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: true };
export function LastReleaseCreatedSummary({ spaceId, projectSlug, release }: LastReleaseCreatedSummaryProps) {
    if (!release) {
        return (<ExpandableFormSection errorKey="Last Release Created" title="Last Release Created" focusOnExpandAll summary={Summary.summary("This trigger hasn\u2019t created a release yet.")} help="See details about the most recent trigger execution that resulted in a new release. ">
                <div className={css({ color: themeTokens.color.text.secondary })}>This trigger hasn’t created a release yet.</div>
            </ExpandableFormSection>);
    }
    const dateString = new Date(release.Assembled).toLocaleString("en-US", dateOptions);
    return (<ExpandableFormSection errorKey="Last Release Created" title="Last Release Created" focusOnExpandAll summary={Summary.summary(`Release ${release.Version} on ${dateString}`)} help="See details about the most recent trigger execution that resulted in a new release. " isExpandedByDefault={true}>
            <div className={css({ paddingTop: space[16], paddingBottom: space[16] })}>
                <InternalLink to={links.releasePage.generateUrl({ spaceId, projectSlug, releaseVersion: release.Version })}>Release {release.Version}</InternalLink> on {dateString}
            </div>
        </ExpandableFormSection>);
}
