/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/css";
import type { BreadcrumbItem } from "@octopusdeploy/design-system-components";
import { Callout } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { AdHocScriptTargetArguments, TaskResource } from "@octopusdeploy/octopus-server-client";
import { ScriptingLanguage } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type * as H from "history";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { client } from "~/clientInstance";
import type { AdHocScriptModel, AdHocScriptProps, AdHocScriptState } from "~/components/AdHocScript/AdHocScript";
import AdHocScript from "~/components/AdHocScript/AdHocScript";
import { CodeEditor } from "~/components/CodeEditor/CodeEditor";
import { SupportedLanguage } from "~/components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import { Section } from "~/components/Section/Section";
import ExpandableFormSection, { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import type { GlobalState } from "~/store/reducers";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/CommonSummaryHelper";
interface ScriptConsoleModel extends AdHocScriptModel {
    ScriptBody: string;
    Syntax: ScriptingLanguage;
}
interface ScriptConsolePageInternalProps extends AdHocScriptProps {
    location: H.Location;
}
class ScriptConsolePageInternal extends AdHocScript<ScriptConsolePageInternalProps, AdHocScriptState<ScriptConsoleModel>, ScriptConsoleModel> {
    getCustomInputs() {
        return (<div>
                <FormSectionHeading title="Script"/>
                <ExpandableFormSection errorKey="Body" title="Body" isExpandedByDefault={true} fillCardWidth={CardFill.FillRight} summary={CommonSummaryHelper.scriptSummary(this.state.model!.ScriptBody, this.state.model!.Syntax)} help={"Select the script language and enter the body of the script that will be executed."}>
                    <CodeEditor language={this.state.model!.Syntax} syntax={this.state.model!.Syntax} value={this.state.model!.ScriptBody} allowFullScreen={true} onChange={(ScriptBody) => this.setModelState({ ScriptBody })} autoExpand={true} showToolbar={true} showCopyButton={true} scriptingLanguageSelectorOptions={{
                supportedLanguages: SupportedLanguage.All,
                onScriptingLanguageChanged: (syntax) => {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    this.setModelState({ Syntax: syntax as ScriptingLanguage });
                },
            }}/>
                </ExpandableFormSection>
            </div>);
    }
    getHeading() {
        const differentSpaceCallout = this.state.taskSpace && this.state.taskSpace.Id != client.spaceId && (<Callout hideTitle={true} type={"information"}>
                You are modifying and re-running a script for Space: <strong>{this.state.taskSpace.Name}</strong>.
            </Callout>);
        return (<>
                {differentSpaceCallout}
                <Section>
                    Use the <em>Script Console</em> to run one-off scripts remotely on deployment targets. Scripts run this way are not associated with projects or deployments.
                </Section>
            </>);
    }
    async getInitialPartialModel(taskToRetry?: TaskResource<any>) {
        const model = {
            ScriptBody: taskToRetry ? taskToRetry.Arguments.ScriptBody : "",
            Syntax: taskToRetry ? taskToRetry.Arguments.Syntax : ScriptingLanguage.PowerShell,
        };
        return model;
    }
    getTitle() {
        return "Script Console";
    }
    getBreadcrumbItems(): BreadcrumbItem[] | undefined {
        return [
            {
                label: "Tasks",
                pageUrl: links.tasksPage.generateUrl(),
            },
        ];
    }
    wrapWithLayout(content: React.ReactNode) {
        return (<main>
                <div className={containerStyles}>{content}</div>
            </main>);
    }
    createAdHocScriptTask(targetTaskArguments: AdHocScriptTargetArguments): Promise<TaskResource<any>> {
        return this.state.scopedRepository!.Tasks.createScriptConsoleTask(targetTaskArguments, this.state.model!.Syntax, this.state.model!.ScriptBody);
    }
    getLocation() {
        return this.props.location;
    }
}
const containerStyles = css({
    margin: space[32],
});
export function ScriptConsolePage() {
    const isBuiltInWorkerEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isBuiltInWorkerEnabled);
    const location = useLocation();
    return <ScriptConsolePageInternal isBuiltInWorkerEnabled={isBuiltInWorkerEnabled} location={location}/>;
}
