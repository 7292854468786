import { ExternalFeed } from "app/areas/library/components/ExternalFeeds/ExternalFeed";
import * as React from "react";
interface BaseExternalFeedProps {
    spaceId: string;
}
interface CreateExternalFeedProps extends BaseExternalFeedProps {
    create: true;
}
interface EditExternalFeedProps extends BaseExternalFeedProps {
    feedId: string;
}
type ExternalFeedPageProps = CreateExternalFeedProps | EditExternalFeedProps;
export function ExternalFeedPage(props: ExternalFeedPageProps) {
    return <ExternalFeed {...props}/>;
}
