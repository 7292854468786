/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import type { ChipProps } from "@material-ui/core/Chip";
import Chip from "@material-ui/core/Chip";
import React from "react";
import { withTheme } from "~/components/Theme";
import { GitIcon } from "~/primitiveComponents/dataDisplay/Icon";
export const GitChip: React.FC<{
    enabled: boolean;
} & ChipProps> = ({ enabled, ...chipProps }) => {
    return withTheme((theme) => {
        if (enabled) {
            return <Chip icon={<GitIcon />} label="Enabled" color="primary" style={{ backgroundColor: theme.successText }} {...chipProps}/>;
        }
    });
};
GitChip.displayName = "GitChip"
