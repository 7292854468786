import { values } from "lodash";
import type * as React from "react";
export interface DisplayOrder {
    displayOrder: number;
}
export interface PackagingInstruction extends DisplayOrder {
    name: string;
    render: () => React.ReactElement;
}
export interface PackagingRegistration extends DisplayOrder {
    name: string;
    id: string;
    instructions: PackagingInstruction[];
}
class PackagingRegistry {
    private registrations: Record<string, PackagingRegistration> = {};
    public getRegistration(id: string) {
        return this.registrations[id];
    }
    public registerPackaging(registration: PackagingRegistration) {
        this.registrations[registration.id] = registration;
    }
    public getAllRegistrations(): PackagingRegistration[] {
        return values(this.registrations);
    }
}
const registry = new PackagingRegistry();
export default registry;
