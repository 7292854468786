import { sortBy } from "lodash";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import type { SortableItemModel } from "~/primitiveComponents/dataDisplay/SortableList/SortableList";
import SortableList from "~/primitiveComponents/dataDisplay/SortableList/SortableList";
import type TagModel from "./TagModel";
interface SortTagsProps {
    tags: TagModel[];
    onTagsSorted(sortIndex: string[]): void;
}
interface SortedTagModel extends SortableItemModel {
    Id: string;
}
interface SortTagsState extends DataBaseComponentState {
    sortedTags: SortedTagModel[];
}
export default class SortTags extends DataBaseComponent<SortTagsProps, SortTagsState> {
    constructor(props: SortTagsProps) {
        super(props);
        const items = sortBy(this.props.tags, (t) => t.sortOrder).map((t) => ({
            Id: t.localId,
            Name: t.name,
        }));
        this.state = { sortedTags: items };
    }
    render() {
        return (<OkDialogLayout title="Sort Tags" errors={this.errors} busy={this.state.busy} onOkClick={() => this.onOk()}>
                <SortableList items={this.state.sortedTags} onOrderChanged={(orderedItems) => this.setState({ sortedTags: orderedItems })}/>
            </OkDialogLayout>);
    }
    onOk() {
        this.props.onTagsSorted(this.state.sortedTags.map((t) => t.Id));
        return true;
    }
    static displayName = "SortTags";
}
