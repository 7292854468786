import type { SummaryView, TenantTagSummary } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { Leftovers } from "~/areas/tenants/components/DataTable/Cells/Leftovers";
import Tag from "~/components/Tag/index";
interface TenantsDataTableTagsCellProps {
    tenantId: string;
    tags: SummaryView<TenantTagSummary>;
    allTags?: TenantTagSummary[];
    onShowAll?: (category: string) => Promise<void>;
    isTenantExpanded?: boolean;
}
export function TenantsDataTableTagsCell({ tenantId, tags, allTags, onShowAll, isTenantExpanded }: TenantsDataTableTagsCellProps) {
    const [showAll, setShowAll] = useState(false);
    async function handleClickShowAll() {
        if (onShowAll) {
            await onShowAll("Tenant Tag");
        }
        setShowAll(!showAll);
    }
    if ((tags && tags.Items.length === 0) || (allTags && allTags.length === 0)) {
        return <div>No tags</div>;
    }
    const tagsToShow = (isTenantExpanded || showAll) && allTags ? allTags : tags.Items;
    const displayTags = tagsToShow.map((tag) => <Tag tagName={tag.Name} tagColor={tag.Color} key={tenantId + tag.Name} description={tag.Description}/>);
    return (<div>
            {displayTags}
            {!isTenantExpanded && <Leftovers count={tags.MoreItems} name={"tag"} onShowAllChanged={onShowAll ? handleClickShowAll : undefined} showAll={showAll}/>}
        </div>);
}
