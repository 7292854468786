import * as React from "react";
export enum Key {
    Esc = "Esc",
    Enter = "Enter",
    CtrlEnter = "CtrlEnter"
}
type KeyboardHandlerRegistrations = Array<{
    key: Key;
    onKeyPressed(e: KeyboardEvent): boolean;
}>;
interface KeyboardHandlerProps {
    className?: string;
    // the return boolean is to indicate if KeyboardHandler should preventDefault()
    registrations: KeyboardHandlerRegistrations;
}
const KeyboardHandler: React.FC<KeyboardHandlerProps> = (props) => {
    React.useEffect(() => {
        const escapeRegistrations = filterRegistrationsForKey(props.registrations, Key.Esc);
        const hasEscRegistration = escapeRegistrations.length > 0;
        const handleEsc = (event: KeyboardEvent): void => {
            if (event.key !== "Escape") {
                return;
            }
            escapeRegistrations.forEach((registration) => {
                if (registration.onKeyPressed(event)) {
                    event.preventDefault();
                }
            });
        };
        if (hasEscRegistration) {
            window.document.addEventListener("keydown", handleEsc);
        }
        return () => window.document.removeEventListener("keydown", handleEsc);
    }, [props.registrations]);
    const handleKey = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        const event = e.nativeEvent;
        const targetKey = mapEventKey(event);
        if (!event.defaultPrevented && targetKey && targetKey !== "Esc") {
            filterRegistrationsForKey(props.registrations, targetKey).forEach((registration) => {
                const result = registration.onKeyPressed(event);
                if (result) {
                    event.preventDefault();
                }
            });
        }
    };
    return (<div onKeyDown={handleKey} style={{ width: "100%", height: "100%" }} className={props.className}>
            {props.children}
        </div>);
};
KeyboardHandler.displayName = "KeyboardHandler"
function filterRegistrationsForKey(registrations: KeyboardHandlerRegistrations, targetKey: Key) {
    return registrations.filter((x) => x.key === targetKey);
}
function mapEventKey(event: KeyboardEvent) {
    switch (event.key) {
        case "Enter":
            return event.ctrlKey ? Key.CtrlEnter : Key.Enter;
        case "Esc":
            return Key.Esc;
    }
}
export default KeyboardHandler;
