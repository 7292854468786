import React from "react";
import type { EmbeddedImageDescription } from "~/components/DevTools/Images/EmbeddedImageDescription";
import { ExecuteOctopusServer } from "~/components/Images/ExecutionLocation/ExecuteOctopusServer";
import { ExecuteOctopusServerRoles } from "~/components/Images/ExecutionLocation/ExecuteOctopusServerRoles";
import { ExecuteTargets } from "~/components/Images/ExecutionLocation/ExecuteTargets";
import { ExecuteWorker } from "~/components/Images/ExecutionLocation/ExecuteWorker";
import { ExecuteWorkerRoles } from "~/components/Images/ExecutionLocation/ExecuteWorkerRoles";
import { OnboardingCowboy } from "~/components/Images/GettingStarted/OnboardingCowboy";
import { LoadingImage } from "~/components/Images/LoadingCowboy/LoadingImage";
import { AzureCloudRegion } from "~/components/Images/MachineSettings/AzureCloudRegion";
import { AzureCloudService } from "~/components/Images/MachineSettings/AzureCloudService";
import { AzureServiceFabric } from "~/components/Images/MachineSettings/AzureServiceFabric";
import { AzureVm } from "~/components/Images/MachineSettings/AzureVm";
import { AzureWebApp } from "~/components/Images/MachineSettings/AzureWebApp";
import { KubernetesApi } from "~/components/Images/MachineSettings/KubernetesApi";
import { ListeningTarget } from "~/components/Images/MachineSettings/ListeningTarget";
import { OfflinePackageDrop } from "~/components/Images/MachineSettings/OfflinePackageDrop";
import { PollingTarget } from "~/components/Images/MachineSettings/PollingTarget";
import { SshConnection } from "~/components/Images/MachineSettings/SshConnection";
import { NoPermissionsPage } from "~/components/Images/NoPermissionsPage/NoPermissionsPage";
import { NoResults } from "~/components/Images/NoResults/NoResults";
import { SomethingsWrong } from "~/components/Images/NotFound/SomethingsWrong";
import { OnboardingSuccess } from "~/components/Images/OnboardingSuccess/OnboardingSuccess";
import { ShipmentError } from "~/components/Images/PackageList/ShipmentError";
import { ShipmentOk } from "~/components/Images/PackageList/ShipmentOk";
import { StepRolling } from "~/components/Images/Process/StepRolling";
import { LibraryVariable } from "~/components/Images/ReactPortal/LibraryVariable";
import { LibraryVariableTemplate } from "~/components/Images/ReactPortal/LibraryVariableTemplate";
import { ProjectVariable } from "~/components/Images/ReactPortal/ProjectVariable";
import { ProjectVariableTemplate } from "~/components/Images/ReactPortal/ProjectVariableTemplate";
import { Octopus } from "~/components/Images/Resources/Octopus";
import { StarFish } from "~/components/Images/SignIn/StarFish";
const machineSettingsImages: EmbeddedImageDescription = {
    componentName: "MachineSettings",
    variants: [
        { variantDescription: "AzureCloudRegion", content: <AzureCloudRegion /> },
        { variantDescription: "AzureCloudService", content: <AzureCloudService /> },
        { variantDescription: "AzureServiceFabric", content: <AzureServiceFabric /> },
        { variantDescription: "AzureVm", content: <AzureVm /> },
        { variantDescription: "AzureWebApp", content: <AzureWebApp /> },
        { variantDescription: "KubernetesCluster", content: <KubernetesApi /> },
        { variantDescription: "ListeningTarget", content: <ListeningTarget /> },
        { variantDescription: "OfflinePackageDrop", content: <OfflinePackageDrop /> },
        { variantDescription: "PollingTarget", content: <PollingTarget /> },
        { variantDescription: "SshConnection", content: <SshConnection /> },
    ],
};
// This represents every usage of <SvgImage> in a react component.
export const embeddedSvgImageDescriptions: EmbeddedImageDescription[] = [
    { componentName: "ExecuteOctopusServer", content: <ExecuteOctopusServer /> },
    { componentName: "ExecuteOctopusServerRoles", content: <ExecuteOctopusServerRoles /> },
    { componentName: "ExecuteTargets", content: <ExecuteTargets /> },
    { componentName: "ExecuteWorker", content: <ExecuteWorker /> },
    { componentName: "ExecuteWorkerRoles", content: <ExecuteWorkerRoles /> },
    { componentName: "OnboardingCowboy", content: <OnboardingCowboy /> },
    { componentName: "LoadingImage", content: <LoadingImage /> },
    { componentName: "NoPermissionsPage", content: <NoPermissionsPage /> },
    { componentName: "NoResults", content: <NoResults /> },
    { componentName: "NotFound / SomethingsWrong", content: <SomethingsWrong /> },
    { componentName: "OnboardingSuccess", content: <OnboardingSuccess /> },
    { componentName: "ShipmentError", content: <ShipmentError /> },
    { componentName: "ShipmentOk", content: <ShipmentOk /> },
    { componentName: "StepRolling", content: <StepRolling /> },
    { componentName: "LibraryVariable", content: <LibraryVariable /> },
    { componentName: "LibraryVariableTemplate", content: <LibraryVariableTemplate /> },
    { componentName: "ProjectVariable", content: <ProjectVariable /> },
    { componentName: "ProjectVariableTemplate", content: <ProjectVariableTemplate /> },
    { componentName: "Octopus", content: <Octopus /> },
    { componentName: "StarFish", content: <StarFish /> },
    machineSettingsImages,
];
