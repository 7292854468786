import type { InsightsReportResource } from "@octopusdeploy/octopus-server-client";
import type { PageRouteDefinition, QueryParamValues, QueryParamValuesSetter, UnknownQueryParam } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import type { Loader } from "~/routing/pageRegistrations/Loader";
import type { PageIdentity } from "~/routing/pageRegistrations/PageIdentity";
import type { PageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import type { StandardLoaderContext } from "~/routing/pageRegistrations/StandardLoaderContext";
import type { StandardPageContext } from "~/routing/pageRegistrations/StandardPageContext";
export function createInsightsPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData>(route: PageRouteDefinition<RouteParams, QueryParams>, config: {
    render: (parameters: {
        context: StandardPageContext;
        loaderData: LoaderData;
        routeParams: RouteParams;
        queryParams: QueryParamValues<QueryParams>;
        setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>;
    }) => ReactElement;
    pageIdentity: PageIdentity;
    loader?: Loader<LoaderData, StandardLoaderContext, RouteParams, QueryParams>;
    loadingTitle?: string;
}): PageRegistration<RouteParams, QueryParams, LoaderData, StandardLoaderContext, StandardPageContext> {
    return {
        route,
        render: config.render,
        pageIdentity: config.pageIdentity,
        verticalNavigationPageArea: "Insights",
        searchKeywords: [],
        loader: config.loader,
        loadingTitle: config.loadingTitle,
    };
}
export function createInsightsReportPageRegistration<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData>(route: PageRouteDefinition<RouteParams, QueryParams>, config: {
    render: (parameters: {
        context: InsightsReportPageContext;
        loaderData: LoaderData;
        routeParams: RouteParams;
        queryParams: QueryParamValues<QueryParams>;
        setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>;
    }) => ReactElement;
    loader?: Loader<LoaderData, StandardLoaderContext, RouteParams, QueryParams>;
    loadingTitle?: string;
    pageIdentity: PageIdentity;
}): PageRegistration<RouteParams, QueryParams, LoaderData, StandardLoaderContext, InsightsReportPageContext> {
    return {
        route,
        render: config.render,
        loader: config.loader,
        loadingTitle: config.loadingTitle,
        pageIdentity: config.pageIdentity,
        verticalNavigationPageArea: "Insights",
        searchKeywords: [],
    };
}
export interface InsightsReportPageContext extends StandardPageContext {
    report: InsightsReportResource;
    refreshReport: () => Promise<void>;
}
