import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface DataTableFooterRowProps {
    className?: string;
}
export class DataTableFooterRow extends React.Component<DataTableFooterRowProps> {
    render() {
        return <tr className={cn(this.props.className, styles.dataTableFooterRow)}>{this.props.children}</tr>;
    }
    static displayName = "DataTableFooterRow";
}
