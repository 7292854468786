/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { ActionButton, ActionButtonType, InlineSnackbar, SimpleMenu, useMenuState } from "@octopusdeploy/design-system-components";
import type { AccountResource } from "@octopusdeploy/octopus-server-client";
import { AccountType } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import { useState } from "react";
import type { MouseEvent } from "react";
import { useCorrelationId } from "~/analytics/Analytics";
import { RawAccountTypeDetailsMap } from "~/areas/infrastructure/InfrastructureDetails";
import { AccountEditDrawer } from "~/areas/infrastructure/components/AccountEdit/AccountEditDrawer";
import { createAddAccountAnalyticsEvent } from "~/areas/infrastructure/components/AccountEdit/amplitudeAnalytics";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList/index";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import type { BoundFieldProps } from "~/components/form/BoundField/BoundField";
import { isCallbackResetValue, withBoundField } from "~/components/form/BoundField/BoundField";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Note from "~/primitiveComponents/form/Note/Note";
import SelectWithAddRefresh from "../SelectWithAddRefresh/SelectWithAddRefresh";
import styles from "./style.module.less";
type AccountSelectProps = FormFieldProps<string> & {
    multiple?: boolean;
    fixedLabel?: boolean;
    type: AccountType | AccountType[];
    items: AccountResource[];
    placeholder?: string;
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    onRequestRefresh(): Promise<any>;
};
export function AccountSelect(props: AccountSelectProps) {
    const warning = getWarning(props.type);
    return (<>
            <AccountSelectInternal {...props}/>
            {warning ? <Note>{warning}</Note> : null}
        </>);
}
export function BoundAccountSelect(props: BoundFieldProps<string | undefined> & AccountSelectProps) {
    const resetValue = isCallbackResetValue(props.resetValue) ? props.resetValue() ?? "" : props.resetValue ?? "";
    const warning = getWarning(props.type);
    return (<>
            <BoundAccountSelectInternal {...props} resetValue={resetValue}/>
            {warning ? <Note>{warning}</Note> : null}
        </>);
}
function AccountSelectInternal({ onRequestRefresh, validate, onValidate, onChange, items, type: allowedTypes, ...otherProps }: AccountSelectProps) {
    const [error, setError] = useState<string | undefined>();
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [newAccountType, setNewAccountType] = useState<AccountType>(AccountType.None);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const trackEvent = useTrackEvent();
    const correlationId = useCorrelationId();
    const [openMenu, menuState] = useMenuState();
    const handleChange = (accountId: string | undefined) => {
        const value = accountId === "" ? null : accountId;
        if (validate) {
            const result = validate(value!);
            setError(result);
            if (onValidate) {
                onValidate(result);
            }
        }
        onChange!(value!);
    };
    const getItems = () => {
        const map = (acc: AccountResource) => ({ value: acc.Id, text: acc.Name });
        const types = Array.isArray(allowedTypes) ? allowedTypes : [allowedTypes];
        return items.filter((a) => types.find((t) => a.AccountType === t)).map(map);
    };
    const selectionRenderer = (accountId: string) => {
        const account = items.find((acc) => acc.Id === accountId);
        if (!account) {
            return accountId;
        }
        return <div className={styles.innerContainer}>{account.Name}</div>;
    };
    const handleRequestRefresh = async () => {
        setRefreshing(true);
        try {
            await onRequestRefresh();
        }
        finally {
            setRefreshing(false);
        }
    };
    const openDrawer = (accountType: AccountType) => {
        setNewAccountType(accountType);
        trackEvent(createAddAccountAnalyticsEvent({
            "Account Type": accountType,
            Location: "Drawer",
            "Correlation Id": correlationId,
        }));
        setIsDrawerOpen(true);
    };
    const activeAccountTypes = RawAccountTypeDetailsMap.filter((accountTypeDetails) => accountTypeDetails.types.some((t) => allowedTypes.includes(t)));
    const addAccountItems: SimpleMenuItem[] = activeAccountTypes.map((accountTypeDetails) => ({
        type: "button",
        label: accountTypeDetails.name,
        onClick: () => {
            openDrawer(accountTypeDetails.types[0]);
        },
    }));
    const handleOnSave = (account: AccountResource) => {
        handleChange(account.Id);
        setIsDrawerOpen(false);
        setShowSnackbar(true);
        handleRequestRefresh();
    };
    const handleAddAccount = (e?: MouseEvent) => {
        // Just open the drawer if there is only one option
        if (addAccountItems.length === 1) {
            openDrawer(activeAccountTypes[0].types[0]);
        }
        else if (e) {
            openMenu(e);
        }
    };
    const filteredItems = getItems();
    return (<div className={cn({ [styles.outerContainer]: filteredItems.length > 0 })}>
            {filteredItems.length !== 0 && (<SelectWithAddRefresh addUrl={links.infrastructureAccountsPage.generateUrl({ spaceId: repository.spaceId! })} addAction={handleAddAccount} onRequestRefresh={handleRequestRefresh} label="Select account" {...otherProps} allowFilter={true} onChange={handleChange} error={error || otherProps.error} items={filteredItems} selectionRenderer={selectionRenderer}/>)}
            {filteredItems.length === 0 && (<ActionList alignStart={true} actions={[<ActionButton type={ActionButtonType.Secondary} label={`Add Account`} onClick={handleAddAccount}/>, <IconButtonWithTooltip onClick={handleRequestRefresh} toolTipContent="Refresh" icon="Refresh"/>]}/>)}

            <>
                <InlineSnackbar variant="success" show={showSnackbar} content={"Account Created"} onClose={() => setShowSnackbar(false)} autoHideDuration={5000}/>
                <SimpleMenu menuState={menuState} items={addAccountItems} accessibleName={"Add account"} compact={true}/>
                <AccountEditDrawer newAccountType={newAccountType} isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} onSuccess={handleOnSave}/>
            </>
        </div>);
}
const BoundAccountSelectInternal = withBoundField(AccountSelectInternal);
const getWarning = (allowedTypes: AccountType | AccountType[]) => {
    const onlyTypeIs = (check: string) => {
        const types = Array.isArray(allowedTypes) ? allowedTypes : [allowedTypes];
        return types.length === 1 && types[0] === check;
    };
    if (onlyTypeIs(AccountType.AzureSubscription)) {
        return "Only Azure Management Certificate Accounts may be selected.";
    }
    if (onlyTypeIs(AccountType.AzureServicePrincipal)) {
        return "Only Azure Service Principal Accounts may be selected.";
    }
    if (onlyTypeIs(AccountType.AzureOidc)) {
        return "Only Azure OpenID Connect Accounts may be selected.";
    }
    if (onlyTypeIs(AccountType.AmazonWebServicesAccount)) {
        return "Only Amazon Web Service Accounts may be selected.";
    }
    if (onlyTypeIs(AccountType.AmazonWebServicesOidcAccount)) {
        return "Only Amazon Web Service OIDC Accounts may be selected.";
    }
    if (onlyTypeIs(AccountType.GoogleCloudAccount)) {
        return "Only Google Cloud Accounts may be selected.";
    }
    if (onlyTypeIs(AccountType.SshKeyPair)) {
        return "Only SSH Key Pair Accounts may be selected.";
    }
    if (onlyTypeIs(AccountType.Token)) {
        return "Only Token Accounts may be selected.";
    }
    if (onlyTypeIs(AccountType.UsernamePassword)) {
        return "Only Username/Password Accounts may be selected.";
    }
    return null;
};
export default AccountSelect;
