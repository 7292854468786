export function getSingle<T>(items: ReadonlyArray<T>, errorWhenMultipleResults: string, errorWhenNoResult: string) {
    const [result, ...rest] = items;
    if (rest.length > 0) {
        throw new Error(errorWhenMultipleResults);
    }
    if (!result) {
        throw new Error(errorWhenNoResult);
    }
    return result;
}
