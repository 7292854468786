/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { MachineModelHealthStatus, MachineModelHealthStatusResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { MachineModelHealthStatusChip, MissingChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface MachineModelHealthStatusMultiSelectProps extends FormFieldProps<MachineModelHealthStatus[]> {
    items: MachineModelHealthStatusResource[];
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
const MachineModelHealthStatusTypedMultiSelect = MultiSelect<MachineModelHealthStatusResource>();
export const MachineModelHealthStatusMultiSelect: React.FC<MachineModelHealthStatusMultiSelectProps> = (props) => {
    const { onChange, ...otherProps } = props;
    const chipRenderer = (r: MachineModelHealthStatusResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <MachineModelHealthStatusChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} healthStatus={item}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.MachineModelHealthStatus} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <MachineModelHealthStatusTypedMultiSelect fieldName="health statuses" renderChip={chipRenderer} onChange={(value) => onChange(value as MachineModelHealthStatus[])} {...otherProps}/>;
};
MachineModelHealthStatusMultiSelect.displayName = "MachineModelHealthStatusMultiSelect"
