import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { Section } from "~/components/Section/Section";
const DeprecatedEndpoint: React.SFC = () => {
    return (<Section>
            <Callout type={"danger"} title="Deprecated">
                This endpoint has been deprecated and is no longer supported by the Octopus UI.
            </Callout>
        </Section>);
};
DeprecatedEndpoint.displayName = "DeprecatedEndpoint"
export default DeprecatedEndpoint;
