import type { DependencyList, EffectCallback } from "react";
import { useEffect, useRef } from "react";
// Similar to useEffect but won't run anything on first render
export function useDidUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
        else {
            return effect();
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps);
}
