import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonInfrastructureMachinePolicyOverview } from "../../CommonComponents/CommonInfrastructureMachinePolicyViews";
export const InfrastructureMachinePoliciesRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.machinePolicies.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureMachinePolicyOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
