import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useProjectContext } from "../../context/ProjectContext";
import type { SetRequiresKubernetesTarget } from "../ProjectLayout/ProjectLayout";
import { ProcessPaperLayout } from "./CustomPaperLayouts/ProcessPaperLayout";
import ProcessStepsLayoutLoader from "./ProcessStepsLayoutLoader";
type DeploymentProcessStepsPageProps = {
    setShowK8sStatusItem?: SetRequiresKubernetesTarget;
};
export function DeploymentProcessStepsPage(props: DeploymentProcessStepsPageProps) {
    const projectContext = useProjectContext();
    if (!projectContext.state.model) {
        return <ProcessPaperLayout processType={ProcessType.Deployment} busy={true}/>;
    }
    return <ProcessStepsLayoutLoader processId={projectContext.state.model.DeploymentProcessId} processType={ProcessType.Deployment} setShowK8sStatusItem={props.setShowK8sStatusItem}/>;
}
