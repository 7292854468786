/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { MachineModelHealthStatus, MachineResource } from "@octopusdeploy/octopus-server-client";
import { isStepPackageEndpointResource, Permission, EndpointsHelper } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import type { AnyAction } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import { MachineHealthStatusIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/MachineHealthStatusIcon";
import endpointRegistry from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { repository } from "~/clientInstance";
import Chip from "~/components/Chips/Chip";
import DataBaseComponent from "~/components/DataBaseComponent";
import type { NavLink } from "~/components/NavigationSidebarLayout";
import { Navigation } from "~/components/NavigationSidebarLayout";
import { PortalPageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { PortalPageLayout } from "~/components/PortalPageLayout/PortalPageLayout";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import type { UnknownStepPackageDeploymentTarget } from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTarget";
import { getMachineIconUrl } from "~/utils/MachineIconUrlFetchers/machineIconUrlFetchers";
import { machineActions } from "../../reducers/machines";
import { getCurrentMachine } from "../../reducers/selectors";
type MachineLayoutRouteProps = {
    machineId: string;
};
interface MachineLayoutInternalProps {
    machineId: string;
    machineName?: string;
    machineType?: string;
    machineIconUrl?: string;
    machineIsDisabled?: boolean;
    isStepPackage?: boolean;
    machineHealthStatus?: MachineModelHealthStatus;
    title: string;
    getMachine(id: string): Promise<MachineResource>;
    isDeploymentTargetPage: boolean;
    onFetchMachine(machine: MachineResource): void;
    isVerticalNavigationEnabled: boolean;
}
interface MachineLayoutInternalState {
    stepPackageDeploymentTarget: UnknownStepPackageDeploymentTarget | undefined;
    machine: MachineResource | undefined;
}
class MachineLayoutInternal extends DataBaseComponent<MachineLayoutInternalProps, MachineLayoutInternalState> {
    constructor(props: MachineLayoutInternalProps) {
        super(props);
        this.state = {
            stepPackageDeploymentTarget: undefined,
            machine: undefined,
        };
    }
    componentDidMount() {
        if (this.props.machineId) {
            this.doBusyTask(async () => {
                const machine = await this.props.getMachine(this.props.machineId);
                const deploymentTarget = isStepPackageEndpointResource(machine.Endpoint) ? await endpointRegistry.getStepPackageDeploymentTarget(machine.Endpoint.DeploymentTargetTypeId, machine.Endpoint.StepPackageVersion) : undefined;
                this.setState((prevState) => ({ ...prevState, machine, stepPackageDeploymentTarget: deploymentTarget }));
                this.props.onFetchMachine(machine);
            });
        }
    }
    render() {
        const machineId = this.props.machineId;
        const navLinks: Array<NavLink | null> = [];
        if (machineId) {
            const settingsLinkHref = this.props.isDeploymentTargetPage
                ? links.deploymentTargetSettingsPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId!, machineId })
                : links.workerMachineSettingsPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId!, machineId });
            const connectionLinkHref = this.props.isDeploymentTargetPage
                ? links.deploymentTargetConnectionPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId!, machineId })
                : links.workerMachineConnectionPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId!, machineId });
            const eventLinksHref = this.props.isDeploymentTargetPage
                ? links.deploymentTargetEventsPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId!, machineId })
                : links.workerMachineEventsPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId!, machineId });
            navLinks.push(Navigation.navItem("Settings", settingsLinkHref));
            navLinks.push(Navigation.navItem(<React.Fragment>Connectivity {this.props.machineHealthStatus && <MachineHealthStatusIcon healthStatus={this.props.machineHealthStatus}/>}</React.Fragment>, connectionLinkHref));
            if (this.props.isDeploymentTargetPage) {
                navLinks.push(Navigation.navItem("Deployments", links.deploymentTargetDeploymentsPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId!, machineId }), undefined, { permission: Permission.TaskView, wildcard: true }));
                navLinks.push(Navigation.navItem("Runbook Runs", links.deploymentTargetRunbookRunsPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId!, machineId }), undefined, { permission: Permission.TaskView, wildcard: true }));
            }
            navLinks.push(Navigation.navItem("Events", eventLinksHref, undefined, { permission: Permission.EventView, wildcard: true }));
        }
        const rootLink = this.props.isDeploymentTargetPage
            ? links.deploymentTargetsPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId! })
            : links.workerMachinesPage.generateUrl({ spaceId: this.state.machine?.SpaceId ?? repository.spaceId! });
        let machineType = this.props.machineType;
        if (this.props.isStepPackage) {
            machineType = this.state.stepPackageDeploymentTarget?.name ?? "";
        }
        if (!this.props.machineName) {
            return (<main>
                    <SkeletonLoadingLayout errors={this.errors}/>
                </main>);
        }
        const machineChips = [];
        if (machineType) {
            machineChips.push(<MachineChip label={machineType}/>);
        }
        if (this.props.machineIsDisabled) {
            machineChips.push(<MachineChip label={"Disabled"}/>);
        }
        return (<main>
                <PortalPageLayout navItems={compact(navLinks)} content={this.props.children} header={<PortalPageHeaderPrimary breadcrumbs={[{ label: this.props.isVerticalNavigationEnabled ? this.props.title : "Infrastructure", pageUrl: rootLink }]} title={this.props.machineName ?? this.props.title} logo={this.props.machineIconUrl ? { href: this.props.machineIconUrl, accessibleName: `${this.props.machineName} icon` } : undefined} titleChips={machineChips}/>} enablePageLayoutVNext={true}/>
            </main>);
    }
    static displayName = "MachineLayoutInternal";
}
function MachineChip({ label }: {
    label: string;
}) {
    return (<Chip noMargin={true} noTooltip={true} backgroundColor={themeTokens.color.chip.filled.background.primary} labelColor={themeTokens.color.chip.filled.text.primary}>
            {label}
        </Chip>);
}
interface SharedMachineLayoutProps {
    title: string;
    getMachine(id: string): Promise<MachineResource>;
    isDeploymentTargetPage: boolean;
    children: React.ReactNode;
}
function SharedMachineLayout({ title, getMachine, isDeploymentTargetPage, children }: SharedMachineLayoutProps) {
    const match = useRouteMatch<MachineLayoutRouteProps>();
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    const machineId = match?.params?.machineId;
    const machine = useSelector(getCurrentMachine);
    const dispatchFetchMachine = useDispatchFetchMachine();
    if (!machineId) {
        // This should never happen, machineId is a required route parameter.
        // When this page is moved to the vNext routing infrastructure, this can be changed to be a required prop instead, and this null check removed.
        return null;
    }
    return (<MachineLayoutInternal machineId={machineId} machineName={machine?.name} machineType={machine?.machineType} machineIconUrl={machine?.machineIconUrl} machineIsDisabled={machine?.isDisabled} title={title} getMachine={getMachine} isDeploymentTargetPage={isDeploymentTargetPage} machineHealthStatus={machine?.healthStatus} onFetchMachine={dispatchFetchMachine} isVerticalNavigationEnabled={isVerticalNavigationEnabled}>
            {children}
        </MachineLayoutInternal>);
}
export function WorkerMachineLayout({ children }: {
    children: React.ReactNode;
}) {
    return (<SharedMachineLayout title="Workers" getMachine={(id: string) => repository.Workers.get(id)} isDeploymentTargetPage={false}>
            {children}
        </SharedMachineLayout>);
}
export function MachineLayout({ children }: {
    children: React.ReactNode;
}) {
    return (<SharedMachineLayout title="Deployment Targets" getMachine={(id: string) => repository.Machines.get(id)} isDeploymentTargetPage={true}>
            {children}
        </SharedMachineLayout>);
}
const useDispatchFetchMachine = () => {
    const dispatch: ThunkDispatch<GlobalState, void, AnyAction> = useDispatch();
    return React.useCallback((machine: MachineResource) => {
        const machineIconUrl = getMachineIconUrl(machine);
        dispatch(machineActions.machineFetched({
            id: machine.Id,
            name: machine.Name,
            machineIconUrl,
            machineType: EndpointsHelper.getFriendlyName(machine),
            isDisabled: machine.IsDisabled,
            isStepPackage: isStepPackageEndpointResource(machine.Endpoint),
            healthStatus: machine.HealthStatus,
        }));
    }, [dispatch]);
};
