import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { firstAuthorized } from "~/components/PermissionCheck/PermissionCheck";
export function DefaultConfigurationPageRedirect() {
    return <InternalRedirect to={getDefaultPage()}/>;
}
function getDefaultPage() {
    switch (firstAuthorized([Permission.AdministerSystem, Permission.EventView, Permission.TeamView])) {
        case Permission.AdministerSystem:
            return links.featuresPage.generateUrl();
        case Permission.EventView:
            return links.auditPage.generateUrl();
        case Permission.TeamView:
            return links.teamsPage.generateUrl();
        default:
            return links.spacesPage.generateUrl();
    }
}
