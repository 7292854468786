/* eslint-disable @typescript-eslint/no-explicit-any */
import type { EventCategoryResource, EventGroupResource, DocumentTypeResource, EnvironmentResource, TenantResource, UserResource, EventAgentResource, ProjectGroupResource, ProjectSummaryResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { connect } from "react-redux";
import BaseComponent from "~/components/BaseComponent";
import { userList } from "~/components/Chips/UserChip";
import { documentChipList, environmentChipList, eventCategoryList, eventGroupList, projectChipList, tenantChipList, eventAgentList, projectGroupChipList } from "~/components/Chips/index";
import buildValueList from "~/components/EventFilter/buildValueList";
import Tag from "~/components/Tag/Tag";
import type { TagIndex } from "~/components/tenantTagsets";
interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}
interface EventFilterSummaryProps {
    // Supply the collections of resources for each filter you wish to support (leave it null and it will be hidden).
    documentTypes: DocumentTypeResource[];
    eventCategories: EventCategoryResource[];
    eventGroups: EventGroupResource[];
    eventAgents: EventAgentResource[];
    environments: EnvironmentResource[];
    projects: ProjectSummaryResource[];
    projectGroups: ProjectGroupResource[];
    tenants: TenantResource[];
    users: UserResource[];
    tagIndex: TagIndex;
    // Supply the selected values for each filter.
    selectedDocumentTypes: string[];
    selectedEventCategories: string[];
    selectedEventGroups: string[];
    selectedEventAgents: string[];
    selectedEnvironments: string[];
    selectedProjects: string[];
    selectedProjectGroups: string[];
    selectedTenants: string[];
    selectedTenantTags: string[];
    selectedUsers: string[];
}
export class EventFilterSummary extends BaseComponent<GlobalConnectedProps & EventFilterSummaryProps> {
    render() {
        const documentTypes = documentChipList(this.props.documentTypes, this.props.selectedDocumentTypes);
        const environments = environmentChipList(this.props.environments, this.props.selectedEnvironments);
        const eventCategories = eventCategoryList(this.props.eventCategories, this.props.selectedEventCategories);
        const eventGroups = eventGroupList(this.props.eventGroups, this.props.selectedEventGroups);
        const eventAgents = eventAgentList(this.props.eventAgents, this.props.selectedEventAgents);
        const projects = projectChipList(this.props.projects, this.props.selectedProjects);
        const projectGroups = projectGroupChipList(this.props.projectGroups, this.props.selectedProjectGroups);
        const users = userList(this.props.users, this.props.selectedUsers);
        let tenants: JSX.Element[] = [];
        let tenantTags: JSX.Element[] = [];
        if (this.props.isMultiTenancyEnabled) {
            tenants = tenantChipList(this.props.tenants, this.props.selectedTenants);
            tenantTags = this.props.selectedTenantTags
                .map((t) => this.props.tagIndex[t])
                .filter((t) => !!t)
                .map((t) => <Tag key={t.Id} description={t.Description} tagName={t.Name} tagColor={t.Color}/>);
        }
        const notificationSummary = this.getEventFilterSummary(eventGroups, eventCategories, eventAgents, documentTypes, users, projects, projectGroups, environments, tenants, tenantTags);
        return <span>{notificationSummary}</span>;
    }
    getEventFilterSummary(eventGroups: JSX.Element[], eventCategories: JSX.Element[], eventAgents: JSX.Element[], documentTypes: JSX.Element[], users: JSX.Element[], projects: JSX.Element[], projectGroups: JSX.Element[], environments: JSX.Element[], tenants: JSX.Element[], tenantTags: JSX.Element[]): JSX.Element {
        const eventGroupsElement = this.selectedEventGroupsElement(eventGroups);
        const eventCategoriesElement = this.selectedEventCategoriesElement(eventCategories);
        const eventAgentsElement = this.selectedEventAgentsElement(eventAgents);
        const documentTypesElement = this.selectedDocumentTypesElement(documentTypes);
        const usersElement = this.selectedUsersElement(users);
        const projectsElement = this.selectedProjectsElement(projects);
        const projectGroupsElement = this.selectedProjectGroupsElement(projectGroups);
        const environmentsElement = this.selectedEnvironmentElement(environments);
        const tenantsElement = this.selectedTenantsElement(tenants);
        const tenantTagElement = this.selectedTenantTagElement(tenantTags);
        const eventSelectionElements: JSX.Element[] = [];
        if (eventGroupsElement) {
            eventSelectionElements.push(eventGroupsElement); //for
        } //or
        if (eventCategoriesElement) {
            eventSelectionElements.push(eventCategoriesElement); //for
        } //or
        if (eventAgentsElement) {
            eventSelectionElements.push(eventAgentsElement); //for
        } //or
        if (documentTypesElement) {
            eventSelectionElements.push(documentTypesElement); //for
        }
        const notificationSummary: JSX.Element[] = eventSelectionElements.reduce((accu: any, elem) => {
            return accu === null ? [elem] : [...accu, " and ", elem];
        }, null) || [];
        if (notificationSummary && notificationSummary.length > 0) {
            notificationSummary.push(<span key="specificEventsRaised"> are raised</span>);
        }
        else {
            notificationSummary.push(<span key="anyEventsRaised">any events are raised</span>);
        }
        const filteringElements = [];
        if (projectsElement) {
            filteringElements.push(projectsElement);
        }
        if (projectGroupsElement) {
            filteringElements.push(projectGroupsElement);
        }
        if (environmentsElement) {
            filteringElements.push(environmentsElement);
        }
        if (tenantsElement) {
            filteringElements.push(tenantsElement);
        }
        if (tenantTagElement) {
            filteringElements.push(tenantTagElement);
        }
        const filteringElementsSummary: JSX.Element[] = filteringElements.reduce((accu: any, elem) => {
            return accu === null ? [elem] : [...accu, " ", elem];
        }, null) || [];
        if (filteringElementsSummary && filteringElementsSummary.length > 0) {
            notificationSummary.push(<span key="filteringElements"> {filteringElementsSummary}</span>);
        }
        if (usersElement) {
            notificationSummary.push(<span key="usersElement"> triggered by {usersElement}</span>); //triggered by
        }
        if (notificationSummary.length === 0) {
            return <span key="notificationSummary">Notified whenever any events are raised.</span>;
        }
        return <span>Notified whenever {notificationSummary}.</span>;
    }
    private selectedEventGroupsElement(selectedEventGroups: JSX.Element[]) {
        return selectedEventGroups.length > 0 && <span key={"event-groups"}>{buildValueList(selectedEventGroups, " ", " or ")}</span>;
    }
    private selectedEventCategoriesElement(selectedEventCategories: JSX.Element[]) {
        return selectedEventCategories.length > 0 && <span key={"event-categories"}>{buildValueList(selectedEventCategories, " ", " or ")} events</span>;
    }
    private selectedEventAgentsElement(selectedEventAgents: JSX.Element[]) {
        return selectedEventAgents.length > 0 && <span key={"event-agents"}>{buildValueList(selectedEventAgents, " ", " or ")} events</span>;
    }
    private selectedDocumentTypesElement(selectedDocumentTypes: JSX.Element[]) {
        return selectedDocumentTypes.length > 0 && <span key={"document-types"}>{buildValueList(selectedDocumentTypes, " ", " or ")} document type events</span>;
    }
    private selectedUsersElement(selectedUsers: JSX.Element[]) {
        return selectedUsers.length > 0 && <span key={"users"}>{buildValueList(selectedUsers, " ", " or ")}</span>;
    }
    private selectedProjectsElement(selectedProjects: JSX.Element[]) {
        return (selectedProjects.length > 0 && (<span key={"projects"}>
                    for project{selectedProjects.length === 1 ? "" : "s"} {buildValueList(selectedProjects, " ", " or ")}
                </span>));
    }
    private selectedProjectGroupsElement(selectedProjectGroups: JSX.Element[]) {
        return (selectedProjectGroups.length > 0 && (<span key={"projectGroups"}>
                    for project group{selectedProjectGroups.length === 1 ? "" : "s"} {buildValueList(selectedProjectGroups, " ", " or ")}
                </span>));
    }
    private selectedEnvironmentElement(selectedEnvironments: JSX.Element[]) {
        return (selectedEnvironments.length > 0 && (<span key={"environment"}>
                    for environment{selectedEnvironments.length === 1 ? "" : "s"} {buildValueList(selectedEnvironments, " ", " or ")}
                </span>));
    }
    private selectedTenantsElement(selectedTenants: JSX.Element[]) {
        return (selectedTenants.length > 0 && (<span key={"tenants"}>
                    for tenant{selectedTenants.length === 1 ? "" : "s"} {buildValueList(selectedTenants, " ", " or ")}
                </span>));
    }
    private selectedTenantTagElement(selectedTenantTags: JSX.Element[]) {
        return selectedTenantTags.length > 0 && <span key={"tenant-tags"}>for tenants tagged with {buildValueList(selectedTenantTags, " ", " or ")}</span>;
    }
    static displayName = "EventFilterSummary";
}
const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};
export default connect(mapGlobalStateToProps)(EventFilterSummary);
