export const TemplateValuesSourceTypes = ["GitRepository", "Chart", "Package", "KeyValues", "InlineYaml"];
export type TemplateValuesSourceType = (typeof TemplateValuesSourceTypes)[number];
export type TemplateValuesSources = TemplateValuesSource[];
export type KeyValuesTemplateValuesSource = {
    Type: "KeyValues";
    Value: Record<string, string>;
};
export type InlineYamlTemplateValuesSource = {
    Type: "InlineYaml";
    Value: string;
};
export type PackageTemplateValuesSource = {
    Type: "Package";
    PackageId: string;
    PackageName: string;
    ValuesFilePaths: string;
};
export type ChartTemplateValuesSource = {
    Type: "Chart";
    ValuesFilePaths: string;
};
export type GitRepositoryTemplateValuesSource = {
    Type: "GitRepository";
    GitDependencyName: string;
    ValuesFilePaths: string;
};
export type TemplateValuesSource = KeyValuesTemplateValuesSource | InlineYamlTemplateValuesSource | PackageTemplateValuesSource | ChartTemplateValuesSource | GitRepositoryTemplateValuesSource;
export function isPackageTemplateValuesSource(obj: unknown): obj is PackageTemplateValuesSource {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (obj as PackageTemplateValuesSource)?.Type === "Package";
}
export function isGitRepositoryTemplateValuesSource(obj: unknown): obj is GitRepositoryTemplateValuesSource {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (obj as GitRepositoryTemplateValuesSource)?.Type === "GitRepository";
}
