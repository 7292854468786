import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectRunbookSnapshotOverview } from "../../CommonComponents/CommonProjectRunbookSnapshotsViews";
import { CommonProjectRunbooksTopics } from "../../CommonComponents/CommonProjectRunbooksViews";
export const ProjectRunbookRunNowRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().runbook.runNow,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbookSnapshotOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbooksTopics />;
    },
};
