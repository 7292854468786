import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { PropsWithChildren } from "react";
import KubernetesCategoryDefinition from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesCategoryDefinition";
import type { EndpointRegistration, CategorizedEndpointRegistration, CategoryDefinition, StepPackageDeploymentTargetRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry, { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import type { Errors } from "~/components/DataBaseComponent";
import DataLoader from "~/components/DataLoader";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { useSpaceAwareNavigation } from "../../../../components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { EndpointSelector } from "./Endpoints/EndpointSelector";
const MachineTargetNewDataLoader = DataLoader<LoadedData>();
type LoadedData = {
    registrations: EndpointRegistration[];
};
interface MachineTargetNewProps {
    environmentId: string | undefined;
    spaceId: string;
}
export function MachineTargetNewPage(props: MachineTargetNewProps) {
    return (<MachineTargetNewDataLoader load={async () => {
            const registrations = await endpointRegistry.getAllRegistrations();
            return { registrations };
        }} renderAlternate={({ busy, errors }) => <MachineTargetNewLayout busy={busy} errors={errors} spaceId={props.spaceId}/>} renderWhenLoaded={(loadedData) => <MachineTargetNewWhenLoaded registrations={loadedData.registrations} environmentId={props.environmentId} spaceId={props.spaceId}/>}/>);
}
interface MachineTargetNewWhenLoadedProps {
    registrations: EndpointRegistration[];
    environmentId: string | undefined;
    spaceId: string;
}
function MachineTargetNewWhenLoaded({ registrations, environmentId, spaceId }: MachineTargetNewWhenLoadedProps) {
    const navigate = useNavigateToDeploymentTarget(spaceId, environmentId);
    const categories = endpointRegistry.categorizeEndpoints(registrations);
    return (<MachineTargetNewLayout spaceId={spaceId}>
            <EndpointSelector spaceId={spaceId} heading={<Heading />} onSelect={navigate} scope={EndpointSelectionScope.DeploymentTarget} registrations={registrations} categories={categories} defaultCategory={KubernetesCategoryDefinition.category}/>
        </MachineTargetNewLayout>);
}
function MachineTargetNewLayout({ busy, errors, spaceId, children }: PropsWithChildren<{
    busy?: boolean;
    errors?: Errors;
    spaceId: string;
}>) {
    return (<PaperLayoutVNext busy={busy} errors={errors} title="Add Deployment Target" breadcrumbsItems={[{ label: "Deployment Targets", pageUrl: links.deploymentTargetsPage.generateUrl({ spaceId }) }]}>
            {children}
        </PaperLayoutVNext>);
}
function Heading() {
    return (<>
            What type of <strong>Deployment Target</strong> do you want to set up? Learn more about <ExternalLink href="DeploymentTargets">Deployment Targets</ExternalLink>
        </>);
}
function isStepPackageDeploymentTarget(obj: EndpointRegistration): obj is StepPackageDeploymentTargetRegistration {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (obj as StepPackageDeploymentTargetRegistration).targetType !== undefined;
}
export function useNavigateToDeploymentTarget(spaceId: string, environmentId: string | undefined) {
    const navigation = useSpaceAwareNavigation();
    return React.useCallback((registration: CategorizedEndpointRegistration, category: CategoryDefinition) => {
        if (endpointRegistry.isMachineRegistration(registration) && registration.discoverable) {
            if (environmentId)
                return navigation.navigate(links.discoverMachineWithEnvironmentPage.generateUrl({ spaceId, endpointKey: registration.key, environmentId }, { category: category.category }));
            else
                return navigation.navigate(links.discoverMachinePage.generateUrl({ spaceId, endpointKey: registration.key }, { category: category.category }));
        }
        else {
            if (isStepPackageDeploymentTarget(registration))
                return navigation.navigate(links.createDeploymentTargetPage.generateUrl({ spaceId }, { type: registration.communicationStyle, deploymentTargetTypeId: registration.key, environment: environmentId }));
            else
                return navigation.navigate(links.createDeploymentTargetPage.generateUrl({ spaceId }, { type: registration.communicationStyle, environment: environmentId }));
        }
    }, [environmentId, navigation, spaceId]);
}
