/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Tooltip, Callout } from "@octopusdeploy/design-system-components";
import type { PageAction } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ResourceCollection, OctopusPackageVersionBuildInformationMappedResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import DeleteDialog from "~/components/Dialog/DeleteDialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import PagingDataTable from "~/components/PagingDataTable";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import { Text } from "~/components/form";
import DateFormatter from "~/utils/DateFormatter";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import styles from "./style.module.less";
class BuildInformationDataTable extends PagingDataTable<OctopusPackageVersionBuildInformationMappedResource> {
}
interface BuildInformationVersionsPageProps {
    spaceId: string;
    packageId: string;
}
interface BuildInformationVersionsPageInternalProps extends BuildInformationVersionsPageProps {
    deleteDialogControls: DialogControls;
}
interface BuildInformationVersionsPageState extends DataBaseComponentState {
    packagesResponse?: ResourceCollection<OctopusPackageVersionBuildInformationMappedResource>;
    packagesToDelete: string[];
    isSure: boolean;
    confirmationInputValue: string;
}
class BuildInformationVersionsPageInternal extends DataBaseComponent<BuildInformationVersionsPageInternalProps, BuildInformationVersionsPageState> {
    private packageId: string;
    private isBuildInfoAdmin = isAllowed({ permission: Permission.BuildInformationAdminister, project: "*" });
    constructor(props: BuildInformationVersionsPageInternalProps) {
        super(props);
        this.packageId = this.props.packageId;
        this.state = {
            packagesToDelete: [],
            isSure: false,
            confirmationInputValue: "",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.load(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async load() {
        const packagesResponse = await repository.BuildInformationRepository.list({ packageId: this.packageId, take: 30 });
        this.setState({ packagesResponse });
    }
    async delete() {
        await this.doBusyTask(async () => {
            await repository.BuildInformationRepository.deleteMany(this.state.packagesToDelete);
            await this.load();
        });
        return true;
    }
    render() {
        if (this.state.packagesResponse && this.state.packagesResponse.Items.length === 0) {
            return <InternalRedirect to={links.buildInformationOverviewPage.generateUrl({ spaceId: this.props.spaceId })}/>;
        }
        const sideBar = <p>Build information is snapshotted with releases. Deleting build information makes it unavailable for new releases.</p>;
        const additionalRequestParams = new Map<string, string>();
        additionalRequestParams.set("packageId", this.packageId);
        const table = this.state.packagesResponse && this.state.packagesResponse.Items.length > 0 ? (<BuildInformationDataTable initialData={this.state.packagesResponse} onRow={this.buildRow} apiSearchParams={["filter"]} additionalRequestParams={additionalRequestParams} filterSearchEnabled={true} onFilter={this.filter} headerColumns={["Version", "Created", "Branch", "Build"]} onEmpty={this.handleOnEmpty} rowColumnClassName={styles.packageVersionCell} onItemsChecked={this.isBuildInfoAdmin ? (packagesToDelete: string[]) => this.setState({ packagesToDelete }) : null!}/>) : null;
        return (<LibraryLayout spaceId={this.props.spaceId}>
                <PaperLayoutVNext title={this.packageId} breadcrumbsItems={[{ label: "Build Information", pageUrl: links.buildInformationOverviewPage.generateUrl({ spaceId: this.props.spaceId }) }]} pageActions={[this.getDeletePageAction()]} busy={this.state.busy} errors={this.errors}>
                    {this.renderDeleteDialog()}
                    <SidebarLayout sideBar={sideBar}>{table}</SidebarLayout>
                </PaperLayoutVNext>
            </LibraryLayout>);
    }
    private filter(filter: string, resource: OctopusPackageVersionBuildInformationMappedResource) {
        return !filter || filter.length === 0 || (resource.PackageId ? resource.PackageId.toLowerCase().includes(filter.toLowerCase()) : false) || (resource.Version ? resource.Version.toLowerCase().includes(filter.toLowerCase()) : false);
    }
    private handleOnEmpty = () => {
        return <div>No packages found</div>;
    };
    private getDeletePageAction = (): PageAction => {
        const selectedPackageCount = this.state.packagesToDelete.length;
        return {
            type: "button",
            buttonType: "destructive",
            hasPermissions: isAllowed({ permission: Permission.BuiltInFeedAdminister, project: "*" }),
            label: "Delete selected",
            disabled: !this.state.packagesResponse || selectedPackageCount === 0,
            onClick: this.props.deleteDialogControls.openDialog,
        };
    };
    private renderDeleteDialog = () => {
        const message = `Are you sure you want to delete this build information?`;
        const isSure = this.state.isSure;
        return (<DeleteDialog deleteButtonDisabled={!isSure} title={message} open={this.props.deleteDialogControls.isOpen} onClose={() => {
                this.props.deleteDialogControls.closeDialog();
                this.setState({ confirmationInputValue: "", isSure: false });
            }} onDeleteClick={() => this.delete()} renderContent={() => (<>
                        <Callout title="This is a destructive action" type={"danger"}>
                            This action <strong>cannot</strong> be undone.
                        </Callout>
                        <div>
                            <p>
                                Please type in <strong>Delete</strong> to confirm.
                            </p>
                            <Text value={this.state.confirmationInputValue} onChange={this.confirmationOnChange}/>
                        </div>
                    </>)}/>);
    };
    private confirmationOnChange = (value: string) => {
        let isSure = false;
        if (value === "Delete") {
            isSure = true;
        }
        this.setState({ confirmationInputValue: value, isSure });
    };
    private buildRow = (resource: OctopusPackageVersionBuildInformationMappedResource) => {
        return [
            <div className={styles.row}>
                <InternalLink to={links.buildInformationDetailPage.generateUrl({ spaceId: this.props.spaceId, id: resource.Id })}>{resource.Version}</InternalLink>
            </div>,
            <Tooltip content={DateFormatter.dateToLongFormat(resource.Created)!}>{DateFormatter.dateToShortFormat(resource.Created)}</Tooltip>,
            !resource.Branch ? " " : resource.Branch,
            <ExternalLink href={resource.BuildUrl}>{resource.BuildNumber}</ExternalLink>,
        ].filter((c) => !!c);
    };
    static displayName = "BuildInformationVersionsPageInternal";
}
export function BuildInformationVersionsPage(props: BuildInformationVersionsPageProps) {
    const deleteDialogControls = useDialogTrigger();
    return <BuildInformationVersionsPageInternal {...props} deleteDialogControls={deleteDialogControls}/>;
}
