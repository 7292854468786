import type { GitDependencyReference, ActionProperties, ActionTemplateParameterResource, PackageReference, StepPackageInputs } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useAccountsFromContext, useRefreshAccountsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessAccountsContextProvider";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { useGitCredentialsFromContext, useRefreshGitCredentialsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import { ActionPropertiesEditor } from "~/components/ActionPropertiesEditor/ActionPropertiesEditor";
import { enforceNewActionFeatures } from "~/components/ActionPropertiesEditor/enforceNewActionFeatures";
import type { ActionEditProps, ActionPlugin } from "~/components/Actions/pluginRegistry";
import type { ActionTemplateStepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { FieldErrors } from "../DataBaseComponent/Errors";
interface ActionTemplateEditorInternalProps {
    localNames: string[];
    plugin: ActionPlugin;
    inputs?: StepPackageInputs;
    properties: ActionProperties;
    packages: Array<PackageReference>;
    gitDependencies?: GitDependencyReference[];
    errors: FieldErrors | undefined; // only used for shouldComponentUpdate
    busy: Promise<unknown> | boolean | undefined;
    expandedByDefault: boolean;
    parameters: ActionTemplateParameterResource[];
    getFieldError(field: string): string;
    setInputs(inputs: StepPackageInputs, callback?: () => void): void;
    setProperties(properties: Partial<ActionProperties>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference>, initialise?: boolean): void;
    setGitDependencies?(gitDependencies: Array<GitDependencyReference>): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    isNew: boolean;
}
const MergedSectionsPluginEditor: React.FC<ActionEditProps> = (actionEditProps: ActionEditProps) => {
    const { top: TopSection, default: DefaultSection } = actionEditProps.plugin.editSections;
    return (<>
            {TopSection && <TopSection {...actionEditProps}/>}
            {DefaultSection && <DefaultSection {...actionEditProps}/>}
        </>);
};
MergedSectionsPluginEditor.displayName = "MergedSectionsPluginEditor"
export function ActionTemplatePropertiesEditor(props: ActionTemplateEditorInternalProps) {
    const { packages, setPackages, parameters, localNames, gitDependencies, setGitDependencies } = props;
    useEffect(() => {
        const properties = { ...props.properties };
        const enabledFeatures = enforceNewActionFeatures(props.plugin, props.properties, props.isNew);
        if (!!enabledFeatures) {
            // Server will strip empty properties, so only set this if we need to, otherwise this triggers our save-changes dialog unnecessarily.
            properties["Octopus.Action.EnabledFeatures"] = enforceNewActionFeatures(props.plugin, props.properties, props.isNew);
        }
        props.setProperties(properties, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const accounts = useAccountsFromContext();
    const refreshAccounts = useRefreshAccountsFromContext();
    const gitCredentials = useGitCredentialsFromContext();
    const refreshGitCredentials = useRefreshGitCredentialsFromContext();
    const stableSetPackages = useCallback((getUpdatedPackages: (prev: Array<PackageReference>) => Array<PackageReference>) => setPackages(getUpdatedPackages(packages)), [packages, setPackages]);
    const stableSetGitDependencies = useCallback((getUpdatedGitDependencies: (prev: GitDependencyReference[]) => GitDependencyReference[]) => setGitDependencies?.(getUpdatedGitDependencies(gitDependencies ?? [])), [gitDependencies, setGitDependencies]);
    const stableRefreshFeeds = useCallback(async () => {
        await refreshFeeds();
    }, [refreshFeeds]);
    const stableRefreshAccounts = useCallback(async () => {
        await refreshAccounts();
    }, [refreshAccounts]);
    const stableRefreshGitCredentials = useCallback(async () => {
        await refreshGitCredentials();
    }, [refreshGitCredentials]);
    const dependencies = useMemo<ActionTemplateStepInputDependencies>(() => ({
        feeds: feeds,
        refreshFeeds: stableRefreshFeeds,
        parameters: parameters,
        setPackages: stableSetPackages,
        setGitDependencies: stableSetGitDependencies,
        localNames: localNames,
        accounts,
        refreshAccounts: stableRefreshAccounts,
        gitCredentials: gitCredentials,
        refreshGitCredentials: stableRefreshGitCredentials,
    }), [feeds, stableRefreshFeeds, parameters, stableSetPackages, stableSetGitDependencies, localNames, accounts, stableRefreshAccounts, gitCredentials, stableRefreshGitCredentials]);
    if (!props.plugin.editSections) {
        throw new Error(`Plugin ${props.plugin.actionType} is missing 'editSections' property`);
    }
    return (<ActionPropertiesEditor inputDependencies={dependencies} plugin={props.plugin} editor={MergedSectionsPluginEditor} properties={props.properties} packages={packages} gitDependencies={gitDependencies} doBusyTask={props.doBusyTask} busy={props.busy} inputs={props.inputs} setInputs={props.setInputs} setProperties={props.setProperties} setGitDependencies={props.setGitDependencies} setPackages={setPackages} getFieldError={props.getFieldError} errors={props.errors} expandedByDefault={props.expandedByDefault}/>);
}
