/* eslint-disable no-eq-null */
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { ResourceCollection, UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type { ReactNode } from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import List from "~/components/PagingList";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Avatar from "~/primitiveComponents/dataDisplay/Avatar";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/ListTitle";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { InviteOctoIdUserDialogLink } from "./InviteOctoIdUserDialogLink";
import { isOctopusIdEnabled } from "./identityProviders";
import styles from "./style.module.less";
interface UsersPageState extends DataBaseComponentState {
    usersResponse?: ResourceCollection<UserResource>;
    isOctoIdEnabled?: boolean;
}
class UserList extends List<UserResource> {
}
export class UsersPage extends DataBaseComponent<{}, UsersPageState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const usersResponse = await repository.Users.list();
            this.setState({ usersResponse, isOctoIdEnabled: await isOctopusIdEnabled() });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    render() {
        const [primaryPageAction, pageActions] = this.createUserPageActions();
        return (<PaperLayoutVNext title="Users" busy={this.state.busy} errors={this.errors} primaryAction={primaryPageAction} pageActions={pageActions}>
                {this.state.usersResponse && (<UserList initialData={this.state.usersResponse} onRow={(item: UserResource) => this.buildUserRow(item)} onRowRedirectUrl={(item: UserResource) => links.editUserPage.generateUrl({ userId: item.Id })} onFilter={this.filter} filterSearchEnabled={true} apiSearchParams={["filter"]} filterHintText="Filter by user..."/>)}
            </PaperLayoutVNext>);
    }
    private createUserPageActions(): [
        PrimaryPageAction | undefined,
        PageAction[]
    ] {
        const isOctoIdEnabled = this.state.isOctoIdEnabled;
        if (isOctoIdEnabled === undefined) {
            return [undefined, []];
        }
        const addUserPrimaryPageAction: PrimaryPageAction = {
            type: "navigate",
            hasPermissions: isAllowed({ permission: Permission.UserEdit }),
            label: "Add Users",
            path: links.createUserPage.generateUrl(),
        };
        const createServiceAccountPageAction: PageAction = {
            type: "navigate",
            buttonType: "secondary",
            hasPermissions: isAllowed({ permission: Permission.UserEdit }),
            label: "Create Service Account",
            path: links.createUserPage.generateUrl(),
        };
        const inviteUserPrimaryPageAction: PrimaryPageAction = {
            type: "custom",
            hasPermissions: isAllowed({ permission: Permission.UserEdit }),
            key: "Invite User",
            content: <InviteOctoIdUserDialogLink label="Invite User"/>,
        };
        return isOctoIdEnabled ? [inviteUserPrimaryPageAction, [createServiceAccountPageAction]] : [addUserPrimaryPageAction, []];
    }
    private buildUserRow(user: UserResource): ReactNode {
        return (<div className={styles.row}>
                <div className={styles.avatar}>
                    <Avatar avatarLink={user && user.Links && user.Links.Avatar} isService={user && user.IsService} size={40}/>
                </div>
                <div>
                    <ListTitle>{user.DisplayName}</ListTitle>
                    <div>{user.EmailAddress || user.Username}</div>
                </div>
            </div>);
    }
    private filter(filter: string, resource: UserResource) {
        return (!filter ||
            filter.length === 0 ||
            !resource ||
            resource.Username.toLowerCase().includes(filter.toLowerCase()) ||
            (resource.EmailAddress && resource.EmailAddress.toLowerCase().includes(filter.toLowerCase())) ||
            resource.DisplayName.toLowerCase().includes(filter.toLowerCase()));
    }
    static displayName = "UsersPage";
}
