import * as React from "react";
import { ShellNameChip } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
interface ShellNameMultiSelectProps extends FormFieldProps<string[]> {
    items: string[];
    openOnFocus?: boolean;
    autoFocus?: boolean;
    label?: string | JSX.Element;
    accessibleName?: string;
    error?: string;
    hideFloatingLabel?: boolean;
    validate?(value: string[]): string;
}
const ShellNameTypedMultiSelect = MultiSelect<SelectItem>();
export const ShellNameMultiSelect: React.FC<ShellNameMultiSelectProps> = (props) => {
    const chipRenderer = (r: SelectItem, onRequestDelete: () => void) => {
        return <ShellNameChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${r.Name}`} shellName={r.Name}/>;
    };
    return (<ShellNameTypedMultiSelect items={props.items.map((i) => ({ Id: i, Name: i }))} fieldName="CLI/shell" label={props.label} renderChip={chipRenderer} value={props.value} onChange={props.onChange} error={props.error} openOnFocus={props.openOnFocus} autoFocus={props.autoFocus} hideFloatingLabel={props.hideFloatingLabel} accessibleName={props.accessibleName}/>);
};
ShellNameMultiSelect.displayName = "ShellNameMultiSelect"
