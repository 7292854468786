import { css } from "@emotion/css";
import { DeploymentFreezeStatus } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import DateFormatter from "~/utils/DateFormatter/index";
interface DeploymentFreezeDataTableNameCellProps {
    id: string;
    name: string;
    start: string;
    end: string;
    status: DeploymentFreezeStatus;
}
export const DeploymentFreezeDataTableNameCell = ({ id, name, start, end, status }: DeploymentFreezeDataTableNameCellProps) => {
    const dateString = () => {
        switch (status) {
            case DeploymentFreezeStatus.Active:
                return `Will end on ${DateFormatter.dateToLongFormat(end)}`;
            case DeploymentFreezeStatus.Scheduled:
                return `Will start on ${DateFormatter.dateToLongFormat(start)}`;
            case DeploymentFreezeStatus.Expired:
                return `Ended on ${DateFormatter.dateToLongFormat(end)}`;
        }
    };
    return (<DataTableRowColumn className={cellStyles}>
            <div className={nameCellStyles}>
                <InternalLink size={1.25} to={links.deploymentFreezesEditPage.generateUrl({ deploymentFreezeId: id })}>
                    <div className={nameStyles}>{name}</div>
                </InternalLink>
                <div className={dateStyles}>{dateString()}</div>
            </div>
        </DataTableRowColumn>);
};
const nameCellStyles = css({
    padding: "1rem 0.5rem",
});
const nameStyles = css({
    paddingBottom: "0.5rem",
});
const dateStyles = css({
    fontSize: "12px",
});
const cellStyles = css({ width: "35%", overflowWrap: "anywhere" });
