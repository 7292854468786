import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface DataTableBodyProps {
    className?: string;
    children?: React.ReactNode;
}
export class DataTableBody extends React.Component<DataTableBodyProps, never> {
    render() {
        return <tbody className={cn(this.props.className, styles.dataTableBody)}>{this.props.children}</tbody>;
    }
    static displayName = "DataTableBody";
}
