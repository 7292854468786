import { Callout, RadioButton, RadioButtonGroup, Tooltip } from "@octopusdeploy/design-system-components";
import type { GitRef, ProjectResource } from "@octopusdeploy/octopus-server-client";
import { toGitBranch } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { ExternalGitRefSelector } from "~/areas/projects/components/GitRefSelector/ExternalGitRefSelector";
import styles from "~/areas/projects/components/Releases/Edit/style.module.less";
import { useSelectGitRefOnCreateRelease } from "~/areas/projects/components/Releases/Edit/useSelectGitRefOnCreateRelease";
import { GitRefChip } from "~/areas/projects/components/Releases/GitRefChip/GitRefChip";
import { FilesList } from "~/areas/projects/components/Releases/GitResources/FilesList";
import type { GitReferenceEditInfo } from "~/areas/projects/components/Releases/gitResourceModel";
import { GitReferenceType } from "~/areas/projects/components/Releases/gitResourceModel";
import { GitResourceReleaseSelectionContextualHelp } from "~/components/ContextualHelp/ContextualHelpSnippets";
import { withTheme } from "~/components/Theme/index";
import WarningIcon from "~/components/WarningIcon/index";
import { CardFill } from "~/components/form/Sections/ExpandableFormSection";
import { ExpandableFormSection, Summary } from "~/components/form/index";
import type { DataTableRowProps } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
type GitReferenceDataTableRowProps = PropsWithChildren<DataTableRowProps & {
    accessibleName: string;
}>;
const GitResourceDataTableRow = (props: GitReferenceDataTableRowProps) => {
    const { accessibleName, ...otherProps } = props;
    const accessibilityProps = {
        role: "radiogroup",
        "aria-label": accessibleName,
    };
    return (<DataTableRow {...otherProps} {...accessibilityProps}>
            {props.children}
        </DataTableRow>);
};
type GitResourceSectionProps = {
    project: Readonly<ProjectResource>;
    context: "Release" | "Runbook";
    gitResources: GitReferenceEditInfo[];
    onUpdateGitRef: (gitRef: GitReferenceEditInfo) => void;
    channelId?: string;
    releaseId?: string;
    runbookId?: string;
    runbookSnapshotId?: string;
    showLastGitResource?: boolean;
    actionsThatViolateGitResourceRules?: Set<string>;
};
export const GitResourcesSection = (props: GitResourceSectionProps) => {
    const selectGitRefEnabled = useSelectGitRefOnCreateRelease();
    const showLastGitResource = props.gitResources && props.gitResources.some((gr) => gr.LastGitResource !== null) && props.showLastGitResource;
    const unresolvableResources = props.gitResources && props.gitResources.filter((r) => !r.IsResolvable);
    const buildGitResourceRadioButton = (gitRef: GitReferenceEditInfo, type: GitReferenceType, label: React.ReactNode) => (<RadioButtonGroup className={styles.radioButtonContainer} value={gitRef.GitReferenceType} onChange={(newValue) => {
            if (type === GitReferenceType.specific)
                gitRef.SpecificGitResource = { GitRef: toGitBranch(gitRef.DefaultBranch) };
            gitRef.GitReferenceType = newValue;
            props.onUpdateGitRef(gitRef);
        }}>
            <RadioButton accessibleName={`git-${type}`} className={styles.myRadioButton} value={type} label={label}/>
        </RadioButtonGroup>);
    const getSummary = () => {
        if (!props.gitResources || props.gitResources.length === 0) {
            return Summary.placeholder("No Git references included");
        }
        if (props.gitResources.length === 1) {
            return Summary.summary("1 Git reference included");
        }
        return Summary.summary(`${props.gitResources.length} Git references included`);
    };
    const onSpecificRefChange = (gitRef: GitReferenceEditInfo, ref: GitRef) => {
        gitRef.GitReferenceType = GitReferenceType.specific;
        gitRef.SpecificGitResource = {
            GitRef: ref,
        };
        props.onUpdateGitRef(gitRef);
    };
    return withTheme((theme) => (<ExpandableFormSection errorKey="gitResources" title="Git Resources" fillCardWidth={CardFill.FillAll} summary={getSummary()} help={`Select Git reference(s) for this ${props.context === "Release" ? "release" : "snapshot"}`} isExpandedByDefault={unresolvableResources && unresolvableResources.length > 0} chip={props.actionsThatViolateGitResourceRules && props.actionsThatViolateGitResourceRules.size > 0 ? (<Tooltip content={<>
                                Channel Git resource rules are not satisfied by: <strong>{[...props.actionsThatViolateGitResourceRules].join(", ")}.</strong>
                            </>}>
                        <WarningIcon />
                    </Tooltip>) : undefined}>
            <div className={styles.gitReferenceTableContainer}>
                {unresolvableResources.length > 0 && (<Callout type={"danger"} title="Unresolvable variables">
                        One or more steps with Git resources contain variables which are not resolvable at release creation:
                        <ul className={styles.unorderedList}>
                            {unresolvableResources.map((r) => (<li key={r.ActionName}>{r.ActionName}</li>))}
                        </ul>
                    </Callout>)}
                <DataTable className={styles.gitReferenceTable}>
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn>Step Repository</DataTableHeaderColumn>
                            {showLastGitResource && <DataTableHeaderColumn>Last</DataTableHeaderColumn>}
                            <DataTableHeaderColumn>
                                <span>Specific </span> <GitResourceReleaseSelectionContextualHelp />
                            </DataTableHeaderColumn>
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        {props.gitResources &&
            props.gitResources.map((r, ix) => (<GitResourceDataTableRow key={r.ActionName + ix} accessibleName={r.ActionName + ix}>
                                    <DataTableRowColumn key={r.ActionName + ix} className={cn(styles.repositoryColumn)}>
                                        <div className={styles.actionName}>
                                            {r.ActionName}
                                            {!!r.GitResourceReferenceName && <span>/{r.GitResourceReferenceName}</span>}
                                        </div>
                                        <div className={styles.repoUri}>{r.RepositoryUri}</div>
                                        {r.FilePathFilters?.length > 0 && <FilesList filePaths={r.FilePathFilters} actionName={r.ActionName}/>}
                                    </DataTableRowColumn>
                                    {showLastGitResource && (<DataTableRowColumn key={`rowForLast${ix}`} className={cn(styles.lastColumn)}>
                                            {r.LastGitResource && buildGitResourceRadioButton(r, GitReferenceType.last, <GitRefChip key={`${r.LastGitResource.GitRef} ${r.LastGitResource.GitCommit} ${ix}`} vcsRef={r.LastGitResource}></GitRefChip>)}
                                        </DataTableRowColumn>)}
                                    <DataTableRowColumn key={`rowForSpecific${ix}`} className={cn(styles.specificColumn)}>
                                        {buildGitResourceRadioButton(r, GitReferenceType.specific, selectGitRefEnabled ? (<ExternalGitRefSelector projectId={props.project.Id} actionName={r.ActionName} channelId={props.channelId} releaseId={props.releaseId} runbookId={props.runbookId} runbookSnapshotId={props.runbookSnapshotId} gitDependencyName={r.GitResourceReferenceName} initialGitRef={r.SpecificGitResource.GitRef ?? r.DefaultBranch} onChange={(ref: GitRef) => onSpecificRefChange(r, ref)} allowBranchCreation={false} defaultBranchName={r.DefaultBranch}/>) : (<>
                                                    <em className="fa-solid fa-code-branch" style={{ color: theme.iconNeutral, fontSize: "0.850rem" }}/>
                                                    <span className={cn(styles.specificBranchOrTag)}>{r.DefaultBranch}</span>
                                                </>))}
                                    </DataTableRowColumn>
                                </GitResourceDataTableRow>))}
                    </DataTableBody>
                </DataTable>
            </div>
        </ExpandableFormSection>));
};
