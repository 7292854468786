import * as React from "react";
import FormSectionHeading from "~/components/form/Sections/FormSectionHeading";
import styles from "./style.module.less";
interface StyleGuideEntryProps {
    heading: string;
    context?: React.ReactNode;
}
export default class StyleGuideEntry extends React.Component<StyleGuideEntryProps> {
    constructor(props: StyleGuideEntryProps) {
        super(props);
    }
    render() {
        return (<li className={styles.entry} id={this.props.heading}>
                <FormSectionHeading title={this.props.heading}/>
                {this.props.context && <span className={styles.context}>{this.props.context}</span>}
                <div className={styles.content}>{this.props.children}</div>
            </li>);
    }
    static displayName = "StyleGuideEntry";
}
