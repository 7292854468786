import type { ProcessType } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMaybeProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { useProjectContext } from "~/areas/projects/context";
import type { Errors } from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
import { getCommonOverflowMenuItems } from "./Common/CommonProcessHelpers";
import { useMaybeLoadedActionTemplatesFromContext } from "./Contexts/ProcessActionTemplatesContextProvider";
import { useProcessContext } from "./Contexts/ProcessContext";
import { useProcessErrorActions } from "./Contexts/ProcessErrors/ProcessErrorsContext";
import { useFeedsFromContext } from "./Contexts/ProcessFeedsContextProvider";
import { useProcessWarningActions } from "./Contexts/ProcessWarnings/ProcessWarningsContext";
import { EnhancedProcessContextPaperLayout } from "./CustomPaperLayouts/ProcessContextPaperLayout";
import ProcessList from "./ProcessList";
import type { ProcessListLayoutLoaderLookupData } from "./ProcessListLayoutLoader";
interface ProcessPageProps {
    processType: ProcessType;
    lookups: ProcessListLayoutLoaderLookupData;
    errors?: Errors;
    busy?: boolean;
    doBusyTask: DoBusyTask;
    isBuiltInWorkerEnabled: boolean;
}
const ProcessListLayout: React.FC<ProcessPageProps> = (props) => {
    const location = useLocation();
    const history = useHistory();
    const processContext = useProcessContext();
    const { state: { model: project, projectContextRepository }, actions: projectContextActions, } = useProjectContext();
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    const errorActions = useProcessErrorActions();
    const warningActions = useProcessWarningActions();
    const feeds = useFeedsFromContext();
    const actionTemplates = useMaybeLoadedActionTemplatesFromContext();
    const blueprints = useMaybeProcessBlueprintsFromContext();
    const hasLoadedNecessaryLookupData = (): boolean => {
        const feedViewPermissionGranted = isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true });
        return actionTemplates !== "NotLoaded" && (!feedViewPermissionGranted || (feedViewPermissionGranted && feeds && feeds.length > 0)) && blueprints !== "NotLoaded";
    };
    if (!processContext.selectors.hasValidProcess() || !hasLoadedNecessaryLookupData()) {
        return <EnhancedProcessContextPaperLayout processType={props.processType} busy={props.busy} errors={props.errors} disableAnimations={true} doBusyTask={props.doBusyTask}/>;
    }
    const runbook = runbookContext?.state?.runbook;
    const legacyOverflowMenuItems = getCommonOverflowMenuItems(projectContextRepository, project, projectContext.state.gitRef, runbook, props.processType, processContext.selectors, processContext.actions, projectContextActions, errorActions, warningActions, undefined);
    const convertedOverflowMenuItems = OverflowMenuConverterVNext.convertAll(legacyOverflowMenuItems);
    const { busy, errors, lookups } = props;
    return (<EnhancedProcessContextPaperLayout processType={props.processType} busy={props.busy} errors={props.errors} overflowActions={convertedOverflowMenuItems.menuItems} doBusyTask={props.doBusyTask}>
            {convertedOverflowMenuItems.dialogs}
            <ProcessList lookups={lookups} busy={busy} errors={errors} doBusyTask={props.doBusyTask} location={location} history={history}/>
        </EnhancedProcessContextPaperLayout>);
};
ProcessListLayout.displayName = "ProcessListLayout"
export default React.memo(ProcessListLayout);
