/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React from "react";
import type { ActionCreatorsMapObject } from "redux";
import type { ActionCreator } from "typesafe-actions";
export type InferredBoundAction<T> = T extends ActionCreator<infer TAction> ? <TArgs extends Parameters<T>>(...args: TArgs) => void : never;
export type BoundActionCreatorsMap<T extends ActionCreatorsMapObject<TMap>, TMap> = {
    [K in keyof T]: InferredBoundAction<T[K]>;
};
export const bindActionsToDispatch = <TActions extends ActionCreatorsMapObject<T>, T>(dispatch: React.Dispatch<T>, actionsToBind: TActions): BoundActionCreatorsMap<TActions, T> => {
    return Object.keys(actionsToBind).reduce<BoundActionCreatorsMap<TActions, T>>((prev, current: keyof TActions) => {
        return {
            ...prev,
            [current]: (...args: Array<TActions[typeof current]>) => {
                const creator = actionsToBind[current];
                const action = creator(...args);
                dispatch(action);
            },
        };
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any);
};
export const useBoundDispatch = <TActions extends ActionCreatorsMapObject<T>, T>(dispatch: React.Dispatch<T>, actionsToBind: TActions) => {
    return React.useMemo(() => bindActionsToDispatch(dispatch, actionsToBind), [dispatch, actionsToBind]);
};
