import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { GitBranchResource, GitRef } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, toGitBranch } from "@octopusdeploy/octopus-server-client";
import { ProjectGitRefSelector } from "app/areas/projects/components/GitRefSelector/ProjectGitRefSelector";
import React from "react";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import { useProjectContext } from "~/areas/projects/context";
import { Note } from "~/components/form";
interface Props {
    value?: GitRef;
    onChange: (gitRef: GitRef) => void;
    canResetToDefaultBranch?: boolean;
    disabled?: boolean;
}
const GitRefFormSection: React.FC<Props> = ({ disabled, value, onChange, canResetToDefaultBranch }) => {
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const dispatchAction = useAnalyticSimpleActionDispatch();
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const defaultBranch: GitRef = toGitBranch(project.PersistenceSettings.DefaultBranch);
    const [gitRef, setGitRef] = React.useState<GitRef>(value ?? defaultBranch);
    const setValue = (value: GitRef) => {
        setGitRef(value);
        onChange(value);
    };
    const handleOnClick = () => {
        dispatchAction("Reset to Default Branch");
        setValue(defaultBranch);
    };
    const onCreateBranch = async (newBranchName: string, parentRef: GitRef): Promise<GitBranchResource> => await projectContext.state.projectContextRepository.Branches.createBranch(project, newBranchName, parentRef);
    if (!HasGitPersistenceSettings(project.PersistenceSettings))
        throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    return (<>
            <ProjectGitRefSelector style={"white"} key={`${gitRef}`} initialGitRef={gitRef} onCreateBranch={onCreateBranch} onChange={setValue} project={projectContext.state.model} disabled={disabled}/>
            {canResetToDefaultBranch && (<>
                    <ActionButton label={`Reset to ${project.PersistenceSettings.DefaultBranch} (default) branch`} type={ActionButtonType.Ternary} disabled={gitRef === defaultBranch || disabled} onClick={handleOnClick}/>
                    <Note>This release will snapshot the deployment process from the head of the branch selected.</Note>
                </>)}
        </>);
};
GitRefFormSection.displayName = "GitRefFormSection"
export default GitRefFormSection;
