import type { Dictionary } from "lodash";
import { groupBy } from "lodash";
export default function groupVariablesByName<TVariable>(variableResources: ReadonlyArray<TVariable>, getName: (variable: TVariable) => string): Dictionary<TVariable[]> {
    const variablesByNormalizedName = groupBy([...variableResources], (v) => getName(v).toLowerCase());
    return Object.keys(variablesByNormalizedName)
        .map((normalizedName) => {
        const variables = variablesByNormalizedName[normalizedName];
        // This allows us to preserve original name for variables with single value
        const name = getName(variables[0]);
        return { name, variables };
    })
        .reduce<Dictionary<TVariable[]>>((dict, groupedVariables) => {
        // Don't refactor this `reduce` to a single line with spread operator;
        // It causes an O(n^2) (see https://prateeksurana.me/blog/why-using-object-spread-with-reduce-bad-idea/)
        dict[groupedVariables.name] = groupedVariables.variables;
        return dict;
    }, {});
}
