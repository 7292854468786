import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonInfrastructureProxyOverview } from "../../CommonComponents/CommonInfrastructureProxyViews";
export const InfrastructureProxyRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.infrastructure.proxy,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonInfrastructureProxyOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
