import type { LicenseStatusResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { useState } from "react";
import isWithinLicenceLimit from "~/areas/configuration/components/License/isWithinLicenceLimit";
import { kubernetesAgentEndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgentEndpoint";
import kubernetesCategoryDefinition from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesCategoryDefinition";
import linuxCategoryDefinition from "~/areas/infrastructure/components/MachineSettings/Endpoints/LinuxCategoryDefinition";
import type { CategorizedEndpointRegistration, CategoryDefinition, EndpointRegistration } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import endpointRegistry, { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { useKubernetesAgentAsWorker } from "~/areas/infrastructure/components/WorkerMachineSettings/useKubernetesAgentAsWorker";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import DataLoader from "~/components/DataLoader";
import Dialog from "~/components/Dialog/Dialog";
import { WorkerLimitReachedDialogLayout } from "~/components/LicenseLimitReachedDialogLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { useSpaceAwareNavigation } from "../../../../components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { EndpointSelector } from "../MachineSettings/Endpoints/EndpointSelector";
const WorkerMachineNewDataLoader = DataLoader<LoadedData>();
interface LoadedData {
    licenseStatus: LicenseStatusResource;
    registrations: EndpointRegistration[];
    defaultCategory: string;
}
export interface WorkerMachineNewProps {
    spaceId: string;
    workerPoolId: string | undefined;
}
export function WorkerMachineNewPage(props: WorkerMachineNewProps) {
    const agentAsAWorkerToggleEnabled = useKubernetesAgentAsWorker();
    return (<WorkerMachineNewDataLoader load={async () => {
            const licenseStatus = repository.Licenses.getCurrentStatus();
            const registrations = endpointRegistry.getAllMachines();
            if (!agentAsAWorkerToggleEnabled) {
                const index = registrations.indexOf(kubernetesAgentEndpointRegistration);
                if (index !== -1) {
                    registrations.splice(index, 1);
                }
            }
            const defaultCategory = agentAsAWorkerToggleEnabled ? kubernetesCategoryDefinition.category : linuxCategoryDefinition.category;
            return {
                registrations,
                licenseStatus: await licenseStatus,
                defaultCategory,
            };
        }} renderAlternate={({ busy, errors }) => <WorkerMachineNewLayout spaceId={props.spaceId} busy={busy} errors={errors}/>} renderWhenLoaded={(loadedData) => <WorkerMachineNewWhenLoaded spaceId={props.spaceId} loadedData={loadedData} workerPoolId={props.workerPoolId}/>}/>);
}
interface WorkerMachineNewWhenLoadedProps {
    spaceId: string;
    loadedData: LoadedData;
    workerPoolId: string | undefined;
}
function WorkerMachineNewWhenLoaded({ loadedData, workerPoolId, spaceId }: WorkerMachineNewWhenLoadedProps) {
    const { licenseStatus, registrations, defaultCategory } = loadedData;
    const navigate = useNavigateToWorker(spaceId, workerPoolId);
    const isWithinWorkerLimit = isWithinLicenceLimit(licenseStatus, "Workers");
    const [isUpgradeDialogOpen, setUpgradeDialogOpen] = useState(!isWithinWorkerLimit);
    const categories = endpointRegistry.categorizeEndpoints(registrations);
    //we default to the linux category definition as default for the workers
    return (<WorkerMachineNewLayout spaceId={spaceId}>
            <EndpointSelector spaceId={spaceId} heading={<Heading />} registrations={registrations} categories={categories} defaultCategory={defaultCategory} onSelect={navigate} scope={EndpointSelectionScope.Worker}/>

            <Dialog open={isUpgradeDialogOpen}>
                <WorkerLimitReachedDialogLayout onActionClick={() => {
            setUpgradeDialogOpen(false);
            window.history.back();
        }}/>
            </Dialog>
        </WorkerMachineNewLayout>);
}
function WorkerMachineNewLayout({ spaceId, busy, errors, children }: PropsWithChildren<{
    spaceId: string;
    busy?: boolean;
    errors?: Errors;
}>) {
    return (<PaperLayoutVNext busy={busy} errors={errors} title="Add Worker" breadcrumbsItems={[{ label: "Workers", pageUrl: links.workerMachinesPage.generateUrl({ spaceId }) }]}>
            {children}
        </PaperLayoutVNext>);
}
function Heading() {
    return (<React.Fragment>
            What type of <strong>Worker</strong> do you want to set up? Learn more about <ExternalLink href="Worker">Workers</ExternalLink>
        </React.Fragment>);
}
function useNavigateToWorker(spaceId: string, workerPoolId: string | undefined) {
    const navigation = useSpaceAwareNavigation();
    return React.useCallback((registration: CategorizedEndpointRegistration, category: CategoryDefinition) => {
        if (endpointRegistry.isMachineRegistration(registration) && registration.discoverable) {
            return navigation.navigate(workerPoolId
                ? links.discoverWorkerMachineWithWorkerPoolPage.generateUrl({ spaceId, workerPoolId, endpointKey: registration.key }, { category: category.category })
                : links.discoverWorkerMachinePage.generateUrl({ spaceId, endpointKey: registration.key }, { category: category.category }));
        }
        else {
            return navigation.navigate(links.createWorkerMachinePage.generateUrl({ spaceId }, { type: registration.communicationStyle, environment: workerPoolId }));
        }
    }, [workerPoolId, navigation, spaceId]);
}
