import type { LinkHref } from "@octopusdeploy/portal-routes";
import { resolvePathWithSpaceId } from "@octopusdeploy/portal-routes";
import { isUrl } from "app/components/Navigation/InternalLink/isUrl";
import { useUrlResolver } from "app/components/Navigation/useUrlResolver";
import type { History } from "history";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Redirect, withRouter } from "react-router";
type LocationDescriptor = History.LocationDescriptor;
interface InternalRedirectComponentProps {
    to: LocationDescriptor | LinkHref;
    push?: boolean;
    exact?: boolean;
    from?: string;
}
type InternalRedirectProps = InternalRedirectComponentProps & RouteComponentProps<{
    spaceId: string;
}>;
const InternalRedirect: React.SFC<InternalRedirectProps> = (props: InternalRedirectProps) => {
    const urlResolver = useUrlResolver();
    const resolvedPath = isUrl(props.to) ? urlResolver.resolve(props.to) : resolvePathWithSpaceId(props.to, props.match.params.spaceId);
    /* eslint-disable react/forbid-elements */
    return <Redirect from={props.from} to={resolvedPath} push={props.push} exact={props.exact}/>;
};
InternalRedirect.displayName = "InternalRedirect"
const EnhancedInternalRedirect = withRouter(InternalRedirect);
const InternalRedirectWithHistory: React.SFC<InternalRedirectProps> = (props) => <EnhancedInternalRedirect push={true} {...props}/>;
InternalRedirectWithHistory.displayName = "InternalRedirectWithHistory"
export default EnhancedInternalRedirect;
export { InternalRedirectWithHistory };
