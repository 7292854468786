/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css, cx } from "@emotion/css";
import { Callout, EmptyStateDefaultIllustration } from "@octopusdeploy/design-system-components";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ChannelResource, CreateReleaseActionResource, DeploymentActionPackageResource, DeploymentProcessResource, EnvironmentResource, EventCategoryResource, EventGroupResource, MachineFilterResource, ProjectResource, ResourceCollection, RunbookResource, RunRunbookActionResource, ScopedDeploymentActionResource, TenantResource, TriggerResource, TriggerScheduleResource, } from "@octopusdeploy/octopus-server-client";
import { Permission, TenantedDeploymentMode, TriggerActionCategory, TriggerActionType, TriggerFilterType, triggerIdIs } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type * as H from "history";
import * as _ from "lodash";
import { flatten } from "lodash";
import * as React from "react";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router";
import { ProjectPaperLayout } from "~/areas/projects/components/ProjectPaperLayout";
import { useProjectContext } from "~/areas/projects/context";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/AdvancedFilterLayout";
import { ChannelChip, matchesToChips } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { EmptyPage } from "~/components/EmptyPage/EmptyPage";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FilterSearchBox from "~/components/FilterSearchBox";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import PagingDataTable from "~/components/PagingDataTable";
import List from "~/components/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout from "~/components/SidebarLayout/SidebarLayout";
import Select from "~/primitiveComponents/form/Select/Select";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import ScheduledTriggerDescriptionHelper from "~/utils/ScheduledTriggerDescriptionHelper";
import RunbooksOnboarding from "../Runbooks/RunbooksOnboarding";
import { AddDeploymentTriggerButton } from "./AddDeploymentTriggerButton";
import { AddProjectTriggerDialogButton } from "./AddProjectTriggerDialog";
import { FeedTriggerDescription, TriggerNameAndLink } from "./Feed/FeedTriggerDescriptionHelper";
import { GitTriggerDescription } from "./Git/GitTriggerDescription";
import ScheduledTrigger from "./Scheduled/ScheduledTrigger";
import SideBar from "./SideBar";
import Trigger from "./Trigger";
import TriggerDescription from "./TriggerDescription";
import { TriggerType } from "./TriggerType";
import styles from "./Triggers.module.less";
import { loadAllTriggerRunbooks } from "./loadAllTriggerRunbooks";
import { GitTriggersFeatureToggleVisibility } from "./useIsGitTriggersEnabled";
interface TriggersState extends DataBaseComponentState {
    project: ProjectResource;
    triggersResponse: ResourceCollection<TriggerResource>;
    environments: EnvironmentResource[];
    categories: EventCategoryResource[];
    groups: EventGroupResource[];
    builtInFeedPackageActions: DeploymentActionPackageResource[];
    deploymentProcess: DeploymentProcessResource;
    builtInPackageRepositoryInUse: boolean;
    showAutomaticReleaseCreation: boolean;
    channels: ChannelResource[];
    open: boolean;
    anchor?: any;
    filter: TriggersFilter;
    isSearching: boolean;
    runbooksInProject: RunbookResource[];
    tenants: TenantResource[];
}
interface TriggersFilter {
    searchText: string;
    showDeploymentTargetTriggers: boolean;
    showScheduledTriggers: boolean;
    showBuiltInRepositoryTriggers: boolean;
    showExternalFeedTriggers: boolean;
    showGitTriggers: boolean;
    runbookId: string;
}
interface GlobalConnectedProps {
    isMultiTenancyEnabled?: boolean;
}
type TriggersInternalProps = {
    history: H.History;
} & TriggersProps & GlobalConnectedProps & WithProjectContextInjectedProps;
class FilterLayout extends AdvancedFilterLayout<TriggersFilter> {
}
class TriggersList extends List<TriggerResource> {
}
class TriggersDataTable extends PagingDataTable<TriggerResource> {
}
class TriggersInternal extends DataBaseComponent<TriggersInternalProps, TriggersState> {
    static defaultProps: Partial<TriggersProps> = {
        triggerActionCategory: TriggerActionCategory.Deployment,
    };
    constructor(props: TriggersInternalProps) {
        super(props);
        this.state = {
            project: null!,
            triggersResponse: null!,
            environments: null!,
            categories: null!,
            deploymentProcess: null!,
            groups: null!,
            builtInFeedPackageActions: null!,
            builtInPackageRepositoryInUse: null!,
            showAutomaticReleaseCreation: null!,
            channels: null!,
            open: false,
            filter: null!,
            isSearching: false,
            runbooksInProject: null!,
            tenants: null!,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const { model: project, projectContextRepository, gitRef } = this.props.projectContext.state;
            const isForDeployments = this.props.triggerActionCategory == TriggerActionCategory.Deployment;
            const isForRunbooks = this.props.triggerActionCategory == TriggerActionCategory.Runbook;
            // We grab the deployment process for the default branch only as that is all that external feed
            // and built-in package triggers support right now. This will need to be updated if/when it is extended.
            // prettier-ignore
            const deploymentProcessPromise = isForDeployments && isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })
                ? projectContextRepository.DeploymentProcesses.getForDefaultBranch()
                : Promise.resolve<DeploymentProcessResource>(null!);
            // prettier-ignore
            const channelsPromise = (isForDeployments) && isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })
                ? repository.Projects.getChannels(project)
                : Promise.resolve<ResourceCollection<ChannelResource>>(null!);
            const builtInFeedPromise = repository.Feeds.getBuiltIn();
            const environmentsPromise = repository.Environments.all();
            const categoriesPromise = repository.Events.categories({ appliesTo: "Machine" });
            const groupsPromise = repository.Events.groups({ appliesTo: "Machine" });
            const triggersPromise = repository.Projects.getTriggers(project, gitRef, 0, 30, undefined, this.props.triggerActionCategory);
            const runbooksPromise = isForRunbooks ? loadAllTriggerRunbooks(project) : Promise.resolve<RunbookResource[]>(null!);
            // prettier-ignore
            const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) && this.props.isMultiTenancyEnabled && project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted
                ? repository.Tenants.all({ projectId: project.Id })
                : Promise.resolve([]);
            const deploymentProcess = await deploymentProcessPromise;
            const channels = await channelsPromise;
            const builtInFeed = await builtInFeedPromise;
            const actions = deploymentProcess && flatten(deploymentProcess.Steps.map((step) => step.Actions)).filter((a) => !a.IsDisabled);
            const builtInFeedPackageActions = actions &&
                // start with the steps
                _.chain(deploymentProcess.Steps)
                    // Get the step actionPackages
                    .flatMap((step) => step.Actions)
                    // Filter by built-in feed id
                    // Convert them to deployment-action-packages
                    .flatMap((action) => _.chain(action.Packages)
                    .filter((pkg) => pkg.FeedId === builtInFeed.Id)
                    .map((pkg) => ({ DeploymentAction: action.Name, PackageReference: pkg.Name! }))
                    .value())
                    .value();
            const showAutomaticReleaseCreation = (builtInFeedPackageActions && builtInFeedPackageActions.length > 0) || project.AutoCreateRelease === true;
            this.setState({
                triggersResponse: await triggersPromise,
                environments: await environmentsPromise,
                categories: await categoriesPromise,
                deploymentProcess,
                groups: await groupsPromise,
                project,
                builtInPackageRepositoryInUse: builtInFeedPackageActions && builtInFeedPackageActions.length > 0,
                builtInFeedPackageActions: builtInFeedPackageActions!,
                showAutomaticReleaseCreation,
                channels: channels && channels.Items,
                filter: this.createDefaultFilter(),
                runbooksInProject: await runbooksPromise,
                tenants: await tenantsPromise,
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad(this.props.projectContext.state.model.IsVersionControlled) });
    }
    handleTouchTap = (event: any) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchor: event.currentTarget,
        });
    };
    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };
    filterTriggers = (searchText: string, trigger: TriggerResource) => {
        const filter = this.state.filter;
        const deploymentTargetTriggerFilterTypes = [TriggerFilterType.MachineFilter];
        const scheduledTriggerFilterTypes = [TriggerFilterType.OnceDailySchedule, TriggerFilterType.ContinuousDailySchedule, TriggerFilterType.DaysPerMonthSchedule, TriggerFilterType.CronExpressionSchedule];
        return ((filter.searchText === "" || (filter.searchText !== "" && trigger.Name.toLowerCase().includes(filter.searchText.toLowerCase()))) &&
            (filter.showDeploymentTargetTriggers || (!filter.showDeploymentTargetTriggers && !deploymentTargetTriggerFilterTypes.includes(trigger.Filter.FilterType))) &&
            (filter.showScheduledTriggers || (!filter.showScheduledTriggers && !scheduledTriggerFilterTypes.includes(trigger.Filter.FilterType))) &&
            (filter.showBuiltInRepositoryTriggers || (!filter.showBuiltInRepositoryTriggers && !triggerIdIs(trigger, "built-in-feed"))) &&
            (filter.showExternalFeedTriggers || (!filter.showExternalFeedTriggers && trigger.Filter.FilterType !== TriggerFilterType.FeedFilter)) &&
            (filter.showGitTriggers || (!filter.showGitTriggers && trigger.Filter.FilterType !== TriggerFilterType.GitFilter)) &&
            (!filter.runbookId || (!!filter.runbookId && (trigger.Action as any as RunRunbookActionResource).RunbookId === filter.runbookId)));
    };
    render() {
        const content = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") ? (<MediaQuery maxWidth={600}>
                {(small) => (<MediaQuery minWidth={901}>
                        {(large) => (<TriggersDataTable tableClassName={small ? "" : emotionStyles.tableCellHeightHack} headerColumns={this.buildDataTableColumnHeaders(getBreakpoint(small, large))} initialData={this.state.triggersResponse} additionalRequestParams={new Map([["triggerActionCategory", [this.props.triggerActionCategory]]])} onRow={(item: any) => this.buildDataTableColumns(item, getBreakpoint(small, large))} onFilter={this.filterTriggers} onEmpty={() => (<EmptyPage image={<div className={emotionStyles.emptyStateIllustration}>
                                                <EmptyStateDefaultIllustration />
                                            </div>} title={"You have no triggers available yet"} description={"Add a trigger to get started"}/>)}></TriggersDataTable>)}
                    </MediaQuery>)}
            </MediaQuery>) : (<TriggersList initialData={this.state.triggersResponse} additionalRequestParams={new Map([["triggerActionCategory", [this.props.triggerActionCategory]]])} onRow={(item: any) => this.buildTriggerRow(item)} onRowRedirectUrl={(trigger) => getTriggerRedirectUrl(trigger, this.props.projectSlug)} onFilter={this.filterTriggers} empty={<NoResults />}/>);
        const showRunbooksOnboarding = this.props.triggerActionCategory === TriggerActionCategory.Runbook && this.state.runbooksInProject && this.state.runbooksInProject.length === 0;
        const project = this.state.project;
        let primaryAction: PrimaryPageAction | undefined = undefined;
        const pageActions: PageAction[] = [];
        if (project) {
            const addTriggerPageAction: PrimaryPageAction = {
                type: "custom",
                key: "Add Trigger",
                content: <AddDeploymentTriggerButton spaceId={this.props.spaceId} projectSlug={this.props.projectSlug}/>,
            };
            const addScheduledTriggerPageAction: PrimaryPageAction = {
                type: "navigate",
                label: "Add Scheduled Trigger",
                path: links.projectRunbookCreateScheduledTriggerPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
                disabled: showRunbooksOnboarding,
                hasPermissions: isAllowed({
                    permission: Permission.TriggerCreate,
                    project: project.Id,
                }),
            };
            const addReleaseTriggerPageAction: PrimaryPageAction = {
                type: "custom",
                key: "Add Trigger",
                content: <AddProjectTriggerDialogButton project={project}/>,
            };
            switch (this.props.triggerActionCategory) {
                case TriggerActionCategory.Deployment:
                    primaryAction = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") ? addReleaseTriggerPageAction : addTriggerPageAction;
                    break;
                case TriggerActionCategory.Runbook:
                    primaryAction = addScheduledTriggerPageAction;
                    break;
                default:
                    break;
            }
        }
        const conditionallyRenderWithSidebar = (children: React.ReactNode) => {
            if (isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle")) {
                return children;
            }
            return (<SidebarLayout sideBar={this.state.project &&
                    isAllowed({ permission: Permission.ProcessView, project: this.state.project.Id, tenant: "*" }) &&
                    this.props.triggerActionCategory === TriggerActionCategory.Deployment && (<SideBar project={this.state.project} builtInPackageRepositoryInUse={this.state.builtInPackageRepositoryInUse} actionPackages={this.state.builtInFeedPackageActions} showAutomaticReleaseCreation={this.state.showAutomaticReleaseCreation} channels={this.state.channels} deploymentProcess={this.state.deploymentProcess} onProjectUpdated={(p) => this.onProjectUpdated(p)}/>)}>
                    {children}
                </SidebarLayout>);
        };
        return (<ProjectPaperLayout busy={this.state.busy} errors={this.errors} title={this.props.triggerActionCategory === TriggerActionCategory.Runbook ? "Runbook Triggers" : "Triggers"} primaryAction={primaryAction} pageActions={pageActions} fullWidth={isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle")}>
                {showRunbooksOnboarding ? (<RunbooksOnboarding />) : (conditionallyRenderWithSidebar(<>
                            {this.getInvalidConfigurationCallout()}
                            {this.state.triggersResponse && (<FilterLayout filter={this.state.filter} defaultFilter={this.createDefaultFilter()} filterSections={this.props.triggerActionCategory === TriggerActionCategory.Deployment
                        ? [
                            {
                                render: (<>
                                                              <p>
                                                                  <strong>Show trigger types:</strong>
                                                              </p>
                                                              {isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") && (<AdvancedFilterCheckbox label="External feed" value={this.state.filter.showExternalFeedTriggers} onChange={(x) => this.setFilterState({ showExternalFeedTriggers: x }, async () => {
                                            await this.onFilterChange();
                                        })}/>)}
                                                              <GitTriggersFeatureToggleVisibility>
                                                                  <AdvancedFilterCheckbox label="Git repository" value={this.state.filter.showGitTriggers} onChange={(x) => this.setFilterState({ showGitTriggers: x }, async () => {
                                        await this.onFilterChange();
                                    })}/>
                                                              </GitTriggersFeatureToggleVisibility>
                                                              <AdvancedFilterCheckbox label="Scheduled" value={this.state.filter.showScheduledTriggers} onChange={(x) => this.setFilterState({ showScheduledTriggers: x }, async () => {
                                        await this.onFilterChange();
                                    })}/>
                                                              {isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") && (<AdvancedFilterCheckbox label="Built-in package repository" value={this.state.filter.showBuiltInRepositoryTriggers} onChange={(x) => this.setFilterState({ showBuiltInRepositoryTriggers: x }, async () => {
                                            await this.onFilterChange();
                                        })}/>)}
                                                              <AdvancedFilterCheckbox label="Deployment target" value={this.state.filter.showDeploymentTargetTriggers} onChange={(x) => this.setFilterState({ showDeploymentTargetTriggers: x }, async () => {
                                        await this.onFilterChange();
                                    })}/>
                                                          </>),
                            },
                        ]
                        : []} additionalHeaderFilters={[
                        <FilterSearchBox placeholder="Filter by name..." value={this.state.filter.searchText} autoFocus={true} onChange={(searchText) => this.setFilterState({ searchText }, async () => {
                                await this.onFilterChange();
                            })}/>,
                        this.props.triggerActionCategory === TriggerActionCategory.Runbook && (<ActionList actions={[
                                <div className={styles.filterControl}>
                                                        <Select label="" placeholder="Filter by Runbook" allowFilter={true} allowClear={true} value={this.state.filter.runbookId} onChange={(x) => {
                                        this.setFilterState({ runbookId: x! }, async () => {
                                            await this.onFilterChange();
                                        });
                                    }} items={this.state.runbooksInProject.map((r) => ({ value: r.Id, text: r.Name }))}/>
                                                    </div>,
                            ]}/>),
                    ]} onFilterReset={(filter: TriggersFilter) => {
                        this.setState({ filter }, async () => {
                            await this.onFilterChange();
                            const location = { ...this.props.history, search: null! as any };
                            this.props.history.replace(location);
                        });
                    }} renderContent={() => content}/>)}
                        </>))}
            </ProjectPaperLayout>);
    }
    async reloadTriggers() {
        const project = this.state.project;
        const gitRef = this.props.projectContext.state.gitRef;
        await this.doBusyTask(async () => {
            this.setState({
                triggersResponse: await repository.Projects.getTriggers(project, gitRef, 0, 30, undefined, this.props.triggerActionCategory, this.state.filter.runbookId ? [this.state.filter.runbookId] : null!, this.state.filter.searchText),
            });
        });
    }
    private getInvalidConfigurationCallout() {
        if (this.props.triggerActionCategory !== TriggerActionCategory.Deployment) {
            return;
        }
        if (this.state.deploymentProcess && this.state.project.AutoCreateRelease) {
            if (!this.state.project.ReleaseCreationStrategy || !this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage) {
                return (<Callout type={"warning"} title="Invalid Configuration">
                        This project is configured to use Automatic Release Creation, but the step is missing. Please adjust or disable the Automatic Release Creation configuration.
                    </Callout>);
            }
            else {
                const action = flatten(this.state.deploymentProcess.Steps.map((step) => step.Actions)).filter((a) => a.Name === this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
                if (action && action.length > 0 && action[0].IsDisabled) {
                    return (<Callout type={"warning"} title="Invalid Configuration">
                            Step <InternalLink to={links.deploymentProcessStepsPage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug }, { actionId: action[0].Id })}>{action[0].Name}</InternalLink> is currently
                            used for Automatic Release Creation, but it has been disabled.
                            <br />
                            Please re-enable the step, disable Automatic Release Creation, or choose a different step.
                        </Callout>);
                }
            }
        }
        return null!;
    }
    private async onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.reloadTriggers();
            this.setState({ isSearching: false });
        });
    }
    private setFilterState<K extends keyof TriggersFilter>(state: Pick<TriggersFilter, K>, callback?: () => void) {
        this.setState((prev) => ({
            filter: { ...(prev!.filter as object), ...(state as object) },
        }), callback);
    }
    private createDefaultFilter(): TriggersFilter {
        return {
            searchText: "",
            showDeploymentTargetTriggers: true,
            showScheduledTriggers: true,
            showBuiltInRepositoryTriggers: true,
            showExternalFeedTriggers: true,
            showGitTriggers: true,
            runbookId: "",
        };
    }
    private buildTriggerRow(trigger: TriggerResource) {
        const overflowMenuItems: any = this.getOverflowMenuItems(trigger);
        if (trigger.Filter.FilterType === TriggerFilterType.MachineFilter) {
            return <Trigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} environments={this.state.environments} categories={this.state.categories} groups={this.state.groups}/>;
        }
        return <ScheduledTrigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} runbooks={this.state.runbooksInProject} environments={this.state.environments} channels={this.state.channels}/>;
    }
    private getOverflowMenuItems(trigger: TriggerResource) {
        const menuItems = [];
        const triggerEditPermission = { permission: Permission.TriggerEdit, project: this.state.project.Id };
        // Can't disable ARC, only delete
        if (!triggerIdIs(trigger, "built-in-feed")) {
            menuItems.push(OverflowMenuItems.item(trigger.IsDisabled ? "Enable" : "Disable", () => (trigger.IsDisabled ? this.enable(trigger) : this.disable(trigger)), triggerEditPermission));
        }
        return menuItems;
    }
    private async enable(trigger: TriggerResource) {
        trigger.IsDisabled = false;
        await this.saveTrigger(trigger);
    }
    private async disable(trigger: TriggerResource) {
        trigger.IsDisabled = true;
        await this.saveTrigger(trigger);
    }
    private async saveTrigger(trigger: TriggerResource) {
        const gitRef = this.props.projectContext.state.gitRef;
        const isSuccess = await this.doBusyTask(async () => {
            await repository.ProjectTriggers.modify(trigger);
            const triggersResponse = await repository.Projects.getTriggers(this.state.project, gitRef, 0, 30, undefined, this.props.triggerActionCategory);
            this.setState({
                triggersResponse,
            });
        });
        if (!isSuccess) {
            await this.doBusyTask(async () => {
                this.setState({
                    triggersResponse: await repository.Projects.getTriggers(this.state.project, gitRef, 0, 30, undefined, this.props.triggerActionCategory),
                });
            }, { preserveCurrentErrors: true, timeOperationOptions: timeOperationOptions.forInitialLoad() });
        }
    }
    private onProjectUpdated(project: ProjectResource) {
        this.setState({
            project,
        });
    }
    private buildDataTableColumnHeaders(screenSize: "sm" | "md" | "lg") {
        const columnHeaders = ["Name"];
        if (screenSize !== "sm") {
            columnHeaders.push("Type");
            columnHeaders.push("Channel");
        }
        if (screenSize === "lg") {
            columnHeaders.push("Trigger action");
        }
        if (screenSize !== "sm") {
            columnHeaders.push("");
        }
        return columnHeaders;
    }
    private buildDataTableColumns(trigger: TriggerResource, screenSize: "sm" | "md" | "lg") {
        const columns = [<TriggerNameAndLink trigger={trigger} projectSlug={this.props.projectSlug}/>];
        if (screenSize !== "sm") {
            columns.push(<TriggerType trigger={trigger}/>);
            const channelChip = this.channelChip(trigger);
            if (channelChip) {
                columns.push(channelChip);
            }
            else {
                columns.push(<></>);
            }
        }
        if (screenSize === "lg") {
            columns.push(this.buildTriggerDescription(trigger));
        }
        if (screenSize !== "sm") {
            columns.push(this.getOverflowMenu(trigger));
        }
        return columns.map((column) => this.wrapWithStyles(column, trigger));
    }
    private wrapWithStyles(children: React.ReactNode, trigger: TriggerResource) {
        let cellStyle = emotionStyles.verticallyCenter;
        if (trigger.IsDisabled) {
            cellStyle = cx(cellStyle, emotionStyles.disabled);
        }
        return <div className={cellStyle}>{children}</div>;
    }
    private channelChip(trigger: TriggerResource): React.ReactElement | undefined {
        const channelId = (trigger.Action as CreateReleaseActionResource).ChannelId;
        if (!channelId || !this.state.channels)
            return;
        return this.state.channels.find((c) => c.Id === channelId) ? (matchesToChips(this.state.channels, [channelId], (channel) => (<ChannelChip labelColor={trigger.IsDisabled ? themeTokens.color.text.disabled : themeTokens.color.text.primary} channelName={channel.Name} key={channel.Id} noMargin={true}/>))[0]) : (<ChannelChip labelColor={trigger.IsDisabled ? themeTokens.color.text.disabled : themeTokens.color.text.primary} channelName={channelId} noMargin={true}/>);
    }
    private buildTriggerDescription(trigger: TriggerResource) {
        if (trigger.Filter.FilterType === TriggerFilterType.FeedFilter || trigger.Filter.FilterType === TriggerFilterType.ArcFeedFilter) {
            return <FeedTriggerDescription trigger={trigger} builtInFeedPackageActions={this.state.builtInFeedPackageActions} projectSlug={this.props.projectSlug} deploymentProcess={this.state.deploymentProcess}/>;
        }
        else if (trigger.Filter.FilterType === TriggerFilterType.GitFilter) {
            return <GitTriggerDescription trigger={trigger} project={this.state.project} deploymentProcess={this.state.deploymentProcess}/>;
        }
        if (trigger.Filter.FilterType === TriggerFilterType.MachineFilter) {
            const filter = trigger.Filter as MachineFilterResource;
            const description = new TriggerDescription(filter, this.state.environments, this.state.categories, this.state.groups);
            return description.buildDescription();
        }
        const scheduleDescription = ScheduledTriggerDescriptionHelper.getScheduleDescription(trigger.Filter as TriggerScheduleResource);
        const actionDescription = ScheduledTriggerDescriptionHelper.getActionDescription(trigger.Action as ScopedDeploymentActionResource, this.state.environments, this.state.runbooksInProject, this.state.channels);
        return (<span>
                {actionDescription}
                {scheduleDescription}.
            </span>);
    }
    private getOverflowMenu(trigger: TriggerResource) {
        return <OverflowMenu menuItems={this.getOverflowMenuItems(trigger)}/>;
    }
    static displayName = "TriggersInternal";
}
type TriggersProps = {
    triggerActionCategory: TriggerActionCategory;
    spaceId: string;
    projectSlug: string;
};
export const TriggersPage: React.FC<TriggersProps> = (props) => {
    const isMultiTenancyEnabled = useSelector(isMultiTenancyEnabledSelector);
    const projectContext = useProjectContext();
    const history = useHistory();
    return <TriggersInternal history={history} isMultiTenancyEnabled={isMultiTenancyEnabled} projectContext={projectContext} {...props}/>;
};
TriggersPage.displayName = "TriggersPage"
function getTriggerRedirectUrl(trigger: TriggerResource, projectSlug: string) {
    if (trigger.Action.ActionType === TriggerActionType.RunRunbook) {
        return links.projectRunbookEditScheduledTriggerPage.generateUrl({ spaceId: trigger.SpaceId, projectSlug, triggerId: trigger.Id });
    }
    if (trigger.Action.ActionType === TriggerActionType.AutoDeploy) {
        return links.editDeploymentTriggerPage.generateUrl({ spaceId: trigger.SpaceId, projectSlug, triggerId: trigger.Id });
    }
    return links.editDeploymentScheduledTriggerPage.generateUrl({ spaceId: trigger.SpaceId, projectSlug, triggerId: trigger.Id });
}
const isMultiTenancyEnabledSelector = (state: GlobalState) => state.configurationArea.currentSpace.isMultiTenancyEnabled;
const getBreakpoint = (small: boolean, large: boolean) => {
    if (small)
        return "sm";
    if (large)
        return "lg";
    return "md";
};
const emotionStyles = {
    // This is required so we can set the height of a cell to 100% so we can center the content
    tableCellHeightHack: css({ height: "1px" }),
    verticallyCenter: css({ height: "100%", display: "flex", alignItems: "center" }),
    disabled: css({ color: themeTokens.color.text.disabled }),
    emptyStateIllustration: css({ height: 300 }),
};
