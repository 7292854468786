import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "./CommonViews";
export function CommonDashboardOverview() {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    The <b>dashboard</b> is where you can see what's happening with your deployments. Use the 'Configure' button to include only the projects or environments you're interested in.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
}
export const CommonDashboardTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="GettingStarted">Getting Started</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="FirstDeploymentGuide">First Deployment Guide</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="FirstKubernetesGuide">First Kubernetes Deployment Guide</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="BestPractices">Best Practices</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
