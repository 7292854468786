import cn from "classnames";
import * as React from "react";
import styles from "./MachineIcon.module.less";
export type MachineIconVariant = "default" | "small";
type MachineIconProps = {
    imageUrl: string;
    variant?: MachineIconVariant;
};
export function MachineIcon(props: MachineIconProps) {
    return (<div className={cn(styles.machineImage, { [styles.machineImageSmall]: props.variant === "small" })}>
            <img src={props.imageUrl} className={styles.machineIcon} alt="Machine"/>
        </div>);
}
