import type { PhaseResource } from "@octopusdeploy/octopus-server-client";
import type { LifecycleStatus } from "../MapProgressionToStatus/MapProgressionToStatus";
export function getSkippedState(phase: PhaseResource, status: LifecycleStatus): boolean {
    return (phase.IsOptionalPhase &&
        !!status.progress[phase.Name] &&
        status.progress[phase.Name] !== "Blocked" &&
        status.progress[phase.Name] !== "Complete" &&
        status.progress[phase.Name] !== "Current" &&
        !status.deployed[getSingleDeploymentTarget(phase)]);
}
export function getSingleDeploymentTarget(phase: PhaseResource): string {
    if (phase.AutomaticDeploymentTargets.length > 0) {
        return phase.AutomaticDeploymentTargets[0];
    }
    else {
        return phase.OptionalDeploymentTargets[0];
    }
}
