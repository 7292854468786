import type { GitHubActionsSubjectParseResult, GitHubActionsFilterType } from "./ConfigureGitHubActionsOidcIdentity";
export function parseGitHubActionsSubject(subject: string): GitHubActionsSubjectParseResult | undefined {
    const regex = "^repo:(?<organization>.+)/(?<repository>.+):(pull_request|ref:refs/heads/(?<branch>.+)|ref:refs/tags/(?<tag>.+)|environment:(?<environment>.+))$";
    const match = subject.match(regex);
    if (match === null || match.groups === undefined)
        return undefined;
    let scope: GitHubActionsFilterType = "pull_request";
    let value: string | undefined = undefined;
    if (match.groups["branch"]) {
        scope = "branch";
        value = match.groups["branch"];
    }
    else if (match.groups["tag"]) {
        scope = "tag";
        value = match.groups["tag"];
    }
    else if (match.groups["environment"]) {
        scope = "environment";
        value = match.groups["environment"];
    }
    return {
        organization: match.groups["organization"],
        repository: match.groups["repository"],
        filterType: scope,
        filterValue: value,
    };
}
