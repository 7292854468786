import { pageId } from "@octopusdeploy/portal-routes";
import configurationPageIds from "~/areas/configuration/components/ConfigurationRoutes/configurationPageIds";
import dashboardPageIds from "~/areas/dashboard/DashboardOverview/dashboardPageIds";
import deploymentPageIds from "~/areas/deployments/deploymentPageIds";
import infrastructurePageIds from "~/areas/infrastructure/components/InfrastructureRoutes/infrastructurePageIds";
import insightsPageIds from "~/areas/insights/components/InsightsRoutes/insightsPageIds";
import libraryPageIds from "~/areas/library/components/LibraryRoutes/libraryPageIds";
import projectGroupPageIds from "~/areas/projects/components/ProjectGroupLayout/projectGroupPageIds";
import projectPageIds from "~/areas/projects/components/ProjectsRoutes/projectPageIds";
import taskPageIds from "~/areas/tasks/components/TaskRoutes/taskPageIds";
import tenantPageIds from "~/areas/tenants/TenantsRoutes/tenantPageIds";
import userProfilePageIds from "~/areas/users/UserProfileRoutes/userProfilePageIds";
const pageIds = parameterizedPageIds();
function parameterizedPageIds() {
    const root = "";
    return {
        root,
        dashboard: dashboardPageIds,
        ...projectPageIds(),
        ...tenantPageIds(),
        ...taskPageIds(),
        ...projectGroupPageIds(),
        ...deploymentPageIds(),
        currentUser: userProfilePageIds(),
        library: libraryPageIds(),
        infrastructure: infrastructurePageIds(),
        insights: insightsPageIds(),
        configuration: configurationPageIds(),
        styleGuide: pageId({ area: "Dev Tools", id: "StyleGuide", name: "Style guide" }),
    };
}
export default pageIds;
