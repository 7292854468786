import type { ProjectSummaryResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import Card from "~/components/Card/Card";
import Logo from "~/components/Logo/Logo";
import Markdown from "~/components/Markdown";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import styles from "./style.module.less";
interface ProjectCardProps {
    project: ProjectSummaryResource;
    disableMargin?: boolean;
}
const ProjectCard: React.FC<ProjectCardProps> = (props) => {
    const project = props.project;
    return (<Card logo={<Logo url={props.project.Logo} isDisabled={project.IsDisabled}/>} link={<InternalLink to={links.projectRootRedirect.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}/>} header={project.Name} content={<Markdown markup={project.Description}/>} includeContentFade={true} isDisabled={project.IsDisabled} className={styles.projectCard} disableMargin={props.disableMargin}/>);
};
ProjectCard.displayName = "ProjectCard"
ProjectCard.displayName = "ProjectCard";
export default ProjectCard;
