import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface DashboardLimitSummaryProps {
    matchCount: number;
    projectLimit: number | null;
    hasFilter: boolean;
    totalProjects: number;
}
const DashboardLimitSummary: React.FC<DashboardLimitSummaryProps> = ({ matchCount, projectLimit, hasFilter, totalProjects }) => {
    if (hasFilter && matchCount >= 0) {
        return <span className={cn(styles.matchCount, styles.info)}>{`${matchCount} of ${totalProjects} projects match`}</span>;
    }
    if (!hasFilter && projectLimit !== null && totalProjects >= projectLimit) {
        return <span className={styles.matchCount}>{`${projectLimit} of ${totalProjects} projects displayed`}</span>;
    }
    return null;
};
DashboardLimitSummary.displayName = "DashboardLimitSummary"
export default DashboardLimitSummary;
