import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableHeader } from "~/primitiveComponents/dataDisplay/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableHeaderColumn";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import type { CertificateUsageEntry } from "./certificateUsageSummary";
interface Props {
    certificateUsage: CertificateUsageEntry[];
}
const CertificateUsage = (props: Props) => {
    return (props.certificateUsage && (<div>
                {props.certificateUsage.length === 0 && <p>This certificate is not used anywhere</p>}
                {props.certificateUsage.length > 0 && (<DataTable>
                        <DataTableHeader>
                            <DataTableRow>
                                <DataTableHeaderColumn>Source</DataTableHeaderColumn>
                                <DataTableHeaderColumn>Type</DataTableHeaderColumn>
                            </DataTableRow>
                        </DataTableHeader>
                        <DataTableBody>
                            {props.certificateUsage.map((usage: CertificateUsageEntry) => (<DataTableRow key={usage.ownerName}>
                                    <DataTableRowColumn>
                                        <InternalLink to={usage.ownerLink}>{usage.ownerName}</InternalLink>
                                    </DataTableRowColumn>
                                    <DataTableRowColumn>{usage.ownerType}</DataTableRowColumn>
                                </DataTableRow>))}
                        </DataTableBody>
                    </DataTable>)}
            </div>));
};
export default CertificateUsage;
