import { css } from "@emotion/css";
import { borderRadius, fontSize, space, themeTokens } from "@octopusdeploy/design-system-tokens";
export const radioButtonStyles = css({
    "& .MuiFormGroup-root[role='radiogroup']": {
        gap: space["12"],
        "& label": {
            border: `1px solid ${themeTokens.color.border.primary}`,
            borderRadius: borderRadius["small"],
            padding: `${space["8"]} ${space["12"]}`,
            minWidth: "170px",
            "& .MuiTypography-root.MuiFormControlLabel-label": {
                fontSize: fontSize["medium"],
                "& .note": {
                    color: themeTokens.color.text.tertiary,
                },
            },
        },
        "& label:has(.active)": {
            border: `1px solid ${themeTokens.color.icon.selected}`,
        },
    },
});
