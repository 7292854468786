import React from "react";
import { YouTubePlayer } from "~/components/ContextualHelpLayout/YouTubePlayer";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { Note } from "~/components/form";
import HelpImageTeams from "../../Images/Teams.svg";
import { OverviewContainer, OverviewHighlightText, TermsText } from "./CommonViews";
const video = {
    youtubeId: "f_JPU7sAE8M",
    shortlink: "ManagingUsersAndTeamsVideo",
};
export const CommonConfigurationTeamOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <YouTubePlayer videoId={video.youtubeId}/>
                <p>
                    Users can be organized into <ExternalLink href="ManagingUsersAndTeams">teams</ExternalLink> and given various permissions by including user roles. Teams can be restricted to the system or a space and scoped to particular projects,
                    environments or tenants.
                </p>
                <ImageWithPlaceholder src={HelpImageTeams} alt={"Teams"}/>
            </OverviewHighlightText>
            <TermsText>
                <Note heading={"Types of permissions"}>
                    <li>
                        <b>System level permissions</b> are available to teams accessible in <i>all spaces</i> and involve administering the entire system, but do not include permissions within an individual space. An example of system level
                        permissions are the `User` permissions, since users are not scoped to a space.
                    </li>
                </Note>
                <Note>
                    <li>
                        <b>Space level permissions</b> are available for teams accessible in <i>all spaces and an individual space</i>, and apply to resources within spaces, for example, projects and environments.
                    </li>
                </Note>
            </TermsText>
        </OverviewContainer>);
};
