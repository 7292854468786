import { colorScales } from "@octopusdeploy/design-system-tokens";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
export const chartStrokeColorValues = ["green", "cyan", "orange", "purple", "red", "greenSecondary", "cyanSecondary", "orangeSecondary", "purpleSecondary", "redSecondary"];
export type ChartStrokeColor = (typeof chartStrokeColorValues)[number];
interface ThemedChartColors {
    grid: string;
    xAxis: string;
    yAxis: string;
    label: string;
    strokes: Record<ChartStrokeColor, string>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addColorToDataset<T extends Record<string, any>>(data: T[]) {
    let colorIndex = 0;
    return data.map((d) => {
        const withColor = { ...d, color: chartStrokeColorValues[colorIndex] };
        colorIndex = colorIndex === chartStrokeColorValues.length - 1 ? 0 : (colorIndex += 1);
        return withColor;
    });
}
export const useThemedChartColors = (): ThemedChartColors => {
    const mode = useThemePaletteType();
    const isLight = mode === "light";
    const textColor = isLight ? colorScales.grey["600"] : colorScales.grey["400"];
    return {
        grid: textColor,
        xAxis: textColor,
        yAxis: textColor,
        label: textColor,
        strokes: {
            green: "#00B065",
            cyan: "#0D80D8",
            orange: "#FC8431",
            purple: "#7A41C5",
            red: "#FF4848",
            greenSecondary: "#76F5A9",
            cyanSecondary: "#3CA4F3",
            orangeSecondary: "#FFB867",
            purpleSecondary: "#C39AF8",
            redSecondary: "#FFADA5",
        },
    };
};
