import type { KubernetesSecretProperties } from "@octopusdeploy/legacy-action-properties";
import * as React from "react";
import { KubernetesSecretComponent } from "~/components/Actions/kubernetes/kubernetesSecretComponent";
import type { ScriptPackageProperties } from "~/components/Actions/script/ScriptPackageReferenceDialog";
import type { ActionEditProps } from "../../Actions/pluginRegistry";
import pluginRegistry from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
class KubernetesSecretFeature extends BaseComponent<ActionEditProps<KubernetesSecretProperties, ScriptPackageProperties>, never> {
    render() {
        return (<KubernetesSecretComponent properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} errors={this.props.errors} projectId={this.props.projectId} gitRef={this.props.gitRef} expandedByDefault={this.props.expandedByDefault} importLabels={false}/>);
    }
    static displayName = "KubernetesSecretFeature";
}
pluginRegistry.registerFeature({
    featureName: "Octopus.Features.KubernetesSecret",
    title: "Secret",
    description: "Defines a Kubernetes Secret resource, which is a collection of key/value settings",
    edit: KubernetesSecretFeature,
    priority: 20,
});
