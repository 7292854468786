import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { repository } from "~/clientInstance";
import ExternalLink from "~/components/Navigation/ExternalLink";
import InternalLink from "~/components/Navigation/InternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
export const CommonLibraryGitCredentialsOverview = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spaceId = repository.spaceId!;
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>Git Credentials lets you store Git account credentials in a shared library.</p>
                <br />
                <p>
                    If you’re connecting to GitHub repositories, we recommend using <InternalLink to={links.gitConnectionsPage.generateUrl({ spaceId })}>GitHub Connections</InternalLink> to connect your GitHub accounts. It's seamless, secure, and
                    avoids the need to use and rotate personal access tokens.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonLibraryGitCredentialsTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="GitCredentialsAuth">Git credentials authentication guide</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="ConfigAsCodeConfiguration">Connecting projects to Git</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="ConfigAsCodeMoving">Moving version control</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
