import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { MixedExecutionLocationsConfig } from "~/components/Actions/pluginRegistry";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
export const kubernetesMixedExecutionLocationConfig = (allLocationsRequireActionContainer?: boolean): MixedExecutionLocationsConfig => ({
    enabled: true,
    allLocationsRequireActionContainer: allLocationsRequireActionContainer || false,
    title: "Kubernetes API Deployment Targets",
    callout: (<Callout type={"information"} title={"These fields are not required for Kubernetes agents"}>
            The <ExternalLink href={"kubernetes-agent"}>Kubernetes agent</ExternalLink> doesn’t require workers and will ignore these fields. Complete the fields below if you’re deploying to Kubernetes API deployment targets.
        </Callout>),
});
