import { css } from "@emotion/css";
import { lineHeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client/src/repositories/serviceAccountOidcIdentitiesRepository";
import * as React from "react";
import { useState } from "react";
import Card from "~/components/Card";
import DataBaseComponent from "~/components/DataBaseComponent";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { useErrors } from "~/components/ErrorContext/ErrorContext";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import { ConfigureGitHubActionsOidcIdentity } from "./ConfigureGitHubActionsOidcIdentity";
import { ConfigureOtherIssuerOidcIdentity } from "./ConfigureOtherIssuerOidcIdentity";
import { IssuerTypeLogo } from "./IssuerTypeLogo";
import type { IssuerType } from "./getIssuerType";
import { getIssuerType } from "./getIssuerType";
type ConfigureServiceAccountOidcIdentityDialogLayoutInternalProps = {
    externalId: string;
    oidcIdentity: ServiceAccountOidcIdentityResource;
    onSave: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => void;
    doBusyTask: DoBusyTask;
    busy?: Promise<unknown> | boolean;
};
function ConfigureServiceAccountOidcIdentityDialogLayoutInternal({ externalId, oidcIdentity, onSave, onCancel, doBusyTask, busy }: ConfigureServiceAccountOidcIdentityDialogLayoutInternalProps) {
    const [oidcIdentityBeingConfigured, setOidcIdentityBeingConfigured] = useState<ServiceAccountOidcIdentityResource>(oidcIdentity);
    const [issuerType, setIssuerType] = useState<IssuerType | undefined>(getIssuerType(oidcIdentity));
    const themePaletteType = useThemePaletteType();
    const errors = useErrors();
    const isNew = oidcIdentity.Id === "";
    async function onSaveClick() {
        return await doBusyTask(async () => {
            await onSave(oidcIdentityBeingConfigured);
        });
    }
    let validOidcIdentity = false;
    if (oidcIdentityBeingConfigured.Name && oidcIdentityBeingConfigured.Issuer && oidcIdentityBeingConfigured.Subject)
        validOidcIdentity = true;
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "column",
            gap: space[32],
        }),
        issuerTypesContainer: css({
            display: "flex",
            gap: space[16],
        }),
        issuerTypeCard: css({
            margin: "0 !important",
            padding: space[16],
            cursor: "pointer",
        }),
        issuerTypeCardSelected: css({
            margin: "0 !important",
            padding: space[16],
            cursor: "pointer",
            background: `${themeTokens.color.background.selected} !important`,
            color: `${themePaletteType === "dark" ? "inherit" : themeTokens.color.text.inverse} !important`,
        }),
        issuerTypeCardHeader: css({
            color: `${themeTokens.color.text.primary}`,
        }),
        issuerTypeCardSelectedHeader: css({
            color: `${themePaletteType === "dark" ? "inherit" : themeTokens.color.text.inverse} !important`,
        }),
        issuerTypeCardContent: css({
            maxHeight: "5rem !important",
        }),
        issuerTypeCardSelectedContent: css({
            maxHeight: "5rem !important",
            color: `${themePaletteType === "dark" ? "inherit" : themeTokens.color.text.inverse} !important`,
        }),
        issuerTypeDescription: css({
            marginTop: space[8],
            display: "flex",
            flexDirection: "column",
            gap: space[4],
            lineHeight: lineHeight.xSmall,
        }),
    };
    return (<SaveDialogLayout title={`${isNew ? "New" : "Edit"} OIDC Identity`} busy={busy} errors={errors} onSaveClick={onSaveClick} saveButtonDisabled={!validOidcIdentity} onCancelClick={() => {
            onCancel();
            return true;
        }}>
            <div className={styles.container}>
                <div className={styles.issuerTypesContainer}>
                    <div onClick={() => setIssuerType("GitHubActions")}>
                        <Card logo={<IssuerTypeLogo issuerType="GitHubActions"/>} header={<div className={issuerType === "GitHubActions" ? styles.issuerTypeCardSelectedHeader : styles.issuerTypeCardHeader}>GitHub Actions</div>} content={<div className={styles.issuerTypeDescription}>Connect from GitHub Actions using OIDC with our published actions</div>} contentClassName={issuerType === "GitHubActions" ? styles.issuerTypeCardSelectedContent : styles.issuerTypeCardContent} className={issuerType === "GitHubActions" ? styles.issuerTypeCardSelected : styles.issuerTypeCard}/>
                    </div>
                    <div onClick={() => setIssuerType("Other")}>
                        <Card logo={<IssuerTypeLogo issuerType="Other"/>} header={<div className={issuerType === "Other" ? styles.issuerTypeCardSelectedHeader : styles.issuerTypeCardHeader}>Other Issuer</div>} content={<div className={styles.issuerTypeDescription}>Connect from other providers that can issue signed OIDC tokens</div>} contentClassName={issuerType === "Other" ? styles.issuerTypeCardSelectedContent : styles.issuerTypeCardContent} className={issuerType === "Other" ? styles.issuerTypeCardSelected : styles.issuerTypeCard}/>
                    </div>
                </div>
                {issuerType === "GitHubActions" && <ConfigureGitHubActionsOidcIdentity oidcIdentity={oidcIdentityBeingConfigured} setOidcIdentity={setOidcIdentityBeingConfigured}/>}
                {issuerType === "Other" && <ConfigureOtherIssuerOidcIdentity externalId={externalId} oidcIdentity={oidcIdentityBeingConfigured} setOidcIdentity={setOidcIdentityBeingConfigured}/>}
            </div>
        </SaveDialogLayout>);
}
type ConfigureServiceAccountOidcIdentityDialogLayoutProps = {
    externalId: string;
    oidcIdentity: ServiceAccountOidcIdentityResource;
    onSave: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => void;
};
export class ConfigureServiceAccountOidcIdentityDialogLayout extends DataBaseComponent<ConfigureServiceAccountOidcIdentityDialogLayoutProps> {
    constructor(props: ConfigureServiceAccountOidcIdentityDialogLayoutProps) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.clearErrors();
    }
    render() {
        return (<ConfigureServiceAccountOidcIdentityDialogLayoutInternal externalId={this.props.externalId} oidcIdentity={this.props.oidcIdentity} onSave={this.props.onSave} onCancel={this.props.onCancel} doBusyTask={this.doBusyTask} busy={this.state.busy}/>);
    }
    static displayName = "ConfigureServiceAccountOidcIdentityDialogLayout";
}
