import { css } from "@emotion/css";
import type { ReactElement } from "react";
import * as React from "react";
import { Level2EmptyStateLayout } from "~/components/GettingStarted/Level2EmptyStateLayout";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
interface AccountsOnboardingProps {
    actionButtonsAndLinks?: ReactElement[];
}
const Onboarding = ({ actionButtonsAndLinks }: AccountsOnboardingProps) => {
    const intro = (<div className={introWrapper}>
            {"Accounts help you to centralise account details used during your deployments, including things like username/password, tokens, Azure and AWS credentials and SSH key pairs."}
            <ExternalLink href="OnboardingAccountsLearnMore">Learn more</ExternalLink>
        </div>);
    return <Level2EmptyStateLayout title={"Add accounts to use during your deployments"} intro={intro} actionButtonsAndLinks={actionButtonsAndLinks}/>;
};
const introWrapper = css({
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
});
export default Onboarding;
