import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import ExternalVideoLink from "~/components/Navigation/ExternalLink/ExternalVideoLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
const Onboarding = (props: {
    project: ProjectResource;
    actionButtons: React.ReactNode;
}) => {
    return (<OnboardingPage title="Start adding steps and configuration variables to define your deployment process" intro="A project is the collection of deployment steps, configuration variables, packages and assets that Octopus brings together and executes during the process of deploying your software." learnMore={<>
                    <ExternalLink href="OnboardingDeploymentProcessLearnMore">Docs</ExternalLink>
                    <ExternalVideoLink href="OnboardingDeploymentProcessVideo"/>
                </>} actionButtons={props.actionButtons}/>);
};
export default Onboarding;
