import type { GetDeploymentFreezes, GetDeploymentFreezesResponse } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import type { Moment } from "moment";
import moment from "moment";
function isActive(freeze: GetDeploymentFreezes, projectId: string, selectedEnvironmentIds: string[], queueTime: Moment | undefined) {
    const targetDeployTime = queueTime ?? moment();
    const startMoment = moment(freeze.Start);
    const endMoment = moment(freeze.End);
    return targetDeployTime.isAfter(startMoment) && targetDeployTime.isBefore(endMoment) && freeze.ProjectEnvironmentScope[projectId] && freeze.ProjectEnvironmentScope[projectId].some((environment) => selectedEnvironmentIds.includes(environment));
}
export function getActiveFreezes(freezes: GetDeploymentFreezesResponse | null, projectId: string, selectedEnvironmentIds: string[], queueTime: Moment | undefined): {
    Id: string;
    Name: string;
    Environments: string[];
}[] {
    if (freezes === null) {
        return [];
    }
    return freezes.DeploymentFreezes.filter((f) => isActive(f, projectId, selectedEnvironmentIds, queueTime)).map((freeze) => ({
        Id: freeze.Id,
        Name: freeze.Name,
        Environments: freeze.ProjectEnvironmentScope[projectId].filter((environment) => selectedEnvironmentIds.includes(environment)),
    }));
}
