/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import type { ReleasePackageVersionBuildInformationResource, WorkItemLink } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import CommitDetails from "~/components/Commits/CommitDetails";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import { ShipmentError } from "~/components/Images/PackageList/ShipmentError";
import { ShipmentOk } from "~/components/Images/PackageList/ShipmentOk";
import LoadMoreWrapper from "~/components/LoadMoreWrapper/LoadMoreWrapper";
import MarkdownDescription from "~/components/MarkdownDescription";
import ExternalLink from "~/components/Navigation/ExternalLink";
import SimpleExpander from "~/components/SimpleExpander/SimpleExpander";
import WorkItems from "~/components/WorkItems/WorkItems";
import { DataTable, DataTableBody, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import DateFormatter from "~/utils/DateFormatter/index";
import Note from "../../../../../primitiveComponents/form/Note/Note";
import type { PackageModel } from "../packageModel";
import styles from "./style.module.less";
interface PackagesListProps {
    packages: PackageModel[];
    buildInformation: ReleasePackageVersionBuildInformationResource[] | null;
}
export default class PackagesList extends DataBaseComponent<PackagesListProps, DataBaseComponentState> {
    constructor(props: PackagesListProps) {
        super(props);
        this.state = {};
    }
    //TODO: Refactor - We're mixing presentation and styling here with the magic string of "1.75rem". This width can be moved into the Shipment components themselves.
    render() {
        return (<div>
                {this.props.packages && this.props.packages.length > 0 ? (<ul>
                        <LoadMoreWrapper items={this.props.packages} initialTakeSize={10} renderLoadMore={(children) => {
                    return <li>{children}</li>;
                }} renderItem={(pkg, index) => (<li key={index} className={pkg.Notes.Succeeded ? styles.ok : styles.error}>
                                    <b>
                                        {pkg.Notes.Succeeded ? <ShipmentOk width="1.75rem"/> : <ShipmentError width="1.75rem"/>}
                                        <dfn title={pkg.PackageId + " from feed " + pkg.FeedName}>
                                            {this.renderPackageReference(pkg)} version {pkg.Version}
                                        </dfn>
                                    </b>
                                    {pkg.Notes.Notes && (<Note>
                                            {pkg.Notes.Published && <p>Published: {DateFormatter.dateToLongFormat(pkg.Notes.Published)}</p>}
                                            <MarkdownDescription markup={pkg.Notes.Notes}/>
                                        </Note>)}
                                    {pkg.Notes.FailureReason && (<div className={styles.container}>
                                            <Callout title={pkg.Notes.FailureReason} type={"danger"}/>
                                        </div>)}
                                    {this.props.buildInformation && this.buildInformationForPackage(pkg)}
                                </li>)}/>
                    </ul>) : (<Note>There are no packages associated with any steps.</Note>)}
            </div>);
    }
    buildInformationForPackage = (pkg: PackageModel) => {
        // Version checking in the following is problemmatic due to SemVer. There should only be one version per packageId
        // in the buildInformation list anyway so just use the Id.
        const buildInformation = this.props.buildInformation!.find((p) => p.PackageId.toLowerCase() === pkg.PackageId.toLowerCase());
        if (buildInformation) {
            return (<div className={styles.container}>
                    <SimpleExpander containerKey={`${pkg.PackageId}-${pkg.ActionName}`} title={<div className={styles.buildInformationTitle}>Build Information</div>} errorKey="buildInformation">
                        <div className={styles.buildInformationSummary}>
                            <DataTable>
                                <DataTableBody>
                                    <DataTableRow>
                                        <DataTableRowColumn className={styles.buildInformationSummaryLabel}>Build Environment</DataTableRowColumn>
                                        <DataTableRowColumn>{buildInformation.BuildEnvironment}</DataTableRowColumn>
                                    </DataTableRow>
                                    <DataTableRow>
                                        <DataTableRowColumn className={styles.buildInformationSummaryLabel}>Build</DataTableRowColumn>
                                        <DataTableRowColumn>
                                            <ExternalLink href={buildInformation.BuildUrl}>{buildInformation.BuildNumber}</ExternalLink>
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                    {buildInformation && buildInformation.Commits && buildInformation.Commits.length > 0 && (<DataTableRow>
                                            <DataTableRowColumn className={styles.buildInformationSummaryLabel}>Commits</DataTableRowColumn>
                                            <DataTableRowColumn>
                                                <CommitDetails data={buildInformation.Commits}/>
                                            </DataTableRowColumn>
                                        </DataTableRow>)}
                                    <DataTableRow>
                                        <DataTableRowColumn className={styles.buildInformationSummaryLabel}>Work Items</DataTableRowColumn>
                                        <DataTableRowColumn>
                                            <WorkItems data={buildInformation.WorkItems}/>
                                        </DataTableRowColumn>
                                    </DataTableRow>
                                </DataTableBody>
                            </DataTable>
                        </div>
                    </SimpleExpander>
                </div>);
        }
        return;
    };
    workItemSummary = (workItems: WorkItemLink[]) => {
        return workItems && workItems.length > 0 ? `(${workItems.length} work items)` : "";
    };
    renderPackageReference = (pkg: PackageModel) => {
        if (pkg.ProjectName) {
            return (<React.Fragment>
                    {pkg.ActionName}:{pkg.ProjectName}
                </React.Fragment>);
        }
        if (pkg.PackageReferenceName) {
            return (<React.Fragment>
                    {pkg.ActionName}:{pkg.PackageReferenceName}({pkg.PackageId})
                </React.Fragment>);
        }
        return (<React.Fragment>
                {pkg.ActionName}:{pkg.PackageId}
            </React.Fragment>);
    };
    static displayName = "PackagesList";
}
