import type { MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import type { Reducer } from "redux";
import { combineReducers } from "redux";
import type { ActionType } from "typesafe-actions";
import { createAction, getType } from "typesafe-actions";
export const machineActions = {
    machineFetched: createAction("MACHINE_FETCHED", (machine: CurrentMachineState) => machine)(),
    machineSaved: createAction("MACHINE_SAVED", (machine: CurrentMachineState) => machine)(),
    machineCleared: createAction("MACHINE_CLEARED")(),
};
type MachineAction = ActionType<typeof machineActions>;
export interface MachinesState {
    current: CurrentMachineState | null;
}
export interface CurrentMachineState {
    id: string;
    name: string;
    machineIconUrl: string;
    machineType: string;
    isDisabled: boolean;
    isStepPackage: boolean;
    healthStatus: MachineModelHealthStatus;
}
const currentMachineReducer: Reducer<CurrentMachineState | null> = (state = null, action: MachineAction) => {
    switch (action.type) {
        case getType(machineActions.machineFetched):
        case getType(machineActions.machineSaved):
            return action.payload;
        case getType(machineActions.machineCleared):
            return null;
        default:
            return state;
    }
};
export default combineReducers<MachinesState>({ current: currentMachineReducer });
