import type { ProjectResource, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { isRunbookConsumerOnly } from "./isRunbookConsumerOnly";
export function isRunbookConsumerTryingToRunAnUnpublishedSnapshot(project: ProjectResource, runbook: RunbookResource, runbookSnapshotId: string) {
    if (!project || !runbook) {
        return false;
    }
    const isConsumerOnly = isRunbookConsumerOnly(project.Id);
    if (!isConsumerOnly) {
        return false;
    }
    // Consumers must be running a published runbook.
    if (!runbook.PublishedRunbookSnapshotId) {
        return true;
    }
    // Consumers must be running the published snapshot only.
    return runbook.PublishedRunbookSnapshotId !== runbookSnapshotId;
}
