import { css, cx } from "@emotion/css";
import { useSetPageTitlePrimarySegmentEffect } from "@octopusdeploy/design-system-components";
import type { SimpleMenuItem, PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { Fragment } from "react";
import * as React from "react";
import type { ReactElement, ReactNode } from "react";
import { useMediaQuery } from "react-responsive";
import { PortalPageHeaderPrimary } from "../PageHeaderPrimary";
export interface Level1EmptyStateLayoutProps {
    title: string;
    intro: ReactNode;
    actionButtonsAndLinks?: ReactElement[];
    image: ReactElement;
    analyticLocation?: string;
    header?: {
        title: string;
        primaryAction?: PrimaryPageAction;
        actions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
    };
}
export function Level1EmptyStateLayout({ title, intro, image, actionButtonsAndLinks, analyticLocation, header }: Level1EmptyStateLayoutProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    useSetPageTitlePrimarySegmentEffect(title);
    return (<AnalyticLinkLocationProvider location={analyticLocation ?? "Onboarding Page"}>
            <div className={containerStyles}>
                {header && <PortalPageHeaderPrimary title={header.title} actions={header.actions} primaryAction={header.primaryAction} overflowActions={header.overflowActions}/>}
                <section className={cx(onboardingContainerStyles, { [onboardingContainerMobileStyles]: !isLargerThanIpad })}>
                    <div className={cx(infoStyles, { [infoMobileStyles]: !isLargerThanIpad })}>
                        <h1 className={titleStyles}>{title}</h1>
                        {intro}
                        {actionButtonsAndLinks && actionButtonsAndLinks.length !== 0 && (<div className={actionsContainerStyles}>
                                {actionButtonsAndLinks.map((item, index) => (<Fragment key={index}>{item}</Fragment>))}
                            </div>)}
                    </div>
                    <div className={cx(imageContainerStyles, { [imageContainerMobileStyles]: !isLargerThanIpad })}>{image}</div>
                </section>
            </div>
        </AnalyticLinkLocationProvider>);
}
const containerStyles = css({
    display: "flex",
    flexDirection: "column",
    height: "100%",
});
const onboardingContainerStyles = css({
    flex: 1,
    display: "flex",
    gap: space["32"],
    padding: space["32"],
    justifyContent: "center",
    alignItems: "center",
    color: themeTokens.color.text.primary,
});
const onboardingContainerMobileStyles = css({
    flexDirection: "column-reverse",
    padding: "1.5rem 1rem",
});
const infoStyles = css({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: space["24"],
    width: "31.25rem",
    font: text.interface.body.default.base,
});
const infoMobileStyles = css({
    width: "auto",
});
const titleStyles = css({
    all: "unset",
    font: text.interface.heading.large,
});
const actionsContainerStyles = css({
    display: "flex",
    gap: space["24"],
    alignItems: "center",
    flexWrap: "wrap",
});
const imageContainerStyles = css({
    width: "28.75rem",
});
const imageContainerMobileStyles = css({
    width: "auto",
    height: "auto",
});
