import type { PropsWithChildren } from "react";
import React, { useState } from "react";
import { useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { OnboardingQuestionnaireDialogLayout } from "~/areas/projects/components/Projects/OnboardingQuestionnaire/OnboardingQuestionnaireDialog";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import { OnboardingDialogFrame } from "~/components/OnboardingDialog/OnboardingDialog";
import { CreateEnvironmentsPage } from "~/components/ProjectBasedActivation/CreateEnvironmentsDialog";
import questionnaireImage from "~/components/ProjectBasedActivation/assets/img-questionnaire.svg";
export const NewlyCreatedProjectWizardDialog: React.FC<{
    spaceId: string;
    open: boolean;
    close: () => void;
    showOnboardingQuestionnaire: boolean;
    showCreateEnvironments: boolean;
    doBusyTask: DoBusyTask;
}> = ({ spaceId, open, close, showOnboardingQuestionnaire, showCreateEnvironments, doBusyTask, }) => {
    const [page, setPage] = useState<number>(0);
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchAction = useAnalyticActionDispatch();
    const nextPage = () => {
        const hasNextPage = page + 1 < pages.length;
        if (hasNextPage) {
            setPage(page + 1);
        }
        else {
            close();
        }
    };
    const pages: JSX.Element[] = [];
    if (showCreateEnvironments)
        pages.push(<CreateEnvironmentsPage spaceId={spaceId} next={nextPage} trackAction={trackAction} dispatchAction={dispatchAction}/>);
    if (showOnboardingQuestionnaire)
        pages.push(<OnboardingQuestionnairePage close={nextPage}/>);
    if (!pages.length)
        return null;
    return <CustomDialog open={open} close={close} render={() => pages[page]}/>;
};
NewlyCreatedProjectWizardDialog.displayName = "NewlyCreatedProjectWizardDialog"
const OnboardingQuestionnairePage = ({ close }: PropsWithChildren<{
    close: () => void;
}>) => {
    const helpPanelContent = <span>We're looking to learn more about the projects our customers are creating to help us guide the direction of Octopus. Thanks for your time, we really appreciate it.</span>;
    return (<OnboardingDialogFrame name="newly-created-project-wizard" helpPanelImage={{ src: questionnaireImage, altText: "Questionnaire" }} helpPanelContent={helpPanelContent}>
            <OnboardingQuestionnaireDialogLayout close={close}/>
        </OnboardingDialogFrame>);
};
