import { VariableType } from "@octopusdeploy/octopus-server-client";
import type { VariableAccountType } from "@octopusdeploy/octopus-server-client";
export function isAccountType(type: VariableType): type is VariableAccountType {
    switch (type) {
        case VariableType.String:
            return false;
        case VariableType.Sensitive:
            return false;
        case VariableType.Certificate:
            return false;
        case VariableType.WorkerPool:
            return false;
        case VariableType.AmazonWebServicesAccount:
            return true;
        case VariableType.AzureAccount:
            return true;
        case VariableType.GoogleCloudAccount:
            return true;
        case VariableType.UsernamePasswordAccount:
            return true;
    }
    return assertUnreachable(type);
}
function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here - unrecognised variable type");
}
