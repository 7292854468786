import { ActionButton, NavigationButton } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useState } from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import { TabItem, UrlNavigationTabsContainer, ControlledTabsContainer } from "~/primitiveComponents/navigation/Tabs";
import StyleGuideEntry from "./StyleGuideEntry";
enum TabKeys {
    First = "First",
    Second = "Second",
    Third = "Third"
}
const ControlledTabsExample: React.FC = () => {
    const [tab, changeTab] = useState<string>(TabKeys.First);
    return (<React.Fragment>
            <ControlledTabsContainer value={tab} onChange={changeTab}>
                <TabItem label="First Tab" value={TabKeys.First}>
                    <div>This is the first tab and the value is {tab}</div>
                </TabItem>
                <TabItem label="Second Tab" value={TabKeys.Second}>
                    <div>This is the second tab and the value is {tab}</div>
                </TabItem>
                <TabItem label="Third Tab" value={TabKeys.Third}>
                    <div>This is the third tab and the value is {tab}</div>
                </TabItem>
            </ControlledTabsContainer>
            <ActionButton label="Change to Second Tab" onClick={() => changeTab(TabKeys.Second)}/>
        </React.Fragment>);
};
ControlledTabsExample.displayName = "ControlledTabsExample"
const TabHeadingNote: React.FC = () => (<React.Fragment>
        Used to display different <strong>parts</strong> of the <strong>same</strong> thing. If you need to display <strong>different</strong> things that are <strong>related</strong> to each other don't use tabs. Use separate pages and group them
        using <strong>NavigationSidebarLayout</strong>. Have a look at
        <InternalLink to={links.currentUserRootRedirect.generateUrl()}> User Profile</InternalLink> if you need an example.
    </React.Fragment>);
TabHeadingNote.displayName = "TabHeadingNote"
const UrlTabNavigationExample: React.FC = () => {
    return (<UrlNavigationTabsContainer defaultValue="dynamicData1">
            <TabItem label="Dynamic Data1" value="dynamicData1">
                Data that has unpredictable length
            </TabItem>
            <TabItem label="Tab With Warning" warning="We have a problem" value="dynamicData2">
                Some tab item with a warning in the label
            </TabItem>
            <TabItem label="Static data" value="staticData">
                Navigate to tab via URL parameter
                <NavigationButton label="Navigate to Dynamic Data1" href={links.styleGuidePage.generateUrl({ activeTab: "dynamicData1" })}/>
            </TabItem>
        </UrlNavigationTabsContainer>);
};
UrlTabNavigationExample.displayName = "UrlTabNavigationExample"
const controlledTabsEntry = () => (<StyleGuideEntry heading="Tabs fully controlled" context={<div>
                <TabHeadingNote />
                <br />
            </div>}>
        <ControlledTabsExample />
    </StyleGuideEntry>);
const urlNavigationTabsEntry = () => (<StyleGuideEntry heading="Tabs using Navigation" context={<div>
                <TabHeadingNote />
                <br />
                Tabs being controlled by the URL can conflict with one another if multiple exist on the same page. You can use the urlParameterName prop to custommize the name of the query parameter used. Avoid having multiple of these on a single
                page if possible as these modify the history. Things such as the help drawer should use the controlled version of tabs for this very reason. The value of each tab is used as the query parameter value for navigation.
            </div>}>
        <UrlTabNavigationExample />
    </StyleGuideEntry>);
const tabsEntries = () => [controlledTabsEntry(), urlNavigationTabsEntry()];
export default tabsEntries;
