import { css } from "@emotion/css";
import { DatePicker } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import moment from "moment";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import Dialog from "~/components/Dialog/Dialog";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
export interface DateRange {
    startDate: moment.Moment;
    endDate: moment.Moment;
}
interface DatePickerDialogProps {
    dialogOpen: boolean;
    startDate: moment.Moment;
    endDate: moment.Moment;
    onSaveClick(range: {
        startDate: moment.Moment;
        endDate: moment.Moment;
    }): Promise<void>;
    onCancelClick?(): void;
}
interface DatePickerDialogState extends DataBaseComponentState {
    dialogOpen: boolean;
    startDate: moment.Moment;
    endDate: moment.Moment;
}
const datePickerFormat = "MMM dd, yyyy";
export default class DatePickerDialog extends DataBaseComponent<DatePickerDialogProps, DatePickerDialogState> {
    constructor(props: DatePickerDialogProps) {
        super(props);
        this.state = {
            dialogOpen: this.props.dialogOpen,
            startDate: this.props.startDate.clone(),
            endDate: this.props.endDate.clone(),
        };
    }
    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };
    executeCallback = async () => {
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;
        if (this.props.onSaveClick) {
            if (startDate <= endDate) {
                await this.props.onSaveClick({
                    startDate: moment(startDate),
                    endDate: moment(endDate),
                });
                this.closeDialog();
                return true;
            }
            else {
                this.setValidationErrors("\"From Date\" must start before \"To Date\".");
                return false;
            }
        }
        return false;
    };
    notifyCancel = () => {
        if (this.props.onCancelClick) {
            this.props.onCancelClick();
        }
        // Return false, as we don't want the dialog to auto-close
        return false;
    };
    render() {
        return (<Dialog open={this.state.dialogOpen} fullScreen={false}>
                <SaveDialogLayout title="Date Range" busy={this.state.busy} errors={this.errors} onSaveClick={this.executeCallback} onCancelClick={this.notifyCancel}>
                    <DatePickersLayout>
                        <DatePicker label="From Date" variant={"inline"} value={this.state.startDate.toDate()} onChange={(value) => {
                this.setState({ startDate: moment(value) });
            }} format={datePickerFormat}/>
                        <DatePicker label="To Date" variant={"inline"} value={this.state.endDate.toDate()} onChange={(value) => {
                this.setState({ endDate: moment(value) });
            }} format={datePickerFormat}/>
                    </DatePickersLayout>
                </SaveDialogLayout>
            </Dialog>);
    }
    static displayName = "DatePickerDialog";
}
function DatePickersLayout(props: React.PropsWithChildren<{}>) {
    return (<div className={datePickerLayoutStyles}>
            {React.Children.map(props.children, (child) => (<DatePickerContainer>{child}</DatePickerContainer>))}
        </div>);
}
const datePickerLayoutStyles = css({
    display: "flex",
    flexDirection: "row",
    gap: space[16],
    "@media(max-width:768px)": {
        flexDirection: "column",
    },
});
function DatePickerContainer(props: React.PropsWithChildren<{}>) {
    return <div className={datePickerContainerStyles}>{props.children}</div>;
}
const datePickerContainerStyles = css({
    flex: 1,
});
