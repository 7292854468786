/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PackageReference } from "@octopusdeploy/octopus-server-client";
import { PackageSelectionMode } from "@octopusdeploy/octopus-server-client";
export function getChangesToPackageReference(newPackageSelectionMode: PackageSelectionMode): Partial<PackageReference> {
    switch (newPackageSelectionMode) {
        case PackageSelectionMode.Immediate:
            return { Properties: { PackageParameterName: null!, SelectionMode: newPackageSelectionMode } };
        case PackageSelectionMode.Deferred:
            return { PackageId: null!, FeedId: null!, Properties: { SelectionMode: newPackageSelectionMode } };
    }
}
