/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import Task from "~/areas/tasks/components/Task/Task";
import { repository, session } from "~/clientInstance";
import DataBaseComponent from "~/components/DataBaseComponent";
import { PortalPageHeaderPrimary } from "~/components/PageHeaderPrimary";
import { getImportExportMenuItems } from "./ImportExportMenu";
import styles from "./style.module.less";
export class ImportProjectTaskPage extends DataBaseComponent<ImportProjectTaskPageProps, {
    space?: SpaceResource;
}> {
    taskId: string;
    constructor(props: ImportProjectTaskPageProps) {
        super(props);
        this.taskId = this.props.taskId;
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId!)]);
            this.setState({
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        return this.state.space !== undefined && session.currentPermissions!.isSpaceManager(this.state.space);
    }
    render() {
        return (<main>
                <PortalPageHeaderPrimary title="Import project" breadcrumbs={[{ label: "Projects", pageUrl: links.projectsPage.generateUrl({ spaceId: this.props.spaceId }) }]} overflowActions={getImportExportMenuItems(this.props.spaceId)}/>
                {this.state.space !== undefined && !this.isSpaceManager() && (<Callout title="Permissions" type={"danger"}>
                        Only Space Managers can perform an export or import.
                    </Callout>)}
                {this.state.space !== undefined && this.isSpaceManager() && (<div className={styles.container}>
                        <Task taskId={this.taskId} delayRender={() => false}/>
                    </div>)}
            </main>);
    }
    static displayName = "ImportProjectTaskPage";
}
interface ImportProjectTaskPageProps {
    spaceId: string;
    taskId: string;
}
