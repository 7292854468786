import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonConfigurationUserOverview } from "../../CommonComponents/CommonConfigurationUserViews";
export const ConfigurationUsersRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.configuration.users.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonConfigurationUserOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
