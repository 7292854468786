import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonProjectRunbooksOverview } from "../../CommonComponents/CommonProjectRunbooksViews";
export const ProjectOperationsRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().operations.root,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonProjectRunbooksOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => null,
};
