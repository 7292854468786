import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import GitHubIcon from "~/primitiveComponents/dataDisplay/Icon/GitHubIcon";
import OpenIdConnectIcon from "~/primitiveComponents/dataDisplay/Icon/OpenIdConnectIcon";
import type { IssuerType } from "./getIssuerType";
type IssuerTypeLogoProps = {
    issuerType: IssuerType;
    small?: boolean;
};
export function IssuerTypeLogo({ issuerType, small }: IssuerTypeLogoProps) {
    const styles = {
        container: css({
            display: "flex",
            fontSize: small ? space[32] : space[48],
        }),
    };
    return <div className={styles.container}>{issuerType === "GitHubActions" ? <GitHubIcon fontSize="inherit"/> : <OpenIdConnectIcon fontSize="inherit"/>}</div>;
}
