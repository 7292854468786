/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Active, DragEndEvent } from "@dnd-kit/core";
import { DragOverlay, DndContext, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import { useMemo, useState } from "react";
import * as React from "react";
import { createPortal } from "react-dom";
import { CustomMouseSensor, CustomTouchSensor } from "./CustomSensor";
import SortableItem from "./SortableItem";
interface SortableItemModel {
    Id: string;
    Name: string;
}
interface SortableListProps<TItemType> {
    items: TItemType[];
    label?: string;
    renderItem?: (item: TItemType) => React.ReactNode;
    onOrderChanged(sortedItems: TItemType[]): void;
    onItemDeleted?(remainingItems: TItemType[]): void;
    onItemUpdated?(updatedItems: TItemType[]): void;
}
function SortableList<TItemType extends SortableItemModel>(props: SortableListProps<TItemType>) {
    const items = useMemo(() => props.items.map((x) => ({ id: x.Id, value: x })), [props.items]);
    const [active, setActive] = useState<Active | null>(null);
    const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items]);
    const sensors = useSensors(useSensor(CustomMouseSensor), useSensor(CustomTouchSensor));
    const itemMoved = ({ active, over }: DragEndEvent) => {
        if (over && active.id !== over.id) {
            const activeIndex = items.findIndex(({ id }) => id === active.id);
            const overIndex = items.findIndex(({ id }) => id === over.id);
            props.onOrderChanged(arrayMove(items, activeIndex, overIndex).map((x) => x.value));
        }
        setActive(null);
    };
    const itemDeleted = (itemId: string) => {
        if (props.onItemDeleted) {
            const remainingItems = items.filter((x) => x.id != itemId);
            props.onItemDeleted(remainingItems.map((x) => x.value));
        }
    };
    const itemUpdated = (itemId: string, newValue: string) => {
        if (props.onItemUpdated) {
            const orderedItems = items.slice();
            const itemToUpdate = orderedItems.find((x) => x.id === itemId);
            itemToUpdate!.value!.Name = newValue;
            props.onItemUpdated(orderedItems.map((x) => x.value));
        }
    };
    return (<DndContext sensors={sensors} onDragStart={({ active }) => setActive(active)} onDragEnd={itemMoved} onDragCancel={() => setActive(null)}>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
                <ol className={styles.sortableList}>
                    {items.map((item, index) => (<SortableItem key={item.id} id={item.id} name={item.value.Name} label={props.label} customContent={props.renderItem?.(item.value)} onItemUpdated={props.onItemUpdated ? (newValue: string) => itemUpdated(item.id, newValue) : undefined} onItemDeleted={props.onItemDeleted ? () => itemDeleted(item.id) : undefined}/>))}
                </ol>
            </SortableContext>

            {createPortal(<DragOverlay dropAnimation={null} className={styles.dragOverlay}>
                    {activeItem ? (<SortableItem key={activeItem.id} id={activeItem.id} name={activeItem.value.Name} label={props.label} onItemUpdated={props.onItemUpdated ? () => { } : undefined} onItemDeleted={props.onItemDeleted ? () => { } : undefined}/>) : null}
                </DragOverlay>, document.body)}
        </DndContext>);
}
const styles = {
    sortableList: css({
        display: "flex",
        flexDirection: "column",
        gap: space[16],
        listStyle: "none",
        touchAction: "none",
    }),
    dragOverlay: css({
        opacity: "0.8",
        zIndex: "9999 !important",
    }),
};
export default SortableList;
export { SortableItemModel };
