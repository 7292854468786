import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useAnalyticsViewHelpDispatch } from "~/analytics/Analytics";
import InternalLink from "~/components/Navigation/InternalLink";
import type { Placement } from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
export const ProjectGitRepositorySourceContextualHelp = ({ position = "right-start", absolutePosition = false, project }: {
    position?: Placement;
    absolutePosition?: boolean;
    project?: ProjectResource;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    const versionControlSettingsElement = project ? <InternalLink to={links.projectVersionControlSettingsPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}>version control settings</InternalLink> : "version control settings";
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "Project git repository" })} placement={position} absolutePosition={absolutePosition}>
            <p>The commit associated with the release will be used to obtain the files from the repository configured in {versionControlSettingsElement}.</p>
        </PopoverHelp>);
};
export const ExternalGitRepositorySourceContextualHelp = ({ position = "right-start", absolutePosition = false }: {
    position?: Placement;
    absolutePosition?: boolean;
}) => {
    const dispatchAction = useAnalyticsViewHelpDispatch();
    return (<PopoverHelp trigger="click" onOpen={() => dispatchAction("View Contextual Help Popover", { context: "External git repository" })} placement={position} absolutePosition={absolutePosition}>
            <p>The tip of the selected repository and branch will be used to obtain the files.</p>
        </PopoverHelp>);
};
