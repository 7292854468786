import * as React from "react";
import { CommonInsightsReportHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsMeanTimeToRecoveryOverview, CommonInsightsMeanTimeToRecoveryTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsMeanTimeToRecoveryViews";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration } from "../../../Registry/pageHelpRegistry";
export const InsightsReportMeanTimeToRecoveryRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.insights.report.timeToRecovery,
    renderOverview: (props) => (<>
            <CommonInsightsMeanTimeToRecoveryOverview /> <CommonInsightsReportHelp />
        </>),
    renderTopics: (props) => <CommonInsightsMeanTimeToRecoveryTopics />,
};
