import * as React from "react";
import styles from "./style.module.less";
interface ColorSwatchProps {
    colorName: string;
    colorValue: string;
}
class ColorSwatch extends React.Component<ColorSwatchProps> {
    constructor(props: ColorSwatchProps) {
        super(props);
    }
    render() {
        return (<div className={styles.colorSwatch}>
                <div className={styles.swatch} style={{ background: this.props.colorValue }}/>
                <div className={styles.colorName}>{this.props.colorName}</div>
                <div className={styles.colorValue}>{this.props.colorValue}</div>
            </div>);
    }
    static displayName = "ColorSwatch";
}
export default ColorSwatch;
