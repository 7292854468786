/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import type { FeedResource, HelmFeedResource } from "@octopusdeploy/octopus-server-client";
import { FeedType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import BasicFeedCredentialsFormSection from "../BasicFeedCredentialsFormSection";
import type { FeedEditProps } from "./feedTypeRegistry";
import feedTypeRegistry from "./feedTypeRegistry";
class HelmFeed extends React.Component<FeedEditProps<HelmFeedResource>> {
    render() {
        return (<React.Fragment>
                <BasicFeedCredentialsFormSection model={{ username: this.props.feed.Username!, password: this.props.feed.Password! }} onChanged={(model) => this.props.onChange({ ...this.props.feed, Password: model.password, Username: model.username })} containerKey={this.props.expandableContainerKey}/>
            </React.Fragment>);
    }
    static displayName = "HelmFeed";
}
feedTypeRegistry.registerFeedType({
    type: FeedType.Helm,
    edit: HelmFeed as React.ComponentType<FeedEditProps<FeedResource>>,
    text: "Helm Feed",
    uriNotes: <span>The uri to your helm repository.</span>,
    uriDefault: "https://charts.helm.sh/stable",
});
