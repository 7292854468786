import type { AnalyticsEvent, AnalyticsEventPageProperties } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
interface CancelProjectSurveyAnalyticsEvent extends AnalyticsEvent<"Cancel project survey">, AnalyticsEventPageProperties {
    "Action Type": "Cancel";
    "Resource Type": "Survey";
}
export function createCancelProjectSurveyAnalyticsEvent(page: PageDefinition): CancelProjectSurveyAnalyticsEvent {
    return {
        eventName: "Cancel project survey",
        "Action Type": "Cancel",
        "Resource Type": "Survey",
        "Page Area": page.Area,
        "Page Name": page.Name,
    };
}
interface DismissProjectSurveyAnalyticsEvent extends AnalyticsEvent<"Dismiss project survey">, AnalyticsEventPageProperties {
    "Action Type": "Dismiss";
    "Resource Type": "Survey";
}
export function createDismissProjectSurvey(page: PageDefinition): DismissProjectSurveyAnalyticsEvent {
    return {
        eventName: "Dismiss project survey",
        "Action Type": "Dismiss",
        "Resource Type": "Survey",
        "Page Area": page.Area,
        "Page Name": page.Name,
    };
}
interface SubmitProjectSurveyAnalyticsEvent extends AnalyticsEvent<"Submit project survey">, AnalyticsEventPageProperties {
    "Action Type": "Save";
    "Resource Type": "Survey";
    "Technology Stack": string | undefined;
    "Build Server": string | undefined;
    "Package Host": string | undefined;
    "Hosting Platform": string | undefined;
    "Runbook Only": "True" | "False" | undefined;
}
export function createSubmitProjectSurveyAnalyticsEvent(page: PageDefinition, questionnaireData: QuestionnaireData): SubmitProjectSurveyAnalyticsEvent {
    return {
        eventName: "Submit project survey",
        "Action Type": "Save",
        "Resource Type": "Survey",
        "Page Area": page.Area,
        "Page Name": page.Name,
        "Technology Stack": questionnaireData.technologyStack,
        "Build Server": questionnaireData.buildServer,
        "Package Host": questionnaireData.packageHost,
        "Hosting Platform": questionnaireData.hostingPlatform,
        "Runbook Only": questionnaireData.runbookOnly === undefined ? undefined : questionnaireData.runbookOnly ? "True" : "False",
    };
}
export interface QuestionnaireData {
    technologyStack: string | undefined;
    buildServer: string | undefined;
    packageHost: string | undefined;
    hostingPlatform: string | undefined;
    runbookOnly: boolean | undefined;
}
