import React from "react";
import PaperLayout from "~/components/PaperLayout";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
import { Level2PageLayout } from "~/routing/pageRegistrations/Level2PageLayout";
import type { Level2PageLayoutProps } from "~/routing/pageRegistrations/Level2PageLayoutProps";
export function PaperLayoutVNext(props: Level2PageLayoutProps) {
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    const PageLayout = isVerticalNavigationEnabled ? Level2PageLayout : PaperLayout;
    return <PageLayout {...props}/>;
}
