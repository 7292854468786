/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/init-declarations */
import { NavigationButton, NavigationButtonType, Tooltip } from "@octopusdeploy/design-system-components";
import type { RunbookSnapshotResource, RunbookResource, RunbookRunTemplateResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { PermissionCheck } from "~/components/PermissionCheck";
import StringHelper from "~/utils/StringHelper";
import styles from "./PublishButton.module.less";
import { useRunbookContext } from "./RunbookContext";
export const publishingExplainedElement = <span>Publishing makes the runbook available to consumers and triggers.</span>;
export const processTabErrorElement = <span>The runbook can not be published until the error on the Process tab is resolved.</span>;
interface PublishedRunbookSnapshotAndTemplate {
    publishedRunbookSnapshot: RunbookSnapshotResource;
    runbookRunTemplate: RunbookRunTemplateResource;
}
export const usePublishedRunbookSnapshotSetup = (doBusyTask: DoBusyTask, runbook: RunbookResource) => {
    const [state, setState] = React.useState<PublishedRunbookSnapshotAndTemplate | null>(null);
    const publishedRunbookSnapshotId = runbook.PublishedRunbookSnapshotId;
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (!publishedRunbookSnapshotId)
            return;
        const publishedRunbookSnapshot = await repository.RunbookSnapshots.get(publishedRunbookSnapshotId);
        const runbookRunTemplate = await repository.RunbookSnapshots.getRunbookRunTemplate(publishedRunbookSnapshot);
        setState({ publishedRunbookSnapshot, runbookRunTemplate });
    }, [publishedRunbookSnapshotId]);
    return state;
};
export interface PublishButtonProps {
    isDisabled?: boolean;
    publishedRunbookSnapshot?: RunbookSnapshotResource;
    isRunbookRunTemplateModified?: boolean;
}
const PublishButton: React.FC<PublishButtonProps> = ({ isDisabled, publishedRunbookSnapshot, isRunbookRunTemplateModified }) => {
    const projectContext = useProjectContext();
    const runbookContext = useRunbookContext();
    const project = projectContext.state.model;
    const runbook = runbookContext.state.runbook;
    const hasGitVariablesError = !!projectContext.state.gitVariablesHasError;
    const toolTipContent = (<>
            {publishingExplainedElement} {hasGitVariablesError ? processTabErrorElement : null}
        </>);
    const publishButton = (<PermissionCheck permission={Permission.RunbookEdit} project={project.Id} wildcard={true}>
            <div className={styles.container}>
                <Tooltip content={toolTipContent}>
                    <NavigationButton label={"Publish..."} href={links.projectRunbookSnapshotCreatePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook!.Id })} type={NavigationButtonType.Ternary} disabled={isDisabled || hasGitVariablesError}/>
                </Tooltip>
            </div>
        </PermissionCheck>);
    if (!runbook?.PublishedRunbookSnapshotId) {
        return publishButton;
    }
    if (!publishedRunbookSnapshot) {
        return <div>{StringHelper.ellipsis}</div>;
    }
    return isRunbookRunTemplateModified ? publishButton : null;
};
PublishButton.displayName = "PublishButton"
export default PublishButton;
