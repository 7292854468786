import React from "react";
import { LearnMoreResources, OverviewContainer, OverviewHighlightText, TopicsContainer } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonViews";
import ExternalLink from "~/components/Navigation/ExternalLink";
export const CommonInsightsDeploymentDurationOverview = () => (<OverviewContainer>
        <OverviewHighlightText>
            <p>
                <b>Successful deployment duration</b> is the mean run time for successful deployments that match the filter criteria.
            </p>
        </OverviewHighlightText>
        <OverviewHighlightText>
            <p>
                <b>How it's calculated</b>
                <br />
                The run time is the time from the start of the deployment until it completes, including any manual intervention time. The value shown is the average (mean) of all successful deployments that completed in the time period that match the
                filter. filter.
            </p>
        </OverviewHighlightText>
        <OverviewHighlightText>
            <p>
                <b>Deployment queued duration</b> is the mean queue time for deployments that match the filter criteria.
            </p>
        </OverviewHighlightText>
        <OverviewHighlightText>
            <p>
                <b>How it's calculated</b>
                <br />
                The queue time is the time from when the deployment is queued until the time it starts executing. If a deployment is scheduled to execute in the future, that future time is used. The value shown is the average (mean) for all
                deployments started in that time period that match the filter.
            </p>
        </OverviewHighlightText>
    </OverviewContainer>);
export const CommonInsightsDeploymentDurationTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="InsightsDeploymentDuration">Deployment duration</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
