import { ControlType, VariableType } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import type { VariableSelectProps } from "~/components/form/VariableSelect/VariableSelect";
import { VariableSelect } from "~/components/form/VariableSelect/VariableSelect";
import { loadProjectVariableNames } from "~/utils/LoadProjectVariables/loadProjectVariables";
type AccountVariableSelectProps = Omit<VariableSelectProps, "fetchVariables">;
function AwsAccountVariableSelect(props: AccountVariableSelectProps) {
    return <VariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, props.gitRef, [VariableType.AmazonWebServicesAccount], [ControlType.AmazonWebServicesAccount])} {...props}/>;
}
function AzureAccountVariableSelect(props: AccountVariableSelectProps) {
    return <VariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, props.gitRef, [VariableType.AzureAccount], [ControlType.AzureAccount])} {...props}/>;
}
export function GoogleCloudAccountVariableSelect(props: AccountVariableSelectProps) {
    return <VariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, props.gitRef, [VariableType.GoogleCloudAccount], [ControlType.GoogleCloudAccount])} {...props}/>;
}
export function UsernamePasswordAccountVariableSelect(props: AccountVariableSelectProps) {
    return <VariableSelect fetchVariables={() => loadProjectVariableNames(props.projectId, props.gitRef, [VariableType.UsernamePasswordAccount], [ControlType.UsernamePasswordAccount])} {...props}/>;
}
export const AwsBoundAccountVariableSelect = withBoundField(AwsAccountVariableSelect);
export const AzureBoundAccountVariableSelect = withBoundField(AzureAccountVariableSelect);
export const UsernamePasswordBoundAccountVariableSelect = withBoundField(UsernamePasswordAccountVariableSelect);
