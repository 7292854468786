import type { EventAgentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { EventAgentChip, MissingChip, ChipIcon } from "~/components/Chips";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
import type { SelectItem } from "../VirtualListWithKeyboard/SelectItem";
interface EventAgentMultiSelectProps extends FormFieldProps<string[]> {
    items: EventAgentResource[];
    label?: string | JSX.Element;
    error?: string;
}
const EventAgentTypedMultiSelect = MultiSelect<EventAgentResource>();
const EventAgentMultiSelect: React.FC<EventAgentMultiSelectProps> = (props) => {
    const chipRenderer = (r: EventAgentResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <EventAgentChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} eventAgent={item}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.EventAgent} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <EventAgentTypedMultiSelect fieldName="event agents" renderChip={chipRenderer} {...props}/>;
};
EventAgentMultiSelect.displayName = "EventAgentMultiSelect"
export default EventAgentMultiSelect;
