import { css, cx } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { useAnalyticSession } from "@octopusdeploy/portal-analytics";
import { getPageFields, usePage } from "@octopusdeploy/portal-routes";
import React, { useEffect, useRef, useState } from "react";
import { Action } from "~/analytics/Analytics";
import useLocalStorage from "~/hooks/useLocalStorage";
const minimumContentWidth = 880;
const minimumSidebarWidth = 320;
export function useDispatchHelpSidebarUserChangedWidthEvent() {
    const session = useAnalyticSession();
    const page = usePage();
    return (newWidth: string) => {
        session.track("Help Sidebar: User Changed Width", {
            "New Width": parseInt(newWidth, 10),
            "Resource Type": "Help Sidebar Handle",
            "Action Type": Action.Configure,
            ...getPageFields(page),
        });
    };
}
export function HelpSidebarHandle() {
    const [dragging, setDragging] = useState<boolean>(false);
    const dispatchEvent = useDispatchHelpSidebarUserChangedWidthEvent();
    const root = document.documentElement;
    const drawerWidthCssVar = "--help-sidebar-width";
    const [userPreferenceSidebarWidth, setUserPreferenceSidebarWidth] = useLocalStorage<string | undefined>(`Octopus.HelpSidebar.Width`, undefined);
    const animationFrameRef = useRef<number | null>(null);
    const lastEventRef = useRef<MouseEvent | TouchEvent | null>(null);
    const handlePointerDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
        e.persist();
        setDragging(true);
        root.style.cursor = "col-resize";
        root.style.userSelect = "none";
        root.style.pointerEvents = "none";
        root.style.webkitUserSelect = "none"; // Safari still doesn't support user-select
    };
    useEffect(() => {
        const handleResize = () => {
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            animationFrameRef.current = requestAnimationFrame(() => {
                const viewportWidth = window.innerWidth;
                const currentSidebarWidth = parseInt(root.style.getPropertyValue(drawerWidthCssVar), 10);
                const contentWidth = viewportWidth - currentSidebarWidth;
                if (contentWidth < minimumContentWidth) {
                    const potentialSidebarWidth = viewportWidth - minimumContentWidth;
                    const newSidebarWidth = potentialSidebarWidth < minimumSidebarWidth ? minimumSidebarWidth : potentialSidebarWidth;
                    root.style.setProperty(drawerWidthCssVar, `${newSidebarWidth}px`);
                    setUserPreferenceSidebarWidth(`${newSidebarWidth}px`);
                }
            });
        };
        window.addEventListener("resize", handleResize);
        if (userPreferenceSidebarWidth !== undefined) {
            root.style.setProperty(drawerWidthCssVar, userPreferenceSidebarWidth);
        }
        return () => {
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            window.removeEventListener("resize", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const updateSidebarWidth = () => {
        const event = lastEventRef.current;
        if (event && dragging) {
            const viewportWidth = root.clientWidth;
            const pointerXPosition = "touches" in event ? event.touches[0].clientX : event.clientX;
            const spaceBetweenPointerAndViewPortEdge = viewportWidth - pointerXPosition;
            let potentialSidebarWidth = spaceBetweenPointerAndViewPortEdge >= minimumSidebarWidth ? spaceBetweenPointerAndViewPortEdge : minimumSidebarWidth;
            if (viewportWidth - potentialSidebarWidth <= minimumContentWidth) {
                potentialSidebarWidth = viewportWidth - minimumContentWidth;
            }
            const newSidebarWidth = potentialSidebarWidth < minimumSidebarWidth ? minimumSidebarWidth : potentialSidebarWidth;
            root.style.setProperty(drawerWidthCssVar, `${newSidebarWidth}px`);
        }
        animationFrameRef.current = null;
    };
    useEffect(() => {
        if (!dragging)
            return;
        const handlePointerMove = (e: MouseEvent | TouchEvent) => {
            lastEventRef.current = e;
            if (!animationFrameRef.current) {
                animationFrameRef.current = requestAnimationFrame(updateSidebarWidth);
            }
        };
        const handlePointerUp = () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
                animationFrameRef.current = null;
            }
            const lastSidebarWidth = getComputedStyle(root).getPropertyValue(drawerWidthCssVar);
            setDragging(false);
            root.style.cursor = "auto";
            root.style.userSelect = "auto";
            root.style.webkitUserSelect = "auto"; // Safari still doesn't support user-select
            root.style.pointerEvents = "auto";
            if (lastSidebarWidth !== userPreferenceSidebarWidth) {
                setUserPreferenceSidebarWidth(lastSidebarWidth);
                dispatchEvent(lastSidebarWidth);
            }
        };
        window.addEventListener("mousemove", handlePointerMove);
        window.addEventListener("mouseup", handlePointerUp);
        window.addEventListener("touchmove", handlePointerMove);
        window.addEventListener("touchend", handlePointerUp);
        return () => {
            window.removeEventListener("mousemove", handlePointerMove);
            window.removeEventListener("mouseup", handlePointerUp);
            window.removeEventListener("touchmove", handlePointerMove);
            window.removeEventListener("touchend", handlePointerUp);
        };
    });
    return (<div className={cx(styles.base, { [styles.whileDragging]: dragging })} onMouseDown={handlePointerDown} onTouchStart={handlePointerDown}>
            <div className={styles.lineVisual}/>
        </div>);
}
const visibleHandleWidth = 3;
const invisibleHandleWidth = 8;
const styles = {
    base: css({
        position: "relative",
        left: `${(invisibleHandleWidth + visibleHandleWidth + invisibleHandleWidth) / 2}px`,
        height: "100%",
        width: `${invisibleHandleWidth + visibleHandleWidth + invisibleHandleWidth}px`,
        cursor: "col-resize",
        zIndex: 10, // Needs to be higher than help sidebar (<ContextualHelpDrawer />) but less than nav bar
        "&:hover": {
            div: { backgroundColor: themeTokens.color.border.selected },
        },
    }),
    whileDragging: css({
        div: { backgroundColor: themeTokens.color.border.selected },
    }),
    lineVisual: css({
        position: "relative",
        left: `${invisibleHandleWidth - visibleHandleWidth + 1}px`,
        height: "100%",
        width: `${visibleHandleWidth}px`,
        transition: "border-color 166ms ease-out",
        transitionDelay: "66ms",
    }),
};
