import { css } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import React from "react";
export const CloseButton = ({ onClick }: {
    onClick: () => void;
}) => (<div className={closeIconStyles} onClick={onClick}>
        <Tooltip content={<div className={tooltipContentStyles}> Once dismissed, project status can’t be re-opened</div>} position="top" distance={25}>
            <em className="fa fa-times-circle"/>
        </Tooltip>
    </div>);
const closeIconStyles = css({
    color: colorScales.grey[500],
    fill: colorScales.grey[500],
    fontSize: "20px",
    marginLeft: "auto",
    cursor: "pointer",
});
const tooltipContentStyles = css({
    position: "relative",
    bottom: "4px",
    width: "174px",
    height: "32px",
    marginTop: "2px",
    marginBottom: "2px",
});
