import React from "react";
import ImageWithPlaceholder from "~/components/GettingStarted/ImageWithPlaceholder/ImageWithPlaceholder";
import ExternalLink from "~/components/Navigation/ExternalLink";
import HelpImageTenants from "../../Images/Tenants.svg";
import { OverviewContainer, OverviewHighlightText, LearnMoreResources, TopicsContainer } from "./CommonViews";
export const CommonTenantOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="Tenants">Tenants</ExternalLink> allow you to easily create customer specific deployment pipelines without duplicating project configuration.
                </p>
                <ImageWithPlaceholder src={HelpImageTenants} alt={"Tenants"}/>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonTenantTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="MultiTenantDeployments">Multi-tenant Deployments</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
