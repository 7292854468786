import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonProjectRunbookOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="OnboardingRunbooksLearnMore">Runbooks</ExternalLink> can be used to automate routine or emergency operations-centric processes, for instance, disaster recovery and database backups.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
