import type { EnvironmentResource, ProjectSummaryResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import type { TenantTagsLookup, TagWithSet } from "~/components/tenantTagsets";
import * as tenantTagSets from "~/components/tenantTagsets";
export interface TenantFiltersData extends TenantTagsLookup {
    projects: Map<string, ProjectSummaryResource>;
    environments: Map<string, EnvironmentResource>;
}
export const useTenantFiltersData = (doBusyTask: DoBusyTask): TenantFiltersData | null => {
    const [projects, setProjects] = useState<Map<string, ProjectSummaryResource> | null>(null);
    const [environments, setEnvironments] = useState<Map<string, EnvironmentResource> | null>(null);
    const [tagSets, setTagSets] = useState<Map<string, TagSetResource> | null>(null);
    const [tags, setTags] = useState<Map<string, TagWithSet> | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const [projects, environments, tagsData] = await Promise.all([loadProjects(), loadEnvironments(), tenantTagSets.loadTenantTagsLookup()]);
        setProjects(projects);
        setEnvironments(environments);
        setTagSets(tagsData.tagSets);
        setTags(tagsData.tags);
    }, []);
    return projects && environments && tagSets && tags ? { projects, environments, tagSets, tags } : null;
};
export const useTenantTagsLookup = (doBusyTask: DoBusyTask): TenantTagsLookup => {
    const [tagSets, setTagSets] = useState<Map<string, TagSetResource>>(new Map());
    const [tags, setTags] = useState<Map<string, TagWithSet>>(new Map());
    useDoBusyTaskEffect(doBusyTask, async () => {
        const tagsData = await tenantTagSets.loadTenantTagsLookup();
        setTagSets(tagsData.tagSets);
        setTags(tagsData.tags);
    }, []);
    return { tagSets, tags };
};
const loadProjects = async (): Promise<Map<string, ProjectSummaryResource>> => {
    if (!hasPermission(Permission.ProjectView)) {
        return new Map();
    }
    const all = await repository.Projects.summaries();
    return all.reduce((lookup, project) => lookup.set(project.Id, project), new Map<string, ProjectSummaryResource>());
};
const loadEnvironments = async (): Promise<Map<string, EnvironmentResource>> => {
    if (!hasPermission(Permission.EnvironmentView)) {
        return new Map();
    }
    const all = await repository.Environments.all();
    return all.reduce((lookup, env) => lookup.set(env.Id, env), new Map<string, EnvironmentResource>());
};
