import type { CertificateUsageResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
export interface CertificateUsageEntry {
    ownerName: string;
    ownerLink: LinkHref;
    ownerType: string;
}
export const certificateUsageSummary = (usage: CertificateUsageResource) => {
    const certificateUsages = [
        ...usage.ProjectUsages.map((projectUsage) => {
            const item: CertificateUsageEntry = {
                ownerName: projectUsage.Name,
                ownerLink: links.projectRootRedirect.generateUrl({ spaceId: projectUsage.SpaceId, projectSlug: projectUsage.Slug }),
                ownerType: "Project",
            };
            return item;
        }),
        ...usage.LibraryVariableSetUsages.map((lvsUsage) => {
            const item: CertificateUsageEntry = {
                ownerName: lvsUsage.Name,
                ownerLink: links.editVariableSetPage.generateUrl({ spaceId: lvsUsage.SpaceId, variableSetId: lvsUsage.Id }),
                ownerType: "Variable Set",
            };
            return item;
        }),
        ...usage.TenantUsages.map((tenantUsage) => {
            const item: CertificateUsageEntry = {
                ownerName: tenantUsage.Name,
                ownerLink: links.tenantVariablesPage.generateUrl({ tenantId: tenantUsage.Id, spaceId: tenantUsage.SpaceId }),
                ownerType: "Tenant",
            };
            return item;
        }),
        ...usage.DeploymentTargetUsages.map((deploymentTarget) => {
            const item: CertificateUsageEntry = {
                ownerName: deploymentTarget.Name,
                ownerLink: links.deploymentTargetSettingsPage.generateUrl({ spaceId: deploymentTarget.SpaceId, machineId: deploymentTarget.Id }),
                ownerType: "Deployment Target",
            };
            return item;
        }),
    ];
    return certificateUsages;
};
