import type { AccountResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import useRequiredContext from "~/hooks/useRequiredContext";
type ProcessAccountsContextValue = AccountResource[];
type ProcessAccountsRefreshContextValue = () => Promise<unknown>;
const ProcessAccountsContext = React.createContext<ProcessAccountsContextValue>([]);
const RefreshProcessAccountsContext = React.createContext<ProcessAccountsRefreshContextValue | undefined>(undefined);
export function useAccountsFromContext(): ProcessAccountsContextValue {
    return useRequiredContext(ProcessAccountsContext, "ProcessAccountsContext");
}
export function useRefreshAccountsFromContext(): ProcessAccountsRefreshContextValue {
    return useRequiredContext(RefreshProcessAccountsContext, "RefreshProcessAccountsContext");
}
type ProcessAccountsContextProviderProps = {
    accounts: AccountResource[];
    refreshAccounts: () => Promise<unknown>;
};
export function ProcessAccountsContextProvider(props: React.PropsWithChildren<ProcessAccountsContextProviderProps>) {
    return (<ProcessAccountsContext.Provider value={props.accounts}>
            <RefreshProcessAccountsContext.Provider value={props.refreshAccounts}>{props.children}</RefreshProcessAccountsContext.Provider>
        </ProcessAccountsContext.Provider>);
}
