import * as React from "react";
import { CommonProjectInsightsHelp } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightViews";
import { CommonInsightsMeanTimeToRecoveryOverview, CommonInsightsMeanTimeToRecoveryTopics } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registrations/CommonComponents/CommonInsightsMeanTimeToRecoveryViews";
import type { CategorizedPageHelpRegistration } from "~/components/ContextualHelpLayout/PageHelpRegistry/Registry/pageHelpRegistry";
import pageIds from "~/pageIds";
export const ProjectInsightsMeanTimeToRecoveryRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.project().insights.timeToRecovery,
    renderOverview: (props) => (<>
            <CommonInsightsMeanTimeToRecoveryOverview /> <CommonProjectInsightsHelp />
        </>),
    renderTopics: (props) => <CommonInsightsMeanTimeToRecoveryTopics />,
};
