import { EmptyStateProcessIllustration, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import ActionList from "~/components/ActionList/ActionList";
import OnboardingPage from "~/components/GettingStarted/OnboardingPage";
interface OnboardingProps {
    spaceId: string;
    blueprintId: string;
}
const Onboarding = ({ spaceId, blueprintId }: OnboardingProps) => {
    const createProcessHref = links.editBlueprintPage.generateUrl({ blueprintId, spaceId }, { stepTemplates: true });
    return (<OnboardingPage title="Create your blueprint process" intro={`The blueprint process is a group of steps that are reusable for any project within this space.`} learnMore={<></>} image={<EmptyStateProcessIllustration />} actionButtons={<ActionList alignStart={true} actions={[<NavigationButton label={"Create Process"} href={createProcessHref} type={NavigationButtonType.Primary}/>]}/>}/>);
};
export default Onboarding;
