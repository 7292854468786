import * as React from "react";
import Tasks from "~/areas/tasks/components/Tasks/Tasks";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
interface TenantTasksProps {
    tenantId: string;
}
export const TenantTasksPage: React.FC<TenantTasksProps> = ({ tenantId }) => {
    return (<Tasks restrictToTenantId={tenantId} renderLayout={({ busy, errors, hasLoadedOnce, children }) => {
            return (<PaperLayoutVNext title={"Tasks"} busy={busy} enableLessIntrusiveLoadingIndicator={hasLoadedOnce} errors={errors} fullWidth={true}>
                        {children}
                    </PaperLayoutVNext>);
        }}/>);
};
TenantTasksPage.displayName = "TenantTasksPage"
