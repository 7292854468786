import type { DynamicEnvironmentOverviewProjectState } from "@octopusdeploy/octopus-server-client";
export function getDynamicEnvironmentProjectStateDescription(state: DynamicEnvironmentOverviewProjectState) {
    switch (state) {
        case "Provisioning":
            return "Provisioning";
        case "Provisioned":
            return "Provisioned";
        case "Deprovisioned":
            return "Deprovisioned";
        case "Deprovisioning":
            return "Deprovisioning";
        case "ProvisioningFailed":
            return "Provisioning Error";
        case "DeprovisioningFailed":
            return "Deprovisioning Error";
        default:
            return "Unknown";
    }
}
