/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-non-null-assertion */
import type { DeployNewReleaseActionResource, EnvironmentResource, LifecycleResource, PhaseResource } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import { useCallback, useMemo } from "react";
import * as React from "react";
import { EnvironmentChip, ChipIcon } from "~/components/Chips";
import LookupResourceChipComponent from "~/components/LookupResourceChip";
import { ExpandableFormSection, Select, Summary } from "~/components/form";
interface DeployNewReleaseActionEditorProps {
    action: DeployNewReleaseActionResource;
    allEnvironments: EnvironmentResource[];
    lifecycle: LifecycleResource;
    onActionChange(action: DeployNewReleaseActionResource): void;
}
const LookupEnvironmentChip = LookupResourceChipComponent<EnvironmentResource>();
export const DeployNewReleaseActionEditor: React.FC<DeployNewReleaseActionEditorProps> = ({ action, allEnvironments, lifecycle, onActionChange }) => {
    const environmentId = useMemo(() => action.EnvironmentId, [action]);
    const phaseEnvironments = React.useMemo(() => {
        const environmentNameMap: Record<string, string> = {};
        allEnvironments.forEach((env) => {
            environmentNameMap[env.Id] = env.Name;
        });
        return getEnvironmentsWithSimulatedReleaseProgression(lifecycle.Phases).map((e) => ({ value: e, text: environmentNameMap[e] }));
    }, [allEnvironments, lifecycle.Phases]);
    const onDestinationEnvironmentChange = useCallback((destinationEnvironmentId: string | undefined) => {
        onActionChange({
            ...action,
            EnvironmentId: destinationEnvironmentId!,
        });
    }, [action, onActionChange]);
    const destinationEnvironmentSummary = useMemo(() => environmentId
        ? Summary.summary(<span>
                          New release will be deployed to the{" "}
                          {<LookupEnvironmentChip lookupCollection={allEnvironments} key={environmentId} lookupId={environmentId} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name}/>}/>} environment
                      </span>)
        : Summary.placeholder("No destination environment selected"), [environmentId, allEnvironments]);
    return (<div>
            <ExpandableFormSection errorKey="DestinationEnvironment" title="Destination environment" focusOnExpandAll summary={destinationEnvironmentSummary} help="The environment to deploy the created release to.">
                <Select items={phaseEnvironments} value={environmentId} onChange={onDestinationEnvironmentChange} sortItems={false}/>
            </ExpandableFormSection>
        </div>);
};
DeployNewReleaseActionEditor.displayName = "DeployNewReleaseActionEditor"
const getEnvironmentsWithSimulatedReleaseProgression = (phases: PhaseResource[]): string[] => {
    const firstPhase = phases[0];
    // This can happen if the project has no environments or phases defined
    if (!firstPhase) {
        return [];
    }
    const remainingPhases: PhaseResource[] = [];
    for (const p of phases.slice(1)) {
        // because we don't have a release, we can't get it's 'Progression' calculated from server side,
        // so lets just do better than suggesting only the 'firstPhase'
        // walk the phases till we get to the non-optional one, include it in the list
        remainingPhases.push(p);
        if (!p.IsOptionalPhase) {
            break;
        }
    }
    return _.flatten([
        // seed it with the first phase
        ...firstPhase.AutomaticDeploymentTargets,
        ...firstPhase.OptionalDeploymentTargets,
        // populate with the rest of the phases worked out above
        ...remainingPhases.map((p) => [...p.AutomaticDeploymentTargets, ...p.OptionalDeploymentTargets]),
    ]);
};
