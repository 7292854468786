/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css } from "@emotion/css";
import { Checkbox, SimpleDataTable, Tooltip } from "@octopusdeploy/design-system-components";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentActionPackageResource, DeploymentActionResource, DeploymentStepResource, IProcessResource } from "@octopusdeploy/octopus-server-client";
import { displayName } from "@octopusdeploy/octopus-server-client";
import { flatMap } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import getActionLogoUrl from "~/areas/projects/components/getActionLogoUrl";
import AdvancedFilterLayout from "../AdvancedFilterLayout";
import FilterSearchBox from "../FilterSearchBox";
import Logo from "../Logo";
import NumberedPagingBar from "../PagingBaseComponent/NumberedPagingBar";
import { useThemePaletteType } from "../Theme/useThemePaletteType";
import type { FormFieldProps } from "../form";
class FilterLayout extends AdvancedFilterLayout<DeploymentActionPackageFilter> {
}
interface DeploymentActionPackageFilter {
    searchText: string;
}
export interface DeploymentActionPackageTableSelectItem {
    id: string;
    deploymentActionName: string;
    deploymentActionResource: DeploymentActionResource;
    packageReference?: string;
    packageFriendlyName: string;
    stepActionNumber: string;
}
interface DeploymentActionPackageTableMultiSelectProps extends FormFieldProps<string[]> {
    rowDisabled: (item: DeploymentActionPackageTableSelectItem) => boolean;
    disabledMessage?: (item: DeploymentActionPackageTableSelectItem) => string;
    items: DeploymentActionPackageTableSelectItem[];
}
export const createDeploymentActionPackageTableItemId = (deploymentActionPackageResource: DeploymentActionPackageResource): string => displayName(deploymentActionPackageResource);
export function DeploymentActionPackageTableMultiSelect({ items, value, onChange, rowDisabled, disabledMessage }: DeploymentActionPackageTableMultiSelectProps) {
    const defaultFilter = { searchText: "" };
    const [filter, setFilter] = useState<DeploymentActionPackageFilter>(defaultFilter);
    const [filteredItems, setFilteredItems] = useState(items);
    const [pagedItems, setPagedItems] = useState(items);
    const PAGESIZE = 10;
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    useEffect(() => {
        const searchedItems = items.filter((item) => search(item.deploymentActionName, filter.searchText) || search(item.packageFriendlyName, filter.searchText));
        setFilteredItems(searchedItems);
    }, [items, filter.searchText]);
    useEffect(() => {
        /* This is a separate useEffect hook because we only want to reset the page index when the filter is changed
         * Selecting a checkbox causes the items prop to update as it is rebuilt by the parent of this component
         */
        setCurrentPageIndex(0);
    }, [filter.searchText]);
    useEffect(() => {
        const pagedItems = filteredItems.slice(PAGESIZE * currentPageIndex, PAGESIZE * (currentPageIndex + 1));
        setPagedItems(pagedItems);
    }, [currentPageIndex, filteredItems]);
    const tableSelect = (<SimpleDataTable columns={[
            {
                accessibleName: "Package",
                title: "Package",
                render: (item) => (<SelectablePackage item={item} disabled={rowDisabled(item)} disabledMessage={disabledMessage?.(item)} selected={value.includes(item.id)} onChange={(newValue) => {
                        if (newValue) {
                            onChange(value.concat(item.id));
                        }
                        else {
                            onChange(value.filter((id) => id !== item.id));
                        }
                    }}/>),
            },
            {
                accessibleName: "Step",
                title: "Step",
                render: (item) => <DeploymentActionWithStepNumber item={item} disabled={rowDisabled(item)}/>,
            },
        ]} data={pagedItems} getRowKey={(item) => item.id}/>);
    const searchBox = <FilterSearchBox placeholder="Search by step or package name" value={filter.searchText} onChange={(searchText) => setFilter({ ...filter, searchText })} containerClassName={styles.searchBoxContainer} fullWidth={true}/>;
    return (<>
            <FilterLayout filterSections={[]} filter={filter} defaultFilter={defaultFilter} onFilterReset={() => setFilter(defaultFilter)} additionalHeaderFilters={[searchBox]} renderContent={() => tableSelect}></FilterLayout>
            {filteredItems.length > PAGESIZE && <NumberedPagingBar totalItems={filteredItems.length} currentPageIndex={currentPageIndex} pageSize={PAGESIZE} onPageSelected={(_, index) => setCurrentPageIndex(index)}/>}
        </>);
}
interface SelectablePackageProps {
    item: DeploymentActionPackageTableSelectItem;
    disabled: boolean;
    selected: boolean;
    disabledMessage?: string;
    onChange: (newValue: boolean) => void;
}
function SelectablePackage({ item, disabled, disabledMessage, selected, onChange }: SelectablePackageProps) {
    const content = (<CellWrapper disabled={disabled}>
            <Checkbox value={selected} onChange={onChange} disabled={disabled} label={item.packageFriendlyName} noMargin={true}></Checkbox>
        </CellWrapper>);
    if (disabled && disabledMessage) {
        return <Tooltip content={disabledMessage}>{content}</Tooltip>;
    }
    else {
        return content;
    }
}
interface DeploymentActionWithStepNumberProps {
    item: DeploymentActionPackageTableSelectItem;
    disabled: boolean;
}
function DeploymentActionWithStepNumber({ item, disabled }: DeploymentActionWithStepNumberProps) {
    const darkModeSpacer = useThemePaletteType() === "dark" ? <div className={styles.darkModeSpacer}/> : <></>;
    const logoUrl = getActionLogoUrl(item.deploymentActionResource);
    return (<CellWrapper disabled={disabled}>
            <Logo url={logoUrl} isDisabled={disabled} size={"2rem"}/>
            {darkModeSpacer}
            <span className={styles.actionLabel}>
                {item.stepActionNumber} {item.deploymentActionName}
            </span>
        </CellWrapper>);
}
interface CellWrapperProps {
    disabled: boolean;
    children: React.ReactElement | React.ReactElement[];
}
function CellWrapper({ disabled, children }: CellWrapperProps) {
    return <div className={styles.cellWrapper(disabled)}>{children}</div>;
}
const search = (text: string | undefined, term: string) => text?.toLowerCase().includes(term.toLowerCase());
export const buildItemList = (deploymentProcess: IProcessResource, supportedFeedIds: string[]): DeploymentActionPackageTableSelectItem[] => {
    return flatMap(deploymentProcess.Steps.map((step, stepIndex) => step.Actions.flatMap((action, actionIndex) => action.Packages.filter((pkg) => supportedFeedIds.includes(pkg.FeedId)).map((pkg) => createSelectItem(step, stepIndex, action, actionIndex, { DeploymentAction: action.Name, PackageReference: pkg.Name })))));
};
const createSelectItem = (step: DeploymentStepResource, stepIndex: number, action: DeploymentActionResource, actionIndex: number, pkg: DeploymentActionPackageResource): DeploymentActionPackageTableSelectItem => {
    const primaryPackage = action.Packages.find((pkg) => pkg.Name === "");
    return {
        id: createDeploymentActionPackageTableItemId(pkg),
        deploymentActionName: pkg.DeploymentAction,
        packageReference: pkg.PackageReference,
        deploymentActionResource: action,
        packageFriendlyName: pkg.PackageReference || primaryPackage?.PackageId || "Nameless package",
        stepActionNumber: stepNumber(step, stepIndex, actionIndex),
    };
};
const stepNumber = (step: DeploymentStepResource, stepIndex: number, actionIndex: number) => {
    if (step.Actions.length === 1) {
        return `${stepIndex + 1}.`;
    }
    else {
        return `${stepIndex + 1}.${actionIndex + 1}.`;
    }
};
const styles = {
    searchBoxContainer: css({ minWidth: 275 }),
    cellWrapper: (disabled: boolean) => css({ display: "flex", flexWrap: "nowrap", alignItems: "center", color: disabled ? themeTokens.color.text.disabled : themeTokens.color.text.primary }),
    actionLabel: css({ whiteSpace: "nowrap" }),
    darkModeSpacer: css({ width: space[8] }),
};
