import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonLibraryVariableSetOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="LibraryVariableSets">Variable sets</ExternalLink> are collections of variables that can be be shared between multiple projects.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
