import type { AnalyticsEvent, AnalyticsEventPageProperties } from "@octopusdeploy/portal-analytics";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
import { usePage } from "@octopusdeploy/portal-routes";
import { useCallback } from "react";
export function useTrackGlobalSearchActivated() {
    const trackEvent = useTrackEvent();
    const page = usePage();
    return useCallback((reasonForActivation: ReasonForActivation) => {
        trackEvent(createGlobalSearchActivatedEvent(reasonForActivation, page));
    }, [page, trackEvent]);
}
interface GlobalSearchActivatedEvent extends AnalyticsEvent<"Global search activated">, AnalyticsEventPageProperties {
    "Reason for activation": ReasonForActivation;
}
function createGlobalSearchActivatedEvent(reasonForActivation: ReasonForActivation, page: PageDefinition): GlobalSearchActivatedEvent {
    return {
        eventName: "Global search activated",
        "Page Name": page.Name,
        "Page Area": page.Area,
        "Reason for activation": reasonForActivation,
    };
}
export type ReasonForActivation = "Focused" | "HotKey";
