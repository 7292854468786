import moment from "moment";
import * as React from "react";
import DurationBetweenLabel from "~/components/TimeLabels/DurationBetweenLabel";
interface RetryCountdownProps {
    to: string;
}
export const RetryCountdown: React.FC<RetryCountdownProps> = (props) => {
    const [currentTime, setCurrentTime] = React.useState(moment().toISOString());
    React.useEffect(() => {
        //as we can have multiple of these on the screen at once, we (attempt) to sync the setState
        //so that all times re-render in sync
        const millisecondsUntilNextRoundedSecond = 1000 - new Date().getMilliseconds();
        const timeoutId = setTimeout(() => {
            setCurrentTime(moment().toISOString());
        }, millisecondsUntilNextRoundedSecond);
        return () => clearTimeout(timeoutId);
    }, 
    //we are using a bit of a mind-bending dependency here - each time we set the time
    //we invalidate the effect, causing it to clear the existing timeout and create a new one
    [currentTime]);
    const from = moment(currentTime);
    const to = moment(props.to);
    if (to.diff(from, "seconds") < 1) {
        return <span>Retrying now</span>;
    }
    return (<span>
            Retrying in <DurationBetweenLabel from={currentTime} to={props.to}/>
        </span>);
};
RetryCountdown.displayName = "RetryCountdown"
