import type { ProjectResource, SpaceResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ChipIcon } from "~/components/Chips";
import { MissingChip, SpaceChip } from "~/components/Chips/index";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
import Lookup from "../Lookup";
interface SpaceMultiSelectProps extends FormFieldProps<string[]> {
    items: SpaceResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    placeholder?: string;
    error?: string;
}
const SpaceTypedMultiSelect = MultiSelect<SpaceResource>();
export const SpaceMultiSelect: React.FC<SpaceMultiSelectProps> = (props) => {
    const chipRenderer = (r: ProjectResource | SelectItem, onRequestDelete: () => void) => {
        return (<Lookup lookupCollection={props.items} lookupId={r.Id} getIdFromElement={(element) => element.Id} render={(item) => <SpaceChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} space={item}/>} renderFallback={<MissingChip type={ChipIcon.Space} lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete}/>}/>);
    };
    return <SpaceTypedMultiSelect label={props.label} placeholder={props.placeholder} fieldName="spaces" renderChip={chipRenderer} {...props}/>;
};
SpaceMultiSelect.displayName = "SpaceMultiSelect"
