import type { UserResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "~/components/DialogLayout/OkDialogLayout";
import { UserMultiSelect } from "~/components/MultiSelect/UserMultiSelect";
interface AddMemberDialogButtonState extends DataBaseComponentState {
    userIds: string[];
}
interface AddMemberDialogButtonProps {
    users: UserResource[];
    onSelected(users: string[]): void;
}
class AddMember extends DataBaseComponent<AddMemberDialogButtonProps, AddMemberDialogButtonState> {
    constructor(props: AddMemberDialogButtonProps) {
        super(props);
        this.state = {
            userIds: [],
        };
    }
    render() {
        return (<OkDialogLayout title={this.state.userIds && this.state.userIds.length > 1 ? "Add members" : "Add member"} okButtonLabel="Add" busy={this.state.busy} errors={this.errors} onOkClick={() => this.props.onSelected(this.state.userIds)}>
                <UserMultiSelect items={this.props.users} onChange={(userIds) => this.setState({ userIds })} value={this.state.userIds ? this.state.userIds : []}/>
            </OkDialogLayout>);
    }
    static displayName = "AddMember";
}
export default AddMember;
