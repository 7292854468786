import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { processScopedEditPermission } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import { useProcessQueryStringContext } from "~/areas/projects/components/Process/Contexts/ProcessQueryString/ProcessQueryStringContext";
import { PermissionCheck } from "~/components/PermissionCheck/index";
type AddBlueprintStepButtonProps = {
    projectId: string;
};
export function AddBlueprintStepButton({ projectId }: AddBlueprintStepButtonProps) {
    const processQueryStringContext = useProcessQueryStringContext();
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectId);
    return (<PermissionCheck permission={processScopedEditPermission(ProcessType.Deployment)} project={projectId} wildcard={true}>
            <ActionButton type={ActionButtonType.Secondary} label="Add Blueprint" onClick={() => {
            processQueryStringContext.actions.addBlueprintStep();
            dispatchAction("Add Blueprint Step", { resource: "Deployment Process", action: Action.Add });
        }}/>
        </PermissionCheck>);
}
