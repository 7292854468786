/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Checkbox } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { withBoundField } from "~/components/form/BoundField/BoundField";
import PopoverHelp from "~/primitiveComponents/dataDisplay/PopoverHelp/PopoverHelp";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
import styles from "./style.module.less";
type TimeoutFormSectionProps = {
    enableCheckBox: boolean;
    label: string;
    note: string;
    help: JSX.Element;
    optionEnabled: boolean;
    onOptionEnabledChange: (enabled: boolean) => void;
    value: string;
    onValueChange: (value: string) => void;
    error: string;
    localNames: string[] | undefined;
};
const BoundDebounceText = withBoundField(DebounceText);
const TimeoutFormSection = (props: TimeoutFormSectionProps) => (<div className={styles.timeoutFormSectionContainer}>
        <div className={styles.inputWithHelp}>
            {props.enableCheckBox ? (<div>
                    <Checkbox value={props.optionEnabled} onChange={props.onOptionEnabledChange} label={props.label} noMargin={true}/>
                </div>) : (<div>
                    <span>{props.label}</span>
                </div>)}
            &nbsp;
            <PopoverHelp trigger={"click"} placement={"right-start"}>
                {props.help}
            </PopoverHelp>
        </div>
        {props.optionEnabled && (<BoundDebounceText accessibleName={`Input ${props.label}`} variableLookup={{ localNames: props.localNames }} label={props.note} value={props.value.toString()} onChange={(value) => props.onValueChange(value)} error={props.error}/>)}
    </div>);
export default TimeoutFormSection;
