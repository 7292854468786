/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import NotesIcon from "@material-ui/icons/Notes";
import SmsIcon from "@material-ui/icons/Sms";
import { Tooltip } from "@octopusdeploy/design-system-components";
import * as React from "react";
import styles from "./style.module.less";
export enum CellIcons {
    description = "description",
    multiline = "multiline"
}
interface VariableCellIconProps {
    type: CellIcons;
    description?: string;
    onClick?: () => void;
}
export const VariableCellIcon: React.FC<VariableCellIconProps> = (props) => {
    const icon = getIcon(props.type, props.onClick);
    return (<div className={props.onClick ? `${styles.iconContainer} ${styles.canClick}` : styles.iconContainer} onClick={props.onClick ? props.onClick : undefined}>
            {props.description ? (<div className={styles.iconSizing}>
                    <Tooltip content={props.description}>{icon}</Tooltip>
                </div>) : (icon)}
        </div>);
};
VariableCellIcon.displayName = "VariableCellIcon"
const getIcon = (type: CellIcons, onClick?: () => void) => {
    switch (type) {
        case CellIcons.description:
            return <SmsIcon className={onClick ? styles.highlightedCellIcon : styles.cellIcon}/>;
        case CellIcons.multiline:
            return <NotesIcon className={onClick ? styles.highlightedCellIcon : styles.cellIcon}/>;
        default:
            return <SmsIcon className={onClick ? styles.highlightedCellIcon : styles.cellIcon}/>;
    }
};
export default VariableCellIcon;
