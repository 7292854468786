/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ScopeValues } from "@octopusdeploy/octopus-server-client";
import { flatten, zip } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import { fetchAllAccounts } from "~/areas/infrastructure/reducers/accounts";
import { getSourceLinkName } from "~/areas/variables/SourceLink/SourceLink";
import VariableDisplayer, { mergeAndSortVariables } from "~/areas/variables/VariableDisplayer/VariableDisplayer";
import type { VariableFilter, VariableQuery } from "~/areas/variables/VariableFilter";
import { containsFilter, createEmptyFilter, filterVariableGroups, matchesFilter } from "~/areas/variables/VariableFilter/VariableFilter";
import { default as VariableFilterLayout } from "~/areas/variables/VariableFilterLayout/VariableFilterLayout";
import type { VariableFilterLayoutProps } from "~/areas/variables/VariableFilterLayout/VariableFilterLayout";
import getVariablesMessages from "~/areas/variables/VariableMessages/VariableMessages";
import type { AllVariableMessages, VariableMessages, ValueMessages } from "~/areas/variables/VariableMessages/VariableMessages";
import { AdvancedFilterTextInput } from "~/components/AdvancedFilterLayout";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import type { CellAligner } from "~/primitiveComponents/dataDisplay/ScrollTable/ScrollTable";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import { type VariableWithSource, type AdditionalFilter, type FilteredVariable, type ValueWithSource } from ".";
interface VariableDisplayerFilter extends VariableFilter {
    source: string;
    additional?: string;
}
interface VariableDisplayerQuery extends VariableQuery {
    source?: string;
}
const FilterLayout: React.SFC<VariableFilterLayoutProps<VariableDisplayerFilter>> = (props) => VariableFilterLayout<VariableDisplayerFilter>(props);
FilterLayout.displayName = "FilterLayout"
const VariableQueryStringFilters = QueryStringFilters.For<VariableDisplayerFilter, VariableDisplayerQuery>();
interface FilteredVariableDisplayerProps {
    availableScopes: ScopeValues;
    variableSections: ReadonlyArray<ReadonlyArray<VariableWithSource>>;
    hideSource?: boolean;
    hideScope?: boolean;
    isTenanted?: boolean; // will be overridden in here, if there is tenant related variable scoping
    alwaysShowCheckboxFilters?: boolean;
    hideAdvancedFilters?: boolean;
    doBusyTask: DoBusyTask;
    additionalFilter?: AdditionalFilter;
    sectionHeader?: {
        renderSectionHeader: (sectionIndex: number, cellAligner: CellAligner) => React.ReactNode;
        sectionHeaderRowHeight: number;
    };
    shouldHideSectionContent?(sectionIndex: number): boolean;
    onLoad?(): void;
    disableQueryStringFilters?: boolean;
}
interface FilteredVariableDisplayerState {
    filter: VariableDisplayerFilter;
    queryFilter?: VariableDisplayerFilter;
}
class FilterableVariableDisplayer extends React.Component<FilteredVariableDisplayerProps, FilteredVariableDisplayerState> {
    constructor(props: FilteredVariableDisplayerProps) {
        super(props);
        this.state = {
            filter: { ...createEmptyFilter(), source: "", additional: "" },
        };
    }
    render() {
        const variableSections = this.props.variableSections.map((variables) => mergeAndSortVariables(variables, this.props.availableScopes));
        const variableMessages = variableSections.map((variables) => getVariablesMessages(variables, (v) => v.name, (v) => v.values));
        const filteredVariableSections = variableSections.map((variables, index) => {
            return this.getFilteredVariables(variables, variableMessages[index], index);
        });
        const allVariableMessages: AllVariableMessages = this.combineMessages(variableMessages);
        const queryStringFilters = !this.props.disableQueryStringFilters && (<VariableQueryStringFilters key="queryStringFilters" filter={this.state.filter} getQuery={this.queryFromFilters} getFilter={this.getFilter} onFilterChange={(filter) => this.setState({ filter, queryFilter: filter })}/>);
        return [
            queryStringFilters,
            <FilterLayout key="filterLayout" filter={{ ...this.state.filter, additional: this.props.additionalFilter ? this.props.additionalFilter.value : "" }} queryFilter={this.state.queryFilter!} availableScopes={this.props.availableScopes} defaultFilter={{ ...createEmptyFilter(), source: "", additional: "" }} messages={allVariableMessages} onFilterChanged={(filter) => {
                    this.setState({ filter });
                    if (this.props.additionalFilter) {
                        this.props.additionalFilter.onValueChanged(filter.additional!);
                    }
                }} alwaysShowCheckboxFilters={this.props.alwaysShowCheckboxFilters} hideAdvancedFilters={this.props.hideAdvancedFilters} isTenanted={this.isTenantedOrHasTenantScopingOnVariables(variableSections)} doBusyTask={this.props.doBusyTask} extraFilters={[
                    !this.props.hideSource && <AdvancedFilterTextInput key="sourceFilter" fieldName={"source"} value={this.state.filter.source} onChange={(source) => this.setState({ filter: { ...this.state.filter, source } })}/>,
                    this.props.additionalFilter && <AdvancedFilterTextInput key="additionalFilter" fieldName={this.props.additionalFilter.fieldName} value={this.props.additionalFilter.value} onChange={this.props.additionalFilter.onValueChanged}/>,
                ]} renderContent={(filterPanelIsVisible) => (<VariableDisplayer doBusyTask={this.props.doBusyTask} variableSections={filteredVariableSections} hideScope={this.props.hideScope} hideSource={this.props.hideSource} availableScopes={this.props.availableScopes} isDisplayingFullWidth={!filterPanelIsVisible} sectionHeader={this.props.sectionHeader}/>)}/>,
        ];
    }
    private isTenantedOrHasTenantScopingOnVariables(variableSections: ReadonlyArray<ReadonlyArray<VariableWithSource>>) {
        // if we were told true then just show it, otherwise check for TenantTags
        const variables = flatten(flatten(variableSections.map((s) => [...s]))
            .filter((v: any) => !!v.variables)
            .map((v: any) => v.variables));
        const tenantTagsExist = variables.some((v: any) => !!v.scope.TenantTag && v.scope.TenantTag.length > 0);
        return this.props.isTenanted || tenantTagsExist;
    }
    private combineMessages(variableMessages: AllVariableMessages[]) {
        return variableMessages.reduce((p, c) => {
            return {
                duplicateVariableNames: [...p.duplicateVariableNames, ...c.duplicateVariableNames],
                variableMessages: [...p.variableMessages, ...c.variableMessages],
            };
        }, {
            duplicateVariableNames: [],
            variableMessages: [],
        });
    }
    private queryFromFilters = (filter: VariableDisplayerFilter): VariableDisplayerQuery => {
        const query: VariableDisplayerQuery = {
            name: filter.name,
            value: filter.value,
            description: filter.description,
            source: filter.source,
            filterEmptyValues: filter.filterEmptyValues ? "true" : undefined,
            filterDuplicateNames: filter.filterDuplicateNames ? "true" : undefined,
            filterNonPrintableCharacters: filter.filterNonPrintableCharacters ? "true" : undefined,
            filterVariableSubstitutionSyntax: filter.filterVariableSubstitutionSyntax ? "true" : undefined,
            environment: [...(filter.scope.Environment! as string[])],
            machine: [...(filter.scope.Machine! as string[])],
            role: [...(filter.scope.Role! as string[])],
            action: [...(filter.scope.Action! as string[])],
            channel: [...(filter.scope.Channel! as string[])],
            tenantTag: [...(filter.scope.TenantTag! as string[])],
        };
        return query;
    };
    private getFilter = (query: VariableDisplayerQuery): VariableDisplayerFilter => {
        const filter: VariableDisplayerFilter = {
            name: query.name || "",
            value: query.value || "",
            description: query.description || "",
            source: query.source || "",
            filterEmptyValues: query.filterEmptyValues === "true",
            filterDuplicateNames: query.filterDuplicateNames === "true",
            filterNonPrintableCharacters: query.filterNonPrintableCharacters === "true",
            filterVariableSubstitutionSyntax: query.filterVariableSubstitutionSyntax === "true",
            scope: {
                Environment: arrayValueFromQueryString(query.environment),
                Machine: arrayValueFromQueryString(query.machine),
                Role: arrayValueFromQueryString(query.role),
                Action: arrayValueFromQueryString(query.action),
                Channel: arrayValueFromQueryString(query.channel),
                TenantTag: arrayValueFromQueryString(query.tenantTag),
            },
        };
        return filter;
    };
    private getFilteredVariables(variables: ReadonlyArray<VariableWithSource>, messages: AllVariableMessages, variableSectionIndex: any): ReadonlyArray<FilteredVariable> {
        const variablesWithMessages = zip<VariableWithSource | VariableMessages>(variables, messages.variableMessages).map((gz) => {
            const variable = gz[0] as VariableWithSource;
            const variableMessages = gz[1] as VariableMessages;
            const filteredVariables = zip<ValueWithSource | ValueMessages>(variable.values, variableMessages.valuesMessages)
                .map((vz) => {
                const value = vz[0] as ValueWithSource;
                const valueMessages = vz[1] as ValueMessages;
                return {
                    ...value,
                    messages: valueMessages,
                };
            })
                .filter((v) => {
                const matchesSourceFilter = containsFilter(getSourceLinkName(v.source), this.state.filter.source);
                const shouldHideSectionContent = this.props.shouldHideSectionContent ? this.props.shouldHideSectionContent(variableSectionIndex) : false;
                return matchesFilter(v, variableMessages, v.messages, this.state.filter) && matchesSourceFilter && !shouldHideSectionContent;
            });
            return {
                name: variable.name,
                variableMessages,
                values: filteredVariables,
            };
        });
        return filterVariableGroups(variablesWithMessages, messages, this.state.filter, (g) => g.name)
            .filter((g) => g.matchesFilter)
            .map((g) => g.group);
    }
    static displayName = "FilterableVariableDisplayer";
}
const mapGlobalActionDispatchersToProps = (dispatch: Dispatch) => bindActionCreators({ onLoad: fetchAllAccounts }, dispatch);
const ConnectedFilteredVariableDisplayer = connect<{}, {}, FilteredVariableDisplayerProps>(null, mapGlobalActionDispatchersToProps)(FilterableVariableDisplayer);
export default ConnectedFilteredVariableDisplayer;
