import { css } from "@emotion/css";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import Logo from "~/components/Logo/Logo";
import InternalLink from "~/components/Navigation/InternalLink/index";
type TenantPageLink = "Overview" | "Variables";
interface TenantNameCellWithLinkProps {
    spaceId: string;
    tenantId: string;
    tenantName: string;
    tenantLogoLink: string;
    linkToPage?: TenantPageLink;
}
export function TenantNameCellWithLink({ spaceId, tenantId, tenantName, tenantLogoLink, linkToPage = "Overview" }: TenantNameCellWithLinkProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const linkTarget = linkToPage === "Variables" ? links.tenantVariablesPage.generateUrl({ tenantId, spaceId }) : links.tenantOverviewPage.generateUrl({ spaceId, tenantId });
    function handleClick() {
        const resource = linkToPage === "Variables" ? "Tenant Variables" : "Tenant";
        dispatchAction(`View ${resource}`, { resource: resource, action: Action.View });
    }
    return (<div className={cellStyles}>
            <InternalLink to={linkTarget} onClick={handleClick}>
                <div className={cellStyles}>
                    <div className={logoStyle}>
                        <Logo url={tenantLogoLink}/>
                    </div>
                    <div>{tenantName}</div>
                </div>
            </InternalLink>
        </div>);
}
interface TenantNameCellProps {
    tenantName: string;
    tenantLogoLink: string;
}
export function TenantNameCell({ tenantName, tenantLogoLink }: TenantNameCellProps) {
    return (<div className={cellStyles}>
            <div className={logoStyle}>
                <Logo url={tenantLogoLink}/>
            </div>
            <div>{tenantName}</div>
        </div>);
}
const cellStyles = css({
    display: "flex",
    alignItems: "center",
});
const logoStyle = css({
    scale: "0.7",
});
