import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React, { useLayoutEffect, useRef } from "react";
//We can calculate the appropriate size for the drawer from these locations
//which may be different from the actual drawer locations we want to support
//for example, we may only want to be able to dock left, bottom or right, but
//this resize handler also knows how to handle a drawer docked to the top.
type DrawerLocation = "left" | "right" | "top" | "bottom";
export function useDrawerResizeHandler(resizeTarget: React.RefObject<HTMLElement>, drawerLocation: DrawerLocation) {
    const [size, setSize] = React.useState<number | undefined>(undefined);
    const [isResizing, setIsResizing] = React.useState(false);
    const animationFrameRef = useRef<number | null>(null);
    useLayoutEffect(() => {
        if (size === undefined) {
            setSize(drawerLocation === "right" || drawerLocation === "left" ? resizeTarget.current?.clientWidth : resizeTarget.current?.clientHeight);
        }
    }, [drawerLocation, resizeTarget, size]);
    const handleResize = React.useCallback((e: MouseEvent) => {
        e.preventDefault();
        if (animationFrameRef.current !== null) {
            cancelAnimationFrame(animationFrameRef.current);
        }
        animationFrameRef.current = requestAnimationFrame(() => {
            if (!resizeTarget.current || !isResizing) {
                return;
            }
            const dimension = getDrawerAxisDimension(resizeTarget.current.getBoundingClientRect(), drawerLocation);
            const clientDimensionLocation = drawerLocation === "left" || drawerLocation === "right" ? e.clientX : e.clientY;
            setSize(Math.abs(dimension.start - clientDimensionLocation));
        });
    }, [resizeTarget, isResizing, drawerLocation]);
    const onMouseUp = React.useCallback((e: MouseEvent) => {
        setIsResizing(false);
    }, []);
    const onResizeDrawer = React.useCallback(() => {
        setIsResizing(true);
    }, []);
    React.useEffect(() => {
        document.addEventListener("mousemove", handleResize);
        document.addEventListener("mouseup", onMouseUp);
        return () => {
            document.removeEventListener("mousemove", handleResize);
            document.removeEventListener("mouseup", onMouseUp);
        };
    }, [onMouseUp, handleResize]);
    return { size, onResizeDrawer, isResizing };
}
interface DraweAxisDimension {
    start: number;
    end: number;
    size: number;
}
function getDrawerAxisDimension(boundingRect: DOMRect, drawerLocation: DrawerLocation): DraweAxisDimension {
    switch (drawerLocation) {
        case "left":
            return { start: boundingRect.left, end: boundingRect.right, size: boundingRect.width };
        case "right":
            return { start: boundingRect.right, end: boundingRect.left, size: boundingRect.width };
        case "top":
            return { start: boundingRect.top, end: boundingRect.bottom, size: boundingRect.height };
        case "bottom":
            return { start: boundingRect.bottom, end: boundingRect.top, size: boundingRect.height };
        default:
            exhaustiveCheck(drawerLocation, "Not all values have been handled");
    }
}
