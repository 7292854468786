import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, TopicsContainer, LearnMoreResources } from "./CommonViews";
export const CommonLibraryGitConnectionOverview = () => {
    const styles = {
        helpContent: css({
            "& ol": {
                listStyleType: "decimal",
                paddingLeft: space[24],
            },
        }),
    };
    return (<OverviewContainer>
            <OverviewHighlightText>
                <div className={styles.helpContent}>
                    <p>GitHub Connections is the recommended way to connect your GitHub accounts. It provides a seamless and secure connection via the Octopus GitHub App, without using personal access tokens.</p>
                    <br />
                    <p>Install the Octopus GitHub App:</p>
                    <ol>
                        <li>
                            Click Install the <strong>Octopus Deploy GitHub App</strong>.
                        </li>
                        <li>Follow the prompts in your GitHub account, then you'll get redirected here.</li>
                        <li>Click on your GitHub account name to see your repositories.</li>
                        <li>
                            Select the relevant repositories, then click <strong>CONNECT</strong>.
                        </li>
                        <li>Return to Version Control Settings and select the GitHub accounts and repositories to store your project configuration.</li>
                    </ol>
                </div>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonLibraryGitConnectionTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="GitHubApp">GitHub App</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="ConfigAsCodeConfiguration">Connecting projects to Git</ExternalLink>
                </div>
                <div>
                    <ExternalLink href="ConfigAsCodeMoving">Moving version control</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
