import type { WildflyStateProperties } from "@octopusdeploy/legacy-action-properties";
import { AllWildflyStateProperties } from "@octopusdeploy/legacy-action-properties";
import type { ActionProperties } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "~/components/form";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { BoundStringCheckbox } from "~/primitiveComponents/form/Checkbox/StringCheckbox";
import Note from "~/primitiveComponents/form/Note/Note";
import { BoundSelect } from "~/primitiveComponents/form/Select/Select";
import { ValueInPropertiesOrErrorsHasChanged } from "~/utils/ShouldUpdate/ValueInPropertiesHasChanged";
import WildflyConnectionDetailsComponent from "./wildflyConnectionDetailsComponent";
interface WildflyActionEditProps<T = ActionProperties> extends ActionEditProps<T> {
    actionDescription: string;
    domainDefaultMessage: string;
    applicationNameNote: JSX.Element;
    applicationStateNote: JSX.Element;
    applicationServerHostnameNote: JSX.Element;
}
/**
 * A component that provide the connection details and application details
 * for a application deployment or state change.
 */
export default class WildflyFeatureTemplate extends BaseComponent<WildflyActionEditProps<WildflyStateProperties>, never> {
    shouldComponentUpdate(newProps: WildflyActionEditProps<WildflyStateProperties>): boolean {
        return ValueInPropertiesOrErrorsHasChanged(AllWildflyStateProperties, newProps, this.props);
    }
    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            const properties: ActionProperties = {};
            if (!this.props.properties["WildFly.Deploy.Controller"]) {
                properties["WildFly.Deploy.Controller"] = "localhost";
            }
            if (!this.props.properties["WildFly.Deploy.Port"]) {
                properties["WildFly.Deploy.Port"] = "9990";
            }
            if (!this.props.properties["WildFly.Deploy.Protocol"]) {
                properties["WildFly.Deploy.Protocol"] = "remote+http";
            }
            if (this.props.properties["WildFly.Deploy.ServerType"] !== "Standalone" && this.props.properties["WildFly.Deploy.ServerType"] !== "Domain") {
                properties["WildFly.Deploy.ServerType"] = "Standalone";
            }
            if (!this.props.properties["WildFly.Deploy.Enabled"]) {
                properties["WildFly.Deploy.Enabled"] = "True";
            }
            this.props.setProperties(properties, true);
        });
    }
    render() {
        const properties = this.props.properties;
        const applicationErrorKey = "WildFly.Deploy.Name|" + "WildFly.Deploy.ServerType|" + "WildFly.Deploy.Enabled|" + "WildFly.Deploy.EnabledServerGroup|" + "WildFly.Deploy.DisabledServerGroup";
        return (<div>
                <WildflyConnectionDetailsComponent properties={this.props.properties} packages={this.props.packages} plugin={this.props.plugin} getFieldError={this.props.getFieldError} setProperties={this.props.setProperties} setPackages={this.props.setPackages} doBusyTask={this.props.doBusyTask} busy={this.props.busy} projectId={this.props.projectId} gitRef={this.props.gitRef} applicationServerHostnameNote={this.props.applicationServerHostnameNote} actionDescription={<span>{this.props.actionDescription} an application via the management interface listening on</span>} errors={this.props.errors} expandedByDefault={this.props.expandedByDefault}/>
                <ExpandableFormSection errorKey={applicationErrorKey} isExpandedByDefault={this.props.expandedByDefault} title="Application Details" summary={this.applicationSummary()} help="Specify the details of the application.">
                    <VariableLookupText localNames={this.props.localNames} value={properties["WildFly.Deploy.Name"]} onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.Name"]: x })} label="Deployment name" error={this.props.getFieldError("WildFly.Deploy.Name")}/>
                    <Note>{this.props.applicationNameNote}</Note>
                    <BoundSelect variableLookup={{
                localNames: this.props.localNames,
            }} resetValue={"Standalone"} value={properties["WildFly.Deploy.ServerType"]} items={[
                { text: "Standalone", value: "Standalone" },
                { text: "Domain", value: "Domain" },
            ]} label="Standalone or Domain Server" onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.ServerType"]: x })}/>
                    <Note>Select the kind of server you are deploying to.</Note>
                    {properties["WildFly.Deploy.ServerType"] === "Standalone" && (<div>
                            <BoundStringCheckbox variableLookup={{
                    localNames: this.props.localNames,
                }} resetValue={"True"} value={properties["WildFly.Deploy.Enabled"]} onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.Enabled"]: x })} label="Enable the deployment"/>
                            <Note>{this.props.applicationStateNote}</Note>
                        </div>)}
                    {properties["WildFly.Deploy.ServerType"] === "Domain" && (<div>
                            <VariableLookupText localNames={this.props.localNames} value={properties["WildFly.Deploy.EnabledServerGroup"]} onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.EnabledServerGroup"]: x })} label="Enabled server groups"/>
                            <Note>
                                A comma separated list that defines the domain server groups where the deployment will be assigned and enabled. This value is ignored when deploying to a standalone instance. Set the{" "}
                                <em>Standalone or Domain Server</em> option to <em>Standalone</em> to see the <em>Enable/Disable the Deployment</em> option to control the state of the application when deployed to a standalone server.
                            </Note>
                            <VariableLookupText localNames={this.props.localNames} value={properties["WildFly.Deploy.DisabledServerGroup"]} onChange={(x) => this.props.setProperties({ ["WildFly.Deploy.DisabledServerGroup"]: x })} label="Disabled server groups"/>
                            <Note>
                                A comma separated list that defines the domain server groups where the deployment will be assigned and disabled. This value is ignored when deploying to a standalone instance. Set the{" "}
                                <em>Standalone or Domain Server</em> option to <em>Standalone</em> to see the <em>Enable/Disable the Deployment</em> option to control the state of the application when deployed to a standalone server.
                            </Note>
                        </div>)}
                </ExpandableFormSection>
            </div>);
    }
    private applicationSummary() {
        const properties = this.props.properties;
        if (properties["WildFly.Deploy.ServerType"] === "Standalone") {
            return Summary.summary(<span>
                    {properties["WildFly.Deploy.Enabled"] === "True" ? "Enabling " : "Disabling "}
                    the application{properties["WildFly.Deploy.Name"] && <strong>&nbsp;{properties["WildFly.Deploy.Name"]}</strong>}
                    &nbsp;in a standalone server.
                </span>);
        }
        else {
            if (properties["WildFly.Deploy.EnabledServerGroup"] || properties["WildFly.Deploy.DisabledServerGroup"]) {
                return Summary.summary(<span>
                        The application
                        {properties["WildFly.Deploy.Name"] && <strong>&nbsp;{properties["WildFly.Deploy.Name"]}</strong>}
                        {properties["WildFly.Deploy.EnabledServerGroup"] && (<span>
                                &nbsp;will be enabled in server group
                                {properties["WildFly.Deploy.EnabledServerGroup"].indexOf(",") !== -1 && <span>s</span>}
                                &nbsp;<strong>{properties["WildFly.Deploy.EnabledServerGroup"]}</strong>
                            </span>)}
                        {properties["WildFly.Deploy.EnabledServerGroup"] && properties["WildFly.Deploy.DisabledServerGroup"] && <span>&nbsp;and</span>}
                        {properties["WildFly.Deploy.DisabledServerGroup"] && (<span>
                                &nbsp;will be disabled in server group
                                {properties["WildFly.Deploy.DisabledServerGroup"].indexOf(",") !== -1 && <span>s</span>}
                                &nbsp;<strong>{properties["WildFly.Deploy.DisabledServerGroup"]}</strong>
                            </span>)}
                        .
                    </span>);
            }
            else {
                return Summary.placeholder(<span>{this.props.domainDefaultMessage}</span>);
            }
        }
    }
    static displayName = "WildflyFeatureTemplate";
}
