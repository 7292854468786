import { css, cx } from "@emotion/css";
import { colorScales, space } from "@octopusdeploy/design-system-tokens";
import type { GitHubRepository } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import React from "react";
import Chip from "~/components/Chips/Chip";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
export interface GitHubChipListProps {
    children: ReactNode;
}
export function GitHubChipList({ children }: GitHubChipListProps) {
    const styles = {
        chipList: css({
            display: "flex",
            flexGrow: 0,
            flexDirection: "row-reverse",
            overflow: "hidden",
        }),
    };
    return <div className={styles.chipList}>{children}</div>;
}
export interface GitHubRepositoryVisibilityChipProps {
    repository: GitHubRepository;
}
export function GitHubRepositoryVisibilityChip({ repository }: GitHubRepositoryVisibilityChipProps) {
    return (<Chip noTooltip>
            <LockIcon unlocked={!repository.IsPrivate}/>
            {repository.Visibility}
        </Chip>);
}
export interface GitHubRepositoryLanguageChipProps {
    repository: GitHubRepository;
}
export function GitHubRepositoryLanguageChip({ repository }: GitHubRepositoryLanguageChipProps) {
    if (!repository.Language) {
        // Some repositories won't have a langauge, so don't show anything if that's the case.
        return null;
    }
    return <Chip noTooltip>{repository.Language}</Chip>;
}
export enum GitHubConnectionStatus {
    None,
    Connected,
    Available
}
export interface GitHubConnectionStatusChipProps {
    status: GitHubConnectionStatus;
}
export function GitHubConnectionStatusChip({ status }: GitHubConnectionStatusChipProps) {
    const dark = useThemePaletteType() === "dark";
    const text = dark ? colorScales.black : colorScales.white;
    const backgroundScale = dark ? 300 : 700;
    if (status === GitHubConnectionStatus.Connected) {
        return (<Chip labelColor={text} backgroundColor={colorScales.green[backgroundScale]} noTooltip>
                Connected
            </Chip>);
    }
    if (status === GitHubConnectionStatus.Available) {
        return (<Chip labelColor={text} backgroundColor={colorScales.blue[backgroundScale]} noTooltip>
                Not Connected
            </Chip>);
    }
    // None or some other unhandled status
    return null;
}
interface LockIconProps {
    unlocked?: boolean;
}
function LockIcon({ unlocked }: LockIconProps) {
    const iconInLabelStyles = css({
        marginRight: space[8],
    });
    const lockClass = unlocked ? "fa-lock-open" : "fa-lock";
    return <em className={cx("fa", lockClass, iconInLabelStyles)} aria-hidden={true}/>;
}
