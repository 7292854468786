import { css } from "@emotion/css";
import { List, ListItem } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitHubAppConnectionSummary, GitHubAppConnections, GitHubAppInstallation, GitHubAppInstallations } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import { GitHubConnectionStatus } from "./ChipList/GitHubChips";
import GitHubAppInstallationDisplay from "./GitHubAppInstallationDisplay";
interface GitHubAppConnectionListProps {
    spaceId: string;
    connections: GitHubAppConnections;
    notConnectedInstallations: GitHubAppInstallations | undefined;
}
export default function GitHubAppConnectionList({ spaceId, connections, notConnectedInstallations }: GitHubAppConnectionListProps) {
    const items = [];
    for (const connection of connections.Connections) {
        items.push({
            id: "connection-" + connection.Id,
            listItem: <GitHubAppConnectionListItem spaceId={spaceId} connection={connection}/>,
        });
    }
    if (notConnectedInstallations) {
        for (const installation of notConnectedInstallations.Installations) {
            items.push({
                id: "installation-" + installation.InstallationId,
                listItem: <GitHubAppInstallationListItem spaceId={spaceId} installation={installation}/>,
            });
        }
    }
    return <List items={items} rowKey={(i) => i.id} renderRow={(props) => props.item.listItem}/>;
}
interface GitHubAppConnectionListItemProps {
    spaceId: string;
    connection: GitHubAppConnectionSummary;
}
function GitHubAppConnectionListItem({ spaceId, connection }: GitHubAppConnectionListItemProps) {
    const link = links.editGitHubConnectionPage.generateUrl({ spaceId: spaceId, connectionId: connection.Id });
    return (<ListItem>
            <InternalLink to={link}>
                {connection.Installation ? <GitHubAppInstallationDisplay installation={connection.Installation} connectionStatus={GitHubConnectionStatus.Connected}/> : <GitHubAppConnectionMissingDetails connectionId={connection.Id}/>}
            </InternalLink>
        </ListItem>);
}
interface GitHubAppInstallationListItemProps {
    spaceId: string;
    installation: GitHubAppInstallation;
}
function GitHubAppInstallationListItem({ spaceId, installation }: GitHubAppInstallationListItemProps) {
    const link = links.createGitHubConnectionPage.generateUrl({ spaceId, installationId: installation.InstallationId });
    return (<ListItem>
            <InternalLink to={link}>
                <GitHubAppInstallationDisplay installation={installation} connectionStatus={GitHubConnectionStatus.Available}/>
            </InternalLink>
        </ListItem>);
}
interface GitHubAppConnectionMissingDetailsProps {
    connectionId: string;
}
function GitHubAppConnectionMissingDetails({ connectionId }: GitHubAppConnectionMissingDetailsProps) {
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            padding: space[16],
            gap: space[16],
            alignItems: "center",
        }),
    };
    return (<div className={styles.container}>
            <h3>{connectionId}</h3>
        </div>);
}
