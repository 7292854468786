import type { AnalyticsEvent, AnalyticsEventPageProperties } from "@octopusdeploy/portal-analytics";
import type { PageDefinition } from "@octopusdeploy/portal-routes";
export interface HelpPanelAnalyticsEvent extends AnalyticsEvent<"Help Panel">, AnalyticsEventPageProperties {
    Interaction: "open" | "close";
    InteractedElement: "HelpNavigationActionButton";
}
export function createHelpPanelAnalyticsEvent(page: PageDefinition, isCurrentlyOpen: boolean): HelpPanelAnalyticsEvent {
    return {
        eventName: "Help Panel",
        "Page Area": page.Area,
        "Page Name": page.Name,
        Interaction: isCurrentlyOpen ? "close" : "open",
        InteractedElement: "HelpNavigationActionButton",
    };
}
