/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/consistent-type-assertions */
import { RadioButtonGroup, RadioButton } from "@octopusdeploy/design-system-components";
import { TriggerScheduleIntervalResource, TriggerScheduleIntervalType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { ExpandableFormSection, Summary } from "~/components/form";
import Number from "~/primitiveComponents/form/Number/Number";
interface ScheduleIntervalProps {
    interval: TriggerScheduleIntervalResource;
    onChange?(interval: TriggerScheduleIntervalResource): void;
}
interface ScheduleIntervalState {
    intervalType: TriggerScheduleIntervalType;
    intervalValue: number;
}
export default class ScheduleInterval extends React.Component<ScheduleIntervalProps, ScheduleIntervalState> {
    constructor(props: ScheduleIntervalProps) {
        super(props);
        this.state = this.initState(this.props.interval!);
    }
    componentDidUpdate(prevProps: ScheduleIntervalProps) {
        if (this.props.interval.Interval !== prevProps.interval.Interval || this.props.interval.HourInterval !== prevProps.interval.HourInterval || this.props.interval.MinuteInterval !== prevProps.interval.MinuteInterval) {
            this.setState(this.initState(this.props.interval));
        }
    }
    render() {
        const inputStyle = {
            width: "100px",
        };
        return (<ExpandableFormSection errorKey="ScheduleInterval" title="Interval" summary={this.getIntervalSummary()} help="Select at what interval the schedule should execute.">
                <RadioButtonGroup value={this.state.intervalType} onChange={this.updateIntervalType}>
                    {[
                <RadioButton value={TriggerScheduleIntervalType.OnceDaily} label="Once" key={TriggerScheduleIntervalType.OnceDaily}/>,
                <RadioButton value={TriggerScheduleIntervalType.OnceHourly} label="Hour" key={TriggerScheduleIntervalType.OnceHourly}/>,
                <RadioButton value={TriggerScheduleIntervalType.OnceEveryMinute} label="Minute" key={TriggerScheduleIntervalType.OnceEveryMinute}/>,
            ]}
                </RadioButtonGroup>
                {this.state.intervalType !== TriggerScheduleIntervalType.OnceDaily && (<div style={inputStyle}>
                        <Number label={this.state.intervalType === TriggerScheduleIntervalType.OnceHourly ? "Hours" : "Minutes"} value={this.state.intervalValue} onChange={this.updateIntervalValue} min={1} max={this.state.intervalType === TriggerScheduleIntervalType.OnceHourly ? 23 : 59}/>
                    </div>)}
            </ExpandableFormSection>);
    }
    private initState(value?: TriggerScheduleIntervalResource) {
        const interval = value || new TriggerScheduleIntervalResource();
        return {
            intervalType: interval.Interval,
            intervalValue: (interval.Interval === TriggerScheduleIntervalType.OnceHourly ? interval.HourInterval : interval.MinuteInterval)!,
        };
    }
    private getIntervalSummary() {
        let summary = "";
        switch (this.props.interval.Interval) {
            case TriggerScheduleIntervalType.OnceDaily:
                summary = "Runs once";
                break;
            case TriggerScheduleIntervalType.OnceHourly:
                summary = `Runs every ${this.props.interval.HourInterval === 1 ? "hour" : `${this.props.interval.HourInterval} hours`}`;
                break;
            case TriggerScheduleIntervalType.OnceEveryMinute:
                summary = `Runs every ${this.props.interval.MinuteInterval === 1 ? "minute" : `${this.props.interval.MinuteInterval} minutes`}`;
                break;
            default:
                return Summary.placeholder("Please select the triggers schedule");
        }
        return Summary.summary(summary);
    }
    private updateIntervalType = (typeString: string) => {
        const type = typeString as TriggerScheduleIntervalType;
        const value = type === TriggerScheduleIntervalType.OnceDaily ? null : 1;
        this.setState({
            intervalType: type,
            intervalValue: value!,
        }, () => this.raiseChange());
    };
    private updateIntervalValue = (value: number | undefined) => {
        this.setState({
            intervalValue: value ?? 1,
        }, () => this.raiseChange());
    };
    private raiseChange() {
        const newInterval = new TriggerScheduleIntervalResource();
        newInterval.Interval = this.state.intervalType;
        if (this.state.intervalType === TriggerScheduleIntervalType.OnceHourly) {
            newInterval.HourInterval = this.state.intervalValue;
        }
        else if (this.state.intervalType === TriggerScheduleIntervalType.OnceEveryMinute) {
            newInterval.MinuteInterval = this.state.intervalValue;
        }
        if (this.props.onChange) {
            this.props?.onChange(newInterval);
        }
    }
    static displayName = "ScheduleInterval";
}
