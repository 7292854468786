/* eslint-disable @typescript-eslint/consistent-type-assertions */
import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
type SummaryNodeType = "placeholder" | "summary" | "default";
export interface SummaryNode {
    node: React.ReactNode;
    type: SummaryNodeType;
}
export default class Summary {
    static placeholder(node: React.ReactNode): SummaryNode {
        return {
            node,
            type: "placeholder",
        };
    }
    static default(node: React.ReactNode): SummaryNode {
        return {
            node,
            type: "default",
        };
    }
    static summary(node: React.ReactNode): SummaryNode {
        return {
            node,
            type: "summary",
        };
    }
}
export interface ClassNameProps {
    className?: string;
}
export interface SummaryNodeProps {
    forceMobileBehaviour?: boolean;
}
const getSummaryClasses = (props: SummaryNodeProps & ClassNameProps, ...other: string[]) => cn(props.className, { [styles.cardSubTitleForceMobile]: props.forceMobileBehaviour }, other);
const SummaryContainer: React.FC<ClassNameProps> = ({ className, children }) => <span className={className}>{children}</span>;
SummaryContainer.displayName = "SummaryContainer"
export const PlaceholderSummary: React.FC<SummaryNodeProps> = (props) => <SummaryContainer className={getSummaryClasses(props, styles.cardPlaceholderSubTitle)}>{props.children}</SummaryContainer>;
PlaceholderSummary.displayName = "PlaceholderSummary"
PlaceholderSummary.displayName = "PlaceHolderSummary";
export const DefaultSummary: React.FC<SummaryNodeProps> = (props) => <SummaryContainer className={getSummaryClasses(props, styles.cardDefaultSubTitle)}>{props.children}</SummaryContainer>;
DefaultSummary.displayName = "DefaultSummary"
DefaultSummary.displayName = "DefaultSummary";
export const OverflowSummary: React.FC<SummaryNodeProps> = (props) => <SummaryContainer className={getSummaryClasses(props, styles.cardSubTitle)}>{props.children}</SummaryContainer>;
OverflowSummary.displayName = "OverflowSummary"
OverflowSummary.displayName = "OverflowSummary";
const lookup: Record<SummaryNodeType, React.ComponentType<SummaryNodeProps>> = {
    default: DefaultSummary,
    placeholder: PlaceholderSummary,
    summary: OverflowSummary,
};
export const getSummary = (node: SummaryNode | React.ReactNode, props: SummaryNodeProps) => {
    if (isSummaryNode(node)) {
        const { type, node: content } = node;
        return (<SummaryLookup type={type} {...props}>
                {content}
            </SummaryLookup>);
    }
    else if (React.isValidElement(node)) {
        return React.cloneElement(node, props);
    }
    return node;
};
export const SummaryLookup: React.FC<{
    type: SummaryNodeType;
} & SummaryNodeProps> = ({ type, ...rest }) => {
    const Component = lookup[type];
    return <Component {...rest}/>;
};
SummaryLookup.displayName = "SummaryLookup"
SummaryLookup.displayName = "SummaryLookup";
const isSummaryNode = (node: SummaryNode | React.ReactNode): node is SummaryNode => {
    const summaryNode = node as SummaryNode;
    return summaryNode && summaryNode.type && lookup.hasOwnProperty(summaryNode.type);
};
