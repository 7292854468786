import { css } from "@emotion/css";
import { GreenTickIcon } from "@octopusdeploy/design-system-components";
import React from "react";
import { ActiveStatusIcon } from "~/components/ActiveStatusIcon/ActiveStatusIcon";
import type { TaskState } from "./ProjectStatus";
export const ProjectStatusIcon = ({ state }: {
    state: TaskState;
}): JSX.Element => {
    if (state === "Current")
        return <ActiveStatusIcon active={true} style={{ marginTop: "-2px" }}/>;
    if (state === "Pending")
        return <ActiveStatusIcon active={false} style={{ marginTop: "-2px" }}/>;
    if (state === "Done")
        return (<div className={greenTickStyles}>
                <GreenTickIcon />
            </div>);
    return <></>;
};
const greenTickStyles = css({
    marginTop: "-2px",
});
