import * as React from "react";
import styles from "./style.module.less";
interface TenantDeploymentCountProps {
    expected: number;
    progress: number;
}
const TenantDeploymentCount: React.StatelessComponent<TenantDeploymentCountProps> = (props) => {
    return (<span className={styles.container}>
            <span className={styles.progress}>{props.progress}</span>
            <span className={styles.expected}>/{props.expected}</span>{" "}
        </span>);
};
TenantDeploymentCount.displayName = "TenantDeploymentCount"
export default TenantDeploymentCount;
