import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText } from "./CommonViews";
export const CommonLibraryTenantTagSetOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="TenantTags">Tenant tag sets</ExternalLink> let you classify and deal with tenants as groups throughout Octopus.
                </p>
                <p>Using tags you can apply meaningful metadata to tenants, to describe them using your own terminology, improve search and filtering, and tailor the deployment process to their needs.</p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
