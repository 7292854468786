/* eslint-disable @typescript-eslint/no-explicit-any */
import type { InsightsTrendDirection, SparklineTrends } from "@octopusdeploy/octopus-server-client";
import type { ReactNode } from "react";
import React from "react";
import type { ChartStrokeColor } from "../../chartColors";
import { SparklineTrendPair } from "./SparklineTrendPair/SparklineTrendPair";
interface Props<T extends string | number> {
    trends: SparklineTrends<T>;
    metricDescription: ReactNode;
    colorLookup?: Record<InsightsTrendDirection, ChartStrokeColor>;
    valueFormatter?: (val: T | null) => string | number;
    dataPointFormatter?: (val: T | null) => number;
}
export function CommonSparklineTrends<T extends string | number>({ trends, colorLookup, valueFormatter, metricDescription, dataPointFormatter }: Props<T>) {
    return (<>
            {trends.ProjectTrends !== null && (<SparklineTrendPair sectionTitle="Projects" leftData={trends.ProjectTrends.Up} rightData={trends.ProjectTrends.Down} valueFormatter={valueFormatter} dataPointFormatter={dataPointFormatter} leftTitle="Trending up" rightTitle="Trending down" note={<>
                            The projects that have trended up or down in the last 28 days compared with the previous 28 days. The first 10 in descending order by the percentage difference between the current and previous value are shown. The value
                            shown is the {metricDescription} for the last 28 days.
                        </>} directionColorLookup={colorLookup}/>)}
            {trends.EnvironmentTrends !== null && (<SparklineTrendPair sectionTitle="Environments" leftData={trends.EnvironmentTrends.Up} rightData={trends.EnvironmentTrends.Down} valueFormatter={valueFormatter} dataPointFormatter={dataPointFormatter} leftTitle="Trending up" rightTitle="Trending down" note={<>
                            The environments that have trended up or down in the last 28 days compared with the previous 28 days. The first 10 in descending order by the percentage difference between the current and previous value are shown. The
                            value shown is the {metricDescription} for the last 28 days.
                        </>} directionColorLookup={colorLookup}/>)}
            {trends.TenantTrends !== null && (<SparklineTrendPair sectionTitle="Tenants" leftData={trends.TenantTrends.Up} rightData={trends.TenantTrends.Down} valueFormatter={valueFormatter} dataPointFormatter={dataPointFormatter} leftTitle="Trending up" rightTitle="Trending down" note={<>
                            The tenants that have trended up or down in the last 28 days compared with the previous 28 days. The first 10 in descending order by the percentage difference between the current and previous value are shown. The value
                            shown is the {metricDescription} for the last 28 days.
                        </>} directionColorLookup={colorLookup}/>)}
        </>);
}
