import { css } from "@emotion/css";
import { IconButton } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import * as React from "react";
import { useIsVerticalNavigationEnabled } from "~/components/RootRoutes/useIsVerticalNavigationEnabled";
import styles from "./style.module.less";
type CommonCardTitleProps = {
    title: JSX.Element;
    forceMobileBehaviour?: boolean;
    useCardTitleContainerStyle?: boolean;
};
type NonExpandableCardTitleProps = CommonCardTitleProps;
interface ExpandableCardTitleProps extends CommonCardTitleProps {
    onToggleExpand: React.MouseEventHandler;
    isExpanded: boolean;
    accessibleName: string;
}
type CardTitleProps = NonExpandableCardTitleProps | ExpandableCardTitleProps;
function CardTitle(props: CardTitleProps) {
    const isVerticalNavigationEnabled = useIsVerticalNavigationEnabled();
    if ("onToggleExpand" in props) {
        const { isExpanded = false } = props;
        const clickableStyles = isVerticalNavigationEnabled ? clickableStylesVNext : styles.clickable;
        return (<div onClick={props.onToggleExpand} className={Boolean(props.onToggleExpand) ? clickableStyles : undefined}>
                <div className={cn({ [styles.cardTitleContainer]: Boolean(props.onToggleExpand) || props.useCardTitleContainerStyle }, { [styles.cardTitleContainerForceMobile]: props.forceMobileBehaviour })}>{props.title}</div>
                <div className={styles.cardExpander}>
                    {Boolean(props.onToggleExpand) && <ExpandIconButton isExpanded={isExpanded} onClick={props.onToggleExpand} accessibleName={getExpansionButtonAccessibleName(props.accessibleName, isExpanded)}/>}
                </div>
            </div>);
    }
    return (<div>
            <div className={cn({ [styles.cardTitleContainer]: props.useCardTitleContainerStyle }, { [styles.cardTitleContainerForceMobile]: props.forceMobileBehaviour })}>{props.title}</div>
            <div className={styles.cardExpander}/>
        </div>);
}
const clickableStylesVNext = css({
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    "&:hover": {
        backgroundColor: themeTokens.color.background.primary.hovered,
    },
});
function getExpansionButtonAccessibleName(name: string | undefined, expanded: boolean) {
    const action = expanded ? "Collapse" : "Expand";
    return name ? `${action} ${name}` : `${action} expander`;
}
interface ExpandIconButtonProps {
    onClick: ((e: React.MouseEvent) => void) | undefined;
    isExpanded: boolean;
    accessibleName: string;
}
function ExpandIconButton({ isExpanded, onClick, accessibleName }: ExpandIconButtonProps) {
    return (<IconButton className={cn(styles.expandIcon, isExpanded ? styles.expandOpen : styles.expandClosed)} onClick={onClick} disableRipple={true} aria-expanded={isExpanded} accessibleName={accessibleName} icon="Expand" style={{ marginRight: "1rem" }}/>);
}
export default CardTitle;
