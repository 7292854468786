import type { PackagingRegistration } from "../../Registry/packagingRegistry";
import BuildServerPackagingInstruction from "./BuildServerPackagingInstruction";
import CommandLinePackagingInstruction from "./CommandLinePackagingInstruction";
import ManualPackagingInstruction from "./ManualPackagingInstruction";
export const NetCorePackagingRegistration: PackagingRegistration = {
    displayOrder: 200,
    instructions: [BuildServerPackagingInstruction, ManualPackagingInstruction, CommandLinePackagingInstruction],
    name: "ASP.NET Core",
    id: "AspNetCore",
};
export default NetCorePackagingRegistration;
