import { logger } from "@octopusdeploy/logging";
import { Environment } from "@octopusdeploy/utilities";
import React from "react";
import { useKeyCombo } from "~/areas/configuration/components/FeaturesLayout/useKeyCombo";
const konamiCodeKeys = ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight"];
export function useHiddenFeatures() {
    const isKonamiCodeTriggered = useKeyCombo(konamiCodeKeys);
    React.useEffect(() => {
        if (isKonamiCodeTriggered) {
            logger.info("Hidden features unlocked");
        }
    }, [isKonamiCodeTriggered]);
    return Environment.isInDevelopmentMode() || isKonamiCodeTriggered;
}
