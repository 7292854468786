import type { LoginState } from "@octopusdeploy/octopus-server-client";
import URI from "urijs";
function calculateLoginState(currentAbsoluteUrl: string, redirectToPath: string, isInDevelopmentMode: boolean): LoginState {
    if (!currentAbsoluteUrl) {
        throw new Error("url must be set to a value");
    }
    const octopusServerKey = "octopus.server";
    const octopusServerIndex = currentAbsoluteUrl.indexOf(octopusServerKey);
    const pathName = octopusServerIndex !== -1 ? new URI(currentAbsoluteUrl.substring(octopusServerIndex + octopusServerKey.length + 1)).fragment() : new URI(currentAbsoluteUrl).fragment();
    const targetUrl = currentAbsoluteUrl.toString().replace(pathName, redirectToPath);
    return {
        RedirectAfterLoginTo: isInDevelopmentMode ? targetUrl : toRelative(targetUrl),
        UsingSecureConnection: currentAbsoluteUrl.startsWith("https://"),
    };
}
function toRelative(url: string) {
    return new URI(url).resource();
}
export default calculateLoginState;
