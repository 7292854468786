import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import type { FeedType, ExternalFeedResource } from "@octopusdeploy/octopus-server-client";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import type { ExternalFeedStateTypes } from "app/areas/library/components/ExternalFeeds/ExternalFeed";
import { ExternalFeedInternal } from "app/areas/library/components/ExternalFeeds/ExternalFeed";
import type { ReactNode } from "react";
import React, { useState } from "react";
import { useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { createSaveAndTestFeedAnalyticsEvent, createSaveFeedAnalyticsEvent } from "~/areas/library/components/ExternalFeeds/amplitudeTracking";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import useLocalStorage from "~/hooks/useLocalStorage";
import { DrawerWithForm } from "~/primitiveComponents/dataDisplay/DrawerWithForm/DrawerWithForm";
import StringHelper from "~/utils/StringHelper";
interface FormState {
    model: ExternalFeedResource | undefined;
    cleanModel: ExternalFeedResource | undefined;
    busy: Promise<void> | undefined;
    errors?: Errors;
}
export interface ExternalFeedDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (feed: ExternalFeedResource) => void;
    allowedFeedTypes?: FeedType[];
    analyticsCorrelationId?: string;
}
export const ExternalFeedDrawer = ({ onSave, isOpen, onClose, allowedFeedTypes, analyticsCorrelationId }: ExternalFeedDrawerProps) => {
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchAction = useAnalyticActionDispatch();
    const spaceAwareNavigation = useSpaceAwareNavigation();
    const [state, setState] = useState<ExternalFeedStateTypes>({
        state: "create",
    });
    const [calloutDismissed, setCalloutDismissed] = useLocalStorage(`Octopus.Callout.Drawer.ExternalFeed.Dismissed`, false);
    const [formState, setFormState] = useState<FormState>({
        busy: undefined,
        model: undefined,
        errors: undefined,
        cleanModel: undefined,
    });
    const [actions, setActions] = useState<ReactNode[]>();
    const trackEvent = useTrackEvent();
    const onStateTransition = (currentState: ExternalFeedStateTypes | null, newState: ExternalFeedStateTypes) => {
        setState((prevState) => {
            if (prevState.state !== "new" && newState.state === "new" && newState.feed) {
                trackEvent(createSaveFeedAnalyticsEvent({
                    Location: "Drawer",
                    "Feed Type": newState.feed.FeedType,
                    "Correlation Id": analyticsCorrelationId,
                }));
                onSave(newState.feed);
            }
            else if (prevState.state !== "test" && newState.state === "test" && newState.feed) {
                trackEvent(createSaveAndTestFeedAnalyticsEvent({
                    Location: "Drawer",
                    "Feed Type": newState.feed.FeedType,
                    "Correlation Id": analyticsCorrelationId,
                }));
                onSave(newState.feed);
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                spaceAwareNavigation.open(links.testFeedPage.generateUrl({ spaceId: newState.feed.SpaceId ?? repository.spaceId!, feedId: newState.feed.Id }));
            }
            return { ...newState };
        });
        const mappedActions: ReactNode[] = [<ActionButton label="Cancel" onClick={onClose} type={ActionButtonType.Secondary}/>];
        if (newState.secondaryActions) {
            newState.secondaryActions.map((action) => {
                if (action.type === "button")
                    mappedActions.push(<ActionButton onClick={() => action.onClick(undefined)} label={action.label} type={ActionButtonType.Secondary}/>);
            });
        }
        const primaryAction = newState.primaryAction;
        if (primaryAction?.type === "button") {
            mappedActions.push(<ActionButton onClick={() => primaryAction.onClick(undefined)} label={primaryAction.label} type={ActionButtonType.Primary}/>);
        }
        setActions(mappedActions);
    };
    const onCloseCallout = () => {
        setCalloutDismissed(true);
    };
    const title = state.state === "create" ? "Create Feed" : formState.model ? formState.model.Name : StringHelper.ellipsis;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const spaceId = repository.spaceId!;
    return (<DrawerWithForm isOpen={isOpen} onClose={onClose} title={title} busy={formState.busy} errors={formState.errors} onSubmit={() => {
            if (state.primaryAction?.type === "button")
                state.primaryAction.onClick(undefined);
        }} expandAllOnMount={true} showExpandAll={true} expandableContainerKey={"ExternalFeedDrawer"} variant="customActions" customActions={actions} correlationId={analyticsCorrelationId}>
            {!calloutDismissed && state.state === "create" ? (<Callout type={"information"} title="Finalise external feed" hideTitle={false} canClose={true} onClose={onCloseCallout}>
                    <div>
                        After you create this feed, you can find it in{" "}
                        <InternalLink to={links.feedsPage.generateUrl({ spaceId })} openInSelf={false}>
                            External Feeds
                        </InternalLink>
                    </div>
                </Callout>) : (<></>)}
            <ExternalFeedInternal spaceId={spaceId} create={true} trackAction={trackAction} dispatchAction={dispatchAction} feedId={undefined} onStateTransition={onStateTransition} onFormStateChanged={setFormState} expandableContainerKey={"ExternalFeedDrawer"} allowedFeedTypes={allowedFeedTypes}/>
        </DrawerWithForm>);
};
