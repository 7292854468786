import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { UnstructuredFormSection } from "~/components/form";
import { TentacleCalloutHeading } from "./TentacleCalloutHeading";
export const LinuxListeningTentacleCallout: React.SFC<{
    thumbprint: React.ReactNode;
}> = ({ thumbprint }) => (<UnstructuredFormSection stretchContent={true}>
        <Callout type={"information"} title={"Installation Instructions"}>
            <TentacleCalloutHeading />
            <div>
                <p>
                    For getting started instructions please visit the <ExternalLink href="LinuxListeningTentacle">Linux Tentacle documentation</ExternalLink>.
                </p>
                {thumbprint}
            </div>
        </Callout>
    </UnstructuredFormSection>);
LinuxListeningTentacleCallout.displayName = "LinuxListeningTentacleCallout"
export default LinuxListeningTentacleCallout;
