import * as React from "react";
import styles from "./style.module.less";
interface PagingControlButtonProps {
    label: string;
    isActive: boolean;
    onPageSelected?(event: React.MouseEvent): void;
}
const PagingControlButton: React.StatelessComponent<PagingControlButtonProps> = (props) => {
    const activeClass = props.isActive ? styles.pagingControlButtonActive : styles.pagingControlButtonInactive;
    return (<button className={styles.pagingControlButton + " " + activeClass} onClick={props.onPageSelected} title={props.label}>
            <span className={styles.pagingControlButtonText}>{props.label}</span>
        </button>);
};
PagingControlButton.displayName = "PagingControlButton"
export default PagingControlButton;
