import type { ObjectRuntimeInputs, PathToInput } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessorForDeploymentTarget, getPathToInput } from "@octopusdeploy/step-runtime-inputs";
import type { NumberComponent } from "@octopusdeploy/step-ui";
import React from "react";
import { Note } from "~/components/StepPackageEditor/Inputs/Note/Note";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import Number from "~/primitiveComponents/form/Number/Number";
export function getDeploymentTargetNumberSummary<StepInputs>(component: NumberComponent, inputs: ObjectRuntimeInputs<StepInputs>): InputSummary {
    const inputAccessor = createInputValueAccessorForDeploymentTarget(component.input);
    const inputValue = inputAccessor.getInputValue(inputs);
    if (inputValue === undefined)
        return "empty";
    return {
        isDefaultValue: false,
        value: inputValue.toString(),
    };
}
interface DeploymentTargetNumberProps<StepInputs> {
    configuredStepUIProps: NumberComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (name: PathToInput) => string;
}
export function DeploymentTargetNumber<StepInputs>(props: DeploymentTargetNumberProps<StepInputs>) {
    const inputAccessor = createInputValueAccessorForDeploymentTarget<StepInputs, number | undefined>(props.configuredStepUIProps.input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    const inputPath = getPathToInput(props.configuredStepUIProps.input);
    return (<>
            <Number value={inputValue} onChange={(newValue: number | undefined) => {
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue);
            props.setInputs(updatedInputs);
        }} label={props.configuredStepUIProps.label} error={props.getFieldError(inputPath)}/>
            <Note note={props.configuredStepUIProps.note}/>
        </>);
}
