/* eslint-disable @typescript-eslint/no-explicit-any */
import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
interface ActionTemplateCardListProps {
    children: React.ReactNode;
}
const ActionTemplateCardList = (props: ActionTemplateCardListProps) => {
    return (<ol className={cx({
            [stepTileStyles]: true,
            [fixedWidthColumns]: React.Children.count(props.children) < 3,
        })}>
            {props.children}
        </ol>);
};
export default ActionTemplateCardList;
const stepTileStyles = css({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(255px, 1fr))",
    gap: space["24"],
    ["@media (min-width: 1675px)"]: {
        gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
    },
});
const fixedWidthColumns = css({
    "&&": {
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 348px))",
    },
});
