import { useHistory } from "react-router";
import { client } from "~/clientInstance";
import { useUrlResolver } from "../useUrlResolver";
import { SpaceAwareNavigation } from "./SpaceAwareNavigation";
export function useSpaceAwareNavigation() {
    const history = useHistory();
    const spaceId = client.spaceId ?? undefined;
    const urlResolver = useUrlResolver();
    return new SpaceAwareNavigation(history, spaceId, urlResolver);
}
