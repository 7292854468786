import isEqual from "lodash/isEqual";
import React from "react";
export function useKeyCombo(keyCombo: string[]) {
    // Using a ref to store keys so that key presses don't trigger a re-render
    const keys = React.useRef<string[]>([]);
    const comboTimeout = React.useRef<number>();
    const [hasKeyComboTriggered, setHasKeyComboTriggered] = React.useState(false);
    const keyHandler = React.useCallback((event: KeyboardEvent) => {
        keys.current = [...keys.current, event.key];
        if (isEqual(keys.current, keyCombo)) {
            setHasKeyComboTriggered(true);
        }
        clearTimeout(comboTimeout.current);
        comboTimeout.current = window.setTimeout(() => {
            keys.current = [];
        }, 5000);
    }, [keyCombo]);
    React.useEffect(() => {
        window.document.addEventListener("keydown", keyHandler);
        return () => {
            window.document.removeEventListener("keydown", keyHandler);
            clearTimeout(comboTimeout.current);
        };
    }, [keyHandler]);
    return hasKeyComboTriggered;
}
