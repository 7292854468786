import type { InstanceFeatureToggle } from "@octopusdeploy/octopus-server-client";
import { useEffect, useState } from "react";
import { session } from "~/clientInstance";
// Exposes the feature toggles to consumers
export function isFeatureToggleEnabled(featureToggle: InstanceFeatureToggle): boolean {
    if (!session.featureToggles) {
        throw "Feature toggles have not yet loaded. These settings are only available once logged in.";
    }
    return session.featureToggles.includes(featureToggle) ?? false;
}
export function isOptionalFeatureToggleEnabled(featureToggle: InstanceFeatureToggle): boolean | "session-not-established" {
    if (!session.featureToggles) {
        return "session-not-established";
    }
    return session.featureToggles.includes(featureToggle) ?? false;
}
export function useIsOptionalFeatureToggleEnabled(featureToggle: InstanceFeatureToggle): boolean {
    const [isEnabled, setIsEnabled] = useState(session.featureToggles?.includes(featureToggle) ?? false);
    useEffect(() => session.subscribe(() => setIsEnabled(session.featureToggles?.includes(featureToggle) ?? false)), [featureToggle]);
    return isEnabled;
}
