import * as React from "react";
import pageIds from "~/pageIds";
import type { CategorizedPageHelpRegistration, RenderRegistrationCardProps } from "../../../Registry/pageHelpRegistry";
import { CommonLibraryGitConnectionOverview, CommonLibraryGitConnectionTopics } from "../../CommonComponents/CommonLibraryGitConnectionViews";
export const LibraryGitConnectionsGetRegistration: CategorizedPageHelpRegistration = {
    page: pageIds.library.gitConnections.getGitHub,
    renderOverview: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryGitConnectionOverview />;
    },
    renderTopics: (renderProps: RenderRegistrationCardProps) => {
        return <CommonLibraryGitConnectionTopics />;
    },
};
