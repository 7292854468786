import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import SortableList from "~/primitiveComponents/dataDisplay/SortableList/SortableList";
interface EnvironmentSorterFormProps {
    saveDone(): void;
}
interface EnvironmentSorterFormState extends DataBaseComponentState {
    environments: EnvironmentResource[];
}
class EnvironmentsSorter extends DataBaseComponent<EnvironmentSorterFormProps, EnvironmentSorterFormState> {
    constructor(props: EnvironmentSorterFormProps) {
        super(props);
        this.state = {
            environments: [],
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const environments = await repository.Environments.all();
            this.setState({ environments });
        });
    }
    async save() {
        const environmentIdsSorted = this.state.environments.map((environment) => {
            return environment.Id;
        });
        return this.doBusyTask(async () => {
            await repository.Environments.sort(environmentIdsSorted);
            this.props.saveDone();
        });
    }
    render() {
        return (<SaveDialogLayout title="Reorder Environments" busy={this.state.busy} errors={this.errors} onSaveClick={() => this.save()}>
                <SortableList items={this.state.environments} onOrderChanged={(x: EnvironmentResource[]) => this.setState({ environments: x })}/>
            </SaveDialogLayout>);
    }
    static displayName = "EnvironmentsSorter";
}
export default EnvironmentsSorter;
