import type { ReactNode } from "react";
import React from "react";
import { Note } from "~/components/form";
import { DataTable, DataTableBody, DataTableHeader } from "~/primitiveComponents/dataDisplay/DataTable";
import styles from "./styles.module.less";
interface Props {
    title?: string;
    isEmpty: boolean;
    note: ReactNode;
    headerContent: ReactNode;
    bodyContent: React.ReactNode;
}
export function TrendTable({ title, headerContent, bodyContent, isEmpty, note }: Props) {
    return (<div className={styles.container}>
            <div className={styles.sectionHeading}>
                <h2 className={styles.title}>{title}</h2>
            </div>
            <div className={styles.contentContainer}>
                {isEmpty ? (<span className={styles.nothingToShow}>Nothing to show</span>) : (<DataTable>
                        <DataTableHeader>{headerContent}</DataTableHeader>
                        <DataTableBody>{bodyContent}</DataTableBody>
                    </DataTable>)}
            </div>
            <div className={styles.contentContainer}>
                <Note>{note}</Note>
            </div>
        </div>);
}
