import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DeploymentFreezeStatus } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon/index";
interface DeploymentFreezeDataTableStatusCellProps {
    status: DeploymentFreezeStatus;
}
export const DeploymentFreezeDataTableStatusCell = ({ status }: DeploymentFreezeDataTableStatusCellProps) => {
    switch (status) {
        case "Scheduled":
            return (<DataTableRowColumn className={cellStyles}>
                    <div className={statusCellStyles}>
                        <ThirdPartyIcon iconType={ThirdPartyIconType.Clock} color={themeTokens.color.icon.info}/>
                        <div className={statusWordStyles}>Scheduled</div>
                    </div>
                </DataTableRowColumn>);
            break;
        case "Active":
            return (<DataTableRowColumn className={cellStyles}>
                    <div className={statusCellStyles}>
                        <ThirdPartyIcon iconType={ThirdPartyIconType.CheckCircle} color={themeTokens.color.icon.success}/>
                        <div className={statusWordStyles}>Active</div>
                    </div>
                </DataTableRowColumn>);
            break;
        default:
            return (<DataTableRowColumn className={cellStyles}>
                    <div className={statusCellStyles}>
                        <ThirdPartyIcon iconType={ThirdPartyIconType.Clock} color={themeTokens.color.icon.secondary}/>
                        <div className={statusWordStyles}>Expired</div>
                    </div>
                </DataTableRowColumn>);
    }
};
const statusCellStyles = css({
    display: "flex",
});
const statusWordStyles = css({
    margin: "4px",
});
const cellStyles = css({ width: "15%" });
