import type { StepPackageEndpointResource, AccountResource, WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import type { ObjectResourceInputs } from "@octopusdeploy/step-runtime-inputs";
import * as React from "react";
import type { UnknownStepPackageDeploymentTarget } from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTarget";
import StepPackageDeploymentTargetEditor from "~/components/StepPackageDeploymentTargetEditor/StepPackageDeploymentTargetEditor";
import { ExpandableFormSection, Summary, Select, FormSectionHeading } from "~/components/form";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper";
interface StepPackageEndpointProps {
    endpoint: StepPackageEndpointResource;
    workerPools: WorkerPoolResource[];
    accounts: AccountResource[];
    refreshAccounts: () => Promise<{}>;
    stepPackage: UnknownStepPackageDeploymentTarget | undefined;
    onChange(newValue: StepPackageEndpointResource): void;
    getFieldError(field: string): string;
    isNew: boolean;
}
const StepPackageEndpoint = (props: StepPackageEndpointProps) => {
    if (props.stepPackage === undefined || props.endpoint === undefined)
        return null;
    return (<React.Fragment>
            <StepPackageDeploymentTargetEditor inputDependencies={{ accounts: props.accounts, refreshAccounts: props.refreshAccounts }} 
    /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */
    inputs={props.endpoint.Inputs as ObjectResourceInputs<unknown>} stepPackage={props.stepPackage} setInputs={(newInputs) => {
            if (props.onChange)
                props.onChange({ ...props.endpoint, Inputs: newInputs });
        }} getFieldError={props.getFieldError} isExpandedByDefault={props.isNew}/>
            {props.workerPools.length > 1 && (<React.Fragment>
                    <FormSectionHeading title="Communication"/>
                    <ExpandableFormSection errorKey={"DefaultWorkerPool"} title="Worker Pool" summary={props.endpoint.DefaultWorkerPoolId ? CommonSummaryHelper.resourceSummary(props.endpoint.DefaultWorkerPoolId, props.workerPools, "worker pool") : Summary.placeholder("No pool selected - default pool")} help="Select a default pool for this target (optional).">
                        <Select label={"Select a default pool"} items={props.workerPools.map((e) => ({ value: e.Id, text: e.Name }))} value={props.endpoint.DefaultWorkerPoolId} allowFilter={true} allowClear={true} onChange={(x) => props.onChange({ ...props.endpoint, DefaultWorkerPoolId: x })} sortItems={false}/>
                    </ExpandableFormSection>
                </React.Fragment>)}
        </React.Fragment>);
};
export default StepPackageEndpoint;
