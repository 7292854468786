import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { repository } from "~/clientInstance";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { AsyncInternalRedirect } from "~/routing/AsyncInternalRedirect";
import StringHelper from "~/utils/StringHelper/index";
export function ScopedUserRoleToTeamRedirect({ scopedUserRoleId }: {
    scopedUserRoleId: string;
}) {
    return (<AsyncInternalRedirect loader={async () => {
            const scopedUserRole = await repository.ScopedUserRoles.get(scopedUserRoleId);
            return links.editTeamPage.generateUrl({ teamId: scopedUserRole.TeamId });
        }} renderWhenLoading={<PaperLayoutVNext busy={true} title={StringHelper.ellipsis}/>}/>);
}
