import React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import { OverviewContainer, OverviewHighlightText, LearnMoreResources, TopicsContainer } from "./CommonViews";
export const CommonConfigurationNodesOverview = () => {
    return (<OverviewContainer>
            <OverviewHighlightText>
                <p>
                    <ExternalLink href="ManagingHighAvailabilityNodes">Nodes</ExternalLink> represent your Octopus Server instances.
                </p>
            </OverviewHighlightText>
        </OverviewContainer>);
};
export const CommonConfigurationNodesTopics = () => {
    return (<TopicsContainer>
            <LearnMoreResources>
                <div>
                    <ExternalLink href="ManagingHighAvailabilityNodes">Managing High Availability Nodes</ExternalLink>
                </div>
            </LearnMoreResources>
        </TopicsContainer>);
};
