import { type UserResource } from "@octopusdeploy/octopus-server-client";
import type { ServiceAccountOidcIdentityResource } from "@octopusdeploy/octopus-server-client/src/repositories/serviceAccountOidcIdentitiesRepository";
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import { ConfigureServiceAccountOidcIdentityDialogLayout } from "./ConfigureServiceAccountOidcIdentityDialogLayout";
type CreateServiceAccountOidcIdentityDialogProps = {
    externalId: string;
    open: boolean;
    serviceAccount: UserResource;
    onSave: (identity: ServiceAccountOidcIdentityResource) => Promise<unknown>;
    onCancel: () => unknown;
};
export function CreateServiceAccountOidcIdentityDialog({ externalId, open, serviceAccount, onSave, onCancel }: CreateServiceAccountOidcIdentityDialogProps) {
    return (<Dialog open={open} dialogWidth={"1000px"}>
            <ConfigureServiceAccountOidcIdentityDialogLayout externalId={externalId} oidcIdentity={{
            Id: "",
            ServiceAccountId: serviceAccount.Id,
            Name: "",
            Issuer: "",
            Subject: "",
        }} onSave={onSave} onCancel={onCancel}/>
        </Dialog>);
}
