import type { InputPathToObject, InputPathToValue } from "@octopusdeploy/step-inputs";
import type { ObjectRuntimeInputs, PathToInput, PlainObjectTypeDefinition } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessor, createObjectValueAccessor, getPathToInput, getPathToInputObject, isInputPathToObject } from "@octopusdeploy/step-runtime-inputs";
import type { NoteExpression, SelectComponent, SelectOption } from "@octopusdeploy/step-ui";
import { isEqual } from "lodash";
import React from "react";
import { getSelectedOption, getSelectedOptionForPrimitive } from "~/components/StepPackageEditor/Inputs/Components/DiscriminatorComponents/getSelectedOption";
import { getSchemaForInputObject } from "~/components/StepPackageEditor/Inputs/schemaTraversal";
import type { StepInputDependencies } from "~/components/StepPackageEditor/StepInputDependencies";
import type { StepPackageContext } from "~/components/StepPackageEditor/StepPackageContext";
import type { InputSummary } from "~/components/StepPackageEditor/Summary/InputSummary";
import Select from "~/primitiveComponents/form/Select/Select";
import { getSingle } from "~/utils/getSingle";
import { Note } from "../../../Note/Note";
import { mapInitialInputs } from "../../../mapInitialInputs";
interface StepPackageSelectProps<StepInputs> extends SharedSelectProps<StepInputs> {
    input: InputPathToObject<unknown> | InputPathToValue<unknown>;
    options: SelectOption<unknown>[];
    label: string;
    note?: NoteExpression[];
}
interface SharedSelectProps<StepInputs> {
    inputs: ObjectRuntimeInputs<StepInputs>;
    getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (path: PathToInput) => string;
    inputDependencies?: StepInputDependencies;
}
const isNonDiscriminatorInput = (input: InputPathToObject<unknown>, schema: PlainObjectTypeDefinition): boolean => getSchemaForInputObject(input, schema).discriminatorProperties.length === 0;
export function StepPackageSelect<StepInputs>(props: StepPackageSelectProps<StepInputs>) {
    return (<>
            {isInputPathToObject(props.input) ? (isNonDiscriminatorInput(props.input, props.getInputSchema(props.inputs)) ? (<NonDiscriminatorObjectSelect input={props.input} label={props.label} options={props.options} setInputs={props.setInputs} getFieldError={props.getFieldError} inputs={props.inputs} getInputSchema={props.getInputSchema} inputDependencies={props.inputDependencies}/>) : (<DiscriminatedUnionSelect input={props.input} label={props.label} options={props.options} setInputs={props.setInputs} getFieldError={props.getFieldError} inputs={props.inputs} getInputSchema={props.getInputSchema} inputDependencies={props.inputDependencies}/>)) : (<LiteralUnionSelect input={props.input} label={props.label} options={props.options} inputs={props.inputs} getInputSchema={props.getInputSchema} setInputs={props.setInputs} getFieldError={props.getFieldError} inputDependencies={props.inputDependencies}/>)}
            <Note note={props.note}/>
        </>);
}
export function getSelectSummary<StepInputs>(inputs: ObjectRuntimeInputs<StepInputs>, select: SelectComponent, getInputSchema: (inputs: ObjectRuntimeInputs<StepInputs>) => PlainObjectTypeDefinition): InputSummary {
    if (isInputPathToObject(select.input)) {
        const inputSchema = getInputSchema(inputs);
        if (isNonDiscriminatorInput(select.input, inputSchema)) {
            const accessor = createObjectValueAccessor<StepInputs, unknown>(select.input);
            const value = accessor.getInputValue(inputs);
            // Non discriminated object selects default to the first option if no value is set
            if (value === undefined) {
                return { isDefaultValue: true, value: select.options[0].label };
            }
            const selectedOption = getSingle(select.options.filter((o) => isEqual(o.newValue, value)), "Multiple options match the selected value, based on their values. Ensure that each option has a unique value", "None of the available options matched the selected value, based on their values.");
            return { isDefaultValue: false, value: selectedOption.label };
        }
        const selectedOption = getSelectedOption(select.input, select.options, getInputSchema(inputs));
        return { isDefaultValue: false, value: selectedOption.label };
    }
    const accessor = createInputValueAccessor<StepInputs, unknown>(select.input);
    const value = accessor.getInputValue(inputs);
    const selectedOption = getSelectedOptionForPrimitive(value, select.options);
    //TODO: step-api handle Bound case
    return { isDefaultValue: false, value: selectedOption.label };
}
interface DiscriminatedUnionSelectProps<StepInputs> extends SharedSelectProps<StepInputs> {
    input: InputPathToObject<unknown>;
    label: string;
    options: SelectOption<unknown>[];
}
function DiscriminatedUnionSelect<StepInputs>(props: DiscriminatedUnionSelectProps<StepInputs>) {
    const { options, label, input } = props;
    const accessor = createObjectValueAccessor<StepInputs, unknown>(input);
    const inputPath = getPathToInputObject(input);
    const selectedOption = getSelectedOption(input, options, props.getInputSchema(props.inputs));
    const items = options.map((o) => {
        return { value: o.label, text: o.label };
    });
    return (<Select items={items} value={selectedOption.label} onChange={(x) => {
            const newlySelectedOption = options.find((o) => o.label === x);
            if (!newlySelectedOption) {
                throw new Error("Selected option not found");
            }
            const newValue = newlySelectedOption.newValue;
            const updatedInputs = accessor.changeInputValue(props.inputs, newValue);
            const newRuntimeSchema = getSchemaForInputObject(props.input, props.getInputSchema(updatedInputs));
            // TODO: Action context can be mapped from input dependencies as they have the same properties
            // this feels a bit out of place though, we could look to plumb them through
            const actionContext: StepPackageContext = props.inputDependencies ?? {};
            const mappedInputs = mapInitialInputs(newRuntimeSchema, false, inputPath, updatedInputs, actionContext);
            props.setInputs(mappedInputs);
        }} error={props.getFieldError(inputPath)} label={label} sortItems={false}/>);
}
interface LiteralUnionSelectProps<StepInputs> extends SharedSelectProps<StepInputs> {
    input: InputPathToValue<unknown>;
    label: string;
    options: SelectOption<unknown>[];
}
function LiteralUnionSelect<StepInputs>(props: LiteralUnionSelectProps<StepInputs>) {
    const { options, label, input } = props;
    const accessor = createInputValueAccessor<StepInputs, unknown>(input);
    const value = accessor.getInputValue(props.inputs);
    //TODO: step-api handle bound case here
    const inputPath = getPathToInput(input);
    const selectedOption = getSelectedOptionForPrimitive(value, options);
    const items = options.map((o) => {
        return { value: o.label, text: o.label };
    });
    return (<Select items={items} value={selectedOption.label} onChange={(x) => {
            const newlySelectedOption = options.find((o) => o.label === x);
            if (!newlySelectedOption) {
                throw new Error("Selected option not found");
            }
            const newValue = newlySelectedOption.newValue;
            const updatedInputs = accessor.changeInputValue(props.inputs, newValue);
            props.setInputs(updatedInputs);
        }} error={props.getFieldError(inputPath)} label={label} sortItems={false}/>);
}
interface NonDiscriminatorObjectSelectProps<StepInputs> extends SharedSelectProps<StepInputs> {
    input: InputPathToObject<unknown>;
    label: string;
    options: SelectOption<unknown>[];
}
function NonDiscriminatorObjectSelect<StepInputs>(props: NonDiscriminatorObjectSelectProps<StepInputs>) {
    const { options, label, input } = props;
    const accessor = createObjectValueAccessor<StepInputs, unknown>(input);
    const inputPath = getPathToInputObject(input);
    const currentInputValue = accessor.getInputValue(props.inputs);
    // Non discriminated object selects default to the first option is no value is currently set for the specified input
    const selectedOption = currentInputValue === undefined
        ? options[0]
        : getSingle(options.filter((o) => isEqual(o.newValue, currentInputValue)), "Multiple options match the selected value, based on their values. Ensure that each option has a unique value", "None of the available options matched the selected value, based on their values.");
    const items = options.map((o) => {
        return { value: o.label, text: o.label };
    });
    return (<Select items={items} value={selectedOption.label} onChange={(x) => {
            const newlySelectedOption = options.find((o) => o.label === x);
            if (!newlySelectedOption) {
                throw new Error("Selected option not found");
            }
            const newValue = newlySelectedOption.newValue;
            const updatedInputs = accessor.changeInputValue(props.inputs, newValue);
            props.setInputs(updatedInputs);
        }} error={props.getFieldError(inputPath)} label={label} sortItems={false}/>);
}
