import { Tooltip } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
export default (props: {
    for: ProjectOrEnvironment;
}) => {
    const thingText = props.for === ProjectOrEnvironment.Project ? "project" : "environment";
    return (<Tooltip content={`There is a deployment freeze in place for this ${thingText}`}>
            <em className={cn(styles.frozenIcon, "fa-regular", "fa-snowflake")}/>
        </Tooltip>);
};
export enum ProjectOrEnvironment {
    Project,
    Environment
}
