import type { LinkHref } from "@octopusdeploy/portal-routes";
import { flatten } from "lodash";
import type * as React from "react";
export interface MenuNodeItem {
    url: LinkHref;
    text: string;
    title?: string;
    icon?: JSX.Element;
    exact?: boolean;
    accessoryView?: React.ReactNode;
    linkClassName?: string;
}
export interface MenuNodeGroup {
    label: string;
    children: MenuNode[];
}
export type MenuNode = MenuNodeGroup | MenuNodeItem;
export function isMenuGroup(node: MenuNode): node is MenuNodeGroup {
    return "children" in node;
}
export function isMenuItem(node: MenuNode): node is MenuNodeItem {
    return !isMenuGroup(node);
}
export function hasActiveDescendants(group: MenuNodeGroup, isUrlActive: (to: LinkHref, exact: boolean) => boolean): boolean {
    const allDescendantLinks = (_: MenuNodeGroup): Array<MenuNodeItem> => flatten(_.children.map((c: MenuNode) => {
        if (isMenuGroup(c)) {
            return allDescendantLinks(c);
        }
        return [c];
    }));
    return allDescendantLinks(group).some((l) => isUrlActive(l.url, Boolean(l.exact)));
}
