/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import type { NavigationBarActionData, NavigationBarItemData, NavigationBarLinkItemData, ShowLinkAsActive } from "@octopusdeploy/design-system-components";
import { ConfigurationIcon, NavigationBar, Theme, Tooltip } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { isNotNull } from "@octopusdeploy/type-utils";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { WelcomeDialog } from "~/areas/dashboard/Onboarding/WelcomeDialog/WelcomeDialog";
import { client, repository, session } from "~/clientInstance";
import { HelpNavigationActionButton } from "~/components/Navbar/HelpNavigationActionButton";
import DeprecationNotificationsPoller from "~/components/Navbar/NotificationPoller/DeprecationNotificationsPoller";
import TargetTagsNotifications from "~/components/Navbar/NotificationPoller/TargetTagsNotifications";
import { ProjectSwitcherNavigationBarItem } from "~/components/Navbar/ProjectSwitcherNavigationBarItem";
import { SpaceSwitcherNavigationBarItem } from "~/components/Navbar/SpaceSwitcherNavigationBarItem";
import { UserAccountMenu } from "~/components/Navbar/UserAccountMenu";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import SearchAndOpenPopover from "~/globalSearch/SearchAndOpenPopover";
import LicenceNotificationPoller from "./NotificationPoller/LicenceNotificationPoller";
import PageLayoutUpliftNotifications from "./NotificationPoller/PageLayoutUpliftNotification";
import UpdateAvailableNotificationPoller from "./NotificationPoller/UpdateAvailableNotificationPoller";
import NotificationsMenu from "./NotificationsMenu";
import styles from "./style.module.less";
interface NavbarProps {
    spaceContext: SpaceContext;
}
export const Navbar = ({ spaceContext }: NavbarProps) => {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const navigationItems: NavigationBarItemData[] = [...getNavigationItems(spaceContext)];
    const actions: NavigationBarActionData[] = [...getNavigationActions(session.currentUser!, spaceContext, isLargerThanIpad)];
    return (<div id="topnavbar" className={styles.topnavbar}>
            <div className={navbarUpliftStyles}>
                <Theme themeName={"dark"}>
                    <NavigationBar logo={<SpaceSwitcherNavigationBarItem spaceContext={spaceContext}/>} items={navigationItems} actions={actions}/>
                </Theme>
                <UpdateAvailableNotificationPoller />
                <LicenceNotificationPoller />
                <PageLayoutUpliftNotifications />
                <TargetTagsNotifications />
                <DeprecationNotificationsPoller />
                <WelcomeDialog />
            </div>
        </div>);
};
function getNavigationItems(spaceContext: SpaceContext) {
    const createLinkNavigationItem = (linkData: LinkData): NavigationBarLinkItemData => {
        return {
            type: "link",
            href: linkData.href,
            label: linkData.label,
            showLinkAsActive: linkData.showAsActive ?? "if path partially matches",
        };
    };
    const navigationItems: NavigationBarItemData[] = [];
    if (isSpecificSpaceContext(spaceContext)) {
        navigationItems.push(createLinkNavigationItem({ label: "Dashboard", href: links.dashboardPage.generateUrl({ spaceId: spaceContext.Id }), showAsActive: "if path matches exactly" }));
        const hasProjectPermissions = session.currentPermissions!.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.ProjectView);
        const hasInfrastructurePermissions = isAllowed({ permission: [Permission.MachineView, Permission.EnvironmentView, Permission.WorkerView], wildcard: true });
        const hasTenantPermissions = session.currentPermissions!.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.TenantView);
        const hasInsightsPermissions = session.currentPermissions!.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.InsightsReportView);
        const hasLibraryPermissions = isAllowed({ permission: [Permission.FeedView, Permission.LifecycleEdit, Permission.VariableView, Permission.ActionTemplateView, Permission.CertificateView], wildcard: true });
        if (hasProjectPermissions) {
            const { href } = createLinkNavigationItem({ label: "Projects", href: links.projectsPage.generateUrl({ spaceId: spaceContext.Id }) });
            navigationItems.push({
                type: "custom",
                key: "Projects",
                content: <ProjectSwitcherNavigationBarItem projectsHref={href}/>,
                fallbackLink: {
                    href: href,
                    label: "Projects",
                    showLinkAsActive: "if path partially matches",
                },
            });
        }
        if (hasInfrastructurePermissions) {
            navigationItems.push(createLinkNavigationItem({ href: links.infrastructureRootRedirect.generateUrl({ spaceId: spaceContext.Id }), label: "Infrastructure" }));
        }
        if (hasTenantPermissions) {
            navigationItems.push(createLinkNavigationItem({ href: links.tenantsPage.generateUrl({ spaceId: spaceContext.Id }), label: "Tenants" }));
        }
        if (hasInsightsPermissions) {
            navigationItems.push(createLinkNavigationItem({ href: links.insightReportListPage.generateUrl({ spaceId: spaceContext.Id }), label: "Insights" }));
        }
        if (hasLibraryPermissions) {
            navigationItems.push(createLinkNavigationItem({ href: links.libraryRootRedirect.generateUrl({ spaceId: spaceContext.Id }), label: "Library" }));
        }
    }
    if (!isSpaceNotFound(spaceContext) || spaceContext.isAlsoInSystemContext) {
        const hasTaskPermissions = session.currentPermissions!.hasPermissionInAnyScope(Permission.TaskView);
        if (hasTaskPermissions) {
            navigationItems.push(createLinkNavigationItem({ href: links.tasksPage.generateUrl(), label: "Tasks" }));
        }
    }
    return navigationItems;
}
function getNavigationActions(currentUser: UserResource, spaceContext: SpaceContext, isLargerThanIpad: boolean): NavigationBarActionData[] {
    return [
        { key: "search", content: <SearchAndOpenPopover isFullWidth={false}/> },
        { key: "configuration", content: <ConfigurationNavigationActionButton spaceContext={spaceContext}/> },
        { key: "notifications", content: <NotificationsMenu /> },
        isLargerThanIpad ? { key: "help-menu", content: <HelpNavigationActionButton /> } : null,
        {
            key: "user-menu",
            content: (<AnalyticLinkLocationProvider location={"Profile Menu"}>
                    <UserAccountMenu currentUser={currentUser} spaceContext={spaceContext}/>
                </AnalyticLinkLocationProvider>),
        },
    ].filter(isNotNull);
}
function ConfigurationNavigationActionButton({ spaceContext }: {
    spaceContext: SpaceContext;
}) {
    if (!hasConfigurationPermissions(spaceContext)) {
        return null;
    }
    return (<Tooltip content="Configuration">
            <NavigationBar.IconLink href={links.configurationRootRedirect.generateUrl()} showLinkAsActive="if path partially matches" accessibleName="Configuration" icon={<ConfigurationIcon />}/>
        </Tooltip>);
}
function hasConfigurationPermissions(spaceContext: SpaceContext) {
    if (!isSpaceNotFound(spaceContext) || spaceContext.isAlsoInSystemContext) {
        return (session.currentPermissions!.scopeToSpace(repository.spaceId).hasAnyPermissions() ||
            isAllowed({
                permission: [Permission.AdministerSystem, Permission.EventView, Permission.TeamView, Permission.UserView, Permission.UserRoleView, Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete],
                wildcard: true,
            }));
    }
    return false;
}
interface LinkData {
    label: string;
    href: LinkHref;
    showAsActive?: ShowLinkAsActive;
}
const navbarUpliftStyles = css({
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${themeTokens.color.navigation.border.primary}`,
    "a:hover": {
        color: "inherit",
    },
});
