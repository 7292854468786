/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { NewMachineResource, NewEndpointResource, ListeningTentacleEndpointResource, SshEndpointResource, AzureCloudServiceEndpointResource, AzureServiceFabricClusterEndpointResource, KubernetesEndpointResource, OfflineDropEndpointResource, StepPackageEndpointResource, } from "@octopusdeploy/octopus-server-client";
import { AzureCloudServiceEndpointDeploymentSlot, AzureServiceFabricSecurityMode, AADCredentialType, OfflineDropDestinationType, MachineModelHealthStatus } from "@octopusdeploy/octopus-server-client";
import SshEndpoint from "../MachineSettings/Endpoints/SshEndpoint";
import { CommunicationStyle } from "../MachineSettings/Endpoints/endpointRegistry";
export default function CreateNewMachineResource(location: URI, defaultMachinePolicyId: string): NewMachineResource {
    const query = location.search(true);
    const resourceName = (query.name as string) ?? "";
    let endpoint: NewEndpointResource = {
        CommunicationStyle: query.type as CommunicationStyle,
        Name: resourceName,
    };
    switch (endpoint.CommunicationStyle) {
        case CommunicationStyle.TentaclePassive: {
            const tentacleEndpoint = endpoint as ListeningTentacleEndpointResource;
            tentacleEndpoint.Uri = query.uri;
            tentacleEndpoint.Thumbprint = query.thumbprint;
            tentacleEndpoint.ProxyId = query.proxyId;
            endpoint = tentacleEndpoint;
            break;
        }
        case CommunicationStyle.Ssh: {
            const sshEndpoint = endpoint as SshEndpointResource;
            sshEndpoint.Uri = query.uri;
            sshEndpoint.Host = query.host;
            sshEndpoint.Port = query.port ? query.port : 22;
            sshEndpoint.Fingerprint = query.fingerprint;
            sshEndpoint.ProxyId = query.proxyId;
            sshEndpoint.DotNetCorePlatform = SshEndpoint.dotnetCorePlatforms[0];
            sshEndpoint.HostKeyAlgorithm = query.hostKeyAlgorithm;
            endpoint = sshEndpoint;
            break;
        }
        case CommunicationStyle.AzureCloudService: {
            const csEndpoint = endpoint as AzureCloudServiceEndpointResource;
            csEndpoint.Slot = AzureCloudServiceEndpointDeploymentSlot.Staging;
            csEndpoint.SwapIfPossible = true;
            csEndpoint.UseCurrentInstanceCount = true;
            endpoint = csEndpoint;
            break;
        }
        case CommunicationStyle.AzureServiceFabricCluster: {
            const fabricEndpoint = endpoint as AzureServiceFabricClusterEndpointResource;
            fabricEndpoint.SecurityMode = AzureServiceFabricSecurityMode.Unsecure;
            fabricEndpoint.AadCredentialType = AADCredentialType.UserCredential;
            endpoint = fabricEndpoint;
            break;
        }
        case CommunicationStyle.Kubernetes: {
            const kubernetesEndpoint = endpoint as KubernetesEndpointResource;
            endpoint = kubernetesEndpoint;
            break;
        }
        case CommunicationStyle.OfflineDrop: {
            const offlineDropEndpoint = endpoint as OfflineDropEndpointResource;
            offlineDropEndpoint.Destination = {
                DestinationType: OfflineDropDestinationType.Artifact,
            };
            break;
        }
        case CommunicationStyle.StepPackage: {
            const stepPackageEndpoint = endpoint as StepPackageEndpointResource;
            stepPackageEndpoint.DeploymentTargetTypeId = query.deploymentTargetTypeId;
            break;
        }
        default: {
            break;
        }
    }
    const machine: NewMachineResource = {
        MachinePolicyId: defaultMachinePolicyId,
        Name: resourceName ? resourceName : query.host,
        IsDisabled: false,
        HealthStatus: MachineModelHealthStatus.Unknown,
        HasLatestCalamari: true,
        IsInProcess: true,
        Endpoint: endpoint,
        ShellName: "",
    };
    return machine;
}
