import type { GitDependencyReference } from "@octopusdeploy/octopus-server-client";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useGitCredentialsFromContext, useRefreshGitCredentialsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import type { TemplateValuesSectionComponent, TemplateValuesSectionProps } from "~/components/Actions/helmChartUpgrade/HelmTemplateValuesDrawer";
import type { GitRepositoryTemplateValuesSource } from "~/components/Actions/helmChartUpgrade/TemplateValuesSource";
import { SimpleGitRepositorySourceSelector } from "~/components/GitRepositorySource/SimpleGitRepositorySourceSelector";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { ExpandableFormSection, Note, Summary } from "~/components/form/index";
import StringHelper from "~/utils/StringHelper/index";
const DefaultYamlFileLocation = "values.yaml";
export const GitRepositoryTemplateValuesSection = forwardRef<TemplateValuesSectionComponent, TemplateValuesSectionProps<GitRepositoryTemplateValuesSource>>((props: TemplateValuesSectionProps<GitRepositoryTemplateValuesSource>, ref) => {
    const [valuesFilePaths, setValuesFilePaths] = useState<string>(props.editingTemplateValuesSource?.ValuesFilePaths || "");
    const [gitDependencyReference, setGitDependencyReference] = useState<GitDependencyReference>(props.editingGitDependency || {
        Name: "",
        RepositoryUri: "",
        DefaultBranch: "main",
        GitCredentialType: "Library",
        FilePathFilters: [],
    });
    const gitCredentials = useGitCredentialsFromContext();
    const refreshGitCredentials = useRefreshGitCredentialsFromContext();
    useImperativeHandle(ref, () => ({
        validateAndSave: () => {
            if (StringHelper.isNullOrWhiteSpace(gitDependencyReference.RepositoryUri)) {
                return "A repository URI must be defined";
            }
            if (gitDependencyReference.GitCredentialType === "Library" && !gitDependencyReference.GitCredentialId) {
                return "A library Git credential must be selected";
            }
            if (StringHelper.isNullOrWhiteSpace(gitDependencyReference.DefaultBranch)) {
                return "A default branch must be defined";
            }
            if (!valuesFilePaths) {
                return "At least one values file path must be defined";
            }
            return {
                valuesSource: {
                    Type: "GitRepository",
                    GitDependencyName: gitDependencyReference.Name,
                    ValuesFilePaths: valuesFilePaths,
                },
                gitDependency: gitDependencyReference,
            };
        },
    }));
    const valueFilePathsSummary = () => {
        if (!StringHelper.isNullOrWhiteSpace(valuesFilePaths)) {
            return Summary.summary("Paths to values files has been provided");
        }
        return Summary.placeholder("No paths to values files has been provided");
    };
    return (<>
            <SimpleGitRepositorySourceSelector gitDependency={gitDependencyReference} onGitDependencyChanged={setGitDependencyReference} gitCredentials={gitCredentials} refreshGitCredentials={refreshGitCredentials} expandedByDefault={true} localNames={props.localNames} getFieldError={() => ""} errorKeyPrefix={"GitRepositoryTemplateValuesSection"}/>
            <ExpandableFormSection errorKey="GitRepositoryTemplateValuesSectionPaths" isExpandedByDefault={true} title="Paths" summary={valueFilePathsSummary()} help="Specify the paths to values files">
                <VariableLookupText localNames={props.localNames} value={valuesFilePaths} onChange={(path) => setValuesFilePaths(path)} placeholder={DefaultYamlFileLocation} label="Relative path to values files"/>
                <Note>
                    A newline-separated list of file names, relative to the repository root to be included as additional <code>--values</code> files. Variable replacement will be run on these files before they are used. Extended template and wildcard
                    syntax is supported. E.g., <em>values.{`#{Octopus.Environment.Name}`}.yaml</em>, <em>**\specific-folder\*.yaml</em>.
                </Note>
            </ExpandableFormSection>
        </>);
});
