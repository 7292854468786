import { SpaceSwitcher } from "@octopusdeploy/main-navigation";
import { AnalyticLinkLocationProvider } from "@octopusdeploy/portal-analytics";
import { links } from "@octopusdeploy/portal-routes";
import { useCallback, useState } from "react";
import * as React from "react";
import AddSpaceDialogLayout from "~/areas/configuration/components/Spaces/AddSpaceDialogLayout";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { SpaceLimitReachedDialogLayout } from "~/components/LicenseLimitReachedDialogLayout/index";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { isSpecificSpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
interface SpaceSwitcherUpliftProps {
    spaceContext: SpaceContext;
}
export function SpaceSwitcherNavigationBarItem({ spaceContext }: SpaceSwitcherUpliftProps) {
    const navigation = useSpaceAwareNavigation();
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
    const { isOpen: isAddSpaceDialogOpen, openDialog: openAddSpaceDialog, closeDialog: closeAddSpaceDialog } = useDialogTrigger();
    const currentSpace = isSpecificSpaceContext(spaceContext) ? spaceContext : undefined;
    const onUpgradeSpacesDialogRequested = useCallback(() => {
        setIsUpgradeDialogOpen(true);
    }, []);
    return (<AnalyticLinkLocationProvider location={"Space Switcher"}>
            <SpaceSwitcher currentSpace={currentSpace} onNewSpaceRequested={openAddSpaceDialog} onUnlockMoreSpacesRequested={onUpgradeSpacesDialogRequested}/>
            <Dialog open={isUpgradeDialogOpen}>
                <SpaceLimitReachedDialogLayout onActionClick={() => setIsUpgradeDialogOpen(false)}/>
            </Dialog>
            <Dialog open={isAddSpaceDialogOpen}>
                <AddSpaceDialogLayout onSpaceCreated={(space) => {
            closeAddSpaceDialog();
            navigation.navigate(links.editSpacePage.generateUrl({ currentSpaceId: space.Id }));
        }}/>
            </Dialog>
        </AnalyticLinkLocationProvider>);
}
