/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ProcessType } from "@octopusdeploy/octopus-server-client";
import type { ScopeSpecification } from "~/areas/variables/ReadonlyVariableResource/ReadonlyVariableResource";
import type { ScopeItem } from "../VariableSorting/sortVariables";
import { ScopeType } from "../VariableSorting/sortVariables";
import type { ScopeConsistencySuccessResult, ScopeConsistencyFailureResult, ScopeConsistencyRule, AvailableScopeLookup } from "./model";
class ScopeConsistencyResults {
    static success = (): ScopeConsistencySuccessResult => ({ success: true });
    static failure = (message: string, culprits: ScopeItem[]): ScopeConsistencyFailureResult => ({ success: false, message, culprits });
}
function getRunbookProcesses(scopes: ScopeSpecification, lookup: AvailableScopeLookup) {
    return getProcessOwners(scopes, lookup, (id) => lookup("Processes", id).ProcessType === ProcessType.Runbook);
}
function getProcessOwners(scopes: ScopeSpecification, lookup: AvailableScopeLookup, filter: (id: string) => boolean = () => true) {
    const results: Array<ScopeItem & {
        processType: ProcessType;
    }> = scopes
        .ProcessOwner!.filter((x) => lookup("Processes", x!) && filter(x!))
        .map((x) => lookup("Processes", x!))
        .map((x) => ({ type: ScopeType.ProcessOwner, id: x.Id, name: x.Name, processType: x.ProcessType }));
    return results;
}
export const processChannelConsistency: ScopeConsistencyRule = {
    isApplicable: ({ ProcessOwner = [], Channel = [] }) => {
        return ProcessOwner.length > 0 && Channel.length > 0;
    },
    validate: (scopes, lookup) => {
        const runbooks = getRunbookProcesses(scopes, lookup);
        if (runbooks.length === 0) {
            return ScopeConsistencyResults.success();
        }
        return ScopeConsistencyResults.failure(`Scoping to a runbook and a channel is mutually exclusive and not supported.`, runbooks);
    },
};
//To do match the action against the process we need to know which process the action belongs to or we need a way to lookup the process based on the action.
//We don't currently support scoping to runbook actions, so we assume any action scopes are those from the deployment process at this point.
export const deploymentProcessActionConsistency: ScopeConsistencyRule = {
    isApplicable: ({ ProcessOwner = [], Action = [] }) => {
        return ProcessOwner.length > 0 && Action.length > 0;
    },
    validate: (scopes, lookup) => {
        const processes = getProcessOwners(scopes, lookup);
        const runbooks = processes.filter((x) => x.processType === ProcessType.Runbook);
        if (runbooks.length === 0) {
            return ScopeConsistencyResults.success();
        }
        //No deployment process scope applied sothis is mutually exclusive
        if (processes.length === runbooks.length) {
            return ScopeConsistencyResults.failure(`Scoping to a runbook and a deployment process step is mutually exclusive.`, runbooks);
        }
        return ScopeConsistencyResults.failure(`This variable value will be unavailable to runbooks as it has been scoped to a deployment process step.`, runbooks);
    },
};
export function getAllScopeConsistencyRules() {
    return [processChannelConsistency, deploymentProcessActionConsistency];
}
