import * as React from "react";
import styles from "./style.module.less";
interface ThumbprintTextProps {
    thumbprint: string;
}
class ThumbprintText extends React.PureComponent<ThumbprintTextProps> {
    render() {
        return <span className={styles.thumbprint}>{this.props.thumbprint}</span>;
    }
    static displayName = "ThumbprintText";
}
export default ThumbprintText;
