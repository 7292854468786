import { css, cx } from "@emotion/css";
import { Checkbox, ListItem, Tooltip } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import { color } from "@octopusdeploy/design-system-tokens/src/generated/themeTokens";
import type { GitHubRepository, GitHubUnknownRepository } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { GitHubChipList, GitHubRepositoryLanguageChip, GitHubRepositoryVisibilityChip } from "./ChipList/GitHubChips";
export interface GitHubRepositoryWithDisabled extends GitHubRepository {
    disabled?: boolean;
    disabledText?: string;
}
export interface GitHubAppRepositoryListItemProps {
    repository: GitHubRepositoryWithDisabled | GitHubUnknownRepository;
    selected: boolean;
    onChange: (newValue: boolean, repositoryId: string) => void;
    disableInteraction?: boolean;
}
export function GitHubAppRepositoryListItem({ repository, selected, onChange, disableInteraction }: GitHubAppRepositoryListItemProps) {
    const styles = {
        container: css({
            display: "flex",
            flexDirection: "row",
            paddingLeft: space[16],
            paddingRight: space[16],
            gap: space[4],
            alignItems: "center",
        }),
        checkboxContainer: css({
            flexGrow: 1,
            alignSelf: "flex-start",
        }),
        repositoryLabel: css({
            marginTop: space[16],
            marginBottom: space[16],
            height: space[24],
            lineHeight: "1.5",
        }),
        repositoryLabelDisabled: css({
            marginTop: space[16],
            marginBottom: space[16],
            height: space[24],
            lineHeight: "1.5",
            color: color.text.disabled,
        }),
    };
    const isKnownRepository = isKnownGitHubRepository(repository);
    return (<ListItem>
            <div className={styles.container}>
                <div className={styles.checkboxContainer}>
                    {disableInteraction ? (<div className={!isKnownRepository || !repository.disabled ? styles.repositoryLabel : styles.repositoryLabelDisabled}>{repository.RepositoryName ?? repository.RepositoryId}</div>) : (<Checkbox value={selected} label={<RepositoryLabel repository={repository}/>} accessibleName={repository.RepositoryName ?? repository.RepositoryId} onChange={(selected) => onChange(selected, repository.RepositoryId)} disabled={isKnownRepository && repository.disabled}/>)}
                </div>
                {isKnownRepository && (<GitHubChipList>
                        <GitHubRepositoryVisibilityChip repository={repository}/>
                        <GitHubRepositoryLanguageChip repository={repository}/>
                    </GitHubChipList>)}
            </div>
        </ListItem>);
}
interface RepositoryLabelProps {
    repository: GitHubRepositoryWithDisabled | GitHubUnknownRepository;
}
function RepositoryLabel({ repository }: RepositoryLabelProps) {
    if (isKnownGitHubRepository(repository) && repository.disabled && repository.disabledText) {
        return (<Tooltip content={repository.disabledText} position="right">
                {repository.RepositoryName ?? repository.RepositoryId}
            </Tooltip>);
    }
    return <>{repository.RepositoryName ?? repository.RepositoryId}</>;
}
interface LockIconProps {
    unlocked?: boolean;
}
function isKnownGitHubRepository(object: GitHubRepositoryWithDisabled | GitHubUnknownRepository): object is GitHubRepositoryWithDisabled {
    return "IsPrivate" in object;
}
export function LockIcon({ unlocked }: LockIconProps) {
    const iconInLabelStyles = css({
        marginRight: space[8],
    });
    const lockClass = unlocked ? "fa-lock-open" : "fa-lock";
    return <em className={cx("fa", lockClass, iconInLabelStyles)} aria-hidden={true}/>;
}
