/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { KubernetesConfigMapProperties } from "@octopusdeploy/legacy-action-properties";
import { PackageRequirement } from "@octopusdeploy/octopus-server-client";
import * as _ from "lodash";
import * as React from "react";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import { EditResourceYaml } from "~/components/Actions/kubernetes/editResourceYaml";
import type { ActionEditProps } from "~/components/Actions/pluginRegistry";
import type { ScriptPackageProperties } from "~/components/Actions/script/ScriptPackageReferenceDialog";
import { BaseComponent } from "~/components/BaseComponent/BaseComponent";
import StringKeyValueEditList from "~/components/EditList/KeyValueEditList";
import { default as ExpandableFormSection } from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import { VariableLookupText } from "~/components/form/VariableLookupText";
import { JsonUtils } from "~/utils/jsonUtils";
import type { ActionWithFeeds } from "../commonActionHelpers";
import { exportConfigMap, importConfigMap } from "./importYaml";
interface ScriptActionEditState {
    originalStepPackageRequirement: PackageRequirement;
    resourceYaml: string;
}
export type KubernetesConfigMapComponentProps = ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties> & {
    importLabels: boolean;
};
type Props = KubernetesConfigMapComponentProps & ActionWithFeeds;
class KubernetesConfigMapComponentInternal extends BaseComponent<Props, ScriptActionEditState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            originalStepPackageRequirement: PackageRequirement.LetOctopusDecide,
            resourceYaml: exportConfigMap(this.props, this.props.importLabels),
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const yaml = exportConfigMap(nextProps, nextProps.importLabels);
        if (this.state.resourceYaml !== yaml) {
            this.setState({ resourceYaml: yaml });
        }
    }
    render() {
        return (<div>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.ConfigMapYaml" isExpandedByDefault={false} title="Edit YAML" summary={Summary.placeholder("Edit the resource YAML")} help={"Edit the resource YAML."}>
                    <EditResourceYaml yaml={this.state.resourceYaml} onSave={(value) => this.setState({ resourceYaml: value }, () => importConfigMap(this.props, value, this.props.importLabels))}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.ConfigMapName" isExpandedByDefault={this.props.expandedByDefault} title="Config Map Name" summary={this.configMapSummary()} help={"The name of the config map resource"}>
                    <VariableLookupText localNames={this.props.localNames} value={this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapName"]} onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ConfigMapName"]: x })} error={this.props.getFieldError("Octopus.Action.KubernetesContainers.ConfigMapName")} label="Config Map name"/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="Octopus.Action.KubernetesContainers.ConfigMapValues" isExpandedByDefault={this.props.expandedByDefault} title="Config Map Items" summary={this.configMapLiteralSummary()} help={"The config map resource values"}>
                    <StringKeyValueEditList items={this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapValues"]} name="Config map item" separator="" onChange={(val) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ConfigMapValues"]: val })} valueLabel="Value" valueMultiline={true} valueRowsMax={5} keyLabel="Key" hideBindOnKey={false} projectId={this.props.projectId} gitRef={this.props.gitRef}/>
                </ExpandableFormSection>
            </div>);
    }
    private configMapSummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapName"]) {
            return Summary.summary(<span>
                    Create a config map called <strong>{this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapName"]}</strong>
                </span>);
        }
        return Summary.placeholder(<span>Define the config map name</span>);
    }
    private configMapLiteralSummary() {
        const items = _.toPairs<string>(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapValues"], {}));
        if (items.length !== 0) {
            return Summary.summary(<span>
                    <span>Create a config map with the values </span>
                    {_.chain(items)
                    .flatMap((item) => [
                    <strong key={item[0]}>
                                {item[0]}: {item[1].toString().replace(/\n[\S\s]*/, " ...")}
                            </strong>,
                    <span key={`${item[0]}_,`}>, </span>,
                ])
                    .slice(0, -1)
                    .value()}
                </span>);
        }
        return Summary.placeholder(<span>Define the config map values</span>);
    }
    static displayName = "KubernetesConfigMapComponentInternal";
}
export function KubernetesConfigMapComponent(props: React.PropsWithChildren<KubernetesConfigMapComponentProps>) {
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    return <KubernetesConfigMapComponentInternal {...props} feeds={feeds} refreshFeeds={refreshFeeds}/>;
}
