import { css } from "@emotion/css";
import { LinearProgress } from "@octopusdeploy/design-system-components";
import * as React from "react";
import type { Errors } from "~/components/DataBaseComponent/Errors";
import BusyFromPromise from "../BusyFromPromise/BusyFromPromise";
import ErrorPanel from "../ErrorPanel/ErrorPanel";
interface LoadingProps {
    busy?: Promise<void> | boolean;
    errors?: Errors | undefined;
    className?: string;
    children?: React.ReactNode;
}
export function Loading({ busy, errors, children }: LoadingProps) {
    return (<div className={loadingStyles}>
            <BusyFromPromise promise={busy}>{(busy: boolean) => <LinearProgress variant={"indeterminate"} show={busy}/>}</BusyFromPromise>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} parsedHelpLinks={errors.parsedHelpLinks} helpText={errors.helpText} helpLink={errors.helpLink} statusCode={errors.statusCode}/>}
            {children}
        </div>);
}
const loadingStyles = css({
    position: "relative",
});
