import { Snackbar } from "@octopusdeploy/design-system-components";
import type { PageAction } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { PropsWithChildren } from "react";
import * as React from "react";
import { useState } from "react";
import { AddProjectTemplateButton } from "~/areas/projects/components/Variables/TenantVariables/AddProjectTemplateButton";
import { IncludeLibraryVariableSetsButton } from "~/areas/projects/components/Variables/TenantVariables/IncludeLibraryVariableSetsButton";
import type { TemplatesWarnings } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesNavigationTabs";
import ProjectTenantVariablesNavigationTabs from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesNavigationTabs";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent/index";
import { Form } from "~/components/FormPaperLayout/Form";
import { PaperLayoutVNext } from "~/components/PaperLayout/PaperLayoutVNext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
interface ProjectTenantVariablesInlineLayoutProps {
    project: ProjectResource;
    busy: Promise<void> | undefined;
    errors: Errors | undefined;
    doBusyTask: DoBusyTask;
    saveAction: TenantVariablesPageSaveAction | undefined;
    warnings: TemplatesWarnings;
    page: TemplatesPage;
}
type TemplatesPage = "project" | "common";
const noChanges = { hasChanges: false };
export default function ProjectTenantVariablesInlineLayout({ children, project, busy, errors, doBusyTask, saveAction, warnings, page }: PropsWithChildren<ProjectTenantVariablesInlineLayoutProps>) {
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const openSnackbar = (text: string) => {
        setIsSnackbarOpen(true);
        setSnackbarText(text);
    };
    const createProjectTemplatesActions = (): PageAction[] => {
        const showOnboardingPage = !project.Templates || project.Templates.length === 0;
        if (showOnboardingPage) {
            return [];
        }
        const addProjectTemplate: PageAction = {
            type: "custom",
            key: "Add project template",
            hasPermissions: isAllowed({ permission: Permission.ProjectEdit, project: project.Id }),
            content: <AddProjectTemplateButton project={project} doBusyTask={doBusyTask} openSnackbar={openSnackbar}/>,
        };
        return [addProjectTemplate];
    };
    const createCommonTemplatesActions = (): PageAction[] => {
        const showOnboardingPage = !project.IncludedLibraryVariableSetIds || project.IncludedLibraryVariableSetIds.length === 0;
        if (showOnboardingPage) {
            return [];
        }
        const includeSetAction: PageAction = {
            type: "custom",
            key: "Include Variable Sets",
            hasPermissions: isAllowed({ permission: Permission.ProjectEdit, project: project.Id }),
            content: <IncludeLibraryVariableSetsButton project={project} doBusyTask={doBusyTask} openSnackbar={openSnackbar}/>,
        };
        return [includeSetAction];
    };
    const actions = buildPageActions(page, createProjectTemplatesActions, createCommonTemplatesActions);
    const model = { hasChanges: !!(saveAction && !saveAction.disabled) };
    return (<Form model={model} cleanModel={noChanges} savePermission={{
            permission: Permission.VariableEdit,
            project: project.Id,
            wildcard: true,
        }} saveText={"Variables updated"} onSaveClick={(isNavigationConfirmation: boolean) => saveAction?.onClick()} disableDirtyFormChecking={false} disableKeyboardFormSubmission={true}>
            {({ FormContent, createSaveAction }) => (<PaperLayoutVNext primaryAction={createSaveAction({})} title={"Tenant Variables"} busy={busy} errors={errors} fullWidth={true} pageActions={actions} disableAnimations={true}>
                    <FormContent>
                        <ProjectTenantVariablesNavigationTabs warnings={warnings}></ProjectTenantVariablesNavigationTabs>
                        {children}
                        <Snackbar open={isSnackbarOpen} content={snackbarText} autoHideDuration={3500} onClose={() => setIsSnackbarOpen(false)} textAlign={"center"}/>
                    </FormContent>
                </PaperLayoutVNext>)}
        </Form>);
}
function buildPageActions(page: TemplatesPage, createForProjectTemplates: () => PageAction[], createForCommonTemplates: () => PageAction[]): PageAction[] {
    if (page === "project") {
        return createForProjectTemplates();
    }
    if (page === "common") {
        return createForCommonTemplates();
    }
    return [];
}
export interface TenantVariablesPageSaveAction {
    onClick: () => Promise<void>;
    disabled: boolean;
}
