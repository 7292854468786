// copied from material-ui so that we can set tabIndex
import { IconButton } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
interface CardExpandableProps {
    expanded?: boolean;
    tabIndex?: number;
    position?: boolean;
    onExpanding?(event: React.MouseEvent<{}, MouseEvent>): void;
}
class CardExpandable extends React.Component<CardExpandableProps> {
    render() {
        return <IconButton onClick={this.props.onExpanding} tabIndex={this.props.tabIndex} icon="Expand" className={cn(this.props.expanded ? styles.expandOpen : styles.expandClosed)}/>;
    }
    static displayName = "CardExpandable";
}
export default CardExpandable;
