/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { EnvironmentResource, TenantResource, ProjectGroupResource, UserPermissionRestriction, PermissionDescriptions, PermissionDescription, ProjectSummaryResource } from "@octopusdeploy/octopus-server-client";
import { isAllProjectGroups, isAllProjects, isAllEnvironments, isAllTenants } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { sortBy } from "lodash";
import * as React from "react";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { DataTable } from "~/primitiveComponents/dataDisplay/DataTable/DataTable";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableHeader } from "~/primitiveComponents/dataDisplay/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableHeaderColumn";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
interface Props {
    permissions: Record<string, UserPermissionRestriction[]>;
    permissionDescriptions: PermissionDescriptions;
    projectSummaries: Record<string, ProjectSummaryResource>;
    environments: Record<string, EnvironmentResource>;
    tenants: Record<string, TenantResource>;
    projectGroups: Record<string, ProjectGroupResource>;
    showRestrictions: boolean;
}
interface PermissionDetails {
    name: string;
    description: PermissionDescription;
    restrictions: UserPermissionRestriction[];
}
type restrictionType = "projects" | "projectgroups" | "tenants" | "environments";
const PermissionTable = (props: Props) => {
    const supportsRestriction = (p: PermissionDetails, category: restrictionType) => p.restrictions && p.description.SupportedRestrictions.indexOf(category) >= 0;
    const buildLink = (collection: Record<string, {
        Name: string;
        SpaceId?: string;
    }>, id: string, getlink: (spaceId: string) => LinkHref) => {
        if (!collection.hasOwnProperty(id)) {
            return id;
        }
        const name = collection[id].Name;
        const spaceId = collection[id].SpaceId;
        const path = getlink(spaceId!);
        return (<InternalLink key={`${path}-${name}`} to={path}>
                {name}
            </InternalLink>);
    };
    const commaDelimitList = (list: React.ReactNode[]) => {
        const result: React.ReactNode[] = [];
        list.forEach((l, i) => {
            result.push(l);
            if (i !== list.length - 1) {
                result.push(", ");
            }
        });
        return result;
    };
    const permissionsDetails: PermissionDetails[] = Object.keys(props.permissionDescriptions)
        .filter((p) => props.permissions[p])
        .map((p) => ({ name: p, description: props.permissionDescriptions[p], restrictions: props.permissions[p] }));
    const sortedPermissionDetails: PermissionDetails[] = sortBy(permissionsDetails, "name");
    return (<React.Fragment>
            {sortedPermissionDetails.length > 0 && (<DataTable>
                    <DataTableHeader>
                        <DataTableRow>
                            <DataTableHeaderColumn>Permission</DataTableHeaderColumn>
                            {props.showRestrictions && <DataTableHeaderColumn>Project Groups</DataTableHeaderColumn>}
                            {props.showRestrictions && <DataTableHeaderColumn>Projects</DataTableHeaderColumn>}
                            {props.showRestrictions && <DataTableHeaderColumn>Environments</DataTableHeaderColumn>}
                            {props.showRestrictions && <DataTableHeaderColumn>Tenants</DataTableHeaderColumn>}
                        </DataTableRow>
                    </DataTableHeader>
                    <DataTableBody>
                        {sortedPermissionDetails.map((permissionDetail) => permissionDetail.restrictions.map((restriction, index) => (<DataTableRow key={permissionDetail.name + index}>
                                    {index === 0 ? <DataTableRowColumn title={permissionDetail.description.Description}>{permissionDetail.name}</DataTableRowColumn> : <DataTableRowColumn />}

                                    {props.showRestrictions && (<DataTableRowColumn>
                                            {supportsRestriction(permissionDetail, "projectgroups") && (<span>
                                                    {!isAllProjectGroups(restriction.RestrictedToProjectGroupIds)
                            ? commaDelimitList(restriction.RestrictedToProjectGroupIds.map((id: string) => buildLink(props.projectGroups, id, (spaceId: string) => links.editProjectGroupPage.generateUrl({ spaceId, projectGroupId: id }))))
                            : "All project groups"}
                                                </span>)}
                                        </DataTableRowColumn>)}
                                    {props.showRestrictions && (<DataTableRowColumn>
                                            {supportsRestriction(permissionDetail, "projects") && (<span>
                                                    {!isAllProjects(restriction.RestrictedToProjectIds)
                            ? commaDelimitList(restriction.RestrictedToProjectIds.map((id: string) => buildLink(props.projectSummaries, id, (spaceId: string) => links.projectRootRedirect.generateUrl({ spaceId, projectSlug: id }))))
                            : "All projects"}
                                                </span>)}
                                        </DataTableRowColumn>)}
                                    {props.showRestrictions && (<DataTableRowColumn>
                                            {supportsRestriction(permissionDetail, "environments") && (<span>
                                                    {!isAllEnvironments(restriction.RestrictedToEnvironmentIds)
                            ? commaDelimitList(restriction.RestrictedToEnvironmentIds.map((id: string) => buildLink(props.environments, id, (spaceId: string) => links.infrastructureEnvironmentPage.generateUrl({ spaceId, environmentId: id }))))
                            : "All environments"}
                                                </span>)}
                                        </DataTableRowColumn>)}
                                    {props.showRestrictions && (<DataTableRowColumn>
                                            {supportsRestriction(permissionDetail, "tenants") && (<span>
                                                    {!isAllTenants(restriction.RestrictedToTenantIds)
                            ? commaDelimitList(restriction.RestrictedToTenantIds.map((id: string) => buildLink(props.tenants, id, (spaceId: string) => links.tenantOverviewPage.generateUrl({ spaceId, tenantId: id }))))
                            : "All tenants"}
                                                </span>)}
                                        </DataTableRowColumn>)}
                                </DataTableRow>)))}
                    </DataTableBody>
                </DataTable>)}
        </React.Fragment>);
};
export { PermissionTable };
