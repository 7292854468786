import type { DesignSystemLinkProps } from "@octopusdeploy/design-system-components";
import type { LinkEvent } from "@octopusdeploy/portal-routes";
import { nameForLink, resolveStringPathWithSpaceId, useDispatchLinkClicked } from "@octopusdeploy/portal-routes";
import { isUrl } from "app/components/Navigation/InternalLink/isUrl";
import { useUrlResolver } from "app/components/Navigation/useUrlResolver";
import * as React from "react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
export const PortalDesignSystemLink = React.forwardRef<HTMLAnchorElement, DesignSystemLinkProps>(({ className, href, tabIndex, onClick, children, ...other }, ref) => {
    const dispatchLinkClicked = useDispatchLinkClicked();
    const urlResolver = useUrlResolver();
    // The space id is currently fetched from the route params in order to resolve any space id in the href.
    // Ideally this link would not need to know anything about spaces, and the href passed in would already have the space id resolved.
    // However, because of how routeLinks are structured, they require us to resolve the space id on the fly.
    const routeParams = useParams<{
        spaceId: string | undefined;
    }>();
    const spaceId = routeParams.spaceId ?? undefined;
    const path = href ? (isUrl(href) ? urlResolver.resolve(href) : resolveStringPathWithSpaceId(href, spaceId)) : undefined;
    const handleLinkClicked: React.MouseEventHandler<HTMLAnchorElement> = React.useCallback((ev) => {
        const target = ev.currentTarget;
        const label = target.innerText ? target.innerText : target.getAttribute("aria-label") ?? "";
        const linkEvent: LinkEvent = {
            linkLabel: label,
            documentUrl: ev.currentTarget.href,
        };
        dispatchLinkClicked(nameForLink(), linkEvent);
        onClick?.(ev);
    }, [dispatchLinkClicked, onClick]);
    if (path === undefined) {
        return null;
    }
    return (<ReactRouterLink className={className} innerRef={ref} to={path} target="_self" tabIndex={tabIndex} onClick={handleLinkClicked} 
    /*
    We need to spread unknown props here as the design link could be used
    in situations where the props aren't explicit i.e. material menu list items,
    the basic props for regular usage should still be constrained.
    */
    {...other}>
            {children}
        </ReactRouterLink>);
});
