import cssVars from "css-vars-ponyfill";
import React from "react";
import { useThemePaletteType } from "~/components/Theme/useThemePaletteType";
import type { ThemePaletteType } from "~/theme";
import type { OctopusTheme } from "./Themes";
import { LightTheme, DarkTheme } from "./Themes";
export const ThemeApplier: React.FC = React.memo(() => {
    const palette = useThemePaletteType();
    React.useEffect(() => {
        applyTheme(palette);
    }, [palette]);
    return null;
});
ThemeApplier.displayName = "ThemeApplier"
const lookup: Record<ThemePaletteType, OctopusTheme> = {
    light: LightTheme,
    dark: DarkTheme,
};
function applyTheme(themeId: ThemePaletteType) {
    if (!themeId) {
        return;
    }
    const theme = lookup[themeId];
    if (!theme) {
        return;
    }
    const keys = Object.entries(theme);
    const variables: Record<string, string> = {};
    for (const [key, value] of keys) {
        variables[key] = value;
    }
    cssVars({
        watch: true,
        variables,
    });
}
