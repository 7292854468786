import type { ChannelResource, GetTenantedProjectDashboardBffResponse, LifecycleResource, OverviewReleaseBff, ProjectResource, TenantedProjectDashboardFilter } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import type { RenderDashboardProps } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import { dashboardRefreshIntervalInMs } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import DashboardDataCube from "~/areas/projects/components/DashboardDataSource/DashboardDataCube";
import type { DataCube, DeploymentOverviewFilters } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "~/components/tenantTagsets";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface TenantedProjectDashboardDataSourceProps {
    project: ProjectResource;
    filters: DeploymentOverviewFilters;
    render: (props: RenderDashboardProps) => JSX.Element;
    doBusyTask: DoBusyTask;
    onDashboardLoad: (filters: DeploymentOverviewFilters, pageNumber: number, pageSize: number) => void;
    pageNumber: number;
    pageSize: number;
}
export default function TenantedProjectDashboardDataSource({ doBusyTask, filters, project, render, onDashboardLoad, pageNumber, pageSize }: TenantedProjectDashboardDataSourceProps) {
    const [cube, setCube] = useState<DataCube | undefined>(undefined);
    const [totalItems, setTotalItems] = useState<number | undefined>(undefined);
    const dashboardRequest = useDoBusyTaskEffect(doBusyTask, async () => {
        const { cube, totalItems } = await tenantedProjectDashboardRefresh(project, filters, pageNumber, pageSize);
        setCube(cube);
        setTotalItems(totalItems);
        onDashboardLoad(filters, pageNumber, pageSize);
    }, [filters, project, pageNumber, pageSize], { timeOperationOptions: timeOperationOptions.forRefresh() });
    useRefreshLoop(dashboardRequest, dashboardRefreshIntervalInMs);
    return render({ cube, totalItems });
}
function loadTenantedProjectDashboardData(project: ProjectResource, filters: DeploymentOverviewFilters, pageNumber: number, pageSize: number, releaseId?: string): Promise<GetTenantedProjectDashboardBffResponse> {
    const selectedTenants = tryGetDeploymentOverviewFilterValues(filters, DimensionTypes.Tenant);
    const selectedTags = tryGetDeploymentOverviewFilterValues(filters, DimensionTypes.TagSet);
    const args: TenantedProjectDashboardFilter = { projectId: project.Id, showAll: true, selectedTenants, selectedTags, releaseId, skip: (pageNumber - 1) * pageSize, take: pageSize };
    return repository.Dashboards.getTenantedProjectDashboard(args);
}
async function tenantedProjectDashboardRefresh(project: ProjectResource, filters: DeploymentOverviewFilters, skip: number, take: number): Promise<{
    cube: DataCube;
    totalItems: number;
}> {
    const releaseId = tryGetDeploymentOverviewFilterValues(filters, DimensionTypes.Release)?.[0];
    const releases = await loadReleases(project, releaseId);
    const channelsLoader = repository.Channels.allFromProject(project);
    const [{ Dashboard: dashboard, TotalItems: totalItems }, channels, promotions, tagSets, lifecycles] = await Promise.all([
        loadTenantedProjectDashboardData(project, filters, skip, take, releaseId),
        channelsLoader,
        releaseId ? repository.Releases.getDeploymentTemplate(releases[0]) : Promise.resolve(null),
        tenantTagsets.getAll(),
        loadLifecycles(channelsLoader),
    ]);
    const deploymentFreezes = isFeatureToggleEnabled("DeploymentFreezeFeatureToggle") ? await repository.DeploymentFreezes.allActive() : { DeploymentFreezes: [] };
    const missingVariableTenantsPromise = listTenantsWithMissingVariables(project);
    const cube = new DashboardDataCube(dashboard, dashboard.Tenants, releases, channels, promotions, tagSets, lifecycles, missingVariableTenantsPromise, deploymentFreezes.DeploymentFreezes, project, releaseId);
    return { cube, totalItems };
}
async function loadReleases(project: ProjectResource, releaseId?: string): Promise<OverviewReleaseBff[]> {
    if (releaseId) {
        return Promise.resolve([await repository.Releases.get(releaseId)]);
    }
    return await repository.Projects.getBffOverviewReleases(project);
}
function tryGetDeploymentOverviewFilterValues(filters: DeploymentOverviewFilters, dimensionType: DimensionTypes) {
    return filters[dimensionType] ? Object.keys(filters[dimensionType]) : undefined;
}
async function loadLifecycles(channelsAsync: Promise<ChannelResource[]>): Promise<LifecycleResource[]> {
    const channels = await channelsAsync;
    const lifecycleIds = channels.map((channel) => channel.LifecycleId);
    const lifecycles = await repository.Lifecycles.allById(lifecycleIds);
    return Object.keys(lifecycles).map((id) => lifecycles[id]);
}
async function listTenantsWithMissingVariables(project: ProjectResource): Promise<string[]> {
    const hasLibraryVariableSetView = isAllowed({ permission: Permission.LibraryVariableSetView, environment: "*", tenant: "*" });
    if (hasLibraryVariableSetView) {
        const missingVariables = await repository.Tenants.missingVariables({ projectId: project.Id }, false);
        return missingVariables.map((mv) => mv.TenantId);
    }
    return Promise.resolve([]);
}
