/* eslint-disable @typescript-eslint/no-explicit-any */
import { TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { TriggersPage } from "~/areas/projects/components/Triggers/TriggersPage";
interface RunbookTriggersPageProps {
    spaceId: string;
    projectSlug: string;
}
export const RunbookTriggersPage: React.FC<RunbookTriggersPageProps> = ({ spaceId, projectSlug }) => {
    return <TriggersPage spaceId={spaceId} projectSlug={projectSlug} triggerActionCategory={TriggerActionCategory.Runbook}/>;
};
RunbookTriggersPage.displayName = "RunbookTriggersPage"
