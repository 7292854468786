import type { ActivityLogElement } from "@octopusdeploy/octopus-server-client";
import { ActivityLogEntryCategory } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import * as React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { createLinkGeneratorForElement, getSelectedLogLine, toggleSelectedLine } from "~/areas/tasks/components/Task/TaskLog/TaskLogUtil";
import type { UniqueActivityElement } from "~/components/TaskLogLines/TaskLogBlock";
import { TaskLogGap } from "~/components/TaskLogLines/TaskLogGap";
import { TaskLogLine } from "~/components/TaskLogLines/TaskLogLine";
import styles from "~/components/TaskLogLines/style.module.less";
import logStyles from "./taskLogBlock.module.less";
interface TaskLogLinesProps {
    lines: ActivityLogElement[];
    parent?: UniqueActivityElement;
    showTimestamps: boolean;
    onFetchRange(start: number, end: number): void;
    isFetchDisabled: boolean;
}
const TaskLogLines = (props: TaskLogLinesProps) => {
    const nav = { location: useLocation(), history: useHistory() };
    const selfRef = React.useRef<HTMLDivElement>(null);
    useEffect(() => {
        const currentElement = selfRef.current;
        if (!currentElement) {
            return;
        }
        const prettyCopyListener = (event: ClipboardEvent) => {
            if (document === null)
                return;
            const selection = document.getSelection()?.toString() ?? "";
            if (!selection.indexOf("##OCTOCOPY##"))
                return;
            const cleaned = selection
                .split("##OCTOCOPY##")
                .map((line) => {
                const trimmed = line.trim();
                let parts = trimmed.split("\n");
                if (parts.length == 2) {
                    // text without timestamps
                    return parts[1];
                }
                if (!(parts.length == 3 || parts.length == 4)) {
                    return trimmed.trim();
                }
                if (parts.length == 4) {
                    // Remove line number
                    parts = parts.slice(1);
                }
                const time = parts[0];
                const category = parts[1];
                const message = parts[2];
                return [time, category.padEnd(12, " "), message].join("  ");
            })
                .join("\n");
            event.clipboardData?.clearData();
            event.clipboardData?.setData("text/plain", cleaned);
            event.preventDefault();
        };
        currentElement.addEventListener("copy", prettyCopyListener);
        return () => {
            currentElement.removeEventListener("copy", prettyCopyListener);
        };
    });
    if (!(props.lines && props.lines.length > 0))
        return <></>;
    const linkTemplate = (props.parent && createLinkGeneratorForElement(nav.location, props.parent.uniqueId)) || ((l: number) => undefined);
    const onToggleLine = (line: ActivityLogElement) => props.parent && toggleSelectedLine(nav, props.parent.uniqueId, line.Number);
    const selection = getSelectedLogLine(nav);
    const lastLine = props.lines[props.lines.length - 1];
    const isBig = (lastLine.Number != undefined && lastLine.Number > 5000) ?? false;
    return (<div ref={selfRef} className={cn(logStyles.lines, styles.copyHelper)}>
            {props.lines.map((l, n) => l.Category === ActivityLogEntryCategory.Gap ? (<TaskLogGap key={l.Number || n} line={l} splitGaps={isBig} showTimestamps={props.showTimestamps} onFetchRange={props.onFetchRange} isFetchDisabled={props.isFetchDisabled}/>) : (<TaskLogLine key={l.Number || n} line={l} link={l.Number ? linkTemplate(l.Number) : undefined} onToggleLine={onToggleLine} selected={selection?.line === l.Number && selection?.elementUniqueId == props.parent?.uniqueId} showTimestamps={props.showTimestamps}/>))}
        </div>);
};
export default TaskLogLines;
