import type { CommonInputProps, SectionComponent, SectionGroupComponent } from "@octopusdeploy/step-ui";
import React from "react";
import type { RenderedComponentAndSummary } from "~/components/StepPackageEditor/EditStepPackageInputs";
import { Section } from "~/components/StepPackageEditor/Structure/Section/Section";
import { getUnnamedSectionKey, UnnamedSection } from "~/components/StepPackageEditor/Structure/UnnamedSection/UnnamedSection";
import { groupInputComponents } from "~/components/StepPackageEditor/Structure/groupInputComponents";
import { FormSectionHeading } from "~/components/form/Sections";
interface SectionGroupProps<StepInputs, TInputComponents> {
    sectionGroup: SectionGroupComponent<TInputComponents>;
    isExpandedByDefault: boolean;
    getRenderedComponentAndSummary(component: TInputComponents): RenderedComponentAndSummary;
}
export function SectionGroup<StepInputs, TInputComponents extends CommonInputProps>(props: SectionGroupProps<StepInputs, TInputComponents>) {
    const groupedContent = groupInputComponents<SectionComponent<TInputComponents>, TInputComponents>(props.sectionGroup.content, (component): component is SectionComponent<TInputComponents> => "type" in component && component.type === "section");
    return (<>
            <FormSectionHeading title={props.sectionGroup.title}/>
            {groupedContent.map((groupedContent, i) => {
            if (Array.isArray(groupedContent)) {
                return (<UnnamedSection<StepInputs, TInputComponents> key={getUnnamedSectionKey(groupedContent)} content={groupedContent} isExpandedByDefault={props.isExpandedByDefault} getRenderedComponentAndSummary={props.getRenderedComponentAndSummary}/>);
            }
            return <Section key={groupedContent.title} section={groupedContent} isExpandedByDefault={props.isExpandedByDefault} getRenderedComponentAndSummary={props.getRenderedComponentAndSummary}/>;
        })}
        </>);
}
